import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import SignatureCanvas from 'react-signature-canvas';

const SignatureInputField = ({
  initialSignatureBase64,
  onChange,
  setCanvasRef,
  isInvalid,
}) => {
  const { t, _ } = useTranslation();
  const [typeNameInstead, setTypeNameInstead] = useState(false);
  const [typedName, setTypedName] = useState('');
  const [disableClearButton, setDisableClearButton] = useState(true);

  let signaturePad = null;
  useEffect(() => {
    signaturePad.fromDataURL(initialSignatureBase64 || '');
  }, []);

  useEffect(() => {
    if (typedName.length > 0) {
      const canvas = signaturePad.getCanvas();
      const canvasContext = canvas.getContext('2d'); canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      canvasContext.font = '60px Parisienne';
      canvasContext.fillText(typedName.trim(), 15, canvas.height / 4);

      onChange(signaturePad.toDataURL())
    }
  }, [typedName]);

  return (
    <div>
      <div style={{ height: '120px', maxWidth: '750px' }} className="mb-2">
        <SignatureCanvas
          ref={(ref) => {
            if (setCanvasRef) setCanvasRef(ref);
            signaturePad = ref;
            setDisableClearButton(signaturePad?.isEmpty());
          }}
          canvasProps={{
            className: 'canvas-style h-100 w-100 rounded',
            style: {borderColor: "#CBD2E0"},
            name: 'signature',
            'data-test-hook': 'signature',
            'aria-describedby': 'signatureErrorDesc',
            'aria-invalid': isInvalid,
            tabIndex: 0,
            label: 'signature pad',
          }}
          onEnd={(data) => {
            onChange(signaturePad.toDataURL())
          }}
          clearOnResize={false}
        />
        <span
          className='text-secondary fw-bold text-decoration-underline pointer float-start my-2'
          style={{fontSize: 14}}
          onClick={(e) => {
            e.preventDefault();
            setTypeNameInstead(true);
            return false;
          }}
        >
          {t('translation.consent.type_name')}
        </span> 
        <button
          className={`btn btn-link float-end pointer black ${
            disableClearButton && !typedName ? 'disabled' : ''
          }`}
          style={{ lineHeight: 1.5, fontSize: '1rem', fontWeight: 400 }}
          onClick={(e) => {
            e.preventDefault();
            signaturePad.clear();
            onChange('')
            return false;
          }}
          href="#"
          type="button"
        >
          {t('registration.clear_button')}
        </button>
      </div>
      <div className="mt-5 mb-4">
        {typeNameInstead && (
          <div className="input-container mt-3">
            <Form.Control
              className="w-100"
              style={{ maxWidth: '500px' }}
              autoFocus
              type="string"
              value={typedName}
              onChange={(e) => setTypedName(e.target.value)}
              aria-describedby="signatureErrorDesc"
              aria-invalid={isInvalid}
              id="typed_name"
            />
            <label className={typedName && 'filled'} htmlFor="typed_name">
              {t('translation.consent.type_name')}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignatureInputField;
