import React from 'react';
import '../../../common/locales/i18n';
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import MemberLink from '../common/MemberLink';
import { useMediaQuery } from 'react-responsive';
import MemberWrapper from './MemberWrapper';

const ActionWrapper = ({
  backLink,
  showSettings=false,
  title,
  callToActionProps={},
  callToActionTitle,
  children
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
 
  return isMobile ? (
    <div
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        paddingTop: "10vw",
        zIndex: 99,
      }}
    >
      <Row className='mb-2'>
        <Col xs="auto me-auto">
          <MemberLink to={backLink}>
            <FontAwesomeIcon icon={faChevronLeft} className="text-dark" />
          </MemberLink>
        </Col>
        {showSettings &&
          <Col xs="auto">
            <MemberLink to="/members/settings">
              <img src="/images/login/settings.svg" />
            </MemberLink>
          </Col>
        }
      </Row>
      <h4 className='mb-4'>{title}</h4>
      {children}
      <Button
        block
        {...callToActionProps}
        data-test-hook={callToActionTitle}
        style={{position: "absolute", bottom: "4vh", width: "90vw", textDecoration: "none"}}
      >
        {callToActionTitle}
      </Button>
    </div>
  ) : (
    <MemberWrapper>
      <h4 className='mb-4'>{title}</h4>
      <div style={{maxWidth: 450}}>
        {children}
        <Button block {...callToActionProps} data-test-hook={callToActionTitle} className='mt-3'>
          {callToActionTitle}
        </Button>
      </div>
    </MemberWrapper>
  );
};

export default ActionWrapper;
