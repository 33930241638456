import React, { useState } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';

import ArabEthnicityDropdown from '../../../../Registration/components/registration/inputs/ArabEthnicityDropdown';
import CheckInContext from './CheckInContext';
import { ChevronDropDown } from '../../../../common/components/Chevron';
import EthnicityDropdown from '../../../../Registration/components/registration/inputs/EthnicityDropdown';
import GenderInput from '../../../../Registration/components/registration/inputs/GenderInput';
import Pronouns from '../../../../Registration/components/registration/inputs/Pronouns';
import RaceDropdown from '../../../../Registration/components/registration/inputs/RaceDropdown';
import SelectLanguage from '../../../../Registration/components/registration/inputs/SelectLanguage';
import SexAtBirthDropdown from '../../../../Registration/components/registration/inputs/SexAtBirthDropdown';
import SexualOrientation from '../../../../Registration/components/registration/inputs/SexualOrientation';
import { showFieldOnRegistration } from '../../../../Registration/components/registration/utils';

const DemographicSection = ({ testGroup, user }) => {

  const [show, setShow] = useState(false);
  const showField = (field) => showFieldOnRegistration(testGroup, field);

  const [races, setRaces] = useState(user.races);
  const [ethnicityBlob, setEthnicityBlob] = useState(user.ethnicity_blob);
  const [arabEthnicity, setArabEthnicity] = useState(user.arab_ethnicity);
  const [sexAtBirth, setSexAtBirth] = useState(user.sex_at_birth);
  const [genderString, setGenderString] = useState(user.gender_string);
  const [sexualOrientation, setSexualOrientation] = useState(user.sexual_orientation);
  const [pronoun, setPronoun] = useState(user.pronoun);
  const [languagePreference, setLanguagePreference] = useState(user.language_preference);

  const context = {
    testGroup,
    localFormState: {},
    setLocalFormState: () => {},
  };

  const inputFields = [
    showField('race') && (
      <div className="my-2">
        <input type="hidden" value={JSON.stringify(races)} name="user[races]" />
        <RaceDropdown
          setFieldValue={(e, races) => setRaces(races)}
          values={{'races': races}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('ethnicity') && (
      <div className="my-3">
        <input type="hidden" value={JSON.stringify(ethnicityBlob)} name="user[ethnicity_blob]" />
        <EthnicityDropdown
          setFieldValue={(e, ethnicityBlob) => setEthnicityBlob(ethnicityBlob)}
          values={{'ethnicity_blob': ethnicityBlob}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('arab_ethnicity') && (
      <div className="my-4">
        <input type="hidden" value={arabEthnicity} name="user[arab_ethnicity]" />
        <ArabEthnicityDropdown
          setFieldValue={(e, arabEthnicity) => setArabEthnicity(arabEthnicity)}
          values={{'arab_ethnicity': arabEthnicity}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('sex_at_birth') && (
      <div className="my-3">
        <input type="hidden" value={sexAtBirth} name="user[sex_at_birth]" />
        <SexAtBirthDropdown
          setFieldValue={(e, sexAtBirth) => setSexAtBirth(sexAtBirth)}
          values={{'sex_at_birth': sexAtBirth}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('gender_identity') && (
      <div>
        <input type="hidden" value={genderString} name="user[gender_string]" />
        <GenderInput
          setFieldValue={(e, genderString) => setGenderString(genderString)}
          values={{'gender_string': genderString}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('sexual_orientation') && (
      <div>
        <input type="hidden" value={sexualOrientation} name="user[sexual_orientation]" />
        <SexualOrientation
          setFieldValue={(e, sexualOrientation) => setSexualOrientation(sexualOrientation)}
          values={{'sexual_orientation': sexualOrientation}}
          context={CheckInContext}
        />
      </div>
    ),
    showField('pronoun') && (
      <div>
        <input type="hidden" value={pronoun} name="user[pronoun]" />
        <Pronouns
          setFieldValue={(e, pronoun) => setPronoun(pronoun)}
          values={{'pronoun': pronoun}}
          context={CheckInContext}
        />
      </div>
    ),
    (showField('language_preference') || showField('preferred_language')) && (
      <div>
        <input type="hidden" value={languagePreference} name="user[language_preference]" />
        <SelectLanguage
          setFieldValue={(e, languagePreference) => setLanguagePreference(languagePreference)}
          values={{'language_preference': languagePreference}}
          context={CheckInContext}
        />
      </div>
    )
  ].filter(v => !!v);

  const half = Math.ceil(inputFields.length / 2);
  const firstHalf = inputFields.slice(0, half);
  const secondHalf = inputFields.slice(half);

  return (
    <CheckInContext.Provider value={context}>
      <div className="my-3">
        <hr/>
        <div onClick={() => setShow(!show)} className="h5-vivid-blue" data-test-hook="demographic_information_collapse">
          <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
          <h5>Demographic information</h5>
        </div>

        <Collapse in={show}>
          <Row>
            <Col xs={12} xl={6}>
              {firstHalf}
            </Col>
            <Col xs={12} xl={6}>
              {secondHalf}
            </Col>
          </Row>
        </Collapse>
      </div>
    </CheckInContext.Provider>
  );
};

export default DemographicSection;