import React, { useState, useRef, useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark, faRotate, faRightLeft } from '@fortawesome/pro-solid-svg-icons';

export default ({ setImageData, setShow, guideImage, style={} }) => {
  const webcamRef = useRef(null);
  const [showFrontCamera, setCameraView] = useState(false);
  const [deviceIndex, setDeviceIndex] = useState(0);
  const [deviceId, setDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);
  const capture = useCallback(
    () => {
      const imageData = webcamRef.current.getScreenshot();
      setImageData(imageData);
      setShow(false);
    },
    [webcamRef]
  );

  const handleDevices = useCallback((mediaDevices) => {
    setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
  }, [setDevices]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <div>
      <div style={{margin: "0 auto", maxWidth: 500, position: 'relative'}}>
        <FontAwesomeIcon
          className="pointer text-white"
          icon={faCircleXmark}
          onClick={() => setShow(false)}
          style={{
            position: 'absolute',
            right: '10px',
            transform: 'translateY(10px)',
            zIndex: 999,
          }}
        />
        <div className="camera-wrapper">
          {guideImage && (
            <div className="camera-image-overlay">
              <img src={guideImage} />
            </div>
          )}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={style}
            videoConstraints={{
              width: 500,
              height: 500,
              deviceId: devices[deviceIndex % devices.length],
              facingMode: showFrontCamera ? "user" : "environment"
            }}
          />
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="my-1 p-2 d-flex justify-content-around" style={{ gap: 12 }}>
        <Button
          className="flex-grow-1"
          style={{ minWidth: 120 }}
          onClick={capture}
        >
          <FontAwesomeIcon icon={faCamera} />
        </Button>
        <Button
          className="flex-grow-1"
          variant="outline-primary"
          style={{ minWidth: 120 }}
          onClick={() => setCameraView(!showFrontCamera)}
        >
          <FontAwesomeIcon icon={faRotate} />
        </Button>
        {
          devices.length > 1 && 
            <Button
              className="flex-grow-1"
              variant="outline-primary"
              style={{ minWidth: 120 }}
              onClick={() => setDeviceIndex(deviceIndex + 1)}
            >
              <FontAwesomeIcon icon={faRightLeft} />
            </Button>
        }
      </div>
    </div>
  );
};

