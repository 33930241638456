import React from 'react';
import FloatingLabelInput from '../common/components/FloatingLabelInput';

const AutocompleteSearch = ({
  dataTestHook,
  id,
  input,
  label,
  name,
  onChange,
  autoFocus = false,
  onKeyDown,
}) => {
  return (
    <div>
      <FloatingLabelInput
        id={id}
        dataTestHook={dataTestHook}
        onChange={(e) => onChange(e)}
        label={label}
        name={name}
        prependIcon="search"
        value={input}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}
export default AutocompleteSearch;
