import React, { useState, useContext } from 'react';
import '../../common/locales/i18n';
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import ActionWrapper from './partials/ActionWrapper';
import axios from 'axios';
import MemberLink from './common/MemberLink';
import { currentLanguage } from './utils';
import MembersPortalContext from '../context';
import { useMediaQuery } from 'react-responsive';

const Settings = () => {
  const [showDeleteModal, openDeleteModal] = useState(false);
  const { currentUser, t } = useContext(MembersPortalContext); 
  const buildRow = (title, subtitle="") => (
    <Row style={{minHeight: "7vh"}}>
      <Col xs={9} style={{alignSelf: "center"}}>
        <div className='list-title text-dark'>{title}</div>
        <div className='text-muted list-subtitle'>{subtitle}</div>
      </Col>
      <Col xs="auto" className='ms-auto' style={{alignSelf: "center"}}>
        <FontAwesomeIcon icon={faChevronRight} className="text-dark" />
      </Col>
    </Row>
  );

  const handleDestroyAccount = () => {
    axios.delete("/members/household_members/destroy_account")
      .then((_) => window.location.href = "/members/sign_out")
      .catch((_) => alert("something went wrong"))
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <ActionWrapper
        backLink="/members/choose_member"
        title={t("login.account_and_settings")}
        callToActionProps={{variant: "danger", href: "/members/sign_out"}}
        callToActionTitle={t('login.log_out')}
      >
        <MemberLink to="/members/language" style={{textDecoration: "none"}}>
          {buildRow(t('login.language'), t("login.language_current", {language: currentLanguage(currentUser.user.locale)}))}
        </MemberLink>
        <hr/>
        <MemberLink to="/members/contact_us" style={{textDecoration: "none"}}>
          {buildRow(t('login.route.help'))}
        </MemberLink>
        <hr/>
        <a href="/terms-of-service" style={{textDecoration: "none"}}>
          {buildRow(t('self_resulting.terms_of_service'))}
        </a>
        <hr/>
        <Row onClick={() => openDeleteModal(true)} data-test-hook={t('login.delete_account')} className="pointer">
          <Col xs={9}>
            <h4 className='list-title text-dark'>{t('login.delete_account')}</h4>
          </Col>
          <Col xs="auto" className='ms-auto' style={{alignSelf: "center"}}>
            <FontAwesomeIcon icon={faTrashCan} />
          </Col>
        </Row>
      </ActionWrapper>
      <Modal
        show={showDeleteModal}
        onHide={() => openDeleteModal(false)}
        centered
        size={isMobile ? "md" : "lg"}
        className='members'
      >
        <Modal.Body>
          <h2>{t('login.delete_account_title')}</h2>
          {isMobile
            ? <>
              <Button variant="danger" className='mb-2' block onClick={handleDestroyAccount}>
                {t('login.delete_account')}
              </Button>
              <Button variant="light" block onClick={() => openDeleteModal(false)}>
                {t('self_administration.cancel')}
              </Button>
            </>
            : <Row className='mt-5'>
              <Col>
                <Button variant="danger" className='mb-2' block onClick={handleDestroyAccount}>
                  {t('login.delete_account')}
                </Button>
              </Col>
              <Col>
                <Button variant="light" block onClick={() => openDeleteModal(false)}>
                  {t('self_administration.cancel')}
                </Button>
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};




export default Settings;

