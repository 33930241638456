import React from 'react';
import styled, { css } from 'styled-components';

export const KitContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

export const KitBackground = styled.div`
  @media (min-width: 768px) {  
    background: #E9F1FB;
    flex: 1 1 auto;
    background-image: url(/images/sti-programs/clouds.svg);
    background-size: cover;
    text-align: -webkit-center;
  }
`;
export const KitCard = styled.div`
  @media (min-width: 768px) {
    padding: 32px 48px;
    background: #FFFFFF;
    border-radius: 24px;
    margin: 48px 0 160px 0;
    text-align: start;
    max-width: 770px;
  }

  padding: 0px 24px;
`;

export const SectionWrapper = styled.div`
  @media (min-width: 768px) {  
    min-height: 564px;
  }
`;

export const SectionBody = styled.div`
  min-height: 391px;
  @media (max-width: 769px) {  
    min-height: 50vh;
  }
`;

const Actions = styled.div`  
  @media (min-width: 768px) {   
    float: right;
    display: flex;
  }
  @media (max-width: 769px) {  
    button {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;

export const SectionActions = ({children}) => {
  return (
    <>
      <div className='mt-5'></div>
      <Actions>{children}</Actions>
      <div style={{clear: "both"}}></div>
    </>
  )
}

export const Section = styled.div`
  padding: 24px 0;
`;

export const SectionItem = styled.div`
  margin: 0 auto 24px auto;
  &: last-child {
    margin-bottom: 0;
  }
`;

export const ReactMarkdownCustomClass = ({ children, customClass }) => (
  <p className={`${customClass}`}>
    {React.Children.map(children, (child) => {
      if (child.type === 'strong') {
        return (
          <span className={`${customClass} bold`}>{child.props.children}</span>
        );
      }
      return child;
    })}
  </p>
);
