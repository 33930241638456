import React from 'react';

const TestTube = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M10.7725 1.77295L14.3133 5.31382L4.88279 14.7444C3.905 15.7221 2.3197 15.7221 1.34192 14.7444V14.7444C0.364134 13.7666 0.364135 12.1813 1.34192 11.2035L10.7725 1.77295Z"
      stroke={color}
      strokeLinecap="round"
    />
    <line
      x1="9.88789"
      y1="0.631348"
      x2="15.5182"
      y2="6.26164"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M10.5719 9.19276L3.60077 9.19276L0.802509 11.8345L1.50193 14.9812L4.29925 15.3309L10.5719 9.19276Z"
      fill={color}
      stroke={color}
      strokeWidth="0.564939"
    />
  </svg>
);

export default TestTube;
