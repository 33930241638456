import React, { useState } from 'react';
import {
  Modal,
  Button,
} from 'react-bootstrap';
import axios from 'axios';
import CheckboxCard from '../../../common/components/CheckBoxCard';
import TooltipWrapper from '../../../common/components/TooltipWrapper';


const AdditionalConsentsModal = ({ requestableConsentForms, testGroup, user }) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedConsentFormIds, setSelectedConsentFormIds] = useState([]);

  const bulkRequestConsent = async () => {
    return axios.post(`/test_groups/${testGroup.id}/participants/${user.id}/requested_consents/bulk_create`, {
      requested_consent_ids: selectedConsentFormIds,
    })
  }

  if (requestableConsentForms.length < 1) {
    return (
      <TooltipWrapper
        tooltipContent="No additional consents available to request"
        placement="top"
      >
        <span>
          <Button
            variant="link"
            className="my-2"
            disabled
          >
            Request additional consents
          </Button>
        </span>
      </TooltipWrapper>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant="link"
        onClick={() => setShowModal(true)}
        className="my-2"
      >
        Request additional consents
      </Button>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <div className="p-4">
          <h4 className="my-3">Request additional consents</h4>
          <div className="my-3">
            {requestableConsentForms.map((cf) => {
              return (
                <CheckboxCard
                  name={cf.label}
                  onChange={() => {
                    if (selectedConsentFormIds.includes(cf.id)) {
                      const updatedSelectedConsetForms = selectedConsentFormIds.filter(id => id !== cf.id)
                      setSelectedConsentFormIds(updatedSelectedConsetForms)
                    } else {
                      setSelectedConsentFormIds([...selectedConsentFormIds, cf.id])
                    }
                  }}
                  checked={selectedConsentFormIds.includes(cf.id)}
                  label={cf.label}
                />
              )
            })}
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-primary"
              className="mx-2"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
            <Button
              className="mx-2"
              onClick={async () => {
                const response = await bulkRequestConsent();
                if (response.status === 200) {
                  toastr.success("Additional consents requested");
                  setShowModal(false);
                } else {
                  toastr.error("Something went wrong");
                }
              }}
            >
              Request
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AdditionalConsentsModal;