import React from 'react';
import { useTranslation } from 'react-i18next';

export function TestKitOverview() {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="my-2">{t('viral_surveillance.overview.title')}</h4>
      <div className="my-2">{t('viral_surveillance.overview.subtitle')}</div>
    </div>
  );
}
