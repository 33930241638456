import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

const GroupHeader = ({ children }) => (
  <div
    className="d-flex flex-row align-items-center mb-2"
    style={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'default',
      fontWeight: 500,
      paddingLeft: 12,
      paddingRight: 12,
      gap: 6,
    }}
  >
    <span className="flex-shrink-0" style={{ color: '#999' }}>
      {children}
    </span>
    <div
      className="w-100 flex-grow-1"
      style={{ backgroundColor: '#9996', height: 1 }}
    />
  </div>
);

const GroupContainer = styled.div`
  & [class$='-option']::before {
    padding-left: 12px;
    content: '';
  }
`;

export const Group = (props) => (
  <GroupContainer>
    <components.Group {...props} Heading={GroupHeader} />
  </GroupContainer>
);
