import React from 'react';

const NoseSwab = ({ color = '#193154', ...props }) => (
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_778_7180)">
      <path
        d="M11.8875 0.900771C11.6312 0.413271 11.0312 0.219521 10.5375 0.475771C10.0438 0.732021 9.85625 1.33202 10.1125 1.82577C14.7375 10.7258 20.8563 17.3633 24.0938 20.532C25.2875 21.7008 26 23.2633 26 24.882C26 27.9133 23.5437 30.3633 20.5187 30.3633H18.3937L18.25 30.2008L15.8313 27.482C14.6375 26.1383 12.925 25.3695 11.125 25.3695C9.4 25.3695 8.00625 26.7633 8.00625 28.4883V28.8633C8.00625 30.7945 9.575 32.3633 11.5063 32.3633H20.5187C24.65 32.3633 28 29.0133 28 24.882C28 22.6758 27.0312 20.6133 25.4937 19.107C22.3375 16.0133 16.3813 9.54452 11.8875 0.907021V0.900771ZM14.3313 28.807L15.7188 30.3633H11.5C10.6687 30.3633 10 29.6945 10 28.8633V28.482C10 27.8633 10.5 27.3633 11.1187 27.3633C12.35 27.3633 13.5188 27.8883 14.3313 28.807ZM8.20625 19.3445C8.74375 19.2258 9.0875 18.6945 8.975 18.157C8.8625 17.6195 8.325 17.2758 7.7875 17.3883L6.3625 17.6945C2.65 18.4883 0 21.7633 0 25.5633C0 27.9508 1.0625 30.2195 2.89375 31.7445L3.35625 32.132C3.78125 32.4883 4.4125 32.4258 4.7625 32.007C5.1125 31.5883 5.05625 30.9508 4.6375 30.6008L4.175 30.2133C2.8 29.057 2 27.357 2 25.5633C2 22.7133 3.99375 20.2445 6.78125 19.6508L8.20625 19.3445Z"
        style={{
          fill: color,
        }}
      />
    </g>
    <rect
      x="12.2495"
      y="14.6132"
      width="0.5"
      height="24.5"
      rx="0.25"
      fill="#D9D9D9"
      stroke={color}
      strokeWidth="0.5"
    />
    <rect x="10.9995" y="10.3632" width="3" height="8" rx="1.5" fill={color} />
    <defs>
      <clipPath id="clip0_778_7180">
        <rect
          width="28"
          height="32"
          fill="white"
          transform="translate(0 0.363281)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NoseSwab;
