import React from "react";
import axios from "axios";
import { Button, Col, Modal, Row } from "react-bootstrap";

import CheckboxCard from "../../common/components/CheckBoxCard";
import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import ModalHeader from "../../common/components/ModalHeader";

const TestConfigurationActionsModal = ({
  allowedTestGroups,
  filteredTestGroupIds,
  modalType,
  onHide,
  searchInput,
  selectedActiveCount,
  selectedInactiveCount,
  selectedTestConfigurationIds,
  selectedTestGroupIds,
  setFilteredTestGroupIds,
  setSearchInput,
  setSelectedTestGroupIds,
  show,
  testConfigurations,
}) => {

  const selectableTestGroups = modalType != "deactivate";

  const handleActivateDeactivate = async (activate) => {
    const testConfigurationIds = testConfigurations
      .filter((tc) => selectedTestConfigurationIds.includes(tc.id))
      .filter((tc) => tc.active != activate)
      .map(tc => tc.id)
    const params = {
      active: activate,
      test_configuration_ids: testConfigurationIds,
      test_group_ids: selectedTestGroupIds,
    }
    try {
      const response = await axios.put("/bulk_activate_deactivate_test_configurations", params);
      toastr.success("Success");
      window.location.reload();
    } catch (e) {
      toastr.error("Something went wrong");
    }
  };

  const handleAddIntoTestGroups = async () => {
    const params = {
      test_configuration_ids: selectedTestConfigurationIds,
      test_group_ids: selectedTestGroupIds,
    }
    try {
      const response = await axios.post("/bulk_add_test_configurations_into_test_groups", params);
      toastr.success("Success");
      window.location.reload();
    } catch (e) {
      toastr.error("Something went wrong");
    }
  };

  const handleSearchBarChange = (searchInput) => {
    setSearchInput(searchInput);
    const processedSearchInput = searchInput.trim().toLowerCase();
    const filteredIds = allowedTestGroups
      .filter((tg) => tg.name?.trim().toLowerCase().indexOf(processedSearchInput) > -1)
      .map((tg) => tg.id);
    setFilteredTestGroupIds(filteredIds);
  };

  const affectedTestGroups = (activeToInactive) => {
    return testConfigurations.filter((tc) => selectedTestConfigurationIds.includes(tc.id))
      .filter((tc) => tc.active == activeToInactive)
      .map((tc) => tc.test_groups)
      .flat()
      .filter((tg) => !filteredTestGroupIds.length || filteredTestGroupIds.includes(tg.id))
  };

  const SelectAllTestGroupsButton = ({ testGroups }) => {
    const allSelected = !!selectedTestGroupIds.length && selectedTestGroupIds.length == testGroups.length;
    return (
      <Button
        onClick={() => {
          setSearchInput("");
          setFilteredTestGroupIds([]);
          allSelected
            ? setSelectedTestGroupIds([])
            : setSelectedTestGroupIds(testGroups.map(tg => tg.id))
        }}
        variant="outline-primary"
      >
        Select all
      </Button>
    );
  };

  const TestGroupList = ({ testGroups }) => {
    testGroups.sort((a, b) => {
      const indexA = selectedTestGroupIds.indexOf(a.id);
      const indexB = selectedTestGroupIds.indexOf(b.id);
      if (indexA !== -1 && indexB !== -1) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      }
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      return 0;
    })
    return (
      <div
        className="my-2"
        style={{
          maxHeight: "300px",
          overflowY: "scroll",
          border: "1px solid #e5e5e5",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {selectableTestGroups ? (
          testGroups.map((tg) => {
            return (
              <CheckboxCard
                name={tg.name}
                onChange={() => {
                  if (selectedTestGroupIds.includes(tg.id)) {
                    const updatedSelectedTestGroupIds = selectedTestGroupIds.filter(id => id !== tg.id)
                    setSelectedTestGroupIds(updatedSelectedTestGroupIds)
                  } else {
                    setSelectedTestGroupIds([...selectedTestGroupIds, tg.id])
                  }
                }}
                checked={selectedTestGroupIds.includes(tg.id)}
                label={tg.name}
              />
            )
          })
        ) : (
          testGroups.map((tg) => {
            return (<div>• {tg.name}</div>)
          })
        )}
      </div>
    );
  };

  const modalMapping = {
    activate: {
      action: async () => handleActivateDeactivate(true),
      actionDisabled: false,
      actionText: "Activate",
      subTitle: "Select all test groups you'd like to activate these services",
      testGroups: affectedTestGroups(false),
      title: `Activate ${selectedInactiveCount} service configurations`,
    },
    deactivate: {
      action: async () => handleActivateDeactivate(false),
      actionDisabled: false,
      actionText: "Deactivate",
      subTitle: `${affectedTestGroups(true).length} test group test configuration will be deactivated in the following test groups:`,
      testGroups: affectedTestGroups(true),
      title: `Deactivate ${selectedActiveCount} service configurations`,
    },
    addIntoTestGroups: {
      action: async () => handleAddIntoTestGroups(),
      actionDisabled: !selectedTestGroupIds.length,
      actionText: "Add into groups",
      subTitle: "Select all test groups you'd like to add these services",
      testGroups: !!filteredTestGroupIds.length
        ? allowedTestGroups.filter((tg) => filteredTestGroupIds.includes(tg.id))
        : allowedTestGroups,
      title: `Add ${selectedTestConfigurationIds.length} services to ${selectedTestGroupIds.length} groups`,
    },
  };

  if (!modalType) return <span/>

  return (
    <div>
      <Modal
        onHide={onHide}
        show={show}
        size="lg"
      >
        <div>
          <ModalHeader closeButton onHide={onHide}>
            <Modal.Title as="h2">
              {modalMapping[modalType].title}
            </Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <div className="my-2">
              {modalMapping[modalType].subTitle}
            </div>
            {selectableTestGroups && (
              <Row className="mt-3 mb-2">
                <Col>
                  <FloatingLabelInput
                    className="mb-3"
                    label="Search"
                    onChange={(e) => handleSearchBarChange(e.target.value)}
                    value={searchInput}
                    prependIcon="search"
                  />
                </Col>
                <Col xs="auto">
                  <SelectAllTestGroupsButton testGroups={allowedTestGroups} />
                </Col>
              </Row>
            )}
            <TestGroupList testGroups={modalMapping[modalType].testGroups} />
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between">
              <Button
                onClick={onHide}
                variant="outline-primary"
              >
                Cancel
              </Button>
              <Button
                className="ms-2"
                disabled={modalMapping[modalType].actionDisabled}
                onClick={modalMapping[modalType].action}
              >
                {modalMapping[modalType].actionText}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default TestConfigurationActionsModal;