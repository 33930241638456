import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Header from '../Registration/components/Header';
import PersonalInformation, {
  validatePersonalInformation,
} from '../Registration/components/registration/PersonalInformation';
import RegistrationContext from '../Registration/contexts/RegistrationContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SurveyPreview from '../common/components/SurveyPreview';
import { useRef } from 'react';
import ErrorMessage from '../common/components/ErrorMessage';

const onSubmit = (values, bag) => {
  const url = `/test_groups/${values.test_group_slug}/registrations/${values.appointment.access_code}/update_user_information.json`;

  const params = {
    appointment: values.appointment,
    user: values,
  };

  axios
    .post(url, params)
    .then((response) => {
      window.location.href = response.data.redirect_url;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const FinishRegistrationBase = ({
  onSubmit,
  ...props
}) => {
  const [localFormState, setLocalFormState] = useState({});
  const { t, i18n } = useTranslation();
  const surveyRef = useRef();
  const hasSurvey = Object.keys(props.test_group.custom_survey).length > 0;
  const context = {
    testGroup: props.test_group,
    localFormState,
    setLocalFormState,
  };

  const validate = (values) => {
    let errors = {};
    if (hasSurvey && surveyRef.current.hasErrors()) {
      errors.appointment = 'registration.errors.survey_unanswered';
    }
    const validateForPersonalInformation = validatePersonalInformation.bind(
      null,
      context.testGroup,
    );
    const personalInformationErrors = validateForPersonalInformation()(values);
    errors = { ...errors, ...personalInformationErrors };
    return errors;
  };

  return (
    <>
      <RegistrationContext.Provider value={context}>
        <Formik
          initialValues={{
            ethnicity_blob: props.user.ethnicity_blob,
            races: props.user.races,
            language_preference: props.user.language_preference,
            gender_string: props.user.gender_string,
            sex_at_birth: props.user.sex_at_birth,
            self_described_gender: props.user.self_described_gender,
            sexual_orientation: props.user.sexual_orientation,
            pronoun: props.user.pronoun,
            arab_ethnicity: props.user.arab_ethnicity,
            appointment: props.user.appointment,
            test_group_slug: props.test_group.slug,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={validate}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            return (
              <form onSubmit={formikProps.handleSubmit}>
                <PersonalInformation columnWidth={8} {...formikProps} />
                {hasSurvey && (
                  <div>
                    <h5 className="font-weight-bold px-3">
                      {t('registration.custom_survey')}
                    </h5>
                    <SurveyPreview
                      modelRef={surveyRef}
                      json={props.test_group.custom_survey || {}}
                      data={formikProps.values.appointment.questionnaire || {}}
                      onValueChanged={(e) => {
                        formikProps.setFieldValue(
                          'appointment.questionnaire',
                          e.data,
                        );
                      }}
                    />
                    {formikProps.touched && formikProps.errors.appointment ? (
                      <ErrorMessage
                        id="appointment.questionnaire"
                        message={t(formikProps.errors.appointment)}
                      />
                    ) : null}
                  </div>
                )}
                <div className="text-end pt-3">
                  <Button data-test-hook="submit" type="submit">
                    {t('finish_registration.button')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </RegistrationContext.Provider>
    </>
  );
};

const FinishRegistration = (props) => {
  return (
    <>
      <Header testGroup={props.test_group} />
      <div
        style={{
          maxWidth: '770px',
          margin: 'auto',
        }}
        className="mt-lg-4 d-flex flex-row justify-content-between p-3"
        role="main"
      >
        <FinishRegistrationBase onSubmit={onSubmit} {...props} />
      </div>
    </>
  );
};

export default FinishRegistration;
