import React from 'react';
import { vaccineName, manufacturerName } from '../../common/utils/vaccine';
import { Alert } from 'react-bootstrap';

const ImmunizationTable = ({
  histories,
  message = 'No vaccine history available',
  showAlert,
}) => {
  return (
    <>
      <section className="fabrx-tables-light table-responsive">
        <table className="table table-lg">
          <thead>
            <tr>
              <th>Vaccine group</th>
              <th>Vaccine</th>
              <th>Manufacturer</th>
              <th>Date</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {histories.map(history => {
              const { id, vaccine, manufacturer, vaccinated_on, source, group } = history;
              return (
                <tr key={id}>
                  <td>{group}</td>
                  <td title={vaccine}>{vaccineName(vaccine)}</td>
                  <td title={manufacturer}>{manufacturerName(manufacturer)}</td>
                  <td>{vaccinated_on}</td>
                  <td>{source?.toUpperCase()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      {showAlert && (
        <Alert variant="info" style={{ display: 'inline-block' }}>
          {message}
        </Alert>
      )}
    </>
  );
};

export default ImmunizationTable;
