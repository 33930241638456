import React, { FC } from 'react';
import styled from 'styled-components';

import FilterChip from './FilterChip';
import { Button } from 'react-bootstrap';
import { DisplayFilter } from './hooks/useFilters';

interface FilterAreaProps {
  children: React.ReactNode;
  displayFilters: DisplayFilter[];
  clearAll?: () => void;
}

const FiltersContainer = styled.div`
  padding: 16px 16px 12px 16px;
  border-radius: 16px;
  background: #fafafb;
`;

const VisibleFilters = ({ children }) => (
  <FiltersContainer>{children}</FiltersContainer>
);

interface FilterChipsContainerProps {
  displayFilters: DisplayFilter[];
  clearAll?: () => void;
}

const FilterChipsContainer: FC<FilterChipsContainerProps> = ({
  displayFilters,
  clearAll,
}) => (
  <div className="d-flex flex-row flex-wrap" style={{ gap: '8px 16px' }}>
    {displayFilters.map((filter, idx) => (
      <FilterChip key={idx} label={filter.label} onRemove={filter.onRemove} />
    ))}
    {displayFilters.length > 0 && clearAll && (
      <Button
        className="ms-auto"
        variant="link"
        style={{ height: 32 }}
        onClick={clearAll}
      >
        Clear filters
      </Button>
    )}
  </div>
);

const FilterArea: FC<FilterAreaProps> = ({
  children,
  displayFilters,
  clearAll,
}) => (
  <div className="d-flex flex-column" style={{ gap: 16 }}>
    <VisibleFilters children={children} />
    <FilterChipsContainer displayFilters={displayFilters} clearAll={clearAll} />
  </div>
);

interface ExpandedFilterProps {
  label: string;
  children: React.ReactNode;
  displayFilters?: DisplayFilter[];
}

export const ExpandedFilter: FC<ExpandedFilterProps> = ({
  label,
  displayFilters = [],
  children,
}) => {
  const filteredDisplayFilters = displayFilters.filter(
    (filter) => !!filter.value,
  );

  return (
    <div className="d-flex flex-column mb-4">
      <span className="form-label">
        {label}
        {filteredDisplayFilters.length > 0 &&
          ` (${filteredDisplayFilters.length})`}
      </span>
      {children}
      {filteredDisplayFilters && filteredDisplayFilters.length > 0 && (
        <div className="mt-3">
          <FilterChipsContainer displayFilters={filteredDisplayFilters} />
        </div>
      )}
    </div>
  );
};

export default FilterArea;
