import React, { useContext, useRef } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";

import { languageKey } from "./NewMessage";
import MessageFilter from "./MessageFilter";
import NewMessageContext from "../contexts/NewMessageContext";
import TextField from "../../common/components/Forms/components/TextField";

const VARIABLES = [
  {
    label: "Group name",
    value: "%{name}",
  },
  {
    label: "Participant name",
    value: "%{full_name}",
  },
  {
    label: "Registration",
    value: "%{reg_link}",
  },
  {
    label: "Record test",
    value: "%{report_test_link}",
  },
  {
    label: "Access key registration",
    value: "%{reg_link(key=value)}",
  },
  {
    label: "Location registration",
    value: "%{reg_link(location=id)}",
  },
];

export const MessagePreview = ({
  emailMessage,
  emailSubject,
  sectionTitle = null,
  senderOrganizationName,
  smsMessage,
}) => {

  const {
    dispatch,
    messageOptions,
    state,
  } = useContext(NewMessageContext);

  return (
    <div>
      <div>
        {!!sectionTitle && (
          <>
            <h3 className="mb-5">{sectionTitle}</h3>
            <PreviewLanguageSelector
              dispatch={dispatch}
              languageOptions={messageOptions.language_options}
              messageLanguages={state.message_settings.languages}
              selectedLanguage={state.selected_language}
            />
          </>
        )}
        <div className="body-medium-bold inter semibold">Email preview</div>
        <PreviewField
          helperText={"From:"}
          text={senderOrganizationName}
        />
        <PreviewField
          helperText={"Subject:"}
          text={emailSubject}
        />
        <PreviewField
          minHeight="100px"
          text={emailMessage}
        />
      </div>
      <div className="mt-5">
        <div className="body-medium-bold inter semibold">Text message preview</div>
        <PreviewField
          helperText={"From:"}
          text={senderOrganizationName}
        />
        <PreviewField
          minHeight="50px"
          text={smsMessage}
        />
      </div>
    </div>
  );
};

export const PreviewLanguageSelector = ({
  dispatch,
  languageOptions,
  messageLanguages,
  selectedLanguage,
}) => {
  const _messageLanguages = [...messageLanguages];
  if (!!_messageLanguages.length && !_messageLanguages.includes("en")) _messageLanguages.unshift("en");
  if (!_messageLanguages.length) return <span></span>;
  return (
    <div className="d-flex my-5">
      {_messageLanguages.map((languageKey) => {
        const languageLabel = languageOptions?.find(o => o.value == languageKey)?.label;
        return (
          <Button
            className="me-2"
            onClick={() => dispatch({
              type: "setSelectedLanguage",
              values: languageKey == "en" ? null : languageKey
            })}
            size="sm"
            variant={
              (selectedLanguage == languageKey ||
                (!selectedLanguage && languageKey == "en")
              ) ? "primary"
                : "outline-primary"
            }
          >
            {languageLabel}
          </Button>
        )
      })}
    </div>
  );
};

const PersonalizeMessage = ({
  field,
  insertVariable,
  type,
}) => {
  return (
    <div className="my-3">
      <div className="body-medium-normal">
        Personalize your {field} with dynamic fields
      </div>
      <div className="my-2 d-flex flex-row flex-wrap" style={{ gap: "8px 16px" }}>
        {VARIABLES.map((v) => {
          return (
            <span
              className="p-2 pointer body-small-regular"
              onClick={() => insertVariable(v.value, field, type)}
              style={{ border: "1px solid #DEE2E6", borderRadius: "30px" }}
            >
              {v.label}
            </span>
          )
        })}
      </div>
    </div>
  );
};

const PreviewField = ({
  helperText,
  minHeight,
  text,
}) => {
  return (
    <div
      className="my-2 p-2"
      style={{
        backgroundColor: "#FFFFFF",
        border: "0px",
        borderRadius: "8px",
        minHeight: minHeight,
        whiteSpace: "pre-wrap",
      }}
    >
      {helperText && (
        <span className="body-medium-bold">{helperText}{" "}</span>
      )}
      {text}
    </div>
  );
};

const NewMessageCustomizeMessage = ({}) => {

  const {
    dispatch,
    displayingMessageTemplateForm,
    messageOptions,
    state,
  } = useContext(NewMessageContext);

  const emailMessageRef = useRef(null);
  const smsMessageRef = useRef(null);
  const eventRef = useRef(new Event("content_change"));
  const sendingOrganizationOptions = state.filter_options.organization_filter_options.filter((o) => {
    return state.filter_params.organizations.includes(o['value'])
  });

  const insertVariable = (variable, field, type) => {
    const textAreaRef = field === "email" ? emailMessageRef : smsMessageRef;
    const selectionStart = textAreaRef.current?.selectionStart;
    const selectionEnd = textAreaRef.current?.selectionEnd;
    const text = textAreaRef.current?.value;
    const newText = `${text.substring(
      0,
      selectionStart,
    )}${variable}${text.substring(selectionEnd, text.length)}`;

    textAreaRef.current.value = newText;
    textAreaRef.current.focus();
    textAreaRef.current.selectionStart = selectionStart + variable.length;
    textAreaRef.current.selectionEnd = textAreaRef.current.selectionStart
    textAreaRef.current?.dispatchEvent(eventRef?.current);
    dispatch(({ type: type, values: newText }))
  };

  return (
    <Row>
      <Col
        className="px-5 pt-5"
        style={{ backgroundColor: "#F7F9FC" }}
        xs={6} xl={7}
      >
        <MessagePreview
          emailMessage={state.message_settings[languageKey("email_message", state.selected_language)]}
          emailSubject={state.message_settings[languageKey("email_subject", state.selected_language)]}
          sectionTitle={displayingMessageTemplateForm ? "Template preview" : "Message preview"}
          senderOrganizationName={state.sender_organization_name}
          smsMessage={state.message_settings[languageKey("sms_message", state.selected_language)]}
        />
      </Col>
      <Col
        className="px-5 pt-5"
        xs={6} xl={5}
      >

        {displayingMessageTemplateForm ? (
          <div className="mb-3">
            <h3 className="mb-5">Template details</h3>
            <TextField
              label="Template name"
              placeholder="Enter the name of template"
              value={state.template_name}
              setValue={(e) => {
                dispatch({
                  type: "setTemplateName",
                  values: e.target.value,
                })
              }}
              autoComplete="off"
              required
            />
            <MessageFilter
              dispatch={dispatch}
              filterName="Template organization"
              options={state.filter_options.organization_filter_options}
              placeHolder="Select template organization"
              selectedOptions={state.template_organization_id}
              type="setTemplateOrganization"
            />
          </div>
        ) : (
          <div className="mb-3">
            <h3 className="mb-5">Customize your message</h3>
            <MessageFilter
              dispatch={dispatch}
              filterName="Sending organization*"
              isDisabled={sendingOrganizationOptions.length == 1}
              options={sendingOrganizationOptions}
              placeHolder="Select the sending organization"
              selectedOptions={state.message_settings.organization_id}
              type="setOrganizationId"
            />
          </div>
        )}

        <MessageFilter
          dispatch={dispatch}
          filterName="Languages"
          isMulti
          options={messageOptions.language_options}
          placeHolder="Select language(s) to use for this message"
          selectedOptions={state.message_settings.languages}
          type="setLanguages"
        />

        <div className="my-5">
          <div className="body-medium-bold inter semibold text-muted mb-3">
            EMAIL CONTENT
          </div>
          <TextField
            label="Email subject"
            placeholder="Enter your subject"
            value={state.message_settings[languageKey("email_subject", state.selected_language)]}
            setValue={(e) => {
              dispatch({
                type: "setEmailSubject",
                values: e.target.value,
              })
            }}
            autoComplete="off"
            required
          />
          <h6 className="body-medium-bold inter semibold">
            Email message*
          </h6>
          <FormControl
            as="textarea"
            onChange={(e) => {
              dispatch({
                type: "setEmailMessage",
                values: e.target.value,
              })
            }}
            placeholder="Enter your email content here!"
            ref={emailMessageRef}
            rows={6}
            value={state.message_settings[languageKey("email_message", state.selected_language)]}
          />
          <PersonalizeMessage
            field="email"
            insertVariable={insertVariable}
            type="setEmailMessage"
          />
        </div>

        <div className="my-5">
          <div className="body-medium-bold inter semibold text-muted mb-3">
            TEXT MESSAGE CONTENT
          </div>
          <h6 className="body-medium-bold inter semibold">
            Text message
          </h6>
          <FormControl
            as="textarea"
            onChange={(e) => {
              dispatch({
                type: "setSmsMessage",
                values: e.target.value,
              })
            }}
            placeholder="Enter your sms content here!"
            ref={smsMessageRef}
            rows={4}
            value={state.message_settings[languageKey("sms_message", state.selected_language)]}
          />
          <PersonalizeMessage
            field="text message"
            insertVariable={insertVariable}
            type="setSmsMessage"
          />
        </div>
      </Col>
    </Row>
  );
};

export default NewMessageCustomizeMessage;