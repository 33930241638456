// FIXME: This needs to be dynamic and come from the DB somehow
const COVID_CVX_CODES = [
  207, // Moderna
  208, // Pfizer
  212, // Janssen
];

const MANUFACTURERS = {
  'MOD': 'Moderna',
  'PFR': 'Pfizer',
  'JSN': 'Janssen'
};

const vaccineCode = vaccine => vaccine.split('^')[0];
const vaccineName = vaccine => {
  return vaccine && vaccine.split('^')[1] || vaccineCode(vaccine);
}

const manufacturerCode = manufacturer => manufacturer && manufacturer.split('^')[0];
const manufacturerName = manufacturer => {
  return manufacturer && (manufacturer.split('^')[1] || manufacturerNameFromCode(manufacturerCode(manufacturer)));
}
const manufacturerNameFromCode = code => {
  return MANUFACTURERS[code] || code;
}

const isCovidVaccine = (vaccine) => {
  const code = vaccineCode(vaccine);
  return COVID_CVX_CODES.find(covidCode => covidCode == parseInt(code));
};

const inTimeZone = (date, timeZone) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: timeZone}));
};

const doseValidity = valid_dose => {
  return valid_dose ? '' : 'INVALID';
};

export {
  isCovidVaccine,
  vaccineCode,
  vaccineName,
  manufacturerCode,
  manufacturerName,
  inTimeZone,
  doseValidity
};
