import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ModalHeader from '../../../common/components/ModalHeader';

const AbbottInvalidReasonModal = ({
  test,
  updateDidNotResultReason,
  didNotResultOptions,
}) => (
  <Modal
    show={!test.abbott_test_result?.did_not_result_reason}
    backdrop="static"
    keyboard={false}
    size="md"
  >
    <ModalHeader>
      <Modal.Title as="h2">
        Which did your result most look like?
      </Modal.Title>
    </ModalHeader>
    <Modal.Body>
      {didNotResultOptions.map(({ value, label, image }, idx) => (
        <div
          key={idx}
          className="w-100 px-3 my-2 form-control d-flex justify-content-between align-items-center pointer"
          style={{ height: 80 }}
          onClick={() => updateDidNotResultReason(value)}
        >
          <div className="form-check-rounded d-flex">
            <input
              className="form-check-input faux-radio me-2 flex-shrink-0"
              name={`test_${test.id}_result`}
              id={`test_${test.id}_confirmation_${value}`}
              checked={test.result === value}
              onChange={() => updateDidNotResultReason(value)}
              type="radio"
            />
            <label
              className="form-check-label"
              htmlFor={`test_${test.id}_confirmation_${value}`}
              style={{ textTransform: 'capitalize', marginBottom: 0 }}
            >
              {label}
            </label>
          </div>
          {image && <img src={`/images/abbott/${image}.png`} height="65" />}
        </div>
      ))}
    </Modal.Body>
  </Modal>
);

const AbbottResultOptions = ({ test, updateTestAttr, edit }) => {
  const updateDidNotResultReason = (reason) => {
    updateTestAttr('abbott_test_result')({
      ...test.abbott_test_result,
      did_not_result_reason: reason,
    });
  };

  const updateResult = (result) => {
    const _updateResult = updateTestAttr('result');
    if (test.result === result) _updateResult(null);
    else _updateResult(result);
  };

  const didNotResultOptions = [
    { label: 'No lines seen', value: 'no_lines', image: 'did_not_result' },
    {
      label: 'Blue control line only',
      value: 'blue_control_line_only',
      image: 'blue_control_line_only',
    },
    {
      label: 'Pink sample line only',
      value: 'pink_sample_line_only',
      image: 'pink_sample_line_only',
    },
    {
      label: 'Blue control line AND pink/purple sample line',
      value: 'blue_control_line_and_pink_sample_line',
      image: 'blue_control_line_and_pink_sample_line',
    },
    { label: 'Still not sure', value: 'still_not_sure' },
  ];

  const didNotResultValue = didNotResultOptions.find(
    (o) => o.value === test.abbott_test_result?.did_not_result_reason,
  );

  const resultOptions = [
    { value: 'positive', label: 'Positive', image: 'positive' },
    { value: 'negative', label: 'Negative', image: 'negative' },
    {
      value: 'did_not_result',
      label: `Invalid ${
        test?.abbott_test_result?.did_not_result_reason
          ? `(${didNotResultValue.label})`
          : ''
      }`,
      image: test?.abbott_test_result?.did_not_result_reason
        ? didNotResultValue.image
        : 'did_not_result',
    },
  ];

  useEffect(() => {
    if (
      test?.abbott_test_result?.did_not_result_reason &&
      test.result !== 'did_not_result'
    )
      updateDidNotResultReason(null);
  }, [test]);

  return edit ? (
    <div>
      {resultOptions.map(({ value, label, image }, idx) => (
        <div
          key={idx}
          className="px-3 my-2 form-control d-flex justify-content-between align-items-center pointer"
          style={{ height: 'unset', maxWidth: 240 }}
          onClick={() => updateResult(value)}
        >
          <div className="form-check-rounded d-flex">
            <input
              className="form-check-input faux-radio flex-shrink-0 me-2"
              name={`test_${test.id}_result`}
              id={`test_${test.id}_confirmation_${value}`}
              checked={test.result === value}
              type="radio"
            />
            <label
              className="form-check-label"
              htmlFor={`test_${test.id}_confirmation_${value}`}
              style={{ textTransform: 'capitalize', marginBottom: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              {label}
            </label>
          </div>
          {image && <img src={`/images/abbott/${image}.png`} height="65" />}
        </div>
      ))}
      {test.result === 'did_not_result' && (
        <AbbottInvalidReasonModal
          test={test}
          updateDidNotResultReason={updateDidNotResultReason}
          didNotResultOptions={didNotResultOptions}
        />
      )}
    </div>
  ) : (
    <span
      className="mt-2 mb-4"
      style={{ textTransform: 'capitalize', opacity: 0.5 }}
    >
      {test.result &&
        resultOptions.find((ro) => ro.value === test.result).label}
    </span>
  );
};

export default AbbottResultOptions;
