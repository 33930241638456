import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Calendar, DateObject } from 'react-multi-date-picker';

import ModalHeader from '../../common/components/ModalHeader';
import { slotComponents } from './SelectAppointmentSlot';

const MoreDatesModal = ({
  appointmentSlotsByDates,
  appointmentSlotDates,
  onHide,
  selectedDay,
  setDateIndex,
  setSelectedDay,
  show,
  t,
}) => {
  const [temporarySelectedDay, setTemporarySelectedDay] = useState(selectedDay);
  return (
    <Modal 
      className="more-dates-modal"
      onHide={onHide}
      show={show}
      size="lg"
    >
      <ModalHeader closeButton onHide={onHide}></ModalHeader>
      <Modal.Body>
        <h3>{t('family_registrations.select_date')}</h3>
        <Row>
          <Col xs={12} lg={6} className="d-flex justify-content-center pt-4">
            <Calendar
              shadow={false}
              value={new DateObject().setFormat('ddd, M/DD').parse(temporarySelectedDay || selectedDay)}
              disableYearPicker
              mapDays={({ date }) => {
                const dayAvailable = appointmentSlotDates.includes(date.format('ddd, M/DD'));
                if (!dayAvailable) return { disabled: true };
              }}
              minDate={new Date()}
              onChange={(objectDate) => {
                setTemporarySelectedDay(objectDate.format('ddd, M/DD'));
              }}
            />
          </Col>
          <Col xs={12} lg={6} className='overflow-y-auto' style={{ maxHeight: 450 }}>
            <div className="inter mb-2 body-medium-bold bold mt-4">
              {t('family_registrations.available_appointments')}
            </div>
            {slotComponents({
              appointmentSlots: appointmentSlotsByDates[temporarySelectedDay]?.appointment_slots,
              t,
            }).map((component, idx) => (
              <React.Fragment key={idx}>
                {component}
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mx-2"
          variant="link"
          onClick={onHide}
        >
          Back
        </Button>
        <Button
          className="mx-2"
          onClick={() => {
            setSelectedDay(temporarySelectedDay);
            setDateIndex(Math.max(appointmentSlotDates.indexOf(temporarySelectedDay) - 2, 0));
            onHide();
          }}
        >
          Select date
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoreDatesModal;