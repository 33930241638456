import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from '../../common/components/ModalHeader';
import Button from 'react-bootstrap/Button';

const ErrorModal = ({ handleClose, errors, submit }) => {
  return (
    <Modal show={true} onHide={handleClose}>
      <ModalHeader closeButton onHide={handleClose}>
        <Modal.Title as="h2">Some records seem incomplete</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {Object.keys(errors).map((errorKey) => {
          return <div>{errors[errorKey]}</div>;
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Close & fix
        </Button>
        <Button variant="danger" onClick={submit}>
          Save anyway
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
