import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import AppointmentDateCarousel from '../../../FamilyRegistration/components/AppointmentDateCarousel';
import { AppointmentTimeButton } from '../../../FamilyRegistration/components/SelectAppointmentSlot';
import { EditTestConfigurations } from '../../../FamilyRegistration/components/EditServiceSpecifications';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import { formatAndLocalizeDateTime } from '../../../common/locales/utils';
import { SectionTitle } from '../SectionComponents';

const ConfirmAppointment = ({
  setValues,
  values,
}) => {
  const [showAllOptions, setShowAllOptions] = useState(false);
  const { appointment, date_of_birth, full_name } = values;
  const allAppointmentSlots = Object.values(appointment.appointment_slots_by_dates)
    .map(asbd => asbd["appointment_slots"]).flat();
  const selectedAppointmentSlot = allAppointmentSlots.find(as => as.id == appointment.appointment_slot_id);
  const selectedAppointmentSlotDate = selectedAppointmentSlot?.localized_starts_at_date;
  const [selectedDay, setSelectedDay] = useState(selectedAppointmentSlotDate ? format(new Date(selectedAppointmentSlotDate), 'EEE, M/dd') : null);
  const appointmentSlots = appointment.appointment_slots_by_dates[selectedDay]?.appointment_slots;
  const availableTestConfigurations = appointment.available_test_configurations;  
  const chosenTestConfigurations = appointment.chosen_test_configurations;
  const { t, i18n } = useTranslation();

  const updateChosenTestConfigurations = (newIds) => {
    const newChosenTestConfigurations = newIds.map(id => {
      return { test_configuration_id: id, quabtity: 1 }
    });
    setValues({
      ...values,
      appointment: {
        ...appointment,
        chosen_test_configurations: newChosenTestConfigurations,
      }
    })
  };

  return (
    <div>
      <SectionTitle className={"mb-4"}>{t('registration.confirm_appointment')}</SectionTitle>
      <div className="my-5">
        <h6 className="lead-20-medium inter bold">{t('result_page.patient')}</h6>
        <span className="me-4">{full_name}</span>
        <span>{t('registration.dob')}: {date_of_birth}</span>
      </div>
      <EditTestConfigurations
        onChange={(values) => updateChosenTestConfigurations(values)}
        selectedTestConfigurations={chosenTestConfigurations.map(ctc => ctc["test_configuration_id"])}
        testConfigurations={availableTestConfigurations}
      />
      <h6 className="lead-20-medium inter bold">{t('registration.select_appointment_time')}</h6>
      <div className="my-3">
        {[...new Set(appointment.appointment_slot_ids)].map(id => {
          const appointmentSlot = allAppointmentSlots.find(as => as.id == id) || allAppointmentSlots[0];
          const localizedStartsAtDate = formatAndLocalizeDateTime(new Date(appointmentSlot.localized_starts_at_date), 'eeee, MMMM dd, yyyy');
          return (
            <FabrxCheckbox
              checked={id == appointment.appointment_slot_id}
              className='my-2'
              label={`${localizedStartsAtDate || appointmentSlot.localized_starts_at_date} @ ${appointmentSlot.localized_starts_at_time}`}
              onChange={() => {
                setValues({
                  ...values,
                  appointment: {
                    ...appointment,
                    appointment_slot_id: id
                  }
                })
              }}
              radio
            />
          )
        })}
      </div>
      {showAllOptions ? (
        <>
          <div className="my-5">
            <AppointmentDateCarousel
              appointmentSlotsByDates={appointment.appointment_slots_by_dates}
              className="flex-nowrap"
              onClick={(day) => setSelectedDay(day)}
              selectedDay={selectedDay}
              t={t}
            />
          </div>
          <div className="my-5">
            {appointmentSlots?.map(slot => {
              return (
                <AppointmentTimeButton
                  onClick={(slot) =>
                    setValues({
                      ...values,
                      appointment: {
                        ...appointment,
                        appointment_slot_id: slot.id
                      }
                    })
                  }
                  selected={appointment.appointment_slot_id == slot.id}
                  slot={slot}
                />
              )
            })}
          </div>
        </>
      ) : (
        <Button
          onClick={() => setShowAllOptions(!showAllOptions)}
          size='sm'
          variant='outline-tertiary'
        >
          {t('registration.different_appointment')}
        </Button>
      )}
    </div>
  );
};

export default ConfirmAppointment;