import React from 'react';

const HideEyeball = ({ color = '#2862FA', ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3966_11119)">
      <path
        d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.42636 4.48702C2.06977 5.65475 0.937767 7.06388 0.0891333 8.64538C-0.0260921 8.86012 -0.029804 9.11745 0.0791797 9.33541L0.75 9C0.0791797 9.33541 0.0797777 9.33661 0.0797777 9.33661L0.0806543 9.33835L0.0832426 9.34348L0.0917535 9.36014C0.0989065 9.37405 0.109018 9.39352 0.122065 9.41818C0.148155 9.46748 0.186018 9.53758 0.235479 9.62551C0.334344 9.80127 0.479888 10.0489 0.670752 10.3444C1.05155 10.934 1.61763 11.7221 2.3591 12.513C3.82836 14.0802 6.07537 15.75 9 15.75L9.01226 15.7499C10.5636 15.7245 12.0722 15.2652 13.3696 14.4302L16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0732 17.4874 18.0732 17.0126 17.7803 16.7197L1.28033 0.21967ZM1.60693 9.00237C2.36201 7.69363 3.33773 6.52603 4.49032 5.55099L6.40203 7.4627C6.32739 7.58176 6.26104 7.70619 6.20358 7.83514C6.03962 8.20314 5.95145 8.60039 5.94434 9.00321C5.93723 9.40602 6.01133 9.80614 6.16222 10.1797C6.3131 10.5532 6.53768 10.8926 6.82255 11.1775C7.10743 11.4623 7.44677 11.6869 7.82032 11.8378C8.19387 11.9887 8.59399 12.0628 8.9968 12.0557C9.39961 12.0486 9.79686 11.9604 10.1649 11.7964C10.2938 11.739 10.4182 11.6726 10.5373 11.598L12.2786 13.3392C11.2828 13.9143 10.153 14.23 8.99388 14.25C6.67134 14.2477 4.79546 12.9186 3.4534 11.487C2.78862 10.778 2.27658 10.066 1.93081 9.53061C1.79694 9.32333 1.68874 9.14367 1.60693 9.00237ZM7.44411 9.02967C7.4468 8.87699 7.47279 8.72591 7.52092 8.58158L9.41842 10.4791C9.2741 10.5272 9.12302 10.5532 8.97034 10.5559C8.76893 10.5595 8.56887 10.5224 8.3821 10.447C8.19532 10.3715 8.02565 10.2592 7.88321 10.1168C7.74078 9.97435 7.62849 9.80468 7.55305 9.61791C7.4776 9.43113 7.44056 9.23107 7.44411 9.02967Z"
        style={{
          fill: color,
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
      />
      <path
        d="M16.3838 9.01506C14.2663 5.52429 11.4492 3.17742 7.6116 3.87915C7.20414 3.95366 6.81343 3.68375 6.73893 3.27629C6.66442 2.86883 6.93433 2.47812 7.34179 2.40362C12.2572 1.5048 15.6295 4.74398 17.8632 8.56796C18.0209 8.79227 18.0494 9.09521 17.9113 9.35357C17.4315 10.2513 16.8592 11.0965 16.2038 11.8754C15.9371 12.1923 15.464 12.2331 15.147 11.9664C14.8301 11.6997 14.7893 11.2266 15.056 10.9096C15.5537 10.3182 15.9979 9.68419 16.3838 9.01506Z"
        style={{
          fill: color,
        }}
      />
    </g>
    <defs>
      <clipPath id="clip0_3966_11119">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HideEyeball;
