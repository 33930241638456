import React, { useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { languageKey } from "./NewMessage";
import ModalHeader from "../../common/components/ModalHeader";
import NewMessage from "./NewMessage";

export const PARAM_KEYS = ["email_message", "email_subject", "sms_message"];

export const languagesFromCustomMessageTemplateParams = (customMessageTemplateParams) => {
  if (!customMessageTemplateParams) return [];
  const languages = [];
  const keyToFind = "email_message_";
  Object.keys(customMessageTemplateParams).forEach(paramKey => {
    if (paramKey.includes(keyToFind)) languages.push(paramKey.slice(keyToFind.length));
  })
  return languages;
};

export const getTranslationParams = (messageSettings) => {
  const translationParams = {};
  messageSettings.languages.forEach((language) => {
    PARAM_KEYS.forEach((paramKey) => {
      const key = languageKey(paramKey, language);
      const translation = messageSettings[key];
      if (Object.keys(messageSettings).includes(key)) {
        translationParams[key] = translation;
      }
    })
  })
  return translationParams;
};

export const DeleteCustomMessageTemplateModal = ({
  backButtonText="Back to template",
  deleteCustomMessageTemplateModalOpen,
  setDeleteCustomMessageTemplateModalOpen,
  templateId,
}) => {

  const deleleTemplate = async () => {
    const response = await axios.delete(`/custom_message_templates/${templateId}`);
    if (response.status === 200) {
      toastr.success("Template deleted");
      window.location.href = `/messages?messages=templates`;
    } else {
      toastr.error("Something went wrong");
    }
  };

  return (
    <Modal
      onHide={() => setDeleteCustomMessageTemplateModalOpen(false)}
      show={deleteCustomMessageTemplateModalOpen}
    >
      <ModalHeader closeButton onHide={() => setDeleteCustomMessageTemplateModalOpen(false)}>
        <Modal.Title as="h2">Please confirm deletion</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <p>Are you sure you want to delete this message template?</p>
        <p>Deleting this message template will permanently remove it from your collection. Please confirm your action below.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => setDeleteCustomMessageTemplateModalOpen(false)}>
          {backButtonText}
        </Button>
        <Button variant="primary" onClick={() => deleleTemplate()}>
          Delete template
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const TemplateFormHeader = ({ state }) => {

  const [deleteCustomMessageTemplateModalOpen, setDeleteCustomMessageTemplateModalOpen] = useState(false);

  const saveTemplate = async () => {
    const translationParams = getTranslationParams(state.message_settings);
    const params = {
      name: state.template_name,
      organization_id: state.template_organization_id,
      params: {
        email_message: state.message_settings.email_message,
        email_subject: state.message_settings.email_subject,
        sms_message: state.message_settings.sms_message,
        ...translationParams,
      }
    };
    const response = !state.template_id
      ? await axios.post("/custom_message_templates", params) // # custom_message_templates#create
      : await axios.put(`/custom_message_templates/${state.template_id}`, params); // # custom_message_templates#edit
    if (response.status === 200) {
      toastr.success("Template saved");
      window.location.href = `/messages?messages=templates`;
    } else {
      toastr.error("Something went wrong");
    }
  };

  return (
    <div
      className="d-flex justify-content-between pt-3 pb-4"
      style={{ borderBottom: "1px solid #DEE2E6" }}
    >
      <Button
        href="/messages?messages=templates"
        variant="link"
      >
        Back to templates
      </Button>
      <div>
        {!!state.template_id && (
          <>
            <Button
              className="me-2"
              onClick={() => setDeleteCustomMessageTemplateModalOpen(true)}
              variant="link"
            >
              <FontAwesomeIcon icon="fa-regular fa-trash-can" />
            </Button>
            <DeleteCustomMessageTemplateModal
              deleteCustomMessageTemplateModalOpen={deleteCustomMessageTemplateModalOpen}
              setDeleteCustomMessageTemplateModalOpen={setDeleteCustomMessageTemplateModalOpen}
              templateId={state.template_id}
            />
          </>
        )}
        <Button onClick={() => saveTemplate()}>
          Save template
        </Button>
      </div>
    </div>
  );
};

const MessageTemplateForm = (props) => {
  return (
    <div>
      <NewMessage
        displayingMessageTemplateForm
        {...props}
      />
    </div>
  );
};

export default MessageTemplateForm;