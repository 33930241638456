import React, { useState, useContext } from 'react';
import '../../common/locales/i18n';
import { useHistory } from 'react-router';
import ActionWrapper from './partials/ActionWrapper';
import MembersPortalContext from "../context";
import MemberSelection from './partials/MemberSelection';
import DeleteMember from './partials/DeleteMember';

const MemberSelectionPage = () => {
  const { onClickProfile, t } = useContext(MembersPortalContext);
  const [edit, setEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingDelete, setPendingDelete] = useState({});
  const history = useHistory();

  const handleCloseDeleteMember = () => {
    setShowDeleteModal(false);
    setPendingDelete({});
  };

  return (
    <ActionWrapper
      backLink="/members/me"
      showSettings
      title={t('login.select_member')}
      callToActionProps={{onClick: () => setEdit(!edit)}}
      callToActionTitle= {edit ? t('registration.done') : <><img src="/images/login/pencil.svg" style={{filter: "brightness(10)"}} className='me-1'/>{t('login.edit_members')}</>}
    >
      <div className='subtitle-text text-muted mb-5'>{t('login.edit_members_subtitle')}</div>
      <MemberSelection
        edit={edit}
        onClickMember={(householdMember) => {
          if (edit) {
            setPendingDelete(householdMember);
            setShowDeleteModal(true);
          } else {
            onClickProfile(householdMember);
            history.go();
          }
        }}
      />
      <DeleteMember
        householdMember={pendingDelete}
        handleClose={handleCloseDeleteMember}
        show={showDeleteModal}
      /> 
    </ActionWrapper>
  );
};

export default MemberSelectionPage;
