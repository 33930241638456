import React, { useCallback, useEffect, useRef } from 'react';

const PopOverWindow = ({
  open,
  onHide,
  title = undefined,
  children,
  position,
  style = {},
}) => {
  const containerRef = useRef(null);
  const positionStyle =
    position === 'left'
      ? { left: 0, right: 'unset' }
      : { right: 0, left: 'unset' };

  const handleClickOutside = useCallback(
    (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target))
        onHide();
    },
    [containerRef],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [open]);

  return open ? (
    <div
      ref={containerRef}
      className="position-absolute popover fade shadow show bs-popover-right"
      style={{ minWidth: 275, top: 48, ...positionStyle, ...style }}
    >
      {title && (
        <div className="popover-header">
          <span
            className="fw-bold text-uppercase text-muted"
            style={{ fontSize: '95%' }}
          >
            {title}
          </span>
          <button
            type="button"
            className="btn-close float-end"
            aria-label="Close"
            onClick={onHide}
          ></button>
        </div>
      )}

      <div className="popover-body">{children}</div>
    </div>
  ) : null;
};

export default PopOverWindow;
