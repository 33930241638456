import React from "react";
import { Col, Row, Image } from "react-bootstrap";

import {
  determineBloodPressureStatus,
  valueFromGenericTestResult
} from "../../CheckoutPage/components/utils"
import LocalizedMarkdown from "../../Registration/components/registration/fields/LocalizedMarkdown";

const BLOOD_PRESSURE_RESULTS = {
  normal: {
    color: "#048154",
    label: "normal",
    offset: "65",
  },
  elevated: {
    color: "#FFAE03",
    label: "elevated",
    offset: "105",
  },
  high_stage_1: {
    color: "#FC834F",
    label: "high stage 1",
    offset: "140",
  },
  high_stage_2: {
    color: "#FB4E03",
    label: "high stage 2",
    offset: "180",
  },
  hypertensive_crisis: {
    color: "#AD052D",
    offset: "220",
    label: "hypertensive crisis",
  },
};

const ResultIndicator = ({ result, label }) => {
  return (
    <div
      className="fw-bold position-absolute"
      style={{ marginLeft: "-5px", top: `${BLOOD_PRESSURE_RESULTS[result]['offset']}px` }}
    >
      &#9667; {label}
    </div>
  );
};

const BloodPressureResult = ({ systolic, diastolic, result, label }) => {
  return (
    <div className="mb-4">
      {label && (
        <div>{label} reading</div>
      )}
      <div className="d-flex my-2">
        <div className="status-line" style={{ backgroundColor: BLOOD_PRESSURE_RESULTS[result]['color'] }}></div>
        <div className="text-center">
          <div className="fs-5 fw-bold">{systolic}</div>
          <div className="text-muted" style={{ fontSize: "12px" }}>systolic</div>
        </div>
        <div className="fs-5 fw-bold text-center">/</div>
        <div className="text-center">
          <div className="fs-5 fw-bold">{diastolic}</div>
          <div className="text-muted" style={{ fontSize: "12px" }}>diastolic</div>
        </div>
      </div>
      <p>Your {label?.toLowerCase()} blood pressure reading was in the <b>{BLOOD_PRESSURE_RESULTS[result]['label']}</b> range.</p>
    </div>
  );
};

const MedicalHistoryHypertensionScreeningCardBody = ({
  test,
}) => {

  const systolic = valueFromGenericTestResult(test, "systolic");
  const diastolic = valueFromGenericTestResult(test, "diastolic");
  const result = determineBloodPressureStatus(systolic, diastolic);

  const systolicRepeated = valueFromGenericTestResult(test, "systolic_repeated");
  const diastolicRepeated = valueFromGenericTestResult(test, "diastolic_repeated");
  const resultRepeated = determineBloodPressureStatus(systolicRepeated, diastolicRepeated);

  const resultRepeatedDiffers = !!resultRepeated && result != resultRepeated;

  if (!result) return <span></span>;

  return (
    <>
      <Row>
        <Col xs={12} xl={9}>
          <BloodPressureResult
            systolic={systolic}
            diastolic={diastolic}
            result={result}
            label={!!resultRepeated ? "First" : null}
          />
          {!!resultRepeated && (
            <BloodPressureResult
              systolic={systolicRepeated}
              diastolic={diastolicRepeated}
              result={resultRepeated}
              label={"Repeat"}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={9}>
          <Image src="https://primarybio-public.s3.us-east-2.amazonaws.com/blood_pressure_ranges.png" />
        </Col>
        <Col className="d-none d-xl-block position-relative">
          <ResultIndicator
            result={result}
            label={resultRepeatedDiffers ? "First reading" : "Your results"}
          />
          {resultRepeatedDiffers && (
            <ResultIndicator
              result={resultRepeated}
              label={"Repeat reading"}
            />
          )}
        </Col>
      </Row>
      <div className="my-4">
        <LocalizedMarkdown
          container={test.test_configuration}
          stringKey={`custom_result_result_text`}
        />
      </div>
    </>
  );
};

export default MedicalHistoryHypertensionScreeningCardBody;