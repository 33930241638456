import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import SurveyPreview from '../../../common/components/SurveyPreview';
import * as SurveyOriginal from "survey-react";
import { Model } from "survey-core";
import RegistrationContext from '../../../Registration/contexts/RegistrationContext';

export const validateSurvey = (survey, testSurveyIndex, testGroup) => (values) => {
  const errors = {};
  if (testGroup.use_new_surveyjs_validation) {
    let model;
    if (testGroup.flipper_flags.upgraded_surveyjs) {
      model = new Model(survey);
    } else {
      model = new SurveyOriginal.Model(survey);
    }
    const data = values.test_surveys[testSurveyIndex].survey
    model.data = data || {};
    if (testGroup.use_new_surveyjs_validation ? model.hasErrors() : model.startedPage?.hasErrors()) errors.survey = 'registration.errors.survey_unanswered';
  } else {
    var requiredQuestions = []

    survey.pages.forEach(page => {
      Object.keys(page.elements).forEach(idx => {
        if (page.elements[idx]['isRequired']){
          requiredQuestions.push(page.elements[idx]['name'])
        }
      })
    });
      
    if (requiredQuestions.length > 0){
      !requiredQuestions.some(function (question) {;
        if(
          !values.test_surveys[testSurveyIndex].survey || 
          values.test_surveys[testSurveyIndex].survey[question] === undefined
        ) errors.survey = 'registration.errors.survey_unanswered';
      }); 
    }
  }

  return errors;
}


const TestSurvey = ({userTestSurvey, values, errors, setFieldValue}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);
  const urlParams = new URLSearchParams(window.location.search);
  
  const testSurveyIndex = values.test_surveys.findIndex(x => {
    return x.test_configuration_id === userTestSurvey.test_configuration_id
  });

  useEffect(() => {
   i18n.changeLanguage(urlParams.get('locale'))
  }, [])

  return (
    <div>
      <SurveyPreview
        json={userTestSurvey.test_configuration_survey}
        data={values.test_surveys[testSurveyIndex].survey}
        onValueChanged={(e)=>{
          setFieldValue(`test_surveys[${testSurveyIndex}].survey`, e.data);
        }}
        errors={errors.survey && testGroup.use_new_surveyjs_validation}
        newSurvey={testGroup.upgraded_surveyjs}
      />
      {errors.survey && <div className="text-danger" role="alert" aria-live="polite">{t(errors.survey)}</div>}
    </div>
  );
};

export default TestSurvey;
