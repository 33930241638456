import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import Icon from '../../common/components/AssetIcon';
import { isMobile } from '../../ImmunizationStatus/components/UploadRecords';
import Select from '../../common/components/Select';
import ServiceSpecificationBadge  from './ServiceSpecificationBadge';
import ServiceSpecificationSelector from './ServiceSpecificationSelector';
import AutocompleteLocation from '../../PrimaryDesignSystem/AutocompleteLocation';

const HACK_BOX_HEIGHT = "300px";

export const RADIUS_OPTIONS = [
  { label: 'family_registrations.within_x_miles', value: 10 },
  { label: 'family_registrations.within_x_miles', value: 20 },
  { label: 'family_registrations.within_x_miles', value: 30 },
  { label: 'family_registrations.any_distance', value: null },
];

export const REGISTRATION_TYPE_OPTIONS = [
  { label: 'family_registrations.individual_appointment', value: 'individual' },
  { label: 'family_registrations.family_appointment', value: 'family' },
];

export const FamilyOrIndividualButton = ({
  active,
  className = '',
  icon,
  label,
  onClick,
}) => {
  return (
    <div
      className={`p-3 pointer regular poppins lead-20-medium square-button text-center ${
        active && 'active'
      } ${className}`}
      onClick={onClick}
      data-test-hook={label}
    >
      {icon && (
        <Icon
          className={`me-2`}
          path={`/images/icons/${icon}${active ? '-active' : ''}.svg`}
          style={{ height: 26 }}
        />
      )}
      {label}
    </div>
  );
};

const IndividualOrFamily = ({ state, dispatch, t }) => {
  const [hackBoxHeight, setHackBoxHeight] = useState("");

  const individualOrFamily = (
    <div className="mb-5">
      <h2 className="my-4 poppins semibold">
        {t('family_registrations.find_clinic')}
      </h2>
      <div className="my-2 h4-20-semi-bold">
        {t('family_registrations.what_kind_appointment')}
      </div>
      <div className="text-muted lead-20-medium inter regular my-2">
        {t('family_registrations.select_family_or_individual')}
      </div>
      <Row className="my-3">
        <Col md={5} xs={12}>
          <FamilyOrIndividualButton
            active={state.formState.registrationType === 'individual'}
            className="my-2"
            icon="icon-individual"
            label={t('family_registrations.individual_appointment')}
            onClick={() =>
              dispatch({
                type: 'updateForm',
                values: { registrationType: 'individual' },
              })
            }
          />
        </Col>
        <Col md={5} xs={12}>
          <FamilyOrIndividualButton
            active={state.formState.registrationType === 'family'}
            className="my-2"
            icon="icon-family"
            label={t('family_registrations.family_appointment')}
            onClick={() =>
              dispatch({
                type: 'updateForm',
                values: { registrationType: 'family' },
              })
            }
          />
        </Col>
      </Row>
    </div>
  );

  const careLocation = (
    <div className="mb-5">
      <div className="mt-2 h4-20-semi-bold">
        {t('family_registrations.where_care')}
      </div>
      <Row className="mb-2">
        <Col md={4}>
          <Select
            ariaLabel="radius select"
            className="my-3"
            styles={{
              option: (base) => ({
                ...base,
                borderBottom: `1px solid #E2E7F0`,
                fontWeight: 500,
              }),
            }}
            onChange={(value) => {
              dispatch({
                type: 'updateForm',
                values: {
                  radius: value,
                },
              });
            }}
            options={RADIUS_OPTIONS.map(option => ({
              label: t(option.label, { miles: option.value }),
              value: option.value,
            }))}
            value={state.formState.radius}
          />
        </Col>
        <Col md={6} className='place-self-center'>
          <AutocompleteLocation
            value={state.formState.address}
            name="address"
            onChange={(e) => {
              dispatch({
                type: 'updateForm',
                values: {
                  address: e.target.description || e.target.value,
                },
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      {individualOrFamily}
      {!!state.formState.registrationType && careLocation}
      {!!state.formState.registrationType && !!state.formState.address && (
        <div className="mb-5">
          <div className="my-2 h4-20-semi-bold">
            {t(`family_registrations.select_vaccines_for_${state.formState.registrationType}`)}
          </div>
          <Row className="mt-3 mb-4">
            <Col md={5} className='mb-4 me-4'>
              <ServiceSpecificationSelector
                dispatch={dispatch}
                onMenuClose={() => !state.isTestEnvironment && setHackBoxHeight("")}
                onMenuOpen={() => !state.isTestEnvironment && setHackBoxHeight(HACK_BOX_HEIGHT)}
                // weirdly breaks a spec on Github when not adding `!state.isTestEnvironment`
                // works as expected when testing on local or doing manual test
                serviceSpecifications={state.serviceSpecifications}
                selectedServiceSpecifications={state.selectedServiceSpecifications}
              />
            </Col>
            <Col md={6} className='mb-4'>
              {state.filteredServiceSpecifications.length > 0 && (
                <Row>
                  <h6
                    className="body-medium-bold text-secondary my-3 mt-sm-0 text-uppercase"
                  >
                    {t('family_registrations.vaccines_selected')}
                  </h6>
                </Row>
              )}
              <Row>
                {state.filteredServiceSpecifications.map(
                  (serviceSpecification, idx) => (
                    <Col xs="auto" key={idx} className="mb-4">
                      <ServiceSpecificationBadge
                        t={t}
                        serviceSpecification={serviceSpecification}
                        onRemove={() =>
                          dispatch({
                            type: 'updateServiceSpecifications',
                            values: {
                              [serviceSpecification.key]: false,
                            },
                          })
                        }
                      />
                    </Col>
                  ),
                )}
              </Row>
            </Col>
          </Row>

          {!isMobile() && (
            <div style={{ height: hackBoxHeight }}></div> // hack to give extra space below dropdown when open
          )}

          <div className="d-flex flex-row justify-content-end mt-2">
            <Button
              className="d-none d-sm-block"
              data-test-hook="submit"
              onClick={() => dispatch({ type: 'perform_navigation', action: 'next' })}
            >
              {state.navigation.loading ? (
                <Spinner variant="light" animation="border" />
              ) : (
                t('enter_information.search')
              )}
            </Button>
          </div>
          <div className="d-sm-none">
            <Button
              block
              data-test-hook="submit"
              onClick={() => dispatch({ type: 'perform_navigation', action: 'next' })}
            >
              {state.navigation.loading ? (
                <Spinner variant="light" animation="border" />
              ) : (
                t('enter_information.search')
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualOrFamily;
