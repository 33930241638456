import React, { useEffect, useState } from 'react';
import Welcome from './components/Welcome';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepFour from './components/StepFour';
import StepFive from './components/StepFive';
import StepSix from './components/StepSix';
import StepSeven from './components/StepSeven';
import StepEight from './components/StepEight';
import StepNine from './components/StepNine';
import StepTen from './components/StepTen';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Header from '../../../Registration/components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { languageObject } from '../../common/utils';
import { lang } from '../../common/utils';

export const otcInstructionSubSteps = [
  <Welcome />, <StepOne />, <StepTwo />, <StepThree />, <StepFour />, <StepFive />, <StepSix />, <StepSeven />, <StepEight />, <StepNine />, <StepTen />
];

const DemoInstructions = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <div>
      <Header testGroup={{ locales: Object.keys(languageObject) }} />
      <div className="m-3 pb-3">
        <Container style={{ maxWidth: '546px', color: '#212529' }}>
          {currentStep === 0 && <Welcome />}
          {currentStep === 1 && <StepOne />}
          {currentStep === 2 && <StepTwo />}
          {currentStep === 3 && <StepThree />}
          {currentStep === 4 && <StepFour />}
          {currentStep === 5 && <StepFive />}
          {currentStep === 6 && <StepSix />}
          {currentStep === 7 && <StepSeven />}
          {currentStep === 8 && <StepEight />}
          {currentStep === 9 && <StepNine />}
          {currentStep === 10 && <StepTen />}
          <Row style={{ marginTop: 36 }}>
            <Col>
              <div className="mt-2">
                <Button
                  variant="link"
                  style={{ color: '#08173A' }}
                  onClick={() => {
                    if (currentStep === 0) {
                      window.location.href = `/test-instructions/l?locale=${lang()}`;
                    } else {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {t('public_test_groups.demo.instructions.back')}
                </Button>
              </div>
            </Col>
            <Col className="text-end">
              <Button
                variant="primary"
                style={{ backgroundColor: '#08173A', borderRadius: 200 }}
                onClick={() => {
                  if (currentStep === 10) {
                    window.location.href = `/test-instructions/l?locale=${lang()}`;
                  } else {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {t('public_test_groups.demo.instructions.continue')}&nbsp;&nbsp;
                <FontAwesomeIcon icon="arrow-right" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default DemoInstructions;
