import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { PersonalFieldDropdown } from '../../SectionComponents';

const VaccineProgramInput = ({
  columnWidth,
  setFieldValue,
  t,
  values,
}) => {

  const { testGroup } = useContext(RegistrationContext);
  const [vaccineProgramOptions, setVaccineProgramOptions] = useState({});

  const year = values['date_of_birth_datetime(1i)'];
  const month = values['date_of_birth_datetime(2i)'];
  const day = values['date_of_birth_datetime(3i)'];

  const fetchVaccineProgramOptions = async () => {
    const response = await axios.get(`/test_groups/${testGroup.id}/vaccine_program_options`, {
      params: { date_of_birth: `${year}-${month}-${day}` }
    });
    setVaccineProgramOptions(response.data);
  };

  useEffect(() => {
    if (testGroup.display_vaccine_program_options) fetchVaccineProgramOptions();
  }, [year, month, day]);

  if (!vaccineProgramOptions.vfc_options?.length) return <span></span>;

  return (
    <div className={`my-2 col-md-${columnWidth}`}>
      <>
        {/* VFA options can be implemented using !!vaccineProgramOptions.vfa_options */}
        {!!vaccineProgramOptions.vfc_options && (
          <PersonalFieldDropdown
            helpText={t('registration.vfc_eligibility_subtext')}
          >
            <FloatingLabelSelect
              containerClassName="my-2"
              filledValue={!!values?.appointment?.vfc_eligibility}
              label={t('registration.vfc_eligibility')}
              onChange={(s) => {
                setFieldValue('appointment.vfc_eligibility', s.value)
              }}
              options={vaccineProgramOptions.vfc_options}
              value={vaccineProgramOptions.vfc_options?.find(o => o.value == values?.appointment?.vfc_eligibility)}
            />
          </PersonalFieldDropdown>
        )}
      </>
    </div>
  );
};

export default VaccineProgramInput;