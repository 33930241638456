import React from 'react';
import { ListGroup } from 'react-bootstrap';

import CovidWarningBuilder from './CovidWarningBuilder';

const CovidWarnings = ({histories, tests, iisReferences, className, setHasWarning}) => {
  const warningBuilder = new CovidWarningBuilder({histories, tests, iisReferences});
  if (setHasWarning) setHasWarning(warningBuilder.warnings().length > 0);

  return (
    <ListGroup className={className}>
      {warningBuilder.warnings().map(({level, message}, index) => (
        <ListGroup.Item key={index} variant={level}>{message}</ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default CovidWarnings;
