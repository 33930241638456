import React from 'react';
import Select, { components } from 'react-select';

export const customStyles = {
  menuPortal: (provided) => ({
    ...provided,
    marginTop: '1px',
    zIndex: '15',
  }),

  control: (provided, state) => ({
    ...provided,
    borderRadius: '12px',
    minHeight: '48px',
    backgroundColor: state.isDisabled ? 'rgba(249, 249, 249, 0.5)' : '#fff',
    borderColor: state.isDisabled ? '#E2E7F0' : '#CBD2E0',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#5D6F88',
  }),

  multiValue: (provided) => ({
    ...provided,
    marginTop: '8px',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'unset' : '#2D3648',
  }),

  placeholder: (provided, state) => ({
    ...provided,
    opacity: state.isFocused ? '1' : '0',
    color: '#5D6F88',
  }),
};

export const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#2862FA',
    primary75: '#2862FA75',
    primary50: '#2862FA50',
    primary25: '#2862FA25',
    danger: '#F46363',
    dangerLight: '#EECDCF',
    neutral90: '#1A202C',
    neutral20: '#CBD2E0',
  },
});

export const DropdownIndicator = () => (
  <img
    alt="dropdown menu indicator"
    src="/images/caret-down.svg"
    style={{ marginRight: '11px', height: 7 }}
  />
);

const Option = ({innerProps, children, data, ...props}) => (
  <components.Option
    innerProps={{...innerProps, "data-test-hook": `option_${data.value}`}}
    {...props}
  >
    {children}
  </components.Option>
);

const SelectContainer = ({innerProps, children, dataTestHook, ...props}) => (
  <components.SelectContainer
    innerProps={{...innerProps, "data-test-hook": dataTestHook}}
    {...props}
  >
    {children}
  </components.SelectContainer>
);

export const IndicatorSeparator = () => null;

const FloatingLabelSelect = ({
  ariaDescribedby,
  ariaInvalid,
  ariaLabel,
  ariaRequired,
  autoFocus,
  className,
  classNamePrefix,
  components = {},
  containerClassName,
  defaultValue,
  filledValue,
  filterOption,
  id,
  isClearable,
  isDisabled,
  isMulti,
  isSearchable,
  label,
  menuPortalTarget,
  name,
  onChange,
  options,
  placeholder,
  readOnly,
  type,
  value,
  maxItems,
  style = {},
  inputRef = React.createRef(),
  hideSelectedOptions = true,
  controlShouldRenderValue = true,
}) => {
  const isOptionDisabled = () => maxItems && value?.length >= maxItems;
  return (
    <div
      className={`input-container select-container ${
        containerClassName ? containerClassName : ''
      }${ariaRequired ? ' required' : ''}`}
    >
      <label className={filledValue && 'filled'} htmlFor={id} id={`${id}-label`}>
        {label}
      </label>
      <Select
        aria-describedby={ariaDescribedby}
        aria-invalid={ariaInvalid}
        aria-label={ariaLabel}
        aria-labelledby={`${id}-label`}
        aria-required={ariaRequired}
        autoFocus={autoFocus}
        className={className}
        classNamePrefix={classNamePrefix}
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          Option,
          SelectContainer: (props) => <SelectContainer {...props} dataTestHook={name || id} />,
          ...components
        }}
        defaultValue={defaultValue}
        filterOption={filterOption}
        inputId={id}
        key={value?.length || ''}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuPortalTarget={menuPortalTarget}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder || ''}
        readOnly={readOnly}
        ref={inputRef}
        styles={{ ...customStyles, ...style }}
        type={type}
        value={value}
        required={ariaRequired}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={hideSelectedOptions}
        controlShouldRenderValue={controlShouldRenderValue}
        theme={selectTheme}
      />
    </div>
  );
};

export default FloatingLabelSelect;
