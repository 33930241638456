import React, { useContext, useEffect, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import RegistrationContext from '../../../contexts/RegistrationContext';
import MembersPortalContext from '../../../../MembersPortal/context';
import {
  buildCustomizedLabel,
} from '../../../../common/locales/langs';
import PhoneInput from '../inputs/PhoneInput';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import ErrorMessage from '../../../../common/components/ErrorMessage';
import { showFieldOnRegistration } from '../utils';
import PreferredMethodOfCommunication from '../fields/PreferredMethodOfCommunication';
import { requiredByTestGroup, phoneOrEmailRequired } from '../ContactInformation';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';
import DefaultInformation from '../DefaultInformation';

const CONTACT_FIELDS = ["email", "phone_number", "phone_or_email"];

const PhoneAndEmailFields = ({
  values,
  errors,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, currentSectionHeader, sections, currentRegistrationStep } = useContext(RegistrationContext);
  const { currentMember } = useContext(MembersPortalContext);
  const [editEmail, setEditEmail] = useState(false);
  const isFamilyRegistration = !!values.appointment?.appointment_slots_by_dates;

  const setSameValuesAsFamilyMemberOne = () => {
    CONTACT_FIELDS.forEach((field) => setFieldValue(field, values.family_member_one_contact[field]));
  };
  const clearValues = () => {
    CONTACT_FIELDS.forEach((field) => setFieldValue(field, ""));
  };
  if (isFamilyRegistration && !values["family_member_one_contact"]) {
    setFieldValue("family_member_one_contact", {
      "email": values.email,
      "phone_number": values.phone_number,
      "phone_or_email": values.phone_or_email,
    });
    clearValues();
  }
  useEffect(() => {
    CONTACT_FIELDS.every((field) => {
      if (values["family_member_one_contact"] && values[field] != values["family_member_one_contact"][field]) {
        setFieldValue("use_same_contact_as_family_member_one", false);
        return false;
      }
      return true;
    });
  }, [values.email, values.phone_number, values.phone_or_email]);

  const handleContactInformationChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
    setFieldValue('contact_verification_id', null);
  }

  const phoneOrEmailValue = () => {
    if(phoneOrEmailRequired(testGroup)) {
      if (values.has_default_contact) {
        return ""
      }
      if (values.phone_or_email || values.phone_or_email == "") {
        return values.phone_or_email;
      } else if (values.email) {
        values.phone_or_email = values.email;
        return values.phone_or_email;
      } else if (values.phone_number) {
        values.phone_or_email = values.phone_number;
        return values.phone_or_email;
      } else {
        return ""
      }
    }
  }

  const contactSectionEmailAndPhone = (
    <div>
      <Row as="fieldset" className='mb-5 align-items-center'>
        <legend className="sr-only">contact section email and phone</legend>
        <Col xs="auto" md={6}>
          <PhoneInput
            name=""
            handleChange={handleContactInformationChange}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            required={requiredByTestGroup(testGroup, 'phone_number')}
            withLandine={false}
            disabled={values.has_default_contact}
          />
        </Col>
        <Col xs="auto">
          <FabrxCheckbox
            name="phone_number_unreachable"
            ariaLabel="landline phone number"
            onChange={(checked) => {
              setFieldValue('phone_number_unreachable', !values.phone_number_unreachable)
            }}
            labelClassName='lead-20-medium inter regular'
            checked={values.phone_number_unreachable || false}
            label={ t('registration.phone_number_unreachable_label') }
            disabled={values.has_default_contact}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto" md={6}>
          <FloatingLabelInput
            name="email"
            className="email optional mb-2"
            value={values.has_default_contact ? "" : values.email || ''}
            onChange={handleContactInformationChange}
            readOnly={currentMember && !editEmail}
            ariaLabel="email"
            ariaRequired={requiredByTestGroup(testGroup, 'email')}
            ariaDescribedby="emailErrorDesc"
            ariaInvalid={!!errors.email}
            id="email"
            type="email"
            label={
              t('registration.email') +
              (requiredByTestGroup(testGroup, 'email') ? ' *' : '')
            }
            validation={errors.email && 'is-invalid'}
            disabled={values.has_default_contact}
          />
          {currentMember &&
            (testGroup.population.email_domain || testGroup.email_domain) && (
              <Col xs="auto">
                <Button
                  size="sm"
                  onClick={() => setEditEmail(!editEmail)}
                  className="mt-1"
                >
                  Edit
                </Button>
              </Col>
            )}
          {errors.email && (
            <ErrorMessage
              id="emailErrorDesc"
              message={t(errors.email, { field: t('registration.email') })}
            />
          )}
        </Col>
      </Row>
    </div>
  );

  const emailAndPhone = (
    <Row as="fieldset" className="mb-3">
      <legend className="sr-only">email and phone</legend>
      <Col xs={12} lg={6} md={6} xl={6}>
        <PhoneInput
          name=""
          handleChange={handleContactInformationChange}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          required={requiredByTestGroup(testGroup, 'phone_number')}
        />
      </Col>
      <Col>
        <FloatingLabelInput
          name="email"
          className="email optional mb-2"
          value={values.has_default_contact ? "" : values.email || ''}
          onChange={handleContactInformationChange}
          readOnly={currentMember && !editEmail}
          ariaLabel="email"
          ariaRequired={requiredByTestGroup(testGroup, 'email')}
          ariaDescribedby={errors.email ? "emailErrorDesc" : undefined}
          ariaInvalid={!!errors.email}
          id="email_input"
          type="email"
          label={
            t('registration.email') +
            (requiredByTestGroup(testGroup, 'email') ? ' *' : '')
          }
          validation={errors.email && 'is-invalid'}
        />

        {currentMember &&
          (testGroup.population.email_domain || testGroup.email_domain) && (
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() => setEditEmail(!editEmail)}
                className="mt-1"
              >
                Edit
              </Button>
            </Col>
          )}
        {errors.email && (
          <ErrorMessage
            id="emailErrorDesc"
            message={t(errors.email, { field: t('registration.email') })}
          />
        )}
      </Col>
    </Row>
  );

  const phoneOrEmail = (
    <Row as="fieldset" className="mb-3">
      <legend className="sr-only">phone or email</legend>
      <Col xs={12} lg={6} md={6} xl={6}>
        <FloatingLabelInput
          name="phone_or_email"
          className="mb-2"
          value={phoneOrEmailValue()}
          onChange={handleContactInformationChange}
          ariaLabel="phone_or_email"
          ariaRequired={requiredByTestGroup(testGroup, 'phone_or_email')}
          ariaDescribedby="phoneOrEmailErrorDesc"
          ariaInvalid={!!errors.phone_or_email}
          id="phone_or_email"
          label={`${t('registration.phone_or_email')} *`}
          validation={errors.phone_or_email && 'is-invalid'}
          />
        {errors.phone_or_email && (
          <ErrorMessage
            id="phoneOrEmailErrorDesc"
            message={t(errors.phone_or_email, { field: t('registration.phone_or_email') })}
          />
        )}
      </Col>
    </Row>
  )

  const phoneEmailInput = phoneOrEmailRequired(testGroup)
    ? phoneOrEmail
    : sections[currentRegistrationStep].name == "phone_and_email"
      ? contactSectionEmailAndPhone
      : emailAndPhone

  return (
    <div>
      {testGroup.population.require_guardian ? (
        <h5>{ t(buildCustomizedLabel(testGroup.population,'registration.contact_information_additional')) }</h5>
      ) : (
        <label className="lead-20-medium inter bold">{t('test_strip.credentials.label')}</label>
      )}
      {isFamilyRegistration && !!Object.values(values.family_member_one_contact || {}).filter(n => n).length && (
        <div className="my-2">
          <FabrxCheckbox
            onChange={() => {
              const checked = values.use_same_contact_as_family_member_one;
              !checked && setSameValuesAsFamilyMemberOne();
              setFieldValue("use_same_contact_as_family_member_one", !checked);
            }}
            checked={values.use_same_contact_as_family_member_one}
            label={"Contact information is the same as family member 1."}
          />
        </div>
      )}
      { phoneEmailInput }
      {!values.has_default_contact && showFieldOnRegistration(testGroup, "preferred_method_of_communication") &&
        <PreferredMethodOfCommunication {...{values, setFieldValue}} />
      }
      { testGroup.has_default_contact &&
        <div className='mt-3'>
          <FabrxCheckbox
            name="default_contact"
            onChange={() => {
              if (values.has_default_contact) {
                setFieldValue('email', "");
                setFieldValue('phone_number', "");
                setFieldValue('phone_or_email', "");
                setFieldValue('has_default_contact', false)
              } else {
                const defaults = testGroup.default_user_fields.contacts;
                setFieldValue('email', defaults?.email);
                setFieldValue('phone_or_email', defaults?.email);
                setFieldValue('phone_number', defaults?.phone_number);
                setFieldValue('has_default_contact', true)
              }
            }}
            labelClassName='lead-20-medium inter regular'
            checked={values.has_default_contact}
            label={t('registration.decline_email_or_phone')}
          />
        </div>
      }
      {values.has_default_contact && <DefaultInformation />}
    </div>
  );
};

export default PhoneAndEmailFields;
