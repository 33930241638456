import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import AssignAppointmentSlotGroupModal from '../../TestGroupUsers/components/AssignAppointmentSlotGroupModal';
import CheckoutButton from '../../TestGroupUsers/components/CheckoutButton';
import Table from '../../common/components/Table/components/Table';
import Pagination, {
  PaginationMetadata,
} from '../../common/components/TablePagination';
import useRefetchable from '../../common/hooks/useRefetchable';
import {
  pushQueryParam,
  turbolinksOnPopState,
} from '../../common/utils/history';

interface TestGroup {
  id: number;
  assignAppointmentSlotGroupsToUsers: boolean;
  activeTestConfigurations: Array<{
    id: number;
    label: string;
  }>;
  appointmentSlotGroups: Array<{
    id: number;
    label: string;
  }>;
}

interface TestGroupUser {
  id: number;
  fullName: string;
  dateOfBirth: string;
  school: string;
  assignedAppointmentSlotGroupId?: number;
  studentProfile: {
    studentId: string;
    grade: string;
  };
  user: {
    readyToCheckout: boolean;
  };
  links: {
    createOnDemandAppointmentUrl: string;
  };
  studentProfileEditPath: string;
}

interface IndexTableProps {
  testGroupUsers: Array<TestGroupUser>;
  testGroup: TestGroup;
  meta: PaginationMetadata;
}

interface CheckoutModalState {
  open: boolean;
  testGroupUser: TestGroupUser | null;
  customUrl: string;
}

type CheckoutModalAction =
  | { type: 'open'; customUrl: string; testGroupUser: TestGroupUser }
  | { type: 'close' };

const checkoutModalReducer = (
  state: CheckoutModalState,
  action: CheckoutModalAction,
): CheckoutModalState => {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        open: true,
        customUrl: action.customUrl,
        testGroupUser: action.testGroupUser,
      };
    case 'close':
      return {
        ...state,
        open: false,
        customUrl: '',
        testGroupUser: null,
      };
    default:
      throw new Error();
  }
};

const StudentCheckoutButton = ({ testGroup, testGroupUser, onClick }) => (
  <CheckoutButton
    testGroup={testGroup}
    onDemand
    links={{
      create_on_demand_appointment_url:
        testGroupUser.links.createOnDemandAppointmentUrl,
    }}
    readyToCheckout={testGroupUser.user.readyToCheckout}
    onClick={onClick}
  />
);

const fetchData = async () =>
  axios
    .get(`${window.location.pathname}.json`, {
      params: Object.fromEntries(
        new URL(window.location.href).searchParams.entries(),
      ),
    })
    .then((response): IndexTableProps => response.data);

const StudentsIndexTable: FC<IndexTableProps> = (props) => {
  const [page, _setPage] = useState(props.meta.currentPage);
  const [{ testGroupUsers, meta, testGroup }] = useRefetchable(
    fetchData,
    props,
    [page],
  );

  // Checkout location modal
  const [modalState, dispatch] = React.useReducer(checkoutModalReducer, {
    open: false,
    testGroupUser: null,
    customUrl: '',
  });

  const setPage = (page: number) => {
    pushQueryParam('page', page, { turbolinks: {} });
    _setPage(page);
    document.getElementById('students-index-table').scrollIntoView();
  };

  useEffect(() => {
    window.addEventListener('popstate', turbolinksOnPopState);

    return () => {
      window.removeEventListener('popstate', turbolinksOnPopState);
    };
  }, []);

  return (
    <React.Fragment>
      <section className="fabrx-tables-light table-responsive">
        <Table
          id="students-index-table"
          headers={[
            {
              name: 'Student name',
              CellComponent: ({ fullName }) => <a>{fullName}</a>,
            },
            {
              name: 'Student ID',
              CellComponent: ({ studentProfile: { studentId } }) => (
                <>{studentId}</>
              ),
            },
            {
              name: 'DOB',
              field: 'dateOfBirth',
            },
            {
              name: 'School',
              field: 'school',
            },
            {
              name: 'Grade',
              CellComponent: ({ studentProfile: { grade } }) => <>{grade}</>,
            },
            {
              name: '',
              CellComponent: ({ studentProfileEditPath }) => (
                <a href={studentProfileEditPath}>Student profile</a>
              ),
            },
            // Checkout button if active test configurations exist
            ...(testGroup.activeTestConfigurations.length > 0
              ? [
                  {
                    name: '',
                    CellComponent: (testGroupUser: TestGroupUser) => (
                      <StudentCheckoutButton
                        testGroup={testGroup}
                        testGroupUser={testGroupUser}
                        onClick={
                          testGroup.assignAppointmentSlotGroupsToUsers &&
                          ((href: string) =>
                            dispatch({
                              type: 'open',
                              customUrl: href,
                              testGroupUser,
                            }))
                        }
                      />
                    ),
                  },
                ]
              : []),
          ]}
          data={testGroupUsers}
        />
      </section>
      <br />

      <Pagination {...meta} setPage={setPage} />

      {modalState.open && (
        <AssignAppointmentSlotGroupModal
          show={modalState.open}
          onHide={() => dispatch({ type: 'close' })}
          customUrl={modalState.customUrl}
          testGroupUser={modalState.testGroupUser}
          appointmentSlotGroupOptions={testGroup.appointmentSlotGroups.map(
            (loc) => ({ ...loc, value: loc.id }),
          )}
        />
      )}
    </React.Fragment>
  );
};

export default StudentsIndexTable;
