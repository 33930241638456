import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { OpenCircleCheck } from '../../../common/components/Icons';
import { Section, SectionItem } from '../../components/styled';
import ReactMarkdown from 'react-markdown';

export function List({ title, subtitle, steps, t }) {
  return (
    <Section>
      <SectionItem>
        <h3 className="poppins semibold ls-small">{t(title)}</h3>
      </SectionItem>
      <SectionItem>
        <h3 className="kit-body-text">{t(subtitle)}</h3>
      </SectionItem>
      {steps.map(({ step }, index) => (
        <SectionItem key={index}>
          <Row style={{ margin: '0 21px' }} className="justify-content-center">
            <Col xs={'auto'} className="pe-0">
              <OpenCircleCheck style={{ fontSize: '2em' }} />
            </Col>
            <Col>
              <ReactMarkdown className="h4-20-regular" source={t(step)} />
            </Col>
          </Row>
        </SectionItem>
      ))}
    </Section>
  );
}

export default function PackagingStep({ packaging, t }) {
  return <List {...packaging} t={t} />;
}
