import React from 'react';

const Digital = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M0 2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H14C14.5304 0.5 15.0391 0.710714 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5V4.5C16 5.03043 15.7893 5.53914 15.4142 5.91421C15.0391 6.28929 14.5304 6.5 14 6.5H2C1.46957 6.5 0.960859 6.28929 0.585786 5.91421C0.210714 5.53914 0 5.03043 0 4.5V2.5ZM14 3.5C14 3.76522 13.8946 4.01957 13.7071 4.20711C13.5196 4.39464 13.2652 4.5 13 4.5C12.7348 4.5 12.4804 4.39464 12.2929 4.20711C12.1054 4.01957 12 3.76522 12 3.5C12 3.23478 12.1054 2.98043 12.2929 2.79289C12.4804 2.60536 12.7348 2.5 13 2.5C13.2652 2.5 13.5196 2.60536 13.7071 2.79289C13.8946 2.98043 14 3.23478 14 3.5ZM0 10.5C0 9.96957 0.210714 9.46086 0.585786 9.08579C0.960859 8.71071 1.46957 8.5 2 8.5H14C14.5304 8.5 15.0391 8.71071 15.4142 9.08579C15.7893 9.46086 16 9.96957 16 10.5V12.5C16 13.0304 15.7893 13.5391 15.4142 13.9142C15.0391 14.2893 14.5304 14.5 14 14.5H2C1.46957 14.5 0.960859 14.2893 0.585786 13.9142C0.210714 13.5391 0 13.0304 0 12.5V10.5ZM14 11.5C14 11.7652 13.8946 12.0196 13.7071 12.2071C13.5196 12.3946 13.2652 12.5 13 12.5C12.7348 12.5 12.4804 12.3946 12.2929 12.2071C12.1054 12.0196 12 11.7652 12 11.5C12 11.2348 12.1054 10.9804 12.2929 10.7929C12.4804 10.6054 12.7348 10.5 13 10.5C13.2652 10.5 13.5196 10.6054 13.7071 10.7929C13.8946 10.9804 14 11.2348 14 11.5Z"
    />
  </svg>
);

export default Digital;
