import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import format from 'date-fns/format';
import SurveyPreview from "../../../common/components/SurveyPreview";
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';

const TestSurveyForm = ({
  test,
  updateTest,
  disabled,
  isInvalid=false,
 }) => {

  const survey = (test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey
    : test.test_configuration.survey) || {};
  const surveyAnswers = (test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey_answers
    : test.test_survey) || { admin_approved: false };

  const [render, reRender] = useState(false);

  let localizedCompletedAt,
      localizedAdminApprovedAt;
  const surveyCompletedAt = surveyAnswers.created_at;
  const surveyAdminApprovedAt = surveyAnswers.admin_approved_at;
  if (surveyCompletedAt) localizedCompletedAt = format(new Date(surveyCompletedAt), 'MMMM dd, yyyy HH:mm:ss');
  if (surveyAdminApprovedAt) localizedAdminApprovedAt = format(new Date(surveyAdminApprovedAt), 'MMMM dd, yyyy HH:mm:ss');

  return (
    <React.Fragment>
      {Object.keys(survey).length > 0 &&
        <SurveyPreview
          json={survey}
          data={surveyAnswers.answers || surveyAnswers.survey}
          onValueChanged={(e)=> {
            reRender(!render);
            if (!disabled){
              test.use_medical_screening_survey_on_checkout
                ? updateTest({...test, medical_screening_survey_answers: {...surveyAnswers, answers: e.data}})
                : updateTest({...test, test_survey: {...surveyAnswers, survey: e.data}})
            }
          }}
        />
      }
      <Card body className="mt-3">
        <div className="my-2">
          Participant completed screening: {localizedCompletedAt || "False"}
        </div>
        {disabled && surveyAnswers.admin_approved ? (
          <div>
            Screening approved: {localizedAdminApprovedAt || "True"}
          </div>
        ) : (
          <FabrxCheckbox
            name="screening_completed"
            onChange={(checked) => {
              reRender(!render);
              test.use_medical_screening_survey_on_checkout
                ? updateTest({ ...test, medical_screening_survey_answers: { ...surveyAnswers, admin_approved: !surveyAnswers.admin_approved }})
                : updateTest({ ...test, test_survey: { ...surveyAnswers, admin_approved: !surveyAnswers.admin_approved }})
            }}
            inputClassName={isInvalid ? "is-invalid" : ""}
            checked={surveyAnswers.admin_approved}
            label="Screening Complete?"
          />
        )}
      </Card>
    </React.Fragment>
  );
};


export default TestSurveyForm;
