import React from 'react';
import ImmunizationCard from './ImmunizationCard';

const ImmunizationInfo = ({ pendingImmunizationRecords = [] }) => (
  <div className="mt-4 mt-md-0">
    <span className="section-header">Immunization info.</span>
    <div className="immunization-card-wrapper mt-2">
      {pendingImmunizationRecords.map((immunizationRecord, idx) => (
        <ImmunizationCard key={idx} immunizationRecord={immunizationRecord} />
      ))}
    </div>
  </div>
);

export default ImmunizationInfo;
