import React from 'react';
import AutocompleteSearch from '../PrimaryDesignSystem/AutocompleteSearch';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RegistrationDirectorySearch = ({ loading, form, setForm, submit }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="rd-search">
        <div className="w-100 me-3">
          <AutocompleteSearch
            dataTestHook="search-by-keyword"
            id="search_keyword"
            input={form.keyword}
            label={t("registration_direcory.keyword_search_label_mobile")}
            name="keyword"
            prependIcon="search"
            autoFocus
            onChange={(e) => setForm({...form, keyword: e.target.value})}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
          />
        </div>
        <Button
          data-test-hook="search_locations"
          variant="primary"
          className="btn-icon btn-search"
          disabled={loading || (!form.location && !form.keyword)}
          name="commit"
          onClick={submit}
          data-disable-with="<i class='fa fa-circle-notch fa-spin'></i>"
        >
          {loading && (
            <span>
              <FontAwesomeIcon icon="spinner" className="fa-spin" />
            </span>
          )}
          {!loading && (
            <span>
              <span className='me-3 d-inline d-xl-none'>See results</span>
              <i className="fa fa-arrow-right" />
            </span>
          )}
        </Button>
      </div>
    </>
  )
}

export default RegistrationDirectorySearch;
