import React from 'react';

const CapTube = ({ color = '#FFC0CB', ...props }) => (
  <svg
    width="36"
    height="58"
    viewBox="0 0 36 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
      }}
      d="M21.3442 32.6997L21.3111 36.4451L14.6488 43.1074L14.6819 32.6665C14.6819 32.2688 14.3505 31.9373 13.9527 31.9373C13.5218 31.9042 13.1572 32.2688 13.1904 32.6997L13.1572 49.7034C13.1904 52.2556 15.0465 54.5758 17.6319 54.7747C19.0903 54.841 20.4161 54.3106 21.3774 53.3494C22.2723 52.4545 22.8026 51.2612 22.8026 49.9354L22.8358 32.6665C22.8358 32.2688 22.5043 31.9373 22.1066 31.9373C21.6757 31.9042 21.3111 32.2688 21.3442 32.6997ZM21.3442 49.9354C21.3111 50.8303 20.9465 51.659 20.3167 52.2888C19.6206 52.9848 18.6926 53.3163 17.7313 53.2831C16.0078 53.1505 14.6819 51.5596 14.6819 49.7034L14.6488 45.2287L21.3111 38.5665L21.3442 49.9354ZM23.8633 28.9211H12.196C11.9971 28.9211 11.7983 28.9874 11.6657 29.12C11.5 29.2857 11.4337 29.4846 11.4337 29.6834C11.4337 30.0812 11.7983 30.4458 12.196 30.4458H23.8633C24.0621 30.4458 24.2279 30.3463 24.3936 30.1806C24.5262 30.048 24.6256 29.8823 24.6256 29.6834C24.6256 29.2857 24.261 28.9211 23.8633 28.9211Z"
    />
    <path
      style={{
        fill: color,
      }}
      d="M18.7466 25.4453C18.3716 25.8516 17.7153 25.8516 17.3403 25.4453L12.3403 20.4453C12.0591 20.1641 11.9653 19.7266 12.1216 19.3516C12.2778 18.9766 12.6528 18.7266 13.0591 18.7266H16.0591V12.9766C16.0591 12.2891 16.5903 11.7266 17.3091 11.7266H18.8091C19.4966 11.7266 20.0591 12.2891 20.0591 12.9766V18.7266H23.0591C23.4341 18.7266 23.8091 18.9766 23.9653 19.3516C24.1216 19.7266 24.0278 20.1641 23.7466 20.4453L18.7466 25.4453Z"
    />
    <rect
      x="8.80908"
      y="1.61328"
      width="19.5"
      height="6.5"
      rx="2.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <rect
      x="24.4355"
      y="3.48828"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="20.4355"
      y="3.48828"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="16.4355"
      y="3.48828"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="12.4355"
      y="3.48828"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
  </svg>
);

export default CapTube;
