import React, { useCallback, useMemo } from 'react';
import ReactSelect, { createFilter } from 'react-select';

const Select = ({
  className = '',
  isMulti,
  value: valueProp = isMulti ? [] : null,
  options: optionsProp,
  onChange: onChangeProp = () => {},
  name,
  valueKey = 'value',
  labelKey = 'label',
  includeBlank,
  blankLabel = '',
  ...props
}) => {
  const options = useMemo(() => {
    const newOptions = optionsProp.map((opt) => ({
      ...opt,
      value: opt[valueKey],
      label: opt[labelKey],
    }));

    if (includeBlank) newOptions.unshift({ value: '', label: blankLabel });

    return newOptions;
  }, [optionsProp]);

  const value = useMemo(() => {
    return isMulti
      ? options.filter((option) => valueProp.includes(option.value))
      : options.find((option) => valueProp === option.value);
  }, [isMulti, valueProp, options]);

  const onChange = useCallback(
    (option) => {
      const value = isMulti ? option.map((o) => o.value) : option.value;
      onChangeProp(value);
    },
    [onChangeProp, isMulti],
  );

  const selectTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#2862FA",
      primary75: "#2862FA75",
      primary50: "#2862FA50",
      primary25: "#2862FA25",
      danger: "#F46363",
      dangerLight: "#EECDCF",
      neutral90: "#1A202C",
    }
  })

  return (
    <div className={`dropdown ${className}`}>
      <ReactSelect
        className={`basic-${isMulti ? 'multi' : 'single'}`}
        classNamePrefix="select"
        filterOption={createFilter({ ignoreAccents: false })}
        value={value}
        options={options}
        isSearchable
        components={{ IndicatorSeparator: () => null }}
        name={name}
        onChange={onChange}
        isMulti={isMulti}
        theme={selectTheme}
        {...props}
      />
    </div>
  );
};

export default Select;
