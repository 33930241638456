import React, { useContext } from 'react';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { validateSurvey } from './CustomSurvey';
import RegistrationContext from '../../contexts/RegistrationContext';
import PersonalInformationFields from './fields/PersonalInformationFields';
import { ethnicityOn } from './inputs/EthnicityCheckboxes';

export const validatePersonalInformation = (testGroup) => (values) => {
  let survey_errors = {};
  if (Object.keys(testGroup.federal_surveyjs).length) {
    survey_errors = validateSurvey.bind(
      null,
      'federal_questionnaire',
      testGroup.federal_surveyjs,
    )(testGroup)(values);
  }
  const errors = { ...survey_errors };

  const shouldValidateSelfDescribedRace = () =>
    testGroup.allow_self_described_race_enabled &&
    testGroup.allow_self_described_race &&
    (testGroup.required_attributes.race ||
      testGroup.fields_requested.includes('race')) &&
    values.races.length &&
    values.races.includes('other');

  if (testGroup.required_attributes.gender_identity && !values.gender_string)
    errors.gender_string = 'registration.errors.required';
  if (testGroup.required_attributes.arab_ethnicity && !values.arab_ethnicity)
    errors.arab_ethnicity = 'registration.errors.required';
  if (
    testGroup.required_attributes.ethnicity &&
    Object.entries(values.ethnicity_blob).filter(([_, ethnicityValue]) =>
      ethnicityOn(ethnicityValue),
    ).length === 0
  ) {
    errors.ethnicity_blob = 'registration.errors.required';
  }

  if (
    testGroup.required_attributes.preferred_language &&
    !values.language_preference
  )
    errors.preferred_language = 'registration.errors.required';
  if (testGroup.required_attributes.race && !values.races.length)
    errors.race = 'registration.errors.required';
  if (shouldValidateSelfDescribedRace() && !values.self_described_race)
    errors.self_described_race = 'registration.errors.required';
  if (testGroup.required_attributes.sex_at_birth && !values.sex_at_birth)
    errors.sex_at_birth = 'registration.errors.required';
  if (testGroup.required_attributes.pronoun && !values.pronoun)
    errors.pronoun = 'registration.errors.required';

  if (testGroup.required_attributes.sexual_orientation) {
    if (!values.sexual_orientation) {
      errors.sexual_orientation = 'registration.errors.required';
    } else if (values.sexual_orientation === 'orientation_not_listed') {
      errors.sexual_orientation = 'registration.errors.required';
    }
  }

  return errors;
};

const PersonalInformation = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  columnWidth = columnWidth || '7'
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState } =
    useContext(RegistrationContext);

  return (
    <div className="form-section">
      <PersonalInformationFields
        values={values}
        errors={errors}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        columnWidth={columnWidth}
      />
      <div className="text-muted">
        <LocalizedMarkdown
          container={testGroup}
          stringKey="demographic_privacy_policy"
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
