import React, { useContext, useState } from 'react';
import '../../../common/locales/i18n';
import Sidebar from './Sidebar';
import MembersPortalContext from '../../context';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import MemberSelection from './MemberSelection';
import { useMediaQuery } from 'react-responsive';
import DeleteMember from './DeleteMember';
import { useHistory } from 'react-router';

const PortalWrapper = ({withSidebar=true, children}) => {
  const { t, onClickProfile } = useContext(MembersPortalContext);
  const [showMembers, setShowMembers] = useState(false);
  const [editMembers, setEditMembers] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingDelete, setPendingDelete] = useState({});

  const handleCloseDeleteMember = () => {
    setShowMembers(true);
    setEditMembers(true);
    setShowDeleteModal(false);
    setPendingDelete({});
  };

  const handleHideMemberSelection = () => {
    setShowMembers(false);
    setEditMembers(false);
    setShowDeleteModal(false);
    setPendingDelete({});
  };

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  
  return (
    <div className="members members-portal">
      <section className="d-flex">     
        {(withSidebar || isDesktop) &&
          <Sidebar
            onShowMembers={() => setShowMembers(true)}
          />
        }
        {showMembers && 
          <Modal
            show={showMembers}
            onHide={handleHideMemberSelection}
            centered
            size="lg"
            className='members'
          >
            <Modal.Body>
              <h2>{t('login.select_member')}</h2>
              <p className='text-muted mb-3'>{t('login.edit_members_subtitle')}</p>
              <MemberSelection
                edit={editMembers}
                onClickMember={(householdMember) => {
                  if (editMembers) {
                    setPendingDelete(householdMember);
                    setShowDeleteModal(true);
                  } else {
                    onClickProfile(householdMember);
                  }
                  setShowMembers(false);
                }}
              />
              <Row className='mt-5'>
                <Col>
                  <Button block onClick={() => setEditMembers(!editMembers)}>
                    {editMembers
                      ? t('registration.done')
                      : <><img src="/images/login/pencil.svg" style={{filter: "brightness(10)"}} className='me-1'/>{t('login.edit_members')}</>
                    }
                  </Button>
                </Col>
                <Col>
                  <Button variant="light" block onClick={handleHideMemberSelection}>
                    {t('self_administration.cancel')}
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        }
        <DeleteMember
          householdMember={pendingDelete}
          handleClose={handleCloseDeleteMember}
          show={showDeleteModal}
        /> 
        <div className='portal-view'>
          {children}
        </div>
      </section>
    </div>
  );
};

export default PortalWrapper;
