import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import FormSelect from './FormSelect';

const options = (records) => {
  return records.map(record => {
    return {value: record.id, label: record.name};
  });
};

const testGroupIsServedByLocation = ({ testGroupId, testLocation }) => {
  return testLocation.test_group_ids.includes(parseInt(testGroupId));
}

const GlobalSearchFilters = ({ testGroups, testLocations, testLanes }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const testLaneId = urlParams.get('test_lane');
  const testLane = testLaneId && testLanes.find(testLane => testLane.id == testLaneId);

  const [testGroupId, setTestGroupId] = useState(urlParams.get('test_group'));
  const [testLocationId, setTestLocationId] = useState(urlParams.get('test_location_id') || testLane?.test_location_id);
  const testLocation = testLocationId && testLocations.find(testLocation => testLocation.id == testLocationId);

  let filteredTestGroups;
  if (testLocation) {
    filteredTestGroups = testGroups.
      filter(testGroup => testGroupIsServedByLocation({testLocation, testGroupId: testGroup.id}));
  } else {
    filteredTestGroups = testGroups;
  }

  let filteredTestLocations;
  if (testGroupId) {
    filteredTestLocations = testLocations.filter((testLocation) => {
      return testGroupIsServedByLocation({ testLocation, testGroupId });
    });
  } else {
    filteredTestLocations = testLocations;
  }

  let filteredTestLanes;
  if (testLocation) {
    filteredTestLanes = testLanes.filter((testLane) => {
      return testLane.test_location_id == testLocationId;
    });
  }

  return (
    <Row className="gx-lg-2 gx-xl-3">
      <Col xs={12} lg={testLocationId ? 4 : 6} className="mb-3 mb-lg-0">
        <FormSelect
          key={`testGroups-${testLocationId}`}
          fieldName="test_group"
          prompt="Group"
          options={options(filteredTestGroups)}
          selected={testGroupId}
          onChange={id => setTestGroupId(id)}
        />
      </Col>
      <Col xs={12} lg={testLocationId ? 4 : 6} className="mb-3 mb-lg-0">
        <FormSelect
          fieldName="test_location_id"
          prompt="Location"
          options={options(filteredTestLocations)}
          selected={testLocationId}
          onChange={id => setTestLocationId(id)}
        />
      </Col>
      {testLocationId &&
        <Col xs={12} lg={4} className="mb-3 mb-lg-0">
          <FormSelect
            key={`testLanes-${testLocationId}`}
            fieldName="test_lane"
            prompt="Test lane"
            options={options([{id: 'unnassigned', name: 'Unassigned'}].concat(filteredTestLanes))}
            selected={testLaneId}
          />
        </Col>
      }
    </Row>
  )
}

export default GlobalSearchFilters;
