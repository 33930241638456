import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import { FilterBadge } from '../../School/components/StudentImmunizationStatusFilters';

export const IMMUNIZATION_FILTER_LABELS = {
  medically_exempt: "Exempt: Med",
  non_medically_exempt: "Exempt: Non-Med",
};

const IMMUNIZATION_FILTERS = [
  { label: "All", value: null },
  { label: "Overdue", value: "overdue" },
  { label: "Conditional", value: "conditional" },
  { label: "Compliant", value: "compliant" },
  { label: IMMUNIZATION_FILTER_LABELS["medically_exempt"], value: "medically_exempt" },
  { label: IMMUNIZATION_FILTER_LABELS["non_medically_exempt"], value: "non_medically_exempt" },
];

const ImmunizationsFilter = ({
  allowNonMedicalExemptions,
  immunizationRecords,
  setFilteredImmunizationRecords,
}) => {

  const [filterValue, setFilterValue] = useState("");
  const [selectedFilterBadge, setSelectedFilterBadge] = useState(null);

  const resetFilters = () => {
    setFilterValue("");
    setSelectedFilterBadge(null);
  };

  const filterRecords = () => {
    if (!immunizationRecords) return;
    if (!filterValue && !selectedFilterBadge) return setFilteredImmunizationRecords(immunizationRecords);
    const filterKeyWords = filterValue ? filterValue.trim().split(" ").map(v => v.toLowerCase()) : [];
    if (selectedFilterBadge) filterKeyWords.push(selectedFilterBadge);
    const keysWithMatches = [];
    Object.entries(immunizationRecords).forEach(([k, v]) => {
      const immunizationRecordStatus = v["immunization_record_status"];
      const matchInRecord = filterKeyWords.some(keyWord => keyWord === immunizationRecordStatus);
      if (matchInRecord) keysWithMatches.push(k);
    })
    const filteredImmunizationRecords = Object.entries(immunizationRecords).filter(([k, v]) => keysWithMatches.includes(k));
    setFilteredImmunizationRecords(Object.fromEntries(filteredImmunizationRecords));
  };

  useEffect(() => {
    filterRecords();
  }, [filterValue, selectedFilterBadge]);

  useEffect(() => {
    resetFilters();
  }, [immunizationRecords]);

  return (
    <Card className="bg-light p-4 my-3">
      <div className="d-flex" style={{alignItems: "center"}}>
        <span className="my-2 me-2"><b>Filter by</b></span>
        {IMMUNIZATION_FILTERS.filter((f) => {
          return allowNonMedicalExemptions || f.value !== "non_medically_exempt";
        }).map((f) => {
          return (
            <span className='my-2' key={f.value}>
              <FilterBadge
                value={f.value}
                label={f.label}
                isSelected={f.value === selectedFilterBadge}
                onClick={() => {
                  f.value === selectedFilterBadge
                    ? setSelectedFilterBadge(null)
                    : setSelectedFilterBadge(f.value);
                }}
              />
            </span>
          )
        })}
      </div>
      <FloatingLabelInput
        value={filterValue}
        label='Search immunization'
        className="mt-3"
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
      />
    </Card>
  )
};

export default ImmunizationsFilter;
