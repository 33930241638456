import React from 'react';

const Shield = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M0.166 3.48522C3.06114 3.45004 5.84481 2.3893 8 0.5C10.155 2.38965 12.9387 3.45075 15.834 3.4862C15.944 4.12135 16 4.77605 16 5.44149C16 10.5471 12.66 14.8906 8 16.5C3.34 14.8896 0 10.5462 0 5.44052C0 4.77409 0.0569999 4.12135 0.166 3.48522Z"
    />
  </svg>
);

export default Shield;
