export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Buwan",
    "day": "Araw",
    "year": "Taon",
    "january": "Enero",
    "february": "Pebrero",
    "march": "Marso",
    "april": "Abril",
    "may": "Mayo",
    "june": "Hunyo",
    "july": "Hulyo",
    "august": "Agosto",
    "september": "Setyembre",
    "october": "Oktubre",
    "november": "Nobyembre",
    "december": "Disyembre"
  },
  "user": {
    "send_appointment_confirmation_message": "Kumpirmado ang appointment para kay {{name}}.",
    "landline_appointment_reminder_message": "Hello, {{full_name}}. Paalala ito sa inyong appointment sa  {{name}}. Ang inyong appointment ay sa  {{date}} ng {{time}} sa {{address}}.",
    "send_at_home_visit_confirmation_message": "Kumpirmado ang appointment mo sa {{date}} sa oras na {{time}}.",
    "send_mail_order_confirmation_message": "Kumpirmado ang mail order mo.",
    "send_waitlist_message": "Naka-waitlist ka sa {{name}}. Mangyaring huwag pumunta hanggang may kumpirmadong oras ng appointment.",
    "verify_contact": {
      "phone": "Pakikumpirma ang numero ng telepono sa pamamagitan ng pag-click sa: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Ipagpatuloy ang pagrehistro ng appointment dito: {{url}}",
    "on_demand_confirmation_message": "Tagumpay ang pagpaparehistro mo para kay {{name}}.",
    "appointment_reminder": "Bago ang inyong appointment sa {{start_time}}, paki-click ang link upang makita ang hindi pa naaksiyonang aytem at upang makita ang kumpirmasyon. Magtungo 15 minuto bago ang appointment.",
    "appointment_reminder_base": "{{text}} I-CLICK ITO: {{link}}",
    "test_result_notifier": {
      "text_message": "Ang portal ng pasyente para sa {{first_name_with_last_initial}} ay na-update. Pumunta sa {{url}} at gamitin ang code: {{access_code}}",
      "email_subject": "Mga bagong update sa iyong portal ng pasyente",
      "email_intro": "Kamusta {{first_name_with_last_initial}}",
      "email_p1": "Isang bagong mensahe o update ng pasyente ang available para sa iyo.",
      "email_p2": "I-click ang button sa ibaba o gamitin ang link upang tingnan ang iyong portal ng pasyente at gamitin ang code",
      "email_p3": "Tingnan ang portal ng pasyente sa:'",
      "email_button": "Tingnan ang portal"
    },
    "landline_appointment_reminder_message_without_time": "Hello, {{full_name}}. Paalala ito sa inyong appointment sa  {{name}}. Ang inyong appointment ay sa {{date}} sa {{address}}.",
    "appointment_reminder_on_demand": "Bago ang inyong appointment sa {{date}}, paki-click ang link upang makita ang hindi pa naaksiyonang aytem at upang makita ang kumpirmasyon. ",
    "new_test_result_notifier": {
      "email_intro": "Na-update ang iyong portal ng pasyente.",
      "email_text_1": "Bisitahin ang iyong portal ng Medical History sa ibaba upang makita ang anumang mga bagong rekord ng pasyente o mga ulat sa lab.",
      "email_button": "Medical History",
      "email_text_2": "Maaari mo ring i-copy at paste ang URL na ito sa browser mo:"
    },
    "mailers": {
      "email_contact_us": "May tanong? Kontakin kami sa"
    }
  },
  "errors": {
    "generic": {
      "message": "May problema, subukan muli"
    },
    "messages": {
      "blank": "hindi dapat blangko"
    },
    "incorrect_credentials": "Mali ang mga kredensiyal mo, subukan ulit.",
    "error_activating_test_kit": "May error sa pag-activate sa test kit."
  },
  "type": "Tipo",
  "payment": {
    "continue_label": "Magpatuloy sa Pagbabayad",
    "policy_text": "Kung ikinansela o hindi ka nagtungo sa inyong appointment, magre-refund kami ng 80% ng inyong bayad 7 araw matapos ng petsa ng appointment. Maaari mong makita ang buong [Terms of Service]({{link}})",
    "card_number": "Card Number",
    "expiry_date": "Petsa ng expiration",
    "pay_button": "Magbayad",
    "no_credit_card": "Magbabayad ako sa site",
    "cash_payment_helper": "Tiyaking magdala ng **{{payment}}** sa inyong appointment",
    "invoice": "Resibo",
    "pay_by_card": "Magbayad gamit ang card",
    "cost": "Halaga",
    "total": "Kabuoan",
    "pay_cash_at_appointment": "Magbabayad ako nang cash sa aking appointment sa {{appointment}}",
    "view_invoice": "I-view/I-print ang Invoice",
    "refund_policy": "Pag kinansela mo ang appointment mo, ire-refund namin ang {{amount}} ng ibinayad mo, 7 araw pagkatapos ng petsa ng appointment mo. Maaaring tingnan ang buong [Terms of Service]({{link}})",
    "refund_window_passed": "Di maaari ang refund para sa appointment mo dahil nakalipas na ang petsa ng appointment. Kausapin ang mga koordineytor sa site para sa anumang resolusyon.",
    "amount_paid": "Halagang Binayaran",
    "balance": "Balanse"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Mag-upload ng waiver ng pahintulot o lumagda sa kahon sa ibaba",
    "stop": "Kinakailangan ng Pahintulot upang Makompleto ang Rehistrasyon",
    "parent_or_guardian_required_html": "Upang matapos ang rehistrasyon, kinakailangang magbigay ng pahintulot ang inyong magulang, guardian, o awtorisadong tagapagpasiya sa form sa ibaba at lumagda. <b>HINDI</b> ka makapagbibigay ng pahintulot nang mag-isa.",
    "send_parent_or_guardian": "Maaari mong ipadala sa inyong magulang o guardian ang link na ito o palagdaan sa kanila sa pahinang ito.",
    "consent_guardian_name_signature": "Unang Pangalan",
    "consent_for_minor": "Lagda ng pahintulot para sa menor de edad",
    "guardian_signature": "Lagda ng magulang, guardian, o awtorisadong tagapagpasiyang medikal",
    "after_you_schedule": "Ang mga kalahok na {{age}} pababa ay kinakailangang may nilagdaang pahintulot ng magulang o guardian. Mangyaring magpatuloy sa dulo ng rehistrasyon upang maipadala sa inyong guardian o lumagda para sa inyong dependent.",
    "consent_guardian_last_name_signature": "Apelyido",
    "consent_first_and_last_name": "Unang Pangalan at Apelyido",
    "phone_number": "Numero ng telepono ng tagapangalaga",
    "email": "Email ng tagapangalaga",
    "consent_registrar_with_guardian_name_signature": "Pangalan ng tagapag-alaga",
    "consent_registrar_with_guardian_last_name_signature": "Apelyido ng tagapag-alaga",
    "consent_registrar_first_name_signature": "Pangalan ng tagatala",
    "consent_registrar_last_name_signature": "Apelyido ng tagatala",
    "consent_on_behalf": "Pumipirma ako sa ngalan ni {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "Pasalita akong naawtorisa ni {{first_name}} {{last_name}} na pumirma sa ngalan niya.",
    "consent_on_behalf_registrar_with_guardian": "Pasalita akong naawtorisa ng tagapag-alaga ni {{first_name}} {{last_name}} na pumirma sa ngalan niya.",
    "registrar_phone": "Numero ng telepono ng registrar",
    "registrar_email": "Email ng registrar",
    "consented_by_parent_guardian": "Magulang / Tagapangalaga",
    "consented_by_decision_maker": "Awtorisado sa Pagdedesisyong-medikal",
    "consented_by_guardian_verbal": "Registrar Kasama ang Tagapangalaga (may pasalitang pagpayag)",
    "consented_by_registrar": "Registrar (may pasalitang pagpayag)",
    "full_name": "Buong pangalan",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Pangalan at Address",
    "contact_information_additional": "Kontak ng Magulang o Guardian para sa Komunikasyon ng Appointment at Resulta",
    "personal_information": "Demograpikong impormasyon",
    "address_placeholder": "Mangyaring ilagay ang inyong adres sa bahay",
    "first_name_label": "Unang Pangalan",
    "last_name_label": "Apelyido",
    "errors": {
      "state": "Ang estado ay kinakailangang valid na 2-character state code",
      "phone_number_invalid": "Invalid ang numero ng telepono",
      "required": "Kinakailangan ang value na ito",
      "email_domain": "Invalid ang entri mo; mangyaring i-double-check. Limitado ang rehistrasyon sa mga kalahok na pinili ng mga isponsor na organisasyon. Mangyaring gamitin ang email sa trabaho o komunidad. Kung sa tingin mo ay elihible ka at may mali, mangyaring ikontak ang support.",
      "age_requirement": "Hindi naabot ang kahingiang edad ayon sa araw ng kapanganakan",
      "signature_required": "KInakailangan ang lagda",
      "regex_invalid": "Tila invalid ang value na ito",
      "date_invalid": "Hindi umiiral ang petsang ito",
      "invalid_entry": "Mangyaring huwag magpatuloy sa rehistrasyon. Sundin ang panuto sa iskrin o tawagan ang inyong kontak.",
      "city": "Kinakailangan ang valid na lungsod",
      "survey_unanswered": "Tiyakin na lahat ng mga tanong na may * ay nasagutan.",
      "postal_code": "Kailangang valid na 5-dihitong postal code",
      "field_required": "{{field}} ay kailangang may value",
      "option_required": "Pumili ng isa sa mga opsiyon",
      "year_length_invalid": "Ilagay ang petsa ng kapanganakan nang apat na digit ang taon (tttt)",
      "invalid_day": "Ang araw dapat ay nasa pagitan ng 1 hanggang 31",
      "reached_max_chars": "Naabot mo na ang pinapayagang bilang ng karakter.",
      "chars_max": "Limit ng pinapayagang bilang ng karakter",
      "minimum_length": "Dapat may habang {{length}} karakter ang input."
    },
    "insurance_status": {
      "question": "May health insurance ka ba?",
      "have_health_insurance": "Oo, may health insurance ako.",
      "do_not_have_health_insurance": "Wala akong health insurance."
    },
    "insurance_policy_holder": {
      "question": "Sino ang policy holder?",
      "i_am": "Ako",
      "my_spouse": "Asawa o partner ko",
      "my_parents": "Mga magulang ko",
      "someone_else": "Ibang tao",
      "policy_first_name": "Unang Pangalan ng Policy Holder",
      "policy_last_name": "Apelyido ng Policy Holder",
      "policy_dob": "Araw ng Kapanganakan ng Policy Holder",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "Impormasyon sa Insurance",
      "company_name": "Pangalan ng Insurance Company",
      "id_number": "Member Identification Number",
      "group_number": "Member Group Number",
      "secondary_insurance_label": "May secondary insurance ako",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "Empleo",
      "address_1": "Adres ng kompanya",
      "address_2": "Hal: Suite 200",
      "phone_number": "Numero ng Kompanya",
      "company": "Pangalan ng Kompanya",
      "postal_code": "Postal Code ng Employer",
      "employed": "May-trabaho",
      "sole_prorietor": "Tanging May-ari",
      "not_employed": "Walang trabaho",
      "reporting_supervisor": "Superbisor na nag-uulat",
      "reporting_department": "Departamentong nag-uulat",
      "supervisor_name": "Pangalan ng supervisor",
      "supervisor_email": "Email ng supervisor"
    },
    "location_availability": "Priyoridad ang testing sa mga kasapi ng komunidad na naninirahan sa mga area na ito",
    "custom_survey": "Talatanungan",
    "confirmation": "Iribyu",
    "waitlisted": "Naka-waitlist",
    "schedule_your_appointment": "Iiskedyul ang Appointment",
    "information": "Impormasyon",
    "consent": "Pahintulot",
    "location": "Lokasyon",
    "symptoms": "Mga sintomas",
    "address_required": "Pakilagay ang inyong adres",
    "consent_required": "Pahintulot para magpatuloy",
    "required_field": "tumutukoy sa kinakailangang field",
    "phone_number": "Numero ng mobile phone",
    "email": "email",
    "date_of_birth": "Petsa ng Kapanganakan",
    "minimum_age": "Ang minimum na edad ng kalahok ay {{year}} taon.",
    "no_minimum_age": "Walang minimum na edad para sa testing",
    "continue_button": "Magpatuloy",
    "email_required": "Ang value na ito ay isang valid na email.",
    "done": "Tapos na",
    "signature": "Lagda",
    "clear_button": "Burahin",
    "back_button": "Bumalik",
    "choose_location": "Pumili ng Lokasyon",
    "no_slots": "Wala nang slot sa {{location}}",
    "choose_appointment": "Pumili ng oras ng appointment sa {{location}}",
    "appointment_required": "Pumili ng oras ng appointment",
    "phone_number_required": "Invalid ang oras ng appointment",
    "phone_number_label": "Maglagay ng numero na tatanggap text message para sa mas mabilis na test result",
    "symptoms_experiencing": "I-click ang mga sintomas na nararanasan. Kung walang sintomas, magpatuloy.",
    "household": "Kasapi ng Household",
    "household_p1": "Opsiyonal na maaaring lumikha ng appointment para sa mga kasapi ng household na kailangan ng testing.",
    "add_dependent": "Magdagdag ng kasapi ng household",
    "remove_dependent": "Tanggalin ang dependent",
    "dependent_consents": "Mga pahintulot",
    "submit": "Kompleto",
    "add_waitlist": "Idagdag sa waitlist",
    "address": "Adres ng Bahay",
    "address_1": "Adres Linya 1",
    "address_2": "Apt/Suite #",
    "address_city": "Lungsod",
    "address_state": "Estado",
    "postal_code": "Postal Code ng Employer",
    "race_ethnicity": "Etnisidad",
    "gender": "Kasarian",
    "self_described_gender": "Kasarian (mo ayon sa sariling pananaw)",
    "interpreter": "Kailangan ng interpreter? Kung oo, anong wika?",
    "consent_to_terms": "Pinahihintulutan ko ang mga kondisyong ito.",
    "reg_not_open": "Hindi pa bukas ang rehistrasyon",
    "no_more_avail_spots": "Hindi na available ang piniling oras. Subukan muli.",
    "consent_helper": "I-click at drag ang mouse o daliri sa kahon upang lumagda",
    "phone_number_unreachable_label": "Telepono?",
    "select": "Piliin",
    "test_surveys": "Mga Tanong sa Appointment",
    "edit": "iedit",
    "continue_to_registration": "Magpatuloy sa Rehistrasyon",
    "accounts": {
      "already_have_an_account": "May account na?",
      "log_in": "Login",
      "sign_up": "Mag-sign up",
      "sign_up_description": "Mangyaring punan ng inyong impormasyon upang malikha ang account at makatipid sa oras sa susunod na mga rehistrasyon.",
      "log_in_description": "Mangyaring punan ng inyong email at password, o mag-sign in gamit ang Google o Outlook sa ibaba",
      "sign_in_with_label": "Mag-sign in gamit ang {{provider}}",
      "password_must_match": "Kinakailangang mag-match ng passwords",
      "password_uppercase": "Kinakailangang may hindi bababa sa (%s) uppercase letter ang inyong password",
      "password_lowercase": "Kinakailangang may hindi bababa sa (%s) lowercase letter ang inyong password",
      "password_number": "Kinakailangang may hindi bababa sa (%s) numero ang inyong password",
      "password_special": "Kinakailangang may hindi bababa sa (%s) special character ang inyong password"
    },
    "password": "Pasword",
    "password_confirmation": "Kumpirmasyon sa Password",
    "consent_for": "Kumpirmasyon para kay {{name}}",
    "book_one_time_appointment": "Mag-book ng isang beses na appointment",
    "duplicate_users": {
      "exists": "Rehistrado ka na",
      "overlapping_email_and_phone_p1": "Nagpadala na kami ng mensahe sa inyong numero at email adres.",
      "p2": "Gamit ang mensahe, maaari kayong magbago ng appointment o magpasok ng resulta ng test.",
      "overlapping_phone_and_email_p3": "Mangyaring itsek ang inyong email o numero para sa mga detalye.",
      "overlapping_email_p3": "Mangyaring itsek ang inyong email para sa mga detalye.",
      "overlapping_phone_p3": "Mangyaring itsek ang inyong numero para sa mga detalye.",
      "overlapping_phone_p1": "Nagpadala na kami ng mensahe sa inyong numero.",
      "overlapping_email_p1": "Nagpadala na kami ng mensahe sa inyong email adres.",
      "p4": "Kung sa tingin mo ay may mali, ikontak kami sa support@primary.health",
      "overlapping_email_and_phone_p3": "Mangyaring itsek ang inyong email para sa mga detalye."
    },
    "duplicate_waitlist": {
      "exists": "Waitlisted ka na",
      "overlapping_email_and_phone_p1": "Nagpadala kami ng isa pang kumpirmasyon sa inyong numero at email adres.",
      "p2": "Gamit ang kumpirmasyon, maaari kang maglagay ng dagdag na impormasyon o tanggalin ang sarili sa waitlist.",
      "overlapping_phone_and_email_p3": "Mangyaring itsek ang inyong email o numero para sa mga detalye.",
      "overlapping_email_p3": "Mangyaring itsek ang inyong email para sa mga detalye.",
      "overlapping_phone_p3": "Mangyaring itsek ang inyong numero para sa mga detalye.",
      "overlapping_email_p1": "Nagpadala kami ng isa pang kumpirmasyon sa inyong email."
    },
    "insist_guardian_consent": "Nagbibigay ako ng pahintulot para kay",
    "confirmation_section": {
      "title": "Iribyu at ikumpirma",
      "p1": "Mangyaring iribyu ang impormasyon bago kompletuhin ang rehistrasyon.",
      "edit_information": "Bumalik at mag-edit"
    },
    "iemodal": {
      "title": "Di-suportado ang browser",
      "body": "Maaaring isagawa ang rehistrasyon sa sumusunod na browsers"
    },
    "show_other_locations": "I-click para maipakita ang ibang lokasyon",
    "non_us_address": "International address ito",
    "test_group_user_survey": "Screening ng Kalahok",
    "self_consent": "Nagbibigay ako ng pahintulot para sa sarili",
    "address_country": "Bansa",
    "appointment": "Appointment",
    "employee_id": "ID ng empleo",
    "appointment_time": "Oras ng appointment",
    "appointment_location": "Lokasyon",
    "phone_or_email": "numero ng phone o email",
    "no_self_consent": "Itinatanggi ko ang permiso para sa sarili ko",
    "no_insist_guardian_consent": "Itinatanggi ko ang permiso para kay {{full_name}}",
    "additional_consents_helpertext": "Maaaring humingi pa ng adisyonal na permiso",
    "minimum_age_with_months": "Ang pinakamababang edad para lumahok ay {{year}} taon at {{month}} buwang gulang.",
    "assistive_technology": "Teknolohiyang Nakatutulong",
    "covid_vaccine_y_n": "Gusto mo bang magsama ng bakuna para sa COVID-19 sa booking na ito?",
    "received_covid_vaccine_y_n": "Nagpaturok ka na ba ng bakuna para sa COVID-19?",
    "covid_vaccinations": "Pagbabakuna para sa Covid-19",
    "select_vaccine_dose": "Piliin ang bakunang gusto mong matanggap",
    "demographic_info": "demograpikong impormasyon",
    "additional_info": "Karagdagang impormasyon",
    "self_described_race": "Lahi Base sa Sarili",
    "verify_contact_information": "Beripikasyon",
    "verify_contact": {
      "we_sent_you_a_code": "Ilagay ang code na pinadala namin sa {{contact}}",
      "confirmation_code": "Confirmation code",
      "code_resent": {
        "email": "Panibagong code ang pinadala sa iyong email",
        "phone_number": "Panibagong code ang pinadala sa iyong telepono"
      },
      "did_not_receive_code": "Hindi nakuha ang confirmation code?",
      "verify_by": {
        "email": "I-verify gamit ang email",
        "phone_number": "I-verify gamit ang phone number"
      }
    },
    "preferred_method_of_communication": {
      "question": "Gustong paraan ng komunikasyon",
      "helper_text": "Kokontakin ka namin para sa balita sa appointment",
      "phone_number": "Telepono (SMS lang at maaaring may bayad para sa data)",
      "landline": "Di namin suportado ang mga landline sa ngayon. Ibigay ang email para sa mga mahalagang mensahe.",
      "verification_method": "Saan mo gustong matanggap ang authentication code?",
      "verification_helper_text": "Padadalhan ka ng code para maberika ang pagkakakilanlan mo bago maka-log in."
    },
    "skip_for_now": "I-skip sa ngayon",
    "default_information": {
      "title": "Sige, gagamitin namin ang address na ito",
      "title_v2": "Sige, gagamitin namin ang impormasyong pang-kontak na ito"
    },
    "middle_name_label": "Gitnang pangalan",
    "confirm_appointment": "Kumpirmahin ang appointment",
    "dob": "Araw ng kapanganakan",
    "different_appointment": "Ibang appointment",
    "select_appointment_time": "Pumili ng oras ng appointment",
    "decline_address": "Ayaw kong magbigay ng address. Address ng programa ang gamitin.",
    "patient_information": "Impormasyong ukol sa pasyente",
    "scan_license": "I-scan ang lisensiyang pandrayber",
    "how_to_contact": "Paano ka namin kokontakin?",
    "decline_email_or_phone": "Ayaw kong magbigay ng email o numero ng telepono. Pinahihintulutan ko ang mga administrador ng programa na tanggapin ang mga resulta ko.",
    "medical_screening": "Medikal na iskrining",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "opsiyonal",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Lalaki",
    "female": "Babae",
    "cisgender": "Cisgender",
    "non_binary": "Genderqueer o Non-binary",
    "other": "Iba pa",
    "prefer_to_self_describe": "Hindi naitala ang identidad",
    "prefer_not_to_disclose": "Nais hindi sabihin",
    "transgender_male": "Trans Male/ Transman",
    "transgender_female": "Trans Female/ Transwoman",
    "unknown": "Di-matukoy",
    "non_binary_only": "Di baynari",
    "intersex": "Interseks",
    "transgender": "Transgender",
    "gender_fluid": "Kasarian na umaagos",
    "not_applicable": "Hindi naaangkop"
  },
  "ethnicity": {
    "american_indian_alaska_native": "American Indian o Alaska Native",
    "american_indian_central_america": "American Indian mula South o Central America",
    "asian": "Asyano",
    "black": "Black o African American",
    "latinx": "Latino, Latinx o Hispaniko",
    "middle_eastern_north_african": "Middle Eastern o North African",
    "native_hawaiian_pacific_islander": "Native Hawaiian o Pacific Islander",
    "white_european": "White o Caucasian",
    "unknown": "Di-matukoy",
    "prefer_not_to_disclose": "Nais hindi sabihin",
    "asian_indian": "Asyanong Indiano",
    "filipino": "Pilipino",
    "japanese": "Hapon",
    "korean": "Koreyano",
    "vietnamese": "Taga-Vietnam",
    "other_asian": "Iba pang Asyano",
    "native_hawaiian": "Katutubong Hawaiian",
    "guamanian_or_chamorro": "Guamanian o Chamorro",
    "samoan": "Taga-Samoa",
    "other_pacific_islander": "Iba pang Pacific Islander",
    "chinese": "Tsino",
    "help": "Tumutukoy ang lipi sa set ng pisikal na mga katangian na ginagamit upang tukuyin ang pagkakaiba ng mga pangkat ng tao. Maaaring hindi tugma sa nasyonalidad ang etnisidad, o sa bansa na inyong kinabibilangan.",
    "subtitle": "Hinihingi ng mga regulasyon na kolektahin namin ang sumusunod na impormasyon",
    "laotian": "Taga-Laos",
    "cambodian": "Taga-Cambodia",
    "other": "Iba pa",
    "bangladeshi": "Bangladeshi",
    "hmong": "Hmong",
    "indonesian": "Indones",
    "malaysian": "Malay",
    "pakistani": "Pakistani",
    "sri_lankan": "Sri Lankan",
    "thai": "Thai",
    "taiwanese": "Taiwanese",
    "fijian": "Fijian",
    "guamanian": "Guamanian",
    "tongan": "Tongan"
  },
  "languages": {
    "en": "Ingles",
    "fr": "French",
    "es": "Spanish",
    "so": "Soomaali",
    "hmn": "Hmong",
    "asl": "American Sign Language (ASL)",
    "kar": "Karen",
    "am": "Amharic",
    "ru": "русский",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Lagnat o panginginig",
    "cough": "Ubo",
    "muscle_aches": "Pananakit ng mga kalamnan",
    "severe_fatigue": "Labis na pagkahapo (higit kaysa karaniwan)",
    "trouble_breathing": "Hirap sa paghinga",
    "diarrhea": "Pagtatae",
    "loss_of_smell": "Kawalan ng pang-amoy",
    "loss_of_taste": "Kawalan ng panlasa",
    "shortness_of_breath": "Hirap sa paghinga",
    "headache": "Pananakit ng ulo",
    "sore_throat": "Namamagang lalamunan (sore throat)",
    "congestion": "Sipon",
    "nausea": "Pagkahilo o pagsusuka",
    "close_contact": "Close contact sa isang taong may impeksiyon*",
    "helper_text": {
      "close_contact": "*Naging close contact (sa loob ng 6 talampakan nang mas matagal sa 15 minuto) ka ba ng kumpirmadong kaso ng COVID-19?"
    },
    "suspected_exposure": "Suspected na exposure",
    "none": "Walang sintomas"
  },
  "instructions": {
    "title": "Nakaenrol ka na ngunit bago magpatuloy...",
    "p1": "Naglalaman ang pahinang ito ng impormasyon tungkol sa appointment maging ng inyong appointment barcode",
    "appointment_scheduled": "Nakaiskedyul na ang inyong appointment",
    "verify_info": {
      "title": "I-verify ang Inyong Impormasyon",
      "p1": "Mahalagang i-validate ang inyong contact information para mabilis na maakses ang portal ng pasyente.",
      "p2": "Naipadala na ang kumpirmasyon sa inyong email. Mangyaring i-click ang link.",
      "p3": "Kung kailangang baguhin ang contact information, mangyaring pindutin ang help button."
    },
    "verified": "Na-verify",
    "not_verified": "Hindi pa na-verify",
    "resend_text": "Walang natanggap na link?",
    "resend": "Ipadala muli",
    "skip": "I-skip ang Balidasyon at Ipakita ang Kumpirmasyon",
    "name": "Pangalan",
    "appointment_details": "Appointment",
    "date_and_time": "Petsa at Oras",
    "add_to_calendar": "Idagdag sa Calendar",
    "instructions": "Pangkalahatang Panuto",
    "successfully_flash": "Nakalikha na ng appointment!",
    "success_flash_email": "Nai-verify na ang email",
    "success_flash_phone": "Bai-verify na ang numero ng telepono",
    "mail_order": "Matatanggap mo ang test-kit sa pamamagitan ng koreo sa linggong ito.",
    "at_home": "Isang medical provider ang magtutungo sa inyong bahay sa mga oras na ito",
    "at_home_instructions": "Panuto sa Bahay",
    "mail_order_instructions": "Panuto sa Koreo",
    "request_additional_appointment": "Mag-book ng follow-up appointment",
    "book_additional_appointment": "Mag-book ng Ibang Appointment",
    "confirmation_code": "Confirmation Code",
    "completed": "Nakompleto",
    "appointment_barcode": "Appointment Barcode",
    "dependent_links": "Mga Link sa Household Appointment",
    "on_demand_title": "Nakaenrol ka na ngunit bago magpatuloy...",
    "save_link": "I-save ang link na ito",
    "verify_phone": "I-verify ang numero ng telepono",
    "verify_phone_description": "Mahalaga na mabalida ang inyong contact information upang mabilis na maakses ang inyong rekords. Naipadala ang isang verification text message sa inyong numero.",
    "verify_email_description": "Mahalaga na mabalida ang inyong contact information upang mabilis na maakses ang inyong rekords. Naipadala ang isang verification email sa inyong email adres.",
    "information": "Impormasyon ng Rehistrasyon",
    "follow_up_appointment": "I-follow up ang Appointment",
    "get_directions": "Kumuha ng Direksiyon",
    "cancel": "I-cancel ang appointment",
    "reschedule_appointment": "Mag-reschedule ng appointment",
    "reschedule_linked_appointments": "I-reschedule ang Appointment",
    "no_slots": "Wala nang oras na available",
    "check_results": "I-check ang Patient Portal",
    "follow_up_modal_header": "Mangyaring Iiskedyul ang inyong 2nd Dose",
    "are_you_sure_you_want_to_cancel": "Tiyak ka bang ikakansela ang appointment?",
    "please_choose_cancellation_reason": "Pumili ng dahilan ng pagkansela sa ibaba",
    "additional_details": "Magdagdag ng ilan pang detalye sa ibaba",
    "errors": {
      "missing_cancellation_reason": "Mangyaring pumili ng dahilan ng pagkansela"
    },
    "verify_email": "I-verify ang email",
    "redcap_url": "I-click upang makompleto ang survey",
    "verify_contact_information": "I-verify ang Contact Information",
    "please_also": "Isagawa rin ang",
    "new_title": "Kumpirmasyon para kay {{name}}",
    "contact_method": "Paraan ng komunikasyon",
    "next_steps": {
      "title": "Mga susunod na hakbang",
      "p1": "May ia-activate kang test kit. Kapag handa ka na sa test, i-follow ang link na ipinadala sa email mo para makabalik sa page na ito at mai-activate ang kit mo sa ibaba.",
      "administered": "Kokontakin ka namin kapag nakarating sa lab ang kit mo at kapag handa na ang mga resulta mo. Madalas na 3-5 araw ang hihintayin pagkarating nito sa lab. Maaari mong makita ang mga resulta mo sa [Medical history]({{link}}) page kapag handa na.",
      "resulted": "Ang mga resulta ay makikita sa iyong [Medical history]({{link}}) page.",
      "shipping_information": "Impormasyong ukol sa pagpapadala"
    },
    "save_this_page": {
      "title": "I-save ang page na ito",
      "p1": "Gamitin ang page na ito para sa pag-report ng self test o para makita ang mga resulta sa medical history.",
      "p2": "Para ma-access ang page na ito sa hinaharap, i-click ang link sa kumpirmasyong email o SMS text.",
      "p3": "Maaari mong i-save ang page na ito sa pamamagitan ng pag-bookmark, pag-add nito sa home screen o pagkopya ng link sa ligtas na espasyo."
    },
    "show_my_barcode": "Ipakita ang barcode ko",
    "my_account": "Account ko",
    "register_another": "Magrehistro ng iba",
    "update_vaccine": "Mag-update ng impormasyon sa pagpapabakuna",
    "medical_history": "Medical history",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Invalid ang access code"
    },
    "landing": {
      "p1": "Upang mai-view ang resulta, ilagay:",
      "access_code_label": "Code para sa akses",
      "submit_button": "Isumite",
      "recent_results": "Pinakabagong test result"
    },
    "view_results": {
      "headline": "Test Results para kay {{name}}",
      "reregister": "Magrehistro muli",
      "status": {
        "likely_positive": "Di-matukoy ang resulta",
        "test_not_performed": "Kailangan ng retest",
        "results_ready": "Handa na ang resulta",
        "processing": "Pinoproseso",
        "test_status": "Istatus ng Test",
        "test_result": "Resulta ng Test",
        "administered": "Nag-administer",
        "results_pending": "Wala pang resulta",
        "test_results": "Mga resulta ng test"
      },
      "at": "sa {{address}}"
    },
    "result_label": "Resulta",
    "result": {
      "flu_a": {
        "result_label": "Resulta ng Flu A"
      },
      "flu_b": {
        "result_label": "Resulta ng Flu B"
      },
      "covid19": {
        "result_label": "Resulta ng COVID-19"
      },
      "covid": {
        "result_label": "Resulta ng COVID-19"
      },
      "sars": {
        "result_label": "Resulta ng SARS test"
      },
      "control": {
        "result_label": "Kontrol"
      },
      "rsv": {
        "result_label": "Resulta ng RSV"
      },
      "result": {
        "result_label": "Resulta"
      },
      "hba1c": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "lead_venous": {
        "danger": "Di normal",
        "normal": "Normal"
      },
      "tc": {
        "result_label": "Resulta sa TC"
      },
      "hdl": {
        "result_label": "Resulta sa HDL"
      },
      "trg": {
        "result_label": "Resulta sa TRG"
      },
      "ldl": {
        "result_label": "Resulta sa LDL"
      },
      "non_hdl": {
        "result_label": "Resulta sa non-HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "Tumbasang TC/HDL"
      },
      "glu": {
        "result_label": "Resulta sa GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "Nanganganib",
        "warning": "Di normal",
        "normal": "Normal"
      },
      "lead": {
        "result_label": "Lead"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Di normal",
        "normal": "Normal"
      },
      "creatinine": {
        "danger": "Di normal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "Normal",
        "warning": "Mataas",
        "danger": "Mataas",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "Prediyabetes",
        "diabetes": "Diyabetes"
      },
      "total_cholesterol_fasted": {
        "normal": "Normal",
        "elevated": "Mataas",
        "high": "Mataas",
        "low": "Mababa",
        "abnormal": "Hindi normal"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normal",
        "elevated": "Mataas",
        "high": "Mataas"
      },
      "a1c_now": {
        "normal": "Normal",
        "warning": "Pangamba ng Diabetes",
        "danger": "Diabetes"
      },
      "blood_glucose": {
        "warning": "Mababang",
        "normal": "Normal",
        "prediabetes": "Prediyabetis",
        "danger": "Diyabetis",
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "Medikal na emerhensiya",
        "inconclusive_1": "Hindi tiyak 1",
        "inconclusive_2": "Hindi tiyak 2",
        "possible_diabetes": "Posibleng diyabetes"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      },
      "blood_glucose_ucsf": {
        "low": "Mababang",
        "normal": "Normal",
        "high": "Mataas"
      }
    },
    "documents": "Mga dokumento",
    "self_administered": "Self-administered test na kinuha sa {{location}}",
    "patient": "Pasyente",
    "medical_history": "Medical History",
    "overview_title": "Pumili ng test o serbisyo para makita ang ibang detalye at iba pang kailangang gawin. Maaaring wala pa ang ilang resulta ng test o makikita lang matapos makausap ang provider.",
    "insurance_information": "Punan ng impormasyong ukol sa insurance",
    "contact_support": "Kung kailangan ng tulong, o para mapalitan ang detalyeng pangkomunikasyon, kontakin ang support.",
    "show": "Ipakita",
    "hide": "Itago",
    "lab_report": "Lab report",
    "contact_provider": {
      "header": "Positibo ang mga resulta mo",
      "description": "Gusto mo bang makipag-usap sa provider ukol ssa mga resulta at gamutan?",
      "yes_option_text": "Oo, gusto kong makipag-usap sa provider",
      "no_option_text": "Hindi, nabasa at nauunawaan ko ang mga resulta, at ayaw kong makipag-usap sa provider",
      "confirm_phone_number_header": "Kumpirmahin ang numero ng telepono mo",
      "confirm_phone_number_description": "Kumpirmahin ang pinakamainam na numero ng telepono para sa konsultasyon mo.",
      "confirm_button_text": "Kumpirmahin",
      "consultation_confirmed_header": "Kumpirmado ang konsultasyon",
      "consultation_confirmed_description": "Tatawagan ka ng provider sa {{phone_number}} sa loob ng 2-3 araw ng trabaho.",
      "acknowledgement_option_helper_text": "Nagpapaliwanag ng kahalagahan ng gamutan at ipinapaalam sa gumagamit kung paano mag-iskedyul ng tawag kapag nagbago ang isip.",
      "acknowledgement_confirmed_header": "Kumpirmadong natanggap",
      "acknowledgement_confirmed_description": "Tungkol sa kahalagahan ng gamutan at may mga naka-link na sanggunian. Libre ang konsultasyon at ang provider ay maaaring magreseta o anumang makatutulong sa paggamot sa sakit.",
      "acknowledgement_confirmed_change_mind_text": "Kapag nagbago ang isip mo, i-click lang ang \"Gusto ko ng konsultasyon\" sa ibaba.",
      "request_consultation_button_text": "Gusto ko ng konsultasyon"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Hindi nagtutugma ang inyong adres sa eligibility requirements ng mga lokasyon sa programang ito."
    }
  },
  "member": {
    "medical_history": "Kasaysayan",
    "view_instructions": "I-view ang Kumpirmasyon",
    "next_appointment": "Sunod na Appointment",
    "over_18": "Pinatutunayan kong 18 pataas na ako",
    "choose_test_configurations_title": "Mangyaring pumili ng (mga) serbisyo para sa appointment na ito",
    "member_taken_error": "May account na ang user",
    "choose_test_configurations_subtitle": "Ia-apply ang (mga) serbisyo sa lahat ng kasapi ng household",
    "service": "Serbisyo",
    "group_or_location": "Grupo/Lokasyon",
    "details": "Mga Detalye",
    "see_all": "Tingnan lahat",
    "no_history": "Walang maibibigay na rekord sa ngayon.",
    "medical_history_title_with_name": "Kasaysayan ni {{name}}",
    "no_dashboard": "Walang nahanap sa ngayon.",
    "product": "Produkto",
    "price": "Presyo",
    "quantity": "Bilang",
    "total_services_selected": "Suma total ng mga piniling serbisyo",
    "total_price": "Suma total ng presyo"
  },
  "or": "o",
  "account": {
    "errors": {
      "must_be_13": "Kailangang 13 taong gulang para makapagrehistro"
    }
  },
  "self_administration": {
    "title": "Self-Administration",
    "self_administer_action": "Magself-Administer",
    "skip_to_link": "I-skip sa Self-Administration",
    "select_person": "Pumili ng tao na itse-check out",
    "adult": "matanda",
    "child": "bata",
    "checked_out": "Naka-check out",
    "go_back": "Bumalik",
    "switch_to_scanner": "I-switch sa scanner",
    "enter_barcode": "Ilagay ang barcode",
    "scan_barcode": "I-scan ang barcode",
    "cancel": "Ikansela",
    "barcode_for": "Barcode para sa ",
    "enter_barcode_manually": "Itipa ang barcode ",
    "instructions": "I-scan o ilagay sa ibaba ang barcode na matatagpuan sa inyong test tube",
    "regex_prefix": "Kinakailangang magtaglay ang barcode ng ",
    "all_completed": "Nakompleto: Lahat ng kasama sa bahay ay tapos na sa hakbang na ito",
    "errors": {
      "no_appointment": "May problema sa paghahanap ng appointment",
      "barcode_in_use": "Nagamit na ang barcode na ito. Kung hindi ka pag nagself-administer gamit ang kit na ito, mangyaring kumausap ng staff sa site na nagbigay sa inyo ng kit para mabigyan ng bago. Kung hindi, i-click ang Help sa ibaba.",
      "test_error_general": "May problema sa paglikha ng test",
      "different_barcodes": "Hindi tugma ang inilagay na barcode"
    },
    "confirm_barcode_input": "Ilagay muli ang barcode para ikumpirma",
    "click_to_self_test": "I-click ito para magself-test"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Hintayan",
      "unavailable": "Walang available na appointment"
    },
    "labels": {
      "address": "Adres",
      "next_appointment": "Sunod na available appointment",
      "services": "Available na mga serbisyo"
    }
  },
  "waiting_room": {
    "header": "Nasa waiting room ka na; mangyaring maghintay habang ikonokonekta ka namin sa scheduling system.",
    "p1": "Hinihinging habaan ang inyong pasensiya. Sabay nating wawakasan ang pandemyang ito.",
    "signature": "Sumasainyo",
    "p2": "Batid naming nakababahala ang pagdanas ng COVID-19. Mangyaring maghintay habang mabilis naming tinutugunan upang mai-book sa site ang inyong appointment."
  },
  "users": {
    "send_appointment_confirmation_message": "Hello {{full_name}}. Paalala ito para sa inyong appointment {{name}}. Ang inyong appointment ay sa {{date}} ng {{time}} sa {{address}}.",
    "send_registration_link": "Hello, {{full_name}}. Sundan ang link na ito upang irehistro ang {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Unang dose",
    "previous_appointment": "Nakaraang Appointment",
    "booked_appointment": "Inyong Appointment",
    "second_dose": "Ikalawang dose",
    "choose_second_location": "Pumili ng Lokasyon ng Ikalawang Appointment"
  },
  "cancellation_types": {
    "cant_get_to_location": "May conflict sa iskedyul",
    "timing_conflict": "Nagkaroon ako ng COVID-19",
    "became_ill_with_covid19": "Tinanggap ko ang serbisyong ito sa ibang lugar",
    "received_service_elsewhere": "Iba pa",
    "other": "Sa halip i-type ang pangalan",
    "duplicate_appointment": "Duplicate na appointment ito"
  },
  "translation": {
    "consent": {
      "type_name_instead": "I-type ang pangalan",
      "type_name": "Maglagay ng naka-type na lagda"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Ang access code mo ay",
      "please_confirm_your_email": "Ikumpirma ang email sa pag-click ng button sa ibaba",
      "hello": "Kumusta",
      "click_to_reschedule": "I-click upang makita ang Panuto/Reschedule",
      "click_to_reschedule_on_demand": "I-click upang makita ang Panuto"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Ang appointment mo sa {{location}} sa oras na {{time}} ay kanselado. Kung pagkakamali ito, mag-email sa support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Pangalan ng Kalahok",
    "date": "Petsa",
    "signature": "Lagda ng Kalahok",
    "and_or": "AT/O",
    "guardian_name": "Pangalan ng Magulang/Guardian",
    "guardian_signature": "Lagda ng Magulang/Guardian"
  },
  "employer_testing": {
    "hello_name": "Kumusta {{name}}",
    "results": {
      "negative": "Negatibo",
      "positive": "Positibo",
      "did_not_result": "Di-matukoy",
      "unknown": "Di-kilala",
      "invalid": "Hindi valid",
      "pending": "Hindi pa tapos"
    },
    "code_reader": {
      "scan_your_code": "I-scan ang code sa test card",
      "find_in_top_right_hand_corner": "Walang katulad ang scan code sa inyong test at matatagpuan ito sa pinakaitaas na kanang bahagi ng test.",
      "center_code": "Igitna dito ang code.",
      "already_used_error": "Nagamit na ang barcode na ito. Mangyaring ikontak ang test kit provider para sa pagtugon.",
      "click_to_scan": "I-click para ma-scan ang QR code.",
      "scan_new_test_card": "I-click ito para ma-scan ang bagong test card.",
      "not_working": "Hindi gumagana?",
      "try_again": "I-click ito para subukan muli."
    },
    "continue": "Magpatuloy",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Mangyaring ikumpirma ang araw ng kapanganakan ng kalahok para magpatuloy",
      "contact_administrator": "Kung hindi wasto ang impormasyon, mangyaring ikontak ang administrator para sa assistance",
      "error": "Mali ang araw ng kapanganakan—mangyaring subukang muli o ikontak ang site administrator upang mai-update ito."
    },
    "result_entry": {
      "code_is_registered": "Nairehistro na ang inyong code.",
      "take_the_test": "Kunin ang test",
      "follow_the_instructions": "Sundin ang panuto na nasa test at simulan ang 15 minutong timer kapag naipasok na ang nasal swab sa vial ng testing card.",
      "cant_find_instructions": "Di mahanap ang mga panuto?",
      "view_instructions": "Tingnan ang Binax Now Instructions",
      "start_timer": "SIMULAN ANG 15-MINUTE TIMER (OPSIYONAL)",
      "submit_within_15": "Tip: Isumite ang resulta sa loob ng 15 minuto",
      "enter_test_results": "Ilagay ang Test Result",
      "choose_a_result": "Pumili ng opsiyon na naglalarawan ng resulta ng testing card",
      "submit": "Isumite",
      "must_log_result_and_photo": "Kailangang maglagay ng resulta at kumuha ng retrato ng test upang magpatuloy",
      "submit_within_15_v2": "Tip: Isumite ang resulta matapos ang 15 minuto",
      "click_to_take_photo": "I-click para kumuha ng retrato",
      "results_may_be_invalid": "Maaaring invalid ang test result"
    },
    "summary": {
      "title": "Buod ng mga Resulta",
      "negative_subtitle": "Wala kang COVID-19",
      "negative_message": "Nagpadala na kami ng pabatid sa inyong employer na maaari ka nang pumasok para makapagtrabaho.",
      "positive_subtitle": "Paumanhin, may COVID-19 ka",
      "positive_message": "Nagpadala na kami ng pabatid sa inyong employer na kayo ay may COVID-19.",
      "what_should_you_do": "Ano ang inyong gagawin:",
      "employer_name_resources": "Pangalan ng Employer:",
      "follow_cdc_guidelines": "Sundin ang Rekomendadong Alituntunin ng CDC"
    },
    "clarifying_results": {
      "title": "Klaripikasyon sa Resulta",
      "which_did_your_result_most_look_like": "Ano ang hitsura ng inyong resulta?",
      "no_lines": "Walang linyang makita",
      "blue_control_line_only": "Asul na control line lamang",
      "pink_sample_line_only": "Pink na sample line lamang",
      "blue_control_line_and_pink_sample_line": "Asul na control line AT pink/purple na sample line",
      "still_not_sure": "Di pa rin tiyak",
      "submit_results": "Isumite ang Resulta",
      "thank_you": "Salamat!",
      "clarification_received_message": "Natanggap ang klaripikasyon ng resulta.",
      "only_pink_line": "Tanging pink na linya",
      "no_lines_v2": "Walang linya"
    },
    "return_to_confirmation": "Bumalik sa pahina ng kumpirmasyon"
  },
  "preferred_language": {
    "title": "Nais na Wika",
    "subtitle": "Anong wika ang gusto mong gamitin?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Serye ng mga inisyal na bakuna",
    "one_dose": "Mag-book ng appointment para sa espesipikong dose. Ito ang aking",
    "title": "Pagpipiliang Dose",
    "first_dose": "Unang dose",
    "second_dose": "Ikalawang dose",
    "single_dose_title": "Single Dose",
    "additional": "Karagdagan",
    "booster": "Booster",
    "supplemental": "Supplemental",
    "third_dose": "Ikatlong dosis lamang",
    "no_vaccine": "Wala sa mga nauna"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "may format dapat na {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "may format dapat na {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "di mahanap ang test"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "may user ngunit di pinahihintulutang mag-update"
            },
            "date_of_birth": {
              "invalid_date": "may format dapat na {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "walang {{unknown_ethnicities}}, pumili sa sumusunod na values: {{ethnicities}}",
                "other": "walang {{unknown_ethnicities}}, pumili sa sumusunod na values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "hindi umiiral ang kasarian, pumili sa sumusunod na values:  {{genders}}"
            },
            "phone_number": {
              "invalid": "invalid ang numero"
            },
            "population_name": {
              "unknown_population": "Hindi umiiral ang {{unknown_population}}, pumili sa sumusunod na values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "hindi ito mapupunan kung pinili ang \"nais_ilarawan_ang_sarili\""
            },
            "sex": {
              "unknown_sex": "hindi umiiral ang seks, pumili sa sumusunod na values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "hindi umiiral ang oryentasyong seksuwal, pumili sa sumusunod na values: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "invalid ang istatus, pumili sa sumusunod na values: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "walang {{unknown_races}}, pumili sa sumusunod na values:  {{races}}",
                "other": "hindi umiiral ang {{unknown_races}}, pumili sa sumusunod na values:  {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "di-sapat ang \"privileges\" para maiset ang \"role\""
            },
            "user_id": {
              "unknown_user": "di mahanap ang user"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "invalid JSON"
            },
            "user_id": {
              "unknown_user": "di mahanap ang user"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "di mahanap ang lokasyon ng appointment"
            },
            "date_of_birth": {
              "invalid_date": "may format dapat na {{date_format}}"
            },
            "email": {
              "duplicated": "nagamit na"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "walang {{unknown_ethnicities}}, pumili sa sumusunod na values: {{ethnicities}}",
                "other": "walang {{unknown_ethnicities}}, pumili sa sumusunod na values: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "hindi umiiral ang kasarian, pumili sa sumusunod na values:  {{genders}}"
            },
            "phone_number": {
              "invalid": "invalid ang numero"
            },
            "population": {
              "unknown_population": "Hindi umiiral ang {{unknown_population}}, pumili sa sumusunod na values: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "hindi ito mapupunan kung pinili ang \"nais_ilarawan_ang_sarili\""
            },
            "sex": {
              "unknown_sex": "hindi umiiral ang seks, pumili sa sumusunod na values: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "hindi umiiral ang oryentasyong seksuwal, pumili sa sumusunod na values: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "walang {{unknown_races}}, pumili sa sumusunod na values:  {{races}}",
                "other": "hindi umiiral ang {{unknown_races}}, pumili sa sumusunod na values:  {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "at ang adres ay nagamit na sa ibang umiiral na Lokasyon"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Hispaniko o Latino/a",
    "not_hispanic": "Hindi Hispaniko o Latino/a",
    "hispanic_expanded": {
      "other": "Ibang Hispaniko, Latino/a, o Espanyol na pinagmulan",
      "mexican": "Mehikano, Amerikano, Chicano/a",
      "puerto_rican": "Taga-Puerto Rico",
      "cuban": "Cubano"
    },
    "subtitle": "Hinihingi ng mga regulasyon na kolektahin namin ang sumusunod na impormasyon",
    "help": "Tumutukoy ang etnisidad sa isang set ng komon na katangian gaya ng wika, kultural na gawi, relihiyon, at iba pang katangian na ginagamit upang mapag-iba-iba ang pangkat ng tao. Maaari o hindi maaari ito maging katulad ng liping kinabibilangan.",
    "unknown": "Di-matukoy",
    "title": "Etnisidad"
  },
  "sex_at_birth": {
    "question": "Seks",
    "female": "Babae",
    "male": "Lalaki",
    "help": "Tumutukoy ang seks sa nasa birth certificate",
    "non_binary": "Hindi baynari",
    "subtitle": "Hinihingi ng mga regulasyon na kolektahin namin ang sumusunod na impormasyon",
    "decline": "Tumangging sumagot",
    "unknown": "Di-matukoy"
  },
  "gender": {
    "help": "Tumutukoy ang identidad pangkasarian kung paano mo tinutukoy ang sarili. Maaaring maging ayon o hindi ito sa seks nang ikaw ay ipinanganak.",
    "subtitle": "Kung nais mong magdagdag ng impormasyon tungkol sa inyong identidad pangkasarian, maaari mong gawin."
  },
  "sexual_orientation": {
    "title": "Oryentasyong Seksuwal",
    "subtitle": "Kung nais mong magdagdag ng impormasyon tungkol sa inyong oryentasyong seksuwal, maaari mong gawin.",
    "gay": "Bakla, lesbiyana, o homosexual",
    "heterosexual": "Heterosexual o straight",
    "bisexual": "Bayseksuwal",
    "questioning": "Di-tiyak",
    "prefer_not_to_disclose": "Nais hindi sabihin",
    "unknown": "Di-matukoy",
    "orientation_not_listed": "Hindi naitala ang oryentasyon",
    "not_applicable": "Hindi naaangkop",
    "pansexual": "Panseksuwal",
    "queer": "Queer"
  },
  "pronouns": {
    "title": "Nais na pantawag",
    "he": "Siya (he/him)",
    "she": "Siya (she/her)",
    "they": "Sila",
    "choose_not_to_disclose": "Nais hindi sabihin",
    "prefer_to_self_describe": "Nais na ilarawan ang sarili"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Driver's License o State ID Number",
    "driver_license": "Driver's License",
    "use_ssn_instead": "Gamitin ang SSN",
    "social_security_number": "Social Security Number",
    "state": "Estado",
    "upload_front_of_driver_license": "I-upload ang harap ng inyong driver's license o state ID number",
    "choose_file": "Piliin ang file",
    "no_file_chosen": "Walang napiling file",
    "no_identification": "Wala akong ID",
    "insurance_card_has_a_back": "May likurang bahagi ang aking insurance card",
    "upload_front_of_insurance_card": "I-upload ang harap ng inyong insurance card.",
    "front_of_insurance_card_uploaded": "Nai-upload na ang harap ng inyong insurance card.",
    "insurance_card": "Insurance kard",
    "insurance_card_back": "Likod ng Insurance card",
    "back_of_insurance_card_uploaded": "Nai-upload na ang likod ng inyong insurance card."
  },
  "quidel": {
    "certify_text": "Pinatutunayan kong minsa lamang ilalagay ang resulta ng test na ito.",
    "entering_results": "Ilagay ang Resulta",
    "review_instructions_1": "Basahing Mabuti ang mga Panuto",
    "review_instructions_2": "Maaaring iribyu ang mga panuto na kasama ng test, basahin ang mga hakbang, o panoorin ang instructional video sa ibaba:",
    "read_instructions": "Basahin ang mga panuto.",
    "watch_video": "Panoorin ang video.",
    "timer": "Simulan ang timer matapos haluin ang swab sa tube. Titigil ang timer matapos ang 1 minuto (para s apagtanggal ng swab sa tube). Tandaan: Kailangang ituloy ang timer para magsimula ng 10 minuto para sa resulta ng test.",
    "start_timer": "Simulan ang Timer (opsiyonal)",
    "restart_timer": "Tumigil at Magsimula muli",
    "resume": "Magpatuloy",
    "one_minute_countdown": "Lumipas na ang 1 minuto! Tanggalin ang swab sa tube at ituloy ang timer.",
    "take_photo": "Retratuhan ang Inyong Test Strip",
    "photo_warning": "Kinakailangan ang retrato para magpatuloy.",
    "enter_results": "Ienter ang Resulta ng Test",
    "choose_result": "Piliin ang opsiyon na nagpapakita ng test strip. Kailangan ng tulong? I-click ito para makita ang mga halimbawang resulta.",
    "positive": "Positibo",
    "negative": "Negatibo",
    "unknown": "Di-matukoy",
    "clarify": "Paglilinaw sa Inyong Resulta",
    "strip_question": "Ano ang hitsura ng test strip mo?",
    "no_lines": "Walang linya",
    "pink_line": "Tanging pink na linya",
    "not_sure": "Hindi pa rin tiyak"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Retrato ng resulta",
    "retake_photo": "Ulitin ang retrato.",
    "capture_photo": "Kuhanan ng retrato ",
    "confirm_information_correct": "Kinokompirma kong wasto ang mga impormasyon sa itaas.",
    "submit": "Isumite",
    "title": "Ienter ang resulta para kay {{name}}",
    "subtitle": "Hindi mo kailangang i-download o gamitin ang iHealth COVID-19 app.",
    "instruction_title": "Tingnan ang iHealth instructions",
    "instruction_guide": "Basahin ang instructions.",
    "video_guide": "Panoorin ang video.",
    "add_photo": "Magdagdag ng retrato",
    "confirm_result": "Ikompirma ang resulta",
    "result_warning": "Pumili ng resulta upang isumite",
    "confirm_warning": "Ikompirma ang resulta upang isumite",
    "administered_at_label": "Kailan ka kumuha ng test na ito?",
    "instruction_subtitle": "Tingnan ang instructions na kasama ng iyong test o iribyu ang instructions dito."
  },
  "public_test_groups": {
    "title": "Mga COVID test kit",
    "appointment_recoveries_title": "Bumalik?",
    "appointment_recoveries_button_text": "Hanapin ang aking link",
    "search_test_group_title": "Unang beses?",
    "search_test_group_button_text": "Magrehistro ngayon",
    "title_2": "Simpleng rehistrasyon at pag-uulat",
    "step_one": "Rehistrasyon ng account",
    "step_two": "Kolektahin ang laway upang ipadala",
    "step_three": "Tingan ang resulta online",
    "title_3": "Magrehistro upang ma-activate ang iyong kit",
    "population_title": "Rehistrasyon ng account",
    "population_button": "i-activate",
    "faq_subtitle": "Aralin pa",
    "faq_title": "Madalas itanong",
    "faq_1": "Paano ko ibabalik ang aking test kit?",
    "faq_1_footer": "Tingnan ang panuto ng SalivaDirect Funnel Collection",
    "faq_2": "Nakapagrehistro na ako pero nakalimutan ang account.",
    "faq_3": "Paano ko ia-activate ang test kit?",
    "faq_2_subtitle": "Bilang bumalik na user, maaari mong ikompirma ang ulat gamit ang '**Hanapin ang aking link'** sa itaas.",
    "faq_3_subtitle": "Magtungo sa proseso ng Primary Health account gamit ang mga opsiyon sa ibaba:",
    "footer_text_1": "Hindi pa nakakukuha ang produktong ito ng clearance o aprobasyon sa FDA, ngunit may awtorisasyon sa emergency use ng FDA sa ilalim ng EUA.",
    "footer_text_2": "May awtorisasyon ang produktong ito para lamang sa koleksiyon at pagmamantene ng ispesimen ng laway bilang pantulong sa pagtukoy ng nucleic acid ng SARS-CoV-2, hindi para sa iba pang virus o patoheno.",
    "footer_text_3": "Ang emergency use ng produktong ito ay awtorisado lamang sa panahon ng deklarasyon na umiiral ang mga sirkunstansiya na nagmamatwid sa paggamit ng emergency use ng mga kagamitang medikal sa ilalim ng Seksiyon 564(b)(1) ng Federal Food, Drug and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1), o hanggang sa pagwawakas ng deklarasyon o napawalang-bisa ang awtorisasyon.",
    "description": "Para sa inyong kaginhawahan, maaari mong i-activate ang test kit gamit ang mga opsiyon sa ibaba.",
    "subtitle": "Unang beses sa Primary?",
    "subtitle_1": "Tungkol sa inyong COVID-19 test kits.",
    "faq_1_1": "Ilagay ang isinarang sample tube sa biospecimen bag.",
    "faq_1_2": "Ilagay ang biospecimen bag sa box na may tatak na SalivaDirect na kasama nitong dumating. Ilagay ang box na may tatak na SalivaDirect sa  FedEx UN 3373 Pak.",
    "faq_1_3": "Tanggalin ang adhesive liner ng FedEx UN 3373 Pak at pinduting mabuti upang maisara nang husto ang Pak.",
    "faq_1_4": "Mangyaring i-drop off ang inyong sample sa parehong araw na kinolekta ito at bago ang huling oras ng pick up. Huwag ideliver ang inyong sample sa drop box kung Sabado o Linggo.",
    "faq_1_5": "Bisitahin ang FedEx website sa **[fedex.com/labreturns](https://www.fedex.com/labreturns)** para makita ang lokasyon ng mga drop box at iskedyul ng pick up.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Mag-report ng resulta",
    "report_result_subtitle": "Kung nagbibigay ng self-testing ang inyong organisasyon, at kung handa ka nang mag-ulat ng resulta, paki-click ang button sa ibaba.",
    "need_help": "Kailangan ng tulong?",
    "assistance": "Kung kailangan ng tulong, o upang mapalitan ang inyong contact information, paki-contact ang Support Center",
    "contact_support": "I-contact ang Support",
    "save_link": "I-save ang link na ito upang maireport ang mga resulta sa susunod.",
    "send_via_email": "Ipadala ang link sa email",
    "send_via_text": "Ipadala ang link sa SMS text",
    "copy_link": "Kopyahin ang link",
    "resend_email": "Ipadala muli ang beripikasyon sa email",
    "phone_verified": "Naberipika ang phone",
    "add_vaccination_status": "Magdagdag/Mag-update ng COVID-19 Vaccination Status",
    "terms_of_service": "Termino ng serbisyo",
    "verify_contact_information": "Iberipika ang iyong contact information upang matanggap ang resulta at mga komunikasyon. Kung nagbago ka ng contact information, paki-contact ang ating Support Center.",
    "resend_text": "Ipadala muli ang beripikasyon sa SMS text",
    "loading": "Naglo-load",
    "add_to_account": "Idagdag sa Account",
    "hi": "Kumusta",
    "email_verified": "Naberipika ang email",
    "go_to_my_account": "Pumunta sa aking account",
    "activate_kit": "I-activate ang test kit",
    "report_custom_result": "Mag-ulat ng resulta: {{test}}",
    "activate_custom_test": "I-activate ang test: {{test}}",
    "update_vaccination_status": "Update sa vaccination status",
    "order_a_test_kit": "Mag-order ng test kit"
  },
  "enter_information": {
    "contact_info": "Pakilagay ang inyong contact information",
    "label": "Phone o email",
    "invalid": "Hindi ito valid na email o phone.",
    "send": "Ipadadala namin ang code sa phone o email",
    "legal": "Sa paglalagay ng inyong numero o email, sumasang-ayon ka sa  **[Terms of Service](https://primary.health/terms-of-service/)**  ng Primary Health at sa **[Privacy Policy](https://primary.health/privacy-policy/)**.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "Nakarehistro na? Hanapin ang iyong link",
    "not_registered": "Not Registered?",
    "search_by_keyword": "Hanapin ayon sa pangalan, keyword, siyudad, zip, o koda ng organisasyon!",
    "search": "Hanapin",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "Hindi valid ang impormasyon",
    "invalid_explanation": "Humingi ka ng sign-on link sa Primary.Health. Ngunit,",
    "never_registered": "Di nagrehistro kailanman? Makipag-ugnayan sa iyong testing provider para sa link ng rehistrasyon, o hanapin ang iyong site sa baba.",
    "get_help": "Maaaring humingi ng tulong sa pag-match ng inyong record sa contact information gamit ang",
    "get_help_calling": "o sa pagtawag sa ",
    "invalid_explanation_bold": "hindi valid ang ibinigay na impormasyon",
    "get_help_label": "Humingi ng tulong",
    "mobile": {
      "invalid_explanation": "Walang user na nahanap para idagdag sa iyong account",
      "get_help": "Kung may mga karagdagang tanong, maaring tumawag sa",
      "reason": "Hindi lahat ng Primary Health users ay maaring makagamit ng bagong Accounts feature. Kung ikaw ay kuwalipikado na, ikaw ay makakatanggap ng imbitasyon galing sa Primary Health o sa iyong group administrator."
    },
    "zen_name": "Pangalan ng kontak",
    "ticket_type": "Tungkol saan ito",
    "appointment_access_code": "Confirmation code (kung alam)",
    "zen_desc": "Mensahe",
    "contact_us": "Makipag-ugnayan sa amin",
    "need_assistance": "Kung kailangan ng tulong, o para mapalitan ang detalyeng pangkomunikasyon, kontakin ang support."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "Naidagdag sa account",
    "error": "Ang partisipanteng ito ay maaaring mayroon nang account",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "Ano ang code?",
    "enter_code": "Ilagay ang ipinadalang code",
    "incorrect_code": "Naglagay ka ng maling code.",
    "verify": "Iberipika ang account",
    "verified": "Naberipika",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "Anong test ang kinuha mo?",
    "photo_required": "Kinakailangan ang retrato ng resulta",
    "find_your_test": "Hanapin ang iyong test"
  },
  "login": {
    "create_account": "Lumikha ng account",
    "create_account_help": "Magkaiba ang paggawa ng account at pagpaparehistro para sa testing o bakuna. Pag rehistrado na",
    "create_account_help_schedule": "Mag-iskedyul ng appointment sa hinaharap",
    "create_account_help_record": "Mag-record ng COVID-19 at-home tests",
    "create_account_help_match": "Pangasiwaan ang pagpapabakuna at testing record.",
    "continue": "Magpatuloy bilang guest",
    "already": "May account na? I-click",
    "login": "para mag-login.",
    "or_sign_in": "O mag-sign in gamit ang:",
    "no_account": "Wala pang account? I-click",
    "signup": "para mag-signup.",
    "here": "dito",
    "email_address": "Email address:",
    "password": "Password:",
    "complete_registration": "Kompletuhin ang rehistrasyon",
    "last_covid_test": "Huling COVID-19 test result",
    "no_record": "Walang record",
    "viewing_information": "Tinitingnan ang impormasyon ni {{first_name}}",
    "download": "I-download",
    "not_registered": "Mukhang hindi ka rehistrado sa anumang grupo",
    "please_follow_link": "Sundan ang invite link sa inyong email upang mai-schedule at ma-access ang impormasyon sa Primary.Health.",
    "log_results": "I-log ang aking test results",
    "book_appointment": "Mag-book ng appointment",
    "no_new_appointments": "Kasalukuyang hindi tumatanggap ng bagong appointment",
    "upload_vaccinations": "I-upload ang mga pagpapabakuna",
    "vaccination": "pagpapabakuna",
    "new_appointment": "Bagong appointment",
    "log_otc_results": "I-log ang resulta para sa OTC test",
    "no_household_members": "Wala ka pang mga kasapi ng kabahayan.",
    "no_upcoming": "Walang nakaiskedyul na appointment",
    "update_vaccination": "I-update ang pagpapabakuna",
    "upload_vaccination": "I-upload ang pagpapabakuna",
    "title": "Maligayang pagdating sa Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Sa paglikha ng account, maaari kang:",
    "p2": "Mag-iskedyul ng appointment sa hinaharap",
    "p3": "Mag-record ng COVID-19 at-home tests",
    "p4": "Mangasiwa ng pagpapabakuna at testing record ng mga dependent",
    "p5": "Simpleng proseso ng login mula sa my.primary.health",
    "no_vaccination_record": "Wala pang naidadagdag na bakuna",
    "vaccination_title_card": "Istatus ng bakuna laban sa Covid-19",
    "account_and_settings": "Account at kompigurasyon",
    "delete_account": "Burahin ang account",
    "language": "Wika",
    "language_current": "Kasalukuyang wika: {{language}}",
    "log_out": "Mag-log out",
    "delete_account_title": "Sigurado ka bang gusto mong burahin ang iyong account?",
    "new_encounter": "Bagong miting ni {{first_name}}",
    "new_encounter_subtitle": "Para sa aling grupo ang bagong miting na ito?",
    "no_programs": "Mukhang hindi ka naka-enrol sa alinmang programa.",
    "edit_members": "I-edit ang mga miyembro",
    "edit_members_subtitle": "Piliin kung aling dashboard ang nais mong puntahan o magdagdag ng bagong miyembro sa iyoong account",
    "add_members": "Magdagdag ng miyembro",
    "delete_member": "Burahin ang account ni {{full_name}}?",
    "delete_member_subtitle": "Sa pagbubura ng user matatanggal lahat ng impormasyon na konektado sa account.",
    "select_member": "Pagpili ng miyembro",
    "edit_appointment": "I-edit ang appointment",
    "route": {
      "me": "Dashboard",
      "history": "Rekord",
      "programs": "Magdagdag ng test",
      "contact_us": "Tulong",
      "choose_member": "Miyembro",
      "settings": "Mga kompigurasyon",
      "help": "Tulong"
    },
    "user_dashboard": "Dashboard ni {{first_name}}",
    "view_full_results": "Tingnan sa portal ng mga resulta",
    "required": "Kinakailangan kung Primary App ang gamit mo.",
    "no_email": "Wala akong email address"
  },
  "vaccination_status": {
    "not_vaccinated": "Hindi bakunado",
    "fully_vaccinated": "Kompleto na ang bakuna",
    "partially_vaccinated": "Partial na nabakunahan",
    "vaccination_records": "Records sa pagkabakuna",
    "title": "Bakunado ka ba laban sa COVID-19?",
    "definition_title": "Mga depinisyong kaugnay sa pagiging bakunado",
    "definition_cdc": "Depinisyon ng pagiging bakunado ayon sa CDC",
    "definition_osha": "Depinisyon ng pagiging bakunado ayon  sa OSHA",
    "definition": {
      "fully_vaccinated": "2 linggo matapos ang huling dosis ng Pfizer-BioNTech o Moderna na may kahingiang 2 dosis, o 1 dosis ng bakuna ng Johnson & Johnson's Janssen.",
      "partially_vaccinated": "Nakatanggap ng 1 sa 2 dosis na pangunahing kahingian, o di hihigit sa 2 linggo matapos makatanggap ng huling dosis ng may kahingiang 2 dosis, o 1 dosis ng Johnson & Johnson's Janssen.",
      "not_vaccinated": "Di nabakunahan laban sa COVID-19.",
      "fully_vaccinated_with_one_booster": "Nakatanggap ng booster na dosis ng Pfizer-BioNTech o Moderna matapos ang pangunahing kahingiang 2 dosis o 1 dosis ng Johnson & Johnson's Janssen.",
      "fully_vaccinated_with_two_boosters": "Nakatanggap ng ikalawang booster na dosis ng Pfizer-BioNTech o Moderna matapos ang pangunahing kahingiang 2 dosis o 1 dosis ng Johnson & Johnson's Janssen at booster na dosis."
    },
    "label": {
      "fully_vaccinated": "Ganap na bakunado",
      "partially_vaccinated": "Di ganap na bakunado",
      "not_vaccinated": "Di bakunado",
      "fully_vaccinated_with_one_booster": "Ganap na bakunado + booster na dosis",
      "fully_vaccinated_with_two_boosters": "Ganap na bakunado + 2 booster na dosis",
      "prefer_not_to_answer": "Pinipiling di sumagot"
    },
    "progress_bar_title": "Tala ng pagpapabakuna laban sa COVID-19",
    "upload_record_card": "I-upload ang iyong tala ng pagpapabakuna laban sa COVID-19",
    "show_example": "Ipakita ang halimbawa",
    "hide_example": "Itago ang halimbawa",
    "take_photo": "I-click para kumuha ng larawan",
    "or": "OR",
    "upload_image_or_pdf_instead": "I-upload ang larawan/PDF na lang",
    "select_doses_received": "Piliin ang lahat ng dosis ng bakunang natanggap laban sa COVID-19.",
    "first_dose": "Unang dosis",
    "second_dose": "Ikalawang dosis",
    "first_booster_dose": "Unang booster na dosis",
    "second_booster_dose": "Ikalawang booster na dosis",
    "additional_dose": "Dagdag na dosis",
    "first_dose_manufacturer": "Ano ang kumpanyang gumawa ng unang dosis ng bakuna mo laban sa COVID-19?",
    "first_dose_date": "Ano ang petsa ng unang dosis mo?",
    "second_dose_manufacturer": "Ano ang kumpanyang gumawa ng ikalawang dosis ng bakuna mo laban sa COVID-19?",
    "second_dose_date": "Ano ang petsa ng ikalawang dosis mo?",
    "first_booster_dose_manufacturer": "Ano ang kumpanyang gumawa ng unang booster na dosis ng bakuna mo laban sa COVID-19?",
    "first_booster_dose_date": "Ano ang petsa ng unang booster na dosis mo?",
    "second_booster_dose_manufacturer": "Ano ang kumpanyang gumawa ng ikalawang booster na dosis mo laban sa COVID-19?",
    "second_booster_dose_date": "Ano ang petsa ng ikalawang booster na dosis mo?",
    "additional_dose_manufacturer": "Ano ang kumpanyang gumawa ng karagdagang dosis ng bakuna mo laban sa COVID-19?",
    "additional_dose_date": "Ano ang petsa ng karagdagang dosis mo?",
    "completed_one": "Kumpleto na",
    "completed_two": "Salamat sa pag-update ng tala ng pagpapabakuna mo!",
    "progress_bar_complete": "Kumpleto",
    "upload_image": "I-upload ang larawan",
    "retake_photo": "Ulitin ang pagkuha ng larawan",
    "other": "Iba pa",
    "remove_first_dose": "Tanggalin ang tala ng unang dosis",
    "remove_second_dose": "Tanggalin ang tala ng ikalawang dosis",
    "remove_first_booster_dose": "Tanggalin ang tala ng unang booster na dosis",
    "remove_second_booster_dose": "Tanggalin ang tala ng ikalawang booster na dosis",
    "remove_additional_dose": "Tanggalin ang tala ng karagdagang dosis",
    "exemption": "May eksempsiyon ka ba?",
    "exempt": "Eksempted ako",
    "not_exempt": "Di ako eksempted",
    "enter_exemption": "Ilagay ang eksempsiyon",
    "upload_exemption_documentation": "I-upload ang dokumento para sa eksempsiyon",
    "remove_dose": "Tanggalin ang dosis",
    "continue": "Magpatuloy",
    "enter_credentials": "Ilagay ang iyong mga kredensiyal",
    "incorrect_credentials": "Mali ang mga kredensiyal, subukan ulit.",
    "add_photo": "Ilagay ang larawan"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Hello {{name}}, Napansin naming di mo pa tapos ang pagpapaiskedyul para sa iyong {{group_name}}. Para matuloy ang appointment, kailangang makumpleto ang pagbabayad. I-click ang link na ito para makumpleto ang proseso {{complete_url}}. Kung gusto mong kanselahin ang appointment mo, i-click ang link na ito {{cancel_url}}. Salamat"
    }
  },
  "otc": {
    "record_results_for_abbott": "Mag-test at itala ang mga resulta mo para sa BinaxNOW test",
    "record_results": "Kumuha ng test at itala ang resulta",
    "activate_a_kit": "I-activate ang kit",
    "activate_a_kit_for_test": "I-activate ang kit para sa {{test}}",
    "todays_test_process": "Proseso ng test ngayon:",
    "date_of_birth_question": "Kailan ang araw ng kapanganakan ni {{first_name}}?",
    "confirm_dob_to_continue": "Kumpirmahin ang petsa ng kapanganakan ng kalahok para magpatuloy:",
    "incorrect_dob": "Maling petsa ng kapanganakan",
    "file_too_large": "Tiyaking ang sukat ng naka-upload na file ay mas maliit sa {{size}}",
    "enter_valid_date_in_last_x_days": "Maglagay ng balidong petsa sa huling {{n}} araw",
    "barcode_format_not_valid": "Di gumagana ang barcode",
    "complete_button": "Kumpleto",
    "next_button": "Susunod",
    "back_button": "Ibalik",
    "report_a_test": "Mag-ulat ng test:",
    "x_%_completed": "{{n}}% kumpleto",
    "answer_survey": "Sagutin ang mga tanong sa sarbey",
    "steps": {
      "answer_survey": "Sagutan ang sarbey",
      "review_instructions": "Rebyuhin ang mga panuto",
      "scan_code": "I-scan ang code",
      "enter_time": "Ilagay ang oras",
      "enter_result": "Ilagay ang resulta",
      "take_photo": "Kumuha ng larawan",
      "confirm_result": "Kumpirmahin ang resulta",
      "selest_test_kit": "Piliin ang klase ng test",
      "enter_date": "Ilagay ang petsa",
      "enter_date_and_time": "Ilagay ang petsa at oras"
    },
    "review_instructions": "Rebyuhin ang mga panuto sa test",
    "follow_instructions_or_below": "Sundin ang mga panutong nakalagay sa test mo o rebyuhin ang mga panuto sa ibaba.",
    "watch_video": "Panoorin ang video",
    "view_instructions": "Tingnan ang mga panuto",
    "follow_instructions": "Sundin ang mga panutong nakalagay sa test mo.",
    "scan_code": "I-scan ang QR code ng test",
    "qr_code_description": "Walang katulad ang QR code sa test mo at makikita ito sa kanang itaas ng test mo",
    "show_example": "Ipakita ang halimbawa",
    "hide_example": "Itago ang halimbawa",
    "barcode_scanned": "Ganap na na-scan ang barcode",
    "enter_barcode": "Ilagay ang barcode",
    "scan_qr_code": "I-scan ang QR code",
    "which_test_did_you_take": "Aling test ang kinuha mo?",
    "when_did_you_take_test": "Kailan mo kinuha ang test na ito?",
    "what_day_did_you_take_test": "Anong araw mo kinuha ang test na ito?",
    "what_time_did_you_take_test": "Anong oras mo kinuha ang test?",
    "time": {
      "today": "Ngayon",
      "yesterday": "Kahapon",
      "two_days_ago": "2 araw ang nakalipas"
    },
    "enter_current_time": "Ilagay ang oras ngayon",
    "enter_result": "Ilagay ang resulta ng test",
    "choose_result_option": "Piliin ang opsiyong naglalarawan sa resulta sa testing card mo:",
    "clarify_results": "Kumpirmahin ang resulta mo",
    "take_result_photo": "Kumuha ng larawan ng mga resulta",
    "take_result_photo_description": "Kumuha ng larawan ng nakumpletong card ng resulta para maitala ang resulta mo.",
    "my_result": "Resulta ko",
    "switch_camera": "Magpalit ng kamera",
    "confirm_result": "Kumpirmahin ang resulta",
    "name": "Pangalan:",
    "date": "Petsa:",
    "result": "Resulta:",
    "results": "Mga resulta",
    "test_submitted": "Salamat, {{first_name}}! Ang {{test}} mo ay naisumite na.",
    "dob": "Petsa ng kapanganakan:",
    "administered": "Isinagawa:",
    "or": "OR",
    "upload": "I-upload",
    "change_uploaded_file": "Palitan ang na-upload na file",
    "take_photo": "Kumuha ng larawan",
    "confirm_information": "Kumpirmahin ang impormasyon",
    "barcode": "Barcode:",
    "scan_test_barcode": "I-scan ang barcode ng test",
    "barcode_description": "Walang katulad ang barcode sa test mo at makikita ito sa test mo",
    "enter_date_in_last_30_days": "Maglagay ng petsa sa huling 30 araw at naestimang oras ng pagkuha ng test",
    "add_image": "Magdagdag ng imahen",
    "change_image": "Palitan ang imahen",
    "skip_barcode_reader": "Di ma-scan ang code? I-click ang Susunod para mag-ulat pa rin ng test",
    "enter_date_and_time": "Ilagay ang petsa at oras ng test",
    "enter_date": "Ilagay ang petsa ng test",
    "did_you_take_test_today": "Nag-test ka ba ngayon?",
    "record_results_for_generic": "Mag-test at i-record ang mga resulta mo para sa test na {{name}}",
    "choose_result_option_custom": "Piliin ang naglalarawan sa resulta ng {{test_name}} na test mo:"
  },
  "yes": "Oo",
  "no": "Hindi",
  "event_token": {
    "title": "Ilagay ang code sa ibaba tungo sa vending machine para mailabas ang test kit mo",
    "loading_text": "Naglo-load, tingnan ulit mamaya"
  },
  "appointment_recovery": {
    "complete_title": "Handa ka na!",
    "subtitle_1": "Kung iPhone ang iyong ginagamit, pakipindot ang **Done** sa taas ng iskrin sa bandang kaliwa para bumalik sa iyong Dashboard.",
    "subtitle_2": "Kung Android ang iyong ginagamit, pindutin ang **X** sa taas ng iskrin sa bandang kaliwa.",
    "subtitle_0": "Paki-exit sa browser pa magpatuloy."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Ugnayang may pagsang-ayon",
  "finish_registration": {
    "title": "Kumpletuhin ang rehistrasyon mo",
    "subtitle": "Kumpletuhin ang sumusunod na impormasyon bago ang appointment mo:",
    "button": "Tapusin ang pagpaparehistro"
  },
  "arab_ethnicity": {
    "arab": "Arabo",
    "non_arab": "Di Arab",
    "prefer_not_to_answer": "Pinipiling di sumagot",
    "question": "Arab na Etnisidad",
    "subtitle": "Kahingian ng mga regulasyong kolektahin namin ang sumusunod na impormasyon.",
    "help": "Ang etnisidad ay tumutukoy sa mga karaniwang katangian gaya ng wika, gawing kultural, relihiyon, at iba pang mga katangiang ginagamit upang matukoy ang grupo ng mga tao. Maaari rin itong umayon o di umayon sa sariling identipikasyon ng lahi."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Depende sa gamit mo, i-click ang link sa ibaba para mai-download ang Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Susunod",
    "back": "Bumalik",
    "complete": "Kumpleto"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Buksan ang camera ng iyong device",
    "point_to_qr_code": "Itutok sa QR code ang i-tap ang link",
    "complete_registration": "Tapusin ang rehistrasyon",
    "need_help": "Kailangan ng tulong? Kontakin kami sa"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Atestasyon sa detalye ng pagpapabakuna",
    "select_checkbox": "Rebyuhin ang sumusunod at piliin ang angkop na kahon sa ibaba bago magpatuloy.",
    "opportunity_to_read": "Ako ay may oportunidad o nabigyan ng oportunidad na mabasa, o binasa sa akin, ang Vaccine Information Statement(s) (\"VIS\") o Emergency Use Authorization (\"EUA\") fact sheet na para sa ibabakuna:",
    "vaccine_info_sheet": "information sheet para sa bakuna",
    "vaccine_fact_sheet": "fact sheet para sa bakuna",
    "vaccine_info_statement": "pahayag ng impormasyon sa bakuna"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "ang kit na ito ay may mga test din para sa chlamydia at gonorrhea.",
    "B": "ang kit na ito ay may mga test din para sa chlamydia at gonorrhea.",
    "C": "ang kit na ito ay may mga test din para sa syphilis.",
    "D": "ang kit na ito ay may mga test din para sa syphilis, chlamydia at gonorrhea.",
    "E": "ang kit na ito ay may mga test din para sa HIV at syphilis.",
    "box_type": "Kahon {{type}}",
    "administered_text": "Kokontakin ka namin kapag nakarating sa lab ang kit mo at kapag handa na ang mga resulta mo.",
    "activate_kit": "I-activate ang test kit ko",
    "register_another_test": "Magrehistro ng ibang test",
    "credentials": {
      "title": "Simulan na natin",
      "label": "Kontak",
      "p1": "Simulan natin sa ilang detalye ukol sa iyo.",
      "location_step": {
        "p1": "Sino ang nagbigay ng test kit mo?",
        "load": "Mag-load ng mas marami"
      }
    },
    "checklist": {
      "not_urinated": "Di ka umihi sa nakaraang oras.",
      "bathroom": "May akses ka sa banyo para sa pagkuha ng ihi.",
      "anal_swab": "May akses ka sa pribadong lugar para makakuha ng anal swab.",
      "blood_extraction": "May akses ka sa malinis na lugar para sa pagkuha ng dugo.",
      "washed_hands": "Nakapaghugas at sabon ka ng kamay sa loob ng 30 segundo.",
      "title": "Handa ka nang simulan ang pagkolekta ng sampol?",
      "no_alcohol": "Huwag uminom ng alak sa araw ng pagkolekta.",
      "menstruation": "Huwag kumuha ng vaginal swab specimen kapag may regla o sa loob ng 24 oras matapos makipagtalik."
    },
    "confirm": {
      "title": "Kumpirmahin ang impormasyon mo",
      "p1": "Tama ba ang impormasyong ito?"
    },
    "display_name": {
      "A": "Chlamydia at Gonorrhea (Sa Iisang Parte)",
      "B": "Chlamydia at Gonorrhea (3 Parte)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia at Gonorrhea",
        "B": "Chlamydia at Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "Colorectal Cancer Screening (FIT)",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia at Gonorrhea (Iisang Parte)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV at Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Lebel ng Testosteron",
      "VD": "Bitamina D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "I-rescan",
    "scanned": "Na-rescan mo na ang Kahon {{type}}",
    "not_yet": "Hindi Pa Tapos",
    "success_registration": "Rehistrado na!",
    "no_box": "Walang kahon?",
    "faq_link": "Bisitahin ang FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "Malapit ka na sa mas malusog na ikaw.",
      "p1": "I-scan o ilagay ang **bar code ng kit** na nasa gilid ng kahon."
    },
    "appointments": {
      "title": "Pumili ng pasyente at provider",
      "p1": "Nakakita kami ng higit sa isang profile na kaugnay ng mga detalye mo. Piliin ang tamang profile:",
      "new": "Bagong pasyente o provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "Nahanap namin ang patient record mo."
    },
    "section_1": "Kumpirmahin ang laman ng kit",
    "section_2": "Pagkolekta para sa test",
    "section_3": "Pagbalot",
    "section_4": "Pagpapadala",
    "box_a": "Kahon A",
    "box_b": "Kahon B",
    "box_c": "Kahon C",
    "kit_flow": {
      "button_1": "Magpatuloy sa pagbalot",
      "button_2": "Handa na ako",
      "button_3": "Kompleto at naka-pack na ang kit ko",
      "button_4": "Tapos na ako",
      "button_5": "Susunod na test",
      "button_6": "Laktawan ang mga panuto"
    },
    "contents": {
      "header": "Ayos! Siguraduhin nating kompleto ang kit mo",
      "text_1": "Nasa test kit box mo ba ang sumusunod?",
      "blood_collection_card": "Card para sa pagkolekta ng dugo",
      "blood_collection_card_subtitle": "(1 o 2 depende sa test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Isang gamitang lanseta",
      "up_to_other": "(Hanggang 4 depende sa test kit)",
      "adhesive_bandages": "Mga adhesive bandage",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Nabubulok na papel pangkolekta",
      "bottle_and_probe": "Boteng pang-sampling at pagsusuri",
      "prepaid_envelope": "Pre-paid na sobreng para sa pagpapadala",
      "biohazard_subtitle": "(may absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Mga bag na may peligro sa katawan",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 malinis na gauze pad"
    },
    "packaging": {
      "title": "Tseklist para sa pagbalot",
      "subtitle": "Bago ipadala ang kit mo, siguraduhing:"
    },
    "packaging_box_a": {
      "step_1": "Nai-pack mo lahat ng laman ng test kit",
      "step_2": "Nagamit mo lahat ng pangkolektang materyal na nasa kit para makolekta ang kailangang specimens",
      "step_3": "Ang **araw-ng-kapanganakan** mo ay nakasulat sa lahat ng specimen collection tubes na may format na MM/DD/YYYY",
      "step_4": "Ang **petsa ng pagkolekta** ay nakasulat sa lahat ng specimen collection tubes na may format na MM/DD/YYYY",
      "step_5": "Selyado ang specimen collection tube sa biohazard bag nito",
      "step_6": "Lahat ng specimen ay nasa kahon",
      "step_7": "Nasa pre-paid na sobreng para sa pagpapadala ang kahon",
      "step_8": "Ganap na selyado ang sobreng para sa pagpapadala pabalik"
    },
    "packaging_box_b": {
      "step_1": "Naibalot mo lahat ng laman ng test kit, kahit hindi ka nag-test(s)",
      "step_2": "Nagamit mo na lahat ng pangkolektang materyal sa kit para sa pagkolekta ng mga kailangang specimen",
      "step_3": "Ang **araw-ng-kapanganakan** mo ay nakasulat sa lahat ng specimen collection tubes na may format na MM/DD/YYYY",
      "step_4": "Ang **araw ng pagkolekta** ay nakasulat sa lahat ng specimen collection tubes na may format na MM/DD/YYYY",
      "step_5": "Bawat specimen collection tube ay selyado sa sariling biohazard bag (**isang** specimen kada bag)",
      "step_6": "Lahat ng mga specimen ay nasa kahon",
      "step_7": "Ang kahon ay nasa loob ng pre-paid na sobreng para sa pagpapadala",
      "step_8": "Ganap na selyado ang sobreng para sa pagpapadala pabalik"
    },
    "packaging_box_c": {
      "step_1": "Nai-pack mo na lahat ng laman ng test kit, pati ang mga gamit na lanseta.",
      "step_2": "Ang **araw-ng-kapanganakan** mo ay nakasulat sa blood collection card na may format na MM/DD/YYYY",
      "step_3": "Ang **petsa ng pagkolekta** ay nakasulat sa blood collection card na may format na MM/DD/YYYY",
      "step_4": "Ang blood collection card at lahat ng gamit na lanseta ay nasa biohazard bag",
      "step_5": "Ang biohazard bag ay nasa kahon",
      "step_6": "Ang kahong ito ay nasa loob ng pre-paid na sobreng para sa pagpapadala",
      "step_7": "Ganap na selyado ang sobreng para sa pagpapadala pabalik"
    },
    "success": {
      "header_1": "Ayos!",
      "text_1": "Handa ka nang kumolekta ng unang sampol mo.",
      "header_2": "Nagawa mo!",
      "text_2": "**Susunod:** ibalot ang kit para sa pagpapadala pabalik.",
      "header_3": "Mahusay!",
      "text_3": "Mas malapit ka na sa mas mainam na kalusugan.",
      "header_4": "Mahusay!",
      "text_4": "Isa na lang at tapos na!",
      "text_5": "Handa ka nang kumolekta ng unang sampol mo"
    },
    "instructions": {
      "title": "Pumili ng test para makita ang mga panuto:",
      "subtitle": "Test",
      "button": "Mga panuto",
      "pdf": "Mga panuto sa PDF"
    },
    "instructions_box_a": {
      "title": "Pagkolekta ng ihi",
      "subtitle": "Para sa test na ito, hinihingi ang sumusunod:",
      "step_1": "Huwag umihi 1 oras bago ang pagkolekta. Pinakamainam ang paggamit ng unang ihi sa umaga.",
      "step_2": "Umihi direkta sa PeeCanter o collection cup, puno sa pagitan ng 1/3 at 1/2.",
      "step_3": "Gamitin ang PeeCanter o ang pipette para mailipat ang ihi mula sa collection cup tungo sa collection tube(s) na may kulay kahel <span style=\"color: #FF5000\">**\"URINE\"**</span> na marka.",
      "step_4": "Punuin ang collection tube(s) hanggang ang kabuoang likido ay nasa pagitan ng itinakdang linya.",
      "step_4_sublabel": "Huwag punuin ang tube nang lagpas sa itinakdang linya para hindi ma-reject ang specimen.",
      "step_5": "Takpan ang collection tube at siguraduhing mahigpit at pantay ang pagkakaselyado nito.",
      "step_6": "Isulat ang araw ng kapanganakan at petsa ng pagkolekta ng specimen (petsa ngayon) na may format na MM/DD/YYYY sa tube sa itinakdang lugar.",
      "step_7": "Ilagay ang specimen sa walang lamang biohazard bag kasama ang absorbent pad at siguraduhin ang pagkakaselyado nito."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Pagkolekta ng ihi",
        "subtitle": "Para sa test na ito, kailangan ang sumusunod:",
        "step_1": "Huwag umihi 1 oras bago ang pagkolekta. Pinakamainam ang paggamit ng unang ihi sa umaga.",
        "step_2": "Umihi direkta sa PeeCanter o collection cup, puno sa pagitan ng 1/3 at 1/2.",
        "step_3": "Gamitin ang PeeCanter o ang pipette para mailipat ang ihi mula sa collection cup tungo sa collection tube(s) na may kulay kahel <span style=\"color: #FF5000\">**\"URINE\"**</span> na marka.",
        "step_4": "Punuin ang collection tube(s) hanggang ang kabuoang likido ay nasa pagitan ng itinakdang linya.",
        "step_4_subtitle": "Huwag punuin ang tube nang lagpas sa itinakdang linya para hindi ma-reject ang specimen.",
        "step_5": "Takpan ang collection tube at siguraduhing mahigpit at pantay ang pagkakaselyado nito.",
        "step_6": "Isulat ang araw ng kapanganakan at petsa ng pagkolekta ng specimen (petsa ngayon) na may format na MM/DD/YYYY sa tube sa itinakdang lugar.",
        "step_7": "Ilagay ang specimen sa walang lamang biohazard bag kasama ang absorbent pad at siguraduhin ang pagkakaselyado nito."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "Para sa test na ito, kailangan ang sumusunod:",
        "step_1": "Hawakan ang swab sa pagitan ng maputlang score line (kung meron) at ng parte ng swab na may bulak.",
        "step_2": "Ipasok ang swab sa bibig at ikiskis ang dulo ng swab sa likod ng lalamunan sa loob ng 10 segundo.",
        "step_3": "Maingat na tanggalin ang swab at ilagay sa collection tube na may asul <span style=\"color: #001F70\">**\"ORAL\"**</span> na marka. Putulin ang swab sa score line sa pamamagitan ng pagbaluktot nito sa collection tube.",
        "step_4": "Takpan ang collection tube at siguraduhing mahigpit at pantay ang pagkakaselyado nito.",
        "step_5": "Isulat ang araw ng kapanganakan at petsa ng pagkolekta ng specimen (petsa ngayon) na may format na MM/DD/YYYY sa tube sa itinakdang lugar.",
        "step_6": "Ilagay ang specimen sa walang lamang biohazard bag kasama ang absorbent pad at siguraduhing ganap itong selyado."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "Para sa test na ito, kailangan ang sumusunod:",
        "step_1": "Hawakan ang swab sa pagitan ng maputlang score line (kung meron) at ng may bulak na parte ng swab.",
        "step_2": "Ipasok ang swab nang 3 - 5 cm (1 - 2\") sa butas ng puwet. Dahan-dahang ikutin ang swab sa loob ng 5 - 10 segundo para makakolekta ng anumang organismo.",
        "step_3": "Maingat na tanggalin ang swab at ilagay sa collection tube na may berdeng <span style=\"color: #00C08C\">**“ANAL”**</span> label. Putulin ang swab sa score line sa pamamagitan ng pagbaluktot nito sa collection tube.",
        "step_4": "Takpan ang collection tube at siguraduhing mahigpit at pantay ang pagkakaselyado nito.",
        "step_5": "Isulat ang araw ng kapanganakan at petsa ng pagkolekta ng specimen (petsa ngayon) na may format na MM/DD/YYYY sa tube sa itinakdang lugar.",
        "step_6": "Ilagay ang specimen sa walang lamang biohazard bag kasama ang absorbent pad at siguraduhing ganap itong selyado."
      }
    },
    "instructions_box_c": {
      "title": "Pagkolekta ng dugo",
      "subtitle": "Para sa test na ito, kailangan ang sumusunod:",
      "step_1": "Isulat ang pangalan, araw ng kapanganakan, at petsa ng pagkolekta mo sa wastong mga espasyo. Gamitin ang format na MM/DD/YYYY.",
      "step_2": "Buksan ang blood card flap para lumabas ang mga bilog sa pangkoleksiyong papel para sa dugo. Huwag hawakan ang pangkoleksiyong papel para sa dugo.",
      "step_3": "Maghugas ng kamay gamit ang maligamgam na tubig sa loob ng 30 segundo, at alugin ang mga kamay sa loob ng 15 segundo para sa mas mainam na pagdaloy ng dugo tungo sa mga daliri mo.",
      "step_4": "Linisin ang dulo ng daliri gamit ang alcohol pad. Pinakamainam na gitnang daliri o palasingsingan ng di-dominanteng kamay ang gamitin.",
      "step_5": "Kunin ang lanseta at buksan. Pumindot nang mariin sa maliit na pantusok gamit ang dulo ng daliri, hanggang sa mag-click ang karayom. Habang ang kamay mo ay nasa baba ng puso mo sa pagkolekta, masahiin ang daliri hanggang dulo para sa mas mainam na pagdaloy ng dugo.",
      "step_5_subtitle": "Isang gamitan lang ang mga lanseta. Lahat ng lanseta ay kailangang maibalik kasama ng sampol sa laboratoryo para sa wastong pagtapon.",
      "step_6": "Mula sa sentro, maglagay ng 3-6 patak sa bilog at ibabad ang pangkoleksiyong papel. Huwag hawakan ang papel dahil nakaaapekto ito sa daloy ng dugo. Kapag puno na ang bilog, isunod ang kabilang bilog. Ayos lang na lumagpas sa linya ang dugo, pero huwag hayaang tumama ang mga patak ng dugo sa isa't isa.",
      "step_6_subtitle": "Huwag magdagdag ng dugo sa bilog kapag kompleto o natuyo na. Ang \"pagsalansan\" ng dugo ay magpapawalang-bisa sa koleksiyon.",
      "step_7": "Habang nakabukas ang blood card, ilapat ito sa patag na lugar at hayaang matuyo ang pangkolektang papel para sa dugo, sa hindi bababa sa 30 minuto. Huwag painitan, pahanginan, o itapat sa araw ang pangkolektang papel para sa dugo. Nakasisira ng specimen ang init.",
      "step_7_subtitle": "Tingnan ang likod ng pangkolektang papel para sa dugo. Dapat na nakababad ito sa dugo at dapat na puno ang bawat bilog sa pangkolektang papel.",
      "step_8": "Kapag tuyo ang pangkolektang papel para sa dugo, isara ang blood card gamit ang flap. Ilagay ang blood card at mga lanseta sa biohazard bag kasama ang pakete ng desiccant. Siguraduhing selyado ang biohazard bag."
    },
    "test_tips": {
      "title": "Mga Payo para sa Tamang Pagkolekta ng Dugo",
      "subtitle": "Para sa pinakamainam na mga resulta:",
      "step_1": "Siguraduhing hindi ka dehydrated bago magkolekta. Nagpapabuti ng daloy ng dugo ang pag-inom ng tubig.",
      "step_2": "Huwag magkolekta kapag katatapos manigarilyo.",
      "step_3": "Makatutulong sa mas mainam na pagdaloy ng dugo tungo sa mga kamay mo ang paghuhugas gamit ang maligamgam na tubig.",
      "step_4": "Ialog ang mga kamay pasahig para sa mas mainam na daloy ng dugo tungo sa mga daliri mo.",
      "step_5": "Panatilihin ang collection device at mga kamay mo sa ibaba ng puso mo habang nagkokolekta para sa pinakamainam na pagdaloy ng dugo.",
      "step_6": "Maaaring higit sa isang pag-prick ng daliri ang kailangan mo. Ulitin ang mga ito sa pagitan ng bawat pag-prick ng daliri."
    },
    "shipping": {
      "header": "Ipadadala na ang kit mo!",
      "text": "**Pagbati!** Tapos ka na sa una mong sexual health screening."
    },
    "pick_up": "Kunin ang rekomendadong test kit mo sa site staff at magparehistro sa ibaba",
    "short_display_name": {
      "A": "Chlamydia at Gonorrhea",
      "B": "Chlamydia at Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia at Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV at Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Bitamina D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "Tingnan ang mga resulta",
    "recommended_kit_title": "Rekomendadong Test Kits",
    "instructions_box_h": {
      "title": "Pagkolekta ng Swab mula sa Ari",
      "subtitle": "Maaaring may likidong preservative ang collection tubes. <span class=\"text-danger\">**Huwag alisin ang likidong preservative sa collection tubes kung meron.**</span>",
      "step_1": "Hawakan ang swab sa pagitan ng maputlang score line (kung meron) at ng may bulak na parte ng swab sa isang kamay at ihiwalay ang labia (mga tupi ng balat sa pagitan ng ari).",
      "step_2": "Ipasok ang swab nang 5cm (2in) sa ari.",
      "step_3": "Maingat na tanggalin ang swab at ilagay sa collection tube na may markang <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Putulin ang swab sa pamamagitan ng pagbaluktot nito sa collection tube.",
      "step_4": "Takpan ang collection tube at siguraduhing mahigpit at pantay ang pagkakaselyado nito.",
      "step_5": "Isulat ang araw ng kapanganakan mo sa format na MM/DD/YYYY sa tube sa itinakdang lugar (DOB).",
      "step_6": "Isulat ang petsa ng pagkolekta ng specimen (petsa ngayon) sa format na MM/DD/YYYY sa tube sa itinakdang lugar (Coll. Date).",
      "step_7": "Ilagay ang specimen sa walang lamang biohazard bag kasama ang absorbent pad at siguraduhing ganap itong selyado."
    },
    "packaging_box_h": {
      "step_3": "Ang **araw-ng-kapanganakan** mo ay nakasulat ssa specimen collection tube na may format na MM/DD/YYYY",
      "step_4": "Ang **petsa ng pagkolekta** mo ay nakasulat sa specimen collection tube na may format na MM/DD/YYYY",
      "step_6": "Nasa kahon ang biohazard bag"
    },
    "instructions_box_cc": {
      "title": "Pagkolekta ng stool",
      "step_1": "Isulat ang araw ng kapanganakan mo at ang petsa ng pagkolekta ng specimen (petsa ngayon) sa format na MM/DD/YYYY sa tube sa itinakdang lugar. Buksan ang berdeng takip sa pamamagitan ng pag-ikot at pag-angat.",
      "step_2": "Ilagay ang pangkolektang papel sa inidoro, sa taas ng tubig.",
      "step_3": "Ilagay ang stool sample sa taas ng pangkolektang papel.",
      "step_4": "Kumuha ng stool sample bago lumubog ang papel at bago ito mabasa.",
      "step_5": "Kiskisin palapat ang fecal sample gamit ang sample probe.",
      "step_6": "Ganap na takpan ang nakuhanang parte ng sample probe kasama ang stool sample.",
      "step_7": "Isara ang sampling bottle sa pamamagitan ng ganap na pagpasok ng sample probe kasama ng stool sample.",
      "step_8": "I-flush. Nabubulok ang pangkoleksiyong papel at hindi makapepeligro sa mga septic system.",
      "step_9": "Ibalot ang sampling bottle sa absorbent pad at ipasok sa biohazard bag."
    },
    "contact_support": {
      "title": "Ayusin natin ito",
      "text_1": "Ikinalulungkot naming may isyu!",
      "text_2": "Kontakin kami para ipaalam ang isyu at tutulungan ka naming mapalitan ang kit mo."
    },
    "contact_support_success": {
      "title": "Natanggap namin ang mensahe mo",
      "text_1": "Salamat sa pagkontak sa amin.",
      "text_2": "Makikipag-ugnayan ang miyembro ng support team.",
      "button_text": "Bumalik sa home page"
    },
    "kit_in_transit_to_patient": "Parating na ang order mo! Karamihan sa mga order ay naipadadala sa loob ng 2-5 araw ng trabaho. Kapag natanggap mo ang kit, magpapadala kami ng impormasyon kung paano ito ma-activate at maipadadala pabalik. Link sa\n\nPag-track: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}\"'",
    "kit_received_at_lab": "Natanggap ng lab ang kit mo! Makatatanggap ka ng notipikasyon para matsek ang patient portal kapag handa na ang mga resulta",
    "registration_confirmation": "tapos na ang rehistrasyon mo! Bisitahin ang registration confirmation page para ma-activate ang test kit mo:",
    "kit_ordered_online": "Natanggap namin ang kit order mo at magpapadala kami ng update kapag naipadala na! # ng order: {{order_number}} Petsa ng pag-order: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Natanggap namin ang order mo!",
      "p1": "Natanggap namin ang order mo! Kapag naipadala na ang order, magpapadala kami ng update.",
      "order_number": "# ng order",
      "order_date": "Petsa ng pag-order"
    },
    "completed": "Kompleto na",
    "I": "ang kit na ito ay may mga test para sa HIV.",
    "CC": "ang kit na ito ay may mga test para sa colorectal cancer.",
    "CE": "ang kit na ito ay may mga test para sa celiac disease.",
    "CR": "ang kit na ito ay may mga test para sa creatinine.",
    "DD": "ang kit na ito ay may mga test para sa diabetes.",
    "H": "ang kit na ito ay may mga test para sa HPV.",
    "P": "ang kit na ito ay may mga test para sa hepatitis C.",
    "HH": "ang kit na ito ay may mga test para sa hemoglobin.",
    "HS": "ang kit na ito ay may mga test para sa HSV-2.",
    "TT": "ang kit na ito ay may mga test para sa testosterone.",
    "VD": "ang kit na ito ay may mga test para sa bitamina D."
  },
  "copy_link": "Kopyahin ang link ng page",
  "copied_link": "Nakopya ang link!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Proteksiyon laban sa mga virus na kaugnay ng karaniwang trangkaso",
      "vaccine_covid": "Proteksiyon laban sa mga virus na kaugnay ng COVID-19",
      "tdap": "Makatutulong makaiwas sa tetanus ang Tdap na bakuna",
      "polio": "Proteksiyon laban sa polio virus. Kahingiang maiturok sa mga bata bago pumasok sa eskuwela.",
      "varicella": "Bakunang nakapoprotekta laban sa bulutong",
      "shingles": "Bakunang nakapoprotekta laban sa shingles. Para sa 18 taon pataas.",
      "human_papillomavirus": "Bakunang nakapoprotekta laban sa HPV. Rekomendado sa mga batang 11 taon.",
      "meningococcal": "Nakapoprotekta ang bakuna laban sa apat na klase ng meningococcal bacteria."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Humanap ng klinika",
    "select_pin": "Mamili ng pin para makita ang mga detalye",
    "miles_shorten": "{{miles}} milya",
    "next_available": "Susunod na bakante",
    "appointment_type": "Klase ng appointment",
    "individual_appointment": "Indibidwal na appointment",
    "family_appointment": "Pampamilyang appointment",
    "vaccines": "Mga bakuna",
    "what_kind_appointment": "Anong klaseng appointment ang gusto mong i-book?",
    "select_family_or_individual": "Piliin ang pampamilyang appointment kung nagpapaiskedyul ka nang minsanan para sa maraming kapamilya.",
    "vaccines_selected": "Mga piniling bakuna",
    "where_care": "Saan mo gustong mapaunlakan?",
    "select_vaccines_for_individual": "Mamili ng mga bakuna para sa appointment mo (opsiyonal).",
    "select_vaccines_for_family": "Mamili ng mga bakuna para sa appointment ng pamilya mo (opsiyonal).",
    "schedule_services": "Iskedyul ng serbisyo",
    "add_family": "Add family members and select services below.",
    "family_member": "Kapamilya {{number}}",
    "morning": "Umaga",
    "afternoon": "Hapon",
    "slot_available": "{{number}} bakante",
    "within_x_miles": "Sa loob ng {{miles}} milya mula",
    "any_distance": "Gaano man kalayo sa",
    "partial_results": "Parsiyal na resulta",
    "partial_matches": "Ilan at hindi lahat sa mga bakunang hanap mo ang nasa sumusunod na klinika",
    "no_matches": "Walang nahanap na tugma sa hanap mo. Subukang palitan ang filters para sa mas mainam na resulta.",
    "no_clinics_found": "Walang mga klinikang tugma sa hanap mo.",
    "broaden_filters": "Lawakan ang filters mo para sa mas mainam na resulta.",
    "unavailable_vaccines": "Mga bakunang wala:",
    "available_vaccines": "Mga bakunang magagamit:",
    "select_date": "Mamili ng petsa",
    "available_appointments": "MGA BAKANTENG APPOINTMENT",
    "appointment_scheduling_info": "Makapagtatakda ka ng oras ng appointment para sa mga kapamilya mo sa susunod na hakbang. Makapag-iiskedyul ka ng mga appointment sa magkakaibang araw kung gusto mo.",
    "hold_selected_appointments": "Naka-hold ang mga piniling appointment sa loob ng 15 minuto.",
    "appointments_selected": "MGA PINILING APPOINTMENT",
    "no_appointments_selected": "Walang piniling appointment",
    "vaccines_needed": "Mga bakunang kailangan",
    "select_x_appointments": "Piliin ang {{number}} appointments.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Beripikahin natin ang email mo!",
    "hi_full_name": "Hi {{full_name}},",
    "verify_email_button": "Beripikahin ang email",
    "please_verify_email": "Gamitin ang pindutan sa ibaba para maberika ang email mo."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Hepatitis A",
    "hepatitis_b": "Hepatitis B",
    "hepatitis_a_b": "Hepatitis A at B",
    "hib": "Hib",
    "mmr": "Measles, Mumps, Rubella",
    "meningococcal": "Meningococcal",
    "mpox": "Mpox",
    "pneumococcal": "Pneumococcal",
    "polio": "Polio",
    "rsv": "Respiratory syncytial virus",
    "rotovirus": "Rotavirus",
    "zoster": "Zoster (Shingles)",
    "tetanus_diptheria": "Tetanus at Diphtheria",
    "tdap": "Tdap",
    "typhoid": "Typhoid",
    "varicella": "Varicella (Bulutong)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Mayor de-edad)",
    "covid-19_adult_description": "Para sa mga taong 12 taon o higit pa.",
    "covid-19_children": "COVID-19 (Bata)",
    "covid-19_children_description": "Para sa mga batang edad 4 - 11 taon.",
    "covid-19_infants": "COVID-19 (Sanggol)",
    "covid-19_infants_description": "Para sa mga batang anim na buwan - 3 taong gulang.",
    "influenza": "Trangkaso",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}