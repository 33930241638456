import React from "react";
import { Image, Col, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/pro-regular-svg-icons";


const ResultCard = ({ imgSrc, key, text, onSelect, isSelected, testConfiguration }) => {

  const showWideImage = testConfiguration?.results_schema?.otc?.images?.results?.__wide;

  return (
    <a onClick={() => onSelect()}>
      <Card
        className={`pointer result-card ${isSelected ? "selected-otc-result-card" : "unselected-otc-result-card"}`}
        style={{minHeight: "90px"}}
        data-test-hook={`${text.toLowerCase().replace(/\s/g, '')}testcard`}
      >
        <Row style={{margin: "auto 15px auto 0"}}>
          {!!imgSrc && (
            <Col className="text-start" md={showWideImage ? 5 : 2} xs={showWideImage ? 6 : 3}>
              <Image className="img-fluid" src={imgSrc} style={{margin: "10px 0"}} />
            </Col>
          )}
          <Col className="text-start" style={{ margin: "auto 0" }} >
            <Row>
              <Col>
                <b className={`${!imgSrc ? 'ms-3' : ''}`}>{text}</b>
              </Col>
              <Col xs={1} style={{ margin: "auto 10px auto 0" }}>
                {isSelected ? <FontAwesomeIcon icon={'check-circle'} size="lg" color="#2862FA" /> : <FontAwesomeIcon icon={faCircle} size="lg" color="#E2E2E2"/>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </a>
  )
}

export default ResultCard;
