import React from 'react';
import {
  ActionCableProvider,
  ActionCableConsumer,
} from 'react-actioncable-provider';

const ActionCableSubscriber = ({ channel, onReceived }) => {
  const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
  const url = `${protocol}://${window.location.host}/cable`;

  return (
    <ActionCableProvider url={url}>
      <ActionCableConsumer
        channel={channel}
        onReceived={onReceived}
      ></ActionCableConsumer>
    </ActionCableProvider>
  );
};

export default ActionCableSubscriber;
