import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../common/locales/i18n';
import PureButton from '../../common/components/PureButton';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import InsuranceContext from '../contexts/InsuranceContext';
import InsuranceForm, { validateInsuranceForm } from './InsuranceForm';
import Header from '../../Registration/components/Header';

const IAM_POLICYHOLDER = 'i_am';
const standardSubmit = true;

const showInsuranceField = (testGroup, field) =>
  testGroup.insurance_fields_requested.includes(field) ||
  testGroup.insurance_fields_required.includes(field);
const requireInsuranceField = (testGroup, field) =>
  testGroup.insurance_fields_required.includes(field);

const Insurance = (props) => {
  const testGroup = props.test_group;
  const { t, i18n } = useTranslation();
  const [formAction, setFormAction] = useState({
    requestInsurance: showInsuranceField(testGroup, 'insurance'),
    requireInsurance: requireInsuranceField(testGroup, 'insurance'),
    requestDriversLicense: showInsuranceField(testGroup, 'drivers_license'),
    requireDriversLicense: requireInsuranceField(testGroup, 'drivers_license'),
    requestSocialSecurityNumber: showInsuranceField(testGroup, 'ssn'),
    requireSocialSecurityNumber: requireInsuranceField(testGroup, 'ssn'),
    showInsuranceBack: false,
    showInsuranceInformation:
      requireInsuranceField(testGroup, 'insurance') ||
      props.user.insurance_policy_holder,
    showNonInsuranceInformation: false,
    policyHolder: props.user.insurance_policy_holder,
    secondaryPolicyHolder: props.user.insurance_secondary_policy_holder,
    showSecondary: !!props.user.insurance_secondary_policy_holder,
    useSSN:
      !showInsuranceField(testGroup, 'drivers_license') &&
      showInsuranceField(testGroup, 'ssn'),
    participantHasID: true,
    memberBirthMonth: null,
    memberBirthYear: null,
    memberBirthDay: null,
    memberSecondaryBirthMonth: null,
    memberSecondaryBirthYear: null,
    memberSecondaryBirthDay: null,
    driverLicenseState: null,
  });

  const insuranceView = {
    view: (props) => <InsuranceForm {...props} />,
    validate: validateInsuranceForm,
  };

  const handleSubmit = () => {
    [
      ['insurance_status', formAction.showInsuranceInformation ? 0 : 1],
      ['insurance_policy_holder', formAction.policyHolder],
      ['insurance_secondary_policy_holder', formAction.secondaryPolicyHolder],
      [
        'insurance_primary_member_date_of_birth(1i)',
        formAction.memberBirthYear,
      ],
      ['insurance_primary_member_date_of_birth(3i)', formAction.memberBirthDay],
      [
        'insurance_primary_member_date_of_birth(2i)',
        formAction.memberBirthMonth,
      ],
      [
        'insurance_secondary_member_date_of_birth(1i)',
        formAction.memberSecondaryBirthYear,
      ],
      [
        'insurance_secondary_member_date_of_birth(3i)',
        formAction.memberSecondaryBirthDay,
      ],
      [
        'insurance_secondary_member_date_of_birth(2i)',
        formAction.memberSecondaryBirthMonth,
      ],
      ['driver_license_state', formAction.driverLicenseState],
    ].map((field) => {
      $('<input />')
        .attr('type', 'hidden')
        .attr('name', `user[${field[0]}]`)
        .attr('value', field[1])
        .appendTo('#insuranceForm');
    });
    document.insuranceForm.submit();
  };

  return (
    <React.Fragment>
      <Header testGroup={testGroup} initialValues={props.user} />
      <main>
        <div className="mt-lg-4 bg-white container-sm p-3" style={{maxWidth: "560px"}}>
          <InsuranceContext.Provider
            value={{
              testGroup,
              formAction,
              standardSubmit,
              setFormAction,
              IAM_POLICYHOLDER,
            }}
          >
            <Formik
              initialValues={props}
              validate={insuranceView.validate(formAction)}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <form
                  onSubmit={formikProps.handleSubmit}
                  encType="multipart/form-data"
                  action={testGroup.submit_insurance_url}
                  method="post"
                  name="insuranceForm"
                  id="insuranceForm"
                >
                  <input name="_method" type="hidden" value="patch" />
                  <div
                    className="form-inputs"
                    aria-describedby="insuranceStatusDesc"
                  >
                    {insuranceView.view(formikProps)}
                  </div>
                  <div className="text-end">
                    <PureButton
                      type="submit"
                      className="mt-4 ms-auto btn-block"
                    >
                      {t('registration.submit')}
                    </PureButton>
                  </div>
                </form>
              )}
            </Formik>
          </InsuranceContext.Provider>
        </div>
      </main>
    </React.Fragment>
  );
};

Insurance.propTypes = {
  test_group: PropTypes.object.isRequired, // this is passed from the Rails view
  user: PropTypes.object.isRequired, // this is passed from the Rails view
};

export default Insurance;
