import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const OrderingFacilityFields = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const stateOptions = state.testConfigurationOptions.states.map((state) => {
    return { value: state, label: state }
  });
  const selectedState = stateOptions.find((option) => {
    return option.value == state.testGroupTestConfiguration.ordering_facility_state_abbv;
  });

  return (
    <>
      <div className="py-2"><hr/></div>
      <h5 className="my-4">Ordering facility</h5>
      <Row className="my-4">
        <Col xs={6}>
          <h6>Name</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_name}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_name: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={6}>
          <h6>Mailing address</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_mailing_address}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_mailing_address: e.target.value,
                }
              });
            }}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={6}>
          <h6>CLIA</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_clia}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_clia: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={2} lg={2}>
          <h6>City</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_city}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_city: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={2}>
          <h6>State</h6>
          <FloatingLabelSelect
            filledValue={!!state.testGroupTestConfiguration.ordering_facility_state_abbv}
            onChange={(ordering_facility) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_state_abbv: ordering_facility.value
                }
              });
            }}
            options={stateOptions}
            value={selectedState}
          />
        </Col>
        <Col xs={2}>
          <h6>Zip</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_postal_code}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_postal_code: e.target.value,
                }
              });
            }}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={6}>
          <h6>Phone number</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_phone_number}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_phone_number: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={6}>
          <h6>Country</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_country}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_country: e.target.value,
                }
              });
            }}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={6}>
          <h6>Performing organization name</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.calredi_reporting_school_name}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  calredi_reporting_school_name: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={6}>
          <h6>Address type code</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.ordering_facility_address_type_code}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  ordering_facility_address_type_code: e.target.value,
                }
              });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrderingFacilityFields;