import React, { useState } from 'react';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import HighlightedDatePicker from '../../common/components/HighlightedDatePicker';

export const FilterBar = ({ month_capacities_path, form_url, dates }) => {
  const [dayValue, setDayValue] = useState(dates ? dates.split(',') : '');
  const onDateChange = (arr) => {
    setDayValue(arr.map((dateObj) => dateObj.format('YYYY-MM-DD')));
  };

  return (
    <form
      noValidate="novalidate"
      className="simple_form search"
      action={form_url}
      acceptCharset="UTF-8"
      method="get"
    >
      <div className="row">
        <div className="col">
          <div className="string required search_dates">
            <input type="hidden" name="dates" id="dates" value={dayValue} />
            <HighlightedDatePicker
              remoteUrl={month_capacities_path}
              multiple
              format="YYYY-MM-DD"
              inputClass="form-control"
              placeholder="Dates"
              value={dayValue}
              onChange={onDateChange}
              plugins={[<DatePanel />]}
              style={{ width: '380px' }}
            />
          </div>
        </div>
        <div className="col">
          <input
            type="submit"
            name="commit"
            value="Search"
            className="btn btn-primary"
            data-disable-with="Search"
          />
        </div>
      </div>
    </form>
  );
};
