import React from 'react';
import { Form } from 'react-bootstrap';

const Switch = ({
  className,
  containerClassName,
  subLabel,
  ...props
}) => {

  return (
    <div className={containerClassName}>
      <Form.Check
        className={`switch ${className}`}
        type="switch"
        {...props}
      />
      {subLabel &&
        <div
          className="text-muted"
          style={{ marginLeft: "3rem" }}
        >
          {subLabel}
        </div>
      }
    </div>
  )
};

export default Switch;