import { Button, Spinner } from 'react-bootstrap';
import React from 'react';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import isMobilePhone from '../../../Registration/shared/PhoneValidator';
import { validateEmail } from '../../../common/utils';
import SectionHeader from '../SectionHeader';
import { SectionWrapper, SectionBody, SectionActions } from '../styled';

export default function CredentialsStep ({state: {loading, formState, t}, dispatch, checkCredentials, hasContact}) {
  return (
    <SectionWrapper>
      <Button variant="link" className='d-sm-none p-0 mb-2' onClick={() => dispatch({type: 'back'})}>
        {t('otc.back_button')}
      </Button>
      <SectionHeader title={t('test_strip.credentials.title')}/>
      <SectionBody>
        <div className='poppins pb-2 h3-24-semi-bold regular ls-small'>{t('test_strip.credentials.p1')}</div>
        <div className='my-2' style={{maxWidth: 488}}>
          <label className='form-label mb-3 h3-24-semi-bold' style={{fontSize: 18}}>{t('test_strip.credentials.label')}</label>
          <FloatingLabelInput
            name="phone_or_email"
            className="mb-2"
            value={formState.contact}
            onChange={(e) => {
              const contact = {contact: e.target.value}
              if (isMobilePhone(e.target.value)) {
                contact.phone_number =  e.target.value;
              } else if (validateEmail(e.target.value)) {
                contact.email =  e.target.value;
              }
              dispatch({type: 'updateForm', values: contact})
            }}
            ariaLabel="phone_or_email"
            ariaRequired
            ariaDescribedby="phoneOrEmailErrorDesc"
            id="phone_or_email"
            label={`${t('registration.phone_or_email')}`}
            onKeyDown={(e) => {
              if (e.keyCode == 13 && hasContact){
                checkCredentials()
              }
            }}
          />
        </div>
      </SectionBody>
      <SectionActions>
        <Button variant="link" className='d-none d-sm-block' onClick={() => dispatch({type: 'back'})}>
          {t('otc.back_button')}
        </Button>
        <Button onClick={checkCredentials} disabled={!hasContact} data-test-hook="submit">
          {loading ? <Spinner animation="border" variant="light" /> : t('otc.next_button')}
        </Button>
      </SectionActions>
    </SectionWrapper>
  );
}
