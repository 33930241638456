import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const Footer = ({partnerLogo}) => (
  <>
    <div style={{backgroundColor: "rgba(37, 37, 37, 1)"}} className="text-secondary p-5">
        <Row>
          <Col md={4} xs={12}>
            {partnerLogo && <div><img src={partnerLogo} width="150" className="mb-2"/></div>}
            <img src="/images/primary_logo_horizontal_white.png" width="150"/>
            <div className="my-3">Primary Diagnostics, Inc.</div>
            <div>595 Pacific Ave Floor 4</div>
            <div>San Francisco, CA 94133</div>
            <div className="text-primary mb-3">1-855-970-3223</div>
          </Col>
          <Col md={2} xs={4}>
            <h6 className="text-light">Company</h6>
            <div><a href="https://primary.health/#about" className="text-secondary">About</a></div>
            <div><a href="https://primary.health/news" className="text-secondary">News</a></div>
            <div><a href="https://primary.health/careers" className="text-secondary">Careers</a></div>
            <div><a href="https://primary.health/research" className="text-secondary">Research</a></div>
            <div><a href="https://primary.health/contact" className="text-secondary">Contact</a></div>
          </Col>
          <Col md={2} xs={4}>
            <h6 className="text-light">Services</h6>
            <div><a href="https://primary.health/#product" className="text-secondary">Product</a></div>
            <div><a href="https://primary.health/#client" className="text-secondary">Clients</a></div>
            <div><a href="https://primary.health/schools" className="text-secondary">Schools</a></div>
            <div><a href="https://primary.health/#features" className="text-secondary">Features</a></div>
          </Col>
          <Col md={3} xs={4}>
            <h6 className="text-light">Software</h6>
            <div><a href="https://primary.health/terms-of-service" className="text-secondary">Terms of Service</a></div>
            <div><a href="https://primary.health/privacy-policy" className="text-secondary">Privacy Policy</a></div>
            <div><a href="https://my.primary.health/sessions/new" className="text-secondary">Administrator Login</a></div>
            <div><a href="https://primary.health/accessibility-statement" className="text-secondary">Accessibility Policy</a></div>
          </Col>
        </Row>
    </div>
    <div style={{backgroundColor: "#1F1F1F"}} className="text-secondary p-3">
      <Container>
        <div className="text-end">Primary Diagnostics © 2021. All rights reserved.</div>
      </Container>
    </div>
  </>
)

export default Footer;
