import axios from 'axios';
import React, { useEffect, useReducer } from 'react';

import SelectableTable from './components/SelectableTable';
import Toolbar from './components/Toolbar';

import {
  pushQueryParam,
  turbolinksOnPopState,
  updateQueryParams,
} from '../common/utils/history';
import TablePagination from '../common/components/TablePagination';
import useRefetchable from '../common/hooks/useRefetchable';
import { camelToSnakeCase } from '../common/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      const snakeCaseKey = camelToSnakeCase(action.key);
      if (action.value) {
        updateQueryParams(
          {
            updateValues: { [snakeCaseKey]: action.value },
            deleteKeys: ['page'],
          },
          { turbolinks: {} },
        );
      } else {
        updateQueryParams(
          {
            deleteKeys: [snakeCaseKey, 'page'],
          },
          { turbolinks: {} },
        );
      }

      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case 'RESET_FILTERS':
      updateQueryParams(
        {
          deleteKeys: [
            ...Object.keys(state.filters).map((key) => camelToSnakeCase(key)),
            'page',
          ],
        },
        { turbolinks: {} },
      );

      return {
        ...state,
        page: 1,
        filters: { organizationId: null, status: null, q: null },
      };
    case 'SET_PAGE':
      pushQueryParam('page', action.page, { turbolinks: {} });

      return {
        ...state,
        page: action.page,
      };
    case 'SET_IN_BULK_EDIT':
      return {
        ...state,
        inBulkEdit: action.value,
        selectedTestGroupIds: [],
      };

    case 'SET_SELECTED_TEST_GROUPS':
      return {
        ...state,
        selectedTestGroupIds: action.value,
      };
    default:
      return state;
  }
};

const fetchData = async () =>
  axios
    .get(`${window.location.pathname}.json`, {
      params: Object.fromEntries(
        new URL(window.location.href).searchParams.entries(),
      ),
    })
    .then((response) => response.data);

const Index = ({
  testGroups: testGroupsProp,
  meta: metaProp,
  organizations,
  filters,
  user,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    filters,
    page: metaProp.currentPage,
    inBulkEdit: false,
    selectedTestGroupIds: [],
  });

  const [{ testGroups, meta }, loading] = useRefetchable(
    fetchData,
    { testGroups: testGroupsProp, meta: metaProp },
    [state.page, state.filters],
  );

  const setPage = (page) => {
    dispatch({ type: 'SET_PAGE', page });
    document.getElementById('students-index-table').scrollIntoView();
  };

  useEffect(() => {
    window.addEventListener('popstate', turbolinksOnPopState);

    return () => {
      window.removeEventListener('popstate', turbolinksOnPopState);
    };
  }, []);

  return (
    <React.Fragment>
      <Toolbar
        user={user}
        filters={state.filters}
        organizations={organizations}
        testGroupsCount={meta.totalCount}
        updateFilter={(key, value) =>
          dispatch({ type: 'SET_FILTER', key, value })
        }
        resetFilters={() => dispatch({ type: 'RESET_FILTERS' })}
        setBulkEditEnabled={(enabled) =>
          dispatch({ type: 'SET_IN_BULK_EDIT', value: enabled })
        }
        bulkEditEnabled={state.inBulkEdit}
        selectedTestGroups={state.selectedTestGroupIds}
      />
      <SelectableTable
        user={user}
        testGroups={testGroups}
        loading={loading}
        bulkEditEnabled={state.inBulkEdit}
        selectedTestGroupIds={state.selectedTestGroupIds}
        setSelectedTestGroupIds={(testGroupIds) =>
          dispatch({ type: 'SET_SELECTED_TEST_GROUPS', value: testGroupIds })
        }
      />
      <TablePagination {...meta} setPage={setPage} />
    </React.Fragment>
  );
};

export default Index;
