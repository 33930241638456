import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { languageObject } from "../../common/utils";
import QRCode from 'qrcode';

const PreviewWrapper = styled.div`
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
  height: 635px;
  width: 491px;
  background-image: url(https://primarybio-public.s3.us-east-2.amazonaws.com/registration-pdf-bg-v2${
    props => props.displayTranslations ? '' : '-no-contact-info-no-translation'
  }.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  -webkit-print-color-adjust: exact;

  .logo {
    position: absolute;
    height: 8mm;
    width: auto;
    top: 2mm;
    left: 5mm;
  }

  .test-group-name {
    position: absolute;
    top: 19mm;
    left: 6mm;
    color: white;
    font-size: 22px;
    text-align: center;
    width: 90%;
  }

  .long-name {
    top: 16mm;
    font-size: 19px;
  }

  .open {
    position: absolute;
    top: 67mm;
    color: #667084;
    font-size: 10px;
    width: 119px;
    left: 8mm;
  }

  .point {
    position: absolute;
    top: 67mm;
    color: #667084;
    font-size: 10px;
    width: 119px;
    left: 50mm;
  }

  .complete {
    position: absolute;
    top: 67mm;
    color: #667084;
    font-size: 10px;
    width: 119px;
    left: 91mm;
  }

  .qr-code {
    position: absolute;
    width: 100%;
    text-align: center;
    top: ${props => props.displayTranslations ? '93mm' : '83mm'};
  }

  .url {
    display: block;
    font-size: 12px;
  }

  .help-container {
    position: absolute;
    bottom: 8mm;
    width: 100%;
    padding-bottom: 4px;
    color: #FFFFFF;
  }
`;

const PdfPreview = ({ locale, testGroup, qrCodeUrl }) => {
  const { t } = useTranslation();
  const qrRef = useRef(null);
  const displayTranslations = !!locale && locale !== 'en';
  const previewText = displayTranslations
    ? `English to ${locale} (${languageObject[locale]}) translation preview`
    : !!locale
      ? "English"
      : "Preview unavailable";

  useEffect(() => {
    QRCode.toCanvas(
      qrRef.current,
      qrCodeUrl,
      { width: 141 },
      function (error) {
        if (error) console.log('Error', error);
      },
    );
  }, []);

  return(
    <div className="w-100 w-md-50 gray-light-100 p-3" style={{minHeight:"638px"}}>
      <h6 className="text-muted mb-3 d-print-none">{previewText}</h6>
      <PreviewWrapper
        displayTranslations={displayTranslations}
        hidden={!locale}
      >
        { testGroup.partner_logo_url &&
          <img
            src={testGroup.partner_logo_url}
            className="logo"
          />
        }
        <div className={`test-group-name ${testGroup.name.length > 39 && "long-name"}`}>{testGroup.name}</div>
        {displayTranslations && (
          <>
            <div className="open">{ t('registration_flyer.open_camera') }</div>
            <div className="point">{ t('registration_flyer.point_to_qr_code') }</div>
            <div className="complete">{ t('registration_flyer.complete_registration') }</div>
          </>
        )}
        <div className="qr-code">
          <canvas ref={qrRef} />
          <a href={qrCodeUrl} className="url">{qrCodeUrl}</a>
        </div>
      </PreviewWrapper>
    </div>
  )
}

export default PdfPreview;
