import React from 'react';
import axios from 'axios';
import CSVImporter from './components/CSVImporter';

const App = ({fields, submitUrl}) => {
  const onCompleteWithSubmitUrl = (data) => {
    axios.post(submitUrl, {data: data}).then((response) => {
      console.log(response)
    });
  }
  return (
    <div>
      <CSVImporter
        fields={fields}
        onComplete={onCompleteWithSubmitUrl}
      />
    </div>
  )
};

export default App;
