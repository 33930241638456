import React, { useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChrome,
  faFirefoxBrowser,
  faSafari,
} from '@fortawesome/free-brands-svg-icons';
import ModalHeader from '../../common/components/ModalHeader';

const IEModal = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader
        closeButton
        onHide={handleClose}
        className="flex justify-content-center"
      >
        <Modal.Title as="h2">{t('registration.iemodal.title')}</Modal.Title>
      </ModalHeader>

      <Modal.Body className="flex flex-column text-center">
        <p className="mb-3">{t('registration.iemodal.body')}</p>
        <Row>
          <Col sm className="my-3">
            <a href="https://www.google.com/chrome/" target="_blank">
              <FontAwesomeIcon icon={faChrome} size="4x" color="green" />
              <p className="mt-3">Chrome (51+)</p>
            </a>
          </Col>
          <Col sm className="my-3">
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFirefoxBrowser}
                size="4x"
                color="orange"
              />
              <p className="mt-3">Firefox (54+)</p>
            </a>
          </Col>
          <Col sm className="my-3">
            <a
              href="https://support.apple.com/downloads/safari"
              target="_blank"
            >
              <FontAwesomeIcon icon={faSafari} size="4x" color="blue" />
              <p className="mt-3">Safari (10+)</p>
            </a>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default IEModal;
