/* jshint esversion: 6 */

import React, { useContext } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import RegistrationContext from '../contexts/RegistrationContext';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 576px) {
    position: sticky;
    background-color: white;
    margin: 0px -24px;
    padding: 18px 12px;
    box-shadow: 0px -4px 4px rgba(33, 37, 41, 0.1);
    bottom: 0;
    z-index: 10;

    button {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 40%;
    }
  }
`;

const FormActions = ({ onSubmitText }) => {
  const { t, i18n } = useTranslation();
  const {
    testGroup,
    isSubmitting,
    submitButtonDisabled,
    currentRegistrationStep,
    setCurrentRegistrationStep,
    sectionsLength,
    localFormState,
  } = useContext(RegistrationContext);
  const finalStep = currentRegistrationStep + 1 === sectionsLength,
    customContinueKey = localFormState?.continueKey && localFormState.continueKey[currentRegistrationStep],
    buttonText = finalStep
      ? onSubmitText
      : t(customContinueKey ? customContinueKey : 'registration.continue_button');
  return (
    <ActionsWrapper
      className="form-actions form-navigation"
      aria-live="polite"
      aria-busy={isSubmitting}
      aria-label={isSubmitting ? 'Submitting' : ''}
    >
      {currentRegistrationStep > 0 && (
        <Button
          variant="link"
          className='ms-auto'
          onClick={() =>
            setCurrentRegistrationStep(currentRegistrationStep - 1)
          }
        >
          {t('registration.back_button')}
        </Button>
      )}
      {!localFormState?.hideNextButton && (
        <Button
          data-test-hook="submit"
          type="submit"
          variant='custom'
          style={{
            '--custom-color-focus-visible': "#007bff50",
            '--custom-color-hover': "#007bffb3",
          }}
          className={currentRegistrationStep === 0 ? "ms-auto" : undefined}
          disabled={submitButtonDisabled}
        >
          { isSubmitting
            ? <Spinner animation="border" variant='light' aria-live="polite" />
            : buttonText
          }
        </Button>
      )}
    </ActionsWrapper>
  );
};

export default FormActions;
