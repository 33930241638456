import React from 'react';
import { Image } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const StepFour = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mt-3 py-3 instruction-sub-step-title">
        {t('public_test_groups.demo.instructions.step_four_title')}
      </h3>
      <ol type="A">
        <li>
          <ReactMarkdown
            source={t('public_test_groups.demo.instructions.step_four_text_1')}
          />
        </li>
      </ol>
      <div
        style={{
          border: '1px solid #DADADA',
          borderRadius: 5,
          height: 285,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#FFFFFF",
        }}
      >
        <div>
          <Image src="/images/public_test_groups/instructions/step_four_a.svg" />
        </div>
      </div>
      <ol type="A" start="2" className="mt-3">
        <li>
          <ReactMarkdown
            source={t('public_test_groups.demo.instructions.step_four_text_2')}
          />
          <ReactMarkdown
            source={t('public_test_groups.demo.instructions.step_four_text_3')}
          />
        </li>
      </ol>
      <div
        style={{
          border: '1px solid #DADADA',
          borderRadius: 5,
          height: 285,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#FFFFFF",
        }}
      >
        <div>
          <Image src="/images/public_test_groups/instructions/step_four_b.svg" />
        </div>
      </div>
    </div>
  );
};

export default StepFour;
