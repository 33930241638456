import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
`;

export const Col = styled.div`
  max-width: 100%;
  flex: 1;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
`;

export const AutoCol = styled.div`
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
`;

interface EvenlySpacedRowProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const EvenlySpacedRow: FC<EvenlySpacedRowProps> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

export default EvenlySpacedRow;
