import React, { useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Button } from "react-bootstrap";

const ButtonSelect = ({
  onChange,
  options,
  selectedLabel,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.text_color,
      cursor: 'pointer',
    }),
  };

  const CustomControl = props => {
    return (
      <Button
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        variant="outline-tertiary"
      >
        {selectedLabel}
        <FontAwesomeIcon
          className="ms-3"
          icon={props.selectProps.menuIsOpen ? faChevronUp : faChevronDown}
        />
      </Button>
    );
  };

  const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div ref={ref}>
      <Select
        onChange={onChange}
        options={options}
        styles={customStyles}
        components={{
          Control: CustomControl,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        menuIsOpen={isOpen}
      />
    </div>
  );
}

export default ButtonSelect;
