import React, { useContext, useEffect } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import LocalizedMarkdown from './LocalizedMarkdown';
import { Row, Col } from 'react-bootstrap';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';
import { buildCustomizedLabel } from '../../../../common/locales/langs';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import ErrorMessage from '../../../../common/components/ErrorMessage';
import RegistrationContext from '../../../contexts/RegistrationContext';
import DefaultInformation from '../DefaultInformation';
import AutocompleteLocation from '../../../../PrimaryDesignSystem/AutocompleteLocation';
import { onAutocompleteChange } from '../utils';

const ADDRESS_FIELDS = ["address_1", "address_2", "city", "country", "county", "postal_code", "state"];

const ContactInformationFields = ({values, errors, setFieldValue, stateFieldRef}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);
  const defaultAddress = testGroup.default_user_fields.mailing_address;
  const isFamilyRegistration = !!values.appointment?.appointment_slots_by_dates;
  const handleValueChange = (e) => {
    const value = Primary.normalizeString(e.target.value);
    setFieldValue(e.target.name, value);
  }

  const usStateOptions = Object.keys(testGroup.us_states).map((key) => (
    {label: testGroup.us_states[key], value: key}
  ));

  const countryOptions = Object.keys(testGroup.countries).map((countryCode) => (
    { label: testGroup.countries[countryCode]["display"], value: countryCode }
  ));

  const setSameValuesAsFamilyMemberOne = () => {
    ADDRESS_FIELDS.forEach((field) => setFieldValue(field, values.family_member_one_address[field]));
  };
  const clearValues = () => {
    ADDRESS_FIELDS.forEach((field) => setFieldValue(field, ""));
  };
  const handleAutocompleteLocationChange = (e) => onAutocompleteChange(e, setFieldValue);

  if (isFamilyRegistration && !values["family_member_one_address"]) {
    setFieldValue("family_member_one_address", {
      "address_1": values.address_1,
      "address_2": values.address_2,
      "city": values.city,
      "country": values.country,
      "county": values.county,
      "postal_code": values.postal_code,
      "state": values.state,
    });
    clearValues();
  }
  useEffect(() => {
    ADDRESS_FIELDS.every((field) => {
      if (values["family_member_one_address"] && values[field] != values["family_member_one_address"][field]) {
        setFieldValue("use_same_address_as_family_member_one", false);
        return false;
      }
      return true;
    });
  }, [values.address_1, values.address_2, values.city, values.country, values.county, values.postal_code, values.state]);

  return (
    <React.Fragment>
      <label className="lead-20-medium inter bold">{t('location_details.labels.address')}</label>
      {isFamilyRegistration && !!values.family_member_one_address?.address_1 && (
        <div className="my-2">
          <FabrxCheckbox
            onChange={() => {
              const checked = values.use_same_address_as_family_member_one;
              !checked && setSameValuesAsFamilyMemberOne();
              setFieldValue("use_same_address_as_family_member_one", !checked);
            }}
            checked={values.use_same_address_as_family_member_one}
            label={"Address is the same as family member 1."}
          />
        </div>
      )}
      <Row className="mb-3" as="fieldset">
        <Col xs={12} lg={8} sm={8} md={8} className="mb-3">
          <AutocompleteLocation
            autoFocus={false}
            ariaLabel="home address"
            onChange={handleAutocompleteLocationChange}
            id="user-address-1"
            invalid={errors.address_1}
            isDisabled={values.use_default_test_group_address}
            label={t(buildCustomizedLabel(testGroup.population, 'registration.address')) + (testGroup.request_mailing_address ? " *" : '')}
            name="address_1"
            onFocus={() => $('#user-address-1').attr('autocomplete', 'new-password')}
            value={!values.use_default_test_group_address ? values.address_1 : ""}
          />
          {errors.address_1 && <ErrorMessage message={t(errors.address_1, { field: t('registration.address_1') })} />}
        </Col>

        <Col xs={12} lg={4} sm={4} md={4} className="mb-3">
          <FloatingLabelInput
            name="address_2"
            autoFocus={false}
            isDisabled={values.use_default_test_group_address}
            value={values.use_default_test_group_address ? "" : values.address_2 || ""}
            onChange={handleValueChange}
            ariaLabel="apt/suite #"
            ariaInvalid={!!errors.address_2}
            id="user-address-2"
            label={ t('registration.address_2') }
            validation={errors.address_2 && 'is-invalid'}
          />
        </Col>

        <Col xs={12} lg={4} sm={4} md={4} className="mb-3">
          <FloatingLabelInput
            name="city"
            autoFocus={false}
            isDisabled={values.use_default_test_group_address}
            value={values.use_default_test_group_address ? "" : values.city || ""}
            onChange={handleValueChange}
            ariaLabel="city"
            ariaInvalid={!!errors.city}
            id="user-city"
            label={ `${t('registration.address_city')} ${testGroup.request_mailing_address ? " *" : ''}` }
            validation={errors.city && 'is-invalid'}
          />
          {errors.city && <ErrorMessage message={t(errors.city, { field: t('registration.address_city') })} />}
        </Col>

        <Col xs={12} lg={4} sm={4} md={4} className="mb-3">
          { !values.non_us_address ? (
            <div>
              <FloatingLabelSelect
                id="user-state"
                isDisabled={values.use_default_test_group_address}
                inputRef={stateFieldRef}
                name="state"
                ariaLabel="state select, required"
                value={values.use_default_test_group_address ? null : usStateOptions.filter(option => option.value === values.state)}
                onChange={(s) => {
                  setFieldValue("state", s.value)
                }}
                className={`test-user-state ${errors.state && 'is-invalid'}`}
                options={usStateOptions}
                components={{ IndicatorSeparator: () => null }}
                filledValue={values.use_default_test_group_address ? "" : values.state}
                label={`${t('registration.address_state')} ${testGroup.request_mailing_address ? " *" : ''}`}
              />
              {errors.state && <ErrorMessage message={t(errors.state, { field: t('registration.address_state') })} />}
            </div>
          ) : (
            <div>
              <FloatingLabelSelect
                id="user-country"
                name="country"
                isDisabled={values.use_default_test_group_address}
                ariaLabel="country select, required"
                value={values.use_default_test_group_address ? null : countryOptions.filter(option => option.value === values.country)}
                onChange={(s) => {
                  setFieldValue("country", s.value)
                }}
                options={countryOptions}
                components={{ IndicatorSeparator: () => null }}
                filledValue={values.use_default_test_group_address ? "" : values.country}
                label={`${t('registration.address_country')} ${testGroup.request_mailing_address ? " *" : ''}`}
                className={errors.state && 'is-invalid'}
              />
              {errors.country && <ErrorMessage message={t(errors.country, { field: t('registration.address_country') })} />}
            </div>
          )}
        </Col>

        <Col xs={12} lg={4} sm={4} md={4} className="mb-3">
          <FloatingLabelInput
            name="postal_code"
            autoFocus={false}
            isDisabled={values.use_default_test_group_address}
            value={values.use_default_test_group_address ? "" : values.postal_code || ""}
            onChange={handleValueChange}
            ariaLabel="postal code"
            ariaInvalid={!!errors.postal_code}
            id="user-postal-code"
            label={
              t('registration.postal_code') +
              (testGroup.request_mailing_address
                ? testGroup.address_type === "us_address" || !values.non_us_address
                  ? " *"
                  : ""
                : ""
              )
            }
            validation={errors.postal_code && 'is-invalid'}
          />
          {errors.postal_code && <ErrorMessage message={t(errors.postal_code, { field: t('registration.postal_code') })} />}
        </Col>
      </Row>
      { testGroup.address_type === "global" &&
        <FabrxCheckbox
          name="non_us_address"
          onChange={checked => setFieldValue("non_us_address", !values.non_us_address)}
          checked={values.non_us_address || false}
          label={t("registration.non_us_address")}
        />
      }
      { testGroup.has_default_mailing_address &&
        <FabrxCheckbox
          name="default_mailing_address"
          checked={values.use_default_test_group_address}
          onChange={() => {
            if (values.use_default_test_group_address) {
              setFieldValue('address_1', "");
              setFieldValue('city', "");
              setFieldValue('state', "");
              setFieldValue('county', "");
              setFieldValue('postal_code', "");
            } else {
              setFieldValue('address_1', defaultAddress.address_1?.trim());
              setFieldValue('city', defaultAddress.city?.trim());
              setFieldValue('state', defaultAddress.state?.trim());
              setFieldValue('county', defaultAddress.county?.trim());
              setFieldValue('postal_code', defaultAddress.postal_code?.trim());
            }
            setFieldValue("use_default_test_group_address", !values.use_default_test_group_address)
          }}
          label={t('registration.decline_address')}
        />
      }
      {values.use_default_test_group_address && <DefaultInformation />}
      { errors.addressDoesNotQualify &&
        <div className="mb-4">
          <LocalizedMarkdown container={testGroup} stringKey='location_failed_text' />
        </div>
      }
    </React.Fragment>
  );
};

export default ContactInformationFields;
