import React, { useContext, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';
import HelpText from '../../../../common/components/HelpText';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import { isMobile } from '../../../../common/utils/browser';
import { PersonalFieldDropdown } from '../../SectionComponents';

const SexAtBirthDropdown = ({
  setFieldValue,
  values,
  attributeString = '',
  context = RegistrationContext,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(context);
  const label = buildLocalizedString(testGroup, 'sex_at_birth_label');
  const fieldKey = `${attributeString}sex_at_birth`;
  const required = testGroup.required_attributes.sex_at_birth;

  const sexOptions = testGroup.sexes.map((sex) => ({
    label: t(`sex_at_birth.${sex}`),
    value: sex,
  }));

  return (
    <PersonalFieldDropdown
      helpText={
        buildLocalizedString(testGroup, 'sex_at_birth_help_text') ? (
          <LocalizedMarkdown
            container={testGroup}
            stringKey="sex_at_birth_help_text"
          />
        ) : (
          <span>{t('sex_at_birth.help')}</span>
        )
      }
    >
      <FloatingLabelSelect
        isSearchable={!isMobile}
        value={sexOptions.find((so) => so.value === values[fieldKey])}
        onChange={(sexOpt) => setFieldValue(fieldKey, sexOpt.value)}
        options={sexOptions}
        filledValue={!!values[fieldKey]}
        name="sex_at_birth"
        label={
          label
            ? `${label} ${required ? '*' : '(Optional)'}`
            : `${t('sex_at_birth.question')}  ${required ? '*' : '(Optional)'}`
        }
      />
    </PersonalFieldDropdown>
  );
};

export default SexAtBirthDropdown;
