import React, { FC } from 'react';

export interface HeaderProps<RecordType> {
  name: string;
  field?: keyof RecordType;
  HeaderComponent?: FC<{}>;
  CellComponent?: FC<RecordType>;
  cellStyle?: React.CSSProperties;
  cellClassName?: string;
  colSpan?: number;
}

const Header = <RecordType,>({
  name,
  colSpan,
  HeaderComponent,
}: HeaderProps<RecordType>) => (
  <th colSpan={colSpan}>{HeaderComponent ? <HeaderComponent /> : name}</th>
);

export default Header;
