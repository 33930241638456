import React, { HTMLProps, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: relative;

  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 10;
  }

  & > * :not(.overlay-text) {
    opacity: 0.85;
  }
`;

interface LoadingOverlayProps {
  loading: boolean;
  overlayStyle?: HTMLProps<HTMLDivElement>;
  loadingText?: string;
}

const LoadingOverlay = ({
  loading,
  overlayStyle,
  loadingText = 'Loading...',
  children,
}: PropsWithChildren<LoadingOverlayProps>) =>
  loading ? (
    <Overlay style={overlayStyle}>
      {loading && (
        <span className="overlay-text">
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          ></span>{' '}
          {loadingText}
        </span>
      )}
      {children}
    </Overlay>
  ) : (
    children
  );

export default LoadingOverlay;
