import React, { useContext } from "react";
import OtcContext from "../contexts/OtcContext";
import { Image } from "react-bootstrap";
import ErrorMessage from "../../common/components/ErrorMessage";
import OldDateOfBirthInput from "../../common/components/OldDateOfBirthInput";
import { useTranslation } from "react-i18next";
import { createContext } from "react";
import FloatingLabelInput from "../../common/components/FloatingLabelInput";
export const VerifyDateOfBirthContext = createContext({});

export default function OtcVerifyDateOfBirth() {
  const {
    t,
    dateOfBirth,
    setDateOfBirth,
    firstName,
    activeErrors,
    flow,
    sections,
    allowSelfResulting,
    testConfiguration,
    testGroup,
    isArchived,
  } = useContext(OtcContext);

  const stepsArray = sections.filter(section => section.description);

  const resultsSchemaMainImage = () =>
    testConfiguration.results_schema?.otc?.images?.main;

  const mainImageUrl = {
    "self_checkouts": "/images/abbott/abbott_box_with_frame.png",
    "general_self_checkouts": "/images/all_rapid_boxes_with_frame.png",
    "self_admin": "/images/self_admin_logo_with_frame.png",
  }

  const resultsSchemaTestName = () =>
    testConfiguration.results_schema?.otc && t("otc.record_results_for_generic", {name: testConfiguration.results_schema.label})

  const testNames = {
    "self_checkouts": t("otc.record_results_for_abbott"),
    "general_self_checkouts": t("otc.record_results"),
    "self_admin": t("otc.record_results"),
  }
  const imageUrl = testConfiguration.logo_url || resultsSchemaMainImage() || mainImageUrl[flow];
  const imageClassName = "text-center";
  const imageStyle = {maxHeight: "310px"}
  const name = allowSelfResulting
    ? (resultsSchemaTestName() || testNames[flow])
    : (["general_self_checkouts"].includes(flow)
    ? t("otc.activate_a_kit")
    : t("otc.activate_a_kit_for_test", {test: testConfiguration.display_name}));
  const title = t('otc.date_of_birth_question', { first_name: firstName });
  const subtitle = t('otc.confirm_dob_to_continue');
  const requestLastName = false;
  return (
    <VerifyDateOfBirthContext.Provider value={{
      name,
      dateOfBirth,
      setDateOfBirth,
      firstName,
      activeErrors,
      imageUrl,
      imageClassName,
      imageStyle,
      testGroup,
      description: (<ol>
        {stepsArray.map(step => <li key={step.name}>{step.description}</li>)}
      </ol>),
      title,
      subtitle,
      requestLastName,
      isArchived,
    }}>
      <VerifyDateOfBirth />
    </VerifyDateOfBirthContext.Provider>
  )
}

export function VerifyDateOfBirth() {
  const { t } = useTranslation();
  const {
    dateOfBirth,
    setDateOfBirth,
    lastName,
    setLastName,
    firstName,
    activeErrors,
    testGroup,
    imageUrl,
    imageClassName,
    imageStyle,
    name,
    description,
    title,
    subtitle,
    requestLastName,
    isArchived,
  } = useContext(VerifyDateOfBirthContext);

  return (
    <div>
      {imageUrl && (
        <div className={imageClassName}>
          <Image style={imageStyle} src={imageUrl} className="mb-4" rounded />
        </div>
      )}

      {name && <h5 className="mb-4">{name}</h5>}
      {description && (
        <div>
          <h6>{t("otc.todays_test_process")}</h6>
          {description}
          <hr />
        </div>
      )}

      <div className="mb-4">
        <h5>{title}</h5>
        <p>{subtitle}</p>
        {requestLastName && (
          <div className="my-4">
            <FloatingLabelInput
              id="lastName"
              name="lastName"
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              label={t('registration.last_name_label') + " *"}
              validation={
                activeErrors['incorrect_date_of_birth'] ? 'is-invalid' : ''
              }
            />
          </div>
        )}
        <div className="my-4">
          <OldDateOfBirthInput currentDate={dateOfBirth} setCurrentDate={setDateOfBirth} errors={activeErrors["incorrect_date_of_birth"]} isDisabled={isArchived}/>
          {activeErrors["incorrect_date_of_birth"] && (
            <ErrorMessage
              className="my-3"
              message={activeErrors["incorrect_date_of_birth"]}
            />
          )}
        </div>
      </div>
    </div>
  )
}
