import axios from 'axios';
import React from 'react';
import Importer from 'react-importer';

const toCsv = (data) => {
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(data[0])
  const csv = [
    header.join(','), // header row first
    ...data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n')
  return csv
}
//Add a way to only show the success once the on complete has been called

const StudentsImporter = ({ upload_url, import_params }) => {
  const { grades, ethnicities, races } = import_params;
  const ethnicityOptions = Object.keys(ethnicities)
  const raceOptions = Object.keys(races)
  const s = getComputedStyle(document.body);
  const theme = {
    colors: {
      primary: s.getPropertyValue('--bs-primary'),
      success: s.getPropertyValue('--bs-success'),
      secondary: s.getPropertyValue('--bs-secondary'),
      info: s.getPropertyValue('--bs-info'),
      warning: s.getPropertyValue('--bs-warning'),
      danger: s.getPropertyValue('--bs-warning'),
      light: s.getPropertyValue('--bs-light'),
      dark: s.getPropertyValue('--bs-dark'),
    }
  }

  return (
    <div className="my-5">
      <Importer
        theme={theme}
        fields={[
          {
            label: "Student ID",
            key: "student_id",
            validators: [
              { validate: "required" },
              { validate: "unique" },
            ]
          },
          {
            label: "Grade",
            key: "grade",
            validators: [
              { validate: "required" },
              { validate: "includes", values: grades }
            ]
          },
          {
            label: "Email",
            key: "email",
            validators: [
              { validate: "required" },
              { validate: "regex_matches", regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, error: "This is not a valid email" },
            ]
          },
          {
            label: "First name",
            key: "first_name",
            validators: [
              { validate: "required" },
            ]
          },
          {
            label: "Last name",
            key: "last_name",
            validators: [
              { validate: "required" },
            ],
          },
          {
            label: "Ethnicity",
            key: "ethnicity",
            validators: [
              { validate: "multi_includes", values: ethnicityOptions }
            ],
          },
          {
            label: "Race",
            key: "race",
            validators: [
              { validate: "multi_includes", values: raceOptions }
            ],
          },
          {
            label: "Address 1",
            key: "address_1",
          },
          {
            label: "Address 2",
            key: "address_2",
          },
          {
            label: "City",
            key: "city",
          },
          {
            label: "State",
            key: "state",
            transformers: [
              { transformer: "state_code" },
            ]
          },
          {
            label: "Postal code",
            key: "postal_code",
            validators: [
              { validate: "regex_matches", regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/, error: "This is not a valid postal code" },
            ]
          },
        ]}
        onComplete={async (data) => {
          const csv = toCsv(data);
          const fileName = "upload.csv";
          const fileType = "text/csv";
          const file = new File([csv], fileName, { type: fileType });
          const formData = new FormData();
          formData.append('data_import[import_type]', 'participants_import');
          formData.append('data_import[import_file]', file);
          formData.append('data_import[allow_updates]', true);
          formData.append('data_import[dry_run]', false);
          formData.append('data_import[notify_users]', false);


          await axios.post(
            upload_url,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        }}
      />
    </div>
  );
}

export default StudentsImporter;
