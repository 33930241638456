import React from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchPlaceholder = (props) =>
  components.DropdownIndicator && (
    <React.Fragment>
      <components.Placeholder {...props}>
        <FontAwesomeIcon className="me-2" icon="fa-solid fa-magnifying-glass" />
        {props.children}
      </components.Placeholder>
    </React.Fragment>
  );

export default SearchPlaceholder;
