import React from 'react';

const Location = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99064 16L5.61622 15.5507C5.56755 15.4923 5.51129 15.4301 5.44892 15.3611C5.35135 15.2533 5.23882 15.1289 5.117 14.9766C3.44462 13.1295 0 9.26053 0 5.99064C0 2.69579 2.69579 0 5.99064 0C9.28549 0 11.9813 2.69579 11.9813 5.99064C11.9813 9.16623 8.70621 12.8916 7.04558 14.7806L6.93916 14.9017C6.80516 15.0655 6.68005 15.2026 6.57441 15.3184L6.57438 15.3184C6.50292 15.3968 6.44037 15.4653 6.39002 15.5257L5.99064 16ZM5.99064 0.99844C3.24493 0.99844 0.99844 3.24493 0.99844 5.99064C0.99844 8.82216 4.24483 12.513 5.74215 14.2153L5.74216 14.2153L5.84087 14.3276C5.8908 14.3775 5.94072 14.4275 5.96568 14.4774C6.0156 14.4025 6.09048 14.3276 6.16536 14.2527L6.16537 14.2527C7.6131 12.6303 10.9828 8.83619 10.9828 5.99064C10.9828 3.24493 8.73635 0.99844 5.99064 0.99844ZM5.99064 8.36193C4.46802 8.36193 3.24493 7.13885 3.24493 5.61622C3.24493 4.0936 4.46802 2.87051 5.99064 2.87051C7.51326 2.87051 8.73635 4.0936 8.73635 5.61622C8.73635 7.13885 7.51326 8.36193 5.99064 8.36193ZM5.99064 3.86895C5.01716 3.86895 4.24337 4.64275 4.24337 5.61623C4.24337 6.5897 5.01716 7.36349 5.99064 7.36349C6.96412 7.36349 7.73791 6.5897 7.73791 5.61623C7.73791 4.64275 6.96412 3.86895 5.99064 3.86895Z"
      fill={color}
    />
  </svg>
);

export default Location;
