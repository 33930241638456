import React, { useState } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Row, Col } from 'react-bootstrap';
import ActionWrapper from './ActionWrapper';

const RadioOptions = ({title, subtitle, options, onClick}) => {
  const [value, setValue] = useState(null);
  const callToActionProps = {disabled: !value};
  if (onClick) {
    callToActionProps.onClick = () => onClick(value);
  } else {
    callToActionProps.href = value
  }

  return (
    <ActionWrapper
      backLink="/members/me"
      title={title}
      callToActionProps={callToActionProps}
      callToActionTitle="Submit"
    >
    {subtitle && <div className='mb-3'>{subtitle}</div>}
    {options.map((option, idx) => (
      <>
        <Row key={idx} onClick={() => setValue(option.value)} data-test-hook={option.name}>
          <Col>
            {option.name}
          </Col>
          <Col xs="auto">
          <div className="form-check form-check-rounded">
            <input
              className="form-check-input optional faux-radio"
              type="radio"
              checked={option.value == value}
            />
          </div>
          </Col>
        </Row>
        <hr/>
      </>
    ))}
    </ActionWrapper>
   
  );
};

export default RadioOptions;

