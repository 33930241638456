import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";

import LocalizedMarkdown from "../../Registration/components/registration/fields/LocalizedMarkdown";
import { SERVICE_SPECIFICATION_TYPES } from "./TestGroupTestConfigurationsIndex";
import TestGroupTestConfigurationSelector from "./TestGroupTestConfigurationSelector";
import { TestGroupTestConfigurationTitle } from "./TestGroupTestConfigurationForm";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";
import { fetchVaccineInfoSheetVersions, VaccineInfoSheetVersions } from "./VaccineTestGroupTestConfigurationInputFields";

export const fetchTestConfigurationOptions = async (dispatch, testGroupId) => {
  dispatch({ type: "setLoading", values: true });
  const response = await axios.get(`/test_groups/${testGroupId}/test_configuration_options`);
  dispatch({ type: "setTestConfigurationOptions", values: response.data });
  dispatch({ type: "setLoading", values: false });
};

const NewTestGroupTestConfigurationSelector = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  useEffect(() => {
    if (!state.testConfigurationOptions) fetchTestConfigurationOptions(dispatch, state.testGroupTestConfiguration.test_group_id);
  }, [state.testGroupTestConfiguration.test_group_id]);

  useEffect(() => {
    fetchVaccineInfoSheetVersions(dispatch, state.selectedTestConfiguration);
  }, [state.selectedTestConfiguration]);

  if (!state.testConfigurationOptions) return <span />;

  return (
    <div>
      <Card>
        <Card.Body>
          <h5 className="mb-4">New service</h5>
          <h6>Service:</h6>
          <Row>
            <Col xs={12} lg={5}>
              <TestGroupTestConfigurationSelector />
            </Col>
          </Row>
          {!!state.selectedTestConfiguration && (
            <div className="mt-5">
              <TestGroupTestConfigurationTitle
                checkoutName={state.selectedTestConfiguration?.checkout_name}
                selectedTestConfiguration={state.selectedTestConfiguration}
                ageRangelabel={state.selectedTestConfiguration?.age_range_label}
              />
              <div className="my-3">
                This is a {SERVICE_SPECIFICATION_TYPES[state.selectedTestConfiguration.service_specification_type].label_v2} for
                {state.selectedTestConfiguration.age_range_label == "All ages"
                  ? " all ages"
                  : ` participants aged ${state.selectedTestConfiguration.age_range_label}`
                }.
              </div>
              {state.selectedTestConfiguration.service_specification_type == "immunization" && !state.loading && (
                <div className="my-3">
                  {!!state.vaccineInfoSheetVersions?.length ? (
                    <>
                      <div className="mb-3">The vaccine information statement(s) for this vaccine:</div>
                      <VaccineInfoSheetVersions vaccineInfoSheetVersions={state.vaccineInfoSheetVersions} />
                    </>
                  ) : (
                    <>
                      There is no vaccine information statement pre-loaded in Primary for this service.
                      Please contact your client success manager to update for you.
                    </>
                  )}
                </div>
              )}
              <div className="my-3">
                <LocalizedMarkdown container={state.selectedTestConfiguration} stringKey="description" />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default NewTestGroupTestConfigurationSelector;