import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { faCircleMinus, faSyringe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatAndLocalizeDateTime } from '../../common/locales/utils';
import Icon from '../../common/components/AssetIcon';
import ScheduleAppointmentButton from './ScheduleAppointmentButton';

const NextAvailableAppointments = ({
  appointmentSlotsByDates,
  startOn,
  t,
}) => {
  const appointmentSlotDatesArray = Object.entries(
    appointmentSlotsByDates || [],
  )
    .filter(([date, data]) => {
      let selectValue;
      try {
        selectValue = startOn
          ? new Date(data.appointment_slots[0].localized_starts_at_date) >= new Date(startOn.format('YYYY-MM-DD'))
          : true
      } catch {
        selectValue = true
      }
      return selectValue;
    })
    .map(([date, data]) => {
      const localizedStartsAtDate = formatAndLocalizeDateTime(new Date(data.appointment_slots[0].localized_starts_at_date), 'eee, MM/dd');
      return { date: localizedStartsAtDate || date, count: data.count };
    })
    .slice(0, 4);
  return (
    <div className="mt-3 mb-2">
      <div className="body-medium-bold inter semibold">{t('family_registrations.next_available')}:</div>
      <Row>
        {appointmentSlotDatesArray.map((dwc, idx) => {
          return (
            <Col
              key={idx}
              xs={2}
              className="me-3 my-2 text-muted text-nowrap text-center"
            >
              <div className="body-medium-normal">{dwc.date}</div>
              <div className="body-medium-normal inter regular">{dwc.count} appts</div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const LocationCard = ({
  active,
  appointmentSlotGroup,
  dispatch,
  matchType,
  showAvailableServices,
  setSelectedAppointmentSlotGroup,
  state,
  t,
}) => {
  const { selectedServiceSpecifications, serviceSpecifications } = state;
  const appointmentSlotsByDates = appointmentSlotGroup.appointment_slots_by_dates;
  const [cardClicked, setCardClicked] = useState(false);
  const matchedServices = selectedServiceSpecifications.
    filter(service =>
      !!appointmentSlotGroup.test_configurations.find(x => x.service_specification === service)
    ).
    map(service => serviceSpecifications.find(x => x.key === service).label);
  const availableServices = serviceSpecifications.filter(service =>
    appointmentSlotGroup.test_configurations.find(tc =>
      [tc.age_grouped_vaccine_service_specification, tc.service_specification].includes(service.key)
    )
  ).map(service => service.label);
  const missingServices = selectedServiceSpecifications.
    filter(service =>
      !appointmentSlotGroup.test_configurations.find(x => x.service_specification === service || x.age_grouped_vaccine_service_specification === service)
    ).
    map(service => serviceSpecifications.find(x => x.key === service).label);
  useEffect(() => {
    if (!active) setCardClicked(false);
  }, [active]);
  return (
    <div>
      <Card
        id={`appointment_slot_group_${appointmentSlotGroup.id}`}
        className={`p-3 mb-2 pointer ${
          active ? 'active-location-card' : undefined
        }`}
        onMouseEnter={() =>
          setSelectedAppointmentSlotGroup(appointmentSlotGroup)
        }
        onMouseLeave={() =>
          !cardClicked && setSelectedAppointmentSlotGroup(null)
        }
        onClick={() =>
          setCardClicked(!cardClicked)
        }
      >
        <h6 className="inter bold">{appointmentSlotGroup.title}</h6>
        <div className="body-medium-normal inter regular text-muted">
          <Icon
            className="me-1"
            path="/images/icons/icon-location-marker.svg"
            style={{ width: 14 }}
          />
          {appointmentSlotGroup.test_location.address_line_1}, {appointmentSlotGroup.test_location.city}
          {" • "}
          {appointmentSlotGroup.distance_from &&
            t('family_registrations.miles_shorten', { miles: appointmentSlotGroup.distance_from })
          }
        </div>
        {matchType === "partial" && (
          <div className="my-2">
            <div className="body-medium-bold inter semibold">{t('family_registrations.unavailable_vaccines')}</div>
            {missingServices.map(service =>
              <span className='me-2 body-medium-normal inter regular'>
                <FontAwesomeIcon icon={faCircleMinus} className='fa-flip-horizontal text-danger' />{' '}
                {t(service)}
              </span>
            )}
          </div>
        )}
        {showAvailableServices && !!availableServices.length && (
          <div className="my-2">
            <div className="body-medium-bold inter semibold">{t('family_registrations.available_vaccines')}</div>
            {availableServices.map(service =>
              <span className='me-2 body-medium-normal inter regular'>
                <FontAwesomeIcon icon={faSyringe} />{' '}
                {t(service)}
              </span>
            )}
          </div>
        )}
        {!appointmentSlotGroup.test_location.on_demand && (
          <NextAvailableAppointments
            appointmentSlotsByDates={appointmentSlotsByDates}
            startOn={state.formState.startOn}
            t={t}
          />
        )}
        <div className="my-2">
          <ScheduleAppointmentButton
            bordered={false}
            appointmentSlotGroup={appointmentSlotGroup}
            dispatch={dispatch}
          />
        </div>
      </Card>
    </div>
  );
};

export default LocationCard;
