import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { faExclamationTriangle, faCheck} from '@fortawesome/pro-regular-svg-icons';

import {
  InputWithUnits,
  LabeledField,
  ResultStatus,
  SaveButton,
  ServiceCardSubHeader,
} from "../ServiceCard";
import {
  calculateBmi,
  convertHeighInFeetAndInchesToHeightInInches,
  convertHeighInInchesToHeightInFeetAndInches,
  getBmiStatus,
  handleGenericTestResultChange,
  idFromGenericTestResult,
  isValidInteger,
  resetGenericTestResultAttributes,
  valueFromGenericTestResult
} from "../utils";

const VALIDATIONS = {
  feet: {
    min: 0,
    max: 9,
  },
  inches: {
    min: 0,
    max: 12,
  },
  weight: {
    min: 1,
    max: 2000,
  },
  pulse: {
    min: 1,
    max: 300,
  },
};

const BMI_STATUSES = {
  underweight: {
    color: "#D14110",
    icon: faExclamationTriangle,
  },
  normal: {
    color: "#048154",
    icon: faCheck,
  },
  overweight: {
    color: "#D14110",
    icon: faExclamationTriangle,
  },
  obese: {
    color: "#CB2531",
    icon: faExclamationTriangle,
  },
};

const BMIDisplayComponent = ({
  height,
  test,
  updateTest,
  value,
  weight,
}) => {
  const bmi = calculateBmi(weight, height);
  if (!!bmi && bmi != value) handleGenericTestResultChange({ target: { name: "bmi", value: bmi } }, test, updateTest);
  const status = getBmiStatus(bmi);

  return (
    <div className="d-flex">
      <span className="poppins" style={{ fontSize: "36px" }}>
        {bmi ? bmi : "--"}
      </span>
      {!!status && (
        <ResultStatus
          className="my-auto ms-4"
          color={BMI_STATUSES[status]["color"]}
          icon={BMI_STATUSES[status]["icon"]}
          status={status}
        />
      )}
    </div>
  );
};

const VitalsScreeningCardBody = ({
  test,
  submitTests,
  updateTest,
  setIsOpen,
}) => {

  const [heightInFeet, setHeightInFeet] = useState(null);
  const [heightInInches, setHeightInInches] = useState(null);
  const height = valueFromGenericTestResult(test, "height");
  const pulse = valueFromGenericTestResult(test, "pulse");
  const weight = valueFromGenericTestResult(test, "weight");
  const saveButtonDisabled = !heightInFeet || !heightInInches || !height || !weight || !pulse;
  const [errors, setErrors] = useState({});
  const [nonResetable, setNonResetable] = useState(!!idFromGenericTestResult(test, "height"));

  const handleSave = async () => {
    updateTest({...test, administered: true})

    const response = await submitTests([test]);
    if (response.data.success) {
      setNonResetable(true);
      setIsOpen(false);
      toastr.success("Vitals screening saved");
    } else {
      toastr.error("Something went wrong");
    }
  };

  const handleReset = () => {
    if (nonResetable) return;
    setHeightInFeet('');
    setHeightInInches('');
    resetGenericTestResultAttributes(test, updateTest);
  };

  const splitHeightIntoFeetAndInches = () => {
    if (!height || !!heightInFeet || !!heightInInches) return;
    const { feet, inches } = convertHeighInInchesToHeightInFeetAndInches(height);
    setHeightInFeet(feet);
    setHeightInInches(inches);
  };

  const joinHeightInFeetAndInches = () => {
    if (!heightInFeet && !heightInInches) return;
    const height = convertHeighInFeetAndInchesToHeightInInches(heightInFeet, heightInInches);
    handleGenericTestResultChange({ target: { name: "height", value: height } }, test, updateTest);
  };

  const validateField = (e) => {
    const { name, value } = e.target;
    const minValue = VALIDATIONS[name].min;
    const maxValue = VALIDATIONS[name].max;
    const newErrors = { ...errors };
    if (!value) {
      newErrors[name] = "Please enter a value";
    } else if (!isValidInteger(value) || parseInt(value) < minValue || parseInt(value) > maxValue) {
      newErrors[name] = `Please enter a value between ${minValue} and ${maxValue}`;
    } else {
      newErrors[name] = null;
    }
    setErrors({ ...newErrors });
  };

  const handleFieldChange = (e) => handleGenericTestResultChange(e, test, updateTest);

  // updates height prop in inches based on feet and inches
  useEffect(() => joinHeightInFeetAndInches(), [heightInFeet, heightInInches]);

  // fills feet and inches input fields based on height in inches
  useEffect(() => splitHeightIntoFeetAndInches(), [test]);

  return (
    <>
      <ServiceCardSubHeader
        containerClassName="my-3"
        handleLock={() => setIsOpen(false)}
        handleReset={handleReset}
        title="Vitals"
      />
      <Row className="my-4">
        <Col>
          <LabeledField label="Height">
            <div className="d-flex">
              <InputWithUnits
                error={errors.feet}
                inputContainerClassName="me-4"
                inputStyle={{ width: "140px" }}
                name="feet"
                onBlur={validateField}
                onChange={e => setHeightInFeet(e.target.value)}
                type="text"
                unit="ft"
                value={heightInFeet}
              />
              <InputWithUnits
                error={errors.inches}
                inputStyle={{ width: "140px" }}
                name="inches"
                onBlur={validateField}
                onChange={e => setHeightInInches(e.target.value)}
                type="text"
                unit="in"
                value={heightInInches}
              />
            </div>
           </LabeledField>
        </Col>
        <Col>
          <LabeledField
            error={errors.weight}
            inputStyle={{ width: "140px" }}
            label="Weight"
            name="weight"
            onBlur={validateField}
            onChange={handleFieldChange}
            type="text"
            unit="lbs"
            value={weight}
          />
        </Col>
        <Col>
          <LabeledField label="BMI">
            <BMIDisplayComponent
              height={valueFromGenericTestResult(test, "height")}
              test={test}
              updateTest={updateTest}
              value={valueFromGenericTestResult(test, "bmi")}
              weight={weight}
            />
          </LabeledField>
        </Col>
      </Row>
      <hr />
      <Row className="my-4">
        <Col xs={4}>
          <LabeledField
            error={errors.pulse}
            inputStyle={{ width: "140px" }}
            label="Pulse"
            name="pulse"
            onBlur={validateField}
            onChange={handleFieldChange}
            type="text"
            unit="BPM"
            value={pulse}
          />
        </Col>
      </Row>
      <hr />
      <Row className="my-4">
        <LabeledField
          as="textarea"
          label="Notes"
          onChange={(e) => {
            updateTest({ ...test, notes: e.target.value })
          }}
          type="text"
          value={test.notes}
        />
      </Row>
      <SaveButton
        disabled={saveButtonDisabled}
        displayRemove={!test.result}
        onClick={handleSave}
        slug={test.test_group.slug}
        testId={test.id}
      />
    </>
  );
};

export default VitalsScreeningCardBody;