import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import TestConfigurationActionsModal from "./TestConfigurationActionsModal";

const TestConfigurationActions = ({
  allowedTestGroups,
  selectedTestConfigurationIds,
  testConfigurations,
  totalCount,
}) => {

  const selectedCount = selectedTestConfigurationIds.length;
  const selectedTestConfigurations = testConfigurations.filter(tc => selectedTestConfigurationIds.includes(tc.id));
  const selectedActiveCount = selectedTestConfigurations.filter(tc => tc.active).length;
  const selectedInactiveCount = selectedTestConfigurations.filter(tc => !tc.active).length;
  const [filteredTestGroupIds, setFilteredTestGroupIds] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedTestGroupIds, setSelectedTestGroupIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    if (!!modalType) setShowModal(true);
  }, [modalType]);

  useEffect(() => {
    if (!showModal) {
      setModalType(null);
      setSelectedTestGroupIds([]);
    }
  }, [showModal]);

  const onModalHide = () => {
    setShowModal(false);
    setSearchInput("");
    filteredTestGroupIds([]);
  };

  return (
    <div>
      <TestConfigurationActionsModal
        allowedTestGroups={allowedTestGroups}
        filteredTestGroupIds={filteredTestGroupIds}
        modalType={modalType}
        onHide={onModalHide}
        searchInput={searchInput}
        selectedActiveCount={selectedActiveCount}
        selectedInactiveCount={selectedInactiveCount}
        selectedTestConfigurationIds={selectedTestConfigurationIds}
        selectedTestGroupIds={selectedTestGroupIds}
        setFilteredTestGroupIds={setFilteredTestGroupIds}
        setSearchInput={setSearchInput}
        setSelectedTestGroupIds={setSelectedTestGroupIds}
        show={showModal}
        testConfigurations={testConfigurations}
      />
      <div className="mt-3 d-flex justify-content-between">
        <div className="my-auto">
          <b>{selectedCount} of {totalCount} selected</b>
        </div>
        <div className="d-flex">
          {!!selectedActiveCount && (
            <Button
              className="mx-2"
              onClick={() => setModalType("deactivate")}
              variant="outline-danger"
            >
              Make {selectedActiveCount} inactive
            </Button>
          )}
          {!!selectedInactiveCount && (
            <Button
              className="mx-2"
              onClick={() => setModalType("activate")}
              variant="outline-success"
            >
              Make {selectedInactiveCount} active
            </Button>
          )}
          <Button
            className="ms-2"
            onClick={() => setModalType("addIntoTestGroups")}
          >
            Add {selectedCount} into groups
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestConfigurationActions;