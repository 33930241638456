import React from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Questionnaire } from '../../common/components/Icons/registration';
import { Col, Row } from 'react-bootstrap';
import TooltipWrapper from '../../common/components/TooltipWrapper';

const [PAST, CURRENT] = [0, 1];

const RegistrationTab = styled.button`
  box-sizing: border-box;
  display: flex;
  height: 60px;
  padding: 0;
  border-width: 0;
  background: #f7f9fc;
  --primary-color-0: #2862fa;
  --primary-color-1: #f7f9fc;
  --inactive-color: #3f3b3b;
  ${({withMobileCss}) => 
    withMobileCss && 
    css`
      @media (max-width: 576px) {
        background-color: inherit !important;
      } 
  `}
`;

const RegistrationDivider = styled.div`
  width: 100%;
  height: 2px;
  align-self: center;
  border-radius: 20px;
  border: 1px solid;
  transition: border-color 0.5s;
  @media (max-width: 576px) {
    display: none;
  }
  ${({ past }) =>
    past
      ? css`
          background-color: #2862fa;
          border-color: #2862fa;
        `
      : css`
          background-color: #aad0ff;
          border-color: #aad0ff;
        `}
`;

const RegistrationHeader = styled.div`
  ${({image}) =>
    image
      ? css`
        margin: 24px 0px;
      `
      : css`
        display: none;
      `
  } 
`;

const RegistrationTabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  text-align: start;
  padding: 0px 9px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  font-style: normal;
  color: var(--inactive-color);
  width: max-content;
  ${({ active }) =>
    active &&
    css`
      color: var(--primary-color-0);
      padding: 0px 25px;
      .icon {
        margin-right: 10px;
      }
    `}
  ${({ withMobileCss }) =>
    withMobileCss &&
    css`
      padding: 0px;
    `}
  div svg path:first-child {
    stroke-width: 1px;
    stroke: #aad0ff;
    ${({ active, past }) =>
      (active || past) &&
      css`
        stroke-width: 1px;
        stroke: #2862fa;
      `}
  }
`;

const RegistrationTabText = styled.div`
  width: 0px;
  transition: color 0.5s;
  color: transparent;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;

  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      width: max-content;
      color: inherit;
    `}
  }
`;

const SectionWrapper = styled.div`
  background: #F7F9FC;
  padding-bottom: 8px;
  display: flex;

  @media (max-width: 576px) {
    display: none;
  }
`;

const SectionTab = ({
  header,
  Icon = Questionnaire,
  index,
  currentRegistrationStep,
  state = CURRENT,
  primaryColor,
  isLast = false,
  onClick = () => {},
  withMobileCss = false,
}) => {
  const active = currentRegistrationStep === index && state === CURRENT;
  const past =
    state === PAST || (currentRegistrationStep > index && state === CURRENT);

  return (
    <>
      <RegistrationTab
        withMobileCss={withMobileCss}
        active={active}
        past={past}
        primaryColor={primaryColor}
        pointer={active || past}
        tabIndex={active || past ? 0 : -1}
        aria-label={`${header} page`}
        aria-selected={active}
        aria-current={active}
        role="tab"
        key={header}
        disabled={!past}
        onClick={onClick}
      >
        <RegistrationTabContent
          active={active}
          past={past}
          withMobileCss={withMobileCss}
        >
          {Icon && (
            <div className="icon">
              <TooltipWrapper
                tooltipContent={header}
                placement="top"
              >
                <Icon
                  height={24}
                  width={24}
                  color={active || past ? '#2862FA' : '#AAD0FF'}
                />
              </TooltipWrapper>
            </div>
          )}
          <RegistrationTabText active={active}>{header}</RegistrationTabText>
        </RegistrationTabContent>
      </RegistrationTab>
      {!isLast && <RegistrationDivider past={past} />}
    </>
  );
};


const NewSectionsSidebar = ({
  image = '',
  title = 'Registration',
  sections,
  additionalSections = [],
  setCurrentRegistrationStep = () => {},
  previousUrl = '',
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);

  const handleOnClick = (index, state) => (past) => {
    if (past && state === CURRENT) {
      setCurrentRegistrationStep(index);
    } else if (state === PAST) {
      urlParams.append('step', index);
      history.push(previousUrl + '&' + urlParams.toString());
    }
  };

  return (
    <>
     <RegistrationHeader image={image}>
        {image && (
          <Col xs="auto gx-0">
            <img src={image} height={64}></img>
          </Col>
        )}
        <Col xs={`${image ? 'auto gx-3' : ''}`} className="align-self-center">
          <h1 className="poppins regular mb-0">{t(title)}</h1>
        </Col>
      </RegistrationHeader>
      <SectionWrapper
        aria-label={`${title} steps`}
        role="tablist"
      >
        {sections.map(({ header, Icon }, index) => (
          <SectionTab
            key={index}
            header={t(header)}
            Icon={Icon}
            index={index}
            state={CURRENT}
            isLast={index == sections.length - 1}
            onClick={handleOnClick(index, CURRENT)}
            {...props}
          />
        ))}
      </SectionWrapper>
      <div className='d-sm-none'>
        <SectionTab
          withMobileCss
          header={t(sections[props.currentRegistrationStep].header)}
          Icon={sections[props.currentRegistrationStep].Icon}
          index={props.currentRegistrationStep}
          state={CURRENT}
          isLast
          {...props}
        /> 
      </div>
    </>
  );
};

export default NewSectionsSidebar;
