import React, { FC } from 'react';
import { FilterValue, ValueType } from './hooks/useFilters';

import Select, { CheckboxOption } from '../../../common/components/Select';

export interface DropdownOption {
  value: number | string;
  label: string;
}

interface FilterDropdownProps {
  id?: string;
  name?: string;
  label?: string;
  isMulti?: boolean;
  value?: ValueType;
  onChange?: (value: FilterValue) => void;
  options: DropdownOption[];
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  id,
  name,
  label,
  isMulti = false,
  onChange,
  value,
  options,
}) => {
  return isMulti ? (
    <Select
      isMulti
      value={value}
      options={options}
      onChange={onChange}
      blankLabel="Unassigned"
      placeholder={label || ''}
      components={{ Option: CheckboxOption }}
      controlShouldRenderValue={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      verticallyCenterCheckBox={true}
    />
  ) : (
    <div className="position-relative select optional search_result input-container select-container">
      {label && (
        <label className="form-label select optional" htmlFor={id}>
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        className="form-select form-select-lg select optional"
        value={value || ''}
        onChange={(e) => onChange && onChange(e.target.value)}
      >
        <option value=""></option>
        {options.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDropdown;
