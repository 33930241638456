import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { buildCustomizedLabel } from '../../../common/locales/langs';
import RegistrationContext from '../../contexts/RegistrationContext';
import FabrxCheckbox from '../../primary/FabrxCheckbox';
import { SectionTitle } from '../SectionComponents';
import AutocompleteLocation from '../../../PrimaryDesignSystem/AutocompleteLocation';
import { onAutocompleteChange } from './utils';

export const validateEmploymentInformation = (testGroup) => (values) => {
  const errors = {};
  if (!values.employment_status) errors.employment_status = 'registration.errors.required';
  if (values.employment_status === 'employed') {
    if (!values.employer_name) errors.employer_name = 'registration.errors.required';
    if (!values.employer_address_1) errors.employer_address_1 = 'registration.errors.required';
    if (!values.employer_city) errors.employer_city = 'registration.errors.required';
    if (!values.employer_state) errors.employer_state = 'registration.errors.required';
    if (!values.employer_postal_code) errors.employer_postal_code = 'registration.errors.required';
  }
  return errors;
}

const EmploymentInformation = ({ employmentStatuses, values, errors, handleChange, handleBlur, setFieldValue }) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  //useEffect(); with no dependencies to render map
  const employmentOptions = employmentStatuses.map ((employmentKey) => {
    return {
      value: employmentKey,
      label: t(`registration.employment_information.${employmentKey}`),
    }
  });
  const handleAutocompleteLocationChange= (e) => {
    onAutocompleteChange(e, setFieldValue, 'employer_', false)
  };

  return (
    <div className="form-section">
      <SectionTitle>{ t(buildCustomizedLabel(testGroup.population, 'registration.employment_information.label')) }</SectionTitle>
      <Row className='mt-4'>
        <Col lg={6}>
          <div className="select user_employment_status mb-3">
            <FloatingLabelSelect
              id="employment_status"
              value={employmentOptions.filter(option => option.value === values.employment_status)}
              onChange={(s) => setFieldValue('employment_status', s.value)}
              options={employmentOptions}
              filledValue={values.employment_status}
              label={t('registration.employment_information.label') + " *"}
              className={errors.employment_status && 'is-invalid'}
            />
            {errors.employment_status && <ErrorMessage message={t(errors.employment_status)} />}
          </div>
        </Col>
        <Col lg={6}></Col>
        {
          values.employment_status === 'employed' && (
            <React.Fragment>
              <Col xs={12} lg={6} md={6} sm={6} className="my-2">
                <div>
                  <FloatingLabelInput
                    name="employer_name"
                    value={values.employer_name || ""}
                    onChange={handleChange}
                    type="text"
                    autoComplete= "new-password"
                    label={t('registration.employment_information.company') + " *"}
                    validation={errors.employer_name && 'is-invalid'}
                    ariaLabel="employer_name"
                    ariaInvalid={!!errors.employer_name}
                  />
                  {errors.employer_name && <ErrorMessage message={t(errors.employer_name)} />}
                </div>
              </Col>

              <Col xs={12} lg={6} md={6} sm={6} className="my-2">
                <div className="tel">
                  <FloatingLabelInput
                    name="employer_phone_number"
                    value={values.employer_phone_number || ""}
                    onChange={handleChange}
                    autoComplete= "new-password"
                    type="tel"
                    label={t('registration.employment_information.phone_number')}
                    validation={errors.employer_phone_number && 'is-invalid'}
                    ariaLabel="employer_phone_number"
                    ariaInvalid={!!errors.employer_phone_number}
                  />
                  {errors.employer_phone_number && <ErrorMessage message={t(errors.employer_phone_number)} />}
                </div>
              </Col>

              <Col xs={12} lg={7} md={7} sm={6} className="my-2">
                <div>
                  <AutocompleteLocation
                    ariaLabel="employer address"
                    onChange={handleAutocompleteLocationChange}
                    id="employer-address-1"
                    invalid={errors.employer_address_1}
                    label={t('registration.employment_information.address_1') + " *"}
                    name="employer_address_1"
                    value={values.employer_address_1}
                  />
                  {errors.employer_address_1 && <ErrorMessage message={t(errors.employer_address_1)} />}
                </div>
              </Col>

              <Col xs={12} lg={5} md={5} sm={6} className="my-2">
                <div>
                  <FloatingLabelInput
                    name="employer_address_2"
                    value={values.employer_address_2 || ""}
                    onChange={handleChange}
                    id="employer-address-2"
                    autoComplete= "new-password"
                    label={t('registration.employment_information.address_2')}
                    validation={errors.employer_address_2 && 'is-invalid'}
                    ariaLabel="employer_address_2"
                    ariaInvalid={!!errors.employer_address_2}
                  />
                </div>
              </Col>

              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <div>
                  <FloatingLabelInput
                    name="employer_city"
                    value={values.employer_city || ""}
                    onChange={handleChange}
                    id="employer-city"
                    type="text"
                    autoComplete= "new-password"
                    label={t('registration.address_city') + " *"}
                    validation={errors.employer_city && 'is-invalid'}
                    ariaLabel="employer_city"
                    ariaInvalid={!!errors.employer_city}
                  />
                  {errors.employer_city && <ErrorMessage message={t(errors.employer_city)} />}
                </div>
              </Col>

              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <div>
                  <FloatingLabelInput
                    name="employer_state"
                    value={values.employer_state || ""}
                    onChange={handleChange}
                    id="employer-state"
                    type="text"
                    autoComplete= "new-password"
                    label={t('registration.address_state') + " *"}
                    validation={errors.employer_state && 'is-invalid'}
                    ariaLabel="employer_state"
                    ariaInvalid={!!errors.employer_state}
                  />
                  {errors.employer_state && <ErrorMessage message={t(errors.employer_state)} />}
                </div>
              </Col>

              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <div>
                  <FloatingLabelInput
                    name="employer_postal_code"
                    value={values.employer_postal_code || ""}
                    onChange={handleChange}
                    id="employer-postal-code"
                    type="text"
                    autoComplete= "new-password"
                    label={t('registration.employment_information.postal_code') + " *"}
                    validation={errors.employer_postal_code && 'is-invalid'}
                    ariaLabel="employer_postal_code"
                    ariaInvalid={!!errors.employer_postal_code}
                  />
                  {errors.employer_postal_code && <ErrorMessage message={t(errors.employer_postal_code)} />}
                </div>
              </Col>
              <Col xs={12} className="my-2">
                <FabrxCheckbox
                  name="reporting_supervisor"
                  ariaLabel="is reporting supervisor"
                  onChange={() => {
                    setFieldValue(
                      'reporting_supervisor',
                      !values.reporting_supervisor,
                    );
                  }}
                  checked={!!values.reporting_supervisor}
                  label={t(
                    'registration.employment_information.reporting_supervisor',
                  )}
                  labelClassName="mb-0"
                />
              </Col>
              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <FloatingLabelInput
                  name="reporting_department"
                  value={values.reporting_department || ""}
                  onChange={handleChange}
                  id="reporting-department"
                  type="text"
                  autoComplete= "new-password"
                  label={t('registration.employment_information.reporting_department')}
                  ariaLabel="reporting_department"
                />
              </Col>
              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <FloatingLabelInput
                  name="supervisor_name"
                  value={values.supervisor_name || ""}
                  onChange={handleChange}
                  id="supervisor-name"
                  type="text"
                  autoComplete= "new-password"
                  label={t('registration.employment_information.supervisor_name')}
                  ariaLabel="supervisor_name"
                />
              </Col>
              <Col xs={12} lg={4} md={4} sm={4} className="my-2">
                <FloatingLabelInput
                  name="supervisor_email"
                  value={values.supervisor_email || ""}
                  onChange={handleChange}
                  id="supervisor-email"
                  type="text"
                  autoComplete= "new-password"
                  label={t('registration.employment_information.supervisor_email')}
                  ariaLabel="supervisor_email"
                />
              </Col>
            </React.Fragment>
          )
        }
      </Row>
    </div>
  );
};

EmploymentInformation.propTypes = {
  employmentStatuses: PropTypes.array,
};

export default EmploymentInformation;
