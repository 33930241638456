import React, { useState } from 'react';
import RegistrationDirectorySearch from '../Search/RegistrationDirectorySearch';
import { Button, Card, Collapse, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryBalloonSvg from '../PrimaryDesignSystem/BalloonSvg';
import PrimaryMap from '../PrimaryDesignSystem/Map';
import { useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';


const SearchTestGroupIndex = ({
  test_groups=[],
  access_token: accessToken,
  default_reporting,
  url="/find-your-location.json?",
  pagination = {page: 0, next_page: null, total_pages: 0},
  withMap=true,
  disableModals=false,
}) => {
  const params = new URLSearchParams(window.location.search);
  const [testGroups, setTestGroups] = useState(test_groups)
  const [paginator, setPaginator] = useState(pagination)
  const [resultName, setResultName] = useState("")
  const [resultUrl, setResultUrl] = useState("#")
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultReporting, setDefaultReporting] = useState(default_reporting);
  const [form, setForm] = useState({
    q: params.get("q"),
    keyword: params.get("keyword"),
    location: params.get("location"),
  })
  const { t } = useTranslation();

  const getLocations = () => {
    let locations = []

    testGroups.forEach(testGroups => {
      testGroups.test_locations.forEach(test_location => {
        locations.push({ test_group: testGroups, test_location: test_location })
      })
    })

    return locations
  }
  const locations = getLocations()
  const loadMoreResults = async (groups, page) => {
    setLoading(true);
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const params = new URLSearchParams(query).toString() + `&page=${page + 1}`;
    try {
      const response = await axios.get(url + params, {
        headers: { 'Accept': 'application/json' }
      })

      if (response.status === 200) {
        if (window.history.pushState) {
          const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + params;
          window.history.pushState({ path: newurl }, '', newurl);
        }
        const json = response.data
        setTestGroups([...groups, ...json.test_groups])
        setDefaultReporting(json.default_reporting);
        setPaginator(json.pagination)
      } else {
        console.log(response);
      }
    } catch(error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (test_groups.length == 0 && Object.keys(form).length !== 0) loadMoreResults(test_groups, pagination.page)
  }, []);

  const toggleModal = (testGroup, accessToken) => {
    const url =`/l/${testGroup.slug}${!!accessToken ? `?access_token=${accessToken}` : ""}`
    if (disableModals) {
      window.location.href = url
    } else {
      setResultUrl(url)
      setResultName(testGroup.name)
      setShowModal(true)
    }
  }

  return (
    <React.Fragment>
      <p className="body-large-16-regular mt-2">{t("enter_information.search_by_keyword")}</p>
      <div className="rd-search-results-wrapper row">
        <div className={`col-12 mb-2 ${withMap && "col-xl-8"}`}>
          <RegistrationDirectorySearch
            loading={loading}
            form={form}
            setForm={setForm}
            submit={() => {
              loadMoreResults([], 0)
            }} />
        </div>
        <div className="col-12 col-xl-4 rd-search-placeholder-map">
          {withMap
            && <>
                {locations.length > 0 ? <PrimaryMap locations={locations} />
                  : <PrimaryBalloonSvg />
                }
               </>
          }
        </div> 
        <div className={`col-12 ${withMap && "col-xl-8"}`} id="rd-results">
          <div className="search-results">
            {pagination.page != 0 && <SearchResultsMessage
              quantity={testGroups.length}
              accessToken={accessToken}
              state={defaultReporting?.location?.state_name}
              slug={defaultReporting?.test_group?.slug} />}
            {
              testGroups.map((test_group) => {
                return (
                  <SearchTestGroupResult
                    key={test_group.id}
                    searchTerm={form.q}
                    accessToken={accessToken}
                    toggleModal={() => toggleModal(test_group, accessToken)}
                    {...test_group} />
                )
              })
            }
            {paginator.total_pages > paginator.page &&
              <div className="d-flex justify-content-center">
                <Button variant="outline-primary" onClick={() => loadMoreResults(testGroups, paginator.page)} className="btn-sm btn-load-more-results">Load More</Button>
              </div>
            }
            <SearchResultsDialog url={resultUrl} name={resultName} show={showModal} setShow={setShowModal} />
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

const SearchResultsDialog = ({ name, url, show, setShow }) => {
  const goToUrl = () => {
    window.location.href = url
  }

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} centered className='spaced-modal'>
      <Modal.Body>
        <h3>Ready to register?</h3>
        <p className="mb-5">
          Are you sure you want to register in <strong>{name}</strong>
        </p>
        <div className="search-results-modal-actions">
          <Button variant="primary" onClick={goToUrl}>
            Yes, register
            <FontAwesomeIcon icon={faArrowRight} className='ms-2'/>
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            No, go back
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const SearchResultsMessage = (props) => {
  const slug = props.slug
  const accessToken = props.accessToken
  const url = () => {
    return (`/l/${slug}${!!accessToken ? `?access_token=${accessToken}` : ""}`)
  }

  return (
    props.state
      ? <p className="h4-20-regular mt-32">Didn't find what you were looking for? Search again or <span className="h4-20-semi-bold"><a href={url()}>click here to report an at-home test in {props.state}.</a></span></p>
      : props.quantity === 0
        ? <p className="h4-20-regular mt-32">Sorry! There are <span className="h4-20-semi-bold">{props.quantity} organizations</span> within your search criteria. Please try searching using different words.</p>
        : <p className="h4-20-regular mt-32">Showing <span className="h4-20-semi-bold">{props.quantity} organizations</span> within your search criteria.</p>
  )
}

const SearchTestGroupResultItem = (props) => {
  return (
    <li key={props.id}>
      <div className="body-medium-bold">{props.name}</div>
      <div className="body-medium-normal">{props.address}</div>
    </li>
  )
}

const SearchTestGroupResult = (props) => {
  const matchingLocation = props.test_locations.find(test_location => test_location.name === props.searchTerm)
  const sortedLocations = props.test_locations.sort((a, b) => a.name.localeCompare(b.name))
  let locations = sortedLocations

  if (matchingLocation) {
    locations.pop(matchingLocation)
    locations.push(matchingLocation)
  }
  const featuredLocations = locations.slice(0, 3)
  const showToggle = props.test_locations?.length > 3
  const otherLocations = showToggle ? locations.filter(x => !featuredLocations.includes(x)) : []
  const [open, setOpen] = useState(false)

  return (
    <Card body className="search-result-item">
      {
        props.partner_logo_url
          ? <img src={props.partner_logo_url} alt={props.name} className="search-result-item-logo" />
          : null
      }
      <h3 className="inter-medium">{props.name}</h3>
      <ul className="mb-0">
        {
          featuredLocations.map(test_location => {
            return (
              <SearchTestGroupResultItem key={test_location.id} {...test_location} />
            )
          })
        }
      </ul>
      {showToggle &&
        <>
          <Collapse in={open}>
            <ul id={props.id} className="my-0">
              {
                otherLocations.map(location => {
                  return (
                    <SearchTestGroupResultItem key={location.id} {...location} />
                  )
                })
              }
            </ul>
          </Collapse>
          <Button variant="link" className="btn-sm btn-search-results-more" aria-controls={props.id}
            aria-expanded={open} onClick={() => setOpen(!open)}>
            {
              open
                ? "View Less"
                : `View more(${otherLocations.length})`
            }
            <FontAwesomeIcon icon={faChevronDown} className='ms-2 text-primary' />
          </Button >
        </>
      }
      <div className="text-end">
        <Button onClick={props.toggleModal} data-test-hook={`continue_${props.id}`} variant="primary" size="md" className="mt-3">
          Continue
          <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
        </Button>
      </div>
    </Card>
  )
};

export default SearchTestGroupIndex;
