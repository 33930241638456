import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { FilterBadge } from '../../School/components/StudentImmunizationStatusFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import ShowExemptions from './ShowExemptions';

const VaccineGroup = ({
  exemptionCategories,
  exemptionOptions,
  exemptionTypes,
  immunizationRecordStatus,
  onEdit,
  pendingStatuses,
  record,
  testGroup,
  testGroupUser,
}) => {
  const sourceLabel = (source) => {
    if (source === "upload") {
      return "Admin";
    } else if (source) {
      return source.toUpperCase();
    } else {
      return "n/a";
    };
  };

  const ageGivenLabel = (ageGiven) => {
    const { year, month } = ageGiven;
    if (!!year) {
      return year + " years";
    } else if (!!month) {
      return month + " months";
    } else {
      return "n/a";
    };
  };

  const setBadge = (record) => {
    if (["medically_exempt", "non_medically_exempt"].includes(immunizationRecordStatus)) {
      return <ShowExemptions
        categoryOptions={exemptionCategories}
        conditionName={record.name}
        conditionOptions={exemptionOptions}
        exemptions={record.exemptions}
        immunizationRecordStatus={immunizationRecordStatus}
        testGroup={testGroup}
        testGroupUser={testGroupUser}
        typeOptions={exemptionTypes}
      />
    } else {
      return <FilterBadge value={immunizationRecordStatus} />
    };
  };

  return <Card className="px-4 pt-4 my-4">
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <h4 className="mb-3">{record.name}</h4>
        {record.next_dose_due && !pendingStatuses.includes(immunizationRecordStatus) && (
          <span>
            <span className="mx-2">|</span>
            <span>{"Next due: "}{record.next_dose_due}</span>
          </span>
        )}
      </div>
      {setBadge(record)}
    </div>
    <Table className="table-lg carded-table" responsive style={{tableLayout: "fixed"}}>
      <thead>
        <tr>
          <th>Series</th>
          <th>Date given</th>
          <th>Age given</th>
          <th>Vaccine</th>
          <th>Source</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {record.doses.map((dose, idx) => {
         return (
          <tr key={idx}>
            <td>{idx + 1} of {record.num_doses}</td>
            <td>{dose.date}</td>
            <td>{ageGivenLabel(dose.age_given)}</td>
            <td>{dose.name}</td>
            <td>{sourceLabel(dose.source)}</td>
            <td>
              {dose.source === "upload" && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="pointer"
                  onClick={() => {
                    onEdit(dose);
                  }}
                />
              )}
            </td>
          </tr>
         )
        })}
      </tbody>
    </Table>
  </Card>
}

export default VaccineGroup;
