import {
  Dispatch,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

export type LocationKey = string;

export type ActionKey = string;

export type BeforeReturnType<BaseState> = BaseState | State<BaseState> | void;

export interface NodeNavigationActions {
  next?: LocationKey;
  back?: LocationKey;
  [id: ActionKey]: LocationKey;
}

export interface NodeActions {
  [id: ActionKey]: (state: any) => any;
}

export type NavigationHook<BaseState> = (
  state: State<BaseState>,
  action?: ActionKey,
) => BeforeReturnType<BaseState>;

export type NavigationHookAsync<BaseState> = (
  state: State<BaseState>,
  action?: ActionKey,
) => Promise<BeforeReturnType<BaseState>>;

export interface NavigationNode<BaseState> {
  navigationActions?: NodeNavigationActions;
  actions?: NodeActions;
  skipIf?: (state: State<BaseState>) => boolean;
  beforeEnter?: NavigationHook<BaseState>;
  beforeEnterAsync?: NavigationHookAsync<BaseState>;
  beforeLeave?: NavigationHook<BaseState>;
  beforeLeaveAsync?: NavigationHookAsync<BaseState>;
  [id: string]: any;
}

export interface NavigationMap<BaseState> {
  [id: LocationKey]: NavigationNode<BaseState>;
}

interface NavigationState {
  location: LocationKey;
  intentAction?: ActionKey;
  intentLocation?: LocationKey;
  loading?: boolean;
  // Support History in the future
  // history: Array<LocationKey>;
}

export type State<BaseState extends {}> = BaseState & {
  navigation: NavigationState;
};

type UpdateAction = {
  type: 'update';
  key?: string;
  values: { [id: string]: any };
};

type NavigateToAction = { type: 'navigate_to'; location: LocationKey };

type PerformNavigationAction = {
  type: 'perform_navigation';
  action: ActionKey;
};

type NavigateAction = NavigateToAction | PerformNavigationAction;

type GenericAction = { type: keyof NodeActions; [id: string]: any };

export type Action = UpdateAction | NavigateAction | GenericAction;

export const useNavigation = <BaseState>(
  navigationMap: NavigationMap<BaseState>,
  initialRoute: LocationKey,
  initialState: BaseState,
  reducer: Reducer<State<BaseState>, Action>,
): {
  state: State<BaseState>;
  dispatch: Dispatch<Action>;
  currentNode: NavigationNode<BaseState>;
  // navigateTo: (location: LocationKey) => void;
  // performNavigation: (location: ActionKey) => void;
} => {
  const unskippableLocation = useCallback(
    (location: LocationKey, state: State<BaseState>): LocationKey => {
      let intentLocation = location;
      let shouldSkip = false;
      do {
        const navigationNode = navigationMap[intentLocation];
        shouldSkip = navigationNode.skipIf && navigationNode?.skipIf(state);
        if (shouldSkip && navigationNode.navigationActions.next) {
          intentLocation = navigationNode.navigationActions.next;
        } else {
          shouldSkip = false;
        }
      } while (shouldSkip);

      return intentLocation;
    },
    [navigationMap],
  );

  const initialRouteMemo = useMemo(() => {
    return unskippableLocation(initialRoute, {
      ...initialState,
      navigation: { location: initialRoute },
    });
  }, []);

  const navigationReducer = useCallback(
    (state: State<BaseState>, action: Action): State<BaseState> => {
      switch (action.type) {
        case 'update':
          const { key, values } = action as UpdateAction;

          if (key) {
            // Update specific key
            return { ...state, [key]: { ...state[key], ...values } };
          } else {
            // Update entire state
            return { ...state, ...values };
          }
        case 'perform_navigation':
        case 'navigate_to':
          const currentNavigationNode =
            navigationMap[state.navigation.location];
          const { navigationActions = {} } = currentNavigationNode;

          let intentLocation: LocationKey;
          let intentAction: ActionKey;
          let peformCallbacks = true;

          if (action.type === 'navigate_to') {
            if (action.location in navigationMap) {
              intentLocation = action.location;
            } else {
              // Throw error
              console.error('Invalid location', action);
            }
          } else {
            const { action: intentActionKey } =
              action as PerformNavigationAction;
            if (intentActionKey in navigationActions) {
              peformCallbacks = intentActionKey !== 'back';
              intentAction = intentActionKey;
              intentLocation =
                intentActionKey === 'next'
                  ? unskippableLocation(
                      currentNavigationNode.navigationActions[intentActionKey],
                      state,
                    )
                  : currentNavigationNode.navigationActions[intentActionKey];
            } else {
              // Throw error
              console.error('Invalid navigation action', action);
            }
          }

          const intentNavigationNode = navigationMap[intentLocation];
          if (intentNavigationNode) {
            if (
              peformCallbacks &&
              (currentNavigationNode.beforeLeaveAsync ||
                intentNavigationNode.beforeEnterAsync)
            ) {
              // Update state to perform async callbacks
              return {
                ...state,
                navigation: {
                  ...state.navigation,
                  intentAction,
                  intentLocation,
                  loading: true,
                },
              };
            } else {
              // Perform sync callbacks and update navigation state
              let newState = state;

              // Navigation action's before should return an object or null
              if (peformCallbacks) {
                const beforeLeaveState =
                  (currentNavigationNode.beforeLeave &&
                    currentNavigationNode.beforeLeave(
                      newState,
                      intentAction,
                    )) ||
                  {};
                newState = { ...newState, ...beforeLeaveState };
                const beforeEnterState =
                  (intentNavigationNode.beforeEnter &&
                    intentNavigationNode.beforeEnter(newState, intentAction)) ||
                  {};
                newState = { ...newState, ...beforeEnterState };
              }

              return {
                ...newState,
                navigation: {
                  ...newState.navigation,
                  location: intentLocation,
                },
              };
            }
          } else {
            console.error(
              "Location doesn't exist in navigation map",
              intentLocation,
            );
          }
        default:
          const { actions = {} } = navigationMap[state.navigation.location];

          if (action.type in actions) {
            return { ...state, ...actions[action.type](state) };
          } else {
            return reducer(state, action);
          }
      }
    },
    [navigationMap],
  );

  const [state, dispatch] = useReducer(navigationReducer, {
    ...initialState,
    navigation: { location: initialRouteMemo },
  });

  // Executes async action and updates state
  useEffect(() => {
    const {
      navigation: { loading, location, intentAction, intentLocation },
    } = state;

    if (loading) {
      const currentNavigationNode = navigationMap[location];
      const intentNavigationNode = navigationMap[intentLocation];

      const onReject = (error: { message: any; response: any }) => {
        const errors = error.response?.data?.errors?.join('\n');
        const toastr = window['toastr'];
        toastr?.error(`${error.message}\n${errors}`);
        console.error(error);

        dispatch({
          type: 'update',
          key: 'navigation',
          values: {
            location,
            loading: false,
            intentError: error,
          },
        });
      };

      const onResolve = (
        actionReturnState: State<BaseState>,
      ): State<BaseState> => ({
        ...state,
        ...actionReturnState,
      });

      const updateNavigation = (newState: State<BaseState>): void => {
        newState.navigation = {
          ...newState.navigation,
          location: intentLocation,
          loading: false,
          intentAction: undefined,
          intentLocation: undefined,
        };

        dispatch({
          type: 'update',
          values: newState,
        });
      };

      if (
        currentNavigationNode.beforeLeaveAsync &&
        intentNavigationNode.beforeEnterAsync
      ) {
        currentNavigationNode
          .beforeLeaveAsync(state, intentAction)
          .then(onResolve)
          .then((newState) =>
            currentNavigationNode.beforeLeaveAsync(newState, intentAction),
          )
          .then(onResolve)
          .then(updateNavigation)
          .catch(onReject);
      } else {
        (
          currentNavigationNode.beforeLeaveAsync ||
          intentNavigationNode.beforeEnterAsync
        )(state, intentAction)
          .then(onResolve)
          .then(updateNavigation)
          .catch(onReject);
      }
    }
  }, [state.navigation.loading]);

  return {
    state,
    dispatch,
    currentNode: navigationMap[state.navigation.location],
    // navigateTo: (location: LocationKey) =>
    //   dispatch({ type: 'navigate_to', location }),
    // performNavigation: (action: ActionKey) =>
    //   dispatch({ type: 'perform_navigation', action }),
  };
};
