import React, { useState, useEffect } from 'react';
import '../../common/locales/i18n';
import MembersPortalContext from '../context'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Programs from './Programs';
import History from './History';
import Health from './Health';
import Loader from "react-loader-spinner";
import axios from 'axios';
import MemberSelectionPage from './MemberSelectionPage';
import Settings from './Settings';
import ContactUs from './ContactUs';
import PortalWrapper from './partials/PortalWrapper';
import LanguageChange from './LanguageChange';
import { useTranslation } from 'react-i18next';

const NOT_READY = 0;
const READY = 1;
const NO_HOUSEHOLD_MEMBERS = 2;

const buildBaseUrl = (location) => {
  return location.protocol + '//' + location.host
}

const MembersPortal = ({currentMember}) => {
  const { t, i18n } = useTranslation();
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [ready, setReady] = useState(NOT_READY);
  const params = new URLSearchParams(window.location.search);
  const setActiveHouseholdMember = (householdMember) => {
    _setActiveHouseholdMember(householdMember)
    setCurrentUser(householdMember);
    axios.post(`/members/household_members/${householdMember.id}/make_active`);
  }

  const _setActiveHouseholdMember = (householdMember) => {
    if (householdMember) params.set('current_user', householdMember.id);
    const newUrl = buildBaseUrl(window.location) + "/members/me?" + params.toString()
    window.history.pushState({}, null, newUrl);
  }

  useEffect(() => {
    fetch(`/members/household_members.json`).then(response => {
      return response.json();
    }).then((data) => {
      setHouseholdMembers(data.household_members);
      const currentUserParam = params.get('current_user')
      if (currentUserParam) {
        const activeHouseholdMember = data.household_members.find(h => h.id === Number(currentUserParam));
        if (activeHouseholdMember) {
          _setActiveHouseholdMember(activeHouseholdMember)
          setCurrentUser(activeHouseholdMember);
        } else {
          setActiveHouseholdMember(data.household_members[0])
        }
        setReady(READY);
      } else if (data.household_members.length > 0) {
        setCurrentUser(data.household_members[0]);
        setReady(READY);
      } else {
        setReady(NO_HOUSEHOLD_MEMBERS);
        window.location.href = "/finder"
      }
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    if (ready == READY) {
      i18n.changeLanguage(currentUser.user.locale)
    }
  },[ready])

  return (
    <MembersPortalContext.Provider value={{
      currentMember,
      householdMembers,
      currentUser,
      ready,
      onClickProfile: setActiveHouseholdMember,
      t: t,
    }}>
      <BrowserRouter>   
        {ready === NOT_READY && (
          <div className="mt-10 text-center">
            <Loader
              type="Hearts"
              color="#17a2b8"
              height={200}
              width={200}
              timeout={3000} //3 secs
            />
          </div>
        )}
        { ready === NO_HOUSEHOLD_MEMBERS && (
          <PortalWrapper >
            <div className="text-center px-4" style={{position: "absolute", top: "25vh"}}>
              <img className="img-fluid" src="/images/login/no_programs.svg" alt="Placeholder"/>
              <h4 className="my-3">{t('login.no_programs')}</h4>
              <div className='text-muted'>Please follow the invite link / registration link provided by your administrator to join your Group in Primary.Health</div>
            </div>
          </PortalWrapper>
        ) }
        { ready === READY && (
          <Switch>
            <Route exact path="/members/me" render={(props) => (
              <PortalWrapper>
                <Health />
              </PortalWrapper>
            )} />
            <Route exact path="/members/programs" render={(props) => (
              <PortalWrapper withSidebar={false}>
                <Programs />
              </PortalWrapper>
            )} />
            <Route exact path="/members/health" render={(props) => (
              <PortalWrapper >
                <Health />
              </PortalWrapper>
            )} />
            <Route exact path="/members/history" render={(props) => (
              <PortalWrapper >
                <History />
              </PortalWrapper>
            )} />
            <Route exact path="/members/settings" render={(props) => (
              <PortalWrapper withSidebar={false} >
                <Settings />
              </PortalWrapper>
            )} />
            <Route exact path="/members/choose_member" render={(props) => (
              <PortalWrapper withSidebar={false} >
                <MemberSelectionPage />
              </PortalWrapper>
            )} />
            <Route exact path="/members/contact_us" render={(props) => (
              <PortalWrapper withSidebar={false}>
                <ContactUs />
              </PortalWrapper>
            )} />
            <Route exact path="/members/language" render={(props) => (
              <PortalWrapper withSidebar={false}>
                <LanguageChange />
              </PortalWrapper>
            )} />
          </Switch>
        ) }    
      </BrowserRouter>
    </MembersPortalContext.Provider>
  );
};

export default MembersPortal;
