import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import ModalHeader from '../../../common/components/ModalHeader';

const DownloadImmunizationRecordsModal = ({
  showDownloadImmunizationRecordsModal,
  setShowDownloadImmunizationRecordsModal,
  onPrintClick,
}) => {
  const [staffInitials, setStaffInitials] = useState("");
  const closeModal = () => setShowDownloadImmunizationRecordsModal(false);

  return (
    <div>
      <Modal show={!!showDownloadImmunizationRecordsModal}>
        <ModalHeader closeButton onHide={closeModal}>
          <Modal.Title as="h2">
            Print Student Immunization Form
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <p>Please enter the staff initials to be printed on the immunization form.</p>
          <label className="font-weight-medium mt-2">Staff initials</label>
          <FloatingLabelInput
            id='staffInitials'
            name='staffInitials'
            value={staffInitials}
            onChange={(e) => setStaffInitials(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            type='submit'
            disabled={!staffInitials}
            onClick={() => { onPrintClick(staffInitials), closeModal() }}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DownloadImmunizationRecordsModal;
