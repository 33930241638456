import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import VerifyContactInformation from '../../../ContactVerification/Verify';
import SectionHeader from '../SectionHeader';
import { SectionActions, SectionBody, SectionWrapper } from '../styled';

export default function VerifyContactInformationStep ({dispatch, verifyContactInformation, state: { formState, t } }) {
  const [verified, setVerified] = useState(false);
  const onVerify = async (id) => {
    setVerified(true);
    await verifyContactInformation(id); 
  } 
  return (
    <SectionWrapper>
      <Button variant="link" className='d-sm-none p-0 mb-2' onClick={() => dispatch({type: 'back'})}>
        {t('otc.back_button')}
      </Button>
      <SectionHeader title={t('test_strip.verify.title')} includeIcon={false} />
      <SectionBody>
        <div className='kit-body-text pb-2 mb-3'>{t('test_strip.verify.p1')}</div>
        <VerifyContactInformation 
          email={formState.email}
          phoneNumber={formState.phone_number}
          onVerify={onVerify}
          maskContact
          verified={verified}
        />
        <a
          className='text-secondary font-weight-bold body big text-decoration-underline pointer'
          data-bs-toggle="modal" data-bs-target="#supportModal" 
        >
          {t('self_resulting.need_help')}
        </a>
      </SectionBody>
      <SectionActions>
        <Button variant="link" className='d-none d-sm-block' onClick={() => dispatch({type: 'back'})}>
          {t('otc.back_button')}
        </Button>
      </SectionActions>
    </SectionWrapper>
  );
}
