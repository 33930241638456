import React, { useContext, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import FabrxCheckbox from '../../../primary/FabrxCheckbox'
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';

export const ethnicityOn = (ethnicityValue) => {
  return ethnicityValue === true || ethnicityValue === '1'
}

const EthnicityCheckboxes = ({setFieldValue, values, attributeString=""}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  const coreEthnicities = Object.keys(testGroup.ethnicity_options)
  const label = buildLocalizedString(testGroup, 'ethnicity_label');

  return (
    <div>
      <div className="mb-2">
        { label ? <div>{ label }</div> : <div>{ t('hispanic_questions.title') } { testGroup.required_attributes.ethnicity && "*" }</div> }
        <div className="text-muted">
          { buildLocalizedString(testGroup, 'ethnicity_subtitle') ? (
            <div className="d-inline-block"><LocalizedMarkdown container={testGroup} stringKey='ethnicity_subtitle' /></div>
            ) : <span>{ t('hispanic_questions.subtitle') }</span>
          }
          {' '}
          <HelpText text={ buildLocalizedString(testGroup, 'ethnicity_help_text') ? (
            <LocalizedMarkdown container={testGroup} stringKey='ethnicity_help_text' />
            ) : <span>{ t('hispanic_questions.help') }</span>
          } />
        </div>
      </div>
      
      { coreEthnicities.map(ethnicityKey => {
          const label = t(`hispanic_questions.${ethnicityKey}`);
          const primaryEthnicities = Object.keys(values.ethnicity_blob).filter(x => !x.includes("."))
          return (
            <React.Fragment key={ethnicityKey}>
              <div>
                <FabrxCheckbox
                  radio={true}
                  ariaLabel={label}
                  onChange={(checked) => {
                    const newEthnicityBlob = {};
                    newEthnicityBlob[ethnicityKey] = true;
                    setFieldValue(`${attributeString}ethnicity_blob`, newEthnicityBlob);
                  }}
                  checked={ethnicityOn(values.ethnicity_blob[ethnicityKey]) || false}
                  label={label}
                />
              </div>
              { primaryEthnicities[0] == ethnicityKey &&
                testGroup.ethnicity_options[ethnicityKey].map((ethnicitySubKey, idx) => {
                  const keyStr = `${ethnicityKey}.${ethnicitySubKey}`;
                  const label = t(`hispanic_questions.hispanic_expanded.${ethnicitySubKey}`);
                  return (
                    <div key={idx} className="ms-3">
                      <FabrxCheckbox
                        ariaLabel={label}
                        onChange={(checked) => {
                          setFieldValue(
                            `${attributeString}ethnicity_blob["${keyStr}"]`,
                            ethnicityOn(values.ethnicity_blob[`${keyStr}`]) ? !checked : checked
                          )
                        }}
                        checked={ethnicityOn(values.ethnicity_blob[keyStr]) || false}
                        label={label}
                      />
                    </div>
                  )
                })
              }
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

export default EthnicityCheckboxes;
