import React, { useContext } from "react";
import OtcContext from '../contexts/OtcContext';
import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import { useMediaQuery } from "react-responsive";


const OtcTestKitSelector = () => {

  const {
    t,
    allTestKits,
    prettyTestKitNames,
    selectedKit,
    setSelectedKit,
    setNextButtonDisabled,
    stepNumber,
  } = useContext(OtcContext);

  const kits = Object.keys(allTestKits).filter(t => t !== "other");
  const kitOptions = kits.map((kit) => {
    return { value: kit, label: prettyTestKitNames[kit] }
  });

  const compareDisplayNames = (a,b) => {
    return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
  }

  kitOptions.sort(compareDisplayNames).push({ value: "other", label: "Other" });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      <h4>{stepNumber}. {t("otc.which_test_did_you_take")}</h4>

      <FloatingLabelSelect
        isSearchable={!isMobile}
        containerClassName="z6 mt-4"
        name="find your test"
        ariaLabel="find your test"
        value={kitOptions.filter(option => option.value === selectedKit)}
        onChange={(s) => {
          setSelectedKit(s.value);
          setNextButtonDisabled(false);
        }}
        className="test-kit-selector"
        options={kitOptions}
        components={{ IndicatorSeparator: () => null }}
        filledValue={selectedKit}
        label={t("general_self_checkout.find_your_test")}
      />
    </div>
  )
}

export default OtcTestKitSelector;