import React, { useContext } from 'react';
import GeoMap from './GeoMap';

const GoogleMapsWidget = ({title, address, marker}) => {
  // Fetch from meta tag
  const googleMapsApiKeyContainer = document.getElementsByName('google-maps-api-key')[0];
  let googleMapsApiKey = "";
  if (googleMapsApiKeyContainer) {
    googleMapsApiKey = googleMapsApiKeyContainer.attributes.content.value
  }

  return (
    <div>
      <b>{ title }</b><br/>
      <a target="_blank" href={`https://maps.google.com/?q=${encodeURIComponent(address)}`} className="black">
        { address }
      </a>
      {marker && marker.latitude && marker.longitude
        ?
          <GeoMap
            markers={[{location: marker, key: title}]}
            height="200px"
            fullScreenControl={false}
            streetViewControl={false}
            mapTypeControlDropdown={true}
          />
        :
          <div id="map-container-google-8" className="z-depth-1-half map-container-5">
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${encodeURIComponent(address)}&zoom=13`}
              frameBorder="0"
              style={{border:0}}
              allowFullScreen=""
              width="80%"
              height="200"
            >
            </iframe>
          </div>
      }
    </div>
  );
}

export default GoogleMapsWidget;
