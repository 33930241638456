import React from 'react';

const CircleCheck = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16ZM8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1ZM4 8.7438L6.44156 11.3333L12 5.43802L11.2727 4.66667L6.44156 9.79063L4.72727 7.97245L4 8.7438Z"
      fill={color}
    />
  </svg>
);

export default CircleCheck;
