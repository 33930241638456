import React from 'react';
import '../../../common/locales/i18n';
import { Row, Card, Col } from 'react-bootstrap';
import { humanize } from '../../../common/utils'
import format from 'date-fns/format'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TitleCard from '../common/TitleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSyringe, faVial } from '@fortawesome/pro-regular-svg-icons';
import MemberLink from '../common/MemberLink';
import { useContext } from 'react';
import MembersPortalContext from "../../context";
import { useMediaQuery } from 'react-responsive';

const HistorySection = ({tests, preview=false, children}) => {
  const { t } = useContext(MembersPortalContext);
  const groupedTests = {};

  tests.map(test => {
    const dateKey = format(new Date(test.administered_at), 'MMMM yyyy')
    if (groupedTests[dateKey] === undefined) {
      groupedTests[dateKey] = [test]
    } else {
      groupedTests[dateKey] = groupedTests[dateKey].concat(test)
    }
  })


  const testResult = (test) => {
    if (test.vaccine_result) return null;
    let color = "secondary";
    if (test.result === "positive") color = "danger";
    if (test.result === "negative") color = "success";

    return (
      <div className={`font-weight-bold text-${color}`}>
        {humanize(test.result) || "Pending"}
      </div>
    )
  }

  const nothingHere = (
    <div className='my-3 text-muted'>
      {t('member.no_history')}
    </div>
  );

  const buildPreview = (test) => (
    <Row style={{alignItems: "center"}}>
      <Col xs={2} style={{alignSelf: "center"}}>
        {test.vaccine_information.found ?
          <div className='bg-light-purple icon-badge-wrapper'>
            <FontAwesomeIcon
              icon={faSyringe}
              className="text-purple"
            />
          </div>
        : <div className='bg-light-primary icon-badge-wrapper'>
            <FontAwesomeIcon
              icon={faVial}
              className="text-primary"
            />
          </div>
        }
      </Col>
      <Col>
        <div className="text-muted small">{format(new Date(test.administered_at), 'MM/dd/yy @ hh:mm b')}</div>
        <b>{test.display_name}</b>
      </Col>
      {testResult(test) && <Col xs="auto">{testResult(test)}</Col>}
    </Row>
  );

  const contentWrapper = (content) => (
    <Row className='mt-3'>
      <Col xs={2}></Col>
      <Col>
        {content}
      </Col>
    </Row>
  )

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
 
  return preview ? ( tests.length === 0
    ? isMobile ? <div></div> : <div>
        <div className="h4 my-3">{t('member.medical_history')}</div>
        {nothingHere}
      </div>
    : <div className='mt-2'>
      {tests.length > 0 && <MemberLink to="/members/history" className='btn btn-link float-end'>{t('member.see_all')}</MemberLink>}
      <div className="h4 my-3">{t('member.medical_history')}</div>
      {tests.slice(0,3).map((test, idx) => (
        <Card body className='bg-light my-2' key={idx}>
          {buildPreview(test)}
        </Card>
      ))}
    </div>
  ) : (
    <div className='my-2'>
      {Object.keys(groupedTests).map(date =>
        <>
          <div className="h4 my-3">{date}</div>
          {groupedTests[date].map((test, idx) => (
            <Card body className='bg-light my-2' key={idx}>
              {buildPreview(test)}
              {contentWrapper(
                <div className='small text-muted text-truncate'>
                  {test.test_group.name}<br/>{test.appointment_slot_group.name}
                </div>
              )}
              {contentWrapper(
                <a href={test.results_link} className="small font-weight-bold">
                  {t('login.view_full_results')} <FontAwesomeIcon icon={faArrowRight} />
                </a>
              )}
            </Card>
          ))}
        </>
      )}
      {tests.length === 0 &&
        <TitleCard title={t('member.medical_history')} >
          {tests.length === 0 && nothingHere}
        </TitleCard>
      }
      {children}
    </div>
  );
}

export default HistorySection;
