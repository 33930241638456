import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown,faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';

const Chevron = ({ sm }) => {
  const height = sm ? 35 : 55;
  const strokeWidth = sm ? 1.7 : 1.2;

  return (
    <svg
      className="flow-chevron"
      height={height}
      viewBox="0 0 11.25 28.85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{
          stroke: '#ced4da',
          strokeWidth,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeOpacity: 1,
        }}
        d="M 0.99362398,1.5270424 10.524271,14.83785 0.99362398,28.255591"
        id="path40"
      />
    </svg>
  );
};

export const Bar = ({ height = 60, strokeWidth = 1.1, ...props }) => (
  <svg
    height={height}
    viewBox="0 0 1.6 28.85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        stroke: '#ced4da',
        strokeWidth,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: '4',
        strokeOpacity: 1,
      }}
      d="M 0.99362398,1.5270424 0.99362398,28.255591"
      id="path40"
    />
  </svg>
);

export const ChevronDropDown = ({
  className="float-end",
  open=true,
  setOpen,
  btnClass,
  style={ color: "var(--vivid-blue)", fontSize: 25 }
}) => {
  const icon = open ? faChevronUp : faChevronDown;

  return (
    <div className={className}>
      <Button
        onClick={() => setOpen(!open)}
        variant="link"
        className={btnClass}
      >
        <FontAwesomeIcon icon={icon} style={style} />
      </Button>
    </div>
  )
};

export default Chevron;
