import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SearchFilterBadge = ({
  searchFilter,
  selectedFilter,
  setSelectedFilter,
  setShowDeleteSearchFilterModal,
  source_url,
  testGroup,
}) => {

  const isSelected = searchFilter.id === selectedFilter?.id
  const badgeColor = isSelected ? '#3579B6' : '#A2ACBD';

  const redirectWithNewParams = (filterParams) => {
    delete filterParams.search_filter_id;
    const newFilterParams = Object.entries(filterParams).map(([k, v]) => `${k}=${v}`).join('&');
    window.location = `/test_groups/${testGroup.slug}/${source_url}?${newFilterParams}`
                    + `&search_filter_id=${searchFilter.id}`;
  }

  const removeParamsFromUrl = () => {
    window.location = `/test_groups/${testGroup.slug}/${source_url}`;
  }

  return (
    <span
      className='mx-1 py-2 ps-3 pe-2'
      style={{backgroundColor: badgeColor, color: 'white', borderRadius: '20px'}}
    >
      <span
        className='pointer'
        onClick={() => {
          if (isSelected) {
            removeParamsFromUrl();
          } else {
            setSelectedFilter(searchFilter);
            redirectWithNewParams(JSON.parse(searchFilter.filter_params))
          }
        }}
      >
        {searchFilter.name}
      </span>
      <FontAwesomeIcon
        className='mx-2 pointer'
        icon={'times'}
        onClick={() => {
          setSelectedFilter(searchFilter)
          setShowDeleteSearchFilterModal(true)
        }}
      />
    </span>
  )
}

export default SearchFilterBadge;
