import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Modal, Spinner, Row } from "react-bootstrap";

import ModalHeader from "../../common/components/ModalHeader";

const DeliveryErrorModal = ({
  onHide,
  selectedCommunicationId,
  show,
  subject,
}) => {

  const [failedCommunicationTasks, setFailedCommunicationTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFailedCommunicationTasks = async () => {
    setLoading(true);
    const response = await axios.get(`messages/${selectedCommunicationId}/failed_communication_tasks`);
    if (response.status === 200) {
      setFailedCommunicationTasks(response.data.failed_communication_tasks)
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) fetchFailedCommunicationTasks();
  }, [show]);

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="xl"
    >
      <ModalHeader closeButton onHide={onHide}>
        <h3>Delivery errors</h3>
      </ModalHeader>
      <Modal.Body>
        <h6 className="mb-3" style={{ marginTop: "-20px" }}>{subject}</h6>
        <div
          className="overflow-auto"
          style={{ maxHeight: "400px" }}
        >
          <Row>
            <Col className="body-small-regular" xs="2">Participant</Col>
            <Col className="body-small-regular" xs="2">Contact</Col>
            <Col className="body-small-regular" xs="3">Status</Col>
            <Col className="body-small-regular" xs="5">Error</Col>
          </Row>
          <hr />
          {loading ? (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            failedCommunicationTasks.map(t => {
              return (
                <>
                  <Row>
                    <Col xs="2">
                      <a
                        className="body-small-regular inter bold"
                        href={t.test_group_user_url}
                        target="_blank"
                      >
                        {t.recipient_name}
                      </a>
                    </Col>
                    <Col xs="2">
                      <div className="body-small-regular">{t.recipient_phone}</div>
                      <div className="body-small-regular">{t.recipient_email}</div>
                    </Col>
                    <Col xs="3">
                      <div className="body-small-regular">Failed</div>
                      <div className="body-small-regular">{t.failed_at}</div>
                    </Col>
                    <Col xs="5">
                      <div className="body-small-regular">{t.log_line}</div>
                    </Col>
                  </Row>
                  <hr />
                </>
              )
            })
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeliveryErrorModal;