import React, { FC } from 'react';
import {
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';

interface CounterInputProps {
  handleIncrement: () => {};
  handleDecrement: () => null;
  count: number;
  id: any;
  incrementDisabled: boolean;
  minCount: number;
}

const CounterInput: FC<CounterInputProps> = ({
  handleIncrement= () => {},
  handleDecrement = () => {},
  count = 0,
  incrementDisabled = false,
  minCount = 0,
  id = "",
}) => {
  return (
    <InputGroup
      style={{
        maxWidth: 135,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      <Button
        variant="outline-secondary"
        style={{
          minWidth: 'inherit',
          border: '1px solid #E2E7F0',
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          borderRight: 0,
          paddingInline: 12,
        }}
        onClick={handleDecrement}
        disabled={count === minCount}
      >
        -
      </Button>
      <FormControl
        inputMode="numeric"
        value={count}
        style={{
          border: '1px solid #E2E7F0',
          borderRight: 0,
          borderLeft: 0,
          textAlign: 'center',
          paddingRight: 0,
          paddingLeft: 0,
          width: '100%',
        }}
      />
      <Button
        data-test-hook={`increment_${id}`}
        variant="outline-secondary"
        style={{
          minWidth: 'inherit',
          border: '1px solid #E2E7F0',
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          borderLeft: 0,
          paddingInline: 12,
        }}
        onClick={handleIncrement}
        disabled={incrementDisabled}
      >
        +
      </Button>
    </InputGroup>
  );
};

export default CounterInput;
