import HandsBubbles from "../../../common/components/Icons/HandsBubbles";
import RemoveTubeCap from "../../../common/components/Icons/RemoveTubeCap";
import NoseSwab from "../../../common/components/Icons/NoseSwab";
import CapTube from "../../../common/components/Icons/CapTube";

export default {
    "title": "viral_surveillance.instructions.title",
    "steps": [
        {
            "label": "viral_surveillance.instructions.step_1",
            "icon": HandsBubbles
        },
        {
            "label": "viral_surveillance.instructions.step_2",
            "icon": RemoveTubeCap,
            "substeps": [
                {
                    "label": "viral_surveillance.instructions.step_2a"
                }
            ]
        },
        {
            "label": "viral_surveillance.instructions.step_3",
            "icon": NoseSwab,
            "substeps": [
                {
                    "label": "viral_surveillance.instructions.step_3a"
                },
                {
                    "label": "viral_surveillance.instructions.step_3b",
                },
                {
                    "label": "viral_surveillance.instructions.step_3c",
                },
                {
                    "label": "viral_surveillance.instructions.step_3d",
                }
            ]
        },
        {
            "label": "viral_surveillance.instructions.step_4",
            "icon": CapTube
        },
        {
            "label": "viral_surveillance.instructions.step_5",
            "substeps": [
                {
                    "label": "viral_surveillance.instructions.step_5"
                }
            ]
        }
    ]
}
