import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import CheckBoxCard from '../../../common/components/CheckBoxCard';
import { buildLocalizedString } from '../../../common/locales/langs';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { SectionTitle } from '../SectionComponents';
import RegistrationContext from '../../contexts/RegistrationContext';

export const SymptomButton = ({ symptom, active, onChange }) => {
  const { t, i18n } = useTranslation();
  return (
    <div key={symptom.key}>
        <CheckBoxCard
          id={symptom.key}
          name={symptom.key}
          onChange={onChange}
          checked={active}
          label={t(`symptoms.${symptom.key}`)}
          ariaLabel={`Symptom: ${t(`symptoms.${symptom.key}`)} ${active ? ", checked" : ", unchecked"}`}
        />
      </div>
  );
}


const Symptoms = ({ symptoms, values, errors, setFieldValue }) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  const symptomsOnRegistration = symptoms.filter(symptom => symptom.show_on_registration);
  const symptomsWithHelperText = symptoms.filter(symptom => symptom.show_helper_text);
  const symptomCheckedDictionary = { true: 1, false: 0 }
  return (
    <div className="form-section">
      <SectionTitle>{t('registration.symptoms')}</SectionTitle>
      {buildLocalizedString(testGroup, 'symptoms_form_text') ? (
        <LocalizedMarkdown container={testGroup} stringKey='symptoms_form_text' />
      ) : <p>{t('registration.symptoms_experiencing')}</p>
      }
      <Row className="d-flex justify-content-center pb-3" style={{maxWidth: "560px"}}>
        <Col>
          {
            symptomsOnRegistration.map((symptom, idx) => {
              const active = !!values.appointment.symptoms_blob[symptom.key];
              return (
                <SymptomButton
                  symptom={symptom}
                  active={active}
                  onChange={() => {
                    setFieldValue(
                      `appointment.symptoms_blob.${symptom.key}`,
                      symptomCheckedDictionary[!values.appointment.symptoms_blob[symptom.key]],
                    )
                  }}
                />
              );
            })
          }
        </Col>
      </Row>
      {
        symptomsWithHelperText.map(symptom => {
          return (
            <div className="text-muted mb-3" key={symptom.key + "helper_text"}>{t(`symptoms.helper_text.${symptom.key}`)}</div>
          );
        })
      }
    </div>
  );
};

Symptoms.propTypes = {
  symptoms: PropTypes.array,
};

export default Symptoms;
