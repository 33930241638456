import React, { FC, useEffect, useRef } from 'react';

interface SideBarProps extends React.HTMLAttributes<HTMLDivElement> {
  placement?: 'start' | 'end';
  show?: boolean;
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
}

const bootstrap = window['bootstrap'];

export const Body = ({ children }) => (
  <div className="offcanvas-body">{children}</div>
);

export const Footer = ({ children }) => (
  <div className="offcanvas-footer">{children}</div>
);

// Can be opened through a button with data-bs-toggle="offcanvas" and custom data-bs-target
// or through the show prop
const SideBar: FC<SideBarProps> = ({
  placement = 'start',
  show = false,
  onClose,
  title,
  subTitle,
  children,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const bsOffcanvas = useRef(null);

  useEffect(() => {
    if (ref.current) {
      bsOffcanvas.current = new bootstrap.Offcanvas(ref.current, {
        backdrop: true,
      });

      if (onClose) {
        ref.current.addEventListener('hide.bs.offcanvas', onClose);

        return () => {
          ref.current.removeEventListener('hide.bs.offcanvas', onClose);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (bsOffcanvas.current) {
      if (show) {
        bsOffcanvas.current.show();
      } else {
        bsOffcanvas.current.hide();
      }
    }
  }, [show]);

  return (
    <React.Fragment>
      <div
        className={`offcanvas offcanvas-${placement}`}
        tabIndex={-1}
        ref={ref}
        {...props}
      >
        {title && (
          <div className="offcanvas-header">
            <div>
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                {title}
              </h5>
              {subTitle && <div className="offcanvas-subtitle">{subTitle}</div>}
            </div>
          </div>
        )}
        {children}
      </div>
    </React.Fragment>
  );
};

export default SideBar;
