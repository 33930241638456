import React, { FC } from 'react';
import {
  DisplayFilter,
  FilterMap,
  FilterOption,
  FilterValue,
  GetDisplayFilters,
  SetFilter,
} from './hooks/useFilters';

interface FilterValueProviderProps {
  filterOption: FilterOption;
  filters: FilterMap;
  setFilter: SetFilter;
  getDisplayFilters?: GetDisplayFilters;
  children: (props: {
    label: string;
    value: any;
    onChange: (value: any) => void;
    displayFilters?: DisplayFilter[];
  }) => JSX.Element;
}

const FilterValueProvider: FC<FilterValueProviderProps> = ({
  filterOption,
  filters,
  setFilter,
  children,
  getDisplayFilters,
}) => (
  <React.Fragment>
    {children({
      label: filterOption.paramLabel,
      value: filters[filterOption.paramKey],
      onChange: (value: FilterValue) => setFilter(filterOption.paramKey, value),
      displayFilters:
        getDisplayFilters && getDisplayFilters(filterOption.paramKey),
    })}
  </React.Fragment>
);

export default FilterValueProvider;
