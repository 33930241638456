import React from 'react';

const CheckoutServiceCardErrors = ({
  consentFormsUsers=[],
  consentForm={},
  meetsAgeRequirements,
  checkoutAlert,
}) => {
  const consent = consentFormsUsers.find(cfu => cfu.consent_form_id == consentForm.id);
  const declinedConsent = consent?.consent == "no";
  const hasConsentForm = consentForm.found;
  const consentErrored = declinedConsent || (hasConsentForm && !consent?.consented);

  const alertIcon = declinedConsent
    ? "x-circle"
    : (consentErrored || !meetsAgeRequirements)
      ? "alert-circle"
      : checkoutAlert
        ? "alert-triangle"
        : null;

  const errorStyle = {
    margin: "2px 0px",
    color: "var(--danger-500, #CB2531)",
  }

  const redIconStyle = {
    // makes svg icon color red
    filter: "invert(75%) sepia(93%) saturate(3246%) hue-rotate(313deg) brightness(88%) contrast(123%)",
    width: "20px",
    margin: "0px 0px 3px 0px",
  }

  return (
    <>
      {alertIcon && (
        <img
          src={`/images/icons/icon-${alertIcon}.svg`}
          style={redIconStyle}
          className="ms-2"
        />
      )}
      <div>
        {consentErrored && (
          <div style={errorStyle}>
            {declinedConsent
              ? "Participant declined consent for this service."
              : "Consent not provided"
            }
          </div>
        )}
        {!meetsAgeRequirements && (
          <div style={errorStyle}>
            Does not meet age requirement
          </div>
        )}
        {checkoutAlert && (
          <div style={errorStyle}>
            {checkoutAlert}
          </div>
        )}
      </div>
    </>
  )
};

export default CheckoutServiceCardErrors;
