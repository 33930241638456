import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import '../../../../common/locales/i18n';
import LocalizedMarkdown from '../fields/LocalizedMarkdown';
import WaitlistButton from '../fields/WaitlistButton';

const createAppointmentSlotOptions = (appointmentSlots) => {
  return appointmentSlots.map(appointmentSlotKey => {
    return {
      value: appointmentSlotKey.id,
      label: appointmentSlotKey.localized_starts_at,
    }
  });
}

const AppointmentSlotSelector = ({
  onSelect,
  appointmentSlotGroup,
  appointmentSlots,
  appointmentSlotId,
  onWaitlist,
  appointmentSlotsLoaded=true,
  allowWaitlist=true,
  isWaitlisting=false,
}) => {
  const { t, i18n } = useTranslation();

  let appointmentSlotOptions = createAppointmentSlotOptions(appointmentSlots);
  if (!appointmentSlotGroup){
    return null
  }

  if (!appointmentSlotsLoaded) {
    return (
      <div>
        Checking availability...{' '}
        <Spinner animation="border" size="sm" variant="secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (appointmentSlotOptions.length === 0) {
    return (
      <div>
        {t('registration.no_slots', { location: appointmentSlotGroup?.title })}<br/>
        {
          allowWaitlist && (
            <React.Fragment>
              <WaitlistButton
                onClick={onWaitlist}
                isWaitlisting={isWaitlisting}
              />
              <div className="mt-3 text-muted">
                <LocalizedMarkdown container={appointmentSlotGroup} stringKey='description' />
              </div>
            </React.Fragment>
          )
        }
      </div>
    )
  }

  return (
    <div>
      <div className={`select`}>
        <label className="select form-label">
          { t('registration.choose_appointment', {location: appointmentSlotGroup.title}) + " *" }
        </label>
        <FloatingLabelSelect
          id="appointment_slots"
          className="test-slot"
          containerClassName="mt-3"
          ariaLabel={ t('registration.choose_appointment', { location: appointmentSlotGroup.title }) }
          value={appointmentSlotOptions.filter(option => option.value === appointmentSlotId)}
          onChange={onSelect}
          options={appointmentSlotOptions}
          filledValue={appointmentSlotOptions.find(option => option.value === appointmentSlotId)}
          label={t('registration.appointment_time')}
        />
      </div>
      <div className="text-muted">
        <LocalizedMarkdown container={appointmentSlotGroup} stringKey='description' />
      </div>
    </div>
  );
}

export default AppointmentSlotSelector;
