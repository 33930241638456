import React from 'react';

export default ({onClick, mainText, secondaryText, ...props}) => {
  return (
    <div
      className='m-2 pointer'
      onMouseDown={() => onClick(props)}
      data-test-hook={props.dataTestHook}
    >
      <span
        className='poppins regular fs-6'
        dangerouslySetInnerHTML={{ __html: mainText }}
      />
      <i className="fa fa-map-marker-alt mx-1 text-muted" style={{ height: 12 }} />
      <span
        className='text-muted'
        style={{fontSize: 14}}
        dangerouslySetInnerHTML={{ __html: secondaryText }}
      />
    </div>
  )
} 
