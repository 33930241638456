import React, { FC } from 'react';
import BsPagination from 'react-bootstrap/Pagination';

export interface PaginationMetadata {
  currentPage: number;
  nextPage?: number;
  prevPage?: number;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

interface PaginationProps extends PaginationMetadata {
  setPage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  nextPage,
  prevPage,
  totalPages,
  setPage,
}) => {
  const showPrevControls = currentPage > 1;
  const showNextControls = currentPage < totalPages;
  const prevPagesRange = Array(4)
    .fill(null)
    .map((_, i) => currentPage - (i + 1))
    .filter((i) => i >= 1)
    .sort((a, b) => a - b);
  const nextPagesRange = Array(4)
    .fill(null)
    .map((_, i) => currentPage + (i + 1))
    .filter((i) => i <= totalPages)
    .sort((a, b) => a - b);
  const showPrevEllipsis = currentPage > 5;
  const showNextEllipsis = currentPage < totalPages - 4;

  return (
    <BsPagination className="col-12">
      {showPrevControls && (
        <React.Fragment>
          <BsPagination.First onClick={() => setPage(1)}>
            « First
          </BsPagination.First>
          <BsPagination.Prev onClick={() => setPage(prevPage)}>
            ‹ Prev
          </BsPagination.Prev>
        </React.Fragment>
      )}
      {showPrevEllipsis && <BsPagination.Ellipsis disabled />}
      {prevPagesRange.map((page, idx) => (
        <BsPagination.Item key={idx} onClick={() => setPage(page)}>
          {page}
        </BsPagination.Item>
      ))}
      <BsPagination.Item active>{currentPage}</BsPagination.Item>
      {nextPagesRange.map((page, idx) => (
        <BsPagination.Item key={idx} onClick={() => setPage(page)}>
          {page}
        </BsPagination.Item>
      ))}
      {showNextEllipsis && <BsPagination.Ellipsis disabled />}
      {showNextControls && (
        <React.Fragment>
          <BsPagination.Next onClick={() => setPage(nextPage)}>
            Next ›
          </BsPagination.Next>
          <BsPagination.Last onClick={() => setPage(totalPages)}>
            Last »
          </BsPagination.Last>
        </React.Fragment>
      )}
    </BsPagination>
  );
};

export default Pagination;
