import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  calculateBmi,
  convertHeighInInchesToHeightInFeetAndInches,
  valueFromGenericTestResult
} from "../../CheckoutPage/components/utils"

const MedicalHistoryVitalsScreeningCardBody = ({
  test,
}) => {

  const { feet, inches } = convertHeighInInchesToHeightInFeetAndInches(valueFromGenericTestResult(test, "height"));
  const height = valueFromGenericTestResult(test, "height");
  const weight = valueFromGenericTestResult(test, "weight");
  const bmi = calculateBmi(weight, height);

  return (
    <Row>
      <Col>
        <div className="text-muted">Height</div>
        <div className="body-large-16-regular inter medium">{feet}' {inches}"</div>
      </Col>
      <Col>
        <div className="text-muted">Weight</div>
        <div className="body-large-16-regular inter medium">{weight} lbs</div>
      </Col>
      <Col>
        <div className="text-muted">BMI</div>
        <div className="body-large-16-regular inter medium">{bmi}</div>
      </Col>
      <Col>
        <div className="text-muted">Pulse</div>
        <div className="body-large-16-regular inter medium">{valueFromGenericTestResult(test, "pulse")} BPM</div>
      </Col>
    </Row>
  );
}

export default MedicalHistoryVitalsScreeningCardBody;