export const usStateOptions = [
  {
      "label": "Alabama",
      "value": "AL"
  },
  {
      "label": "Alaska",
      "value": "AK"
  },
  {
      "label": "American Samoa",
      "value": "AS"
  },
  {
      "label": "Arizona",
      "value": "AZ"
  },
  {
      "label": "Arkansas",
      "value": "AR"
  },
  {
      "label": "California",
      "value": "CA"
  },
  {
      "label": "Colorado",
      "value": "CO"
  },
  {
      "label": "Connecticut",
      "value": "CT"
  },
  {
      "label": "Delaware",
      "value": "DE"
  },
  {
      "label": "District Of Columbia",
      "value": "DC"
  },
  {
      "label": "Federated States Of Micronesia",
      "value": "FM"
  },
  {
      "label": "Florida",
      "value": "FL"
  },
  {
      "label": "Georgia",
      "value": "GA"
  },
  {
      "label": "Guam",
      "value": "GU"
  },
  {
      "label": "Hawaii",
      "value": "HI"
  },
  {
      "label": "Idaho",
      "value": "ID"
  },
  {
      "label": "Illinois",
      "value": "IL"
  },
  {
      "label": "Indiana",
      "value": "IN"
  },
  {
      "label": "Iowa",
      "value": "IA"
  },
  {
      "label": "Kansas",
      "value": "KS"
  },
  {
      "label": "Kentucky",
      "value": "KY"
  },
  {
      "label": "Louisiana",
      "value": "LA"
  },
  {
      "label": "Maine",
      "value": "ME"
  },
  {
      "label": "Marshall Islands",
      "value": "MH"
  },
  {
      "label": "Maryland",
      "value": "MD"
  },
  {
      "label": "Massachusetts",
      "value": "MA"
  },
  {
      "label": "Michigan",
      "value": "MI"
  },
  {
      "label": "Minnesota",
      "value": "MN"
  },
  {
      "label": "Mississippi",
      "value": "MS"
  },
  {
      "label": "Missouri",
      "value": "MO"
  },
  {
      "label": "Montana",
      "value": "MT"
  },
  {
      "label": "Nebraska",
      "value": "NE"
  },
  {
      "label": "Nevada",
      "value": "NV"
  },
  {
      "label": "New Hampshire",
      "value": "NH"
  },
  {
      "label": "New Jersey",
      "value": "NJ"
  },
  {
      "label": "New Mexico",
      "value": "NM"
  },
  {
      "label": "New York",
      "value": "NY"
  },
  {
      "label": "North Carolina",
      "value": "NC"
  },
  {
      "label": "North Dakota",
      "value": "ND"
  },
  {
      "label": "Northern Mariana Islands",
      "value": "MP"
  },
  {
      "label": "Ohio",
      "value": "OH"
  },
  {
      "label": "Oklahoma",
      "value": "OK"
  },
  {
      "label": "Oregon",
      "value": "OR"
  },
  {
      "label": "Palau",
      "value": "PW"
  },
  {
      "label": "Pennsylvania",
      "value": "PA"
  },
  {
      "label": "Puerto Rico",
      "value": "PR"
  },
  {
      "label": "Rhode Island",
      "value": "RI"
  },
  {
      "label": "South Carolina",
      "value": "SC"
  },
  {
      "label": "South Dakota",
      "value": "SD"
  },
  {
      "label": "Tennessee",
      "value": "TN"
  },
  {
      "label": "Texas",
      "value": "TX"
  },
  {
      "label": "Utah",
      "value": "UT"
  },
  {
      "label": "Vermont",
      "value": "VT"
  },
  {
      "label": "Virgin Islands",
      "value": "VI"
  },
  {
      "label": "Virginia",
      "value": "VA"
  },
  {
      "label": "Washington",
      "value": "WA"
  },
  {
      "label": "West Virginia",
      "value": "WV"
  },
  {
      "label": "Wisconsin",
      "value": "WI"
  },
  {
      "label": "Wyoming",
      "value": "WY"
  }
];

export const profileColors = [
  "#F89595",
  "#27B264",
  "#7879F1",
  "#FF9138",
  "#082054",
];

export const currentLanguage = (locale) => languageOptions.find(option => option.value == locale)?.name || "English";

export const languageOptions = [
    {
      "value": "am",
      "name": "አማርኛ"
    },
    {
      "value": "ar",
      "name": "العربية"
    },
    {
      "value": "bn",
      "name": "বাঙালি"
    },
    {
      "value": "en",
      "name": "English"
    },
    {
      "value": "es",
      "name": "Español"
    },
    {
      "value": "fa",
      "name": "فارسی"
    },
    {
      "value": "fr",
      "name": "Français"
    },
    {
      "value": "hat",
      "name": "Kreyòl ayisyen"
    },
    {
      "value": "hi",
      "name": "हिन्दी, हिंदी"
    },
    {
      "value": "hmn",
      "name": "Hmong"
    },
    {
      "value": "hy",
      "name": "հայերէն"
    },
    {
      "value": "kar",
      "name": "Karen"
    },
    {
      "value": "ko",
      "name": "한국어"
    },
    {
      "value": "lo",
      "name": "ລາວ"
    },
    {
      "value": "my",
      "name": "မြန်မာဘာသာ"
    },
    {
      "value": "ne",
      "name": "नेपाली"
    },
    {
      "value": "om",
      "name": "Afaan Oromoo"
    },
    {
      "value": "ru",
      "name": "\tрусский"
    },
    {
      "value": "so",
      "name": "Soomaali"
    },
    {
      "value": "tl",
      "name": "Tagalog"
    },
    {
      "value": "uk",
      "name": "українська"
    },
    {
      "value": "ur",
      "name": "اردو"
    },
    {
      "value": "vi",
      "name": "Tiếng Việt"
    },
    {
      "value": "zh",
      "name": "中文"
    }
  ];

export const setHousehold = async (token, setLoggedIn, user) => {
  const data =  await fetch(`/members/household_members.json`)
    .then(response => response.json())

  if (data.authorization) {
    setLoggedIn({fromMobile: false, currentUser: data.household_members.find(x => x.user.id == user.id).id })
  } else {
    if (token && token != "null") setLoggedIn({fromMobile: true})
  }
}