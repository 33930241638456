import React from 'react';

export const ClipboardList = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11V9ZM11 11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9V11ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15V13ZM11 15C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V15ZM5 9C4.44772 9 4 9.44771 4 10C4 10.5523 4.44772 11 5 11V9ZM5.01 11C5.56228 11 6.01 10.5523 6.01 10C6.01 9.44771 5.56228 9 5.01 9V11ZM5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15V13ZM5.01 15C5.56228 15 6.01 14.5523 6.01 14C6.01 13.4477 5.56228 13 5.01 13V15ZM14 5V17H16V5H14ZM13 18H3V20H13V18ZM2 17V5H0V17H2ZM3 4H5V2H3V4ZM11 4H13V2H11V4ZM3 18C2.44772 18 2 17.5523 2 17H0C0 18.6569 1.34315 20 3 20V18ZM14 17C14 17.5523 13.5523 18 13 18V20C14.6569 20 16 18.6569 16 17H14ZM16 5C16 3.34315 14.6569 2 13 2V4C13.5523 4 14 4.44772 14 5H16ZM2 5C2 4.44772 2.44772 4 3 4V2C1.34315 2 0 3.34315 0 5H2ZM8 11H11V9H8V11ZM8 15H11V13H8V15ZM7 2H9V0H7V2ZM9 4H7V6H9V4ZM7 4C6.44772 4 6 3.55228 6 3H4C4 4.65685 5.34315 6 7 6V4ZM10 3C10 3.55228 9.55228 4 9 4V6C10.6569 6 12 4.65685 12 3H10ZM9 2C9.55228 2 10 2.44772 10 3H12C12 1.34315 10.6569 0 9 0V2ZM7 0C5.34315 0 4 1.34315 4 3H6C6 2.44772 6.44772 2 7 2V0ZM5 11H5.01V9H5V11ZM5 15H5.01V13H5V15Z"
      style={{
        fill: color,
        stroke: 'none',
      }}
    />
  </svg>
);

export default ClipboardList;