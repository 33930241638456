import React from 'react';
import { useTranslation } from 'react-i18next';

const StepNine = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mt-3 py-3 instruction-sub-step-title">
        {t('public_test_groups.demo.instructions.step_nine_title')}
      </h3>
      <p>{t('public_test_groups.demo.instructions.step_nine_text_1')}</p>
      <b>
        <ol>
          <li>{t('public_test_groups.demo.instructions.step_nine_text_2')}</li>
          <li>{t('public_test_groups.demo.instructions.step_nine_text_3')}</li>
          <li>{t('public_test_groups.demo.instructions.step_nine_text_4')}</li>
        </ol>
      </b>
    </div>
  );
};

export default StepNine;
