import React, { useContext, useEffect, useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import SurveyPreview from '../../common/components/SurveyPreview';
import { buildCustomizedLabel } from '../../common/locales/langs';
import PureButton from '../../common/components/PureButton';
import PureToggleButton from '../../common/components/PureToggleButton';
import ErrorMessage from '../../common/components/ErrorMessage';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import InsuranceContext from '../contexts/InsuranceContext';
import PolicyHolderFields from '../fields/PolicyHolderFields';
import PolicyInformationFields from '../fields/PolicyInformationFields';
import NoInsuranceInformationFields from '../fields/NoInsuranceInformationFields';
import * as SurveyOriginal from "survey-react";
import { Model } from "survey-core";

const INSURANCE_REQUIRED_FIELDS = ["insurance_company", "insurance_id"];
const MEMBER_NAME_FIELDS = ["insurance_primary_member_first_name", "insurance_primary_member_last_name"];
const MEMBER_DATE_OF_BIRTH_FIELDS = [
  'insurance_primary_member_date_of_birth(2i)',
  'insurance_primary_member_date_of_birth(1i)',
  'insurance_primary_member_date_of_birth(3i)',
];
const SECONDARY_INSURANCE_FIELDS = [
  "insurance_secondary_company",
  "insurance_secondary_id",
  "insurance_secondary_policy_holder"
];
const SECONDARY_MEMBER_FIELDS = ["insurance_secondary_member_first_name", "insurance_secondary_member_last_name"];
const SECONDARY_DATE_OF_BIRTH_FIELDS = [
  'insurance_secondary_member_date_of_birth(2i)',
  'insurance_secondary_member_date_of_birth(1i)',
  'insurance_secondary_member_date_of_birth(3i)',
]
const IAM_POLICYHOLDER = 'i_am';

const INSURANCE_CARD_FIELDS = {
  primary: ["insurance_card_front"],
  secondary: ["insurance_secondary_card_front"]
}

export const validateInsuranceForm = (formAction) => (values) => {
  const errors = {};
  const submitError = (valueKey, errorKey) => {
    if (!values.user[valueKey]) errors[errorKey ? errorKey : valueKey] = 'registration.errors.required';
  }

  const {
    user: {
      insurance_status: insuranceStatus,
      driver_license_number: driverLicense,
      driver_license_state: driverLicenseState,
      social_security_number: socialSecurity,
    },
    test_group: {
      show_only_insurance_survey: showOnlySurveyForWhenNoInsurance,
      insurance_fields_required: requiredFields,
      use_new_surveyjs_validation: newSurveyValidation,
      insurance_survey: insuranceSurvey,
      flipper_flags: flipperFlags,
    },
  } = values;
  if (!(values.user && insuranceStatus !== undefined)) {
    errors.insurance_status = 'registration.errors.option_required';
  }
  if (newSurveyValidation && Object.keys(insuranceSurvey || {}).length > 0) {
    let model;
    if (flipperFlags.upgraded_surveyjs) {
      model = new Model(insuranceSurvey);
    } else {
      model = new SurveyOriginal.Model(insuranceSurvey);
    }
    let data = values.appointment.insurance_answers
    model.data = data || {};
    if (model.hasErrors()) errors.questionnaire = 'registration.errors.survey_unanswered';
  }

  if (requiredFields.includes('insurance_card')) {
    INSURANCE_CARD_FIELDS.primary.map(key => submitError(key))
  }
  if (formAction.showInsuranceInformation) {
    INSURANCE_REQUIRED_FIELDS.concat("insurance_policy_holder").map(key => submitError(key));

    if (formAction.policyHolder !== IAM_POLICYHOLDER) {
      MEMBER_NAME_FIELDS.map(key => submitError(key));
      MEMBER_DATE_OF_BIRTH_FIELDS.map(key => submitError(key, "date_of_birth"));
    }
    if (formAction.showSecondary) {
      SECONDARY_INSURANCE_FIELDS.map(key => submitError(key));
      if (formAction.secondaryPolicyHolder !== IAM_POLICYHOLDER) {
        SECONDARY_MEMBER_FIELDS.map(key => submitError(key));
        SECONDARY_DATE_OF_BIRTH_FIELDS.map(key => submitError(key, "secondary_date_of_birth"));
      }
      if (requiredFields.includes('insurance_card')) {
        INSURANCE_CARD_FIELDS.secondary.map(key => submitError(key))
      }
    }
  } else {
    if (
      !driverLicense &&
      !socialSecurity &&
      formAction.participantHasID &&
      !showOnlySurveyForWhenNoInsurance
    )
      errors.no_insurance = 'registration.errors.field_required';
    if (driverLicense && !driverLicenseState)
      errors.driver_license_state = 'registration.errors.field_required';
  }
  return errors;
};

const InsuranceSurvey = ({ value, survey, inputName, setValues, errors=false, newSurvey=false }) => {
  const [answers, setAnswers] = useState(value);

  const handleOnValueChanged = (e) => {
    setAnswers(e.data);
    setValues(e.data);
  }

  return (
    <React.Fragment>
      <input type="hidden" name={inputName} value={JSON.stringify(answers)} />
      <SurveyPreview json={survey} newSurvey={newSurvey} data={answers} onValueChanged={handleOnValueChanged} errors={errors} />
    </React.Fragment>
  );
};

const InsuranceForm = ({ values, errors, handleChange, setFieldValue }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction } = useContext(InsuranceContext);
  const userClearValue = (k) =>  setFieldValue(`user[${k}]`, null);
  const setPolicyHolderFalse = () => {
    ["my_parents", "someone_else", "my_spouse", "i_am"].map(key => (
      setFieldValue(`user[insurance_policy_holder][${key}]`, false)
    ));
  }

  const clearInsuranceInformation = (clearInsuranceInformation) => {
    if (clearInsuranceInformation) {
      clearPolicyHolderValues(clearInsuranceInformation);
      clearSecondaryFields(true);
      INSURANCE_REQUIRED_FIELDS.concat([
        'insurance_card_front',
        'insurance_card_back',
        'insurance_group_number',
        'insurance_secondary_card_front',
        'insurance_secondary_card_back',
      ]).map(k => userClearValue(k));
      setPolicyHolderFalse();
    } else {
      ['driver_license_number', 'driver_license_state','social_security_number'].map(k => (
        userClearValue(k)
      ));
    }
  };

  const clearPolicyHolderValues = (clearPolicyHolder) => {
    if (clearPolicyHolder) {
      MEMBER_NAME_FIELDS
        .concat(MEMBER_DATE_OF_BIRTH_FIELDS)
        .concat(SECONDARY_MEMBER_FIELDS)
        .concat(SECONDARY_DATE_OF_BIRTH_FIELDS)
        .map(k => userClearValue(k));
    }
  };

  const clearSecondaryFields = (showSecondary) => {
    if (showSecondary) {
      SECONDARY_INSURANCE_FIELDS
        .concat(SECONDARY_MEMBER_FIELDS)
        .concat(SECONDARY_DATE_OF_BIRTH_FIELDS)
        .concat('insurance_secondary_group_number')
        .map(k => userClearValue(k));
    }
  };

  useEffect(() => {
    if (formAction.requireInsurance) setFieldValue('user.insurance_status', 0);
  }, [formAction]);

  const noInsuranceSubmitButton = () => {
    const showNoInsuranceForm = formAction.requestDriversLicense ||
      Object.keys(values.test_group.insurance_survey || {}).length > 0 ||
      formAction.requestSocialSecurityNumber;

    return (
      <PureToggleButton
        name="user[insurance_status]"
        className="btn-block"
        outline
        active={values.user.insurance_status === 1}
        ariaLabel={ `${t( buildCustomizedLabel(testGroup.population, 'registration.insurance_status.do_not_have_health_insurance'))} ${values.user.insurance_status === 1 ? " checked" : " unchecked"}` }
        onClick={(checked) => {
          setFieldValue('user.insurance_status', 1);
          setFormAction({
            ...formAction,
            showInsuranceInformation: false,
            showNonInsuranceInformation: showNoInsuranceForm,
            participantHasID: showNoInsuranceForm ? formAction.participantHasID : !formAction.participantHasID,
          });
          clearInsuranceInformation(true);
        }}
        tabIndex="-1"
      >
        {t(
          buildCustomizedLabel(
            testGroup.population,
            'registration.insurance_status.do_not_have_health_insurance',
          ),
        )}
      </PureToggleButton>
    )
  }

  const hasInsuranceSurvey = Object.keys(values.test_group.insurance_survey || {}).length > 0;
  const showOnlySurveyWhenNoInsurance = values.test_group.show_only_insurance_survey && formAction.showNonInsuranceInformation;
  const showInsuranceSurvery = (formAction.showNonInsuranceInformation || formAction.showInsuranceInformation) &&
        (showOnlySurveyWhenNoInsurance || values.test_group.show_insurance_survey) &&
        hasInsuranceSurvey;

  return (
    <div>
      <h5 className="mb-3">{t('registration.insurance_information.title')}</h5>
      <LocalizedMarkdown
        container={testGroup.appointment_slot_group}
        stringKey="insurance_text"
      />
      {!formAction.requireInsurance && (
        <React.Fragment>
          <div className="my-2">
            {t(
              buildCustomizedLabel(
                testGroup.population,
                'registration.insurance_status.question',
              ),
            )}
          </div>
          {errors.insurance_status && <ErrorMessage id="insuranceStatusDesc" message={t(errors.insurance_status)} />}
          <input
            type="hidden"
            value={values.user.insurance_status === 0}
            name="user[insurance_status][has_insurance]"
            autoComplete="off"
          />
          <input
            type="hidden"
            value={values.user.insurance_status === 1}
            name="user[insurance_status][no_insurance]"
            autoComplete="off"
          />
          <div className="my-3 text-center">
            <PureToggleButton
              name="user[insurance_status]"
              type="radio"
              className="btn-block"
              outline
              active={values.user.insurance_status === 0}
              ariaLabel={ `${t( buildCustomizedLabel(testGroup.population, 'registration.insurance_status.have_health_insurance'))}` }
              onClick={(checked) => {
                setFieldValue('user.insurance_status', 0);
                setFormAction({
                  ...formAction,
                  showInsuranceInformation: true,
                  showNonInsuranceInformation: false,
                });
                clearInsuranceInformation(false);
              }}
              tabIndex="0"
            >
              {t(
                buildCustomizedLabel(
                  testGroup.population,
                  'registration.insurance_status.have_health_insurance',
                ),
              )}
            </PureToggleButton>
          </div>
          <div className="my-3 text-center">
            {noInsuranceSubmitButton()}
          </div>
        </React.Fragment>
      )}
      {formAction.showInsuranceInformation && (
        <div>
          <PolicyInformationFields
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            onShowInsuranceBack={()=>{
              setFormAction({
                ...formAction,
                showInsuranceBack: !formAction.showInsuranceBack
              }
            )}}
          />
          <PolicyHolderFields
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            onSetPolicyHolder={(checked, policyHolder) => {
              setFieldValue(`user[insurance_policy_holder][${policyHolder}]`, checked);
              setFormAction({...formAction, policyHolder: policyHolder});
              if (policyHolder !== IAM_POLICYHOLDER) clearPolicyHolderValues(true);
            }}
          />
          <PureButton
            className="btn-block"
            outline
            active={formAction.showSecondary}
            onClick={() => {
              clearSecondaryFields(formAction.showSecondary);
              setFormAction({
                ...formAction,
                showSecondary: !!!formAction.showSecondary,
              });
            }}
          >
            {t('registration.insurance_information.secondary_insurance_label')}
          </PureButton>
          {formAction.showSecondary && (
            <div>
              <PolicyInformationFields
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                handleChange={handleChange}
                onShowInsuranceBack={()=>{
                  setFormAction({
                    ...formAction,
                    showSecondaryInsuranceBack: !formAction.showSecondaryInsuranceBack
                  }
                )}}
                names={{
                  insuranceCardFront: "insurance_secondary_card_front",
                  showInsuranceBack: "showSecondaryInsuranceBack",
                  insuranceCardBack: "insurance_secondary_card_back",
                  insuranceCompany: "insurance_secondary_company",
                  insuranceId: "insurance_secondary_id",
                  insuranceGroupNumber: "insurance_secondary_group_number",
                }}
              />
              <PolicyHolderFields
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                handleChange={handleChange}
                onSetPolicyHolder={(checked, policyHolder) => {
                  setFieldValue(`user[insurance_secondary_policy_holder][${policyHolder}]`, checked);
                  setFormAction({...formAction, secondaryPolicyHolder: policyHolder});
                }}
                names={{
                  insurancePolicyHolder: "insurance_secondary_policy_holder",
                  policyHolderKey: "secondaryPolicyHolder",
                  primaryMemberFirstName: "insurance_secondary_member_first_name",
                  primaryMemberLastName: "insurance_secondary_member_last_name",
                  secondary: true,
                  dob: "secondary_date_of_birth",
                }}
              />
            </div>
          )}
        </div>
      )}
      {formAction.showNonInsuranceInformation &&
      (formAction.requestDriversLicense || formAction.requestSocialSecurityNumber) &&
        <NoInsuranceInformationFields
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          clearInsuranceInformation={clearInsuranceInformation}
        />
      }
      {showInsuranceSurvery && 
        <InsuranceSurvey
          survey={values.test_group.insurance_survey}
          newSurvey={values.test_group.flipper_flags.upgraded_surveyjs}
          value={values.appointment.insurance_answers}
          setValues={(data) => setFieldValue('appointment.insurance_answers', data)}
          inputName="appointment[insurance_answers]"
          errors={errors.questionnaire && values.test_group.use_new_surveyjs_validation}
        />
      }
    </div>
  );
};

export default InsuranceForm;
