import React, { FC } from 'react';

import { PHONE_NUMBER_REGEX_VALIDATOR } from '../../../common/utils/validators';

interface StudentProfileCardProps {
  user: {
    full_name: string;
    date_of_birth: string;
    sex_at_birth?: string;
    address_1: string;
    city: string;
    state: string;
    postal_code: string;
    phone_number: string;
  };
  testGroupSlug: string;
  testGroupUserId: string;
}

const StudentProfileCard: FC<StudentProfileCardProps> = ({
  user,
  testGroupSlug,
  testGroupUserId,
}) => (
  <div className="d-flex flex-column">
    <div className="d-flex">
      <a
        className="mb-2"
        href={`/test_groups/${testGroupSlug}/schools/student_profiles/${testGroupUserId}/edit`}
        target="_blank"
      >
        <span className="h5">Student profile</span>
        <i className="ms-2 fa-solid fa-up-right-from-square"></i>
      </a>
    </div>
    <div className="d-flex flex-row flex-wrap" style={{ columnGap: 48 }}>
      <div>
        <span className="fw-bold">{user.full_name}</span>
      </div>
      <div>DOB: {user.date_of_birth}</div>
      <div>Gender: {user.sex_at_birth?.charAt(0)?.toUpperCase()}</div>
      <div className="d-flex flex-row">
        <span>Address:</span>
        <div className="ms-1">
          {user.address_1}
          {user.address_1 && <br />}
          {user.city}, {user.state} {user.postal_code}
        </div>
      </div>
      <div>
        Phone:{' '}
        {user.phone_number.replace(
          PHONE_NUMBER_REGEX_VALIDATOR,
          '$1 ($2$3) $4-$5',
        )}
      </div>
    </div>
  </div>
);

export default StudentProfileCard;
