import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

import { humanize } from '../../common/utils';
import { StudentStatusIcon } from '../../School/components/StudentProfiles';
import MultipleMatchResolver from '../../CheckoutPage/components/MultipleMatchResolver';
import StudentProfileCard from './widgets/StudentProfileCard';
import axios from 'axios';

const ViewMoreLessBadge = ({
  cardExpanded,
  hiddedElementCount,
  setCardExpanded,
}) => {
  const label = cardExpanded
    ? 'View less'
    : `View more (${hiddedElementCount})`;
  return (
    <div
      onClick={() => setCardExpanded(!cardExpanded)}
      className="pointer my-1"
      style={{ color: 'var(--primary, #2862FA)' }}
    >
      <b>{label}</b>
      <FontAwesomeIcon
        className="ms-1"
        icon={cardExpanded ? faChevronUp : faChevronDown}
      />
    </div>
  );
};

const ImmunizationsSummarySection = ({
  dataToDisplay,
  displayOverdueDates = true,
  displayDataValues = true,
  cardExpanded,
  setCardExpanded,
  title,
}) => {
  let body, numberOfHiddenKeys, moreThanThreeKeys;
  if (typeof dataToDisplay === 'string') {
    body = <div className="my-1">{dataToDisplay}</div>;
  } else {
    const dataKeys = Object.keys(dataToDisplay);
    moreThanThreeKeys = dataKeys.length > 3;
    const numberOfLinesToDisplay = moreThanThreeKeys ? 2 : 3;
    const keysToDisplay = cardExpanded
      ? dataKeys
      : dataKeys.slice(0, numberOfLinesToDisplay);
    if (displayOverdueDates) {
      keysToDisplay.sort((a, b) => {
        const dateA = new Date(dataToDisplay[a]);
        const dateB = new Date(dataToDisplay[b]);
        return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
      });
    }
    numberOfHiddenKeys = dataKeys.length - keysToDisplay.length;
    body = (
      <>
        {keysToDisplay.map((key) => {
          return (
            <div className="my-1" style={{ fontSize: '0.8rem' }} key={key}>
              <span className={!displayOverdueDates && 'font-weight-bold'}>
                {key}
              </span>
              {displayDataValues && dataToDisplay[key] && (
                <>
                  {displayOverdueDates && ' due date'}
                  {': '}
                  {dataToDisplay[key]}
                </>
              )}
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div>
      <h5>
        <StudentStatusIcon status={title} className="me-1" />
        {humanize(title)}
      </h5>
      {body}
      {moreThanThreeKeys && (
        <ViewMoreLessBadge
          cardExpanded={cardExpanded}
          hiddedElementCount={numberOfHiddenKeys}
          setCardExpanded={setCardExpanded}
        />
      )}
    </div>
  );
};

const buildGuardianInfo = (student_profile) => {
  const { parent_guardian_name, parent_phone_number, parent_email_address } =
    student_profile;
  return !!parent_guardian_name
    ? [parent_guardian_name, parent_phone_number, parent_email_address]
        .filter((v) => !!v)
        .join(' | ')
    : 'n/a';
};

const UserSummary = ({
  testGroupUser: { user, student_profile },
  cardExpanded,
  setCardExpanded,
}) => {
  const userSummary = {
    'Student ID': student_profile.student_id || 'n/a',
    'Parent/Guardian': buildGuardianInfo(student_profile),
    Gender: user.gender_string || 'n/a',
  };

  return (
    <ImmunizationsSummarySection
      cardExpanded={cardExpanded}
      setCardExpanded={setCardExpanded}
      dataToDisplay={userSummary}
      title={user.full_name}
      displayOverdueDates={false}
    />
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'expandCard':
      return { ...state, cardExpanded: action.value };
    case 'setImmunizationStatus':
      return { ...state, immunizationStatus: action.value };
    case 'setImmunizationRecords':
      return { ...state, immunizationRecords: action.values, loading: false };
    case 'isLoading':
      return {...state, loading: true};
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const useImmunizationSummary = (
  currentImmunizationStatus,
  recordCount,
  immunizationRecords,
  testGroup,
  loading,
) => {
  const initialState = {
    immunizationStatus: currentImmunizationStatus,
    cardExpanded: false,
    immunizationRecords,
    loading,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  let dataToDisplay, immunizationStatus; 

  switch (state.immunizationStatus) {
    case "no_records":
      if (recordCount === 0) {
        immunizationStatus = 'no_records'
        dataToDisplay = "Please collect full immunization history. This student's information was not found in the state registry."
      }
      break;
    case "compliant":
      immunizationStatus = 'compliant'
      dataToDisplay = "This student has all necessary immunizations completed for their grade."
      break;
    case "pending":
      immunizationStatus = 'pending'
      dataToDisplay = "Please wait while we calculate this student's immunization compliance. If this doesn't reload automatically, please refresh."
      break;
    case "multiple_matches": 
      immunizationStatus = 'multiple_matches'
      dataToDisplay = 'Multiple matches were found in the state registry. Click "Find Match" to resolve.'
      break;
    default:
      // do nothing
  }

  const isStatusInImmunizationRecords = useCallback((status) =>
    state.immunizationRecords &&
    Object.values(state.immunizationRecords).some(
      (d) => d.immunization_record_status === status,
    ), [state.immunizationRecords]);

  const userHasOverdueRecord = isStatusInImmunizationRecords('overdue');
  const userHasConditionalRecord = isStatusInImmunizationRecords('conditional');

  const createRecordSummary = useCallback(() => {
    if (immunizationStatus === 'complaint') return;
    const newSummary = {};

    const addValuesToSummary = (status) => {
      const dueDates = {};
      Object.values(state.immunizationRecords)
        .filter((d) => d.immunization_record_status === status)
        .forEach((record) => (dueDates[record.name] = record.next_dose_due));
      newSummary[status] = dueDates;
    };

    if (!['no_records', 'multiple_matches'].includes(immunizationStatus)) {
      if (userHasOverdueRecord) addValuesToSummary('overdue');
      if (userHasConditionalRecord) addValuesToSummary('conditional');
    }
    return newSummary;
  }, [state.immunizationRecords]);
  const recordSummary = createRecordSummary();

  const fetchRecords = async (testGroupUser) => {
    if (state.loading) {
      const response = await axios.
        get(`/test_groups/${testGroup.id}/immunizations/${testGroupUser.id}.json?shallow=true`)
      dispatch({type: "setImmunizationRecords", values: response.data.immunization_records}) 
    }
  }

  return {
    state,
    dispatch,
    recordSummary,
    fetchRecords,
    immunizationStatus,
    dataToDisplay,
  };
};

const ImmunizationsSummaryCard = ({
  immunizationRecords,
  testGroup,
  testGroupUser,
  recordCount,
  currentImmunizationStatus,
  cardClasses = 'p-4 mb-4 mt-5',
  fetchOnExpand=false,
}) => {
  const { user } = testGroupUser;
  const {
    state,
    dispatch,
    recordSummary,
    fetchRecords,
    immunizationStatus,
    dataToDisplay,
  } = useImmunizationSummary(
    currentImmunizationStatus,
    recordCount,
    immunizationRecords,
    testGroup,
    fetchOnExpand,
  );

  useEffect(() => {
    if (!immunizationRecords) {
      dispatch({type: 'isLoading'}) 
      fetchRecords(testGroupUser)
    }
  }, [fetchOnExpand])

  const statusSummary = () => {
    if (state.loading) return <Spinner variant='dark' animation="border" />
    if (['compliant', 'no_records', 'pending'].includes(immunizationStatus))  {
      return (
        <Col>
          <ImmunizationsSummarySection
            dataToDisplay={dataToDisplay}
            title={immunizationStatus}
          />
        </Col>
      );
    } else if (immunizationStatus === 'multiple_matches') {
      return (
        <>
          <Col>
            <ImmunizationsSummarySection
              dataToDisplay={dataToDisplay}
              title={immunizationStatus}
            />
          </Col>
          <Col className="col-sm-2 align-self-center">
            <MultipleMatchResolver
              onMatchSelected={async () => {
                try {
                  const response = await requestHistoryUpdate({
                    testGroupId: testGroup.slug,
                    userId: user.id,
                  });
                  dispatch({type: 'setImmunizationStatus', value: 'pending'});
                  // re-calculate immunization compliance
                } catch (error) {
                  console.log('error');
                }
              }}
              resolveButtonVariant="outline-secondary"
              resolveButtonSize="sm"
              resolveButtonText="Find Match"
              testGroupId={testGroup.slug}
              user={user}
              modalSubtitle="Please select the correct student."
              nameColumnAlias="Student"
              recordDetails={
                <StudentProfileCard
                  user={user}
                  testGroupSlug={testGroup.slug}
                  testGroupUserId={testGroupUser.id}
                />
              }
            />
          </Col>
        </>
      );
    } else {
      return Object.entries(recordSummary).map(([status, record]) => (
        <Col key={status}>
          <ImmunizationsSummarySection
            cardExpanded={state.cardExpanded}
            dataToDisplay={record}
            displayDataValues={status !== 'overdue'}
            setCardExpanded={(value) => dispatch({type: 'expandCard', value: value})}
            title={status}
          />
        </Col>
      ));
    }
  };

  return (
    <Card className={cardClasses}>
      <Row>
        <Col xs={4}>
          <UserSummary
            testGroupUser={testGroupUser}
            cardExpanded={state.cardExpanded}
            setCardExpanded={(value) => dispatch({type: 'expandCard', value: value})}
          />
        </Col>
        {statusSummary()}
      </Row>
    </Card>
  );
};

export default ImmunizationsSummaryCard;
