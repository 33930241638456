import React, { useEffect } from 'react';
import { filterImmunizationRecords } from '../helpers/utils';
import Header from './Header';
import ImmunizationInfo from './ImmunizationInfo';
import SchoolImmunizations from './SchoolImmunizations';

const Summary = ({
  user,
  navigateTo,
  immunizationRecords: immunizationRecordsProp = {},
  pendingUploads = [],
  refreshImmunizationRecords = () => {},
}) => {
  // Set local status if there's pending uploads associated
  const pendingImmunizationRecords = filterImmunizationRecords(
    immunizationRecordsProp,
  )
    .map((record) =>
      pendingUploads.find((pendingUpload) => pendingUpload[record.key])
        ? { ...record, immunization_record_status: 'pending' }
        : record,
    )
    .sort(
      (a, b) => a.immunization_record_status > b.immunization_record_status,
    );

  useEffect(refreshImmunizationRecords, []);

  return (
    <React.Fragment>
      <Header user={user} />
      <div className="two-columns">
        <SchoolImmunizations
          startFileUpload={() => navigateTo('upload')}
          pendingImmunizationRecords={pendingImmunizationRecords}
        />
        <ImmunizationInfo
          pendingImmunizationRecords={pendingImmunizationRecords}
        />
      </div>
    </React.Fragment>
  );
};

export default Summary;
