import React, { useEffect, useState } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Container,
  Button,
  Overlay,
  Popover,
  Modal,
  Spinner,
  Row,
  Col,
  Accordion,
} from 'react-bootstrap';
import Select from 'react-select';
import LocalizedMarkdown from '../../../Registration/components/registration/fields/LocalizedMarkdown';
import { buildLocalizedString } from '../../../common/locales/langs';
import { loadAppointmentSlots } from "../../../common/utils/"
import SelfAdministration from './SelfAdministration';
import ModalHeader from '../../../common/components/ModalHeader';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyringe,
  faUserPlus,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import CancelAppointment from './CancelAppointment';
import AlertHeader from '../../../common/components/AlertHeader';
import { PrimaryIcon } from '../../../common/components/Icons/PrimaryIcon';
import TestKits from '../../../KitFlow/components/ConfirmationPageKits';
import { UserCircle } from '../../../common/components/Icons/registration';
import AppointmentGroupCards from './AppointmentGroupCards';
import AppointmentSlotGroupCard from '../../../FamilyRegistration/components/AppointmentSlotGroupCard';
import StickyHeader, { STICKY_STYLE } from '../../../common/components/StickyHeader';

const [LOCATION, HOME, MAIL] = ['location', 'at_home', 'mail_order'];

const AppointmentBarcode = ({ appointment }) => {
  return (
    <>
      <hr />
      <div className="my-4">
        <svg
          id="access_code"
          className="my-1 w-100 px-4"
          style={{ height: 90 }}
        ></svg>
        <div className="text-center fw-bold">
          {appointment.access_code}
        </div>
      </div>
      <hr />
    </>
  );
};

const TopSection = ({
  testGroup,
  user,
}) => {
  const { t } = useTranslation();
  const { contentRef, isSticky } = StickyHeader();
  return (
    <div className="py-3" ref={contentRef}>
      {!!user.appointment_group.appointments_needed &&
        <AlertHeader
          title={t("family_registrations.register_next_family_member")}
          message={
            t("family_registrations.successfully_registered_x_of_y_family_members", {
              x: user.appointment_group.appointments_made,
              y: user.appointment_group.appointments_needed + user.appointment_group.appointments_made,
            })
          }
          icon={faCircleExclamation}
          btnAction={() => window.location.href = `/r/${testGroup.slug}`}
          btnTxt={t("family_registrations.next_family_member")}
          type="info"
          dataTestHook="continue_family_registration"
          style={isSticky ? STICKY_STYLE : {}}
        />
      }
      <div className='poppins ls-small semibold fs-3 mb-4' data-test-hook="instruction_title">
        {!!user.appointment_group.appointments.length ? (
          <>{t("family_registrations.registration_confirmation")}</>
        ) : (
          t('instructions.new_title', {name: `${user.first_name} ${user.last_name[0]}`})
        )}
      </div>
      {user.appointment_group.appointments.length > 0 && (
        <div className="confirmation-page-map-container">
          <AppointmentSlotGroupCard
            appointmentSlotGroup={user.appointment_group.appointments[0].appointment_slot_group}
            t={t}
          />
        </div>
      )}
      {user.preferred_method_of_communication &&
        <Card className="my-3" style={{height: "fit-content"}}>
          <Card.Body style={{padding: "16px 24px"}}>
            <div>
              <b>{t('instructions.contact_method')}:</b>{' '}
              {user.preferred_method_of_communication === 'email'
                ? t(`registration.email`)
                : t('consent_waiver.phone_number')}
            </div>
          </Card.Body>
        </Card>
      }
      <EventTokenInformation eventToken={user.event_token} t={t} />

      {buildLocalizedString(testGroup, 'instructions_text') && (
        <Card body className="mb-4 gray-light-100 border-0" style={{height: "auto"}}>
          <div className="my-4">
            <LocalizedMarkdown
              container={testGroup}
              stringKey="instructions_text"
              user={user}
            />
          </div>
        </Card>
      )}

      <SelfAdministration
        testGroup={testGroup}
        appointment={user.appointment}
        newConfirmation
        archived={user.archived}
      />
      <div>
        <TestKits testKits={user.appointment.test_kits} testGroup={testGroup} user={user} />
      </div>
      <div>
        <AppointmentGroupCards
          appointmentGroup={user.appointment_group}
          testGroup={testGroup}
        />
      </div>
    </div>
  );
};

export const CalendarPopover = ({ target, show }) => {
  useEffect(() => {
    show && appendCalendarToElement('#calendar-popover-content');
  }, [show]);

  return (
    <Overlay target={target} show={show} placement="bottom">
      <Popover id="calendar-popover">
        <Popover.Content>
          <div id="calendar-popover-content" />
        </Popover.Content>
      </Popover>
    </Overlay>
  );
};

const NewAppointmentModal = ({
  onHide,
  testGroup,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={true} onHide={onHide} size="lg">
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2">
          Book new appointment
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        Your appointment has been completed and cannot be rescheduled. You can book a new appointment by clicking the button below.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="mx-2"
          onClick={onHide}
        >
          {t('self_administration.cancel')}
        </Button>
        <Button href={`/r/${testGroup.slug}`}>
          New appointment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const RescheduleModal = ({
  onHide,
  appointment,
  appointmentSlotGroup,
  testGroup,
  appointmentSlot,
}) => {
  const { t } = useTranslation();
  const [appointmentSlotId, setAppointmentSlotId] = useState(appointmentSlot.id);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onSubmit = () => {
    setLoading(true)
    axios.put(
      `/registrations/${appointment.access_code}.json`,
      {appointment: {appointment_slot_id: appointmentSlotId }}
    )
      .then(r => window.location.reload())
      .catch(err => {
        setLoading(false);
        setError("Something went wrong")
      })
  };

  useEffect(() => {
    loadAppointmentSlots({
      accessKey: appointment.registration_access_key,
      testGroupSlug: testGroup.slug,
      onLoad: (data) => {
        setOptions(
          data.appointment_slot_groups[0].appointment_slots.map(x => ({value: x.id, label: x.localized_starts_at}))
           .filter(x => x.value != appointmentSlot.id)
           .concat({value: appointmentSlot.id, label: appointmentSlot.localized_starts_at})
        );
        setLoading(false);
      },
      accessCode: appointment.access_code,
      testLocationId: appointmentSlotGroup.test_location_id,
    })
  }, []);

  return (
    <Modal show={true} onHide={onHide} size="lg">
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2">
          {t('instructions.reschedule_appointment')}
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <h5>{appointmentSlotGroup.title}</h5>
        <a target="_blank" href={`https://maps.google.com/?q=${encodeURIComponent(appointmentSlotGroup.address)}`}>
          {appointmentSlotGroup.address}
        </a>
        <br />
        <label className="form-label select optional mt-4">
          {t('registration.choose_appointment', {location: appointmentSlotGroup.title})}
        </label>
        <Select
          className="basic-single mb-3"
          classNamePrefix="select"
          value={options.find((option) => option.value === appointmentSlotId)}
          onChange={(e) => setAppointmentSlotId(e.value)}
          components={{ IndicatorSeparator: () => null }}
          options={options}
          isSearchable={true}
          name="appointment[appointment_slot_id]"
        />
      </Modal.Body>
      <Modal.Footer>
        {error && <div className="text-danger">{error}</div>}
        <Button
            variant="outline-primary"
            className="mx-2"
            onClick={onHide}
          >
            {t('self_administration.cancel')}
          </Button>
        <Button onClick={onSubmit}>
          {loading &&
            <Spinner animation="border" size="sm" className="me-2" variant="light" role="status">
              <span className="visually-hidden">{t('self_resulting.loading')}...</span>
            </Spinner>
          }
          {t('result_page.landing.submit_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EventTokenInformation = ({
  eventToken: { loading_event_token: loadingEventToken, uid },
  t
}) => {
  if (!uid && !loadingEventToken) return <></>;

  return (
    <div className='mb-4'>
      <div className="mt-2 mb-2">{t('event_token.title')}</div>
      {uid
        ? <h1>{uid}</h1>
        : <>
          <Spinner variant='primary' animation='border' size="sm" /> {t('event_token.loading_text')}
        </>
      }
    </div>
  )
}

const MiddleSection = ({
  appointment,
  appointment_slot,
  appointment_slot_group,
  isAppointmentCanceled,
  modalOpen,
  results,
  setShowRescheduleModal,
  showRescheduleModal,
  testGroup,
  user,
}) => {
  const { t } = useTranslation();

  const [copyText, setCopyText] = useState("copy_link")

  const isArchived = user.archived;

  const appointmentGroup = { appointments: [{
    access_code: appointment.access_code,
    appointment_slot: appointment_slot,
    appointment_slot_group: appointment_slot_group,
    canceled: appointment.canceled,
    test_configuration_names: testGroup.request_test_configurations_on_registration
      ? appointment.test_configuration_names
      : [],
    user: user,
  }] };

  return (
    <div className="my-3">
      {!appointment_slot.on_demand && !user.appointment_group.appointments.length && (
        <div>
          <AppointmentGroupCards
            appointmentGroup={appointmentGroup}
            testGroup={testGroup}
          />
        </div>
      )}

      {buildLocalizedString(
        testGroup,
        `appointment_slot_group_${user.appointment.appointment_slot_group.location_type}_instruction_text`,
      ) && (
        <div className="my-4">
          <LocalizedMarkdown
            container={testGroup}
            stringKey={`appointment_slot_group_${user.appointment.appointment_slot_group.location_type}_instruction_text`}
            user={user}
          />
        </div>
      )}

      {!appointment_slot.on_demand && (
        <div>
          <div className={`${user.appointment_group.appointments.length ? 'd-none' : ''}`}>
            <AppointmentBarcode appointment={appointment}/>
          </div>
          {showRescheduleModal && (
            appointment.unable_to_reschedule ? (
              <NewAppointmentModal
                onHide={() => setShowRescheduleModal(false)}
                testGroup={testGroup}
              />
            ) : (
              <RescheduleModal
                appointment={appointment}
                appointmentSlotGroup={appointment_slot_group}
                onHide={() => setShowRescheduleModal(false)}
                testGroup={testGroup}
                appointmentSlot={appointment_slot}
              />
            )
          )
          }
          {testGroup.has_redcap_integration && appointment.redcap_survey_url && (
            <Button href={appointment.redcap_survey_url} block className="my-4" variant="outline-primary" target="_blank">
              {t('instructions.redcap_url')}
            </Button>
          )}

          {testGroup.request_additional_appointment && (
            <Button href={appointment.request_additional_appointment_link} block className="my-4">
              {t('instructions.book_additional_appointment')}
            </Button>
          )}
        </div>
      )}

      <Card body className='gray-light-100 border-0'>
        <h5>{t("instructions.save_this_page.title")}</h5>
        <ul className='h4-20-regular'>
          <li>{t("instructions.save_this_page.p1")}</li>
          <li>{t("instructions.save_this_page.p2")}</li>
          <li>{t("instructions.save_this_page.p3")}</li>
        </ul>
        <Row className="mt-4">
          <Col xs="auto">
            <a
              href={appointment_slot.resend_email_path}
              rel="nofollow"
              data-method="post"
              aria-label={"Resend confirmation"}
              style={isArchived ? {color: "#717D96", pointerEvents: "none"} : null}
            >
              <h6>{t("instructions.resend_confirmation")}</h6>
            </a>
          </Col>
          <Col xs="auto">
            <a
              href="javascript:;"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setCopyText("copied_link")
              }}
              aria-label={"Copy link"}
              style={isArchived ? {color: "#717D96", pointerEvents: "none"} : null}
            >
              <h6>{t(copyText)}</h6>
            </a>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const BottomSection = ({
  appointment,
  appointment_slot,
  results,
  user,
  testGroup,
  loggedIn,
}) => {
  const { t } = useTranslation();

  const [showBarcode, setShowBarcode] = useState(false);

  const isArchived = user.archived;

  return (
    <div>
      {appointment_slot.on_demand && (
        <Accordion>
          <Row className="justify-content-between my-4">
            <Col xs="auto">
              <h5 className='poppins semibold ls-small fs-4'>{t('instructions.information')}</h5>
            </Col>
            <Col xs="auto">
              <Accordion.Toggle
                disabled={isArchived}
                as={Button}
                variant="link"
                className="mb-2 p-0"
                eventKey="0"
                onClick={() => setShowBarcode(!showBarcode)}
              >
                <span>
                  <PrimaryIcon icon="placeholder" color="#2862FA" height={13} width={13} />{' '}
                  {t('instructions.show_my_barcode')}
                </span>
              </Accordion.Toggle>
            </Col>
          </Row>
          <Accordion.Collapse eventKey="0">
            <div className="py-3 px-4">
              <svg id="access_code" className="my-1 w-100 px-4" style={{ height: 90 }} ></svg>
              <div className="text-center fw-bold">{user.test_group_user_access_code}</div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      )}

      <div className="mt-5">
        {results.check_results && (
          <div className="my-4">
            <a href={`/t/${testGroup.slug}/r/?access_code=${appointment.access_code}`} style={{textDecoration: "none"}}>
              <PrimaryIcon icon="clipboard" color="#2862FA" height={18} width={18} /><span className="mx-2 lead-20-medium inter bold">{t("instructions.medical_history")}</span>
            </a>
          </div>
        )}
        {user['belongs_to_account?'] && (
          <div className="my-4">
            <a href={`${loggedIn ? `/members/me` : `/members/sign_in`}?current_user=${user.current_member_id}`} style={isArchived ? {textDecoration: "none", pointerEvents: "none"} : {textDecoration: "none"}}>
              <UserCircle height={18} width={18} color={isArchived ? "#717D96": "#2862FA"} />
              <span className="mx-2 lead-20-medium inter bold" style={isArchived ? {color: "#717D96"} : null}>{t("instructions.my_account")}</span>
            </a>
          </div>
        )}

        <div className="my-4">
          <a href={`/r/${testGroup.slug}`} style={isArchived ? {textDecoration: "none", pointerEvents: "none"} : {textDecoration: "none"}}>
            <FontAwesomeIcon icon={faUserPlus} style={isArchived ? {color: "#717D96"} : null} />
            <span className="mx-2 lead-20-medium inter bold" style={isArchived ? {color: "#717D96"} : null}>{t("instructions.register_another")}</span>
          </a>
        </div>

        <Card body className="mb-4 gray-light-100 border-0" style={{height: "auto"}}>
          <h5 className='h4-20-semi-bold inter semibold'>{t('self_resulting.need_help')}</h5>
          <p className='h4-20-regular'>
            {t("contact_support.need_assistance")}
          </p>
          <Button variant="link" data-bs-toggle="modal" data-bs-target="#supportModal" className="p-0">
            <PrimaryIcon icon="help-circle" color="#2862FA" height={15} width={15} className="me-1" />
            {t("self_resulting.contact_support")}
          </Button>
        </Card>
      </div>
    </div>
  )
}

const Show = ({ user, testGroup, isAppointmentCanceled, loggedIn, modalOpen }) => {
  const { t, i18n } = useTranslation();
  const {
    appointment: {
      results,
      appointment_slot,
      appointment_slot_group,
      ...appointment
    },
  } = user;

  const [showRescheduleModal, setShowRescheduleModal] = useState(modalOpen == "reschedule" ? true : false);

  useEffect(() => {
    const renderBarcode = (appointment_slot.on_demand && user.test_group_user_access_code) || !appointment_slot.on_demand;
    if (renderBarcode) {
      JsBarcode('#access_code', appointment_slot.on_demand ? user.test_group_user_access_code : appointment.access_code, {
        displayValue: false,
        margin: 0,
      });
    }
    if (appointment.just_created) {
      toastr.success(
        appointment_slot.on_demand ? "Registration confirmed!" : "Appointment confirmed!",
        "",
        {positionClass: "toast-top-left"}
      )
    }
  }, []);

  return (
    <Container fluid="sm" style={{maxWidth: 770}}>
      <div
        className="pt-4 d-flex flex-column justify-content-between confirmation-text"
        style={{minHeight: window.innerHeight - 250}}
      >
        {user.archived && <AlertHeader
          title={"Your profile has been disabled"}
          message={<div>Please contact your school or organization for more information, or find your site <a href={`/test_groups/${testGroup.slug}`} style={{textDecoration: "none"}}>here</a>.</div>}
          icon={faTriangleExclamation}
        />}
        <TopSection
          user={user}
          testGroup={testGroup}
        />
        <MiddleSection
          appointment={appointment}
          appointment_slot={appointment_slot}
          appointment_slot_group={appointment_slot_group}
          isAppointmentCanceled={isAppointmentCanceled}
          modalOpen={modalOpen}
          results={results}
          setShowRescheduleModal={setShowRescheduleModal}
          showRescheduleModal={showRescheduleModal}
          testGroup={testGroup}
          user={user}
        />
        <BottomSection
          appointment={appointment}
          appointment_slot={appointment_slot}
          appointment_slot_group={appointment_slot_group}
          results={results}
          user={user}
          loggedIn={loggedIn}
          testGroup={testGroup}
        />
        <div className="text-center mt-6">
          <a href="/terms-of-service">{t('self_resulting.terms_of_service')}</a>
        </div>
      </div>
    </Container>
  );
};

// Instructions.propTypes = {
//   testGroup.show_general_instructions && (): PropTypes.object.isRequired, // this is passed from the Rails view
//   valid_access_key: PropTypes.string, // this is passed from the Rails view
//   employment_statuses: PropTypes.array.isRequired, // this is passed from the Rails view
//   country_codes: PropTypes.object.isRequired, // this is passed from the Rails view
//   symptoms: PropTypes.array.isRequired, // this is passed from the Rails view
//   user: PropTypes.object.isRequired, // this is passed from the Rails view
// };

export default Show;
