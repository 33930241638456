import React, { useContext } from 'react';
import LocalizedMarkdown from '../../../Registration/components/registration/fields/LocalizedMarkdown';
import { Card, Collapse, Row } from 'react-bootstrap';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import SignatureInput from '../../../Registration/components/registration/inputs/SignatureInput';
import RegistrationContext from '../../../Registration/contexts/RegistrationContext';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import { useState } from 'react';
import { ChevronDropDown } from '../../../common/components/Chevron';
import { setGuardianOptions, GuardianForm } from '../../../Registration/components/registration/Consent';

export const validateConsent = (testGroup) => (values) => {
  let errors = {};
  const consents = findConsents(values, testGroup);
  if (consents.length == 0) return errors;

  const consentErrors = {}
  consents.map((testConfiguration) => {
    const idx = findIndexOfTestConfiguration(values, testConfiguration);
    consentErrors[idx] = {};
    const consentValue = values.consent_form_test_group_test_configurations[idx];
    if (consentValue.consent == "yes" && !consentValue.signature_base64) consentErrors[idx].signature_base64 = "registration.errors.field_required";
    if (!consentValue.consent) consentErrors[idx].consent = "registration.errors.field_required";
    
    if (consentValue.guardian_consented && !consentValue.consented_guardian_relationship_type) {
      consentErrors[idx].consented_guardian_relationship_type = 'registration.errors.option_required';
    }
    if (Object.keys(consentErrors[idx]).length == 0) delete consentErrors[idx];
  });
  if (Object.keys(consentErrors).length > 0) errors = consentErrors;

  return errors;
}

const findConsents = (values, testGroup) => {
  const chosenTestConfigurations =  values.chosen_test_configurations.length > 0 ? values.chosen_test_configurations.map(x => x.test_configuration_id) : [];
  return values.chosen_test_configurations.length > 0
    ? testGroup.test_configurations.filter(tc => tc.test_group_test_configuration.active_consent && chosenTestConfigurations.includes(tc.id))
    : testGroup.test_configurations.filter(tc => tc.test_group_test_configuration.active_consent)
}

const findIndexOfTestConfiguration = (values, testConfiguration) => {
  return values.consent_form_test_group_test_configurations.findIndex(consent_form_tg => (
    consent_form_tg.test_group_test_configuration_id == testConfiguration.test_group_test_configuration.id
  ))
}

const SignConsentsForTestConfigurations = ({ values, setFieldValue, errors }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, user } = useContext(RegistrationContext);
  const [collapse, setCollapse] = useState({})

  const consents = findConsents(values, testGroup);
  const guardianOptions = setGuardianOptions(testGroup.allow_verbal_consent, user.over_age_of_consent, t);

  const iAmProvidingConsentForMyself = (idx) => (
    <FabrxCheckbox
      name={`consent_form_test_group_test_configurations[${idx}][consent]consent_for_myself`}
      ariaLabel={t("registration.self_consent")}
      checked={
        !values.consent_form_test_group_test_configurations[idx].guardian_consented &&
        values.consent_form_test_group_test_configurations[idx].consent == "yes"
      }
      radio
      onChange={() => {
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent]`, "yes")          
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][guardian_consented]`, false)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consented_guardian_relationship_type]`, null)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent_guardian_name_signature]`, null)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent_guardian_last_name_signature]`, null)
      }}
      label={t('registration.self_consent')} />
  )

  const iDeclineConsentForMyself = (idx) => (
    <FabrxCheckbox
      name={`consent_form_test_group_test_configurations[${idx}][consent]decline_consent_for_myself`}
      ariaLabel={t("registration.no_self_consent")}
      checked={
        values.consent_form_test_group_test_configurations[idx].consent == "no" &&
        !values.consent_form_test_group_test_configurations[idx].guardian_consented
      }
      radio
      onChange={() => {
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][guardian_consented]`, false)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent]`, "no")
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consented_guardian_relationship_type]`, null)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consented_guardian_name]`, null)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consented_guardian_last_name]`, null)
      }}
      label={t('registration.no_self_consent')} />
  );

  const iAmProvidingConsentOnBehalf = (idx) => (
    <FabrxCheckbox
      name={`consent_form_test_group_test_configurations[${idx}][guardian_consented]consent_on_behalf`}
      ariaLabel={t('registration.insist_guardian_consent')}
      checked={
        !!values.consent_form_test_group_test_configurations[idx].guardian_consented &&
        values.consent_form_test_group_test_configurations[idx].consent == "yes"
      }
      radio
      onChange={() => {
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][guardian_consented]`, true)
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent]`, "yes")
      }}
      label={`${t('registration.insist_guardian_consent')} ${user.first_name} ${user.last_name}`} />
  );

  const iDeclineConsentOnBehalf = (idx) => (
    <FabrxCheckbox
      name={`consent_form_test_group_test_configurations[${idx}][guardian_consented]decline_consent_on_behalf`}
      ariaLabel={t('registration.no_insist_guardian_consent')}
      checked={
        values.consent_form_test_group_test_configurations[idx].consent == "no" &&
        !!values.consent_form_test_group_test_configurations[idx].guardian_consented
      }
      radio
      onChange={() => {
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][consent]`, "no")
        setFieldValue(`consent_form_test_group_test_configurations[${idx}][guardian_consented]`, true)
      }}
      label={t('registration.no_insist_guardian_consent', {full_name: `${user.first_name} ${user.last_name}`})} />
  );

  const guardianInputs = (idx, value, show) => (
    show ? <>
        <div className='my-2'>
          <FloatingLabelSelect
            ariaLabel="Select consented relationship"
            value={guardianOptions.find((o) => o.value === value.consented_guardian_relationship_type)}
            onChange={(selectedOption) => {
              setFieldValue(`consent_form_test_group_test_configurations[${idx}].consented_guardian_relationship_type`, selectedOption.value)
            }}
            options={guardianOptions}
            ariaDescribedby="guardianRelationshipDesc"
            id={"guardian_relationship_type" + idx}
            name={"guardian_relationship_type" + idx}
            filledValue={value.consented_guardian_relationship_type}
            label="Consented relationship"
            className={errors[idx]?.consented_guardian_relationship_type && 'is-invalid'}
          />
          {warning(errors[idx]?.consented_guardian_relationship_type)}
        </div>
        <Row className="form-inline">
          <GuardianForm
            values={value}
            name={`consent_form_test_group_test_configurations[${idx}]`}
            errors={errors[idx] || {}}
            user={user}
            t={t}
            handleChange={(e) => setFieldValue(e.target.name, e.target.value)}
            setFieldValue={setFieldValue}
          />
        </Row>
      </>
    : <></>
  )

  const handleShow = (idx) => setCollapse({...collapse, [idx]: !collapse[idx]});
  const warning = (error) => error && <div className='text-danger'>{t(error)}</div>;
  return (
    <div className="form-section">
      <h2 className="h5 font-weight-bold">Additional consents for {user.first_name} {user.last_name}</h2>
      <div>Please provide or decline your consent for services.</div>
      {consents.map((testConfiguration) => {
        const idx = findIndexOfTestConfiguration(values, testConfiguration);
        const value = values.consent_form_test_group_test_configurations[idx];
        const canConsentForSelf = user.over_age_of_consent || testConfiguration.test_group_test_configuration.active_consent.custom_age_range;
        return (
          <Card body className='my-4'>
            <div onClick={()=> handleShow(idx)}>
              <ChevronDropDown open={!collapse[idx]} setOpen={() => handleShow(idx)} />
              <h6>
                {testConfiguration.display_name} consent
              </h6>
            </div>
            <Collapse in={!collapse[idx]}>
              <div>
                <LocalizedMarkdown container={testConfiguration.test_group_test_configuration.active_consent} stringKey='consent_form_text' />
                {canConsentForSelf && iAmProvidingConsentForMyself(idx)}
                {canConsentForSelf && iDeclineConsentForMyself(idx)}
                {iAmProvidingConsentOnBehalf(idx)}
                {guardianInputs(idx, value, value.guardian_consented && value.consent == "yes")}
                {iDeclineConsentOnBehalf(idx)}
                {guardianInputs(idx, value, value.guardian_consented && value.consent == "no")}
                {warning(errors[idx]?.consent)}
                {values.consent_form_test_group_test_configurations[idx].consent == "yes" &&
                  <SignatureInput
                    initialSignatureBase64={value.signature_base64}
                    fieldValueName={`consent_form_test_group_test_configurations[${idx}].signature_base64`}
                    setFieldValue={setFieldValue}
                  />
                }
                {warning(errors[idx]?.signature_base64)}
              </div>
            </Collapse>
          </Card>
        )}
      )}
    </div>
)};

export default SignConsentsForTestConfigurations;
