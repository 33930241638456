import React from "react";
import { capitalize } from 'lodash';

const STATUS_COLORS = {
  default: {
    color: "#000000",
    backgroundColor: "#FAFAFA",
  },
  active: {
    color: "#2E948E",
    backgroundColor: "#EEFBF9",
  },
  draft: {
    color: "#FB4E03",
    backgroundColor: "#FFF9DB",
  },
  template: {
    color: "#4C00C6",
    backgroundColor: "#EDE9FB",
  },
  demo: {
    color: "#2862FA",
    backgroundColor: "#E9F1FB",
  },
  read_only: {
    color: "#4A5468",
    backgroundColor: "#EDF0F7",
    icon: "fa-lock",
  },
};

const StatusBadge = ({
  backgroundColor = null,
  capitalizeLabel = true,
  className = "my-auto",
  color = null,
  status = "default",
  text = status.replace(/_/g, '\u00A0'),
}) => {
  const label = capitalizeLabel ? capitalize(text): text;
  return (
    <span
      className={`my-auto ${className}`}
      style={{
        color: color || STATUS_COLORS[status]?.color,
        backgroundColor: backgroundColor || STATUS_COLORS[status]?.backgroundColor,
        padding: "4px 8px",
        borderRadius: "4px",
      }}
    >
      {!!STATUS_COLORS[status]?.icon && (
        <i className={`fa-regular ${STATUS_COLORS[status].icon} me-1`} style={{height: "12px", marginBottom: "2px"}}></i>
      )}
      <span
        className="my-auto"
        style={{
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: "700",
        }}
      >
        {label}
      </span>
    </span>
  );
};

export default StatusBadge;