import React, { useContext, useState, useEffect } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Modal, Button } from 'react-bootstrap'

import RegistrationContext from '../../../contexts/RegistrationContext';

const UserAlreadyExistsModal = () => {
  const { t, i18n } = useTranslation();
  const {
    testGroup,
    currentRegistrationStep,
    setCurrentRegistrationStep,
    localFormState,
    setLocalFormState,
  } = useContext(RegistrationContext);

  const [timeLeft, setTimeLeft] = useState(20);
  const overlappingContactInformation = localFormState.overlappingContactInformation;
  const duplicationType = localFormState.duplicationType;

  let timer;
  useEffect(() => {
    timer = setInterval(() => {
      setTimeLeft(timeLeft => Math.max(0, timeLeft - 1));
    }, 1000);
  }, [], () => {
    clearInterval(timer);
  });
  const overlappingEmail = overlappingContactInformation.email;
  const overlappingPhoneNumber = overlappingContactInformation.phone_number;
  let message;
  const phraseKey = duplicationType == 'appointment' ? 'duplicate_users' : 'duplicate_waitlist';

  if (overlappingEmail && overlappingPhoneNumber) {
    message = (
      <p>
        {t(`registration.${phraseKey}.overlapping_email_and_phone_p1`)}
        <br/>
        {t(`registration.${phraseKey}.p2`)}
        <br/>
        <b>{t(`registration.${phraseKey}.overlapping_phone_and_email_p3`)}</b>
      </p>
    );
  } else if (overlappingEmail) {
    message = (
      <p>
        {t(`registration.${phraseKey}.overlapping_email_p1`)}
        <br/>
        {t(`registration.${phraseKey}.p2`)}
        <br/>
        <b>{t(`registration.${phraseKey}.overlapping_email_p3`)}</b>
      </p>
    );
  } else if (overlappingPhoneNumber) {
    message = (
      <p>
        {t(`registration.duplicate_users.overlapping_phone_p1`)}
        <br/>
        {t(`registration.${phraseKey}.p2`)}
        <br/>
        <b>{t(`registration.${phraseKey}.overlapping_phone_p3`)}</b>
      </p>
    );
  }
  return (
    <Modal
      show={localFormState.showUserAlreadyExistsModal}
      dialogClassName="modal-600w"
    >
      <Modal.Body className="text-center">
        <div className="h1 text-warning">
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </div>
        <h5>{ t(`registration.${phraseKey}.exists`) }</h5>
        {message}
        <a
          href="mailto:support@primary.health"
          className="my-2"
        >
          {t('registration.duplicate_users.p4')}
        </a><br/>
        <Button onClick={() => location.reload()} style={{width: "200px"}} className="my-3">Close</Button>
        {localFormState.currentUserPresent && 
          <Button
            variant="warning"
            className="ms-3"
            onClick={() =>{
              setLocalFormState({
                ...localFormState,
                showUserAlreadyExistsModal: false,
              })
            }}
          >
            Continue Registration Anyways
          </Button>
        }
      </Modal.Body>
    </Modal>
  );
};
export default UserAlreadyExistsModal;
