import React, { useState } from 'react';
import FloatingLabelInput from '../common/components/FloatingLabelInput';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const PrimarySearchBar = ({ children, ...props }) => {
  const [disabled, setDisabled] = useState(props.isDisabled)
  const { t } = useTranslation();
  const buttonText = props.buttonText || t("enter_information.search")
  const label = props.label || t("enter_information.search")

  return (
    <Form action={props.action} className="pds-search-form">
      <div className="w-100">
        <FloatingLabelInput
          dataTestHook={props.dataTestInputHook}
          value={props.value}
          onChange={(e) => {
            if (props.onChange) { props.onChange(e) }
            setDisabled(e.target.value?.length === 0 || e.target.value === undefined)
          }}
          label={label}
          validation={props.validation}
          id="search_q"
          name="q"
        />
        {props.validation !== "" && <div className="text-danger lead-4 mt-2">{props.error}</div>}
        {children}
      </div>
      <Button variant="primary" className="btn-search btn-lg" onClick={props.onClick} disabled={disabled}
        as="input" name="commit" type="submit" value={buttonText} data-disable-with={buttonText}
        data-test-hook={props.dataTestButtonHook} />
    </Form>
  );
};

export default PrimarySearchBar;