import React from 'react';
import Icon from '../../common/components/AssetIcon';

const ServiceSpecificationBadge = ({
  serviceSpecification: { label },
  onRemove,
  t,
}) => {
  return (
    <span className="p-2 my-2 service-specification-badge">
      {t(label)}
      <Icon
        className="ms-2 pointer"
        onClick={onRemove}
        path="/images/icons/icon-x.svg"
        style={{ width: 14 }}
      />
    </span>
  );
};

export default ServiceSpecificationBadge;
