import React, { useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HelpText = ({
  text,
  size = 'sm',
  placement = 'bottom',
  activeClass = 'text-primary',
  inactiveClass = 'text-secondary',
  icon = ['far', 'question-circle'],
  style,
}) => {
  const [className, setClassName] = useState(inactiveClass);
  const updateClassName = (className) => () => setClassName(className);

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip>
          {typeof text === 'string'
            ? text.split(/\n|\\n|<br>/).map((subText, idx) => (
                <span className="d-block" key={idx}>
                  {subText}
                </span>
              ))
            : text}
        </Tooltip>
      }
      onEnter={updateClassName(activeClass)}
      onExit={updateClassName(inactiveClass)}
    >
      <FontAwesomeIcon
        className={className}
        size={size}
        icon={icon}
        style={style}
      />
    </OverlayTrigger>
  );
};

export default HelpText;
