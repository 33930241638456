import React, { useEffect, useRef, useState } from 'react';

const useRefetchable = <State>(
  fetchData: (args: React.DependencyList) => Promise<State>,
  initialState?: State,
  deps?: React.DependencyList,
): [State, boolean] => {
  const previousDeps = useRef(deps);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (previousDeps.current !== deps) {
      setLoading(true);
      fetchData(deps).then((data) => {
        setState(data);
        setLoading(false);
        previousDeps.current = deps;
      });
    }
  }, deps);

  return [state, loading];
};

export default useRefetchable;
