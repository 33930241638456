import '../../common/locales/i18n';

export const handleLanguageChange = (i18n) => (locale) => {
  if (locale === 'ar' || locale === 'ur') {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    document
      .getElementsByTagName('body')[0]
      .setAttribute('class', 'language_rtl');
  } else {
    document.getElementsByTagName('html')[0].setAttribute('dir', '');
    document.getElementsByTagName('body')[0].setAttribute('class', '');
  }
  i18n.changeLanguage(locale);
};
