import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import ScheduleAppointmentButton from './ScheduleAppointmentButton';

const selectedPinSvg = `
  <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 12C24 17.4625 16.6875 27.1875 13.4812 31.2C12.7125 32.1562 11.2875 32.1562 10.5188 31.2C7.3125 27.1875 0 17.4625 0 12C0 5.375 5.375 0 12 0C18.625 0 24 5.375 24 12Z" fill="#F70235"/>
  </svg>
`;
const unselectedPinSvg = `
  <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 12C24 17.4625 16.6875 27.1875 13.4812 31.2C12.7125 32.1562 11.2875 32.1562 10.5188 31.2C7.3125 27.1875 0 17.4625 0 12C0 5.375 5.375 0 12 0C18.625 0 24 5.375 24 12Z" fill="#808080" opacity="0.6"/>
  </svg>
`;
const selectedPartialPinSvg = `
  <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 32C12 32 24 19 24 12C24 5.375 18.625 0 12 0C5.375 0 0 5.375 0 12C0 19 12 32 12 32ZM12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8Z" fill="#F70235"/>
  </svg>
`;
const unselectedPartialPinSvg = `
  <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 32C12 32 24 19 24 12C24 5.375 18.625 0 12 0C5.375 0 0 5.375 0 12C0 19 12 32 12 32ZM12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8Z" fill="#808080" opacity="0.6"/>
  </svg>
`;
export const selectedPinIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(selectedPinSvg);
export const unselectedPinIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(unselectedPinSvg);
export const selectedPartialPinIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(selectedPartialPinSvg);
export const unselectedPartialPinIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(unselectedPartialPinSvg);

function ClinicsMap({
  center,
  dispatch,
  exactMatchAppointmentSlotGroups,
  markers,
  selectedMarker,
  setSelectedMarker,
  zoomControl=true,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: gon.google_maps_api_key // ,
  })

  const defaultCenter = {
    lat: parseFloat(markers[0]?.latitude),
    lng: parseFloat(markers[0]?.longitude)
  }

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return (
      <div className='clinics-map position-relative'>
        <GoogleMap
          id="google-map"
          mapContainerStyle={{
            height: "100%",
            width: "100%"
          }}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            scaleControl: false,
            navigationControl: false,
            zoomControl
          }}
          zoom={11}
          center={center ? center : defaultCenter}
        >
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.latitude) + 0.01,
                lng: parseFloat(selectedMarker.longitude)
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div style={{
                background: `white`,
                padding: 15,
                width: "350px",
              }}>
                <b>{selectedMarker.title}</b>
                <div className="text-secondary">
                  {selectedMarker.address}
                </div>
                <div className="mt-2">
                  <ScheduleAppointmentButton dispatch={dispatch} appointmentSlotGroup={selectedMarker} />
                </div>
              </div>
            </InfoWindow>
          )}

          {markers.map(marker => {
            const displayAsSelected = !selectedMarker || selectedMarker === marker;
            const icon = exactMatchAppointmentSlotGroups?.includes(marker)
              ? displayAsSelected
                ? selectedPinIcon
                : unselectedPinIcon
              : displayAsSelected
                ? selectedPartialPinIcon
                : unselectedPartialPinIcon;
            return (
              <Marker
                icon={icon}
                onClick={() => setSelectedMarker(marker)}
                position={{
                  lat: parseFloat(marker.latitude),
                  lng: parseFloat(marker.longitude)
                }}
              />
            )
          })}
        </GoogleMap>
      </div>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : "Loading"
}

export default ClinicsMap;
