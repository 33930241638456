import React from 'react';
import { Button } from 'react-bootstrap'
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';

export const PhotoBypassButtons = ({
  displayBypassPhotoFields,
  locked,
  requireLabPhoto,
  setTest,
  test,
}) => {
  if (!requireLabPhoto || locked) return <span></span>;
  return (
    <>
      {displayBypassPhotoFields ? (
        <Button
          className="my-2"
          onClick={() => {
            const clonedTest = { ...test, dirty: true, };
            delete clonedTest["administration"];
            setTest(clonedTest);
          }}
          size="sm"
          variant="outline-secondary"
        >
          Take a photo instead
        </Button>
      ) : (
        <Button
          className="my-2"
          onClick={() => {
            setTest({
              ...test,
              dirty: true,
              administration: {}
            })
          }}
          size="sm"
          variant="outline-secondary"
        >
          Not able to upload a photo?
        </Button>
      )}
    </>
  );
};

const PhotoBypassFields = ({
  locked,
  photoBypassReason,
  photoBypassCustomReason,
  setTest,
  test,
}) => {

  const photoBypassReasonOptions = test?.options?.photo_bypass_reason_options &&
    Object.entries(test.options.photo_bypass_reason_options).map(([k,v]) => {
      return { value: k, label: v };
    });

  return (
    <div className="notes-area my-3">
      <label className="no-select form-label mx-2 my-3">Select a reason why you can't upload a photo</label>
      <FloatingLabelSelect
        filledValue={photoBypassReason}
        isDisabled={locked}
        label="Reason *"
        onChange={(e) => {
          setTest({
            ...test,
            dirty: true,
            administration: {
              photo_bypass_reason: e.value,
            }
          })
        }}
        options={photoBypassReasonOptions}
        value={photoBypassReasonOptions.filter(o => o.value === photoBypassReason)}
      />
      {photoBypassReason === "other" && (
        <div className="my-3">
          <FloatingLabelInput
            isDisabled={locked}
            label="Please provide further explanation *"
            onChange={(e) => {
              setTest({
                ...test,
                dirty: true,
                administration: {
                  ...test.administration,
                  photo_bypass_custom_reason: e.target.value,
                }
              })
            }}
            value={photoBypassCustomReason}
          />
        </div>
      )}
    </div>
  );
};

export default PhotoBypassFields;
