import React, { FC, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from '../Registration/components/Header';
import PolicyHolder from "./components/PolicyHolder";
import HaveInsurance from "./components/HaveInsurance";
import UploadInsuranceCard from "./components/UploadInsuranceCard";
import InsuranceSurvey from "./components/InsuranceSurvey";
import ReviewAndConfirm from "./components/ReviewAndConfirm";
import {
  Action,
  NavigationMap,
  State,
 useNavigation } from '../common/components/Navigation/helpers/useNavigation';
import { submit } from "./helpers/requests";
import { useTranslation } from 'react-i18next';

interface InsuranceProps {
  test_group: any;
  user: any;
}

interface InsuranceState {
  formState: {
    policyNumber?: string;
    policyHolder?: string;
    policyHolderDateOfBirth?: {
      day?: number;
      month?: number;
      year?: number;
    }
    memberId?: string;
    groupNumber?: string;
    insuranceCompanyName?: string;
    policyHolderLastName?: string;
    policyHolderFirstName?: string;
    haveHealthInsurance?: boolean;
    insuranceCardFront?: File,
    insuranceCardBack?: File,
    insuranceCardFrontPreview?: Blob;
    insuranceCardBackPreview?: Blob;
    insuranceAnswers?: any;
  },
  testGroup?: any;
  user?: any;
}

const STEPS: NavigationMap<InsuranceState> = {
  haveInsurance: {
    component: HaveInsurance,
    isDisabled: ({formState}) => formState.haveHealthInsurance === undefined,
    navigationActions: {
      next: 'policyHolder',
    },
  },
  policyHolder: {
    component: PolicyHolder,
    isDisabled: ({formState}) => (
      !formState.policyHolder ||
      !formState.policyHolderDateOfBirth.month ||
      !formState.policyHolderDateOfBirth.day ||
      !formState.policyHolderDateOfBirth.year
    ),
    skipIf: ({formState}) => formState.haveHealthInsurance === false,
    navigationActions: {
      next: "uploadInsuranceCard",
      back: 'haveInsurance',
    }
  },
  uploadInsuranceCard: {
    component: UploadInsuranceCard,
    isDisabled: ({formState}) => (
      !formState.groupNumber ||
      !formState.memberId ||
      !formState.insuranceCompanyName ||
      !formState.policyHolderLastName ||
      !formState.policyHolderFirstName
    ),
    skipIf: ({formState}) => formState.haveHealthInsurance === false,
    navigationActions: {
      next: "insuranceSurvey",
      back: "policyHolder",
    }
  },
  insuranceSurvey: {
    component: InsuranceSurvey,
    isDisabled: () => (
      false
    ),
    skipIf: ({testGroup, formState}) => (
      (testGroup.show_only_insurance_survey && formState.haveHealthInsurance === true) ||
      (!testGroup.show_insurance_survey && !testGroup.show_only_insurance_survey) ||
      Object.keys(testGroup.insurance_survey || {}).length == 0
    ),
    navigationActions: {
      next: "review",
      back: "haveInsurance",
    }
  },
  review: {
    component: ReviewAndConfirm,
  }
};

const reducer = (
  state: State<InsuranceState>,
  action: Action,
): State<InsuranceState> => {
  switch (action.type) {
    case 'updateForm':
      if (action.values.policyHolder == "i_am") {
        const dob = state.user.date_of_birth.split("-")
        return ({
          ...state,
          formState: {
            ...state.formState,
            ...action.values,
            policyHolderFirstName: state.user.first_name,
            policyHolderLastName: state.user.last_name,
            policyHolderDateOfBirth: {
              month: parseInt(dob[1]),
              year: parseInt(dob[0]),
              day: parseInt(dob[2]),
            }
          }
        })
      }
      return action.key
        ? {
            ...state,
            formState: {
              ...state.formState,
              [action.key]: {
                ...(state.formState[action.key] || {}),
                ...action.values,
              },
            },
          }
        : {
            ...state,
            formState: { ...state.formState, ...action.values },
          };
    default:
      throw new Error(`Unknown action ${action.type}.`);
  }
};


const InsuranceV2: FC<InsuranceProps> = ({test_group, user}) => {
  const { t } = useTranslation();
  const { state, dispatch, currentNode } = useNavigation(
    STEPS,
    'haveInsurance',
    { formState: {policyHolderDateOfBirth: {}}, testGroup: test_group, user },
    reducer,
  );
  const CurrentComponent = currentNode.component;

  useEffect(()=> {
    window.scrollTo(0,0);
  },[currentNode]);

  return (
    <div>
      <Header testGroup={test_group} user={user} />
      <Container className='mt-4' style={{maxWidth: 600}}>
      {currentNode.navigationActions?.back &&
        <Button variant="link" className='p-0 mb-2' onClick={() => dispatch({ type: 'perform_navigation', action: 'back' })}>
          {t('registration.back_button')}
        </Button>
      }
        <CurrentComponent state={state} dispatch={dispatch} />
        {state.navigation.location === "review"
          ? <Button
            variant="primary"
            className='my-4'
            block
            onClick={() => submit(state)}
          >
            {t('registration.submit')}
          </Button>
          : <Button
            variant="primary"
            block
            className='my-4'
            disabled={currentNode.isDisabled(state)}
            onClick={() => dispatch({ type: 'perform_navigation', action: 'next' })}
          >
            {t('registration.continue_button')}
          </Button>
        }
      </Container>
    </div>
  )
};

export default InsuranceV2;
