import React, { useState } from 'react';
import Countdown from 'react-countdown';

const InactivityBanner = ({ expires_at }) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    const style = {
      backgroundColor: '#ff6a0036',
      margin: 5,
      padding: 5,
      borderRadius: 3,
      border: 'solid 1px #dadada',
      width: 'fit-content',
      maxWidth: 400,
      fontSize: 13
    };

    if (new Date(expires_at) - new Date() < 120000) {
      if (completed) {
        setTimeout(() => { location.reload() }, 5000);
        return <div style={style}><span>logging out..</span></div>
      } else {
        return <div style={style}><span>You will be logged out due to inactivity soon: {minutes}:{seconds}</span></div>
      }
    } else {
      return <></>
    }
  };

  return (
    <Countdown
      percision={10}
      date={new Date(expires_at)}
      renderer={renderer}
      zeroPadTime={2}
    />
  );
};

export default InactivityBanner;
