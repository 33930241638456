import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Spinner } from "react-bootstrap";

import NewCommunicationContext from "../contexts/NewMessageContext";
import TablePagination from "../../common/components/TablePagination";

const RecipientRow = ({
  user,
}) => {
  return (
    <Row>
      <Col className="my-auto">
        <div className="body-medium-bold inter semibold">{user.full_name}</div>
        <div className="body-medium-normal inter regular">{user.date_of_birth} ({user.age})</div>
      </Col>
      <Col className="my-auto">
        <div className="body-medium-normal inter regular">{user.phone_number}</div>
        <div className="body-medium-normal inter regular">{user.email}</div>
      </Col>
      <Col className="body-medium-normal inter regular my-auto">
        {user.test_groups}
      </Col>
      <Col className="body-medium-normal inter regular my-auto">
        {user.populations}
      </Col>
    </Row>
  );
};

const RecipientsTable = ({}) => {

  const {
    dispatch,
    state,
  } = useContext(NewCommunicationContext);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationMetadata, setPaginationMetadata] = useState({});

  const fetchTestGroupUsers = async () => {
    setLoading(true);
    const response = await axios.get("/filter_message_recipients.json", {
      params: {
        filter_params: {
          ...state.filter_params,
          administered_at_date: state.filter_params.administered_at_date?.map(d => d.format("YYYY-MM-DD")),
          appointment_date: state.filter_params.appointment_date?.map(d => d.format("YYYY-MM-DD")),
        },
        page: page,
      }
    });
    if (response.status === 200) {
      setPaginationMetadata(response.data.pagination_meta_data);
      dispatch({ type: "setAllFilteredTestGroupUserIds", values: response.data.all_filtered_test_group_user_ids });
      dispatch({ type: "setPaginatedRecipients", values: response.data.users });
      dispatch({ type: "setRecipientCount", values: response.data.user_count });

      const filterOptionsChanged = !_.isEqual(state.filter_options, response.data.updated_filter_options);
      if (filterOptionsChanged) {
        dispatch({ type: "setFilterOptions", values: response.data.updated_filter_options });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTestGroupUsers();
  }, [state.filter_params, page]);

  return (
    <div>
      <Row>
        <Col className="body-medium-normal inter regular">NAME</Col>
        <Col className="body-medium-normal inter regular">CONTACT INFO</Col>
        <Col className="body-medium-normal inter regular">GROUP</Col>
        <Col className="body-medium-normal inter regular">POPULATION</Col>
      </Row>
      <hr />
      {loading ? (
        <div className="text-center mt-6">
          <Spinner variant="primary" animation="border" />
        </div> 
      ) : (
        <>
          {state.paginated_recipients.map((user, idx) => {
            return (
              <>
                <RecipientRow user={user} />
                {idx != state.paginated_recipients.length - 1 && (
                  <hr />
                )}
              </>
            )
          })}
        </>
      )}
      <div className="mt-5">
        <TablePagination
          currentPage={paginationMetadata.current_page}
          nextPage={paginationMetadata.next_page}
          prevPage={paginationMetadata.prev_page}
          totalPages={paginationMetadata.total_pages}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default RecipientsTable;