import React, { useEffect, useState } from 'react';
import YesNoCheckbox from '../../common/components/YesNoCheckbox';

const RandomValueField = () => {
  const [randomValue, setRandomValue] = useState('');
  const [valueType, setValueType] = useState(null);
  const fieldName = `random_${valueType}`;

  const handleRandomValueChange = (value) => {
    if (/^[0-9\b]*$/.test(value)) {
      if (valueType === 'percentage' && parseInt(value) > 100)
        setRandomValue('100');
      else setRandomValue(value);
    }
  };

  useEffect(() => {
    handleRandomValueChange(randomValue);
  }, [valueType]);

  return (
    <React.Fragment>
      <YesNoCheckbox
        value={valueType}
        yesLabel="Random amount"
        noLabel="Random percentage"
        yesValue="amount"
        noValue="percentage"
        onChange={setValueType}
      />
      {valueType && (
        <React.Fragment>
          <label
            class="action-label mt-4"
            htmlFor="communication_params_filter_params_random_amount"
          >
            Enter the {valueType} of users to randomly select
          </label>
          <input
            className="form-control string required mt-1 mb-2"
            type="text"
            value={randomValue}
            onChange={(e) => handleRandomValueChange(e.target.value)}
            name={`communication[params][filter_params][${fieldName}]`}
            id="communication_params_filter_params_random_amount"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RandomValueField;
