import React from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Spinner } from "react-bootstrap";
import { faSignOutAlt, faUser } from '@fortawesome/pro-regular-svg-icons';
import { profileColors } from '../utils';

const NOT_READY = 0;
const NO_HOUSEHOLD_MEMBERS = 2;

const ProfileImage = ({householdMember, householdMembers}) => {
  const colorIndex = householdMembers.findIndex(x => x.id == householdMember.id);

  return (
    <div
      className='profile-circle'
      style={{
        backgroundColor: profileColors[colorIndex] ? profileColors[colorIndex] : "#2862FA",
      }}
    >
      <div>
        {householdMember.user.first_name[0].toUpperCase()}
      </div>
    </div>
  )
}

const SwitchUsers = ({setCurrentUser, currentUser={}, householdMembers=[], ready=NO_HOUSEHOLD_MEMBERS, disableOtherMembers=false}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  if (ready === NOT_READY) return <Spinner animation="border" variant="primary" size="sm" />;
  if (ready === NO_HOUSEHOLD_MEMBERS) return (
    <Dropdown className="ms-2">
      <Dropdown.Toggle variant="link" id="dropdown-account">
        <FontAwesomeIcon icon={faUser} className="mb-1"/>
      </Dropdown.Toggle>

      <Dropdown.Menu className='mt-2 members'>
        <Dropdown.Item href="/members/me">
          Dashboard
        </Dropdown.Item>
        <Dropdown.Item href="/members/sign_out">
          <FontAwesomeIcon icon={faSignOutAlt} /><span className="ms-2">Logout</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  const householdMemberDropdownOptions = householdMembers.filter(hm => hm.id != currentUser.id)

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-account" data-test-hook="dropdown_account">
        {currentUser.id ? <ProfileImage householdMember={currentUser} householdMembers={householdMembers} />: <FontAwesomeIcon icon={faUser} className='mb-1'/>}
        <span>
          {!isMobile && currentUser.id && currentUser.user.first_name}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className='mt-2 members'>
        {!disableOtherMembers && householdMemberDropdownOptions.map(h => (
          <Dropdown.Item
            key={h.id}
            data-test-hook={`household_member_${h.id}`}
            onClick={() => setCurrentUser(h)}
            style={{textDecoration: "none"}}
          >
            <ProfileImage householdMember={h} householdMembers={householdMembers} /> {h.user.first_name} {h.user.last_name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        {disableOtherMembers && 
          <Dropdown.Item href={`/members/me?current_user=${currentUser.id}`}>
            <span className="ms-2">Dashboard</span>
          </Dropdown.Item>
        }
        <Dropdown.Item href="/members/sign_out">
          <FontAwesomeIcon icon={faSignOutAlt} /><span className="ms-2">Sign out</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SwitchUsers;
