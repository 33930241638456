import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Portal from '../../Portal';
import ProgressBar from './ProgressBar';
import { useTranslation } from 'react-i18next';

const ContentWrapper = styled.div`
  width: 100%;

  @media (max-width: 576px) {
    min-height: 80vh;
  }
`;

const StickyContainer = styled.div`
  position: sticky;
  bottom: 0;
  height: 96;
  margin-left: -24px;
  margin-right: -24px;
  box-shadow: 0px -4px 4px rgba(33, 37, 41, 0.1);
`;

const StepButtons = ({
  onPreviousClick,
  onNextClick,
  validate = () => true,
  onCompleteClick,
  isLastStep,
  completeText = t('stepper.complete'),
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const handleNext = () => validate() && onNextClick();
  const handleComplete = () => validate() && onCompleteClick();
  const { t } = useTranslation();

  return isMobile ? (
    <StickyContainer className="position-sticky bg-white mt-4 px-3 py-4">
      <Row className="d-flex justify-content-between position-relative">
        {onPreviousClick && (
          <Col>
            <Button
              className="w-100"
              variant="outline-primary"
              onClick={onPreviousClick}
            >
              {t('stepper.back')}
            </Button>
          </Col>
        )}
        <Col>
          {isLastStep ? (
            <Button
              className="w-100"
              onClick={handleComplete}
              data-test-hook="submit"
            >
              {completeText}
            </Button>
          ) : (
            <Button
              className="w-100"
              onClick={handleNext}
              data-test-hook="next"
            >
              {t('stepper.next')}
            </Button>
          )}
        </Col>
      </Row>
    </StickyContainer>
  ) : (
    <div className="my-4 d-flex justify-content-between position-relative">
      <Button
        variant="outline-primary"
        onClick={onPreviousClick}
        disabled={!onPreviousClick}
      >
        {t('stepper.back')}
      </Button>
      {isLastStep ? (
        <Button onClick={handleComplete} data-test-hook="submit">
          {completeText}
        </Button>
      ) : (
        <Button onClick={handleNext} data-test-hook="next">
          {t('stepper.next')}
        </Button>
      )}
    </div>
  );
};

const Stepper = ({
  expandedView = false,
  getStepList,
  completeText,
  onComplete,
  ...props
}) => {
  const [step, setStep] = useState(0);
  const stepList = getStepList(props);
  const currentStep = stepList[step];
  const { t } = useTranslation();

  const onPreviousClick = () => {
    if (step > 0) setStep(step - 1);
  };

  const onNextClick = () => {
    if (step < stepList.length) setStep(step + 1);
  };

  return expandedView ? (
    <React.Fragment>
      {stepList.map((currentStep, idx) => (
        <React.Fragment key={idx}>
          {idx !== 0 && <hr />}
          <currentStep.Component {...currentStep.filterProps(props)} />
        </React.Fragment>
      ))}
      <div className="mt-4 d-flex justify-content-end">
        <Button
          onClick={() => {
            if (!stepList.map((step) => step.validate()).includes(false))
              onComplete();
          }}
          data-test-hook="submit"
        >
          {completeText}
        </Button>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Portal parentId="test_group_header">
        <ProgressBar
          title={t('vaccination_status.progress_bar_title')}
          totalSteps={stepList.length}
          completedSteps={step}
        />
      </Portal>
      <ContentWrapper>
        {currentStep && (
          <currentStep.Component {...currentStep.filterProps(props)} />
        )}
      </ContentWrapper>
      <StepButtons
        completeText={completeText}
        onPreviousClick={step !== 0 ? onPreviousClick : undefined}
        validate={currentStep.validate}
        onNextClick={onNextClick}
        onCompleteClick={onComplete}
        isLastStep={step === stepList.length - 1}
      />
    </React.Fragment>
  );
};

export default Stepper;
