import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildCustomizedLabel } from '../../../../common/locales/langs';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';
import { Alert } from 'react-bootstrap';
import { PrimaryIcon } from '../../../../common/components/Icons/PrimaryIcon';
import HelpText from '../../../../common/components/HelpText';

const PreferredMethodOfCommunication = ({values, setFieldValue}) => {
  const { t } = useTranslation();
  const { testGroup: {verify_contact_information: verifyContact, ...testGroup} } = useContext(RegistrationContext);
  const helperText = t(buildCustomizedLabel(
    testGroup,
    `registration.preferred_method_of_communication.${verifyContact ? 'verification_' : ''}helper_text`
  ));

  if (values.phone_number_unreachable) {
    return <div>
      <Alert variant="primary">
        <PrimaryIcon icon="alert-circle" style={{filter:"invert(9%) sepia(100%) saturate(7494%) hue-rotate(243deg) brightness(96%) contrast(103%)"}}/>
        <span className='ms-2 inter-medium text-dark'>{t("registration.preferred_method_of_communication.landline")}</span>
      </Alert>
      </div>
  }

  if (values.phone_number && values.email) {
    return (
      <div className="my-3">
        <h6 className='inter bold lead-20-medium'>
          {t(`registration.preferred_method_of_communication.${verifyContact ?  "verification_method" : "question"}`)}
          {' '}
          <HelpText text={helperText} placement='right' />
        </h6>
        <div>
          <FabrxCheckbox
            ariaLabel={t('registration.email')}
            name="preferred_method_of_communication"
            onChange={() => setFieldValue("preferred_method_of_communication", "email")}
            checked={values.preferred_method_of_communication == "email"}
            radio
            labelClassName='lead-20-medium inter regular'
            label={t('registration.email')}
            dataTestHook="prefer_email"
          />
        </div>
        <div>
          <FabrxCheckbox
            ariaLabel={t('registration.preferred_method_of_communication.phone_number')}
            name="preferred_method_of_communication"
            onChange={() => setFieldValue("preferred_method_of_communication", "phone_number")}
            checked={values.preferred_method_of_communication == "phone_number"}
            radio
            labelClassName='lead-20-medium inter regular'
            label={t('registration.preferred_method_of_communication.phone_number')}
            dataTestHook="prefer_phone_number"
          />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default PreferredMethodOfCommunication;
