import React from 'react';
import axios from 'axios';
import { FinishRegistrationBase } from '../../FinishRegistration';

const CustomFinishRegistration = ({ onSuccess, ...props }) => {
  const onSubmit = (values, bag) => {
    const url = `/test_groups/${values.test_group_slug}/registrations/${values.appointment.access_code}/update_user_information.json`;

    const params = {
      appointment: values.appointment,
      user: values,
    };

    axios
      .post(url, params)
      .then((response) => {
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FinishRegistrationBase
      onSubmit={onSubmit}
      {...props}
    />
  );
};

export default CustomFinishRegistration;
