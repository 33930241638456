import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { faFile } from '@fortawesome/pro-regular-svg-icons';

import ImageMagnifier from './ImageMagnifier';
export const TrashCanIcon = ({ color = '#4A5468', ...props }) => (
  <svg
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 2.5C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H12.875L12.125 14.1562C12.0312 15.2188 11.1875 16 10.125 16H3.84375C2.78125 16 1.9375 15.2188 1.84375 14.1562L1.09375 4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125L11.0625 2.5H13.25ZM5.53125 1.5C5.4375 1.5 5.34375 1.5625 5.3125 1.625L4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125ZM11.375 4H2.59375L3.34375 14.0625C3.375 14.3125 3.59375 14.5 3.84375 14.5H10.125C10.375 14.5 10.5938 14.3125 10.625 14.0625L11.375 4Z"
      fill={color}
    />
  </svg>
);

export const ExpandIcon = ({ color = '#4A5468', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0C10.4375 0 10 0.46875 10 1C10 1.5625 10.4375 2 11 2H12.5625L9.28125 5.3125C8.875 5.6875 8.875 6.34375 9.28125 6.71875C9.65625 7.125 10.3125 7.125 10.6875 6.71875L14 3.4375V5C14 5.5625 14.4375 6 15 6C15.5312 6 16 5.5625 16 5V1C16 0.46875 15.5312 0 15 0H11ZM6.6875 9.3125C6.3125 8.90625 5.65625 8.90625 5.28125 9.3125L2 12.5938V11C2 10.4688 1.53125 10 1 10C0.4375 10 0 10.4688 0 11V15C0 15.5625 0.4375 16 1 16H5C5.53125 16 6 15.5625 6 15C6 14.4688 5.53125 14 5 14H3.40625L6.6875 10.7188C7.09375 10.3438 7.09375 9.6875 6.6875 9.3125Z"
      fill={color}
    />
  </svg>
);

export const CollapseIcon = ({ color = '#4A5468', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m 2.0291618,8.9729231 c -0.5625001,0 -1,0.46875 -1,1 0,0.5624999 0.4374999,0.9999999 1,0.9999999 h 1.5625 l -3.2812501,3.3125 c -0.40625,0.375 -0.40625,1.03125 0,1.40625 0.375,0.40625 1.03125,0.40625 1.40625,0 l 3.3125,-3.28125 v 1.5625 c 0,0.5625 0.4375,1 1,1 0.5312,0 1,-0.4375 1,-1 V 9.9729231 c 0,-0.53125 -0.4688,-1 -1,-1 z M 15.695313,0.30290068 c -0.375,-0.40625 -1.03125,-0.40625 -1.40625,0 l -3.28125,3.28130002 v -1.5938 c 0,-0.5312 -0.46875,-1.00000002 -1,-1.00000002 -0.5625005,0 -1.0000005,0.46880002 -1.0000005,1.00000002 v 4 c 0,0.5625 0.4375,1 1.0000005,1 h 4 c 0.53125,0 1,-0.4375 1,-1 0,-0.5312 -0.46875,-1 -1,-1 h -1.59375 l 3.28125,-3.2812 c 0.406249,-0.375 0.406249,-1.03130002 0,-1.40630002 z"
      fill={color}
    />
  </svg>
);

const ActionButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: white;
  opacity: 0.9;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  line-height: 1;

  & > * {
    vertical-align: unset;
  }
`;

export const ActionButton = ({
  IconComponent = React.Fragment,
  iconColor,
  onClick,
}) => (
  <ActionButtonWrapper className="action-button" onClick={onClick}>
    <IconComponent style={{ maxHeight: 16, maxWidth: 16 }} color={iconColor} />
  </ActionButtonWrapper>
);

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 24px;
  aspect-ratio: 4 / 5;
  overflow: hidden;

  background: #f8f8fc;
  border: 1px solid #eaeaf2;

  .actions {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    gap: 12px;
    right: 15px;
    bottom: 15px;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: gray;
    opacity: 0.3;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      :not(:has(embed)) {
        aspect-ratio: auto;
        min-height: 200px;
      }

      .overlay {
        display: none;
      }

      .actions {
        top: 15px;
        bottom: unset;

        .action-button {
          background-color: #1a202c4d;
          color: white;
        }
      }
    `}
`;

export const FilePreview = ({
  url,
  contentType,
  actions = [],
  expanded,
  magnifiable = true,
}) => {
  let view;
  if (contentType.indexOf('image') >= 0) {
    view =
      expanded && magnifiable ? (
        <ImageMagnifier src={url} />
      ) : (
        <Image src={url} fluid />
      );
  } else if (contentType.indexOf('pdf') >= 0) {
    view = expanded ? (
      <embed src={url} height="100%" width="100%" type="application/pdf" />
    ) : (
      <Image
        className="icon"
        height="auto"
        src="/images/pdf-card-image.jpg"
        alt="document"
        fluid
      />
    );
  } else {
    view = <FontAwesomeIcon icon={faFile} />;
  }

  return (
    <PreviewContainer className="preview-container" expanded={expanded}>
      {view}
      <div className="overlay" />
      <div className="actions">
        {actions.map((action, idx) => (
          <React.Fragment key={idx}>{action}</React.Fragment>
        ))}
      </div>
    </PreviewContainer>
  );
};

export const AddFile = ({
  id = 'file_upload',
  name = 'file_upload',
  onChange: propOnChange = () => {},
  inputRef = React.createRef(),
  accept = 'image/png,image/jpeg,application/pdf',
}) => (
  <div className="text-center">
    <label className="w-100" htmlFor={id}>
      <PreviewContainer className="d-flex align-items-center justify-content-center pointer">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 12.5C1 12.7652 1.10536 13.0196 1.29289 13.2071C1.48043 13.3946 1.73478 13.5 2 13.5H10.75C10.9489 13.5 11.1397 13.579 11.2803 13.7197C11.421 13.8603 11.5 14.0511 11.5 14.25V23C11.5 23.2652 11.6054 23.5196 11.7929 23.7071C11.9804 23.8946 12.2348 24 12.5 24C12.7652 24 13.0196 23.8946 13.2071 23.7071C13.3946 23.5196 13.5 23.2652 13.5 23V14.25C13.5 14.0511 13.579 13.8603 13.7197 13.7197L13.7197 13.7197C13.8603 13.579 14.0511 13.5 14.25 13.5H23C23.2652 13.5 23.5196 13.3946 23.7071 13.2071C23.8946 13.0196 24 12.7652 24 12.5C24 12.2348 23.8946 11.9804 23.7071 11.7929C23.5196 11.6054 23.2652 11.5 23 11.5H14.25C14.0511 11.5 13.8603 11.421 13.7197 11.2803C13.579 11.1397 13.5 10.9489 13.5 10.75V2C13.5 1.73478 13.3946 1.48043 13.2071 1.29289C13.0196 1.10536 12.7652 1 12.5 1C12.2348 1 11.9804 1.10536 11.7929 1.29289L11.441 0.941031L11.7929 1.29289C11.6054 1.48043 11.5 1.73478 11.5 2V10.75C11.5 10.9489 11.421 11.1397 11.2803 11.2803L1 12.5ZM1 12.5C1 12.2348 1.10536 11.9804 1.29289 11.7929L0.941031 11.441M1 12.5L0.941031 11.441M0.941031 11.441L1.29289 11.7929C1.48043 11.6054 1.73478 11.5 2 11.5H10.75C10.9489 11.5 11.1397 11.421 11.2803 11.2803L0.941031 11.441Z"
            fill="#1B495A"
            stroke="#1B495A"
          />
        </svg>
      </PreviewContainer>
      <div className="d-flex flex-column mt-2">
        <a className="filename link-unstyled pointer">Add file</a>
      </div>
    </label>
    <input
      id={id}
      ref={inputRef}
      className="form-file-input file optional"
      name={name}
      type="file"
      accept={accept}
      style={{
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      }}
      onChange={(e) => propOnChange(inputRef.current.files?.item(0))}
    />
  </div>
);
