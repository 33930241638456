import React from 'react';

export const PrimaryHeartOutline = ({ color = '#947CE9', ...props }) => (
  <svg
    width="137"
    height="91"
    viewBox="0 0 137 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55.7467 88.6525C55.7467 88.6525 3.20001 64.1431 3.20001 30.8541C3.20001 9.85663 17.2702 2.63644 28.7046 2.63644C43.6534 2.63644 51.7075 11.8459 56.9427 16.4383C56.9427 16.4383 70.0122 2.63645 84.6682 2.85748C105.584 3.17674 111.979 14.0684 111.735 30.6331C111.54 44.0666 104.108 57.058 92.7101 57.058C79.6039 57.058 75.5402 41.1564 86.3644 27.8825C96.6517 15.2595 112.955 13.9702 136.8 14.228"
      stroke={color}
      strokeWidth="5"
      strokeLinejoin="round"
    />
  </svg>
);

export default PrimaryHeartOutline;
