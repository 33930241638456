import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { Button, Modal, Row, Col } from "react-bootstrap";
import axios from 'axios';
import MembersPortalContext from "../../context";
import { useMediaQuery } from 'react-responsive';

const DeleteMember = ({householdMember, handleClose, show}) => {
  const { t } = useContext(MembersPortalContext);

  const onDeleteMember = () => {
    axios.delete(`/members/household_members/${householdMember.id}`)
      .then(response => window.location.reload())
      .catch((e) => {
        handleClose();
        alert("Something went wrong");
      });
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Modal show={show} onHide={handleClose} size={isMobile ? "md" : "lg"} centered className='members'>
      <Modal.Body>
        <h2>{t('login.delete_member', {full_name: `${householdMember.user?.first_name} ${householdMember.user?.last_name}`})}</h2>
        <p className='text-muted'>{t('login.delete_member_subtitle')}</p>
        {isMobile
          ? <>
            <Button variant="danger" className='mb-2' block onClick={onDeleteMember}>
              {t('login.delete_account')}
            </Button>
            <Button variant="light" block onClick={handleClose}>
              {t('self_administration.cancel')}
            </Button>
          </>
          : <Row className='mt-5'>
            <Col>
              <Button variant="danger" className='mb-2' block onClick={onDeleteMember}>
                {t('login.delete_account')}
              </Button>
            </Col>
            <Col>
              <Button variant="light" block onClick={handleClose}>
                {t('self_administration.cancel')}
              </Button>
            </Col>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
};

export default DeleteMember;

