import Header from '../Registration/components/Header';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { KitContainer, KitBackground, KitCard }  from './components/styled';
import ScanStep from './components/landing_page_steps/ScanStep';
import FindLocationStep from './components/landing_page_steps/FindLocationStep';
import CredentialsStep from './components/landing_page_steps/CredentialsStep';
import ShowAppointmentsStep from './components/landing_page_steps/ShowAppointmentsStep';
import VerifyContactInformationStep from './components/landing_page_steps/VerifyContactInformationStep';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { KIT_TYPE_REGEX } from './Sti/StiFlow';

const STEPS = {
  scanStep: {
    component: ScanStep,
    navigationActions: {
      verifiedBarcode: 'verifyContactInformationStep',
      newBarcode: 'credentialsStep',
      noBarcode: 'credentialsStep',
    }
  },
  findLocationStep: {
    component: FindLocationStep,
    navigationActions: {
      back: 'credentialsStep',
    },
  },
  credentialsStep: {
    component: CredentialsStep,
    navigationActions: {
      back: 'scanStep',
      noUserExists: 'findLocationStep',
      verifyContact: 'verifyContactInformationStep',
      showAppointments: 'showAppointmentsStep',
    },
  },
  verifyContactInformationStep: {
    component: VerifyContactInformationStep,
    navigationActions: {
      back: 'credentialsStep',
      noAppointmentsFound: 'findLocationStep',
      appointmentsFound: 'showAppointmentsStep',
    },
  },
  showAppointmentsStep: {
    component: ShowAppointmentsStep,
    navigationActions: {
      back: 'credentialsStep',
      newPatientProvider: 'findLocationStep',
    }
  },
};

const validateBarcode = (barcode, accessCode) => (
  axios.post('/kits/validate_barcode', { barcode, access_code: accessCode }).
    then(response => response).catch(err => err.response)
);

const getAppointmentRecovery = (phoneNumber, email) => (
  axios.post("/finder.json",
    { appointment_recovery: { phone_number: phoneNumber, email: email },
      skip_otp: true,
      filters: { sti_kind: true }, 
    },
  ).then(response => response).catch(err => err.response)
);

const retrieveAppointments = (id) => (
  axios.post("/registrations/contact_verifications/retrieve_appointments.json",
      { id: id, filters: { sti_kind: true } }
    ).then(response => response).catch(err => err.response)
);

const createKit = (barcode, accessCode) => (
  axios.post(
    "/kits",
    { input_barcode: barcode, access_code: accessCode }
  ).then(response => response).catch(err => err.response)
)

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateForm':
      return { ...state, formState: { ...state.formState, ...action.values } };
    case 'setLoading':
      return { ...state, loading: action.value };
    case 'setError':
      return {...state, error: action.value };
    default:
      const currentStepActions =
        STEPS[state.currentStep]?.navigationActions || [];

      if (Object.keys(currentStepActions).includes(action.type)) {
        return { ...state, currentStep: currentStepActions[action.type] };
      } else {
        throw new Error(`Unknown action: ${action.type}`);
      }
  }
};

export const useKitFlow = (
  appointment,
  user,
  test_strip,
  kitTypes,
) => {
  const { t } = useTranslation();
  const initialState = {
    formState: {
      appointment,
      ...user,
      test_strip,
    },
    currentStep: 'scanStep',
    isMobile: useMediaQuery({ query: '(max-width: 768px)' }),
    t,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const validateKit = useCallback(async (barcode) => {
    if (!barcode.match(KIT_TYPE_REGEX)[1]) {
      dispatch({ type: 'setError', value: "unknown_kit_type" });
      return
    }
    dispatch({ type: 'setLoading', value: true });
    const response = await validateBarcode(barcode, state.formState.appointment?.access_code);
    if (response.status !== 200) {
      dispatch({type: 'updateForm', values: { barcode } })
      dispatch({type: 'newBarcode'})
    } else {
      if (response.data.verified) {
        window.location.href = response.data.test_strip.administered
          ? response.data.appointment.confirmation_link
          : `/kits?barcode=${barcode}`  
      } else {
        dispatch({type: 'updateForm', values: { ...response.data, barcode }})
        dispatch({type: 'verifiedBarcode'}) 
      }
    }
    dispatch({ type: 'setLoading', value: false });
  }, [state.formState.appointment?.access_code]);

  const checkCredentials = useCallback(async () => {
    dispatch({ type: 'setLoading', value: true });
    const { status, data } = await getAppointmentRecovery(state.formState.phone_number, state.formState.email);
    if (status !== 200) {
      dispatch({type: 'noUserExists'})
    } else {
      dispatch({type: 'updateForm', values: {appointmentRecovery: data.appointment_recovery, appointments: data.appointments }})
      if (data.appointments) {
        dispatch({type: 'showAppointments'})
      } else {
        dispatch({type: 'verifyContact'}) 
      }
    }
    dispatch({ type: 'setLoading', value: false });
  }, [state.formState.phone_number, state.formState.email]);

  const verifyContactInformation = useCallback(async (id) => {
    if (state.formState.test_strip.administered) {
      window.location.href = state.formState.appointment.confirmation_link
    } else if (state.formState.appointment.access_code) {
      await new Promise( res => setTimeout(res, 1000) );
      window.location.href = `/kits?barcode=${state.formState.barcode}`  
    } else if (!!state.formState.appointmentRecovery) {
      const response = await retrieveAppointments(id)
      if (response.status !== 200) {
        dispatch({type: 'noAppointmentsFound'})
      } else {
        dispatch({type: 'updateForm', values: {appointments: response.data}})
        dispatch({type: 'appointmentsFound'})
      }
    }
  }, [
    state.formState.test_strip.administered,
    state.formState.appointment.access_code,
    state.formState.appointmentRecovery
  ])

  const createTestKit = useCallback(async(appointment) => {
    if (!state.formState.barcode) {
      window.location.href = appointment.confirmation_url
      return
    }
    const response = await createKit(state.formState.barcode, appointment.access_code)
    if (response.status !== 200) {
      dispatch({ type: 'setError', value: "test_kit_not_created" });
    } else {
      window.location.href = `/kits?barcode=${state.formState.barcode}` 
    }
  }, [state.formState.barcode]);

  const noBarcode = useCallback(() => {
    if (state.formState.appointment.confirmation_link) {
      window.location.href = state.formState.appointment.confirmation_link
    } else {
      dispatch({type: 'noBarcode'}) 
    }
  }, [state.formState.appointment.confirmation_link])

  const hasContact = state.formState.phone_number || state.formState.email;

  return {
    state,
    dispatch,
    validateKit,
    checkCredentials,
    hasContact,
    verifyContactInformation,
    createTestKit,
    noBarcode,
  };
};



export default function LandingPage({user={date_of_birth: {}}, appointment={}, test_strip={}, kit_types}) {
  const kitFlowProps = useKitFlow(appointment, user, test_strip, kit_types);
  
  useEffect(() => window.scrollTo(0, 0), [STEPS[kitFlowProps.state.currentStep]]);

  const CurrentComponent = STEPS[kitFlowProps.state.currentStep].component;
  return (
    <KitContainer>
      <Header testGroup={{locales: ["en", "es"]}} borderBottom={false} />
      <KitBackground>
        <KitCard>
          <CurrentComponent {...kitFlowProps} />
        </KitCard>
      </KitBackground>
    </KitContainer>
  );
}
