import React, { useState, useEffect } from 'react';
import { Container, Alert, Col } from 'react-bootstrap';
import EnterInformation from './EnterInformation';
import EnterCode from './EnterCode';
import ShowAppointments from './ShowAppointments';
import PleaseContactSupport from './PleaseContactSupport';
import Header from '../Registration/components/Header';
import { languageObject } from '../common/utils';
import SwitchUsers from '../MembersPortal/components/partials/SwitchUsers';
import { useTranslation } from 'react-i18next';
import Loader from "react-loader-spinner";
import { useMediaQuery } from 'react-responsive';
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AppointmentRecovery = () => {
  const { t, i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token') || params.get('access_token');
  const appointmentRecoveryId = params.get('appointment_recovery_id');
  const [currentStep, setCurrentStep] = useState(appointmentRecoveryId ? 1 : 0);
  const [appointmentRecovery, setAppointmentRecovery] = useState(appointmentRecoveryId ? {id: appointmentRecoveryId} : {});

  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [tryAgain, setTryAgain] = useState(false);
  const [showAlert, setShowAlert] = useState(null);

  useEffect(() => {
    const setHousehold = async () => {
      let fetchHousehold = token
        ? fetch(`/api_v2/members/household_members`, { headers: { "X-Authorization": token } })
        : fetch(`/members/household_members.json`)

      await fetchHousehold
        .then(response => response.json())
        .then((data) => setLoggedIn(data.authorization))
        .catch((error) => setLoggedIn(false));
      setLoading(false);
    };
    if (tryAgain && currentStep === 0) {
      setShowAlert(true);
    };
    setHousehold();
  }, [currentStep, tryAgain]);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return loading ? (
    <div className="mt-10 text-center">
      <Loader
        type="Hearts"
        color="#17a2b8"
        height={200}
        width={200}
        timeout={3000} //3 secs
      />
    </div>) : (
    <>
      {(tryAgain && currentStep === 0 && showAlert) ?
        <div className="alert alert-info alert-dismissible fade show container mt-3" role="info" style={{ width: "99vw", color: "#E9F1FB", boxShadow: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)", borderRadius: "12px" }}>
          <FontAwesomeIcon icon={faCircleInfo} style={{ color: '#1125F6', marginRight: "9px" }} size="1x" />
          <div className="inter medium" style={{ color: "#08173A" }}>Try again to find your member</div>
          <button onClick={() => setShowAlert(false)} type="button" className="btn-close p-3" data-dismiss="alert" aria-label="Close" style={{ color: "#08173A" }}>
          </button>
        </div>
        :
        <Header
          testGroup={{ locales: Object.keys(languageObject) }}
          loggedInComponent={(loggedIn && !isMobile) && <SwitchUsers />}
        />
      }
      <div className="members mb-5">
        <Container id="appointment-recovery">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              {error && <PleaseContactSupport onClick={(e) => {
                e.preventDefault();
                setError(false);
                setCurrentStep(0);
              }} fromMobile={loggedIn} />}
              {!error && (
                <>
                  {currentStep === 0 && (
                    <EnterInformation token={token} onComplete={async (newAppointmentRecovery) => {
                      setAppointmentRecovery(newAppointmentRecovery);
                      setCurrentStep(1);
                    }} onError={() => {
                      setError(true);
                    }} />
                  )}
                  {currentStep === 1 && (
                    <EnterCode
                      loggedIn={loggedIn}
                      appointmentRecovery={appointmentRecovery}
                      titleKey={appointmentRecoveryId ? "verify_account_information" : "code"}
                      onResend={(newAppointmentRecovery) => {
                        setAppointmentRecovery(newAppointmentRecovery);
                      }}
                      onComplete={async (newAppointments) => {
                      setAppointments(newAppointments);
                      setCurrentStep(2);
                    }} onError={() => {
                      setError(true);
                    }} />
                  )}
                  {currentStep === 2 && (
                    <ShowAppointments
                      appointments={appointments}
                      loggedIn={loggedIn}
                      onComplete={(householdMemberId) => {
                        location.href = `/members/sign_in?current_user=${householdMemberId}`
                      }}
                      setTryAgain={setTryAgain}
                      setCurrentStep={setCurrentStep}
                      informationEntered={appointmentRecovery && (appointmentRecovery.email != null ? appointmentRecovery.email : appointmentRecovery.phone_number)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
};

export default AppointmentRecovery;
