import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import styled, { css } from 'styled-components';
import Select from '../../common/components/Select';
import { Row, Col } from 'react-bootstrap';
import HelpText from '../../common/components/HelpText';

const SwitchContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
`;

const SwitchOption = styled.div`
  padding: 5px 9px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;

  ${({ selected }) =>
    selected &&
    css`
      color: white;
      background: #2862FA;
      font-weight: 600;
    `}
`;

const CustomSwitch = ({
  options,
  setValue,
  showAllSlots,
  value: selectedValue,
}) => (
  <div className="mt-2 mb-3">
    <SwitchContainer>
      {options.map(({ value, label }, idx) => (
        <SwitchOption
          key={idx}
          onClick={() => setValue(value)}
          selected={value === selectedValue}
        >
          {label}
        </SwitchOption>
      ))}
    </SwitchContainer>
    <HelpText
      inactiveClass="text-primary"
      icon={faCircleExclamation}
      text={
        `Available slots are based on open slotting in the future, with capacity in mind.
        ${showAllSlots
          ? "\nAll slots are regardless of availability capacity, which can cause overbooking."
          : ""
        }`
      }
      style={{ marginLeft: 8 }}
    />
  </div>
);

const LocationTimeSelect = ({
  slotType,
  appointment_slot_availabilities_url,
  appointment_slot_groups,
  appointment_slots_url,
}) => {
  const [loading, setLoading] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [allSlotsFiltered, setAllSlotsFiltered] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new DateObject());
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);

  const fetchAvailableSlots = useCallback(() => {
    setLoading(true);

    axios.post(appointment_slot_availabilities_url).then(({ data }) => {
      setAvailableSlots(data.appointment_slot_groups);
      setLoading(false);
    });
  });

  const fetchAllSlotsFiltered = useCallback(() => {
    setLoading(true);

    axios
      .get(appointment_slots_url, {
        params: {
          start_on: selectedDate.format('YYYY-MM-DD'),
          end_on: selectedDate.format('YYYY-MM-DD'),
          appointment_slot_group_id: selectedLocation,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        setAllSlotsFiltered(data.appointment_slots);
        setLoading(false);
      });
  }, [selectedLocation, selectedDate]);

  const locationOptions = useMemo(
    () =>
      slotType === 'available_slots'
        ? availableSlots.map(({ id: value, title: label }) => ({
            value,
            label,
          }))
        : slotType === 'all_slots'
        ? appointment_slot_groups.map(([label, value]) => ({
            value,
            label,
          }))
        : [],
    [slotType, availableSlots, appointment_slot_groups],
  );

  const appointmentSlotsOptions = useMemo(
    () =>
      (slotType === 'available_slots'
        ? availableSlots.find((loc) => loc.id === selectedLocation)
            ?.appointment_slots || []
        : slotType === 'all_slots'
        ? allSlotsFiltered
        : []
      ).map(({ id: value, localized_starts_at_with_location: label }) => ({
        value,
        label,
      })),
    [slotType, availableSlots, allSlotsFiltered, selectedLocation],
  );

  useEffect(() => {
    fetchAvailableSlots();
  }, [slotType]);

  useEffect(() => {
    if (slotType === 'all_slots' && !!selectedLocation) fetchAllSlotsFiltered();
  }, [slotType, selectedLocation, selectedDate]);

  useEffect(() => {
    if (selectedAppointmentSlot) $('#edit_appointment').data('dirty', true);
  }, [selectedAppointmentSlot]);

  return (
    <div>
      <Row>
        <Col>
          <label className="mt-3">Location</label>
          <Select
            value={selectedLocation}
            options={locationOptions}
            onChange={(locId) => setSelectedLocation(locId)}
            placeholder={
              slotType === 'available_slots' && loading ? (
                <FontAwesomeIcon icon="spinner" className="fa-spin" />
              ) : null
            }
          />
        </Col>
        {slotType === 'all_slots' && (
          <Col lg={3} md={4}>
            <Row>
              <label className="mt-3">Date</label>
            </Row>
            <Row>
              <DatePicker
                value={selectedDate}
                onChange={setSelectedDate}
                format="YYYY-MM-DD"
                inputClass="form-control mw-25"
                containerStyle={{ flex: '1 0 3' }}
              />
            </Row>
          </Col>
        )}
      </Row>
      <label className="mt-3">Appointment time</label>
      <Select
        value={selectedAppointmentSlot}
        options={appointmentSlotsOptions}
        onChange={(slotId) => setSelectedAppointmentSlot(slotId)}
        isDisabled={loading || !selectedLocation}
        placeholder={
          slotType === 'all_slots' && loading ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : null
        }
      />
      {selectedLocation && appointmentSlotsOptions.length == 0 && (
        <div
          className="text-warning mx-1 my-2"
          id="collapse-force-reschedule-warning"
        >
          <FontAwesomeIcon icon={faTriangleExclamation} className='me-1' />
          {' '}No appointment slots available
        </div>
      )}
      {selectedLocation && (
        <input
          id="selected_location_id"
          value={selectedLocation}
          type="hidden"
        />
      )}
      {selectedAppointmentSlot && (
        <input
          id="appointment_appointment_slot_id"
          name="appointment[appointment_slot_id]"
          value={selectedAppointmentSlot}
          type="hidden"
        />
      )}
      {slotType === "available_slots" && (
        <input
          name="verify_appointment_slot_capacity"
          value="true"
          type="hidden"
        />
      )}
    </div>
  );
};

const Reschedule = ({
  show_all_slots: showAllSlots,
  ...props
}) => {
  const [slotType, setSlotType] = useState('available_slots');

  const options = [{ value: 'available_slots', label: 'Available slots' }];
  if (showAllSlots) options.push({ value: 'all_slots', label: 'All slots' });

  return (
    <div className="my-3 reschedule-section">
      <div className="reschedule-separator" />
      <div className="my-1 w-100">
        <span className="fw-bold">Reschedule appointment?</span>
        <CustomSwitch
          value={slotType}
          setValue={setSlotType}
          options={options}
          showAllSlots={showAllSlots}
        />
        <span className="fw-bold">
          Please select the new appointment location and time below.
        </span>
        <LocationTimeSelect slotType={slotType} {...props} />
      </div>
    </div>
  );
};

export default Reschedule;
