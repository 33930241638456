import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Table, Card } from 'react-bootstrap';
import ModalHeader from '../../common/components/ModalHeader';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';

const MultipleMatchResolver = ({
  testGroupId,
  user,
  source,
  showModal,
  setShowModal,
  onMatchSelected = () => {},
  setLatestReference,
}) => {
  const [matches, setMatches] = useState([]);
  const [iisProvider, setIisProvider] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);

  const multipleMatchResolutionsPath = `/test_groups/${testGroupId}/participants/${user.id}/vaccine_history_updates/multiple_match_resolutions`;

  const fetchMatches = () => {
    axios.get(`${multipleMatchResolutionsPath}/new`).then((response) => {
      setMatches(response.data.candidates);
      setIisProvider(response.data.iis_provider);
    });
  };

  useEffect(()=> {
    fetchMatches();
  }, []);

  const hideModal = () => setShowModal(false);

  const submit = (iisId) => {
    const payload = {
      iis: {
        id: iisId,
        provider: iisProvider,
      },
    };

    axios
      .post(multipleMatchResolutionsPath, payload)
      .then(() => {
        setLatestReference((prevState)=>({
          ...prevState,
          status: 'ok',
        }));
        hideModal();
        onMatchSelected();
      })
      .catch(() => {
        toastr.error('Something went wrong');
      });
  };

  return (
    <Modal
      className="spaced-modal"
      show={showModal}
      onHide={hideModal}
      size="xl"
    >
      <ModalHeader closeButton onHide={hideModal}>
        <Modal.Title className="h4-20-semi-bold poppins semibold mb-3">
          Select patient
        </Modal.Title>
        <p>
          Select patient from the {source.toUpperCase()} directory to verify
          their identity
        </p>
        <div className="body-large-16-regular inter semibold mb-3">
          Patient name
        </div>
        <FloatingLabelInput
          className="mb-3"
          dataTestHook="patient_name"
          prependIcon="search"
          disabled
          value={user.full_name}
        />
      </ModalHeader>
      <Modal.Body>
        <Card className="p-3">
          <section className="fabrx-tables-light table-responsive">
            <Table responsive className="table-lg">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Date of birth</th>
                  <th>Gender</th>
                  <th>Address</th>
                  <th>Phone number</th>
                  <th>Source</th>
                </tr>
              </thead>
              <tbody>
                {matches.map((match) => (
                  <tr key={match.iis_id}>
                    <td>
                      <input
                        checked={selectedMatch === match.iis_id}
                        onChange={() => setSelectedMatch(match.iis_id)}
                        type="radio"
                        className="form-check-input"
                      />
                    </td>
                    <td>
                      {match.name.first_name} {match.name.other_names}{' '}
                      {match.name.last_name}
                    </td>
                    <td>{match.date_of_birth}</td>
                    <td>{match.gender}</td>
                    <td>
                      {match.address && (
                        <>
                          {match.address.street}
                          <br />
                          {match.address.city}, {match.address.state}
                          <br />
                          {match.address.zip_code}
                        </>
                      )}
                    </td>
                    <td>
                    {match?.phone_number?.area_code && match?.phone_number?.local_number && (
                        <>
                          {`(${match.phone_number.area_code}) ${match.phone_number.local_number}`}
                        </>
                      )}
                    </td>
                    <td>{iisProvider.toUpperCase()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </section>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!selectedMatch}
          onClick={() => submit(selectedMatch)}
        >
          Confirm patient
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultipleMatchResolver;
