import React, { useState } from 'react';
import HighlightedDatePicker from '../../common/components/HighlightedDatePicker';
import AsyncSelect from '../../common/components/AsyncSelect';

export const FilterBar = ({
  location_id,
  location_options_url,
  form_url,
  start_day,
  month_capacities_url,
  edit_location_url
}) => {
  const [date, setDate] = useState(start_day);
  const onDateChange = (dateObj) => setDate(dateObj.format('YYYY-MM-DD'));

  const redirectToCapacityPlanningUrl = (locationId) => {
    window.location.href = `/capacity_plannings/${locationId}?start_day=${start_day}`;
  };

  return (
    <form
      noValidate="novalidate"
      className="simple_form dates"
      action={form_url}
      acceptCharset="UTF-8"
      method="get"
    >
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 mb-3">
          <AsyncSelect
            valueKey="id"
            labelKey="name"
            selected={location_id}
            optionsUrl={location_options_url}
            onChange={redirectToCapacityPlanningUrl}
          />
        </div>
        <div className="col-12 col-sm-4 col-lg-auto mb-3">
          <input type="hidden" name="start_day" id="start_day" value={date} />
          <HighlightedDatePicker
            remoteUrl={month_capacities_url}
            format="YYYY-MM-DD"
            inputClass="form-control"
            placeholder="Day"
            value={date}
            onChange={onDateChange}
          />
        </div>
        <div className="col-12 col-sm-auto mb-3">
          <input
            type="submit"
            name="commit"
            value="Search"
            className="btn btn-primary"
            data-disable-with="Search"
          />
        </div>
        <div className="col-12 col-sm-auto mb-3">
          <a href={edit_location_url} className="btn btn-outline-primary">Edit location</a>
        </div>
      </div>
    </form>
  );
};
