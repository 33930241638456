import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const UploadBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ isDragOver }) =>
    isDragOver ? 'var(--bs-light)' : '#fafafb'};
  outline: 0.1em dashed
    ${({ isDragOver }) => (isDragOver ? 'var(--bs-primary)' : '#c4c4c4')};
  border-radius: 6px;
  ${({ isDragOver }) => {
    if (isDragOver)
      return css`
        svg {
          color: var(--bs-primary);
        }
      `;
  }}
`;

const PictureUploadBoxContainer = styled.label`
  background: #f8f8fc;
  border: 1px solid #eaeaf2;
  border-radius: 24px;
`;

export const PictureUploadBox = ({
  id = 'picture_upload',
  name = 'picture_upload',
  onChange = () => {},
  inputRef = React.createRef(),
  style = {},
}) => (
  <div style={{ height: 175, ...style }}>
    <PictureUploadBoxContainer
      className="d-flex w-100 h-100 mb-0 align-items-center justify-content-center"
      htmlFor={id}
    >
      <svg
        width="48"
        height="40"
        viewBox="0 0 48 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12C18.4772 12 14 16.4772 14 22C14 27.5228 18.4772 32 24 32C29.5228 32 34 27.5228 34 22C34 16.4772 29.5228 12 24 12ZM18 22C18 18.6863 20.6863 16 24 16C27.3137 16 30 18.6863 30 22C30 25.3137 27.3137 28 24 28C20.6863 28 18 25.3137 18 22Z"
          fill="#2862FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0C17.3313 0 16.7068 0.334202 16.3359 0.8906L12.9296 6H6C4.4087 6 2.88258 6.63214 1.75736 7.75736C0.632141 8.88258 0 10.4087 0 12V34C0 35.5913 0.632141 37.1174 1.75736 38.2426C2.88258 39.3679 4.4087 40 6 40H42C43.5913 40 45.1174 39.3679 46.2426 38.2426C47.3679 37.1174 48 35.5913 48 34V12C48 10.4087 47.3679 8.88258 46.2426 7.75736C45.1174 6.63214 43.5913 6 42 6H35.0704L31.6641 0.8906C31.2932 0.334202 30.6687 0 30 0H18ZM15.6641 9.1094L19.0704 4H28.9296L32.3359 9.1094C32.7068 9.6658 33.3313 10 34 10H42C42.5304 10 43.0391 10.2107 43.4142 10.5858C43.7893 10.9609 44 11.4696 44 12V34C44 34.5304 43.7893 35.0391 43.4142 35.4142C43.0391 35.7893 42.5304 36 42 36H6C5.46957 36 4.96086 35.7893 4.58579 35.4142C4.21071 35.0391 4 34.5304 4 34V12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10H14C14.6687 10 15.2932 9.6658 15.6641 9.1094Z"
          fill="#2862FA"
        />
      </svg>
      <input
        id={id}
        ref={inputRef}
        className="form-file-input file optional"
        name={name}
        type="file"
        accept="image/png,image/jpeg,application/pdf"
        style={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        onChange={(e) => onChange(inputRef.current.files?.item(0))}
      />
    </PictureUploadBoxContainer>
  </div>
);

const UploadBox = ({
  id = 'file_upload',
  name = 'file_upload',
  onChange: propOnChange = () => {},
  inputRef = React.createRef(),
  style = {},
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const onDragEvent = (e, isDragOver) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(isDragOver);
  };
  const onDragOver = (e) => onDragEvent(e, true);

  const onDragLeave = (e) => onDragEvent(e, false);

  const onDrop = (e) => {
    onDragEvent(e, false);
    propOnChange(e.dataTransfer.files?.item(0));
  };

  return (
    <UploadBoxContainer
      className="pt-3 pb-2 justify-content-center"
      style={{ gap: 12, ...style }}
      isDragOver={isDragOver}
      onDragOver={onDragOver}
      onDragEnter={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnd={onDragLeave}
      onDrop={onDrop}
    >
      <img
        src="/images/clipboard-plus.svg"
        alt="Upload file icon"
        style={{ width: 35 }}
      />
      <input
        id={id}
        ref={inputRef}
        className="form-file-input file optional"
        name={name}
        type="file"
        style={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        onChange={(e) => propOnChange(inputRef.current.files?.item(0))}
      />
      <label htmlFor={id}>
        <span
          className="text-center"
          style={{
            maxWidth: '16ch',
            display: 'inline-block',
          }}
        >
          Drag and drop files{' '}
          <strong className="link-primary" style={{ fontWeight: 'normal' }}>
            or browse
          </strong>
        </span>
      </label> 
    </UploadBoxContainer>
  );
};

export default UploadBox;
