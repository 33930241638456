import axios from 'axios';

export const uploadImmunizations = ({
  immunization_document_upload_path: uploadPath,
  files,
  immunization_records,
}) => {
  var formData = new FormData();
  files.forEach((file) => {
    formData.append('documents[]', file);
  });
  formData.append('metadata', JSON.stringify(immunization_records));

  return axios.post(uploadPath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
