import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FabrxCheckbox from "../../Registration/primary/FabrxCheckbox";
import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import OrderingFacilityFields from "./OrderingFacilityFields";
import Switch from "../../common/components/Switch";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";
import TooltipWrapper from "../../common/components/TooltipWrapper";

const RESUlT_SHARING_OPTIONS = [
  {
    key: "negative",
    label: "Negative",
    description: "No evidence of the targeted infection was detected in the test sample.",
  },
  {
    key: "positive",
    label: "Positive",
    description: "Evidence of the targeted infection was detected in the test sample.",
  },
  {
    key: "test_not_performed",
    label: "Test not performed",
    description: "The sample provided could not be processed and re-testing may be required.",
  },
  {
    key: "did_not_result",
    label: "Did not result",
    description: "The test did not result, potentially due to technical issues, insufficient sample quality, or procedural errors.",
  },
  {
    key: "canceled",
    label: "Canceled",
    description: "The testing process was intentionally halted before completion, potentially due to patient request, clinical decision, specimen quality, or administrative reasons.",
  },
  {
    key: "custom_result",
    label: "Custom result",
    description: "The result is custom to the specific lab test, or contains multiple results (such a multiplex point of care test).",
  },
];

const LabTestGroupTestConfigurationInputFields = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const selectedBarcodeType = state.testConfigurationOptions.barcode_type_options.find((option) => {
    return option.label == state.testGroupTestConfiguration.barcode_type
  });

  return (
    <div>
      <Row className="my-2">
        <Col xs={6} lg={3}>
          <h6>Barcode prepend string</h6>
          <FloatingLabelInput
            type="text"
            value={state.testGroupTestConfiguration.barcode_prepend_string}
            onChange={(e) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  barcode_prepend_string: e.target.value,
                }
              });
            }}
          />
        </Col>
        <Col xs={6} lg={4}>
          <h6>Barcode type</h6>
          <FloatingLabelSelect
            filledValue={!!state.testGroupTestConfiguration.barcode_type}
            onChange={(barcodeType) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  barcode_type: barcodeType.value
                }
              });
            }}
            options={state.testConfigurationOptions.barcode_type_options}
            value={selectedBarcodeType}
          />
        </Col>
      </Row>

      <div className="my-3">
        <h6>Medical screening survey:</h6>
        <Switch
          checked={!!state.testGroupTestConfiguration.use_medical_screening_survey_on_checkout}
          containerClassName="my-3"
          id="use_medical_screening_survey_on_checkout"
          label="Enable medical screening survey"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                use_medical_screening_survey_on_checkout: !state.testGroupTestConfiguration.use_medical_screening_survey_on_checkout
              }
            });
          }}
          subLabel="The medical screening survey includes questions that may be relevant to multiple services.
            Enabling this will replace service specific surveys on checkout."
        />
      </div>

      <div className="py-2"><hr/></div>
      <h5 className="my-4">Result sharing</h5>
      <p>When turned on, status notifications and updates will automatically be sent and published to the participant portal.</p>
      <div className="my-3">
        <Row>
          <Col xs={6} lg={5} className="inter medium">Result status</Col>
          <Col xs={3} lg={2} className="inter medium d-flex justify-content-center">
            Notify
            <TooltipWrapper
              placement="top"
              tooltipContent="Automatically notify participants when a test is updated with this status"
              >
              <FontAwesomeIcon
                className="ms-2 my-auto"
                color="blue"
                icon="fa-solid fa-circle-info"
                height={12}
                width={12}
              />
            </TooltipWrapper>
          </Col>
          <Col xs={3} lg={2} className="inter medium d-flex justify-content-center">
            Publish
            <TooltipWrapper
              placement="top"
              tooltipContent="Automatically publish to participant profile when a test is updated with this status"
              >
              <FontAwesomeIcon
                className="ms-2 my-auto"
                color="blue"
                icon="fa-solid fa-circle-info"
                height={12}
                width={12}
              />
            </TooltipWrapper>
          </Col>
        </Row>
        {RESUlT_SHARING_OPTIONS.map((option) => {
          return (
            <Row
              className="my-3"
              key={option.key}
            >
              <Col xs={6} lg={5}>
                <div className="inter medium">{option.label}</div>
                <div className="text-muted">{option.description}</div>
              </Col>
              <Col xs={3} lg={2} className="d-flex justify-content-center">
                <FabrxCheckbox
                  checked={state.testGroupTestConfiguration?.notification_settings[option.key]}
                  onChange={() => {
                    dispatch({
                      type: "setTestGroupTestConfiguration",
                      values: {
                        ...state.testGroupTestConfiguration,
                        notification_settings: {
                          ...state.testGroupTestConfiguration.notification_settings,
                          [option.key]: !state.testGroupTestConfiguration?.notification_settings[option.key]
                        }
                      }
                    });
                  }}
                />
              </Col>
              <Col xs={3} lg={2} className="d-flex justify-content-center">
                <FabrxCheckbox
                  checked={state.testGroupTestConfiguration.publish_results_settings[option.key]}
                  onChange={() => {
                    dispatch({
                      type: "setTestGroupTestConfiguration",
                      values: {
                        ...state.testGroupTestConfiguration,
                        publish_results_settings: {
                          ...state.testGroupTestConfiguration?.publish_results_settings,
                          [option.key]: !state.testGroupTestConfiguration?.publish_results_settings[option.key]
                        }
                      }
                    });
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </div>

      <OrderingFacilityFields />
    </div>
  );
};

export default LabTestGroupTestConfigurationInputFields;
