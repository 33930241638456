import React, { FC } from 'react';
import styled from 'styled-components';

import HelperText from './ErrorHelperText';
import Wrapped from '../../Wrapped';

const Container = styled.div`
  &.error {
    input {
      border-color: #cb2531;

      &::placeholder {
        color: #cb2531;
      }
    }
  }
`;

interface TextFieldProps extends React.HTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  required?: boolean;
  value?: any;
  type?: string;
  setValue?: (any) => void;
  topHelperText?: string;
  helperText?: string;
  error?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  labelClass?: string;
  startAdorment?: React.ReactNode;
}

const InlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .input-group-prepend {
    position: absolute;
    left: 1rem;
  }

  input {
    padding-left: 2.4rem;
  }
`;

const TextField: FC<TextFieldProps> = ({
  id,
  name,
  label,
  placeholder,
  type = 'text',
  required = false,
  value = '',
  setValue,
  topHelperText,
  helperText,
  error = false,
  inputRef = React.createRef(),
  labelClass = '',
  startAdorment,
  ...props
}) => (
  <Container className={`mb-4 string required${error ? ' error' : ''}`}>
    {label && (
      <label
        className={`form-label string required ${labelClass}`}
        htmlFor={id}
      >
        {label} {required && <abbr>*</abbr>}
      </label>
    )}
    {topHelperText && <p className="mb-2 text-muted">{topHelperText}</p>}
    <Wrapped WrapperComponent={InlineWrapper} wrapIf={!!startAdorment}>
      {startAdorment && (
        <div className="input-group-prepend">{startAdorment}</div>
      )}
      <input
        ref={inputRef}
        id={id}
        name={name}
        className="form-control string required"
        type={type}
        value={value}
        onChange={setValue}
        placeholder={placeholder}
        data-test-hook={name}
        {...props}
      />
    </Wrapped>
    {helperText && <HelperText isError={error} text={helperText} />}
  </Container>
);

export default TextField;
