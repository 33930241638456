import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DaySlotsTable from './TableComponents';
import DaySummary from './DaySummary';
import Preferences from './Preferences';
import { TestLocationFilters } from './Filters';
import { mergeAndSortSlots } from '../../common/utils/slotting';

const EndOfDaySlots = ({
  day_summary,
  day_slots,
  group_only_slots,
  end_of_day_slots,
  create_slot_path,
  doseManagementData: { additionalCapacity, additionalAccessKeyCapacity },
  setDoseManagementData,
}) => {
  const [daySlots, setDaySlots] = useState(
    mergeAndSortSlots(day_slots, group_only_slots),
  );
  const [endOfDaySlots, setEndOfDaySlots] = useState(end_of_day_slots);

  useEffect(() => {
    setDaySlots(mergeAndSortSlots(day_slots, group_only_slots));
    setEndOfDaySlots(end_of_day_slots);
  }, [day_slots, group_only_slots, end_of_day_slots]);

  const createSlot = (slotParams) => {
    const values = {
      shared_capacity: {
        ...slotParams,
        staged: true,
      },
    };

    axios.post(create_slot_path, values).then((response) => {
      const { data } = response;
      if (data.success) {
        const newAppointmentSlot = data['shared_capacity'];
        const newDaySlots = [...daySlots, newAppointmentSlot];
        setDaySlots(newDaySlots);
        setEndOfDaySlots([endOfDaySlots[1]]);
        const { capacity, access_key_capacity } = slotParams;
        setDoseManagementData({
          additionalCapacity: additionalCapacity + capacity,
          additionalAccessKeyCapacity:
            additionalAccessKeyCapacity + access_key_capacity,
        });
      }
    });
  };

  return (
    <DaySlotsTable
      day_summary={day_summary}
      daySlots={daySlots}
      endOfDaySlots={endOfDaySlots}
      createSlot={createSlot}
      setDaySlots={setDaySlots}
    />
  );
};

const DoseManagement = ({
  day_summary,
  day_slots,
  group_only_slots,
  filter_props,
  end_of_day_slots,
  create_slot_path,
  update_day_data_path,
  month_capacities_path,
  preferences,
  setPreferences,
}) => {
  const [secondDoseModalOpen, setSecondDoseModalOpen] = useState(false);
  const [dmData, setDMData] = useState(day_summary.day_data);

  const updateDoseManagementData = (values) => {
    axios.put(update_day_data_path, values).then((response) => {
      const { data } = response;
      if (data.success) setDMData({ ...dmData, ...values });
    });
  };

  return (
    <React.Fragment>
      <div className="mt-3 mb-2">
        <div className="d-flex flex-column flex-md-row">
          <TestLocationFilters
            {...filter_props}
            month_capacities_path={month_capacities_path}
          />
          <Preferences
            doseManagementData={dmData}
            setDoseManagementData={updateDoseManagementData}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        </div>
      </div>
      <hr className="my-0" />
      <DaySummary
        {...day_summary}
        doseManagementData={dmData}
        setDoseManagementData={updateDoseManagementData}
        preferences={preferences}
      />
      <EndOfDaySlots
        day_summary={day_summary}
        day_slots={day_slots}
        group_only_slots={group_only_slots}
        end_of_day_slots={end_of_day_slots}
        create_slot_path={create_slot_path}
        doseManagementData={dmData}
        setDoseManagementData={updateDoseManagementData}
      />
      <hr />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary mb-2 float-end"
          type="button"
          onClick={() => setSecondDoseModalOpen(true)}
        >
          Follow up availability
        </button>
      </div>
    </React.Fragment>
  );
};

export const DoseManagementRemote = ({
  dose_management_path,
  update_preferences_path,
  test_location_id,
  preferences: preferencesProp,
  ...props
}) => {
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(true);
  const [doseManagementProps, setDoseManagementProps] = useState({});
  const [preferences, setPreferences] = useState(
    JSON.parse(preferencesProp).preferences,
  );

  useEffect(() => {
    if (!loading) setFirstRender(false);
  }, [loading]);

  const setNewPreferences = (prefs) => {
    axios
      .put(update_preferences_path, { test_location_id, ...prefs })
      .then((response) => {
        const { data } = response;
        if (data.success) setPreferences({ ...preferences, ...prefs });
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${dose_management_path}&no_show_interval=${preferences.no_show_interval}`,
      )
      .then((response) => {
        const { data } = response;
        setDoseManagementProps(data);
        setLoading(false);
      });
  }, [preferences]);

  return (
    <div className="position-relative" style={{ minHeight: '10vh' }}>
      {loading && (
        <div
          className="d-flex m-auto position-absolute"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            backgroundColor: '#FFFFFFB3',
          }}
        >
          <div className="m-auto">
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Loading...
          </div>
        </div>
      )}
      {!firstRender && (
        <DoseManagement
          {...doseManagementProps}
          preferences={preferences}
          setPreferences={setNewPreferences}
          {...props}
        />
      )}
    </div>
  );
};

export default DoseManagement;
