import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

const WaitlistButton = ({onClick, isWaitlisting}) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      {isWaitlisting && <Spinner animation="border" variant="dark" />}
      <input 
        type="submit"
        onClick={onClick}
        className={isWaitlisting ? "d-none" : "pure-button"}
        value= {t('registration.add_waitlist')}
      />
    </div>
  );
};

export default WaitlistButton;
