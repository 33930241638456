import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 0.25rem !important;
  margin-left: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &.error {
    color: #cb2531;
  }
`;

const HelperText = ({ text, isError }) => (
  <Container className={isError ? 'error' : ''}>
    {isError && <FontAwesomeIcon icon="fa-regular fa-triangle-exclamation" />}
    <span>{text}</span>
  </Container>
);

export default HelperText;
