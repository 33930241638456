import React from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import CancelAppointmentModal from './CancelAppointmentModal';
import { useState } from 'react';

const CancelAppointment = ({ appointment, className="text-danger", text, testGroup, modalOpen }) => {
  const { t } = useTranslation();
  const [showCancellationModal, setShowCancellationModal] = useState(modalOpen == "cancel" ? true : false);

  return (
    <div>
    {showCancellationModal && (
      <CancelAppointmentModal
        closeModal={() => setShowCancellationModal(false)}
        appointment={appointment}
        testGroup={testGroup}
      />
    )}
    {!appointment.frozen_from_canceling && (
      <div className="text-center my-2">
        <a
          className={className}
          data-test-hook="cancel"
          onClick={() => {
            setShowCancellationModal(true);
          }}
        >
          {text ?? t('instructions.cancel')}
        </a>
      </div>
    )}
  </div>
  );
};

export default CancelAppointment;
