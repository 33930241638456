import React from 'react';
import { CodeBlock } from '@atlaskit/code';

export const PrimaryCodeBlock = (props) => {
  const language = props.language || "jsx"
  return (
    <CodeBlock language={language} text={props.text} />
  )
}

export default PrimaryCodeBlock