import React, { useContext } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OtcContext from '../contexts/OtcContext';
import PrimaryHeartOutline from '../../common/components/Icons/PrimaryHeartOutline';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
export const ViralSurveillanceOptInContext = createContext({});

const Section = (props) => {
  return <div {...props} className="otc-surveillance-section" />;
};

const SectionItem = (props) => {
  return <div {...props} className="otc-surveillance-section-item" />;
};

const OTCButton = (props) => {
  return <Button {...props} className="otc-surveillance-button" block />;
};

const steps = [
  {
    label: 'viral_surveillance.opt_in.section_1_text_1',
  },
  {
    label: 'viral_surveillance.opt_in.section_1_text_2',
  },
  {
    label: 'viral_surveillance.opt_in.section_1_text_3',
  },
];

const cards = [
  {
    image: '/images/primary_clock_and_calendar.svg',
    alt: 'primary_clock_and_calendar',
    title: 'viral_surveillance.opt_in.section_2.card_1_title',
    text: 'viral_surveillance.opt_in.section_2.card_1_text',
  },
  {
    image: '/images/primary_light_bulb.svg',
    alt: 'primary_light_bulb',
    title: 'viral_surveillance.opt_in.section_2.card_2_title',
    text: 'viral_surveillance.opt_in.section_2.card_2_text',
  },
  {
    image: '/images/primary_success_shield.svg',
    alt: 'primary_success_shield',
    title: 'viral_surveillance.opt_in.section_2.card_3_title',
    text: 'viral_surveillance.opt_in.section_2.card_3_text',
  },
];

export default function () {
  const { handleSurveillance, testStripCreated, stepForward } =
    useContext(OtcContext);
  const showOptOut = true;
  return (
    <ViralSurveillanceOptInContext.Provider
      value={{
        handleSurveillance,
        testStripCreated,
        stepForward,
        showOptOut,
      }}
    >
      <ViralSurveillanceOptIn />
    </ViralSurveillanceOptInContext.Provider>
  );
}

export function ViralSurveillanceOptIn() {
  const { handleSurveillance, testStripCreated, stepForward, showOptOut } =
    useContext(ViralSurveillanceOptInContext);
  const { t } = useTranslation();
  const initialPage = (
    <>
      <Section style={{ marginBottom: '24px' }}>
        <SectionItem>
          <h4>{t('viral_surveillance.opt_in.title')}</h4>
          <p>{t('viral_surveillance.opt_in.subtitle')}</p>
          <div>
            <h5>
              <b>{t('viral_surveillance.opt_in.section_1_title')}</b>
            </h5>
            {steps.map(({ label }, index) => (
              <Row className="my-2 py-2" key={index}>
                <Col xs="auto" className="pe-0">
                  <span
                    className="fa-stack"
                    style={{ verticalAlign: 'middle' }}
                  >
                    <i>
                      <FontAwesomeIcon
                        icon={'circle'}
                        style={{ color: 'F39B7D' }}
                        className="fa-stack-2x"
                      />
                    </i>
                    <span className="fa-layers-text" style={{ color: 'white' }}>
                      {index + 1}
                    </span>
                  </span>
                </Col>
                <Col>
                  <div className="my-1">{t(label)}</div>
                </Col>
              </Row>
            ))}
            <OTCButton
              onClick={() => handleSurveillance(true)}
              data-test-hook="yestoparticipate"
            >
              {t('viral_surveillance.opt_in.sign_up')}
            </OTCButton>
          </div>
        </SectionItem>
      </Section>
      <Section style={{ backgroundColor: '#FFEFE8' }}>
        <SectionItem>
          <h5>
            <b>{t('viral_surveillance.opt_in.section_2_title')}</b>
          </h5>
        </SectionItem>
        {cards.map(({ image, alt, title, text }, index) => (
          <SectionItem>
            <Card
              className="p-4 my-4"
              style={{ backgroundColor: 'white' }}
              key={index}
            >
              <div className="d-flex justify-content-center pointer">
                <img alt={alt} src={image} className="my-3" />
              </div>
              <div>
                <strong>{t(title)}</strong>
                <div>{t(text)}</div>
              </div>
            </Card>
          </SectionItem>
        ))}{' '}
      </Section>
      <Section style={{ marginTop: '24px' }}>
        <SectionItem>
          <OTCButton onClick={() => handleSurveillance(true)}>
            {t('viral_surveillance.opt_in.sign_up')}
          </OTCButton>
          {showOptOut && (
            <OTCButton
              variant="outline-primary"
              onClick={() => handleSurveillance(false)}
              data-test-hook="notoparticipate"
            >
              {t('viral_surveillance.opt_in.no_to_participate')}
            </OTCButton>
          )}
        </SectionItem>
      </Section>
    </>
  );

  const testStripCreatedPage = (
    <Section style={{ marginTop: '24px' }}>
      <SectionItem>
        <div className="text-center">
          <PrimaryHeartOutline color="#08173A" className="my-3 py-3" />
          <h3>{t('viral_surveillance.opt_in.create_page_title')}</h3>
          <p>
            <b>{t('viral_surveillance.opt_in.create_page_text_1')}</b>
            <br />
            {t('viral_surveillance.opt_in.create_page_text_2')}
          </p>
          <OTCButton onClick={() => stepForward()} data-test-hook="gotoprofile">
            {t('viral_surveillance.opt_in.continue_to_result')}
          </OTCButton>
        </div>
      </SectionItem>
    </Section>
  );

  return <>{testStripCreated ? testStripCreatedPage : initialPage}</>;
}
