import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "react-bootstrap";

export const getBase64 = (file) => {
  if ((file.size / 1000000) > 20) return setError("file_too_large");
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const PhotoUploader = ({
  primaryTitle,
  secondaryTitle,
  imageData,
  setImageData,
  setShowWebCam = () => {},
  setNextButtonDisabled = () => {},
  setError,
  setFile,
  hidePrimaryButton,
}) => {

  const HiddenButton = () => {
    return (
      <label
        htmlFor="photo-uploader"
        id="file-upload-trigger"
        style={{width: "1px", height: "1px", cursor: "default", color: "#FBFBFB"}}
      >.</label>
    )
  }

  const DefaultButton = () => {
    return (
      <Button size="sm otc-button" variant="outline-primary">
        <label htmlFor="photo-uploader" style={{marginBottom: "0px"}}>
          <span color="#2862FA">{!imageData ? primaryTitle : secondaryTitle}</span>
        </label>
      </Button>
    )
  }

  return (
    <div>
      {hidePrimaryButton ? <HiddenButton /> : <DefaultButton />}

      <input
        type="file"
        id="photo-uploader"
        onChange={(e) => {
          setFile(e.currentTarget.files[0])
          getBase64(e.currentTarget.files[0])
            .then(data => {
              setImageData(data);
              setShowWebCam(true);
              setNextButtonDisabled(false);
            }
          );
        }}
        style={{ display: "none" }}
        accept="image/*, application/pdf"
      />
    </div>
  )
}

export default PhotoUploader;
