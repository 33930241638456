import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../common/components/ErrorMessage';
import DateOfBirthField from '../../../Insurance/fields/DateOfBirthField';
import InsuranceContext from '../../../Insurance/contexts/InsuranceContext';
import { Col, Row } from 'react-bootstrap';


const PolicyHolderFields = ({
    setFieldValue,
    values,
    errors,
    handleChange,
    names={
      policyHolderKey: "policyHolder",
      primaryMemberFirstName: "insurance_primary_member_first_name",
      primaryMemberLastName: "insurance_primary_member_last_name",
      secondary: false,
      dob: "date_of_birth",
    }
  }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction, IAM_POLICYHOLDER } = useContext(InsuranceContext);
  const {
    policyHolderKey,
    primaryMemberFirstName,
    primaryMemberLastName,
    secondary,
    dob
  } = names;
  const { user: {
    insurance_primary_member_first_name,
    insurance_primary_member_last_name
  }} = values;

  return (
    <div>
      {
        ![IAM_POLICYHOLDER, null, undefined, ""].includes(formAction[policyHolderKey]) ? (
          <div className='mb-1'>
            <Row className='mb-4'>
              <Col sm={12} md={6}>
                <label htmlFor="primary_first_name">{t('registration.insurance_policy_holder.policy_first_name')}</label>
                <input
                  name={`user[${primaryMemberFirstName}]`}
                  className={`form-control ${errors[primaryMemberFirstName] && 'is-invalid'}`}
                  value={values.user[primaryMemberFirstName] || ""}
                  onChange={handleChange}
                  aria-label={t('registration.insurance_policy_holder.policy_first_name')}
                  aria-invalid={!!errors[primaryMemberFirstName]}
                  id="primary_first_name"
                />
                {errors[primaryMemberFirstName] && <ErrorMessage message={t(errors[primaryMemberFirstName])} />}
              </Col>
              <Col sm={12} md={6}>
                <label htmlFor="primary_last_name">{t('registration.insurance_policy_holder.policy_last_name')}</label>
                <input
                  name={`user[${primaryMemberLastName}]`}
                  className={`form-control ${errors[primaryMemberLastName] && 'is-invalid'}`}
                  value={values.user[primaryMemberLastName] || ""}
                  onChange={handleChange}
                  aria-label={t('registration.insurance_policy_holder.policy_last_name')}
                  aria-invalid={!!errors[primaryMemberLastName]}
                  id="primary_last_name"
                />
                {errors[primaryMemberLastName] && <ErrorMessage message={t(errors[primaryMemberLastName])} />}
              </Col>
            </Row>
            {t('registration.insurance_policy_holder.policy_dob')}
            <DateOfBirthField
              setFieldValue={setFieldValue}
              values={values}
              secondary={secondary}
              errors={errors}
            />
            {errors[dob] && <ErrorMessage message={t(errors[dob])} />}
          </div>
        ) : null
      }
    </div>
  );
};

export default PolicyHolderFields;
