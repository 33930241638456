import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const ShowAppointments = ({ appointments, loggedIn = false, onComplete, setTryAgain, setCurrentStep, informationEntered }) => {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [foundAppointments, setFoundAppointments] = useState(appointments);
  const [name, setName] = useState('');
  const [height, setHeight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const ref = useRef(null);
  const ref1 = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const filterAppointments = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = appointments.filter((appointment) => {
        return (appointment.user.first_name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          appointment.user.last_name[0].toLowerCase().startsWith(keyword.toLowerCase()) ||
          (appointment.user.first_name.toLowerCase() + " " + appointment.user.last_name[0].toLowerCase()).includes(keyword.toLowerCase()))
      });
      setFoundAppointments(results);
    } else {
      setFoundAppointments(appointments);
    }
    setName(keyword);
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setBottomHeight(ref.current.clientHeight);
  }, [height, bottomHeight]);

  return (
    <div>
      <div>
        <div className="h2 header" data-test-hook="dashboard_title">{t("show_appointments.welcome")}</div>
        <div className="text-muted subtitle-text">{`${t("show_appointments.welcome_subtitle_1")} `}
          {informationEntered}{`. ${t("show_appointments.welcome_subtitle_2")} `}
          <a href="javascript:setTryAgain(true)" className="underline-link" onClick={() => { setTryAgain(true); setCurrentStep(0) }}>{t("show_appointments.welcome_subtitle_3")}</a>.
        </div>
        {appointments.length >= 12 && <input onChange={(e) => filterAppointments(e)} type="search" placeholder="Search" value={name} className="form-control" style={{ marginBottom: "16px" }}></input>}
        <div className="scroll-container">
          {foundAppointments.map((appointment) => {
            const confirmationButtonName = () => {
              if (selectedAppointment.test_group.has_self_resulting_test_configurations) {
                return "Report a result"
              } else if (selectedAppointment.test_group.has_self_administered_test_configurations) {
                return "Activate a kit"
              } else if (selectedAppointment.test_group.has_test_kit) {
                return "Activate a kit"
              } else {
                return false
              }
            };

            return (
              <div key={appointment.id} className="option-container">
                <Row onClick={() => { setDisabled(false); setSelectedAppointment(appointment); setActive(appointment.id) }}
                  className={`g-0 no-shadow-card ${active == appointment.id && 'checked'}`} ref={ref}>
                  <Col xs="1" style={{ width: "48px", paddingRight: "16px" }}>
                    {
                      (appointment.test_group.simplified_partner_logo_url || appointment.test_group.partner_logo_url) ?
                        <img
                          src={appointment.test_group.simplified_partner_logo_url || appointment.test_group.partner_logo_url}
                        />
                        :
                        <div
                          className='profile-circle mt-2'
                          style={{
                            backgroundColor: "#6544E0",
                          }}
                        >
                          <div>
                            {appointment.user.first_name[0].toUpperCase()}{appointment.user.last_name[0].toUpperCase()}
                          </div>
                        </div>
                    }
                  </Col>
                  <Col xs="9">
                    <div className="font-weight-bold">{appointment.user.first_name} {appointment.user.last_name[0]}</div>
                    <div className="text-muted card-caption">{appointment.test_group.name}</div>
                  </Col>
                  {(active == appointment.id && selectedAppointment) && !loggedIn &&
                    <Row>
                      <Col xs="1"></Col>
                      {confirmationButtonName() &&
                        <Col>
                          <a className="small" href={selectedAppointment.confirmation_url}><b>{confirmationButtonName()}</b></a>
                        </Col>
                      }
                      <Col>
                        <a className="small" href={selectedAppointment.results_url}><b>View results</b></a>
                      </Col>
                    </Row>
                  }
                </Row>
              </div>
            )
          })}
        </div>
      </div>
      <div ref={ref1} className="container bottom-menu-container" style={isMobile ? { left: "0", right: "0" } : {}}>
        <div className={`${!isMobile && "row ml-auto"}`}>
          <div className="col-12 col-lg-8">
            <Button onClick={() => {
              loggedIn != false ? selectedAppointment &&
                (fetch('/api_v2/members/household_members/add_to_household', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    "X-Authorization": loggedIn
                  },
                  body: JSON.stringify({ access_code: selectedAppointment.access_code, last_name: selectedAppointment.user.last_name, date_of_birth: selectedAppointment.user.date_of_birth }),
                }).then((response) => response.json()).then((data) => {
                  console.log(data)
                  onComplete(data.household_member_id);
                })
                  .catch((error) => {
                    console.error('Error:', error);
                    alert("Something went wrong");

                  }))
                : selectedAppointment && (window.location.href = selectedAppointment.confirmation_url)
            }} variant="primary"
              disabled={disabled} className={`btn btn-primary btn-md ${isMobile && "btn-block"}`} data-test-hook="continue_to_patient_portal">
              {loggedIn ? "Add Member" : "Continue"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ShowAppointments;
