import React from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';


const CovidTypeAppointment = ({values, setFieldValue, onClick}) => {
  const { t, i18n } = useTranslation();
  const { appointment } = values;


  const onChange = (refuseVaccine) => {
    setFieldValue('appointment.refuse_covid_vaccination', refuseVaccine);
    setFieldValue('appointment.insist_on_single_dose', null);
    setFieldValue('appointment.appointment_type', "default");
    setFieldValue("appointment.appointment_slot_id", null); 
    setFieldValue("appointment_slot_group_id", null);
    onClick(refuseVaccine);
  }

  return (
    <div className="mb-3">
      <h6>{t('registration.covid_vaccine_y_n')}</h6>
      <div>
        <FabrxCheckbox
          ariaLabel={t('yes')}
          name="covid_vaccination"
          className="me-4"
          onChange={() => onChange(false)}
          checked={appointment.refuse_covid_vaccination == false}
          radio={true}
          label={t('yes')}
        />
        <FabrxCheckbox
          ariaLabel={t('no')}
          name="refuse_covid_vaccination"
          onChange={() => onChange(true)}
          checked={appointment.refuse_covid_vaccination}
          radio={true}
          label={t('no')}
        />
      </div>
    </div>
  )
};

export default CovidTypeAppointment;
