import React from "react";
import { Card } from "react-bootstrap";
import { faCircleExclamation, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const stringToHexColor = (str) => {
  // Generate a numeric hash value from the input string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash |= 0; // Convert to 32-bit integer
  }
  // Ensure the hash is positive
  hash = Math.abs(hash);
  // Convert the numeric hash to a hex color string
  const hexColor = "#" + ((hash % 0x1000000).toString(16)).padStart(6, "0");
  return hexColor;
};

export const MessageSummary = ({
  deliveredVia,
  justifyContent,
  recipientCount,
  startedBy,
  startedByInitials,
}) => {
  return (
    <div className={`d-flex ${justifyContent}`}>
      <div className="d-flex">
        <div
          className="profile-circle me-1"
          style={{
            backgroundColor: stringToHexColor(startedBy),
            height: "24px",
            width: "24px",
          }}
        >
          <div 
            className="text-nowrap"
            style={{
              fontSize: "8px",
              fontWeight: 700,
              letterSpacing: "1px",
            }}
          >
            {startedByInitials}
          </div>
        </div>
        <span className="body-medium-normal inter my-auto">{startedBy}</span>
      </div>
      <div className="me-2">
        <span className="mx-2" style={{ color: "#DEE2E6" }}>|</span>
        <FontAwesomeIcon
          className="mx-1"
          icon={faUserGroup}
          style={{ height: 13 }}
        />
        <span className="body-medium-normal inter">{recipientCount}</span>
        <span className="mx-2" style={{ color: "#DEE2E6" }}>|</span>
        <span className="body-medium-normal inter">{deliveredVia}</span>
      </div>
    </div>
  );
};

const MessageItem = ({
  communication,
  isSelected,
  setSelectedCommunicationId,
}) => {

  const {
    delivered_via,
    failed_communication_task_count,
    id,
    preview,
    recipient_count,
    start_date_short,
    started_by,
    started_by_initials,
  } = communication;

  return (
    <Card
      className="p-3 pointer"
      onClick={() => setSelectedCommunicationId(id)}
      style={{
        backgroundColor: isSelected ? "#F7F7F7" : "",
        borderRadius: "0px",
        border: "1px solid #DEE2E6",
        borderLeft: "0px",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {!!failed_communication_task_count && (
            <FontAwesomeIcon
              className="my-auto me-2"
              color="#CB2531"
              icon={faCircleExclamation}
              onClick={() => setShowDeliveryErrorModal(true)}
              size="lg"
            />
          )}
          <span className="lead-20-medium inter bold">{preview?.email_subject}</span>
        </div>
        <span className="body-medium-normal inter">{start_date_short}</span>
      </div>
      <div className="mt-2 mb-3 body-medium-normal inter truncate-after-two-lines">
        {preview?.email_message}
      </div>
      <MessageSummary
        deliveredVia={delivered_via}
        justifyContent="justify-content-between"
        recipientCount={recipient_count}
        startedBy={started_by}
        startedByInitials={started_by_initials}
      />
    </Card>
  );
};

export default MessageItem;