import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Calendar } from 'react-multi-date-picker';
import { sumProperty } from '../utils';
import { formatTime } from '../utils/slotting';
import ModalHeader from './ModalHeader';

const Slots = ({
  display_date: date,
  location: { name: location_name },
  groupIdx,
  ...props
}) => {
  const groupSlots = props.appointment_slots || props.shared_capacities;

  const details = useMemo(() => {
    const firstSlot = groupSlots[0];
    const lastSlot = groupSlots[groupSlots.length - 1];

    return {
      hours: `${formatTime(firstSlot.starts_at)} - ${formatTime(
        lastSlot.ends_at,
      )}`,
      capacity: sumProperty(groupSlots, 'capacity'),
      access_key_capacity: sumProperty(groupSlots, 'access_key_capacity'),
    };
  }, [groupSlots]);

  return (
    <React.Fragment>
      <tr>
        <td>{location_name}</td>
        <td>{date}</td>
        <td>
          <div>{details['hours']}</div>
          <div>
            <a
              data-bs-toggle="collapse"
              href={`.collapsible-row[data-group='${groupIdx}']`}
            >
              See slots
            </a>
          </div>
        </td>
        <td>{details['capacity']}</td>
        <td>{details['access_key_capacity']}</td>
      </tr>
      {groupSlots.map((slot, idx) => (
        <tr
          key={idx}
          className="collapse collapsible-row"
          data-group={groupIdx}
        >
          <td colSpan={2}></td>
          <td>{`${formatTime(slot.starts_at)} - ${formatTime(
            slot.ends_at,
          )}`}</td>
          <td>{slot.capacity}</td>
          <td>{slot.access_key_capacity}</td>
        </tr>
      ))}
    </React.Fragment>
  );
};

const SlotGroupTable = ({ slotGroups }) => (
  <section className="fabrx-tables-light table-responsive">
    <table className="table table-lg">
      <thead>
        <tr>
          <th>Location name</th>
          <th>Day</th>
          <th>Hours</th>
          <th>Standard capacity</th>
          <th>Access key capacity</th>
        </tr>
      </thead>
      <tbody>
        {slotGroups.map((sg, idx) => (
          <Slots key={idx} groupIdx={idx} {...sg} />
        ))}
      </tbody>
    </table>
  </section>
);

const CloneSlotsForm = ({
  cloneDayPath,
  cloneSlotsPath,
  isBulkClone,
  slotGroups,
  onHide,
}) => {
  const datesInputEl = useRef(null);
  const [targetDates, setTargetDates] = useState([]);

  const onChange = (arr) => {
    setTargetDates(arr.map((dateObj) => dateObj.format('YYYY-MM-DD')));
  };

  useEffect(() => {
    if (datesInputEl.current) {
      if (targetDates.length === 0) {
        datesInputEl.current.setCustomValidity(
          'You should select at least one day',
        );
      } else {
        datesInputEl.current.setCustomValidity('');
      }
    }
  }, [targetDates]);

  return (
    <form
      action={isBulkClone ? cloneSlotsPath : cloneDayPath}
      id="clone-day-form"
      className="mt-4"
      acceptCharset="UTF-8"
      data-remote="true"
      method="post"
      onSubmit={onHide}
    >
      <div className="col">
        <label className="fw-bold form-label" htmlFor="target_dates">
          Select the dates below
        </label>
        <br />
        <Calendar onChange={onChange} multiple />
        <input
          value={targetDates.join(', ')}
          id="target_dates"
          ref={datesInputEl}
          onChange={() => {}}
          style={{
            opacity: 0,
            position: 'absolute',
            bottom: 5,
            left: 37,
            zIndex: -10,
          }}
        />
        {isBulkClone ? (
          <React.Fragment>
            {slotGroups.map((sg) =>
              sg.appointment_slots.map((as, idx) => (
                <input
                  key={idx}
                  value={as.id}
                  type="hidden"
                  name="appointment_slot_ids[]"
                />
              )),
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              value={slotGroups[0].date}
              type="hidden"
              name="date"
              id="date"
            />
            <input
              value={slotGroups[0].location.id}
              type="hidden"
              name="slot_group_id"
              id="slot_group_id"
            />
          </React.Fragment>
        )}
        {targetDates.map((td, idx) => (
          <input key={idx} value={td} type="hidden" name="target_dates[]" />
        ))}
      </div>
    </form>
  );
};

const CloneSlotsModal = ({
  show,
  onHide,
  groupsToClone,
  cloneDayPath,
  cloneSlotsPath,
  isBulkClone,
}) => (
  <Modal
    id="clone-modal"
    size="lg"
    show={show}
    onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <ModalHeader closeButton onHide={onHide}>
      <Modal.Title as="h2" id="example-modal-sizes-title-lg">
        Clone appointment slots
      </Modal.Title>
    </ModalHeader>
    <Modal.Body>
      <SlotGroupTable slotGroups={groupsToClone} />
      <CloneSlotsForm
        cloneDayPath={cloneDayPath}
        cloneSlotsPath={cloneSlotsPath}
        isBulkClone={isBulkClone}
        slotGroups={groupsToClone}
        onHide={onHide}
      />
      {groupsToClone.map((dayGroup, idx) => (
        <React.Fragment key={idx}></React.Fragment>
      ))}
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-outline-primary" onClick={onHide}>
        Close
      </button>
      <button
        type="submit"
        className="btn btn-primary"
        form="clone-day-form"
        id="submit-clone-appointments"
      >
        Clone
      </button>
    </Modal.Footer>
  </Modal>
);

export default CloneSlotsModal;
