import React from 'react';
import styled from 'styled-components';

const ActionCardContainer = styled.div`
  width: 250px;
  border-width: 2px;

  &:active,
  &:hover,
  &:focus {
    background-color: #e9f1fb;
  }

  &:focus {
    box-shadow: #8fadf8 0 0 0 3px;
  }

  @media (max-width: 576px) {
    width: inherit;
  }
`;

const ActionCard = ({
  icon,
  header,
  children,
  onClick,
}) => (
  <ActionCardContainer
    className={`card border ${onClick ? 'pointer' : ''}`}
    onClick={onClick}
    tabIndex={0}
  >
    <div className="card-body">
      {icon && (
        <img src={`/images/icons/${icon}.svg`} />
      )}
      <div className="mt-4 mb-1 h5">{header}</div>
      <div>{children}</div>
    </div>
  </ActionCardContainer>
);

const redirectTo = (url) => () => {
  window.location = url;
};

const DailyOperations = ({
  test_group: {
    on_demand_only,
    active_test_configurations_count,
    extensions,
    appointments_path,
    participant_list_path,
    send_communication_path,
    test_configurations,
    case_reports_path,
    errored_case_reports_path,
    statistics_path,
  },
}) => (
  <div className="row">
    <div className="col-12 col-sm-auto mt-4">
      {extensions.student_profiles ? (
        <ActionCard
          icon="icon-calendar-duotone"
          header="View student roster"
          onClick={redirectTo(
            on_demand_only ? participant_list_path : appointments_path,
          )}
        >
          <p className="card-text">
            View your school's student roster here
            {active_test_configurations_count === 0
              ? '.'
              : ', and checkout students as well.'}
          </p>
        </ActionCard>
      ) : (
        <ActionCard
          icon={
            on_demand_only
              ? "icon-person-duotone"
              : "icon-calendar-duotone"
          }
          header={
            on_demand_only
              ? 'Manage & checkout participants'
              : 'Appointments'
          }
          onClick={redirectTo(
            on_demand_only
              ? participant_list_path
              : appointments_path,
          )}
        >
          <p className="card-text">
            {
              on_demand_only
                ? 'View and checkout participants'
                : 'View appointments, check patients in & out'
            }
          </p>
        </ActionCard>
      )}
    </div>
    {test_configurations.length !== 0 && (
      <div className="col-12 col-sm-auto mt-4">
        <ActionCard
          icon="icon-testtube-duotone"
          header="Record test results"
          onClick={redirectTo('/abbott')}
        >
          <p className="card-text">Input test results for POC services</p>
        </ActionCard>
      </div>
    )}
    <div className="col-12 col-sm-auto mt-4">
      <ActionCard
        icon="icon-document-duotone"
        header="Case reports"
        onClick={redirectTo(case_reports_path)}
      >
        <p className="card-text">View test results and vaccination records</p>
        {errored_case_reports_path && (
          <span className="badge rounded-pill bg-danger">Errors found</span>
        )}
      </ActionCard>
    </div>
    <div className="col-12 col-sm-auto mt-4">
      <ActionCard
        icon="icon-envelope-duotone"
        iconStyles={{ marginRight: 2 }}
        header="Messages"
        onClick={redirectTo(send_communication_path)}
      >
        <p className="card-text">
          Send custom emails & SMS to participants
        </p>
      </ActionCard>
    </div>
    <div className="col-12 col-sm-auto mt-4">
      <ActionCard
        icon="icon-stats-duotone"
        iconStyles={{ padding: 3 }}
        header="Dashboards"
        onClick={redirectTo(statistics_path)}
      >
        View data visualizations on daily operations
      </ActionCard>
    </div>
  </div>
);

export default DailyOperations;