import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import * as SurveyOriginal from "survey-react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/plugins/bootstrap-integration";
import RegistrationContext from '../../contexts/RegistrationContext';
import { updateValuesFromSurvey } from './utils';
import { SectionTitle } from '../SectionComponents';

const surveyValidationErrors = (validationObject, data) => {
  if (!validationObject) return false
  if (!data) return false
  return Object.keys(validationObject).some(key => validationObject[key] !== data[key])
}

export const validateSurvey = (valuePath, survey, testGroup) => (values) => {
  const errors = {};
  if (testGroup.use_new_surveyjs_validation) {
    let model;
    if (testGroup.flipper_flags.upgraded_surveyjs) {
      model = new Model(survey);
    } else {
      model = new SurveyOriginal.Model(survey);
    }
    let data;
    if (valuePath) {
      data = valuePath.split(".").reduce((previous, current) => previous[current], values)
      data ? model.data = JSON.parse(data) : model.data = {};
    } else {
      data = values;
      model.data = values;
    }
    if (testGroup.use_new_surveyjs_validation) {
      if (testGroup.flipper_flags.upgraded_surveyjs) {
        if (model.hasErrors()) errors.questionnaire = 'registration.errors.survey_unanswered';
      } else {
        if (model.startedPage?.hasErrors()) errors.questionnaire = 'registration.errors.survey_unanswered';
      }
    }
    if (surveyValidationErrors(testGroup.metadata?.survey_validation, data)) {
      errors.test_group_questionnaire_error = "registration.errors.invalid_entry"
    } else if (surveyValidationErrors(testGroup.metadata?.disable_continuation_on_survey, data)) {
      errors.disable_continuation_on_survey = "registration.errors.invalid_entry"
    }
  } else {
    let requiredQuestions = []
    let data;
    if (valuePath) {
      const value = valuePath.split(".").reduce((previous, current) => previous[current], values)
      data = value ? JSON.parse(value) : null;
    } else {
      data = values
    }
    survey.pages.forEach(page => {
      Object.keys(page.elements).forEach(idx => {
        const questionName = page.elements[idx]['name'];
        if (page.elements[idx]['isRequired']){
          requiredQuestions.push(questionName);
        } else if (page.elements[idx]['requiredIf']) {
          // example: requiredIf: "{TEST6} notcontains 1"} or requiredIf: "{TEST12} allof ['item1', 'item2', 'item3']"}
          const requiredIfQuestionParts = page.elements[idx]['requiredIf'].split(" ");
          const dependantQuestion = requiredIfQuestionParts[0].slice(1,-1);
          const requiredIfCondition = requiredIfQuestionParts[1];
          let requiredIfValue = requiredIfQuestionParts.slice(2).join("");
          const requiredIfValueArray = requiredIfValue && requiredIfValue.slice(1,-1).split(",").map(val => val.slice(1,-1));
          if (requiredIfValue[0] === "'") requiredIfValue = requiredIfValue.slice(1,-1);
          if (requiredIfValue === "true") requiredIfValue = true;
          if (requiredIfValue === "false") requiredIfValue = false;
          switch(requiredIfCondition) {
            case 'empty':
              if (!data || !data[dependantQuestion] || data[dependantQuestion] === "") {
                requiredQuestions.push(questionName);
              }
              break;
            case 'notempty':
              if (data && data[dependantQuestion] && data[dependantQuestion] !== "") {
                requiredQuestions.push(questionName);
              }
              break;
            case '=':
              if (data && data[dependantQuestion] && data[dependantQuestion] === requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case '<>':
              if (data && data[dependantQuestion] && data[dependantQuestion] !== requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case 'contains':
              if (data && data[dependantQuestion] && data[dependantQuestion].includes(requiredIfValue)) {
                requiredQuestions.push(questionName);
              }
              break;
            case 'notcontains':
              if (!data || !data[dependantQuestion] || data[dependantQuestion] === "" || !data[dependantQuestion].includes(requiredIfValue)) {
                requiredQuestions.push(questionName);
              }
              break;
            case '>':
              if (data && data[dependantQuestion] && data[dependantQuestion] > requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case '<':
              if (data && data[dependantQuestion] && data[dependantQuestion] < requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case '<=':
              if (data && data[dependantQuestion] && data[dependantQuestion] <= requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case '>=':
              if (data && data[dependantQuestion] && data[dependantQuestion] >= requiredIfValue) {
                requiredQuestions.push(questionName);
              }
              break;
            case 'anyof':
              if (data && data[dependantQuestion] && requiredIfValueArray.some(v => data[dependantQuestion].includes(v))) {
                requiredQuestions.push(questionName);
              }
              break;
            case 'allof':
              if (data && data[dependantQuestion] && requiredIfValueArray.every(v => data[dependantQuestion].includes(v))) {
                requiredQuestions.push(questionName);
              }
              break;
            default:
              // do nothing
          }
        }
      })
    });

    if (requiredQuestions.length > 0){
      !requiredQuestions.some(function (question) {
        if(!data || data[question] === undefined) errors.questionnaire = 'registration.errors.survey_unanswered';
      });
    }

    if (surveyValidationErrors(testGroup.metadata?.survey_validation, data)) {
      errors.test_group_questionnaire_error = "registration.errors.invalid_entry"
    } else if (surveyValidationErrors(testGroup.metadata?.disable_continuation_on_survey, data)) {
      errors.disable_continuation_on_survey = "registration.errors.invalid_entry"
    }
  }

  return errors;
}

const CustomSurvey = ({
  showHeader=true,
  className,
  customSurvey,
  valuePath,
  values,
  errors,
  setFieldValue,
  title='registration.custom_survey',
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  let SurveyComponent, model;
  if (testGroup.flipper_flags.upgraded_surveyjs) {
    StylesManager.applyTheme('bootstrap');
    model = new Model(customSurvey);
    model.onScrollingElementToTop.add((_sender, options) => {
      options.cancel = true;
    })
    SurveyComponent = Survey;
  } else {
    SurveyOriginal.StylesManager.applyTheme('bootstrap');
    model = new SurveyOriginal.Model(customSurvey);
    SurveyComponent = SurveyOriginal.Survey;
  }
  const value = valuePath.split(".").reduce((previous, current) => previous[current], values)
  if (value) {
    model.data = JSON.parse(value);
  } else {
    model.data = {}
  }

  model.locale = i18n.language;
  model.render();
  if (Object.keys(errors).length > 0 && testGroup.use_new_surveyjs_validation) {
   testGroup.flipper_flags.upgraded_surveyjs
    ? model.hasErrors()
    : model.startedPage?.hasErrors();
  }

  const css = {
    question: {
      title: "survey-question-title"
    },
    boolean: {
      switch: "sv-boolean__switch",
    },
    radiogroup: {
      label: "radio-label survey-label has-check",
    },
    checkbox: {
      label: "survey-label checkbox-label has-check",
    },
    body: "panel-body card-block"
  }

  const errorsToShow = [
    'questionnaire',
    'test_group_questionnaire_error',
    'disable_continuation_on_survey',
  ];

  const removeAriaLabels = () => {
    // removes aria-label attributes from legend tags; solves accessibility issues
    Array.from($('legend')).forEach((el) => el.removeAttribute("aria-label"));
  }

  useEffect(() => {
    removeAriaLabels();
  }, [])

  return (
    <div
      className={`${className} ${testGroup.flipper_flags.upgraded_surveyjs && "upgraded_surveyjs"}`}
      data-test-hook="custom_survey"
      >
      {showHeader && (
        <SectionTitle className={"mb-4"}>{t(title)}</SectionTitle>
      )}
      <SurveyComponent
        model={model}
        onValueChanged={(e)=> {
          setFieldValue(valuePath, JSON.stringify(e.data))
          updateValuesFromSurvey(values, e.data, setFieldValue)
        }}
        css={css}
      />
      {errorsToShow.map(errorName =>
        errors[errorName] && <div className='text-danger' role="alert" aria-live="polite">{t(errors[errorName])}</div>
      )}
    </div>
  );
};

export default CustomSurvey;
