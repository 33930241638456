import React from 'react';

export const Printer = ({ color = '#314677', ...props }) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 2.33969C5 1.86138 5.44772 1.47363 6 1.47363H18C18.5523 1.47363 19 1.86138 19 2.33969V7.53606H20C20.7957 7.53606 21.5587 7.8098 22.1213 8.29705C22.6839 8.78431 23 9.44516 23 10.1342V14.4646C23 15.1536 22.6839 15.8145 22.1213 16.3017C21.5587 16.789 20.7957 17.0627 20 17.0627H19V19.6609C19 20.1392 18.5523 20.527 18 20.527H6C5.44772 20.527 5 20.1392 5 19.6609V17.0627H4C3.20435 17.0627 2.44129 16.789 1.87868 16.3017C1.31607 15.8145 1 15.1536 1 14.4646V10.1342C1 9.44516 1.31607 8.78431 1.87868 8.29705C2.44129 7.8098 3.20435 7.53606 4 7.53606H5V2.33969ZM7 18.7949H17V13.5985H7V18.7949ZM19 15.3306V12.7324C19 12.2541 18.5523 11.8664 18 11.8664H6C5.44772 11.8664 5 12.2541 5 12.7324V15.3306H4C3.73478 15.3306 3.48043 15.2394 3.29289 15.0769C3.10536 14.9145 3 14.6942 3 14.4646V10.1342C3 9.90455 3.10536 9.68427 3.29289 9.52185C3.48043 9.35943 3.73478 9.26818 4 9.26818H20C20.2652 9.26818 20.5196 9.35943 20.7071 9.52185C20.8946 9.68427 21 9.90455 21 10.1342V14.4646C21 14.6942 20.8946 14.9145 20.7071 15.0769C20.5196 15.2394 20.2652 15.3306 20 15.3306H19ZM17 3.20576V7.53606H7V3.20576H17Z"
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
  </svg>
);
