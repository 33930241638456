import React from 'react';
import { Row, Col, Button, Container, Card } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Footer from '../common/components/Footer';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Header from '../Registration/components/Header';
import { languageObject } from '../common/utils';

const IMAGES = {
  stepOne: "/images/public_test_groups/step_1.svg",
  stepTwo: "/images/public_test_groups/step_2.svg",
  stepThree: "/images/public_test_groups/step_3.svg",
  tempusInstructions: "/images/public_test_groups/tempus_instructions.png",
  logo: "/images/public_test_groups/logo.svg",
  salivaDirectLogo: "/images/public_test_groups/sdirect_logo.svg",
  phone: "/images/public_test_groups/phone.svg",
  email: "/images/public_test_groups/email.svg",
  png_logo: "/images/public_test_groups/gcb_logo.svg"
}

const TEMPUS_INSTRUCTIONS = "https://www.tempus.com/wp-content/uploads/2021/05/SalivaDirectFunnelIFU_041421.pdf"

const FAQ = ({title, text, footer}) => {
  return (
    <Col xs={12} sm={9} className="my-2">
      <Card className="border-primary" style={{boxShadow: "none"}}>
        <h6 className="text-primary ms-3 mt-2">{title}</h6>
        <Card.Body>{text}</Card.Body>
        {footer &&
          <Card.Footer style={{backgroundColor: "#F7FBFD"}} className='text-center border-top rounded-bottom border-primary'>{footer}</Card.Footer>
        }
      </Card>
    </Col>
  )
};

const Section = ({color, width=1000, children}) => (
  <div style={{backgroundColor: color}}>
    <Container style={{maxWidth: width}}>
      {children}
    </Container>
  </div>
)

export default (props) => {
  const { t, i18n } = useTranslation();
  const xs = useMediaQuery({ query: '(max-width: 440px)' });
  const md = useMediaQuery({query: '(max-width: 764px)'});
  const sm = useMediaQuery({query: "(max-width: 530px)"});

  const resizePhoto = () => {
    if(xs) return 162;
    if (sm) return 121;
    if (md) return 141
    return 162
  }

  const backgroundStyle = () => {
    if (xs) return {
      backgroundImage: "url(/images/public_test_groups/covid_test.png)",
      backgroundSize: "auto 100%",
      backgroundRepeat: "no-repeat",
    }
    if (sm || md) return {
        backgroundImage: "url(/images/public_test_groups/covid_test.png)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
    } 
    return {
      backgroundImage: "url(/images/public_test_groups/covid_test.png)",
      backgroundSize: "50%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
    }
  }

  return (
    <div>
      <Header testGroup={{locales: Object.keys(languageObject)}} />
      <Section color="rgb(245, 249, 251)">
        <div style={backgroundStyle()}>
          <div >
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <h1 className="mx-3 mt-3 ">{t('public_test_groups.title')}</h1>
                <p className="mx-3">{t('public_test_groups.description')}</p>
                <h6 className="mx-3">{t('public_test_groups.appointment_recoveries_title')}</h6>
                <Button href="/finder" variant="primary" className="mx-3 mb-3">{t('public_test_groups.appointment_recoveries_button_text')}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </Section>
      <Section color="#F5FBF7">
        <div className="py-3">
          <h6 className="mx-3 mt-3 text-primary">{t('public_test_groups.search_test_group_title')}</h6>
          <h5 className="mx-3">{t('public_test_groups.title_3')}</h5>
          <div style={{ maxWidth: 398 }}>
          </div>
          <Row>
            <Col sm={6} xs={12} className="my-2">
              <Card body className="border-primary" style={{boxShadow: "none"}}>
                <div className="text-end">
                  <img style={{ maxHeight: 45 }} src="/images/public_test_groups/mbio_logo.svg" />
                </div>
                <img className="my-2" src="/images/public_test_groups/staff.svg"/>
                <h5>Mammoth Biosciences</h5>
                <h5 className="text-muted">{t('public_test_groups.population_title')}</h5>
                <Button href="/finder" className="float-end">{t('public_test_groups.population_button')}</Button>
              </Card>
            </Col>
            <Col sm={6} xs={12} className="my-2">
              <Card body className="border-primary" style={{ boxShadow: "none" }}>
                <div className='text-end'>
                  <img style={{ maxHeight: 45 }} src="/images/public_test_groups/gcb_logo.svg" />
                </div>
                <img className="my-2" src="/images/public_test_groups/staff.svg"/>
                <h5>Gambling Control Board</h5>
                <h5 className="text-muted">{t('public_test_groups.population_title')}</h5>
                <Button href="/r/mn-gambling-control-board-employer-testing?registration_type=default" className="float-end">{t('public_test_groups.population_button')}</Button>
              </Card>
            </Col>
          </Row>
        </div>
      </Section>
      <Section color="#FBFBF5" width={769}>
        <div className="py-3">
          <div style={{maxWidth: 650}}>
            <h6 className="mx-3 mt-3 text-primary">{t('public_test_groups.subtitle_1')}</h6>
            <h5 className="mx-3">{t('public_test_groups.title_2')}</h5>
          </div>
          <Row>
            <Col xs={xs ? 12 : 4} className={`my-4 ${xs && "text-center"}`}>
              <img style={{maxWidth: resizePhoto()}} src={IMAGES.stepOne}/>
              <div className="caption"><b>{t('public_test_groups.step_one')}</b></div>
            </Col>
            <Col xs={xs ? 12 : 4} className={`my-4 ${xs ? "text-center" : "ms-auto"}`}>
              <img style={{maxWidth: resizePhoto()}} src={IMAGES.stepTwo}/>
              <div className="caption"><b>{t('public_test_groups.step_two')}</b></div>
            </Col>
            <Col md="auto" xs={xs ? 12 : 4} className={`my-4 ${xs ? "text-center" : "ms-auto"}`}>
              <img style={{maxWidth: resizePhoto()}} src={IMAGES.stepThree}/>
              <div className="caption"><b>{t('public_test_groups.step_three')}</b></div>
            </Col>
          </Row>
        </div>
      </Section>
      <Section color="#FBF5F5">
        <div className="py-3">
          <h6 className="mx-3 mt-3 text-primary">{t('public_test_groups.faq_subtitle')}</h6>
          <h5 className="mx-3">{t('public_test_groups.faq_title')}</h5>
          <Row className="justify-content-center my-2">
            <FAQ
              title={t('public_test_groups.faq_1')}
              text={(
                <p>
                  <ol start="1" className='mb-0'>
                    <li>
                      {t('public_test_groups.faq_1_1')}
                    </li>
                    <li>
                      {t('public_test_groups.faq_1_2')}
                    </li>
                    <li>
                      {t('public_test_groups.faq_1_3')}
                    </li>
                    <li>
                      {t('public_test_groups.faq_1_4')}
                    </li>
                  </ol>
                  <ReactMarkdown source={"5. " + t('public_test_groups.faq_1_5')} />
                </p>
              )}
              footer={(
                <a href={TEMPUS_INSTRUCTIONS}>{t('public_test_groups.faq_1_footer')}</a>
              )}
            />
            <FAQ
              title={t('public_test_groups.faq_2')}
              text={(<ReactMarkdown source={t('public_test_groups.faq_2_subtitle')} />)}
            />
            <FAQ
              title={t('public_test_groups.faq_3')}
              text={(<p>{t('public_test_groups.faq_3_subtitle')}</p>)}
            />
            <Col xs={12} sm={4} className="my-2">
              <h6>{t('public_test_groups.appointment_recoveries_title')}</h6>
              <Button style={{width: "150px"}} variant="outline-primary" href="/finder">{t('public_test_groups.appointment_recoveries_button_text')}</Button>
            </Col>
          </Row>
        </div>
      </Section>
      <Section color="rgb(245, 249, 251)">
        <div className="py-3 pb-3">
          <Row className='justify-content-center'>
            <Col xs="auto">
              <img src={IMAGES.logo} /><br/><br/>
              <img src={IMAGES.salivaDirectLogo} />
              <div className="my-3">
                <img src={IMAGES.phone}/> <b className="text-primary">1-855-970-3223</b>
              </div>
              <div className="my-3">
                <img src={IMAGES.email}/> <b className="text-primary">primary@primary.health</b>
              </div>
            </Col>
          </Row>
          <p>
            {t('public_test_groups.footer_text_1')}<br/><br/>
            {t('public_test_groups.footer_text_2')}<br/><br/>
            {t('public_test_groups.footer_text_3')}
          </p>
        </div>
      </Section>
      <Footer partnerLogo={'/images/public_test_groups/hh_logo.svg'}/>
    </div>
  );
};
