import React, { useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {CardScanView} from "@cardscan.ai/insurance-cardscan-react";
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

export default ({state, dispatch}) => {
  const { t } = useTranslation();
  const [openCardScan, setOpenCardScan] = useState(false);
  const handleChange = (e) => {
    dispatch({type: "updateForm", values: {[e.target.name]: e.target.value}})
  }

  const onNewFile = (file, name) => {
    dispatch({ type: 'updateForm', values: {[`insuranceCard${name}`]: file}});
    const reader = new FileReader();

    reader.addEventListener('load', function() {
      dispatch({ type: 'updateForm', values: {[`insuranceCard${name}Preview`]: reader.result}});
    });
    reader.readAsDataURL(file);
  };

  const insuranceCompanyOptions = state.testGroup.insurance_companies.map(v => ({ label: v, value: v }));
  async function onSuccess(card) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      onNewFile(xhr.response, "Front")
    };
    xhr.open('GET', card.images.front.url);
    xhr.send();
    const memberName = card.details.memberName?.value?.split(" ");
    const values = {
      groupNumber: card.details.groupNumber?.value,
      memberId: card.details.memberNumber?.value,
      insuranceCompanyName: card.details.payerName?.value
    }
    if (memberName?.length == 2){
      values.policyHolderFirstName = memberName[0];
      values.policyHolderLastName = memberName[1];
    }
    dispatch({type: 'updateForm', values });
    setOpenCardScan(false)
  }

  function onError(error) {
    console.error('Error occurred: ', error);
  }

  // const sides = ["Front", "Back"]
  const sides = ["Front"]
  return (
    <>
      <h4 className='mt-4 mb-3 inter medium'>{t('no_insurance_information.insurance_card')}</h4>
      <p>{t('registration.insurance_information.take_photo')}</p>
      {openCardScan
      ? <div className='cardscan'>
          <div style={{position: "absolute", top: 0, left: "50%"}}><Spinner variant='primary' animation='border' /></div>
          <CardScanView
            live={false}
            sessionToken={state.testGroup.card_scan_access_token}
            onSuccess={onSuccess}
            onError={onError}
            progressBarColor='#048154'
            // backsideSupport
            closeButton={<FontAwesomeIcon icon={faCircleXmark} />}
            onCancel={() => setOpenCardScan(false)}
          />
        </div>
      : <Row>
        {sides.map(face => (
          <Col xs="6" style={{position: 'relative'}}>
            {state.formState[`insuranceCard${face}Preview`]
              ? <>
                <input
                  id={`fileUpload${face}`}
                  class="form-file-input file optional"
                  name={`fileUpload${face}`}
                  type="file"
                  onChange={(e) => onNewFile(e.target.files[0], face)}
                  style={{
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: "-1"
                  }}/>
                  <label
                    for={`fileUpload${face}`}
                    style={{
                      background: "#2862FA",
                      borderRadius: 100,
                      height: 30,
                      width: 32,
                      position: "absolute",
                      right: 0,
                      top: "-11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} color='white' style={{padding: 7}} />
                  </label>
                  <img src={state.formState[`insuranceCard${face}Preview`]} alt="Preview Image" style={{borderRadius: 20}} />
                </>
              : <div>
                <div
                  style={{height: 112, borderRadius: 24, padding: "44px 59px", gap: 8, backgroundColor: "#F8F8FC", border: "1px solid #EAEAF2"}}
                  className='text-center pointer'
                  onClick={() => setOpenCardScan(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            }
            <div className='text-center mt-2' style={{fontSize: 14, fontWeight: 400}}>{t(`registration.insurance_information.${face.toLowerCase()}_of_card`)}</div>
          </Col>
        ))}
      </Row>
      }
      <h4 className='mt-4 inter medium'>{t('registration.insurance_policy_holder.name')}</h4>
      <FloatingLabelInput
        name="policyHolderFirstName"
        className="mt-4"
        value={state.formState.policyHolderFirstName || ''}
        onChange={handleChange}
        ariaRequired="true"
        ariaLabel="first name"
        minLength={2}
        label={t('registration.first_name_label') + "*"}
      />
      <FloatingLabelInput
        name="policyHolderLastName"
        className="mt-4"
        value={state.formState.policyHolderLastName || ''}
        onChange={handleChange}
        ariaRequired="true"
        ariaLabel="first name"
        minLength={2}
        label={t('registration.last_name_label') + "*"}
      />
      <h4 className='mt-4 mb-3 inter medium'>{t('registration.insurance_information.card_information')}</h4>
      <div className={'input-container select-container mb-4'}>
        <CreatableSelect
          name="insuranceCompanyName"
          value={{label: state.formState.insuranceCompanyName, value: state.formState.insuranceCompanyName}}
          onChange={(e) =>    dispatch({type: "updateForm", values: {insuranceCompanyName: e.value}}) }
          options={insuranceCompanyOptions}
          formatCreateLabel={(value) => `Other - click here to add: ${value}`}
          aria-label={t('registration.insurance_information.company_name')}
        />
        <label className={state.formState.insuranceCompanyName && 'filled'} htmlFor={"insuranceCompanyName"}>
          {t('registration.insurance_information.company_name') + "*"}
        </label>
      </div>
      <FloatingLabelInput
        name="memberId"
        className="mb-4"
        value={state.formState.memberId || ''}
        onChange={handleChange}
        ariaRequired="true"
        ariaLabel={t('registration.insurance_information.id_number')}
        label={t('registration.insurance_information.id_number')}
      />
      <FloatingLabelInput
        name="groupNumber"
        className="mb-4"
        value={state.formState.groupNumber || ''}
        onChange={handleChange}
        ariaRequired="true"
        ariaLabel={t('registration.insurance_information.group_number')}
        label={t('registration.insurance_information.group_number')}
      />
    </>
  )
}
