import React, { useContext } from 'react';
import '../../common/locales/i18n';
import { Row, Col } from 'react-bootstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import StatCard from './common/StatCard';
import HistorySection from './partials/HistorySection';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MemberWrapper from './partials/MemberWrapper';
import MembersPortalContext from "../context";
import { useMediaQuery } from 'react-responsive';

const buildRecentTestResult = (mostRecentCovidTest, actionLink, t) => {
    let statProps = {
      title: t('login.last_covid_test'),
      dataTestHook: "most_recent_covid_test",
      actionLink: actionLink,
      actionText: (<><FontAwesomeIcon icon={['fa-solid', 'vial']} className='me-1'/> {t('login.route.programs')}</>),
    }
    if (!mostRecentCovidTest.found) {
      return <StatCard {...{...statProps, variant: "grey", stat: t('login.no_record')}} />;
    }
  
    if (!mostRecentCovidTest.test.result) {
      return <StatCard {...{...statProps, stat: t('employer_testing.results.pending')}} />;
    }
  
    let stat, variant;
    const result = mostRecentCovidTest.test.result;
    if (result == "negative") {
      stat = t('employer_testing.results.negative');
      variant = "success";
    } else if (result == "positive") {
      stat = t('employer_testing.results.positive');
      variant = "danger"
    } else if (result == "processing") {
      stat = t('result_page.view_results.status.processing');
    } else {
      stat =  t('employer_testing.results.unknown');
    }

  
    return (
      <StatCard
        {...{
          ...statProps,
          variant,
          stat,
          description: format(new Date(mostRecentCovidTest.test.localized_administered_at), "EEEE, LLLL d, y @ hh:mm b")
        }}
      />
    )
}

const buildUpcomingAppointment = (upcomingAppointment, actionLink, t) => {
  const props = {
    title: t('member.next_appointment'),
    icon: "/images/login/calendar.svg",
    actionLink: actionLink,
    dataTestHook: "edit_appointment",
    actionText: (<><img src="/images/login/pencil.svg" className='me-1'/>{t('login.edit_appointment')}</>),
    stat: t('login.no_upcoming')
  };

  if (!upcomingAppointment.found) return <StatCard {...props} />;
  const { localized_start_time: {weekday, month, day, hour, minute, am_pm} } = upcomingAppointment;

  return <StatCard
    {...props}
    stat={`${weekday}, ${month} ${day}`}
    description={`${hour}:${minute} ${am_pm} @ ${upcomingAppointment.appointment_slot_group.title}`}
  />
}

const Health = () => {
  const { currentUser, t } = useContext(MembersPortalContext);

  const selfAdminLink = (selfAdminTestGroupUsers) => {
    if (selfAdminTestGroupUsers.length == 0) {
      return null
    }
    if (selfAdminTestGroupUsers.length == 1) {
      return selfAdminTestGroupUsers[0].self_checkout_appointment.self_checkout_link
    }
    return `/members/programs?current_user=${currentUser.id}`
  }

  let oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const nextAppointmentLink = currentUser.next_appointment.edit_appointment_link;

  const selfAdminTestGroupUsers = currentUser.test_group_users.filter(
    testGroupUser => testGroupUser.self_checkout_appointment.found
  );

  const isArchived = currentUser?.test_group_users.filter(testGroupUser => testGroupUser.user_id == currentUser.id).archived;
  
  const nothingHere = (
    <div className='my-3 text-muted'>
      {t('member.no_dashboard')}
    </div>
  )

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <MemberWrapper>
      <Row>
        <Col xs={12} md={7}>
          {isDesktop && <div className="h4 my-3" data-test-hook="dashboard_title">{t('login.route.me')}</div>}
          {(selfAdminTestGroupUsers.length > 0 || currentUser.important_tests.most_recent_covid_test.found) &&
            <div style={isArchived ? {color: "#717D96", pointerEvents: "none"} : null} className="mb-3">
              {buildRecentTestResult(
                currentUser.important_tests.most_recent_covid_test,
                selfAdminLink(selfAdminTestGroupUsers),
                t
              )}
            </div>
          }
          {currentUser.next_appointment.found &&
            <div className="mb-3">
              {buildUpcomingAppointment(currentUser.next_appointment, nextAppointmentLink, t)}
            </div>
          }
          { !currentUser.important_tests.most_recent_covid_test.found &&
            !currentUser.next_appointment.found &&
            nothingHere
          }
        </Col>
        <Col xs={12} md={5}>
          <HistorySection tests={currentUser.medical_history} preview />
        </Col>
      </Row>
    </MemberWrapper>
  );
};

export default Health;
