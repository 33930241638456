import React from 'react';

export const PrimaryIcon = ({ icon, color, style={}, ...props }) => {
  if (color){
    return (
      <span
        style={{
          backgroundColor: color,
          mask: `url(/images/icons/icon-${icon}.svg) no-repeat center`,
          WebkitMask: `url(/images/icons/icon-${icon}.svg) no-repeat center`,
          WebkitMaskSize: props.height,
          height: props.height,
          width: props.width,
          display: "inline-block",
          ...style,
        }}
        {...props}
      ></span>
    )
  }

  return <img src={`/images/icons/icon-${icon}.svg`} alt={icon} {...props} />
};
