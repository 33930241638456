import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";

import MessageFilter from "./MessageFilter";
import NewMessageContext from "../contexts/NewMessageContext";
import RecipientsTable from "./RecipientsTable";

const NewMessageAddRecipients = ({}) => {

  const {
    dispatch,
    state,
  } = useContext(NewMessageContext);

  return (
    <Row>
      <Col
        className="ps-5 pt-5"
        xs={6} xl={7}
      >
        <RecipientsTable />
      </Col>
      <Col
        className="px-5 pt-5"
        style={{ borderLeft: "1px solid #DEE2E6" }}
        xs={6} xl={5}
      >
        <div className="mb-6">
          <h3>Add recipients</h3>
          <p className="body-medium-bold inter semibold text-muted">Define the audience of your message</p>
          <p><u className="inter bold">{state.recipient_count} people</u> will receive a message based on your filters</p>
        </div>

        <MessageFilter
          dispatch={dispatch}
          filterName="Organization*"
          isDisabled={state.filter_options.organization_filter_options.length <= 1}
          isMulti
          isOptionDisabled={(option) => option.value == "1"} // disabling Primary
          options={state.filter_options.organization_filter_options}
          placeHolder="Add org(s)"
          selectedOptions={state.filter_params.organizations}
          type="setOrganizations"
        />

        <MessageFilter
          dispatch={dispatch}
          filterName="Group"
          isDisabled={state.filter_options.test_group_filter_options.length <= 1}
          isMulti
          options={state.filter_options.test_group_filter_options}
          placeHolder="Add group(s)"
          selectedOptions={state.filter_params.test_groups}
          type="setTestGroups"
        />

        <MessageFilter
          dispatch={dispatch}
          filterName="Population"
          isDisabled={state.filter_params.organizations.length < 1 &&
            state.filter_params.test_groups.length < 1
          }
          isMulti
          options={state.filter_options.population_filter_options}
          placeHolder="Add population(s)"
          selectedOptions={state.filter_params.populations}
          type="setPopulations"
        />

        <MessageFilter
          dispatch={dispatch}
          filterName="Tag"
          isDisabled={state.filter_params.organizations.length < 1 &&
            state.filter_params.test_groups.length < 1
          }
          isMulti
          options={state.filter_options.tag_filter_options}
          placeHolder="Add tag(s)"
          selectedOptions={state.filter_params.tags}
          type="setTags"
        />

        <MessageFilter
          dispatch={dispatch}
          filterName="Location"
          isDisabled={state.filter_params.organizations.length < 1 &&
            state.filter_params.test_groups.length < 1
          }
          isMulti
          options={state.filter_options.location_filter_options}
          placeHolder="Add location(s)"
          selectedOptions={state.filter_params.locations}
          type="setLocations"
        />

        <MessageFilter
          blankLabel="N/A"
          dispatch={dispatch}
          filterName="Consent"
          includeBlank
          options={state.filter_options.consent_filter_options}
          placeHolder="Select consent preference for this message"
          selectedOptions={state.filter_params.consented_to}
          type="setConsentedTo"
        />

        <MessageFilter
          blankLabel="N/A"
          dispatch={dispatch}
          filterName="Case status"
          includeBlank
          options={state.filter_options.case_report_status_filter_options}
          placeHolder="Select case status"
          selectedOptions={state.filter_params.case_report_status}
          type="setCaseReportStatus"
        />

        <div className="my-3">
          <h6 className="body-medium-bold inter semibold">
            Case report date
          </h6>
          <DatePicker
            containerStyle={{ width: '100%' }}
            disableYearPicker
            format="ddd, MMMM D"
            inputClass="form-control"
            maxDate={new Date()}
            onChange={(objectDate) =>
              dispatch({
                type: 'setAdministeredAtDate',
                values: objectDate,
              })
            }
            placeholder="Select a date"
            range
            shadow={false}
            value={state.filter_params.administered_at_date}
          />
        </div>

        <MessageFilter
          blankLabel="N/A"
          dispatch={dispatch}
          filterName="Appointment status"
          includeBlank
          options={state.filter_options.appointment_status_filter_options}
          placeHolder="Select appointment status"
          selectedOptions={state.filter_params.appointment_status}
          type="setAppointmentStatus"
        />

        <div className="my-3">
          <h6 className="body-medium-bold inter semibold">
            Appointment date
          </h6>
          <DatePicker
            containerStyle={{ width: '100%' }}
            disableYearPicker
            format="ddd, MMMM D"
            inputClass="form-control"
            maxDate={new Date()}
            onChange={(objectDate) =>
              dispatch({
                type: 'setAppointmentDate',
                values: objectDate,
              })
            }
            placeholder="Select a date"
            range
            shadow={false}
            value={state.filter_params.appointment_date}
          />
        </div>
      </Col>
    </Row>
  );
};

export default NewMessageAddRecipients;