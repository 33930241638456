import React, { useState } from "react";
import { Button } from "react-bootstrap";

import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import SideBar, { Body } from "../../common/components/SearchFilters/SideBar";

const MessagesFiltersBar = ({
  currentUserIsGod = false,
  organizationFilterOptions,
  testGroupFilterOptions,
  type = "messages",
}) => {

  const url = new URL(window.location.href);

  const [filters, setFilters] = useState({
    organizations: url.searchParams.get("organizations")?.split(",")
      || organizationFilterOptions.map(o => o.value),
    test_groups: url.searchParams.get("test_groups")?.split(",") || [],
  });

  const applyFilters = () => {
    Object.entries(filters).forEach(([filter, value]) => {
      url.searchParams.set(filter, value);
    })
    window.location.href = url.toString();
  };

  const resetFilters = () => {
    window.location.href = location.protocol + "//" + location.host + location.pathname
  };

  const useSimplifiedGodOption = currentUserIsGod 
    && filters.organizations.length === organizationFilterOptions.length;

  return (
    <SideBar
      title={`Filter ${type}`}
      subTitle={`Apply filters to your ${type} view.`}
      id="filterSideBar"
    >
      <Body>
        <div className="my-3">
          <FloatingLabelSelect
            filledValue={!!filters["organizations"].length}
            isDisabled={organizationFilterOptions.length === 1}
            isMulti
            label="Organizations"
            onChange={(organizations) => {
              setFilters({
                ...filters,
                organizations: organizations.map(tg => tg.value)
              })
            }}
            options={organizationFilterOptions}
            value={
              useSimplifiedGodOption // for god users instead of putting all orgs into the multi input by default, we show 'All'
                ? [{value: null, label: "All"}]
                : organizationFilterOptions.filter(o => filters["organizations"].includes(o.value))
            }
          />
        </div>
        <div className="my-3">
          <FloatingLabelSelect
            filledValue={!!filters["test_groups"].length}
            isMulti
            label="Test groups"
            onChange={(testGroups) => {
              setFilters({
                ...filters,
                test_groups: testGroups.map(tg => tg.value)
              })
            }}
            options={testGroupFilterOptions}
            value={testGroupFilterOptions.filter(o => filters["test_groups"].includes(o.value))}
          />
        </div>
      </Body>
      <div
        className="m-3 d-flex flex-row justify-content-end"
        style={{ gap: 8 }}
      >
        <Button
          className="me-2"
          onClick={() => resetFilters()}
          variant="link">
          Clear filters
        </Button>
        <Button
          className="me-2"
          onClick={() => applyFilters()}
          variant="primary"
        >
          Apply filters
        </Button>
      </div>
    </SideBar>
  );
};

export default MessagesFiltersBar;