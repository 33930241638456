import React from 'react';

export const Flipper = ({
  children,
  feature = false,
  record = {},
  flag = '',
}) => {
  const flipperFlags = record.flipper_flags || {};
  const active = feature || flipperFlags[flag];
  const _children = React.Children.toArray(children);

  if (_children.length === 1) {
    return active ? children : null;
  }

  return _children.map((element) => {
    const { type: Component } = element;

    if (active) {
      if (Component.type === 'enabled') {
        return element;
      }
    } else if (Component.type === 'else') {
      return element;
    }

    return null;
  });
};

export const Enabled = ({ children }) => children;
Enabled.type = 'enabled';

export const Else = ({ children }) => children;
Else.type = 'else';

Flipper.Enabled = Enabled;
Flipper.Else = Else;

export default Flipper;
