import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import FloatingLabelInput from '../FloatingLabelInput';


const SearchFiltersModal = ({
  test_group,
  filter_params,
  modal_type,
  source_url,
  filter_type,
}) => {

  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (modal_type === 'edit') getFilterToEdit();
    $('.open-search-filters-modal').click(function (e) {
      e.preventDefault();
      setShow(true);
    });
  }, []);

  const closeModal = () => {
    setShow(false);
  }

  const redirectWithNewParams = (filterParams, searchFilterId) => {
    delete filterParams.search_filter_id;
    const newFilterParams = Object.entries(filterParams).map(([k, v]) => `${k}=${v}`).join('&');
    window.location = `/test_groups/${test_group.slug}/${source_url}?${newFilterParams}`
                    + `&search_filter_id=${filter_params['search_filter_id'] || searchFilterId}`;
  }

  const getFilterToEdit = async () => {
    const result = await fetchSearchFilter(filter_params['search_filter_id']);
    const searchFilter = result.data.search_filter;
    setSelectedSearchFilter(searchFilter);
    setFilterName(searchFilter?.name);
  }

  const fetchSearchFilter = async (searchFilterId) => {
    return axios.get(`/test_groups/${test_group.id}/search_filters/${searchFilterId}`);
  }

  const saveSearchFilter = async () => {
    return axios.post(`/test_groups/${test_group.id}/search_filters`, {
      name: filterName,
      filter_params: JSON.stringify(filter_params),
      filter_type: filter_type,
    })
  }

  const editSearchFilter = async (searchFilterId) => {
    return axios.put(`/test_groups/${test_group.id}/search_filters/${searchFilterId}`, {
      name: filterName,
      filter_params: JSON.stringify(filter_params),
      filter_type: filter_type,
    })
  }

  return (
    <Modal show={show} size='lg'>
      <div className='px-4 py-5'>
        <h3>{modal_type === 'edit' ? 'Update' : 'Save'} this filter?</h3>
        {modal_type === 'edit' ? (
          <p className='my-4'>
            You are updating your filter preferences for your pre-saved
            “{selectedSearchFilter?.name}” filter. Feel free to update your name as well.
          </p>
        ) : (
          <p className='my-4'>
            Save these current filter preferences to access them with a click of a button next time.
          </p>
        )}
        <FloatingLabelInput
          value={filterName}
          onChange={(e) => {
            if (e.target.value.length <= 25) setFilterName(e.target.value);
          }}
          label='Filter name *'
          validation={(error || filterName?.length >= 25) && 'is-invalid'}
          displayIcon="true"
        />
        {error && <div className="text-danger mx-2">{error}</div>}
        {filterName?.length >= 25 && (
          <div className="text-danger mx-2">Just a heads up, you have reached the maximum number of charachters.</div>
        )}
        <div className='text-muted mx-2'>Character max: {filterName?.length}/25</div>
        <div className='d-flex justify-content-end my-3'>
          <Button
            variant='outline-primary'
            className='mx-2'
            onClick={() => closeModal()}
          >
            No, go back
          </Button>
          <Button
            className='mx-2'
            disabled={filterName === ""}
            onClick={ async () => {
              try {
                const response = modal_type === 'edit'
                                  ? await editSearchFilter(filter_params['search_filter_id'])
                                  : await saveSearchFilter();
                if (response.status === 200) {
                  if (response.data.name_already_in_use) {
                    setError(response.data.name_already_in_use);
                  } else {
                    const searchFilter = response.data.search_filter;
                    toastr.success(`${searchFilter.name} successfully ${modal_type === 'edit' ? 'updated' : 'saved'}!`);
                    redirectWithNewParams(JSON.parse(searchFilter.filter_params), searchFilter.id);
                    closeModal();
                  }
                } else {
                  toastr.error('Something went wrong.');
                  closeModal();
                }
              } catch(e) {
                toastr.error('Something went wrong.');
                closeModal();
              }
            }}
          >
            Yes, {modal_type === 'edit' ? 'edit' : 'save'} filter
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SearchFiltersModal;

