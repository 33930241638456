import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Header from '../../Registration/components/Header';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { languageObject } from '../common/utils';
import { lang } from '../common/utils';

export default () => {
  const { t, i18n } = useTranslation();
  const xs = useMediaQuery({ query: '(max-width: 440px)' });
  const md = useMediaQuery({ query: '(max-width: 764px)' });
  const sm = useMediaQuery({ query: '(max-width: 530px)' });
  const lg = useMediaQuery({ query: '(max-width: 992px)' });
  const xl = useMediaQuery({ query: '(max-width: 1200px)' });
  const xxl = useMediaQuery({ query: '(max-width: 1400px)' });

  const instructionsLink = () => {
    if (i18n.language === 'es')
      return 'https://primarybio-public.s3.us-east-2.amazonaws.com/spanish_quickvue.pdf';
    else if (i18n.language === 'so')
      return 'https://primarybio-public.s3.us-east-2.amazonaws.com/somali_quickvue.pdf';
    else if (i18n.language === 'hmn')
      return 'https://primarybio-public.s3.us-east-2.amazonaws.com/hmong_quickvuepdf.pdf';
    return 'https://primarybio-public.s3.us-east-2.amazonaws.com/english_quickvue.pdf';
  };

  const backgroundStyle = () => {
    if (xs || sm || md)
      return {
        backgroundImage: 'url(/images/public_test_groups/quickvue_box.svg)',
        borderRadius: '50%',
        height: 350,
        width: 350,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 'auto',
      };
    if (lg)
      return {
        backgroundImage: 'url(/images/public_test_groups/quickvue_box.svg)',
        borderRadius: 12,
        height: 250,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
    if (xl)
      return {
        backgroundImage: 'url(/images/public_test_groups/quickvue_box.svg)',
        borderRadius: 12,
        height: 300,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
    return {
      backgroundImage: 'url(/images/public_test_groups/quickvue_box.svg)',
      borderRadius: 12,
      height: 400,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
  };

  const textMargin = () => {
    if (xs || sm || md) return { textAlign: 'center' };
    return {
      marginLeft: 30,
    };
  };

  return (
    <div>
      <Header testGroup={{ locales: Object.keys(languageObject) }} />
      <Container className="p-3" style={{ color: '#08173A' }}>
        <Row
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
          className="my-3 py-3"
        >
          <Col md={6} style={backgroundStyle()}></Col>
          <Col md={6}>
            <div className="my-3" style={textMargin()}>
              <h1>{t('public_test_groups.demo.title')}</h1>
              <Button
                variant="primary"
                className="mt-3"
                style={{ backgroundColor: '#08173A', borderRadius: 200 }}
                onClick={() => window.open(instructionsLink())}
              >
                {t('public_test_groups.demo.download_instructions')}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon="arrow-down" />
              </Button>
            </div>
          </Col>
        </Row>
        <div>
          <Row
            style={{
              backgroundImage:
                'url(/images/public_test_groups/landing_page_bottom.svg)',
              borderRadius: 12,
              height: 250,
              alignItems: 'center',
            }}
            className="text-white"
          >
            <Col lg={6} className="text-center">
              <h1>{t('public_test_groups.demo.online_instructions')}</h1>
            </Col>
            <Col lg={6} className="text-center">
              <Button
                variant="primary-outline"
                size="xl"
                className="px-10"
                style={{ backgroundColor: 'white', borderRadius: 200 }}
                onClick={() => {
                  window.location.href = `/test-instructions/quickvue?locale=${lang()}`;
                }}
              >
                {t('public_test_groups.demo.begin')}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
