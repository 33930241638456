import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import { PersonalFieldDropdown } from '../../SectionComponents';

const Pronouns = ({
  attributeString="",
  context = RegistrationContext,
  error,
  handleChange,
  setFieldValue,
  values,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState } = useContext(context);

  const pronounOptions = testGroup.pronouns.map((pronounKey) => {
    return {
      value: pronounKey,
      label: t(`pronouns.${pronounKey}`),
    }
  });
  const label = buildLocalizedString(testGroup, 'preferred_pronouns_label');
  const subtitle = buildLocalizedString(testGroup, 'preferred_pronouns_subtitle');
  const helpText = buildLocalizedString(testGroup, 'preferred_pronouns_help_text');
  const required = testGroup.required_attributes.pronoun;

  return (
    <PersonalFieldDropdown>
      <div className="mb-2">
        <div className="text-muted">
          { subtitle && <div className="d-inline-block"><LocalizedMarkdown container={testGroup} stringKey='preferred_pronouns_subtitle' /></div> }
          {' '}
          { helpText && <HelpText text={ <LocalizedMarkdown container={testGroup} stringKey='preferred_pronouns_help_text' /> } /> }
        </div>

        <FloatingLabelSelect
          className={`test-user-pronoun ${error && 'is-invalid'}`}
          containerClassName='mt-3'
          ariaLabel="pronoun select, required"
          id="pronoun"
          value={pronounOptions.filter(option => (
            option.value === (localFormState.describePronoun ? "prefer_to_self_describe" : values.pronoun)
          ))}
          onChange={(s) => {
            setLocalFormState({...localFormState, describePronoun: s.value === "prefer_to_self_describe"});
            setFieldValue(`${attributeString}pronoun`, s.value);
          }} 
          options={pronounOptions}
          filledValue={localFormState.describePronoun ? "prefer_to_self_describe" : values.pronoun}
          label={
            label
              ? `${label} ${required ? '*' : '(Optional)'}`
              : `${t('pronouns.title')} ${required ? '*' : '(Optional)'}`
          }
        />
      </div>
      {localFormState.describePronoun &&
        <div className="mt-2 mb-2">
          <FloatingLabelInput
            value={values.pronoun === "prefer_to_self_describe" ? "" : values.pronoun}
            onChange={handleChange}
            className="mt-4"
            name={attributeString + "pronoun"}
            label={t('registration.self_described_gender')}
          />
        </div>
      }
    </PersonalFieldDropdown> 
  );
};

export default Pronouns;
