import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';


const AddVaccineCard = ({stagedVaccineHistory, onChange, onRemove}) => {
  return (
    <Card className="p-2 px-3 pb-3">
      <div className="text-end mb-2">
        <FontAwesomeIcon
          onClick={onRemove}
          className="pointer"
          icon={faXmark}
        />
      </div>
      <Row>
        <Col>
          <h4>{stagedVaccineHistory.vaccine}</h4>
          <div>{stagedVaccineHistory.description}</div>
        </Col>
        <Col>
          <FloatingLabelInput
            label="Date given"
            type="date"
            value={stagedVaccineHistory.vaccinatedAt}
            onChange={(e) => {
              onChange({ ...stagedVaccineHistory, vaccinatedAt: e.target.value });
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default AddVaccineCard;
