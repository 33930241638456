import React, { useContext } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import ErrorMessage from '../../common/components/ErrorMessage';

import { buildCustomizedLabel } from '../../common/locales/langs';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import DateOfBirthField from './DateOfBirthField';
import InsuranceContext from '../contexts/InsuranceContext';

const PolicyHolderFields = ({
    setFieldValue,
    values,
    errors,
    handleChange,
    onSetPolicyHolder,
    names={
      insurancePolicyHolder: "insurance_policy_holder",
      policyHolderKey: "policyHolder",
      primaryMemberFirstName: "insurance_primary_member_first_name",
      primaryMemberLastName: "insurance_primary_member_last_name",
      secondary: false,
      dob: "date_of_birth",
    }
  }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction, IAM_POLICYHOLDER } = useContext(InsuranceContext);
  const {
    insurancePolicyHolder,
    policyHolderKey,
    primaryMemberFirstName,
    primaryMemberLastName,
    secondary,
    dob
  } = names;
  const { user: {
    insurance_primary_member_first_name,
    insurance_primary_member_last_name
  }} = values;

  const policyHolderQuestion = (
    <div
      className="mb-3"
      data-test-hook={`policy_holder_question_${ secondary ? 'secondary' : 'primary' }`}
      >
      <div>{t('registration.insurance_policy_holder.question')}</div>
      {
        testGroup.policy_holder_options.map((policyHolder) => {
          return (
            <FabrxCheckbox
              key={policyHolder}
              name={`user[${insurancePolicyHolder}]`}
              dataTestHook={`policy_holder_${policyHolder}`}
              ariaLabel={`${t('registration.insurance_policy_holder.question')} ${t(`registration.insurance_policy_holder.${policyHolder}`)}`}
              onChange={(checked) => onSetPolicyHolder(checked, policyHolder)}
              checked={formAction[policyHolderKey] === policyHolder || false}
              label={t(buildCustomizedLabel(testGroup.population, `registration.insurance_policy_holder.${policyHolder}`))}
              radio
            />
        )})
      }
      {errors[insurancePolicyHolder] && <ErrorMessage message={t(errors[insurancePolicyHolder])} />}
    </div>
  );

  return (
    <div className="mt-3 mb-3">
      {policyHolderQuestion}
      {
        ![IAM_POLICYHOLDER, null, undefined, ""].includes(formAction[policyHolderKey]) ? (
          <div>
            <FloatingLabelInput
              name={`user[${primaryMemberFirstName}]`}
              className="mt-3"
              value={values.user[primaryMemberFirstName] || ""}
              onChange={handleChange}
              ariaLabel={t('registration.insurance_policy_holder.policy_first_name')}
              ariaInvalid={!!errors[primaryMemberFirstName]}
              id="primary_first_name"
              label={t('registration.insurance_policy_holder.policy_first_name')}
              validation={errors[primaryMemberFirstName] && 'is-invalid'}
            />
            {errors[primaryMemberFirstName] && <ErrorMessage message={t(errors[primaryMemberFirstName])} />}
            <br/>
            <FloatingLabelInput
              name={`user[${primaryMemberLastName}]`}
              className="mt-3"
              value={values.user[primaryMemberLastName] || ""}
              onChange={handleChange}
              ariaLabel={t('registration.insurance_policy_holder.policy_last_name')}
              ariaInvalid={!!errors[primaryMemberLastName]}
              id="primary_last_name"
              label={t('registration.insurance_policy_holder.policy_last_name')}
              validation={errors[primaryMemberLastName] && 'is-invalid'}
            />
            {errors[primaryMemberLastName] && <ErrorMessage message={t(errors[primaryMemberLastName])} />}
            <br/>
            {t('registration.insurance_policy_holder.policy_dob')}
            <DateOfBirthField
              setFieldValue={setFieldValue}
              values={values}
              secondary={secondary}
              errors={errors}
            />
            {errors[dob] && <ErrorMessage message={t(errors[dob])} />}
          </div>
        ) : null
      }
    </div>
  );
};

export default PolicyHolderFields;
