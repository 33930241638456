import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { DateObject } from 'react-multi-date-picker';
import { faCircleUser, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forEach } from 'lodash';
import { Formik } from 'formik';
import AppointmentSlotGroupCard from './AppointmentSlotGroupCard';
import { dateStringToDate } from '../../common/utils';
import {
  DatePickerField,
  TextField,
} from '../../common/components/Forms';
import { EditTestConfigurations } from './EditServiceSpecifications';
import { saveFamilyAndRedirectToRegistration } from '../helpers/requests';

const validate = (values) => {
  const errors = {};

  forEach(values.participants, (participant, index) => {
    const participantErrors = {};

    if (!participant.first_name) {
      participantErrors.first_name = 'Enter a valid first name';
    }

    if (!participant.last_name) {
      participantErrors.last_name = 'Enter a valid last name';
    }

    if (!participant.date_of_birth) {
      participantErrors.date_of_birth = 'Enter a valid date of birth';
    }

    if (Object.keys(participantErrors).length > 0) {
      errors.participants = errors.participants || [];
      errors.participants[index] = participantErrors;
    }
  });

  return errors;
};

const AddFamilyMembers = ({ state, dispatch, t }) => {
  const defaultParticipantForm = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    test_configuration_ids: [],
  };

  const selectedAppointmentSlotsById = state.formState.selectedAppointmentSlotsById;

  useEffect(() => {
    dispatch({
      type: 'updateForm',
      values: { participants: [defaultParticipantForm] },
    });
  }, []);

  useEffect(() => {
    saveFamilyAndRedirectToRegistration(state, dispatch)
  }, [selectedAppointmentSlotsById]);

  const handleAddFields = (values, setValues) =>
    setValues({
      participants: [...values.participants, defaultParticipantForm],
    });

  const handleRemoveFields = (index, values, setValues) => {
    const newValues = [...values.participants];
    newValues.splice(index, 1);
    setValues({ participants: newValues });
  };

  const onSubmit = (values) => {
    const appointmentSlotGroup = state.formState.appointmentSlotGroup;
    if(appointmentSlotGroup.test_location.on_demand == true) {
      const appointmentSlotId = Object.values(appointmentSlotGroup.appointment_slots_by_dates).flatMap(asbd => asbd["appointment_slots"])[0].id;
      const qty = values.participants.length;
      const formValues = {participants: values.participants, selectedAppointmentSlotsById: {[appointmentSlotId]: qty}}

      dispatch({ type: 'updateForm', values: formValues });
    } else {
      dispatch({ type: 'updateForm', values });
      dispatch({ type: 'perform_navigation', action: 'next' });
    }
  };

  const testConfigurationOptions = useMemo(() => state.formState.appointmentSlotGroup.test_configurations.
    map(testConfiguration => ({
      key: testConfiguration.id,
      label: testConfiguration.display_name,
      calculateAgeInDays: testConfiguration.calculate_age_gating_in_days,
      minimumAgeInMonths: testConfiguration.minimum_age_in_months,
      maximumAgeInMonths: testConfiguration.maximum_age_in_months,
      minimumAgeInWeeks: testConfiguration.minimum_age_in_weeks,
      maximumAgeInWeeks: testConfiguration.maximum_age_in_weeks,
      minimumAgeInDays: testConfiguration.minimum_age_in_days,
      maximumAgeInDays: testConfiguration.maximum_age_in_days,
    })
  ),[]);

  const isFamilyRegistration = state.formState.registrationType === "family";

  return (
    <React.Fragment>
      <h3 className="mt-4 poppins semibold">
        {t('family_registrations.select_services')}
      </h3>
      <AppointmentSlotGroupCard
        appointmentSlotGroup={state.formState.appointmentSlotGroup}
        t={t}
      />
      {isFamilyRegistration &&
        <div>{t('family_registrations.add_family')}</div>
      }
      <Formik
        initialValues={{
          participants: state.formState.participants || [
            defaultParticipantForm,
          ],
        }}
        validate={validate}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          dirty,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            {values.participants.map((participant, index) => (
              <div key={index} className={index != 0 ? 'animate__animated animate__slideInUp animate__faster' : undefined}>
                <div className="my-4 d-flex">
                  {isFamilyRegistration &&
                    <div
                      className="lead-20-medium inter medium bold place-self-center text-secondary-400"
                    >
                      <FontAwesomeIcon icon={faCircleUser} />{' '}
                      {t('family_registrations.person', { number: index + 1 })}
                    </div>
                  }
                  {index !== 0 && (
                    <Button
                      variant="outline-light"
                      className="ms-auto remove-family-member"
                      onClick={() =>
                        handleRemoveFields(index, values, setValues)
                      }
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                  )}
                </div>
                <Row>
                  <Col xs={12} md={4}>
                    <TextField
                      id={`participants[${index}].first_name`}
                      name={`participants[${index}].first_name`}
                      label={t('instructions.name')}
                      placeholder={t('registration.first_name_label')}
                      value={participant.first_name}
                      setValue={handleChange}
                      autoComplete="off"
                      required
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <TextField
                      id={`participants[${index}].last_name`}
                      name={`participants[${index}].last_name`}
                      label="Last name"
                      labelClass="ag-opacity-zero d-none d-md-block"
                      placeholder={t('registration.last_name_label')}
                      value={participant.last_name}
                      setValue={handleChange}
                      autoComplete="off"
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <label className="form-label">{t('registration.date_of_birth')} *</label>
                    <div>
                      <DatePickerField
                        id={`date_of_birth_input_${index}`}
                        containerClass="w-100"
                        name="date_of_birth"
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        value={participant.date_of_birth}
                        onChange={(objectDate) =>
                          setFieldValue(
                            `participants[${index}].date_of_birth`,
                            objectDate?.format('YYYY-MM-DD'),
                          )
                        }
                        shadow={false}
                        maxDate={new DateObject()}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <EditTestConfigurations
                  onChange={(values) => {
                    setFieldValue(
                      `participants[${index}].test_configuration_ids`,
                      values,
                    )
                   } }
                  dateOfBirth={dateStringToDate(participant.date_of_birth)}
                  testConfigurations={testConfigurationOptions}
                  selectedTestConfigurations={
                    participant.test_configuration_ids
                  }
                  index={index}
                />
                {!!values.participants[index + 1] && <hr/>}
              </div>
            ))}
            {/* TODO: make below a common component */}
            {state.formState.participants?.length < 4 && isFamilyRegistration && (
              <Card
                className="pointer my-4"
                style={{ border: 'dashed 1px #DEE2E6' }}
                data-test-hook="add_new_fields"
                onClick={() => handleAddFields(values, setValues)}
              >
                <Card.Body
                  className="flex-center w-100 text-muted"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-1" />
                  <span className="text-secondary">{t('family_registrations.add_person')}</span>
                </Card.Body>
              </Card>
            )}
            <div className="d-flex flex-row justify-content-end mt-2">
              <Button
                className="d-none d-sm-block me-2"
                variant="link"
                onClick={() => dispatch({ type: 'perform_navigation', action: 'back' })}
                data-test-hook="back"
              >
                {t('otc.back_button')}
              </Button>
              <Button
                className="d-none d-sm-block"
                type="submit"
                data-test-hook="submit"
                disabled={!dirty || Object.keys(errors).length}
              >
                {t('otc.next_button')}
              </Button>
            </div>
            <div className="d-sm-none">
              <Button
                className="my-3"
                block
                variant="link"
                onClick={() => dispatch({ type: 'perform_navigation', action: 'back' })}
                data-test-hook="back"
              >
                {t('otc.back_button')}
              </Button>
              <Button
                block
                type="submit"
                data-test-hook="submit"
                disabled={!dirty || Object.keys(errors).length}
              >
                {t('otc.next_button')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AddFamilyMembers;
