import React from 'react';

const Home = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.283 13.7862H9.00629V10.2642H6.99371V13.7862H2.71698V8H0L8 0L16 8H13.283V13.7862ZM10.0126 12.7799H12.2767V6.99371H13.5849L8 1.4088L2.41509 6.99371H3.72327V12.7799H5.98742V9.25786H10.0126V12.7799Z"
      fill={color}
    />
  </svg>
);

export default Home;
