import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNavigation } from '../../common/components/Navigation';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import { Button } from 'react-bootstrap';
import HelperText from '../../common/components/Forms/components/ErrorHelperText';
import { PHONE_NUMBER_REGEX_VALIDATOR } from '../../common/utils/validators';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #f7f9fc;
  padding: 1.5rem;

  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  :has(.consultation-confirmed) {
    background-color: #eefbf9;
  }

  &.has-pending-acknowledgement.focus {
    ::before {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;

      width: 280px;
      min-height: 40px;

      background: #08173a;
      border-radius: 4px;
      color: white;
      content: 'Please acknowledge your results';
      position: absolute;
      right: calc(100% + 1rem);
      top: 1rem;
      line-height: 150%;
    }

    box-sizing: border-box;
    border: 1px solid #2862fa;
  }

  h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #1a202c;
  }

  .user-phone-number {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #1a202c;
  }

  .edit-phone-number {
    input {
      max-width: 240px;
    }
    .btn {
      min-width: unset;
      width: 50px;
      height: 50px;
    }
  }

  .consultation-confirmed {
    p {
      margin-bottom: 0;
    }
  }

  .btn-outline-tertiary {
    margin-top: 1rem;
    margin-left: auto;
  }
`;

const ConfirmContactDetails = ({ isParent, state: { user }, dispatch, t }) => {
  const [editing, isEditing] = useState(false);
  const [phoneNumber, _setPhoneNumber] = useState(user.phone_number);
  const [hasError, setHasError] = useState(false);

  const onUpdatePhoneNumber = () => {
    if (!PHONE_NUMBER_REGEX_VALIDATOR.test(phoneNumber)) {
      setHasError(true);
    } else {
      dispatch({
        type: 'update_user',
        values: { phone_number: phoneNumber },
      });
      isEditing(false);
    }
  };

  const setPhoneNumber = (value) => {
    setHasError(false);
    _setPhoneNumber(value);
  };

  return (
    <React.Fragment>
      <h6>{t('result_page.contact_provider.confirm_phone_number_header')}</h6>
      <p>
        {t('result_page.contact_provider.confirm_phone_number_description')}
      </p>
      <div className="mt-2">
        {!editing ? (
          <div
            className="d-flex flex-row align-items-center"
            style={{ gap: 12 }}
          >
            <div className="user-phone-number">{user.phone_number}</div>
            <FontAwesomeIcon
              className="pointer text-primary"
              icon="fa-regular fa-pen-to-square"
              onClick={() => isEditing(true)}
            />
          </div>
        ) : (
          <React.Fragment>
            <div
              className="edit-phone-number d-flex flex-row align-items-center"
              style={{ gap: 6 }}
            >
              <input
                type="text"
                className="form-control"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && onUpdatePhoneNumber()}
              />
              <Button onClick={onUpdatePhoneNumber}>
                <FontAwesomeIcon icon="fa-sharp fa-solid fa-check" />
              </Button>
            </div>
            {hasError && <HelperText text="Invalid phone number" isError />}
          </React.Fragment>
        )}
      </div>
      {isParent && (
        <Button
          variant="outline-tertiary"
          type="button"
          onClick={() =>
            dispatch({
              type: 'perform_navigation',
              action: 'confirm',
            })
          }
        >
          {t('result_page.contact_provider.confirm_button_text')}
        </Button>
      )}
    </React.Fragment>
  );
};

const YesNoOptions = ({ isParent, ...props }) => {
  const {
    state: { acknowledgement_status },
    dispatch,
    t,
  } = props;

  return (
    <React.Fragment>
      <h6>{t('result_page.contact_provider.header')}</h6>
      <p>{t('result_page.contact_provider.description')}</p>
      <FabrxCheckbox
        label={t('result_page.contact_provider.yes_option_text')}
        checked={acknowledgement_status === 'consultation_requested'}
        onChange={() =>
          dispatch({
            type: 'update_acknowledgement',
            value: 'consultation_requested',
          })
        }
        radio
      />
      <FabrxCheckbox
        label={t('result_page.contact_provider.no_option_text')}
        checked={acknowledgement_status === 'acknowledged'}
        onChange={() =>
          dispatch({
            type: 'update_acknowledgement',
            value: 'acknowledged',
          })
        }
        radio
      />
      {acknowledgement_status === 'consultation_requested' && (
        <div className="mt-4">
          <ConfirmContactDetails {...props} />
        </div>
      )}
      {acknowledgement_status === 'acknowledged' && (
        <p style={{ color: '#314677' }}>
          {t('result_page.contact_provider.acknowledgement_option_helper_text')}
        </p>
      )}
      {acknowledgement_status !== 'pending' && (
        <Button
          variant="outline-tertiary"
          type="button"
          onClick={() =>
            dispatch({
              type: 'perform_navigation',
              action:
                acknowledgement_status === 'consultation_requested'
                  ? 'yes'
                  : 'no',
            })
          }
        >
          {t('result_page.contact_provider.confirm_button_text')}
        </Button>
      )}
    </React.Fragment>
  );
};

const ConsultationConfirmed = ({ state: { user }, t }) => (
  <div className="consultation-confirmed">
    <h6>{t('result_page.contact_provider.consultation_confirmed_header')}</h6>
    <p>
      {t('result_page.contact_provider.consultation_confirmed_description', {
        phone_number: user.phone_number.replace(
          PHONE_NUMBER_REGEX_VALIDATOR,
          '$1 ($2$3) $4-$5',
        ),
      })}
    </p>
  </div>
);

const AcknowledgementConfirmed = ({ dispatch, t }) => (
  <React.Fragment>
    <h6>
      {t('result_page.contact_provider.acknowledgement_confirmed_header')}
    </h6>
    <p>
      {t('result_page.contact_provider.acknowledgement_confirmed_description')}
    </p>
    <p>
      {t(
        'result_page.contact_provider.acknowledgement_confirmed_change_mind_text',
      )}
    </p>
    <Button
      variant="outline-tertiary"
      type="button"
      onClick={() => {
        dispatch({
          type: 'update_acknowledgement',
          value: 'consultation_requested',
        });
        dispatch({
          type: 'perform_navigation',
          action: 'request_consultation',
        });
      }}
    >
      {t('result_page.contact_provider.request_consultation_button_text')}
    </Button>
  </React.Fragment>
);

const NAVIGATION_MAP = {
  yes_no_options: {
    Component: YesNoOptions,
    navigationActions: {
      yes: 'consultation_confirmed',
      no: 'acknowledgement_confirmed',
    },
  },
  confirm_contact_details: {
    Component: ConfirmContactDetails,
    navigationActions: {
      confirm: 'consultation_confirmed',
    },
  },
  acknowledgement_confirmed: {
    Component: AcknowledgementConfirmed,
    navigationActions: {
      request_consultation: 'confirm_contact_details',
    },
    hasAcknowledgement: true,
    beforeEnterAsync: ({
      recordAcknowledgement,
      acknowledgement_status,
      test_strip,
    }) => recordAcknowledgement(test_strip, acknowledgement_status),
  },
  consultation_confirmed: {
    Component: ConsultationConfirmed,
    hasAcknowledgement: true,
    beforeEnterAsync: ({
      recordAcknowledgement,
      acknowledgement_status,
      test_strip,
      user,
    }) => recordAcknowledgement(test_strip, acknowledgement_status, user),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_acknowledgement':
      return {
        ...state,
        acknowledgement_status: action.value,
      };
    case 'update_user':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.values,
        },
      };
    default:
      return state;
  }
};

const RequestConsultationFlow = ({
  recordAcknowledgement,
  testStripId,
  acknowledgementStatus = 'pending',
  user,
}) => {
  const { t } = useTranslation();
  const { state, dispatch, currentNode } = useNavigation(
    NAVIGATION_MAP,
    acknowledgementStatus === 'acknowledged'
      ? 'acknowledgement_confirmed'
      : acknowledgementStatus === 'consultation_requested'
      ? 'consultation_confirmed'
      : 'yes_no_options',
    {
      acknowledgement_status: acknowledgementStatus,
      user,
      recordAcknowledgement,
      test_strip: {
        id: testStripId,
      },
    },
    reducer,
  );

  useEffect(() => {
    console.log('Changed!!!');
  }, [recordAcknowledgement]);

  const pendingAcknowledgement = !currentNode.hasAcknowledgement;

  return (
    <Container
      className={pendingAcknowledgement ? 'has-pending-acknowledgement' : ''}
    >
      <currentNode.Component {...{ state, dispatch, t, isParent: true }} />
    </Container>
  );
};

export default RequestConsultationFlow;
