import React, { useState, useContext, useCallback, useRef } from 'react';
import { Button, Image, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OtcContext from '../contexts/OtcContext';
import PhotoUploader from './PhotoUploader';
import Webcam from 'react-webcam';
import ErrorMessage from '../../common/components/ErrorMessage';
import { verifyIfPhone, verifyIfSpecificDevice } from './OtcFlow';


const OtcPhoto = () => {
  const {
    t,
    setNextButtonDisabled,
    showWebCam,
    setShowWebCam,
    testImageData,
    setTestImageData,
    flow,
    setError,
    activeErrors,
    stepNumber,
    testConfiguration,
  } = useContext(OtcContext);

  const webcamRef = useRef(null);
  const [cameraView, setCameraView] = useState("environment");
  const [showExample, setShowExample] = useState(false);
  const usePhonesUploadOptions = verifyIfSpecificDevice(["iPhone", "iPad", "Android"]);

  const capture = useCallback(
    () => {
      const imageData = webcamRef.current.getScreenshot();
      setTestImageData(imageData);
      setNextButtonDisabled(false);
    },
    [webcamRef]
  );

  const resultsSchemaExampleImage = () =>
    testConfiguration.results_schema?.otc?.images?.example;

  const exampleImageUrl =
    resultsSchemaExampleImage() ||
    (flow === 'self_checkouts' && '/images/abbott/abbott_example.png');

  const viewExampleButton =
    resultsSchemaExampleImage() || flow === 'self_checkouts';

  const [file, setFile] = useState(null);
  const fileName = file ? file["name"] : null;
  const fileType = testImageData
    ? testImageData.split(";")[0] === "data:application/pdf"
      ? "pdf"
      : null
    : null

  return (
    <div>
      <h4>{stepNumber}. {t("otc.take_result_photo")}</h4>
      <p>{t("otc.take_result_photo_description")}</p>

      {viewExampleButton && (
        <Button
          size="sm"
          className="mb-4 otc-button"
          variant="link"
          onClick={() => {
            setShowExample(!showExample);
          }}
        >
          {!showExample
            ? (<span>{t("otc.show_example")}  <FontAwesomeIcon size="md" icon="angle-down" color="#2862FA" /></span>)
            : (<span>{t("otc.hide_example")}  <FontAwesomeIcon size="md" icon="angle-up" color="#2862FA" /></span>)
          }
        </Button>
      )}

      {showExample && <Image src={exampleImageUrl} className="mb-4" />}

      <div className="mb-2 rounded py-3">
        {(testImageData || showWebCam) && (
          <div className="mb-2" style={{fontSize: "12px"}}>{t("otc.my_result")}</div>
        )}
        <div className="text-center mb-3">

          {!showWebCam && (
            <div
              data-test-hook="addphoto"
              style={{ height: "400px", maxWidth: "400px", border: "1px solid #CED4DA", borderRadius: "3px", backgroundColor: "#F8F8F8", cursor: "pointer" }}
              className="center"
              onClick={() => {
                usePhonesUploadOptions
                  ? document.getElementById("file-upload-trigger").click()
                  : setShowWebCam(!showWebCam)
              }}
            >
              <FontAwesomeIcon className="mt-17" icon={"camera"} size="6x" color="#2862FA" />
              <div style={{ color: "var(--bs-primary)" }} className="mt-2">
                {usePhonesUploadOptions ? t("otc.add_image") : t("otc.take_photo")}
              </div>
            </div>
          )}

          {showWebCam && !testImageData && (
            <div className="camera-wrapper mb-4">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  width: 400,
                  height: 400,
                  facingMode: cameraView
                }}
                style={{ cursor: "pointer", borderRadius: "10px" }}
                onClick={() => capture()}
              />
              <div className="clearfix"></div>
            </div>
          )}

          {showWebCam && testImageData && (
            fileType === "pdf" ? (
              <div className="my-2">
                <FontAwesomeIcon icon={"file-pdf"} size="2x" color="#0074d9" />{" "}
                <span><b>{fileName}</b></span>
              </div>
            ) : (
              <img src={testImageData} className="img-fluid" style={{borderRadius: "10px"}} />
            )
          )}

          {showWebCam && !usePhonesUploadOptions && (
            <Row>
              <Col>
                <Button
                  block
                  variant="primary"
                  className="mt-4 otc-button"
                  data-test-hook="capturephoto"
                  size={verifyIfPhone() ? "sm" : "lg"}
                  onClick={() => {
                    testImageData ? setTestImageData(null) : capture();
                  }}
                >
                  <FontAwesomeIcon icon={"camera"} color="#2862FA" />
                  <span className="ms-2">{(testImageData && fileType !== "pdf") ? "Retake photo" : "Capture photo"}</span>
                </Button>
              </Col>
              {!testImageData && verifyIfPhone() && (
                <Col>
                  <Button
                    block
                    variant="outline-primary"
                    className="mt-4 otc-button"
                    size="sm"
                    onClick={() => {
                      cameraView === "user" ? setCameraView("environment") : setCameraView("user")
                    }}
                  >
                    <FontAwesomeIcon icon={"sync"} color="#2862FA" />
                    <span className="ms-2">{t("otc.switch_camera")}</span>
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>

      <div className="text-center">
        {!usePhonesUploadOptions && (
          <div>
            <div>{t("otc.or")}</div>
            <br />
          </div>
        )}
        <PhotoUploader
          primaryTitle={t("otc.upload")}
          secondaryTitle={t("otc.change_image")}
          imageData={testImageData}
          setImageData={setTestImageData}
          setShowWebCam={setShowWebCam}
          setNextButtonDisabled={setNextButtonDisabled}
          setError={setError}
          setFile={setFile}
          hidePrimaryButton={usePhonesUploadOptions && !testImageData}
        />
      </div>

      {activeErrors["file_too_large"] && (
        <ErrorMessage
          className="my-3"
          message={activeErrors["file_too_large"]}
        />
      )}

    </div>
  )
}

export default OtcPhoto;
