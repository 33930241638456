import { Dispatch, Reducer, useReducer } from 'react';
import {
  currentLocationWithQueryParams,
  filtersFromQueryParams,
} from '../helpers/utils';

export type ValueType = string | number;

interface FilterOptionBase {
  paramKey: string;
  paramLabel: string;
}

export interface FilterOption extends FilterOptionBase {
  numericValue?: boolean;
  multiValue?: boolean;
  hide?: boolean;
  valueToLabel?: (value: ValueType) => string;
}

interface FilterOptionMap {
  [key: string]: FilterOption;
}

export type FilterValue = ValueType | ValueType[];

export interface FilterMap {
  [key: string]: FilterValue;
}

export interface DisplayFilter extends FilterOptionBase {
  value: FilterValue;
  label: string;
  onRemove: () => void;
}

export interface State {
  options: FilterOptionMap;
  filters: FilterMap;
}

type SetFilterAction = {
  type: 'SET_FILTER';
  paramKey: string;
  value: ValueType;
};

type RemoveFilterAction = {
  type: 'REMOVE_FILTER';
  paramKey: string;
  key?: number;
};

type ResetFiltersAction = {
  type: 'RESET_FILTERS';
};

type Action = SetFilterAction | RemoveFilterAction | ResetFiltersAction;

export type SetFilter = (paramKey: string, value: FilterValue) => void;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FILTER': {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.paramKey]: action.value,
        },
      };
    }
    case 'REMOVE_FILTER': {
      const filterOption = state.options[action.paramKey];
      if (filterOption.multiValue) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.paramKey]: (
              state.filters[action.paramKey] as ValueType[]
            ).filter((_, key) => key !== action.key),
          },
        };
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.paramKey]: null,
          },
        };
      }
    }
    case 'RESET_FILTERS':
      return {
        options: state.options,
        filters: {},
      };
    default:
      return state;
  }
};

const toDisplayFilterArr = (
  option: FilterOption,
  value: FilterValue,
  dispatch: Dispatch<Action>,
): DisplayFilter[] => {
  const { paramKey, paramLabel } = option;

  return option.multiValue
    ? ((value || []) as ValueType[]).map((filterValue, idx) => ({
        paramKey,
        paramLabel,
        value: filterValue,
        label: option.valueToLabel && option.valueToLabel(filterValue),
        onRemove: () => dispatch({ type: 'REMOVE_FILTER', paramKey, key: idx }),
      }))
    : [
        {
          paramKey,
          paramLabel,
          value: value as ValueType,
          label: option.valueToLabel && option.valueToLabel(value as ValueType),
          onRemove: () => dispatch({ type: 'REMOVE_FILTER', paramKey }),
        },
      ];
};

const getDisplayFilters = (
  state: State,
  dispatch: Dispatch<Action>,
  key?: string,
): DisplayFilter[] => {
  if (key) {
    const filterOption = state.options[key];
    const value = state.filters[key];

    return toDisplayFilterArr(filterOption, value, dispatch);
  } else {
    return Object.entries(state.filters)
      .filter(([paramKey, value]) => value && !state.options[paramKey].hide)
      .map(([paramKey, value]) => {
        const filterOption = state.options[paramKey];

        return toDisplayFilterArr(filterOption, value, dispatch);
      })
      .flat();
  }
};

const filterOptionMapFromArray = (
  filterOptions: FilterOption[],
): FilterOptionMap =>
  filterOptions.reduce((acc, filterOption) => {
    acc[filterOption.paramKey] = filterOption;
    return acc;
  }, {});

export type GetDisplayFilters = (key?: string) => DisplayFilter[];

export type RemoveFilter = (paramKey: string) => void;

const useFilters = (
  filterOptions: FilterOption[],
): {
  filters: FilterMap;
  getDisplayFilters: GetDisplayFilters;
  getPathWithParams: () => string;
  setFilter: SetFilter;
  removeFilter: RemoveFilter;
  resetFilters: () => void;
} => {
  const initialState = {
    options: filterOptionMapFromArray(filterOptions),
    filters: filtersFromQueryParams(filterOptions),
  };

  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState,
  );

  return {
    filters: state.filters,
    getDisplayFilters: (key?: string) =>
      getDisplayFilters(state, dispatch, key),
    getPathWithParams: () => currentLocationWithQueryParams(state),
    setFilter: (paramKey: string, value: ValueType) =>
      dispatch({ type: 'SET_FILTER', paramKey, value }),
    removeFilter: (paramKey: string) =>
      dispatch({ type: 'REMOVE_FILTER', paramKey }),
    resetFilters: () => dispatch({ type: 'RESET_FILTERS' }),
  };
};

export default useFilters;
