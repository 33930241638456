import React, { useContext, useEffect } from 'react';
import RegistrationContext from '../../contexts/RegistrationContext';
import Verify from "../../../ContactVerification/Verify";
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../SectionComponents';

export const validateVerifyContactInformation = async (localFormState) => {
  const errors = {}
  if (localFormState.validateContactInformationCode?.length <= 0) {
    errors.verify_contact_information = 'registration.errors.verify_contact_information.too_short';
  }
}

export const onAfterStep = (values, context) => {
  return new Promise((resolve, reject) => {
    context.localFormState.validateContactInformationCode;
    resolve(true);
  })
}

const VerifyContactInformation = ({
  values,
  setFieldValue,
}) => {
  const {
    localFormState,
    setLocalFormState,
    currentRegistrationStep,
  } = useContext(RegistrationContext);
  const emailOnly = values.email && values.preferred_method_of_communication == "email"
  const phoneOnly = values.phone_number && values.preferred_method_of_communication == "phone_number"
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!values.has_default_contact && !values.contact_verification_id) {
      setLocalFormState({
        ...localFormState,
        continueKey: {...localFormState.continueKey, [currentRegistrationStep]: "registration.skip_for_now"},
      });
    }
  }, []);

  return (
    <div className="form-section">
      <SectionTitle className="mb-4">{ t('registration.verify_contact_information') }</SectionTitle>
      <Verify
        onVerify={(contactVerificationId) => {
          setLocalFormState({
            ...localFormState,
            continueKey: {...localFormState.continueKey, [currentRegistrationStep]: 'registration.continue_button'}
          })
          setFieldValue('contact_verification_id', contactVerificationId)
        }}
        email={phoneOnly ? null : values.email}
        phoneNumber={emailOnly ? null : values.phone_number}
        verified={values.contact_verification_id || values.has_default_contact}
      />
    </div>
  );
};

export default VerifyContactInformation;
