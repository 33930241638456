import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns-tz";

import { DeleteCustomMessageTemplateModal } from './MessageTemplateForm';
import MessagesFiltersBar from "./MessagesFiltersBar";

const TemplateCard = ({
  button,
  children,
  className,
}) => {
  return (
    <div
      style={{
        width: "375px",
      }}
    >
      <Card
        className={`p-4 ${className}`}
        style={{
          border: "0px",
          borderRadius: "15px",
          boxShadow: "1px 1px 5px 1px rgba(33, 37, 41, 0.1)",
          height: "265px",
        }}
      >
        {children}
        <div className="d-flex justify-content-end mt-auto">
          {button}
        </div>
      </Card>
    </div>
  );
};

const MessageTemplates = ({
  communications_props,
}) => {

  const [templates, setTemplates] = useState([]);
  const [deleteCustomMessageTemplateModalOpen, setDeleteCustomMessageTemplateModalOpen] = useState(false);
  const [selectedMessageTemplateId, setSelectedMessageTemplateId] = useState(false);

  const fetchTemplates = async () => {
    const response = await axios.get("/custom_message_templates.json");
    setTemplates(response.data)
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const newTemplateButton = (
    <Button 
      href={"custom_message_templates/new"}
      size="sm"
      variant="outline-secondary"
    >
      Add new template
      <FontAwesomeIcon
        className="ms-2"
        icon="fa-regular fa-plus"
      />
    </Button> 
  );

  return (
    <div className="m-3">
      <DeleteCustomMessageTemplateModal
        backButtonText="Back to templates"
        deleteCustomMessageTemplateModalOpen={deleteCustomMessageTemplateModalOpen}
        setDeleteCustomMessageTemplateModalOpen={setDeleteCustomMessageTemplateModalOpen}
        templateId={selectedMessageTemplateId}
      />
      <MessagesFiltersBar
        organizationFilterOptions={communications_props.filter_options.organization_filter_options}
        testGroupFilterOptions={communications_props.filter_options.test_group_filter_options}
        type={"templates"}
      />
      <div className="d-flex justify-content-start my-2">
        <span className="inter bold my-auto me-2">{templates.length} total results</span>
        <Button
          data-bs-target="#filterSideBar"
          data-bs-toggle="offcanvas"
          size="sm"
          variant="outline-secondary"
        >
          Filter / Sort
          <FontAwesomeIcon
            className="ms-1"
            icon="fa-solid fa-sliders-simple"
          />
        </Button>
      </div>

      <TemplateCard
        button={newTemplateButton}
        className="my-4"
      >
        <img
          className="pointer mx-2"
          height="160px"
          src="/images/stuck_at_home_to_do_list.svg"
        />
      </TemplateCard>
      <h4>Recent templates</h4>
      <div
        className="my-3 d-flex flex-row flex-wrap"
        style={{ gap: "20px" }}
      >
        {templates.map((t) => {
          const createdOrUpdatedAt = (
            <div className="body-small-regular inter medium">
              {t.created_at == t.updated_at ? "Created: " : "Edited: "}
              {format(new Date(t.updated_at), "MM/dd/yyyy")}
            </div>
          );
          const button = (
            <Button 
              href={`custom_message_templates/${t.id}`}
              size="sm"
            >
              View template
            </Button>
          );
          return (
            <TemplateCard
              button={button}
            >
              <div className="d-flex justify-content-between">
                <div className="inter bold mb-3">
                  {t.name}
                </div>
                <Button
                  className="me-2"
                  onClick={() => {
                    setSelectedMessageTemplateId(t.id);
                    setDeleteCustomMessageTemplateModalOpen(true);
                  }}
                  size="sm"
                  variant="link"
                >
                  <FontAwesomeIcon icon="fa-regular fa-trash-can" />
                </Button>
              </div>
              {createdOrUpdatedAt}
              <hr />
              <div className="text-truncate mb-2">
                {t.params?.email_subject}
              </div>
              <div className="truncate-after-two-lines">
                {t.params?.email_message}
              </div>
            </TemplateCard>
          )
        })}
      </div>
    </div>
  );
};

export default MessageTemplates;