import React, { useState } from "react";
import axios from "axios";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import MessageDetails from "./MessageDetails";
import MessagesFiltersBar from "./MessagesFiltersBar";
import MessagesList from "./MessagesList";

const Messages = ({
  communications_props,
}) => {

  const url = new URL(window.location.href);
  const [searchTerm, setSearchTerm] = useState(url.searchParams.get("q") || null);
  const [communications, setCommunications] = useState(communications_props.communications);
  const [selectedCommunicationId, setSelectedCommunicationId] = useState(communications[0]?.id);
  const selectedCommunication = communications.find(c => c.id == selectedCommunicationId);
  const [loadingCommunications, setLoadingCommunications] = useState(false);

  const fetchMoreCommunications = async () => {
    setLoadingCommunications(true);
    const response = await axios.get(`/messages.json`, {
      params: {
        fetched_communication_ids: communications.map(c => c.id),
        ...Object.fromEntries(
          new URL(window.location.href).searchParams.entries(),
        )
      }
    });
    setLoadingCommunications(false);
    if (response.status === 200) {
      setCommunications([...communications, ...response.data.communications_props.communications]);
    }
  };

  const noResultsFound = (
    <div className="horizontal-vertical-center">
      <div className="text-center">
        <img height={200} src="/images/icons/icon-search-not-found-2.svg"/>
        <h2 className="mt-5">
          There are no active <br/> messages
        </h2>
        <p>
          Create a new message to targeted audiences and <br/> track its reach now
        </p>
        <Button
          onClick={() => {
            window.location.href = location.protocol + "//" + location.host + location.pathname + "/new"
          }}
        >
          Create new message
        </Button>
      </div>
    </div>
  );

  return (
    <div className="m-3">
      <MessagesFiltersBar
        currentUserIsGod={communications_props.current_user_is_god}
        organizationFilterOptions={communications_props.filter_options.organization_filter_options}
        testGroupFilterOptions={communications_props.filter_options.test_group_filter_options}
      />
      <div className="d-flex justify-content-start my-2">
        <span className="inter bold my-auto me-2">{communications_props.filtered_communications_count} total results</span>
        <Button
          data-bs-target="#filterSideBar"
          data-bs-toggle="offcanvas"
          size="sm"
          variant="outline-secondary"
        >
          Filter / Sort
          <FontAwesomeIcon
            className="ms-1"
            icon="fa-solid fa-sliders-simple"
          />
        </Button>
      </div>
      {!communications.length ? (
        noResultsFound
      ) : (
        <Row>
          <Col>
            <FloatingLabelInput
              className="my-2"
              label="Search ongoing messages"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                url.searchParams.set("q", searchTerm);
                window.location.href = url.toString();
              }}
              value={searchTerm}
            />
            <MessagesList
              allCommunicationsFetched={communications.length === communications_props.filtered_communications_count}
              communications={communications}
              loadingCommunications={loadingCommunications}
              fetchMoreCommunications={fetchMoreCommunications}
              selectedCommunicationId={selectedCommunicationId}
              setSelectedCommunicationId={setSelectedCommunicationId}
            />
          </Col>
          <Col>
            <MessageDetails
              communications={communications}
              filterOptions={communications_props.filter_options}
              selectedCommunication={selectedCommunication}
              setCommunications={setCommunications}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Messages;