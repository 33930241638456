import React, { useState } from 'react';
import { SectionWrapper, SectionBody, SectionActions } from '../styled';
import SectionHeader from '../SectionHeader';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { PrimaryIcon } from '../../../common/components/Icons/PrimaryIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';

export const SHOW_APPOINTMENTS_STEP = "ShowAppointmentsStep";

const Appointment = ({appointment, onClick, selected}) => {
  return (
    <Card
      onClick={onClick}
      className="pointer"
      data-test-hook={`select_appointment_${appointment.access_code}`}
      style={selected ? {border: "1px solid #1125F6"} : {}}
    >
      <Card.Body style={{padding: 16}}>
        <Row>
          <Col xs="auto" className='my-1'>
            <div
              className='profile-circle mt-2'
              style={{
                backgroundColor: "#6544E0",
                width: 48,
                height: 48,
              }}
            >
              <div>
                {appointment.user.first_name[0].toUpperCase()}{appointment.user.last_name[0].toUpperCase()}
              </div>
            </div>
          </Col>
          <Col sm={3} xs="auto" className='my-1' style={{alignSelf: "center"}}>
            <div className="lead-20-medium">{appointment.user.first_name} {appointment.user.last_name[0]}</div>
            <div className="text-muted body-medium-normal">DOB {appointment.user.date_of_birth}</div>
          </Col>
          <Col xs="auto" className='my-1' style={{alignSelf: "center"}}>
            <div className="poppins body-medium-bold medium mb-2">{appointment.test_group.name}</div>
            <div className="text-muted card-caption text-truncate">
              <FontAwesomeIcon icon={faLocationDot} className="me-1" />
              {appointment.appointment_slot_group.address}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default function ShowAppointmentsStep ({ state: {formState, t, error}, createTestKit, dispatch }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <SectionWrapper>
      <Button variant="link" className='d-sm-none p-0 mb-2' onClick={() => dispatch({type: 'back'})}>{t('otc.back_button')}</Button> 
      <SectionHeader title={t('test_strip.appointments.title')} />
      <SectionBody>
        <div className='poppins pb-2 h3-24-semi-bold regular ls-small' style={{lineHeight: "154%"}}>
          {t('test_strip.appointments.p1')}
        </div>
        {error == "test_kit_not_created" && <div className="text-danger">{t('errors.generic.message')}</div>}
        {formState.appointments.map((appointment, idx) => (
          <div className="my-4" key={idx}>
            <Appointment
              appointment={appointment}
              onClick={() => setSelectedIndex(idx)}
              selected={selectedIndex === idx}
            />
          </div>
        ))}
        <div className="my-4">
          <Card
            className='pointer'
            style={selectedIndex == -1 ? {border: "1px solid #1125F6"} : {border: "dashed 1px #DEE2E6"}}
            onClick={() => setSelectedIndex(-1)}
          >
            <Card.Body style={{placeContent: "center"}} className='d-flex align-items-center w-100 text-muted text-center'>
              <PrimaryIcon icon="plus-circle" height={16} width={16} color="#717D96" className="me-1" />
              <span>{t('test_strip.appointments.new')}</span>
            </Card.Body>
          </Card>
        </div>
      </SectionBody>
      <SectionActions>
        <Button variant="link" className='d-none d-sm-block' onClick={() => dispatch({type: 'back'})}>{t('otc.back_button')}</Button> 
        <Button
          data-test-hook="submit"
          disabled={selectedIndex === null}
          onClick={() => {
            if (selectedIndex > -1) {
              createTestKit(formState.appointments[selectedIndex]) 
            } else {
              dispatch({type: 'newPatientProvider'})
            }
          }}
        >
          {t('otc.next_button')}
        </Button>
      </SectionActions>
    </SectionWrapper>
  );
}
