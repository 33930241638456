import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import CancelScheduledMessageModal from "./CancelScheduledMessageModal";
import DeliveryErrorModal from "./DeliveryErrorModal";
import { FilterBadges } from "./NewMessageScheduleDelivery";
import MessageMetrics from "./MessageMetrics";
import { MessageSummary } from "./MessagesItem";

const MessageDetails = ({
  communications,
  filterOptions,
  selectedCommunication,
  setCommunications,
}) => {

  const [showCancelScheduledMessageModal, setShowCancelScheduledMessageModal] = useState(false);
  const [showDeliveryErrorModal, setShowDeliveryErrorModal] = useState(false);

  const {
    delivered_via,
    id,
    failed_communication_task_count,
    params,
    pretty_repeating_interval,
    preview,
    recipient_count,
    repeating,
    start_date,
    started_by,
    started_by_initials,
  } = selectedCommunication;

  return (
    <div>
      <CancelScheduledMessageModal
        communication={selectedCommunication}
        communications={communications}
        onHide={() => setShowCancelScheduledMessageModal(false)}
        setCommunications={setCommunications}
        show={showCancelScheduledMessageModal}
      />
      <DeliveryErrorModal
        onHide={() => setShowDeliveryErrorModal(false)}
        selectedCommunicationId={id}
        show={showDeliveryErrorModal}
        subject={preview?.email_subject}
      />
      <div className="my-3">
        <h2 className="mb-4">{preview?.email_subject}</h2>
        <MessageSummary
          deliveredVia={delivered_via}
          justifyConten="justify-content-start"
          recipientCount={recipient_count}
          startedBy={started_by}
          startedByInitials={started_by_initials}
        />
        <Row className="mt-4 mb-3">
          <Col
            className="body-small-regular inter medium d-flex flex-column justify-content-center"
            xs={2}
          >
            Sent
          </Col>
          <Col className="body-medium-normal">
            {start_date}
            {pretty_repeating_interval && (
              <div className="body-medium-normal">
                ({pretty_repeating_interval})
              </div>
            )}
          </Col>
        </Row>
        <Row className="my-3">
          <Col
            className="body-small-regular inter medium d-flex flex-column justify-content-center"
            xs={2}
          >
            Sent to
          </Col>
          <Col className="body-medium-normal">
            <FilterBadges
              appliedFilters={params.filter_params}
              availableFilterOptions={filterOptions}
            />
          </Col>
        </Row>
      </div>

      <MessageMetrics
        failedCommunicationPresent={!!failed_communication_task_count}
        selectedCommunicationId={id}
        setShowDeliveryErrorModal={setShowDeliveryErrorModal}
      />

      <div className="my-5">
        <h5 className="lead-20-medium inter semibold">Email message</h5>
        <div
          className="body-medium-normal"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {preview?.email_message}
        </div>
      </div>

      <div className="my-5">
        <h5 className="lead-20-medium inter semibold">SMS message</h5>
        <div
          className="body-medium-normal"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {preview?.sms_message}
        </div>
      </div>

      {repeating && (
        <Button
          onClick={() => setShowCancelScheduledMessageModal(true)}
          size="sm"
          variant="outline-primary"
        >
          Cancel schedules message
        </Button>
      )}
    </div>
  );
};

export default MessageDetails;