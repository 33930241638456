import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import format from 'date-fns/format';

import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import SurveyPreview from "../../../common/components/SurveyPreviewV2";


const TestSurveyFormV2 = ({
  disabled,
  isInvalid=false,
  test,
  updateTest,
 }) => {

  const survey = (test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey
    : test.test_configuration.survey) || {};
  const surveyAnswers = (test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey_answers
    : test.test_survey) || { admin_approved: false };

  const [render, reRender] = useState(false);

  let localizedCompletedAt,
      localizedAdminApprovedAt;
  const surveyCompletedAt = surveyAnswers.created_at;
  const surveyAdminApprovedAt = surveyAnswers.admin_approved_at;
  if (surveyCompletedAt) localizedCompletedAt = format(new Date(surveyCompletedAt), 'MMMM dd, yyyy HH:mm:ss');
  if (surveyAdminApprovedAt) localizedAdminApprovedAt = format(new Date(surveyAdminApprovedAt), 'MMMM dd, yyyy HH:mm:ss');

  return (
    <>
      {!!localizedCompletedAt && (
        <div className="my-3">Completed by participant {localizedCompletedAt}</div>
      )}
      {Object.keys(survey).length > 0 &&
        <SurveyPreview
          json={survey}
          data={surveyAnswers.answers || surveyAnswers.survey}
          onValueChanged={(e)=> {
            reRender(!render);
            if (!disabled){
              test.use_medical_screening_survey_on_checkout
                ? updateTest({...test, saved: false, medical_screening_survey_answers: {...surveyAnswers, answers: e.data}})
                : updateTest({...test, saved: false, test_survey: {...surveyAnswers, survey: e.data}})
            }
          }}
        />
      }
      <Card
        body className="my-3"
        style={{
          border: isInvalid ? "1px solid #CB2531" : "1px solid #DEE2E6",
        }}
      >
        {!surveyAnswers.admin_approved && (
          <div
            className="d-flex p-2 my-3"
            style={{
              height: "51px",
              width: "343px",
              borderRadius: "12px",
              backgroundColor: isInvalid ? "#FFDDE4" : "#E9F1FB",
            }}
          >
            <FontAwesomeIcon className="my-auto me-2" icon={faCircleInfo} color={isInvalid ? "#CB2531" : "#2862FA"} style={{ height: "22px", width: "22px" }} />
            <div className="my-auto">Clinician review required</div>
          </div>
        )}
        <div className="font-weight-bold my-2 mb-3">Please confirm all Participant Screening responses are complete</div>
        <FabrxCheckbox
          name="screening_completed"
          onChange={() => {
            reRender(!render);
            test.use_medical_screening_survey_on_checkout
              ? updateTest({ ...test, saved: false, medical_screening_survey_answers: { ...surveyAnswers, admin_approved: !surveyAnswers.admin_approved }})
              : updateTest({ ...test, saved: false, test_survey: { ...surveyAnswers, admin_approved: !surveyAnswers.admin_approved }})
          }}
          checked={surveyAnswers.admin_approved}
          label="Participant screening responses are complete"
        />
        {localizedAdminApprovedAt && (
          <div className="my-2">Confirmed by clinician {localizedAdminApprovedAt}</div>
        )}
      </Card>
    </>
  );
};

export default TestSurveyFormV2;