import React, { useContext, useEffect } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import HelpText from '../../../../common/components/HelpText';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import { PersonalFieldDropdown } from '../../SectionComponents';

const SelectLanguage = ({
  attributeString="",
  context = RegistrationContext,
  error=false,
  handleChange,
  setFieldValue,
  values,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState } = useContext(context);

  const otherOption = {value: "other", label: "Other"}

  const languageOptions = Object.keys(testGroup.languages).map(languageKey => ({
      value: languageKey,
      label: testGroup.languages[languageKey],
  })).concat(otherOption);
  const label = buildLocalizedString(testGroup, 'preferred_language_label');
  const subtitle = buildLocalizedString(testGroup, 'preferred_language_subtitle');
  const helpText = buildLocalizedString(testGroup, 'preferred_language_help_text');

  useEffect(() => {
    if (context == RegistrationContext) setFieldValue(attributeString + 'language_preference', "en");
  },[])

  return (
    <PersonalFieldDropdown>
      <div className="mb-2">
        <div className="text-muted">
          { subtitle && <div className="d-inline-block"><LocalizedMarkdown container={testGroup} stringKey='preferred_language_subtitle' /></div> }
          {' '}
          { helpText && <HelpText text={ <LocalizedMarkdown container={testGroup} stringKey='preferred_language_help_text' /> } /> }
        </div>

        <FloatingLabelSelect
          value={localFormState.describeLanguagePreference ? [otherOption] : (
            languageOptions.filter(option => option.value === values.language_preference)
          )}
          onChange={(s) => {
            setFieldValue(attributeString + 'language_preference', s.value)
            setLocalFormState({...localFormState, describeLanguagePreference: s.value === "other"})
          }}
          name={attributeString + "language_preference_select"}
          options={languageOptions}
          containerClassName="mt-3"
          filledValue={values.language_preference}
          label={label ? label : t('preferred_language.title') + (testGroup.required_attributes.preferred_language ? ' *' : '')}
          className={error && 'is-invalid'}
        />
        {localFormState.describeLanguagePreference &&
          <div className="my-2">
            <FloatingLabelInput
              value={values.language_preference === "other" ? "" : values.language_preference}
              onChange={handleChange}
              className="mt-4"
              name={attributeString + "language_preference"}
              label={t('preferred_language.title')}
            />
          </div>
        }
      </div>
    </PersonalFieldDropdown>
  );
};

export default SelectLanguage;
