import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const StepTen = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mt-3 py-3 instruction-sub-step-title">
        {t('public_test_groups.demo.instructions.step_ten_title')}
      </h3>
      <ReactMarkdown
        source={t('public_test_groups.demo.instructions.step_ten_text_1')}
      />
      <div
        style={{
          border: '1px solid #DADADA',
          borderRadius: 5,
          height: 285,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#FFFFFF",
        }}
      >
        <div>
          <Image src="/images/public_test_groups/instructions/step_ten.svg" />
        </div>
      </div>
    </div>
  );
};

export default StepTen;
