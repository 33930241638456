import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import ModalHeader from '../../common/components/ModalHeader';
import { assembleUrl } from './TestGroupUserCard';

const AssignAppointmentSlotGroupModal = ({
  show,
  onHide,
  customUrl,
  testGroupUser,
  appointmentSlotGroupOptions: propAppointmentSlotGroupOptions,
}) => {
  const appointmentSlotGroupOptions =
    propAppointmentSlotGroupOptions ||
    testGroupUser.test_group.appointment_slot_groups
      .map((location) => ({
        label: location.title,
        value: location.id,
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  const submissionLink = testGroupUser.links.create_on_demand_appointment_url;
  const [assignedAppointmentSlotGroup, setAssignedAppointmentSlotGroup] =
    useState(testGroupUser.assigned_appointment_slot_group_id);

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2">Select Location for Participant</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        Available Locations
        <Select
          value={appointmentSlotGroupOptions.filter(
            (option) => option.value === assignedAppointmentSlotGroup,
          )}
          onChange={(s) => setAssignedAppointmentSlotGroup(s.value)}
          options={appointmentSlotGroupOptions}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          href={
            assignedAppointmentSlotGroup &&
            assembleUrl(
              customUrl || submissionLink,
              `assigned_appointment_slot_group_id=${assignedAppointmentSlotGroup}`,
            )
          }
          data-method={assignedAppointmentSlotGroup && 'post'}
          disabled={!assignedAppointmentSlotGroup}
        >
          Save and Proceed to Checkout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignAppointmentSlotGroupModal;
