import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { SectionBody, SectionWrapper } from '../../components/styled';
import SupportModal from '../../../common/components/SupportModal';

export default function ContactSupport({
  isMobile,
  t,
  user,
  appointment,
  test_group,
  contactSupport,
}) {
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [supportFormSubmitted, setSupportFormSubmitted] = useState(false);

  const ContactSupportBody = ({
    title = 'test_strip.contact_support.title',
    textOne = 'test_strip.contact_support.text_1',
    textTwo = 'test_strip.contact_support.text_2',
  }) => (
    <SectionWrapper className="text-center">
      <SectionBody style={{ margin: '32px 0' }}>
        <h1 className="poppins regular mb-0 ls-small">{t(title)}</h1>
        <img
          src="/images/primary_balloon_with_string.svg"
          style={{ marginTop: '32px' }}
        ></img>
        <div
          className="poppins h3-24-semi-bold regular mb-3 ls-small"
          style={{ margin: '32px 0' }}
        >
          {t(textOne)}
        </div>
        <div
          className="poppins h3-24-semi-bold regular mb-3 ls-small"
          style={{ margin: '32px 0' }}
        >
          {t(textTwo)}
        </div>
      </SectionBody>
      <div
        className={`${isMobile && 'd-flex'}`}
        style={{ minHeight: 'min-content', padding: '12px 0' }}
      >
        {supportFormSubmitted ? (
          <Button
            block={isMobile}
            onClick={() =>
              (window.location.href = appointment.confirmation_link)
            }
          >
            {t('test_strip.contact_support_success.button_text')}
          </Button>
        ) : (
          <>
            <Button
              variant="outline-primary"
              block={isMobile}
              onClick={() => contactSupport(false)}
            >
              {t('otc.back_button')}
            </Button>
            <Button
              className="ms-3"
              block={isMobile}
              onClick={() => {
                setShowSupportModal(true);
              }}
            >
              {t('self_resulting.contact_support')}
            </Button>
          </>
        )}
      </div>
    </SectionWrapper>
  );

  if (supportFormSubmitted) {
    return (
      <ContactSupportBody
        title="test_strip.contact_support_success.title"
        textOne="test_strip.contact_support_success.text_1"
        textTwo="test_strip.contact_support_success.text_2"
      />
    );
  }

  return (
    <>
      <ContactSupportBody />
      <SupportModal
        setShowModal={setShowSupportModal}
        showModal={showSupportModal}
        user={user}
        appointment={appointment}
        testGroup={test_group}
        setSupportFormSubmitted={setSupportFormSubmitted}
      />
    </>
  );
}
