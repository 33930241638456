import React, { useContext } from 'react';
import FabrxCheckbox from '../../primary/FabrxCheckbox';
import PureButton from '../../../common/components/PureButton';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { Button } from 'react-bootstrap';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import isMobilePhone from 'validator/lib/isMobilePhone';

import LocalizedMarkdown from './fields/LocalizedMarkdown';
import SimpleConsentModal from './fields/SimpleConsentModal';
import ContactInformationFields from './fields/ContactInformationFields';
import PersonalInformationFields from './fields/PersonalInformationFields';
import { calculateAge } from '../../../common/utils/index';

import RegistrationContext from '../../contexts/RegistrationContext';

export const validateDependents = (testGroup) => (values) => {
  const errors = {};
  values.dependents_attributes.forEach((values, idx) => {
    if (testGroup.required_attributes.gender_identity && !values.gender_string) errors[`dependents_attributes[${idx}].gender_string`] = 'registration.errors.required';
    if (testGroup.required_attributes.sexual_orientation && !values.sexual_orientation) errors[`dependents_attributes[${idx}].sexual_orientation`] = 'registration.errors.required';
    if (testGroup.required_attributes.pronoun && !values.pronoun) errors[`dependents_attributes[${idx}].pronoun`] = 'registration.errors.required';
    if (testGroup.required_attributes.ethinicity && Object.keys(values.ethnicity_blob).length === 0 ) errors[`dependents_attributes[${idx}].ethnicity_blob`] = 'registration.errors.required';
    if (!values.first_name) errors[`dependents_attributes[${idx}].first_name`] = 'registration.errors.required';
    if (!values.last_name) errors[`dependents_attributes[${idx}].last_name`] = 'registration.errors.required';
    if (values.phone_number && !isMobilePhone(values.phone_number)) errors.phone_number = 'registration.errors.phone_number_invalid';
    if (!values.consent) errors[`dependents_attributes[${idx}].consent`] = 'registration.errors.required';

    if (values['date_of_birth_datetime(2i)'] && values['date_of_birth_datetime(3i)'] && values['date_of_birth_datetime(1i)']) {
      const dateOfBirthString = [values['date_of_birth_datetime(2i)'], values['date_of_birth_datetime(3i)'], values['date_of_birth_datetime(1i)']].join('-');
      const dateOfBirth = new Date(dateOfBirthString)
      const age = calculateAge(dateOfBirth);

      if (age < testGroup.minimum_age) {
        errors[`dependents_attributes[${idx}].date_of_birth`] = 'registration.errors.age_requirement'
      }
    } else {
      errors[`dependents_attributes[${idx}].date_of_birth`] = 'registration.errors.required'
    }
  });
  return errors;
}

const Dependents = ({values, errors, handleChange, setFieldValue}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, user, setUser } = useContext(RegistrationContext);
  //useEffect(); with no dependencies to render map
  return (
    <div className="form-section">
      <h1 className="h5 font-weight-bold"> {t('registration.household')} </h1>
      <div className="text-secondary mb-3"> {t('registration.household_p1')} </div>
      {
        values.dependents_attributes.map((d, idx) => {
          return (
            <div key={"dependent_attributes_" + idx}>
              <div className="mb-3 h5">{t('registration.household', { number: idx + 1 })}:</div>
              <ContactInformationFields
                attributeString= {`dependents_attributes[${idx}].`}
                errors= {errors}
                handleChange= {handleChange}
                values={values.dependents_attributes[idx]}
                setFieldValue= {setFieldValue}
              />
              <PersonalInformationFields
                attributeString={`dependents_attributes[${idx}].`}
                errors= {errors}
                handleChange={handleChange}
                values={values.dependents_attributes[idx]}
                setFieldValue= {setFieldValue}
              />
              <Button
                variant="link"
                data-bs-toggle="modal"
                data-bs-target="#consent-modal"
              >
                {t('registration.consent_to_terms')}
              </Button>
              <br/>
              <FabrxCheckbox
                name="dependents_consent"
                onChange={(checked) => {
                  setFieldValue(`dependents_attributes[${idx}].consent`, values.dependents_attributes[idx].consent ? !checked : checked)
                }}
                checked={values.dependents_attributes[idx].consent || false}
                label={t('registration.consent')}
              />
              {errors[`dependents_attributes[${idx}].consent`] && <ErrorMessage message={t(errors[`dependents_attributes[${idx}].consent`])} />}
              <hr/>
              <Button
                variant="link"
                className="mb-3"
                onClick={()=> {
                  setFieldValue('dependents_attributes', values.dependents_attributes.filter((object, index) => idx !== index ));
                }}
              >
                {t('registration.remove_dependent')}
              </Button>
            </div>
          );
        })
      }
      <div className="links mb-3">
        <PureButton
          dataTest="add_dependent"
          autoFocus={true}
          onClick={() => {
            setFieldValue('dependents_attributes', values.dependents_attributes.concat([{
              first_name: null,
              last_name: null,
              phone_number: null,
              phone_number_country_code: null,
              email: null,
              gender_string: null,
              ethnicity_blob: {},
              address_1: values.address_1,
              address_2: values.address_2,
              city: values.city,
              county: values.county,
              state: values.state,
              postal_code: values.postal_code,
              races: [],
              "date_of_birth_datetime(1i)": null,
              "date_of_birth_datetime(2i)": null,
              "date_of_birth_datetime(3i)": null,
              consent: null,
            }]))
          }}
        >
          {t("registration.add_dependent")}
        </PureButton>
      </div>
      <div className="text-muted">
        <LocalizedMarkdown container={testGroup} stringKey='household_privacy_policy' />
      </div>
      <SimpleConsentModal />
    </div>
  );
};

export default Dependents;
