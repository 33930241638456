import axios from 'axios';

const performSearch = (formState) => {
  const params = new URLSearchParams();
  params.append("address", formState.address)
  params.append("radius", formState.radius || 400)
  if (formState.organizationId) params.append("organization_id", formState.organizationId);
  const serviceSpecifications = Object.keys(formState.serviceSpecifications).
    filter(service => !!formState.serviceSpecifications[service]).
    join(",");
  params.append("service_specifications", serviceSpecifications);
  if (formState.startOn) params.append("start_on", formState.startOn.format("YYYY-MM-DD"));
  return axios.
    get(`/family_registrations/search_locations?${params.toString()}`)
}

export const findClinic = ({formState, ...state}) => {
  return performSearch(formState).
    then(response => ({
      ...state,
      formState: {
        appointmentSlotGroups: response.data.appointment_slot_groups,
        ...formState,
      },
    }));
}

export const findClinicAsync = async (state, dispatch, values) => {
  let formState = state.formState;
  if (values) {
    dispatch({ type: "updateForm", values });
    formState = {...formState, ...values}
  }
  dispatch({ type: "isLoading", value: true });
  const response =  await performSearch(formState);
  dispatch({ type: "updateForm", values: { appointmentSlotGroups: response.data.appointment_slot_groups }})

  dispatch({ type: "isLoading", value: false });
}

export const saveFamilyAndRedirectToRegistration = async (state, dispatch) => {
  dispatch({ type: "isLoading", value: true });
  let appointmentSlotIds = []
  const testGroupSlug = state.formState.appointmentSlotGroup.test_group.slug;

  Object.keys(state.formState.selectedAppointmentSlotsById).
    forEach(id => {
      const count = state.formState.selectedAppointmentSlotsById[id]
      for (let i = 0; i < count; i++) {
        appointmentSlotIds.push(id)
      }
    });

    // TODO: catch response if reserved slots are taken
  const response = await axios.post(
    `/test_groups/${testGroupSlug}/family_registrations/reserve_appointment_slots`,
    {
      appointment_slot_ids: appointmentSlotIds,
      participants: state.formState.participants,
      locale: state.locale
    }
  );
  window.location.href = response.data.redirect_url
}
