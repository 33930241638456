import React, { useContext } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { verifiedCheck } from '../../Participants/components/ParticipantCard';
import { faNotesMedical } from '@fortawesome/pro-regular-svg-icons';

export const assembleUrl = (url, param) =>
  url
    ? url.indexOf('?') !== -1
      ? `${url}&${param}`
      : `${url}?${param}`
    : `?${param}`;

const CheckoutButton = ({
  onDemand,
  user,
  links: {
    create_on_demand_appointment_url,
  },
  readyToCheckout,
  onClick: propOnClick,
}) => {
  const buildButtonProps = (href) =>
    propOnClick
      ? { onClick: () => propOnClick(href) }
      : { href: href, 'data-method': 'post' };

  const notReadyTooltip = (props) => (
    !readyToCheckout
      ? <Tooltip {...props}>This participant cannot be checked out until their required information is filled in.</Tooltip>
      : <span></span>
  );

  return onDemand ? (
    <OverlayTrigger overlay={notReadyTooltip}>
      <span>
        <Button
          style={{ minWidth: '100%' }}
          variant="outline-primary"
          disabled={!readyToCheckout}
          className="gray-if-disabled"
          {...buildButtonProps(create_on_demand_appointment_url)}
        >
          Checkout {user.full_name}
        </Button>
      </span>
    </OverlayTrigger>
  ) : null;
};

const TestGroupUserCard = ({
  is_global_search,
  on_demand_only,
  test_group_user,
  assignAppointmentSlotGroup,
}) => {
  const { test_group, user, links } = test_group_user;

  const createBadge = (type, message, link) => {
    return (
      <a href={link}>
        <span className={'badge bg-' + type}>{message}</span>
      </a>
    );
  };

  const createHoverBadge = (type, message, link, position) => {
    return (
      <OverlayTrigger
        placement={position}
        overlay={
          <Tooltip className="primary-tooltip">
            {message}: {user.missing_required_info.join(", ")}
          </Tooltip>
        }
      >
        <a href={link}><span className={"badge bg-" + type}>{message}</span></a>
      </OverlayTrigger>
    )
  };

  let appointment_link = links.participant_url;
  let waitlist_link = links.waitlist_url;
  if (is_global_search) {
    appointment_link = links.global_search.global_search_participant_url;
    waitlist_link = links.global_search.global_search_waitlist_url;
  }

  const hasMissingRequiredInfo = user.missing_required_info.length > 0;
  const readyToCheckout = user.ready_to_checkout;

  const isOnDemand =
    !['draft', 'archived'].includes(test_group.status) &&
    test_group.has_on_demand_location;

  return (
    <React.Fragment>
      <td>
        <a href={links.test_group_user_link}>{user.full_name}</a>
      </td>
      {is_global_search && <td>{test_group.name}</td>}
      <td>
        {user.date_of_birth} (age {user.age})
      </td>
      <td>
        {user.phone_number && (
          <div>
            {user.phone_formatted} {verifiedCheck(user.phone_verified)}
          </div>
        )}
        {user.email && (
          <div>
            {user.email} {verifiedCheck(user.email_verified)}
          </div>
        )}
      </td>
      <td>
        <div>Population: {test_group_user.population_name}</div>
        <div>Tags: {user.tags.map((tag) => tag.friendly_name).join(', ')}</div>
      </td>
      <td>
        {hasMissingRequiredInfo && (
          <div>
            {createHoverBadge(
              'danger',
              'Missing information',
              links.edit_test_group_user_url,
              'top',
            )}
          </div>
        )}
        {!on_demand_only && (
          <div>
            {test_group_user.has_appointment &&
              createBadge(
                'secondary-light',
                'Has appointment',
                links.test_group_participant_url,
              )}
          </div>
        )}
        <div>
          {test_group_user.is_waitlisted &&
            createBadge('warning', 'Waitlisted', waitlist_link)}
        </div>
        <div>
          {test_group_user.archived &&
            createBadge('danger', 'Archived', appointment_link)}
        </div>
        <div>
          {!is_global_search &&
            test_group.has_active_consent &&
            !test_group_user.consented &&
            createBadge(
              'danger',
              test_group_user.consent_gone_status,
              links.consent_url,
            )}
        </div>
      </td>
      <td>
        <CheckoutButton
          user={user}
          testGroup={test_group}
          onDemand={isOnDemand}
          links={links}
          readyToCheckout={readyToCheckout}
          onClick={
            test_group.assign_appointment_slot_groups_to_users &&
            assignAppointmentSlotGroup
          }
        />
      </td>
      {!on_demand_only && (
        <td>
          <a href={links.create_appointment_url}>Create appointment</a>
        </td>
      )}
      <td>
        <a href={links.medical_history_url}>
          <FontAwesomeIcon icon={faNotesMedical} />
        </a>
      </td>
      <td>
        <a href={links.edit_test_group_user_url}>Edit</a>
      </td>
    </React.Fragment>
  );
};

export default TestGroupUserCard;
