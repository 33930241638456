import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HelpText from "../../common/components/HelpText";
import LabTestGroupTestConfigurationInputFields from "./LabTestGroupTestConfigurationInputFields";
import OtcTestGroupTestConfigurationInputFields from "./OtcTestGroupTestConfigurationInputFields";
import SelectableTable from "../../common/components/SelectableTable/SelectableTable";
import TestGroupTestConfigurationRow from "./TestGroupTestConfigurationRow";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";
import VaccineTestGroupTestConfigurationInputFields from "./VaccineTestGroupTestConfigurationInputFields";

export const SERVICE_SPECIFICATION_TYPES = {
  immunization: {
    backgroundColor: "#F7F9FC",
    color: "black",
    component: <VaccineTestGroupTestConfigurationInputFields />,
    label: "Vaccine",
    label_v2: "vaccine",
  },
  lab: {
    backgroundColor: "#EEFBF9",
    color: "#2E948E",
    component: <LabTestGroupTestConfigurationInputFields />,
    label: "Lab",
    label_v2: "lab test",
  },
  otc: {
    backgroundColor: "#EDE9FB",
    color: "#4C00C6",
    component: <OtcTestGroupTestConfigurationInputFields />,
    label: "OTC",
    label_v2: "otc test",
  },
  poc: {
    backgroundColor: "#F9DDB1",
    color: "#DC6601",
    component: <LabTestGroupTestConfigurationInputFields />,
    label: "POC",
    label_v2: "poc test",
  },
  other: {
    backgroundColor: "#FADADD",
    color: "#FB607F",
    component: null,
    label: "Other",
    label_v2: "test",
  }
};

const CheckoutNameComponent = ({ props }) => {
  return (
    <div className="d-flex">
      <FontAwesomeIcon
        className="mb-3 me-2"
        color={props.test_group_test_configuration.active ? "#048154" : "#717D96"}
        icon="fa-solid fa-period"
        size="2x"
      />
      <div className="my-auto">
        {props.test_configuration.checkout_name}
      </div>
    </div>
  );
};

export const ServiceSpecificationTypeComponent = ({ props }) => {
  if (!props?.test_configuration) return <span />;
  const serviceSpecificationType = props.test_configuration.service_specification_type;
  if (!SERVICE_SPECIFICATION_TYPES[serviceSpecificationType]) return <span />;
  return (
    <div
      className="text-center body-medium-bold my-auto"
      style={{
        backgroundColor: SERVICE_SPECIFICATION_TYPES[serviceSpecificationType].backgroundColor,
        borderRadius: "5px",
        color: SERVICE_SPECIFICATION_TYPES[serviceSpecificationType].color,
        width: "70px",
      }}
    >
      {SERVICE_SPECIFICATION_TYPES[serviceSpecificationType].label}
    </div>
  );
};

export const UniqueServiceIdComponent = ({ props }) => {
  return (
    <div className="text-muted">
      {props.test_configuration.test_type}
    </div>
  );
};

const WarningComponent = ({ props }) => {
  const showWarning = props.test_group_test_configuration.status == "deactivated";
  if (!showWarning) return <span />;
  return (
    <HelpText
      activeClass="text-danger"
      icon={['far', 'exclamation-circle']}
      inactiveClass="text-danger"
      style={{ margin: "7px -3px 0px 5px" }}
      text="You cannot turn this service on because it was de-activated."
    />
  );
};

const ActionsComponent = ({
  dispatch,
  props
}) => {
  return (
    <div
      className="d-flex justify-content-end"
      style={{minWidth: "200px"}}
    >
      {/* will be implemented in the future */}
      {/* <FontAwesomeIcon
        className="pointer"
        color="gray"
        icon="fa-solid fa-clone"
        onClick={() => console.log("TODO")}
      /> */}
      <FontAwesomeIcon
        className="ms-4 me-2 pointer"
        color="gray"
        icon="fa-solid fa-pen-to-square"
        onClick={() =>
          dispatch({
            type: "setTestGroupTestConfiguration",
            values: {
              test_configuration: props.test_configuration,
              test_group_test_configuration: props.test_group_test_configuration,
            }
          })
        }
      />
    </div>
  );
};

const TestGroupTestConfigurationsIndex = () => {

  const {
    current_user,
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const nonSelectableItemIds = state.testGroupTestConfigurations
    .filter(s => !s.test_group_test_configuration.active && !s.test_configuration.active)
    .map(s => s.id);

  const statusCount = (status) => state.testGroupTestConfigurations.filter(s => s.test_group_test_configuration.status == status).length;

  return (
    <div>
      <div className="d-flex my-2">
        <a
          className={`pointer lead-20-medium nav-link ${state.activeTab == "active" ? "active border-bottom border-secondary" : ""}`}
          onClick={() => {
            dispatch({
              type: 'setActiveTab',
              values: "active",
            })
          }}
        >
          Active
          <span className="ms-2">
            {statusCount("active")}
          </span>
        </a>
        <a
          className={`pointer lead-20-medium nav-link ${state.activeTab == "inactive" ? "active border-bottom border-secondary" : ""}`}
          onClick={() => {
            dispatch({
              type: 'setActiveTab',
              values: "inactive",
            })
          }}
        >
          Inactive
          <span className="ms-2">
            {statusCount("inactive")}
          </span>
        </a>
        {current_user.authorized_to_see_deactivated_services && (
          <a
            className={`pointer lead-20-medium nav-link ${state.activeTab == "deactivated" ? "active border-bottom border-secondary" : ""}`}
            onClick={() => {
              dispatch({
                type: 'setActiveTab',
                values: "deactivated",
              })
            }}
          >
            Deactivated
            <span className="ms-2">
              {statusCount("deactivated")}
            </span>
          </a>
        )}
      </div>
      <div>
        <SelectableTable
          tableHeaders={["Service", "Ages", "Type", "Unique Service ID", "", ""]}
          filteredPaginatedItems={state.testGroupTestConfigurations.filter(s => s.test_group_test_configuration.status == state.activeTab)}
          nonSelectableItemIds={nonSelectableItemIds}
          RowComponent={TestGroupTestConfigurationRow}
          selectedItemIds={state.selectedTestGroupTestConfigurationIds}
          setSelectedItemIds={(ids) => dispatch({type: "setSelectedTestGroupTestConfigurationIds", values: ids})}
          itemComponents={{
              "actionsComponent": <ActionsComponent dispatch={dispatch} />,
              "test_configuration.checkout_name": <CheckoutNameComponent />,
              "test_configuration.service_specification_type": <ServiceSpecificationTypeComponent />,
              "test_configuration.test_type": <UniqueServiceIdComponent />,
              "warningComponent": <WarningComponent />,
            }}
          itemKeys={[
            "test_configuration.checkout_name",
            "test_configuration.age_range_label",
            "test_configuration.service_specification_type",
            "test_configuration.test_type",
            "warningComponent",
            "actionsComponent",
          ]}
          hideUntilHoverComponents={["actionsComponent"]}
        />
      </div>
    </div>
  );
};

export default TestGroupTestConfigurationsIndex;