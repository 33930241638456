import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { vaccineTestShape } from '../shapes';
import {
  ScreeningStep,
  VaccineLotStep,
  AdministrationStep,
  ConfirmationStep,
  PrintStep,
  VaccineProgramStep,
} from '../fields/VaccineSteps';

const VFA_NAMES = {
  mn: {
    title: 'UUAV eligibility',
    subTitle: 'What UUAV (Uninsured and Underinsured Adult Vaccine) criteria applies to this patient? *',
  },
};

export const computeCompleteStatus = (vaccineResult, surveyAnswers) => {
  let reasons = {};

  if (vaccineResult.request_vfa) {
    if (!vaccineResult['vfa_eligibility']) reasons['vfa_eligibility'] = 'UUAV eligibility answer';
  }

  if (vaccineResult.request_vfc) {
    if (!vaccineResult['vfc_eligibility']) reasons['vfc_eligibility'] = 'VFC eligibility answer';
  }

  if (vaccineResult.request_funding_for_vfa || vaccineResult.request_funding_for_vfc) {
    if (!vaccineResult['funding_source']) reasons['funding_source'] = 'Funding source answer';
  }

  [
    { key: 'administrator_initials', name: 'Administrator Initials' },
    { key: 'administrator_name', name: 'Administrator Name' },
    { key: 'administrator_degree', name: 'Administrator Degree' },
    { key: 'vaccine_lot_id', name: 'Vaccine Lot' },
  ].forEach((field) => {
    if (!vaccineResult[field.key]) {
      reasons[field.key] = field.name;
    }
  });
  const completedAdministratorAndLot = Object.keys(reasons).length === 0;

  [
    { key: 'dose', name: 'Dose' },
    { key: 'dose_name', name: 'Administered dose' },
    { key: 'administration_site', name: 'Administration site' },
    { key: 'statement_provided', name: 'Statement provided' },
    { key: 'administration_type', name: 'Administration type' },
  ].forEach((field) => {
    if (!vaccineResult[field.key]) {
      reasons[field.key] = field.name;
    }
  });

  if (surveyAnswers && !surveyAnswers.admin_approved) {
    reasons['admin_approved'] = 'Screening Questions Approved';
  }
  const completedVaccineResults =
    Object.keys(reasons).length === 0 ||
    vaccineResult.refusal_state !== 'no_refusal';

  return {
    complete: completedAdministratorAndLot && completedVaccineResults,
    reasons,
  };
};

const VaccineForm = ({
  test,
  setEdit,
  updateTest,
  submitTests,
  onComplete,
  hidePrintOptions = false,
  currentAdministratorName,
  setCurrentAdministratorName,
  currentAdministratorDegree,
  setCurrentAdministratorDegree,
  currentAdministratorInitials,
  setCurrentAdministratorInitials
}) => {
  const {
    vaccine_result: vaccineResult,
    test_configuration: testConfiguration,
    vaccine_result: { vaccine_route },
  } = test;

  const surveyAnswers = test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey_answers
    : test.test_survey;
  const [showError, setShowError] = useState(false);
  const [vaccineRoute, setVaccineRoute] = useState(vaccine_route);
  const vaccineInfoSheetProps = vaccineRoute?.vaccine_info_sheets_props;
  const [administrationLogged, setAdministrationLogged] = useState(test.administered);

  const { complete, reasons } = computeCompleteStatus(
    vaccineResult,
    surveyAnswers,
  );

  const stepNumbers = Array.from({length: 6}, (_, i) => i + 1)
  const reasonKeys = Object.keys(reasons);

  return (
    <div className="mx-2 col">
      <ScreeningStep
        test={test}
        updateTest={updateTest}
        showError={showError}
        completeReasons={reasonKeys}
        stepNumber={stepNumbers.shift()}
      />
      {testConfiguration.vaccine_lots ? (
        <div className="mb-4">
          <Form className="w-100 mb-2">
            {(vaccineResult.request_vfa || vaccineResult.request_funding_for_vfa) && (
              <VaccineProgramStep
                completeReasons={reasonKeys}
                fundingSourceOptions={vaccineResult.funding_source_options}
                options={vaccineResult.vfa_options}
                requestEligibility={vaccineResult.request_vfa}
                requestFundingSource={vaccineResult.request_funding_for_vfa}
                showError={showError}
                stepNumber={stepNumbers.shift()}
                subTitle={VFA_NAMES[vaccineResult.state]?.subTitle || "What vaccine program criteria applies to this patient? *"}
                test={test}
                title={VFA_NAMES[vaccineResult.state]?.title || "Vaccine program eligibility"}
                updateTest={updateTest}
                vaccineProgramField="vfa_eligibility"
              />
            )}
            {(vaccineResult.request_vfc || vaccineResult.request_funding_for_vfc) && (
              <VaccineProgramStep
                completeReasons={reasonKeys}
                fundingSourceOptions={vaccineResult.funding_source_options}
                options={vaccineResult.vfc_options}
                requestEligibility={vaccineResult.request_vfc}
                requestFundingSource={vaccineResult.request_funding_for_vfc}
                showError={showError}
                stepNumber={stepNumbers.shift()}
                subTitle="What VFC criteria applies to this patient? *"
                test={test}
                title="VFC eligibility"
                updateTest={updateTest}
                vaccineProgramField="vfc_eligibility"
              />
            )}
            <VaccineLotStep
              test={test}
              updateTest={updateTest}
              complete={complete}
              vaccineRoute={vaccineRoute}
              setVaccineRoute={setVaccineRoute}
              stepNumber={stepNumbers.shift()}
            />
            <AdministrationStep
              test={test}
              updateTest={updateTest}
              complete={complete}
              showError={showError}
              completeReasons={reasonKeys}
              stepNumber={stepNumbers.shift()}
            />
            <ConfirmationStep
              test={test}
              updateTest={updateTest}
              complete={complete}
              submitTests={submitTests}
              completeReasons={reasons}
              onComplete={onComplete}
              showError={showError}
              setShowError={setShowError}
              administrationLogged={administrationLogged}
              vaccineInfoSheetProps={vaccineInfoSheetProps}
              setAdministrationLogged={setAdministrationLogged}
              currentAdministratorName={currentAdministratorName}
              setCurrentAdministratorName={setCurrentAdministratorName}
              currentAdministratorDegree={currentAdministratorDegree}
              setCurrentAdministratorDegree={setCurrentAdministratorDegree}
              currentAdministratorInitials={currentAdministratorInitials}
              setCurrentAdministratorInitials={setCurrentAdministratorInitials}
              stepNumber={stepNumbers.shift()}
            />
            {!hidePrintOptions && (
              <PrintStep
                test={test}
                updateTest={updateTest}
                submitTests={submitTests}
                onComplete={onComplete}
                stepNumber={stepNumbers.shift()}
              />
            )}
          </Form>
          {administrationLogged !== null && (
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-primary"
                onClick={() => setEdit(false)}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      ) : (
        'Add Vaccines to Inventory'
      )}
    </div>
  );
};

VaccineForm.propTypes = vaccineTestShape;

export default VaccineForm;
