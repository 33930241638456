import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';

export function HeaderButton({ stepBackward, isLastStep, isFirstStep, t }) {
  return (
    <Row
      style={{
        maxWidth: '560px',
        padding: '12px',
        margin: 'auto',
      }}
    >
      {!isFirstStep && !isLastStep && (
        <Col>
          <Button
            onClick={stepBackward}
            variant="link"
            className="kit-flow header-btn mt-3"
          >
            {t('otc.back_button')}
          </Button>
        </Col>
      )}
    </Row>
  );
}

export function FooterButtons({
  stepName,
  stepForward,
  nextButtonDisabled,
  t,
}) {
  const BUTTON_TEXT = {
    VERIFY_DOB: 'otc.next_button',
    TEST_KIT_OVERVIEW: 'viral_surveillance.click_to_get_started_button',
    SHIPPING_INFORMATION: 'otc.complete_button',
    INSTRUCTIONS_STEP: 'viral_surveillance.steps_complete',
  };

  return (
    <div>
      <Row
        style={{
          maxWidth: '535px',
          padding: '12px',
          margin: 'auto',
        }}
      >
        {stepName !== 'THANK_YOU' && (
          <Button
            onClick={stepForward}
            className="otc-button my-3"
            disabled={nextButtonDisabled}
            data-test-hook="submit"
            style={{
              minWidth: '100px',
              whiteSpace: 'nowrap',
            }}
          >
            {t(BUTTON_TEXT[stepName])}
          </Button>
        )}
      </Row>
    </div>
  );
}
