import React from 'react';
import '../../common/locales/i18n';
import { languageObject } from "../../common/utils";
import { useTranslation } from 'react-i18next';
import { TrashCan } from '../../common/components/Icons';
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row
} from 'react-bootstrap';

const TranslationSelections = ({ locales, addlLocales, setSelectedLocales, selectedLocales, setActiveLocale, activeLocale }) => {
  const { i18n } = useTranslation();

  const addLocale = (locale) => {
    !selectedLocales.includes(locale) && setSelectedLocales( arr => [...arr, locale].sort());

    if(!activeLocale) {
      setActiveLocale(locale);
      i18n.changeLanguage(locale);
    }
  }

  const removeLocale = (locale) => {
    let newSelections = selectedLocales.filter(l => l !== locale);
    setSelectedLocales(newSelections);
    setActiveLocale(newSelections[0]);
    i18n.changeLanguage(newSelections[0]);
  }

  const setActive = (locale) => {
    setActiveLocale(locale);
    i18n.changeLanguage(locale);
  }

  const LocalesDropdown = ({title, locales, classes}) => (
    <DropdownButton title={title} variant="outline-tertiary" size="sm" className={`d-inline-block ${classes}`}>
      {!locales.length && <Dropdown.Header> No languages in test group</Dropdown.Header>}
      {locales.map((locale) =>
        <Dropdown.Item
          lang={locale}
          onClick={() => {
            addLocale(locale);
          }}
          key={locale}>
          {locale} ({languageObject[locale]})
        </Dropdown.Item>
      )}
    </DropdownButton>
  )

  const Selections = ({selectedLocales}) => (
    <Container className="my-4 ps-0 selections">
      {selectedLocales.length ? (
        selectedLocales.map(locale => {
          return (
            <Row key={locale} className={`mb-3 align-items-center ${locale == activeLocale && 'active'} ${(locale == activeLocale && selectedLocales.length > 1) && 'underline'}`}>
              <Col
                xs="auto"
                className="locale"
                onClick={() => setActive(locale)}
              >
                English
                {locale != "en" && ` to ${locale} (${languageObject[locale]})`}
              </Col>
              <Col xs="auto">
                <Button
                  variant="link"
                  size="sm"
                  className="remove"
                  disabled={locale == activeLocale ? false : true}
                  onClick={() => {
                    removeLocale(locale);
                  }}
                >
                  Remove
                  <TrashCan className="ms-2" color='inherit' />
                </Button>
              </Col>
            </Row>
          )
        })
      ) : (
        <div className="text-center mt-9">
          <img alt="placeholder" src="/images/placeholder.png" />
          <h3 className="mt-3">No language added</h3>
          <p>To preview and print a form, you need to first select a language.</p>
        </div>
      )}
    </Container>
  )

  return(
    <>
      <LocalesDropdown
        title="Add language(s) in test group"
        locales={locales}
        classes="me-2 mb-3"
      />
      <LocalesDropdown
        title="Add additional language(s)"
        locales={addlLocales}
      />
      <Selections
        selectedLocales={selectedLocales}
      />
    </>
  )
}

export default TranslationSelections;
