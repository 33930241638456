import { parseISO, differenceInCalendarDays } from 'date-fns';

export const toDateString = (appointmentSlot) => {
  const dateString = appointmentSlot.localized_day;
  if (dateString === undefined) {
    console.error("Date string undefined: ", appointmentSlot)
  }
  return dateString
}

const appointmentSlotsWithFallback = (appointmentSlotGroup) => {
  return appointmentSlotGroup.appointment_slots || [];
}

export default class Slotter {
  constructor(testGroup) {
    this.testGroup = testGroup;
    this.appointmentSlotGroups = testGroup.appointment_slot_groups;

    this.availableDatesByTestConfigurationId = {};
    this.pairedDatesCacheByTestConfigurationId = {};

    // We need to keep 2 other caches for testGroup.only_one_location_for_follow_up
    this.availableDatesByTestConfigurationIdAndLocationId = {};
    this.pairedDatesCacheByTestConfigurationIdAndLocationId = {};

    this.appointmentSlotGroups.forEach(appointmentSlotGroup => {
      const testConfigurationId = appointmentSlotGroup.follow_up_test_configuration_id;
      if (testConfigurationId === undefined) return;

      if (!(testConfigurationId in this.availableDatesByTestConfigurationId)) {
        this.availableDatesByTestConfigurationId[testConfigurationId] = {};
        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId] = {};
      }

      if (!(appointmentSlotGroup.id in this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId])) {
        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id] = {};
      }
      if (!(testConfigurationId in this.pairedDatesCacheByTestConfigurationId)) {
        this.pairedDatesCacheByTestConfigurationId[testConfigurationId] = {};
        this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId] = {};
      }
      if (!(appointmentSlotGroup.id in this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId])) {
        this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id] = {};
      }

      (appointmentSlotsWithFallback(appointmentSlotGroup)).forEach(appointmentSlot => {
        const date = toDateString(appointmentSlot);
        this.availableDatesByTestConfigurationId[testConfigurationId][date] = true;

        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id][date] = true;
      });
    });
    window.availableDatesByTestConfigurationIdAndLocationId = this.availableDatesByTestConfigurationIdAndLocationId;
  }

  availableSlotsForAppointmentSlotGroup(selectedAppointmentSlotGroup, twoDose=true) {
    const appointmentSlots = appointmentSlotsWithFallback(selectedAppointmentSlotGroup);
    return appointmentSlots;
  }
}
