import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from '../../common/components/ModalHeader';
import Insurance from '../../Insurance/components/Insurance';

const FixInsuranceModal = ({ test_group, user, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <Modal show={true} onHide={handleClose} size="lg">
      <ModalHeader closeButton onHide={handleClose}>
        <h3>
          Admins have requested for you to review your insurance information.
        </h3>
      </ModalHeader>
      <Modal.Body>
        <Insurance test_group={test_group} user={user} />
      </Modal.Body>
    </Modal>
  );
};

export default FixInsuranceModal;
