import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { Printer } from '../../common/components/Icons/Printer';
import { Header } from './TestStripDisplay';
import MedicalHistoryVitalsScreeningCardBody from './MedicalHistoryVitalsScreeningCardBody';
import MedicalHistoryHypertensionScreeningCardBody from './MedicalHistoryHypertensionScreeningCardBody';

const MEDICAL_HISTORY_CARD_BODIES = {
  vitals_screening: MedicalHistoryVitalsScreeningCardBody,
  hypertension_screening: MedicalHistoryHypertensionScreeningCardBody,
};

const deskTopStyle = {
  boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
  borderRadius: 16,
  padding: '24px',
};

export const TestHeader = ({ test, showPrint = false }) => {
  return (
    <div className="my-3">
      <Row className="d-flex my-2">
        <Col>
          <h4>{test.test_configuration.display_name}</h4>
        </Col>
        {showPrint && (
          <Col className="text-end">
            <Button
              aria-label="Print test details"
              className="btn-icon btn-transparent"
              onClick={() => printDetails()}
            >
              <Printer />
            </Button>
          </Col>
        )}
      </Row>
      <div>
        <div className="body-large-16-regular inter medium">{test.display_time}</div>
        <div className="text-muted">{test.location_name}</div>
        <div className="text-muted">{test.location}</div>
        <div className="mt-2">Clinician: {test.checkout_user?.full_name}</div>
      </div>
    </div>
  );
};

const TestDetails = ({ test }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const printDetails = () => window.print();
  const TestBody = MEDICAL_HISTORY_CARD_BODIES[test.test_configuration.service_specification];

  return (
    <div className="mt-3">
      {isMobile && (
        <div className="text-end mb-3">
          <Button
            aria-label="Print test details"
            className="ms-3 btn-icon btn-transparent"
            onClick={() => printDetails()}
          >
            <Printer />
          </Button>
        </div>
      )}
      <div style={isMobile ? {} : {deskTopStyle}}>
        <TestHeader showPrint={!isMobile} test={test}/>
        <hr />
        <TestBody test={test}/>
      </div>
    </div>
  );
};

const WellnessTestDisplay = ({
  test,
  selectedWellnessTest,
  setSelectedWellnessTest,
}) => {
  return (
    <>
      {!selectedWellnessTest && (
        <div
          className="medical_history__item test-card my-3"
          onClick={() => setSelectedWellnessTest(test)}
          key={test.id}
        >
          <Header
            color={test.test_configuration.color_code}
            displayName={test.test_configuration.display_name}
            displayTime={test.display_time}
            icon={test.test_configuration.font_awesome_icon}
            key={'o' + test.id}
            shortDisplayTime={test.short_display_time}
            status={test.result_complete ? test.result_complete : 'processing'}
          />
        </div>
      )}
      {selectedWellnessTest && selectedWellnessTest.id == test.id && (
        <TestDetails test={selectedWellnessTest} />
      )}
    </>
  );
};

export default WellnessTestDisplay;
