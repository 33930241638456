import React from 'react';
import { Container } from 'react-bootstrap';
import PrimaryHeartOutline from './Icons/PrimaryHeartOutline';

const ContactSupport = () => {
  return (
    <Container style={{ maxWidth: '560px' }} className="pt-2 px-4">
      <div className="text-center my-3 py-3">
        <PrimaryHeartOutline className="my-3 py-3" />
      </div>
      <h1 className="h2 my-2">Contact us</h1>
      <p>
        It looks like something went wrong! Please contact us at{' '}
        <a href="mailto:support@primary.health">support@primary.health</a> to
        have this resolved as soon as possible.
      </p>
    </Container>
  );
};

export default ContactSupport;
