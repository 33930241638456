import React, { useContext } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../../common/locales/i18n';
import MembersPortalContext from "../context";
import RadioOptions from './partials/RadioOptions';
import { languageOptions } from './utils';
import axios from 'axios';

const LanguageChange = () => {
  const { currentUser, t } = useContext(MembersPortalContext);

  const updateUserPreference = (value) => {
    axios.put(`/members/users/${currentUser.user.id}`, { user: { language_preference: value }})
      .then(() => window.location.href = `/members/me?current_user=${currentUser.id}`)
      .catch((e) => console.log(e))
  }

  return (
    <RadioOptions
      title={t('preferred_language.title')}
      subtitle={t('preferred_language.subtitle')}
      options={languageOptions}
      onClick={updateUserPreference}
    />
  );
};

export default LanguageChange;

