import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import ModalHeader from '../../../common/components/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

const EditVaccineHistoryModal = ({
  test_group,
  test_group_user,
  vaccineHistoryToEdit,
  setVaccineHistoryToEdit,
  reload,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const deleteVaccineHistory = async () => {
    setLoadingDelete(true);
    const response = await axios.delete(
      `/test_groups/${test_group.slug}/immunizations/${test_group_user.id}/vaccine_histories/${vaccineHistoryToEdit.id}`,
    );
    setVaccineHistoryToEdit(null);
    setLoadingDelete(false);
    await reload();
  }

  const save = async () => {
    setLoadingUpdate(true);
    await axios.put(
      `/test_groups/${test_group.slug}/immunizations/${test_group_user.id}/vaccine_histories/${vaccineHistoryToEdit.id}`,
      {
        vaccine_history: {
          vaccinated_at: vaccineHistoryToEdit.vaccinatedAt
        }
      },
    );
    setVaccineHistoryToEdit(null);
    setLoadingUpdate(false);
    await reload();
  }

  const onHide = () => {
    setVaccineHistoryToEdit(null)
  };

  return (
    <div>
      <Modal show={!!vaccineHistoryToEdit} onHide={onHide}>
        <ModalHeader closeButton onHide={onHide}>
          <Modal.Title as="h2">
            Edit dose
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <FloatingLabelInput
            name="date_given"
            label="Date given"
            type="date"
            value={vaccineHistoryToEdit.vaccinatedAt}
            className="mt-3"
            filledValue={true}
            onChange={(e) => {
              setVaccineHistoryToEdit({...vaccineHistoryToEdit, vaccinatedAt: e.target.value});
            }}
          />
          <div className="text-end mt-3">
            <Button
              className="text-danger"
              variant="link"
              disabled={loadingDelete}
              onClick={deleteVaccineHistory}
            >
              {loadingDelete && "..."}
              {!loadingDelete && "Delete dose"}
              <FontAwesomeIcon icon={faTrash} className="ms-2" />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setVaccineHistoryToEdit(null)}
          >
            Cancel
          </Button>
          <Button
            disabled={loadingUpdate}
            variant="primary"
            onClick={save}
          >
            {loadingUpdate && "..."}
            {!loadingUpdate && "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditVaccineHistoryModal;
