import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from 'react-responsive';

const StatCard = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  let variant;
  if (props.variant == "primary") {
    variant = `bg-primary${isMobile ? " text-white" : "-light"}`;
  } else if (props.variant == "success") {
    variant = `bg-success${isMobile ? " text-white" : "-light"}`;
  } else if (props.variant == "danger") {
    variant = `bg-danger${isMobile ? " text-white" : "-light"}`;
  } else if (props.variant == "grey") {
    variant = `bg-${isMobile ? "grey text-white" : "light"}`;
  } else {
    variant = "bg-light";
  }

  const action = () => {
    window.location = props.actionLink;
  }
  
  return (
    <Card
      body
      className={variant}
      data-test-hook={props.dataTestHook}
    >
      <Row>
        <Col xs={12} xl={8} className={isMobile ? "mb-1 pb-2" : ""}>
          <Row>
            <Col><div className={`subtitle mb-2 ${!props.variant && "text-primary"}`}>{props.title}</div></Col>
            {props.icon && isMobile && <Col xs="auto"><img src={props.icon} /></Col>}
          </Row>
          <div className="h5">{props.stat}</div>
          {props.description &&
            <div className={`${!props.variant && "text-muted"} mt-1 mb-2 caption`}>{props.description}</div>
          }
        </Col>
        {props.actionLink &&
          <Col xs={12} xl={4}>
            <Button
              onClick={action}
              variant={props.variant || "light"}
              className={(isMobile || !props.variant) && "btn-inverse"}
              block
              disabled={!props.actionLink}
            >
              {props.actionText}
            </Button>
          </Col>
        }
      </Row>
    </Card>
  );
};

export default StatCard;
