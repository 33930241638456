import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, FormControl, Card } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import ModalHeader from '../../common/components/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCircleCheck, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';

const buildStatus = (user) => {
  if (user.saved) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
    );
  } else if (user.error) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon={faExclamation} />
        <span style={{ paddingLeft: 5 }}>{user.error}</span>
      </div>
    );
  } else {
    return null;
  }
};

const MultiAddTestGroupUser = ({
  testGroupId,
  tags = [],
  populations = [],
  styling = {},
  sendReg = false,
  accessKeys = [],
}) => {
  const newUserTemplate = {
    accessCode: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    tags: [],
    populationId: populations.length === 1 ? populations[0].id : null,
    accessKey: null,
    saved: false,
    error: false,
  };

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([
    { ...newUserTemplate }, // Initialize it with 1 empty user
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    let success = true;
    const promises = [];

    // Submit this to the API.
    users.forEach((user, index) => {
      if (user.saved) {
        // Don't try to submit saved users.
        return;
      }

      const isValid = user.accessCode || (user.firstName && user.lastName);
      if (!isValid) {
        setField('Needs an access code or name.', 'error', index);
        return;
      }

      const payload = {
        user: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          phone_number: user.phoneNumber,
          tags: user.tags,
        },
        population_id: user.populationId,
        access_key: user.accessKey,
        access_code: user.accessCode,
        test_group_id: testGroupId,
        send_reg_link: true,
      };

      const request = fetch('/api/test_group_users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response.status === 200) {
            setField(true, 'saved', index);
          } else {
            success = false;
            setField('Failed to save', 'error', index);
          }
        })
        .catch((response) => {
          success = false;
          setField('Failed to save', 'error', index);
        });

      promises.push(request);
    });

    Promise.all(promises).then(() => {
      if (success) {
        window.location.reload();
        toastr.success('The self-registration link(s) has been successfully sent.');
      }
    });
  };

  const setField = (value, fieldName, index) => {
    const newUsers = users.slice(0, index);
    const user = users[index];
    const newUser = { ...user };
    newUser[fieldName] = value;
    newUsers.push(newUser);
    newUsers.concat(users.slice(index + 1));

    setUsers(newUsers);
  };

  const tagOptions = tags.map((tag) => ({
    value: tag.id,
    label: tag.friendly_name,
  }));
  const accessKeyOptions = accessKeys.map((key) => ({
    value: key.value,
    label: key.friendly_name,
  }));

  const populationOptions = populations.map((population) => ({
    value: population.id,
    label: population.name,
  }));

  const buttonStyling = styling.button;

  return (
    <>
      {buttonStyling ? (
        <Button
          variant={buttonStyling.variant}
          size={buttonStyling.size}
          block={buttonStyling.block}
          onClick={handleShow}
          className={buttonStyling.className}
        >
          {buttonStyling.text}
        </Button>
      ) : (
        <Button
          variant="outline-primary"
          onClick={handleShow}
          style={{ height: 36 }}
        >
          Create multiple users
        </Button>
      )}

      <Modal show={show} onHide={handleClose} size="xl">
        <ModalHeader closeButton onHide={handleClose}>
          <Modal.Title as="h2">Send registration link</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          {users.map((user, index) => {
            return (
              <div
                key={index}
                style={{
                  marginTop: index === 0 ? 'unset' : 20,
                  marginBottom: 20,
                }}
              >
                <Card body>
                  <Row key={index}>
                    <Col lg={6} xl={3} className="mb-4">
                      <FloatingLabelInput
                        label="Access code"
                        value={user.accessCode}
                        onChange={(e) =>
                          setField(e.target.value, 'accessCode', index)
                        }
                      />
                    </Col>
                    <Col lg={6} xl={3} className="mb-4">
                      <FloatingLabelInput
                        label="First name"
                        value={user.firstName}
                        onChange={(e) =>
                          setField(e.target.value, 'firstName', index)
                        }
                      />
                    </Col>
                    <Col lg={6} xl={3} className="mb-4">
                      <FloatingLabelInput
                        label="Last name"
                        value={user.lastName}
                        onChange={(e) =>
                          setField(e.target.value, 'lastName', index)
                        }
                      />
                    </Col>
                    <Col lg={6} xl={3} className="mb-4">
                      <FloatingLabelInput
                        label="Email"
                        value={user.email}
                        onChange={(e) =>
                          setField(e.target.value, 'email', index)
                        }
                      />
                    </Col>
                    <Col lg={6} xl={3} className="mb-4">
                      <FloatingLabelInput
                        label="Phone"
                        value={user.phoneNumber}
                        onChange={(e) =>
                          setField(e.target.value, 'phoneNumber', index)
                        }
                      />
                    </Col>
                    {tagOptions.length > 0 && (
                      <Col lg={6} xl={3} className="mb-4">
                        <FloatingLabelSelect
                          value={tagOptions.filter((option) =>
                            user.tags.includes(option.value),
                          )}
                          onChange={(values) => {
                            if (values) {
                              setField(
                                values.map((target) => target.value),
                                'tags',
                                index,
                              );
                            } else {
                              setField([], 'tags', index);
                            }
                          }}
                          options={tagOptions}
                          label="Tags"
                          filledValue={tagOptions.filter((option) =>
                            user.tags.includes(option.value),
                          )}
                          isClearable={true}
                          components={{ IndicatorSeparator: () => null }}
                        />
                      </Col>
                    )}
                    {populationOptions.length > 0 && (
                      <Col lg={6} xl={3} className="mb-4">
                        <FloatingLabelSelect
                          value={populationOptions.find(
                            (option) => user.populationId === option.value,
                          )}
                          onChange={(option) => {
                            setField(
                              option ? option.value : null,
                              'populationId',
                              index,
                            );
                          }}
                          options={populationOptions}
                          label="Population"
                          filledValue={populationOptions.find(
                            (option) => user.populationId === option.value,
                          )}
                          isClearable={true}
                          components={{ IndicatorSeparator: () => null }}
                        />
                      </Col>
                    )}
                    {accessKeyOptions.length > 0 && (
                      <Col lg={6} xl={3} className="mb-4">
                        <FloatingLabelSelect
                          value={accessKeyOptions.find(
                            (option) => user.accessKey === option.value,
                          )}
                          onChange={(option) => {
                            setField(
                              option ? option.value : null,
                              'accessKey',
                              index,
                            );
                          }}
                          options={accessKeyOptions}
                          label="Access Key"
                          filledValue={accessKeyOptions.find(
                            (option) => user.accessKey === option.value,
                          )}
                          isClearable={true}
                          components={{ IndicatorSeparator: () => null }}
                        />
                      </Col>
                    )}
                    <Col>{buildStatus(user)}</Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="text-danger"
                      variant="link"
                      onClick={() => setUsers(users.length > 1 ? users.filter(u => u != user) : [{ ...newUserTemplate }])}
                    >
                      Remove user
                      <FontAwesomeIcon icon={faTrash} className="ms-2" />
                    </Button>
                  </div>
                </Card>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="outline-primary"
            onClick={() => setUsers([...users, { ...newUserTemplate }])}
          >
            Add user
            <FontAwesomeIcon icon={faUserPlus} className="ms-2" />
          </Button>
          <div className="m-0">
            <Button
              variant="outline-primary"
              className="mx-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="mx-2"
              onClick={handleSubmit}
            >
              Send link
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MultiAddTestGroupUser.propTypes = {
  testGroupId: PropTypes.string.required,
};

export default MultiAddTestGroupUser;
