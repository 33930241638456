import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import ModalHeader from '../../common/components/ModalHeader';
import axios from 'axios';
import AlertHeader from '../../common/components/AlertHeaderV2';
import { format } from 'date-fns';

const requestMatchRejection = ({ testGroupId, userId, iisReferenceId }) => {
  const url = `/test_groups/${testGroupId}/participants/${userId}/vaccine_history_updates/reject_match`;
  return axios.post(url, { iis_reference_id: iisReferenceId });
};

const requestMatchAcceptance = ({ testGroupId, userId, iisReferenceId }) => {
  const url = `/test_groups/${testGroupId}/participants/${userId}/vaccine_history_updates/accept_match`;
  return axios.post(url, { iis_reference_id: iisReferenceId });
};

const MatchStatusHandler = ({
  latestReference,
  updateImmunizations,
  user,
  testGroupId,
  onMatchAccepted = () => {},
}) => {
  const [showModal, setShowModal] = useState(false);

  if (
    latestReference?.status !== 'ok' &&
    latestReference?.status !== 'match_rejected'
  ) {
    return;
  }

  const rejectMatch = () => {
    requestMatchRejection({
      testGroupId,
      userId: user.id,
      iisReferenceId: latestReference.id,
    })
      .then((response) => {
        updateImmunizations({
          vaccineHistories: response.data.vaccine_histories,
          iisReferences: response.data.iis_references,
        });
        setShowModal(false);
      })
      .catch(() => {
        toastr.error('Something went wrong');
      });
  };

  const acceptMatch = () => {
    requestMatchAcceptance({
      testGroupId,
      userId: user.id,
      iisReferenceId: latestReference.id,
    })
      .then((response) => {
        updateImmunizations({
          vaccineHistories: response.data.vaccine_histories,
          iisReferences: response.data.iis_references,
        });
        onMatchAccepted();
      })
      .catch(() => {
        toastr.error('Something went wrong');
      });
  };

  return (
    <div>
      {latestReference.status === 'match_rejected' && (
        <AlertHeader
          variant="danger"
          message={`This record was considered to "Not a match" by a Primary.Health administrator on ${format(
            new Date(latestReference.updated_at),
            "MMMM d, yyyy 'at' h:mm aa",
          )}.`}
        ></AlertHeader>
      )}
      <div className="d-flex justify-content-between">
        <div className="my-auto inter font-medium medium">
          {latestReference?.source.toUpperCase()} user ID:{' '}
          {latestReference?.iis_id}
        </div>
        <div>
          {latestReference.status === 'ok' && (
            <Button
              variant="outline-tertiary"
              onClick={() => setShowModal(true)}
            >
              Not a match
            </Button>
          )}
          {latestReference.status === 'match_rejected' && (
            <Button variant="outline-tertiary" onClick={acceptMatch}>
              Accept match
            </Button>
          )}
        </div>
        <Modal
          className="spaced-modal"
          show={showModal}
          onHide={!showModal}
          size="md"
        >
          <ModalHeader closeButton onHide={() => setShowModal(false)}>
            <Modal.Title className="h4-20-semi-bold poppins semibold mb-3">
              Are you sure?
            </Modal.Title>
            <div>Please confirm that this patient is not a match.</div>
          </ModalHeader>
          <Modal.Body>
            <Card className="p-3">
              <div className="mb-2">
                <span className="inter semibold">Name:</span>{' '}
                {latestReference?.name?.first_name}{' '}
                {latestReference?.name?.other_names}{' '}
                {latestReference?.name?.last_name}
              </div>
              <div className="mb-2">
                <span className="inter semibold">Date of birth:</span>{' '}
                {latestReference?.date_of_birth}
              </div>
              <div className="mb-2">
                <span className="inter semibold">Gender:</span>{' '}
                {latestReference?.gender}
              </div>
              <div className="mb-2">
                <span className="inter semibold">Address:</span>{' '}
                {latestReference?.address?.street}{' '}
                {latestReference?.address?.city},{' '}
                {latestReference?.address?.state}{' '}
                {latestReference?.address?.zip_code}
              </div>
              <div className="mb-2">
                <span className="inter semibold">Phone:</span>{' '}
                {latestReference.phone_number &&
                  `(${latestReference.phone_number.area_code}) ${latestReference.phone_number.local_number}`}
              </div>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={rejectMatch}>
              Not a match
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MatchStatusHandler;
