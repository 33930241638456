import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopOverWindow from '../../common/components/PopOverWindow';
import { faGear } from '@fortawesome/pro-regular-svg-icons';

const PreferencesPopOver = ({
  open,
  onHide,
  doseManagementData,
  setDoseManagementData,
  preferences,
  setPreferences,
}) => {
  const [discarded, setDiscarded] = useState(
    doseManagementData && doseManagementData.discarded,
  );
  const [noShowInterval, setNoShowInterval] = useState(
    preferences && preferences.no_show_interval,
  );

  const savePreferences = () => {
    onHide();
    setDoseManagementData && setDoseManagementData({ discarded });
    setPreferences && setPreferences({ no_show_interval: noShowInterval });
  };

  return (
    <PopOverWindow
      open={open}
      onHide={onHide}
      title="Settings"
      position="right"
    >
      <label className="form-label">Discarded doses</label>
      <div className="d-flex flex-row">
        <input
          className="form-control w-50 me-2 h-75"
          type="number"
          value={discarded}
          autoComplete="off"
          onChange={(e) => setDiscarded(e.target.value)}
        />
        <label className="form-label">discarded doses for the day</label>
      </div>

      {preferences && setPreferences && (
        <React.Fragment>
          <label className="form-label">Count as no show</label>
          <div className="d-flex flex-row">
            <input
              className="form-control w-50 me-2 h-75"
              type="number"
              value={noShowInterval}
              autoComplete="off"
              onChange={(e) => setNoShowInterval(e.target.value)}
            />
            <label className="form-label">minutes after appointment</label>
          </div>
        </React.Fragment>
      )}

      <button className="mt-2 w-100 btn btn-primary" onClick={savePreferences}>
        Save
      </button>
    </PopOverWindow>
  );
};

const Preferences = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="position-relative mb-2 mt-2 mt-md-0">
      <button
        className="btn btn-light"
        onClick={() => setOpen(!open)}
        style={{
          borderColor: '#dee2e6',
          color: 'var(--primary)',
        }}
      >
        <FontAwesomeIcon icon={faGear} size="sm" /> Edit dashboard
      </button>
      <PreferencesPopOver
        open={open}
        onHide={() => setOpen(false)}
        {...props}
      />
    </div>
  );
};

export default Preferences;
