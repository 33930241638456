import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import RegistrationContext from '../../../Registration/contexts/RegistrationContext'
import RegistrationCore from '../../../Registration/components/RegistrationCore';
import { validateConsent } from '../../../Registration/components/registration/Consent';
import ConsentSection from './ConsentSection';

const Consent = ({loggedIn, user, testGroup}) => {
  // State initialization
  const { t, i18n } = useTranslation();
  const initialValues = user;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const [localFormState, setLocalFormState] = useState({
    errorMessage: null,
    loggedIn: loggedIn,
  });

  let sections = [{
    header: 'registration.consent',
    view: (props) => <ConsentSection t={t} {...props}/>,
    validate: validateConsent,
    name: 'consent',
  }];

  const onSubmit = (values, bag) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
      redirect: 'follow',
    };

    fetch(`/registrations/${values.appointment.access_code}.json`, requestOptions)
      .then(r => r.json())
      .then(data => {
        window.location.href = data.redirect_url;
      })
      .catch(function(err) {
        setIsSubmitting(false);
        if (err.response.data && err.response.data.error_message) {
          const errorMessage = err.response.data.error_message;
          urlParams.append('error', errorMessage)
          location.href = redirect_url
        } else {
          urlParams.append('error', "Something went wrong");
          location.href = redirect_url
        }
      });
  };

  const context = {
    testGroup,
    isSubmitting,
    setIsSubmitting,
    localFormState,
    setLocalFormState,
    currentRegistrationStep,
    setCurrentRegistrationStep,
    sectionsLength: sections.length,
    currentSectionHeader: sections[currentRegistrationStep].header,
    sections: sections,
    setSubmitButtonDisabled: () => {},
  };

  return (
    <div className="mb-5">
      <RegistrationContext.Provider value={context}>
        <RegistrationCore
          initialValues={initialValues}
          onSubmit={onSubmit}
          sections={sections}
          currentRegistrationStep={currentRegistrationStep}
          setCurrentRegistrationStep={setCurrentRegistrationStep}
          showSideBar={false}
          onSubmitText={t('registration.submit')}
        />
      </RegistrationContext.Provider>
    </div>
  );
};

Consent.propTypes = {
  testGroup: PropTypes.object.isRequired, // this is passed from the Rails view
  user: PropTypes.object.isRequired, // this is passed from the Rails view
};

export default Consent;
