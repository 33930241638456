import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import ScanBarcodeComponent from '../ScanBarcodeComponent';
import { SectionWrapper } from '../styled';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownCustomClass } from '../styled';

export default function ScanStep ({state: {isMobile, t, error, loading}, dispatch, validateKit, noBarcode}) {
  const dontHaveABox = (
    <Col xs={12} sm={5} className="text-center">
      <img src="/images/sti-programs/test-kit.png" height={240}></img>
      <div className='text-center'>
        <span
          className='text-secondary fw-bold text-decoration-underline pointer'
          style={{fontSize: 14}}
          onClick={noBarcode}
          data-test-hook="no_barcode"
        >
          {t('test_strip.no_box')}
        </span>
      </div>
    </Col>
  )

  const scanBarcode = (
    <Col  xs={12} sm={6} className="h4-20-regular" style={{alignSelf: "center", textAlign: "-webkit-center"}}>
      <ScanBarcodeComponent handleSubmit={validateKit} isMobile={isMobile} loading={loading}  />
    </Col>
  )

  return (
    <SectionWrapper>
      <div className='text-end mb-2 d-sm-none'>
        <a className="inter lead-20-medium bold" href="https://primarybio-public.s3.us-east-2.amazonaws.com/KIT+Page+FAQ.pdf">{t('test_strip.faq_link')} <FontAwesomeIcon icon={faArrowUp} className="rotate-45" /></a>
      </div>
      <Row className='mb-3'>
        <Col xs="auto gx-3">
          <h3 className="kit-header">
            {t('test_strip.welcome')}
          </h3>
        </Col>
        <Col className='ms-auto d-none d-sm-block' xs="auto">
          <a className="inter lead-20-medium bold" href="https://primarybio-public.s3.us-east-2.amazonaws.com/KIT+Page+FAQ.pdf">{t('test_strip.faq_link')} <FontAwesomeIcon icon={faArrowUp} className="rotate-45" /></a>
        </Col>
      </Row>
      <div className='kit-body-text pb-2'>{t('test_strip.scan.title')}</div>
      <div className="pt-2">
        <ReactMarkdown
          source={t('test_strip.scan.p1')}
          renderers={{
            paragraph: ({ children }) => (
              <ReactMarkdownCustomClass customClass="kit-body-text">
                {children}
              </ReactMarkdownCustomClass>
            ),
          }}
        />
      </div>
      <Row className='mb-4 mt-5 justify-content-center'>
        {isMobile ? (
          <div style={{ marginBottom: '48px' }}>
            {scanBarcode}
            {dontHaveABox}
          </div>
        ) : (
          <>
            {dontHaveABox}
            {scanBarcode}
          </>
        )}
      </Row>
      {error == "unknown_kit_type" && <div className='text-danger'>Unknown kit bar code</div>}
    </SectionWrapper>
  );
}
