import React from 'react';
import { useTranslation } from 'react-i18next';
import HaveInsurance from './HaveInsurance';
import PolicyHolder from './PolicyHolder';
import UploadInsuranceCard from './UploadInsuranceCard';
import InsuranceSurvey from './InsuranceSurvey';

export default (props) => {
  const { t } = useTranslation();
  const showSurvey = (props.state.testGroup.show_only_insurance_survey && props.state.formState.haveHealthInsurance === false) ||
    (props.state.testGroup.show_insurance_survey && props.state.testGroup.show_only_insurance_survey) ||
    Object.keys(props.state.testGroup.insurance_survey || {}).length > 0

  return (
    <>
      <h2 className='poppins semibold mb-4'>{t('registration.confirmation_section.title')}</h2>
      <HaveInsurance {...props} withTitle={false} />
      {props.state.formState.haveHealthInsurance &&
        <>
          <PolicyHolder {...props}/>
          <UploadInsuranceCard {...props}/>
        </>
      }
      {showSurvey &&
        <InsuranceSurvey {...props}/>
      }
    </>
  )
}
