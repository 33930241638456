import React, { useContext, useState } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import InsuranceContext from '../../../Insurance/contexts/InsuranceContext';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faCheck } from '@fortawesome/pro-regular-svg-icons';
import Select from 'react-select';

const NoInsuranceFields = ({
  values,
  errors,
  handleChange,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction } = useContext(InsuranceContext);
  const [fileName, setFileName] = useState(null);

  const usStateOptions = Object.keys(testGroup.us_states).map((key) => ({
    label: testGroup.us_states[key],
    value: key,
  }));

  const announceFileUpload = (file, nodeId) => {
    const announceFileUploadRoot = document.getElementById(nodeId);
    if (file === undefined) {
      announceFileUploadRoot.style.visibility = 'hidden';
    } else {
      announceFileUploadRoot.style.visibility = 'visible';
      announceFileUploadRoot.focus();
    }
  }

  return (
    <div className="rounded-8 px-3 py-4 mb-3 mt-2" style={{ backgroundColor: "#FBFBFB" }}>
      {formAction.requestDriversLicense && (
        <div>
          <FabrxCheckbox
            name="id_type"
            ariaLabel="Driver's License or state ID #"
            onChange={() => {
              setFormAction({
                ...formAction,
                showLicenseInfo: true,
                showSsnInfo: false,
                showNoId: false,
              });
            }}
            checked={formAction.showLicenseInfo || values.user.driver_license_number || false}
            label={`Driver's license or state ID # ${formAction.requireDriversLicense ? '*' : ''}`}
            radio
          />
          {(formAction.showLicenseInfo || values.user.driver_license_number) && (
            <div>
              <Row className="mb-2">
                <Col sm={12} md={6}>
                  <label htmlFor="driver_license_number">Driver's license or state ID #</label>
                  <input
                    id="driver_license_number"
                    className="form-control"
                    name="user[driver_license_number]"
                    value={values.user.driver_license_number || ''}
                    onChange={handleChange}
                    aria-label="Driver's license or state ID #"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <label htmlFor="driver_license_state">{t('no_insurance_information.state')}</label>
                  <Select
                    id="driver_license_state"
                    name="user[driver_license_state]"
                    value={usStateOptions.filter(
                      (option) => option.value === values.user.driver_license_state,
                    )}
                    onChange={(s) => {
                      setFormAction({ ...formAction, driverLicenseState: s.value });
                      setFieldValue('user.driver_license_state', s.value);
                    }}
                    options={usStateOptions}
                    aria-label={t('no_insurance_information.state')}
                    className={errors.driver_license_state && 'is-invalid'}
                  />
                </Col>
              </Row>
              <div className='mb-3'>
                <input
                  type="file"
                  onChange={(e) => {
                    setFieldValue('user[driver_license]', e.currentTarget.files[0]);
                    setFileName(e.currentTarget.files[0]["name"])
                    announceFileUpload(e.currentTarget.files[0], 'announce-card-front-upload-license');
                  }}
                  name={`user[driver_license]`}
                  accept="image/*;capture=camera"
                  id="driver-license-file-upload"
                  style={{visibility: "hidden", width: "0px"}}
                />
                <label htmlFor="driver-license-file-upload" className="btn-link text-decoration-none font-weight-semibold font-size-14">
                  <FontAwesomeIcon icon={faArrowUpFromBracket} style={{paddingRight: "4px"}} /> Upload front of ID
                </label>
                <label className="mx-2">{fileName}</label>
                <span id="announce-card-front-upload-license" aria-live="polite" style={{ visibility: "hidden", color: "var(--bs-green)" }}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {formAction.requestSocialSecurityNumber && (
        <div>
          <FabrxCheckbox
            name="id_type"
            ariaLabel='Social security #'
            onChange={() => {
              setFormAction({
                ...formAction,
                showLicenseInfo: false,
                showSsnInfo: true,
                showNoId: false,
              });
            }}
            checked={formAction.showSsnInfo || values.user.social_security_number || false}
            label={`Social security # ${formAction.requireSocialSecurityNumber ? '*' : ''}`}
            radio
          />
          {(formAction.showSsnInfo || values.user.social_security_number) && (
            <Row className='mb-3'>
              <Col sm={12} md={6}>
                <label htmlFor="social_security_number">SSN</label>
                <input
                  id="social_security_number"
                  name="user[social_security_number]"
                  className="form-control"
                  value={values.user.social_security_number || ''}
                  onChange={handleChange}
                  max-length="9"
                  min-length="9"
                />
              </Col>
            </Row>
          )}
        </div>
      )}
      {!formAction.requireDriversLicense && !formAction.requireSocialSecurityNumber && (
        <FabrxCheckbox
          name="id_type"
          ariaLabel='No identification'
          onChange={() => {
            setFormAction({
              ...formAction,
              showLicenseInfo: false,
              showSsnInfo: false,
              showNoId: true,
            });
          }}
          checked={formAction.showNoId || false}
          label='No identification'
          radio
        />
      )}
    </div>
  );
};

export default NoInsuranceFields;
