import React from 'react';

const Consent = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M4.90911 10.4242C4.71385 10.229 4.39726 10.229 4.202 10.4242C4.00674 10.6195 4.00674 10.9361 4.202 11.1313L4.90911 10.4242ZM6.33333 12.5556L5.97978 12.9091C6.17504 13.1044 6.49162 13.1044 6.68689 12.9091L6.33333 12.5556ZM10.2424 9.35355C10.4377 9.15829 10.4377 8.84171 10.2424 8.64645C10.0472 8.45118 9.7306 8.45118 9.53534 8.64645L10.2424 9.35355ZM12.9444 4.55556V15.2222H13.9444V4.55556H12.9444ZM11.6667 16.5H2.77778V17.5H11.6667V16.5ZM1.5 15.2222V4.55556H0.5V15.2222H1.5ZM2.77778 3.27778H4.55556V2.27778H2.77778V3.27778ZM9.88889 3.27778H11.6667V2.27778H9.88889V3.27778ZM2.77778 16.5C2.07208 16.5 1.5 15.9279 1.5 15.2222H0.5C0.5 16.4802 1.5198 17.5 2.77778 17.5V16.5ZM12.9444 15.2222C12.9444 15.9279 12.3724 16.5 11.6667 16.5V17.5C12.9246 17.5 13.9444 16.4802 13.9444 15.2222H12.9444ZM13.9444 4.55556C13.9444 3.29757 12.9246 2.27778 11.6667 2.27778V3.27778C12.3724 3.27778 12.9444 3.84986 12.9444 4.55556H13.9444ZM1.5 4.55556C1.5 3.84986 2.07208 3.27778 2.77778 3.27778V2.27778C1.5198 2.27778 0.5 3.29757 0.5 4.55556H1.5ZM4.202 11.1313L5.97978 12.9091L6.68689 12.202L4.90911 10.4242L4.202 11.1313ZM6.68689 12.9091L10.2424 9.35355L9.53534 8.64645L5.97978 12.202L6.68689 12.9091ZM6.33333 1.5H8.11111V0.5H6.33333V1.5ZM8.11111 4.05556H6.33333V5.05556H8.11111V4.05556ZM6.33333 4.05556C5.62764 4.05556 5.05556 3.48347 5.05556 2.77778H4.05556C4.05556 4.03576 5.07535 5.05556 6.33333 5.05556V4.05556ZM9.38889 2.77778C9.38889 3.48347 8.81681 4.05556 8.11111 4.05556V5.05556C9.36909 5.05556 10.3889 4.03576 10.3889 2.77778H9.38889ZM8.11111 1.5C8.81681 1.5 9.38889 2.07208 9.38889 2.77778H10.3889C10.3889 1.5198 9.36909 0.5 8.11111 0.5V1.5ZM6.33333 0.5C5.07535 0.5 4.05556 1.5198 4.05556 2.77778H5.05556C5.05556 2.07208 5.62764 1.5 6.33333 1.5V0.5Z"
      fill={color}
    />
  </svg>
);

export default Consent;
