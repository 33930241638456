import { useTranslation } from "react-i18next";

let locale;
if (window) {
  const urlParams = new URLSearchParams(window.location.search);
  locale = urlParams.get('locale');
}
if (!locale) {
  locale = document.getElementsByTagName('html')[0]?.getAttribute('lang') || 'en';
}

export const buildLocalizedString = (container, key, reactLocale = null) => {
  if (reactLocale) locale = reactLocale;
  const localizedSnippet = container.custom_text_snippets.filter(d => d.language === locale && d.phrase_key === key);
  const defaultSnippet = container.custom_text_snippets.filter(d => d.language === "en" && d.phrase_key === key);

  if (localizedSnippet.length == 1) {
    return localizedSnippet[0].content
  } else if (defaultSnippet.length == 1){
    return defaultSnippet[0].content
  } else {
    return ""
  }
}

export const buildCustomizedLabel = (container, key) => {
  const {i18n} = useTranslation();
  if (i18n.language) locale = i18n.language;
  const customizedLabel = buildLocalizedString(container, key);
  return customizedLabel ? customizedLabel : key;
};

export default locale;