import React, { useCallback, useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

const MagnifierContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const MagnifierGlass = styled.div`
  height: 200px;
  width: 300px;
  position: absolute;

  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  border: 1px solid #000;
  border-radius: 12px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
  cursor: crosshair;
`;

const ImageMagnifier = ({ src, scale = 2 }) => {
  const glassRef = useRef(null);
  const imageRef = useRef(null);

  const getCursorPos = useCallback(
    (e) => {
      const imageBox = imageRef.current.getBoundingClientRect();
      let x = e.pageX - imageBox.left;
      let y = e.pageY - imageBox.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;

      return { x, y };
    },
    [imageRef],
  );

  const onMouseMove = useCallback(
    (e) => {
      e.preventDefault();
      let { x, y } = getCursorPos(e);
      const imageBox = imageRef.current.getBoundingClientRect();
      const glassBox = glassRef.current.getBoundingClientRect();

      const imageWidth = imageBox.width;
      const imageHeight = imageBox.height;
      const glassWidth = glassBox.width;
      const glassHeight = glassBox.height;

      const maxLeft = imageWidth - glassWidth;
      const maxTop = imageHeight - glassHeight;
      const relX = x / imageWidth;
      const relY = y / imageHeight;

      const left = relX * maxLeft;
      const top = relY * maxTop;

      const glassWidthOffset = glassRef.current.offsetWidth / 2;
      const glassHeightOffset = glassRef.current.offsetHeight / 2;

      if (x > imageWidth - glassWidthOffset / scale) {
        x = imageWidth - glassWidthOffset / scale;
      }
      if (x < glassWidthOffset / scale) {
        x = glassWidthOffset / scale;
      }
      if (y > imageHeight - glassHeightOffset / scale) {
        y = imageHeight - glassHeightOffset / scale;
      }
      if (y < glassHeightOffset / scale) {
        y = glassHeightOffset / scale;
      }

      glassRef.current.style.left = `${left}px`;
      glassRef.current.style.top = `${top}px`;
      glassRef.current.style.backgroundPosition = `-${
        x * scale - glassWidthOffset
      }px -${y * scale - glassHeightOffset}px`;
    },
    [imageRef, glassRef, getCursorPos, scale],
  );

  const setGlassVisibiliy = useCallback(
    (show = true) => {
      glassRef.current.style.display = show ? 'block' : 'none';
    },
    [glassRef],
  );

  useEffect(() => {
    const imageBox = imageRef.current.getBoundingClientRect();
    glassRef.current.style.backgroundSize = `${imageBox.width * scale}px ${
      imageBox.height * scale
    }px`;
  }, [imageRef, glassRef, scale]);

  return (
    <MagnifierContainer className="image-magnifier">
      <MagnifierGlass
        ref={glassRef}
        className="magnifier-glass"
        src={src}
        onMouseMove={onMouseMove}
        onMouseEnter={() => setGlassVisibiliy(true)}
        onMouseLeave={() => setGlassVisibiliy(false)}
      />
      <Image
        ref={imageRef}
        className="flex-grow-1"
        src={src}
        fluid
        onMouseMove={onMouseMove}
        onMouseEnter={() => setGlassVisibiliy(true)}
        onMouseLeave={() => setGlassVisibiliy(false)}
      />
    </MagnifierContainer>
  );
};

export default ImageMagnifier;
