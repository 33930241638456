import React from 'react';
import TooltipWrapper from '../../../../common/components/TooltipWrapper';
import Wrapped from '../../../../common/components/Wrapped';

export const TwoLineItem = ({
  title,
  count,
  style,
  tooltip: tooltipContent,
}) => (
  <Wrapped
    WrapperComponent={TooltipWrapper}
    wrapIf={!!tooltipContent}
    componentProps={{ tooltipContent }}
  >
    <div className="m-auto d-flex flex-column align-items-center fw-bold">
      <span className="text-secondary" style={{ fontSize: '95%' }}>
        {title}
      </span>
      <span style={{ fontSize: '1.3rem', ...style }}>{count}</span>
    </div>
  </Wrapped>
);

export const ThreeLineItem = ({ title, count, footer, style, tooltip }) => {
  const Wrapper = tooltip ? TooltipWrapper : React.Fragment;
  const wrapperProps = tooltip ? { tooltip } : {};

  return (
    <Wrapper {...wrapperProps}>
      <div className="m-auto d-flex flex-column align-items-center fw-bold">
        <span className="text-secondary">{title}</span>
        <span style={{ fontSize: '1.5rem', ...style }}>{count}</span>
        <span style={{ fontSize: '95%' }}>{footer}</span>
      </div>
    </Wrapper>
  );
};
