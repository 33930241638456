import React, { useState, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const Marker = ({ map, location }) => {
  const [marker, setMarker] = useState();
  const svg = `
    <svg width="46" height="57" viewBox="0 0 52 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.6 25.8C48.6 43.5333 25.8 60 25.8 60C25.8 60 3 42.2667 3 25.8C3 13.2079 13.2079 3 25.8 3C38.3921 3 48.6 13.2079 48.6 25.8Z" fill="#EA3340" stroke="white" strokeWidth="5" />
      <circle cx="25.8" cy="25.8" r="8.55" fill="#EA3340" stroke="white" strokeWidth="5" />
    </svg>
  `

  const getInfoWindowString = (location) => {
    const test_group = location.test_group
    const test_location = location.test_location

    return (`
      <div class="search-result-item info-box">
        <div class="d-flex align-items-center">
          ${test_group.partner_logo_url
        ? `<img src="${test_group.partner_logo_url}" alt="${test_group.name}" class="search-result-item-logo me-2" />`
        : ""
      }
          <div class="inter medium lead mb-2">${test_group.name}</div>
        </div>
        <div class="body-medium-bold">${test_location.name}</div>
        <div class="body-medium-normal">${test_location.address}</div>
        <div class="text-end">
          <a href="/l/${test_group.slug}" class="btn btn-link py-2 px-3">
            Register
            <i class="fa-solid fa-arrow-right ms-2"></i>
          </a>
        </div>
      </div>
    `)
  }

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      const infowindow = new window.google.maps.InfoWindow({
        content: getInfoWindowString(location)
      });

      marker.setOptions({
        position: new google.maps.LatLng(location.test_location.latitude, location.test_location.longitude),
        icon: {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
        },
        map,
      });
      marker.addListener("click", () => {
        let activeWindow = document.querySelector(".gm-style-iw .gm-ui-hover-effect")
        if (activeWindow) { activeWindow.click() }

        infowindow.open({
          anchor: marker,
          shouldFocus: false
        });
      });
    }
  }, [marker]);

  return null;
}

const Map = ({ locations, center, zoom, MarkerComponent }) => {
  const ref = useRef();
  const [map, setMap] = useState();
  const options = {
    center: center,
    clickableIcons: false,
    mapTypeControl: false,
    overviewMapControl: false,
    rotateControl: true,
    streetViewControl: false,
    zoomControl: true,
    zoomControlOptions: { style: "DEFAULT" },
    zoom: zoom
  }

  useEffect(() => {
    if (map) { map.setOptions(options); }
  }, [map, options]);

  useEffect(() => {
    if (!map) {
      setMap(new window.google.maps.Map(ref.current, options))
    }
  }, [map]);

  return (
    <>
      <div id='map' ref={ref} className='primary-map'>
        {
          locations.map((location, index) => {
            return <MarkerComponent key={index} map={map} location={location} />
          })
        }
      </div>
    </>
  )
}

const PrimaryMap = ({ locations, center, zoom = 11, MarkerComponent=Marker }) => {
  const defaultCenter = {
    lat: parseFloat(locations[0]?.test_location.latitude),
    lng: parseFloat(locations[0]?.test_location.longitude)
  }

  const render = (status) => {
    if (status === Status.FAILURE) return <div>There was an error</div>;
    return <div className='map-wrapper'><Spinner animation="border" size="sm" /></div>;
  };

  return (
    <Wrapper render={render}>
      <Map center={center ? center : defaultCenter} zoom={zoom} locations={locations} MarkerComponent={MarkerComponent} />
    </Wrapper>
  );
}
export default PrimaryMap
