import React, { useMemo, useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import { sumProperty } from '../../common/utils';
import { formatTime, mergeAndSortSlots } from '../../common/utils/slotting';
import CloneSlotsModal from '../../common/components/CloneDayConfigurationModal';
import { EditableCell } from '../../DoseManagement';
import { faChartLine, faCheck, faClone, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

const ActionPanel = ({ showActionPanel }) => (
  <div
    className={`my-2 dropdown ${showActionPanel ? '' : 'd-none'}`}
    id="action-panel"
  >
    <button
      className="btn btn-primary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Bulk actions
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#update-modal"
      >
        Update
      </a>
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#bulk-cancel-modal"
      >
        Cancel
      </a>
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#bulk-reschedule-modal"
      >
        Reschedule
      </a>
      <a
        className="dropdown-item"
        id="submit-freeze-shared-capacities"
        href="#"
      >
        Freeze
      </a>
    </div>
  </div>
);

const TableHeaders = () => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Test location</th>
        <th>Day</th>
        <th>Hours</th>
        <th>Shared standard capacity</th>
        <th>Shared access key capacity</th>
        <th>Group standard capacity</th>
        <th>Group access key capacity</th>
        <th>Booked</th>
        <th>Administered</th>
        <th colSpan="1"></th>
      </tr>
    </thead>
  );
};

const SharedCapacityRow = ({
  sharedCapacity: {
    id,
    starts_at,
    ends_at,
    capacity,
    access_key_capacity,
    group_capacity,
    group_access_key_capacity,
    booked,
    administered,
    ...slot
  },
  group,
  expanded,
  updateSlot,
  createSlot,
  countsLoading,
  toggleActionPanel,
}) => {
  const isVirtualSlot = !id;
  const [editing, setEditing] = useState(false);
  const capacityFieldRef = useRef(null);
  const accessKeyCapacityFieldRef = useRef(null);

  const toggleEditing = (e) => {
    e.preventDefault();
    if (editing && updateSlot) {
      let params = {
        capacity: parseInt(capacityFieldRef.current.value) || 0,
        access_key_capacity:
          parseInt(accessKeyCapacityFieldRef.current.value) || 0,
      };

      if (isVirtualSlot) {
        params = {
          shared_capacity: {
            staged: true,
            date: slot.date,
            start_time_hour: starts_at.hour,
            start_time_minute: starts_at.min,
            end_time_hour: ends_at.hour,
            end_time_minute: ends_at.min,
            time_zone: slot.time_zone,
            test_location_id: slot.test_location_id,
            ...params,
          },
        };

        createSlot(params);
      } else {
        updateSlot(params);
      }
    }
    setEditing(!editing);
  };

  return (
    <tr className={expanded ? 'hoverable' : 'd-none'}>
      <td>
        <OverlayTrigger placement="top" overlay={<Tooltip>{id}</Tooltip>}>
          <div className="form-check">
            <input
              className="shared-capacity-slot-checkbox form-check-input"
              type="checkbox"
              name="shared_capacity_slots[][shared_capacity_id]"
              id="shared_capacity_slots__shared_capacity_id"
              group={group}
              disabled={isVirtualSlot}
              value={id}
              onClick={() => toggleActionPanel(id)}
            />
            <label className="form-check-label"></label>
          </div>
        </OverlayTrigger>
      </td>
      <td></td>
      <td></td>
      <td>
        {formatTime(starts_at)} - {formatTime(ends_at)}
      </td>
      <EditableCell
        innerRef={capacityFieldRef}
        value={capacity}
        editing={editing}
      />
      <EditableCell
        innerRef={accessKeyCapacityFieldRef}
        value={access_key_capacity}
        editing={editing}
      />
      {countsLoading ? (
        <React.Fragment>
          {[...Array(4).keys()].map((idx) => (
            <td style={{ opacity: 0.5 }} key={idx}>
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
            </td>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>{group_capacity}</td>
          <td>{group_access_key_capacity}</td>
          <td>{booked}</td>
          <td>{administered}</td>
        </React.Fragment>
      )}
      <td>
        {editing ? (
          <a onClick={toggleEditing} href="#">
            <FontAwesomeIcon icon={faCheck} /> Done
          </a>
        ) : (
          <a className="display-on-hover" onClick={toggleEditing} href="#">
            <FontAwesomeIcon icon={faPenToSquare} />{' '}
            {isVirtualSlot ? 'Create' : 'Edit'}
          </a>
        )}
      </td>
    </tr>
  );
};

const SharedCapacitiesGroup = ({
  display_date,
  date,
  day_name,
  location: { name: location_name },
  group_capacity: group_capacity_count,
  group_access_key_capacity: group_access_key_capacity_count,
  booked: booked_count,
  administered: administered_count,
  index: groupIndex,
  shared_capacities: propSharedCapacities,
  groupCountsPath,
  dose_management_path,
  cloneDay,
  setShowActionPanel,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [countsLoading, setCountsLoading] = useState(false);
  const [sharedCapacities, setSharedCapacities] =
    useState(propSharedCapacities);
  const [checkedCapacitySlots, setCheckedCapacitySlots] = useState([]);

  const details = useMemo(() => {
    const firstSlot = sharedCapacities[0];
    const lastSlot = sharedCapacities[sharedCapacities.length - 1];

    return {
      hours: `${formatTime(firstSlot.starts_at)} - ${formatTime(
        lastSlot.ends_at,
      )}`,
      capacity: sumProperty(sharedCapacities, 'capacity'),
      access_key_capacity: sumProperty(sharedCapacities, 'access_key_capacity'),
      group_capacity: sumProperty(sharedCapacities, 'group_capacity'),
      group_access_key_capacity: sumProperty(
        sharedCapacities,
        'group_access_key_capacity',
      ),
      booked: sumProperty(sharedCapacities, 'booked'),
      administered: sumProperty(sharedCapacities, 'administered'),
    };
  }, [sharedCapacities]);

  const updateSlotValues = (idx, values) => {
    const newSharedCapacities = [...sharedCapacities];
    newSharedCapacities[idx] = { ...newSharedCapacities[idx], ...values };
    setSharedCapacities(newSharedCapacities);
  };

  const updateSlot = (idx) => (values) => {
    axios
      .patch(`${sharedCapacities[idx].update_path}.json`, values)
      .then((response) => {
        const { data } = response;
        if (data.success) updateSlotValues(idx, values);
      });
  };

  const createSlot = (idx) => (values) => {
    axios.post(sharedCapacities[idx].create_path, values).then((response) => {
      const { data } = response;
      if (data.success) {
        const {
          shared_capacity: { id, capacity, access_key_capacity, update_path },
        } = data;
        updateSlotValues(idx, {
          id,
          capacity,
          access_key_capacity,
          update_path,
        });
      }
    });
  };

  const fetchGroupSlots = () => {
    setCountsLoading(true);

    axios
      .get(groupCountsPath, { params: { date } })
      .then((response) => {
        const { shared_capacities, group_only_slots } = response.data;
        setSharedCapacities(
          mergeAndSortSlots(shared_capacities, group_only_slots),
        );
        setCountsLoading(false);
      })
      .catch(() => {
        setCountsLoading(false);
      });
  };

  const toggleActionPanel = (id) => {
    setCheckedCapacitySlots(
      checkedCapacitySlots.includes(id)
        ? checkedCapacitySlots.filter((i) => i != id)
        : [...checkedCapacitySlots, id],
    );
  };

  useEffect(() => {
    if (checkedCapacitySlots.length > 0) {
      setShowActionPanel(true);
    } else {
      setShowActionPanel(false);
    }
  }, [checkedCapacitySlots]);

  return (
    <React.Fragment>
      <tr className="hoverable">
        <td>
          <div className="form-check">
            <input
              type="checkbox"
              className="apply-to-group form-check-input"
              name="apply_to_group"
              value="1"
              group={groupIndex}
            />
            <label className="form-check-label"></label>
          </div>
        </td>
        <td>{location_name}</td>
        <td>
          <span style={{ display: 'inline-block', minWidth: 32 }}>
            {day_name}
          </span>{' '}
          <span>{display_date}</span>
        </td>
        <td className="column">
          <div>{details['hours']}</div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (!expanded) fetchGroupSlots();
              setExpanded(!expanded);
            }}
          >
            {expanded ? 'Hide' : 'See/edit'} slots
          </a>
        </td>
        <td>{details['capacity']}</td>
        <td>{details['access_key_capacity']}</td>
        <td>{details['group_capacity'] || group_capacity_count}</td>
        <td>
          {details['group_access_key_capacity'] ||
            group_access_key_capacity_count}
        </td>
        <td>{details['booked'] || booked_count}</td>
        <td>{details['administered'] || administered_count}</td>
        <td>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a
              className="display-on-hover"
              onClick={(e) => {
                e.preventDefault();
                cloneDay(groupIndex);
              }}
              href="#"
            >
              <FontAwesomeIcon icon={faClone} /> Clone day
            </a>
            {dose_management_path && (
              <a
                className="display-on-hover"
                href={dose_management_path}
                target="blank"
              >
                <FontAwesomeIcon icon={faChartLine} /> Dose management
              </a>
            )}
          </div>
        </td>
      </tr>
      {countsLoading ? (
        <tr>
          <td colSpan="20">
            <div className="d-flex">
              <div className="m-auto">
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Retrieving slots...
              </div>
            </div>
          </td>
        </tr>
      ) : (
        sharedCapacities.map((sharedCapacity, index) => (
          <SharedCapacityRow
            key={index}
            group={groupIndex}
            sharedCapacity={sharedCapacity}
            expanded={expanded}
            updateSlot={updateSlot(index)}
            createSlot={createSlot(index)}
            countsLoading={countsLoading}
            toggleActionPanel={toggleActionPanel}
          />
        ))
      )}
    </React.Fragment>
  );
};

export const GroupedSharedCapacities = ({
  shared_capacities_groups: sharedCapacitiesGroups,
  clone_slots_path: cloneSlotsPath,
  group_counts_path: groupCountsPath,
}) => {
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [slotGroupsToClone, setSlotGroupsToClone] = useState([]);
  const [showActionPanel, setShowActionPanel] = useState(false);

  const startCloneDay = (groupIndex) => {
    setCloneModalOpen(true);
    setSlotGroupsToClone([sharedCapacitiesGroups[groupIndex]]);
  };

  return (
    <React.Fragment>
      <ActionPanel showActionPanel={showActionPanel} />
      <section className="fabrx-tables-light table-responsive">
        <table className="table table-lg table-floating-headers">
          <TableHeaders />
          <tbody>
            {sharedCapacitiesGroups.map((sharedCapacitiesGroup, index) => (
              <SharedCapacitiesGroup
                key={index}
                index={index}
                cloneDay={startCloneDay}
                groupCountsPath={groupCountsPath}
                setShowActionPanel={setShowActionPanel}
                {...sharedCapacitiesGroup}
              />
            ))}
          </tbody>
        </table>
      </section>
      <CloneSlotsModal
        show={cloneModalOpen}
        onHide={() => setCloneModalOpen(false)}
        cloneDayPath={cloneSlotsPath}
        groupsToClone={slotGroupsToClone}
      />
    </React.Fragment>
  );
};
