import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface IconProps extends React.HTMLProps<HTMLImageElement> {
  path: string;
  color?: string;
}

const ImgCssProvider = styled.img`
  // mask: url(${({ src }) => src}) no-repeat center;

  ${({ color }) =>
    color &&
    css`
      --svg-color: ${color};
    `}
`;

const Icon: FC<IconProps> = ({ path, ...props }) => (
  <ImgCssProvider src={path} {...props} />
);

export default Icon;
