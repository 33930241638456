import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import { Button, Col, Collapse, Modal, Row, Spinner } from 'react-bootstrap';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import SignatureInput from './inputs/SignatureInput';
import FabrxCheckbox from '../../primary/FabrxCheckbox';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { calculateAge } from '../../../common/utils/index';
import RegistrationContext from '../../contexts/RegistrationContext';
import { requiredByTestGroup } from './ContactInformation';
import Flipper from '../../../common/components/Flipper';
import { SectionTitle } from '../SectionComponents';
import styled from 'styled-components';
import ContactSupport from '../../../common/components/ContactSupport';
import { VaccineInfoSheetVersions } from '../../../TestGroupTestConfigurations/components/VaccineTestGroupTestConfigurationInputFields';
import { ChevronDropDown } from '../../../common/components/Chevron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const VERBAL_CONSENTS = ["consented_by_decision_maker", "consented_by_guardian_verbal", "consented_by_registrar"]

const ConsentContainer = styled.div`
  height: 250px;
  overflow: auto;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  padding: 8px 4px 8px 10px;
  margin-top: 24px;
`

export const validateConsent = (testGroup) => (values) => {
  const errors = {};
  const relationshipType = values.appointment.consented_guardian_relationship_type
  const urlParams = new URLSearchParams(window.location.search);
  const consentBypassed = urlParams.get('consent_bypass') === 'horse-drawn-wagon';
  let ageOfConsent = true;

  var requireGuardian = testGroup.population.require_guardian;
  if (!values['date_of_birth_datetime(2i)'] && !values['date_of_birth_datetime(3i)'] && !values['date_of_birth_datetime(1i)']) {
    requireGuardian = !values.over_age_of_consent
  } else {
    const dateOfBirthString = [values['date_of_birth_datetime(2i)'], values['date_of_birth_datetime(3i)'], values['date_of_birth_datetime(1i)']].join('/');
    const dateOfBirth = new Date(dateOfBirthString)
    const age = calculateAge(dateOfBirth);

    if (age < testGroup.minimum_age_of_consent) ageOfConsent = false;
    if (ageOfConsent) {
      if (!values.appointment.signature_base64 && !consentBypassed) errors.signature_base64 = 'registration.errors.signature_required';
    }
  }

  if (!testGroup.active_consent && (ageOfConsent || requireGuardian)) {
    errors.missing_active_consent = true;
  }

  if (
    testGroup.flipper_flags?.verbal_consent_notes &&
    (relationshipType === "consented_by_registrar" || relationshipType === "consented_by_guardian_verbal")
  ) {
    if (!values.appointment.consent_forms_user_notes) errors.consent_forms_user_notes = 'registration.errors.field_required';
  }

  if (values.appointment.guardian_consented || requireGuardian) {
    const requiresGuardianInformation = ['consented_by_parent_guardian', 'consented_by_guardian_verbal'].includes(relationshipType);
    const requiresRegistrarInformation = VERBAL_CONSENTS.includes(relationshipType)

    if (!relationshipType) {
      errors.consented_guardian_relationship_type = 'registration.errors.option_required';
    }

    if (requiresGuardianInformation) {
      if (!values.appointment.consented_guardian_name) errors.guardian_name_required = 'registration.errors.field_required';
      if (!values.appointment.consented_guardian_last_name) errors.guardian_last_name_required = 'registration.errors.field_required';
      if (!values.appointment.consented_guardian_phone_number && requiredByTestGroup(testGroup, "phone_number")) errors.consented_guardian_phone_number = 'registration.errors.field_required';
      if (!values.appointment.consented_guardian_email && requiredByTestGroup(testGroup, "email")) errors.consented_guardian_email = 'registration.errors.field_required';
    }

    if (requiresRegistrarInformation) {
      if (!values.appointment.consented_registrar_name) errors.registrar_name_required = 'registration.errors.field_required';
      if (!values.appointment.consented_registrar_last_name) errors.registrar_last_name_required = 'registration.errors.field_required'; 
    }

    if (!values.appointment.signature_base64 && !consentBypassed) {
      errors.signature_base64 = 'registration.errors.signature_required';
    }
  }

  return errors;
}

export const setGuardianOptions = (allowVerbalConsent, ageOfConsent, t) => ([
  { value: 'consented_by_parent_guardian', label: t("consent_waiver.consented_by_parent_guardian") },
  { value: 'consented_by_decision_maker', label: t("consent_waiver.consented_by_decision_maker") },
  allowVerbalConsent && (
    { value: 'consented_by_guardian_verbal', label: t("consent_waiver.consented_by_guardian_verbal") }
  ),
  allowVerbalConsent && ageOfConsent && (
    { value: 'consented_by_registrar', label: t("consent_waiver.consented_by_registrar") }
  ),
].filter(option => option));

export const GuardianForm = ({values, name, errors, user, t, handleChange, setFieldValue, testGroup}) => {
  const relationshipType = values.consented_guardian_relationship_type;
  if (!relationshipType) return <></>;

  const hasGuardian = ["consented_by_guardian_verbal", "consented_by_parent_guardian"].includes(relationshipType);
  const hasRegistrar = ["consented_by_guardian_verbal", "consented_by_registrar", "consented_by_decision_maker"].includes(relationshipType);

  const labelsForErrors = {
    registrar_name_required: "consent_registrar_first_name_signature",
    registrar_last_name_required: "consent_registrar_last_name_signature",
    guardian_name_required: "consent_registrar_with_guardian_name_signature",
    guardian_last_name_required: "consent_registrar_with_guardian_last_name_signature",
    consented_guardian_email:  "email",
    consented_guardian_phone_number: "phone_number",
    consented_registrar_email: "registrar_phone",
    consented_registrar_phone: "registrar_email",
  }

  const consentFormNotes = {
    adaConsentRequired: t("registration.verbal_consent_provided"),
    expired: t("registration.unexpired_written_consent_present"), 
  };

  const handleFullNameChange = (e, prependValue) => {
    const fullNameInput = e.target.value,
      trimmedFullNameInputParts = fullNameInput.trim().split(' '),
      firstName = trimmedFullNameInputParts.slice(0, -1).join(' '),
      lastName = trimmedFullNameInputParts.slice(-1).join(' '),
      inputPrependName = name ? `${name}.${prependValue}` : prependValue;
    setFieldValue(`${inputPrependName}_full_name`, fullNameInput)
    setFieldValue(`${inputPrependName}_name`, firstName)
    setFieldValue(`${inputPrependName}_last_name`, lastName)
  };

  return (
    <div className="my-3">
      <Flipper record={testGroup} flag="verbal_consent_notes">
        <Flipper.Enabled>
          {(relationshipType === "consented_by_registrar" || relationshipType === "consented_by_guardian_verbal") &&
            <> 
              <div>
                <FabrxCheckbox
                  name="ada_consent_required_24_hours"
                  radio
                  onChange={() => setFieldValue(`${name}.consent_forms_user_notes`, consentFormNotes.adaConsentRequired)}
                  checked={values.consent_forms_user_notes == consentFormNotes.adaConsentRequired}
                  label={consentFormNotes.adaConsentRequired}
                /> 
              </div>
              <div>
                <FabrxCheckbox
                  name="site_has_expired_consent"
                  radio
                  onChange={() => setFieldValue(`${name}.consent_forms_user_notes`, consentFormNotes.expired)}
                  checked={values.consent_forms_user_notes == consentFormNotes.expired}
                  label={consentFormNotes.expired}
                />
              </div>
              {errors.consent_forms_user_notes &&
                <ErrorMessage message={t("registration.select_to_proceed")} />
              }
            </>
          }
        </Flipper.Enabled>
      </Flipper>
      {t(`consent_waiver.consent_on_behalf_registrar${relationshipType == "consented_by_parent_guardian" ? "_with_guardian" : ""}`, {
        first_name: user.first_name,
        last_name: user.last_name
      })}
      <Row className='gx-2'>
        {hasRegistrar &&
          <>
            {hasGuardian && <Col xs={12} className='mt-2'>{t("registration.registrars_information")}</Col>}
            <Col md={4} xs={12}>
              <FloatingLabelInput
                name={`${name}.consented_registrar_full_name`}
                value={values.consented_registrar_full_name || ""}
                onChange={(e) => handleFullNameChange(e, "consented_registrar")}
                className="my-2"
                label={t("consent_waiver.full_name")}
                id="consented_registrar_name"
                validation={errors.registrar_name_required && 'is-invalid'}
              />
            </Col>
          </>
        }
        {hasGuardian &&
          <>
            {hasRegistrar && <Col xs={12} className='mt-2'>{t("registration.guardians_information")}</Col>}
            <Col md={4} xs={12}>
              <FloatingLabelInput
                name={`${name}.consented_guardian_full_name`}
                value={values.consented_guardian_full_name || ""}
                onChange={(e) => handleFullNameChange(e, "consented_guardian")}
                className="my-2"
                label={t("consent_waiver.full_name")}
                id="consented_guardian_full_name"
                validation={errors.consented_guardian_name && 'is-invalid'}
              />
            </Col>
          </>
        }
        <Col md={4} xs={12}>
          <FloatingLabelInput
            name={`${name}.consented_guardian_email`}
            value={values.consented_guardian_email || ""}
            onChange={handleChange}
            className="my-2"
            label={t("registration.email")}
            id="consented_guardian_email"
            validation={errors[hasGuardian ? "consented_guardian_email" : "consented_registrar_email"] && 'is-invalid'}
          />
        </Col>
        <Col md={4} xs={12}>
          <FloatingLabelInput
            name={`${name}.consented_guardian_phone_number`}
            value={values.consented_guardian_phone_number || ""}
            onChange={handleChange}
            className="my-2"
            label={t("consent_waiver.phone_number")}
            id="consented_guardian_phone_number"
            validation={errors[hasGuardian ? "consented_guardian_phone_number" : "consented_registrar_phone"] && 'is-invalid'}
          />
        </Col>
      </Row>
      {Object.keys(labelsForErrors).map(key => errors[key] &&
        <ErrorMessage message={
          t((errors[key]), {
            field: t(`consent_waiver.${labelsForErrors[key]}`)
          })}
        />
      )}
    </div>
  )
}

const ConsentAndVaccineInfoSheets = ({
  chosenTestConfigurationIds,
  consent,
  consentRef,
  language,
  printConsent,
  t,
  testGroup,
}) => {

  const [vaccineInfoSheetVersions, setVaccineInfoSheetVersions] = useState([]);
  const [loadingVis, setLoadingVis] = useState(true);
  const [showConsent, setShowConsent] = useState(true);
  const [showVis, setShowVis] = useState(false);

  const getVaccineInfoSheetVersions = async () => {
    setLoadingVis(true);
    const response = await axios.get(`/test_groups/${testGroup.id}/approvable_vaccine_info_sheet_versions.json`, {
      params: {
        locale: language,
        chosen_test_configuration_ids: chosenTestConfigurationIds,
      }
    });
    setVaccineInfoSheetVersions(response.data);
    setLoadingVis(false);
  }

  useEffect(() => {
    getVaccineInfoSheetVersions();
  }, [language])

  return (
    <div>
      <Button
        onClick={() => {
          setShowConsent(true);
          setShowVis(true);
        }}
        variant="link"
      >
        <FontAwesomeIcon className="me-2" icon={faPlus} />
        View all items for consent
      </Button>
      <div>
        <div className="d-flex my-auto pointer" onClick={() => setShowConsent(!showConsent)}>
          <ChevronDropDown className="float-start" open={showConsent} setOpen={setShowConsent} style={{ fill: "black", fontSize: 16 }} />
          <h6 className="my-auto">Consent for services</h6>
        </div>
        <Collapse in={showConsent}>
          <div className="mx-5 my-3" ref={consentRef}>
            <LocalizedMarkdown container={consent} stringKey='consent_form_text' />
            <div className="d-flex justify-content-end my-2">
              <span
                className='text-secondary fw-bold text-decoration-underline pointer'
                style={{fontSize: 14}}
                onClick={printConsent}
              >
                {t("consent_waiver.print")}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
      <div>
        <div className="d-flex my-auto pointer" onClick={() => setShowVis(!showVis)}>
          <ChevronDropDown className="float-start" open={showVis} setOpen={setShowVis} style={{ color: "black", fontSize: 16 }} />
          <h6 className="my-auto">Vaccine information statements</h6>
        </div>
        <Collapse in={showVis}>
          <div className="m-3">
            {loadingVis ? (
              <Spinner variant="primary" animation="border" />
            ) : (
              <VaccineInfoSheetVersions vaccineInfoSheetVersions={vaccineInfoSheetVersions} />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export const ConsentBase = ({
  testGroup,
  values,
  errors,
  setFieldValue,
  handleChange,
}) => {

  const { t, i18n } = useTranslation();
  const consentRef = useRef();
  const printConsent = useCallback(() => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(consentRef.current.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  },[consentRef])

  var ageOfConsent = true;
  var requireGuardian = testGroup.population.require_guardian;
  const showVaccineInfoSheets = !!testGroup.approvable_vaccine_info_sheet_versions?.length;
  const [showSupportModal, setShowSupportModal] = useState(false);

  if (!values['date_of_birth_datetime(2i)'] && !values['date_of_birth_datetime(3i)'] && !values['date_of_birth_datetime(1i)']) {
    requireGuardian = !values.over_age_of_consent
  } else {
    const dateOfBirthString = [values['date_of_birth_datetime(2i)'], values['date_of_birth_datetime(3i)'], values['date_of_birth_datetime(1i)']].join('/');
    const dateOfBirth = new Date(dateOfBirthString);
    const age = calculateAge(dateOfBirth);

    if (age < testGroup.minimum_age_of_consent) {
      ageOfConsent = false;
    }
  }

  const consent = testGroup.active_consent || testGroup;
  const guardianOptions =  setGuardianOptions(testGroup.allow_verbal_consent, ageOfConsent, t);

  useEffect(() => {
    if (requireGuardian) {
      setFieldValue('appointment.consented_guardian_relationship_type', "consented_by_parent_guardian")
    }
  }, [])

  const title =
    ageOfConsent || requireGuardian
      ? t('registration.consent_for', {
        name: `${values.first_name} ${values.last_name}`,
      })
      : t('registration.consent');

  const guardianRelationshipFieldRef = useRef(null)
  useEffect(() => {
    if(errors.consented_guardian_relationship_type) {
      guardianRelationshipFieldRef.current?.focus()
    } else if(errors.registrar_name_required) {
      document.getElementById("consented_registrar_name")?.focus()
    } else if(errors.registrar_last_name_required) {
      document.getElementById("consented_registrar_last_name")?.focus()
    } else if(errors.guardian_name_required) {
      document.getElementById("consented_guardian_name")?.focus()
    } else if (errors.guardian_last_name_required) {
      document.getElementById("consented_guardian_last_name")?.focus()
    }
  }, [errors])

  return (
    <div className="form-section">
      <Modal
        className="p-4"
        onHide={() => setShowSupportModal(false)}
        size="md"
        show={showSupportModal}
      >
        <ContactSupport />
      </Modal>
      <SectionTitle>{title}</SectionTitle>
      {
        (ageOfConsent || requireGuardian) ? (
          <React.Fragment>
            {showVaccineInfoSheets ? (
              <ConsentAndVaccineInfoSheets
                chosenTestConfigurationIds={values.appointment.chosen_test_configurations?.map(ct => ct.test_configuration_id)}
                consent={consent}
                consentRef={consentRef}
                language={i18n.language}
                printConsent={printConsent}
                t={t}
                testGroup={testGroup}
              />
            ) : (
              <ConsentContainer ref={consentRef}>
                <LocalizedMarkdown container={consent} stringKey='consent_form_text' />
              </ConsentContainer>
            )}
            <div className="d-flex justify-content-between my-2">
              <span>
                {errors.missing_active_consent && (
                  <ErrorMessage
                    message={
                      <div>
                        Missing consent form. Please reach out to your group administrator <br/> or {" "}
                        <span
                          className="underline pointer font-weight-bold"
                          onClick={() => setShowSupportModal(true)}
                        >
                          contact support
                        </span>.
                      </div>
                    }
                  />
                )}
              </span>
              {!showVaccineInfoSheets && (
                <span
                  className='text-secondary fw-bold text-decoration-underline pointer'
                  style={{fontSize: 14}}
                  onClick={printConsent}
                >
                  {t("consent_waiver.print")}
                </span>
              )}
            </div>
            <div style={{clear: "both"}}></div>
            <div className="my-3">
              {!requireGuardian &&
                <div style={{marginLeft: 32}}>
                  <FabrxCheckbox
                    name="appointment[guardian_consented]"
                    autoFocus
                    ariaLabel={t("registration.self_consent")}
                    checked={!values.appointment.guardian_consented}
                    radio
                    onChange={() => {
                      const checked = !!!values.appointment.guardian_consented;
                      setFieldValue('appointment.guardian_consented', checked)
                    }}
                    label={`${t('registration.self_consent')}, ${values.first_name} ${values.last_name}`} />
                  <FabrxCheckbox
                    name="appointment[guardian_consented]"
                    ariaLabel={t('registration.insist_guardian_consent')}
                    checked={!!values.appointment.guardian_consented}
                    radio
                    dataTestHook="insist_guardian_consent"
                    onChange={() => {
                      const checked = !!!values.appointment.guardian_consented;
                      setFieldValue('appointment.guardian_consented', checked)
                    }}
                    label={`${t('registration.insist_guardian_consent')} ${values.first_name} ${values.last_name}`} />
                </div>
              }
              {
                (values.appointment.guardian_consented || requireGuardian) &&
                <Row className="form-inline mt-3" style={{marginLeft: 32}}>
                  <Col md={12} lg={8}>
                    <FloatingLabelSelect
                      inputRef={guardianRelationshipFieldRef}
                      ariaLabel="Select consented relationship"
                      value={guardianOptions.find((o) => o.value === values.appointment.consented_guardian_relationship_type)}
                      onChange={(selectedOption) => {
                        setFieldValue('appointment.consented_guardian_relationship_type', selectedOption.value)
                      }}
                      options={guardianOptions}
                      ariaDescribedby="guardianRelationshipDesc"
                      id="guardian_relationship_type"
                      filledValue={values.appointment.consented_guardian_relationship_type}
                      label={t('consented_relationship')}
                      className={errors.consented_guardian_relationship_type && 'is-invalid'}
                    />
                    {errors.consented_guardian_relationship_type && (
                      <ErrorMessage
                        id="guardianRelationshipDesc"
                        className="mb-2"
                        message={t(errors.consented_guardian_relationship_type)}
                      />
                    )}
                  </Col>
                  <GuardianForm
                    values={values.appointment}
                    name="appointment"
                    user={values}
                    {...{errors, t, handleChange, setFieldValue, testGroup}}
                  />
                </Row>
              }
            </div>
            <SignatureInput initialSignatureBase64={values.appointment.signature_base64} errors={errors} setFieldValue={setFieldValue} fieldValueName='appointment.signature_base64' />
          </React.Fragment>
        ) : <div className="text-muted">{t('consent_waiver.after_you_schedule', { age: testGroup.minimum_age_of_consent || 18 })} </div>
      }
      <Flipper feature={testGroup.may_have_test_group_test_configuration_consent}>
        <Flipper.Enabled>
          <div>{t('registration.additional_consents_helpertext')}</div>
        </Flipper.Enabled>
      </Flipper>
    </div>
  );
};

const Consent = (props) => {
  const { testGroup } = useContext(RegistrationContext);

  return <ConsentBase {...props} testGroup={testGroup} />;
};

export default Consent;
