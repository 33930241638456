import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import DownloadImmunizationRecordsModal from './DownloadImmunizationRecordsModal';

const PrintImmunizationFormsButton = ({
  testGroupId,
  organizationId
}) => {
  const [showDownloadImmunizationRecordsModal, setShowDownloadImmunizationRecordsModal] = useState(false);

  const printImmunizationForm = async (staffInitials) => {
    axios
      .post(`/organizations/${organizationId}/exports?export_params%5Bstaff_initials%5D=${staffInitials}&export_params%5Btest_group_id%5D=${testGroupId}&export_type=immunization_forms`)
      .then((_response) => { window.open('/exports', '_blank') })
  };

  return (
    <>
      <DownloadImmunizationRecordsModal
        showDownloadImmunizationRecordsModal={showDownloadImmunizationRecordsModal}
        setShowDownloadImmunizationRecordsModal={setShowDownloadImmunizationRecordsModal}
        onPrintClick={printImmunizationForm}/>
      <Button
        size="sm"
        className="mx-1"
        variant="outline-tertiary"
        onClick={() => setShowDownloadImmunizationRecordsModal(!showDownloadImmunizationRecordsModal)}
      >
        <FontAwesomeIcon
          icon={faUpRightFromSquare}
          className="ms-1"
        />
        &nbsp;Download all records
      </Button>
    </>
  )
};

export default PrintImmunizationFormsButton;
