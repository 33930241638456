import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const MAX_INITIAL_ZOOM = 18;

function buildPolygon(points) {
  const coords = points.map(function (point) {
    return { lat: point[0], lng: point[1] };
  });
  // Close the box
  coords.push(coords[0]);

  return new google.maps.Polygon({
    paths: coords,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  });
}

function buildPin({ marker, map, onMarkerClick }) {
  const { location, icon, data, key } = marker;
  const pin = new google.maps.Marker({
    position: {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    },
    title: 'marker',
  });

  if (icon) {
    pin.setIcon({
      url: icon,
      scaledSize: new google.maps.Size(50, 50),
    });
  }
  pin.data = { key, data };

  pin.addListener('click', (event) => {
    onMarkerClick(pin.data.data);
  });
  pin.setMap(map);
  return pin;
}

const GeoMap = ({
  polygons,
  markers,
  onMarkerClick,
  height = '500px',
  fullScreenControl = true,
  streetViewControl = true,
  className
}) => {
  const [map, setMap] = useState();
  const [pins, setPins] = useState([]);
  const mapRef = useRef();

  useEffect(() => {
    if (!window) return;
    const map = new google.maps.Map(mapRef.current, {
      streetViewControl,
      fullscreenControl: fullScreenControl,
      mapTypeControlOptions: {
        mapTypeIds: google.maps.MapTypeId.ROADMAP,
        style: google.maps.MapTypeControlStyle.DEFAULT,
      },
    });
    setMap(map);

    const bounds = new google.maps.LatLngBounds();
    const newPins = markers.map((marker) => {
      const pin = buildPin({ marker, map, onMarkerClick });
      bounds.extend(pin.getPosition());
      return pin;
    });

    if (!bounds.isEmpty()) {
      if (newPins.length === 1) {
        map.setCenter(newPins[0].getPosition());
        map.setZoom(MAX_INITIAL_ZOOM);
      } else {
        const padding = 50;
        map.fitBounds(bounds, padding);
      }
    }
    setPins(newPins);

    const mapPolygons = polygons.map((points) => {
      const polygon = buildPolygon(points);
      polygon.setMap(map);
      points.forEach((point) =>
        bounds.extend({ lat: point[0], lng: point[1] }),
      );
    });

    return () => {
      pins.forEach((pin) => {
        google.maps.event.clearInstanceListeners(pin);
        pin.setMap(null);
      });
      mapPolygons.forEach((polygon) => polygon.setMap(null));
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    markers.forEach((marker) => {
      const pin = pins.find((pin) => pin.data.key == marker.key);
      if (pin) {
        if (marker.icon) {
          pin.setIcon({ ...pin.getIcon(), url: marker.icon });
        }
        pin.data.data = marker.data;
      } else {
        const pin = buildPin({ marker, map, onMarkerClick });
        pins.push(pin);
      }
    });

    pins.forEach((pin) => {
      // Remove pins no longer in markers
      if (!markers.some((marker) => pin.data.key === marker.key)) {
        pin.setMap(null);
      }
    });

    const newPins = pins.filter((pin) => pin.getMap());

    if (newPins.length === 1) {
      map.setCenter(newPins[0].getPosition());
    }

    setPins(newPins);
  }, [map, markers]);

  return <div style={{ height: height }} ref={mapRef} className={`mb-3 ${className}`} id="map"></div>;
};

GeoMap.propTypes = {
  markers: PropTypes.array,
  polygons: PropTypes.array,
  onMarkerClick: PropTypes.func,
};

GeoMap.defaultProps = {
  onMarkerClick: () => {},
  markers: [],
  polygons: [],
};

export default GeoMap;
