import React, { useContext, useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import ErrorMessage from '../../common/components/ErrorMessage';
import InsuranceContext from '../contexts/InsuranceContext';

const PolicyInformationFields = ({
  setFieldValue,
  values,
  errors,
  handleChange,
  onShowInsuranceBack=() => {},
  names={
    insuranceCardFront: "insurance_card_front",
    showInsuranceBack: "showInsuranceBack",
    insuranceCardBack: "insurance_card_back",
    insuranceCompany: "insurance_company",
    insuranceId: "insurance_id",
    insuranceGroupNumber: "insurance_group_number",
  }
}) => {
  const { t, i18n } = useTranslation();
  const { formAction, testGroup } = useContext(InsuranceContext);
  const {
    insuranceCardFront,
    showInsuranceBack,
    insuranceCardBack,
    insuranceCompany,
    insuranceId,
    insuranceGroupNumber,
  } = names
  const insuranceCompanyOptions = testGroup.insurance_companies.map(v => ({ label: v, value: v }))
  const announceFileUpload = (file, nodeId) => {
    const announceFileUploadRoot = document.getElementById(nodeId);
    if (file === undefined) {
      announceFileUploadRoot.style.visibility = 'hidden';
    } else {
      announceFileUploadRoot.style.visibility = 'visible';
      announceFileUploadRoot.focus();
    }
  }
  const [insuranceFrontName, setInsuranceFrontName] = useState(null);
  const [insuranceBackName, setInsuranceBackName] = useState(null);

  const errorMessage = (error) => (
    errors[error] && <ErrorMessage message={t(errors[error])} />
  );
  return (
    <div className="pt-4 animate__animated animate__fadeIn">
      <b>{t('no_insurance_information.upload_front_of_insurance_card')}</b>
      <div className="mb-3">
        <label className="form-label">{t('no_insurance_information.insurance_card')}</label><br/>
        <input
          id={"front-of-card" + insuranceCardFront}
          style={{visibility: "hidden", width: "0px"}}
          type="file"
          onChange={(e) => {
            setFieldValue(`user[${insuranceCardFront}]`, e.currentTarget.files[0]);
            setInsuranceFrontName(e.currentTarget.files[0]["name"]);
            announceFileUpload(e.currentTarget.files[0], 'announce-card-front-upload' + insuranceCardFront);
          }}
          name={`user[${insuranceCardFront}]`}
          accept="image/*;capture=camera"
        />
        <label htmlFor={"front-of-card" + insuranceCardFront} className="btn-sm btn-outline-secondary text-center"><b>{t('no_insurance_information.choose_file')}</b></label>
        <label className="mx-2">{insuranceFrontName || t('no_insurance_information.no_file_chosen')}</label>
        <br />
        <span id={"announce-card-front-upload" + insuranceCardFront} aria-live="polite" tabIndex="0" style={{ visibility: "hidden" }}>
          {t('no_insurance_information.front_of_insurance_card_uploaded')}
        </span>
        {errorMessage(insuranceCardFront)}
      </div>
      <button
        type="button"
        className="btn btn-outline-secondary mb-3 btn-block"
        onClick={onShowInsuranceBack}
      >
        {t('no_insurance_information.insurance_card_has_a_back')}
      </button>
      {
        formAction[showInsuranceBack] ? (
          <div className="mb-3 animate__animated animate__fadeIn">
            <label className="form-label">{t('no_insurance_information.insurance_card_back')}</label><br/>
            <input
              id={"back-of-card" + insuranceCardBack}
              style={{visibility: "hidden", width: "0px"}}
              type="file"
              name={`user[${insuranceCardBack}]`}
              onChange={(e) => {
                setFieldValue(`user[${insuranceCardBack}]`, e.currentTarget.files[0]);
                setInsuranceBackName(e.currentTarget.files[0]["name"]);
                announceFileUpload(e.currentTarget.files[0], 'announce-card-back-upload' + insuranceCardBack);
              }}
              accept="image/*;capture=camera"
            />
            <label htmlFor={"back-of-card" + insuranceCardBack} className="btn-sm btn-outline-secondary text-center"><b>{t('no_insurance_information.choose_file')}</b></label>
            <label className="mx-2">{insuranceBackName || t('no_insurance_information.no_file_chosen')}</label>
            <span id={"announce-card-back-upload" + insuranceCardBack} aria-live="polite" tabIndex="0" style={{ visibility: "hidden" }}>
              {t('no_insurance_information.back_of_insurance_card_uploaded')}
            </span>
          </div>
        ) : null
      }
    
      <div className={'input-container select-container mt-3'}>
        <CreatableSelect
          id={insuranceCompany}
          name={`user[${insuranceCompany}]`}
          value={{label: values.user[insuranceCompany], value: values.user[insuranceCompany]}}
          onChange={(s) => setFieldValue(`user[${insuranceCompany}]`, s.value)}
          options={insuranceCompanyOptions}
          formatCreateLabel={(value) => `Other - click here to add: ${value}`}
          aria-label={t('registration.insurance_information.company_name')}
          className={errors[insuranceCompany] && 'is-invalid'}
        />
        <label className={values.user[insuranceCompany] && 'filled'} htmlFor={insuranceCompany}>
          {t('registration.insurance_information.company_name')}
        </label>
      </div>
      {errorMessage(insuranceCompany)}
      <br/>
      
      <FloatingLabelInput
        name={`user[${insuranceId}]`}
        className="mt-3"
        value={values.user[insuranceId] || ""}
        onChange={handleChange}
        ariaLabel={t('registration.insurance_information.id_number')}
        ariaInvalid={!!errorMessage(insuranceId)}
        id={insuranceId}
        label={t('registration.insurance_information.id_number')}
        validation={errorMessage(insuranceId) && 'is-invalid'}
      />
      {errorMessage(insuranceId)}
      <br/>

      <FloatingLabelInput
        name={`user[${insuranceGroupNumber}]`}
        className="mt-3"
        value={values.user[insuranceGroupNumber] || ""}
        onChange={handleChange}
        ariaLabel={t('registration.insurance_information.group_number')}
        id="group_number"
        label={t('registration.insurance_information.group_number')}
      />
    </div>
  );
};

export default PolicyInformationFields;
