import React, { FC } from 'react';

import AssigneeDropdown from './AssigneeDropdown';
import { AssignableUser, Test } from './Index';
import { formatNumberWithCommas } from '../../common/utils';

interface TopBarProps {
  showErrorMessage: boolean;
  testsCount: number;
  selectedTests: Test[];
  assignableUsers: AssignableUser[];
}

const TopBar: FC<TopBarProps> = ({
  showErrorMessage = false,
  testsCount,
  selectedTests,
  assignableUsers,
}) => (
  <div className="d-flex flex-row justify-content-between align-items-center">
    {showErrorMessage ? (
      <span className="font-weight-bold text-danger">
        There was an error loading the results. Please try again later.
      </span>
    ) : (
      <span className="font-weight-bold">
        {testsCount !== undefined
          ? `${formatNumberWithCommas(testsCount)} results`
          : 'Loading results count...'}
      </span>
    )}
    {selectedTests.length > 0 && (
      <AssigneeDropdown
        selectedTests={selectedTests}
        assignableUsers={assignableUsers}
        refreshOnAssign
      />
    )}
  </div>
);

export default TopBar;
