import React, { useContext } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../../common/locales/i18n';
import MembersPortalContext from "../context";
import RadioOptions from './partials/RadioOptions';

const Programs = () => {
  const { currentUser, t } = useContext(MembersPortalContext);

  const testGroupUsers = currentUser?.test_group_users?.filter(
    testGroupUser => testGroupUser.self_checkout_appointment.found
  );
  const options = testGroupUsers.map(t => ({
    name: t.test_group.name,
    value: t.self_checkout_appointment.self_checkout_link,
  }));

  return (
    <RadioOptions
      title={t('login.new_encounter', {first_name: currentUser.user.first_name})}
      subtitle={t('login.new_encounter_subtitle')}
      options={options}
    />
  );
};

export default Programs;

