import React, { useContext, useState } from 'react';
import '../../common/locales/i18n';
import { Form } from "react-bootstrap";
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import { usStateOptions } from './utils';
import ActionWrapper from './partials/ActionWrapper';
import axios from 'axios';
import MembersPortalContext from "../context";
import { validateEmail } from '../../common/utils';

const ContactUs = () => {
  const { currentUser, t } = useContext(MembersPortalContext);
  const [errors, setErrors] = useState({});

  const [form, setForm] = useState({
    location_url: "/members/me",
    _wpcf7_locale: currentUser.user.locale,
    user_access_code: currentUser.user.access_code,
    zen_subject: "Member User Support",
  });

  const validate = () => {
    const err = {}
    if (!values.zen_name) err.zen_name = 'registration.errors.field_required';
    if (!values.zen_email || !validateEmail(values.zen_email)) err.zen_email = 'registration.errors.regex_invalid';
    if (!values.ticket_type) err.ticket_type = 'registration.errors.field_required';
    if (!values.zen_desc) err.zen_desc = 'registration.errors.field_required';
    setErrors(err)
    return err;
  }

  const submitForm = () => {
    const err = validate();
    if (Object.keys(err).length == 0) {
      axios
        .post("https://api.kustomerapp.com/v1/hooks/form/5ffe150d32c4a19ca48305ef/b38925aae4e653a827f28925810590e96bff7d36880457999ca5b7f1194e92a3", form)
      . then((response) => alert(response)).catch((e) => alert(e));
    }
  };

  return (
    <ActionWrapper
      backLink="/members/me"
      title={t('contact_support.contact_us')}
      callToActionTitle={t('result_page.landing.submit_button')}
      callToActionProps={{onClick: submitForm}}
    >
      <div className='my-3'>
        <FloatingLabelInput 
          ariaRequired
          autoFocus
          label={t('contact_support.zen_name') + " *"}
          name="zen_name"
          onChange={(e) => setForm({...form, zen_name: e.target.value})}
          placeholder={t('contact_support.zen_name')}
          value={form.zen_name}
        />
        {errors.zen_name && <div className='text-danger'>{t(errors.zen_name)}</div>}
      </div>
      <div className='my-3'>
        <FloatingLabelInput 
          ariaRequired
          label={t('login.email_address') + " *"}
          name="zen_email"
          onChange={(e) => setForm({...form, zen_email: e.target.value})}
          placeholder={t('login.email_address')}
          value={form.zen_email}
        />
        {errors.zen_email && <div className='text-danger'>{t(errors.zen_email)}</div>}
      </div>
      <div className='my-3'>
        <FloatingLabelInput 
          ariaRequired
          label={t('registration.phone_number')}
          name="phone_number"
          onChange={(e) => setForm({...form, phone_number: e.target.value})}
          placeholder={t('registration.phone_number')}
          value={form.phone_number}
        />
      </div>
      <div className='my-3'>
        <FloatingLabelInput 
          ariaRequired
          label={t('contact_support.ticket_type') + " *"}
          name="ticket_type"
          onChange={(e) => setForm({...form, ticket_type: e.target.value})}
          placeholder={t('contact_support.ticket_type')}
          value={form.ticket_type}
        />
        {errors.ticket_type && <div className='text-danger'>{t(errors.ticket_type)}</div>}
      </div>
      <div className='my-3'>
        <FloatingLabelInput
          label={t('contact_support.appointment_access_code')}
          name="appointment_access_code"
          onChange={(e) => setForm({...form, appointment_access_code: e.target.value})}
          placeholder={t('contact_support.appointment_access_code')}
          value={form.appointment_access_code}
        />
      </div>
      <div className='my-3'>
        <FloatingLabelInput
          label="City"
          name={t('registration.city')}
          onChange={(e) => setForm({...form, city: e.target.value})}
          placeholder={t('registration.address_city')}
          value={form.city}
        />
      </div>
      <div className='my-3'>
        <FloatingLabelSelect
          name="state"
          ariaLabel="state select"
          value={usStateOptions.filter(option => option.value === form.state)}
          onChange={(e) => setForm({...form, state: e.value})}
          options={usStateOptions}
          components={{ IndicatorSeparator: () => null }}
          filledValue={form.state}
          label={t('registration.address_state')}
          style={{control: (provided, _) => ({
            ...provided,
            boxShadow: "inherit",
            "&:hover": {
              border: "1px solid #D9D9E1"
            }
          })}}
        />
      </div>
      <div className='my-3'>
        <div className="input-container">
          <Form.Label className={form.zen_desc && 'filled'}>{t('contact_support.zen_desc')} *</Form.Label>
          <Form.Control as="textarea" rows={4} onChange={(e) => setForm({...form, zen_desc: e.target.value})}/>
        </div>
        {errors.zen_desc && <div className='text-danger'>{t(errors.zen_desc)}</div>}
      </div>
    </ActionWrapper>
  );
};

export default ContactUs;
