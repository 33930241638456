import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

const AffectedUsers = ({ selected_ids }) => {
  const [affectedUsersText, setAffectedUsersText] = useState(null);

  useEffect(() => {
    axios.post(`tags/affected_users`, { selected_ids }).then((response) => {
      const { data: { count } } = response;
      setAffectedUsersText(`${count} ${(count > 1 || count === 0) ? 'users' : 'user'} will be affected by this change`)
    })
    .catch(() => {
      setAffectedUsersText("N/A - Unable to load affected user count at this time.");
    });
  }, []);

  return (
    <div>
      <Row>
        <Col>
          {`Are you sure you want to delete ${selected_ids.length} tag(s)?`}
        </Col>
      </Row>
      <Row>
        <Col>
          {affectedUsersText && <div>
            <FontAwesomeIcon icon="info-circle" color="#ff0000cf"/> {affectedUsersText}
          </div>}
          {!affectedUsersText && <>
            <FontAwesomeIcon icon="circle-notch" className="fa-spin"/> Loading affected user count...
          </>}
        </Col>
      </Row>
    </div>
  )
};

export default AffectedUsers;
