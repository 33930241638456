import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import MultiDatePickerField from '../../common/components/MultiDatePickerField';
import MultiSelectField from '../../common/components/MultiSelectField';
import TimeField from '../../common/components/TimeField';

const AppointmentSlotsForm = ({
  initialDates,
  locations,
  onSubmit: propOnSubmit,
  loadingSlots,
}) => {
  const [errors, setErrors] = useState({});

  const validate = (values) => {
    const errors = {};
    if (!(values.appointment_dates && values.appointment_dates.length > 0))
      errors.appointment_dates = 'You should select one date at least';
    if (!values.start_time_hour) errors.start_time_hour = 'Required';
    if (!values.start_time_minute) errors.start_time_minute = 'Required';
    if (!values.end_time_hour) errors.end_time_hour = 'Required';
    if (!values.end_time_minute) errors.end_time_minute = 'Required';
    if (
      !(
        values.appointment_slot_group_ids &&
        values.appointment_slot_group_ids.length > 0
      )
    )
      errors.appointment_slot_group_ids = 'Required';
    if (
      values.start_time_hour &&
      values.start_time_minute &&
      values.end_time_hour &&
      values.end_time_minute &&
      (parseInt(values.start_time_hour) > parseInt(values.end_time_hour) ||
        (parseInt(values.start_time_hour) === parseInt(values.end_time_hour) &&
          parseInt(values.start_time_minute) >
            parseInt(values.end_time_minute)))
    )
      errors.impossible_time_range = true;
    if (!values.capacity && !values.access_key_capacity)
      errors.capacity = 'Required';
    if (!values.access_key_capacity && !values.capacity)
      errors.access_key_capacity = 'Required';

    return errors;
  };

  const onSubmit = (values) => {
    const errors = validate(values);
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    propOnSubmit && propOnSubmit(values);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          appointment_dates: (initialDates && initialDates.split(',')) || [],
          start_time_hour: '',
          start_time_minute: '',
          end_time_hour: '',
          end_time_minute: '',
          capacity: 0,
          access_key_capacity: 0,
          appointment_slot_group_ids: [],
          time_zone: 'America/Los_Angeles',
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ values }) => (
          <Form>
            <h5 className="date required">Date of appointment</h5>
            <div className="row">
              <div className="col-lg-4">
                <div className="text-muted">
                  Please select the dates that you'd like to open up slots.
                </div>
                <span className="mt-2 text-danger">
                  {errors.appointment_dates}
                </span>
                <div className="mt-2 d-flex justify-content-center">
                  <MultiDatePickerField name="appointment_dates" type="text" />
                </div>
              </div>
              <div className="col col-lg-4">
                <div className="text-muted">
                  Please select the hours capacity will be open
                </div>
                <div>
                  <span className="text-danger">
                    {errors.start_time_hour || errors.start_time_minute}
                  </span>
                  <TimeField label="Start" name="start_time" />
                  <div className="mt-2 text-muted">
                    The time your last 15 min block starts
                  </div>
                  <span className="text-danger">
                    {errors.end_time_hour || errors.end_time_minute}
                  </span>
                  <TimeField
                    label="End"
                    name="end_time"
                    minHour={values.start_time_hour}
                    minMinute={
                      values.start_time_hour === values.end_time_hour
                        ? values.start_time_minute
                        : null
                    }
                  />
                </div>
                {errors.impossible_time_range && (
                  <div className="alert alert-danger" role="alert">
                    WARNING! Your end time is before your start time, please
                    remember to use 24 hour time when making your time slots.
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="mt-3">Locations</label>
                <span className="ms-3 text-danger">
                  {errors.appointment_slot_group_ids}
                </span>
                <MultiSelectField
                  name="appointment_slot_group_ids"
                  id="appointment_slot_group_ids"
                  options={locations}
                  styles={{multiValue: (styles, { data }) => {
                    if (data.label.includes("Inactive")) {
                      return {...styles, backgroundColor: "var(--danger-red)"}
                    } else {
                      return {...styles}
                    }
                  }}}
                />
              </div>
            </div>
            <h5 className="mt-3">Daily capacity</h5>
            <div className="row mb-3">
              <div className="col-lg-4">
                Standard Capacity
                <div className="text-muted">
                  Total number of participants, including dependents
                </div>
                <span className="text-danger">{errors.capacity}</span>
                <Field className="form-control" name="capacity" type="number" />
              </div>
              <div className="col-lg-4">
                Access key capacity
                <div className="text-muted">
                  Total number of participants, including dependents, with
                  access_keys
                </div>
                <span className="text-danger">
                  {errors.access_key_capacity}
                </span>
                <Field
                  className="form-control"
                  name="access_key_capacity"
                  type="number"
                />
              </div>
            </div>
            <Field name="time_zone" type="hidden" />
            {loadingSlots ? (
              <button className="btn btn-primary" type="submit" disabled>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Stage & review slotting
              </button>
            )}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AppointmentSlotsForm;
