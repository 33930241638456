import React, { FC, useEffect } from 'react';

import { useNavigation } from '../../common/components/Navigation/helpers/useNavigation';
import { findClinic } from '../helpers/requests';
import IndividualOrFamily from './IndividualOrFamily';
import FindClinic from './FindClinic';
import AddFamilyMembers from './AddFamilyMembers';
import SelectAppointmentSlot from './SelectAppointmentSlot';
import { Container } from 'react-bootstrap';
import Header from '../../Registration/components/Header';
import {
  Action,
  NavigationMap,
  State,
} from '../../common/components/Navigation/helpers/useNavigation';
import { useTranslation } from 'react-i18next';

type ServiceSpecificationOptions = Array<{
  key: string;
  label: string;
}>;

interface ServiceSpecificationSelections {
  [id: string]: boolean;
}

interface IpLocationInformation {
  city_name: string;
}

interface FamilyRegistrationProps {
  service_specification_options: ServiceSpecificationOptions;
  ip_location_information: IpLocationInformation;
  organization_id?: number;
  organization_logo_url?: string;
  is_test_environment: boolean;
  simplified_organization_logo_url?: string;
}

interface AppointmentSlotGroups {
  [id: string]: any;
}

interface Participant {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  test_configuration_ids: Array<string>;
}

interface FamilyRegistrationState {
  formState: {
    address?: string;
    radius?: number;
    registrationType?: string;
    appointmentSlotGroups?: Array<AppointmentSlotGroups>;
    appointmentSlotGroup?: AppointmentSlotGroups;
    participants?: Array<Participant>;
    startOn?: string;
    selectedDay?: string;
    selectedAppointmentSlotsById?: {
      [id: string]: number;
    };
    serviceSpecifications: ServiceSpecificationSelections;
    organizationId?: number;
  };
  serviceSpecifications: ServiceSpecificationOptions;
  filteredServiceSpecifications: any;
  selectedServiceSpecifications: any;
  loading?: boolean;
  isTestEnvironment: boolean;
}

const STEPS: NavigationMap<FamilyRegistrationState> = {
  individualOrFamily: {
    component: IndividualOrFamily,
    navigationActions: {
      next: 'findClinic',
    },
  },
  findClinic: {
    component: FindClinic,
    beforeEnterAsync: findClinic,
    navigationActions: {
      next: 'addFamilyMembers',
    },
  },
  addFamilyMembers: {
    component: AddFamilyMembers,
    navigationActions: {
      next: 'selectAppointmentSlot',
      back: "findClinic",
    },
  },
  selectAppointmentSlot: {
    component: SelectAppointmentSlot,
    navigationActions: {
      back: "addFamilyMembers",
    },
  },
};

const reducer = (
  state: State<FamilyRegistrationState>,
  action: Action,
): State<FamilyRegistrationState> => {
  switch (action.type) {
    case 'updateForm':
      return {
        ...state,
        formState: { ...state.formState, ...action.values },
      };
    case 'updateServiceSpecifications':
      const isRemovingServiceSpecification = Object.values(action.values).includes(false);
      const selectedServiceSpecifications = isRemovingServiceSpecification ? {
        ...state.formState.serviceSpecifications,
        ...action.values,
      } : action.values;
      const filteredServiceSpecifications = state.serviceSpecifications.filter(
        (ss) => !!selectedServiceSpecifications[ss.key]
      );

      return {
        ...state,
        formState: {
          ...state.formState,
          serviceSpecifications: selectedServiceSpecifications
        },
        selectedServiceSpecifications: filteredServiceSpecifications.map(x => x.key),
        filteredServiceSpecifications: filteredServiceSpecifications,
      }
    case 'isLoading':
      return { ...state, loading: action.value };
    default:
      throw new Error(`Unknown action ${action.type}.`);
  }
};

export const buildServiceSpecificationSelections = (
  serviceSpecifications: ServiceSpecificationOptions,
): ServiceSpecificationSelections =>
  Object.fromEntries(serviceSpecifications.map(({ key }) => [key, false]));

const FamilyRegistration: FC<FamilyRegistrationProps> = ({
  service_specification_options: serviceSpecifications,
  ip_location_information: ipLocationInformation,
  organization_id: organizationId,
  organization_logo_url: organizationLogoUrl,
  is_test_environment: isTestEnvironment,
  simplified_organization_logo_url: simplifiedOrganizationLogoUrl,
}) => {
  const { t } = useTranslation();
  const { state, dispatch, currentNode } = useNavigation(
    STEPS,
    'individualOrFamily',
    {
      formState: {
        radius: 10,
        organizationId,
        address: ipLocationInformation?.city_name || "",
        serviceSpecifications: buildServiceSpecificationSelections(
          serviceSpecifications,
        ),
      },
      serviceSpecifications,
      filteredServiceSpecifications: [],
      selectedServiceSpecifications: [],
      isTestEnvironment,
    },
    reducer,
  );
  const Component = currentNode.component;

  useEffect(() => {
    window.scrollTo(0,0)
  },[currentNode]);

  return (
    <div className="family-registration">
      <Header
        justifyContent={state.navigation.location !== 'findClinic'}
        testGroup={{
          locales: ['en', 'es'],
          partner_logo_url: organizationLogoUrl,
          simplified_partner_logo_url: simplifiedOrganizationLogoUrl,
        }}
      />
      <Container
        className="pb-5"
        fluid={state.navigation.location === 'findClinic'}
        style={state.navigation.location === 'findClinic' ? {} : { maxWidth: "770px" }}
      >
        <Component {...{ state, dispatch, t }} />
      </Container>
      <footer style={{backgroundColor: "#fffff"}}>
        <div className="footer-copyright text-center text-dark pb-3 mb-3">
          <div style={{fontSize: 14}}>
            Powered by Primary {' '}
            <img src="/images/logo.png" height="15px" alt="Primary Logo" style={{verticalAlign: "baseline"}}/>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FamilyRegistration;
