import React from 'react';

const Camera = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M0.585786 3.08579C0.960859 2.71071 1.46957 2.5 2 2.5H3.586C3.85119 2.49994 4.10551 2.39455 4.293 2.207L5.414 1.086C5.78899 0.710901 6.29761 0.500113 6.828 0.5H9.172C9.70239 0.500113 10.211 0.710901 10.586 1.086L11.707 2.207C11.8945 2.39455 12.1488 2.49994 12.414 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V12.5C16 13.0304 15.7893 13.5391 15.4142 13.9142C15.0391 14.2893 14.5304 14.5 14 14.5H2C1.46957 14.5 0.960859 14.2893 0.585786 13.9142C0.210714 13.5391 0 13.0304 0 12.5V4.5C0 3.96957 0.210714 3.46086 0.585786 3.08579ZM9.14805 11.2716C8.78407 11.4224 8.39397 11.5 8 11.5C7.20435 11.5 6.44129 11.1839 5.87868 10.6213C5.31607 10.0587 5 9.29565 5 8.5C5 7.70435 5.31607 6.94129 5.87868 6.37868C6.44129 5.81607 7.20435 5.5 8 5.5C8.39397 5.5 8.78407 5.5776 9.14805 5.72836C9.51203 5.87913 9.84274 6.1001 10.1213 6.37868C10.3999 6.65726 10.6209 6.98797 10.7716 7.35195C10.9224 7.71593 11 8.10603 11 8.5C11 8.89397 10.9224 9.28407 10.7716 9.64805C10.6209 10.012 10.3999 10.3427 10.1213 10.6213C9.84274 10.8999 9.51203 11.1209 9.14805 11.2716ZM2.25 5.5C2.66421 5.5 3 5.16421 3 4.75C3 4.33579 2.66421 4 2.25 4C1.83579 4 1.5 4.33579 1.5 4.75C1.5 5.16421 1.83579 5.5 2.25 5.5Z"
    />
  </svg>
);

export default Camera;
