import React, { useMemo } from 'react';
import { ThreeLineInputCard } from './components/Cards';
import LateralPanel from './components/LateralPanel';

const DaySummary = ({
  doseManagementData: { target, ...doseManagementData },
  setDoseManagementData,
  ...props
}) => {
  const setNewTarget = (target) => {
    setDoseManagementData({ target });
  };

  const bellowTarget = useMemo(() => {
    return props.projected - (parseInt(target) || 0);
  }, [target]);

  return (
    <div className="day-summary-panel-wrapper my-3">
      <ThreeLineInputCard
        title="Target"
        value={target}
        setValue={setNewTarget}
        actionText="Set target"
        tooltip="Target doses for the day"
      />
      <LateralPanel
        {...props}
        doseManagementData={doseManagementData}
        bellowTarget={bellowTarget}
      />
    </div>
  );
};

export default DaySummary;
