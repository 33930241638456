import React from 'react';
import { useTranslation } from 'react-i18next';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import '../../../../common/locales/i18n';
import AppointmentSlotGroupPill from './AppointmentSlotGroupPill';
import { appointmentSlotGroupDisabled } from '../AppointmentSlot';

const CollapsableAppointmentSlotGroupSelector = ({
  selectedAppointmentSlotGroupId,
  appointmentSlotGroups,
  onSelect,
  collapsed,
  appointmentType="default",
  appointmentConfigurations=[],
  refuseCovidVaccination=false,
}) => {
  const { t, i18n } = useTranslation();

  const disabled = (appointmentSlotGroup) => (
    appointmentSlotGroupDisabled(appointmentType, appointmentSlotGroup, appointmentConfigurations, refuseCovidVaccination)
  )

  const appointmentSlotGroupOptions = appointmentSlotGroups.map ((appointmentSlotGroup) => {
    if (!disabled(appointmentSlotGroup)) {
      return {
        value: appointmentSlotGroup.id,
        label: appointmentSlotGroup.title,
      }
    }
  }).filter(x => x);

  if (collapsed) {
    return (
      <FloatingLabelSelect
        autoFocus={true}
        value={appointmentSlotGroupOptions.filter(option => option.value === selectedAppointmentSlotGroupId)}
        onChange={onSelect}
        options={appointmentSlotGroupOptions}
        filledValue={selectedAppointmentSlotGroupId}
        label={t('registration.appointment_location')}
        id="collapsed_slot_selector"
        containerClassName="mt-3"
      />
    );
  } else {
    return appointmentSlotGroups.map ((appointmentSlotGroup, idx) => (
      <AppointmentSlotGroupPill
        key={appointmentSlotGroup.id}
        autoFocus={idx === 0}
        appointmentSlotGroup={appointmentSlotGroup}
        selected={selectedAppointmentSlotGroupId === appointmentSlotGroup.id}
        onClick={() => onSelect({value: appointmentSlotGroup.id})}
        disabled={disabled(appointmentSlotGroup)}
      />
    ))
  }
}

export default CollapsableAppointmentSlotGroupSelector;
