import React, { useContext } from 'react';
import '../../common/locales/i18n';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import HistorySection from './partials/HistorySection';
import MemberWrapper from './partials/MemberWrapper';
import MembersPortalContext from "../context";

const History = () => {
  const { currentUser } = useContext(MembersPortalContext);

  return (
    <MemberWrapper>
      <div style={{maxWidth: 500}}>
        <HistorySection tests={currentUser.medical_history} />
      </div>
    </MemberWrapper>
  );
};

export default History;
