import React, { useEffect, useState, useRef } from 'react';
import '../../common/locales/i18n';
import axios from 'axios';
import 'unfetch/polyfill';
import { Container, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Header from '../../Registration/components/Header';
import { languageObject } from '../../common/utils';
import SwitchUsers from '../../MembersPortal/components/partials/SwitchUsers';
import { profileColors } from '../../MembersPortal/components/utils';
import "../../../../../vendor/assets/css/animate.css";

const MemberButton = ({key, name, color, logoName, onClick, disabled, disabledText, outline=false}) => {
  const handleClick = disabled ? () => {} : onClick;
  const [textStyle, setTextStyle] = useState(outline
    ? { color: "var(--vivid-blue)" }
    : {}
  );
  const [className, setClassName] = useState('');
  const textRef = useRef(null);
  const profileRef = useRef(null);

  useEffect(() => {
    if (textRef?.current && profileRef?.current) {
      setTextStyle({...textStyle, paddingTop: (profileRef.current.clientHeight - textRef.current.clientHeight) / 2})
    }
  }, [textRef])
  
  const backgroundColor = outline ? "#fff" : color ? color : "#2862FA";

  const renderTooltip = () => disabled ? 
    <Tooltip id={name + key}>{disabledText}</Tooltip> :
    <Tooltip className="d-none"></Tooltip>;

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
  >
    <div
      key={key}
      onMouseEnter={() => setClassName('animate__animated animate__pulse')}
      onMouseLeave={() => setClassName('')}
      className={`big-profile-icon-wrapper ${disabled ? "text-muted" : className}`}
      onClick={handleClick}
    >
      <div
        className={`big-profile-icon ${outline && "border border-primary"}`}
        style={{backgroundColor: disabled ? '#868e96' : backgroundColor}}
        ref={profileRef}
      >
        <div style={textStyle} ref={textRef}>
          {logoName}
        </div>
      </div>
      <div className='text-center mt-3'>{name}</div>
      {disabled && <div className='text-muted overflow-auto'>{disabledText}</div>}
    </div>
    </OverlayTrigger>
  )
}

const PickHouseholdMember = ({test_group_id}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`/members/household_members.json?test_group_id=${test_group_id}`).then(response => {
      setData(response.data);
      setLoading(false);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <>
     <Header
        testGroup={{locales: Object.keys(languageObject)}}
        loggedInComponent={(
          <SwitchUsers householdMembers={[]} ready={2} />
        )}
      />
      <Container className="my-5">
        <h5>Member selection</h5>
        <hr/>
        <h6 className="mb-3">Who are you trying to register for?</h6>
          {loading ? 
            <Spinner variant="primary" animation="border" /> :
            <>
              {!data.test_group.registration_enabled &&
                <div className='text-muted'>This group is currently not accepting registrations</div>
              }
              <div className="d-flex flex-wrap">
              {data.household_members?.map(householdMember => {
                const colorIndex = data.household_members.findIndex(x => x.id == householdMember.id);

                return (
                  <MemberButton
                    key={householdMember.id}
                    logoName={`${householdMember.user.first_name[0]}${householdMember.user.last_name[0]}`}
                    name={`${householdMember.user.first_name} ${householdMember.user.last_name}`}
                    color={profileColors[colorIndex]}
                    onClick={() => {
                      axios.post(`/members/test_groups/${test_group_id}/test_group_registrations.json`, {
                        household_member: { id: householdMember.id }
                      }).then(response => {
                        const registrationUrl = response.data.test_group_user.access_code_registration_link;
                        location.href = registrationUrl;
                      });
                    }}
                    disabled={!data.test_group.allow_access_code_registration}
                    disabledText="This group does not allow existing members to register"
                  />
                )
              })}
              <MemberButton
                name="New member"
                logoName="+"
                onClick={() => {
                  location.href = data.test_group.allow_normal_registration ?
                    `/r/${test_group_id}?new_member_registration=true` :
                    `/a/${test_group_id}?new_member_registration=true`;
                }}
                outline
                disabled={!data.test_group.registration_enabled}
                disabledText="This group does not allow new member creation"
              />
              </div>
            </>
          }
      </Container>
    </>
  );
};

export default PickHouseholdMember;
