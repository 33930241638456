import React, { useContext } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import range from 'lodash/range';
import { Row, Col } from "react-bootstrap";

import InsuranceContext from '../contexts/InsuranceContext';

const DateOfBirthField = ({setFieldValue, values, secondary, errors}) => {

  const { t, i18n } = useTranslation();
  const { formAction, setFormAction } = useContext(InsuranceContext);

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const monthOptions = months.map((month, index) => {
    return { value: (index + 1), label: t("date." + month) }
  });

  const yearOptions = range(new Date().getFullYear(), 1920).map((year) => {
    return { value: year, label: year }
  });

  const dayOptions = range(1, 32).map((day) => {
    return { value: day, label: day };
  })

  const fieldToSet = secondary ? 'insurance_secondary_member_date_of_birth' : 'insurance_primary_member_date_of_birth';

  return (
    <Row>
      <Col xs={12} md={4} className="my-3">
        <FloatingLabelSelect
          value={monthOptions.filter(option => option.value === values.user[`${fieldToSet}(2i)`])}
          onChange={(s) => {
            setFieldValue(`user[${fieldToSet}(2i)]`, s.value);
            if (secondary) {
              setFormAction({...formAction, memberSecondaryBirthMonth: s.value });
            } else {
              setFormAction({...formAction, memberBirthMonth: s.value });
            }
          }}
          ariaLabel="month"
          options={monthOptions}
          components={{ IndicatorSeparator: () => null }}
          label='Month'
          isSearchable={false}
          filledValue={values.user[`${fieldToSet}(2i)`]}
          id="month"
          className={errors["date_of_birth"] && 'is-invalid'}
        />
      </Col>
      <Col xs={12} md={4} className="my-3">
        <FloatingLabelSelect
          value={dayOptions.filter(option => option.value === values.user[`${fieldToSet}(3i)`])}
          onChange={(s) => {
            setFieldValue(`user[${fieldToSet}(3i)]`, s.value);
            if (secondary) {
              setFormAction({...formAction, memberSecondaryBirthDay: s.value });
            } else {
              setFormAction({...formAction, memberBirthDay: s.value });
            }
          }}
          ariaLabel="day"
          options={dayOptions}
          components={{ IndicatorSeparator: () => null }}
          label='Day'
          isSearchable={false}
          filledValue={values.user[`${fieldToSet}(3i)`]}
          id="day"
          className={errors["date_of_birth"] && 'is-invalid'}
        />
      </Col>
      <Col xs={12} md={4} className="my-3">
        <FloatingLabelSelect
          value={yearOptions.filter(option => option.value === values.user[`${fieldToSet}(1i)`])}
          onChange={(s) => {
            setFieldValue(`user[${fieldToSet}(1i)]`, s.value);
            if (secondary) {
              setFormAction({...formAction, memberSecondaryBirthYear: s.value });
            } else {
              setFormAction({...formAction, memberBirthYear: s.value });
            }
          }}
          ariaLabel="year"
          options={yearOptions}
          components={{ IndicatorSeparator: () => null }}
          label='Year'
          isSearchable={false}
          filledValue={values.user[`${fieldToSet}(1i)`]}
          id="year"
          className={errors["date_of_birth"] && 'is-invalid'}
        />
      </Col>
    </Row>
  );
};

export default DateOfBirthField;
