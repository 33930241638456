import React from "react";
import { Spinner } from "react-bootstrap";

import MessagesItem from "./MessagesItem";

const MessagesList = ({
  allCommunicationsFetched,
  communications,
  loadingCommunications,
  fetchMoreCommunications,
  selectedCommunicationId,
  ...props
}) => {

  const handleScroll = (e) => {
    if (allCommunicationsFetched || loadingCommunications) return;
    if (e.target.scrollHeight - e.target.scrollTop - 100 < e.target.clientHeight) { // when scrolled to the bottom of the element
      fetchMoreCommunications()
    }
  };

  return (
    <div
      className="overflow-auto"
      style={{ maxHeight: "750px" }}
      onScroll={handleScroll}
    >
      {communications.map(communication => {
        return (
          <MessagesItem
            communication={communication}
            isSelected={selectedCommunicationId == communication.id}
            {...props}
          />
        )
      })}
      {loadingCommunications && (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default MessagesList;