import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryIcon } from './Icons/PrimaryIcon';

const CodeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 48px auto;
  max-width: 400px;

  input {
    width: 14%;
    height: 72px;
    padding: 12px 8px;
    font-size: 2em;
    text-align: center;
    margin-right: 6px;
    margin-left: 6px;
  }

  &.is-invalid input {
    border-color: #f46363 !important;
  }

  &.is-verified {
    max-width: 457px;
  }
`;

const VerificationCodeInput = ({ size = 6, onChange, invalid, verified }) => {
  const [code, setCode] = useState([...Array(size)]);

  useEffect(() => {
    onChange && onChange(code.join(''));
  }, [code]);

  return (
      <CodeInputContainer
        className={`${invalid ? 'is-invalid' : undefined} ${verified ? 'is-verified' : undefined}`}
        data-test-hook="verification_code_input"
      >
        {[...Array(size)].map((_, idx) => (
          <input
            key={idx}
            className="form-control string required mb-0"
            type="text"
            disabled={verified}
            name={`code-at-${idx}`}
            id={`code-at-${idx}`}
            value={code.at(idx) || ''}
            inputMode="numeric"
            onChange={(e) => {
              const value = e.target.value;
              const newCode = [...code];
              newCode[idx] = value.at(0);
              setCode(newCode);

              value.length > 0 &&
                idx < size - 1 &&
                document.getElementById(`code-at-${idx + 1}`).focus();
            }}
            onKeyDown={(e) => {
              if (idx > 0) {
                const key = e.keyCode || e.charCode;
                if ((key == 8 || key == 46) && e.target.value.length === 0) {
                  e.preventDefault();
                  document.getElementById(`code-at-${idx - 1}`).focus();
                }
              }
            }}
            onPaste={(e) => {
              e.stopPropagation();
              e.preventDefault();
              let clipboardData = e.clipboardData || window.clipboardData;
              const chars = clipboardData.getData('Text').slice(0, size - idx);
              const newCode = code.map((char, codeIdx) =>
                codeIdx >= idx ? chars[codeIdx - idx] : char,
              );
              setCode(newCode);
              const newFocusIdx =
                idx + chars.length < size - 1 ? idx + chars.length : size - 1;
              document.getElementById(`code-at-${newFocusIdx}`).focus();
            }}
          />
        ))}
        {verified &&
          <div className='ms-1 me-1' style={{ alignSelf: 'center', height: 51 }}>
            <PrimaryIcon
              icon="check-circle"
              color="#60CCB7"
              height={51}
              width={51}
              className="animate__animated animate__rubberBand"
              data-test-hook="verified_icon"
            />
          </div>
        }
      </CodeInputContainer>

  );
};

export default VerificationCodeInput;
