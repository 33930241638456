import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import ModalHeader from '../../common/components/ModalHeader';
import Select from '../../common/components/Select';

const PrintQrCodeModal = ({
  qrcode_print_path,
  locations: availableLocations,
  active_test_configurations: activeTestConfigurations,
}) => {
  const [selectedTestType, setSelectedTestType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const qrcodePrintPath = `${qrcode_print_path}&test_type=${selectedTestType}&location_id=${
    selectedLocation || ''
  }`;

  return (
    <React.Fragment>
      <ModalHeader closeButton>
        <h2 className="modal-title">Choose test type</h2>
      </ModalHeader>
      <Modal.Body>
        <span>Choose test type</span>
        <Select
          className="mb-3"
          value={selectedTestType}
          onChange={setSelectedTestType}
          options={activeTestConfigurations}
          valueKey="id"
          labelKey="label"
        />
        <span>Choose location (optional)</span>
        <Select
          includeBlank
          value={selectedLocation}
          onChange={setSelectedLocation}
          options={availableLocations}
          valueKey="id"
          labelKey="title"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          href={qrcodePrintPath}
          variant="primary"
          disabled={!selectedTestType}
        >
          Print
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default PrintQrCodeModal;
