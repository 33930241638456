import React, { useEffect, useState } from 'react';
import { dotRead } from '../../common/components/SelectableTable/SelectableRow';

const StudenProfileRow = ({
  item,
  itemComponents,
  itemKeys,
  selectedItemIds,
  toggleSelection,
}) => {
  const [expandRow, setExpand] = useState(false);
  const _itemKeys = [...itemKeys, 'dropdown'];

  useEffect(() => {
    setExpand(false)
  }, [item])

  return (
    <>
      <tr key={item.id} className="pointer">
        <td>
          <input
            type="checkbox"
            className="form-check-input"
            checked={selectedItemIds.find((id) => id == item.id) > -1}
            onChange={(e) => toggleSelection(item.id, e.target.checked)}
          />
        </td>
        {_itemKeys.map((key, idx) => {
          let itemData;
          if (key.includes('.')) {
            itemData = dotRead(item, key);
          } else {
            itemData = item[key];
          }
          const itemComponent = itemComponents[key];
          if (key == 'dropdown') {
            return (
              <td key={idx}>
                <button
                  class={`btn btn-sm btn-outline-secondary border-0 ${
                    expandRow ? 'show' : ''
                  }`}
                  type="button"
                  aria-expanded={expandRow ? 'true' : 'false'}
                  onClick={() => setExpand(!expandRow)}
                >
                  <i class="fa fa-angle-down ms-1" style={{ fontSize: 18 }}></i>
                </button>
              </td>
            );
          } else if (!!itemComponent) {
            return (
              <td key={idx}>
                {React.cloneElement(itemComponent, { props: item })}
              </td>
            );
          } else {
            return <td key={idx}>{itemData}</td>;
          }
        })}
      </tr>
      <tr
        className={`animate__animated animate__fadeIn ${
          expandRow ? 'show' : 'collapse'
        }`}
        aria-expanded={expandRow ? 'true' : 'false'}
      >
        <td colspan="100">{itemComponents.immunization_summary_card(item, expandRow)}</td>
      </tr>
    </>
  );
};

export default StudenProfileRow;
