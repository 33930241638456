import { Button, Row, Col } from 'react-bootstrap';
import React from 'react';
import { PrimaryIcon } from '../../../common/components/Icons/PrimaryIcon';
import SectionHeader from '../SectionHeader';
import { SectionWrapper } from '../styled';
import { useTranslation } from 'react-i18next';
import { kitCheckList } from "../../Sti/data/kit_information";

export const SUCCESS_REG = "SUCCESS_REG";

export default function RegistrationSuccessStep ({appointment, isMobile, stepForward, kitType}) {
  const { t } = useTranslation();
  const listItem = (text, idx) => (
    <Row className='my-4 kit-body-text' key={idx}>
      <Col xs={2}>
        <PrimaryIcon
          icon="check-circle"
          color="#2E948E"
          height={22}
          width={22}
          style={{alignSelf: "center"}}
          className="float-end"
        />
      </Col>
      <Col xs={8} className="gx-0">{t(`test_strip.checklist.${text}`)}</Col>
    </Row>
  )
  return (
    <SectionWrapper>
      <SectionHeader
        title={t('test_strip.success_registration')}
        includeIcon={false}
      />
      <div style={{marginBottom: '48px'}}>
        <div className='kit-body-text my-4'>
         {t("test_strip.checklist.title")} 
        </div>
        {kitCheckList[kitType]?.map((checkList, idx) => listItem(checkList, idx))}
      </div>
      <div className={`text-center ${isMobile && "d-flex"}`} style={{minHeight: "min-content"}}>
        <Button variant="outline-primary" block={isMobile} href={appointment.confirmation_link} data-test-hook="back">{t('test_strip.not_yet')}</Button>
        <Button className='ms-3' block={isMobile} onClick={stepForward} data-test-hook="submit">{t('yes')}</Button>
      </div>
    </SectionWrapper>
  );
}
