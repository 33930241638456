import React, { useContext, useMemo, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';

import HelpText from '../../../../common/components/HelpText';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import { Group } from '../../../../common/components/SelectGroup';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import { isMobile } from '../../../../common/utils/browser';
import { Col, Row } from 'react-bootstrap';
import { PersonalFieldDropdown } from '../../SectionComponents';

const RaceDropdown = ({
  handleChange,
  setFieldValue,
  values,
  attributeString = '',
  context = RegistrationContext,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(context);
  const fieldKey = `${attributeString}races`;
  const label = buildLocalizedString(testGroup, 'race_label');
  const required = testGroup.required_attributes.race;

  const raceOptions = useMemo(() =>
    Object.keys(testGroup.race_options).map((race) =>
      Object.keys(testGroup.race_options[race]).length !== 0
        ? {
            label: t(`ethnicity.${race}`),
            options: Object.keys(testGroup.race_options[race]).map(
              (subRace) => ({
                label: t(`ethnicity.${subRace}`),
                value: `${race}.${subRace}`,
              }),
            ),
            value: race,
          }
        : { label: t(`ethnicity.${race}`), value: race },
    ),
  );
  const selectedRaces = useMemo(
    () =>
      raceOptions
        .map((ro) => (ro.options ? [ro, ...ro.options] : ro))
        .flat()
        .filter((ro) => values[fieldKey].includes(ro.value)),
    [raceOptions, values[fieldKey]],
  );

  const selectedOtherRace = selectedRaces.filter(
    (val) => val.value === 'other',
  ).length;
  const SELF_DESCRIBED_RACE_CHAR_LIMIT = 25;

  return (
    <PersonalFieldDropdown
      helpText={
        buildLocalizedString(testGroup, 'race_help_text') ? (
          <LocalizedMarkdown
            container={testGroup}
            stringKey="race_help_text"
          />
        ) : (
          <span>{t('ethnicity.help')}</span>
        )
      }
    >
      <FloatingLabelSelect
        className="test-user-races"
        containerClassName="mt-2 mb-2"
        isMulti
        components={{ Group }}
        value={selectedRaces}
        isSearchable={!isMobile}
        onChange={(raceList) => {
          setFieldValue(
            fieldKey,
            raceList ? raceList.map((race) => race.value) : [],
          );
        }}
        options={raceOptions}
        name="race"
        filledValue={values[fieldKey].length}
        label={
          label
            ? `${label} ${required ? '*' : '(Optional)'}`
            : `${t('registration.race_ethnicity')} ${required ? '*' : '(Optional)'}`
        }
      />
      {testGroup.allow_self_described_race_enabled &&
        testGroup.allow_self_described_race &&
        selectedRaces.length > 0 &&
        selectedOtherRace === 1 && (
          <>
            <FloatingLabelInput
              value={values['self_described_race'] || ''}
              onChange={handleChange}
              className="mt-2 mb-2"
              maxLength={SELF_DESCRIBED_RACE_CHAR_LIMIT}
              name={attributeString + 'self_described_race'}
              label={`${t('registration.self_described_race')} *`}
              validation={
                values['self_described_race']?.length >=
                  SELF_DESCRIBED_RACE_CHAR_LIMIT && 'is-invalid'
              }
            />
            {values['self_described_race']?.length >=
              SELF_DESCRIBED_RACE_CHAR_LIMIT && (
              <div className="text-warning mx-2">
                <small>{t('registration.errors.reached_max_chars')}</small>
              </div>
            )}
            <div className="text-muted mx-2 mb-2">
              <small>
                {t('registration.errors.chars_max')}:{' '}
                {values['self_described_race']?.length}/
                {SELF_DESCRIBED_RACE_CHAR_LIMIT}
              </small>
            </div>
          </>
        )}
    </PersonalFieldDropdown>
  );
};

export default RaceDropdown;
