import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Section, SectionItem } from '../../components/styled';

export const ContentItems = ({ items, t }) => {
  return (
    <>
      {items.map(({ image, contents }, index) => (
        <Col
          lg={4}
          sm={6}
          xs={12}
          className="body-large-16-regular"
          key={index}
          style={{ marginBottom: '32px' }}
        >
          <img src={image} style={{ maxHeight: 140 }} />
          <div className="mt-3 pt-3">
            {contents.map(({ label, count }, index) => (
              <p key={index}>{t(label, { count })}</p>
            ))}
          </div>
        </Col>
      ))}
    </>
  );
};

export default function ContentsStep({ contents, isMobile, t, kitType }) {
  return (
    <Section>
      <SectionItem>
        <Row>
          <Col xs="auto">
            <img
              src={'/images/sti-programs/test-kit-2.png'}
              height={42}
              alt="Test kit"
            ></img>
          </Col>
          <Col xs="auto" className="align-self-center ps-0">
            <h3 className="kit-body-text bold mb-0">
              {t('test_strip.box_type', {
                type: kitType,
              })}
            </h3>
          </Col>
        </Row>
      </SectionItem>
      <SectionItem>
        <div>{t('test_strip.contents.text_1')}</div>
      </SectionItem>
      <SectionItem>
        <Row className={`${isMobile ? 'mx-6 px-3' : ''}`}>
          <ContentItems {...contents} t={t} />
        </Row>
      </SectionItem>
    </Section>
  );
}
