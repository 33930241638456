import React from 'react';

export const Box = ({ color = '#2862FA', ...props }) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2026 0C10.631 0 10.0695 0.150215 9.57435 0.435571L6.10032 2.42073C5.87188 2.46592 5.65879 2.58392 5.49836 2.76471L1.96681 4.78273L1.96262 4.78514C1.5604 5.01736 1.21352 5.33216 0.944084 5.70745C0.878503 5.77306 0.820304 5.84821 0.771651 5.93231C0.729746 6.00476 0.697133 6.08002 0.673444 6.15673C0.449805 6.60622 0.332549 7.10217 0.332031 7.60606V16.3047C0.332618 16.8766 0.483583 17.4383 0.769781 17.9334C1.05598 18.4286 1.46735 18.8397 1.96262 19.1257L1.96681 19.1281L9.57202 23.4739L9.57403 23.475C9.91885 23.6738 10.2958 23.807 10.686 23.8696C10.8396 23.9528 11.0156 24 11.2026 24C11.3896 24 11.5656 23.9528 11.7192 23.8696C12.1094 23.807 12.4864 23.6738 12.8312 23.475L12.8332 23.4739L20.4384 19.1281L20.4426 19.1257C20.9379 18.8397 21.3492 18.4286 21.6354 17.9334C21.9216 17.4383 22.0726 16.8766 22.0732 16.3047V7.60606C22.0727 7.10225 21.9554 6.60638 21.7319 6.15694C21.7082 6.08016 21.6755 6.00482 21.6336 5.93231C21.5849 5.84814 21.5267 5.77295 21.461 5.7073C21.1916 5.33208 20.8448 5.01733 20.4426 4.78513L12.8332 0.436914L12.8309 0.435606C12.3357 0.150227 11.7742 0 11.2026 0ZM12.2897 21.2805L19.3555 17.2428L19.3573 17.2418C19.5216 17.1466 19.6581 17.0099 19.7531 16.8454C19.8485 16.6804 19.8989 16.4931 19.8991 16.3025V8.19151L12.2897 12.5933V21.2805ZM10.1155 12.5933L2.50615 8.19149V16.303C2.50642 16.4935 2.55674 16.6805 2.65206 16.8454C2.74713 17.0099 2.88364 17.1466 3.04796 17.2418L3.04967 17.2428L10.1155 21.2805V12.5933ZM11.7503 2.32216L18.7696 6.33318L16.1 7.87746L8.55116 3.52428L10.6549 2.32216L10.6591 2.31975C10.8243 2.22434 11.0118 2.17411 11.2026 2.17411C11.3934 2.17411 11.5809 2.22434 11.7461 2.31975L11.7503 2.32216ZM11.2026 10.7104L13.9274 9.13427L6.36518 4.77341L3.63559 6.33317L11.2026 10.7104Z"
      style={{
        fill: color,
        stroke: 'none',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
  </svg>
);

export default Box;
