import React, { useState, useEffect } from 'react';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FloatingLabelSelect from './FloatingLabelSelect';
import { PrimaryIcon } from './Icons/PrimaryIcon';

const ExternalMenuList = ({currentlySelected, selectedIcon, setCurrentlySelected}) => (
  <div className='mt-2'>
    {currentlySelected.map(option =>
      <div className='external-menu-item inter medium'>
        <PrimaryIcon icon={selectedIcon} color="#CBD2E0" height={14} width={24} />
        {option.label}
        <PrimaryIcon
          icon="x"
          height="15"
          className="ms-auto pointer"
          onClick={() => setCurrentlySelected(currentlySelected.filter(o => o.value !== option.value))}
        />            
      </div>
    )}
  </div>
)

const MaxItemWarning = ({currentlySelected, maxItems, label}) => (
  <div className='mb-3 inter body-large-16-regular medium text-danger d-flex align-items-center'>
    <PrimaryIcon icon="alert-circle" color="#D44F58" height={20} width={20} />
    <div className='ms-1'>Maximum {label.toLowerCase()} met. Please remove {currentlySelected.length - maxItems} {label.toLowerCase()}.</div>
  </div>
)

const AsyncMultiSelect = ({
  name,
  selected,
  optionsUrl,
  valueKey = 'id',
  labelKey = 'friendly_name',
  onSelect = () => {},
  style = {},
  label,
  placeholder,
  maxItems,
  creatable = false,
  createOption = undefined,
  formatCreateLabel = undefined,
  isDisabled=false,
  showOptionsBelow=false,
  selectedIcon,
}) => {
  const SelectComponent = creatable ? CreatableSelect : FloatingLabelSelect;

  const [selectOptions, setSelectOptions] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState([]);
  const [loading, setLoading] = useState(true);

  const onChange = (selected) => {
    setCurrentlySelected(selected);
    onSelect(selected);
  };

  useEffect(() => {
    axios.get(optionsUrl).then((response) => {
      setLoading(false);
      const options = typeof(response.data.options) == "string"
        ? JSON.parse(response.data.options)
        : response.data.options
      setSelectOptions(
        options.map((option) => ({
          value: option[valueKey],
          label: option[labelKey],
        })),
      );
      setCurrentlySelected(
        selected.map((option) => ({
          value: option[valueKey],
          label: option[labelKey],
        })),
      );
    });
  }, []);

  const onCreateOption = (inputValue) => {
    createOption(inputValue).then((OptionObj) => {
      const newOption = {
        value: OptionObj[valueKey],
        label: OptionObj[labelKey],
      };
      setSelectOptions([...selectOptions, newOption]);
      onChange([...currentlySelected, newOption]);
    });
  };

  const MenuList = (props) => {
    const height = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={140}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  return (
    <div style={{ width: '100%', ...style }}>
      <input
        type="hidden"
        value={
          currentlySelected
            ? currentlySelected.map((item) => item.value).join(',')
            : ''
        }
        name={name.toLowerCase()}
      />
      {maxItems && currentlySelected.length > maxItems &&
        <MaxItemWarning {...{ currentlySelected, maxItems, label }} />
      }
      <SelectComponent
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList }}
        className="basic-multi"
        classNamePrefix="select"
        label={
          loading ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : label ? (
            label
          ) : (
            name
          )
        }
        value={currentlySelected}
        options={selectOptions}
        isSearchable={true}
        name="name"
        onChange={onChange}
        isMulti
        placeholder={placeholder || "Select..."}
        filledValue={
          Array.isArray(currentlySelected) && !!currentlySelected.length
        }
        onCreateOption={createOption && onCreateOption}
        formatCreateLabel={formatCreateLabel}
        maxItems={maxItems}
        isDisabled={isDisabled}
        hideSelectedOptions={!showOptionsBelow}
        controlShouldRenderValue={!showOptionsBelow}
      />
      {showOptionsBelow &&
        <ExternalMenuList {...{currentlySelected, selectedIcon, setCurrentlySelected }} />
      }
    </div>
  );
};

export default AsyncMultiSelect;
