import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import {
  Badge,
  Card,
  Modal,
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDebounce } from '../common/hooks/useDebounce';
import FabrxCheckbox from '../Registration/primary/FabrxCheckbox';
import AddVaccineCard from './components/uploads/AddVaccineCard';
import UploadBox from '../common/components/UploadBox';
import Exemption from './components/Exemption';
import StudentUploadsList from './components/uploads/StudentUploadsList';
import { FilterBadge } from '../School/components/StudentImmunizationStatusFilters';
import File, { FilesContainer, RemoveButton } from '../common/components/File';

const StudentDocumentReview = (initialProps) => {
  const { t } = useTranslation();
  const [props, setProps] = useState(initialProps);
  const {
    document_scanner,
    test_group,
    test_group_user,
    test_group_immunizations_immunization_url,
    test_group_schools_student_upload_url,
    test_group_immunizations_exemption_url,
    reload_url,
    student_uploads: studentUploads,
    student_profile,
    overdue_vaccines,
  } = props;

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setConfirmationCheckboxChecked':
        return { ...state, confirmationCheckboxChecked: action.value };
      case 'setDocumentScannerDisabled':
        return { ...state, documentScannerDisabled: action.value, isScanning: false };
      case 'setError':
        return { ...state, error: action.value };
      case 'setExemption':
        return { ...state, exemption: action.value };
      case 'setExistingExemptions':
        return { ...state, existingExemptions: action.value };
      case 'setFiles':
        return { ...state, files: action.value, loadingUpload: false };
      case 'setFoundVaccines':
        return { ...state, foundVaccines: action.value, isSearching: false };
      case 'setIsScanning':
        return { ...state, isScanning: true };
      case 'setIsSearching':
        return { ...state, isSearching: true };
      case 'setLoadingSave':
        return { ...state, loadingSave: action.value };
      case 'setLoadingUpload':
        return { ...state, loadingUpload: true };
      case 'setShowRejectModal':
        return { ...state, showRejectModal: action.value };
      case 'setStagedVaccineHistories':
        return { ...state, stagedVaccineHistories: action.value };
      case 'setVaccineSearch':
        return { ...state, vaccineSearch: action.value };
      default:
        throw new Error('Unknown action.');
    }
  };
  
  const [state, dispatch] = useReducer(reducer, {
    confirmationCheckboxChecked: false,
    documentScannerDisabled: studentUploads.length === 0,
    error: null,
    isScanning: false,
    isSearching: false,
    loadingSave: false,
    loadingUpload: false,
    showRejectModal: false,
    vaccineSearch: '',
    existingExemptions: [],
    files: [],
    foundVaccines: [],
    stagedVaccineHistories: [],
    exemption: {},
  });

  const pendingStatus = student_profile.immunization_status === 'pending';
  const debouncedSearchTerm = useDebounce(state.vaccineSearch);

  const reload = async () => {
    const response = await axios.get(reload_url);
    setProps(response.data);
    dispatch({ type: 'setDocumentScannerDisabled', value: response.data?.student_uploads?.length === 0 });
  };

  const onDeleteStudentUpload = async (studentUploadId) => {
    await axios.delete(
      `/test_groups/${test_group.slug}/schools/student_uploads/${test_group_user.id}/student_uploads/${studentUploadId}`,
    );
    await reload();
  };

  const searchCharacters = async (searchTerm) => {
    const response = await axios.get(
      `/vaccine_searches/query?query=${searchTerm}`,
    );
    return response.data.vaccine_searches;
  };

  const onNewFile = (file) => {
    dispatch({ type: 'setFiles', value: [...state.files, file] });
  };

  const removeFileAt = (idx) => {
    const newFiles = [...state.files];
    newFiles.splice(idx, 1);
    dispatch({ type: 'setFiles', value: newFiles });
  };

  const getExemptions = async () => {
    const response = await axios.get(
      `/test_groups/${test_group.id}/immunizations/exemptions/${test_group_user.id}/exemptions`,
    );
    return response.data.exemptions;
  };

  const validateMissingFields = () => {
    const exemptionsToBeSaved = Object.keys(state.exemption).length > 0;
    if (exemptionsToBeSaved) {
      if (
        !state.exemption.exemptionType ||
        !state.exemption.conditions?.length > 0 ||
        (state.exemption.exemptionType === 'temporary' &&
          !state.exemption.expiresAt)
      ) {
        dispatch({ type: 'setError', value: 'Please fill the missing required values.' });
        dispatch({ type: 'setConfirmationCheckboxChecked', value: false });
        return true;
      }
    }
    return false;
  };

  const saveImmunizations = async () => {
    if (validateMissingFields()) return;

    // Upload vaccine histories
    dispatch({ type: 'setLoadingSave', value: true });
    for (let i = 0; i < state.stagedVaccineHistories.length; i++) {
      const vaccineHistory = state.stagedVaccineHistories[i];
      const body = {
        vaccine: vaccineHistory.vaccine,
        valid_dose: true,
        source: 'upload',
        vaccinated_at: vaccineHistory.vaccinatedAt,
      };
      await axios.post(test_group_immunizations_immunization_url, {
        vaccine_history: body,
      });
    }
    dispatch({ type: 'setStagedVaccineHistories', value: [] });

    // Upload exemptions
    if (Object.keys(state.exemption).length > 0) {
      await axios.post(test_group_immunizations_exemption_url, {
        exemptions: {
          category: state.exemption.category,
          conditions: state.exemption.conditions,
          expires_at: state.exemption.expiresAt,
          exemption_type: state.exemption.exemptionType,
        },
      });
    }

    dispatch({ type: 'setLoadingSave', value: false });
    localStorage.setItem('immunizationSuccessMessage', 'Successfully saved');
    window.location = test_group_immunizations_immunization_url;
  };

  const calculateImmunizationCompliance = async () => {
    try {
      const response = await axios.post(
        `/test_groups/${test_group.id}/immunizations/${test_group_user.id}/calculate_immunization_compliance`,
      );
      // do something with response?
    } catch (e) {
      console.log(e);
    }
  };

  const scanDocuments = async () => {
    if (studentUploads.length === 0) return toastr.error('There are no documents uploaded.');
    dispatch({ type: 'setIsScanning' });
    try {
      const response = await axios.get(`/test_groups/${test_group.id}/schools/student_uploads/${test_group_user.id}/scan`);
      if (response.status === 200) {
        const predictedVaccineHistories = response.data.predicted_vaccines.map((pv) => {
          return (
            {
              vaccine: pv.resolved_vaccine_search.name,
              vaccinatedAt: pv.vaccinated_at,
              description: pv.resolved_vaccine_search.description,
            }
          );
        });
        const newStagedVaccineHistories = [...state.stagedVaccineHistories, ...predictedVaccineHistories];
        dispatch({ type: 'setStagedVaccineHistories', value: [...newStagedVaccineHistories] });
        dispatch({ type: 'setDocumentScannerDisabled', value: true });
      }
    } catch(e) {
      console.log(e.message)
      dispatch({ type: 'setDocumentScannerDisabled', value: false });
    }
  };

  const uploadFiles = async () => {
    dispatch({ type: 'setLoadingUpload' });
    for (let i = 0; i < state.files.length; i++) {
      const file = state.files[i];
      const formData = new FormData();
      formData.append('student_upload[document]', file);
      formData.append('student_upload[uploaded_by]', 'administrator');
      await axios.post(test_group_schools_student_upload_url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toastr.success(`Uploaded ${file.name}`);
    }
    dispatch({ type: 'setFiles', value: [] });
    reload();
  };

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        dispatch({ type: 'setIsSearching' });
        searchCharacters(debouncedSearchTerm).then((results) => {
          dispatch({ type: 'setFoundVaccines', value: results });
        });
      } else {
        dispatch({ type: 'setFoundVaccines', value: [] });
      }
    },
    [debouncedSearchTerm], // Only call effect if debounced search term changes
  );

  useEffect(() => dispatch({ type: 'setError', value: null }), [state.exemption, state.stagedVaccineHistories]);

  useEffect(() => {
    getExemptions().then((exemptions) =>
      dispatch({ type: 'setExistingExemptions', value: JSON.parse(exemptions) }),
    );
  }, [test_group_user.id]);

  const rejectModal = (
    <Modal
      show={state.showRejectModal}
      onHide={() => dispatch({ type: 'setShowRejectModal', value: false })}
      size='lg'
    >
      <div className='p-4'>
        <h4>
          Notify the student's parents about rejecting the uploaded documents
        </h4>
        <p>
          If you proceed, none of the added vaccines or exemptions will be
          saved. Would you like to continue?
        </p>
        <div className='d-flex justify-content-end'>
          <Button
            variant='outline-primary'
            className='mx-2'
            onClick={() => dispatch({ type: 'setShowRejectModal', value: false })}
          >
            No, go back
          </Button>
          <Button
            className='mx-2'
            data-bs-toggle='modal'
            data-bs-target='#custom-message-modal'
            onClick={async () => {
              dispatch({ type: 'setShowRejectModal', value: false });
              calculateImmunizationCompliance();
              // makes custom_message_modal work for current single student
              document.getElementById('selected_test_group_user_ids').value = [
                test_group_user.id,
              ];
              document.getElementsByClassName('message-count')[0].innerHTML = 1;
              // redirect to test_group_immunizations_immunization_url instead of test_group_communication_url after clicking 'Send message'
              document.getElementById('redirect_url').value =
                test_group_immunizations_immunization_url;
              // save message to localStorage on 'Send message' button click
              document
                .getElementById('create-button')
                .addEventListener('click', () => {
                  localStorage.setItem(
                    'immunizationSuccessMessage',
                    'Communication to parents initiated',
                  );
                });
            }}
          >
            Yes, continue
          </Button>
        </div>
      </div>
    </Modal>
  );

  const overdueImmunizations = overdue_vaccines.length > 0 && (
    <div className='d-flex flex-wrap'>
      <span className='mt-3 me-1'>
        <b>Filter by overdue: </b>
      </span>
      {overdue_vaccines.map((ov, idx) => (
        <span key={idx} className='my-1 me-1'>
          <FilterBadge value={ov} onClick={() => dispatch({ type: 'setVaccineSearch', value: ov })} />
        </span>
      ))}
    </div>
  );

  const savedExemptions = state.existingExemptions.length > 0 && (
    <div className='mt-3'>
      <span>
        <b>Saved: </b>
      </span>
      {state.existingExemptions.map((me) => {
        return (
          <span>
            <Badge style={{ backgroundColor: '#314677' }} className='mx-1'>
              {me.name}{' '}
              {me.exemption_type === 'temporary' && `(exp: ${me.expires_at})`}
            </Badge>
          </span>
        );
      })}
    </div>
  );

  return (
    <div>
      {rejectModal}
      <Container>
        <h2 className='mt-4 mb-5'>
          Manage {test_group_user.user.first_name}{' '}
          {test_group_user.user.last_name}'s Immunizations
        </h2>
        <Row>
          <Col xl={7} className='px-4'>
            <div className='h-auto'>
              <h5>Search & select a vaccine</h5>
              {overdueImmunizations}
              <div className='mt-3'>
                <Form.Control
                  value={state.vaccineSearch}
                  placeholder='Search'
                  onChange={(e) => dispatch({ type: 'setVaccineSearch', value: e.target.value })}
                />
              </div>
              {state.foundVaccines.map((v) => {
                return (
                  <div
                    className='py-3 pointer border-bottom animate__animated animate__fadeIn'
                    onClick={() => {
                      const newStagedVaccineHistories = [
                        ...state.stagedVaccineHistories,
                      ];
                      newStagedVaccineHistories.push({
                        vaccine: v.name,
                        vaccinatedAt: format(new Date(), 'yyyy-MM-dd'),
                        description: v.description,
                      });
                      dispatch({ type: 'setStagedVaccineHistories', value: [...newStagedVaccineHistories] });
                      dispatch({ type: 'setVaccineSearch', value: '' });
                      dispatch({ type: 'setFoundVaccines', value: [] });
                    }}
                  >
                    <h4 className='font-weight-normal'>{v.name}</h4>
                    <div className='text-muted'>{v.description}</div>
                  </div>
                );
              })}
              {state.isSearching && 'Loading...'}

              <div className='my-4'>
                {state.stagedVaccineHistories.map((stagedVaccineHistory, index) => {
                  return (
                    <div className='my-1 animate__animated animate__fadeIn'>
                      <AddVaccineCard
                        stagedVaccineHistory={stagedVaccineHistory}
                        onChange={(newVaccineHistory) => {
                          const copied = [...state.stagedVaccineHistories];
                          copied[index] = newVaccineHistory;
                          dispatch({ type: 'setStagedVaccineHistories', value: [...copied] });
                        }}
                        onRemove={() => {
                          const newStagedVaccineHistories = [
                            ...state.stagedVaccineHistories,
                          ].filter((stagedVaccineHistory, i) => i !== index);
                          dispatch({ type: 'setStagedVaccineHistories', value: [...newStagedVaccineHistories] });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <h5 className='mt-5'>Exemptions</h5>
            {savedExemptions}
            <Exemption
              exemption={state.exemption}
              dispatch={dispatch}
              conditionOptions={props.exemption_options}
              allowNonMedicalExemptions={test_group.allow_non_medical_exemptions}
            />
            {(state.stagedVaccineHistories.length > 0 ||
              Object.keys(state.exemption).length > 0 ||
              pendingStatus) && (
              <div>
                <FabrxCheckbox
                  checked={state.confirmationCheckboxChecked}
                  className='my-2'
                  label='I confirm that the information above is correct.'
                  onChange={() => {
                    dispatch({ type: 'setConfirmationCheckboxChecked', value: !state.confirmationCheckboxChecked });
                  }}
                />
                {state.error && <div className='text-warning'>{state.error}</div>}
                <div className='d-flex justify-content-end'>
                  {pendingStatus && (
                    <Button
                      size='sm'
                      className='ms-2'
                      disabled={state.loadingSave}
                      variant='outline-secondary'
                      onClick={() => dispatch({ type: 'setShowRejectModal', value: !state.showRejectModal })}
                    >
                      Reject
                    </Button>
                  )}
                  <Button
                    size='sm'
                    className='ms-2'
                    disabled={!state.confirmationCheckboxChecked || state.loadingSave}
                    onClick={saveImmunizations}
                  >
                    {state.loadingSave && '...'}
                    {!state.loadingSave && pendingStatus ? 'Approve' : 'Add & save'}
                  </Button>
                </div>
              </div>
            )}
          </Col>
          <Col xl={5} className='px-4'>
            <h5 className='mb-3'>Related documentation</h5>
            <div className='text-muted'>
              Related documentation include but are not limited to: Yellow card,
              medical exemptions, etc.
            </div>
            <div className='my-4'>
              <StudentUploadsList
                studentUploads={studentUploads}
                onDelete={onDeleteStudentUpload}
              />
            </div>
            {document_scanner && (
              state.isScanning ? (
                <div className='text-center my-3'>
                  <FontAwesomeIcon icon='spinner' className='fa-spin' />
                </div>
              ) : (
                <Button
                  block
                  size='sm'
                  onClick={scanDocuments}
                  variant='outline-secondary'
                  className='my-3'
                  disabled={state.documentScannerDisabled}
                >
                  Scan uploaded documents for immunizations (beta)
                </Button>
              )
            )}
            <UploadBox onChange={onNewFile} style={{ minHeight: 230 }} />
            {state.files.length > 0 && (
              <FilesContainer className='my-3'>
                {state.files.map((file, idx) => (
                  <File
                    key={idx}
                    file={file}
                    actions={[
                      <RemoveButton onClick={() => removeFileAt(idx)} />,
                    ]}
                  />
                ))}
              </FilesContainer>
            )}
            <div className='text-end my-2'>
              <Button
                size='sm'
                onClick={uploadFiles}
                variant='outline-secondary'
                disabled={state.loadingUpload}
                className='mt-2'
              >
                {state.loadingUpload && '...'}
                {!state.loadingUpload && 'Upload'}
              </Button>
            </div>
          </Col>
        </Row>
        <a href={test_group_immunizations_immunization_url}>Back</a>
      </Container>
    </div>
  );
};

export default StudentDocumentReview;
