import React, { useState } from "react";
import { Button } from "react-bootstrap";
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import Primary from '../../../primary/index';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import OldDateOfBirthInput from '../../common/components/OldDateOfBirthInput';
import ErrorMessage from '../../common/components/ErrorMessage';

const ResultsAccess = ({ toggleHasCredentials, setData, testGroup }) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);

  const [ accessCode, setAccessCode ] = useState(urlParams.get('access_code') || "");
  const [ lastName, setLastName ] = useState("");
  const [currentDate, setCurrentDate] = useState({year: null, month: null, day: null})

  const [ error, setError ] = useState("");
  const [ dobError, setDobError ] = useState("");
  const [ accessCodeError, setAccessCodeError ] = useState("");
  const [ lastNameError, setLastNameError ] = useState("");

  const verifyCredentials = () => {
    !accessCode ? setAccessCodeError("result_page.errors.invalid_access_code") : setAccessCodeError("")

    !lastName ? setLastNameError("registration.errors.field_required") : setLastNameError("")

    if(!currentDate.year || !currentDate.month || !currentDate.day) {
      setDobError("registration.errors.field_required");
    } else if (!Primary.isValidDate(currentDate.year, currentDate.month, currentDate.day)) {
      setDobError("registration.errors.date_invalid");
    } else {
      setDobError("");
      const credentials = {
        type: "user",
        access_code: accessCode,
        last_name: lastName,
        date_of_birth: `${currentDate.year}-${currentDate.month}-${currentDate.day}`,
        test_group_id: testGroup.slug
      };

      fetch("/api_v2/tokens", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(credentials)
      }).then(response => {
        if (response.ok) {
          loadResult(response)
        } else {
          loadError(response)
        }
      })
    }
  }

  const loadResult = response => {
    response.json()
    .then(data => {
      return _loadResult(data.token)
    })
  }

  const _loadResult = (token) => {
    fetch("/results.json", {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({token: token, test_group_id: testGroup.slug})
    })
    .then(response => response.json())
    .then(data => {
      if (data.needs_contact_verfication) {
        window.location.href = `/results?token=${token}&test_group_id=${testGroup.slug}`
      } else {
        setData(data)
        toggleHasCredentials(true)
      }
    })
  }

  const loadError = response => {
    response.json()
      .then(error => {
        setError(error.message)
      })
  }

  return (
    <div className="mt-3">
      <fieldset>
        <legend className="sr-only">result access input fields</legend>
        <p>{t('result_page.landing.p1')}</p>
        <FloatingLabelInput
          className="mt-3 mb-2"
          type="text"
          value={accessCode}
          autoComplete="off"
          onChange={event => setAccessCode(event.target.value)}
          id="access_code"
          label={`${t('result_page.landing.access_code_label')} *`}
          validation={accessCodeError && 'is-invalid'}
        />
        {accessCodeError && <ErrorMessage className="mb-3" message={t(accessCodeError)} />}

        <FloatingLabelInput
          className="mt-3 mb-2"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          id="last_name"
          label={`${t('registration.last_name_label')} *`}
          validation={lastNameError && 'is-invalid'}
        />
        {lastNameError && <ErrorMessage className="mb-3" message={t(lastNameError, {field: `${t('registration.last_name_label')}`})} />}

        <p className="mb-2">{t('registration.date_of_birth')} *</p>
        <OldDateOfBirthInput setCurrentDate={setCurrentDate} currentDate={currentDate} errors={dobError} />
        {dobError && <ErrorMessage className="mb-3" message={t(dobError, {field: `${t('registration.date_of_birth')}`})} />}
      </fieldset>

      <Button className="mt-3" data-test-hook="submit" onClick={verifyCredentials} variant="primary">Submit</Button>
      {error && <ErrorMessage className="mt-3" message={error} />}
    </div>
  )
}

export default ResultsAccess;
