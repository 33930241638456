const StickyMessage = () => {
  const orderAdministeredMessage = localStorage.getItem('orderAdministeredMessage');

  const displayAdministeredSuccess = () => {
    toastr.success(orderAdministeredMessage, null, { timeOut: 5000 });
    localStorage.removeItem('orderAdministeredMessage');
  }

  if (orderAdministeredMessage) displayAdministeredSuccess();
  return null;
};

export default StickyMessage;
