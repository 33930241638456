import React, { useContext, useState } from "react";
import OtcContext from '../contexts/OtcContext';
import LocalizedMarkdown from "../../Registration/components/registration/fields/LocalizedMarkdown";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { verifyIfPhone } from "./OtcFlow";
import { useTranslation } from 'react-i18next';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

const formatDate = (date) => {
  if (!date) return;
  const dateParts = date.split("-");
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
}

export const MultiplexResults = ({
  multiplexSubTests,
  multiplexResult,
  simplifiedSummary=false,
  testConfiguration,
}) => {

  const { t, i18n } = useTranslation();
  const [resultMarkdown, setResultMarkdown] = useState(null);

  const ResultBadge = ({
    subTestKey,
    subTestLabel,
    subTestResult,
    simplifiedSummary,
    setResultMarkdown,
    selected,
  }) => {

    let icon;
    if (subTestResult === "positive") {
      icon = <img src="/images/icons/icon-plus-circle.svg"/>
    } else if (subTestResult === "negative") {
      icon = <img src="/images/icons/icon-minus-circle.svg"/>
    }

    return (
      <div className="my-1">
        {subTestLabel}: <b>{t(`employer_testing.results.${subTestResult}`)} {icon}</b>
        {!simplifiedSummary && (
          selected ? (
            <span
              className="ms-2 pointer"
              onClick={() => setResultMarkdown(null)}
            >
              <u>More info </u><FontAwesomeIcon className="ms-1" icon={faChevronUp} />
            </span>
          ) : (
            <span
              style={{color: "var(--bs-primary)"}}
              className="ms-2 pointer"
              onClick={() => setResultMarkdown({subTestKey: subTestKey, subTestResult: subTestResult})}
            >
              More info <FontAwesomeIcon className="ms-1" icon={faChevronDown} />
            </span>
          )
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="mt-3">
        {multiplexSubTests.map((st) => {
          return (
            <ResultBadge
              subTestKey={st.key}
              subTestLabel={st.label}
              subTestResult={multiplexResult[st.key]}
              simplifiedSummary={simplifiedSummary}
              setResultMarkdown={setResultMarkdown}
              selected={resultMarkdown?.subTestKey === st.key}
            />
          )
        })}
      </div>
      {!simplifiedSummary && resultMarkdown && (
        <Card.Body className="p-4 my-3">
          <LocalizedMarkdown
            container={testConfiguration}
            stringKey={`${testConfiguration.service_specification}_${resultMarkdown.subTestKey}_${resultMarkdown.subTestResult}_result_text`}
          />
        </Card.Body>
      )}
    </div>
  )
}

const OtcSummary = () => {

  const {
    t,
    user,
    dateOfBirth,
    result,
    testConfiguration,
    resultDate,
    resultTime,
    allowSelfResulting,
    testGroup,
    appointment,
    hideConfirmationPageLink,
    isTestMultiplex,
    multiplexSubTests,
    multiplexResult,
  } = useContext(OtcContext);

  const adminSection = (
    <div>
      <h4 className="mb-4">{t("otc.test_submitted", {first_name: user.first_name, test: testConfiguration.display_name})}</h4>
      <LocalizedMarkdown
        container={testConfiguration}
        stringKey={"self_admin_confirmation"}
      />
      {!hideConfirmationPageLink && (
        <div className="center my-5">
          <a style={{ color: "var(--bs-primary)" }} href={`/t/${testGroup.slug}/u/${appointment.access_code}`}>
            {t("employer_testing.return_to_confirmation")}
          </a>
        </div>
      )}
    </div>
  )

  const dateToDisplay = resultDate ? new Date(resultDate) : new Date()
  if (resultDate) dateToDisplay.setHours(dateToDisplay.getHours() + (dateToDisplay.getTimezoneOffset() / 60))
  const formattedDate = format(dateToDisplay, 'MMMM dd, yyyy')
  const formattedDateOfBirth = formatDate(user.date_of_birth ? user.date_of_birth : `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`);

  const resultSection = (
    <div>
      <h4 className="mb-3">{t("otc.results")}</h4>

      <Card className="p-4">
        <h6>{user.full_name}</h6>
        <div className="text-muted">{t("otc.dob")} {formattedDateOfBirth}</div>
        <hr />
        <Row>
          <Col xs="2" className="d-flex flex-center-vertical">
            <FontAwesomeIcon size="2x" icon={testConfiguration.font_awesome_icon} color="#2862FA"/>
          </Col>
          <Col className="d-flex flex-center-vertical">
            <div>
              <div>{testConfiguration.display_name}</div>
              <div>
                {!verifyIfPhone() && t("otc.administered")}{" "}
                {formattedDate}
                {resultDate && resultTime && ` at ${format(new Date(`${resultDate.replace(/-/g, "/")} ${resultTime}`), 'h:mm aaa')}`}
              </div>
              {!isTestMultiplex && (
                <div style={{ color: result === "positive" ? "red" : result === "negative" ? "green" : "black" }}> {t(`employer_testing.results.${result}`)}</div>
              )}
            </div>
          </Col>
        </Row>
        {isTestMultiplex ? (
          <MultiplexResults
            multiplexSubTests={multiplexSubTests}
            multiplexResult={multiplexResult}
            testConfiguration={testConfiguration}
          />
        ) : (
          <Card.Body>
            <LocalizedMarkdown
              container={testConfiguration}
              stringKey={result ? `${result}_result_text` : "self_admin_confirmation"}
            />
          </Card.Body>
        )}
      </Card>

      {!hideConfirmationPageLink && (
        <div className="center my-5">
          <a style={{ color: "var(--bs-primary)" }} href={`/t/${testGroup.slug}/u/${appointment.access_code}`}>
            {t("employer_testing.return_to_confirmation")}
          </a>
        </div>
      )}
    </div>
  )

  return (
    <div>
      {allowSelfResulting ? resultSection : adminSection}
    </div>
  )
}

export default OtcSummary;
