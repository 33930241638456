import axios from 'axios';

export const pushQueryParam = (key: string, value: any, state = {}) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState(state, '', url.toString());
};

export const deleteQueryParam = (key: string, state = {}) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  window.history.pushState(state, '', url.toString());
};

export const updateQueryParams = (
  {
    updateValues = {},
    deleteKeys = [],
  }: {
    updateValues?: { [key: string]: any };
    deleteKeys?: Array<string>;
  },
  state: {} = {},
) => {
  const url = new URL(window.location.href);
  Object.entries(updateValues).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  deleteKeys.forEach((key) => url.searchParams.delete(key));
  window.history.pushState(state, '', url.toString());
};

export function turbolinksOnPopState(event: PopStateEvent) {
  const Turbolinks = window['Turbolinks'];
  if (Turbolinks) {
    this.turbolinks_location = Turbolinks.Location.wrap(window.location);
    if (
      Turbolinks.controller.location.requestURL ===
      this.turbolinks_location.requestURL
    ) {
      return;
    }
  }
}
