import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  Card,
  Col,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';
import Flipper from '../../../common/components/Flipper';

const imageLinks = {
  rapid_antigen_abbott_covid19: "binaxnow-large.png",
  rapid_general: "all_rapid_boxes_with_frame.png",
  rapid_antigen_carestart_covid19: "carestart-large.png",
};

const links = {
  rapid_antigen_abbott_covid19: "self_checkouts",
  rapid_quidel_quickvue: "quidel_self_checkouts",
  rapid_i_health: "ihealth_self_checkouts",
  rapid_general: "general_self_checkouts",
};

const getReportingLink = (testGroup, appointment, testConfiguration, token="") => {
  return links[testConfiguration.service_specification]
    ? `/otc?flow=${links[testConfiguration.service_specification]}&access_code=${appointment.access_code}&test_configuration_id=${testConfiguration.id}&token=${token}`
    : `/otc?flow=self_admin&access_code=${appointment.access_code}&test_configuration_id=${testConfiguration.id}&token=${token}`
}
const SelfAdministerButton = ({testGroup, testConfiguration, onlyOne, appointment, token}) => {
  const { t } = useTranslation();

  const buttonText = () => {
    return testConfiguration.service_specification == "rapid_general" ? "Other" : testConfiguration.display_name;
  }

  const link = getReportingLink(testGroup, appointment, testConfiguration, token);
  const image =
    testConfiguration.logo_url ||
    testConfiguration.results_schema?.otc?.images?.self_administration ||
    `/images/${
      imageLinks[testConfiguration.service_specification] ||
      'other_service_spec.png'
    }`;

  return (
    <a
      className='link-unstyled'
      href={link}
      data-test-hook={testConfiguration.test_type == "pcr_lab" && "selfadminister"}
    >
      <Card className="my-4 p-1 shadow-sm">
        <Row>
          <Col xs="4" className="d-flex flex-center-vertical">
            <Image src={image} style={{maxWidth: "120px", maxHeight: "65px", margin: "auto"}} rounded />
          </Col>
          <Col className="d-flex flex-center-vertical">
            <b className="ms-3">{buttonText()}</b>
          </Col>
          <Col xs="auto" className="d-flex flex-center-vertical">
            <FontAwesomeIcon icon={faChevronRight} className="me-1" size="2x" color="#0086d0" />
          </Col>
        </Row>
      </Card>
    </a>
  )
}

const SelfAdministration = ({ testGroup, appointment, newConfirmation, archived=false }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const [openModal, setOpenModal] = useState(urlParams.get('token') || urlParams.get('access_token'));
  const selfAdministeredTestConfigurations =
    testGroup.test_configurations.filter((t) => t.allow_self_administration);
  if (selfAdministeredTestConfigurations.length === 0) return <div></div>;
  const onlyOne = selfAdministeredTestConfigurations.length === 1;

  const compareDisplayNames = (a,b) => {
    return a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1;
  }
  selfAdministeredTestConfigurations.sort(compareDisplayNames);
  const idxOfGeneralTestConfig = selfAdministeredTestConfigurations.findIndex(e => e.service_specification === "rapid_general");
  const generalTestConfiguration = selfAdministeredTestConfigurations.splice(idxOfGeneralTestConfig, 1)[0]
  if (generalTestConfiguration) {
    // Push general test configuration to end
    selfAdministeredTestConfigurations.push(generalTestConfiguration);
  }

  const onlySelfResulting = selfAdministeredTestConfigurations.every(x => x.allow_self_administration && x.allow_self_resulting)
  const onlySelfAdmin = selfAdministeredTestConfigurations.every(x => x.allow_self_administration && !x.allow_self_resulting)

  return (
    <div className="my-3">
      {newConfirmation && onlySelfResulting && (
        <div>
          <h5>{t('self_resulting.report_result')}</h5>
          <p>{t('self_resulting.report_result_subtitle')}</p>
        </div>
      )}
      <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header className="border-0 pb-0">
          <span></span>
          <FontAwesomeIcon
            onClick={() => setOpenModal(false)}
            icon={faXmark}
            size="2x"
            color="#0086d0"
            style={{ cursor: 'pointer' }}
          >
            <span className="sr-only">Close</span>
          </FontAwesomeIcon>
        </Modal.Header>
        <Modal.Body data-test-hook="modal">
          <div className="mb-4">
            {selfAdministeredTestConfigurations.filter(
              (x) => x.allow_self_administration,
            ).length > 0 && (
              <>
                <h5>
                  {onlySelfResulting
                    ? t('self_resulting.report_result')
                    : t('self_resulting.activate_kit')}
                </h5>
                <hr />
              </>
            )}
            {selfAdministeredTestConfigurations
              .filter((x) => x.allow_self_administration)
              .map((testConfiguration) => (
                <SelfAdministerButton
                  testGroup={testGroup}
                  testConfiguration={testConfiguration}
                  onlyOne={onlyOne}
                  appointment={appointment}
                  token={urlParams.get('token')}
                />
              ))}
          </div>
        </Modal.Body>
      </Modal>
      {(onlySelfResulting || onlySelfAdmin) &&
      selfAdministeredTestConfigurations.filter(
        (x) => x.allow_self_administration,
      ).length > 1 ? (
        <button
          block
          data-test-hook="openselfadminister"
          size="lg"
          className={`btn btn-block pure-button ${archived ? "disabled" : null}`}
          onClick={(e) => {
            e.preventDefault();
            if (onlyOne) {
              const link = getReportingLink(
                testGroup,
                appointment,
                selfAdministeredTestConfigurations[0],
                urlParams.get('token'),
              );
              window.location.href = link;
            } else {
              setOpenModal(true);
            }
          }}
        >
          {onlySelfResulting
            ? t('self_resulting.report_result')
            : t('self_resulting.activate_kit')}
        </button>
      ) : (
        <div>
          {selfAdministeredTestConfigurations
            .filter((x) => x.allow_self_administration)
            .map((testConfiguration) => {
              const link = getReportingLink(
                testGroup,
                appointment,
                testConfiguration,
                urlParams.get('token'),
              );
              return (
                <a
                  href={link}
                  className="btn pure-button btn-block pt-3 my-2"
                  data-test-hook="selfadminister"
                  style={archived ? {backgroundColor: "#717D96", pointerEvents: "none"} : null}
                >
                  {onlyOne
                    ? onlySelfResulting
                      ? t('self_resulting.report_result')
                      : t('self_resulting.activate_kit')
                    : testConfiguration.allow_self_resulting
                    ? t('self_resulting.report_custom_result', {
                        test: testConfiguration.display_name,
                      })
                    : t('self_resulting.activate_custom_test', {
                        test: testConfiguration.display_name,
                      })}
                </a>
              );
            })}
        </div>
      )}
      <Flipper feature={appointment.order_test_kit_url}>
        <Flipper.Enabled>
          <a
            className="btn btn-outline-primary w-100"
            href={appointment.order_test_kit_url}
            data-method="post"
          >
            {t('self_resulting.order_a_test_kit')}
          </a>
        </Flipper.Enabled>
      </Flipper>
    </div>
  );
};

export default SelfAdministration;
