import React, { useState } from 'react';
import FloatingLabelInput from '../common/components/FloatingLabelInput';
import axios from 'axios';
import { debounce } from 'lodash';  
import SearchBox from './autocomplete/SearchBox';
import Item from './autocomplete/Item';

const convertMarkdownToHTML = (markdownString) => {
  if (!markdownString) return markdownString;
  // Replace **bold** with <strong>bold</strong>
  const htmlString = markdownString.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  return htmlString;
}

const HereItem = ({markdown, id, ...props}) => {
  const mainText = convertMarkdownToHTML(markdown.main_text),
    secondaryText = convertMarkdownToHTML(markdown.secondary);
  return (
    <Item
      mainText={mainText}
      secondaryText={secondaryText}
      {...props}
    />
  )
} 

const HereTechAutocomplete = ({
  className,
  name,
  onChange,
  invalid,
  value,
  ...props
}) => {
  const [items, setItems] = useState([]);
  const[showOptions, setShowOptions] = useState(false);

  const handleInputChange = debounce((search) => {
    axios.get('/geolocation/autocomplete', {params: { search }}).then(
      (response) => {
        setItems(response.data);
        setShowOptions(true);
      }
    )
  }, 500)
  
  const handleChange = (event) => {
    onChange(event)
    handleInputChange(event.target.value)
  };

  return (
    <div className={className || undefined} data-testid="hereTechAutocomplete">
      <div style={{position: 'relative'}}>
        <FloatingLabelInput
          onChange={handleChange}
          prependIcon="map-marker-alt"
          name={name}
          onBlur={() => setShowOptions(false)}
          autoComplete="new-password"
          type="text"
          value={value || ''}
          validation={invalid && 'is-invalid'}
          ariaInvalid={!!invalid}
          {...props}
        />
        {showOptions && items.length > 0 && (
          <SearchBox
            items={items}
            onClickItem={(value) => {
              onChange({target: {value: value.address.label, name, ...value}});
              setItems([]);
            }}
            ItemComponent={HereItem}
          />
        )}
      </div>
    </div>
  );
}

export default HereTechAutocomplete;
