import axios from 'axios';

const assignTestRequest = ({ id: case_report_id, test_group: { id } }) => {
  return axios.put(
    `/test_groups/${id}/case_reports/${case_report_id}/self_assign`,
  );
};

export const assignTest = (test, setAssigned, setAssigning) => {
  setAssigning(true);
  assignTestRequest(test)
    .then((response) => {
      const data = response.data;
      setAssigned(data.assigned_user_name);
      toastr.success(`Assigned to ${data.assigned_user_name}`);
    })
    .catch((error) => {
      setAssigning(false);
      const data = error.response.data;
      if ('message' in data) {
        toastr.error(data['message']);
      } else {
        toastr.error(`Failed to assigned to ${data.assigned_user_name}`);
      }
    });
};

export const assignTests = (tests, selection) => {
  const fn = () => {};
  tests.forEach((test) => {
    if (selection[test.id] === true) {
      assignTest(test, fn, fn);
    }
  });
  setTimeout(() => {
    window.location = window.location.href;
  }, 1500);
};

const updateTestRequest = (
  { id: case_report_id, test_group: { id } },
  params,
) => {
  return axios.put(
    `/test_groups/${id}/case_reports/${case_report_id}.json`,
    params,
  );
};

export const loopThru = (tests, selection, fn = () => {}) => {
  tests.forEach((test) => {
    if (selection[test.id] === true) {
      fn(test);
    }
  });
  setTimeout(() => {
    window.location.reload();
  }, 1500);
};

export const assignTo = (tests, selection) => {
  const $selected = $('select#assign_to_user option:checked');
  const user = $selected.val();
  const user_name = $selected.text();
  if (user && user != '') {
    const assignTo = user === "-1" ? null : user;
    const confirmationMessage = assignTo === null
      ? `Are you sure you want to unassign those cases?`
      : `Are you sure you assign those cases to ${user_name}?`;

    window.confirm(confirmationMessage) &&
      loopThru(tests, selection, (test) => {
        updateTestRequest(test, {
          assigned_user_id: assignTo,
        })
          .then((response) => {
            const data = response.data;
            toastr.success(assignTo ? `Assigned to ${user_name}` : `Unassigned cases`);
          })
          .catch((error) => {
            const data = error.response.data;
            if ('message' in data) {
              toastr.error(data['message']);
            } else {
              toastr.error(`Failed to assigned to ${user_name}`);
            }
          });
      });
  } else {
    toastr.warning('Please select a user to assign to.');
  }
};

const updateCaseRequest = ({ id: test_id, test_group: { id } }, params) => {
  return axios.post(`/test_groups/${id}/case_reports.json`, {
    case_report_history: Object.assign({ test_id }, params),
  });
};

export const caseComplete = (tests, selection) => {
  window.confirm(`Are you sure you to mark those cases as complete?`) &&
    loopThru(tests, selection, (test) => {
      updateCaseRequest(test, {
        case_report_status: 'contacted_complete',
      })
        .then((response) => {
          const data = response.data;
          toastr.success(`Marked as complete!`);
        })
        .catch((error) => {
          const data = error.response.data;
          if ('message' in data) {
            toastr.error(data['message']);
          } else {
            toastr.error(`Failed to mark case as complete!`);
          }
        });
    });
};
