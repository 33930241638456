import React from "react";
import VaccineFormV2 from "../forms/VaccineFormV2";

const GenericVaccineCardBody = ({
  ...props
 }) => {
  return (
    <div>
      <VaccineFormV2
        {...props}
      />
    </div>
  );
};

export default GenericVaccineCardBody;