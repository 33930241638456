import React from 'react';

const ShowEyeball = ({ color = '#2862FA', ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99987 6C7.34301 6 5.99987 7.34315 5.99987 9C5.99987 10.6569 7.34301 12 8.99987 12C10.6567 12 11.9999 10.6569 11.9999 9C11.9999 7.34315 10.6567 6 8.99987 6ZM7.49987 9C7.49987 8.17157 8.17144 7.5 8.99987 7.5C9.8283 7.5 10.4999 8.17157 10.4999 9C10.4999 9.82843 9.8283 10.5 8.99987 10.5C8.17144 10.5 7.49987 9.82843 7.49987 9Z"
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path
      d="M17.9204 8.66406C17.9206 8.66434 17.9207 8.66459 17.2499 9C17.9207 9.33541 17.9206 9.33566 17.9204 9.33594L17.9192 9.33835L17.9166 9.34347L17.9081 9.36013C17.901 9.37405 17.8909 9.39352 17.8778 9.41817C17.8517 9.46748 17.8139 9.53758 17.7644 9.62551C17.6655 9.80127 17.52 10.0489 17.3291 10.3444C16.9483 10.934 16.3822 11.7221 15.6408 12.513C14.1715 14.0802 11.9245 15.75 8.99987 15.75C6.07524 15.75 3.82823 14.0802 2.35897 12.513C1.61749 11.7221 1.05142 10.934 0.67062 10.3444C0.479756 10.0489 0.334212 9.80127 0.235347 9.62551C0.185886 9.53758 0.148023 9.46748 0.121933 9.41817C0.108886 9.39352 0.0987744 9.37405 0.0916215 9.36013L0.0831106 9.34347L0.0805223 9.33835L0.0796456 9.3366C0.0795078 9.33633 0.0790477 9.33541 0.749868 9C0.0790477 8.66459 0.079174 8.66434 0.0793118 8.66406L0.0805223 8.66165L0.0831106 8.65653L0.0916215 8.63987C0.0987744 8.62596 0.108886 8.60648 0.121933 8.58183C0.148023 8.53252 0.185886 8.46242 0.235347 8.37449C0.334212 8.19873 0.479756 7.95114 0.67062 7.65561C1.05142 7.06598 1.61749 6.27795 2.35897 5.48704C3.82823 3.91983 6.07524 2.25 8.99987 2.25C11.9245 2.25 14.1715 3.91983 15.6408 5.48704C16.3822 6.27795 16.9483 7.06598 17.3291 7.65561C17.52 7.95114 17.6655 8.19873 17.7644 8.37449C17.8139 8.46242 17.8517 8.53252 17.8778 8.58183C17.8909 8.60648 17.901 8.62596 17.9081 8.63987L17.9166 8.65653L17.9192 8.66165L17.9204 8.66406ZM1.93068 9.53061C1.79606 9.32216 1.68739 9.14165 1.60542 9C1.68739 8.85835 1.79606 8.67784 1.93068 8.46939C2.27644 7.93402 2.78849 7.22205 3.45327 6.51296C4.79651 5.08017 6.6745 3.75 8.99987 3.75C11.3252 3.75 13.2032 5.08017 14.5465 6.51296C15.2112 7.22205 15.7233 7.93402 16.0691 8.46939C16.2037 8.67784 16.3123 8.85835 16.3943 9C16.3123 9.14165 16.2037 9.32216 16.0691 9.53061C15.7233 10.066 15.2112 10.7779 14.5465 11.487C13.2032 12.9198 11.3252 14.25 8.99987 14.25C6.6745 14.25 4.79651 12.9198 3.45327 11.487C2.78849 10.7779 2.27644 10.066 1.93068 9.53061Z"
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path
      d="M17.2499 9L17.9204 8.66406C18.026 8.87521 18.026 9.12479 17.9204 9.33594L17.2499 9Z"
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path
      d="M0.0793118 8.66406L0.749868 9L0.0790477 9.33541C-0.0265251 9.12426 -0.026261 8.87521 0.0793118 8.66406Z"
      style={{
        fill: color,
      }}
    />
  </svg>
);

export default ShowEyeball;
