import React, { useCallback } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppointmentDateCarousel from './AppointmentDateCarousel';
import AppointmentSlotGroupCard from './AppointmentSlotGroupCard';
import { CounterInput } from '../../common/components/Forms';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { formatAndLocalizeDateTime } from '../../common/locales/utils';
import { saveFamilyAndRedirectToRegistration } from '../helpers/requests';
import { sum } from '../../common/utils';
import { useTranslation } from 'react-i18next';

export const AppointmentTimeButton = ({
  onClick,
  selected,
  slot,
}) => {
  return (
    <Button
      variant='outline-tertiary'
      className={selected ? "active me-3 my-2" : "me-3 my-2"}
      size='sm'
      data-test-hook={`slot_${slot.id}`}
      onClick={() => onClick(slot)}
      selected={selected}
      slot={slot}
    >
      {slot.starts_at_hour}
    </Button>
  );
};

export const slotComponents = ({
  appointmentSelectedForEveryone = false,
  appointmentSlots,
  dispatch = null,
  selectedAppointmentSlotsById = {},
  showSimpleButtons = false,
  t,
}) => {
  if (!appointmentSlots) return [];
  let morningAdded = false;
  let eveningAdded = false;
  return appointmentSlots.reduce((result, slot) => {
    const selected = selectedAppointmentSlotsById[slot.id] === 1;
    if (slot.starts_at_hour.endsWith("AM") && !morningAdded) {
      result.push(<div className='inter body-medium-bold bold text-secondary mt-4 day-time'>{t('family_registrations.morning')}</div>);
      morningAdded = true;
    } else if (slot.starts_at_hour.endsWith("PM") && !eveningAdded) {
      result.push(<div className='inter body-medium-bold bold text-secondary mt-4 day-time'>{t('family_registrations.afternoon')}</div>);
      eveningAdded = true;
    }
    if (showSimpleButtons) {
      result.push(
        <AppointmentTimeButton
          onClick={(slot_id) => {
            dispatch({
              type: 'updateForm',
              values: {
                selectedAppointmentSlotsById: {
                  [slot.id]: 1,
                },
              },
            })
          }}
          selected={selected}
          slot={slot}
        />
      );
    } else {
      result.push(
        <Row className="flex-center mb-2 ms-1">
          <Col xs={dispatch ? 4 : 6} className="body-medium-normal inter medium">
            {slot.starts_at_hour} {slot.time_zone}
          </Col>
          <Col xs={dispatch ? 4 : 6} className="body-medium-normal text-muted">
            {t('family_registrations.slot_available', { number: slot.capacity })}
          </Col>
          {!!dispatch && (
            <Col xs={4}>
              <CounterInput
                id={`${slot.id}`}
                handleDecrement={() =>
                  dispatch({
                    type: 'updateForm',
                    values: {
                      selectedAppointmentSlotsById: {
                        ...selectedAppointmentSlotsById,
                        [slot.id]:
                          selectedAppointmentSlotsById[slot.id] -
                          1,
                      },
                    },
                  })
                }
                handleIncrement={() =>
                  dispatch({
                    type: 'updateForm',
                    values: {
                      selectedAppointmentSlotsById: {
                        ...selectedAppointmentSlotsById,
                        [slot.id]:
                          (selectedAppointmentSlotsById[
                            slot.id
                          ] || 0) + 1,
                      },
                    },
                  })
                }
                count={
                  selectedAppointmentSlotsById[slot.id] || 0
                }
                incrementDisabled={
                  appointmentSelectedForEveryone ||
                    selectedAppointmentSlotsById[slot.id] === slot.capacity
                }
              />
            </Col>
          )}
        </Row>
      )
    }
    return result;
  }, []);
};

const SelectAppointmentSlot = ({ state, dispatch, t }) => {
  const appointmentSlotGroup = state.formState.appointmentSlotGroup,
    appointmentSlots =
      appointmentSlotGroup.appointment_slots_by_dates[state.formState.selectedDay || state.formState.startOn?.format("ddd, M/DD")]
        ?.appointment_slots,
    selectedAppointmentSlotsById =
      state.formState.selectedAppointmentSlotsById || {},
    appointmentSelectedForEveryone = sum(Object.values(selectedAppointmentSlotsById)) ===
      state.formState.participants.length
  const { i18n } = useTranslation();
  state.locale = i18n.language;

  return (
    <div>
      <h3 className="mt-4 poppins semibold">
        {t('family_registrations.schedule_services')}
      </h3>
      <AppointmentSlotGroupCard
        appointmentSlotGroup={state.formState.appointmentSlotGroup}
        t={t}
      />
      <div className="inter lead-20-medium mb-2">
        {t('family_registrations.select_x_appointments', {
          number:
            state.formState.participants.length -
            sum(Object.values(selectedAppointmentSlotsById)),
        })}
      </div>
      <p className="inter regular lead-20-medium text-muted mb-4">
        {state.formState.registrationType === "family" && state.formState.participants.length > 1 && (
          <>
            {t('family_registrations.appointment_scheduling_info')}
            <br />
            <br />
          </>
        )}
        {t('family_registrations.hold_selected_appointments')}
      </p>
      <div className="my-4">
        <AppointmentDateCarousel
          appointmentSlotsByDates={appointmentSlotGroup.appointment_slots_by_dates}
          displayTotalNumberOfAppointments
          onClick={(day) =>
            dispatch({
              type: 'updateForm',
              values: {
                selectedDay: day,
              }
            })
          }
          selectedDay={state.formState.selectedDay || state.formState.startOn?.format("ddd, M/DD")}
          t={t}
        />
      </div>
      {(state.formState.selectedDay || state.formState.startOn) && state.formState.participants.length > 1 && (
        <Row className="mb-4">
          <Col xs={12} md={6} className='overflow-y-auto' style={{ maxHeight: 400 }}>
            <div className="inter mb-2 body-medium-bold bold mt-4">
              {t('family_registrations.available_appointments')}
            </div>
            {slotComponents({
              appointmentSelectedForEveryone,
              appointmentSlots,
              dispatch,
              selectedAppointmentSlotsById,
              showSimpleButtons: state.formState.participants.length === 1,
              t,
            }).map((component, idx) => (
              <React.Fragment key={idx}>
                {component}
              </React.Fragment>
            ))}
          </Col>
          <Col xs={12} md={6}>
            <div className="inter body-medium-bold bold mt-4">
              {t('family_registrations.appointments_selected')}
            </div>
            {sum(Object.values(selectedAppointmentSlotsById)) === 0 && (
              <p className="body-large-16-regular text-muted m-3">
               {t('family_registrations.no_appointments_selected')}
              </p>
            )}
            {Object.keys(selectedAppointmentSlotsById).map((id) => {
              const allAppointmentSlots = Object.values(appointmentSlotGroup.appointment_slots_by_dates)
                .map(asbd => asbd["appointment_slots"]).flat();
              const appointmentSlot = allAppointmentSlots.find(
                (appointmentSlot) => appointmentSlot.id === parseInt(id),
              );
              const localizedStartsAtDate = formatAndLocalizeDateTime(new Date(appointmentSlot.localized_starts_at_date), 'eeee, MMMM dd, yyyy', i18n.language);
              return Array.from(
                { length: selectedAppointmentSlotsById[id] },
                (_, idx) => (
                  <div key={idx}>
                    <Button
                      variant="link"
                      onClick={() =>
                        dispatch({
                          type: 'updateForm',
                          values: {
                            selectedAppointmentSlotsById: {
                              ...selectedAppointmentSlotsById,
                              [id]: selectedAppointmentSlotsById[id] - 1,
                            },
                          },
                        })
                      }
                    >
                      <div className="body-medium-bold text-nowrap">
                        {localizedStartsAtDate || appointmentSlot.localized_starts_at_date} @{' '}
                        {appointmentSlot.localized_starts_at_time}{' '}
                        <FontAwesomeIcon icon={faXmark} className="ms-2" />
                      </div>
                    </Button>
                  </div>
                ),
              ).map((button) => button);
            })}
          </Col>
        </Row>
      )}
      {(state.formState.selectedDay || state.formState.startOn) && state.formState.participants.length === 1 && (
        <div className="mb-4">
          {slotComponents({
            appointmentSelectedForEveryone,
            appointmentSlots,
            dispatch,
            selectedAppointmentSlotsById,
            showSimpleButtons: state.formState.participants.length === 1,
            t,
          }).map((component, idx) => (
            <React.Fragment key={idx}>
              {component}
            </React.Fragment>
          ))}
        </div>
      )}
      <div className="d-flex flex-row justify-content-end mt-2">
        <Button
          className="d-none d-sm-block me-2"
          variant="link"
          onClick={() => dispatch({ type: 'perform_navigation', action: 'back' })}
          data-test-hook="back"
        >
          {t('otc.back_button')}
        </Button>
        <Button
          className="d-none d-sm-block"
          data-test-hook="submit"
          disabled={!appointmentSelectedForEveryone}
          onClick={() => saveFamilyAndRedirectToRegistration(state, dispatch)}
        >
          {t('otc.next_button')}
        </Button>
      </div>
      <div className="d-sm-none">
        <Button
          className="my-3"
          block
          variant="link"
          onClick={() => dispatch({ type: 'perform_navigation', action: 'back' })}
          data-test-hook="back"
        >
          {t('otc.back_button')}
        </Button>
        <Button
          block
          data-test-hook="submit"
          disabled={!appointmentSelectedForEveryone}
          onClick={() => saveFamilyAndRedirectToRegistration(state, dispatch)}
        >
          {t('otc.next_button')}
        </Button>
      </div>
    </div>
  );
};

export default SelectAppointmentSlot;
