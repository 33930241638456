import React from 'react';

export const OpenCircleCheck = ({ color = '#2E948E', ...props }) => (
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0166 9.21542C19.9096 8.78771 21.8902 8.9834 23.663 9.77329C24.1675 9.99807 24.7587 9.77134 24.9834 9.26687C25.2082 8.76239 24.9815 8.17122 24.477 7.94644C22.3103 6.98101 19.8896 6.74184 17.5758 7.2646C15.2621 7.78736 13.1793 9.04404 11.6381 10.8472C10.0969 12.6504 9.17992 14.9035 9.02384 17.2704C8.86777 19.6373 9.48099 21.9913 10.7721 23.9812C12.0631 25.9711 13.9628 27.4904 16.1879 28.3125C18.413 29.1345 20.8442 29.2153 23.1189 28.5427C25.3936 27.8702 27.39 26.4803 28.8103 24.5804C30.2306 22.6806 30.9986 20.3725 31 18.0004V17.0799C31 16.5276 30.5523 16.0799 30 16.0799C29.4477 16.0799 29 16.5276 29 17.0799V17.9993C28.9989 19.9401 28.3705 21.8285 27.2084 23.3829C26.0464 24.9374 24.413 26.0745 22.5518 26.6248C20.6907 27.1751 18.7015 27.109 16.881 26.4364C15.0605 25.7638 13.5062 24.5208 12.4499 22.8927C11.3935 21.2645 10.8918 19.3386 11.0195 17.402C11.1472 15.4654 11.8975 13.622 13.1585 12.1467C14.4194 10.6713 16.1235 9.64314 18.0166 9.21542Z"
      fill={color}
    />
    <path
      d="M30.7075 10.7066C31.0978 10.3159 31.0975 9.68275 30.7068 9.29242C30.316 8.90209 29.6829 8.90241 29.2925 9.29313L19.9996 18.5953L17.7071 16.3028C17.3166 15.9122 16.6834 15.9122 16.2929 16.3028C15.9024 16.6933 15.9024 17.3265 16.2929 17.717L19.2929 20.717C19.4805 20.9046 19.7349 21.0099 20.0003 21.0099C20.2656 21.0098 20.52 20.9043 20.7075 20.7166L30.7075 10.7066Z"
      fill={color}
    />
  </svg>
);

export default OpenCircleCheck;
