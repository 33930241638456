import React, { useState } from 'react';
import { vaccineTestShape } from './shapes';
import { humanize } from '../../common/utils';
import VaccineForm, { computeCompleteStatus } from './forms/VaccineForm';
import { Row, Col, Button, Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faTimes,
  faCheck,
  faArrowsRotate
} from '@fortawesome/pro-solid-svg-icons';
import {
  faLockOpen,
  faLock,
} from '@fortawesome/pro-regular-svg-icons';
import CheckoutServiceCardErrors from './CheckoutServiceCardErrors';
import ServiceCard, {
  CardOptionsWrapper,
  SectionsWrapper,
  ServiceSummaryContainer,
  SummaryBadge,
} from '../../common/components/ServiceCard';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import { VACCINE_LABELS } from './fields/VaccineSteps';
import { VaccinePdfLabelPrinter } from './PdfLabelPrinter';
import { hexToRgba } from '../../../bundles/common/components/ServiceCard';
import Wrapped from '../../common/components/Wrapped';

const PrintOptions = ({
  test,
  updateTest,
  submitTests,
  handleOnComplete: onComplete,
}) => {
  const {
    administered,
    vaccine_result: vaccineResult,
    test_survey: testSurvey,
  } = test;

  const { complete } = computeCompleteStatus(vaccineResult, testSurvey);
  const printDisabled = !administered || !complete;

  return (
    <Wrapped
      WrapperComponent={TooltipWrapper}
      wrapIf={printDisabled}
      componentProps={{
        tooltipContent: 'Available after clicking "Log administration"',
      }}
    >
      <div className="dropdown">
        <Button
          size="sm"
          disabled={printDisabled}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Print labels
          <FontAwesomeIcon className="ms-2" icon={['fa', 'caret-down']} />
        </Button>
        {!printDisabled && (
          <ul className="dropdown-menu dropdown-menu-end">
            {Object.keys(VACCINE_LABELS).map((key) => (
              <VaccinePdfLabelPrinter
                Component={Dropdown.Item}
                className="fw-normal"
                test={test}
                onPrint={(print) => {
                  updateTest({ ...test, administered: true });
                  submitTests([test]).then((response) => {
                    print();
                    onComplete();
                  });
                }}
                label={key}
                name={VACCINE_LABELS[key]}
              />
            ))}
          </ul>
        )}
      </div>
    </Wrapped>
  );
};

const CardOptions = ({ edit, setEdit, handleReset, ...props }) => (
  <CardOptionsWrapper>
    <PrintOptions {...props} />
    <span className="btn-group ms-4">
      <Button
        variant="link"
        data-test-hook="edit-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Button>
      <ul className="dropdown-menu dropdown-menu-end">
        <Dropdown.Item data-test-hook="edit" onClick={() => setEdit(!edit)}>
          <FontAwesomeIcon icon={edit ? faLock : faLockOpen} className="me-1" />{' '}
          {edit ? 'Lock' : 'Unlock'}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleReset}>
          <FontAwesomeIcon icon={faArrowsRotate} className="me-1" /> Reset
        </Dropdown.Item>
      </ul>
    </span>
  </CardOptionsWrapper>
);

const ServiceSummary = ({ test, expanded, ...props }) => (
  <ServiceSummaryContainer test={test} {...props}>
    {!expanded && (
      <Row>
        <Col lg={9} sm={8} className="my-1 text-muted">
          Notes: {test.notes}
          {test.administered && (
            <>
              <br />
              Administered By: {test.vaccine_result?.administrator_name} (
              {test.vaccine_result?.administrator_degree})
              <br />
              Lot #: {test.vaccine_result?.lot_number}
            </>
          )}
        </Col>
        <Col
          lg={3}
          sm={4}
          className="my-1 text-muted d-flex justify-content-end"
          style={{ alignSelf: 'end' }}
        >
          <div className="text-end">
            {test.administered ? (
              <React.Fragment>
                <span>
                  Location: {humanize(test.vaccine_result?.administration_site)}
                  <br />
                  VIS Provided:{' '}
                  {test.vaccine_result?.statement_provided ? 'True' : 'False'}
                  <br />
                </span>
                <SummaryBadge
                  icon={faCheck}
                  color="var(--bs-green)"
                  text="Administered"
                />
              </React.Fragment>
            ) : (
              <SummaryBadge
                icon={faTimes}
                color="var(--bs-danger)"
                text="Not administered"
              />
            )}
          </div>
        </Col>
      </Row>
    )}
  </ServiceSummaryContainer>
);

const Sections = ({ visible, setEdit, handleOnComplete, ...props }) => (
  <SectionsWrapper visible={visible}>
    <VaccineForm onComplete={handleOnComplete} hidePrintOptions {...props} />
  </SectionsWrapper>
);

const VaccineCard = ({ ...props }) => {
  const [edit, setEdit] = useState(!props.test.administered);

  return (
    <ServiceCard {...props}>
      <CardOptions edit={edit} setEdit={setEdit} {...props} />
      <ServiceSummary expanded={edit} {...props} />
      <Sections visible={edit} setEdit={setEdit} {...props} />
    </ServiceCard>
  );
};

const UpdateVaccineResult = ({
  test,
  updateTest,
  submitTests,
  qualified = true,
  consent,
  currentAdministratorName,
  setCurrentAdministratorName,
  currentAdministratorDegree,
  setCurrentAdministratorDegree,
  currentAdministratorInitials,
  setCurrentAdministratorInitials,
}) => {
  const iconStyle = {
    color: test.test_configuration.color_code,
  };
  const rgbaColor = hexToRgba(test.test_configuration.color_code, 0.07);
  const backgroundStyle = {
    backgroundColor: rgbaColor,
    padding: '15px 20px',
  };

  const [edit, setEdit] = useState(!test.locked);
  const resetVaccine = () => ({
    ...test,
    administered: null,
    notes: null,
    test_survey: { ...test.test_survey, admin_approved: null },
    vaccine_result: {
      ...test.vaccine_result,
      administrator_initials: null,
      administrator_name: null,
      administration_site: null,
      administration_type: null,
      administrator_degree: null,
      administrator_id: null,
      dose: null,
      expiration_date: null,
      lot_number: null,
      refusal_note: null,
      refusal_state: 'no_refusal',
      statement_provided: null,
      vaccine_lot_id: null,
    },
  });

  const handleOnComplete = () => setEdit(false);

  const handleReset = () => {
    updateTest(resetVaccine());
    setEdit(true);
  };

  return (
    <Card style={backgroundStyle}>
      <Row>
        <Col xs={1} className="pe-0">
          <FontAwesomeIcon
            icon={['fa-regular', test.test_configuration.font_awesome_icon]}
            className="pe-2 mb-0 h1"
            style={iconStyle}
          />
        </Col>
        <Col xs={11}>
          <Row>
            <Col xs={10}>
              <b>
                {test.test_configuration.display_name} (
                {test.test_configuration.short_code})
              </b>
              <CheckoutServiceCardErrors
                {...consent}
                meetsAgeRequirements={qualified}
                checkoutAlert={test.test_configuration.checkout_alert}
              />
            </Col>
            <Col xs={2} className="text-end">
              <span className="btn-group">
                <Button
                  variant="link"
                  data-test-hook="edit-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
                <ul className="dropdown-menu">
                  <Dropdown.Item
                    data-test-hook="edit"
                    onClick={() => setEdit(!edit)}
                  >
                    {edit ? (
                      <>
                        <FontAwesomeIcon icon={faLock} className="me-1" /> Lock
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faLockOpen} className="me-1" />{' '}
                        Unlock
                      </>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleReset}>
                    <FontAwesomeIcon icon={faArrowsRotate} className="me-1" /> Reset
                  </Dropdown.Item>
                </ul>
              </span>
            </Col>
            {edit ? (
              <VaccineForm
                test={test}
                setEdit={setEdit}
                updateTest={updateTest}
                submitTests={submitTests}
                onComplete={handleOnComplete}
                currentAdministratorName={currentAdministratorName}
                setCurrentAdministratorName={setCurrentAdministratorName}
                currentAdministratorDegree={currentAdministratorDegree}
                setCurrentAdministratorDegree={setCurrentAdministratorDegree}
                currentAdministratorInitials={currentAdministratorInitials}
                setCurrentAdministratorInitials={
                  setCurrentAdministratorInitials
                }
              />
            ) : (
              <>
                <Col sm={9} className="my-1">
                  <div className="text-muted">
                    Notes: {test.notes}
                    {test.administered && (
                      <>
                        <br />
                        Administered By:{' '}
                        {test.vaccine_result?.administrator_name} (
                        {test.vaccine_result?.administrator_degree})
                        <br />
                        Lot #: {test.vaccine_result?.lot_number}
                      </>
                    )}
                  </div>
                </Col>
                <Col sm={3} className="my-1 text-muted text-end">
                  {test.administered ? (
                    <React.Fragment>
                      Location:{' '}
                      {humanize(test.vaccine_result?.administration_site)}
                      <br />
                      VIS Provided:{' '}
                      {test.vaccine_result?.statement_provided
                        ? 'True'
                        : 'False'}
                      <br />
                      <span style={{ color: 'var(--bs-green)' }}>
                        <FontAwesomeIcon icon={faCheck} /> Administered
                      </span>
                    </React.Fragment>
                  ) : (
                    <span style={{ color: 'var(--bs-danger)' }}>
                      <FontAwesomeIcon icon={faTimes} /> Not Administered
                    </span>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

UpdateVaccineResult.propTypes = vaccineTestShape;

export default UpdateVaccineResult;
