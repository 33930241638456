import React, { useState } from 'react';
import axios from 'axios';
import SharedCapacitiesForm from './SharedCapacitiesForm';
import StagedSlots from './StagedSlots';

export const NewSharedCapacities = ({
  stage_url,
  create_url,
  dates: initialDates = null,
}) => {
  const [dates, setDates] = useState(null);
  const [staged, setStaged] = useState(false);
  const [slotConfigurations, setSlotConfigurations] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [processingSlots, setProcessingSlots] = useState(false);

  const stageChanges = (values) => {
    setStaged(false);
    setLoadingSlots(true);

    axios
      .post(stage_url, values)
      .then((response) => {
        setLoadingSlots(false);
        const { data } = response;
        if (data.success) {
          setSlotConfigurations(data.slot_groups);
          setDates(values.dates.sort());
          setStaged(true);
        }
      })
      .catch(() => setLoadingSlots(false));
  };

  const createStaged = (values) => {
    setProcessingSlots(true);
    axios
      .post(create_url, values)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          window.location = data.redirect_path;
        } else {
          setProcessingSlots(false);
        }
      })
      .catch(() => setProcessingSlots(false));
  };

  return (
    <React.Fragment>
      <SharedCapacitiesForm
        initialDates={initialDates}
        onSubmit={stageChanges}
        loadingSlots={loadingSlots}
      />
      {staged && (
        <StagedSlots
          slotConfigurations={slotConfigurations}
          dates={dates}
          onSubmit={createStaged}
          processingSlots={processingSlots}
        />
      )}
    </React.Fragment>
  );
};
