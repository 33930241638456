import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface FilterChipProps {
  label: string;
  onRemove?: () => void;
}

const FilterChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding-inline: 24px;
  gap: 15px;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  border-radius: 40px;
  background: var(--text-elements-background-medium, #f7f7f7);
`;

const FilterChip: FC<FilterChipProps> = ({ label, onRemove }) => (
  <FilterChipContainer>
    {label}
    {onRemove && (
      <FontAwesomeIcon
        className="pointer"
        onClick={onRemove}
        icon={faXmark}
        size="lg"
      />
    )}
  </FilterChipContainer>
);

export default FilterChip;
