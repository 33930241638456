import React, { useContext, useState, useEffect } from 'react';
import { Row, Button, Collapse } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocationDetails from './LocationDetails';
import GeoMap from 'bundles/common/components/GeoMap';
import { clusterByLatLng } from '../../shared/Clustering';
import MarkerAvailable from "images/map/marker-available@2x.png";
import MarkerAvailableSelected from "images/map/marker-available-selected@2x.png";
import MarkerWaitlist from "images/map/marker-limited@2x.png";
import MarkerWaitlistSelected from "images/map/marker-limited-selected@2x.png";
import MarkerUnavailable from "images/map/marker-notavailable@2x.png";
import MarkerUnavailableSelected from "images/map/marker-notavailable-selected@2x.png";
import RegistrationContext from '../../contexts/RegistrationContext';
import Slotter from './scheduling/calculator';
import { useTranslation } from 'react-i18next';

const withCoordinates = (appointmentSlotGroups) => {
  return appointmentSlotGroups.filter(asg => asg.latitude && asg.longitude);
};

export const markerData = (testGroup, mappableLocations, locationDetails, customIcons) => {
  const clusteredLocations = clusterByLatLng(mappableLocations);
  const data = Object.entries(clusteredLocations).map(([coordinates, locations]) => {
    const selected = locationDetails.some(activeLocation => {
      return locations.some(location => location.id == activeLocation.id)
    });
    return {
      location: locations[0],
      icon: getIcon(testGroup, locations, selected, customIcons),
      data: locations,
      key: coordinates
    };
  });
  return data;
};

const hasAvailability = (locations) => {
  return locations.some(location => location.nextAvailableAppointment);
};

const getIcon = (testGroup, locations, selected, customIcons) => {
  if (customIcons) {
    return selected ? customIcons["selected"] : customIcons["unselected"];
  } else if (hasAvailability(locations)) {
    return selected ? MarkerAvailableSelected : MarkerAvailable;
  } else if (testGroup.allow_waitlist) {
    return selected ? MarkerWaitlistSelected : MarkerWaitlist;
  } else {
    return selected ? MarkerUnavailableSelected : MarkerUnavailable;
  }
};

const initialLocationDetails = (mappableLocations) => {
  const clusteredLocations = clusterByLatLng(mappableLocations);
  const distinctCoordinatesCount = Object.keys(clusteredLocations).length;
  return distinctCoordinatesCount === 1 || mappableLocations.length <= 3 ? mappableLocations : [];
};

export default function LocationMap({ testGroup }) {
  const { t, i18n } = useTranslation();
  const { localFormState, appointmentSlotGroupsLoaded } = useContext(RegistrationContext);
  const boundingBoxes = testGroup.access_rules
    .filter(a => a.access_rule_type === 'location_coordinates')
    .map(a => a.coordinates);
  const [mappableLocations, setMappableLocations] = useState(withCoordinates(testGroup.appointment_slot_groups));
  const [locationDetails, setLocationDetails] = useState(initialLocationDetails(mappableLocations));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (appointmentSlotGroupsLoaded) {
      const slotter = new Slotter(testGroup);
      testGroup.appointment_slot_groups.forEach((appointmentSlotGroup) => {
        appointmentSlotGroup.nextAvailableAppointment = slotter.availableSlotsForAppointmentSlotGroup(
          appointmentSlotGroup, appointmentSlotGroup.consider_appointments_with_pairs_only
        )[0]?.localized_starts_at;
      });
      const locations = withCoordinates(testGroup.appointment_slot_groups);
      setMappableLocations(locations);
      const currentLocations = locationDetails.map(location => location.id)
      const newCurrentLocations = locations.filter(location => currentLocations.indexOf(location.id) != -1);
      setLocationDetails(newCurrentLocations);
    }
  }, [appointmentSlotGroupsLoaded]);

  const handleMarkerClick = (locations) => {
    setLocationDetails(locations);
  };
  const markers = markerData(testGroup, mappableLocations, locationDetails);

  const mapIsRenderable = (boundingBoxes.length > 0 || mappableLocations.length > 0)

  return mapIsRenderable && (
    <div>
      { boundingBoxes.length > 0 &&
        <h5 className="mt-4 mb-3">{ t('registration.location_availability') }</h5>
      }
      <Row>
        <div className="card gray-light-100 p-4 ">
          <GeoMap
            polygons={boundingBoxes}
            markers={markers}
            onMarkerClick={handleMarkerClick}
            className="rounded-12"
          />
          {locationDetails.length > 0 && (
            <div>
              {locationDetails.length == 1 && <h4>{locationDetails[0].title}</h4>}
              <dl>
                <dt>{t('location_details.labels.address')}:</dt>
                <dd>
                  {locationDetails[0].address}
                </dd>
              </dl>
              <div style={{marginLeft: "-24px"}}>
                <Button
                  onClick={() => setOpen(!open)}
                  aria-controls="location-details"
                  aria-expanded={open}
                  variant="link"
                >
                  {t("registration.show_service_at_this_location")}
                  <FontAwesomeIcon icon="angle-down" className="fa-angle-down ms-2" />
                </Button>
              </div>
            </div>
          )}
        </div>
        <Collapse in={open} className="p-0">
          <div>
            <div id="location-details" className="card p-4">
              {locationDetails.map(location => (
                <LocationDetails
                  key={location.id}
                  location={location}
                  testGroup={testGroup}
                  appointmentSlotGroupsLoaded={appointmentSlotGroupsLoaded}
                  manyLocations={locationDetails.length > 1}
                />
              ))}
            </div>
          </div>
        </Collapse>
      </Row>
    </div>
  )
}
