import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Card } from 'react-bootstrap';

const AlertHeader = ({
  icon = ['fa-regular', 'circle-exclamation'],
  title = 'Missing critical information',
  message,
  btnTxt,
  btnAction,
  type = 'danger',
  disabledBtn = false,
  className,
  dataTestHook,
  style,
}) => {

  let primaryColor = '#FDEBEC';
  let secondaryColor = 'danger';

  if (type === 'info') {
    primaryColor = "#F8F8FC";
    secondaryColor = "primary";
  }

  return (
    <Card
      className={className + " mb-3 p-2 px-3"}
      style={{ backgroundColor: primaryColor, ...style }}
    >
      <Row className="justify-content-around">
        <Col
          xs={1}
          className="d-flex flex-column justify-content-center"
        >
          <div className="text-center">
            <FontAwesomeIcon
              icon={icon}
              color={`var(--bs-${secondaryColor})`}
              className="my-2"
              style={{ height: "24px", width: "24px" }}
            />
          </div>
        </Col>
        <Col
          md={7}
          xs={10}
          className="d-flex flex-column justify-content-center"
        >
          <div className="my-2">
            <h6>{title}</h6>
            <div
              className="body-medium-normal inter regular"
              style={{ color: "#959393" }}
            >
              {message}
            </div>
          </div>
        </Col>
        <Col
          md={4}
          sm={12}
          className="d-flex flex-column justify-content-center"
          >
            {btnTxt &&
              <Button
                variant={secondaryColor}
                className="my-2 round-button"
                onClick={btnAction}
                disabled={disabledBtn}
                data-test-hook={dataTestHook}
              >
              {btnTxt}
            </Button>
            }
        </Col>
      </Row>
    </Card>
  );
};

export default AlertHeader;
