import React from 'react';
import { Button } from 'react-bootstrap';

const PureToggleButton = ({
  id,
  name,
  color,
  className,
  outline,
  dataTest,
  ariaLabel,
  tabIndex,
  block,
  active,
  type = "radio",
  onClick,
  autoFocus = false,
  children,
  style,
  value,
  ...props
}) => {
  return (
    <React.Fragment>
      <input
        id={id}
        className='btn-check'
        name={name}
        type={type}
        value={value}
        aria-label={ariaLabel}
        autoComplete="off"
        checked={!!active}
        disabled={!!props.disabled}
        onChange={onClick}
      />
      <Button
        variant={outline ? 'outline' : ''}
        data-test-hook={dataTest}
        block={block}
        autoFocus={autoFocus}
        className={`custom-focus pure-button ${active && 'active'} ${className}`}
        style={{ '--custom-color': color, ...style }}
        onClick={onClick}
        tabIndex={tabIndex || "-1"}
        checked={active}
        htmlFor={id}
        aria-hidden="true"
        {...props}
      >
        {children}
      </Button>
    </React.Fragment>
  );
};

export default PureToggleButton;
