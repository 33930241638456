import React from 'react';
import '../locales/i18n';
import { useTranslation } from 'react-i18next';
import range from 'lodash/range';
import { Row, Col } from "react-bootstrap";
import FloatingLabelSelect from './FloatingLabelSelect';
import { isMobile } from '../utils/browser';

const OldDateOfBirthInput = ({currentDate, setCurrentDate, errors=false, isDisabled=false}) => {
  const { t, i18n } = useTranslation();
  const { day, month, year } = currentDate;

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const monthOptions = months.map((month, index) => {
    return { value: (index + 1), label: t("date." + month), dataTestHook: `month_${month}`}
  });

  const yearOptions = range(new Date().getFullYear(), 1900).map((year) => {
    return { value: year, label: year, dataTestHook: `year_${year}` }
  });

  const dayOptions = range(1, 32).map((day) => {
    return { value: day, label: day, dataTestHook: `day_${day}` };
  })

  return (
    <Row as="fieldset">
      <legend className="sr-only">date of birth</legend>
      <Col xs={12} md={4} className="mb-2">
        <FloatingLabelSelect
          containerClassName="z6 test-dob-month"
          value={monthOptions.filter(option => option.value === month)}
          onChange={(s) => {
            setCurrentDate({...currentDate, month: s.value });
          }}
          ariaLabel="month"
          options={monthOptions}
          label={t("date.month")}
          menuPortalTarget={document.body} 
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isSearchable={!isMobile}
          filledValue={month}
          id="month"
          className={errors && 'is-invalid'}
          isDisabled={isDisabled}
        />
      </Col>
      <Col xs={12} md={4} className="mb-2">
        <FloatingLabelSelect
          containerClassName="z6 test-dob-day"
          value={dayOptions.filter(option => option.value === day)}
          onChange={(s) => {
            setCurrentDate({...currentDate, day: s.value });
          }}
          ariaLabel="day"
          options={dayOptions}
          label={t("date.day")}
          menuPortalTarget={document.body} 
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isSearchable={!isMobile}
          filledValue={day}
          id="day"
          className={errors && 'is-invalid'}
          isDisabled={isDisabled}
        />
      </Col>
      <Col xs={12} md={4} className="mb-2">
        <FloatingLabelSelect
          containerClassName="z6 test-dob-year"
          value={yearOptions.filter(option => option.value === year)}
          onChange={(s) => {
            setCurrentDate({...currentDate, year: s.value });
          }}
          ariaLabel="year"
          options={yearOptions}
          label={t("date.year")}
          menuPortalTarget={document.body} 
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isSearchable={!isMobile}
          filledValue={year}
          id="year"
          className={errors && 'is-invalid'}
          isDisabled={isDisabled}
        />
      </Col>
    </Row>
  );
};

export default OldDateOfBirthInput;
