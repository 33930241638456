import React from 'react';

const BarcodeScan = ({ color = '#000000', ...props }) => (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m0 6h6v-6h-6zm2-4h2v2h-2zm6-2v6h6v-6zm4 4h-2v-2h2zm-12 10h6v-6h-6zm2-4h2v2h-2zm11-2h1v4h-3v-1h-1v3h-2v-6h3v1h2zm0 5h1v1h-1zm-2 0h1v1h-1z"
      fill={color}
      strokeWidth="0.1"
    />
  </svg>
);

export default BarcodeScan;
