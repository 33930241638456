import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

const FloatingLabelInput = ({
  ariaDescribedby,
  ariaInvalid,
  ariaLabel,
  ariaRequired,
  autoComplete,
  autoFocus,
  className,
  dataTestHook,
  inputMode,
  inputRef = React.createRef(),
  id,
  isDisabled,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  onKeyDown,
  onKeyUp,
  onFocus,
  onBlur,
  onInput,
  pattern,
  placeholder,
  readOnly,
  type,
  validation,
  value,
  displayIcon,
  prependIcon,
  appendIcon,
  ...props
}) => {
  return (
    <div className={`input-container ${className} ${prependIcon ? 'prepended' : ''}`}>
      {prependIcon && (<i className={`fa fa-${prependIcon} prepend-input`} />)}
      <label
        className={!!value || type === 'date' ? 'filled' : undefined}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        ref={inputRef}
        aria-describedby={ariaDescribedby}
        aria-invalid={ariaInvalid}
        aria-label={ariaLabel}
        aria-required={ariaRequired}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={`form-control ${validation}`}
        data-test-hook={dataTestHook || name}
        disabled={isDisabled}
        id={id}
        inputMode={inputMode}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput}
        pattern={pattern}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value}
        {...props}
      />
      {appendIcon}
      {(displayIcon && validation === "is-invalid") && (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color="var(--bs-danger)"
          size="lg"
          className="input-icon"
          style={{ position: "absolute", marginTop: "14px", right: "16" }}
        />
      )}
    </div>
  )
}

export default FloatingLabelInput
