import React from 'react';
import { humanize } from '../../common/utils';
import { StudentStatusIcon } from './StudentProfiles';

export const FilterBadge = ({
  value,
  label,
  count = null,
  isSelected,
  onClick = null,
}) => {
  return (
    <span
      className={`fabrx-filter-chip m-1 ${isSelected && "selected"}`}
      onClick={onClick}
    >
      <StudentStatusIcon status={value} />
      {humanize(label || value)} {count !== null && `(${count})`}
    </span>
  )
}

const StudentImmunizationStatusFilters = ({
  immunization_status_counts,
}) => {

  const path = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const studentImmunizationStatus = urlParams.get('student_immunization_status');
  const studentImmunizationStatusFilters = Object.entries(immunization_status_counts).map(([s, c]) => {
    return { text: humanize(s), count: c, param: s, }
  });
  studentImmunizationStatusFilters.unshift({ text: "All", count: null, param: "" });

  const reloadWithUpdatedComplianceParam = (newStatus) => {
    newStatus === studentImmunizationStatus
      ? urlParams.set("student_immunization_status", "")
      : urlParams.set("student_immunization_status", newStatus);
    window.location.href = `${path}?${urlParams.toString()}`;
  }

  return (
    <div className='d-flex flex-wrap my-2'>
      {studentImmunizationStatusFilters.map((f) => {
        return (
          <FilterBadge
            value={f.param}
            label={f.text}
            count={f.count}
            isSelected={studentImmunizationStatus === f.param}
            onClick={() => reloadWithUpdatedComplianceParam(f.param)}
          />
        )
      })}
    </div>
  )
}

export default StudentImmunizationStatusFilters;
