import React, { useState } from 'react';
import BarcodeScanner from '../../common/components/BarcodeScanner';
import { withinAgeRange } from '../../common/utils';
import {
  InputGroup,
  Form,
  Row,
  Col,
  Card,
  Button,
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { validateCode } from './CheckoutPage';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import CheckoutServiceCardErrors from './CheckoutServiceCardErrors';
import { useRef } from 'react';
import Flipper from '../../common/components/Flipper';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

//This will probably get more complex
const Scanner = ({
  onSubmit,
  unsavedUid,
  setUnsavedUid,
  validateUid,
  testConfiguration,
  generateIconOptions,
  permission_kinds,
  editable_test_types,
}) => {
  const [showScanner, setShowScanner] = useState(false);
  const isCodeValid = validateUid(unsavedUid);

  const canScanBarcode = () =>
    permission_kinds.includes(testConfiguration.kind) &&
    editable_test_types.includes(testConfiguration.test_type);
  const cantScanBarcode = !canScanBarcode();

  const buttonText = 'Scan Barcode';

  return (
    <div>
      {generateIconOptions ? (
        <img
          src="/images/icons/icon-barcode-reader.svg"
          className={cantScanBarcode ? 'disabled-pointer' : 'pointer'}
          onClick={cantScanBarcode ? null : () => setShowScanner(true)}
          title={
            cantScanBarcode
              ? 'You do not have permission to scan barcode'
              : buttonText
          }
        />
      ) : (
        <ButtonGroupStyleProvider className="btn-group">
          <span
            className="d-inline-block"
            tabIndex="0"
            title={
              cantScanBarcode
                ? 'You do not have permission to scan barcode'
                : buttonText
            }
            data-toggle="tooltip"
            data-placement="bottom"
          >
            <Button
              onClick={cantScanBarcode ? null : () => setShowScanner(true)}
              variant={
                cantScanBarcode ? 'outline-secondary' : 'outline-primary'
              }
              size="sm"
              className="btn-checkout round-button"
              disabled={cantScanBarcode}
              aria-disabled={cantScanBarcode}
              aria-label={buttonText}
            >
              {buttonText}
            </Button>
          </span>
        </ButtonGroupStyleProvider>
      )}
      {showScanner && (
        <Modal show={showScanner} onHide={() => setShowScanner(false)}>
          <Modal.Header>
            <h5>
              <i
                className={`fa-regular fa-${testConfiguration.font_awesome_icon}`}
                style={{ color: testConfiguration.color_code }}
              ></i>{' '}
              <b>{testConfiguration.checkout_name}</b>
            </h5>
            <Button
              onClick={() => setShowScanner(false)}
              variant="link"
              className="p-0 text-dark"
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <InputGroup className="mb-4">
                <Form.Control
                  autoFocus
                  onChange={(e) => setUnsavedUid(e.target.value)}
                  placeholder="Enter it manually"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && isCodeValid) {
                      e.preventDefault();
                      onSubmit(unsavedUid);
                      setUnsavedUid('');
                      setShowScanner(false);
                    }
                  }}
                  value={unsavedUid}
                />
                <Button
                  disabled={!isCodeValid}
                  variant="primary"
                  onClick={() => {
                    onSubmit(unsavedUid);
                    setUnsavedUid('');
                    setShowScanner(false);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </InputGroup>
              <BarcodeScanner
                onScan={(unsavedUid) => {
                  setUnsavedUid(unsavedUid);
                  if (validateUid(unsavedUid)) {
                    onSubmit(unsavedUid);
                    setUnsavedUid('');
                    setShowScanner(false);
                  }
                }}
              />
              {unsavedUid.length > 0 && !isCodeValid && (
                <label className="form-label mt-2" style={{ color: 'red' }}>
                  Incorrect Barcode Format
                </label>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

const AddVaccine = ({ onClick, generateIconOptions }) =>
  generateIconOptions ? (
    <img
      src="/images/icons/icon-circle-plus.svg"
      className="pointer mx-2"
      onClick={onClick}
    />
  ) : (
    <Col className="mt-2 ms-auto text-end" sm={5} md={5} lg={4} xs={10}>
      <Button
        className="my-3 btn-checkout round-button"
        onClick={onClick}
        variant="outline-primary"
        size="sm"
      >
        Add Vaccine
      </Button>
    </Col>
  );

const AddByScan = ({
  testConfiguration,
  writeText,
  onSubmit,
  setUnsavedUid,
  unsavedUid,
  onClick,
  generateIconOptions,
  permission_kinds,
  editable_test_types,
}) => (
  <Col className="mt-2 mt-lg-0 align-self-center" xs={'auto'}>
    <Scanner
      onClick={onClick}
      unsavedUid={unsavedUid}
      setUnsavedUid={setUnsavedUid}
      onSubmit={(e) => onSubmit(e)}
      validateUid={validateCode.bind(null, testConfiguration)}
      testConfiguration={testConfiguration}
      generateIconOptions={generateIconOptions}
      permission_kinds={permission_kinds}
      editable_test_types={editable_test_types}
    />
  </Col>
);

const ButtonGroupStyleProvider = styled.div`
  .btn:not(:first-child),
  .btn-group:not(:first-child) {
    margin-left: -2px;
  }

  .dropdown-toggle-split {
    min-width: 32px;
  }
`;

const GenerateBarcode = ({
  noConfigurations,
  onGenerate,
  onBulkGenerate,
  testConfiguration,
  generateIconOptions,
  permission_kinds,
  editable_test_types,
}) => {
  const [quantity, setQuantity] = useState(1);

  const userCanLogResults = () =>
    permission_kinds.includes(testConfiguration.kind) &&
    editable_test_types.includes(testConfiguration.test_type);

  const cantLogResults = !userCanLogResults();

  let buttonText = 'Generate Barcode';
  if (
    testConfiguration.generate_record_configuration ===
    'shows_result_immediately'
  ) {
    buttonText = 'Log Results';
  } else if (
    testConfiguration.generate_record_configuration === 'generate_barcode_only'
  ) {
    buttonText = 'Add Service';
  }

  const buttonToReturn = generateIconOptions ? (
    <img
      src="/images/icons/icon-circle-plus.svg"
      className={cantLogResults ? 'disabled-pointer' : 'pointer'}
      data-test-hook="generate_test"
      onClick={cantLogResults ? null : onGenerate}
      title={
        cantLogResults
          ? 'You do not have permission to log results'
          : buttonText
      }
    />
  ) : (
    <Col className="mt-2 mt-lg-0 align-self-center d-flex" xs="auto">
      <ButtonGroupStyleProvider className="btn-group">
        <span
          className="d-inline-block"
          tabIndex="0"
          title={
            cantLogResults
              ? 'You do not have permission to log results'
              : buttonText
          }
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <button
            className={`btn btn-sm ${
              cantLogResults ? 'btn-outline-secondary' : 'btn-outline-primary'
            } round-button`}
            onClick={
              cantLogResults
                ? null
                : onBulkGenerate && quantity > 1
                ? () => onBulkGenerate(quantity)
                : onGenerate
            }
            type="button"
            disabled={cantLogResults}
            aria-disabled={cantLogResults}
            aria-label={buttonText}
          >
            {buttonText}
            {quantity > 1 && ` (x${quantity})`}
          </button>
        </span>
        {testConfiguration.generate_record_configuration !==
          'shows_result_immediately' && (
          <Flipper record={testConfiguration} flag="quantity_on_checkout">
            <Flipper.Enabled>
              <button
                type="button"
                className="btn btn-sm btn-outline-primary round-button dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <span className="text-muted dropdown-item pe-none">
                    Select quantity
                  </span>
                </li>
                {[...Array(10)].map((_, idx) => (
                  <li
                    key={idx}
                    className="pointer"
                    onClick={() => setQuantity(idx + 1)}
                  >
                    <span className="dropdown-item">{idx + 1}</span>
                  </li>
                ))}
              </ul>
            </Flipper.Enabled>
          </Flipper>
        )}
      </ButtonGroupStyleProvider>
    </Col>
  );

  return <>{buttonToReturn}</>;
};

const GenerateOptions = ({
  testConfiguration,
  onScan,
  onGenerate,
  onBulkGenerate,
  setUnsavedUid,
  unsavedUid,
  appointment,
  isVaccine,
  generateIconOptions,
  permission_kinds,
  editable_test_types,
}) => {
  if (isVaccine) {
    return (
      <AddVaccine
        onClick={onGenerate}
        generateIconOptions={generateIconOptions}
      />
    );
  }
  const tubeScanOnly =
    appointment.test_location.tube_scan_only ||
    testConfiguration.generate_record_configuration === 'scan_only';
  const noConfigurations =
    !appointment.test_location.tube_scan_only &&
    testConfiguration.generate_record_configuration === 'no_configuration';
  const scanAvailable =
    testConfiguration.generate_record_configuration === 'no_configuration' ||
    testConfiguration.generate_record_configuration === 'scan_only';

  const user = appointment.user;

  const firstName = user.first_name || '';
  const lastName = user.last_name || '';
  const writeText = firstName[0] + lastName[0];

  return (
    <React.Fragment>
      {generateIconOptions ? (
        <span>
          <div className="d-flex">
            {scanAvailable && (
              <span className="mx-2">
                <AddByScan
                  testConfiguration={testConfiguration}
                  writeText={writeText}
                  onSubmit={(unsavedUid) => onScan(unsavedUid)}
                  setUnsavedUid={setUnsavedUid}
                  unsavedUid={unsavedUid}
                  generateIconOptions={generateIconOptions}
                  permission_kinds={permission_kinds}
                  editable_test_types={editable_test_types}
                />
              </span>
            )}
            {!tubeScanOnly && (
              <span className="mx-2">
                <GenerateBarcode
                  noConfigurations={noConfigurations}
                  onGenerate={onGenerate}
                  onBulkGenerate={onBulkGenerate}
                  testConfiguration={testConfiguration}
                  generateIconOptions={generateIconOptions}
                  permission_kinds={permission_kinds}
                  editable_test_types={editable_test_types}
                />
              </span>
            )}
          </div>
        </span>
      ) : (
        <Col xs={12} lg={6}>
          <Row className="justify-content-end">
            {scanAvailable && (
              <AddByScan
                testConfiguration={testConfiguration}
                writeText={writeText}
                onSubmit={(unsavedUid) => onScan(unsavedUid)}
                setUnsavedUid={setUnsavedUid}
                unsavedUid={unsavedUid}
                permission_kinds={permission_kinds}
                editable_test_types={editable_test_types}
              />
            )}
            {!tubeScanOnly && (
              <GenerateBarcode
                noConfigurations={noConfigurations}
                onGenerate={onGenerate}
                onBulkGenerate={onBulkGenerate}
                testConfiguration={testConfiguration}
                generateIconOptions={generateIconOptions}
                permission_kinds={permission_kinds}
                editable_test_types={editable_test_types}
              />
            )}
          </Row>
        </Col>
      )}
    </React.Fragment>
  );
};

export const SimpleCreateTestOfType = ({
  testConfiguration,
  unsavedUid,
  setUnsavedUid,
  appointment,
  addTest,
  addTests = undefined,
  hideComponent,
  isVaccine,
  consentFormsUsers,
  permission_kinds,
  editable_test_types,
}) => {
  const actions = testConfiguration.test_group_test_configuration
    .shown_on_checkout ? (
    <GenerateOptions
      testConfiguration={testConfiguration}
      onScan={(unsavedUid) => {
        addTest(testConfiguration.id, unsavedUid);
        hideComponent();
      }}
      onGenerate={() => {
        addTest(testConfiguration.id);
        hideComponent();
      }}
      onBulkGenerate={
        addTests &&
        ((quantity) => {
          addTests(testConfiguration.id, quantity);
          hideComponent();
        })
      }
      setUnsavedUid={setUnsavedUid}
      unsavedUid={unsavedUid}
      appointment={appointment}
      isVaccine={isVaccine}
      generateIconOptions={true}
      permission_kinds={permission_kinds}
      editable_test_types={editable_test_types}
    />
  ) : (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div className="text-muted">This service is disabled for checkout</div>
    </div>
  );

  const qualified = testConfiguration.calculate_age_gating_in_days
    ? withinAgeRange(
        appointment.user.age_in_days,
        testConfiguration.minimum_age_in_days,
        testConfiguration.maximum_age_in_days,
      )
    : withinAgeRange(
        appointment.user.age_in_months,
        testConfiguration.minimum_age_in_months,
        testConfiguration.maximum_age_in_months,
      );

  return (
    <div
      className="p-3 my-2"
      style={{ border: '1px solid #DFE2E6', borderRadius: '8px' }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <span style={{ color: testConfiguration.color_code }}>
            <FontAwesomeIcon
              icon={['fa-regular', testConfiguration.font_awesome_icon]}
            />
          </span>
          <span className="ms-2">
            {testConfiguration.checkout_name || testConfiguration.display_name}
          </span>
          {!qualified && (
            <TooltipWrapper
              tooltipContent="Does not meet age requirement"
              placement="top"
            >
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="ms-2 text-danger"
              />
            </TooltipWrapper>
          )}
        </div>
        <div>{actions}</div>
      </div>
    </div>
  );
};

const IconArea = styled.div`
  width: 90px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${({ color }) => color};
  border-radius: 12px 0 0 12px;

  @media (width <= 768px) {
    width: 70px;
  }

  @media (width <= 576px) {
    width: 15px;
  }
`;

const CreateTestOfType = ({
  testConfiguration,
  unsavedUid,
  setUnsavedUid,
  appointment,
  addTest,
  addTests = undefined,
  hideComponent,
  isVaccine,
  consentFormsUsers,
  permission_kinds,
  editable_test_types,
}) => {
  const consentForm =
    testConfiguration.test_group_test_configuration.active_consent_form;
  const cardRef = useRef(null);
  const iconRef = useRef(null);

  const actions = testConfiguration.test_group_test_configuration
    .shown_on_checkout ? (
    <GenerateOptions
      testConfiguration={testConfiguration}
      onScan={(unsavedUid) => {
        addTest(testConfiguration.id, unsavedUid);
        hideComponent();
      }}
      onGenerate={() => {
        addTest(testConfiguration.id);
        hideComponent();
      }}
      onBulkGenerate={
        addTests &&
        ((quantity) => {
          addTests(testConfiguration.id, quantity);
          hideComponent();
        })
      }
      setUnsavedUid={setUnsavedUid}
      unsavedUid={unsavedUid}
      appointment={appointment}
      isVaccine={isVaccine}
      permission_kinds={permission_kinds}
      editable_test_types={editable_test_types}
    />
  ) : (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div className="text-muted">This service is disabled for checkout</div>
    </div>
  );

  const meetsAgeRequirements = testConfiguration.calculate_age_gating_in_days
    ? withinAgeRange(
        appointment.user.age_in_days,
        testConfiguration.minimum_age_in_days,
        testConfiguration.maximum_age_in_days,
      )
    : withinAgeRange(
        appointment.user.age_in_months,
        testConfiguration.minimum_age_in_months,
        testConfiguration.maximum_age_in_months,
      );

  return (
    <Card className="d-flex flex-row" ref={cardRef}>
      <IconArea
        className="text-light d-flex"
        color={testConfiguration.color_code}
      >
        <FontAwesomeIcon
          icon={['fa-regular', testConfiguration.font_awesome_icon]}
          className="h2 m-auto d-none d-sm-block"
        />
      </IconArea>
      <Row className="ps-3 pe-4 py-4 flex-grow-1">
        <Col style={{ alignSelf: 'center' }} lg={6} md={7} xs={12}>
          <b>
            {testConfiguration.checkout_name || testConfiguration.display_name}
          </b>
          <CheckoutServiceCardErrors
            consentForm={consentForm}
            consentFormsUsers={consentFormsUsers}
            meetsAgeRequirements={meetsAgeRequirements}
            checkoutAlert={testConfiguration.checkout_alert}
          />
        </Col>
        {actions}
      </Row>
    </Card>
  );
};

export default CreateTestOfType;
