import React, { useState } from 'react';
import { Col, Modal, Row, Button, Card } from 'react-bootstrap';
import { FilterBadge } from '../../School/components/StudentImmunizationStatusFilters';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import ModalHeader from '../../common/components/ModalHeader';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IMMUNIZATION_FILTER_LABELS } from './ImmunizationsFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';

export const EXEMPTION_CATEGORY_LABELS = {
  medical: "Medical",
  non_medical: "Non-medical",
};

const EditExemptionForm = ({
  categoryOptions,
  conditionOptions,
  exemption,
  exemptionId,
  testGroupId,
  testGroupUserId,
  typeOptions,
}) => {

  const { t } = useTranslation();
  const [newExemptionData, setNewExemptionData] = useState({
    category: exemption.category,
    condition: exemption.condition,
    exemption_type: exemption.exemption_type,
    expires_at: exemption.expires_at,
  });
  const [displayExpirationDate, setDisplayExpirationDate] = useState(newExemptionData.exemption_type === "temporary");
  const _categoryOptions = categoryOptions.map(option => ({value: option, label: EXEMPTION_CATEGORY_LABELS[option] || option}));
  const _conditionOptions = conditionOptions.map(option => ({value: option, label: t(`exemption.${option}`)}));
  const _typeOptions = typeOptions.map(option => ({value: option, label: option}));

  const updateExemption = async () => {
    try {
      const path = `/test_groups/${testGroupId}/immunizations/exemptions/${testGroupUserId}/exemptions/${exemptionId}`;
      const response = await axios.put(path, { exemption: newExemptionData });
      if (response.status === 200) {
        toastr.success("Exemption updated");
        window.location.reload(false);
      };
    } catch(e) {
      toastr.error(e.message);
    }
  };

  return (
    <Row>
      <Col sm={12} md={6} lg={true} className="mt-3">
        <FloatingLabelSelect
          label="Condition"
          value={_conditionOptions.filter(option => option.value === newExemptionData.condition)}
          options={_conditionOptions}
          filledValue={!!newExemptionData.condition}
          onChange={(s) => {
            setNewExemptionData({...newExemptionData, condition: s.value});
          }}
        />
      </Col>
      <Col sm={12} md={6} lg={true} className="mt-3">
        <FloatingLabelSelect
          label="Category"
          value={_categoryOptions.filter(option => option.value === newExemptionData.category)}
          options={_categoryOptions}
          filledValue={!!newExemptionData.category}
          onChange={(s) => {
            setNewExemptionData({...newExemptionData, category: s.value});
          }}
        />
      </Col>
      <Col sm={12} md={6} lg={true} className="mt-3">
        <FloatingLabelSelect
          label="Type"
          value={_typeOptions.filter(option => option.value === newExemptionData.exemption_type)}
          options={_typeOptions}
          filledValue={!!newExemptionData.exemption_type}
          onChange={(s) => {
            setNewExemptionData({...newExemptionData, exemption_type: s.value});
            setDisplayExpirationDate(s.value === "temporary");
          }}
        />
      </Col>
      {displayExpirationDate && (
        <Col sm={12} md={6} lg={true}>
          <FloatingLabelInput
            label="Expiration date"
            type="date"
            value={newExemptionData.expires_at}
            className="mt-3"
            onChange={(e) => {
              setNewExemptionData({...newExemptionData, expires_at: e.target.value});
            }}
          />
        </Col>
      )}
      <Col sm={12} md="auto" className="my-4">
        <Button
          size="sm"
          onClick={() => updateExemption()}
        >
          Save changes
        </Button>
      </Col>
    </Row>
  );
};

const ShowExemptions = ({
  categoryOptions,
  conditionName,
  conditionOptions,
  exemptions,
  immunizationRecordStatus,
  testGroup,
  testGroupUser,
  typeOptions,
}) => {
  const [modalShown, setModalShown] = useState(false);

  const showModal = () => setModalShown(true);
  const hideModal = () => setModalShown(false);

  const removeExemption = async (exemption_id) => {
    const url = `/test_groups/${testGroup.slug}/immunizations/exemptions/${testGroupUser.id}/exemptions/${exemption_id}.json`;
    if (window.confirm("Are you sure you want to delete this exemption?")){
        try {
        const response = await axios.delete(url)
        window.location.reload(false)
      } catch (error) {
        console.log(error)
      }
    }
  };

  const exemptionCard = (exemption) => {
    const exemption_type_title = exemption.exemption_type[0].toUpperCase() + exemption.exemption_type.substring(1);
    const expiration = exemption.expires_at || `${exemption.expires_at_grade} grade`;
    const [editExemption, setEditExemption] = useState(false);

    return (
      <div key={exemption.id}>
        <Card body className="mb-3">
          <h4 className="mb-3 big">{exemption_type_title} exemption</h4>
          <Row className='justify-content-between'>
            <Col className="my-auto">
              {editExemption ? (
                <EditExemptionForm
                  categoryOptions={categoryOptions}
                  conditionOptions={conditionOptions}
                  exemption={exemption}
                  exemptionId={exemption.id}
                  testGroupId={testGroup.slug}
                  testGroupUserId={testGroupUser.id}
                  typeOptions={typeOptions}
                />
              ) : (
                <>
                  <div><span className="font-weight-medium">Category:</span> {EXEMPTION_CATEGORY_LABELS[exemption.category] || exemption.category}</div>
                  <div><span className="font-weight-medium">Expires:</span> {expiration}</div>
                  <div><span className="font-weight-medium">Created:</span> {exemption.created_at.substring(0, 10)}</div>
                </>
              )}
            </Col>
          </Row>
          <div className="mt-4">
            <Button
              size="sm"
              className="me-2"
              variant="outline-secondary"
              onClick={() => setEditExemption(!editExemption)}
            >
              Edit exemption
              <FontAwesomeIcon icon={faPenToSquare} className="ms-2" />
            </Button>
            <Button
              size="sm"
              className="mx-2"
              variant="outline-danger"
              onClick={() => removeExemption(exemption.id)}
            >
              Remove exemption
              <FontAwesomeIcon icon={faTrash} className="ms-2" />
            </Button>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <FilterBadge
        label={IMMUNIZATION_FILTER_LABELS[immunizationRecordStatus]}
        onClick={showModal}
        value={immunizationRecordStatus}
      />
      <Modal show={modalShown} onHide={hideModal} size="lg">
        <ModalHeader closeButton onHide={hideModal}>
          <Modal.Title as="h2">Manage {conditionName} exemptions</Modal.Title>
        </ModalHeader>
        <Modal.Body className="mb-3">
          {exemptions.map((exemption) => (
            exemptionCard(exemption)
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShowExemptions
