import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';

const LocationDetails = ({ location, testGroup, appointmentSlotGroupsLoaded, manyLocations }) => {
  if (!location) return null;

  const { t, i18n } = useTranslation();
  const { address, test_configurations } = location;
  const addressSegments = address.split(',');
  let nextAppointment = location.nextAvailableAppointment;

  if (!nextAppointment) {
    const noSlotsAvailableKey = testGroup.allow_waitlist ? "waitlist" : "unavailable"
    nextAppointment = t(`location_details.next_appointment.${noSlotsAvailableKey}`);
  }

  const testGroupTestConfigurationsById = testGroup.
    test_configurations.
    reduce((map, test_configuration) => {
      map[parseInt(test_configuration.id)] = test_configuration;
      return map;
    }, {});

  const testNames = test_configurations.map(({ id }) =>
    testGroupTestConfigurationsById[id].display_name
  );

  return (
    <div>
      {manyLocations && <h4>{location.title}</h4>}
      <dl>
        {!location.test_location.on_demand &&
          <React.Fragment>
            <dt className="text-gray-dark-700">{t('location_details.labels.next_appointment')}:</dt>
            <dd>
              {appointmentSlotGroupsLoaded
                ? <p className="text-gray-dark-700">{nextAppointment}</p>
                : (
                  <Spinner animation="border" size="sm" variant="secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )
              }
            </dd>
          </React.Fragment>
        }

        <dt className="text-gray-dark-700">{t('location_details.labels.services')}:</dt>
          <ul>
            {
              testNames.map(name => (
                <li key={name}>{ name }</li>
              ))
            }
          </ul>
        <dd></dd>
      </dl>
    </div>
  );
};

LocationDetails.propTypes = {
  appointmentSlotGroupsLoaded: PropTypes.bool.isRequired,
  testGroup: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LocationDetails;
