import React from 'react';
import { createPortal } from 'react-dom';

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.parentEl = document.getElementById(props.parentId);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.parentEl?.appendChild(this.el);
  }

  componentWillUnmount() {
    this.parentEl?.removeChild(this.el);
  }

  render() {
    return this.parentEl && createPortal(this.props.children, this.el);
  }
}

export default Portal;
