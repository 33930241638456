import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Header from '../../Registration/components/Header';
import { languageObject } from '../common/utils';
import { useTranslation } from 'react-i18next';

const LanguageSelection = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header testGroup={{ locales: Object.keys(languageObject) }} />
      <Container
        className="my-3 py-3"
        style={{ maxWidth: '546px', color: '#212529' }}
      >
        <h1 className="mt-3 pt-3 text-center">
          {t('public_test_groups.demo.choose_language')}
        </h1>
        <div>
          {Object.keys(languageObject).map((key, index) => {
            return (
              <div
                style={{ marginTop: 36 }}
                className="text-center"
                key={index}
              >
                <Button
                  variant="primary"
                  onClick={() => {
                    window.location.href = `/test-instructions/l?locale=${key}`;
                  }}
                  style={{
                    backgroundColor: '#08173A',
                    borderRadius: 8,
                    height: 72,
                    width: 265,
                  }}
                >
                  {languageObject[key]}
                </Button>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default LanguageSelection;
