import React from 'react';
import { Card } from 'react-bootstrap';
import { ThreeLineItem, TwoLineItem } from './Items';
import Chevron from '../../../../common/components/Chevron';
import { formatNumberWithCommas } from '../../../../common/utils';

const Section = ({ title, children, basis, smVertical }) => {
  const style = basis ? { flexBasis: basis } : {};

  return (
    <div className="metrics-section" style={style}>
      <div
        className={`metrics-section-content ${smVertical ? 'sm-vertical' : ''}`}
      >
        {children}
      </div>
      <div className="w-100 d-flex flex-row align-items-center mt-3">
        <hr className="flex-grow-1 mx-2 my-0" style={{ borderWidth: 3 }} />
        <span
          className="fw-bold text-uppercase text-secondary"
          style={{ fontSize: '85%' }}
        >
          {title}
        </span>
        <hr className="flex-grow-1 mx-2 my-0" style={{ borderWidth: 3 }} />
      </div>
    </div>
  );
};

const UpperIndicators = ({
  total_capacity,
  booked,
  active_booked,
  projected,
  bellowTarget,
}) => {
  return (
    <div className="upper-metrics-container p-3">
      <ThreeLineItem
        title="Total capacity"
        count={formatNumberWithCommas(total_capacity)}
        tooltip="Total of standard and access key capacity for day"
      />
      <Chevron />
      <ThreeLineItem
        title="Active appointments"
        count={formatNumberWithCommas(active_booked)}
        tooltip={
          <React.Fragment>
            <p>
              {formatNumberWithCommas(active_booked)} - Active appointments:
              appointments to be administered
            </p>

            <p>
              {formatNumberWithCommas(booked)} - Total appointments: total
              appointments booked from capacity
            </p>
          </React.Fragment>
        }
      />
      <Chevron />
      <ThreeLineItem
        title="Projected"
        count={formatNumberWithCommas(projected)}
        footer={
          bellowTarget < 0 ? (
            <span className="text-danger">
              {formatNumberWithCommas(bellowTarget * -1)} under target
            </span>
          ) : (
            <span className="text-success">
              {formatNumberWithCommas(bellowTarget)} over target
            </span>
          )
        }
        tooltip="Total projected (administered + checked in + remaining)"
      />
    </div>
  );
};

const LowerIndicators = ({
  remaining,
  checked_in,
  administered_tests,
  no_shows,
  refused,
  doseManagementData: { discarded = 0 },
  preferences,
}) => (
  <div className="d-flex flex-column gray-light-100 border-top px-3 py-2">
    <span
      className="mx-auto mb-3 fw-bold text-uppercase text-secondary"
      style={{ fontSize: '95%' }}
    >
      Appointment progress
    </span>
    <div className="sections-container">
      <Section title="Included in Projected" basis="60%" smVertical>
        <TwoLineItem
          title="Administered"
          count={formatNumberWithCommas(administered_tests)}
          style={{ color: 'var(--bs-green)' }}
          tooltip="Total administered on this date"
        />
        <Chevron sm />
        <TwoLineItem
          title="Checked in"
          count={formatNumberWithCommas(checked_in)}
          tooltip="Total checked in but not administered yet"
        />
        <Chevron sm />
        <TwoLineItem
          title="Remaining"
          count={formatNumberWithCommas(remaining)}
          tooltip="Active appointments remaining"
        />
      </Section>
      <Section title="Excluded from projected" basis="40%">
        <TwoLineItem
          title={no_shows > 1 ? 'No shows' : 'No show'}
          count={formatNumberWithCommas(no_shows)}
          style={{ color: 'var(--bs-red)' }}
          tooltip={`>${
            (preferences && preferences.no_show_interval) || 60
          } min past appointment time and not checked in`}
        />
        <TwoLineItem
          title="Discarded"
          count={formatNumberWithCommas(discarded)}
          style={{ color: 'var(--bs-red)' }}
          tooltip="Count of discarded doses"
        />
        <TwoLineItem
          title="Refusals"
          count={formatNumberWithCommas(refused)}
          style={{ color: 'var(--bs-red)' }}
          tooltip={`Refusals logged from appointments`}
        />
      </Section>
    </div>
  </div>
);

const LateralPanel = (props) => {
  return (
    <Card className="d-flex flex-column overflow-hidden">
      <UpperIndicators {...props} />
      <LowerIndicators {...props} />
    </Card>
  );
};

export default LateralPanel;
