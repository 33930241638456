import React, { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import MultipleMatchResolver from './MultipleMatchResolverV2';
import AlertHeader from '../../common/components/AlertHeaderV2';

const statusVariants = {
  multiple_matches: {
    title: 'Multiple matches found',
    message: 'More than one record found for this participant',
    type: 'info',
    btnLabel: 'Resolve',
    btnAction: 'multipleMatchResolver',
  },
  no_match: {
    title: 'Participant not found',
    message: (reference) =>
      `They may be listed in ${reference.source?.toUpperCase()} under a different name or nickname`,
    type: 'danger',
    btnLabel: 'Retry search',
    btnAction: 'checkNow',
  },
  error: {
    title: 'An error occurred connecting to the registry',
    message:
      'Please try again. If the error continues, please contact your program manager.',
    type: 'danger',
    btnLabel: 'Retry search',
    btnAction: 'checkNow',
  }
};

const referenceStatusVariant = (reference, error) => {
  let variant;

  if (error) {
    return variant = statusVariants.error;
  } else if (statusVariants[reference?.status]) {
    variant = statusVariants[reference.status];
  }

  return {
    ...variant,
    message:
      typeof variant?.message === 'function'
        ? variant?.message(reference)
        : variant?.message,
  };
};

const ImmunizationCheck = ({
  checkNow,
  latestReference,
  testGroupId,
  user,
  error,
  setLatestReference,
}) => {
  const statusInfo = useMemo(
    () => referenceStatusVariant(latestReference, error),
    [latestReference, error],
  );
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckNow = () => {
    setLoading(true);
    checkNow().then(() => {
      setLoading(false);
    });
  };

  const handleBtnAction = (btnAction) => {
    if (btnAction === 'checkNow') {
      handleCheckNow();
    } else if (btnAction === 'multipleMatchResolver') {
      setShowModal(true);
    }
  };

  if (!statusInfo.title) {
    return null;
  }

  return (
    <>
      <AlertHeader
        type={statusInfo.type}
        icon={statusInfo.icon}
        title={statusInfo.title}
        message={statusInfo.message}
        btnLabel={
          <>
            {statusInfo.btnLabel}
            {loading &&
              <Spinner animation="border" size="sm" className="ms-3" role="status" />
            }
          </>
        }
        btnAction={() => handleBtnAction(statusInfo.btnAction)}
      />
      {latestReference?.status === 'multiple_matches' && (
        <MultipleMatchResolver
          testGroupId={testGroupId}
          user={user}
          source={latestReference?.source}
          showModal={showModal}
          setShowModal={setShowModal}
          onMatchSelected={checkNow}
          setLatestReference={setLatestReference}
        />
      )}
    </>
  );
};

export default ImmunizationCheck;
