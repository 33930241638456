import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import RegistrationContext from '../../contexts/RegistrationContext';


export const LocationInformation = ({logo, name, address, phoneNumber, children }) => (
  <Row>
    {logo &&
      <Col xs={2} className='gx-0'>
        <img src={logo} />
      </Col>
    }
    <Col xs={10} style={{"--bs-gutter-x": 16}}>
      <div className='lead-20-medium poppins' style={{marginBottom: 6}}>{name}</div>
      <div className='caption poppins regular text-muted' style={{marginBottom: 6}}>
        <i className="fa fa-map-marker-alt mx-1 text-muted" /> {address.address_1}, {address.city}, {address.state} {address.postal_code} 
      </div>
      {phoneNumber &&
        <div className='caption inter regular'>
          {phoneNumber
            .slice(2, phoneNumber.length)
            .replace(
              /(\d{3})(\d{3})(\d{4})/,
              '($1) $2-$3',
            )
          }
        </div>
      }
      {children}
    </Col>
  </Row>
);

const DefaultInformation = () => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  return (
    <div>
      <div className='poppins regular fs-4 mb-2'>{t("registration.default_information.title_v2")}:</div>
      <LocationInformation
        logo={testGroup.partner_logo_url || testGroup.simplified_partner_logo_url}
        address={testGroup.default_user_fields.mailing_address}
        phoneNumber={testGroup.default_user_fields.contacts.phone_number}
        name={testGroup.name} 
      />
    </div>
  );
};

export default DefaultInformation;
