import React, { useContext } from 'react';
import ResultCard from './ResultCard';
import OtcContext from '../contexts/OtcContext';
import ErrorMessage from '../../common/components/ErrorMessage';

const OtcClarifyResult = () => {

  const options = {
    'no_lines': 'did_not_result',
    'blue_control_line_only': 'blue_control_line_only',
    'pink_sample_line_only': 'pink_sample_line_only',
    'blue_control_line_and_pink_sample_line': 'blue_control_line_and_pink_sample_line',
    'still_not_sure': 'still_not_sure'
  };

  const {
    t,
    didNotResultReason,
    setDidNotResultReason,
    setNextButtonDisabled,
    activeErrors,
  } = useContext(OtcContext);

  return (
    <div>
      <h4>{t("otc.clarify_results")}</h4>
      <p>{t("otc.choose_result_option")}</p>

      {Object.entries(options).map(([optionText, optionImage]) => {
        return <div className="my-3" key={optionText}>
          <ResultCard
            isSelected={didNotResultReason === optionText}
            text={t(`employer_testing.clarifying_results.${optionText}`)}
            imgSrc={`/images/abbott/${optionImage}.png`}
            onSelect={() => {
              if (didNotResultReason === optionText) {
                setDidNotResultReason(null);
                setNextButtonDisabled(true);
              } else {
                setDidNotResultReason(optionText);
                setNextButtonDisabled(false);
              }
            }} />
        </div>
      })}

      {activeErrors["generic_error"] && (
        <ErrorMessage
          className="my-3"
          message={activeErrors["generic_error"]}
        />
      )}
    </div>
  )
}

export default OtcClarifyResult;
