import React from "react";
import { Button } from "react-bootstrap";

import SelectableTable from "../../common/components/SelectableTable/SelectableTable";
import TablePagination from "../../common/components/TablePagination";

const TestConfigurationsIndex = ({
  meta,
  selectedTestConfigurationIds,
  setSelectedTestConfigurationIds,
  testConfigurations,
}) => {

  const handlePageChange = (newPage) => {
    const path = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', newPage);
    window.location.href = `${path}?${urlParams.toString()}`;
  };

  const ServiceComponent = ({ props }) => {
    return (
      <div>
        <b>{props.checkout_name}</b>
        <div>{props.test_type}</div>
      </div>
    );
  };

  const StatusComponent = ({ props }) => {
    const active = props.active;
    return (
      <div
        className="text-center"
        style={{
          backgroundColor: "#F7F9FC",
          color: active ? "#314677" : "#717D96",
          fontWeight: "700",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {active ? "Active" : "Inactive"}
      </div>
    );
  };

  const LinkComponent = ({ props, label, propKey, url = "" }) => {
    return (
      <Button
        href={`/test_configurations/${props.id}${url}`}
        variant="link"
      >
        {!!propKey ? props[propKey] : label}
      </Button>
    );
  };

  return (
    <div>
      <SelectableTable
        tableHeaders={["Name", "Service specification", "Short code", "Status", "Groups", "", "", "", ""]}
        filteredPaginatedItems={testConfigurations}
        selectedItemIds={selectedTestConfigurationIds}
        setSelectedItemIds={setSelectedTestConfigurationIds}
        itemComponents={{
          "active": <StatusComponent />,
          "groups": <LinkComponent propKey="active_test_group_count" />,
          "edit": <LinkComponent label="Edit" url="/edit" />,
          "clone": <LinkComponent label="Clone" url="/edit_clone" />,
          "service": <ServiceComponent />,
        }}
        itemKeys={[
          "service",
          "service_specification",
          "short_code",
          "active",
          "groups",
          "",
          "",
          "edit",
          "clone",
        ]}
      />
      <TablePagination
        currentPage={meta.current_page}
        nextPage={meta.next_page}
        prevPage={meta.prev_page}
        totalPages={meta.total_pages}
        setPage={handlePageChange}
      />
    </div>
  );
};

export default TestConfigurationsIndex;