import React from 'react';
import hexRgb from 'hex-rgb';
import { Card, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

export const CardOptionsWrapper = ({ children }) => (
  <div className="d-flex justify-content-end" style={{ marginBottom: -12 }}>
    {children}
  </div>
);

export const SummaryBadge = ({ text, icon, color, style = {}, ...props }) => (
  <span>
    <FontAwesomeIcon
      icon={icon}
      style={{ ...style, minWidth: 18, color: color }}
      {...props}
    />{' '}
    {text}
  </span>
);

export const ServiceSummaryContainer = ({
  test,
  children,
  qualified = true,
}) => (
  <div className="d-flex flex-row align-items-center">
    <div className="ps-2 d-none d-sm-block flex-shrink-0" style={{ width: 60 }}>
      <FontAwesomeIcon
        icon={['fa-regular', test.test_configuration.font_awesome_icon]}
        className="mb-0 h1"
        style={{
          color: test.test_configuration.color_code,
          maxWidth: 35,
          maxHeight: 35,
        }}
      />
    </div>
    <div className="flex-grow-1">
      <Col>
        <div className="pt-3 d-sm-none" />
        <b className="h5 fw-bold me-3">
          {test.test_configuration.display_name} (
          {test.test_configuration.short_code})
        </b>
        {!qualified && (
          <div className="text-danger">
            <FontAwesomeIcon icon={faCircleExclamation} className="me-1" />
            <b>Does not meet age requirement</b>
          </div>
        )}
      </Col>
      {children}
    </div>
  </div>
);

export const SectionsWrapper = ({ visible, children }) =>
  visible ? (
    <div className="d-flex flex-row">
      <div className="d-none d-sm-block" style={{ width: 60 }} />
      <div className="w-100">{children}</div>
    </div>
  ) : null;

export const hexToRgba = (colorCode, opacity = 1) => {
  const isHexValid = colorCode && /^#[0-9A-F]{6}$/i.test(colorCode);
  if (!isHexValid) return 'rgba(245, 245, 245, 0.1)';
  return `rgba(${Object.values(hexRgb(colorCode, {alpha: opacity})).join(", ")})`;
};

const ServiceCard = ({ test, edit, children }) => {
  const rgbaColor = hexToRgba(test.test_configuration.color_code, 0.07);
  const backgroundStyle = {
    padding: '10px 20px 20px',
    backgroundColor:
      !(test.result && test.administered_at) || edit
        ? rgbaColor
        : '#F5F5F5',
    border: '1px solid #C4C4C4',
  };

  return (
    <Card style={backgroundStyle} id={test?.uid}>
      {children}
    </Card>
  );
};

export default ServiceCard;
