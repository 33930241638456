import { format } from 'date-fns';
import { parseRange } from '../../shared_labs/utils/index';

const BMI_STATUSES = [
  { threshold: 18.5, status: "underweight" },
  { threshold: 24.9, status: "normal" },
  { threshold: 29.9, status: "overweight" },
  { threshold: Infinity, status: "obese" }
];

export const calculateBmi = (weight, height) => {
  if (!weight || !height) return null;
  return ((weight * 703) / (height * height)).toFixed(1);
};

export const convertHeighInFeetAndInchesToHeightInInches = (feet, inches) => {
  if (!feet && !inches) return null;
  return (parseInt(feet) * 12) + parseInt(inches);
}

export const convertHeighInInchesToHeightInFeetAndInches = (inches) => {
  if (!inches) return { feet: null, inches: null };
  return { feet: Math.floor(inches / 12), inches: inches % 12 };
}

export const determineBloodPressureStatus = (systolicStr, diastolicStr) => {
  if (!systolicStr || !diastolicStr) return null;
  const systolic = parseInt(systolicStr);
  const diastolic = parseInt(diastolicStr);

  if (systolic > 180 || diastolic > 120) {
    return "hypertensive_crisis";
  } else if (systolic >= 140 || diastolic >= 90) {
    return "high_stage_2";
  } else if ((systolic >= 130 && systolic <= 139) || (diastolic >= 80 && diastolic <= 89)) {
    return "high_stage_1";
  } else if ((systolic >= 120 && systolic <= 129) && diastolic < 80) {
    return "elevated";
  } else if (systolic < 120 && diastolic < 80) {
    return "normal";
  } else {
    return null;
  }
};

export const getBmiStatus = (bmi) => {
  if (!bmi) return null;
  return BMI_STATUSES.find(({ threshold }) => bmi < threshold).status;
};

export const handleGenericTestResultChange = (e, test, updateTest) => {
  const testParams = { ...test, result: "custom_result" };
  const genericTestResultParams = { type: e.target.name, value: e.target.value };
  if (!test?.generic_test_results) {
    updateTest({ ...testParams, saved: false, generic_test_results: [ genericTestResultParams ] });
  } else {
    if (!!test?.generic_test_results.some(({ type }) => type === e.target.name)) {
      const updatedGenericTestResultAttributes = test.generic_test_results.map((attr) => {
        return attr.type === e.target.name ? { ...attr, ...genericTestResultParams } : attr;
      });
      updateTest({...testParams, saved: false, generic_test_results: updatedGenericTestResultAttributes });
    } else {
      updateTest({...testParams, saved: false, generic_test_results: [ ...test.generic_test_results, genericTestResultParams ] });
    }
  }
};

export const isValidInteger= (input) => {
  const number = Number(input);
  return Number.isInteger(number);
};

export const resetGenericTestResultAttributes = (test, updateTest, nonResetableTestTypes = []) => {
  const newGenericTestResultsAttributes = test.generic_test_results?.map((gtra) => {
    if (!!gtra.id || nonResetableTestTypes.includes(gtra.type)) {
      return gtra;
    } else {
      return { ...gtra, value: '' };
    }
  }) || [];
  updateTest({ ...test, saved: false, generic_test_results: newGenericTestResultsAttributes });
};

export const genericTestResultFromTest = (test, type) => {
  return test.generic_test_results?.find(({ type: t }) => t === type);
};

export const idFromGenericTestResult = (test, type) => {
  return genericTestResultFromTest(test, type)?.id;
};

export const valueFromGenericTestResult = (test, type) => {
  const gtr = genericTestResultFromTest(test, type);
  if (!gtr) return undefined;

  switch (gtr.value_type) {
    case 'Numeric':
      return handleNumericType(gtr);
    case 'DateTime':
      return handleDateTimeType(gtr);
    case 'List':
      return handleListType(gtr);
    default:
      return gtr.value;
  }
};

const handleNumericType = (gtr) => {
  const valueNumeric = gtr.value_numeric;
  let range;

  if (!!valueNumeric) {
    if (typeof valueNumeric === 'string') {
      range = parseRange(valueNumeric);
    } else if (typeof valueNumeric === 'object') {
      range = valueNumeric;
    } else {
      // do nothing
    }
  }

  if (range && range.from === range.to) {
    return range.from;
  }

  return range;
};

const handleDateTimeType = (gtr) => {
  return gtr.value_date_time
    ? format(new Date(gtr.value_date_time), 'yyyy-MM-dd HH:mm')
    : null;
};

const handleListType = (gtr) => {
  if (typeof gtr.value === 'array') {
    return gtr.value;
  } else if (typeof gtr.value === 'string') {
    return gtr.value.split(',');
  } else {
    return gtr.value;
  }
};
