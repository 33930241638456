import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

interface SearchButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

const SearchButton: FC<SearchButtonProps> = ({ styles, ...props }) => (
  <Button
    variant="secondary"
    style={{ width: 48, height: 48, minWidth: 'unset', padding: 0, ...styles }}
    {...props}
  >
    <FontAwesomeIcon icon={faMagnifyingGlass} />
  </Button>
);

export default SearchButton;
