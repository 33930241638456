import React, { useState } from 'react';

export function dotRead(obj, str) {
  str = str.split(".");
  for (var i = 0; i < str.length; i++)
      obj = obj[str[i]];
  return obj;
}

const TestGroupTestConfigurationRow = ({
  hideUntilHoverComponents,
  item,
  itemComponents,
  itemKeys,
  selectedItemIds,
  toggleSelection,
}) => {

  const [hideRelevantComponents, setHideRelevantComponents] = useState(true);

  const isSelected = selectedItemIds.find((id) => id == item.id) > -1;
  const disabled = !item.test_configuration.active && !item.test_group_test_configuration.active;

  return (
    <tr
      key={item.id}
      onMouseEnter={() => setHideRelevantComponents(false)}
      onMouseLeave={() => setHideRelevantComponents(true)}
      style={{height: "80px"}}
    >
      <td>
        <input
          checked={isSelected}
          className="form-check-input"
          disabled={disabled}
          onChange={(e) => toggleSelection(item.id, e.target.checked)}
          type="checkbox"
        />
      </td>
      {itemKeys.map((key) => {
        let itemData;
        if (key.includes('.')) {
          itemData = dotRead(item, key);
        } else {
          itemData = item[key];
        }

        const isComponentHideable = hideUntilHoverComponents.includes(key);
        const hideComponent = isComponentHideable && hideRelevantComponents;
        const itemComponent = itemComponents[key];
        return (
          <td
            className="body-medium-normal"
            style={
              isComponentHideable
                ? {minWidth: "250px"}
                : key == "test_configuration.test_type"
                  ? {maxWidth: "250px"}
                  : {}
            }
          >
            {!!itemComponent ? (
              <span
                style={hideComponent ? {display: "none"} : {}}
              >
                {React.cloneElement(itemComponent, { props: item })}
              </span>
            ) : (
              itemData
            )}
          </td>
        )
      })}
    </tr>
  );
};

export default TestGroupTestConfigurationRow;
