import React from 'react';

interface WrappedProps<WrapperProps> {
  WrapperComponent: React.ComponentType<WrapperProps>;
  wrapIf?: boolean;
  componentProps: Omit<WrapperProps, 'children'>;
  children: React.ReactNode;
}

const Wrapped = <WrapperProps,>({
  WrapperComponent,
  wrapIf = true,
  componentProps,
  children,
}: WrappedProps<WrapperProps>) =>
  wrapIf ? (
    <WrapperComponent
      {...(componentProps as WrapperProps)}
      children={children}
    />
  ) : (
    children
  );

export default Wrapped;
