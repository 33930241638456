import React from 'react';

const GenericResultOptions = ({ test, updateTestAttr, edit }) => {
  const updateResult = (result) => {
    const _updateResult = updateTestAttr('result');
    if (test.result === result) _updateResult(null);
    else _updateResult(result);
  };

  const resultOptions = [
    { value: 'positive', label: 'Positive' },
    { value: 'negative', label: 'Negative' },
    { value: 'did_not_result', label: 'Invalid' },
  ];

  return edit ? (
    <div>
      {resultOptions.map(({ value, label }, idx) => (
        <div
          key={idx}
          className="px-3 my-2 form-control pointer"
          style={{ maxWidth: 240 }}
          onClick={() => updateResult(value)}
        >
          <div className="form-check-rounded">
            <input
              className="form-check-input faux-radio me-2"
              name={`test_${test.id}_result`}
              id={`test_${test.id}_confirmation_${value}`}
              checked={test.result === value}
              type="radio"
            />
            <label
              className="form-check-label"
              htmlFor={`test_${test.id}_confirmation_${value}`}
              style={{ textTransform: 'capitalize', marginBottom: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              {label}
            </label>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <span
      className="mt-2 mb-4"
      style={{ textTransform: 'capitalize', opacity: 0.5 }}
    >
      {test.result &&
        resultOptions.find((ro) => ro.value === test.result).label}
    </span>
  );
};

export default GenericResultOptions;
