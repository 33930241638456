import React from 'react';
import CheckboxCard from '../../common/components/CheckBoxCard';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { useTranslation } from 'react-i18next';
import { buildCustomizedLabel } from '../../common/locales/langs';

export default ({dispatch, state, withTitle=true}) => {
  const { t } = useTranslation();
  return (
    <>
      {withTitle &&
        <h2 className='poppins semibold mb-4'>{t('registration.insurance_information.title')}</h2>
      }
      <LocalizedMarkdown
        container={state.testGroup.appointment_slot_group}
        stringKey="insurance_text"
      />
      <h4 className='mt-4 mb-3 inter medium'>
        {t(
          buildCustomizedLabel(
            state.testGroup.population,
            'registration.insurance_status.question',
          ),
        )}
      </h4>
      <CheckboxCard
        name="yes"
        radio
        label={t(
          buildCustomizedLabel(
            state.testGroup.population,
            'registration.insurance_status.have_health_insurance',
          ),
        )}
        checked={state.formState.haveHealthInsurance === true}
        onChange={() => dispatch({type: "updateForm", values: {haveHealthInsurance: true}})}
      />
      <CheckboxCard
        name="no"
        radio
        label={t(
          buildCustomizedLabel(
            state.testGroup.population,
            'registration.insurance_status.do_not_have_health_insurance',
          ),
        )}
        checked={state.formState.haveHealthInsurance === false}
        onChange={() => dispatch({type: "updateForm", values: {haveHealthInsurance: false}})}
      />
    </>
  );
};
