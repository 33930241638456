import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';

import MultipleMatchResolver from './MultipleMatchResolver';
import IISReferences from './IisReferences';

export const requestHistoryUpdate = ({testGroupId, userId}) => {
  const url = `/test_groups/${testGroupId}/participants/${userId}/vaccine_history_updates`;
  return axios.post(url);
};

const lastCheckedLabel = reference => {
  return (reference
    ? `${format(new Date(reference.last_checked_at), 'yyyy-MMM-dd')} (${reference.source.toUpperCase()})`
    : "Never"
  );
};

const badgeVariant = (reference) => {
  switch(reference.status) {
    case "ok":
      return "bg-success";
    case "no_records":
      return "bg-warning text-dark"
    default:
      return "bg-danger";
  }
}

const checkNowVariant = latestReference => {
  if (!latestReference || IISReferences.hasErrors(latestReference) || IISReferences.isStale(latestReference) ) {
    return 'warning';
  } else {
    return 'link';
  }
};

const ImmunizationCheck = ({iisReferences, onCheck, testGroupId, user}) => {
  const latestReference = new IISReferences(iisReferences).mostRecent();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const checkNow = () => {
    setError(false);
    setLoading(true);
    requestHistoryUpdate({testGroupId, userId: user.id}).
      then(response => {
        onCheck({
          vaccineHistories: response.data.vaccine_histories,
          iisReferences: response.data.iis_references,
        });
      }).
      catch(error => { setError(true); }).
      then(() => { setLoading(false); });
  }

  useEffect(() => {
    checkNow(); // check when loading
  }, []);

  return (
    <>
      <strong>Last Checked:</strong> {lastCheckedLabel(latestReference)}
      {' '}
      {latestReference && (
        <>
          {latestReference && latestReference.status != "ok" && (
            <Badge className={badgeVariant(latestReference)}>{latestReference.status.replace("_", " ")}</Badge>
          )}
          {latestReference.status == "multiple_matches" && (
            <MultipleMatchResolver testGroupId={testGroupId} user={user} onMatchSelected={checkNow} />
          )}
        </>
      )}
      <span>&nbsp;&nbsp;</span>
      {loading
        ? <><Spinner animation="border" size="sm" className="mx-3" role="status"/><strong>Checking</strong></>
        : <Button variant={checkNowVariant(latestReference)} className="ms-3" onClick={checkNow}>Check Now</Button>
      }
      {error &&
        <span className="mx-3"><em>An error occurred. Please try again later</em></span>
      }
    </>
  );
};

export default ImmunizationCheck;
