import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';

export const VaccinePdfLabelPrinter = ({
  Component = Button,
  className = 'my-3 me-3',
  onPrint,
  test,
  label,
  name,
}) => {
  const printUrl = () =>
    printJS({
      printable: `/test_groups/${test.test_group.id}/tests/${test.id}/labels/${label}`,
      onError: (error) => toastr.error('Could not print: ' + error),
    });

  return (
    <Component
      onClick={() => onPrint(printUrl)}
      variant="secondary"
      className={className}
    >
      {name}
    </Component>
  );
};

export const printPdfLabelOnClick = (test, updateTest, submitTests) => {
  updateTest({ ...test, administered: true });
  submitTests([test])
    .then((response) => {
      printJS({
        printable: `/test_groups/${test.test_group.id}/tests/${test.id}/labels`,
        onError: (error) => toastr.error('Could not print: ' + error),
      });
    })
    .catch(() => {
      alert('Something went wrong, please refresh and try again');
    });
};


const PdfLabelPrinter = ({ test, updateTest, submitTests }) => {
  const onClick = () => printPdfLabelOnClick(test, updateTest, submitTests)
  return (
    <Button onClick={onClick} variable="primary-outline">Print { test.test_configuration.short_code } Label</Button>
  );
}

export default PdfLabelPrinter;
