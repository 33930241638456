import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import DatePicker, {
  CalendarProps,
  DateObject,
  DatePickerProps,
} from 'react-multi-date-picker';
import { format, parse } from 'date-fns';

import TextField from './TextField';

type DatePickerFieldProps = CalendarProps &
  DatePickerProps & {
    id?: string;
    name?: string;
    label?: string;
    helperText?: string;
    error?: boolean;
    inputId?: string;
    containerClass?: string;
  };

const CalendarIcon = (value, openCalendar) => (
  <FontAwesomeIcon
    icon={faCalendar}
    className="text-muted"
    color="#CBD2E0"
    style={{ cursor: 'pointer' }}
    onClick={openCalendar}
  />
);

const reducer = (state, action) => {
  switch (action.type) {
    case 'setInputValue':
      let newDateObjectValue;
      try {
        // hack to handle other date formats than YYYY-MM-DD when typing
        if (action.value.length >= 8) {
          let date = new Date(action.value);
          if (isNaN(date)) { // to handle date formats without '/' or '-'
            const trimmedFormat = state.format.replace(/[\/-]/g, "").replace(/[DY]/g, (m) => m.toLowerCase());
            date = parse(action.value, trimmedFormat, new Date());
          }
          const formattedDateString = format(date, 'yyyy-MM-dd');
          newDateObjectValue = new DateObject(formattedDateString);
        }
      } catch {
        newDateObjectValue = null;
      }
      return {
        ...state,
        inputValue: action.value,
        dateObjectValue: newDateObjectValue,
      };
    case 'setDateValue':
      return {
        ...state,
        dateObjectValue: action.value,
        inputValue: action.value.format(state.format),
      };
    case 'updateInputValue':
      return {
        ...state,
        inputValue: state.dateObjectValue?.format(state.format) || '',
      };
    default:
      throw new Error();
  }
};

const DatePickerField: FC<DatePickerFieldProps> = ({
  id,
  name,
  value: valueProp,
  helperText,
  error,
  onChange,
  label,
  required,
  containerClass = '',
  format = 'YYYY/MM/DD',
  placeholder = 'YYYY/MM/DD',
  ...props
}) => {
  const [{ inputValue, dateObjectValue }, dispatch] = React.useReducer(
    reducer,
    {
      inputValue: '',
      dateObjectValue: null,
      format,
    },
  );

  useEffect(() => {
    onChange(dateObjectValue);
  }, [dateObjectValue]);

  return (
    <React.Fragment>
      <TextField
        id={id}
        name={name}
        label={label}
        required={required}
        // Set custom value with format mask
        value={inputValue}
        onChange={(e) =>
          dispatch({ type: 'setInputValue', value: e.target.value })
        }
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        startAdorment={
          <DatePicker
            id={id}
            inputClass="form-control"
            value={dateObjectValue}
            onChange={(value) => dispatch({ type: 'setDateValue', value })}
            render={CalendarIcon}
            format={format}
          />
        }
        onBlur={() => dispatch({ type: 'updateInputValue' })}
        onKeyDown={(e) => {
          if (e.key === "Enter") dispatch({ type: 'updateInputValue' })
        }}
        {...props}
      />
    </React.Fragment>
  );
};

export default DatePickerField;
