import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useMergeLink } from '@mergeapi/react-merge-link';
import styled from 'styled-components';
import ActionCableSubscriber from '../../common/components/ActionCableSubscriber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faUserMinus,
  faCircleExclamation,
  faTable,
  faThumbsUp,
  faLinkSlash,
  faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { faLink} from '@fortawesome/pro-solid-svg-icons'

const Overlay = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 2147483647;
  border-width: 0px;
  overflow: hidden auto;
  width: 100%;
  height: 100%;
  background: rgba(8, 8, 8, 0.75) none repeat scroll 0% 0%;
  display: flex;
`;

const ImporterWrapper = styled.div`
  background-color: white;
  position: relative;
  min-height: 550px;
  max-height: 720px;
  width: 375px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 20px, rgba(0, 0, 0, 0.1) 0px 2px 5px;
`;

const ImportSummary = ({
  onHide,
  startMergeImport,
  mergeImportStarted,
  importState,
  mergeImportId,
  onReceived,
  errors,
  testGroupUsersPath,
  removeExistingUsersPath,
  keepExistingUsersPath,
}) => (
  <Overlay className="align-items-center justify-content-center">
    <ImporterWrapper className="d-flex flex-column justify-content-center align-items-start p-4">
      {onHide && (
        <button
          type="button"
          className="btn-close"
          onClick={onHide}
          aria-label="Close"
          style={{
            position: 'absolute',
            top: 15,
            right: 15,
          }}
        />
      )}
      {mergeImportId && (
        <ActionCableSubscriber
          channel={{ channel: 'MergeImportChannel', id: mergeImportId }}
          onReceived={onReceived}
        />
      )}
      {errors && <span className="text-danger font-weight-bold">{errors}</span>}
      <div className="d-flex flex-column mb-1">
        <span className="h3 mb-0">{importState['active_employees_count']}</span>
        <span className="fs-4">
          active employee{importState['active_employees_count'] !== 1 && 's'}{' '}
          found
        </span>
      </div>
      {!['complete', 'failed'].includes(importState['pulling_status']) && (
        <ProgressBar className="w-100 mb-1" striped animated now={100} />
      )}
      {!mergeImportStarted &&
        ['not_ready', 'ready'].includes(importState['import_status']) && (
          <button
            className="btn btn-primary mt-3"
            disabled={importState['import_status'] === 'not_ready'}
            onClick={(e) => {
              e.preventDefault();
              startMergeImport();
            }}
          >
            Import Employees
          </button>
        )}
      {importState['import_status'] === 'complete' && (
        <div
          className="py-2 px-3 rounded-pill"
          style={{ backgroundColor: '#E7F5D8' }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            className='me-1'
            style={{ color: '#6BB01C' }}
          />
          <span className="font-weight-bold" style={{ color: '#2A460B' }}>
            Import complete
          </span>
        </div>
      )}
      <div className="mt-4 mb-2">
        <span className="h5 mb-0">
          {importState['imported_employees_count']}
        </span>{' '}
        employee{importState['imported_employees_count'] !== 1 && 's'} imported
      </div>
      <ProgressBar
        className="w-100 my-1"
        variant="success"
        now={
          importState['active_employees_count'] &&
          (importState['imported_employees_count'] * 100) /
          importState['active_employees_count']
        }
      />
      {importState['import_status'] === 'complete' && (
        <React.Fragment>
          {importState['new_test_group_users_count'] > 0 && (
            <div className="mt-2">
              <span className="h5 mb-0">
                {importState['new_test_group_users_count']}
              </span>{' '}
              new employee
              {importState['new_test_group_users_count'] !== 1 && 's'} added
            </div>
          )}
          {!importState['keep_non_imported_users'] &&
            importState['not_included_test_group_users_count'] > 0 ? (
            <React.Fragment>
              <div className="mt-2">
                <span className="h5 mb-0">
                  {importState['not_included_test_group_users_count']}
                </span>{' '}
                Existing employee
                {importState['not_included_test_group_users_count'] !== 1 &&
                  's'}{' '}
                not updated
              </div>
              <div className="mt-4 d-flex w-100 justify-content-center">
                <a
                  className="btn btn-primary btn-sm me-3"
                  style={{ minHeight: 38 }}
                  href={removeExistingUsersPath}
                  data-method="post"
                >
                  <FontAwesomeIcon icon={faUserMinus} className='me-1' />
                  {' '} Remove from list
                </a>
                <a
                  className="btn btn-outline-primary btn-sm ms-3"
                  style={{ minHeight: 38 }}
                  href={keepExistingUsersPath}
                  data-method="post"
                >
                  <FontAwesomeIcon icon={faThumbsUp} className='me-1' />
                  {' '}Keep in list
                </a>
              </div>
            </React.Fragment>
          ) : (
            <div className="mt-4 d-flex w-100 justify-content-center">
              <a className="btn btn-primary" href={testGroupUsersPath}>
                <FontAwesomeIcon icon={faTable} className='me-1' />
                {' '}Return to your participant
                list
              </a>
            </div>
          )}
        </React.Fragment>
      )}
    </ImporterWrapper>
  </Overlay>
);

const EmployeeImporter = ({ getMergeImportId, ...props }) => {
  const [importState, setImportState] = useState({
    pulling_status: 'started',
    import_status: 'not_ready',
    active_employees_count: 0,
    imported_employees_count: 0,
  });

  const onReceived = useCallback((data) => {
    setImportState(data);
  }, []);

  useEffect(() => {
    getMergeImportId();
  }, []);

  return (
    <ImportSummary
      {...props}
      importState={importState}
      onReceived={onReceived}
    />
  );
};

const LastMergeImportSummary = ({ lastMergeImportStatusPath, ...props }) => {
  const [showSummary, setShowSummary] = useState(false);
  const [importState, setImportState] = useState({});

  useEffect(() => {
    axios.get(lastMergeImportStatusPath).then((response) => {
      setImportState(response.data);
    });
  }, []);

  return (
    <React.Fragment>
      {showSummary && (
        <ImportSummary
          {...props}
          onHide={() => setShowSummary(false)}
          importState={importState}
        />
      )}
      {!importState['keep_non_imported_users'] &&
        importState['not_included_test_group_users_count'] > 0 && (
          <div
            className="mt-1 text-danger font-weight-bold pointer"
            onClick={() => setShowSummary(true)}
          >
            <FontAwesomeIcon icon={faCircleExclamation} className='me-1' />
            <span>Edit existing employees not included in import</span>
            <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
          </div>
        )}
    </React.Fragment>
  );
};

const DisconnectButton = ({ onClick }) => (
  <div className="pointer font-weight-light text-danger my-1" onClick={(e) => {
    e.preventDefault();
      if (window.confirm("Disconnect your HRIS?")) {
        onClick();
      }
    }}>
      <FontAwesomeIcon icon={faLinkSlash} className='me-1' />{' '}
      Disconnect your HRIS direct import
  </div>
);

const ConnectButton = ({ linkToken, onSuccess }) => {
  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  });

  return (
    <button
      className="btn btn-sm btn-outline-primary"
      disabled={!isReady}
      onClick={(e) => {
        e.preventDefault();
        open();
      }}
    >
        <FontAwesomeIcon icon={faLink} className='me-1' />{' '}
        Click Here to connect your HRIS
      directly
    </button>
  );
};

const MergeConnect = ({
  account_token_present,
  last_sync_at: lastSyncAt,
  get_link_token_path: getLinkTokenPath,
  update_merge_account_path: updatePath,
  remove_merge_account_path: removePath,
  new_merge_import_path: newMergeImportPath,
  start_merge_import_path: startMergeImportPath,
  test_group_users_path: testGroupUsersPath,
  last_merge_import_status_path: lastMergeImportStatusPath,
  remove_existing_users_path: removeExistingUsersPath,
  keep_existing_users_path: keepExistingUsersPath,
}) => {
  const [linkToken, setLinkToken] = useState('');
  const [showImporter, setShowImporter] = useState(false);
  const [accountTokenPresent, setAccountTokenPresent] = useState(
    account_token_present,
  );
  const [mergeImportId, setMergeImportId] = useState(null);
  const [mergeImportStarted, setMergeImportStarted] = useState(false);
  const [errors, setErrors] = useState('');

  const getLinkToken = useCallback(() => {
    axios.get(getLinkTokenPath).then((response) => {
      setLinkToken(response.data.link_token);
    });
  }, []);

  const getMergeImportId = useCallback(() => {
    axios
      .post(newMergeImportPath)
      .then((response) => {
        setMergeImportId(response.data.merge_id);
      })
      .catch((error) => {
        setErrors(error.response.data.error);
      });
  }, [setMergeImportId, setErrors]);

  const startMergeImport = useCallback(() => {
    if (!mergeImportId) return;

    axios
      .post(startMergeImportPath, { id: mergeImportId })
      .then(() => setMergeImportStarted(true))
      .catch((error) => {
        setErrors(error.response.data.error);
      });
  }, [mergeImportId, setErrors]);

  const onSuccess = useCallback((public_token) => {
    axios.post(updatePath, { public_token }).then(() => {
      setShowImporter(true);
      setAccountTokenPresent(true);
    });
  }, []);

  const removeMergeAccountToken = useCallback(() => {
    axios.post(removePath).then(() => {
      setShowImporter(false);
      setAccountTokenPresent(false);
    });
  }, []);

  useEffect(() => {
    if (!accountTokenPresent) getLinkToken();
  }, [accountTokenPresent]);

  return (
    <div className="mt-n2 mb-3">
      {accountTokenPresent ? (
        <React.Fragment>
          <div className="btn btn-outline-primary" onClick={() => setShowImporter(true)}>
            <FontAwesomeIcon icon={faRotateRight} className="me-1" />  Refresh HRIS import
          </div>
          <span className="text-secondary d-block">
            Last sync at: {lastSyncAt}
          </span>
          <DisconnectButton onClick={removeMergeAccountToken} />
          {showImporter && (
            <EmployeeImporter
              onHide={() => setShowImporter(false)}
              mergeImportId={mergeImportId}
              getMergeImportId={getMergeImportId}
              startMergeImport={startMergeImport}
              mergeImportStarted={mergeImportStarted}
              testGroupUsersPath={testGroupUsersPath}
              removeExistingUsersPath={removeExistingUsersPath}
              keepExistingUsersPath={keepExistingUsersPath}
              errors={errors}
            />
          )}
          <LastMergeImportSummary
            lastMergeImportStatusPath={lastMergeImportStatusPath}
            mergeImportStarted
            testGroupUsersPath={testGroupUsersPath}
            removeExistingUsersPath={removeExistingUsersPath}
            keepExistingUsersPath={keepExistingUsersPath}
            errors={errors}
          />
        </React.Fragment>
      ) : (
        <ConnectButton
          getLinkToken={getLinkToken}
          linkToken={linkToken}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default MergeConnect;
