import styled, { css } from 'styled-components';

const GridList = styled.div`
  ${({ columns }) =>
    columns &&
    css`
      --columns: ${columns};
    `}
  ${({ gap }) =>
    gap &&
    css`
      --gap: ${gap}px;
    `}
  --col-width: calc(
    (100% - var(--gap) * (var(--columns) - 1)) / var(--columns)
  );

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;

export const GridListElement = styled.div`
  width: var(--col-width);
`;

export default GridList;
