import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locale from './langs';

import am from "./langs/am";
import ar from "./langs/ar";
import bn from "./langs/bn";
import en from "./langs/en";
import es from "./langs/es";
import fa from "./langs/fa";
import fr from "./langs/fr";
import hat from "./langs/hat";
import hi from "./langs/hi";
import hmn from "./langs/hmn";
import hy from "./langs/hy";
import ja from "./langs/ja";
import kar from "./langs/kar";
import ko from "./langs/ko";
import lo from "./langs/lo";
import my from "./langs/my";
import ne from "./langs/ne";
import om from "./langs/om";
import ru from "./langs/ru";
import so from "./langs/so";
import tl from "./langs/tl";
import uk from "./langs/uk";
import ur from "./langs/ur";
import vi from "./langs/vi";
import zh from "./langs/zh";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  am: { translation: am },
  ar: { translation: ar },
  bn: { translation: bn },
  en: { translation: en },
  es: { translation: es },
  fa: { translation: fa },
  fr: { translation: fr },
  hat: { translation: hat },
  hi: { translation: hi },
  hmn: { translation: hmn },
  hy: { translation: hy },
  ja: { translation: ja },
  kar: { translation: kar },
  ko: { translation: ko },
  lo: { translation: lo },
  my: { translation: my },
  ne: { translation: ne },
  om: { translation: om },
  ru: { translation: ru },
  so: { translation: so },
  tl: { translation: tl },
  ur: { translation: ur },
  vi: { translation: vi },
  zh: { translation: zh },
  uk: { translation: uk },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: locale,
    fallbackLng: 'en',
    whitelist: [
      'am',
      'ar',
      'bn',
      'en',
      'es',
      'fa',
      'fr',
      'hat',
      'hi',
      'hmn',
      'hy',
      'ja',
      'kar',
      'ko',
      'lo',
      'my',
      'ne',
      'om',
      'ru',
      'so',
      'tl',
      'uk',
      'ur',
      'vi',
      'zh',
    ],

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
export default i18n;