import { Button, Card, Row, Col, Spinner } from 'react-bootstrap';
import React, { useMemo, useRef, useState, useEffect, useCallback, useReducer } from 'react';
import SectionHeader from '../SectionHeader';
import { SectionActions, SectionWrapper, SectionBody } from '../styled';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import styled, { css } from 'styled-components';
import { PrimaryIcon } from '../../../common/components/Icons/PrimaryIcon';
import axios from 'axios';
import { useFetchMemo } from '../../../common/utils/tools';

const SearchBox = styled.div`
  background: #FFFFFF;
  border: 1px solid #F7F9FC;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  position: relative;
  max-height: 219px;
  overflow: auto;
  position: absolute;
  z-index: 100;
  ${({ width }) => css`width: ${width}px;`}
`;

const Item = ({test_group: testGroup, test_location: location, onClick}) => (
  <div className='m-2 pointer' onClick={onClick}>
    <span className='poppins regular fs-6'>{testGroup.name}</span>  <i className="fa fa-map-marker-alt mx-1 text-muted" style={{height: 12}} />
    <span className='text-muted' style={{fontSize: 14}}>{location.name}</span>
  </div>
)

const SearchTestGroupResult = ({test_group: testGroup, test_location: location, onClick, selected}) => {
  return (
    <Card
      body
      className="my-2 pointer"
      style={selected ? {border: "1px solid #1125F6"} : {}}
      data-test-hook={`continue_${testGroup.id}`}
      onClick={onClick}
    >
      <Row>
        <Col xs={2}>
          { testGroup.simplified_partner_logo_url || testGroup.partner_logo_url
            ? <img src={testGroup.simplified_partner_logo_url || testGroup.partner_logo_url} alt={testGroup.name} className="search-result-item-logo" />
            : null
          }
        </Col>
        <Col>
          <div className="poppins h4-20-semi-bold">{testGroup.name} ({location.name})</div>
          <i className="fa fa-map-marker-alt me-1 text-muted" /> <span className='poppins text-muted lead-20-medium'>{location.address}</span>
        </Col>
      </Row>
    </Card>
  )
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadMoreGroupResults':
      return {
        ...state,
        testGroups: [...state.testGroups, ...action.values.testGroups],
        paginator: action.values.pagination,
      }; 
    case 'updateGroupResults':
      return { ...state, testGroups: action.values, autoCompleteGroups: [] };
    case 'updateAutoCompleteOptions':
      return { ...state, autoCompleteGroups: action.values };
    case 'select':
      return {...state, selectedLocation: action.value };
    case 'beginSearch':
      return {...state, searchBegan: action.value};
    case 'delaySearch':
      return {...state, fetchLocations: false };
    case 'enableSearch':
      return {...state, fetchLocations: true };
    case 'updateCache':
      return {...state, cache: action.value };
    case 'clearForm':
      return {
        ...state,
        testGroup: [],
        autoCompleteGroups: [],
        selectedLocation: null,
        searchBegan: false,
        paginator: {page: 0, next_page: null, total_pages: 0},
      }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export default function FindLocationStep ({state: {formState, t, isMobile}, dispatch}) {
  const [locationState, locationDispatch] = useReducer(reducer, {
    testGroups: [],
    autoCompleteGroups: [],
    paginator: {page: 0, next_page: null, total_pages: 0},
    cache: new Map(),
  });
  const {
    testGroups,
    autoCompleteGroups,
    paginator,
    selectedLocation,
    searchBegan,
    fetchLocations,
    cache,
  } = locationState;
  const inputRef = useRef();
  const cachedAxios = useFetchMemo(axios.get, locationDispatch, cache); 

  const getLocations = (groups) => {
    let locations = []

    groups.forEach(testGroup => {
      testGroup.test_locations.forEach(test_location => {
        locations.push({ test_group: testGroup, test_location: test_location })
      })
    })

    return locations
  }
  const locations = useMemo(() => getLocations(testGroups), [testGroups]);
  const autoCompeleteLocations = useMemo(() => getLocations(autoCompleteGroups), [autoCompleteGroups]); 

  const fetchTestGroups = useCallback(async () => {
    if (formState.location && formState.location.length > 2 && fetchLocations) {
      locationDispatch({type: 'beginSearch', value: true});
      locationDispatch({type: 'delaySearch'})
      const params = `location=${formState.location}&page=1`;
      const response = await cachedAxios("/find-your-location.json?sti_kind=true&" + params)

      if (response.status === 200) {
        locationDispatch({type: "updateAutoCompleteOptions", values: response.data.test_groups});
      } else {
        console.log(response);
      }
    }
    if (!formState.location) locationDispatch({type: "updateAutoCompleteOptions", values: []});
  }, [formState.location, fetchLocations]);

  useEffect(() => {
    fetchTestGroups()
  }, [fetchLocations, formState.location])

  const loadMoreResults = async () => {
    const params = `location=${formState.location}&page=${paginator.page + 1}`;
    const response = await fetch("/find-your-location.json?sti_kind=true&" + params, {
      headers: { 'Accept': 'application/json' }
    })

    if (response.ok) {
      const json = await response.json()
      locationDispatch({
        type: 'loadMoreGroupResults',
        values: { testGroups: json.test_groups, pagination: json.pagination }
      });
    } else {
      console.log(response);
    }
  }

  const onSubmit = (data) => {
    const landingPagePath = `/${data.test_group.has_landing_page ? 'l' : 'r'}/${data.test_group.slug}`
    location.href = formState.barcode
      ? `${landingPagePath}?after_hooks=%5B%7B%22action%22%3A%22CREATE_TEST_STRIP%22%2C%22params%22%3A%7B%22barcode%22%3A%22${formState.barcode}%22%7D%7D%5D`
      : landingPagePath
  }
  return (
    <SectionWrapper>
       <Button variant="link" className='d-sm-none p-0 mb-2' onClick={() => dispatch({type: 'back'})}>{t('otc.back_button')}</Button> 
      <SectionHeader title={t('test_strip.credentials.title')} />
      <SectionBody>
        <div className='poppins pb-2 h3-24-semi-bold regular'>{t('test_strip.credentials.location_step.p1')}</div>
        <Row className='my-3'>
          <Col>
            <FloatingLabelInput
              inputRef={inputRef}
              prependIcon="search"
              name="query[location]"
              className="mb-2"
              autoFocus
              value={formState.location}
              onChange={(e) => {
                setTimeout(() => locationDispatch({type: "enableSearch"}), 1 * 1000);
                dispatch({type: 'updateForm', values: {location: e.target.value}});
                if (e.target.value === "") {
                  locationDispatch({type: 'beginSearch', value: false});
                }
              }}
              appendIcon={formState.location?.length > 0 &&
                <div
                  className="input-icon pointer"
                  style={{ position: "absolute", marginTop: "14px", right: "16px" }}
                  onClick={() => {
                    locationDispatch({type: 'clearForm'}); 
                    dispatch({type: 'updateForm', values: {location: ""}});
                  }}
                >
                  <PrimaryIcon icon="x-circle" color="#717D96" width={22} height={22} />
                </div>
              }
              dataTestHook="address"
              id="search_address"
              label={isMobile ? t('registration_direcory.keyword_search_label_mobile') : t('registration_direcory.keyword_search_label')}
              onKeyDown={(e) => {
                if (e.keyCode == 13){
                  locationDispatch({type: 'updateGroupResults', values: autoCompleteGroups}) 
                }
              }}
            />
            {autoCompeleteLocations.length > 0 &&
              <SearchBox data-test-hook="search-box" width={inputRef.current?.clientWidth}>
                {autoCompeleteLocations.map((data, idx) => (
                  <Item
                    {...data}
                    key={idx}
                    onClick={() => {
                      locationDispatch({type: 'updateGroupResults', values: [{...data.test_group, test_locations: [data.test_location]}]}) 
                    }}
                  />
                ))}
              </SearchBox>
            }
          </Col>
        </Row>
        <div className="col-12" style={{maxHeight: 350, overflow: "auto"}}>
          <div className="search-results" data-test-hook="search-results">
            {formState.location && searchBegan && locations.length === 0 && autoCompeleteLocations.length === 0 &&
              <div className='poppins regular lead-20-medium'>
                <p>{t('registration_direcory.zero_results.title')}</p>
                <p>{t('registration_direcory.zero_results.sub_title')}</p>
                <ul>
                  <li>{t('registration_direcory.zero_results.p1')}</li>
                  <li>{t('registration_direcory.zero_results.p2')}</li>
                  <li>{t('registration_direcory.zero_results.p3')}</li>
                  <li>{t('registration_direcory.zero_results.p4')}</li>
                </ul>
              </div>
            }
            {
              locations.map((data, idx) => {
                return (
                  <SearchTestGroupResult
                    {...data}
                    key={idx}
                    selected={selectedLocation === idx}
                    onClick={() => locationDispatch({type: 'select', value: idx })} />
                )
              })
            }
            {paginator.total_pages > paginator.page &&
              <div className="d-flex justify-content-center">
                <Button variant="outline-primary" onClick={() => loadMoreResults(formState.location, paginator.page + 1)} className="btn-sm btn-load-more-results">
                  {t('test_strip.credentials.location_step.load')}
                </Button>
              </div>
            } 
          </div>
        </div>
      </SectionBody>
      <SectionActions>
        <Button variant="link" className='d-none d-sm-block' onClick={() => dispatch({type: 'back'})}>{t('otc.back_button')}</Button> 
        <Button
          data-test-hook="submit"
          disabled={selectedLocation === null}
          onClick={() => onSubmit(locations[selectedLocation])}
        >
          {t('otc.next_button')}
        </Button>
      </SectionActions>
    </SectionWrapper>
  );
}
