import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import ErrorMessage from '../../common/components/ErrorMessage';
import ModalHeader from '../../common/components/ModalHeader';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

const BulkResultModal = ({
  test_group,
  location,
  start_day,
  end_day,
  start_time,
  end_time,
  params,
}) => {

  const [show, setShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmationCheckboxChecked, setConfirmationCheckboxChecked] = useState(false);
  const [numberOfResultableTestLeft, setNumberOfResultableTestLeft] = useState(0);

  const [numBulkResultableNotResultedTests, setNumBulkResultableNotResultedTests] = useState(0);
  const [numBulkResultablePositiveTests, setNumBulkResultablePositiveTests] = useState(0);
  const [numBulkResultableNegativeTests, setNumBulkResultableNegativeTests] = useState(0);
  const [numBulkResultableInvalidTests, setNumBulkResultableInvalidTests] = useState(0);
  const [bulkResultableNotResultedTests, setBulkResultableNotResultedTests] = useState([]);
  const [userResultedNegativeDuringPastDay, setUserResultedNegativeDuringPastDay] = useState(true);

  useEffect(() => {
    $('.open-bulk-result-modal').click(function (e) {
      e.preventDefault();
      fetchTestsForBulkResulting();
    });
  }, []);

  const closeModal = () => {
    setShow(false);
    setCurrentStep(0);
    setConfirmationCheckboxChecked(false);
  }

  const fetchTestsForBulkResulting = async () => {
    try {
      const response = await fetchTests();
      if (response.status === 200) {
        setNumBulkResultableNotResultedTests(response.data.num_bulk_resultable_not_resulted_tests);
        setNumBulkResultablePositiveTests(response.data.num_bulk_resultable_positive_tests);
        setNumBulkResultableNegativeTests(response.data.num_bulk_resultable_negative_tests);
        setNumBulkResultableInvalidTests(response.data.num_bulk_resultable_invalid_tests);
        setBulkResultableNotResultedTests(response.data.bulk_resultable_not_resulted_tests);
        setUserResultedNegativeDuringPastDay(response.data.user_resulted_negative_during_past_day);
        setShow(true);
      }
    } catch(e) {
      toastr.error("Something went wrong.");
      closeModal();
    }
  }

  const fetchTests = async () => {
    return axios.get(`/test_groups/${test_group.id}/fetch_bulk_resultable_tests`, {
      params: params,
    })
  }

  const bulkResult = async () => {
    return axios.put(`/test_groups/${test_group.id}/bulk_result_negative_tests`, {
      total_num_bulk_resultable_not_resulted_tests: numBulkResultableNotResultedTests,
      not_resulted_test_ids: bulkResultableNotResultedTests.map((t) => t.id),
    })
  }

  const TooltipWithNames = ({tests}) => {
    return (
      <OverlayTrigger
        overlay={<Tooltip>{tests.map(t => <div>{t.uid}</div>)}</Tooltip>}
      >
        <FontAwesomeIcon icon={faEye} color="var(--custom-color)" />
      </OverlayTrigger>
    )
  }

  const ConfirmResultedPositives = () => {
    return (
      <div>
        <ModalHeader closeButton onHide={closeModal}>
          <Modal.Title as="h2" id="accessCodeModalLabel">
            Did you result all positives?
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <p className="mb-0">Please be sure to result all positive tests prior to bulk resulting negative tests.</p>
          {!userResultedNegativeDuringPastDay && (
            <ErrorMessage
              message='In order to bulk result negatives, please result a negative test through the lab manager.'
              className="mt-2"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => closeModal()}
          >
            No, go back
          </Button>
          <Button
            className="mx-2"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={!userResultedNegativeDuringPastDay}
          >
            Save and proceed
          </Button>
        </Modal.Footer>
      </div>
    )
  }

  const BulkResultMain = () => {
    return (
      <div>
        <ModalHeader closeButton onHide={closeModal}>
          <Modal.Title as="h2" id="accessCodeModalLabel">
            Bulk result: <span className="text-success">NEGATIVE</span>
            <p className="mb-0 font-size-16 fw-normal">{test_group.name}</p>
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <div>
            <b>Location: </b>{location.title}
          </div>
          <div>
            <b>Date: </b>{end_day ? `${start_day} - ${end_day}` : start_day}
          </div>
          {(start_time || end_time) && (
            <div>
              <b>Time: </b>{start_time || "12:00 AM"} - {end_time || "12:00 AM"}
            </div>
          )}

          <div className="d-flex justify-content-between m-4">
            <div><b>Un-resulted: {numBulkResultableNotResultedTests}</b> <TooltipWithNames tests={bulkResultableNotResultedTests} /></div>
            <div><b>Positive: {numBulkResultablePositiveTests}</b></div>
            <div><b>Negative: {numBulkResultableNegativeTests}</b></div>
            <div><b>Invalid: {numBulkResultableInvalidTests}</b></div>
          </div>

          <p>
            By proceeding below, you will be bulk-resulting
            {bulkResultableNotResultedTests.length} unresulted rapid tests to negative.
            Please be sure to result all positive tests prior to bulk resulting negative.
          </p>

          <p className="text-muted">
            You are only able to result tests that have been administered more than 30 minutes ago. You can only bulk result up to 100 rapid tests at a time.
          </p>

          <FabrxCheckbox
            checked={confirmationCheckboxChecked}
            className="mt-4"
            label={`I confirm negative result for ${numBulkResultableNotResultedTests} total`}
            name="confirminformation"
            onChange={() => {
              setConfirmationCheckboxChecked(!confirmationCheckboxChecked)
            }}
          />
          {!userResultedNegativeDuringPastDay && (
            <ErrorMessage
              message='In order to bulk result, please result a negative test.'
              className="mt-2"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => closeModal()}
          >
            No, go back
          </Button>
          <Button
            className="mx-2"
            disabled={!confirmationCheckboxChecked || !userResultedNegativeDuringPastDay}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Save and proceed
          </Button>
        </Modal.Footer>
      </div>
    )
  }

  const BulkResultConfirmation = () => {
    return (
      <div>
        <ModalHeader closeButton onHide={closeModal}>
          <Modal.Title as="h2">Are you sure?</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          You will be bulk-resulting
          <b> {bulkResultableNotResultedTests.length} tests as <span className="text-success-400">negative</span></b>.
          Please be sure to result all positive tests prior to bulk resulting negative.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            No, go back
          </Button>
          <Button
            className="mx-2"
            onClick={ async () => {
              try {
                const response = await bulkResult();
                toastr.success(`Bulk update ${response.data.number_of_updated_tests} tests to negative result.`);
                if (response.data.number_of_resultable_tests_left > 0) {
                  setNumberOfResultableTestLeft(response.data.number_of_resultable_tests_left);
                  setCurrentStep(currentStep + 1);
                } else {
                  closeModal();
                }
              } catch(e) {
                if (e.response.status === 403) {
                  toastr.error(e.response.data.error);
                } else {
                  toastr.error("Something went wrong.");
                }
                closeModal();
              }
            }}
          >
            Yes, bulk result
          </Button>
        </Modal.Footer>
      </div>
    )
  }

  const ResultMoreTests = () => {
    return (
      <div>
        <ModalHeader closeButton onHide={closeModal}>
          <Modal.Title as="h2">Bulk result more tests?</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          There are {numberOfResultableTestLeft} tests still unresulted. Would you like to bulk result more tests?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => closeModal()}
          >
            No, go back
          </Button>
          <Button
            className="mx-2"
            onClick={() => {
              fetchTestsForBulkResulting();
              setCurrentStep(1);
            }}
          >
            Yes, bulk result more tests
          </Button>
        </Modal.Footer>
      </div>
    )
  }

  return (
    <Modal show={show} size="lg">
      {currentStep === 0 && (
        <ConfirmResultedPositives />
      )}
      {currentStep === 1 && (
        <BulkResultMain />
      )}
      {currentStep === 2 && (
        <BulkResultConfirmation />
      )}
      {currentStep === 3 && (
        <ResultMoreTests />
      )}
    </Modal>
  );
};

export default BulkResultModal;
