import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncSelect from 'react-select/async';

const MergeTagsModal = ({
  show,
  onHideModal,
  selectedTags,
  setError,
  showMessage,
  testGroupId
}) => {
  const [mainTag, setMainTag] = useState(null);
  const [tagQuery, setTagQuery] = useState('');

  const onHide = () => {
    setMainTag(null);
    onHideModal();
  }

  const onSubmit = () => {
    const payload = {
      selected_ids: selectedTags.map((tag) => tag.id),
      parent_id: mainTag.id
    };
    axios.post(`/test_groups/${testGroupId}/tags/merge`, payload).then((response) => {
      showMessage('Merging Tags');
      onHide();
    })
    .catch(() => {
      setError("There was an issue merging tags");
    });
  }

  const loadOptions = () => {
    if (tagQuery.length > 1) {
      return axios.get(`/test_groups/${testGroupId}/tags/select_options?q=${tagQuery}`).then((response) =>
        response.data.options
      );
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header>
        Merge selected tags
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Card.Title>Select main tag</Card.Title>
            <Card.Text>
              <FontAwesomeIcon icon="info-circle" color="#dda654"/> The main tag selected will replace the other selected tags.
            </Card.Text>

            <Card.Text>
              Users under these will be re-tagged with the main tag selected here.
            </Card.Text>

            {selectedTags.map((tag) =>
              <Button
                onClick={() => setMainTag(tag)}
                className="m-1"
                variant={`${(mainTag && mainTag.id === tag.id) ? 'success' : 'outline-dark'}`}>
                {tag.friendly_name}
              </Button>
            )}
            <div>Not seeing the tag you'd like to merge into? Search below</div>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={mainTag}
              getOptionLabel={e => e.friendly_name}
              getOptionValue={e => e.id}
              loadOptions={loadOptions}
              onInputChange={(value) => setTagQuery(value)}
              onChange={(tag) => setMainTag(tag)}
            />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit}>Submit</Button>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MergeTagsModal;
