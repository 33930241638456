import React, { useContext, useMemo } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import range from 'lodash/range';
import { Row, Col } from "react-bootstrap";
import { isMobile } from '../../../../common/utils/browser';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';

import RegistrationContext from '../../../contexts/RegistrationContext';
import { ageInMonthsRemainder, ageInMonthsYear } from '../../../../common/utils';

const OldDateOfBirthInput = ({setFieldValue, values, name, errors, dateFieldRef}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const maximumAgeInMonths = testGroup.maximum_age_in_months || 1440;
  const maximumAgeYears = ageInMonthsYear(maximumAgeInMonths);
  const maximumAgeRemainder = ageInMonthsRemainder(maximumAgeInMonths);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();

  const earliestYear =
    currentYear - maximumAgeYears - (maximumAgeRemainder > currentMonth ? 1 : 0);
  const earliestMonth =
    values['date_of_birth_datetime(1i)'] === earliestYear
      ? maximumAgeRemainder > currentMonth
        ? 11 - (maximumAgeRemainder - currentMonth)
        : currentMonth - maximumAgeRemainder
      : 0;
  const earliestDay =
    values['date_of_birth_datetime(1i)'] === earliestYear &&
    values['date_of_birth_datetime(2i)'] === earliestMonth + 1
      ? currentDate
      : 0;

  const yearOptions = useMemo(() => {
    return range(currentYear, earliestYear - 1).map((year) => ({
      value: year,
      label: year,
    }));
  });

  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: t('date.' + month),
  })).slice(earliestMonth);

  const dayOptions = useMemo(() => {
    return range(earliestDay + 1, 32).map((day) => ({
      value: day,
      label: day,
    }));
  }, [earliestDay]);

  const findError = (key) => errors[`${name}${key}`]

  return (
    <Row className="px-3" as="fieldset">
      <legend className="sr-only">date of birth</legend>
      <Col xs={12} md={4} className="my-2">
        <FloatingLabelSelect
          id="user_date_of_birth_datetime_2i"
          inputRef={dateFieldRef}
          className={`test-dob-month ${findError("date_of_birth") && 'is-invalid'}`}
          value={monthOptions.filter(option => option.value === values["date_of_birth_datetime(2i)"])}
          onChange={(s) => setFieldValue(`${name}date_of_birth_datetime(2i)`, s.value)}
          options={monthOptions}
          ariaLabel="date of birth month, required"
          ariaDescribedby="dateOfBirthErrorDesc"
          components={{ IndicatorSeparator: () => null }}
          isDisabled={values.disabled_fields && !!values.disabled_fields.date_of_birth}
          isSearchable={!isMobile}
          label={t('date.month')}
          filledValue={values['date_of_birth_datetime(2i)']}
          />
      </Col>
      <Col xs={12} md={4} className="my-2">
        <FloatingLabelSelect
          id="user_date_of_birth_datetime_3i"
          className={`test-dob-day ${findError("date_of_birth") && 'is-invalid'}`}
          value={dayOptions.filter(option => option.value === values["date_of_birth_datetime(3i)"])}
          onChange={(s) => setFieldValue(`${name}date_of_birth_datetime(3i)`, s.value)}
          options={dayOptions}
          components={{ IndicatorSeparator: () => null }}
          isDisabled={values.disabled_fields && !!values.disabled_fields.date_of_birth}
          isSearchable={!isMobile}
          aria-label="date of birth day, required"
          aria-describedby="dateOfBirthErrorDesc"
          label={t('date.day')}
          filledValue={values['date_of_birth_datetime(3i)']}
        />
      </Col>
      <Col xs={12} md={4} className="my-2">
        <FloatingLabelSelect
          id="user_date_of_birth_datetime_1i"
          className={`test-dob-year ${findError("date_of_birth") && 'is-invalid'}`}
          value={yearOptions.filter(option => option.value === values["date_of_birth_datetime(1i)"])}
          onChange={(s) => setFieldValue(`${name}date_of_birth_datetime(1i)`, s.value)}
          options={yearOptions}
          components={{ IndicatorSeparator: () => null }}
          isDisabled={values.disabled_fields && !!values.disabled_fields.date_of_birth}
          isSearchable={!isMobile}
          aria-label="date of birth year, required"
          aria-describedby="dateOfBirthErrorDesc"
          label={t('date.year')}
          filledValue={values['date_of_birth_datetime(1i)']}
        />
      </Col>
    </Row>
  );
};

export default OldDateOfBirthInput;
