export const useFetchMemo = (callback, dispatch, cache) => (
  (...args) => {
    const key = JSON.stringify(args);
    if (cache.has(key)) return cache.get(key);
    const value = callback(...args);
    cache.set(key, value);
    dispatch({type: 'updateCache', value: cache})
    return value;
  }
);
