import React from 'react';

const TrashCan = ({ color = '#000000', ...props }) => (
  <svg 
    fill="none"
    height="14"
    viewBox="0 0 12 14"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#2862fa">
      <path
        style={{
          fill: color,
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
        d="m4.83317 5.83325c.32217 0 .58333.26117.58333.58334v3.5c0 .32221-.26116.58331-.58333.58331s-.58333-.2611-.58333-.58331v-3.5c0-.32217.26116-.58334.58333-.58334z"
      />
      <path
        style={{
          fill: color,
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
        d="m7.74984 9.91659v-3.5c0-.32217-.26117-.58334-.58334-.58334-.32216 0-.58333.26117-.58333.58334v3.5c0 .32221.26117.58331.58333.58331.32217 0 .58334-.2611.58334-.58331z"
      />
      <path
        style={{
          fill: color,
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
        d="m3.08317 2.91659v-.58334c0-.46413.18438-.90925.51256-1.23743.32819-.328194.77331-.512568 1.23744-.512568h2.33333c.46413 0 .90925.184374 1.23744.512568.32819.32818.51256.7733.51256 1.23743v.58334h2.3333c.3222 0 .5834.26116.5834.58333 0 .32216-.2612.58333-.5834.58333h-.5833v7.58335c0 .4641-.1844.9092-.5126 1.2374-.32815.3282-.77327.5126-1.2374.5126h-5.83333c-.46413 0-.90925-.1844-1.23744-.5126s-.51256-.7733-.51256-1.2374v-7.58335h-.583333c-.322166 0-.583333-.26117-.583333-.58333 0-.32217.261167-.58333.583333-.58333zm1.33752-.99582c.1094-.10939.25777-.17085.41248-.17085h2.33333c.15471 0 .30309.06146.41248.17085.1094.1094.17086.25777.17086.41248v.58334h-3.5v-.58334c0-.15471.06146-.30308.17085-.41248zm-1.92085 2.16248v7.58335c0 .1547.06146.3031.17085.4125.1094.1094.25777.1708.41248.1708h5.83333c.15471 0 .30309-.0614.41248-.1708.1094-.1094.17086-.2578.17086-.4125v-7.58335z"
      />
    </g>
  </svg>
);

export default TrashCan;
