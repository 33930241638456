import React, { useState } from 'react';
import {
  Collapse,
} from 'react-bootstrap';
import { ChevronDropDown } from '../../../../common/components/Chevron';
import FabrxCheckbox from '../../../../Registration/primary/FabrxCheckbox';

const VaccineProgramSection = ({ appointment }) => {
  if (!appointment?.vaccine_program_options ||
    (!appointment.vaccine_program_options.vfa_options &&
     !appointment.vaccine_program_options.vfc_options)) {
    return <span></span>;
  };

  const [show, setShow] = useState(false);
  const [vfaEligibility, setVfaEligibility] = useState(appointment.vfa_eligibility);
  const [vfcEligibility, setVfcEligibility] = useState(appointment.vfc_eligibility);

  return (
    <div className="my-3">
      <hr/>
      <div onClick={()=> setShow(!show)} className="h5-vivid-blue" data-test-hook="vaccine_programs_collapse">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5>Vaccine programs</h5>
      </div>

      <Collapse in={show}>
        <div>
          {!!appointment?.vaccine_program_options?.vfa_options?.length && (
            <div className="my-3">
              <input type="hidden" value={vfaEligibility} name="appointment[vfa_eligibility]" />
              <h4 className="my-4">Vaccine for Adults</h4>
              {appointment?.vaccine_program_options?.vfa_options
                .map((option) => {
                  return (
                    <FabrxCheckbox
                      name={option.value}
                      checked={vfaEligibility === option.value}
                      radio
                      onChange={() => setVfaEligibility(option.value)}
                      label={option.label}
                    />
                  )
                })
              }
            </div>
          )}
          {!!appointment?.vaccine_program_options?.vfc_options?.length && (
            <div className="my-3">
              <input type="hidden" value={vfcEligibility} name="appointment[vfc_eligibility]" />
              <h4 className="my-4">Vaccine for Children</h4>
              {appointment?.vaccine_program_options?.vfc_options
                .map((option) => {
                  return (
                    <FabrxCheckbox
                      name={option.value}
                      checked={vfcEligibility === option.value}
                      radio
                      onChange={() => setVfcEligibility(option.value)}
                      label={option.label}
                    />
                  )
                })
              }
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default VaccineProgramSection;