import React, { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";

import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const TITLES = {
  newTestGroupTestConfigurationSelector: "New service",
  testGroupTestConfigurationsIndex: "Configure services",
  testGroupTestConfigurationForm: "New service",
};

const TestGroupTestConfigurationsHeader = () => {

  const {
    bulkUpdateActiveStatus,
    dispatch,
    save,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const TestGroupTestConfigurationsIndexButtons = () => {
    return (
      !state.selectedTestGroupTestConfigurationIds.length ? (
        <Button
          onClick={() => {
            dispatch({
              type: 'setCurrentStep',
              values: "newTestGroupTestConfigurationSelector",
            });
          }}
          variant="outline-secondary"
        >
          New service
        </Button>
      ) : (
        <Button
          onClick={() => bulkUpdateActiveStatus()}
        >
          {state.activeTab == "active" ? "Make inactive" : "Make active"}
        </Button>
      )
    );
  };

  const TestGroupTestConfigurationFormButtons = () => {
    if (state.loading) return <Spinner variant="primary" animation="border" />
    return (
      <div className="d-flex">
        <Button
          onClick={() => {
            dispatch({
              type: 'setCurrentStep',
              values: "testGroupTestConfigurationsIndex",
            });
          }}
          variant="link"
        >
          Cancel
        </Button>
        {state.currentStep == "testGroupTestConfigurationForm" ? (
          <Button
            disabled={!state.testGroupTestConfiguration.test_configuration_id}
            onClick={save}
            variant="outline-secondary"
          >
            Save
          </Button>
        ) : (
          <Button
            disabled={!state.selectedTestConfiguration}
            onClick={() => {
              dispatch({
                type: 'setCurrentStep',
                values: "testGroupTestConfigurationForm",
              });
            }}
            variant="outline-secondary"
          >
            Next
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-between my-4">
      <h5 className="my-auto">
        {!!state.testGroupTestConfiguration.id
          ? "Edit service"
          : TITLES[state.currentStep]
        }
      </h5>
      {state.currentStep == "testGroupTestConfigurationsIndex"
        ? <TestGroupTestConfigurationsIndexButtons />
        : <TestGroupTestConfigurationFormButtons />
      }
    </div>
  );
};

export default TestGroupTestConfigurationsHeader;