import React from 'react';
import VerificationCodeInput from '../../common/components/VerificationCodeInput';
import { useVerifyContactInformation } from '../../ContactVerification/Verify';

const EmptyNavbar = () => (
  <div className="navbar py-3" style={{ borderBottom: '1px solid #dee2e6' }}>
    <div className="container">
      <img
        className="navbar-brand logo d-block"
        src="/images/logo.png"
        alt="Primary logo"
        style={{ maxHeight: 36 }}
      ></img>
    </div>
  </div>
);

const ContactVerification = ({
  user: { email, phone_number: phoneNumber },
  test_group: testGroup,
}) => {
  const queryParams = new URLSearchParams(document.location.search);
  const defaultVerificationMethod =
    queryParams.get('ref') === 'sms' ? 'phone_number' : 'email';

  const {
    setOtpCode,
    verificationMethod,
    canToggleVerificationMethod,
    toggleVerificationMethod,
    loading,
    isInvalidCode,
    resendConfirmationCode,
  } = useVerifyContactInformation(
    email,
    phoneNumber,
    (id) => {
      console.log('redirecting to', queryParams.get('redirect'));
      window.location.href = queryParams.get('redirect');
    },
    false,
    false,
    defaultVerificationMethod,
  );

  return (
    <React.Fragment>
      <EmptyNavbar />
      <div className="container py-4">
        {(testGroup.partner_logo_url || testGroup.partner_logo_url) && (
          <div className="mb-3">
            <img
              height="50"
              src={testGroup.partner_logo_url || testGroup.partner_logo_url}
            />
          </div>
        )}
        <h1 className="h2">Access your student profile here!</h1>
        <p>
          <strong>We sent you a confirmation code.</strong>
          <br />
          Please enter the code we sent to:{' '}
          {verificationMethod === 'email' ? email : phoneNumber}
        </p>
        <VerificationCodeInput onChange={setOtpCode} invalid={isInvalidCode} />
        <p style={{ marginTop: '40vh' }}>
          <span>
            Didn’t get it?{' '}
            <a
              className="fw-bold"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                resendConfirmationCode();
              }}
            >
              Resend {verificationMethod === 'email' ? 'email' : 'SMS'}
            </a>
            .
          </span>
        </p>
        {canToggleVerificationMethod && (
          <p>
            <span>
              <a
                className="fw-bold"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleVerificationMethod();
                }}
              >
                Verify by {verificationMethod === 'email' ? 'SMS' : 'email'}
              </a>{' '}
              instead.
            </span>
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default ContactVerification;
