import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select, {
  CheckboxOption,
  SearchPlaceholder,
} from '../../common/components/Select';
import {
  ageInMonthsRemainder,
  ageInMonthsYear,
  calculateAgeInDays,
  calculateAgeInMonths
} from '../../common/utils';

const ServiceSpecificationOption = ({serviceSpecification}) => {
  // TODO: this doesn't work so need to fix + need to add labels for day/week gating if needed; disabling age gated options still works fine
  const { t, i18n } = useTranslation();
  const label = useMemo(() => {
    const [
      maximumAgeInMonths,
      minimumAgeInMonths,
    ] = [
      serviceSpecification.maximumAgeInMonths || serviceSpecification.maximum_age_in_months,
      serviceSpecification.minimumAgeInMonths || serviceSpecification.minimum_age_in_months,
    ]
    if (!minimumAgeInMonths && !maximumAgeInMonths) return false;
    const minYear = ageInMonthsYear(minimumAgeInMonths) && `${ageInMonthsYear(minimumAgeInMonths)} years`,
      minMonth = ageInMonthsRemainder(minimumAgeInMonths) && `${ageInMonthsRemainder(minimumAgeInMonths)} months`,
      maxYear = ageInMonthsYear(maximumAgeInMonths) && `${ageInMonthsYear(maximumAgeInMonths)} years`,
      maxMonth = ageInMonthsRemainder(maximumAgeInMonths) && `${ageInMonthsRemainder(maximumAgeInMonths)} months`,
      minLabel = [minYear, minMonth].filter(l => !!l && l !== "0 months" && l !== "0 years").join(" "),
      maxLabel = [maxYear, maxMonth].filter(l => !!l && l !== "0 months" && l !== "120 years").join(" ");

    if (!minLabel && !!maxLabel) return `${maxLabel} or under`;
    if (!!minLabel && !maxLabel) return `${minLabel} or over`;

    return [minLabel, maxLabel].filter(l => !!l).join(" - ")
  }, [])
  return <div>
    <div>
      {serviceSpecification.label}
    </div>
    {serviceSpecification.description ? (
      <small>{serviceSpecification.description}</small>
    ) : (
      !!label && <small>For participants {label}</small>
    )}
  </div>
}

const ServiceSpecificationSelector = ({
  closeMenuOnSelect = false,
  dateOfBirth,
  dispatch,
  index,
  selectedServiceSpecifications,
  serviceSpecifications,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const options = serviceSpecifications.map(serviceSpecification => (
    {
      ...serviceSpecification,
      label: t(serviceSpecification.label),
      key: serviceSpecification.key,
    }
  ));

  const isOptionDisabled = (option) => {
    const ageInDays = calculateAgeInDays(dateOfBirth);
    const ageInMonths = calculateAgeInMonths(dateOfBirth);
    if (option.calculateAgeInDays) {
      if (!ageInDays || !(option.minimumAgeInDays || option.maximumAgeInDays)) return false;
      if (option.minimumAgeInDays > ageInDays) return true;
      if (option.maximumAgeInDays < ageInDays) return true;
    } else {
      if (!ageInMonths || !(option.minimumAgeInMonths || option.maximumAgeInMonths)) return false;
      if (option.minimumAgeInMonths > ageInMonths) return true;
      if (option.maximumAgeInMonths < ageInMonths) return true;
    }
    return false;
  };

  return (
    <Select
      isMulti
      options={options}
      className={`service_selector service_selector_${index}`}
      valueKey="key"
      blurInputOnSelect={false}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={false}
      isClearable={false}
      isOptionDisabled={(option) => isOptionDisabled(option)}
      controlShouldRenderValue={false}
      verticallyCenterCheckBox={true}
      placeholder={t('enter_information.search')}
      components={{ Option: CheckboxOption, Placeholder: SearchPlaceholder }}
      styles={{
        placeholder: (styles) => ({
          ...styles,
          color: '#A0ABC0',
        }),
        menuList: (styles) => ({
          ...styles,
          maxHeight: '460px',
        })
      }}
      value={selectedServiceSpecifications}
      onChange={(values) =>
        dispatch({
          type: 'updateServiceSpecifications',
          values: values.reduce((acc, key) => {
                acc[key] = true
                return acc;
              }, {}),
        })
      }
      {...props}
    />
  )
};

export default ServiceSpecificationSelector;