import React, { FC, forwardRef, useMemo, useState } from 'react';
import { Table as BsTable } from 'react-bootstrap';
import Header, { HeaderProps } from './Header';
import Cell from './Cell';
import { TableProps } from './Table';

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  checked: boolean;
  onToggle: (checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({ id, checked, onToggle }) => (
  <input
    id={id}
    type="checkbox"
    className="form-check-input"
    checked={checked}
    onChange={(e) => onToggle(e.target.checked)}
  />
);

type SelectableRowComponentProps<RecordType> = RecordType & {
  selected: boolean;
  toggleSelection: (checked: boolean) => void;
};

export type SelectableTableProps<RecordType> = TableProps<RecordType> & {
  RowComponent?: FC<SelectableRowComponentProps<RecordType>>;
  idField?: string;
  onSelectedChange: (selected: any[]) => void;
};

const SelectableTable = <RecordType,>({
  id,
  headers,
  data,
  RowComponent,
  idField = 'id',
  onSelectedChange,
}: SelectableTableProps<RecordType>) => {
  const [selected, _setSelected] = useState([]);
  const visibleRecordIds = data.map((record) => record[idField]);

  const setSelected = (newSelection: any[]) => {
    _setSelected(newSelection);
    onSelectedChange(newSelection);
  };

  const visibleSelected = useMemo(() => {
    return !visibleRecordIds.find((id) => !selected.includes(id));
  }, [selected, visibleRecordIds]);

  const toggleSelection = (id: number | string, checked: boolean) => {
    const newSelection = checked
      ? [...selected, id]
      : selected.filter((rId) => rId !== id);
    setSelected(newSelection);
  };

  const toggleVisible = (checked: boolean) => {
    if (checked) {
      const additionalSelections = visibleRecordIds.filter(
        (id) => !selected.includes(id),
      );
      setSelected([...selected, ...additionalSelections]);
    } else {
      const newSelection = selected.filter(
        (id) => !visibleRecordIds.includes(id),
      );
      setSelected(newSelection);
    }
  };

  return (
    <BsTable id={id} responsive className="table-lg">
      <thead>
        <tr>
          <th>
            <Checkbox
              id="select-all"
              checked={visibleSelected}
              onToggle={toggleVisible}
            />
          </th>
          {headers.map((header, idx) => (
            <Header key={idx} {...header} />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((record, idx) => {
          const isSelected = selected.includes(record[idField]);
          const toggleRowSelection = (checked: boolean) =>
            toggleSelection(record[idField], checked);

          return RowComponent ? (
            <RowComponent
              key={idx}
              {...record}
              selected={isSelected}
              toggleSelection={toggleRowSelection}
            />
          ) : (
            <tr key={idx} data-selected={isSelected}>
              <td>
                <Checkbox
                  id="select-all"
                  checked={isSelected}
                  onToggle={toggleRowSelection}
                />
              </td>
              {headers.map((header, idx) => (
                <Cell key={idx} header={header} record={record} />
              ))}
            </tr>
          );
        })}
      </tbody>
    </BsTable>
  );
};

export default SelectableTable;
