import React, { useContext, useState } from "react";
import { FormControl } from "react-bootstrap";
import OtcContext from '../contexts/OtcContext';
import ErrorMessage from "../../common/components/ErrorMessage";
import CheckboxCard from "../../common/components/CheckBoxCard";

const OtcDateOfTest = () => {

  const {
    t,
    verifyAndSetResultDate,
    resultDate,
    setResultDate,
    resultTime,
    setResultTime,
    setNextButtonDisabled,
    activeErrors,
    setError,
    isDateInPastThirtyDays,
    clearErrors,
    stepNumber,
    testTakenToday,
    setTestTakenToday,
    testGroupTestConfiguration,
  } = useContext(OtcContext);

  const today = new Date();

  const enterToday = () => {
    let date = today;
    verifyAndSetResultDate(`${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`);
    if (resultTime !== "" || !testGroupTestConfiguration.require_otc_time) setNextButtonDisabled(false);
    clearErrors();
  }

  const enterCurrentTime = () => {
    setResultTime(`${("0" + today.getHours()).slice(-2)}:${("0" + today.getMinutes()).slice(-2)}`);
    if (resultDate !== "") setNextButtonDisabled(false);
  }

  return (
    <div>
      <h4>{stepNumber}. {testGroupTestConfiguration.require_otc_time ? t("otc.steps.enter_date_and_time") : t("otc.steps.enter_date")}</h4>
      <div className="mt-4 mb-2">{t("otc.did_you_take_test_today")}</div>

      <CheckboxCard
        radio
        label={t("yes")}
        checked={testTakenToday === "yes"}
        name="testtakentoday"
        onChange={() => {
          enterToday();
          setTestTakenToday("yes");
        }}
      />

      <CheckboxCard
        radio
        label={t("no")}
        checked={testTakenToday === "no"}
        onChange={() => {
          setTestTakenToday("no");
          setResultDate("");
          setNextButtonDisabled(true);
        }}
      />

      {testTakenToday === "no" && (
        <div>
          <div className="mt-4 mb-2">{t("otc.what_day_did_you_take_test")}</div>
          <FormControl
            type="date"
            className="mb-3 otc-input"
            value={resultDate}
            onChange={e => {
              verifyAndSetResultDate(e.target.value);
              if (!isDateInPastThirtyDays(e.target.value)) {
                setNextButtonDisabled(true);
                setError("test_not_in_last_thirty_days");
              } else if (isDateInPastThirtyDays(e.target.value) && (resultTime !== "" || !testGroupTestConfiguration.require_otc_time)) {
                setNextButtonDisabled(false);
                clearErrors();
              } else {
                setNextButtonDisabled(true);
                clearErrors();
              }
            }}
          />
        </div>
      )}

      {activeErrors["test_not_in_last_thirty_days"] && (
        <ErrorMessage
          className="my-3"
          message={activeErrors["test_not_in_last_thirty_days"]}
        />
      )}

      {testGroupTestConfiguration.require_otc_time && (
        <div>
          <div className="mt-4 mb-2">{t("otc.what_time_did_you_take_test")}</div>
          <FormControl
            type="time"
            className="mb-3 otc-input"
            value={resultTime}
            onChange={e => {
              setResultTime(e.target.value);
              resultDate !== "" && e.target.value !== "" && isDateInPastThirtyDays(resultDate)
              ? setNextButtonDisabled(false)
              : setNextButtonDisabled(true)
            }}
            data-test-hook="timeoftest"
          />
        </div>
      )}

      <div
        data-test-hook="entercurrenttime"
        onClick={() => enterCurrentTime()}
        style={{width: "1px", height: "1px", cursor: "default", color: "#FBFBFB"}}
      >
        &nbsp
      </div>
    </div>
  )
}

export default OtcDateOfTest;