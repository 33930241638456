import React, { useCallback, useMemo, useState } from 'react';
import ReactSelect from 'react-select';

import { Group } from './SelectGroup';
import { useTranslation } from 'react-i18next';
import { customStyles, selectTheme } from './FloatingLabelSelect';

const NestedDropdown = ({
  isMulti,
  options: optionsProp,
  value: defaultValueProp,
  labelFromKey,
  style,
  ...props
}) => {
  const optionLabel = useCallback(
    (key) => (labelFromKey ? labelFromKey(key) : key),
    [labelFromKey],
  );

  const options = useMemo(
    () =>
      Object.entries(optionsProp).map((entry) => {
        const [key, subKeysObj] = entry;
        const option = {
          label: optionLabel(key),
          value: key,
        };

        const subKeys = Object.values(subKeysObj);
        if (subKeys.length > 0) {
          option.options = subKeys.map((subKey) => ({
            label: optionLabel(subKey),
            value: `${key}.${subKey}`,
          }));
        }

        return option;
      }),
    [optionsProp],
  );

  const defaultValue = useMemo(() => {
    return (
      defaultValueProp &&
      (isMulti
        ? defaultValueProp.map((key) => ({
            label: optionLabel(key.split('.').slice(-1)[0]),
            value: key,
          }))
        : { label: optionLabel(defaultValueProp), value: defaultValueProp })
    );
  }, [isMulti, defaultValueProp, options]);

  return (
    <div className="input-container select-container">
      <ReactSelect
        options={options}
        components={{ Group }}
        styles={{ ...customStyles, ...style }}
        theme={selectTheme}
        isMulti={isMulti}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
};

export const LocalizedNestedDropdown = ({
  prefix = '',
  fallbackPrefix = '',
  ...props
}) => {
  const { t } = useTranslation('translation', { prefix });

  const labelFromKey = (key) =>
    (prefix && t(`${prefix}.${key}`, { defaultValue: '' })) ||
    (fallbackPrefix && t(`${fallbackPrefix}.${key}`, { defaultValue: '' })) ||
    t(key);

  return <NestedDropdown {...props} labelFromKey={labelFromKey} />;
};

export default NestedDropdown;
