import React, { useState, useMemo } from 'react';
import axios from 'axios';
import AppointmentSlotsForm from './AppointmentSlotsForm';
import StagedSlots from './StagedSlots';

export const NewAppointmentSlots = ({
  locations: propLocations,
  stage_url,
  create_url,
  dates: initialDates = null,
  location_id: initialLocationId = null,
}) => {
  const [appointmentDates, setAppointmentDates] = useState(null);
  const [slotConfigurations, setSlotConfigurations] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [processingSlots, setProcessingSlots] = useState(false);

  const locations = useMemo(() => {
    return propLocations.map((location) => ({
      value: location[0],
      label: location[1],
    }));
  }, [propLocations]);

  const stageChanges = (values) => {
    setLoadingSlots(true);
    setAppointmentDates(null);
    axios
      .post(stage_url, values)
      .then((response) => {
        setLoadingSlots(false);
        const { data } = response;
        if (data.success) {
          setSlotConfigurations(data.appointment_slot_attrs);
          setAppointmentDates(values.appointment_dates.sort());
        }
      })
      .catch(() => setLoadingSlots(false));
  };

  const createStaged = (values) => {
    setProcessingSlots(true);
    axios
      .post(create_url, values)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          window.location = data.redirect_path;
        } else {
          setProcessingSlots(false);
        }
      })
      .catch(() => setProcessingSlots(false));
  };

  return (
    <React.Fragment>
      <AppointmentSlotsForm
        initialDates={initialDates}
        initialLocationId={initialLocationId}
        locations={locations}
        onSubmit={stageChanges}
        loadingSlots={loadingSlots}
      />
      {appointmentDates && (
        <StagedSlots
          locations={locations}
          appointmentDates={appointmentDates}
          slotConfigurations={slotConfigurations}
          onSubmit={createStaged}
          processingSlots={processingSlots}
        />
      )}
    </React.Fragment>
  );
};
