import { Col, Row } from 'react-bootstrap';
import React from 'react';

export default function SectionHeader({ icon, title, includeIcon = true }) {
  return (
    <Row className="mb-4 align-items-center">
      {includeIcon && (
        <Col xs={'auto'} className="gx-0">
          {icon ? (
            icon
          ) : (
            <img
              src="/images/sti-programs/test-kit-2.png"
              width={64}
              height={64}
            ></img>
          )}
        </Col>
      )}
      <Col xs={`${includeIcon ? '8' : 'auto'}`} sm="auto" className="gx-0 ms-2">
        <h3 className="kit-header mb-0">{title}</h3>
      </Col>
    </Row>
  )
}
