import React, { useContext } from 'react';
import ResultCard from './ResultCard';
import MultiplexResultCard from './MultiplexResultCard';
import OtcContext from '../contexts/OtcContext';
import ErrorMessage from '../../common/components/ErrorMessage';

const OtcResultsEntry = () => {

  const options = ['negative', 'positive', 'did_not_result'];
  const {
    activeErrors,
    flow,
    i18n,
    isTestMultiplex,
    multiplexResult,
    multiplexSubTests,
    prettyTestKitNames,
    result,
    selectedKit,
    setMultiplexResult,
    setNextButtonDisabled,
    setResult,
    stepNumber,
    t,
    testConfiguration,
  } = useContext(OtcContext);

  const resultsSchemaResultImages = () =>
    testConfiguration?.results_schema?.otc?.images?.results;

  const resultImages = resultsSchemaResultImages() || 
    flow === 'self_checkouts' ? {
      negative: '/images/abbott/negative.png',
      positive: '/images/abbott/positive.png',
      did_not_result: '/images/abbott/did_not_result.png',
    } : {};

  const SingleResultOptions = () => {
    return (
      <>
        {i18n.language === "en" ? ( // TODO: remove once translations for choose_result_option_custom are added
          <p>{t("otc.choose_result_option_custom", {test_name: selectedKit ? prettyTestKitNames[selectedKit] : testConfiguration.display_name})}</p>
        ) : (
          <p>{t("otc.choose_result_option")}</p>
        )}
        {options.map((option) => {
          return (
            <div className="my-3" key={option}>
              <ResultCard
                isSelected={result === option}
                text={t(`employer_testing.results.${option}`)}
                imgSrc={resultImages[option]}
                flow={flow}
                testConfiguration={testConfiguration}
                onSelect={() => {
                  if (result === option) {
                    setResult(null);
                    setNextButtonDisabled(true);
                  } else {
                    setResult(option);
                    setNextButtonDisabled(false);
                  }
                }}
              />
            </div>
          );
        })}
      </>
    )
  }

  const MultiplexResultOptions = () => {
    return (
      <>
        <p>For each result, please select the option matching a green positive LED light shown on your test</p>
        <div className="d-flex justify-content-between mt-3">
          <b>Negative -</b>
          <b>Positive +</b>
        </div>
        {multiplexSubTests.map((st) => {
          return (
            <div className="my-3" key={st.key}>
              <MultiplexResultCard
                subTestKey={st.key}
                subTestLabel={st.label}
                multiplexResult={multiplexResult}
                setMultiplexResult={setMultiplexResult}
                setNextButtonDisabled={setNextButtonDisabled}
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div>
      <h4>{stepNumber}. {t("otc.enter_result")}</h4>
      {isTestMultiplex ? <MultiplexResultOptions /> : <SingleResultOptions />}
      {activeErrors["generic_error"] && (
        <ErrorMessage
          className="my-3"
          message={activeErrors["generic_error"]}
        />
      )}
    </div>
  )
}

export default OtcResultsEntry;
