import React from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../SectionComponents';
import PhoneAndEmailFields from './fields/PhoneAndEmailFields';

const PhoneAndEmail = ({
  values,
  errors,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="form-section">
      <SectionTitle>{t('registration.how_to_contact')}</SectionTitle>
      <PhoneAndEmailFields {...{ values, errors, setFieldValue }} />
    </div>
  );
};

export default PhoneAndEmail;
