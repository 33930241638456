import React, { useContext, useState, useEffect } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import { buildLocalizedString } from '../../../common/locales/langs';

import RegistrationContext from '../../contexts/RegistrationContext';
import LocationMap from './LocationMap';


const RegistrationText = (props) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  return (
    <div className="form-section">
      {
        buildLocalizedString(testGroup.population, "registration_text") ? (
          <LocalizedMarkdown container={testGroup.population} stringKey='registration_text' />
        ) : (
          <LocalizedMarkdown container={testGroup} stringKey='registration_text' />
        )
      }
      {testGroup.show_test_locations_map &&
        <LocationMap testGroup={testGroup} />
      }
    </div>
  );
};

export default RegistrationText;
