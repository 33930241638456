import React, { useState } from 'react';
import SelectableTableContainer from '../../common/components/SelectableTable/SelectableTableContainer';
import MultipleMatchResolver from '../../CheckoutPage/components/MultipleMatchResolver';
import { requestHistoryUpdate } from '../../CheckoutPage/components/ImmunizationCheck';
import queryString from 'querystring';
import { humanize } from '../../common/utils';
import { Button } from 'react-bootstrap';
import { PrimaryIcon } from '../../common/components/Icons/PrimaryIcon';
import ImmunizationsSummaryCard from '../../Immunizations/components/ImmunizationsSummaryCard';
import { immunizationRecordCount } from '../../Immunizations/utils';
import StudenProfileRow from './StudentProfileRow';
import StudentProfileCard from '../../Immunizations/components/widgets/StudentProfileCard';

const OVERVIEW_TABLE_HEADERS = [
  'Student',
  'Student ID',
  'DOB',
  'School',
  'Grade',
  'Compliance status',
  '',
];
const OVERVIEW_ITEM_KEYS = [
  'full_name',
  'student_profile.student_id',
  'date_of_birth',
  'school',
  'student_profile.grade',
  'immunization_status',
];
const PENDING_TABLE_HEADERS = [...OVERVIEW_TABLE_HEADERS, ''];
const PENDING_ITEM_KEYS = [...OVERVIEW_ITEM_KEYS, 'review_student'];

const STATUS_ICON_PROPS = {
  compliant: {
    icon: 'check-circle-alt',
    color: 'var(--bs-success)',
  },
  overdue: {
    icon: 'alarm',
    color: 'var(--bs-danger)',
  },
  conditional: {
    icon: 'clock',
    color: 'var(--bs-purple)',
  },
  no_records: {
    icon: 'x-circle',
    color: 'var(--bs-warning)',
  },
  compliant_with_exemptions: {
    icon: 'check-circle',
    color: 'var(--bs-info)',
  },
  medically_exempt: {
    icon: 'file-plus',
    color: 'var(--bs-warning)',
  },
  non_medically_exempt: {
    icon: 'file-text',
    color: 'var(--bs-primary)',
  },
  pending: {
    icon: 'alert-circle',
    color: 'var(--bs-purple)',
  },
  multiple_matches: {
    icon: 'question',
    color: 'var(--bs-yellow)',
  },
};

export const StudentStatusIcon = ({ key, status, className }) =>
  status in STATUS_ICON_PROPS ? (
    <PrimaryIcon
      width={15}
      height={15}
      className={className}
      style={{ verticalAlign: 'middle' }}
      {...STATUS_ICON_PROPS[status]}
    />
  ) : (
    <span></span>
  );

const StudentProfiles = ({
  test_group_users,
  test_group,
  filtered_test_group_users_count,
  ...props
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const displayPending = urlParams.get('pending_review');

  const BoldAnchorComponent = ({ props }) => {
    const { full_name, student_immunizations_url } = props;
    return (
      <a href={student_immunizations_url}>
        <b>{full_name}</b>
      </a>
    );
  };

  const ComplianceStatusComponent = ({ props }) => {
    const { student_profile, student_immunizations_url } = props;
    if (!student_profile.immunization_status) return <span></span>;
    return (
      <a
        className="link-unstyled pointer"
        href={student_immunizations_url}
        data-test-hook={`immunization-status-${student_profile.id}`}
      >
        <span className="fabrx-chip-sm">
          <StudentStatusIcon status={student_profile.immunization_status} />
          {humanize(student_profile.immunization_status)}
        </span>
      </a>
    );
  };

  const ReviewModalButtonComponent = ({ props }) => {
    const {
      id: testGroupUserId,
      student_profile,
      user,
      test_group,
      review_test_group_schools_student_upload_url,
    } = props;
    const buttonVariant = 'outline-secondary';
    const buttonSize = 'sm';
    const [immunizationStatus, setImmunizationStatus] = useState(
      student_profile.immunization_status,
    );

    if (immunizationStatus === 'multiple_matches') {
      return (
        <MultipleMatchResolver
          onMatchSelected={async () => {
            try {
              const response = await requestHistoryUpdate({
                testGroupId: test_group.id,
                userId: user.id,
              });
              setImmunizationStatus('pending');
              window.location.reload(false);
              // re-calculate immunization compliance
            } catch (error) {
              console.log('error');
            }
          }}
          resolveButtonVariant={buttonVariant}
          resolveButtonSize={buttonSize}
          testGroupId={test_group.id}
          user={user}
          modalSubtitle="Please select the correct student."
          nameColumnAlias="Student"
          recordDetails={
            <StudentProfileCard
              user={user}
              testGroupSlug={test_group.slug}
              testGroupUserId={testGroupUserId}
            />
          }
        />
      );
    } else {
      return (
        <Button
          href={review_test_group_schools_student_upload_url}
          size={buttonSize}
          variant={buttonVariant}
        >
          Review
        </Button>
      );
    }
  };

  const itemComponents = {
    full_name: <BoldAnchorComponent />,
    immunization_status: <ComplianceStatusComponent />,
    review_student: <ReviewModalButtonComponent />,
    immunization_summary_card: (testGroupUser, expandRow) => (
      <ImmunizationsSummaryCard
        immunizationRecords={testGroupUser.immunization_records}
        testGroup={test_group}
        testGroupUser={testGroupUser}
        fetchOnExpand={expandRow}
        currentImmunizationStatus={
          testGroupUser.student_profile.immunization_status
        }
        cardClasses="p-4 border-0"
      />
    ),
  };

  const fetchItems = async (
    page,
    setFilteredPaginatedItems,
    setPageMetadata,
  ) => {
    const params = { page: page };
    for (const [key, value] of urlParams.entries()) params[key] = value;

    fetch(
      `/test_groups/${
        test_group.id
      }/schools/student_profiles.json?${queryString.stringify(params)}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((resp) => resp.json())
      .then((json) => {
        setFilteredPaginatedItems(json.test_group_users);
        setPageMetadata(json.meta);
      });
  };

  return (
    <>
      <SelectableTableContainer
        fetchItems={fetchItems}
        itemKeys={displayPending ? PENDING_ITEM_KEYS : OVERVIEW_ITEM_KEYS}
        itemComponents={itemComponents}
        items={test_group_users}
        tableHeaders={
          displayPending ? PENDING_TABLE_HEADERS : OVERVIEW_TABLE_HEADERS
        }
        testGroup={test_group}
        RowComponent={StudenProfileRow}
        filteredItemCount={filtered_test_group_users_count}
        {...props}
      />
    </>
  );
};

export default StudentProfiles;
