import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import HighlightedDatePicker from '../../common/components/HighlightedDatePicker';
import AsyncSelect from '../../common/components/AsyncSelect';

export const TestLocationFilters = ({
  month_capacities_path,
  location_options_url,
  dose_management_path_template,
  test_location_id,
  date: propDate,
}) => {
  const [date, setDate] = useState(propDate);
  const [testLocationId, setTestLocationId] = useState(test_location_id);

  const onDateChange = (dateObj) => setDate(dateObj.format('YYYY-MM-DD'));

  const redirect = () => {
    const path = dose_management_path_template
      .replace(':test_location_id', testLocationId)
      .replace(':date', date);
    window.location.href = path;
  };

  return (
    <Row className="flex-grow-1 me-md-2">
      <Col lg="5" sm className="mb-2 mb-md-0">
        <AsyncSelect
          valueKey="id"
          labelKey="name"
          name="test_group_id"
          selected={testLocationId}
          optionsUrl={location_options_url}
          onChange={setTestLocationId}
        />
      </Col>
      <Col lg="3" sm className="mb-2 mb-md-0">
        <HighlightedDatePicker
          remoteUrl={month_capacities_path}
          format="YYYY-MM-DD"
          inputClass="form-control"
          placeholder="Start date"
          value={date}
          onChange={onDateChange}
          containerStyle={{ width: '100%' }}
        />
      </Col>
      <Col lg="2" sm>
        <button type="button" className="btn btn-primary" onClick={redirect}>
          Go
        </button>
      </Col>
    </Row>
  );
};
