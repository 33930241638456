import React from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ScheduleAppointmentButton = ({
  dispatch,
  appointmentSlotGroup,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <a
      className={`py-3 body-medium-bold inter bold pointer`}
      data-test-hook={`submit_${appointmentSlotGroup.id}`}
      onClick={() => {
        dispatch({ type: 'updateForm', values: { appointmentSlotGroup } });
        dispatch({ type: 'perform_navigation', action: 'next' });
      }}
    >
      {t('family_registrations.register_for_clinic')}
      <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
    </a>
  );
};

export default ScheduleAppointmentButton;
