import React from 'react';
import StiFlow from './Sti/StiFlow';
import { ViralSurveillanceFlow } from './ViralSurveillance/ViralSurveillanceFlow';

export default function KitFlow({ kitProps, finishRegistrationProps }) {
  const params = new URLSearchParams(document.location.search);
  const FLOW =
    params.get('barcode')?.startsWith('PR-VS') ||
    params.get('id')?.startsWith('PR-VS') ||
    params.get('source') === 'viralsurveillance' ||
    params.get('source') === 'vsp'
      ? 'viral_surveillance'
      : 'sti';
  return (
    <>
      {FLOW === 'sti' && (
        <StiFlow
          {...kitProps}
          finishRegistrationProps={finishRegistrationProps}
        />
      )}
      {FLOW === 'viral_surveillance' && <ViralSurveillanceFlow {...kitProps} />}
    </>
  );
}
