import React, { useState } from 'react';
import SearchableTable from '../common/components/SearchableTable';
import { humanize } from '../common/utils';

const columnDateProp = { id: 'created_at', description: 'Date' };
const columnProps = [
          { id: 'id', clickable: true },
          { id: 'old_value', searchable: true },
          { id: 'new_value', searchable: true },
          { id: 'object_changed', optionable: true, searchable: true },
          { id: 'updated_by', optionable: true, searchable: true },
          { id: 'updated_by_role' },
          { id: 'event', optionable: true, searchable: true }
        ];

const columnHeaders = () => {
  return(
    <tr>
      <th>Date</th>
      <th>Updated by</th>
      <th>Event type</th>
      <th>Event</th>
      <th>Version ID</th>
    </tr>
  )
}

const lazyRows = () => {
  return(
    ([...Array(5)].map((_,i) => {
      return (
        <tr key={i}>
          <td>
            <div style={{filter: 'blur(8px)'}}>1/01/2023<br/><small style={{filter: 'blur(8px)'}}>12:00 PM PST</small></div>
          </td>
          <td><div style={{filter: 'blur(8px)'}}>Primary.Health<br/><small style={{filter: 'blur(8px)'}}>Automatic update</small></div></td>
          <td><div style={{filter: 'blur(8px)'}}>Updated field</div></td>
          <td><div style={{filter: 'blur(8px)'}}>Updated at from 1/1/2023 07:23 AM EDT to 1/2/2023 07:23 AM EDT</div></td>
          <td><div style={{filter: 'blur(8px)'}}>123456</div></td>
        </tr>
      );
    }))
  )
};

const change = (version) => {
  const old_value = version.has_date_range ? formatChangeDate(version.old_value) : version.old_value;
  const new_value = version.has_date_range ? formatChangeDate(version.new_value) : version.new_value;
  if(version.event.includes('Create')) {
    return <div>{humanize(version.object_changed)}: <strong>{new_value}</strong></div>
   } else {
    return <div>{humanize(version.object_changed)} from <strong>{old_value}</strong> to <strong>{new_value}</strong></div>
   }
}

const formatChangeDate = (date) => {
  const exractedDate = extractDate(date);
  return exractedDate ? `${exractedDate.date} ${exractedDate.time}` : date;
}

const extractDate = (datetime) => {
  try {
    const newDate = new Date(datetime);
    const parsedDate = newDate.toLocaleDateString('en-US');
    const parsedTime = newDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit', timeZoneName: 'short'});

    if(!datetime || parsedDate.toLowerCase() === 'invalid date' || parsedTime.toLowerCase() === 'invalid date') {
      return null;
    }

    return {
      'date': parsedDate,
      'time': parsedTime
    };
  } catch(e) {
    if (e instanceof RangeError) {
      return null;
    }
  }
}

const PrimaryVersionsTable = ({ title, urlPath }) => {
  const [clickableItem, setClickableItem] = useState(null);
  const eventTypeLabel = (version) => {
    if (version.event == "destroy") {
      return `Destroyed ${version.item_type}`
    } else {
      return `${humanize(version.event)}d ${version.item_type}`
    } 
  }

  const rows = (table) => {
    return(
      table.map( version => {

        const key = `${version.id}-${version.object_changed}`;
        const datetime = extractDate(version.created_at);
        return (
          <tr key={key}>
            <td>
              {datetime ? <div>{datetime.date}<br/><small className='text-muted'>{datetime.time}</small></div> : <div>{version.created_at}</div>}
            </td>
            <td>{version.updated_by}<br/><small className='text-muted'>{humanize(version.updated_by_role)}</small></td>
            <td>{eventTypeLabel(version)}</td>
            <td>{change(version)}</td>
            <td><div className='pointer' onClick={() => setClickableItem(version.id)}><u>{version.id}</u></div></td>
          </tr>
        );
      })
    )
  };

  const clearClickableItem = () => {
    setClickableItem(null);
  };

  return <SearchableTable
      title={title}
      urlPath={urlPath}
      responseParam={'versions'}
      columnDateProp={columnDateProp}
      columnProps={columnProps}
      columnHeaders={columnHeaders}
      rows={rows}
      lazyRows={lazyRows}
      clickableItem={clickableItem}
      clearClickableItem={clearClickableItem}
    />
};

export default PrimaryVersionsTable;
