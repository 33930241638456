import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import 'unfetch/polyfill';
import { Formik } from 'formik';

import RegistrationContext from '../../../Registration/contexts/RegistrationContext';
import TestSurvey, { validateSurvey } from './TestSurvey';
import ChooseTestConfiguration, {
  validateChooseTestConfiguration,
} from './ChooseTestConfiguration';
import FormActions from '../../../Registration/components/FormActions';
import SignConsentsForTestConfigurations, {
  validateConsent,
} from './SignConsentsForTestConfigurations';

const TestConfigurationForm = ({ testGroup, initialValues, user }) => {
  // State initialization
  const { t, i18n } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState(0);
  const [sections, setSections] = useState([]);
  const [chosenTestConfigurations, setChosenTestConfigurations] = useState([]);

  const testSurveys = useMemo(() => {
    const testSurveys = testGroup.request_test_configurations_on_registration
      ? initialValues.test_surveys.filter((ts) =>
          chosenTestConfigurations
            .map((tc) => tc.test_configuration_id)
            .includes(ts.test_configuration_id),
        )
      : initialValues.test_surveys;

    return testSurveys.map((survey) => ({
      view: ({ values, errors, setFieldValue }) => (
        <TestSurvey
          key={survey.id}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          userTestSurvey={survey}
        />
      ),
      validate: validateSurvey.bind(
        null,
        survey.test_configuration_survey,
        initialValues.test_surveys.findIndex(
          (ts) => ts.test_configuration_id === survey.test_configuration_id,
        ),
      ),
      name: `test_surveys_${survey.test_configuration_id}`,
    }));
  }, [testGroup, chosenTestConfigurations]);

  // Update sections
  useEffect(() => {
    setSections(
      [
        testGroup.request_test_configurations_on_registration
          ? {
              view: ({ values, errors, setFieldValue }) => (
                <ChooseTestConfiguration
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  user={user}
                />
              ),
              validate: validateChooseTestConfiguration,
              name: 'choose_test_configuration',
            }
          : null,
        testGroup.has_test_group_test_configuration_consent_forms &&
        !testGroup.request_test_configurations_on_registration
          ? {
              view: (props) => <SignConsentsForTestConfigurations {...props} />,
              validate: validateConsent,
              name: 'consent_forms_test_group_test_configuration',
            }
          : null,
      ]
        .concat(testSurveys)
        .filter((section) => section),
    );
  }, []);

  const onSubmit = (values, bag) => {
    if (currentRegistrationStep === sections.length - 1) {
      let testGroupUrl = `/registrations/${values.access_code}`;

      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ appointment: values }),
        redirect: 'follow',
      };
      fetch(testGroupUrl + '.json', requestOptions)
        .then((r) => r.json())
        .then((data) => {
          window.location.href = data.redirect_url;
        })
        .catch(function (err) {
          console.info(err);
        });
    } else {
      setCurrentRegistrationStep(currentRegistrationStep + 1);
    }
  };

  return (
    <div
      className="mx-0 mx-md-5 my-3"
      style={{ minWidth: '-webkit-fill-available', minHeight: '80vh' }}
    >
      <RegistrationContext.Provider
        value={{
          testGroup,
          user,
          isSubmitting,
          setIsSubmitting,
          currentRegistrationStep,
          setCurrentRegistrationStep,
          sectionsLength: sections.length,
          sections,
          setSections,
          onSubmit,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={sections[currentRegistrationStep]?.validate(testGroup)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            useEffect(() => {
              if (formikProps.errors) setIsSubmitting(false);
            }, [formikProps]);

            useEffect(() => {
              setChosenTestConfigurations(
                formikProps.values.chosen_test_configurations,
              );
            }, [formikProps.values?.chosen_test_configurations]);

            return (
              <form onSubmit={formikProps.handleSubmit}>
                <div className="form-inputs">
                  {sections[currentRegistrationStep]?.view(formikProps)}
                </div>
                <FormActions onSubmitText={t('registration.submit')} />
              </form>
            );
          }}
        </Formik>
      </RegistrationContext.Provider>
    </div>
  );
};

TestConfigurationForm.propTypes = {
  test_group: PropTypes.object.isRequired, // this is passed from the Rails view
  appointment: PropTypes.object.isRequired, // this is passed from the Rails view
};

export default TestConfigurationForm;
