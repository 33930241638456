import React, { FC } from 'react';
import {
  ConsentBase,
  validateConsent,
} from '../../Registration/components/registration/Consent';
import { Formik } from 'formik';
import { Button } from 'react-bootstrap';

interface CustomConsentProps {
  testGroup: {
    population: {
      require_guardian: boolean;
    };
    active_consent: {
      consent_form_text: string;
    };
    minimum_age_of_consent: number;
    allow_verbal_consent: boolean;
    may_have_test_group_test_configuration_consent: boolean;
  };
  onSuccess: () => void;
  appointment: {
    access_code: string;
  };
  user: {
    first_name: string;
    last_name: string;
    over_age_of_consent: boolean;
    'date_of_birth_datetime(1i)': number;
    'date_of_birth_datetime(2i)': number;
    'date_of_birth_datetime(3i)': number;
  };
  t: () => void;
}

const CustomConsent: FC<CustomConsentProps> = ({
  appointment,
  user,
  t,
  onSuccess,
  ...props
}) => {
  const initialValues = {
    appointment: {},
    first_name: user.first_name,
    last_name: user.last_name,
    over_age_of_consent: user.over_age_of_consent,
    'date_of_birth_datetime(1i)': user['date_of_birth_datetime(1i)'],
    'date_of_birth_datetime(2i)': user['date_of_birth_datetime(2i)'],
    'date_of_birth_datetime(3i)': user['date_of_birth_datetime(3i)'],
  };

  const onSubmit = async (values) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    };
    try {
      const response = await fetch(
        `/registrations/${appointment.access_code}.json`,
        requestOptions,
      );
      if (response.status === 200) {
        onSuccess();
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log('Something went wrong!', e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateConsent(props.testGroup)}
      validateOnChange={false}
    >
      {({ handleSubmit, ...formikProps }) => (
        <form onSubmit={handleSubmit}>
          <ConsentBase {...props} {...formikProps} />
          <div style={{ textAlign: 'right', paddingTop: '48px' }}>
            <Button
              variant="primary"
              type="submit"
              data-test-hook="submit"
              disabled={!formikProps.values.appointment.signature_base64}
            >
              {t('otc.next_button')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CustomConsent;
