import React from 'react';
import '../locales/i18n';
import { useTranslation } from 'react-i18next';
import * as SurveyOriginal from "survey-react";
import { Model, StylesManager} from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/plugins/bootstrap-integration";

const SurveyPreview = ({
  modelRef = React.createRef(),
  json,
  data,
  readOnly = false,
  errors = false,
  newSurvey=false,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  let SurveyComponent;
  if (newSurvey) {
    StylesManager.applyTheme('bootstrap');
    modelRef.current = new Model(json);
    modelRef.current.onScrollingElementToTop.add((_sender, options) => {
      options.cancel = true;
    })
    SurveyComponent = Survey;
  } else {
    SurveyOriginal.StylesManager.applyTheme('bootstrap');
    modelRef.current = new SurveyOriginal.Model(json);
    SurveyComponent = SurveyOriginal.Survey;
  }
  modelRef.current.data = data || {};
  modelRef.current.locale = i18n.language;
  modelRef.current.render();
  if (errors) modelRef.current.hasErrors();

  if (readOnly) {
    modelRef.current.mode = 'display';
  }

  const css = {
    question: {
      title: 'survey-question-title',
    },
    boolean: {
      switch: 'sv-boolean__switch',
    },
    radiogroup: {
      label: 'radio-label-v2 survey-label has-circle-check',
    },
    checkbox: {
      label: 'survey-label checkbox-label',
    },
  };

  return(
    <div className={newSurvey && "upgraded_surveyjs"}>
      <SurveyComponent model={modelRef.current} css={css} {...props} />
    </div>
  );
};

export default SurveyPreview;
