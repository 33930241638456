import React, { useCallback, useState } from 'react';
import axios from 'axios';

import CompletionView from './CompletionView';
import NavBar from './NavBar';
import UploadRecords from './UploadRecords';
import Summary from './Summary';
import UploadType from './UploadType';
import { useNavigation } from '../../common/components/Navigation';
import { uploadImmunizations } from '../helpers/requests';

const NAVIGATION_MAP = {
  info: {
    component: Summary,
    navigationActions: {
      upload: 'upload',
    },
  },
  upload: {
    component: UploadRecords,
    navigationActions: {
      back: 'info',
      next: 'uploadType',
    },
  },
  uploadType: {
    component: UploadType,
    navigationActions: {
      back: 'upload',
      next: 'completion',
    },
    beforeLeaveAsync: uploadImmunizations,
  },
  completion: {
    component: CompletionView,
    navigationActions: {
      next: 'info',
    },
  },
};

const initialState = ({ immunization_document_upload_path }) => ({
  immunization_document_upload_path,
  immunization_records: {},
  files: [],
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return { ...state, immunization_records: {}, files: [] };
    default:
      throw new Error('Unknown action:', action);
  }
};

const Dashboard = ({
  user,
  immunization_records_path: immunizationRecordsPath,
  ...props
}) => {
  const [immunizationRecords, setImmunizationRecords] = useState([]);
  const [pendingUploads, setPendingUploads] = useState([]);

  const { state, dispatch, currentNode } = useNavigation(
    NAVIGATION_MAP,
    'info',
    initialState(props),
    reducer,
  );

  const ContentComponent = currentNode.component;

  const refreshImmunizationRecords = useCallback(() => {
    axios.get(immunizationRecordsPath).then((r) => {
      setImmunizationRecords(r.data.immunization_records);
      setPendingUploads(r.data.pending_uploads);
    });
  }, []);

  return (
    <div className="dashboard">
      <NavBar user={user} />
      <div className="dashboard-content">
        <ContentComponent
          {...state}
          user={user}
          dispatch={dispatch}
          navigateTo={(action) =>
            dispatch({ type: 'perform_navigation', action })
          }
          immunizationRecords={immunizationRecords}
          pendingUploads={pendingUploads}
          refreshImmunizationRecords={refreshImmunizationRecords}
        />
      </div>
    </div>
  );
};

export default Dashboard;
