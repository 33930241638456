import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';

import { CardTag } from './ServiceCard';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import ModalHeader from '../../common/components/ModalHeader';
import { SERVICE_SPECIFICATION_TYPES } from '../../TestGroupTestConfigurations/components/TestGroupTestConfigurationsIndex';


const SelectableTestConfigurationRow = ({
  addTestConfiguration,
  checkBoxChecked,
  removeTestConfiguration,
  testConfiguration,
}) => {

  const serviceType = testConfiguration.service_type == "other"
    ? testConfiguration.service_kind_label
    : SERVICE_SPECIFICATION_TYPES[testConfiguration.service_type]['label'];
  const serviceTypeColor = SERVICE_SPECIFICATION_TYPES[testConfiguration.service_type]['color'];
  const serviceTypeBackgroundColor = SERVICE_SPECIFICATION_TYPES[testConfiguration.service_type]['backgroundColor'];

  return (
    <div
      className="px-3 py-4 pointer"
      onClick={() => {
        checkBoxChecked
          ? removeTestConfiguration(testConfiguration.id)
          : addTestConfiguration(testConfiguration.id);
      }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FBFBFB'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
      style={{ borderTop: '1px solid #E9F1FB' }}
    >
      <Row>
        <Col xs={1}>
          <FabrxCheckbox
            checked={checkBoxChecked}
            dataTestHook={'selectable_test_configuration_' + testConfiguration.id}
          />
        </Col>
        <Col className="services-modal-option-text" xs={5}>
          {testConfiguration.checkout_name || testConfiguration.display_name}
        </Col>
        <Col className="services-modal-option-text" xs={3}>
          {testConfiguration.age_range_label}
        </Col>
        <Col xs={3}>
          <CardTag
            backgroundColor={serviceTypeBackgroundColor}
            color={serviceTypeColor}
            text={serviceType}
          />
        </Col>
      </Row>
    </div>
  );
};

const ServicesModal = ({
  addTests,
  showServicesModal,
  setShowServicesModal,
  testConfigurations,
  loading,
}) => {

  const [selectedTestConfigurationIds, setSelectedTestConfigurationIds] = useState([]);
  const [filteredTestConfigurationIds, setFilteredTestConfigurationIds] = useState(testConfigurations.map((tc) => tc.id));
  const [searchInput, setSearchInput] = useState('');
  const allSelected = filteredTestConfigurationIds.length === selectedTestConfigurationIds.length;

  const addTestConfiguration = (testConfigurationId) => {
    if (!selectedTestConfigurationIds.includes(testConfigurationId)) {
      setSelectedTestConfigurationIds(prevIds => [...prevIds, testConfigurationId]);
    }
  };

  const removeTestConfiguration = (testConfigurationId) => {
    setSelectedTestConfigurationIds(prevIds =>
      prevIds.filter(id => id !== testConfigurationId)
    );
  };

  const processSearchResults = (input) => {
    setSearchInput(input);
    const searchTerm = input.trim().toUpperCase();
    const filteredIds = testConfigurations
      .filter(tc =>
        tc.checkout_name?.trim().toUpperCase().includes(searchTerm) ||
        tc.display_name?.trim().toUpperCase().includes(searchTerm)
      )
      .map(tc => tc.id);
    setFilteredTestConfigurationIds(filteredIds);
  };

  const selectAll = () => {
    setSelectedTestConfigurationIds(
      allSelected ? [] : filteredTestConfigurationIds
    );
  };

  return (
    <Modal
      onHide={() => setShowServicesModal(false)}
      show={showServicesModal}
      size="xl"
    >
      <ModalHeader
        closeButton
        onHide={() => setShowServicesModal(false)}
      >
        <Modal.Title as="h2">
          Add Service
          <p>Select services for this appointment.</p>
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <FloatingLabelInput
          className="mb-3"
          dataTestHook="search_services"
          onChange={(e) => processSearchResults(e.target.value)}
          prependIcon="search"
          value={searchInput}
        />
        <Card className="p-4">
          <div className="px-3 py-4">
            <Row>
              <Col xs={1}>
                <FabrxCheckbox
                  checked={allSelected}
                  onChange={selectAll}
                />
              </Col>
              <Col className="services-modal-option-text" xs={5}>Service</Col>
              <Col className="services-modal-option-text" xs={3}>Ages</Col>
              <Col className="services-modal-option-text" xs={3}>Type</Col>
            </Row>
          </div>
          {testConfigurations
            .filter((tc) => filteredTestConfigurationIds.includes(tc.id))
            .map((testConfiguration) => {
              return (
                <div key={testConfiguration.id}>
                  <SelectableTestConfigurationRow
                    addTestConfiguration={addTestConfiguration}
                    checkBoxChecked={selectedTestConfigurationIds.includes(testConfiguration.id)}
                    removeTestConfiguration={removeTestConfiguration}
                    selectedTestConfigurationIds={selectedTestConfigurationIds}
                    setSelectedTestConfigurationIds={setSelectedTestConfigurationIds}
                    testConfiguration={testConfiguration}
                  />
                </div>
              );
            })
          }
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mx-2"
          onClick={() => setShowServicesModal(false)}
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button
          className="mx-2"
          data-test-hook="add_service"
          disabled={loading || selectedTestConfigurationIds.length < 1}
          onClick={async () => {
            addTests(selectedTestConfigurationIds);
            setSelectedTestConfigurationIds([]);
          }}
        >
          {
            loading
              ? (<Spinner animation="border" size="sm" className="me-2" />)
              : (
                selectedTestConfigurationIds.length < 2
                  ? "Add service"
                  : `Add ${selectedTestConfigurationIds.length} services`
              )
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServicesModal;