import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalHeader = ({ children, closeButton, onHide, ...props }) => (
  <Modal.Header {...props}>
    {children}
    {closeButton && onHide && (
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={onHide}
      />
    )}
  </Modal.Header>
);

export default ModalHeader;
