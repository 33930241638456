import React from 'react';

const PaperPlane = ({ color = '#000000', ...props }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props} 
  >
    <path
      d="M0.99007 12.2051C-0.0700107 12.8101 0.0284923 14.4237 1.15893 14.8928L7.73987 17.6368V22.4822C7.73987 23.3312 8.4247 24.016 9.2737 24.016C9.72869 24.016 10.1602 23.8143 10.4511 23.4625L13.3592 19.9774L19.1709 22.3978C20.0574 22.7683 21.0847 22.1867 21.2301 21.2392L24.2321 1.72621C24.3212 1.15864 24.0726 0.586385 23.5989 0.262732C23.1251 -0.0609208 22.5059 -0.0890645 22.004 0.197063L0.99007 12.2051ZM3.43388 13.4012L19.4524 4.24976L9.15175 15.7605L9.20804 15.8074L3.43388 13.4012ZM19.1522 19.954L11.3376 16.694L21.3802 5.46932L19.1522 19.954Z"
      fill={color}
    />
  </svg>
);

export default PaperPlane;
