import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWalking,
  faHandHoldingMedical,
  faLaptopMedical,
} from '@fortawesome/pro-solid-svg-icons';

import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import MultiAddTestGroupUser from '../../MultiAddTestGroupUser/components/MultiAddTestGroupUser';

const ColSection = ({
  stepNumber,
  title,
  colSize = { xs: 12, md: 6 },
  children,
}) => {
  return (
    <Col xs={colSize.xs} md={colSize.md} className="mb-3">
      <div className="mb-3">
        {stepNumber}. {title}
      </div>
      {children}
    </Col>
  );
};

const NewParticipant = ({
  testGroupSlug,
  tags,
  populations,
  displayForceAdd,
  ...props
}) => {
  const [population, setPopulation] = useState({});
  const [accessKey, setAccessKey] = useState({});
  const [accessKeyOptions, setAccessKeyOptions] = useState(props.access_keys);

  const defaultRegistrationLink = `/r/${testGroupSlug}?continue_as_guest=1`;
  const defaultForceAddLink = `/test_groups/${testGroupSlug}/participants/new`;

  const [collectSignature, setCollectSignature] = useState(true);
  const [registerLink, setRegisterLink] = useState(null);
  const [forceAddLink, setForceAddLink] = useState(defaultForceAddLink);

  return (
    <Card body>
      <ColSection
        stepNumber={1}
        title="Which population does this participant belong to?"
      >
        <Card body>
          {populations.map((p) => {
            const active = population.id === p.id;
            return (
              <Button
                className='my-2'
                key={`populationSelection${p.id}`}
                variant={active ? 'primary' : 'outline-primary'}
                active={active}
                block
                onClick={() => {
                  setAccessKey({});
                  if (active) {
                    setPopulation({});
                    setAccessKeyOptions([...props.access_keys]);
                    setRegisterLink(null);
                    setForceAddLink(defaultForceAddLink);
                  } else {
                    setPopulation(p);
                    setAccessKeyOptions([
                      ...props.access_keys,
                      ...p.access_keys,
                    ]);
                    setRegisterLink(
                      `${defaultRegistrationLink}&registration_type=${p.name}`,
                    );
                    setForceAddLink(
                      `${defaultForceAddLink}?population=${p.name}`,
                    );
                  }
                }}
              >
                {p.friendly_name || p.name}
              </Button>
            );
          })}
        </Card>
      </ColSection>
      {accessKeyOptions.length > 0 && (
        <ColSection
          stepNumber={2}
          title="Would you like to add an access key? (does not apply to Force add)"
        >
          <Card body>
            {accessKeyOptions.map((key) => {
              const active = accessKey.value === key.value;
              return (
                <Button
                  key={`accessKeySelection${key.value}`}
                  variant={active ? 'secondary' : 'outline-secondary'}
                  active={active}
                  disabled={!population.id}
                  block
                  onClick={() => {
                    if (active) {
                      setAccessKey({});
                      setRegisterLink(
                        `${defaultRegistrationLink}&registration_type=${population.name}`,
                      );
                      setForceAddLink(
                        `${defaultForceAddLink}?population=${population.name}`,
                      );
                    } else {
                      setAccessKey(key);
                      setRegisterLink(`${registerLink}&key=${key.value}`);
                      setForceAddLink(`${forceAddLink}&key=${key.value}`);
                    }
                  }}
                >
                  {key.friendly_name}
                </Button>
              );
            })}
            <Button
              variant={accessKey.value ? 'outline-secondary' : 'secondary'}
              active={accessKey.value === null}
              disabled={!population.id}
              block
              onClick={() => setAccessKey({})}
            >
              No Access Key
            </Button>
          </Card>
        </ColSection>
      )}
      <ColSection
        stepNumber={accessKeyOptions.length > 0 ? 3 : 2}
        title="How do you want to register this participant?"
        colSize={{ xs: 12 }}
      >
        <Card body>
          <Row className="text-center">
            <Col md={4} className="my-2">
              <h1>
                <FontAwesomeIcon
                  icon={faWalking}
                  style={{ color: 'var(--vivid-blue)' }}
                />
              </h1>
              <p className="text-muted">
                <b>Participant(s)</b> registers and selects timeslot
                independently.
              </p>
              {population.id ? (
                <MultiAddTestGroupUser
                  testGroupId={testGroupSlug}
                  tags={tags}
                  populations={[population]}
                  accessKeys={accessKey.value ? [accessKey] : []}
                  styling={{
                    button: {
                      variant: 'primary',
                      block: true,
                      text: 'Send registration link',
                    },
                  }}
                  sendReg={true}
                />
              ) : (
                <Button disabled variant="secondary" block>
                  Send registration link
                </Button>
              )}
            </Col>
            <Col md={4} className="my-2">
              <h1>
                <FontAwesomeIcon
                  icon={faHandHoldingMedical}
                  style={{ color: 'var(--vivid-blue)' }}
                />
              </h1>
              <p className="text-muted">
                I will register and select timeslot for the participant.
              </p>
              {population.id ? (
                <Button
                  variant="primary"
                  block
                  href={`${registerLink}&on_behalf_registration=true${
                    collectSignature
                      ? ''
                      : '&consent_bypass=horse-drawn-wagon'
                  }`}
                >
                  Register on behalf
                </Button>
              ) : (
                <Button variant="secondary" disabled block>
                  Register on behalf
                </Button>
              )}
              <div
                className={`text-start mt-2 ${!population.id && 'text-muted'}`}
              >
                <FabrxCheckbox
                  checked={collectSignature === true}
                  onChange={() => setCollectSignature(!collectSignature)}
                  label="Collect signature on this device (disable for consent bypass)"
                />
              </div>
            </Col>
            <Col md={4} className="my-2">
              <h1>
                <FontAwesomeIcon
                  icon={faLaptopMedical}
                  style={{ color: 'var(--danger-red)' }}
                />
              </h1>
              <p className="text-muted">
                I will register the participant bypassing the schedule. (No
                Dose Wasted End of Day)
              </p>
              {displayForceAdd && population.id ? (
                <Button variant="danger" block href={forceAddLink}>
                  Force add
                </Button>
              ) : (
                <Button variant="danger" block disabled>
                  Force add
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </ColSection>
    </Card>
  );
};

export default NewParticipant;
