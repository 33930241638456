import IISReferences from './IisReferences';
import { isCovidVaccine } from '../../common/utils/vaccine';

class CovidWarningBuilder {
  static LEVEL_DANGER = 'danger';
  static LEVEL_WARNING = 'warning';
  static LEVEL_OK = 'success';

  static CDC_SUGGESTED_MAXIMUM_FOLLOW_UP_DAYS = 45;

  constructor({histories, tests, iisReferences}) {
    this.histories = histories;
    this.tests = tests;
    this.mostRecentReference = new IISReferences(iisReferences).mostRecent();
  }

  hasHadAdverseReactions() {
    return this.histories.find(({notes}) => notes);
  }

  hasStaleIISReferences() {
    return this.hasNeverBeenChecked() || this.wasLastCheckedWithErrors() || this.isStale();
  }

  hasNeverBeenChecked() {
    return !this.mostRecentReference;
  }

  wasLastCheckedWithErrors() {
    return IISReferences.hasErrors(this.mostRecentReference);
  }

  isStale() {
    return IISReferences.isStale(this.mostRecentReference);
  }

  warnings() {
    const warnings = [];

    if (this.hasHadAdverseReactions()) {
      warnings.push({
        level: CovidWarningBuilder.LEVEL_WARNING,
        message: "Possible adverse reactions in previous immunization history"
      });
    }

    if (this.hasStaleIISReferences()) {
      warnings.push({
        level: CovidWarningBuilder.LEVEL_WARNING,
        message: "Immunization history is not fresh"
      });
    }

    if (warnings.length === 0) {
      warnings.push({
        level: CovidWarningBuilder.LEVEL_OK,
        message: "Eligible for dose"
      });
    }

    return warnings;
  }
}

export default CovidWarningBuilder;
