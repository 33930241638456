import { Button, Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import BarcodeScanner from '../../common/components/BarcodeScanner';
import { PrimaryIcon } from '../../common/components/Icons/PrimaryIcon';
import SingleCharInputGrid from '../../common/components/SingleCharInputGrid';
import { useTranslation } from 'react-i18next';

export default function ScanBarcodeComponent ({
  handleSubmit,
  isMobile,
  loading,
}) {
  const { t } = useTranslation();
  const [showCamera, setShowCamera] = useState(false);
  const [showManualInput, setShowManualInput] = useState(false);
  const [uid, setUid] = useState("");

  const handleScan = uid => {
    if (uid) {
      const trimmed = uid.trim();
      setUid(trimmed);
      setShowCamera(false);
      handleSubmit(trimmed);
    };
  }

  useEffect(() => {
    if (uid.length == 7 && isMobile && !loading) handleScan("PR-" + uid)
  }, [uid])

  return (
    <>
      {showCamera ? (
        <BarcodeScanner onScan={handleScan} containerClassName="col-12"/>
      ) : (
        <Card
          className="text-center pointer"
          onClick={() => setShowCamera(true)}
          style={{
            border: "1px solid #EDF0F7",
            boxShadow: "2px 2px 4px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.08)",
            width: 185,
            height: 156,
            borderRadius: 24,
            marginTop: 10,
          }}
        >
          <Card.Body style={{paddingTop: 30}}>
            <PrimaryIcon icon="placeholder" color="#2862FA" height={60} width={60} />
            <div className='body-small-regular'>{t('self_administration.scan_barcode')}</div>
          </Card.Body>
        </Card>
      )}
      <div className='d-flex my-3 align-items-center text-muted inter medium body-medium-bold justify-content-center'>
        <hr style={{ background: "#DEE2E6", width: 88}}/>
        <div className='px-2 body-small-regular'>{t('or')}</div>
        <hr style={{ background: "#DEE2E6", width: 88}}/>
      </div>
      {showManualInput ? (
        <div className='d-flex align-items-center poppins regular'>
          <div>PR-</div>
          <SingleCharInputGrid
            length={7}
            onSubmit={() => handleScan("PR-" + uid)}
            icon={
              !isMobile && <PrimaryIcon
                icon="arrow-right-circle"
                color="#2862FA"
                className="pointer"
                width={35}
                height={35}
                onClick={() => {
                  if (uid.length == 7) handleScan("PR-" + uid)
                }}
                data-test-hook="submit"
              />
            }
            handleInputChange={(code) => setUid(code)}
            name="barcode"
            style={{width: 35, height: 48, borderRadius: 6, fontSize: 20}}
          /> 
        </div>
      ) : (
        <div style={{textAlign: "center"}}>
          <Button
            variant="link"
            style={{fontSize: 18}}
            onClick={() => setShowManualInput(true)}
            data-test-hook="enter_barcode_manually"
          >
            {t('self_administration.enter_barcode_manually')}
          </Button>
        </div>
      )}
    </>
  )
};
