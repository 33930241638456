import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button } from 'react-bootstrap';
import RegistrationContext from '../../contexts/RegistrationContext';
import { PersonalInformationSummary } from './fields/PersonalInformationFields';
import ErrorMessage from '../../../common/components/ErrorMessage';
import AlertHeader from '../../../common/components/AlertHeader';
import { SectionTitle } from '../SectionComponents';
import * as SurveyOriginal from "survey-react";
import { Model } from "survey-core";
import LocalizedMarkdown from './fields/LocalizedMarkdown';

const isBase64Image = (value) => {
  return typeof value === "string" && value.match(/data:image\/([a-zA-Z]*);base64,([^\"]*)/);
}

const ConfirmationRow = ({ label, value, rowClass, infoType, isRequired = true }) => {
  if (isBase64Image(value)) value = <img src={value} alt={`answer for question ${label}`} />;
  return (
    <Row className={rowClass}>
      <Col md={4} xs={12} className="text-muted">
        {label}
      </Col>
      <Col md={8} xs={12}>
        {
          value
            ? <span className="fw-bold">{value}</span>
            : isRequired
              ? <b className={`missing-${infoType}-info-badge text-danger`}>
                  Not selected
                </b>
              : ""
        }
      </Col>
    </Row>
  )
};

const HighlightBadge = ({ value }) => {
  return (
    <span className="h5">
      <span
        className="badge text-wrap text-dark mw-100"
        style={{
          verticalAlign: 'text-bottom',
          backgroundColor: 'rgba(0,134,208,0.1)',
        }}
      >
        {value}
      </span>
    </span>
  );
};

const Confirmation = ({ values }) => {
  const { t, i18n } = useTranslation();
  const { localFormState, setCurrentRegistrationStep, sections, testGroup, setSubmitButtonDisabled, setIsSubmitting, assignedAppointmentSlotGroupTitle } =
    useContext(RegistrationContext);

  const setRegistrationStep = (sectionName) => {
    const sectionIndex = sections.findIndex((x) => x.name === sectionName);
    setCurrentRegistrationStep(sectionIndex);
  };

  const surveyConfirmationRows = (showSurvey, surveyValues, surveyJson, title, step) => {
    if (!showSurvey) return <></>;
    const data = JSON.parse(surveyValues || "{}");
    if (!Object.keys(data).length) return <></>;
    const survey = testGroup.flipper_flags.upgraded_surveyjs ? new Model(surveyJson) : new SurveyOriginal.Model(surveyJson);
    survey.locale = i18n.language;
    survey.data = data;

    const formatValue = (question) => {
      if (question?.items?.length > 0) {
        return (
          <div>
            {question.items.map((item) => (
              <div>
                <span>{item.title}{" "}</span>
                <span><b>{item.value}</b></span>
              </div>
            ))}
          </div>
        )
      } else {
        return question?.displayValue;
      }
    };

    return (
      <>
      <div className="d-flex flex-row align-items-center justify-content-between mt-4 mb-3">
        <div className='h4-20-regular poppins'>{t(title)}</div>
        <Button
          variant={'outline-primary'}
          size="sm"
          onClick={() => setRegistrationStep(step)}
          style={{ fontSize: 14 }}
        >
          {t('registration.confirmation_section.edit_information')}
        </Button>
      </div>
      <Card body>
        <Row>
          <Col>
            {Object.keys(data).map(qv =>
              <ConfirmationRow
                label={survey.getQuestionByName(qv)?.title}
                value={formatValue(survey.getQuestionByName(qv))}
                rowClass="mb-2"
                infoType="contact"
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
    )
  }


  const address = (
    <span>
      {values.address_1}
      <br />
      {values.city?.trim()}, {values.state} {values.postal_code}
      <br />
      {values.address_2}
    </span>
  );

  const readablePhoneNumber = (phoneNumber, countryCode) => {
    let normalizedPhoneNumber = phoneNumber;
    if (phoneNumber?.length === 10) {
      normalizedPhoneNumber = phoneNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3',
      );
    }
    if (countryCode) {
      normalizedPhoneNumber = `${localFormState.countryCodes[countryCode]} ${normalizedPhoneNumber}`;
    }
    return normalizedPhoneNumber;
  };

  const handleMissingInfoErrors = () => {
    const missingInfoTypes = ["contact", "personal", "location"]
    let isInfoMissing = false;
    missingInfoTypes.forEach(info => {
      if (document.getElementsByClassName(`missing-${info}-info-badge`).length > 0) isInfoMissing = true;
    })
    if (isInfoMissing) {
      setSubmitButtonDisabled(true);
      setIsSubmitting(false);
    } else {
      setSubmitButtonDisabled(false);
    }
  }

  const isRequiredInfoMissing = (info) => {
    handleMissingInfoErrors();
    return document.getElementsByClassName(`missing-${info}-info-badge`).length > 0;
  }

  return (
    <div className="form-section mb-4">
      {isRequiredInfoMissing('contact') && (
        <AlertHeader
          title="Missing critical information"
          message="Please make sure all of your contact information is filled out."
          btnTxt="Add contact information"
          btnAction={() => setRegistrationStep('contact_information')}
        />
      )}
      {isRequiredInfoMissing('personal') && (
        <AlertHeader
          title="Missing critical information"
          message="Please make sure all of your personal information is filled out."
          btnTxt="Add personal information"
          btnAction={() => setRegistrationStep('personal_information')}
        />
      )}
      {sections.findIndex((x) => x.name === "appointment_time") >= 0 && isRequiredInfoMissing('location') && (
        <AlertHeader
          title="Missing location"
          message="Please make sure to select a location."
          btnTxt="Select location"
          btnAction={() => setRegistrationStep('appointment_time')}
        />
      )}
      <SectionTitle>{t('registration.confirmation_section.title')}</SectionTitle>
      <p className="text-muted">
        {t('registration.confirmation_section.p1')}
      </p>

      <div className="my-3">
        <LocalizedMarkdown container={testGroup} stringKey="registration_review_and_confirm_text" />
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mt-4 mb-3">
        <h2 className="h4-20-regular poppins">{t('registration.contact_information')}</h2>
        <Button
          variant={isRequiredInfoMissing('contact') ? 'danger' : 'outline-primary'}
          size="sm"
          onClick={() => setRegistrationStep('contact_information')}
          style={{ fontSize: 14 }}
        >
          {t('registration.confirmation_section.edit_information')}
        </Button>
      </div>
      {isRequiredInfoMissing('contact') && (
        <ErrorMessage
          message="Please make sure all fields are filled out."
          className="mb-3"
        />
      )}
      <Card body>
        <Row>
          <Col>
            <ConfirmationRow
              label={t('registration.first_name_label')}
              value={values.first_name}
              rowClass="mb-2"
              infoType="contact"
            />
            <ConfirmationRow
              label={t('registration.last_name_label')}
              value={values.last_name}
              rowClass="mb-2"
              infoType="contact"
            />
            <ConfirmationRow
              label={t('login.email_address')}
              value={values.email ? <HighlightBadge value={values.email}/> : ""}
              rowClass="mb-2"
              infoType="contact"
              isRequired={testGroup.required_attributes["email"]}
            />
            <ConfirmationRow
              label={t('registration.phone_number')}
              value={values.phone_number ?
                <HighlightBadge
                  value={readablePhoneNumber(
                    values.phone_number,
                    values.phone_number_country_code,
                  )}
                /> : ""
              }
              infoType="contact"
              isRequired={testGroup.required_attributes["phone_number"]}
            />
          </Col>
        </Row>
      </Card>
      {surveyConfirmationRows(
        Object.keys(testGroup.test_group_user_survey).length,
        values.test_group_user.survey,
        testGroup.test_group_user_survey,
        'registration.test_group_user_survey',
        'test_group_user_survey'
      )}
      {surveyConfirmationRows(
        testGroup.show_custom_survey,
        values.appointment.questionnaire,
        testGroup.custom_survey,
        'registration.custom_survey',
        'questionnaire'
      )}

      {values.address_1 && (
        <React.Fragment>
          <div className="d-flex flex-row align-items-center justify-content-between mt-4 mb-3">
            <h2 className="h4-20-regular poppins">{t('registration.address')}</h2>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setRegistrationStep('contact_information')}
              style={{ fontSize: 14 }}
            >
              {t('registration.confirmation_section.edit_information')}
            </Button>
          </div>
          <Card body>
            <Row>
              <Col>
                <ConfirmationRow
                  label={t('registration.address')}
                  value={address}
                />
              </Col>
            </Row>
          </Card>
        </React.Fragment>
      )}
      <div className="d-flex flex-row align-items-center justify-content-between mt-4 mb-3">
        <h2 className="h4-20-regular poppins">{t('registration.personal_information')}</h2>
        <Button
          variant={isRequiredInfoMissing('personal') ? 'danger' : 'outline-primary'}
          size="sm"
          onClick={() => setRegistrationStep('personal_information')}
          style={{ fontSize: 14 }}
        >
          {t('registration.confirmation_section.edit_information')}
        </Button>
      </div>
      {isRequiredInfoMissing('personal') && (
        <ErrorMessage
          message="Please make sure all fields are filled out."
          className="mb-3"
        />
      )}
      <Card body>
        <Row>
          <PersonalInformationSummary values={values} />
        </Row>
      </Card>
      {(sections.findIndex((x) => x.name === "appointment_time") >= 0 && testGroup.on_demand_only) && (
        <React.Fragment>
          <div className="d-flex flex-row align-items-center justify-content-between mt-4 mb-3">
            <h2 className="h4-20-regular poppins">{t('registration.location')}</h2>
            <Button
              variant={isRequiredInfoMissing('location') ? 'danger' : 'outline-primary'}
              size="sm"
              onClick={() => setRegistrationStep('appointment_time')}
              style={{ fontSize: 14 }}
            >
              {t('registration.confirmation_section.edit_information')}
            </Button>
          </div>
          {isRequiredInfoMissing('location') && (
            <ErrorMessage
              message="Please make sure all fields are filled out."
              className="mb-3"
            />
          )}
          <Card body>
            <Row>
              <Col>
                <ConfirmationRow
                  label={t('registration.location')}
                  value={values.appointment.assigned_appointment_slot_group_title ||
                        assignedAppointmentSlotGroupTitle ||
                        <b className="missing-location-info-badge text-danger">Not selected</b>}
                />
              </Col>
            </Row>
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};

export default Confirmation;
