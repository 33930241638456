import React from 'react';

const RemoveTubeCap = ({ color = '#193154', ...props }) => (
  <svg
    width="36"
    height="57"
    viewBox="0 0 36 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
      }}
      d="M21.3442 31.9731L21.3111 35.7186L14.6488 42.3809L14.6819 31.94C14.6819 31.5422 14.3505 31.2108 13.9527 31.2108C13.5218 31.1776 13.1572 31.5422 13.1904 31.9731L13.1572 48.9768C13.1904 51.529 15.0465 53.8492 17.6319 54.0481C19.0903 54.1144 20.4161 53.5841 21.3774 52.6229C22.2723 51.7279 22.8026 50.5347 22.8026 49.2089L22.8358 31.94C22.8358 31.5422 22.5043 31.2108 22.1066 31.2108C21.6757 31.1776 21.3111 31.5422 21.3442 31.9731ZM21.3442 49.2089C21.3111 50.1038 20.9465 50.9324 20.3167 51.5622C19.6206 52.2583 18.6926 52.5897 17.7313 52.5566C16.0078 52.424 14.6819 50.833 14.6819 48.9768L14.6488 44.5022L21.3111 37.8399L21.3442 49.2089ZM23.8633 28.1945H12.196C11.9971 28.1945 11.7983 28.2608 11.6657 28.3934C11.5 28.5591 11.4337 28.758 11.4337 28.9569C11.4337 29.3546 11.7983 29.7192 12.196 29.7192H23.8633C24.0621 29.7192 24.2279 29.6198 24.3936 29.4541C24.5262 29.3215 24.6256 29.1557 24.6256 28.9569C24.6256 28.5591 24.261 28.1945 23.8633 28.1945Z"
    />
    <path
      style={{
        fill: color,
      }}
      d="M17.3403 11.3125C17.7153 10.9062 18.3716 10.9062 18.7466 11.3125L23.7466 16.3125C24.0278 16.5938 24.1216 17.0312 23.9653 17.4062C23.8091 17.7812 23.4653 18.0312 23.0591 18.0312H20.0591V23.75C20.0591 24.4688 19.4966 25 18.8091 25H17.3091C16.5903 25 16.0591 24.4688 16.0591 23.75V18H13.0591C12.6528 18 12.2778 17.7812 12.1216 17.4062C11.9653 17.0312 12.0591 16.5938 12.3403 16.3125L17.3403 11.3125Z"
    />
    <rect
      x="8.80908"
      y="0.75"
      width="19.5"
      height="6.5"
      rx="2.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <rect
      x="24.4355"
      y="2.625"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="20.4355"
      y="2.625"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="16.4355"
      y="2.625"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
    <rect
      x="12.4355"
      y="2.625"
      width="0.25"
      height="2.75"
      rx="0.125"
      stroke={color}
      strokeWidth="0.25"
    />
  </svg>
);

export default RemoveTubeCap;
