import React from 'react';
import styled from 'styled-components';

type LabelDefinition = {
  color: string;
  backgroundColor: string;
  label?: string;
  icon?: string;
};

type LabelDefinitions = {
  [key: string]: LabelDefinition;
};

type LabelProps = {
  definitions?: LabelDefinitions;
  value: string;
  customLabel?: string;
};

interface LabelContainerProps {
  color: string;
  backgroundColor: string;
}

const LabelContainer = styled.div<LabelContainerProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 12px;
  }
`;

const defaultLabelDefinition: LabelDefinitions = {
  default: { color: '#717D96', backgroundColor: '#F7F9FC' },
};

const Label: React.FC<LabelProps> = ({ definitions, value, customLabel }) => {
  const aggregatedDefinitions = { ...defaultLabelDefinition, ...definitions };
  let definition =
    aggregatedDefinitions[value] || aggregatedDefinitions['default'];

  if (!(value || customLabel) || !definition) return null;

  const { color, backgroundColor, icon, label } = definition;

  return (
    <LabelContainer color={color} backgroundColor={backgroundColor}>
      {icon && (
        <i
          className={`fa-solid fa-xs ${icon}`}
          style={{ marginRight: '5px' }}
        />
      )}
      <span>{customLabel || label || value}</span>
    </LabelContainer>
  );
};

export default Label;
