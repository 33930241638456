import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Chevron, { Bar } from '../../../common/components/Chevron';
import TooltipWrapper from '../../../common/components/TooltipWrapper';
import { formatNumberWithCommas } from '../../../common/utils';
import Wrapped from '../../../common/components/Wrapped';

const SectionTitle = ({ title }) => (
  <div className="w-100 d-flex flex-row align-items-center mt-1">
    <hr className="flex-grow-1 mx-2 my-0" style={{ borderWidth: 3 }} />
    <span
      className="fw-bold text-uppercase text-secondary"
      style={{ fontSize: '85%' }}
    >
      {title}
    </span>
    <hr className="flex-grow-1 mx-2 my-0" style={{ borderWidth: 3 }} />
  </div>
);

export const CountItem = ({
  title,
  count,
  availability,
  tooltip: tooltipContent,
  style,
  sm,
}) => {
  const countFontSize = sm ? '1.3rem' : '1.5rem';

  return (
    <Wrapped
      WrapperComponent={TooltipWrapper}
      wrapIf={!!tooltipContent}
      componentProps={{ tooltipContent }}
    >
      <div
        className="m-auto d-flex flex-column align-items-center fw-bold"
        style={style}
      >
        <span
          className="text-secondary text-center"
          style={sm ? { fontSize: '95%' } : {}}
        >
          {title}
        </span>
        <span style={{ fontSize: countFontSize }}>{count}</span>
        {availability ? (
          <span
            className="text-success text-center"
            style={{ fontSize: '95%' }}
          >
            {formatNumberWithCommas(availability)} available
          </span>
        ) : null}
      </div>
    </Wrapped>
  );
};

const UpperIndicators = ({
  total_group_capacity,
  total_shared_capacity,
  available_shared_capacity,
  available_group_capacity,
  total_capacity,
}) => (
  <div className="metrics-container metrics-container-vertical p-4">
    <CountItem
      title="Total group capacity"
      count={total_group_capacity}
      availability={available_group_capacity}
    />
    <Chevron mirror />
    <CountItem
      title="Total shared capacity"
      count={total_shared_capacity}
      availability={available_shared_capacity}
    />
    <Chevron mirror />
    <CountItem title="Total capacity" count={total_capacity} />
  </div>
);

const LowerIndicators = ({
  group_standard_capacity,
  group_access_key_capacity,
  shared_standard_capacity,
  shared_access_key_capacity,
}) => (
  <div className="lower-metrics-container gray-light-100 p-2">
    <div className="d-flex flex-column flex-grow-1">
      <div className="metrics-container p-2">
        <CountItem title="Standard" count={group_standard_capacity} sm />
        <Bar height={50} className="my-2" />
        <CountItem title="Access key" count={group_access_key_capacity} sm />
      </div>
      <SectionTitle title="Group" />
    </div>
    <div className="d-flex flex-column flex-grow-1">
      <div className="metrics-container p-2">
        <CountItem title="Standard" count={shared_standard_capacity} sm />
        <Bar height={50} className="my-2" />
        <CountItem title="Access key" count={shared_access_key_capacity} sm />
      </div>
      <SectionTitle title="Shared" />
    </div>
  </div>
);

export const Metrics = ({ administered, appointments, ...props }) => {
  return (
    <div className="cards-wrapper my-4">
      <Card className="section-wrapper align-items-center">
        <CountItem
          title="Appointments"
          count={appointments}
          tooltip="Total appointments booked from capacity"
          style={{ marginBottom: -12 }}
        />
        <Bar className="my-n4" />
        <CountItem
          title="Administered"
          count={administered}
          tooltip="Appointments administered based on appointment date/time"
        />
      </Card>
      <Card className="section-wrapper">
        <UpperIndicators {...props} />
        <LowerIndicators {...props} />
      </Card>
    </div>
  );
};
