import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { fetchTestConfigurationOptions } from "./NewTestGroupTestConfigurationSelector";
import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import Switch from "../../common/components/Switch";
import {
  ServiceSpecificationTypeComponent,
  SERVICE_SPECIFICATION_TYPES,
  UniqueServiceIdComponent,
} from "./TestGroupTestConfigurationsIndex";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const AgeRangeBadge = ({ ageRange }) => {
  if (!ageRange) return <span />;
  return (
    <div
      className="text-center body-medium-bold my-auto px-2 text-nowrap"
      style={{
        backgroundColor: "#F7F9FC",
        borderRadius: "5px",
      }}
    >
      {ageRange}
    </div>
  );
};

export const TestGroupTestConfigurationTitle = ({
  checkoutName,
  selectedTestConfiguration,
  ageRangelabel,
}) => {
  return (
    <div className="d-flex">
      <h5>{checkoutName}</h5>
      <div className="ms-2">
        <UniqueServiceIdComponent
          props={{test_configuration: selectedTestConfiguration}}
        />
      </div>
      <div className="ms-2 mt-1">
        <ServiceSpecificationTypeComponent
          props={{test_configuration: selectedTestConfiguration}}
        />
      </div>
      <div className="ms-2 mt-1">
        <AgeRangeBadge ageRange={ageRangelabel} />
      </div>
    </div>
  );
};

const TestGroupTestConfigurationForm = () => {

  const {
    dispatch,
    flippers,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const [showRequisitioningPhysicianField, setShowRequisitioningPhysicianField] = useState(!!state.testGroupTestConfiguration.requisitioning_physician_id);

  useEffect(() => {
    if (!state.testConfigurationOptions) fetchTestConfigurationOptions(dispatch, state.testGroupTestConfiguration.test_group_id);
  }, [state.testGroupTestConfiguration.test_group_id]);

  if (!state.testConfigurationOptions) return <span />;

  const reqPhysicianOptions = state.testConfigurationOptions.requisitioning_physician_candidates.map((physician) => {
    return { value: physician.id, label: physician.full_name };
  });
  const selectedReqPhysician = reqPhysicianOptions.find((option) => {
    return option.value == state.testGroupTestConfiguration.requisitioning_physician_id;
  });
  const selectedAdministrationType = state.testConfigurationOptions.administration_type_options.find((type) => {
    return type.value == state.testGroupTestConfiguration.administration_type;
  });

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between mt-3">
            <TestGroupTestConfigurationTitle
              checkoutName={state.selectedTestConfiguration?.checkout_name}
              selectedTestConfiguration={state.selectedTestConfiguration}
              ageRangelabel={state.selectedTestConfiguration?.age_range_label}
            />
            <Switch
                checked={!!state.testGroupTestConfiguration.active}
                className="green-switch ms-5"
                disabled={!state.selectedTestConfiguration.active}
                id="active"
                label="ACTIVE"
                onChange={() => {
                  dispatch({
                    type: "setTestGroupTestConfiguration",
                    values: {
                      ...state.testGroupTestConfiguration,
                      active: !state.testGroupTestConfiguration.active
                    }
                  });
                }}
              />
          </div>
          <Row>
            <Col xs={12} lg={5}>
              {["lab", "poc"].includes(state.selectedTestConfiguration?.service_specification_type) && (
                <>
                  <div className="my-3">
                    <h6>Requisitioning physician:</h6>
                    <FloatingLabelSelect
                      filledValue={!!state.testGroupTestConfiguration.requisitioning_physician_id}
                      onChange={(reqPhysician) => {
                        dispatch({
                          type: "setTestGroupTestConfiguration",
                          values: {
                            ...state.testGroupTestConfiguration,
                            requisitioning_physician_id: reqPhysician.value
                          }
                        });
                      }}
                      options={reqPhysicianOptions}
                      value={selectedReqPhysician}
                    />
                  </div>
                  <div className="my-3">
                    <h6>Administration type:</h6>
                    <FloatingLabelSelect
                      filledValue={!!state.testGroupTestConfiguration.administration_type}
                      onChange={(administrationType) => {
                        dispatch({
                          type: "setTestGroupTestConfiguration",
                          values: {
                            ...state.testGroupTestConfiguration,
                            administration_type: administrationType.value
                          }
                        });
                      }}
                      options={state.testConfigurationOptions.administration_type_options}
                      value={selectedAdministrationType}
                    />
                  </div>
                </>
              )}
              <div className="my-3">
                <h6>Service code:</h6>
                <FloatingLabelInput
                  type="text"
                  value={state.testGroupTestConfiguration.service_code}
                  onChange={(e) => {
                    dispatch({
                      type: "setTestGroupTestConfiguration",
                      values: {
                        ...state.testGroupTestConfiguration,
                        service_code: e.target.value,
                      }
                    });
                  }}
                />
              </div>
              {!!flippers.case_report_url && (
                <>
                  <div className="my-3">
                    <h6>Case report url:</h6>
                    <FloatingLabelInput
                      type="text"
                      value={state.testGroupTestConfiguration.case_report_url}
                      onChange={(e) => {
                        dispatch({
                          type: "setTestGroupTestConfiguration",
                          values: {
                            ...state.testGroupTestConfiguration,
                            case_report_url: e.target.value,
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="my-3">
                    <h6>Documentation url:</h6>
                    <FloatingLabelInput
                      type="text"
                      value={state.testGroupTestConfiguration.documentation_url}
                      onChange={(e) => {
                        dispatch({
                          type: "setTestGroupTestConfiguration",
                          values: {
                            ...state.testGroupTestConfiguration,
                            documentation_url: e.target.value,
                          }
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </Col>
          </Row>

          {state.selectedTestConfiguration?.service_specification_type == "immunization" && (
            <>
              {showRequisitioningPhysicianField ? (
                <Row className="my-3">
                  <h6>Requisitioning physician:</h6>
                  <Col xs={12} lg={8} className="text-muted my-2">
                    Some vaccines may require physician approval in accordance with state regulations. If this does not apply, leave this field empty.
                  </Col>
                  <Col xs={12} lg={5}>
                    <FloatingLabelSelect
                      filledValue={!!state.testGroupTestConfiguration.requisitioning_physician_id}
                      onChange={(reqPhysician) => {
                        dispatch({
                          type: "setTestGroupTestConfiguration",
                          values: {
                            ...state.testGroupTestConfiguration,
                            requisitioning_physician_id: reqPhysician.value
                          }
                        });
                      }}
                      options={reqPhysicianOptions}
                      value={selectedReqPhysician}
                    />
                  </Col>
                </Row>
              ) : (
                <Button
                  className="my-3"
                  onClick={() => setShowRequisitioningPhysicianField(true)}
                  variant="link"
                >
                  <FontAwesomeIcon className="me-2" icon={faPlus} />
                  Add requisitioning physician
                </Button>
              )}
            </>
          )}

          {!["lab", "poc"].includes(state.selectedTestConfiguration?.service_specification_type) && ( // for 'lab' and 'poc', this field is is displayed within LabTestGroupTestConfigurationInputFields
            <Row>
              <Col xs={12} lg={8}>
                <h6>Medical screening survey:</h6>
                <Switch
                  checked={!!state.testGroupTestConfiguration.use_medical_screening_survey_on_checkout}
                  containerClassName="my-3"
                  id="use_medical_screening_survey_on_checkout"
                  label="Enable medical screening survey"
                  onChange={() => {
                    dispatch({
                      type: "setTestGroupTestConfiguration",
                      values: {
                        ...state.testGroupTestConfiguration,
                        use_medical_screening_survey_on_checkout: !state.testGroupTestConfiguration.use_medical_screening_survey_on_checkout
                      }
                    });
                  }}
                  subLabel="The medical screening survey includes questions that may be relevant to multiple services.
                    Enabling this will replace service specific surveys on checkout."
                />
              </Col>
            </Row>
          )}

          {!!state.selectedTestConfiguration?.service_specification_type && (
            SERVICE_SPECIFICATION_TYPES[state.selectedTestConfiguration.service_specification_type]["component"]
          )}

        </Card.Body>
      </Card>
    </div>
  );
};

export default TestGroupTestConfigurationForm;