import React, { useState, useEffect } from "react";
import ResultsAccess from "./ResultsAccess";
import Results from "./Results";
import { Container } from "react-bootstrap";
import Header from '../../Registration/components/Header';
import MedicalHistory from "./MedicalHistory";
import Flipper from '../../common/components/Flipper';

const ResultsRouter = (props) => {
  const [ hasCredentials, toggleHasCredentials ] = useState(false);
  const [ data, setData ] = useState(null);

  useEffect(() => {
    setData(props);
    if (props.appointment && props.user) {
      toggleHasCredentials(true);
    }
  }, [])

  return (
    <div>
      <Header testGroup={props.test_group}/>
      <Container>
        {
          hasCredentials ? (
            <MedicalHistory data={data} />
          ) : (
            <ResultsAccess {...{toggleHasCredentials, setData, testGroup: props.test_group}} />
          )
        }
      </Container>
    </div>
  )
}

export default ResultsRouter;
