import React, { useState, useContext, useEffect } from "react";
import { Button, Image, Alert, Row, Col } from "react-bootstrap";
import OtcContext from '../contexts/OtcContext';
import BarcodeScanner from "../../common/components/BarcodeScanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { verifyIfPhone } from "./OtcFlow";
import ErrorMessage from "../../common/components/ErrorMessage";

const OtcCodeReader = () => {

  const {
    t,
    verifyBarcode,
    activeErrors,
    clearError,
    clearErrors,
    barcodeScanned,
    flow,
    barcode,
    setBarcode,
    barcodeConfirmation,
    setBarcodeConfirmation,
    testConfiguration,
    setError,
    setNextButtonDisabled,
    stepNumber,
    barcodeReaderSkipped,
    showManualInput,
    setShowManualInput,
  } = useContext(OtcContext);

  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [showExample, setShowExample] = useState(false);

  const barcodeIsValid = barcode => {
    if (!testConfiguration.acceptable_code_formats) return true;
    return barcode.match(new RegExp(testConfiguration.acceptable_code_formats)) ? true : false;
  }

  // for passing QR scanner in system test:
  const createId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    if (barcodeScanned) {
      const stream = document.querySelectorAll('video')[0]?.srcObject;
      if (stream) stream.getTracks().forEach(track => track.stop())
    }
  },[barcodeScanned])

  const Scanner = () => {
    return (
      <div className="my-4">
          {!activeErrors["incorrect_barcode_format"] && (
            <BarcodeScanner
              onScan={async (uid) => {
                if (barcodeIsValid(uid)) {
                  verifyBarcode(uid)
                  clearError("incorrect_barcode_format")
                } else {
                  setError("incorrect_barcode_format")
                }
              }}
            />
          )}
        <div
          onClick={() => {
            if (!verifyIfPhone()) {
              const barcode = createId(6)
              setBarcode(barcode)
              verifyBarcode(barcode)
            }
          }}
          data-test-hook="createtestbarcode"
          style={{width: "1px", height: "1px", cursor: "default", color: "#FBFBFB"}}
        >.</div>
        <div className="text-muted text-center mt-1 mb-3">
        <div>
          {activeErrors["incorrect_barcode_format"] && (
            <ErrorMessage
              className="my-3"
              message={activeErrors["incorrect_barcode_format"]}
            />
          )}
          {activeErrors["barcodes_dont_match"] && (
            <ErrorMessage
              className="my-3"
              message={activeErrors["barcodes_dont_match"]}
            />
          )}
        </div>
          <em>
            {t("employer_testing.code_reader.not_working")}
            <a
              style={{ color: "var(--bs-primary)", cursor: "pointer" }}
              onClick={() => {
                setShowBarcodeScanner(false)
                clearErrors()
              }}
            >
              {" "}{t("employer_testing.code_reader.try_again")}
            </a>
          </em>
        </div>
      </div>
    )
  }

  const BarcodeScannerTrigger = () => {
    return (
      <div
        style={{ height: "250px", border: "1px solid #CED4DA", borderRadius: "3px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", cursor: "pointer" }}
        className="center"
        data-test-hook="showscanner"
        onClick={() => { setShowBarcodeScanner(!showBarcodeScanner) }}
      >
        <FontAwesomeIcon
          className="mt-10"
          icon={ flow === "self_checkouts" ? "qrcode" : "barcode" }
          size="3x"
          color="#2862FA"
        />
        <div style={{ color: "var(--bs-primary)" }}>
          {flow === "self_checkouts" ? t("employer_testing.code_reader.click_to_scan") : t("self_administration.scan_barcode")}
        </div>
      </div>
    )
  }

  const AlreadyScannedError = () => {
    return (
      <div>
        <ErrorMessage
          className="my-3"
          message={activeErrors["already_scanned"]}
        />
        <div className="text-center">
          <a
            style={{ color: "var(--bs-primary)", cursor: "pointer" }}
            onClick={() => {
                setShowBarcodeScanner(true);
                clearErrors();
              }}
          >
            <em>{t("employer_testing.code_reader.scan_new_test_card")}</em>
          </a>
        </div>
      </div>
    )
  }

  const SuccessfulScanMessage = () => {
    return (
      <Alert variant="info" className="my-2">
        {t("otc.barcode_scanned")}
      </Alert>
    )
  }

  const SkipScannerMessage = () => {
    return (
      <Alert variant="info" className="my-2">
        {t("otc.skip_barcode_reader")}
      </Alert>
    )
  }

  const abbottScanner = (
    <div>
      {verifyIfPhone() ? (
        <div>
          <h4 className="my-2">{stepNumber}. {t("otc.scan_code")}</h4>
          <p>{t("otc.qr_code_description")}</p>
          <Button
            size="sm"
            className="mb-2 otc-button"
            variant="link"
            onClick={() => {
              if (verifyIfPhone()) setShowExample(!showExample)
              window.scrollTo(0, document.body.scrollHeight)
            }}
          >
            {!showExample
              ? (<span>{t("otc.show_example")}  <FontAwesomeIcon size="md" icon="angle-down" color="#2862FA" /></span>)
              : (<span>{t("otc.hide_example")}  <FontAwesomeIcon size="md" icon="angle-up" color="#2862FA" /></span>)
            }
          </Button>
          {showExample && (
            <div>
              <div className="text-center mb-4">
                <Image src='https://primarybio-public.s3.us-east-2.amazonaws.com/test-binaxNOW-card-zoom.gif' rounded />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Row>
          <Col>
            <h4 className="my-2">{stepNumber}. {t("otc.scan_code")}</h4>
            <p>{t("otc.qr_code_description")}</p>
          </Col>
          <Col>
            <Image src='https://primarybio-public.s3.us-east-2.amazonaws.com/test-binaxNOW-card-zoom.gif' className="mt-2" rounded />
          </Col>
        </Row>
      )}

      {activeErrors["already_scanned"] ? (
        <AlreadyScannedError />
      ) : (
        barcodeScanned ? (
          <SuccessfulScanMessage />
        ) : barcodeReaderSkipped ? (
          <SkipScannerMessage />
        ) : (
        <div>
          {showBarcodeScanner ? (
            <Scanner />
          ) : (
            <div className="my-4">
              <BarcodeScannerTrigger />
            </div>
          )}
        </div>
        )
      )}
    </div>
  )

  const universalScanner = (
    <div>
      <div>
        <h4 className="my-2">{stepNumber}. {t("otc.scan_test_barcode")}</h4>
        <p>{t("otc.barcode_description")}</p>
      </div>

      {activeErrors["already_scanned"] ? (
        <AlreadyScannedError />
      ) : (
        barcodeScanned ? (
          <SuccessfulScanMessage />
        ) : (
        <div>
          {showBarcodeScanner ? (
            <Scanner />
          ) : (
            <div className="my-4">
              <BarcodeScannerTrigger />

              <div className="mt-4">
                <div className="flex-center-vertical">
                  <div className="self-admin__divider"></div>
                  <div className="ms-1 me-1">{t("otc.or")}</div>
                  <div className="self-admin__divider"></div>
                </div>
                <div className="my-3 text-center">
                  <Button
                    onClick={() => setShowManualInput(true)}
                    data-test-hook="manualinput"
                    variant="link"
                    size="sm"
                  >
                    {t("self_administration.enter_barcode_manually")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        )
      )}
    </div>
  )

  const manualInput = (
    <div>
      <h4 className="my-2">1. {t("otc.enter_barcode")}</h4>

      {t("self_administration.enter_barcode")}:
      <input
        type="text"
        value={barcode}
        onChange={(e) => {
          setBarcode(e.target.value)
          e.target.value === barcodeConfirmation ? clearError("barcodes_dont_match") : setError("barcodes_dont_match")
          barcodeIsValid(e.target.value) ? clearError("incorrect_barcode_format") : setError("incorrect_barcode_format")
          e.target.value === barcodeConfirmation && barcodeIsValid(e.target.value) ? setNextButtonDisabled(false) : setNextButtonDisabled(true)
        }}
        data-test-hook="barcodeinput"
        placeholder="PB000000"
        className="form-control mb-3"
        autoFocus
      />
      {t("self_administration.confirm_barcode_input")}:
      <input
        type="text"
        value={barcodeConfirmation}
        data-test-hook="barcodeinputconfirmation"
        onChange={(e) => {
          setBarcodeConfirmation(e.target.value)
          e.target.value === barcode ? clearError("barcodes_dont_match") : setError("barcodes_dont_match")
          barcodeIsValid(e.target.value) ? clearError("incorrect_barcode_format") : setError("incorrect_barcode_format")
          e.target.value === barcode && barcodeIsValid(e.target.value) ? setNextButtonDisabled(false) : setNextButtonDisabled(true)
        }}
        placeholder="PB000000"
        className="form-control mb-3"
      />

      <div>
        {activeErrors["incorrect_barcode_format"] && (
          <ErrorMessage
            className="my-3"
            message={activeErrors["incorrect_barcode_format"]}
          />
        )}
        {activeErrors["barcodes_dont_match"] && (
          <ErrorMessage
            className="my-3"
            message={activeErrors["barcodes_dont_match"]}
          />
        )}
      </div>

      <div className="mt-4">
        <div className="flex-center-vertical">
          <div className="self-admin__divider"></div>
          <div className="ms-1 me-1">{t("otc.or")}</div>
          <div className="self-admin__divider"></div>
        </div>
        <div className="my-3 text-center">
          <Button
            onClick={() => setShowManualInput(false)}
            variant="link"
            size="sm"
          >
            {t("otc.scan_qr_code")}
          </Button>
        </div>
      </div>
      {activeErrors["already_scanned"] && (
        <AlreadyScannedError />
      )}
    </div>
  )

  return (
    <div>
      {showManualInput
        ? manualInput
        : flow === "self_checkouts"
          ? abbottScanner
          : universalScanner
      }
    </div>
  )
}

export default OtcCodeReader;
