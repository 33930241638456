import React from 'react';
import SurveyPreview from '../../common/components/SurveyPreview';
import { useTranslation } from 'react-i18next';

export default ({state, dispatch}) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className='mt-4 mb-3 inter medium'>{t('registration.custom_survey')}</h4>
      <SurveyPreview
        json={state.testGroup.insurance_survey}
        newSurvey={state.testGroup.flipper_flags.upgraded_surveyjs}
        data={state.formState.insuranceAnswers || {}}
        onValueChanged={(e) => dispatch({type: "updateForm", values: {insuranceAnswers: e.data}})}
      />
    </>
  );
}
