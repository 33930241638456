import i18n from "i18next";

export const languageObject = {
    en: 'English',
    es: 'Español',
    hmn: 'Hmong',
    so: 'Soomaali',
};

export const lang = () => {
    if (Object.keys(languageObject).includes(i18n.language))
        return i18n.language;
    else return 'en';
};
