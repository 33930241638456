import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import GridList, { GridListElement } from '../../../common/components/GridList';
import {
  ActionButton,
  CollapseIcon,
  ExpandIcon,
  FilePreview,
  TrashCanIcon,
} from '../../../common/components/UploadList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';

const shortenFilename = (filename) => {
  if (filename.length > 15) {
    return filename.substr(0, 10) + '..' + filename.substr(filename.length - 4);
  }
  return filename;
};

const ItemContainer = styled(GridListElement)`
  ${({ expanded }) =>
    expanded &&
    css`
      width: 100%;
    `}
`;

const DocumentUpload = ({
  upload: {
    id,
    filename,
    url,
    content_type: contentType,
    uploaded_by: uploadedBy,
  },
  onDelete,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ItemContainer className="text-center" expanded={expanded}>
      <FilePreview
        expanded={expanded}
        contentType={contentType}
        url={url}
        actions={[
          <ActionButton
            IconComponent={TrashCanIcon}
            iconColor={expanded ? '#FFF' : '#4A5468'}
            onClick={() => onDelete(id)}
          />,
          <ActionButton
            IconComponent={expanded ? CollapseIcon : ExpandIcon}
            iconColor={expanded ? '#FFF' : '#4A5468'}
            onClick={() => setExpanded(!expanded)}
          />,
        ]}
      />
      <div className="d-flex flex-column mt-2">
        <a
          href={url}
          className="filename link-unstyled"
          target="_blank"
          download
        >
          {shortenFilename(filename)}{' '}
          <FontAwesomeIcon icon={faDownload} className='ms-1 fs-6' />
        </a>
        <span className="lh-sm">
          <small>
            Uploaded by{' '}
            <b>{uploadedBy === 'external' ? 'Parent' : 'Administrator'}</b>
          </small>
        </span>
      </div>
    </ItemContainer>
  );
};

const StudentUploadsList = ({ studentUploads, onDelete }) => (
  <GridList columns={3} gap={24} className="immunization-documents">
    {studentUploads.map((upload, idx) => (
      <DocumentUpload key={idx} upload={upload} onDelete={onDelete} />
    ))}
  </GridList>
);

export default StudentUploadsList;
