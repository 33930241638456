import React, {useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import Select from 'react-select';


export const SelectFormGroup = ({
  label,
  options,
  value,
  handleChange,
  className,
  placeholder,
  showError=false,
}) => {

  const TabletStyles = {
    input: styles => ({ ...styles, fontSize: '18px', letterSpacing: ".1rem"}),
    placeholder: styles => ({ ...styles, fontSize: '18px', letterSpacing: ".1rem" }),
    singleValue: (styles, { data }) => ({ ...styles, fontSize: '18px', letterSpacing: ".1rem" }),
    option: (styles, { data }) => ({ ...styles, fontSize: '18px', letterSpacing: ".1rem" }),
  };

  if (showError) {
    TabletStyles.control = styles => ({
      ...styles,
      borderColor: '#F46363',
      '&:hover': {
        borderColor: '#F46363',
      }
    })
  }


  return ( 
    <Form.Group as={Row}>
      <Form.Label column xs={12} md={4} sm={5}>{ label }:</Form.Label>
      <Col xs={12} md={8} sm={7}>
        <Select
          className={className}
          value={
            options
              .filter(option => option.value === value)
          }
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          styles={TabletStyles}
        />
      </Col>
    </Form.Group>
  );
};

export const LabeledField = ({label, value, showAsBadge = false}) => {

  return (
    <Row className="mb-2" style={{verticalAlign: "middle"}}>
      <Col xs={12} md={4} sm={5}>
        {label}:
      </Col>
      <Col xs={6} md={3} sm={3} style={{verticalAlign: "middle"}}>
        {showAsBadge ? <span className="badge bg-primary">{value}</span> : <b>{value}</b>}
      </Col>
    </Row>
  )
};

export const VaccineResultLabelField = ({label, value, onChange, className, formControl="input"}) => {
  const [showInput, setShowInput] = useState(!value);

  return showInput ? (
    <VaccineResultField
      label={label}
      value={value}
      onChange={onChange}
      className={className}
      formControl={formControl}
    />
  ) : (
    <Row className="mb-2" style={{verticalAlign: "middle"}}>
      <Col xs={12} md={4} sm={5}>
        {label}:
      </Col>
      <Col xs={6} md={3} sm={3} style={{verticalAlign: "middle"}}>
        <b>{value}</b>
      </Col>
      <Col xs="auto">
        <Button size="sm" className="py-0" onClick={() => setShowInput(true)}>Edit</Button>
      </Col>
    </Row>
  )
};

export const VaccineSelectLabelField = ({label,
  options,
  value,
  handleChange,
  className,
  placeholder,
  showError=false,
}) => {
  const [showSelect, setShowSelect] = useState(!value);

  return showSelect ? (
    <SelectFormGroup 
      label={label}
      options={options}
      value={value}
      handleChange={handleChange}
      className={className}
      placeholder={placeholder}
      showError={showError}
    />
  ):(
    <Row className="mb-2" style={{verticalAlign: "middle"}}>
      <Col xs={12} md={4} sm={5}>
        {label}:
      </Col>
      <Col xs={6} md={3} sm={3} style={{verticalAlign: "middle"}}>
        <b>{value}</b>
      </Col>
      <Col xs="auto">
        <Button size="sm" className="py-0" onClick={() => setShowSelect(true)}>Edit</Button>
      </Col>
    </Row>
  )
};


const VaccineResultField = ({label, value, onChange, className, helperText, formControl="input"}) => {

  return (
    <Form.Group as={Row}>
      <Form.Label column xs={12} md={4} sm={5}>{label}:</Form.Label>
      <Col xs={12} md={8} sm={7}>
        <Form.Control
          value={value || ""}
          onChange={onChange}
          className={className || ""}
          style={{fontSize: "18px", letterSpacing: ".1rem"}}
          as={formControl}
        />
        <small className="text-muted">{helperText}</small>
      </Col>
    </Form.Group>
  );
};

export default VaccineResultField;
