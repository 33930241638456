import React from 'react';
import { Button } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const ButtonStyleWrapper = styled.div`
  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-inline: 12px !important;
    padding-block: 2px !important;

    .select__value-container {
      padding-inline-start: 4px !important;
      padding-inline-end: 0 !important;
    }

    .select__placeholder,
    .select__single-value,
    .select__input {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .select__placeholder {
      color: #2862fa;
    }

    .select__single-value {
      color: #314677;
    }
  }
`;

const ButtonControl = ({ children, innerProps, ...props }) => (
  <ButtonStyleWrapper>
    <Button
      className="w-100"
      variant={props.selectProps.borderless ? 'link' : 'outline-primary'}
      {...innerProps}
      {...props}
    >
      {children}
    </Button>
  </ButtonStyleWrapper>
);

export default ButtonControl;
