import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const TitleCard = (props) => {
  return (
    <Card className={`mt-3 mb-3 rounded-6 shadow-20 ${props.className || ""}`}>
      <div className="px-3 px-md-4 pt-3 mt-2 mb-1">
        <Row className="align-items-center">
          <Col>
            {props.logo &&
              <img src={props.logo} height="50"/>
            }
            <h4 className="fs-16 mb-0">{props.title}</h4>
          </Col>
        </Row>
        <hr className="mb-0"/>
      </div>
      {props.children}
    </Card>
  );
};

export default TitleCard;
