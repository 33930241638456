import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/pro-regular-svg-icons";


const MultiplexResultCard = ({ subTestKey, subTestLabel, multiplexResult, setMultiplexResult, setNextButtonDisabled }) => {

  const [isCardPositive, setIsCardPositive] = useState(multiplexResult && multiplexResult[subTestKey] === "positive");
  const [isCardNegative, setIsCardNegative] = useState(multiplexResult && multiplexResult[subTestKey] === "negative");

  const handleClick = (subResult) => {
    const newResult = multiplexResult || {};
    newResult[subTestKey] = subResult;
    setMultiplexResult(newResult);
    setIsCardPositive(subResult === "positive");
    setIsCardNegative(subResult === "negative");
    setNextButtonDisabled(Object.keys(newResult).length < 3);
  }

  return (
    <Card style={{minHeight: "70px", border: "1px solid rgb(172 172 172)"}}>
      <Row style={{margin: "auto 15px auto 0"}} className="text-center">
        <Col xs={2}>
          {isCardNegative ? (
            <img src="/images/icons/icon-radio-button-selected-green.svg" className="m-2"/>
            ) : (
            <div onClick={() => handleClick("negative")} className="pointer p-2">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color="#E2E2E2"
                data-test-hook={`${subTestKey}negativeresult`}
              />
            </div>
          )}
        </Col>
        <Col xs={8} className="p-2">
          {subTestLabel}
        </Col>
        <Col xs={2}>
          {isCardPositive ? (
            <img src="/images/icons/icon-radio-button-selected-green.svg" className="m-2"/>
          ) : (
            <div onClick={() => handleClick("positive")} className="pointer p-2">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color="#E2E2E2"
                data-test-hook={`${subTestKey}positiveresult`}
              />
            </div>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default MultiplexResultCard;
