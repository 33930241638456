import React, { useContext, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import { isMobile } from '../../../../common/utils/browser';
import { PersonalFieldDropdown } from '../../SectionComponents';

const ArabEthnicityDropdown = ({
  attributeString = '',
  context = RegistrationContext,
  setFieldValue,
  values,
}) => {
  const { t } = useTranslation();
  const { testGroup } = useContext(context);
  const label = buildLocalizedString(testGroup, 'arab_ethnicity_label');
  const fieldKey = `${attributeString}arab_ethnicity`;
  const required = testGroup.required_attributes.arab_ethnicity;

  const arabEthnicities = testGroup.arab_ethnicity_options.map(
    (arab_ethnicity) => ({
      label: t(`arab_ethnicity.${arab_ethnicity}`),
      value: arab_ethnicity,
    }),
  );

  return (
    <PersonalFieldDropdown
      helpText={
        buildLocalizedString(testGroup, 'arab_ethnicity_help_text') ? (
          <LocalizedMarkdown
            container={testGroup}
            stringKey="arab_ethnicity_help_text"
          />
        ) : (
          <span>{t('arab_ethnicity.help')}</span>
        ) 
      }
    >
      <FloatingLabelSelect
        isSearchable={!isMobile}
        value={arabEthnicities.find((so) => so.value === values[fieldKey])}
        onChange={(arabEthnicityOpt) =>
          setFieldValue(fieldKey, arabEthnicityOpt.value)
        }
        options={arabEthnicities}
        filledValue={!!values[fieldKey]}
        id="arab"
        name={attributeString + "arab_ethnicity"}
        label={
          label
            ? `${label} ${required ? '*' : '(Optional)'}`
            : `${t('arab_ethnicity.question')}  ${required ? '*' : '(Optional)'}`
        }
      />
    </PersonalFieldDropdown>
  );
};

export default ArabEthnicityDropdown;
