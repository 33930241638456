import React from 'react';

const Header = ({ user }) => (
  <div className="header">
    <h1 className="student-name mb-1">{user.full_name}</h1>
    <span>
      <span className="font-weight-bold">ID:</span> {user.id || 'N/A'}
    </span>
  </div>
);

export default Header;
