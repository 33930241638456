import axios from 'axios';

export const submit = async ({testGroup, formState}) => {
  const formData = new FormData();
  formData.append('user[insurance_status]', formState.haveHealthInsurance ? 0 : 1);
  if (formState.insuranceAnswers){
    Object.keys(formState.insuranceAnswers).forEach(key => formData.append(`appointment[insurance_answers]${key}`, formState.insuranceAnswers[key]));
  }
  if (formState.haveHealthInsurance) {
    formData.append('user[insurance_policy_holder]', formState.policyHolder);
    formData.append('user[insurance_company]', formState.insuranceCompanyName);
    formData.append('user[insurance_id]', formState.memberId);
    formData.append('user[insurance_group_number]', formState.groupNumber);
    formData.append('user[insurance_primary_member_first_name]', formState.policyHolderFirstName);
    formData.append('user[insurance_primary_member_last_name]', formState.policyHolderLastName);
    formData.append('user[insurance_primary_member_date_of_birth(1i)]', formState.policyHolderDateOfBirth.year);
    formData.append('user[insurance_primary_member_date_of_birth(2i)]', formState.policyHolderDateOfBirth.month);
    formData.append('user[insurance_primary_member_date_of_birth(3i)]', formState.policyHolderDateOfBirth.day);
    if (formState.insuranceCardFront) formData.append('user[insurance_card_front]', formState.insuranceCardFront);
    if (formState.insuranceCardBack) formData.append('user[insurance_card_back]', formState.insuranceCardBack);
  }

  const response = await axios.put(
    testGroup.submit_insurance_url + ".json",
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  window.location.href = response.data.url
}
