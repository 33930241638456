import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  & h1,
  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const Bar = styled.div`
  height: 5px;
  background-image: ${({ percentage }) =>
    `linear-gradient(to right, #2862FA ${percentage}%, #dadada ${percentage}%)`};
  animation: gradient 15s ease infinite;
`;

const ProgressBar = ({
  title,
  totalSteps,
  completedSteps,
  capitalizeTitle = false,
  percentage = Math.floor((completedSteps * 100) / totalSteps) || 0,
}) => {
  const { t } = useTranslation();

  return (
    <Container className="w-100">
      <Bar percentage={percentage} />
      <div className="w-100 d-flex justify-content-between text-secondary px-2 px-md-4 mt-1">
        <h1>{title && (capitalizeTitle ? title.toUpperCase() : title)}</h1>
        <span style={{ textAlign: 'right', lineHeight: 1.2 }}>
          {percentage}% {t('vaccination_status.progress_bar_complete')}
        </span>
      </div>
    </Container>
  );
};

export default ProgressBar;
