import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import RegistrationContext from '../../../contexts/RegistrationContext';
import GenderInput from '../inputs/GenderInput';
import SelectLanguage from '../inputs/SelectLanguage';
import RaceDropdown from '../inputs/RaceDropdown';
import SexualOrientation from '../inputs/SexualOrientation';
import CustomSurvey from '../CustomSurvey';
import Pronouns from '../inputs/Pronouns';
import ErrorMessage from '../../../../common/components/ErrorMessage';
import EthnicityDropdown from '../inputs/EthnicityDropdown';
import ArabEthnicityDropdown from '../inputs/ArabEthnicityDropdown';
import SexAtBirthDropdown from '../inputs/SexAtBirthDropdown';
import { buildLocalizedString } from '../../../../common/locales/langs';
import { showFieldOnRegistration } from '../utils';
import { SectionTitle } from '../../SectionComponents';
import LocalizedMarkdown from './LocalizedMarkdown';
import VaccineProgramInput from './VaccineProgramInput';

const PersonalInformationFields = ({
  attributeString = '',
  values,
  errors,
  handleChange,
  setFieldValue,
  columnWidth,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  const findError = (key) => errors[`${attributeString}${key}`];
  const showField = (field) => showFieldOnRegistration(testGroup, field);

  return (
    <div>
      <SectionTitle>
        {t('registration.personal_information')}
      </SectionTitle>
      {buildLocalizedString(testGroup,'registration_personal_information_section_text') ? 
        <LocalizedMarkdown
          container={testGroup}
          stringKey="registration_personal_information_section_text"
        /> : <div className="h4-20-regular">{t('ethnicity.subtitle')}</div> 
      }
      {showField('race') && (
        <div className={`col-md-${columnWidth} mt-3 mb-2`}>
          <RaceDropdown
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
            handleChange={handleChange}
          />
          {findError('race') && <ErrorMessage message={t(findError('race'))} />}
          {testGroup.allow_self_described_race_enabled &&
            testGroup.allow_self_described_race &&
            findError('self_described_race') && (
              <ErrorMessage message={t(findError('self_described_race'))} />
            )}
        </div>
      )}
      {showField('ethnicity') && (
        <div className={`col-md-${columnWidth} mt-3 mb-2`}>
          <EthnicityDropdown
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
          />
          {findError('ethnicity_blob') && (
            <ErrorMessage message={t(findError('ethnicity_blob'))} />
          )}
        </div>
      )}
      {showField('arab_ethnicity') && (
        <div className={`col-md-${columnWidth} mt-3 mb-2`}>
          <ArabEthnicityDropdown
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
          />
          {findError('arab_ethnicity') && (
            <ErrorMessage message={t(findError('arab_ethnicity'))} />
          )}
        </div>
      )}
      {showField('sex_at_birth') && (
        <div className={`col-md-${columnWidth} mt-3 mb-2`}>
          <SexAtBirthDropdown
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
          />
          {findError('sex_at_birth') && (
            <ErrorMessage message={t(findError('sex_at_birth'))} />
          )}
        </div>
      )}
      {showField('gender_identity') && (
        <div className={`col-md-${columnWidth}`}>
          <GenderInput
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
            handleChange={handleChange}
            error={findError('gender_string')}
          />
          {findError('gender_string') && (
            <ErrorMessage message={t(findError('gender_string'))} />
          )}
        </div>
      )}
      {showField('sexual_orientation') && (
        <div className={`col-md-${columnWidth}`}>
          <SexualOrientation
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
            error={findError('sexual_orientation')}
          />
          {findError('sexual_orientation') && (
            <ErrorMessage message={t(findError('sexual_orientation'))} />
          )}
        </div>
      )}
      {showField('pronoun') && (
        <div className={`col-md-${columnWidth}`}>
          <Pronouns
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            attributeString={attributeString}
            error={findError('pronoun')}
          />
          {findError('pronoun') && (
            <ErrorMessage message={t(findError('pronoun'))} />
          )}
        </div>
      )}
      {(showField('language_preference') ||
        showField('preferred_language')) && (
        <div className={`col-md-${columnWidth}`}>
          <SelectLanguage
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            attributeString={attributeString}
            error={findError('preferred_language')}
          />
          {findError('preferred_language') && (
            <ErrorMessage message={t(findError('preferred_language'))} />
          )}
        </div>
      )}
      <VaccineProgramInput
        columnWidth={columnWidth}
        setFieldValue={setFieldValue}
        t={t}
        values={values}
      />
      {Object.keys(testGroup.federal_surveyjs).length > 0 && (
        <Row className="mt-3 mb-2">
          <Col md={`${columnWidth}`}>
            <CustomSurvey
              showHeader={false}
              className="survey-padding"
              customSurvey={testGroup.federal_surveyjs}
              valuePath="federal_questionnaire"
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const ValueRow = ({ label, value }) => (
  <Row className="mb-2">
    <Col md={4} xs={12} className="text-muted">
      {label}
    </Col>
    <Col md={8} xs={12}>
      <span className="fw-bold">{value}</span>
    </Col>
  </Row>
);

export const PersonalInformationSummary = ({ values }) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  const showField = (field) =>
    testGroup.fields_requested.includes(field) ||
    testGroup.required_attributes[field];

  const fieldValue = (field) => {
    let value = null;
    if (field === 'race') {
      value = values.races.map((r) => {
        const race = r.split(".").slice(-1)[0];
        return t(`ethnicity.${race}`);
      }).join(', ');
    } else if (field === 'ethnicity') {
      if (!Object.values(values.ethnicity_blob).every((v) => v === '0')) {
        value = Object.keys(values.ethnicity_blob)
          .filter((ethnicityKey) => values.ethnicity_blob[ethnicityKey] !== '0')
          .map((ethnicityKey) => {
            const key = ethnicityKey;
            return ['hispanic', 'not_hispanic', 'unknown'].includes(key)
              ? key
              : `hispanic_expanded.${key.split('.')[1]}`;
          })
          .map((ethnicity) => t(`hispanic_questions.${ethnicity}`))
          .join(', ');
      }
    } else if (field === 'sex_at_birth' && values.sex_at_birth) {
      value = t(`sex_at_birth.${values.sex_at_birth}`);
    } else if (field === 'gender_identity' && values.gender_string) {
      value = t(`gender_key.${values.gender_string}`);
    } else if (field === 'arab_ethnicity' && values.arab_ethnicity) {
      value = t(`arab_ethnicity.${values.arab_ethnicity}`);
    } else if (field === 'sexual_orientation' && values.sexual_orientation) {
      value = t(`sexual_orientation.${values.sexual_orientation}`);
    } else if (field === 'pronoun' && values.pronoun) {
      value = t(`pronouns.${values.pronoun}`);
    } else if (field === 'language_preference') {
      value = testGroup.languages[values.language_preference || values.preferred_language];
    }

    return value ? (
      value
    ) : testGroup.required_attributes[field] ? (
      <b className="missing-personal-info-badge text-danger">Not selected</b>
    ) : (
      ''
    );
  };

  return (
    <Col>
      {showField('race') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'race_label') ||
            t('registration.race_ethnicity')
          }
          value={fieldValue('race')}
        />
      )}
      {showField('ethnicity') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'ethnicity_label') ||
            t('hispanic_questions.title')
          }
          value={fieldValue('ethnicity')}
        />
      )}
      {showField('arab_ethnicity') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'arab_ethnicity_label') ||
            t('arab_ethnicity.question')
          }
          value={fieldValue('arab_ethnicity')}
        />
      )}
      {showField('sex_at_birth') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'sex_at_birth_label') ||
            t('sex_at_birth.question')
          }
          value={fieldValue('sex_at_birth')}
        />
      )}
      {showField('gender_identity') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'gender_label') ||
            t('registration.gender')
          }
          value={fieldValue('gender_identity')}
        />
      )}
      {showField('sexual_orientation') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'sexual_orientation_label') ||
            t('sexual_orientation.title')
          }
          value={fieldValue('sexual_orientation')}
        />
      )}
      {showField('pronoun') && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'preferred_pronouns_label') ||
            t('pronouns.title')
          }
          value={fieldValue('pronoun')}
        />
      )}
      {(showField('language_preference') ||
        showField('preferred_language')) && (
        <ValueRow
          label={
            buildLocalizedString(testGroup, 'preferred_language_label') ||
            t('preferred_language.title')
          }
          value={fieldValue('language_preference')}
        />
      )}
    </Col>
  );
};

export default PersonalInformationFields;
