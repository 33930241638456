import React from 'react';
import { Field } from 'formik';

import { HOURS, MINUTES } from '../utils/slotting';

const TimeField = ({ name, label = '', minHour = null, minMinute = null }) => (
  <div className="input-group date">
    <span className="me-3">
      <label className="select form-label">{label} hour</label>
      <Field
        className="form-control select"
        name={`${name}_hour`}
        as="select"
        style={{ minWidth: 75 }}
      >
        <option value=""></option>
        {HOURS.map((hour, idx) => (
          <option key={idx} value={hour} disabled={minHour && hour < minHour}>
            {hour}
          </option>
        ))}
      </Field>
    </span>
    <span className="me-3">
      <label className="select form-label">Minute</label>
      <Field
        className="form-control select"
        name={`${name}_minute`}
        as="select"
        style={{ minWidth: 75 }}
      >
        <option value=""></option>
        {MINUTES.map((minuteOption, idx) => (
          <option
            key={idx}
            value={minuteOption[0]}
            disabled={minMinute && minuteOption < minMinute}
          >
            {minuteOption[1]}
          </option>
        ))}
      </Field>
    </span>
  </div>
);

export default TimeField;
