import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";

import { FamilyOrIndividualButton } from "../../../bundles/FamilyRegistration/components/IndividualOrFamily";
import { languageKey } from "./NewMessage";
import MessageFilter from "./MessageFilter";
import { MessageSummary } from "./MessagesItem";
import FabrxCheckbox from "../../Registration/primary/FabrxCheckbox";
import { MessagePreview, PreviewLanguageSelector } from "./NewMessageCustomizeMessage";
import NewMessageContext from "../contexts/NewMessageContext";
import TextField from "../../common/components/Forms/components/TextField";

export const FilterBadges = ({
  appliedFilters = {},
  availableFilterOptions,
}) => {
  const filterLabels = [];
  const filterLabel = (options, searchValue, filterName) => {
    const label = options?.find(o => o.value == searchValue)?.label || searchValue;
    return `${!!filterName ? `${filterName}: ` : ""}${label}`;
  };

  if (!!appliedFilters["organizations"]) {
    appliedFilters["organizations"].forEach(org => {
      filterLabels.push(filterLabel(availableFilterOptions["organization_filter_options"], org))
    })
  }
  if (!!appliedFilters["test_groups"]) {
    appliedFilters["test_groups"].forEach(tg => {
      filterLabels.push(filterLabel(availableFilterOptions["test_group_filter_options"], tg))
    })
  }
  if (!!appliedFilters["locations"]) {
    appliedFilters["locations"].forEach(l => {
      filterLabels.push(filterLabel(availableFilterOptions["location_filter_options"], l))
    })
  }
  if (!!appliedFilters["populations"]) {
    appliedFilters["populations"].forEach(p => {
      filterLabels.push(filterLabel(availableFilterOptions["population_filter_options"], p))
    })
  }
  if (!!appliedFilters["tags"]) {
    appliedFilters["tags"].forEach(t => {
      filterLabels.push(filterLabel(availableFilterOptions["tag_filter_options"], t))
    })
  }
  if (!!appliedFilters["appointment_status"]) {
    filterLabels.push(
      filterLabel(
        availableFilterOptions["appointment_status_filter_options"],
        appliedFilters["appointment_status"],
        "Appointment status"
      )
    )
  }
  if (!!appliedFilters["case_report_status"]) {
    filterLabels.push(
      filterLabel(
        availableFilterOptions["case_report_status_filter_options"],
        appliedFilters["case_report_status"],
        "Case report status"
      )
    )
  }
  if (!!appliedFilters["consented_to"]) {
    filterLabels.push(
      filterLabel(
        availableFilterOptions["consent_filter_options"],
        appliedFilters["consented_to"],
        "Consented to"
      )
    )
  }
  if (!!appliedFilters["appointment_date"]) {
    filterLabels.push(
      `Appointment date: ${appliedFilters["appointment_date"].map(d => d.format("YYYY-MM-DD")).join(" - ")}`
    )
  }
  if (!!appliedFilters["administered_at_date"]) {
    filterLabels.push(
      `Administered at: ${appliedFilters["administered_at_date"].map(d => d.format("YYYY-MM-DD")).join(" - ")}`
    )
  }

  return (
    <>
      {!!filterLabels.length ? (
        <div className="d-flex flex-row flex-wrap" style={{ gap: "8px 16px" }}>
          {filterLabels.map(filterLabel =>
            <span
              className="px-3 py-1 body-small-regular inter bold"
              style={{
                border: "1px solid black",
                borderRadius: "20px",
              }}
            >
              {filterLabel}
            </span>
          )}
        </div>
      ) : (
        <div className="text-break">
          {/* for old messages we simply display the filter_params */}
          {!filterLabels.length && JSON.stringify(appliedFilters)}
        </div>
      )}
    </>
  );
};

const NewMessageScheduleDelivery = ({}) => {

  const [useTotalRuns, setUseTotalRuns] = useState(false);

  const {
    currentUser,
    dispatch,
    messageOptions,
    state,
  } = useContext(NewMessageContext);

  const deliveredVia = !!state.message_settings.sms_message
    ? "Email & text"
    : "Email";

  return (
    <Row>
      <Col
        style={{ backgroundColor: "#F7F9FC" }}
        xs={6} xl={7}
      >
        <div className="px-5">
          <h2 className="mt-5 mb-3">{state.message_settings.email_subject}</h2>
          <MessageSummary
            deliveredVia={deliveredVia}
            justifyConten="justify-content-start"
            recipientCount={state.recipient_count}
            startedBy={currentUser.full_name}
            startedByInitials={currentUser.initials}
          />
          <div className="d-flex my-3">
            <span className="me-2">Send to</span>
            <FilterBadges
              appliedFilters={state.filter_params}
              availableFilterOptions={state.filter_options}
            />
          </div>
          <PreviewLanguageSelector
            dispatch={dispatch}
            languageOptions={messageOptions.language_options}
            messageLanguages={state.message_settings.languages}
            selectedLanguage={state.selected_language}
          />
          <MessagePreview
            emailMessage={state.message_settings[languageKey("email_message", state.selected_language)]}
            emailSubject={state.message_settings[languageKey("email_subject", state.selected_language)]}
            senderOrganizationName={state.sender_organization_name}
            smsMessage={state.message_settings[languageKey("sms_message", state.selected_language)]}
          />
        </div>
      </Col>
      <Col
        className="px-5 pt-5"
        xs={6} xl={5}
      >
        <div className="mb-6">
          <h3>Schedule delivery</h3>
          <p className="body-medium-bold inter semibold text-muted">Schedule when you would like your message to go out</p>
        </div>

        <Row className="my-3">
          <Col xs={12} lg={6}>
            <FamilyOrIndividualButton
              active={state.deliver_now}
              className="my-2"
              // icon={null} TODO
              label={'Send now'}
              onClick={() =>
                dispatch({
                  type: 'setDeliverNow',
                  values: true,
                })
              }
            />
          </Col>
          <Col xs={12} lg={6}>
            <FamilyOrIndividualButton
              active={state.deliver_now === false}
              className="my-2"
              // icon={null} TODO
              label={'Send later'}
              onClick={() =>
                dispatch({
                  type: 'setDeliverNow',
                  values: false,
                })
              }
            />
          </Col>
        </Row>

        {state.deliver_now === false && (
          <>
            <Row>
              <Col
                className="d-flex flex-column justify-content-end"
                xs={7}
              >
                <h6 className="body-medium-bold inter semibold">
                  Date
                </h6>
                <DatePicker
                  containerStyle={{ width: "100%" }}
                  disableYearPicker
                  format="ddd, MMMM D"
                  inputClass="form-control"
                  minDate={new Date()}
                  onChange={(objectDate) =>
                    dispatch({ type: "setDate", values: objectDate })
                  }
                  shadow={false}
                  value={state.message_settings.date}
                />
              </Col>
              <Col xs={5}>
                <MessageFilter
                  dispatch={dispatch}
                  filterName="Time"
                  options={messageOptions.time_options}
                  selectedOptions={state.message_settings.time}
                  type="setTime"
                />
              </Col>
            </Row>

            <MessageFilter
              dispatch={dispatch}
              filterName="Time zone"
              options={messageOptions.time_zone_options}
              selectedOptions={state.message_settings.time_zone}
              type="setTimeZone"
            />

            <MessageFilter
              dispatch={dispatch}
              filterName="Repeating"
              options={messageOptions.repeating_options}
              selectedOptions={state.message_settings.repeating}
              type="setRepeating"
            />

            {!!state.message_settings.repeating && (
              <div className="my-4">
                <h6 className="body-medium-bold inter semibold">End</h6>
                <Row className="my-2">
                  <Col xs={5} sm={4} md={3} lg={2}>
                    <FabrxCheckbox
                      checked={!useTotalRuns}
                      className="mt-2"
                      label="On"
                      onChange={() => {
                        setUseTotalRuns(false)
                        if (useTotalRuns) {
                          dispatch({
                            type: "setTotalRuns",
                            values: null
                          })
                        }
                      }}
                      radio
                    />
                  </Col>
                  <Col xs={7} sm={8} md={9} lg={10}>
                    <DatePicker
                      containerStyle={{ width: "100%" }}
                      disableYearPicker
                      format="ddd, MMMM D"
                      inputClass="form-control"
                      minDate={new Date()}
                      onChange={(objectDate) =>{
                        setUseTotalRuns(false);
                        dispatch({
                          type: "setEndDate",
                          values: objectDate
                        })
                      }}
                      shadow={false}
                      value={state.message_settings.end_date}
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col xs={5} sm={4} md={3} lg={2}>
                    <FabrxCheckbox
                      checked={useTotalRuns}
                      className="mt-2"
                      label="After"
                      onChange={() => {
                        setUseTotalRuns(true)
                        if (!useTotalRuns) {
                          dispatch({
                            type: "setEndDate",
                            values: null,
                          })
                        }
                      }}
                      radio
                    />
                  </Col>
                  <Col xs={7} sm={8} md={9} lg={10}>
                    <TextField
                      placeholder="Occurrences"
                      value={state.message_settings.total_runs}
                      setValue={(e) => {
                        setUseTotalRuns(true);
                        dispatch({
                          type: "setTotalRuns",
                          values: e.target.value,
                        })
                      }}
                      autoComplete="off"
                      required
                    />
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default NewMessageScheduleDelivery;