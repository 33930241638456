import React, { useEffect, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { Button, Form } from 'react-bootstrap';
import Select from '../../common/components/Select';
import { SELECT_TIME_OPTIONS } from '../../common/utils/slotting';
import PopOverWindow from '../../common/components/PopOverWindow';
import YesNoCheckbox from '../../common/components/YesNoCheckbox';
import styled from 'styled-components';
import { weekDays } from '../../common/components/MultiDatePickerField';

const repeatOptions = [
  { value: 'no_repeat', label: 'Does not repeat' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'custom', label: 'Custom...' },
];

const timesOptions = (repeatOption) => {
  const maxTimes =
    repeatOption === 'daily' ? 365 : repeatOption === 'weekly' ? 52 : 12;

  return Array(maxTimes)
    .fill()
    .map((_, idx) => ({
      value: idx + 1,
      label: `${idx + 1} ${idx === 0 ? 'ocurrence' : 'ocurrences'}`,
    }));
};

const HiddenInputs = ({
  date,
  time,
  timezone,
  repeatOption,
  endDateRadioSelected,
  endDate,
  ocurrencesCount,
  customDaysInterval,
}) => {
  const repeating = repeatOption !== 'no_repeat';
  const timeOptionLabel = SELECT_TIME_OPTIONS.find(
    (opt) => opt.value === time,
  ).label;

  return (
    <React.Fragment>
      <input
        type="hidden"
        name="communication[repeating]"
        id="repeating"
        value={repeating}
      />
      {repeating && (
        <input
          type="hidden"
          name="communication[repeating_interval]"
          id="repeating_interval"
          value={repeatOption}
        />
      )}
      {time && (
        <input
          type="hidden"
          name="communication[start_date]"
          id="start_date"
          value={date.format('YYYY/MM/DD')}
        />
      )}
      {time && (
        <React.Fragment>
          <input
            type="hidden"
            name="communication[start_hour]"
            id="start_hour"
            value={time.hour}
          />
          <input
            type="hidden"
            name="communication[start_minute]"
            id="start_minute"
            value={time.minute}
          />
        </React.Fragment>
      )}
      <input
        type="hidden"
        name="communication[time_zone]"
        id="time_zone"
        value={timezone}
      />
      {repeatOption === 'weekly' && (
        <input
          type="hidden"
          name="communication[day_of_week]"
          id="day_of_week"
          value={date.weekDay.name.toLowerCase()}
        />
      )}
      {repeatOption === 'monthly' && (
        <input
          type="hidden"
          name="communication[day_of_month]"
          id="day_of_month"
          value={date.day}
        />
      )}
      {repeatOption === 'custom' && (
        <input
          type="hidden"
          name="communication[custom_days_interval]"
          id="custom_days_interval"
          value={customDaysInterval}
        />
      )}
      {repeating &&
        (endDateRadioSelected ? (
          <input
            type="hidden"
            name="communication[end_date]"
            id="end_date"
            value={`${endDate.format(
              'YYYY/MM/DD',
            )} ${timeOptionLabel} ${timezone}`}
          />
        ) : (
          <input
            type="hidden"
            name="communication[total_runs]"
            id="total_runs"
            value={ocurrencesCount}
          />
        ))}
    </React.Fragment>
  );
};

const ScheduleInput = ({ onClick, scheduled, summary }) => (
  <fieldset className="mb-2 boolean optional communication_scheduled">
    <label className="action-label mt-2">
      Schedule repeating messages to participants
    </label>
    {scheduled && <div className="form-text mb-1">{summary}</div>}
    <YesNoCheckbox
      name="communication[scheduled]"
      yesValue="1"
      noValue="0"
      value={scheduled ? '1' : '0'}
      onChange={onClick}
    />
  </fieldset>
);

const SchedulePopOver = ({
  show,
  onHide,
  timezones,
  onSave,
  date,
  setDate,
  time,
  setTime,
  timezone,
  setTimezone,
  repeatOption,
  setRepeatOption,
  endDateRadioSelected,
  setEndDateRadioSelected,
  endDate,
  setEndDate,
  ocurrencesCount,
  setOcurrencesCount,
  customDaysInterval,
  setCustomDaysInterval,
}) => {
  const displayEndOption = repeatOption !== 'no_repeat';
  const timezoneOptions = timezones.map((tz) => ({ value: tz, label: tz }));

  useEffect(() => {
    if (displayEndOption) {
      const options = timesOptions(repeatOption);
      if (!options.find((opt) => opt.value === ocurrencesCount))
        setOcurrencesCount(options[options.length - 1].value);
    }
  }, [repeatOption]);

  return (
    <PopOverWindow
      open={show}
      onHide={onHide}
      position="left"
      style={{
        top: displayEndOption ? -310 : -230,
        left: 25,
        padding: '12px 6px',
        borderRadius: 16,
      }}
    >
      <h5 className="mb-2">Set up schedule</h5>
      <fieldset className="mb-3">
        <div className="d-flex flex-row" style={{ gap: 12 }}>
          <div className="d-flex flex-column">
            <label className="uppercase-label">Date</label>
            <CalendarInputContainer>
              <DatePicker
                className="rmdp-primary"
                weekDays={weekDays}
                minDate={new DateObject()}
                value={date}
                onChange={setDate}
                format="ddd, MMM D"
                inputClass="form-control date-picker"
                containerStyle={{ flex: '1 0 3' }}
              />
            </CalendarInputContainer>
          </div>
          <div className="d-flex flex-column" style={{ width: '45%' }}>
            <label className="uppercase-label">Time</label>
            <Select
              value={time}
              options={SELECT_TIME_OPTIONS}
              onChange={setTime}
              valueKey="value"
              labelKey="label"
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="mb-3">
        <label className="uppercase-label">Time zone</label>
        <Select
          value={timezone}
          options={timezoneOptions}
          onChange={setTimezone}
          valueKey="value"
          labelKey="label"
        />
      </fieldset>
      <fieldset className="mb-3">
        <label className="uppercase-label">Repeating</label>
        <Select
          value={repeatOption}
          options={repeatOptions}
          onChange={setRepeatOption}
          valueKey="value"
          labelKey="label"
        />
        {repeatOption === 'custom' && (
          <div className="d-flex align-items-center mt-2">
            <span className="flex-shrink-0">Repeat every </span>
            <Form.Control
              className="px-2 mx-2"
              type="number"
              placeholder="Days"
              style={{ maxWidth: 80 }}
              value={customDaysInterval}
              onChange={(e) => setCustomDaysInterval(e.target.value)}
              min={1}
            />
            <span>days</span>
          </div>
        )}
      </fieldset>
      {displayEndOption && (
        <fieldset className="mb-3">
          <label className="uppercase-label">Ends</label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 3fr',
              gap: 12,
              alignItems: 'center',
            }}
          >
            <RadioFieldSet>
              <label htmlFor="onRadioInput">
                <input
                  id="onRadioInput"
                  type="radio"
                  name="endOption"
                  checked={endDateRadioSelected}
                  onChange={() => setEndDateRadioSelected(true)}
                />
                <span>On</span>
              </label>
            </RadioFieldSet>
            <CalendarInputContainer>
              <DatePicker
                className="rmdp-primary"
                weekDays={weekDays}
                minDate={new DateObject()}
                maxDate={new DateObject().add(1, 'year')}
                value={endDate}
                onChange={setEndDate}
                format="ddd, MMMM D"
                inputClass="form-control date-picker"
                containerStyle={{ flex: '1 0 3' }}
                disabled={!endDateRadioSelected}
              />
            </CalendarInputContainer>
            {repeatOption !== 'custom' && (
              <React.Fragment>
                <RadioFieldSet>
                  <label htmlFor="afterRadioInput">
                    <input
                      id="afterRadioInput"
                      type="radio"
                      label="After"
                      name="endOption"
                      checked={!endDateRadioSelected}
                      onChange={() => setEndDateRadioSelected(false)}
                    />
                    <span>After</span>
                  </label>
                </RadioFieldSet>
                <Select
                  value={ocurrencesCount}
                  options={timesOptions(repeatOption)}
                  onChange={setOcurrencesCount}
                  valueKey="value"
                  labelKey="label"
                  isDisabled={endDateRadioSelected}
                />
              </React.Fragment>
            )}
          </div>
        </fieldset>
      )}

      <Button
        className="w-100"
        variant="primary"
        type="submit"
        onClick={onSave}
        disabled={!time || (repeatOption === 'custom' && !customDaysInterval)}
      >
        Save
      </Button>
    </PopOverWindow>
  );
};

const Summary = ({
  scheduled,
  date,
  time,
  timezone,
  repeatOption,
  onClick,
  endDateRadioSelected,
  endDate,
  ocurrencesCount,
  customDaysInterval,
}) => {
  const repeating = repeatOption !== 'no_repeat';
  const repeatOpt = repeatOptions.find((opt) => opt.value === repeatOption);
  const timeOpt = SELECT_TIME_OPTIONS.find((opt) => opt.value === time);

  return scheduled ? (
    <React.Fragment>
      {repeating
        ? `Repeats ${
            repeatOption === 'custom'
              ? `every ${customDaysInterval} days`
              : repeatOpt.label
          } starting`
        : 'Scheduled for'}{' '}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {date.format('dddd, MMMM D')} at {timeOpt.label} {timezone}
      </a>
      .{' '}
      {repeating && (
        <span>
          Ends{' '}
          {endDateRadioSelected
            ? `on ${endDate.format('dddd, MMMM D')}`
            : `after ${ocurrencesCount} ocurrences`}
          .
        </span>
      )}
    </React.Fragment>
  ) : (
    'No repeating schedule setup'
  );
};

const CalendarInputContainer = styled.div`
  position: relative;

  &:after {
    position: absolute;
    content: url('/images/icons/icon-calendar.svg');
    right: 10px;
    bottom: 12px;
  }
`;

const RadioFieldSet = styled.fieldset`
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;

    input[type='radio'] {
      width: 24px;
      height: 24px;
      accent-color: var(--bs-secondary);
      position: absolute;
      opacity: 0;

      &:checked + span {
        &:before {
          content: url('data:image/svg+xml,%3Csvg viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.66234 9.92857L0 6.26623L1.09091 5.17532L3.66234 7.74675L10.9091 0.5L12 1.59091L3.66234 9.92857Z" fill="white"/%3E%3C/svg%3E');
          box-shadow: inset 0 0 0 1em var(--bs-secondary);
        }
      }
    }

    span {
      display: flex;
      align-items: center;

      &:before {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 6px;
        margin-right: 0.65rem;
        flex-shrink: 0;
        content: '';
        background-color: #fff;
        border-radius: 50%;
        transition: 0.15s ease;
        box-shadow: inset 0 0 0 2px #b9b9b9;
        font-size: 12px;
      }

      &:hover:before {
        opacity: 0.9;
      }
    }
  }
`;

export const Scheduler = ({ timezones }) => {
  const [scheduled, setScheduled] = useState(false);
  const [open, setOpen] = useState(false);

  const [date, setDate] = useState(new DateObject());
  const [time, setTime] = useState(null);
  const [timezone, setTimezone] = useState('Pacific Time (US & Canada)');
  const [repeatOption, setRepeatOption] = useState('no_repeat');
  const [endDateRadioSelected, setEndDateRadioSelected] = useState(true);
  const [endDate, setEndDate] = useState(new DateObject().add(1, 'month'));
  const [ocurrencesCount, setOcurrencesCount] = useState(1);
  const [customDaysInterval, setCustomDaysInterval] = useState(1);

  const inputOnClick = (value) => {
    if (value === '0') {
      setScheduled(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      {scheduled && (
        <HiddenInputs
          date={date}
          time={time}
          timezone={timezone}
          repeatOption={repeatOption}
          endDateRadioSelected={endDateRadioSelected}
          endDate={endDate}
          ocurrencesCount={ocurrencesCount}
          customDaysInterval={customDaysInterval}
        />
      )}
      <div className="position-relative ms-auto mb-2 mt-2 mt-md-0">
        <ScheduleInput
          onClick={inputOnClick}
          scheduled={scheduled}
          summary={
            <Summary
              scheduled={scheduled}
              date={date}
              time={time}
              timezone={timezone}
              repeatOption={repeatOption}
              endDateRadioSelected={endDateRadioSelected}
              endDate={endDate}
              ocurrencesCount={ocurrencesCount}
              customDaysInterval={customDaysInterval}
              onClick={() => setOpen(true)}
            />
          }
        />
        <SchedulePopOver
          show={open}
          onHide={() => setOpen(false)}
          timezones={timezones}
          onSave={() => {
            setScheduled(true);
            setOpen(false);
          }}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
          timezone={timezone}
          setTimezone={setTimezone}
          repeatOption={repeatOption}
          setRepeatOption={setRepeatOption}
          endDateRadioSelected={endDateRadioSelected}
          setEndDateRadioSelected={setEndDateRadioSelected}
          endDate={endDate}
          setEndDate={setEndDate}
          ocurrencesCount={ocurrencesCount}
          setOcurrencesCount={setOcurrencesCount}
          customDaysInterval={customDaysInterval}
          setCustomDaysInterval={setCustomDaysInterval}
        />
      </div>
    </React.Fragment>
  );
};
