import React, { useMemo, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sumProperty } from '../../common/utils';
import {
  createDateRanges,
  formatDateRanges,
  formatTime,
} from '../../common/utils/slotting';
import { faCheck, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

const TableHeaders = () => {
  return (
    <thead>
      <tr>
        <th scope="col">Test location</th>
        <th scope="col">Days</th>
        <th scope="col">Hours</th>
        <th scope="col">Daily standard capacity</th>
        <th scope="col">Daily access key capacity</th>
        <th colSpan="1"></th>
      </tr>
    </thead>
  );
};

const AppointmentSlotRow = ({
  values: {
    start_time_hour,
    start_time_minute,
    end_time_hour,
    end_time_minute,
    capacity,
    access_key_capacity,
  },
  index,
  expanded,
}) => {
  const [editing, setEditing] = useState(false);
  const toggleEditing = (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  return (
    <tr className={expanded ? 'hoverable' : 'd-none'}>
      <td colSpan="2"></td>
      <td>
        {`${formatTime({
          hour: start_time_hour,
          min: start_time_minute,
        })} - ${formatTime({
          hour: end_time_hour,
          min: end_time_minute,
        })}`}
      </td>
      {editing ? (
        <React.Fragment>
          <td>
            <Field
              className="form-control"
              name={`slot_attrs[${index}].capacity`}
              type="number"
            />
          </td>
          <td>
            <Field
              className="form-control"
              name={`slot_attrs[${index}].access_key_capacity`}
              type="number"
            />
          </td>
          <td>
            <a onClick={toggleEditing} href="#">
              <FontAwesomeIcon icon={faCheck} /> Done
            </a>
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>{capacity}</td>
          <td>{access_key_capacity}</td>
          <td>
            <a className="display-on-hover" onClick={toggleEditing} href="#">
              <FontAwesomeIcon icon={faPenToSquare} /> Edit
            </a>
          </td>
        </React.Fragment>
      )}
    </tr>
  );
};

const SlotsGroup = ({ formValues: slots, dateRanges, test_location }) => {
  const [expanded, setExpanded] = useState(false);

  const details = useMemo(() => {
    const firstSlot = slots[0];
    const lastSlot = slots[slots.length - 1];

    return {
      hours: `${formatTime({
        hour: firstSlot.start_time_hour,
        min: firstSlot.start_time_minute,
      })} - ${formatTime({
        hour: lastSlot.end_time_hour,
        min: lastSlot.end_time_minute,
      })}`,
      capacity: sumProperty(slots, 'capacity'),
      access_key_capacity: sumProperty(slots, 'access_key_capacity'),
    };
  }, [slots]);

  return (
    <React.Fragment>
      <tr>
        <td>{test_location}</td>
        <td className="w-25">{dateRanges}</td>
        <td className="column">
          <div>{details['hours']}</div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setExpanded(!expanded);
            }}
          >
            {expanded ? 'Hide' : 'See/edit'} slots
          </a>
        </td>
        <td>{details['capacity']}</td>
        <td>{details['access_key_capacity']}</td>
        <td></td>
      </tr>
      {slots.map((attrs, idx) => (
        <AppointmentSlotRow
          key={idx}
          values={attrs}
          index={idx}
          expanded={expanded}
        />
      ))}
    </React.Fragment>
  );
};

const StagedSlots = ({
  dates,
  slotConfigurations: { slot_attrs, ...slotGroupProps },
  onSubmit,
  processingSlots,
}) => {
  const dateRanges = useMemo(() => {
    return formatDateRanges(createDateRanges(dates));
  }, [dates]);

  return (
    <div>
      <h5 className="mt-3">
        These are the appointment slots you are about to create. Please review
        and edit as necessary before saving.
      </h5>
      <Formik
        initialValues={{
          dates,
          slot_attrs,
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => (
          <React.Fragment>
            <Form onSubmit={handleSubmit}>
              <section className="fabrx-tables-light table-responsive">
                <table className="table table-lg mt-3">
                  <TableHeaders />
                  <tbody>
                    <SlotsGroup
                      formValues={values.slot_attrs}
                      dateRanges={dateRanges}
                      {...slotGroupProps}
                    />
                  </tbody>
                </table>
              </section>
              <div className="d-flex justify-content-end">
                {processingSlots ? (
                  <button className="btn btn-primary" type="submit" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating...
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit">
                    Create
                  </button>
                )}
              </div>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};

export default StagedSlots;
