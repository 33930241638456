import React, { useContext } from "react";

import Switch from "../../common/components/Switch";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const OtcTestGroupTestConfigurationInputFields = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  return (
    <>
      <div className="py-2"><hr/></div>
      <h5 className="my-4">Self-reporting requirements</h5>
      <p>When turned on, participants will be required to include the information with their submission.</p>
      <div className="my-4">
        <Switch
          checked={!!state.testGroupTestConfiguration.require_otc_photo}
          containerClassName="my-3"
          id="require_otc_photo"
          label="Require result photo"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                require_otc_photo: !state.testGroupTestConfiguration.require_otc_photo
              }
            });
          }}
          subLabel="By default a photo is requested. When turned on, participants will be required to provide a result photo."
        />
        <Switch
          checked={!!state.testGroupTestConfiguration.require_otc_time}
          containerClassName="my-3"
          id="require_otc_time"
          label="Require administration time"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                require_otc_time: !state.testGroupTestConfiguration.require_otc_time
              }
            });
          }}
          subLabel="By default administration time is requested. When turned on, participants will be required to record when the test was administered."
        />
      </div>
    </>
  );
};

export default OtcTestGroupTestConfigurationInputFields;