import React from 'react';
import { Col, Row } from 'react-bootstrap';
import HelpText from '../../common/components/HelpText';

export const SectionTitle = ({ children, className="" }) => {
  return (
    <h3 className={`poppins ls-small semibold mb-3 ${className}`}>{children}</h3>
  );
};

export const PersonalFieldDropdown = ({children, helpText}) => {
  return (
    <Row className='align-items-center' style={{"--bs-gutter-x": "12px"}}>
      <Col xs={11}>
       {children}
      </Col>
      { helpText && <Col xs={1}>
        <HelpText
          text={helpText}
          size="md"
        /> 
      </Col>}
    </Row>
  )
}
