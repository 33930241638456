import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useField } from 'formik';

const MultiSelectField = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <Select
      defaultValue={value}
      isMulti
      onChange={(array) => setValue(array && array.map((opt) => opt.value))}
      {...props}
    />
  );
};

export default MultiSelectField;
