import React, { useState } from 'react';
import { Card, Tabs, Tab, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { vaccineCode, manufacturerCode } from '../../common/utils/vaccine';
import { ChevronDropDown } from '../../common/components/Chevron';
import CovidWarnings from './CovidWarnings';
import ImmunizationTable from './ImmunizationTable';
import ImmunizationCheck from './ImmunizationCheck';

const uniqueKey = ({ vaccinated_on, vaccine, manufacturer }) => {
  return [
    vaccinated_on,
    vaccineCode(vaccine),
    manufacturerCode(manufacturer),
  ].join('|');
};

export const deduplicateHistories = (histories) => {
  const missing_manufacturers = histories.filter(history => history.manufacturer === null);
  const deduplicated = histories.reduce((uniqueHistories, history) => {
    const key = uniqueKey(history);
    if (uniqueHistories[key]) {
      const uniqueHistory = uniqueHistories[key];
      if (uniqueHistory.source == 'primary') {
        uniqueHistories[key] = history; // Give preference to non-Primary record
      }
      if (uniqueHistories[key].source && !uniqueHistories[key].source.includes(history.source)) {
        uniqueHistories[key].source += `, ${history.source}`;
      }
    } else {
      const missing_manufacturer = missing_manufacturers.find(missing => key.includes(uniqueKey(missing)) && key != uniqueKey(missing));

      if (missing_manufacturer && !missing_manufacturer.source.includes(history.source)) {
        uniqueHistories[key] = missing_manufacturer;
        uniqueHistories[key].source += `, ${history.source}`;
        var matchWarning = `Warning: Reduced confidence with ${history.source} immunization match. IIS missing manufacturer.`;
        if (uniqueHistories[key].notes) {
          uniqueHistories[key].notes += matchWarning;
        } else {
          uniqueHistories[key].notes = matchWarning;
        }
      } else if (!missing_manufacturer) {
        uniqueHistories[key] = history;
      }
    }
    return uniqueHistories;
  }, {});

  return Object.values(deduplicated);
};

const ImmunizationHistory = (props) => {
  const {
    testGroupId,
    user,
    tests,
    isHipaaTrained = false,
    histories: propHistories,
    iisReferences: propIISReferences,
  } = props;

  const updateImmunizations = ({ vaccineHistories, iisReferences }) => {
    setHistories(vaccineHistories);
    setIISReferences(iisReferences);
  };

  const [histories, setHistories] = useState(propHistories);
  const [iisReferences, setIISReferences] = useState(propIISReferences);
  const deduplicatedHistories = deduplicateHistories(histories);
  const [hasWarning, setHasWarning] = useState(false);
  const [show, setShow] = useState(true);

  return (
    <div>
      <ChevronDropDown open={show} setOpen={setShow} className="h5-vivid-blue" btnClass="p-0" />
      <h5 className="my-4">
        Immunization History
        {hasWarning ? (
          <FontAwesomeIcon icon={faCircleExclamation} className="mx-2 text-danger" />
        ) : (
          <FontAwesomeIcon icon={faCircleCheck} className="mx-2" color="var(--bs-success)"/>
        )}
      </h5>
      <Collapse in={show} className="my-4">
        <div>
          <ImmunizationCheck
            iisReferences={iisReferences}
            onCheck={updateImmunizations}
            testGroupId={testGroupId}
            user={user}
          />
          <Tabs className="my-3">
            <Tab eventKey="covid" title="Brief History">
              <CovidWarnings
                histories={deduplicatedHistories}
                tests={tests}
                iisReferences={iisReferences}
                setHasWarning={setHasWarning}
                className="my-3"
              />
            </Tab>
            <Tab eventKey="all" title="Detailed History">
              {isHipaaTrained ? (
                <ImmunizationTable histories={deduplicatedHistories} />
              ) : (
                <Card body bg="warning">
                  You do not have access to the patient’s full history. Please
                  contact your site lead for detailed immunization history.
                </Card>
              )}
            </Tab>
          </Tabs>
        </div>
      </Collapse>
    </div>
  );
};

export default ImmunizationHistory;
