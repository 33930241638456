import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import TestGroupUserCard from './TestGroupUserCard';
import { LocationContextProvider } from '../../LocationSelector/components/LocationContext';
import AssignAppointmentSlotGroupModal from './AssignAppointmentSlotGroupModal';

const TestGroupUsers = ({
  is_global_search,
  on_demand_only,
  test_group_users,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [testGroupUserForModal, setTestGroupUserForModal] = useState({});
  const [customUrl, setCustomUrl] = useState('');

  const openModal = (href) => {
    setCustomUrl(href);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCustomUrl('');
    setModalOpen(false);
  };

  return (
    <LocationContextProvider>
      <section className="fabrx-tables-light">
        <Table
          responsive
          data-test-hook="test_group_users_table"
          className="table-lg"
        >
          <thead>
            <tr>
              <th>Name</th>
              {is_global_search && <th>Test group</th>}
              <th>Birth date</th>
              <th className="d-none d-xl-table-cell">Contact info</th>
              <th className="d-none d-xl-table-cell">Population/tags</th>
              <th colSpan="8"></th>
            </tr>
          </thead>
          <tbody>
            {test_group_users.map((test_group_user) => (
              <tr
                key={test_group_user.id}
                className="test_group_user__page"
                data-test-hook={`test_group_user_${test_group_user.id}`}
              >
                <TestGroupUserCard
                  test_group_user={test_group_user}
                  key={test_group_user.id}
                  is_global_search={is_global_search}
                  on_demand_only={on_demand_only}
                  assignAppointmentSlotGroup={(href) => {
                    setTestGroupUserForModal(test_group_user);
                    openModal(href);
                  }}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
      {modalOpen && (
        <AssignAppointmentSlotGroupModal
          show={modalOpen}
          onHide={closeModal}
          customUrl={customUrl}
          testGroupUser={testGroupUserForModal}
        />
      )}
    </LocationContextProvider>
  );
};

export default TestGroupUsers;
