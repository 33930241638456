import {
  bn,
  es,
  fr,
  hi,
  hy,
  ja,
  ko,
  ru,
  vi,
} from 'date-fns/locale';
// if needed, additional languages can be imported from: https://github.com/date-fns/date-fns
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next';

const LOCALES = {
  bn,
  es,
  fr,
  hi,
  hy,
  ja,
  ko,
  ru,
  vi,
};

export const formatAndLocalizeDateTime = (date, formatStr = 'PP', language) => {
  let formattedAndLocalizedDateTime;
  try {
    formattedAndLocalizedDateTime = format(date, formatStr, {
      locale: LOCALES[language || useTranslation()["i18n"].language]
    });
  } catch {
    formattedAndLocalizedDateTime = null;
  }
  return formattedAndLocalizedDateTime;
};
