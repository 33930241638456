import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns-tz';
import { Printer } from '../../common/components/Icons/Printer';
import { Header } from './TestStripDisplay';

const VaccineDetails = ({ vaccine, setSelectedVaccine }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t, i18n } = useTranslation();
  const vaccineInfoSheet =
    vaccine.vaccine_result.vaccine_route.vaccine_info_sheets_props
      ?.vaccine_info_sheet_versions[0];

  const printDetails = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <Button
        variant="link"
        className="kit-flow header-btn"
        style={{ padding: '24px 0 0 0' }}
        onClick={() => setSelectedVaccine(null)}
      >
        {t('otc.back_button')}
      </Button>
      <div
        style={
          isMobile
            ? { margin: '24px 0px' }
            : {
                boxShadow:
                  '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
                borderRadius: 16,
                padding: '24px',
                margin: '24px 0px',
              }
        }
      >
        <Row>
          <Col>
            <div className="py-2">
              <h4 className="mb-0">
                {vaccine.test_configuration.display_name}
              </h4>
            </div>
          </Col>
          <Col className="text-end" xs="auto">
            <Button
              className="btn-icon btn-transparent"
              aria-label="Print test details"
              onClick={() => printDetails()}
            >
              <Printer />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="inter medium">
              {t(
                `date.${
                  vaccine.administered_at_month &&
                  vaccine.administered_at_month.toLowerCase()
                }`,
              )}{' '}
              {vaccine.administered_at_day}, {vaccine.administered_at_year}{' '}
              {vaccine.administered_at_hour}:{vaccine.administered_at_minute}{' '}
              {vaccine.administered_at_am_pm}
            </div>
            <div className="text-secondary">{vaccine.location_name}</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-secondary ms-2" xs="auto">
            <div className="pt-2">Administrator</div>
            <div className="pt-2">Vaccine name</div>
            <div className="pt-2">Vaccine dose</div>
            <div className="pt-2">Lot number</div>
            {vaccineInfoSheet?.vaccine_info_sheet_version_file_url && (
              <div className="pt-2">Vaccine information statement</div>
            )}
          </Col>
          <Col>
            <div className="pt-2">
              {vaccine.vaccine_result.administrator_name}
            </div>
            <div className="pt-2">
              {
                vaccine.vaccine_result.vaccine_route
                  .trade_name_with_manufacturer
              }
            </div>
            <div className="pt-2">{vaccine.vaccine_result.dose} mL</div>
            <div className="pt-2">{vaccine.vaccine_result.lot_number}</div>
            {vaccineInfoSheet?.vaccine_info_sheet_version_file_url && (
              <div className="pt-2">
                <a
                  target="_blank"
                  href={vaccineInfoSheet.vaccine_info_sheet_version_file_url}
                  variant="primary"
                >
                  Version{' '}
                  {format(
                    new Date(
                      vaccineInfoSheet.vaccine_info_sheet_version.version_date,
                    ),
                    'mm-dd-yyyy',
                  )}
                </a>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const VaccineDisplay = ({
  vaccine,
  selectedVaccine,
  setSelectedVaccine,
}) => {
  return (
    <React.Fragment>
      {!selectedVaccine && (
        <div
          className="medical_history__item test-card my-3"
          onClick={() => setSelectedVaccine(vaccine)}
          key={vaccine.id}
        >
          <Header
            displayName={vaccine.test_configuration.display_name}
            icon={vaccine.test_configuration.font_awesome_icon}
            color={vaccine.test_configuration.color_code}
            shortDisplayTime={vaccine.short_display_time}
            displayTime={vaccine.display_time}
            key={'o' + vaccine.id}
          />
        </div>
      )}
      {selectedVaccine && selectedVaccine.id == vaccine.id && (
        <VaccineDetails
          key={'d' + selectedVaccine.id}
          vaccine={selectedVaccine}
          setSelectedVaccine={setSelectedVaccine}
        />
      )}
    </React.Fragment>
  );
};

export default VaccineDisplay;
