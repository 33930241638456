import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from './Select';

const AsyncSelect = ({
  name,
  selected: selectedProp,
  optionsUrl,
  placeholder = '',
  onChange: onChangeProp = () => {},
  style = {},
  ...props
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selected, setSelected] = useState(selectedProp);
  const [loading, setLoading] = useState(false);

  const onChange = (value) => {
    setSelected(value);
    onChangeProp(value);
  };

  useEffect(() => {
    setLoading(true);
    axios.get(optionsUrl).then((response) => {
      setLoading(false);
      const options = typeof(response.data.options) == "string"
        ? JSON.parse(response.data.options)
        : response.data.options
      setSelectOptions(options);
    });
  }, []);

  return (
    <div style={{ width: '100%', ...style }}>
      {loading
        ? <Select placeholder={<FontAwesomeIcon icon="spinner" className="fa-spin" />} options={[]} />
        : <Select
          placeholder={placeholder}
          value={selected}
          options={selectOptions}
          name={name}
          onChange={onChange}
          {...props}
        />
      }
    </div>
  );
};

export default AsyncSelect;
