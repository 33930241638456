import React, { useContext } from 'react';
import LocalizedMarkdown from './LocalizedMarkdown';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';

import RegistrationContext from '../../../contexts/RegistrationContext';

const SimpleConsentModal = () => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);
  const consent = testGroup.active_consent || testGroup;

  return (
    <div className="modal fade" id="consent-modal" tabIndex="-1" role="dialog" aria-labelledby="consent-modal-label" aria-hidden="true">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="consent-modal-label">{ t('registration.consent') }</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <LocalizedMarkdown container={consent} stringKey='consent_form_text' />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">{ t('registration.done') }</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleConsentModal;
