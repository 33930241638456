import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import FloatingLabelInput from "../common/components/FloatingLabelInput";
import ModalHeader from "../common/components/ModalHeader";
import { SERVICE_SPECIFICATION_TYPES } from "../TestGroupTestConfigurations/components/TestGroupTestConfigurationsIndex";
import StatusBadge from "../common/components/StatusBadge";

const TemplateServicesModal = ({
  handleClose,
  selectedTemplate,
  show,
}) => {
  if (!selectedTemplate) return <></>;

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [filteredServices, setFilteredServices] = useState(selectedTemplate.test_group_test_configurations);

  useEffect(() => {
    let newFilteredServices = selectedTemplate.test_group_test_configurations;
    if (!!search.length) {
      newFilteredServices = newFilteredServices.filter((tgtc) => {
        return tgtc.display_name.toLowerCase().includes(search.toLowerCase());
      })
    }
    if (!!filter) {
      newFilteredServices = newFilteredServices.filter((tgtc) => {
        return tgtc.service_specification_type == filter;
      })
    }
    setFilteredServices(newFilteredServices);
  }, [filter, search]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton onHide={handleClose}>
          <Modal.Title as="h2" id="accessCodeModalLabel">
            <h2 className="poppins semibold">Services</h2>
            <p className="mb-0 font-size-16 fw-normal">
              The services below are configured for the{" "}
              <a href={`/test_groups/new_from_template?template_test_group_id=${selectedTemplate.id}`}>
                {selectedTemplate.name}
              </a>{" "}
              template.
            </p>
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <FloatingLabelInput
            className="my-2"
            label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />

          <div
            className="d-flex p-2 my-2"
            style={{ backgroundColor: "#F7F9FC", borderRadius: "5px" }}
          >
            {Object.entries(SERVICE_SPECIFICATION_TYPES).map(([key, value]) => {
              return (
                <div
                  className="me-3 py-1 px-2 pointer inter semibold"
                  onClick={() => setFilter(filter == key ? null : key)}
                  style={{
                    backgroundColor: filter == key ? "#314677" : "",
                    color: filter == key ? "white" : "black",
                    borderRadius: "5px",
                  }}
                >
                  {value.label}
                </div>
              );
            })}
          </div>

          <div
            className="my-3"
            style={{
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {filteredServices.map((tgtc) => {
              return (
                <div>
                  <div className="d-flex justify-content-between" key={tgtc.id}>
                    <div>
                      <div style={{ fontSize: "18px", color: "#2D3648" }}>{tgtc.display_name}</div>
                      <div style={{ fontSize: "14px", color: "#4A5468" }}>{tgtc.age_range_label}</div>
                    </div>
                    <div className="my-auto">
                      {!tgtc.active && (
                        <StatusBadge
                          className="my-auto me-3"
                          color="#4A5468"
                          text="Inactive"
                        />
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              )
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mx-2"
            onClick={handleClose}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateServicesModal;