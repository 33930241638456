import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  faEllipsis,
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getResultsLink } from '../../UpdateTestForm';
import { hasPermissionToResultTest, renderResultInterfaceLink, saveAndGoToResultInterface, saveTestGroupUrlToLocalStorage } from '../../UpdateTestForm';
import TooltipWrapper from '../../../../common/components/TooltipWrapper';
import Wrapped from '../../../../common/components/Wrapped';

const ActionsDropdown = ({
  editableTestTypes,
  isGroupOnDemandOnly,
  permissionKinds,
  submitTests,
  setRedirectCallbackPath,
  test,
}) => {

  const hasPermissionToResult = hasPermissionToResultTest(permissionKinds, editableTestTypes, test);
  const resultingFromLabManager = !!getResultsLink(test.test_configuration?.service_specification);

  const redirectToResultInterface = () => renderResultInterfaceLink(
    test.test_configuration?.service_specification,
    test,
    saveAndGoToResultInterface,
    submitTests,
    saveTestGroupUrlToLocalStorage,
    setRedirectCallbackPath,
    isGroupOnDemandOnly
  );

  if (!test || (!test.administered_at && !resultingFromLabManager)) return <span></span>;

  return (
    <span className="ms-3">
      <Button
        variant="outline-tertiary"
        data-test-hook="edit-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{
          height: "40px",
          width: "40px",
          minWidth: "40px",
          maxWidth: "40px",
          minHeight: "40px",
          maxHeight: "40px",
          padding: "0px",
        }}
      >
        <FontAwesomeIcon icon={faEllipsis} />
      </Button>
      <ul className="dropdown-menu dropdown-menu-end">
        {!!test.administered_at && (
          <Dropdown.Item
            data-test-hook="edit"
            href={test.case_report_path}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPen} className="me-1" /> Edit
          </Dropdown.Item>
        )}
        {!!resultingFromLabManager && (
          <Wrapped
            WrapperComponent={TooltipWrapper}
            wrapIf={!hasPermissionToResult}
            componentProps={{
              tooltipContent: 'You do not have permission to log results.',
            }}
          >
            <Dropdown.Item
              onClick={hasPermissionToResult && redirectToResultInterface}
              className={!hasPermissionToResult && 'text-muted'}
            >
              <FontAwesomeIcon icon={faArrowRight} className="me-1" />
              Log results
            </Dropdown.Item>
          </Wrapped>
        )}
      </ul>
    </span>
  );
};

export default ActionsDropdown;