import React from 'react';

const User = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 13 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65 7.75C9.775 6.975 10.5 5.7 10.5 4.25C10.5 1.9 8.6 0 6.25 0C3.9 0 2 1.9 2 4.25C2 5.7 2.725 6.975 3.85 7.75C1.625 8.45 0 10.55 0 13V16H12.5V13C12.5 10.55 10.875 8.45 8.65 7.75ZM3 4.25C3 2.45 4.45 1 6.25 1C8.05 1 9.5 2.45 9.5 4.25C9.5 6.05 8.05 7.5 6.25 7.5C4.45 7.5 3 6.05 3 4.25ZM11.5 15H1V13C1 10.525 3.025 8.5 5.5 8.5H7C9.475 8.5 11.5 10.525 11.5 13V15Z"
      fill={color}
    />
  </svg>
);

export default User;
