import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';

const printZpl = (zpl) => {
  var printWindow = window.open();
  printWindow.document.open('text/plain')
  printWindow.document.write(zpl);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
}

export const zplCodeOnClick = (test, updateTest) => {
  const zplCode = test.zpl;
  updateTest({...test, administered: true});
  printZpl(zplCode);
}

const ZplCodePrinter = ({ test, updateTest }) => {
  const onClick = () => zplCodeOnClick(test, updateTest)
  return (
    <div>
      <Button onClick={onClick} variable="primary-outline">Print { test.test_configuration.short_code } Label</Button>
    </div>
  );
}

export default ZplCodePrinter;
