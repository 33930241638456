import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import { profileColors } from '../utils';
import MembersPortalContext from "../../context";

const MemberSelection = ({edit, onClickMember }) => {
  const { t, householdMembers } = useContext(MembersPortalContext);
  return (
      <Row>
        {householdMembers.map(householdMember => {
          const colorIndex = householdMembers.findIndex(x => x.id == householdMember.id);
          return (
            <Col
              xs={4}
              md={3}
              key={householdMember.id}
              data-test-hook={`household_member_${householdMember.id}`}
              onClick={() => onClickMember(householdMember)}
              className="my-2 pointer"
              style={{display: "grid", placeItems: "center"}}
            >
              <div className='profile-circle-wrapper'>
                <div className={edit ? "profile-delete" : "d-none"}><FontAwesomeIcon icon={faCircleMinus}/></div>
                <div
                  className='big profile-circle'
                  style={{
                    backgroundColor: profileColors[colorIndex] ? profileColors[colorIndex] : "#2862FA",
                  }}
                >
                  <div>
                    {householdMember.user.first_name[0].toUpperCase()}{householdMember.user.first_name[1]}
                  </div>
                </div>
              </div>
              <div className='mt-1'>{householdMember.user.first_name}</div>
            </Col>
        )})}
        <Col xs={4} md={3} className="my-2 pointer" style={{display: "grid", placeItems: "center"}}>
          <a href="/finder" style={{textDecoration: "none"}}>
            <div
              className='big profile-circle'
              style={{
                backgroundColor: "white",
                border: "2px dashed #2862FA",
              }}
            >
              <div className='plus-member' style={{fontWeight: "light"}}>
                +
              </div>
            </div>
          </a>
          <div className='text-center'>{t('login.add_members')}</div>
        </Col>
      </Row>
  );
};




export default MemberSelection;
