import React, { useEffect, useState } from 'react';
import { toDate } from 'date-fns-tz';

import Select from '../../common/components/Select';
import { isFuture, SELECT_TIME_OPTIONS } from '../../common/utils/slotting';

const TimeField = ({
  name,
  time,
  setTime,
  enabled: checked,
  minTime = null,
  startDay,
  ...props
}) => (
  <div style={{ width: 125 }}>
    <Select
      value={time}
      options={
        minTime
          ? SELECT_TIME_OPTIONS.filter((timeOpt) =>
            isFuture(timeOpt.value, minTime),
          )
          : SELECT_TIME_OPTIONS
      }
      onChange={setTime}
      valueKey="value"
      labelKey="label"
      isDisabled={!checked}
      {...props}
    />

    {checked && time && (
      <input
        id={name}
        name={name}
        value={toDate(
          `${startDay}T${time?.hour?.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
          })}:${time?.minute?.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
          })}`,
          { timeZone: 'America/Los_Angeles' },
        )}
        type="hidden"
      />
    )}
  </div>
);

const AppointmentStartTimeField = ({ name_prefix = '', start_day = '' }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked && isFuture(startTime, endTime)) setEndTime(null);
  }, [startTime, checked, endTime, setEndTime]);

  return (
    <React.Fragment>
      <div className="form-check">
        <input
          className="form-check-input boolean optional"
          type="checkbox"
          value={checked ? '1' : '0'}
          checked={checked}
          onChange={() => setChecked(!checked)}
          id={`${name_prefix}_checkbox_id`}
        />
        <label
          className="form-check-label boolean optional"
          htmlFor={`${name_prefix}_checkbox_id`}
        >
          Specific Appointment Times Ranges
        </label>
      </div>
      <div className="d-flex flex-row mb-3" style={{ marginLeft: 32, gap: '1rem' }}>
        <TimeField
          name={`${name_prefix}[range_start]`}
          startDay={start_day}
          time={startTime}
          setTime={setStartTime}
          enabled={checked}
          placeholder="Start time"
        />
        <TimeField
          name={`${name_prefix}[range_end]`}
          startDay={start_day}
          time={endTime}
          setTime={setEndTime}
          enabled={checked}
          minTime={checked ? startTime : null}
          placeholder="End time"
        />
      </div>
    </React.Fragment>
  );
};

export default AppointmentStartTimeField;
