import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import ErrorMessage from '../../../../common/components/ErrorMessage';
import RegistrationContext from '../../../contexts/RegistrationContext';
import PhoneInput from '../inputs/PhoneInput';
import OldDateOfBirthInput from '../inputs/OldDateOfBirthInput';

const ContactInformationFields = ({attributeString, values, errors, handleChange, setFieldValue}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);
  const findError = (key) => errors[`${attributeString}${key}`]

  return (
   <div>
    <div className="row">
      <div className="col-lg mb-3">
        <FloatingLabelInput
          dataTestHook="first_name"
          name={`${attributeString}first_name`}
          value={values.first_name || ""}
          onChange={handleChange}
          label={t('registration.first_name_label') + " *"}
          id="first_name"
          className="mt-3"
          ariaInvalid={!!findError("first_name")}
          validation={findError("first_name") && 'is-invalid'}
        />
        {findError("first_name") && <ErrorMessage message={t(findError("first_name"))} />}
      </div>
      <div className="col-lg mb-3">
        <FloatingLabelInput
          dataTestHook="last_name"
          name={`${attributeString}last_name`}
          value={values.last_name || ""}
          onChange={handleChange}
          label={t('registration.last_name_label') + " *"}
          id="last_name"
          className="mt-3"
          ariaInvalid={!!findError("last_name")}
          validation={findError("last_name") && 'is-invalid'}
        />
        {findError("last_name") && <ErrorMessage message={t(findError("last_name"))} />}
      </div>
    </div>

    <div className="row">
      <div className="col-lg mb-3">
        <PhoneInput name={attributeString} handleChange={handleChange} setFieldValue={setFieldValue} values={values} errors={errors} />
      </div>
      <div className="col-lg mb-3">
        <FloatingLabelInput
          dataTestHook="email"
          name={`${attributeString}email`}
          id="email_input"
          value={values.email || ""}
          onChange={handleChange}
          label={t('registration.email')}
          type="email"
          autoComplete="email"
          ariaInvalid={!!findError("email")}
          validation={findError("email") && 'is-invalid'}
        />
        {findError("email") && <ErrorMessage message={t(findError("last_name"))} />}
      </div>
    </div>

    <label className="form-label">{ t('registration.date_of_birth') }</label>
    <div className="text-secondary mb-3">{ t('registration.minimum_age', { year: testGroup.minimum_age }) }</div>
    <div className="row mb-3">
      <div className={"col-sm-12 col-md-8 p-0"}>
        <OldDateOfBirthInput name={attributeString} setFieldValue={setFieldValue} values={values} errors={errors}/>
        {findError("date_of_birth") && <ErrorMessage className="ms-3" message={t(findError("date_of_birth"))} />}
      </div>
    </div>
  </div>
  );
};

export default ContactInformationFields;
