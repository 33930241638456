export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "الشهر",
    "day": "اليوم",
    "year": "العام",
    "january": "يناير",
    "february": "فبراير",
    "march": "مارس",
    "april": "أبريل",
    "may": "مايو",
    "june": "يونيو",
    "july": "يوليو",
    "august": "أغسطس",
    "september": "سبتمبر",
    "october": "أكتوبر",
    "november": "نوفمبر",
    "december": "ديسمبر"
  },
  "user": {
    "send_appointment_confirmation_message": "تم تاكيد الموعد {{name}}",
    "landline_appointment_reminder_message": "مرحبًا، {{full_name}}. هذا تأكيد بالموعد تحت اسم {{name}}. موعدك في تاريخ {{date}} وفي الساعة {{time}} في {{address}}.",
    "send_at_home_visit_confirmation_message": "تم تاكيد الموعد {{date}} بعد {{time}}.",
    "send_mail_order_confirmation_message": "تم تاكيد طلب الايميل",
    "send_waitlist_message": "تمّ إدراجك في قائمة الانتظار تحت اسم {{name}}. يرجى عدم الحضور حتّى يتم تأكيد وقت الموعد.",
    "verify_contact": {
      "phone": "الرجاء تأكيد رقم الهاتف بالضغط على الرابط التالي, {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "أكمل التسجيل للموعد هنا: {{url}}",
    "on_demand_confirmation_message": "تمت عملية التسجيل بنجاح {{name}}",
    "appointment_reminder": "قبل موعدك بمدة {{start_time}}، اضغط على الرابط لإكمال أيّة إجراءات وعرض الموافقة. الرجاء عدم الحضور إلى الموعد بعد 15 دقيقة. ",
    "appointment_reminder_base": "{{text}} انقر هنا {{link}}",
    "test_result_notifier": {
      "text_message": "تم تحديث بوابة المريض لـ {{first_name_with_last_initial}}. وانتقل الى {{url}} واستخدم الكود {{access_code}}",
      "email_subject": "تحديثات جديدة في بوابة المريض الخاصة بك",
      "email_intro": "مرحبا {{first_name_with_last_initial}}",
      "email_p1": "رسالة جديدة أو تحديث المريض أصبح متاح لك.",
      "email_p2": "انقر فوق الزر أدناه أو استخدم الرابط لعرض منصة المريض واستخدام الكود",
      "email_p3": "عرض منصة المرضى على: ",
      "email_button": "عرض المنصة "
    },
    "landline_appointment_reminder_message_without_time": "مرحبًا، {{full_name}}. هذا تذكير بالموعد تحت اسم {{name}}. موعدك في تاريخ {{date}} في {{address}}.",
    "appointment_reminder_on_demand": "قبل موعدك في تاريخ {{date}} يرجى الضغط على الرابط لإكمال أيّة إجراءات وعرض إيصال الموافقة.",
    "new_test_result_notifier": {
      "email_intro": "تم تحديث منصة المرضى الخاصة بك.",
      "email_text_1": "قم بزيارة المنصة الخاصة بسجلك المرضي آدناه للاطلاع على سجلات المرضى الجدد أو تقارير المختبر.  ",
      "email_button": "التاريخ الطبي ",
      "email_text_2": "يمكنك ايضا نسخ ولصق الرابط في محرك البحث الخاص بك:"
    },
    "mailers": {
      "email_contact_us": "هل لديك أسئلة؟ تواصل معنا"
    }
  },
  "errors": {
    "generic": {
      "message": "حدث خطأ ما، يرجى إعادة المحاولة"
    },
    "messages": {
      "blank": "لا يمكن ترك الخانة فارغة"
    },
    "incorrect_credentials": "معلوماتك الشخصيه (الاسم أوكلمة السر)غير صحيحة، الرجاء اعادة المحاولة ",
    "error_activating_test_kit": "حدث خطأ اثناء تفعيل مجموعة الفحص"
  },
  "type": "النوع",
  "payment": {
    "continue_label": "الاستمرار للدفع",
    "policy_text": "في حال إلغائك أو عدم حضورك للموعد، سوف نعيد 80% من المبلغ بعد سبعة أيام من تاريخ الموعد. يمكنك عرض شروط الحدمة ({{link}})",
    "card_number": "رقم البطاقة",
    "expiry_date": "تاريخ الانتهاء",
    "pay_button": "الدفع",
    "no_credit_card": "سوف أدفع على الموقع",
    "cash_payment_helper": "يرجى التأكد من إحضار **{{payment}}** إلى موعدك",
    "invoice": "الإيصال",
    "pay_by_card": "الدفع بالبطاقة",
    "cost": "المبلغ",
    "total": "المجموع",
    "pay_cash_at_appointment": "سأدفع نقدًا خلال موعدي في {{appointment}}",
    "view_invoice": "عرض / طباعة الإيصال",
    "refund_policy": "إذا قمت بإلغاء موعدك، فسنعيد لك {{amount}} من دفعتك، بعد 7 أيام من تاريخ موعدك. يمكنك الاطلاع على كافة [شروط الخدمة من خلال {{link}}]",
    "refund_window_passed": "لا يمكنك استرداد مبلغ تسجيل الموعد لأنّ أوانه قد فات. يرجى التواصل مع منسقي الموقع مباشرة لاتخاذ أي قرارات أخرى.",
    "amount_paid": "المبلغ المدفوع",
    "balance": "الرصيد"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "حمّل نموذج الموافقة أو وقّع في الخانة أدناه",
    "stop": "الموافقة مطلوبة لإكمال التسجيل",
    "parent_or_guardian_required_html": "حتّى يتم إنهاء التسجيل، يجب على إحدى الوالدين أو الوصي أو متخذ القرار الرسمي أن يوفّر وثيقة الموافقة أو التوقيع أدناه. لا يمكنك <b>CANNOT</b> الموافقة على هذه الخدمة بنفسك.",
    "send_parent_or_guardian": "يمكنك إرسال هذا الرابط إلى والديك أو ولي أمرك أو جعلهما يوقعان أدناه على هذه الصفحة.",
    "consent_guardian_name_signature": "الاسم الأول",
    "consent_for_minor": "التوقيع على الموافقة بالنيابة عن القاصر",
    "guardian_signature": "توقيع ولي الأمر أو الوصي أو متخذ القرار الطبي المعتمد",
    "after_you_schedule": "المشاركين تحت عمر {{age}} عليهم الحصول على موافقة موقعه بالنيابة عن الوالدين أو الوصي. يرجى الإكمال لنهاية التسجيل لإرسال النموذج للوصي أو التوقيع للموصي عليه.",
    "consent_guardian_last_name_signature": "الاسم الأخير",
    "consent_first_and_last_name": "الاسم الأول والاسم الأخير",
    "phone_number": "رقم هاتف الكفيل",
    "email": "بريد إلكترون للكفيل",
    "consent_registrar_with_guardian_name_signature": "الإسم الأول للوصي",
    "consent_registrar_with_guardian_last_name_signature": "اسم عائلة الوصي",
    "consent_registrar_first_name_signature": "الإسم الأول للمسجل",
    "consent_registrar_last_name_signature": "لقب عائلة المسجل",
    "consent_on_behalf": "انا اسجل الدخول بالنيابه عن {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "لقد تم تفويضي شفهيًا من قبل {{first_name}} {{last_name}} للتوقيع بالنيابة عنهم.",
    "consent_on_behalf_registrar_with_guardian": "لقد تم تفويضي شفهيًا من قبل الوصي {{first_name}} {{last_name}} للتوقيع بالنيابة عنهم.",
    "registrar_phone": "رقم هاتف المسجل",
    "registrar_email": "عنوان البريد الالكتروني الخاص بالمسجل ",
    "consented_by_parent_guardian": "الوالد/ ولي الامر",
    "consented_by_decision_maker": "المخول باتخاذ القرارات الطبية",
    "consented_by_guardian_verbal": " المسجل مع الوصي (تم الحصول على موافقة شفهية)",
    "consented_by_registrar": "المسجل (تم الحصول على موافقة شفهية)",
    "full_name": "الاسم كاملا",
    "print": "Print"
  },
  "registration": {
    "contact_information": "الاسم والعنوان",
    "contact_information_additional": "معلومات التواصل للوالدين أو الوصي من أجل الموعد والنتائج",
    "personal_information": "المعلومات السكنية",
    "address_placeholder": "الرجاء إدخال عنوان منزلك",
    "first_name_label": "الاسم الأول",
    "last_name_label": "الاسم الأخير",
    "errors": {
      "state": "رمز الولاية الصحيح يتكون من حرفين",
      "phone_number_invalid": "رقم الهاتف غير صحيح",
      "required": "هذه الخانة مطلوبة",
      "email_domain": "المعلومات المدخلة غير صحيحة، يرجى التأكد. التسجيل مقصور على المشاركين المختارين من قبل المنظمة الراعية. يرجى محاولة استخدام البريد الإلكتروني الخاص بالعمل أو المجتمع. إذا كنت تشعر بأنك مؤهل وكان هناك خطأ ما، يرجى الاتصال بوحدة الدعم.",
      "age_requirement": "تاريخ الميلاد لا يتماشى مع العمر المطلوب",
      "signature_required": "التوقيع مطلوب",
      "regex_invalid": "يبدو أنّ هذه الخانة غير صحيحة.",
      "date_invalid": "هذا التاريخ غير موجود",
      "invalid_entry": "يرجى عدم الإكمال في التسجيل. اتبع التعليمات التي تظهر على الشاشة أو تواصل مع جهة الاتصال المناسبة لك.",
      "city": "يجب أن تكون مدينة صحيحة",
      "survey_unanswered": "يرجى التحقق من الإجابة على جميع الأسئلة المطلوبة المميزة بعلامة *.",
      "postal_code": "يجب أن يكون رمزًا بريديًا صالحًا مكونًا من 5 أرقام",
      "field_required": " {{field}} لابد من وجود قيمة",
      "option_required": "يجب أن تختار أحد الخيارات",
      "year_length_invalid": "سجّل تاريخ الميلاد مع تدوين السنة (yyyy)",
      "invalid_day": "يجب أن يكون اليوم بين 1 - 31",
      "reached_max_chars": "لقد وصلت الحد الاقصى من الرموز أو الاحرف",
      "chars_max": "الحد الأقصى للرموز أو الأحرف",
      "minimum_length": "يجب أن يكون طول المدخلات {{length}} حرفًا على الأقل."
    },
    "insurance_status": {
      "question": "هل لديك تأمين صحي؟",
      "have_health_insurance": "نعم، لدي تأمين صحي",
      "do_not_have_health_insurance": "لا، ليس لدي تأمين صحي"
    },
    "insurance_policy_holder": {
      "question": "من هو صاحب بوليصة التأمين؟",
      "i_am": "أنا",
      "my_spouse": "الزوجة أو الشريك",
      "my_parents": "الوالدين",
      "someone_else": "شخص آخر",
      "policy_first_name": "الاسم الأول لصاحب بوليصة التأمين",
      "policy_last_name": "الاسم الأخير لصاحب بوليصة التأمين",
      "policy_dob": "تاريخ ميلاد صاحب بوليصة التأمين",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "معلومات عن بوليصة التأمين",
      "company_name": "اسم شركة التأمين",
      "id_number": "رقم تعريف العضو",
      "group_number": "رقم مجموعة العضو",
      "secondary_insurance_label": "لدي تأمين ثانوي",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "العمل",
      "address_1": "عنوان الشركة",
      "address_2": "مثال: جناح 200",
      "phone_number": "رقم هاتف الشركة",
      "company": "اسم الشركة",
      "postal_code": "الرمز البريدي للشركة",
      "employed": "لديّ عمل",
      "sole_prorietor": "صاحب عمل",
      "not_employed": "لا أعمل",
      "reporting_supervisor": "مشرف النتيجة",
      "reporting_department": "قسم التقارير",
      "supervisor_name": "اسم المشرف",
      "supervisor_email": "البريد الإلكتروني للمشرف"
    },
    "location_availability": "أولوية الاختبار لأفراد المجتمع في هذه المناطق",
    "custom_survey": "الاستبيان",
    "confirmation": "المراجعة",
    "waitlisted": "في قائمة الانتظار",
    "schedule_your_appointment": "تحديد موعد",
    "information": "المعلومات",
    "consent": "الموافقة",
    "location": "الموقع",
    "symptoms": "الأعراض",
    "address_required": "الرجاء إدخال عنوانك",
    "consent_required": "الرجاء الموافقة للاستمرار",
    "required_field": "يشير إلى الخانة المطلوبة",
    "phone_number": "رقم الهاتف المتحرك",
    "email": "البريد الإلكتروني",
    "date_of_birth": "تاريخ الميلاد",
    "minimum_age": "الحد الأدنى لعمر للمشاركين هو {{year}} سنة.",
    "no_minimum_age": "لا يوجد حد أدنى للعمر من أجل الاختبار",
    "continue_button": "الاستمرار",
    "email_required": "يجب أن تتكون الخانة من بريد إلكتروني صحيح.",
    "done": "الانتهاء",
    "signature": "التوقيع",
    "clear_button": "مسح",
    "back_button": "العودة",
    "choose_location": "اختر موقع",
    "no_slots": "لا يوجد مواعيد أخرى في {{location}}",
    "choose_appointment": "اختر موعدًا في {{location}}",
    "appointment_required": "يرجى اختيار وقت للموعد",
    "phone_number_required": "رقم الهاتف غير صحيح",
    "phone_number_label": "يرجى إدخار رقم هاتف صحيح لوصول الرسالئل النصية والحصول على النتائج بوقت أسرع",
    "symptoms_experiencing": "يرجى النقر على الأعراض التي تشكون منها. يرجى الإكمال في حال عدم وجود أي أعراض.",
    "household": "أفراد الأسرة",
    "household_p1": "بشكل اختياري، اختر موعدًا لأفراد الأسرة الذين يحتاجون للاختبار.",
    "add_dependent": "إضافة فرد من الأسرة",
    "remove_dependent": "إزالة فرد من الأسرة",
    "dependent_consents": "الموافقات",
    "submit": "الإكمال",
    "add_waitlist": "الإضافة إلى قائمة الانتظار",
    "address": "عنوان المنزل",
    "address_1": "العنوان 1",
    "address_2": "شقة / جناح #",
    "address_city": "المدينة",
    "address_state": "الولاية",
    "postal_code": "الرمز البريدي",
    "race_ethnicity": "العرق",
    "gender": "هوية النوع الجندري",
    "self_described_gender": "وصف النوع الجندري",
    "interpreter": "هل تحتاج إلى مترجم فوري؟ إن كان الجواب نعم، أي لغة؟",
    "consent_to_terms": "أنا أوافق على هذه الشروط.",
    "reg_not_open": "التسجيل لم يبدأ",
    "no_more_avail_spots": "الوقت الذي اخترته لم يعد متاحًا. يرجى إعادة الاختيار.",
    "consent_helper": "انقر واسحب الفأرة أو الاصبع عبر المربع أدناه للتوقيع",
    "phone_number_unreachable_label": "الهاتف الأرضي؟",
    "select": "اختر",
    "test_surveys": "أسئلة للموعد",
    "edit": "عدّل",
    "continue_to_registration": "المتابعة للتسجيل",
    "accounts": {
      "already_have_an_account": "هل لديك حساب؟",
      "log_in": "تسجيل الدخول",
      "sign_up": "الاشتراك",
      "sign_up_description": "يرجى إدخال معلوماتك لإنشاء حسابك وتوفير الوقت في إجراءات التسجيل القادمة.",
      "log_in_description": "الرجاء إدخال البريد الإلكتروني وكلمة المرور، أو تسجيل الدخول عبر Google أو Outlook أدناه",
      "sign_in_with_label": "تسجيل الدخول مع {{provider}}",
      "password_must_match": "يجب تطابق كلمات المرور",
      "password_uppercase": "يجب أن تحتوي كلمة المرور على (s%) أحرف كبيرة على الأقل.",
      "password_lowercase": "يجب أن تحتوي كلمة المرور على (s%) أحرف صغيرة على الأقل.",
      "password_number": "يجب أن تحتوي كلمة المرور على (s%) رقم على الأقل.",
      "password_special": "يجب أن تحتوي كلمة المرور على (s%) رموز مميزة على الأقل."
    },
    "password": "كلمة المرور",
    "password_confirmation": "تأكيد كلمة المرور",
    "consent_for": "الموافقة الخاصة ب {{name}}",
    "book_one_time_appointment": "احجز موعدًا لمرة واحدة",
    "duplicate_users": {
      "exists": "تم تسجيلك مسبقًا",
      "overlapping_email_and_phone_p1": "لقد أرسلنا رسالة إلى رقم هاتفك وبريدك الإلكتروني.",
      "p2": "باستخدام الرسالة، يمكن تغيير الموعد أو إدخال نتائج الاختبار.",
      "overlapping_phone_and_email_p3": "يرجى التحقق من بريدك الإلكتروني أو الهاتف للتفاصيل.",
      "overlapping_email_p3": "يرجى التحقق من بريدك الإلكتروني للتفاصيل.",
      "overlapping_phone_p3": "يرجى التحقق من هاتفك للتفاصيل.",
      "overlapping_phone_p1": "أرسلنا رسالة إلى رقم هاتفك.",
      "overlapping_email_p1": "أرسلنا رسالة إلى بريدك الإلكتروني.",
      "p4": "إذا كنت تعتقد أن هناك خطأ، يرجى الاتصال بنا عبر support@primary.health",
      "overlapping_email_and_phone_p3": "يرجى التحقق من بريدك الإلكتروني أو الهاتفا للتفاصيل."
    },
    "duplicate_waitlist": {
      "exists": "أنت على قائمة الانتظار",
      "overlapping_email_and_phone_p1": "أرسلنا تأكيدًا آخر إلى رقم هاتفك وعنوان بريدك الإلكتروني",
      "p2": "باستخدام رسالة الموافقة، يمكنك تزويدنا بمعلومات إضافية أو إزالة اسمك من قائمة الانتظار.",
      "overlapping_phone_and_email_p3": "يرجى التحقق من بريدك الإلكتروني أو الهاتف للتفاصيل",
      "overlapping_email_p3": "يرجى التحقق من بريدك الإلكتروني للتفاصيل.",
      "overlapping_phone_p3": "يرجى التحقق من هاتفك للتفاصيل.",
      "overlapping_email_p1": "أرسلنا تأكيدًا آخر إلى بريدك الإلكتروني."
    },
    "insist_guardian_consent": "أنا أقدم الموافقة نيابة عن",
    "confirmation_section": {
      "title": "المراجعة والتأكيد",
      "p1": "يرجى مراجعة المعلومات الخاصة بك قبل الانتهاء من التسجيل.",
      "edit_information": "العودة والتعديل"
    },
    "iemodal": {
      "title": "متصفح بدون دعم",
      "body": "التسجيل متاح على هذه المتصفحات"
    },
    "show_other_locations": "اضغط لعرض المواقع الأخرى",
    "non_us_address": "هذا عنوان دولي",
    "test_group_user_survey": "فحص المشاركين",
    "self_consent": "أنا أوافق بالنيابة عن نفسي",
    "address_country": "الدولة",
    "appointment": "ميعاد",
    "employee_id": "هوية الموظف",
    "appointment_time": "وقت الموعد",
    "appointment_location": "موقع",
    "phone_or_email": "رقم الهاتف أو البريد الإلكتروني",
    "no_self_consent": "أرفض الموافقة التابعة لي",
    "no_insist_guardian_consent": "أرفض الموافقة التي تخصّ {{full_name}}",
    "minimum_age_with_months": "الحد الأدنى لسن المشاركين هو {{year}} سنة و {{month}} شهرًا.",
    "additional_consents_helpertext": "قد يتم طلب موافقات إضافية في وقت لاحق",
    "assistive_technology": "التكنولوجيا المساعدة",
    "covid_vaccine_y_n": "هل ترغب بالحصول على جرعة لقاح كوفيد-19 مع هذا الحجز؟",
    "received_covid_vaccine_y_n": "هل حصلت على جرعة لقاح كوفيد-19 من قبل؟",
    "covid_vaccinations": "لقاحات كوفيد-19",
    "select_vaccine_dose": "يرجى اختيار جرعة اللقاح التي تودّ الحصول عليها",
    "demographic_info": "المعلومات السكانية",
    "additional_info": "معلومات إضافية",
    "self_described_race": "العرق الاثني الذي يتصف نفسك به",
    "verify_contact_information": "التحقق",
    "verify_contact": {
      "we_sent_you_a_code": "الرجاء ادخال الرمز المرسل الى {{contact}}",
      "confirmation_code": "رمز التأكيد",
      "code_resent": {
        "email": "تم ارسال رمز آخر إلى البريد الالكتروني ",
        "phone_number": "تم ارسال رمز آخر إلى الهاتف "
      },
      "did_not_receive_code": "لم يتم الحصول على رمز التأكيد؟ ",
      "verify_by": {
        "email": "التحقق من خلال البريد الالكتروني بدلا من ذلك ",
        "phone_number": "التحقق من خلال رقم الهاتف بدلا من ذلك "
      }
    },
    "preferred_method_of_communication": {
      "question": "طريقة التواصل المفضله",
      "helper_text": "سنقوم بالتواصل معك في حال تحديث الموعد",
      "phone_number": "الهاتف (الرسائل النصية القصيرة فقط وقد يتم اعتبار معدلات البيانات)",
      "landline": "لا يمكننا التواصل معك من خلال الخطوط الأرضية في هذا الوقت، لذا يرجى تزويدنا بعنوان بريدك الإلكتروني لحالات الضرورة.",
      "verification_method": "كيف تريد استقبال رمز المصادقة الخاص بك؟",
      "verification_helper_text": "ستتلقى رمز لتأكيد هويتك قبل تسجيل الدخول."
    },
    "skip_for_now": "تخطي الآن",
    "default_information": {
      "title": "حسنا، سنستعمل هذا العنوان",
      "title_v2": "حسنًا، سنستخدم معلومات الاتصال هذه"
    },
    "middle_name_label": "الاسم الاوسط",
    "confirm_appointment": "تأكيد الموعد",
    "dob": "تاريخ الميلاد",
    "different_appointment": "موعد آخر",
    "select_appointment_time": "اختيار وقت الموعد",
    "decline_address": "لا أريد تزويد عنوان. الرجاء استخدام عنوان البرنامج بدلاً من ذلك.",
    "patient_information": "معلومات المريض",
    "scan_license": "تفحص رخصة القيادة",
    "how_to_contact": "كيف يجدر بنا التواصل معك؟",
    "decline_email_or_phone": "أرفض تقديم عنوان بريد إلكتروني أو رقم هاتف. أسمح لمسؤولي البرنامج باستلام نتائجي.",
    "medical_screening": "الفحص الطبي ",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "اختياري",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "ذكر",
    "female": "أنثى",
    "cisgender": "متوافق النوع",
    "non_binary": "غير ثنائي أو ثنائي النوع",
    "other": "أخرى",
    "prefer_to_self_describe": "الهوية غير مدرجة",
    "prefer_not_to_disclose": "رفض الإجابة",
    "transgender_male": "ذكر متحول جنسيًا",
    "transgender_female": "أنثى متحولة جنسيًا",
    "unknown": "غير معروف",
    "non_binary_only": "غير ثنائيين",
    "intersex": "ثنائي الجنس (خنثى)",
    "transgender": "متحول جنسيا",
    "gender_fluid": "جندر فلويد",
    "not_applicable": "غير قابل للتطبيق"
  },
  "ethnicity": {
    "american_indian_alaska_native": "الهنود الأمريكيون أو سكان ألاسكا الأصليين",
    "american_indian_central_america": "الهنود الأمريكيون من أمريكا الجنوبية أو الوسطى",
    "asian": "آسيوي",
    "black": "أمريكي ذو بشرة داكنة أو من أصل أفريقي",
    "latinx": "من أصل لاتيني أو اسباني",
    "middle_eastern_north_african": "من أصل شرق-أوسطي أو شمال أفريقيا",
    "native_hawaiian_pacific_islander": "من هاواي أو جزر المحيط الهادئ",
    "white_european": "من أصل قوقازي",
    "unknown": "غير معروف",
    "prefer_not_to_disclose": "أفضل عدم الإجابة",
    "asian_indian": "هندي آسيوي",
    "filipino": "فيليبيني",
    "japanese": "ياباني",
    "korean": "كوري",
    "vietnamese": "فيتنامي",
    "other_asian": "آسيوي - أخرى",
    "native_hawaiian": "مواطن أصلي من هاواي",
    "guamanian_or_chamorro": "غوام وتشامورو",
    "samoan": "ساموا",
    "other_pacific_islander": "جزر المحيط الهادئ الأخرى",
    "chinese": "صيني",
    "help": "يشير العرق إلى مجموعة من الخصائص الجسدية المستخدمة للتمييز بين مجموعات من الناس. قد يتماشى العرق مع الجنسية أو لا يتماشى مع الدولة التي تحمل الجنسية فيها.",
    "subtitle": "تتطلب القوانين تجميع هذه المعلومات.",
    "laotian": "اللاوسية",
    "cambodian": "الكمبودية",
    "other": "أخرى",
    "bangladeshi": "بنغلاديشي",
    "hmong": "همونغ",
    "indonesian": "إندونيسي",
    "malaysian": "ماليزي",
    "pakistani": "باكستاني",
    "sri_lankan": "سريلانكي",
    "thai": "تايلاندي",
    "taiwanese": "تايواني",
    "fijian": "فيجي",
    "guamanian": "غوامي",
    "tongan": "تونغي"
  },
  "languages": {
    "en": "الإنجليزية",
    "fr": "الفرنسية",
    "es": "الاسبانية",
    "so": "الصومالية",
    "hmn": "الهمونغية",
    "asl": "لغة الإشارة الأمريكية (ASL)",
    "kar": "الكارن",
    "am": "الأمهرية",
    "ru": "الروسية",
    "om": "الأوروموية"
  },
  "symptoms": {
    "fever": "حمى أو قشعريرة",
    "cough": "سعال",
    "muscle_aches": "آلام العضلات",
    "severe_fatigue": "التعب الشديد (أكثر من المعتاد)",
    "trouble_breathing": "صعوبة في التنفس",
    "diarrhea": "إسهال",
    "loss_of_smell": "فقدان حاسة الشم",
    "loss_of_taste": "فقدان التذوق",
    "shortness_of_breath": "ضيق في التنفس أو صعوبة في التنفس",
    "headache": "صداع الرأس",
    "sore_throat": "التهاب الحلق",
    "congestion": "احتقان أو سيلان الأنف",
    "nausea": "الغثيان أو القيء",
    "close_contact": "اتصال وثيق مع شخص مصاب *",
    "helper_text": {
      "close_contact": "* هل كان لديك اتصال وثيق (على بعد 6 أقدام لمدة 15 دقيقة على الأقل) مع شخص أكد الإصابة بفيروس COVID-19؟"
    },
    "suspected_exposure": "التعرض المشتبه به",
    "none": "لا توجد أعراض"
  },
  "instructions": {
    "title": "أنت مسجل ولكن قبل أن تذهب...",
    "p1": "تحتوي هذه الصفحة على معلومات حول موعدك بالإضافة إلى الرمز الخاص بموعدك.",
    "appointment_scheduled": "تم تحديد موعدك",
    "verify_info": {
      "title": "تحقق من معلوماتك",
      "p1": "من المهم التحقق من صحة معلومات الاتصال الخاصة بك حتى تتمكن من الوصول إلى بوابة المريض الخاصة بك بسرعة.",
      "p2": "تم إرسال رسالة تأكيد إلى البريد الإلكتروني الخاص بك. الرجاء الضغط على الرابط المرسل.",
      "p3": "إذا كنت بحاجة إلى تغيير معلومات الاتصال الخاصة بك، يرجى الضغط على زر المساعدة."
    },
    "verified": "تم التحقق",
    "not_verified": "لم يتم التحقق منه",
    "resend_text": "لم تستلم الرابط؟",
    "resend": "إعادة الإرسال",
    "skip": "تخطي التحقق وعرض إيصال التأكيد",
    "name": "الاسم",
    "appointment_details": "الموعد",
    "date_and_time": "التاريخ و الوقت",
    "add_to_calendar": "الإضافة إلى الجدول",
    "instructions": "تعليمات عامة",
    "successfully_flash": "تم إنشاء الموعد بنجاح!",
    "success_flash_email": "تم التحقق من البريد الإلكتروني بنجاح",
    "success_flash_phone": "تم التحقق من رقم الهاتف بنجاح",
    "mail_order": "ستتلقى مجموعة الاختبار الخاصة بك عبر البريد خلال الأسبوع.",
    "at_home": "سيأتي مزود طبي إلى منزلك في هذا الوقت تقريبًا",
    "at_home_instructions": "تعليمات داخل المنزل",
    "mail_order_instructions": "تعليمات عن طلب البريد",
    "request_additional_appointment": "احجز موعد المراجعة",
    "book_additional_appointment": "احجز موعداً آخر",
    "confirmation_code": "تأكيد الرمز",
    "completed": "انتهاء",
    "appointment_barcode": "الرمز الخاص بالموعد",
    "dependent_links": "روابط مواعيد أفراد الأسرة",
    "on_demand_title": "أنت مسجل ولكن قبل أن تذهب...",
    "save_link": "احتفظ بالرابط",
    "verify_phone": "تحقق من رقم الهاتف",
    "verify_phone_description": "من المهم التحقق من صحة معلومات الاتصال الخاصة بك حتى تتمكن من الوصول إلى سجلاتك بسرعة. تم إرسال رسالة نصية للتحقق إلى رقمك.",
    "verify_email_description": "من المهم التحقق من صحة معلومات الاتصال الخاصة بك حتى تتمكن من الوصول إلى سجلاتك بسرعة. تم إرسال رسالة للتحقق إلى بريدك الإلكتروني.",
    "information": "معلومات التسجيل",
    "follow_up_appointment": "موعد المراجعة",
    "get_directions": "الحصول على الاتجاهات",
    "cancel": "إلغاء الموعد",
    "reschedule_appointment": "إعادة حجز الموعد",
    "reschedule_linked_appointments": "إعادة حجز المواعيد",
    "no_slots": "لا مزيد من الأوقات المتاحة",
    "check_results": "تحقق من بوابة المريض",
    "follow_up_modal_header": "يرجى تسجيل موعد للجرعة الثانية",
    "are_you_sure_you_want_to_cancel": "هل أنت متأكد أنك تريد إلغاء هذا الموعد؟",
    "please_choose_cancellation_reason": "الرجاء اختيار سبب الإلغاء أدناه",
    "additional_details": "الرجاء إضافة أي تفاصيل إضافية أدناه",
    "errors": {
      "missing_cancellation_reason": "الرجاء اختيار سبب الإلغاء"
    },
    "verify_email": "قم بتأكيد بريدك الألكتروني",
    "redcap_url": "انقر لإكمال الاستبيان الخاص بك",
    "verify_contact_information": "تحقق من معلومات الاتصال الخاصة بك",
    "please_also": "يرجى أيضًا",
    "new_title": "تأكيد {{name}}",
    "contact_method": "طريقة التواصل",
    "next_steps": {
      "title": "الخطوة التالية",
      "p1": "لديك مجموعة فحص للتنشيط. عندما تكون مستعدًا لإجراء الفحص، اتبع الرابط المرسل إلى بريدك الإلكتروني للعودة إلى هذه الصفحة وتنشيط مجموعتك أدناه.",
      "administered": "سوف نتصل بك عندما تصل مجموعتك إلى المختبر وعندما تصبح نتائجك جاهزة. عادة تستغرق النتائج من 3-5 أيام بعد وصولها إلى المختبر. يمكنك عرض النتائج من صفحة [Medical history]({{link}}) من خلال الخاصة بك عندما تكون جاهزة.",
      "resulted": "النتيجة متاحة على صفحة [Medical history]({{link}}) من خلال",
      "shipping_information": "معلومات الشحن"
    },
    "save_this_page": {
      "title": "حفظ هذه الصفحة",
      "p1": "استخدم هذه الصفحة للإبلاغ عن اختبار ذاتي أو عرض النتائج من خلال التاريخ الطبي.",
      "p2": "للدخول إلى هذه الصفحة في المستقبل، انقر على الرابط الموجود في رسالة التأكيد الإلكترونية أو الرسالة النصية القصيرة.",
      "p3": "يمكنك حفظ هذه الصفحة عن طريق وضع إشارة عليها أو إضافتها إلى الشاشة الرئيسية أو نسخ الرابط إلى مكان آمن."
    },
    "show_my_barcode": "عرض البار كود الخاص بي",
    "my_account": "حسابي",
    "register_another": "تسجيل مشترك اخر",
    "update_vaccine": "تحديث معلومات اللقاح",
    "medical_history": "السجل الطبي ",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "رمز الوصول غير صحيح"
    },
    "landing": {
      "p1": "لعرض نتائجك ، يرجى إدخال:",
      "access_code_label": "رمز الدخول",
      "submit_button": "التقديم",
      "recent_results": "النتائج الأخيرة"
    },
    "view_results": {
      "headline": "نتائج اختبار {{name}}",
      "reregister": "إعادة التسجيل",
      "status": {
        "likely_positive": "النتائج غير محددة",
        "test_not_performed": "يلزم إعادة الاختبار",
        "results_ready": "النتائج جاهزة",
        "processing": "يتم معالجة المعلومات",
        "test_status": "حالة الاختبار",
        "test_result": "نتيجة الاختبار",
        "administered": "تم أخذه",
        "results_pending": "النتيجة قيد الانتظار",
        "test_results": "نتيجة الفحص"
      },
      "at": "عند {{address}}"
    },
    "result_label": "النتيجة",
    "result": {
      "flu_a": {
        "result_label": "نتيجة الأنفلونزا A"
      },
      "flu_b": {
        "result_label": "نتيجة الأنفلونزا B"
      },
      "covid19": {
        "result_label": "نتيجة فحص كوفيد-19"
      },
      "covid": {
        "result_label": "نتيجة فحص كوفيد-19"
      },
      "sars": {
        "result_label": "نتيجة فحص فيروس السارس"
      },
      "control": {
        "result_label": "المراقبة"
      },
      "rsv": {
        "result_label": "نتيجة RSV"
      },
      "result": {
        "result_label": "النتيجة"
      },
      "hba1c": {
        "normal": "طبيعي",
        "warning": "مرحلة ما قبل السكري",
        "danger": "مرض السكري"
      },
      "lead_venous": {
        "danger": "غير طبيعي",
        "normal": "طبيعي"
      },
      "tc": {
        "result_label": "نتيجة تحليل الكوليسترول"
      },
      "hdl": {
        "result_label": "نتيجة فحص البروتين الشَّحمي عالي الكثافة"
      },
      "trg": {
        "result_label": "نتيجة تحليل الدهون الثلاثية"
      },
      "ldl": {
        "result_label": "نتيجة فحص الكوليسترول الضار في الدم"
      },
      "non_hdl": {
        "result_label": "نتيجة الكوليستيرول غير مرتفع الكثافة"
      },
      "tc_hdl_ratio": {
        "result_label": "نسبة الكوليسترول  ل الكوليسترول مرتفع الكثافه"
      },
      "glu": {
        "result_label": "نتيجة فحص السكر في الدم"
      },
      "alere_cholestech_ldx": {
        "danger": "في خطر",
        "warning": "غير طبيعي",
        "normal": "طبيعي"
      },
      "lead": {
        "result_label": "يؤدي الى"
      },
      "zinc": {
        "result_label": "الزنك"
      },
      "lead_standard_profile": {
        "danger": "غير طبيعي",
        "normal": "طبيعي"
      },
      "creatinine": {
        "danger": "غير طبيعي",
        "normal": "طبيعي"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "عادي",
        "warning": "مرتفع",
        "danger": "مرتفع",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "ما قبل السكري",
        "diabetes": "السكري"
      },
      "total_cholesterol_fasted": {
        "normal": "عادي",
        "elevated": "مرتفع",
        "high": "مرتفع",
        "low": "منخفض",
        "abnormal": "غير طبيعي"
      },
      "total_cholesterol_unfasted": {
        "normal": "عادي",
        "elevated": "مرتفع",
        "high": "مرتفع"
      },
      "a1c_now": {
        "normal": "عادي",
        "warning": "ما قبل السكري",
        "danger": "السكري"
      },
      "blood_glucose": {
        "warning": "منخفض",
        "normal": "طبيعي",
        "prediabetes": "مقدمات السكري",
        "danger": "داء السكري",
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "حالة طوارئ طبية",
        "inconclusive_1": "غير حاسم 1",
        "inconclusive_2": "غير حاسم 2",
        "possible_diabetes": "احتمال الإصابة بالسكري"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      },
      "blood_glucose_ucsf": {
        "low": "منخفض",
        "normal": "طبيعي",
        "high": "مرتفع"
      }
    },
    "documents": "الوثائق",
    "self_administered": "يبدأ تفعيل إجراء الفحص الذاتي عند {{location}}",
    "patient": "مريض",
    "medical_history": "السجل الطبي ",
    "overview_title": "اخترفحص أو خدمة لعرض المزيد من التفاصيل وأي القيام بأي اجراءات إضافية مطلوبة. ملاحظه بعض نتائج الفحص قد لا تكون متاحة بعد أو لن تصبح متاحة إلا بعد التحدث مع مقدم الخدمة.",
    "insurance_information": "تعبئة معلومات التأمين",
    "contact_support": "اذا كنت بحاجة الى مساعده، او تغيير معلومات التواصل الخاصه بك، من فضلك تواصل مع جهات الدعم",
    "show": "عرض",
    "hide": "اخفاء",
    "lab_report": "تقرير المختبر",
    "contact_provider": {
      "header": "لديك نتيجة فحص ايجابية",
      "description": "هل ترغب في التحدث مع مقدم الخدمه لمناقشة نتيجة فحصك وطرق المعالجه ؟",
      "yes_option_text": "نعم، ارغب في التحدث مع مقدم الخدمه",
      "no_option_text": "لا، لقد قمت بقراءة وفهم نتيجة الفحص، ولا أريد التحدث الى مقدم الخدمة",
      "confirm_phone_number_header": "تأكيد رقم هاتفك ",
      "confirm_phone_number_description": "من فضلك قم بتأكيد رقم هاتف المناسب لاستشارتك",
      "confirm_button_text": "تأكيد",
      "consultation_confirmed_header": "تم تاكيد الاستشارة",
      "consultation_confirmed_description": "سيتصل بك أحد مقدمي الخدمة على {{phone_number}} خلال 2-3 أيام عمل.",
      "acknowledgement_option_helper_text": "شيء يوضح أهمية العلاج ويتيح للمستخدم معرفة كيفية جدولة مكالمة إذا قام بتغير رأيه.",
      "acknowledgement_confirmed_header": "تم تأكيد التسليم",
      "acknowledgement_confirmed_description": "شيء عن أهمية الحصول على العلاج بالمصادر المرتبطة. تذكر أن الاستشارة مجانية تمامًا ويمكن لمقدم الخدمة يمكن لمزود الخدمة أن يصف لك أو أي شيء آخر لمساعدته على حل العدوى..",
      "acknowledgement_confirmed_change_mind_text": "اذا قمت بتغيير رأيك. قم ببساطه بالضغط على \"ارغب في الحصول على اسشتاره\" في الاسفل",
      "request_consultation_button_text": "ارغب في الحصول على استشارة"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "عنوانك لا يناسب المؤهلات المذكورة للمواقع في هذا البرنامج."
    }
  },
  "member": {
    "medical_history": "التاريخ",
    "view_instructions": "عرض التأكيد",
    "next_appointment": "الموعد التالي",
    "over_18": "أقر بأنني أبلغ من العمر 18 عامًا أو أكثر",
    "choose_test_configurations_title": "الرجاء تحديد الخدمة (الخدمات) التي تريدها لهذا الموعد",
    "member_taken_error": "المستخدم لديه حساب",
    "choose_test_configurations_subtitle": "سيتم تطبيق الخدمة (الخدمات) على جميع أفراد الأسرة",
    "service": "الخدمة",
    "group_or_location": "المجموعة\\الموقع",
    "details": "التفاصيل",
    "see_all": "عرض الجميع",
    "no_history": "لا يوجد تاريخ متاح في الوقت الحالي",
    "medical_history_title_with_name": "تاريخ {{name}} الطبي",
    "no_dashboard": "لا يوجد شيء متاح حاليا",
    "product": "المنتج",
    "price": "السعر",
    "quantity": "الكمية",
    "total_services_selected": "إجمالي الخدمات المختارة",
    "total_price": "السعر الإجمالي"
  },
  "or": "أو",
  "account": {
    "errors": {
      "must_be_13": "يجب أن يكون عمرك 13 عامًا لتسجيل الحصول على حساب"
    }
  },
  "self_administration": {
    "title": "الإدارة الذاتية",
    "self_administer_action": "المسؤول عن الإدارة الذاتية",
    "skip_to_link": "انتقل للإدارة الذاتية",
    "select_person": "اختر شخصًا لتسجيل الخروج",
    "adult": "بالغ",
    "child": "طفل",
    "checked_out": "تمّ تسجيل الخروج",
    "go_back": "عد",
    "switch_to_scanner": "قم بالتبديل إلى الماسح الضوئي",
    "enter_barcode": "أدخل الباركود",
    "scan_barcode": "مسح الرمز الشريطى",
    "cancel": "يلغي",
    "barcode_for": "الباركود لـ",
    "enter_barcode_manually": "أدخل الباركود يدويًا",
    "instructions": "امسح أو أدخل الرمز الشريطي الموجود في أنبوب الاختبار أدناه.",
    "regex_prefix": "يجب أن يتكون الرمز الشريطي من",
    "all_completed": "مكتملة: أكمل جميع أفراد الأسرة هذه الخطوة",
    "errors": {
      "no_appointment": "مشكلة تحديد موعد",
      "barcode_in_use": "سبق استخدام هذا الرمز الشريطي من قبل. إذا لم تكن قد قمت مسبقًا بالإدارة الذاتية باستخدام هذه المجموعة ، فيرجى التحدث مع الموظفين في الموقع الذي قدم لك المجموعة واطلب مجموعة جديدة. خلاف ذلك ، انقر فوق زر المساعدة أدناه.",
      "test_error_general": "اختبار خلق المشكلة",
      "different_barcodes": "الرموز الشريطية التي أدخلتها غير متطابقة."
    },
    "confirm_barcode_input": "أدخل الباركود مرة أخرى للتأكيد",
    "click_to_self_test": "انقر هنا للاختبار الذاتي"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "قائمة الانتظار",
      "unavailable": "لا توجد مواعيد متاحة"
    },
    "labels": {
      "address": "العنوان",
      "next_appointment": "الموعد التالي المتاح",
      "services": "الخدمات المتاحة"
    }
  },
  "waiting_room": {
    "header": "أنت في غرفة الانتظار، يرجى الانتظار بينما نوصلك بنظام التسجيل.",
    "p1": "يرجى التحلّي بالصبر. سوف نجتاز هذا الوباء معًا.",
    "signature": "مع تحياتنا",
    "p2": "نعلم أنّ تجربة كوفيد-19 قد تكون محبطة. يرجى الانتظار هنا بينما نعمل جاهدين لحجز موعدك بوقت أسرع."
  },
  "users": {
    "send_appointment_confirmation_message": "مرحبًا، {{full_name}}. هذا تذكير بالموعد تحت اسم {{name}}. موعدك في تاريخ {{date}} في الساعة {{time}} في {{address}}.",
    "send_registration_link": "مرحبًا، {{full_name}}. اتبع هذا الرابط لتسجيل {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "الجرعة الأولى",
    "previous_appointment": "الموعد السابق",
    "booked_appointment": "موعدك",
    "second_dose": "الجرعة الثانية",
    "choose_second_location": "اختر موقعًا للموعد الثاني"
  },
  "cancellation_types": {
    "cant_get_to_location": "هناك تعارض في التوقيت",
    "timing_conflict": "أصبت بمرض COVID-19",
    "became_ill_with_covid19": "تلقيت هذه الخدمة في مكان آخر",
    "received_service_elsewhere": "أخرى",
    "other": "اكتب الاسم بدلاً من ذلك",
    "duplicate_appointment": "هناك موعد مكرّر"
  },
  "translation": {
    "consent": {
      "type_name_instead": "اكتب الاسم",
      "type_name": "قم بالتوقيع بدلا من ذلك"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "رمز الوصول الخاص بك هو",
      "please_confirm_your_email": "يرجى تأكيد بريدك الإلكتروني من خلال النقر على الزر أدناه",
      "hello": "مرحبًا",
      "click_to_reschedule": "انقر لإظهار التعليمات / إعادة الجدولة",
      "click_to_reschedule_on_demand": "انقر لإظهار التعليمات"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "تم إلغاء موعدك في {{time}} في {{location}}. إذا كان هذا خطأ، يرجى إرسال بريد إلكتروني إلى support@primary.health"
    }
  },
  "signature_lines": {
    "name": "اسم المشارك",
    "date": "التاريخ",
    "signature": "توقيع المشارك",
    "and_or": "و/أو",
    "guardian_name": "اسم الوالد / الوصي",
    "guardian_signature": "توقيع ولي الأمر / الوصي"
  },
  "employer_testing": {
    "hello_name": "مرحبًا {{name}}",
    "results": {
      "negative": "سلبي",
      "positive": "إيجابي",
      "did_not_result": "مجهول",
      "unknown": "غير معروف",
      "invalid": "باطل",
      "pending": "قيد الانتظار"
    },
    "code_reader": {
      "scan_your_code": "امسح الرمز الموجود على بطاقة الاختبار الخاصة بك",
      "find_in_top_right_hand_corner": "الرمز خاص بالاختبار ويمكن إيجاده في اليمين الأعلى لصفحة الاختبار.",
      "center_code": "أدخل الرمز هنا.",
      "already_used_error": "تم استخدام هذا الرمز من قبل. يرجى التواصل مع مزود الأدوات للمساعدة.",
      "click_to_scan": "اضغط لمسح الرمز",
      "scan_new_test_card": "اضغط هنا لمسح بطاقة اختبار جديدة.",
      "not_working": "لا تعمل؟",
      "try_again": "اضغط هنا للمحاولة مرة أخرى."
    },
    "continue": "الاستمرار",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "يرجى تأكيد تاريخ ميلاد المشارك للمتابعة",
      "contact_administrator": "إن كانت المعلومات معروضة خاطئة، يرجى التواصل مع مسؤول الإدارة للمساعدة",
      "error": "تاريخ الميلاد الذي أدخلته خاطئ - يرجى المحاولة مجددًا أو التواصل مع مسؤول الموقع لتحديث المعلومات."
    },
    "result_entry": {
      "code_is_registered": "الرمز البريدي مسجّل.",
      "take_the_test": "ابدأ بالاختبار",
      "follow_the_instructions": "اتبع التعليمات التي جاءت مع الاختبار وابدأ تشغيل المؤقت من 15 دقيقة عندما تدخل مسحة الأنف إلى القارورة.",
      "cant_find_instructions": "لا يمكنك العثور على التعليمات؟",
      "view_instructions": "عرض التعليمات الخاصة BinaxNow",
      "start_timer": "بدء مؤقت 15 دقيقة (اختياري)",
      "submit_within_15": "نصيحة: أرسل نتائجك في غضون 15 دقيقة",
      "enter_test_results": "أدخل نتائج الاختبار",
      "choose_a_result": "حدد الخيار الذي يصف نتيجة بطاقة الاختبار الخاصة بك",
      "submit": "التقديم",
      "must_log_result_and_photo": "يجب عليك تسجيل نتيجة والتقاط صورة للاختبار الخاص بك للمتابعة",
      "submit_within_15_v2": "نصيحة: أرسل نتائجك بعد بدء الاختبار لمدة 15 دقيقة",
      "click_to_take_photo": "انقر لالتقاط صورة",
      "results_may_be_invalid": "قد تكون نتائج الاختبار غير صحيحة"
    },
    "summary": {
      "title": "ملخص النتائج",
      "negative_subtitle": "ليس لديك COVID-19",
      "negative_message": "لقد أرسلنا إخطارًا إلى صاحب العمل الخاص بك بأنه مسموح لك بالعودة إلى العمل في الموقع.",
      "positive_subtitle": "نحن نعتذر، لكن أنت مصاب بمرض COVID-19",
      "positive_message": "لقد أرسلنا إخطارًا إلى صاحب العمل الخاص بك بأنك مصاب بـ COVID-19.",
      "what_should_you_do": "ماذا عليك أن تفعل:",
      "employer_name_resources": "موارد جهة العمل:",
      "follow_cdc_guidelines": "اتبع الإرشادات الموصى بها من مركز السيطرة على الأمراض"
    },
    "clarifying_results": {
      "title": "توضيح نتائجك",
      "which_did_your_result_most_look_like": "ماذا كانت توضح نتائجك؟",
      "no_lines": "لا توجد خطوط مرئية",
      "blue_control_line_only": "خط التحكم الأزرق فقط",
      "pink_sample_line_only": "خط العينة الوردي فقط",
      "blue_control_line_and_pink_sample_line": "خط التحكم الأزرق وخط العينة الوردي / الأرجواني",
      "still_not_sure": "لا يزال غير مؤكد",
      "submit_results": "إرسال النتائج",
      "thank_you": "شكرا لك!",
      "clarification_received_message": "تم استلام تقرير النتيجة الخاص بك.",
      "only_pink_line": "فقط خط وردي",
      "no_lines_v2": "بلا خطوط"
    },
    "return_to_confirmation": "العودة إلى صفحة التأكيد"
  },
  "preferred_language": {
    "title": "اللغة المفضلة",
    "subtitle": "أي لغة تفضل؟"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "سلسلة اللقاحات الاولية",
    "one_dose": "احجز موعدًا لجرعة محددة. على وجه الخصوص، هذه هي",
    "title": "اختيار الجرعة",
    "first_dose": "الجرعة الأولى",
    "second_dose": "الجرعة الثانية",
    "single_dose_title": "جرعة واحدة",
    "additional": "إضافي",
    "booster": "الداعم",
    "supplemental": "تكميلي",
    "third_dose": "الجرعة الثالثة فقط",
    "no_vaccine": "لا شيء مما سبق"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "يجب أن يكون بالتنسيق {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "يجب أن يكون بالتنسيق {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "الاختبار غير موجود"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "المستخدم موجود ولا يسمح له بتحديثه"
            },
            "date_of_birth": {
              "invalid_date": "يجب أن يكون بالتنسيق {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} غير موجود، اختر من القيم أدناه: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} غير موجود، اختر من القيم أدناه: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "النوع غير موجود، اختر من القيم أدناه: {{genders}}"
            },
            "phone_number": {
              "invalid": "رقم غير صحيح"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} غير موجود، اختر من القيم أدناه: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "لا يمكن تعبئة خانة النوع في حال \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "النوع غير موجود، اختر من القيم أدناه: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation غير موجودة، اختر من القيم الأخرى: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "الحالة غير صحيحة، اختر من القيم الأخرى: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} غير موجود، اختر من القيم أدناه: {{races}}",
                "other": "{{unknown_races}} غير موجود، اختر من القيم أدناه: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "امتيازات غير كافية لتعيين الدور"
            },
            "user_id": {
              "unknown_user": "المستخدم غير متوفر"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON غير صحيح"
            },
            "user_id": {
              "unknown_user": "المستخدم غير متوفر"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "موقع الموعد غير موجود"
            },
            "date_of_birth": {
              "invalid_date": "يجب أن يكون بالتنسيق {{datetime_format}}"
            },
            "email": {
              "duplicated": "لديه حساب مستخدم"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} غير موجود، اختر من القيم أدناه: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} غير موجود، اختر من القيم أدناه: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "النوع غير موجود، اختر من القيم أدناه: {{genders}}"
            },
            "phone_number": {
              "invalid": "الرقم غير صحيح"
            },
            "population": {
              "unknown_population": "{{unknown_population}} غير موجود، اختر من القيم أدناه: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "لا يمكن تعبئة خانة النوع في حال \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "النوع غير موجود، اختر من القيم أدناه: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation غير موجودة، اختر من القيم الأخرى: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} غير موجود، اختر من القيم أدناه: {{races}}",
                "other": "{{unknown_races}} غير موجود، اختر من القيم أدناه: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "تم استخدام العناوين في موقع آخر"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "من أصل لاتيني أو اسباني",
    "not_hispanic": "ليس من أصل لاتيني أو اسباني",
    "hispanic_expanded": {
      "other": "من أصل لاتيني أو اسباني آخر",
      "mexican": "مكسيكي، أمريكي مكسيكي، شيكانو",
      "puerto_rican": "بورتوريكو",
      "cuban": "كوبي"
    },
    "subtitle": "تتطلب القوانين تجميع هذه المعلومات.",
    "help": "يشير العرق إلى مجموعة من السمات المشتركة مثل اللغة والعادات الثقافية والدين وغيرها من الخصائص المستخدمة للتمييز بين مجموعات من الناس. قد تتماشى أو لا تتوافق مع الهوية العرقية أيضًا.",
    "unknown": "غير معروف",
    "title": "الأصل"
  },
  "sex_at_birth": {
    "question": "النوع",
    "female": "أنثى",
    "male": "ذكر",
    "help": "النوع يشير إلى ما هو في شهادة الميلاد.",
    "non_binary": "ثنائي النوع",
    "subtitle": "تتطلب القوانين تجميع هذه المعلومات.",
    "decline": "أرفض الإجابة",
    "unknown": "غير معروف"
  },
  "gender": {
    "help": "هوية النوع تشير إلى شخصيتك. وقد لا تتناسب مع النوع الذي نسب إليك يوم الولادة.",
    "subtitle": "إن أردت إضافة المعلومات عن هوية نوعك، يرجى الاستمرار."
  },
  "sexual_orientation": {
    "title": "التوجيه الجندري",
    "subtitle": "إن أردت إضافة المعلومات عن هوية نوعك، يرجى الاستمرار.",
    "gay": "مثلي الجنس، مثلية، أو مثلي الجنس",
    "heterosexual": "متغاير الجنس أو مستقيم",
    "bisexual": "ثنائي الجنس",
    "questioning": "أتساءل\\غير متأكد\\لا أعلم",
    "prefer_not_to_disclose": "أختار عدم الإجابة",
    "unknown": "غير معروف",
    "orientation_not_listed": "التوجيه الجندري غير مذكور",
    "not_applicable": "غير قابل للتطبيق",
    "pansexual": "بانسكسوال",
    "queer": "كوير"
  },
  "pronouns": {
    "title": "الضمائر المفضلة",
    "he": "هو ",
    "she": "هي ",
    "they": "هم",
    "choose_not_to_disclose": "أختار عدم الإجابة",
    "prefer_to_self_describe": "أفضل الوصف الذاتي"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "رخصة القيادة أو رقم الهوية",
    "driver_license": "رخصة القيادة",
    "use_ssn_instead": "استخدم SSN",
    "social_security_number": "رقم الحماية الاجتماعية",
    "state": "الولاية",
    "upload_front_of_driver_license": "حمّل الجزء الأمامي من رخصة القيادة أو رقم الهوية",
    "choose_file": "اختر ملفًا",
    "no_file_chosen": "لم يتم اختيار ملف",
    "no_identification": "ليس لديّ ملف تعريفي",
    "insurance_card_has_a_back": "لدى بطاقة التأمين الصحّي جزء خلفي.",
    "upload_front_of_insurance_card": "حمّّل الجزء الأمامي من بطاقة التأمين الصحي.",
    "front_of_insurance_card_uploaded": "تمّّ تحميل الجزء الأمامي من بطاقة التأمين الصحي.",
    "insurance_card": "بطاقة التأمين الصحي",
    "insurance_card_back": "الجزء الخلفي من بطاقة التأمين الصحي",
    "back_of_insurance_card_uploaded": "تمّ تحميل الجزء الخلفي من بطاقة التأمين الصحي"
  },
  "quidel": {
    "certify_text": "أقر بأنني أسجل نتائج هذا الاختبار لمرة واحدة فقط.",
    "entering_results": "إدخال النتائج الخاصة بك",
    "review_instructions_1": "راجع التعليمات بعناية",
    "review_instructions_2": "يمكنك مراجعة التعليمات المرفقة مع اختبارك، أو قراءة دليل تفصيلي، أو مشاهدة مقطع فيديو تعليمي أدناه:",
    "read_instructions": "اقرأ التعليمات",
    "watch_video": "شاهد الفيديو",
    "timer": "ابدأ المؤقت بعد تقليب المسحة في الأنبوب. سيتوقف المؤقت بعد دقيقة واحدة (لإزالة المسحة من الأنبوب). يرجى ملاحظة ما يلي: يجب أن تستأنف عداد الوقت لبدء الدقائق العشر لنتيجة اختبارك.",
    "start_timer": "ابدأ الموقت (اختياري)",
    "restart_timer": "توقف وأعد التشغيل",
    "resume": "سيرة ذاتية",
    "one_minute_countdown": "لقد مرت دقيقة واحدة! أزل المسحة من الأنبوب واستأنف المؤقت.",
    "take_photo": "التقط صورة لشريط الاختبار الخاص بك",
    "photo_warning": "تقديم صورة للمتابعة.",
    "enter_results": "أدخل نتائج الاختبار",
    "choose_result": "اختر الخيار الذي يعكس شريط الاختبار الخاص بك على أفضل وجه. تحتاج مساعدة؟ انقر هنا لمشاهدة أمثلة من النتائج.",
    "positive": "إيجابي",
    "negative": "سلبي",
    "unknown": "مجهول",
    "clarify": "توضيح نتائجك",
    "strip_question": "ما هو شكل شريط الاختبار الخاص بك أكثر؟",
    "no_lines": "لا يوجد خطوط",
    "pink_line": "خط وردي فقط",
    "not_sure": "لا أزال غير متأكد"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "صورة النتيجة",
    "retake_photo": "إعادة التقاط الصورة",
    "capture_photo": "التقاط الصورة",
    "confirm_information_correct": "أؤكد أنّ المعلومات المذكورة أعلاه صحيحة.",
    "submit": "أرسل النتيجة",
    "title": "أدخل النتائج المتعلقة ب {{name}}",
    "subtitle": "لست بحاجة الى تحميل أو استخدام تطبيق iHealth المختص بفيروس كورونا المستجد",
    "instruction_title": "تعليمات استخدام iHealth",
    "instruction_guide": "اقرأ التعليمات",
    "video_guide": "شاهد الفيديو",
    "add_photo": "أضف الصورة",
    "confirm_result": "تأكيد النتيجة",
    "result_warning": "اختر نتيجة من أجل  الإرسال",
    "confirm_warning": "تأكد من النتيجة من أجل التقديم",
    "administered_at_label": "متى أجريت الاختبار؟",
    "instruction_subtitle": "اعرض التعليمات المرفقة مع اختبارك أو  اعرض التعليمات هنا."
  },
  "public_test_groups": {
    "title": "عدّة فحص كوفيد-19",
    "appointment_recoveries_title": "لقد عدت؟",
    "appointment_recoveries_button_text": "جد الرابط",
    "search_test_group_title": "First time on Primary?",
    "search_test_group_button_text": "Register here",
    "title_2": "إجراءات بسيطة للتسجيل والتقارير",
    "step_one": "تسجيل الحساب",
    "step_two": "اجمع عيّنة من اللعاب للإرسال",
    "step_three": "عرض النتيجة أونلاين",
    "title_3": "التسجيل لتفعيل عدّة الفحص",
    "population_title": "تسجيل",
    "population_button": "تفعيل",
    "faq_subtitle": "تعلّم المزيد",
    "faq_title": "أسئلة مكرّرة",
    "faq_1": "How do I return my test kit",
    "faq_1_footer": "مراجعة إرشادات فحص عينة اللعاب",
    "faq_2": "تمّ التسجيل من قبل ولكن نسيت كلمة السر",
    "faq_3": "كيف أفعّل عدّة الفحص الخاصة بي؟",
    "faq_2_subtitle": "As a returning user you can confirm your report by using the '**Find my link**' button above.",
    "faq_3_subtitle": "يرجى زيارة حسابك على Primary.Health باستخدام الاختيارات أدناه:",
    "footer_text_1": "This product has not been FDA cleared or approved but has been authorized for emergency use by FDA under an EUA;",
    "footer_text_2": "This product has been authorized only for the collection and maintenance of saliva specimens as an aid in the detection of nucleic acid from SARS-CoV-2 not for any other viruses or pathogens;",
    "footer_text_3": "The emergency use of this product is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of medical devices under Section 564(b)(1) of the Federal Food Drug and Cos- metic Act 21 U.S.C. § 360bbb-3(b)(1) unless the declaration is terminated or authorization is revoked sooner.",
    "description": "For your convenience you can activate your test kit using the buttons below.",
    "subtitle": "أوّل مرة على Primary؟ ",
    "subtitle_1": "حول عدّة فحص كوفيد-19",
    "faq_1_1": "وضع أنبوب العينة المغلق في الكيس الطبّي",
    "faq_1_2": "وضع كيس العيّنة في علبة عيّنات اللعاب ثم وضعه في علبة FedEx UN 3373.",
    "faq_1_3": "إزالة غطاء اللصاق من على علبة FedEx UN 3373 والضغط عليه للإغلاق بإحكام.",
    "faq_1_4": "يرجى تسليم العينة في نفس يوم الاستلام وقبل نهاية موعد التسليم. لا تضع العينة خلال عطلة نهاية الأسبوع.",
    "faq_1_5": "زيارة موقع FedEx عبر **[fedex.com/labreturns](https://www.fedex.com/labreturns)** لعرض مواقع ومواعيد تسليم واستلام العيّنات.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "الإبلاغ عن نتيجة",
    "report_result_subtitle": "إذا كانت مؤسستك تقدم فحصا ذاتيا وكنت مستعد للإبلاغ عن النتيجة، فيرجى النقر فوق الزر أدناه.",
    "need_help": "تحتاجون مساعدة؟",
    "assistance": "إن كنتم تحتاجون للمساعدة أو تغيير تفاصيل الاتصال بكم، يرجى التواصل مع مركز الدعم.",
    "contact_support": "الاتصال بالدعم",
    "save_link": "حفظ الرابط لتسجيل النتائج لاحقًا.",
    "send_via_email": "إرسال الرابط عبر البريد الإلكتروني",
    "send_via_text": "إرسال الرابط عبر رسالة نصية",
    "copy_link": "نسخ الرابط",
    "resend_email": "إعادة إرسال رسالة التحقق عبر البريد الإلكتروني",
    "phone_verified": "تمّ التحقق من الهاتف",
    "add_vaccination_status": "إضافة\\تحديث حالة لقاح كوفيد-19",
    "terms_of_service": "شروط الخدمة",
    "verify_contact_information": "إن تمّ التحقق من بيانات التواصل بك، سوف تتمكن من استقابل النتائج والتواصل. إن احتجت لتغيير معلومات التواصل، يرجى التحدث مع مركز الدعم.",
    "resend_text": "إعادة مشاركة رسالة التحقق عبر الهاتف",
    "loading": "جار التحميل",
    "add_to_account": "الإضافة للحساب",
    "hi": "مرحبًا",
    "email_verified": "تمّّ التحقق من البريد الإلكتروني",
    "go_to_my_account": "الذهاب إلى حسابي.",
    "activate_kit": "تفعيل علبة الاختبار",
    "report_custom_result": "الإبلاغ عن اختبار: {{test}}",
    "activate_custom_test": "تفعيل الاختبار: {{test}}",
    "update_vaccination_status": "تحديث حالة التطعيم",
    "order_a_test_kit": "اطلب مجموعة اختبار"
  },
  "enter_information": {
    "contact_info": "أدخل جهات الاتصال الخاصة بك",
    "label": "الهاتف أو البريد الإلكتروني",
    "invalid": "بريد الكتروني أو هاتف غير صحيح",
    "send": "سوف نرسل الرمز إلى الهاتف أو البريد الإلكتروني",
    "legal": "من خلال مشاركة رقم الهاتف أو البريد الإلكتروني، أنت توافق على Primary Health’s **[شروط الخدمة](https://primary.health/terms-of-service/)** **[السياسة الخاص](https://primary.health/privacy-policy/)**.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "Registered? Find your link",
    "not_registered": "غير مسجل؟ ابحث عن موقعك",
    "search_by_keyword": "ابحث من خلال الإسم، والكلمة الرئيسية، المدينة، الرمز البريدي، أو رمز المنظمة!",
    "search": "ابحث",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "معلومات غير صحيحة",
    "invalid_explanation": "لقد طلبت رابط التسجيل إلى Primary.Health. ولكن، ",
    "never_registered": "لم تسجل من قبل! يرجى التواصل مع مزود الإختبار الخاص بك للحصول على رابط تسجيل أو العثور على موقعك أدناة.",
    "get_help": "يمكنك الحصول على مساعدة لمطابقة سجلك مع معلومات الاتصال الخاصة بك مع",
    "get_help_calling": "أو عبر الاتصال",
    "invalid_explanation_bold": "المعلومات التي وفّرتها غير صحيحة.",
    "get_help_label": "الحصول على المساعدة",
    "mobile": {
      "invalid_explanation": "لم يتم العثور على مستخدمين يمكن إضافتهم إلى حسابك.",
      "get_help": "إذا كان لديك أي أسئلة إضافية، يرجى التواصل",
      "reason": "ليس كل مستخدمي Primary.Health مؤهلين للحصول على ميزة حساباتنا الجديدة. بمجرد أن تكون مؤهلًا، ستتلقى دعوة من Primary.Health أو من مسؤول مجموعتك."
    },
    "zen_name": "اسم جهة الاتصال",
    "ticket_type": "بما يتعلق هذا؟",
    "appointment_access_code": "رمز التأكيد (اذا كان معروفا)",
    "zen_desc": "رسالة نصية",
    "contact_us": "تواصل معنا",
    "need_assistance": "إذا كنت بحاجة إلى المساعدة، أو لتغيير معلومات الاتصال الخاصة بك، يرجى الاتصال بحهات الدعم."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "تمت الإضافة إلى الحساب",
    "error": "قد يكون لدى هذا المشارك حساب بالفعل",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "ما هو الرمز؟",
    "enter_code": "سجّل الرمز الذي تم إرساله إلى",
    "incorrect_code": "لقد سجّلت الرمز الخاطئ.",
    "verify": "التحقق من الحساب",
    "verified": "تمّ التحقق",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "أيّ اختبار خضعت له؟",
    "photo_required": "صورة النتيجة مطلوبة",
    "find_your_test": "عرض نتيجة الاختبار"
  },
  "login": {
    "create_account": "إنشاء حساب",
    "create_account_help": "لا يعتبر انشاء حساب نفس عملية التسجيل للفحص أو للقاح. بمجرد التسجيل",
    "create_account_help_schedule": "تسجيل المواعيد",
    "create_account_help_record": "تسجيل الاختبارت المنزلية",
    "create_account_help_match": "إدارة سجلات التطعيم والاختبار.",
    "continue": "الاستمرار كضيف",
    "already": "لديك حساب؟ اضغط",
    "login": "لتسجيل الدخول.",
    "or_sign_in": "أو سجّل الدخول باستخدام:",
    "no_account": "ليس لديك حساب؟ اضغط",
    "signup": "للتسجيل.",
    "here": "هنا",
    "email_address": "البريد الإلكتروني:",
    "password": "Password",
    "complete_registration": "إكمال التسجيل",
    "last_covid_test": "نتيجة آخر فحص كوفيد-19",
    "no_record": "لا يوجد سجلّات سابقة",
    "viewing_information": "عرض معلومات {{first_name}}",
    "download": "تحميل",
    "not_registered": "يبدو أنّك لم تسجّل في أي مجموعة",
    "please_follow_link": "يرجى اتباع رسالة الدعوة المرسلة إلى البريد الإلكتروني لعرض مع Primary.Health.",
    "log_results": "تسجيل نتائج الاختبار الخاصة بي",
    "book_appointment": "تسجيل موعد",
    "no_new_appointments": "لا يتم قبول مواعيد جديدة حاليًا",
    "upload_vaccinations": "تحميل شهادات اللقاح",
    "vaccination": "اللقاح",
    "new_appointment": "موعد جديد",
    "log_otc_results": "سجّل نتائج فحص كوفيد-19 المنزلي",
    "no_household_members": "ليس لديك أفراد أسرة مسجّلين على الحساب.",
    "no_upcoming": "لا يوجد موعد قادم",
    "update_vaccination": "تحديث حالة اللقاح",
    "upload_vaccination": "تحديث حالة اللقاح",
    "title": "أهلًا بكم في Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "يتيح لك إنشاء الحساب:",
    "p2": "تسجيل مواعيد قادمة",
    "p3": "تسجيل اختبارات كوفيد-19 المنزلية",
    "p4": "إدارة سجلات التطعيم والاختبار لأفراد الأسرة",
    "p5": "إجراءات تسجيل بسيطة في my.primary.health",
    "no_vaccination_record": "لم يتم اضافة أي مطعوم حتى الان",
    "vaccination_title_card": "حالة التطعيم ضد كوفيد-19",
    "account_and_settings": "الحساب والاعدادات",
    "delete_account": "حذف الحساب",
    "language": "اللغة",
    "language_current": "اللغه الحالية: {{language}}",
    "log_out": "تسجيل الخروج",
    "delete_account_title": "هل أنت متأكد من أنك تريد حذف حسابك؟",
    "new_encounter": "لقاء جدید {{first_name}}",
    "new_encounter_subtitle": "لأي مجموعة ينتمي هذا اللقاء الجديد؟",
    "no_programs": "يبدو وكأنك غير مسجل في أي برامج",
    "edit_members": "تعديل الأعضاء",
    "edit_members_subtitle": "حدد لوحة القيادة التي ترغب في الانتقال اليها، أو اختر عضوا جديدا الى حسابك",
    "add_members": "اضافة عضوا",
    "delete_member": "حذف، {{full_name}} لصاحب الحساب",
    "delete_member_subtitle": "ستؤدي إزالة المستخدم إلى حذف كل المعلومات المتعلقة بالحساب",
    "select_member": "اختيار الأعضاء",
    "edit_appointment": "تعديل الموعد",
    "route": {
      "me": "لوحة القيادة",
      "history": "تاريخ",
      "programs": "اضافة فحص",
      "contact_us": "مساعدة",
      "choose_member": "عضو",
      "settings": "اعدادات",
      "help": "مساعدة"
    },
    "user_dashboard": "لوحة القياس {{first_name}}",
    "view_full_results": "عرض في موقع النتائج.",
    "required": "مطلوب اذا كنت تستخدم التطبيق الأساسي.",
    "no_email": "ليس لدي عنوان بريد الكتروني"
  },
  "vaccination_status": {
    "not_vaccinated": "لم أخضع للقاح",
    "fully_vaccinated": "تمّ أخذ اللقاح",
    "partially_vaccinated": "تمّّ أخذ اللقاح الجزئي",
    "vaccination_records": "سجلات التطعيم",
    "title": "ما هي حالة التطعيم الخاصة بك ضد فايروس كورونا COVID-19",
    "definition_title": "تعريف حالة التطعيم",
    "definition_cdc": "تعريف حالة التطعيم CDC",
    "definition_osha": "تعريف حالة التطعيم ضد OSHA",
    "definition": {
      "fully_vaccinated": "أسبوعين بعد تلقي الجرعة النهائية من سلسلة مكونة من جرعتين من فايزر-بيونتيك (Pfizer-BioNTech) أو موديرنا (Moderna)، أو بعد تلقي جرعة من لقاح جونسون آند جونسونز جانسين (Johnson & Johnson's Janssen)",
      "partially_vaccinated": "تلقيت جرعة واحده من أصل جرعتين رئيستين،في غضون أسبوعين أو أقل من تلقي الجرعة النهائية في سلسلة جرعتين أو جرعة واحدة من جونسون آند جونسونز جانسين (Johnson & Johnson's Janssen).",
      "not_vaccinated": "لم يتم الحصول على لقاح ال COVID-19",
      "fully_vaccinated_with_one_booster": "تلقيت جرعة معززة من لقاح فايزر بيونتيك (Pfizer-BioNTech) أو موديرنا (Moderna) بعد الحصول على سلسلة كاملة من اللقاح أو جرعة واحد من جونسون آند جونسونز جانسين ( Johnson & Johnson's Janssen)",
      "fully_vaccinated_with_two_boosters": "تلقيت جرعة معززة ثانية من لقاح فايزر بيونتيك (Pfizer-BioNTech) أو موديرنا (Moderna) بعد سلسلة جرعات كاملة أو جرعة معززة من لقاح جونسون آند جونسونز جانسين (Johnson & Johnson's Janssen)."
    },
    "label": {
      "fully_vaccinated": "مطعم بالكامل",
      "partially_vaccinated": "مطعم جزئيا",
      "not_vaccinated": "غير مطعم",
      "fully_vaccinated_with_one_booster": "مطعم بالكامل + جرعة معززة",
      "fully_vaccinated_with_two_boosters": "مطعم بالكامل + جرعتين معززتين",
      "prefer_not_to_answer": "أفضل عدم الاجابة"
    },
    "progress_bar_title": "تاريخ التطعيم ضد COVID-19",
    "upload_record_card": "قم بتحميل بطاقة سجل مطعوم COVID-19 الخاصة بك",
    "show_example": "عرض المثال",
    "hide_example": "إخفاء المثال",
    "take_photo": "انقر لإلتقاط صورة",
    "or": "أو",
    "upload_image_or_pdf_instead": "فم بتحميل صورة أو pdf بدلا من ذلك",
    "select_doses_received": "اختر جميع جرعات لقاح COVID-19التي تلقيتها.",
    "first_dose": "الجرعة الاولى",
    "second_dose": "الجرعة الثانية",
    "first_booster_dose": "الجرعة المعززة الاولى",
    "second_booster_dose": "الجرعة المعززة الثانية",
    "additional_dose": "جرعة اضافية",
    "first_dose_manufacturer": "ما نوع المطعوم الذي تلقيته لجرعة اللقاح الاولى ل COVID-19؟",
    "first_dose_date": "متى تلقيت الجرعة الاولى؟",
    "second_dose_manufacturer": "ما نوع المطعوم الذي تلقيته لجرعة اللقاح الثانية ل COVID-19؟",
    "second_dose_date": "متى تلقيت الجرعة الثانية؟",
    "first_booster_dose_manufacturer": "ما نوع المطعوم الذي تلقيته لجرعة اللقاح المعززة الاولى ل COVID-19؟",
    "first_booster_dose_date": "متى تلقيت الجرعة المعززة الاولى؟",
    "second_booster_dose_manufacturer": "ما نوع المطعوم الذي تلقيته لجرعة اللقاح المعززة الثانية ل COVID-19؟",
    "second_booster_dose_date": "متى تلقيت الجرعة المعززة الثانية؟",
    "additional_dose_manufacturer": "ما نوع المطعوم الذي تلقيته لجرعة اللقاح الاضافية ل COVID-19؟",
    "additional_dose_date": "متى كان موعد جرعتك الاضافية؟",
    "completed_one": "انتهى",
    "completed_two": "شكرا لتحديث حالة التطعيم الخاصة بك!",
    "progress_bar_complete": "انهاء",
    "upload_image": "حمل صورة",
    "retake_photo": "إعادة التقاط صورة",
    "other": "أخرى",
    "remove_first_dose": "إزالة سجل الجرعة الاولى",
    "remove_second_dose": "إزالة سجل الجرعة الثانية",
    "remove_first_booster_dose": "إزالة سجل الجرعة المعززة الاولى",
    "remove_second_booster_dose": "إزالة سجل الجرعة المعززة الثانية",
    "remove_additional_dose": "إزالة سجل الجرعة الاضافية",
    "exemption": "هل لديك إعفاء؟",
    "exempt": "أنا معفى",
    "not_exempt": "أنا غير معفى",
    "enter_exemption": "أدخل الإعفاء",
    "upload_exemption_documentation": "قم بتحميل مستندات الإعفاء الخاصة بك",
    "remove_dose": "سحب الجرعة",
    "continue": "استمر",
    "enter_credentials": "يرجى إدخال أوراق اعتمادك",
    "incorrect_credentials": "أوراق اعتماد غير صحيحة، يرجى المحاولة مرة أخرى.",
    "add_photo": "إضافة صورة"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "مرحبا {{name}}، لاحظنا بأنك لم تنه تسجيل موعدك {{group_name}} مع {{org_name}}. لتثبيت الموعد، عليك خطوات الدفع. يرجى الضغط على الرابط لإكمال الخطوة {{complete_url}}. في حال أردت إلغاء الموعد، يرجى الضغط على الرابط {{cancel_url}}. شكرًا لك."
    }
  },
  "otc": {
    "record_results_for_abbott": "إجراء الاختبار وتسجيل النتائج لفحص BinaxNOW",
    "record_results": "إجراء الاختبار وتسجيل النتائج",
    "activate_a_kit": "تفعيل علبة",
    "activate_a_kit_for_test": "تفعيل علبة {{test}}",
    "todays_test_process": "إجراءت فحص اليوم:",
    "date_of_birth_question": "ما هو تاريخ ميلادك يا {{first_name}}؟",
    "confirm_dob_to_continue": "يرجى تأكيد تاريخ ميلاد المشارك للاستمرار:",
    "incorrect_dob": "تاريخ الميلاد غير صحيح",
    "file_too_large": "يرجى التأكد بأنّ حجم الملف أقل من {{size}}",
    "enter_valid_date_in_last_x_days": "إدخال تاريخ صحيح في آخر {{n}}  يوم",
    "barcode_format_not_valid": "هيئة الرمز الشريطي غير صحيحة",
    "complete_button": "إكمال",
    "next_button": "التالي",
    "back_button": "العودة",
    "report_a_test": "الإبلاغ عن اختبار:",
    "x_%_completed": "{{n}}% مكتمل",
    "answer_survey": "الإجابة على أسئلة الاستبيان",
    "steps": {
      "answer_survey": "الإجابة على الاستبيان",
      "review_instructions": "عرض التعليمات",
      "scan_code": "مسح الرمز",
      "enter_time": "إدخال الوقت",
      "enter_result": "إدخال النتيجة",
      "take_photo": "تحميل الصورة",
      "confirm_result": "تأكيد النتيجة",
      "selest_test_kit": "اختبار نوع الاختبار",
      "enter_date": "إدخال التاريخ",
      "enter_date_and_time": "إدخال التاريخ والموعد"
    },
    "review_instructions": "عرض تعليمات الاختبار",
    "follow_instructions_or_below": "اتباع التعليمات الموجودة مع الاختبار أو عرض التعليمات أدناه.",
    "watch_video": "مشاهدة الفيديو",
    "view_instructions": "عرض التعليمات",
    "follow_instructions": "اتباع التعليمات الموجودة مع الاختبار.",
    "scan_code": "مسح رمز الاختبار (QR code)",
    "qr_code_description": "لديك رمز شريطي خاص بفحصك وتجده في المنطقة العليا على يمين علبة الاختبار",
    "show_example": "عرض مثال",
    "hide_example": "إخفاء المثال",
    "barcode_scanned": "تمّ مسح الرمز الشريطي بنجاح",
    "enter_barcode": "أدخال الرمز الشريطي",
    "scan_qr_code": "مسح الرمز (QR code)",
    "which_test_did_you_take": "أي اختبار أجريته؟",
    "when_did_you_take_test": "متى أجريت الاختبار؟",
    "what_day_did_you_take_test": "في أي يوم أجريت للاختبار؟",
    "what_time_did_you_take_test": "في أي وقت أجريت الاختبار؟",
    "time": {
      "today": "اليوم",
      "yesterday": "يوم أمس",
      "two_days_ago": "منذ يومين"
    },
    "enter_current_time": "إدخال الوقت الحالي",
    "enter_result": "إدخال نتيجة الاختبار",
    "choose_result_option": "اختيار الوصف المناسب لنتيجة الاختبار:",
    "clarify_results": "توضيح نتائجك",
    "take_result_photo": "التقاط صورة للنتائج",
    "take_result_photo_description": "التقط صورة لبطاقة النتائج لتسجيل النتائج.",
    "my_result": "نتيجتي",
    "switch_camera": "تبديل الكاميرا",
    "confirm_result": "تأكيد النتيجة",
    "name": "الاسم:",
    "date": "التاريخ:",
    "result": "النتيجة:",
    "results": "النتائج",
    "test_submitted": "شكرًا لك، {{first_name}}! تمّ تسجيل {{test}} بنجاح.",
    "dob": "تاريخ الميلاد:",
    "administered": "بإدارة:",
    "or": "أو",
    "upload": "تحميل",
    "change_uploaded_file": "تغيير الصورة التي تم تحميلها",
    "take_photo": "التقاط صورة",
    "confirm_information": "تأكيد المعلومات",
    "barcode": "الرمز الشريطي:",
    "scan_test_barcode": "مسح الرمز الشريطي للاختبار",
    "barcode_description": "لديك رمز شريطي خاص بفحصك وتجده في العلبة",
    "enter_date_in_last_30_days": "إدخال تاريخ في آخر 30 يوم مع الموعد التقريبي لوقت إجراء الاختبار",
    "add_image": "إضافة صورة",
    "change_image": "تغيير الصورة",
    "skip_barcode_reader": "لم تتمكن من مسح الرمز؟ اضغط على التالي لتسجيل نتيجة الفحص",
    "enter_date_and_time": "إدخال تاريخ وموعد الاختبار",
    "enter_date": "إدخال تاريخ الاختبار",
    "did_you_take_test_today": "هل أجريت الفحص اليوم؟",
    "record_results_for_generic": "اختبر وسجل نتائج اختبارك لـ {{name}} الفحص",
    "choose_result_option_custom": "اختر الخيار الذي يصف نتيجة {{test_name}} الخاصة بك:"
  },
  "yes": "نعم",
  "no": "لا",
  "event_token": {
    "title": "الرجاء إدخال الرمز أدناه في الآلة للحصول على علبة الاختبار الخاصة بك",
    "loading_text": "جاري التحميل، يرجى العودة بعد دقيقة"
  },
  "appointment_recovery": {
    "complete_title": "أنت جاهز تماما!",
    "subtitle_1": "اذا كنت تستخدم ايفون، الرجاء النقر على **تم** في الزاوية العلوية اليسرى من هذه الشاشة للعودة الى لوجة التحكم.",
    "subtitle_2": "اذا كنت تستخدم جهاز يعمل بنظام الاندرويد، انقر على **X** في الزاوية العلوية اليسرى من هذه الشاشة.",
    "subtitle_0": "يرجى الخروج من المتصفح للمتابعة."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "علاقة توافقية",
  "finish_registration": {
    "title": "لإتمام عملية التسجيل",
    "subtitle": "يرجى إكمال المعلومات التالية قبل موعدك:",
    "button": "انهاء التسجيل"
  },
  "arab_ethnicity": {
    "arab": "عربي",
    "non_arab": "غير عربي",
    "prefer_not_to_answer": "أفضل عدم الاجابة",
    "question": "العرق العربي",
    "subtitle": "تتطلب اللوائح أن نجمع المعلومات التالية",
    "help": "يشير العرق إلى مجموعة من السمات المشتركة مثل: اللغة، العادات الثقافية، الديانة، وغيرها من الخصائص المستخدمة للتمييز بين مجموعة من الناس. قد يتماشى أو لا يتماشى مع الهوية العرقية أيضا"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "اعتمادا على نوع جهازك الشخصي، انقر فوق الرابط أدناه لتحميل بطاقة الصحة الأساسية."
    }
  },
  "stepper": {
    "next": "التالي",
    "back": "رجوع",
    "complete": "انهاء"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "قم بفتح تطبيق الكاميرا على جهازك",
    "point_to_qr_code": "أشر الى رمز الاجابة السريعة (QR code) وانقر على الرابط",
    "complete_registration": "اتمام التسجيل",
    "need_help": "هل تحتاج إلى مساعدة؟  تواصل معنا على"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "تصديق معلومات اللقاح",
    "select_checkbox": "يرجى مراجعة ما يلي واختيار الخانة  المناسبة أدناه قبل المتابعة.",
    "opportunity_to_read": "لقد أتيحت لي الفرصة لقراءة أو قرأ لي بيان/ بيانات معلومات اللقاح («VIS») أو صحيفة الوقائع الخاصة باستخدام الطوارئ («EUA») المقدمة لإعطاء اللقاح/ اللقاحات:",
    "vaccine_info_sheet": "صفحة معلومات اللقاح",
    "vaccine_fact_sheet": "نشرة الحقائق المتعلقه باللقاح",
    "vaccine_info_statement": "بيان معلومات اللقاح"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "تتضمن هذه المجموعة اختبارات الكلاميديا ​​والسيلان.",
    "B": "تتضمن هذه المجموعة اختبارات الكلاميديا ​​والسيلان.",
    "C": "تتضمن هذه المجموعة اختبارات لمرض الزهري.",
    "D": "تتضمن هذه المجموعة اختبارات لفيروس نقص المناعة البشرية والزهري والكلاميديا ​​والسيلان.",
    "E": "تتضمن هذه المجموعة اختبارات لفيروس نقص المناعة البشرية والزهري",
    "box_type": "صندوق {{type}}",
    "administered_text": "سوف نتصل بك عندما تصل مجموعتك إلى المختبر وعندما تصبح نتائجك جاهزة.",
    "activate_kit": "قم بتنشيط مجموعة الفحص الخاصة بي",
    "register_another_test": "تسجيل فحص اخر",
    "credentials": {
      "title": "لنبدأ",
      "label": "تواصل",
      "p1": "سنبدأ مع بعض المعلومات التي تخصك",
      "location_step": {
        "p1": "من قام بتقديم مجموعة الفحص الخاصة بك؟",
        "load": "تحميل المزيد"
      }
    },
    "checklist": {
      "not_urinated": "لم تتبول في الساعة الماضية",
      "bathroom": "الرجاء الذهاب إلى الحمام لجمع عينة البول ",
      "anal_swab": "لديك الحق في الوصول إلى مكان خاص لإجراء مسحة الشرج.",
      "blood_extraction": "لديك الحق في الوصول إلى بيئة نظيفة لاستخراج الدم.",
      "washed_hands": "غسلت يديك بالصابون والماء الدافئ لمدة 30 ثانية.",
      "title": "هل انت مستعد لبدء جمع العينات؟",
      "no_alcohol": "الرجاء عدم شرب الكحول في يوم جمع العينات",
      "menstruation": "الرجاء عدم جمع عينة المسحة المهبلية أثناء فترة الحيض أو خلال 24 ساعة من الجماع"
    },
    "confirm": {
      "title": "الرجاء بتاكيد معلوماتك",
      "p1": "هل هذه المعلومات صحيحه؟"
    },
    "display_name": {
      "A": "الكلاميديا ​​والسيلان (نفس الموقع)",
      "B": "الكلاميديا ​​والسيلان (3 مواقع)",
      "C": "مرض الزهري",
      "shortened": {
        "A": "الكلاميديا ​​والسيلان ",
        "B": "الكلاميديا ​​والسيلان ",
        "C": "مرض الزهري"
      },
      "CC": "فحص سرطان القولون (FIT)",
      "CE": "فحص مرض الاضطرابات الهضمية",
      "CR": "فحص الكرياتين",
      "D": "اختبار الـ 4 عينات - فيروس نقص المناعة المكتسبه ومرض الزهري والكلاميديا ​​والسيلان (موقع واحد)",
      "DD": "فحص السكري (HbA1c)",
      "E": "فيروس نقص المناعه المكتسبه ومرض الزهري",
      "H": "فيروس الورم الحليمي",
      "P": "فيرس الوباء الكبدي نوع C",
      "HS": "فيروس الهربس البسيط",
      "I": "فيروس نقص المناعه المكتسبه",
      "TT": "مستوى التيستيرون",
      "VD": "فيتامين د",
      "VS": "المراقبة الفيروسية",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "اعادة المسح",
    "scanned": "لقد قمت بمسح الصندوق %{نوع} ",
    "not_yet": "ليس بعد",
    "success_registration": "تم التسجيل بنجاح!",
    "no_box": "لا تملك صندوق؟",
    "faq_link": "الاضطلاع على الأسئلة الأكثر شيوعا",
    "welcome": "اهلا!",
    "scan": {
      "title": "أنت على بعد خطوة واحدة من التمتع بصحة أفضل",
      "p1": "قم بمسح أو إدخال **الرمز الشريطي للمجموعة** الموجود على جانب الصندوق الخاص بك."
    },
    "appointments": {
      "title": "قم بتحديد المريض ومقدم الخدمة",
      "p1": "لقد وجدنا أكثر من ملف تعريف مرتبط بمعلومات الاتصال الخاصة بك. الرجاء تحديد ملف التعريف الصحيح:",
      "new": "مريض جديد او مقدم خدمه جديد"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "لقد وجدنا سجلاتك المرضيه."
    },
    "section_1": "تأكيد محتويات المجموعة",
    "section_2": "مجموعة الفحص ",
    "section_3": "التعبئة والتغليف",
    "section_4": "الشحن",
    "box_a": "صنوق A",
    "box_b": "صندوق B",
    "box_c": "صندوق C",
    "kit_flow": {
      "button_1": "مواصلة التعبئه والتغليف",
      "button_2": "انا جاهز",
      "button_3": "مجموعتي كامله ومعبأه",
      "button_4": "لقد انتهيت",
      "button_5": "الفحص التالي ",
      "button_6": "تخطي التعليمات"
    },
    "contents": {
      "header": "رائع! لنتأكد ان مجموعتك جاهزة",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "بطاقة جمع الدم",
      "blood_collection_card_subtitle": "(1 أو 2 حسب مجموعة الفحص)",
      "alchohol_pads": "2 من المسحات الكحولية",
      "lancets": "مشارط ذات استعمال واحد",
      "up_to_other": "(ما يصل إلى 4، اعتمادًا على مجموعة الفحص)",
      "adhesive_bandages": "ضمادات لاصقة",
      "pipette": "ماصة اطفال",
      "urine_tube": "أنبوب جمع البول",
      "urine_cup": "كأس جمع البول",
      "oral_swab": "مسحة الفم",
      "oral_tube": "انبوب الجمع الفموي",
      "anal_swab": "مسحة الشرج",
      "anal_tube": "انبوب الجمع الشرجي",
      "vaginal_tube": "1 انبوب الجمع المهبلي",
      "vaginal_swab": "1 مسحه مهبلية",
      "biodegradable_paper": "ورق جمع قابل للتحلل",
      "bottle_and_probe": "علبة أخذ العينات والمجس",
      "prepaid_envelope": "مغلف شحن مدفوع مقدمًا",
      "biohazard_subtitle": "(مع ضماده قابله للامتصاص)",
      "biohazard_bag": "1 حقيبة عينات ",
      "biohazard_bag_plural": "({{count}}) الاكياس البيولوجيه",
      "alcohol_pads_plural": " {{count}} ضمادات الكحول",
      "sterile_gauze_pad": "1 قطعة شاش معقمة"
    },
    "packaging": {
      "title": "قائمة التحقق من التعبئة والتغليف",
      "subtitle": "قبل ان تقوم بشحن المجموعه الخاصة بك، يرجى التأكد من: "
    },
    "packaging_box_a": {
      "step_1": " قمت بتعبئة جميع محتويات مجموعة الفحص",
      "step_2": "أنك استخدمت جميع مواد التجميع الموجودة في المجموعة لجمع العينات المطلوبة",
      "step_3": "أن **تاريخ ميلادك** مكتوب على جميع أنابيب جمع العينات بصيغة MM/DD/YYYY",
      "step_4": "أن ** تاريخ الجمع** مكتوب على جميع أنابيب جمع العينات بصيغة MM/DD/YYYY",
      "step_5": "أن أنبوب جمع العينات محفوظ بشكل محكم في حقيبة المخاطر البيولوجية الخاص به",
      "step_6": "أن جميع العينات قد تم وضعها في الصندوق",
      "step_7": "أن الصندوق قد تم وضعه في مغلف الشحن المدفوع مسبقًا",
      "step_8": "أن مغلف شحن الإرجاع مغلق تمامًا"
    },
    "packaging_box_b": {
      "step_1": "أنك قمت بتعبئة جميع محتويات مجموعة الفحص، حتى لو تخطيت الفحص/ الفحوصات ",
      "step_2": "أنك استخدمت جميع مواد التجميع الموجودة في المجموعة لجمع العينات المطلوبة",
      "step_3": "أن **تاريخ ميلادك** مكتوب على جميع أنابيب جمع العينات بصيغة MM/DD/YYYY",
      "step_4": "أن **تاريخ الجمع** مكتوب على جميع أنابيب جمع العينات بصيغة MM/DD/YYYY",
      "step_5": "أن كل أنبوب لجمع العينات محفوظ بشكل محكم في حقيبة المخاطر البيولوجية الخاص به (عينة **واحدة** فقط لكل كيس)",
      "step_6": "أن جميع العينات تم وضعها في الصندوق",
      "step_7": "أن الصندوق تم وضعه في مغلف الشحن المدفوع مسبقًا",
      "step_8": "أن مغلف شحن الإرجاع مغلق بإحكام"
    },
    "packaging_box_c": {
      "step_1": "أنك قمت بتعبئة جميع محتويات مجموعة الفحص، بما في ذلك المشارط المستعملة.",
      "step_2": "أن **تاريخ ميلادك** مكتوب على بطاقة جمع الدم بصيغة MM/DD/YYYY",
      "step_3": "أن **تاريخ الجمع** مكتوب على بطاقة جمع الدم بصيغة MM/DD/YYYY",
      "step_4": "أن بطاقة جمع الدم و جميع المشارط المستخدمة قد تم وضعها في حقيبة المخاطر البيولوجية",
      "step_5": "أن حقيبة المخاطر البيولوجية قد تم وضعها في الصندوق",
      "step_6": "أن الصندوق قد تم وضعه في مغلف الشحن المدفوع مسبقًا",
      "step_7": "أن مغلف شحن الإرجاع مغلق تمامًا"
    },
    "success": {
      "header_1": "رائع!",
      "text_1": "أنت الان جاهز لجمع عينتك الأولى.",
      "header_2": "لقد فعلتها!",
      "text_2": "**الخطوة التالية** قم بتعبئة مجموعتك لإرسالها مرة أخرى.",
      "header_3": "عمل رائع!",
      "text_3": "أنت على بعد خطوة واحدة من صحة أفضل.",
      "header_4": "عمل جيد!",
      "text_4": "مره اخرى فقط وستنتهي!",
      "text_5": "انت الان مستعد لجمع عينتك الاولى"
    },
    "instructions": {
      "title": "اختر فحصا لعرض التعليمات:",
      "subtitle": "فحص",
      "button": "تعليمات",
      "pdf": "تعليمات بصيغة PDF"
    },
    "instructions_box_a": {
      "title": "جمع البول",
      "subtitle": "ستحتاج المواد التالية لهذا الفحص:",
      "step_1": "لا تتبول لمدة ساعة على الأقل قبل موعد الجمع. جمع عينة من أول تبول صباحي هو الأفضل.",
      "step_2": "تبول مباشرة في حاضنة البول أو في كأس الجمع، واملأ ما بين 1/3 و 1/2.",
      "step_3": "اسكب من حاضنة البول أو استخدم الشافطه لنقل البول من كأس الجمع  إلى أنبوب/ أنابيب التجميع المميزة بلون برتقالي <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "املأ أنبوب/ أنابيب الجمع حتى يصبح السائل المدمج بين خطوط التعبئة المحددة.",
      "step_4_sublabel": "لا تملأ الأنبوب بعد الحد الأقصى لخط التعبئة، وإلا سيتم رفض العينة.",
      "step_5": "ضع الغطاء على أنبوب الجمع وتأكد من إغلاقه بشكل متساو ومحكم.",
      "step_6": "اكتب تاريخ ميلادك وتاريخ جمع العينات (تاريخ اليوم) بصيغة MM/DD/YYYY على الأنبوب في المنطقة المخصصة.",
      "step_7": "ضع العينة في كيس بيولوجي فارغ مع لاصقه قابله للامتصاص وتأكد من إغلاق الختم بالكامل."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "جمع البول",
        "subtitle": "ستحتاج إلى المواد التالية لهذا الفحص:",
        "step_1": "لا تتبول لمدة ساعة على الأقل قبل موعد الجمع. جمع عينة من أول تبول صباحي هو الأفضل.",
        "step_2": "تبول مباشرة في حاضنة البول أو في كأس الجمع، واملأ ما بين 1/3 و 1/2.",
        "step_3": "اسكب من حاضنة البول أو استخدم الشافطه  لنقل البول من كاس الجمع  إلى أنبوب/ أنابيب الجمع المميزة بلون برتقالي <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "املأ أنبوب/ أنابيب التجميع حتى يصبح السائل المدمج بين خطوط التعبئة المحددة.",
        "step_4_subtitle": "لتفعيل التوافق مع قارئ الشاشة، يمكنك الضغط على للتعرف على معلومات حول اختصارات لوحة المفاتيح، يمكنك الضغط على شرطة مائلة",
        "step_5": "ضع الغطاء على أنبوب التجميع وتأكد من إغلاقه بشكل متساوٍ ومحكم.",
        "step_6": "اكتب تاريخ ميلادك وتاريخ جمع العينات (تاريخ اليوم) بتنسيق  التاليMM/DD/YYYY على الأنبوب في المنطقة المخصصة.",
        "step_7": "ضع العينة في كيس بيولوجي فارغ مع ضماده قابل للامتصاص وتأكد من إغلاق الختم بالكامل."
      },
      "test_2": {
        "title": "مسحه فموية",
        "subtitle": "من اجل اجراء هذا الفحص فانت بحاجة الى المواد الاتية:",
        "step_1": "أمسك المسحة بين خط النتيجة ذو اللون الباهت (إن وجد) والجزء المغطى بالقطن من المسحة.",
        "step_2": "أدخل المسحة في فمك وامسح طرف المسحة على الجزء الخلفي من حلقك لمدة 10 ثوانٍ.",
        "step_3": "اسحب المسحة بعناية وضعها في أنبوب الجمع المميز باللون الأزرق <span style=\"color: #001F70\">**\"ORAL\"**</span> label. قم بفصل المسحة عند خط النتيجة عن طريق ثنيها عكس انبوب الجمع.",
        "step_4": "ضع الغطاء على أنبوب الجمع وتأكد من إغلاقه بشكل متساوٍ ومحكم.",
        "step_5": "اكتب تاريخ ميلادك وتاريخ جمع العينات (تاريخ اليوم) بصيغة MM/DD/YYYY على الأنبوب في المنطقة المخصصة.",
        "step_6": "ضع العينة في كيس بيولوجي فارغ مع ضماده ماصة وتأكد من إغلاق الختم بالكامل."
      },
      "test_3": {
        "title": "مسحة شرجية",
        "subtitle": "من اجل اجراء هذا الفحص فانت بحاجة الى المواد الاتية:",
        "step_1": "أمسك المسحة بين خط النتيجة ذو اللون الباهت (إن وجد) والجزء المغطى بالقطن من المسحة.",
        "step_2": "أدخل المسحة بمقدار 3 - 5 سم (1 - 2 بوصة) في القناة الشرجية. حرك المسحة بلطف لمدة 5 - 10 ثواني لجمع أي كائنات محتملة.",
        "step_3": "اسحب المسحة بعناية وضعها في أنبوب الجمع المميز باللون الأخضر <span style=\"color: #00C08C\">**“ANAL”**</span> label. قم بفصل المسحة عند خط النتيجة عن طريق ثنيها عكس  أنبوب التجميع.",
        "step_4": "اسحب المسحة بعناية وضعها في أنبوب الجمعالمميز بالملصق الأخضر.قم بكسر المسحة عند خط النتيجة عن طريق ثنيها عكس انبوب الجمع",
        "step_5": "اكتب تاريخ ميلادك وتاريخ جمع العينات (تاريخ اليوم) بتنسيق MM/DD/YYYY على الأنبوب في المنطقة المخصصة لذلك",
        "step_6": "ضع العينة في كيس بيولوجي فارغ مع ضمادة قابلة للامتصاص وتأكد من إغلاق الختم بالكامل."
      }
    },
    "instructions_box_c": {
      "title": "جمع الدم",
      "subtitle": "ستحتاج للمواد التاليه لهذا الفحص:",
      "step_1": "اكتب اسمك، وتاريخ ميلادك، وتاريخ الاستلام في الحقول المخصصة لذلك. استخدم صيغة التاريخ التالية MM/DD/YYYY.",
      "step_2": "افتح غطاء بطاقة الدم لكشف الدوائر الموجودة على ورقة جمع الدم. لا تلمس ورقة جمع الدم.",
      "step_3": "اغسل يديك بالماء الدافئ لمدة 30 ثانية على الأقل، ثم صافح اليدين ​​بقوة لمدة 15 ثانية لتعزيز تدفق الدم إلى أصابعك.",
      "step_4": "نظف أطراف الأصابع باستخدام ضمادة الكحول. من الأفضل استخدام الإصبع الأوسط أو البنصر ليدك غير المسيطرة.",
      "step_5": "خذ المشرط وقم بلف الغطاء. اضغط على الطرف الصغير بقوة في طرف إصبعك، حتى تخرج الإبرة بنقرة واحدة. أبقِ يدك تحت قلبك أثناء التجميع، وقم بتدليك إصبعك من القاعدة إلى الطرف لتعزيز تدفق الدم.",
      "step_5_subtitle": "المشارط تستخدم لمرة واحدة. يجب إعادة جميع المشارط مع عينتك إلى المختبر للتخلص منها.",
      "step_6": "بدءًا من المركز، ضع 3-6 قطرات لملء الدائرة وانقعها  في ورق التجميع. لا تلمس الورقة بإصبعك لأن ذلك سيقيد تدفق الدم. عندما تمتلئ الدائرة، انتقل إلى الدائرة التالية. لا بأس أن يمتد الدم إلى ما بعد الخطوط، لكن لا تدع بقع الدم تنتشر في بعضها البعض.",
      "step_6_subtitle": "لا تقم بوضع دمًا إضافيًا إلى الدائرة بمجرد اكتمالها أو جفافها. \"طبقات\" الدم ستبطل عملية الجمع.",
      "step_7": "من غير إغلاق بطاقة الدم، ضعها على سطح مستو واترك ورقة جمع الدم تجف في الهواء في درجة حرارة الغرفة، لمدة 30 دقيقة على الأقل. لا تقم بتسخين أو تجفيف أو تعريض ورق جمع الدم لأشعة الشمس المباشرة. الحرارة سوف تلحق الضرر بالعينة.",
      "step_7_subtitle": "تحقق من الجانب الخلفي من ورقة جمع الدم. يجب أن يتشبع الدم طوال الاتجاه ويملأ كل دائرة من ورق التجميع.",
      "step_8": "عندما تجف ورقة جمع الدم، أغلق بطاقة الدم عن طريق دفع الغطاء. ضع بطاقة الدم والمشارط في حقيبة المخاطر البيولوجية مع عبوة المادة المجففة. تأكد من إغلاق حقيبة المخاطر البيولوجية بشكل صحيح."
    },
    "test_tips": {
      "title": "نصائح لجمع الدم ",
      "subtitle": "للحصول على افضل نتيجة:",
      "step_1": "تأكد من أنك لا تعاني من الجفاف أثناء إجراء عملية الجمع. الترطيب يعزز تدفق الدم.",
      "step_2": "لا تقم بجمع العينة مباشرة بعد التدخين.",
      "step_3": "غسل يديك وتدفئتهما تحت الماء الدافئ سيساعد على تعزيز تدفق الدم الى يديك.",
      "step_4": "قم بمصافحة الأيدي بقوة باتجاه الأرض لتشجيع تدفق الدم إلى أصابعك.",
      "step_5": "احتفظ بجهاز الجمع مع وضع اليدين أسفل قلبك أثناء جمع العينة للحصول على أفضل تدفق للدم.",
      "step_6": "قد تحتاج إلى أوخز أكثر من إصبع واحد. كرر هذه النصائح بين كل وخز إصبع."
    },
    "shipping": {
      "header": "مجموعتك جاهزة للشحن",
      "text": "**تهانينا** لقد انتهيت من فحص الصحة الجنسية."
    },
    "pick_up": "احصل على مجموعة الفحوصات الموصى بها من موظفي الموقع وقم بالتسجيل أدناه",
    "short_display_name": {
      "A": "الكلاميديا ​​والسيلان",
      "B": "الكلاميديا ​​والسيلان",
      "C": "مرض الزهري",
      "CC": "سرطان القولون",
      "CE": "الاضطرابات الهضمية",
      "CR": "الكرياتينين",
      "D": "فيروس نقص المناعة المكتسبة والزهري والكلاميديا ​​والسيلان",
      "DD": "السكري",
      "E": "فيروس نقص المناعه المكتسبه والزهري",
      "H": "فيروس الورم الحليمي البشري",
      "P": "فيروس الكبد الوبائي C",
      "HS": "الهربس البسيط",
      "I": "نقص المناعه المكتسبه",
      "TT": "التستيرون",
      "VD": "فيتامن د",
      "VS": "المراقبة الفيروسية"
    },
    "test_kit": "مجموعة الفحص ",
    "view_results": "عرض النتيجة",
    "recommended_kit_title": "مجموعات الفحص الموصى بها",
    "instructions_box_h": {
      "title": "جمع المسحة المهبلية",
      "subtitle": "قد تحتوي أنابيب الجمع على مادة حافظة سائلة. الرجاء عدم إفراغ الماده الحافظه السائلة من أنابيب الجمع إذا كانت موجودة",
      "step_1": "امسك  المسحة بين خط النتيجة ذو اللون الباهت (إن وجد) والجزء ذي الرؤوس القطنية من المسحة بيد واحدة وافصلي الشفرين (ثنيات الجلد حول فتحة المهبل).",
      "step_2": "أدخل المسحة بمقدار 5 سم (2 بوصة) في فتحة المهبل. حرك المسحة بلطف لمدة 30 ثانية أثناء فرك المسحة على جدران المهبل.",
      "step_3": "اسحبي المسحة بعناية وضعيها في أنبوب التجميع المميز (بنص خطر) ملصق (مهبلي أحمر) اكسر المسحة عن طريق ثنيها عكس أنبوب التجميع.",
      "step_4": "ضع الغطاء على أنبوب الجمع وتأكد من إغلاقه بشكل متساوٍ ومحكم.",
      "step_5": "اكتب تاريخ ميلادك بصيغة MM/DD/YYYY على الأنبوب في المنطقة المخصصة (تاريخ الميلاد).",
      "step_6": "اكتب تاريخ جمع العينات (تاريخ اليوم) بصيغة MM/DD/YYYY على الأنبوب في المنطقة المخصصة (تاريخ الجمع ).",
      "step_7": "ضع العينة في حقيبة المخاطر البيولوجية الفارغ باستخدام الوسادة الماصة وتأكد من إغلاق الختم تمامًا."
    },
    "packaging_box_h": {
      "step_3": "**تاريخ ميلادك** مكتوب على أنبوب جمع العينات بصيغة MM/DD/YYYY",
      "step_4": "**تاريخ الجمع** الخاص بك  مكتوب على أنبوب جمع العينات بصيغة MM/DD/YYYY",
      "step_6": "يتم وضع حقيبة المخاطر البيولوجية في الصندوق"
    },
    "instructions_box_cc": {
      "title": "جمع البراز",
      "step_1": "اكتب تاريخ ميلادك وتاريخ جمع العينات (تاريخ اليوم) بصيغة MM/DD/YYYY على الأنبوب في المنطقة المخصصة. افتح الغطاء الأخضر عن طريق لفه ومن ثم رفعه.",
      "step_2": "ضع ورق الجمع المرفق في وعاء المرحاض فوق الماء.",
      "step_3": "قم بإيداع عينة البراز فوق ورق التجميع.",
      "step_4": "اجمع العينة من البراز قبل غرق الورق وتلامس عينة البراز الماء.",
      "step_5": "اكشط سطح عينة البراز باستخدام مسبار العينة.",
      "step_6": "قم بتغطية الجزء المحزز من مسبار العينة بالكامل بعينة البراز.",
      "step_7": "اغلق زجاجة أخذ العينات عن طريق إدخال مجس العينة وغلق الغطاء الأخضر بإحكام. لا تعيد فتحه.",
      "step_8": "تدفق ورق التجميع قابل للتحليل ولن يضر أنظمة الصرف الصحي.",
      "step_9": "لف زجاجة أخذ العينات في ضماده قابلة للامتصاص وأدخلها في حقيبة المخاطر البيولوجية."
    },
    "contact_support": {
      "title": "لنقم باصلاح ذلك",
      "text_1": "نأسف لسماع أن هناك خطأ ",
      "text_2": "يرجى الاتصال بنا لإعلامنا بالخطأ وسنساعدك في استبدال مجموعة الفحص الخاصه بك"
    },
    "contact_support_success": {
      "title": "لقد تلقينا رسالتك",
      "text_1": "شكرالتواصلك معنا.",
      "text_2": "سيتواصل معك أحد أعضاء فريق الدعم قريبًا.",
      "button_text": "ارجع إلى المنزل"
    },
    "kit_in_transit_to_patient": "طلبك في الطريق! سيتم تسليم معظم الطلبات خلال 2-5 أيام عمل. بمجرد استلام المجموعة الخاصة بك، سنرسل إليك المزيد من المعلومات حول كيفية تنشيطها وإرسالها مرة أخرى. رابط التتبع: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "لقد وصلت مجموعتك الى المختبر ستتلقى إشعارا للتحقق من ملفك الطبي عندما تكون النتائج جاهزة",
    "registration_confirmation": "اكتمل تسجيلك الآن! قم بزيارة صفحة تأكيد التسجيل لتفعيل مجموعة الفحص الخاصة بك:",
    "kit_ordered_online": "لقد قمنا بتلقي طلب المجموعة الخاصة بك ووسنقوم بإعلامك في حال تم الشحن!  طلب #: {{order_number}} تاريخ الطلب: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "لقد تلقينا طلبك!",
      "p1": "لقد تلقينا طلبك! بمجرد شحن طلبك، سنرسل إليك تحديثًا آخر.",
      "order_number": "رقم الطلب:",
      "order_date": "تاريخ الطلب"
    },
    "completed": "اكتمل",
    "I": "تتضمن هذه المجموعة فحص لفيروس نقص المناعة المكتسبة.",
    "CC": "تتضمن هذه المجموعة فحص لسرطان القولون.",
    "CE": "تتضمن هذه المجموعة فحص لمرض الاضطرابات الهضمية.",
    "CR": "تتضمن هذه المجموعة افحص للكرياتينين.",
    "DD": "تتضمن هذه المجموعة اختبارات لمرض السكري.",
    "H": "تتضمن هذه المجموعة فحص لفيروس الورم الحليمي البشري.",
    "P": "تتضمن هذه المجموعة فحص لفيروس الكبد الوبائي C.",
    "HH": "تتضمن هذه المجموعة فحص للهيموجلوبين.",
    "HS": "تتضمن هذه المجموعة فحص لفيروس الهربس.",
    "TT": "تتضمن هذه المجموعة فحص لهرمون التستيرون.",
    "VD": "تتضمن هذه المجموعة فحص ل فيتامين D."
  },
  "copy_link": "انسخ رابط الصفحة",
  "copied_link": "تم نسخ الرابط!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "الحماية ضد فيروسات الأنفلونزا الشائعة",
      "vaccine_covid": "الحماية ضد فايروسات كورونا",
      "tdap": "لقاح Tdap يمكن أن يمنع الكزاز والسعال الديكي",
      "polio": "الحماية من فيروس شلل الأطفال. مطلوب اعطائة للأطفال قبل بدء المدرسة.",
      "varicella": "لقاح يحمي من مرض جدري الماء",
      "shingles": "لقاح يحمي من مرض القوباء. يسمح بهذا اللقاح للفئة العمرية الـ 18 فما فوق.",
      "human_papillomavirus": "لقاح يحمي من فيروس الورم الحليمي البشري. يوصى بها للأطفال بعمر 11 عامًا.",
      "meningococcal": "يحمي اللقاح من أربعة أنواع من بكتيريا المكورات السحائية."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "ابحث عن عيادة",
    "select_pin": "اختر دبوس لرؤية التفاصيل",
    "miles_shorten": "اميال {{miles}}",
    "next_available": "التالي المتاح",
    "appointment_type": "نوع الموعد",
    "individual_appointment": "موعد فردي",
    "family_appointment": "موعد عائلي",
    "vaccines": "اللقاحات",
    "what_kind_appointment": "ما نوع الموعد الذي تريد حجزه؟",
    "select_family_or_individual": "حدد موعدًا عائليا إذا كنت تقوم بجدولة عدة أفراد من العائلة نفسها في وقت واحد.",
    "vaccines_selected": "اختيار اللقاح",
    "where_care": "اين تريد تلقي العناية؟",
    "select_vaccines_for_individual": "حدد اللقاحات لموعدك (اختياري).",
    "select_vaccines_for_family": "حدد اللقاحات لموعدك العائلي (اختياري).",
    "schedule_services": "جدولة خدمات",
    "add_family": "Add family members and select services below.",
    "family_member": "فرد العائله {{number}}",
    "morning": "فترة الصباح",
    "afternoon": "فترة ما بعد الظهر",
    "slot_available": "%{رقم} متاح",
    "within_x_miles": "خلال %{أميال} اميال لـ",
    "any_distance": "اي مسافة من",
    "partial_results": "نتائج جزئية",
    "partial_matches": "تقدم العيادات التالية بعض اللقاحات التي تبحث عنها وليس جميعها",
    "no_matches": "لم يتم العثور على نتائج مطابقة خلال هذا البحث. حاول تغيير خيارات الفلتر الخاصة بك للحصول على نتائج أفضل.",
    "no_clinics_found": "لا يوجد اي عيادة تطابق البحث الدي قمت فيه",
    "broaden_filters": "حاول توسيع خيارات الفلترة الخاصة بك للحصول على نتائج أفضل.",
    "unavailable_vaccines": "مواعيد غير متاحة",
    "available_vaccines": "لقاحات متوفرة",
    "select_date": "اختيار موعد",
    "available_appointments": "مواعيد متاحة ",
    "appointment_scheduling_info": "سوف تكون قادرا على تحديد مواعيد الزيارة لأفراد العائلة المحدده في المرة القادمة. ويمكنك جدولة مواعيدك في ايام مختلفه من اختيارك ",
    "hold_selected_appointments": "سنقوم بتأخير موعدك المحدد لمدة ١٥ دقيقة",
    "appointments_selected": "تم اختيار/ تحديد موعد",
    "no_appointments_selected": "لم يتم اختيار/تحديد موعد",
    "vaccines_needed": "بحاحة الى اللقاح",
    "select_x_appointments": "اختيار {{number}} المواعيد",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "لنقوم بتأكيد عنوان بريدك الالكتروني!",
    "hi_full_name": "مرحبآ {{full_name}}،",
    "verify_email_button": "تأكيد الايميل",
    "please_verify_email": "من فضلك، استعمل الزر في الاسفل لتأكيد بريدك الالكتروني"
  },
  "services": {
    "dptap_dt": "الخناق /الكزاز /السعال الديكي",
    "hepatitis_a": "فيروس الكبد الوبائي A",
    "hepatitis_b": "فيروس الكبد الوبائي B",
    "hepatitis_a_b": "فيروس الكبد الوبائي A&B",
    "hib": "لقاح التهاب السحايا الجرثومي",
    "mmr": "الحصبة، والنكاف، والحصبة الألمانية",
    "meningococcal": "التهاب السحايا (المكورات السحائية) ",
    "mpox": "فيروس جدري القردة",
    "pneumococcal": "جرثومة الالتهاب الرئوي (العقدية الرئوي) ",
    "polio": "شلل الاطفال/ المصاب بشلل الاطفال",
    "rsv": "فيروس المخلوي التنفسي (فيروس يصيب الحهاز التنفسي) ",
    "rotovirus": "فيروس الروتا (فيروس التهاب الامعاء) ",
    "zoster": "مرض القوباء",
    "tetanus_diptheria": "مرض الكزاز والخناق ",
    "tdap": "لقاح اﻟﺗﯾﺗﺎﻧوس، واﻟدﻓﺗﯾرﯾﺎ، واﻟﺳﻌﺎل اﻟدﯾﮐﻲ",
    "typhoid": "حمى التيفوئيد",
    "varicella": "جدري الماء",
    "covid-19": "مرض الكورونا الفيروسي",
    "covid-19_adult": "مرض الكورونا الفيروسي (للبالغين) ",
    "covid-19_adult_description": "للاشخاص من عمر 12 سنه وأكثر",
    "covid-19_children": "مرض الكورونا الفيروسي (للاطفال) ",
    "covid-19_children_description": "للاطفال من عمر 4 سنوات لغاية 11 سنه",
    "covid-19_infants": "مرض الكورونا الفيروسي (الرضع) ",
    "covid-19_infants_description": "للاطفال من عمر 6 اشهر - 3 سنوات",
    "influenza": "الانفلونزا",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}