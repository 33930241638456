import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Dropdown,
  Form,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { printPdfLabelOnClick } from './PdfLabelPrinter';
import { zplCodeOnClick } from './ZplCodePrinter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faCheck,
  faXmark,
  faArrowRight,
  faEyeSlash,
  faEye,
} from '@fortawesome/pro-solid-svg-icons';
import { faLockOpen, faLock, faPen } from '@fortawesome/pro-regular-svg-icons';
import { getTestResultType } from '../../shared_labs/utils';
import SurveyPreview from '../../common/components/SurveyPreview';
import AbbottResultOptions from './configurations/AbbottResultOptions';
import GenericResultOptions from './configurations/GenericResultOptions';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import ServiceCard, {
  CardOptionsWrapper,
  SectionsWrapper,
  ServiceSummaryContainer,
  SummaryBadge,
} from '../../common/components/ServiceCard';
import TestImageCapture from '../../common/components/TestImageCapture';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import Wrapped from '../../common/components/Wrapped';

export const getResultsLink = (serviceSpecification, test = {}) => {
  if (!test) {
    return false;
  }

  return getTestResultType(serviceSpecification);
};

export const hasPermissionToResultTest = (
  permission_kinds,
  editable_test_types,
  test,
) =>
  permission_kinds.includes(test?.test_configuration?.kind) &&
  editable_test_types.includes(test?.test_configuration?.test_type);

export const renderResultInterfaceLink = (
  serviceSpecification,
  test,
  saveAndGoToResultInterface,
  submitTests,
  saveTestGroupUrlToLocalStorage,
  setRedirectCallbackPath,
  isGroupOnDemandOnly
) => {
  const link = getResultsLink(serviceSpecification, test);
  if (!link) {
    return null;
  }

  saveAndGoToResultInterface(
    `/${link}/results/${test.uid}`,
    test,
    submitTests,
    saveTestGroupUrlToLocalStorage,
    setRedirectCallbackPath,
    isGroupOnDemandOnly
  );
};

export const saveAndGoToResultInterface = (
  path,
  test,
  submitTests,
  saveTestGroupUrlToLocalStorage,
  setRedirectCallbackPath,
  isGroupOnDemandOnly
) => {
  const newTest = { ...test, administered: true };
  submitTests([newTest]);
  saveTestGroupUrlToLocalStorage(isGroupOnDemandOnly, test);
  setRedirectCallbackPath(path);
};

export const saveTestGroupUrlToLocalStorage = (isGroupOnDemandOnly, test) => {
  const viewUrl = isGroupOnDemandOnly ? 'test_group_users' : 'participants';
  const lastVisitedTestGroupUrl = `/test_groups/${test.test_group.slug}/${viewUrl}`;
  localStorage.setItem('lastVisitedTestGroupUrl', lastVisitedTestGroupUrl);
};

export const hasGenericLabModule = (service_specification) =>
  [
    'rapid_quidel_lyra',
    'rapid_chembio',
    'rapid_quidel_quickvue',
    'rapid_flowflex',
    'rapid_lucira',
    'rapid_lumira',
    'rapid_indicaid',
    'rapid_universal',
    'rapid_quidel_quickvue_rsv',
    'rapid_status_strep_a_flip',
  ].includes(service_specification);

const isPOC = (service_specification) =>
  [
    'cepheid_rapid_pcr',
    'naat_abbott_id_now',
    'naat_accula',
    'naat_cue',
    'rapid_antigen_abbott_covid19',
    'rapid_antigen_quidel_covid19',
    'rapid_assure_ecotest_covid19',
    'rapid_quidel_quickvue',
    'spitfire_rapid_pcr',
    'visby_rapid_pcr',
    'luminostics_rapid_pcr',
    'rapid_antigen_bdveritor_covid19',
    'rapid_antigen_carestart_covid19',
    'lamp',
    'rapid_i_health',
    'rapid_general',
    'rapid_quidel_lyra',
    'rapid_chembio',
    'rapid_flowflex',
    'rapid_lumira',
    'rapid_lucira',
    'rapid_indicaid',
    'rapid_universal',
    'sofia2',
    'xpert_xpress',
    'cobas',
    'quidel_quickvue_flu_ab',
  ].includes(service_specification);

export const resultAtCheckoutEligible = ({ service_specification }) =>
  hasGenericLabModule(service_specification) || isPOC(service_specification);

const hasPositiveResult = (test) => test.result === 'positive';

const generateRecordConfigurationIs = (
  { test_configuration: { generate_record_configuration } },
  configuration,
) =>
  Array.isArray(configuration)
    ? configuration.includes(generate_record_configuration)
    : generate_record_configuration === configuration;

const ServiceSummary = ({
  test,
  edit,
  updateTestAttr,
  expanded,
  qualified,
}) => {
  const updateUid = updateTestAttr('uid');
  const editableBarcode = generateRecordConfigurationIs(test, [
    'no_configuration',
    'scan_only',
  ]);

  return (
    <ServiceSummaryContainer test={test} qualified={qualified}>
      <Row>
        <Col lg={9} sm={8} className="my-1 text-muted">
          <span>
            Barcode:
            {editableBarcode && edit ? (
              <input
                className="mt-1 form-control"
                style={{ maxWidth: 240 }}
                type="text"
                data-test-hook="test_input"
                value={test.uid}
                onChange={(e) => updateUid(e.target.value)}
              />
            ) : (
              ` ${test.uid}`
            )}
          </span>
          {!expanded && (
            <React.Fragment>
              <br />
              Notes: {test.notes}
            </React.Fragment>
          )}
        </Col>
        {!expanded && (
          <Col
            lg={3}
            sm={4}
            className="my-1 text-muted d-flex justify-content-end"
            style={{ alignSelf: 'end' }}
          >
            <div className="d-flex flex-column">
              {test.administered ? (
                <SummaryBadge
                  icon={faCheck}
                  color="var(--bs-green)"
                  text="Administered"
                />
              ) : (
                <SummaryBadge
                  icon={faXmark}
                  color="var(--bs-danger)"
                  text="Not administered"
                />
              )}
            </div>
          </Col>
        )}
      </Row>
    </ServiceSummaryContainer>
  );
};

const Section = ({ title, children, index = null }) => (
  <React.Fragment>
    <div className="mt-1">
      <hr className="mt-4 mb-2" />
      <h5 className="fw-bold">
        {index && `${index}. `}
        {title}
      </h5>
    </div>
    <div className="d-flex">
      <div className="mt-2 flex-grow-1">{children}</div>
      <div className="pe-4 d-none d-sm-block" />
    </div>
  </React.Fragment>
);

const testHasSurvey = (test) => {
  const survey = test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey
    : test.test_configuration?.survey
  return Object.keys(survey || {}).length !== 0;
};

const Questionnaire = ({
  test,
  edit,
  updateTestAttr,
  sectionIndex: index,
  modelRef,
  hasValidAnswers,
}) => {
  const { t } = useTranslation();
  const errorRef = useRef();

  const updateTestSurvey = (answers) => {
    updateTestAttr('test_survey')({ ...test.test_survey, survey: answers });
  };

  const updateMedicalScreeningSurvey = (answers) => {
    updateTestAttr('medical_screening_survey_answers')({ ...test.medical_screening_survey_answers, answers: answers });
  };

  useEffect(() => {
    if (!hasValidAnswers && errorRef.current)
      window.scrollTo({
        top:
          errorRef.current.getBoundingClientRect().top +
          window.pageYOffset -
          120,
      });
  }, [hasValidAnswers, errorRef]);

  return (
    <Section title="Questionnaire" defaultShowState={edit} index={index}>
      <div style={edit ? {} : { opacity: 0.5 }}>
        {test.use_medical_screening_survey_on_checkout ? (
          <SurveyPreview
            modelRef={modelRef}
            json={test.medical_screening_survey || {}}
            data={test.medical_screening_survey_answers.answers || {}}
            readOnly={!edit}
            onValueChanged={(e) => updateMedicalScreeningSurvey(e.data)}
          />
        ) : (
          <SurveyPreview
            modelRef={modelRef}
            json={test.test_configuration?.survey || {}}
            data={test.test_survey?.survey || {}}
            readOnly={!edit}
            onValueChanged={(e) => updateTestSurvey(e.data)}
          />
        )}
      </div>
      {!hasValidAnswers && (
        <span ref={errorRef} className="text-danger my-3">
          {t('registration.errors.survey_unanswered')}
        </span>
      )}
    </Section>
  );
};

const ResultOptions = (props) => {
  const {
    test: {
      test_configuration: { service_specification },
    },
  } = props;

  const Component =
    service_specification === 'rapid_antigen_abbott_covid19'
      ? AbbottResultOptions
      : GenericResultOptions;

  return <Component {...props} />;
};

const ConfirmResultCheckbox = ({ test, updateTestAttr, setError, edit }) => {
  const service_specification = getTestResultType(
    test.test_configuration.service_specification,
  );
  const testResultKey = `${service_specification}_test_result`;
  const updateTestResult = updateTestAttr(testResultKey);

  useEffect(() => {
    if (hasPositiveResult(test) && !test[testResultKey]?.results_approved)
      setError('Cannot save positive result without final approval');
    else setError('');
    return () => setError('');
  }, [test]);

  return (
    <div className="form-check my-2">
      <input
        id={`test_${test.id}_results_approved`}
        className="form-check-input"
        type="checkbox"
        checked={test[testResultKey]?.results_approved}
        onChange={(e) =>
          updateTestResult({
            ...test[testResultKey],
            results_approved: e.target.checked,
          })
        }
        disabled={!edit}
      />
      <label
        className="form-check-label"
        htmlFor={`test_${test.id}_results_approved`}
      >
        Confirm result?
      </label>
    </div>
  );
};

const Result = ({
  test,
  updateTestAttr,
  edit,
  setError,
  sectionIndex: index,
}) => {
  const updateImageData = updateTestAttr('test_image_base64');

  return (
    <Section title="Result" defaultShowState={edit} index={index}>
      <Row>
        <Col md={6}>
          {test.administered_at && (
            <div className="d-flex flex-column mb-3">
              <span className="text-muted mb-1">Administered time:</span>
              <input
                className="form-control"
                style={{ maxWidth: 240 }}
                type="text"
                value={test.administered_at}
                disabled
              />
            </div>
          )}
          <div className="d-flex flex-column mb-3">
            <span className="text-muted mb-1">Final result:</span>
            <ResultOptions
              test={test}
              updateTestAttr={updateTestAttr}
              edit={edit}
            />
          </div>
          {hasPositiveResult(test) && (
            <ConfirmResultCheckbox
              setError={setError('result')}
              test={test}
              updateTestAttr={updateTestAttr}
              edit={edit}
            />
          )}
        </Col>
        <Col md={6}>
          <TestImageCapture
            test={test}
            edit={edit}
            setTestImageData={updateImageData}
            allowPictureUpload
          />
        </Col>
      </Row>
    </Section>
  );
};

const Collection = ({ test, edit, setTest, sectionIndex: index }) => {
  return (
    <Section title="Collection" defaultShowState={edit} index={index}>
      <Row>
        <Col>
          <FloatingLabelSelect
            name="specimen_type"
            value={test.test_configuration.specimen_types.filter(option => option.value === test.specimen_type_id)}
            filledValue={!!test.specimen_type_id}
            onChange={(st) => {
              setTest({
                ...test,
                specimen_type_id: st.value
              });
            }}
            options={test.test_configuration.specimen_types}
            label="Specimen type"
            style={{ maxWidth: 240 }}
          />
        </Col>
        <Col>
          <FloatingLabelSelect
            name="body_site"
            value={test.test_configuration.body_sites.filter(option => option.value === test.body_site_id)}
            filledValue={!!test.body_site_id}
            onChange={(bs) => {
              setTest({
                ...test,
                body_site_id: bs.value,
              });
            }}
            options={test.test_configuration.body_sites}
            label="Body site"
            style={{ maxWidth: 240 }}
          />
        </Col>
      </Row>
    </Section>
  );
}

const Confirmation = ({ test, updateTestAttr, edit, sectionIndex: index }) => {
  const updateAdministered = updateTestAttr('administered');

  return (
    <Section title="Confirmation" defaultShowState={edit} index={index}>
      <React.Fragment>
        <div className="d-flex flex-column mb-2">
          {edit ? (
            !(
              test.test_configuration.hide_administered_button &&
              test.test_configuration.hide_not_administered_button
            ) && (
              <React.Fragment>
                <span className="text-muted">Status:</span>
                <span data-test-hook="administered">
                  <Form.Check
                    type="switch"
                    onChange={() => updateAdministered(!test.administered)}
                    label={
                      test.administered === true
                        ? 'Administered'
                        : 'Not Administered'
                    }
                    checked={!!test.administered}
                    className="mt-2 green-switch"
                    id={`administered_switch_${test.uid}`}
                  />
                </span>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <span className="text-muted">Status:</span>
              <div style={{ opacity: 0.5 }}>
                {test.administered ? (
                  <SummaryBadge
                    icon={faCheck}
                    color="var(--bs-green)"
                    text="Administered"
                  />
                ) : (
                  <SummaryBadge
                    icon={faXmark}
                    color="var(--bs-danger)"
                    text="Not administered"
                  />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="d-flex flex-column">
          <span className="text-muted mb-1">
            Notes:{!edit && !test.notes && ' N/A'}
          </span>
          {edit ? (
            <Col md={6}>
              <textarea
                className="form-control text"
                name="test_notes"
                rows={2}
                defaultValue={test.notes}
                onChange={(e) => updateTestAttr('notes')(e.target.value)}
              />
            </Col>
          ) : (
            test.notes && <span style={{ opacity: 0.5 }}>{test.notes}</span>
          )}
        </div>
      </React.Fragment>
    </Section>
  );
};

const Administrator = ({
  test,
  updateTestAttr,
  edit,
  sectionIndex: index,
  administratorFieldsFilled,
  setTest,
}) => {
  const { t } = useTranslation();
  const errorRef = useRef();
  const newAdministration = !test.administration?.id;
  const administration = {
    administered_by_name:
      test.administration?.administered_by_name ||
      (newAdministration ? test.current_user.full_name : null),
    administered_by_npi:
      test.administration?.administered_by_npi ||
      (newAdministration ? test.current_user.npi_number : null),
    administered_by_degree:
      test.administration?.administered_by_degree ||
      (newAdministration ? test.current_user.degree : null),
  };
  const degreeOptions = test.test_configuration.administrator_degree_options;
  const selectedDegree = degreeOptions.filter(
    (o) => o.value === administration.administered_by_degree,
  );

  useEffect(() => {
    if (!administratorFieldsFilled && errorRef.current)
      window.scrollTo({
        top:
          errorRef.current.getBoundingClientRect().top +
          window.pageYOffset -
          120,
      });
  }, [administratorFieldsFilled, errorRef]);

  if (!test.administration) {
    setTest({
      ...test,
      administration: administration,
    });
  }

  return (
    <Section title="Administrator" defaultShowState={edit} index={index}>
      <Row>
        <Col sm={6} xs={12} className="my-2">
          <FloatingLabelInput
            name="administered_by_name"
            value={test.administration?.administered_by_name}
            onChange={(e) => {
              setTest({
                ...test,
                administration: {
                  ...administration,
                  administered_by_name: e.target.value,
                },
              });
            }}
            label="Name *"
          />
        </Col>
        <Col sm={6} xs={12} className="my-2">
          <FloatingLabelInput
            name="administered_by_npi"
            value={test.administration?.administered_by_npi}
            onChange={(e) => {
              setTest({
                ...test,
                administration: {
                  ...administration,
                  administered_by_npi: e.target.value,
                },
              });
            }}
            label="NPI number *"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={12} className="my-2">
          <FloatingLabelSelect
            name="administered_by_degree"
            value={selectedDegree}
            onChange={(ad) => {
              setTest({
                ...test,
                administration: {
                  ...administration,
                  administered_by_degree: ad.value,
                },
              });
            }}
            options={degreeOptions}
            label="Degree *"
            filledValue={selectedDegree.length > 0}
          />
        </Col>
      </Row>
      {!administratorFieldsFilled && (
        <span ref={errorRef} className="text-danger my-3">
          {t('registration.errors.survey_unanswered')}
        </span>
      )}
    </Section>
  );
};

const CardOptions = ({
  test,
  edit,
  setEdit,
  open,
  setOpen,
  printButtonOnClick,
  redirectToResultInterface = null,
  hasPermissionToResult = true,
}) => {
  return (
    <CardOptionsWrapper>
      {printButtonOnClick && (
        <Button size="sm" onClick={printButtonOnClick}>
          Print{' '}
          <span className="d-none d-sm-block ps-1">
            {test.test_configuration.short_code} label
          </span>
        </Button>
      )}
      <span className="btn-group ms-4">
        <Button
          variant="link"
          data-test-hook="edit-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </Button>
        <ul className="dropdown-menu dropdown-menu-end">
          <React.Fragment>
            {!edit && (
              <Dropdown.Item
                data-test-hook="view"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <span>
                    <FontAwesomeIcon icon={faEyeSlash} className="me-1" /> Close
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faEye} className="me-1" /> View
                  </span>
                )}
              </Dropdown.Item>
            )}
            {!!test.administered_at && (
              <Dropdown.Item
                data-test-hook="edit"
                href={test.case_report_path}
                target="_blank"
              >
                <FontAwesomeIcon icon={faPen} className="me-1" /> Edit
              </Dropdown.Item>
            )}
            {(!test.administered_at || !test.result) && (
              <Dropdown.Item
                data-test-hook="edit"
                onClick={() => setEdit(!edit)}
              >
                <FontAwesomeIcon
                  icon={edit ? faLock : faLockOpen}
                  className="me-1"
                />{' '}
                {edit ? 'Lock' : 'Unlock'}
              </Dropdown.Item>
            )}
            {redirectToResultInterface && (
              <Wrapped
                WrapperComponent={TooltipWrapper}
                wrapIf={!hasPermissionToResult}
                componentProps={{
                  tooltipContent: 'You do not have permission to log results.',
                }}
              >
                <Dropdown.Item
                  onClick={hasPermissionToResult && redirectToResultInterface}
                  className={!hasPermissionToResult && 'text-muted'}
                >
                  <FontAwesomeIcon icon={faArrowRight} className="me-1" /> Log
                  results
                </Dropdown.Item>
              </Wrapped>
            )}
          </React.Fragment>
        </ul>
      </span>
    </CardOptionsWrapper>
  );
};

const CloseButton = ({ updateCheckoutTest, hasErrors, errors }) => (
  <div className="d-flex justify-content-end">
    <Wrapped
      WrapperComponent={TooltipWrapper}
      wrapIf={hasErrors}
      componentProps={{
        tooltipContent: Object.keys(errors)
          .filter((errorKey) => errors[errorKey].errored)
          .map((errorKey) => errors[errorKey].errorMessage)
          .join('\n'),
      }}
    >
      <div className="rounded bg-light my-2">
        <Button
          variant="outline-primary"
          className="float-right"
          onClick={updateCheckoutTest}
          disabled={hasErrors}
        >
          Close
        </Button>
      </div>
    </Wrapped>
  </div>
);

const Sections = ({
  visible,
  updateCheckoutTest: propUpdateCheckoutTest,
  showResultSection: propShowResultSection,
  errors,
  ...props
}) => {
  const [hasValidAnswers, setHasValidAnswers] = useState(true);
  const [administratorFieldsFilled, setAdministratorFieldsFilled] =
    useState(true);
  const questionnaireModelRef = useRef(null);
  const showResultImmediately = generateRecordConfigurationIs(
    props.test,
    'shows_result_immediately',
  );
  const testHasQuestionnaire = testHasSurvey(props.test);
  const showResultSection = propShowResultSection && showResultImmediately;
  const showAdministratorSection =
    props.test.test_configuration.require_medical_professional_data_on_checkout;
  const showCollectionSection = props.test.test_configuration.require_collection_on_checkout;

  const sectionsInOrder = [];
  if (testHasQuestionnaire) sectionsInOrder.push('questionnaire');
  if (showResultSection) sectionsInOrder.push('result');
  if (!showAdministratorSection && showCollectionSection) sectionsInOrder.push('collection');
  sectionsInOrder.push('confirmation');
  if (showAdministratorSection) sectionsInOrder.push('administration');

  const validateSurvey = useCallback(
    (answers) => {
      if (!questionnaireModelRef.current) return null;

      const model = questionnaireModelRef.current;
      const requiredQuestions = model.pages
        .map((page) =>
          Object.keys(page.elements)
            .filter((idx) => page.elements[idx]['isRequired'])
            .map((idx) => page.elements[idx]['name']),
        )
        .flat();

      return !requiredQuestions.find(
        (questionName) => answers[questionName] === undefined,
      );
    },
    [questionnaireModelRef],
  );

  const updateCheckoutTest = () => {
    if (showAdministratorSection) {
      const { test } = props;
      const fieldsFilled =
        test.administration?.administered_by_name &&
        test.administration?.administered_by_npi &&
        test.administration?.administered_by_degree;
      setAdministratorFieldsFilled(fieldsFilled);
      if (!fieldsFilled) return;
    }
    if (testHasQuestionnaire) {
      const answers = test.use_medical_screening_survey_on_checkout
        ? test.medical_screening_survey_answers
        : test.test_survey.survey
      const validAnswers = validateSurvey(answers);
      setHasValidAnswers(validAnswers);
      if (!validAnswers) return;
    }
    propUpdateCheckoutTest();
  };

  return (
    <SectionsWrapper visible={visible}>
      {testHasQuestionnaire && (
        <Questionnaire
          {...props}
          modelRef={questionnaireModelRef}
          hasValidAnswers={hasValidAnswers}
          sectionIndex={sectionsInOrder.indexOf('questionnaire') + 1}
        />
      )}
      {showResultSection && (
        <Result
          {...props}
          sectionIndex={sectionsInOrder.indexOf('result') + 1}
        />
      )}
      {!showAdministratorSection && showCollectionSection && (
        <Collection
          {...props}
          sectionIndex={sectionsInOrder.indexOf('collection') + 1}
        />
      )}
      <Confirmation
        {...props}
        sectionIndex={sectionsInOrder.indexOf('confirmation') + 1}
      />
      {showAdministratorSection && (
        <Administrator
          {...props}
          sectionIndex={sectionsInOrder.indexOf('administration') + 1}
          administratorFieldsFilled={administratorFieldsFilled}
        />
      )}
      {props.edit && (
        <CloseButton
          updateCheckoutTest={updateCheckoutTest}
          hasErrors={
            !!Object.keys(errors).find((errorKey) => errors[errorKey].errored)
          }
          errors={errors}
        />
      )}
    </SectionsWrapper>
  );
};

const TestCard = ({
  test,
  updateTest: setTest,
  printButtonOnClick,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(
    (!test.administered_at || test.administered == null) &&
      (test.test_configuration.require_medical_professional_data_on_checkout ||
        generateRecordConfigurationIs(test, 'shows_result_immediately') ||
        (generateRecordConfigurationIs(test, [
          'generate_barcode_only',
          'scan_only',
          'no_configuration',
        ]) &&
          testHasSurvey(test))),
  );
  const [errors, _setErrors] = useState({});
  const expanded = open || edit;

  const setError = (errorKey) => (errorMessage) =>
    _setErrors({
      ...errors,
      [errorKey]: { errored: !!errorMessage, errorMessage },
    });
  const updateTestAttr = (key) => (value) => setTest({ ...test, [key]: value });

  // NOTE: PM requested to always have the service marked as administered
  useEffect(() => {
    if ([null, undefined].includes(test.administered))
      updateTestAttr('administered')(true);
  }, []);

  const updateCheckoutTest = () => {
    setEdit(false);
  };

  const commonProps = {
    test,
    updateTestAttr,
    edit,
    setEdit,
  };

  return (
    <ServiceCard test={test} edit={edit}>
      <CardOptions
        open={open}
        setOpen={setOpen}
        printButtonOnClick={printButtonOnClick}
        {...commonProps}
        {...props}
      />
      <ServiceSummary expanded={expanded} {...commonProps} {...props} />
      <Sections
        visible={expanded}
        updateCheckoutTest={updateCheckoutTest}
        errors={errors}
        setError={setError}
        setTest={setTest}
        {...commonProps}
        {...props}
      />
    </ServiceCard>
  );
};

const UpdateTestForm = ({
  test,
  updateTest,
  submitTests,
  setRedirectCallbackPath,
  isGroupOnDemandOnly,
  consent,
  qualified = true,
  permission_kinds,
  editable_test_types,
}) => {
  const {
    test_configuration: { service_specification },
  } = test;

  let printButtonOnClick = null;
  if (test.saved && test.label_code === 'zebra_label_code') {
    printButtonOnClick = () => {
      zplCodeOnClick(test, updateTest);
    };
  } else if (test.saved) {
    printButtonOnClick = () => {
      printPdfLabelOnClick(test, updateTest, submitTests);
    };
  }

  const hasPermissionToResult = hasPermissionToResultTest(
    permission_kinds,
    editable_test_types,
    test,
  );

  return (
    <TestCard
      test={test}
      updateTest={updateTest}
      qualified={qualified}
      printButtonOnClick={printButtonOnClick}
      showResultSection={resultAtCheckoutEligible(test.test_configuration)}
      redirectToResultInterface={
        () => renderResultInterfaceLink(
          service_specification,
          test,
          saveAndGoToResultInterface,
          submitTests,
          saveTestGroupUrlToLocalStorage,
          setRedirectCallbackPath,
          isGroupOnDemandOnly
        )
      }
      hasPermissionToResult={hasPermissionToResult}
    />
  );
};

UpdateTestForm.propTypes = {
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      uid: PropTypes.string.isRequired,
      saved: PropTypes.bool,
    }),
  ),
};

export default UpdateTestForm;
