import React from 'react';
import styled, { css } from 'styled-components';
import { PrimaryIcon } from '../../common/components/Icons/PrimaryIcon';

const OverdueIcon = () => (
  <PrimaryIcon
    icon="alarm"
    color="var(--icon-fill-color)"
    width="14px"
    height="14px"
  />
);

const PendingIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 3.75C7.25 3.34375 7.5625 3 8 3C8.40625 3 8.75 3.34375 8.75 3.75V7.625L11.4062 9.375C11.75 9.625 11.8438 10.0938 11.5938 10.4375C11.375 10.7812 10.9062 10.875 10.5625 10.625L7.5625 8.625C7.375 8.5 7.25 8.25 7.25 8V3.75ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8Z"
      fill="var(--icon-fill-color)"
    />
  </svg>
);

const Icon = ({ status }) => (
  <div
    className="icon"
    style={{ backgroundColor: 'var(--icon-background-color)' }}
  >
    {status !== 'pending' ? <PendingIcon /> : <OverdueIcon />}
  </div>
);

const Details = ({ immunizationRecord }) => (
  <div className="d-flex flex-column flex-grow-1">
    <span className="due-date">
      Due: {immunizationRecord.next_dose_due || 'N/A'}
    </span>
    <span className="name">{immunizationRecord.name} immunization</span>
  </div>
);

const State = ({ immunizationRecord }) => (
  <span className="record-status flex-grow-0">
    {immunizationRecord.immunization_record_status}
  </span>
);

const CardWrapper = styled.div`
  ${({ status }) =>
    status === 'pending'
      ? css`
          --icon-background-color: #e7edfd;
          --icon-fill-color: #2862fa;
          --status-color: #586da0;
        `
      : css`
          --icon-background-color: #ffdde4;
          --icon-fill-color: #f70235;
          --status-color: #d14110;
        `};
`;

const ImmunizationCard = (props) => (
  <CardWrapper
    className="immunization-card d-flex flex-row"
    status={props.immunizationRecord.immunization_record_status}
  >
    <Icon status={props.immunizationRecord.immunization_record_status} />
    <Details {...props} />
    <State {...props} />
  </CardWrapper>
);

export default ImmunizationCard;
