import React, { useContext } from 'react';
import ErrorMessage from '../../common/components/ErrorMessage';
import OtcContext from '../contexts/OtcContext';
import * as SurveyOriginal from "survey-react";
import { Survey } from "survey-react-ui";

const OtcSurvey = () => {
  const { t, activeErrors, model, stepNumber, flippers } = useContext(OtcContext);

  const css = {
    question: {
      title: "survey-question-title"
    },
    boolean: {
      switch: "sv-boolean__switch",
    },
    radiogroup: {
      label: "radio-label survey-label has-check",
    },
    checkbox: {
      label: "survey-label checkbox-label has-check",
    },
  }

  let SurveyComponent;
  if (flippers.upgraded_surveyjs) {
    SurveyComponent = Survey;
  } else {
    SurveyComponent = SurveyOriginal.Survey;
  }

  return (
    <div className={flippers.upgraded_surveyjs && "upgraded_surveyjs"}>
      <h4>{stepNumber}. {t("otc.answer_survey")}</h4>
      <SurveyComponent model={model} css={css} />
      {
        Object.keys(activeErrors).length > 0 && (
          <ErrorMessage
            className="my-3"
            message={activeErrors["unanswered_survey"] || activeErrors["generic_error"]}
          />
        )
      }
    </div>
  )
}

export default OtcSurvey;
