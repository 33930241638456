import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PleaseContactSupport = ({ onClick, fromMobile = false }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="h5 text-muted mb-3">{t("contact_support.invalid")}</div>
      <div className="my-4">
        {fromMobile
          ? t("contact_support.mobile.invalid_explanation")
          : <>
            {t("contact_support.invalid_explanation")} <b>{t("contact_support.invalid_explanation_bold")}</b>
          </>
        }
      </div>
      {fromMobile
        ? <div className="my-4">
          <p>{t('contact_support.mobile.reason')}</p>
          <p>
            {t("contact_support.mobile.get_help")}
            <a Button variant="link" className="font-weight-bold" data-bs-toggle="modal" data-bs-target="#supportModal"> Primary support </a>
            {t("contact_support.get_help_calling")}
            <a className="font-weight-bold" href="tel:650-275-5419"> (650) 275-5419</a>.
          </p>
        </div>
        : <div className="my-4">
          <p>
            {t("contact_support.get_help")}
            <a Button variant="link" className="font-weight-bold" data-bs-toggle="modal" data-bs-target="#supportModal"> Primary support </a>
            {t("contact_support.get_help_calling")}
            <a className="font-weight-bold" href="tel:650-275-5419"> (650) 275-5419</a>.
          </p>
          <p>
            {t("contact_support.never_registered")}
          </p>
        </div>
      }
      <Button variant="primary" block onClick={onClick}>{t("enter_information.search")}</Button>
    </div>
  )
};

export default PleaseContactSupport;
