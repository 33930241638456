import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, Table } from 'react-bootstrap';

import ModalHeader from '../../common/components/ModalHeader';
import Icon from '../../common/components/AssetIcon';
import styled from 'styled-components';

const SelectButton = styled(Button)`
  min-width: 120px;
`;

const RecordDetailsWrapper = styled.div`
  padding: 24px;
  background-color: #f7f9fc;
  border-radius: 16px;
`;

const MatchText = ({ search = '', target = '' }) => {
  const fuzzysort = window.fuzzysort;
  const result = fuzzysort?.single(search, target);

  return !result
    ? target
    : fuzzysort.highlight(result, (m, i) => (
        <span className="fw-bold" key={i}>
          {m}
        </span>
      ));
};

const MultipleMatchResolver = ({
  resolveButtonSize = 'lg',
  resolveButtonVariant = 'link',
  resolveButtonText = 'Resolve',
  testGroupId,
  user,
  onMatchSelected = () => {},
  modalSubtitle = undefined,
  nameColumnAlias = undefined,
  recordDetails = undefined,
}) => {
  const [modalShown, setModalShown] = useState(false);
  const [matches, setMatches] = useState([]);
  const [iisProvider, setIisProvider] = useState(null);

  const multipleMatchResolutionsPath = `/test_groups/${testGroupId}/participants/${user.id}/vaccine_history_updates/multiple_match_resolutions`;

  const fetchMatches = () => {
    axios.get(`${multipleMatchResolutionsPath}/new`).then((response) => {
      setMatches(response.data.candidates);
      setIisProvider(response.data.iis_provider);
    });
  };

  const showModal = () => {
    fetchMatches();
    setModalShown(true);
  };
  const hideModal = () => setModalShown(false);

  const submit = (iisId) => {
    const payload = {
      iis: {
        id: iisId,
        provider: iisProvider,
      },
    };

    axios.post(multipleMatchResolutionsPath, payload).then(() => {
      onMatchSelected();
      hideModal();
    });
  };

  return (
    <>
      <Button
        variant={resolveButtonVariant}
        size={resolveButtonSize}
        onClick={showModal}
      >
        {resolveButtonText}
      </Button>
      <Modal
        className="spaced-modal"
        show={modalShown}
        onHide={hideModal}
        size="xl"
      >
        <ModalHeader closeButton onHide={hideModal}>
          <Modal.Title as="h2">Multiple Matches</Modal.Title>
          {modalSubtitle && <span>{modalSubtitle}</span>}
        </ModalHeader>
        <Modal.Body>
          {recordDetails && (
            <RecordDetailsWrapper className="mb-4">
              {recordDetails}
            </RecordDetailsWrapper>
          )}
          <section className="fabrx-tables-light">
            <Table responsive className="table-lg">
              <thead>
                <tr>
                  <th>{nameColumnAlias || 'Name'}</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th style={{ width: 150 }}></th>
                </tr>
              </thead>
              <tbody>
                {matches.map((match) => (
                  <tr key={match.iis_id}>
                    <td>
                      <MatchText
                        search={user.last_name}
                        target={match.name.last_name}
                      />
                      ,{' '}
                      <MatchText
                        search={user.first_name}
                        target={match.name.first_name}
                      />{' '}
                      <MatchText
                        search={user.full_name}
                        target={match.name.other_names}
                      />
                    </td>
                    <td>
                      <MatchText
                        search={user.date_of_birth}
                        target={match.date_of_birth}
                      />
                    </td>
                    <td>
                      <MatchText
                        search={user.sex_at_birth?.at(0)}
                        target={match.gender}
                      />
                    </td>
                    <td>
                      {match.address && (
                        <>
                          <MatchText
                            search={user.address_1}
                            target={match.address.street}
                          />
                          <br />
                          <MatchText
                            search={user.city}
                            target={match.address.city}
                          />
                          ,{' '}
                          <MatchText
                            search={user.state}
                            target={match.address.state}
                          />
                          <br />
                          <MatchText
                            search={user.postal_code}
                            target={match.address.postal_code}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      {match.phone_number && (
                        <>
                          <MatchText
                            search={user.phone_number?.replace('+1', '')}
                            target={`(${match.phone_number.area_code}) ${match.phone_number.local_number}`}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <SelectButton
                        variant="primary"
                        onClick={() => submit(match.iis_id)}
                      >
                        Select{' '}
                        <Icon
                          className="ms-2"
                          path="/images/icons/arrow-right.svg"
                          color="red"
                          style={{ height: 14 }}
                        />
                      </SelectButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={hideModal}>
            No match
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MultipleMatchResolver;
