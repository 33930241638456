import React from 'react';
import { Calendar } from 'react-multi-date-picker';
import { useField } from 'formik';

export const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const MultiDatePickerField = ({
  MultiDateComponent = Calendar,
  multiple = true,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const onChange = (array) => {
    setValue(array.map((objectDate) => objectDate.format('YYYY-MM-DD')));
  };

  return (
    <MultiDateComponent
      multiple={multiple}
      showOtherDays
      value={value}
      onChange={onChange}
    />
  );
};

export default MultiDatePickerField;
