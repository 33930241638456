import React from "react";
import { Button, Modal } from "react-bootstrap";

import ModalHeader from "../../common/components/ModalHeader";

const NewMessageConfirmationModal = ({
  confirm,
  deliverNow,
  onHide,
  recipientCount,
  show,
}) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
    >
      <ModalHeader closeButton onHide={onHide}>
        <h3>Confirm message</h3>
      </ModalHeader>
      <Modal.Body>
        <div>
          You are about to {deliverNow ? "send" : "schedule"} a message to <b>{recipientCount}</b> recipient{recipientCount > 1 ? "s" : ""}.
        </div>
        <div className="my-2">
          By continuing you confirm that you reviewed the message settings.   
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={onHide}
        >
          Go back
        </Button>
        <Button
          variant="primary"
          onClick={() => confirm()}
        >
          {deliverNow ? "Send" : "Schedule"} message
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewMessageConfirmationModal