import React, { useState } from 'react';
import '../../../common/locales/i18n';
import Consent from '../../../Registration/components/registration/Consent';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const ConsentSection = ({t, ...props}) => {
  const [copyText, setCopyText] = useState("copy_link");
  const handleCopy = () => {
    navigator.clipboard.writeText(location.href);
    setCopyText("copied_link")
  };

  return <>
    {!props.values.over_age_of_consent &&
      <Container style={{wordBreak: "break-word"}}>
        <div>
          <h3>{t('consent_waiver.stop')}</h3>
          <p><i>
            <ReactMarkdown source={t('consent_waiver.parent_or_guardian_required_html')} escapeHtml={false}/>
          </i></p>
        </div>

        <Row className="mb-4">
          <span>{t('consent_waiver.send_parent_or_guardian')}</span>
          <Col xs={12} md={10}>
            <input id="share-link" type="text" size={location.href.length} className="form-control" readOnly value={location.href}/>
          </Col>
          <Col xs={12} lg={2} className="mt-2 mt-lg-0">
            <Button className="text-nowrap" onClick={handleCopy} onBlur={() => setCopyText("copy_link")}>{t(copyText)}</Button>
          </Col>
        </Row>
      </Container>
    }
    <Consent {...props} />
  </>
}

export default ConsentSection;
