import React from 'react';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';

const OrganizationDropdown = ({
  organizationId,
  organizationOptions,
  updateFilter,
}) => {
  const selectedOrganizationOption = organizationOptions.find(
    (option) => option.value === parseInt(organizationId),
  );

  return (
    <div className="dropdown">
      <FloatingLabelSelect
        name="organization"
        ariaLabel="organization select"
        value={organizationId && selectedOrganizationOption}
        onChange={(o) => updateFilter('organizationId', o.value)}
        options={organizationOptions}
        filledValue={organizationId && selectedOrganizationOption?.label}
        label="Organization"
        isSearchable={true}
      />
    </div>
  );
};

export default OrganizationDropdown;
