
import React, { useRef, useState } from "react";
import { FormControl } from "react-bootstrap";

const SingleCharInputGrid = ({
  length,
  invalid,
  disabled,
  icon,
  handleInputChange,
  onSubmit,
  name,
  style={},
}) => {
  const [valueArray, setValueArray] = useState(new Array(length).fill(null));
  const valid = valueArray.filter(x => x !== null).length == length;
  const focusInput = useRef([]);
  const handleNextInput = (idx, letter) => {
    let newValue = [...valueArray];
    newValue[idx] = letter[0];
    setValueArray(newValue);
    focusInput.current[idx + 1]?.focus();
    handleInputChange(newValue.join(""))
  }
  return (
    <div className='d-flex justify-content-center align-items-center'>
      {valueArray.map((character, idx) => (
        <FormControl
          key={idx}
          className='poppins regular mx-1'
          ref={(el) => focusInput.current[idx] = el}
          style={{height: 72, width: 60, padding: 0, textAlign: "center", fontSize: "2.5 rem", ...style}}
          onChange={(e) => handleNextInput(idx, e.target.value)}
          onKeyDown={(e) => {
            const key = e.keyCode || e.charCode;
            if (key == 13 && valid){
              onSubmit(valueArray.join("")) 
            }
            if (key == 8 || key == 46) {
              e.preventDefault();
              let newValue = [...valueArray];
              newValue[idx] = '';
              setValueArray(newValue);
              focusInput.current[idx === 0 ? 0 : idx - 1]?.focus();
            }
          }}
          value={character || ""}
          id={`${name}_${idx}`}
          validation={invalid && "is-invalid"}
          aria-invalid={invalid}
          disabled={disabled}
          onPaste={(e) => {
            e.stopPropagation();
            e.preventDefault();
            let clipboardData = e.clipboardData || window.clipboardData;
            const newValue = clipboardData.getData("Text").slice(0, length);
            setValueArray(newValue.split(""));
            handleInputChange(newValue)
          }}
        />
      ))}
      {icon}
    </div>
  )
};

export default SingleCharInputGrid;
