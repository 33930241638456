import React, {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  OverlayTriggerProps,
  OverlayTriggerRenderProps,
} from 'react-bootstrap/esm/OverlayTrigger';

interface TooltipProps {
  tooltipContent: string | React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  children:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ((props: OverlayTriggerRenderProps) => ReactNode);
  overlayProps?: OverlayTriggerProps;
}

const TooltipWrapper: FC<TooltipProps> = ({
  tooltipContent,
  placement = 'bottom',
  children,
  overlayProps,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={(props) => (
      <Tooltip id={`tooltip-${placement}`} {...props}>
        {typeof tooltipContent === 'string'
          ? tooltipContent.split(/\n|\\n|<br>/).map((subText, idx) => (
              <span key={idx} className="d-block">
                {subText}
              </span>
            ))
          : tooltipContent}
      </Tooltip>
    )}
    {...overlayProps}
  >
    {children}
  </OverlayTrigger>
);

export default TooltipWrapper;
