import React, { useEffect, useState, useRef } from 'react';
import SelectableTable from './SelectableTable';
import TablePagination from '../TablePagination';
import queryString from 'querystring';


const SelectableTableContainer = ({
  fetchItems,
  filteredItemCount,
  itemComponents,
  itemKeys,
  items,
  tableHeaders,
  testGroup,
  ...props
}) => {

  const [page, setPage] = useState(1);
  const [pageMetadata, setPageMetadata] = useState({});
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [filteredPaginatedItems, setFilteredPaginatedItems] = useState(items);

  const targetTestGroupUsers = useRef(document.getElementById('selected_test_group_user_ids'));

  const updateExportUrl = () => {
    const exportParticipantsLink = document.getElementById('export_participants_link');
    const exportParams = new URLSearchParams(exportParticipantsLink.href);
    exportParams.append('export_params[selected_test_group_user_ids]', selectedItemIds);
    const exportUrlParams = {};
    for (const [key, value] of exportParams.entries()) exportUrlParams[key] = value;
    exportParticipantsLink.href = `/organizations/${testGroup.organization_id}/exports?${queryString.stringify(exportUrlParams)}`;
  }

  const updateSelectedItemValues = () => {
    if (!targetTestGroupUsers.current) return;
    targetTestGroupUsers.current.value = selectedItemIds;
    const modalTestGroupUserCount = document.getElementsByClassName('message-count')[0];
    const selectedItemCount = document.getElementById('selected_item_count');
    const testGroupUserCheckboxBadge = document.getElementById('tgu-checkbox-badge');
    if (selectedItemIds.length > 0) {
      if (!!modalTestGroupUserCount) modalTestGroupUserCount.innerHTML = selectedItemIds.length.toString();
      if (!!selectedItemCount) selectedItemCount.innerHTML = `(selected: ${selectedItemIds.length.toString()})`;
      if (!!testGroupUserCheckboxBadge) testGroupUserCheckboxBadge.classList.remove('d-none');
    } else {
      if (!!modalTestGroupUserCount) modalTestGroupUserCount.innerHTML = filteredItemCount.toString();
      if (!!selectedItemCount) selectedItemCount.innerHTML = '';
      if (!!testGroupUserCheckboxBadge) testGroupUserCheckboxBadge.classList.add('d-none');
    }
  }

  useEffect(() => {
    updateSelectedItemValues();
    updateExportUrl();
    fetchItems(page, setFilteredPaginatedItems, setPageMetadata);
  }, [page, selectedItemIds]);

  return (
    <>
      <SelectableTable
        tableHeaders={tableHeaders}
        filteredPaginatedItems={filteredPaginatedItems}
        selectedItemIds={selectedItemIds}
        setSelectedItemIds={setSelectedItemIds}
        itemKeys={itemKeys}
        itemComponents={itemComponents}
        {...props}
      />
      <TablePagination
        currentPage={pageMetadata.current_page}
        nextPage={pageMetadata.next_page}
        prevPage={pageMetadata.prev_page}
        totalPages={pageMetadata.total_pages}
        setPage={setPage}
      />
    </>
  );
};

export default SelectableTableContainer;
