import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleLanguageChange } from '../shared/LanguageChange';
import { languageObject } from "../../common/utils";
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

const LocalDropdown = ({ locales, language, handleLanguageChange }) => (
  <span className="dropdown nav-link">
    <button
      className="btn btn-link text-secondary dropdown-toggle pe-4 fw-normal"
      id="dropdownMoreLanguages"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      tabIndex={0}
    >
      <img alt="Language options" src="/images/globe.svg" />
      <span className="ms-1">{languageObject[language]}</span>
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMoreLanguages">
      {locales.map((locale) => {
        return (
          <button
            className="dropdown-item"
            key={locale}
            lang={locale}
            onClick={() => {
              handleLanguageChange(locale);
            }}
          >
            {languageObject[locale]}
          </button>
        );
      })}
    </div>
  </span>
);


const Header = ({
  testGroup,
  isHTMLView = false,
  loggedInComponent,
  borderBottom = true,
  justifyContent = false,
}) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const styleBySource = (testGroup.partner_logo_url || testGroup.simplified_partner_logo_url)
  ? { maxWidth: '85%', maxHeight: '85px' }
  : { maxHeight: '50px', padding: '5px 10px 5px 0px'}

  const mobileStyleBySource = (testGroup.partner_logo_url || testGroup.simplified_partner_logo_url)
  ? { maxHeight: '50px' }
  : { maxHeight: '36px', paddingLeft: '5px' }

  const logoStyle = isMobile
    ? mobileStyleBySource
    : styleBySource;

  const imgUrl = isMobile
    ? (testGroup.simplified_partner_logo_url || testGroup.partner_logo_url || "/images/logo.png")
    : (testGroup.partner_logo_url || testGroup.simplified_partner_logo_url || "/images/primary_logo_horizontal.png")

  useEffect(() => {
    i18n.changeLanguage(urlParams.get('locale'))
  }, [])

  return (
    <header id="test_group_header">
      <a
        className="skip-to-main-button"
        href="#main-content"
        onClick={(e) => {
          e.preventDefault();
          $('#main-content').focus();
        }}
      >
        Skip to main
      </a>
      <nav className={`navbar navbar-expand py-3 bg-white ${borderBottom && "border-bottom"}`}>
        <div className={`container-fluid ${justifyContent ? "container" : ""}`}>
          <a className="navbar-brand me-4" href="#">
            <img
              style={logoStyle}
              height="auto"
              src={imgUrl}
              alt={`${testGroup.name} Logo`}
            />
          </a>
          <div className="navbar-collapse justify-content-end">
          <button
            className="btn btn-link text-secondary nav-link"
            data-bs-toggle="modal"
            data-bs-target="#supportModal"
            style={{fontWeight: "inherit"}}
          >
            <FontAwesomeIcon icon={faCircleQuestion} className="me-1 text-secondary" /> {!isMobile && t("contact_support.get_help_label")}
          </button>
          <a
            className="btn btn-link nav-link text-secondary"
            href="https://www.essentialaccessibility.com/primary-health?utm_source=primaryhealthhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=primaryhealth"
            target="_blank"
            style={{fontWeight: "inherit"}}
          >
            <FontAwesomeIcon icon={['fab', 'accessible-icon']} className="me-1" /> {!isMobile && t("registration.assistive_technology")}
          </a>
          <LocalDropdown
            locales={testGroup.locales}
            language={i18n.language}
            handleLanguageChange={isHTMLView ? (
              (lang) => {
                urlParams.set("locale", lang);
                window.location.href = window.location.origin + window.location.pathname + "?" +urlParams.toString();
              }) : handleLanguageChange(i18n)
            }
          />
          {loggedInComponent}
          </div>
        </div>
      </nav>
    </header>
  )
};

export default Header;
