import React, { useContext } from 'react';
import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OtcContext from '../contexts/OtcContext';
import LocalizedMarkdown from "../../Registration/components/registration/fields/LocalizedMarkdown";


const OtcInstructions = () => {

  const { t, i18n, flow, testConfiguration, stepNumber } = useContext(OtcContext);

  const mainImageUrl = {
    self_checkouts: '/images/abbott/abbott_kit_with_frame.png',
    general_self_checkouts: '/images/all_rapid_boxes_with_frame.png',
    self_admin: '/images/self_admin_logo_with_frame.png',
  };

  const resultsSchemaMainImage = () =>
    testConfiguration.results_schema?.otc?.images?.main;

  const resultsSchemaLocaleSpecificValue = (resources) => {
    if (!resources) return null;

    return resources[i18n.language] || Object.values(resources)[0];
  };

  const resultsSchemaVideo = () =>
    resultsSchemaLocaleSpecificValue(testConfiguration.results_schema?.otc?.videos);

  const resultsSchemaInstructions = () =>
    resultsSchemaLocaleSpecificValue(testConfiguration.results_schema?.otc?.instructions);

  const videoUrl =
    resultsSchemaVideo() ||
    (flow === 'self_checkouts' && 'https://www.youtube.com/watch?v=baQQfoX-JXo&t=313s');

  const instructionsUrl =
    resultsSchemaInstructions() ||
    (flow === 'self_checkouts' && 'https://primarybio-public.s3.us-east-2.amazonaws.com/BinaxNOW_COVID-19_Antigen_Self_Test_Prod.pdf?r[…]3e3012cb7552404c78a7232a7f9b89b6cd59dcde65b99c');

  const displayButtons = !["general_self_checkouts", "self_admin"].includes(flow)

  return (
    <div>
      <div className="text-center">
        <Image
          style={{ maxHeight: '310px' }}
          src={
            testConfiguration.logo_url ||
            resultsSchemaMainImage() ||
            mainImageUrl[flow]
          }
          className="mb-4"
          rounded
        />
      </div>
      <h4>{stepNumber}. {t("otc.review_instructions")}</h4>
      {displayButtons ? (
        <div>
          <p className="my-3">{t("otc.follow_instructions_or_below")}</p>
          <div className="mb-6">
            <Button
              size="sm"
              onClick={() => window.open(videoUrl, '_blank')}
              className="otc-button"
            >
              {t("otc.watch_video")} <FontAwesomeIcon className="ms-2" icon={"play"} />
            </Button>
            <a
              href={instructionsUrl}
              target="_blank"
              className="mx-2"
              style={{ fontSize: "13px" }}
            >
              {t("otc.view_instructions")} <FontAwesomeIcon className="ms-1" icon={"external-link-alt"} />
            </a>
          </div>
        </div>
      ) : (
        <p className="my-3">{t("otc.follow_instructions")}</p>
      )}
      <LocalizedMarkdown container={testConfiguration} stringKey="self_administration_test_configuration_text" />
    </div>
  )
}

export default OtcInstructions;
