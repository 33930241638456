import React, { useState, useEffect, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
// import * as SDCCore from "scandit-web-datacapture-core";
// import * as SDCId from "scandit-web-datacapture-id";

function IdScannerDemo({onScan}) {
  const webcamRef = useRef(null);
  const [borderColor, setBorderColor] = useState("white")
  const [ showWebcam, setShowWebcam] = useState(true)
  const capture = useCallback(
    () => {
      setBorderColor("#2ED477")
      onScan({
        first_name: "Marisa",
        middle_name: "Celina",
        last_name: "Lopez",
        address_1: "18880 Barnhart ave",
        city: "Cupertino",
        state: "CA",
        postal_code: "95014",
        "date_of_birth_datetime(1i)": 1996, 
        "date_of_birth_datetime(2i)": 8, 
        "date_of_birth_datetime(3i)": 14, 
      })
    },
    [webcamRef]
  );
  const videoConstraints = {
    width: 400,
    height: 200,
    facingMode: "user"
  };

  useEffect(() => {
    // 'capture' in 15 seconds... until we get our scandit api key
    const timeoutId = setTimeout(() => {  
      capture()
    }, 8000);  
    
    return () => clearTimeout(timeoutId)
  },[])

  useEffect(() => {
    const timeoutId = setTimeout(() => {  
      setShowWebcam(false)
    }, 10000);  
    
    return () => clearTimeout(timeoutId)
  },[])

  if (!showWebcam) return <></>;
  
  return (
    <div style={{position: "relative"}} className='drivers'>
      {[1,2,3,4].map((corner) => <div className={`corner corner${corner}`} style={{borderColor}}></div>) }
      <Webcam
        audio={false}
        height={200}
        width={400}
        style={{maxHeight: 200, minWidth: 400}}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
    </div>
  );
}



// const IdScannerReal = ({
//   onScan,
//   containerClassName = 'col-sm-10 col-md-6',
// }) => {
//   const runScanner = async () => {
//     const view = new SDCCore.DataCaptureView();

//     // Connect the data capture view to the HTML element.
//     view.connectToElement(document.getElementById("data-capture-view"));

//     // Show the progress bar
//     view.showProgressBar();

//     // Set the progress bar message
//     view.setProgressBarMessage("Loading...");
//     // Configure the library
//     await SDCCore.configure({
//       licenseKey: gon?.scandit_api_key,
//       libraryLocation: 'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-id@6.x/build/engine/',
//       moduleLoaders: [SDCId.idCaptureLoader({enableVIZDocuments: true})],
//     });

//     // Hide the progress bar
//     view.hideProgressBar();

//       // Create the context (it will use the license key passed to configure by default)
//     const context = await SDCCore.DataCaptureContext.create();

//     // Set the default camera as frame source. Apply the recommended settings from the IdCapture mode.
//     const camera = SDCCore.Camera.default;
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
//     await camera.applySettings(SDCId.IdCapture.recommendedCameraSettings);
//     await context.setFrameSource(camera);

//     await view.setContext(context);

//     view.addControl(new SDCCore.CameraSwitchControl());

//     // Create the IdCapture settings needed for the selected mode
//     const settings = new SDCId.IdCaptureSettings();
//     settings.supportedDocuments = [
//       SDCId.IdDocumentType.DLVIZ,
//     ];

//     // Create the IdCapture mode with the new settings
//     const idCapture = await SDCId.IdCapture.forContext(context, settings);

//     // Setup the listener to get notified about results
//     idCapture.addListener({
//       didCaptureId: async (idCaptureInstance, session) => {
//         // Disable the IdCapture mode to handle the current result
//         await idCapture.setEnabled(false);

//         const capturedId = session.newlyCapturedId;
//         console.log(capturedId);
//         void idCapture.reset();
//       },
//       didRejectId: async () => {
//         await idCapture.setEnabled(false);
//         console.log("Document type not supported.");
//       },
//     });

//     // Apply a new overlay for the newly created IdCapture mode
//     await SDCId.IdCaptureOverlay.withIdCaptureForView(idCapture, view);

//     // Disable the IdCapture mode until the camera is accessed
//     await idCapture.setEnabled(false);

//     // Finally, switch on the camera
//     await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
//     await idCapture.setEnabled(true);
//   }
//   useEffect(() => {
//     runScanner() 
//   }, []);
//   return (
//     <div>
//       <div className="row">
//         <div className={`center ${containerClassName}`}>
//           <div
//             id="scan-content"
//             className="content"
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//             }}
//           >
//             <div
//               id="data-capture-view"
//               className="barcode-picker"
//               style={{
//                 zIndex: '10',
//                 width: '100%',
//                 height: '250px',
//                 boxSizing: 'border-box',
//                 border: '1px solid rgba(0, 0, 0, 0.26)',
//                 padding: '2px',
//               }}
//             ></div>
//             <br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default IdScannerDemo;
