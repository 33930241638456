import React, { useEffect, useState } from 'react';
import axios from "axios";
import {
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import '../../../common/locales/i18n';
import { loadAppointmentSlots } from "../../../common/utils/"
import ModalHeader from '../../../common/components/ModalHeader';
import { isMobile } from '../../../ImmunizationStatus/components/UploadRecords';

const EditAppointmentModal = ({
  onHide,
  appointment,
  appointmentSlotGroup,
  testGroup,
  appointmentSlot,
  selectedServiceNames,
  showEditAppointmentModal,
}) => {
  const { t } = useTranslation();
  const [useCancelAppointmentModalContent, setUseCancelAppointmentModalContent] = useState(false);
  const [appointmentSlotId, setAppointmentSlotId] = useState(appointmentSlot.id);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onSubmit = () => {
    setLoading(true)
    axios.put(
      `/registrations/${appointment.access_code}.json`,
      {appointment: {appointment_slot_id: appointmentSlotId }}
    )
      .then(r => window.location.reload())
      .catch(err => {
        setLoading(false);
        setError("Something went wrong")
      })
  };

  const onCancel = () => {
    axios
      .delete(`/registrations/${appointment.access_code}`)
      .then(() => location.reload())
      .catch(() => location.reload())
  };

  useEffect(() => {
    if (!showEditAppointmentModal) {
      setAppointmentSlotId(appointmentSlot.id);
      setUseCancelAppointmentModalContent(false);
    }
  }, [showEditAppointmentModal]);

  useEffect(() => {
    loadAppointmentSlots({
      accessKey: appointment.registration_access_key,
      testGroupSlug: testGroup.slug,
      onLoad: (data) => {
        setOptions(
          data.appointment_slot_groups[0].appointment_slots.map(x => ({value: x.id, label: x.localized_starts_at}))
           .filter(x => x.value != appointmentSlot.id)
           .concat({value: appointmentSlot.id, label: appointmentSlot.localized_starts_at})
        );
        setLoading(false);
      },
      accessCode: appointment.access_code,
      testLocationId: appointmentSlotGroup.test_location_id,
    })
  }, []);

  const modalDescription = useCancelAppointmentModalContent ? (
    <>
      <h2>{t("instructions.confirm_cancellation")}</h2>
      <p>{t("instructions.confirm_cancellation_question")}</p>
    </>
  ) : (
    <>
      <h2>{t("login.edit_appointment")}</h2>
      <p>{t("instructions.select_new_appointment_time_below")}</p>
    </>
  );

  const modalFooter = useCancelAppointmentModalContent ? (
    <Modal.Footer>
      {error && <div className="text-danger">{error}</div>}
      <Button
        block={isMobile()}
        className="mx-2"
        onClick={() => setUseCancelAppointmentModalContent(false)}
        variant="outline-primary"
      >
        {t("instructions.no_take_me_back")}
      </Button>
      <Button
        block={isMobile()}
        onClick={onCancel}
        variant="danger"
      >
        {loading &&
          <Spinner animation="border" size="sm" className="me-2" variant="danger" role="status">
            <span className="visually-hidden">{t('self_resulting.loading')}...</span>
          </Spinner>
        }
        {t("instructions.yes_cancel_appointment")}
      </Button>
    </Modal.Footer>
  ) : (
    <Modal.Footer>
      {error && <div className="text-danger">{error}</div>}
      <Button
        block={isMobile()}
        className="mx-2"
        onClick={() => setUseCancelAppointmentModalContent(true)}
        variant="outline-danger"
      >
        {t("instructions.cancel")}
      </Button>
      <Button
        block={isMobile()}
        disabled={appointmentSlotId === appointmentSlot.id}
        onClick={onSubmit}
      >
        {loading &&
          <Spinner animation="border" size="sm" className="me-2" variant="light" role="status">
            <span className="visually-hidden">{t('self_resulting.loading')}...</span>
          </Spinner>
        }
        {t("instructions.update_appointment")}
      </Button>
    </Modal.Footer>
  );

  const appointmentTime = useCancelAppointmentModalContent ? (
    <div className="my-4">
      <label className="fw-bold mb-3">
        {t("instructions.appointment_details")}:
      </label>
      <div>{appointmentSlot.starts_at_label}</div>
    </div>
  ) : (
    <div className="my-4">
      <label className="fw-bold mb-3">
        {t("instructions.select_new_appointment_time")}:
      </label>
      <div>
        <Select
          className="basic-single mb-3"
          classNamePrefix="select"
          value={options.find((option) => option.value === appointmentSlotId)}
          onChange={(e) => setAppointmentSlotId(e.value)}
          components={{ IndicatorSeparator: () => null }}
          options={options}
          isSearchable={true}
          name="appointment[appointment_slot_id]"
        />
      </div>
    </div>
  );

  return (
    <Modal
      onHide={onHide}
      show={showEditAppointmentModal}
      size="lg"
    >
      <div className="px-4">
        <ModalHeader
          closeButton
          onHide={onHide}
          style={{ padding: "20px 50px 0px 0px" }}
        ></ModalHeader>
        <Modal.Body>
          {modalDescription}
          <div className="my-4">
            <label className="fw-bold">{t("result_page.patient")}:</label>
            <div>
              {appointment.user.full_name}
              <span className="ms-4">{t("registration.dob")}: {appointment.user?.date_of_birth && format(parseISO(appointment.user.date_of_birth), "MM/dd/yyyy")}</span>
            </div>
          </div>
          <div className="my-4">
            <label className="fw-bold">{t("instructions.clinic")}:</label>
            <div>
              <a target="_blank" href={`https://maps.google.com/?q=${encodeURIComponent(appointmentSlotGroup.address)}`}>
                {appointmentSlotGroup.title}
              </a>
            </div>
          </div>
          <div className="my-4">
            <label className="fw-bold">{t("instructions.services")}:</label>
            <div>{selectedServiceNames}</div>
          </div>
          {appointmentTime}
        </Modal.Body>
        {modalFooter}
      </div>
    </Modal>
  );
};

export default EditAppointmentModal;