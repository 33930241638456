import React, { useState, useEffect } from 'react';
import '../../../../common/locales/i18n';
import ErrorMessage from '../../../../common/components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import SignatureInputField from '../../../../common/components/SignatureInputField';

const SignatureInput = ({
  errors = {},
  setFieldValue,
  initialSignatureBase64,
  fieldValueName,
}) => {
  const { t, _ } = useTranslation();

  let canvasRef = null;
  useEffect(() => {
    if (!!errors.signature_base64) canvasRef._canvas?.focus();
  }, [errors]);

  return (
    <div>
      <div className="text-gray-dark-700 mb-1">{t('registration.consent_helper')}</div>
      <SignatureInputField
        initialSignatureBase64={initialSignatureBase64}
        onChange={(data) => setFieldValue(fieldValueName, data)}
        isInvalid={!!errors.signature_base64}
        setCanvasRef={(ref) => canvasRef = ref}
      />
      {errors.signature_base64 && (
        <ErrorMessage
          id="signatureErrorDesc"
          message={t(errors.signature_base64)}
        />
      )}
    </div>
  );
};

export default SignatureInput;
