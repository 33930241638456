import React, { useCallback, useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { PrimaryIcon } from '../../common/components/Icons/PrimaryIcon';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import ReactMarkdown from 'react-markdown';
import { LocationInformation } from '../../Registration/components/registration/DefaultInformation';
import { KIT_TYPE_REGEX } from '../Sti/StiFlow';

const TestKits = ({
  testKits,
  testGroup,
  user: {
    appointment: {
      access_code,
      appointment_slot_group: {
        location_type,
        address,
        test_location,
        ...appointment_slot_group
      }
    },
    ...user
  }
}) => {
  const { t } = useTranslation();
  const [copyText, setCopyText] = useState("copy_link");
  let scannedKits = [],
      recommendedKits = [],
      fullfilmentKits = [];
  (testKits || []).forEach(kit => {
    if (kit.barcode) {
      scannedKits.push(kit);
    } else if (kit.fulfillment) {
      fullfilmentKits.push(kit)
    } else {
      recommendedKits.push(kit) 
    }
  });

  const newTestKitButton = (
    <Card
      className='pointer mb-4'
      style={{border: "dashed 1px #DEE2E6"}}
      data-test-hook="activate_test_strip"
      onClick={() => window.location.href = `/kits?access_code=${access_code}` }
    >
      <Card.Body style={{placeContent: "center"}} className='d-flex align-items-center w-100 text-muted text-center'>
        <PrimaryIcon icon="plus" height={16} width={16} color="#314677" className="me-1" />
        <span className='text-secondary'>{t('test_strip.register_another_test')}</span>
      </Card.Body>
    </Card>
  )

  const nextSteps = useCallback(() => {
    const results = scannedKits.map((kit) => {
      switch (true) {
        case kit.status === "resulted":
          return {
            key: "resulted",
            content: (
              <ReactMarkdown
                source={t("instructions.next_steps.resulted", {
                  link: `/t/${testGroup.slug}/r/?access_code=${access_code}`,
                })}
              />
            ),
          };
        case !kit.administered:
          return {
            key: "scanned",
            content: t("instructions.next_steps.p1"),
          };
        case kit.administered:
          return {
            key: "administered",
            content: (
              <ReactMarkdown
                source={t("instructions.next_steps.administered", {
                  link: `/t/${testGroup.slug}/r/?access_code=${access_code}`,
                })}
              />
            ),
          };
        default:
          return null;
      }
    });

    const uniqueSteps = Array.from(new Set(results.filter((result) => result !== null).map((result) => result.key)))
      .map((key) => results.find((result) => result.key === key))
      .map((result, idx) => <div className="h4-20-regular mb-3" key={idx}>{result.content}</div>);

    return uniqueSteps;
  }, []);

  const scannedKitButton = ({administered, status, barcode, test_kit_url}) => {
    if (!administered) {
      return (
        <Button
          className="mt-3"
          onClick={() => location.href = test_kit_url}
          data-test-hook={`activate_test_strip_${barcode}`}
        >
          {t('test_strip.activate_kit')}
        </Button>
      )
    }
    if (status === "resulted") {
      return (
        <Button
          className="mt-3"
          onClick={() => location.href = `/t/${testGroup.slug}/r/?access_code=${access_code}`}
          data-test-hook={`view_results_${barcode}`}
        >
          {t('test_strip.view_results')}
        </Button>  
      )
    }

    return <div className='mt-2'>{t('test_strip.administered_text')}</div>
  }

  return (
    <div>
      {scannedKits.length > 0 &&
        <Card body className='gray-light-100 border-0 mb-4'>
          <h5 className='mb-3'>{t("instructions.next_steps.title")}</h5>
          {nextSteps()}
          {location_type == "mail_order" && fullfilmentKits.length > 0 &&
            <div>
              <h5 className='mb-3'>{t("instructions.next_steps.shipping_information")}</h5>
              <div className='h4-20-regular mb-3'>
                <div>{user.first_name} {user.last_name}</div>
                {user.address_1 &&
                  <address>
                    {user.address_1} {user.address_2}<br/>
                    {user.city && `${user.city},`} {user.state} {user.postal_code}
                  </address>
                }
              </div>
              <div className='mb-4'>
                <h5 className='mb-3'>{t('test_strip.test_kit')}</h5>
                {fullfilmentKits.map(
                  ({ kit_type }, index) => (
                    <Row key={index}>
                      <Col xs="auto">
                        <img
                          src="/images/sti-programs/test-kit-2.png"
                          width={64}
                          height={64}
                        ></img>
                      </Col>
                      <Col style={{alignSelf: "center"}}>
                        <b style={{ color: '#2E948E' }}>{t('test_strip.box_type', { type: kit_type })}</b><span>: {t(`test_strip.${kit_type}`)}</span>
                      </Col>
                    </Row>
                ))}
              </div>
            </div>
          }
          <a
            href="javascript:;"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopyText("copied_link")
            }}
            aria-label={t(copyText)}
          >
            <h6>{t(copyText)}</h6>
          </a>
        </Card> 
      }
      {scannedKits.map(
        ({ barcode, test_kit_url, administered, status}, index) => (
          <Card body className="mb-4" style={{ height: 'auto' }} key={index}>
            <Row>
              <Col xs="auto">
                <img
                  src="/images/sti-programs/test-kit-2.png"
                  width={64}
                  height={64}
                ></img>
              </Col>
              <Col>
                <div className="mt-2">
                  <div>
                    <b style={{ color: '#2E948E' }}>
                      {administered && t('test_strip.completed')}{' '}
                      {t('test_strip.box_type', {
                        type: barcode.match(KIT_TYPE_REGEX)[1],
                      })}
                    </b>
                    <span>: {t(`test_strip.${barcode.match(KIT_TYPE_REGEX)[1]}`)}</span>
                  </div>
                  <div>
                    <b>{t('otc.barcode')}</b> {barcode}
                  </div>
                  {scannedKitButton({barcode, test_kit_url, administered, status})}
                </div>
              </Col>
            </Row>
          </Card>
      ))}
      {testGroup.flipper_flags.order_sti_test_kit &&
        scannedKits.length > 0 &&  
        newTestKitButton
      }
      {recommendedKits.length > 0 && 
        <Card body className='gray-light-100 border-0 mb-4'>
          <h5>{t("instructions.next_steps.title")}</h5>
          <div>{t('test_strip.pick_up')}:</div>
          <Row className='mb-3'>
            <Col xs="auto">
              <iframe src={`https://maps.google.com/maps?q=${encodeURIComponent(address)}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                  style={{border: 0, width: 170, height: 170}} width="170" height="170"></iframe>
            </Col>
            <Col>
              <img src={testGroup.partner_logo_url || testGroup.simplified_partner_logo_url} width={100} />
              <LocationInformation
                address={{
                  address_1: test_location.address_line_1,
                  ...test_location,
                }}
                name={`${testGroup.name} (${appointment_slot_group.title})`} 
              >
                <div className='caption poppins regular text-muted' style={{marginBottom: 6}}>
                  <a className="inter semibold ms-1" href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}>
                    {t('instructions.get_directions')} <FontAwesomeIcon icon={faArrowUp} className="rotate-45" />
                  </a>
                </div>
                <LocalizedMarkdown container={appointment_slot_group} stringKey="description" />
              </LocationInformation>
            </Col>
          </Row>
          <h5 className='mt-3'>{t('test_strip.recommended_kit_title')}</h5>
          {recommendedKits.map(
            ({ kit_type }, index) => (
              <Row key={index}>
                <Col xs="auto">
                  <img
                    src="/images/sti-programs/test-kit-2.png"
                    width={64}
                    height={64}
                  ></img>
                </Col>
                <Col style={{alignSelf: "center"}}>
                  <b style={{ color: '#2E948E' }}>
                    {t("test_strip.box_type", { type: kit_type })}
                  </b><span>: {t(`test_strip.${kit_type}`)}</span>
                </Col>
              </Row>
          ))}
        </Card>
      }
      {testGroup.flipper_flags.order_sti_test_kit &&
        scannedKits.length === 0 && 
        newTestKitButton
      }
    </div>
  );
};


export default TestKits;
