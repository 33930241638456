import React, { useContext, useState } from "react";

import Select, { components } from "react-select";
import { SERVICE_SPECIFICATION_TYPES } from "./TestGroupTestConfigurationsIndex";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const TestGroupTestConfigurationSelector = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const [typedInput, setTypedInput] = useState(null);

  const filterOption = (option) => {
    // filtering the custom dropdown options on typing
    if (!typedInput) return true;
    try {
      return option.label.props.children[0].props.children.toLowerCase().includes(typedInput.toLowerCase());
    } catch(e) {
      return true;
    }
  };

  const testConfigurationOptions = (
    state.filteredTestConfigurationOptions ||
    state.testConfigurationOptions.allowed_test_configurations
  ).map((tc) => {
    return {
      value: tc.id,
      label: (
        <div>
          <div>{tc.checkout_name}</div>
          <div className="body-medium-normal inter regular">
            {tc.age_range_label}
          </div>
          <div className="body-medium-normal inter light text-muted">
            {tc.test_type}
          </div>
        </div>
      )};
  });

  const selectedTestConfiguration = testConfigurationOptions.find((option) => {
    return option.value == state.testGroupTestConfiguration.test_configuration_id;
  });

  const CustomServiceSpecificationTypeFilter = () => {
    return (
      <div
        className="d-flex p-2"
        style={{ backgroundColor: "#F7F9FC" }}
      >
        {Object.keys(SERVICE_SPECIFICATION_TYPES).map((key) => {
          return (
            <div
              className="me-1 p-1 pointer text-center body-medium-bold my-auto"
              onClick={() => {
                dispatch({
                  type: "setTestGroupTestConfigurationSelectedFilter",
                  values: key,
                });
              }}
              style={{
                backgroundColor: state.testGroupTestConfigurationSelectedFilter == key ? "#314677" : "white",
                borderRadius: "5px",
                color: state.testGroupTestConfigurationSelectedFilter == key ? "white" : "black",
                width: "70px",
              }}
            >
              {SERVICE_SPECIFICATION_TYPES[key].label}
            </div>
          )
        })}
      </div>
    );
  };

  const MenuList = ({ ...props }) => {
    return (
      <components.MenuList {...props}>
        <CustomServiceSpecificationTypeFilter />
        {props.children}
      </components.MenuList>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {children.props.children[0].props.children}
      </components.SingleValue>
    );
  };

  return (
    <Select
      components={{ SingleValue, MenuList }}
      filledValue={!!state.testGroupTestConfiguration.test_configuration_id}
      filterOption={filterOption}
      onChange={(option) => {
        dispatch({
          type: "setTestGroupTestConfiguration",
          values: {
            ...state.testGroupTestConfiguration,
            test_configuration_id: option.value
          }
        });
      }}
      onInputChange={(input) => setTypedInput(input)}
      options={testConfigurationOptions}
      value={selectedTestConfiguration}
    />
  )
};

export default TestGroupTestConfigurationSelector;