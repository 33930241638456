import React, { useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";

import ModalHeader from "../../common/components/ModalHeader";

const CancelScheduledMessageModal = ({
  communication,
  communications,
  onHide,
  setCommunications,
  show,
}) => {

  const [loading, setLoading] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const cancelRepeatingCommunication = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`/messages/${communication.id}`, {
        communication: { repeating: false }
      });
      const newCommunications = [...communications];
      const communicationIndex = newCommunications.findIndex(communication => communication.id == response.data.communication.id);
      newCommunications[communicationIndex].repeating = false
      setCommunications(newCommunications);
      toastr.success(`Scheduled message successfully  cancelled`);
    } catch(e) {
      toastr.error("Something went wrong")
    }
    setLoading(false);
    onHide();
  };

  return (
    <Modal onHide={onHide} show={show}>
      <ModalHeader closeButton onHide={onHide}>
        <h3>Confirm cancellation</h3>
      </ModalHeader>
      <Modal.Body>
        {showConfirmationMessage ? (
          <p>Are you sure you want to cancel this scheduled message?</p>
        ) : (
          <p>Cancel recurring message?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="primary"
          onClick={() =>
            showConfirmationMessage
              ? cancelRepeatingCommunication()
              : setShowConfirmationMessage(true)
          }
        >
          {loading && "..."}
          {!loading && "Cancel message"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelScheduledMessageModal;