export default {
    "title": "viral_surveillance.shipping.title",
    "steps": [
        {
            "label": "viral_surveillance.shipping.step_1.label",
            "substeps": [
                {
                    "label": "viral_surveillance.shipping.step_1.substep_1"
                },
                {
                    "label": "viral_surveillance.shipping.step_1.substep_2"
                },
            ]
        },
        {
            "label": "viral_surveillance.shipping.step_2.label",
            "substeps": [
                {
                    "label": "viral_surveillance.shipping.step_2.substep_1"
                },
                {
                    "label": "viral_surveillance.shipping.step_2.substep_2"
                },
            ]
        },
        {
            "label": "viral_surveillance.shipping.step_3.label",
            "substeps": [
                {
                    "label": "viral_surveillance.shipping.step_3.substep_1"
                },
                {
                    "label": "viral_surveillance.shipping.step_3.substep_2"
                }
            ]
        }
    ]
}
