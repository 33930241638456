import React, { useState, useRef } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wrapped from '../../common/components/Wrapped';

export const VARIABLES = [
  {
    name: 'Group name',
    text: '%{name}',
    description:
      'Add the name of your group to the message.\nEx: Primary Covid-19 Testing',
  },
  {
    name: 'Participant name',
    text: '%{full_name}',
    description:
      'Add the participant’s full name to your message.\nEx: John Smith',
  },
  {
    name: 'Registration link',
    text: '%{reg_link}',
    description: 'Add a registration link that is unique to the participant.',
    previewText: 'Click here to get started',
  },
  {
    name: 'Record test link',
    text: '%{report_test_link}',
    previewText: 'Click here to record a test',
  },
  {
    name: 'Access key registration link',
    text: '%{reg_link(key=value)}',
    description:
      'Add a link that gives the participant access to appointment slots that are only available to participants with that access key. Please replace value in %{reg_link(key=value)} with the access key.\nEx: %{reg_link(key=walkup)}',
    replaceText: 'value',
  },
  {
    name: 'Location registration link',
    text: '%{reg_link(location=id)}',
    description:
      'Add a link that directs the participant to a specific location during registration. Please replace id in %{reg_link(location=id)} with the id number for the appointment slot group.\nEx: %{reg_link(location=123)}',
    replaceText: 'id',
  },
];

const VariableChip = ({ name, insertVariable, description }) => (
  <Wrapped
    WrapperComponent={TooltipWrapper}
    wrapIf={!!description}
    componentProps={{ tooltipContent: description, placement: 'bottom' }}
  >
    <Button
      className="variable-chip"
      variant="outline-tertiary"
      size="sm"
      onClick={insertVariable}
    >
      {name}
    </Button>
  </Wrapped>
);

const ChipArea = ({
  additionalVariables,
  hideRecordTestLinkQuickAdd = false,
  expanded,
  insertVariable,
}) => {
  let variables = VARIABLES.concat(additionalVariables || []);
  if (hideRecordTestLinkQuickAdd)
    variables = variables.filter((v) => v.text !== '%{report_test_link}');

  return (
    <Accordion activeKey={expanded ? '1' : '0'}>
      <Accordion.Collapse className="mt-2" eventKey="1">
        <div className="d-flex flex-wrap" style={{ gap: 5 }}>
          {variables.map((variable, idx) => (
            <VariableChip
              {...variable}
              insertVariable={insertVariable(variable)}
              key={idx}
            />
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};

const QuickAdds = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="d-flex flex-column py-2">
      <a
        className="text-secondary"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setExpanded(!expanded);
        }}
      >
        Personalize {props.label?.toLowerCase()}{' '}
        <FontAwesomeIcon icon={['fa-solid', expanded ? 'angle-up' : 'angle-down']} />
      </a>
      <ChipArea expanded={expanded} {...props} />
    </div>
  );
};

export const TextAreaWithVariables = ({
  label,
  name,
  id,
  rows = 2,
  additional_variables,
  hide_record_test_link_quick_add,
  test_hook = null,
}) => {
  const textAreaRef = useRef(null);
  const eventRef = useRef(new Event('content_change'));

  const emitCustomEvent = () =>
    textAreaRef.current?.dispatchEvent(eventRef?.current);

  const insertVariable =
    ({ text: variable, replaceText }) =>
    () => {
      const selectionStart = textAreaRef.current.selectionStart;
      const selectionEnd = textAreaRef.current.selectionEnd;
      const text = textAreaRef.current.value;
      const newText = `${text.substring(
        0,
        selectionStart,
      )}${variable}${text.substring(selectionEnd, text.length)}`;

      textAreaRef.current.value = newText;
      textAreaRef.current.focus();

      const selectionStartOffset = replaceText
        ? variable.indexOf(replaceText)
        : variable.length;
      const selectionEndOffset = replaceText ? replaceText.length : 0;

      textAreaRef.current.selectionStart =
        selectionStart + selectionStartOffset;
      textAreaRef.current.selectionEnd =
        textAreaRef.current.selectionStart + selectionEndOffset;
      emitCustomEvent();
    };

  return (
    <div className={`mb-1 text ${id}`}>
      <label className="form-label text" htmlFor={id}>
        {label}
      </label>
      <textarea
        ref={textAreaRef}
        className="form-control text"
        rows={rows}
        name={name}
        id={id}
        onChange={emitCustomEvent}
        data-test-hook={test_hook}
      />
      <QuickAdds
        label={label}
        additionalVariables={additional_variables}
        hideRecordTestLinkQuickAdd={hide_record_test_link_quick_add}
        insertVariable={insertVariable}
      />
    </div>
  );
};
