import React from 'react';

export const Flask = ({ color = '#2862FA', ...props }) => (
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5731 14.7005C21.2384 14.3658 20.8121 14.1376 20.3479 14.0448L17.4857 13.4723C15.9172 13.1586 14.2889 13.3767 12.8582 14.092L12.4777 14.2823C11.047 14.9976 9.41874 15.2157 7.85025 14.902L5.5344 14.4388C4.74831 14.2816 3.93564 14.5277 3.36878 15.0945M7.87263 1H17.4633L16.2645 2.19883V8.39869C16.2645 9.03459 16.5171 9.64445 16.9667 10.0941L22.9609 16.0883C24.4714 17.5987 23.4016 20.1813 21.2655 20.1813H4.07044C1.93435 20.1813 0.86459 17.5987 2.37504 16.0883L8.36921 10.0941C8.81886 9.64445 9.07147 9.03459 9.07147 8.39869V2.19883L7.87263 1Z"
      style={{
        stroke: color,
        strokeWidth: '2',
        strokeLinecap: 'round',
        strokeLineJoin: 'round',
      }}
    />
  </svg>
);

export default Flask;
