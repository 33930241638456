import React from 'react';
import CheckboxCard from '../../common/components/CheckBoxCard';
import DateOfBirthWithDropdown from '../../common/components/DateOfBirthWithDropdown';
import { useTranslation } from 'react-i18next';
import { buildCustomizedLabel } from '../../common/locales/langs';

export default ({state, dispatch}) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className='mt-4 mb-3 inter medium'>{t('registration.insurance_policy_holder.question')}</h4>
      {state.testGroup.policy_holder_options.map((policyHolder) => (
        <CheckboxCard
          name="policy_holder"
          key={policyHolder}
          radio
          label={t(buildCustomizedLabel(state.testGroup.population, `registration.insurance_policy_holder.${policyHolder}`))}
          checked={state.formState.policyHolder === policyHolder}
          onChange={() => dispatch({type: "updateForm", values: {policyHolder: policyHolder}})}
        />
      ))}
      <h4 className='mt-4 mb-3 inter medium'>{t('registration.insurance_policy_holder.policy_dob')}</h4>
      <DateOfBirthWithDropdown
        customStyling={false}
        currentDate={state.formState.policyHolderDateOfBirth}
        setCurrentDate={({month, year, day}) => {
          dispatch({type: "updateForm", values: {policyHolderDateOfBirth: {month, year, day}}})
        }}
      />
    </>
  );
}
