import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import { Group } from '../../../../common/components/SelectGroup';
import { isMobile } from '../../../../common/utils/browser';
import { PersonalFieldDropdown } from '../../SectionComponents';

export const ethnicityOn = (ethnicityValue) => {
  return ethnicityValue === true || ethnicityValue === '1';
};

const EthnicityDropdown = ({
  attributeString = '',
  context = RegistrationContext,
  setFieldValue,
  values,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(context);
  const fieldKey = `${attributeString}ethnicity_blob`;
  const label = buildLocalizedString(testGroup, 'ethnicity_label');
  const noEthnicitySelected = Object.values(values.ethnicity_blob).every(
    (v) => v === '0',
  );
  const required = testGroup.required_attributes.ethnicity;

  const ethnicityOptions = useMemo(() =>
    Object.keys(testGroup.ethnicity_options).map((ethnicity) =>
      Object.keys(testGroup.ethnicity_options[ethnicity]).length !== 0
        ? {
            label: t(`hispanic_questions.${ethnicity}`),
            options: testGroup.ethnicity_options[ethnicity].map(
              (subEthnicity) => ({
                label: t(
                  `hispanic_questions.hispanic_expanded.${subEthnicity}`,
                ),
                value: `${ethnicity}.${subEthnicity}`,
              }),
            ),
            value: ethnicity,
          }
        : { label: t(`hispanic_questions.${ethnicity}`), value: ethnicity },
    ),
  );

  const selectedEthnicities = useMemo(
    () =>
      ethnicityOptions
        .map((eo) => (eo.options ? [eo, ...eo.options] : eo))
        .flat()
        .filter(
          (eo) =>
            !!values[fieldKey][eo.value] && values[fieldKey][eo.value] !== '0',
        ),
    [ethnicityOptions, values.ethnicity_blob],
  );

  return (
    <PersonalFieldDropdown
      helpText={
        buildLocalizedString(testGroup, 'ethnicity_help_text') ? (
          <LocalizedMarkdown
            container={testGroup}
            stringKey="ethnicity_help_text"
          />
        ) : (
          <span>{t('hispanic_questions.help')}</span>
        )
      }
    >
      <FloatingLabelSelect
        isMulti={
          !(
            values[fieldKey]['hispanic'] ||
            values[fieldKey]['not_hispanic'] ||
            values[fieldKey]['unknown']
          )
        }
        components={{ Group }}
        value={noEthnicitySelected ? [] : selectedEthnicities}
        isSearchable={!isMobile}
        onChange={(ethnicities) => {
          if (Array.isArray(ethnicities)) {
            const singleEthnicity = ethnicities.find((eth) =>
              ['not_hispanic', 'unknown'].includes(eth.value),
            );

            if (!singleEthnicity) {
              setFieldValue(
                fieldKey,
                ethnicities.reduce((blob, ethnicity) => {
                  blob[ethnicity.value] = true;
                  return blob;
                }, {}),
              );
            } else {
              setFieldValue(fieldKey, { [singleEthnicity.value]: true });
            }
          } else {
            setFieldValue(fieldKey, { [ethnicities.value]: true });
          }
        }}
        options={ethnicityOptions}
        filledValue={Object.keys(values[fieldKey]).length > 0}
        id="ethnicity"
        label={
          label
            ? `${label} ${required ? '*' : '(Optional)'}`
            : `${t('hispanic_questions.title')} ${required ? '*' : '(Optional)'}`
        }
      />
    </PersonalFieldDropdown>
  );
};

export default EthnicityDropdown;
