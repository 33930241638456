import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import FloatingLabelInput from './FloatingLabelInput';
import { useTranslation } from 'react-i18next';
import FloatingLabelSelect from './FloatingLabelSelect';
import { usStateOptions } from '../../MembersPortal/components/utils';
import ModalHeader from './ModalHeader';
import { validateEmail } from '../utils';
import ErrorMessage from './ErrorMessage';
import { conversationMainIssues } from '../utils';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const validationRules = {
  customerFirstName: {
    required: true,
    message: (t) =>
      t('registration.errors.field_required', {
        field: t('registration.first_name_label'),
      }),
  },
  customerLastName: {
    required: true,
    message: (t) =>
      t('registration.errors.field_required', {
        field: t('registration.last_name_label'),
      }),
  },
  customerEmail: {
    required: true,
    message: (t) =>
      t('registration.errors.field_required', {
        field: t('registration.email'),
      }),
    validator: validateEmail,
    errorMessage: (t) => t('registration.email_required'),
  },
  conversationMainIssue: {
    required: true,
    message: (t) =>
      t('registration.errors.field_required', {
        field: t('contact_support.ticket_type'),
      }),
  },
  messagePreview: {
    required: true,
    message: (t) =>
      t('registration.errors.field_required', {
        field: t('contact_support.zen_desc'),
      }),
  },
  authenticity_token: {
    required: true,
    message: (t) => t('registration.errors.required'),
  },
};

const SupportModal = ({
  user,
  appointment,
  showModal,
  setShowModal,
  testGroup,
  customOnSubmit,
  setSupportFormSubmitted,
}) => {
  const { t, i18n } = useTranslation();
  const currentUrl = window.location.href;
  const organization = testGroup?.organization;
  const onHide = () => setShowModal(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const onSubmit =
    customOnSubmit ||
    (() => {
      onHide;
      setSupportFormSubmitted(true);
    });

  const [form, setForm] = useState({
    authenticity_token: '',
    customerFirstName: user?.first_name,
    customerLastName: user?.last_name,
    customerEmail: user?.email,
    conversationPhoneNumber: user?.phone_number,
    testGroupSlug: testGroup?.slug,
    organizationName: organization?.name,
    customerLocale: user?.locale,
    organizationContractId: organization?.contract_id,
    incomingLocationHref: currentUrl,
    appointmentAccessCode: appointment?.access_code,
    testGroupUserAccessCode: user?.test_group_user?.access_code,
    messageSubject: 'Website User Support',
  });

  const validate = () => {
    const err = {};
    Object.entries(validationRules).forEach(
      ([key, { required, validator, message, errorMessage }]) => {
        if (required && !form[key]) {
          err[key] = message(t);
        } else if (validator && !validator(form[key])) {
          err[key] = errorMessage(t);
        }
      },
    );
    setErrors(err);
    return err;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const submitForm = () => {
    const err = validate();
    setSubmitted(true);
    if (Object.keys(err).length === 0) {
      axios
        .post('/api/contact_us_submissions', form)
        .then(() => onSubmit())
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (submitted) {
      validate(form);
    }
  }, [form]);

  return (
    <React.Fragment>
      <Modal show={showModal} onHide={onHide}>
        <ModalHeader closeButton onHide={onHide}>
          <Modal.Title>
            <h2 className="mb-0">{t('contact_support.contact_us')}</h2>
          </Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <div>
            <FloatingLabelInput
              id="customerFirstName"
              name="customerFirstName"
              value={form.customerFirstName}
              label={t('registration.first_name_label') + ' *'}
              ariaLabel={t('registration.first_name_label')}
              required={true}
              onChange={handleChange}
              validation={errors.customerFirstName && 'is-invalid'}
            />
            {errors.customerFirstName && (
              <ErrorMessage
                className="mt-1"
                id="customerFirstName"
                message={errors.customerFirstName}
              />
            )}
          </div>
          <div className="mt-3">
            <FloatingLabelInput
              id="customerMiddleName"
              name="customerMiddleName"
              value={form.customerMiddleName}
              label={t('registration.middle_name_label')}
              ariaLabel={t('registration.middle_name_label')}
              onChange={handleChange}
            />
          </div>
          <div className="mt-3">
            <FloatingLabelInput
              id="customerLastName"
              name="customerLastName"
              value={form.customerLastName}
              label={t('registration.last_name_label') + ' *'}
              ariaLabel={t('registration.last_name_label')}
              required={true}
              onChange={handleChange}
              validation={errors.customerLastName && 'is-invalid'}
            />
            {errors.customerLastName && (
              <ErrorMessage
                className="mt-1"
                id="customerLastName"
                message={errors.customerLastName}
              />
            )}
          </div>
          <div className="mt-3">
            <FloatingLabelInput
              id="customerEmail"
              name="customerEmail"
              value={form.customerEmail}
              label={t('registration.email') + ' *'}
              ariaLabel={t('registration.email')}
              required={true}
              type="email"
              onChange={handleChange}
              validation={errors.customerEmail && 'is-invalid'}
            />
            {errors.customerEmail && (
              <ErrorMessage
                className="mt-1"
                id="customerEmail"
                message={errors.customerEmail}
              />
            )}
          </div>
          <div className="mt-3">
            <FloatingLabelInput
              id="conversationPhoneNumber"
              name="conversationPhoneNumber"
              value={form.conversationPhoneNumber}
              label={t('consent_waiver.phone_number')}
              ariaLabel={t('consent_waiver.phone_number')}
              onChange={handleChange}
            />
          </div>
          <div className="mt-3">
            <FloatingLabelSelect
              id="conversationMainIssue"
              name="conversationMainIssue"
              value={conversationMainIssues.filter(
                (option) => option.value === form.conversationMainIssue,
              )}
              label={t('contact_support.ticket_type') + ' *'}
              ariaLabel={t('contact_support.ticket_type')}
              required={true}
              options={conversationMainIssues}
              filledValue={form.conversationMainIssue}
              onChange={(e) => {
                setForm({ ...form, conversationMainIssue: e.value });
              }}
              className={errors.conversationMainIssue && 'is-invalid'}
            />
            {errors.conversationMainIssue && (
              <ErrorMessage
                className="mt-1"
                id="conversationMainIssue"
                message={errors.conversationMainIssue}
              />
            )}
          </div>
          <div className="mt-3">
            <FloatingLabelSelect
              id="customerState"
              name="customerState"
              value={usStateOptions.filter(
                (option) => option.value === form.customerState,
              )}
              filledValue={form.customerState}
              label={t('no_insurance_information.state')}
              ariaLabel={t('no_insurance_information.state')}
              options={usStateOptions}
              onChange={(e) => setForm({ ...form, customerState: e.value })}
            />
          </div>
          <div className="my-3">
            <div className="input-container">
              <Form.Label
                className={form.messagePreview && 'filled'}
                htmlFor="messagePreview"
              >
                {t('contact_support.zen_desc')} *
              </Form.Label>
              <Form.Control
                id="messagePreview"
                name="messagePreview"
                as="textarea"
                rows={4}
                required={true}
                value={form.messagePreview}
                onChange={handleChange}
                className={errors.messagePreview && 'is-invalid'}
              />
              {errors.messagePreview && (
                <ErrorMessage
                  className="mt-1"
                  id="messagePreview"
                  message={errors.messagePreview}
                />
              )}
            </div>
          </div>
          <div className="my-3">
            <ReCAPTCHA
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setForm({ ...form, authenticity_token: value });
              }}
            ></ReCAPTCHA>
            {errors.authenticity_token && (
              <ErrorMessage
                className="mt-1"
                id="authenticity_token"
                message={errors.authenticity_token}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={submitForm}>
            {t('registration.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SupportModal;
