import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { Row, Col } from 'react-bootstrap';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { profileColors } from '../utils';
import MembersPortalContext from "../../context";
import { useMediaQuery } from 'react-responsive';

const MemberWrapper = ({ children }) => {
  const { currentUser, householdMembers, t } = useContext(MembersPortalContext);
  const colorIndex = householdMembers.findIndex(x => x.id == currentUser.id);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return isMobile ? (
    <div className="p-4">
      <div className="px-2">
        <Row className='mb-3 justify-content-center'>
          <Col>
            <h3 data-test-hook="dashboard_title">{t('login.user_dashboard', {first_name: currentUser.user.first_name})}</h3>
          </Col>
          <Col xs="auto">
            <div
              className='big profile-circle'
              style={{
                backgroundColor: profileColors[colorIndex] ? profileColors[colorIndex] : "#2862FA",
                width: 60,
                height: 60,
                fontSize: 24,
              }}
            >
              <div>
                {currentUser.user.first_name[0].toUpperCase()}{currentUser.user.first_name[1]}
              </div>
            </div>
          </Col>
        </Row>
      {children}
    </div>
  </div>
) : (
  <div className='py-4'>
    <h3 className='px-4 pb-4 pt-1 border-bottom'>{currentUser.user.first_name} {currentUser.user.last_name}</h3>
    <div className='px-4'>
      {children}
    </div>
  </div>
);
};

export default MemberWrapper;
