import React, { useContext, useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Button } from 'react-bootstrap';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import ErrorMessage from '../../common/components/ErrorMessage';
import PureToggleButton from '../../common/components/PureToggleButton'

import InsuranceContext from '../contexts/InsuranceContext';

const NoInsuranceInformationFields = ({
  clearInsuranceInformation,
  values,
  errors,
  handleChange,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction } = useContext(InsuranceContext);
  const [fileName, setFileName] = useState(null);
  const [noInsurance, setNoInsurance] = useState(false);

  const usStateOptions = Object.keys(testGroup.us_states).map((key) => ({
    label: testGroup.us_states[key],
    value: key,
  }));

  return (
    <div>
      {formAction.requestDriversLicense && (
        <React.Fragment>
          <Row>
            <b>{t('no_insurance_information.upload_front_of_driver_license')}</b>
            <div className="mt-1 mb-3">
              <input
                type="file"
                onChange={(e) => {
                  setFieldValue('user[driver_license]', e.currentTarget.files[0]);
                  setFileName(e.currentTarget.files[0]["name"])
                }}
                name={`user[driver_license]`}
                accept="image/*;capture=camera"
                id="driver-license-file-upload"
                style={{visibility: "hidden", width: "0px"}}
              />
              <label htmlFor="driver-license-file-upload" className="btn-sm btn-outline-secondary text-center"><b>{t('no_insurance_information.choose_file')}</b></label>
              <label className="mx-2">{fileName || t('no_insurance_information.no_file_chosen')}</label>
            </div>
          </Row>
          <Row>
            <Col className="mb-2">
              <div className={"input-container mt-3"}>
                <Form.Control
                  name="user[driver_license_number]"
                  value={values.user.driver_license_number || ''}
                  onChange={handleChange}
                  id="driver_license_number"
                />
                <label className={values.user.driver_license_number && 'filled'} htmlFor="driver_license_number">
                  {t('no_insurance_information.driver_license_or_state_id')}
                </label>
              </div>
            </Col>
            <Col xs={12} sm={4} md={3} className="mb-2">
              <FloatingLabelSelect
                name="user[driver_license_state]"
                value={usStateOptions.filter(
                  (option) => option.value === values.user.driver_license_state,
                )}
                onChange={(s) => {
                  setFormAction({ ...formAction, driverLicenseState: s.value });
                  setFieldValue('user.driver_license_state', s.value);
                }}
                options={usStateOptions}
                components={{ IndicatorSeparator: () => null }}
                ariaLabel={t('no_insurance_information.state')}
                id="driver_license_state"
                filledValue={values.user.driver_license_state}
                label={t('no_insurance_information.state')}
                containerClassName="mt-3"
                className={errors.driver_license_state && 'is-invalid'}
              />
              {errors.driver_license_state && (
                <ErrorMessage message={
                  t(errors.driver_license_state, {
                    field: t('no_insurance_information.state')
                  })}
                />
              )}
            </Col>
          </Row>
          <br />
        </React.Fragment>
      )}
      <div className="mb-2">
        {!formAction.useSSN &&
          formAction.requestSocialSecurityNumber && (
            <button
              type="button"
              className="btn btn-outline-secondary me-2"
              data-bs-toggle="button"
              onClick={() => {
                setNoInsurance(false)
                setFormAction({ ...formAction, useSSN: !formAction.useSSN });
                if (!formAction.useSSN) {
                  setFieldValue('user.social_security_number', null);
                }
              }}
            >
              {t('no_insurance_information.use_ssn_instead')}
            </button>
          )}
        {(!formAction.requireSocialSecurityNumber && !formAction.requireDriversLicense) && (
          <PureToggleButton
            active={noInsurance}
            outline
            onClick={() => {
              setNoInsurance(!noInsurance)
              setFormAction({
                ...formAction,
                participantHasID: noInsurance,
              });
              if (!noInsurance) {
                clearInsuranceInformation(!formAction.participantHasID);
                clearInsuranceInformation(formAction.participantHasID);
              }
            }}
          >{t('no_insurance_information.no_identification')}</PureToggleButton>
        )}
        {errors.no_insurance && (
          <ErrorMessage message={
            t(errors.no_insurance, {
              field: t('registration.insurance_information.title')
            })}
          />
        )}
      </div>
      {formAction.useSSN && formAction.requestSocialSecurityNumber && (
        <div className="mb-3">
          <FloatingLabelInput
            name="user[social_security_number]"
            className="mt-3"
            value={values.user.social_security_number || ''}
            onChange={handleChange}
            maxLength="9"
            minLength="9"
            id="social_security_number"
            label={t('no_insurance_information.social_security_number')}
          />
        </div>
      )}
    </div>
  );
};

export default NoInsuranceInformationFields;
