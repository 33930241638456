import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import ErrorMessage from '../../../common/components/ErrorMessage';

export function ShippingInformation({ data, activeErrors, t }) {
  const { title, steps } = data;
  return (
    <div>
      <h4 className="my-2">{t(title)}</h4>
      <div className="my-2">
        {steps.map(({ label, substeps }, index) => (
          <Row className="my-2 py-2" key={index}>
            <Col xs="auto" className="pe-0">
              <span className="fa-stack" style={{ verticalAlign: 'middle' }}>
                <i>
                  <FontAwesomeIcon
                    icon={'circle'}
                    style={{ color: '314677' }}
                    className="fa-stack-2x"
                  />
                </i>
                <span className="fa-layers-text" style={{ color: '#FFFFFF' }}>
                  {index + 1}
                </span>
              </span>
            </Col>
            <Col>
              <div className="my-1">{t(label)}</div>
              {substeps && (
                <div className="my-2 pt-2">
                  {substeps.map(({ label }, index) => (
                    <Row
                      className="my-2"
                      style={{ verticalAlign: 'middle' }}
                      key={index}
                    >
                      <Col xs="auto" className="pe-0">
                        <i>
                          <FontAwesomeIcon
                            icon={'circle'}
                            style={{ color: '314677' }}
                            className="fa-xs"
                          />
                        </i>
                      </Col>
                      <Col>
                        <ReactMarkdown source={t(label)} />
                      </Col>
                    </Row>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        ))}
      </div>
      {activeErrors['error_activating_test_kit'] ? (
        <Row>
          <ErrorMessage
            message={activeErrors['error_activating_test_kit']}
            className="my-3"
          />
        </Row>
      ) : null}
    </div>
  );
}
