import React, { useState } from 'react';
import ActionRow from './components/ActionRow';
import PdfPreview from './components/PdfPreview';
import TranslationSelections from './components/TranslationSelections';

const RegistrationFlyer = ({ locales, addlLocales, testGroup, registrationType, qrCodeUrl }) => {
  const [selectedLocales, setSelectedLocales] = useState([]);
  const [activeLocale, setActiveLocale] = useState("");

  return(
    <>
      <div className="d-flex flex-column flex-md-row mt-4 d-print-none registration-flyer">
        <div className="w-100 w-md-50">
          <h6 className="mb-4 me-4">{`Confirm which translations to print for ${registrationType} registration`}</h6>
          <TranslationSelections
            locales={locales}
            addlLocales={addlLocales}
            setSelectedLocales={setSelectedLocales}
            selectedLocales={selectedLocales}
            setActiveLocale={setActiveLocale}
            activeLocale={activeLocale}
          />
        </div>
        <PdfPreview
          locale={activeLocale}
          testGroup={testGroup}
          qrCodeUrl={qrCodeUrl}
        />
      </div>
      <ActionRow
        locales={selectedLocales}
        testGroup={testGroup}
        qrCodeUrl={qrCodeUrl}
      />
    </>
  )
}

export default RegistrationFlyer;
