import React, { useContext, useState } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import FabrxCheckbox from '../../../primary/FabrxCheckbox';
import RegistrationContext from '../../../contexts/RegistrationContext';

const doseOptionAll = [
  "default",
  "initial_vaccine_appointment",
  "booster",
  "additional",
  "supplemental",
  "no_vaccine",
]

const sortDoseOptions = (itemsArray) => itemsArray.sort(function(a, b){  
  return doseOptionAll.indexOf(a.value) - doseOptionAll.indexOf(b.value);
});

export const doseOptions = (appointmentConfigurations) => {
  const fixValue = (ac) => {
    if (ac.split('.')[1] == "initial_vaccine_appointment") return "default"
    return ac.split('.')[1]
  }
  const options =  appointmentConfigurations
    .map((ac) => {
      if (ac.includes('appointment_type')) {
        return { label: ac, value: fixValue(ac) };
      }
    })
    .filter((option) => option);

  return sortDoseOptions(options);
};

const ChooseAppointmentType = ({ values, setFieldValue, errors, onClick }) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);

  if (testGroup.old_choose_appointment_type) {
    return <OldChooseAppointmentType {...{values, setFieldValue, errors, onClick }} />
  }

  const { appointment } = values;
  const [doseSelected, setDoseSelected] = useState(false);

  const options = doseOptions(testGroup.appointment_configurations);

  const onChange = (appointmentType) => {
    setFieldValue('appointment.appointment_type', appointmentType);
    setFieldValue('appointment.appointment_slot_id', null);
    setFieldValue('appointment_slot_group_id', null);
    setDoseSelected(true)
    onClick();
  };


  return (
    <div>
      <h6>{t('registration.select_vaccine_dose')}.</h6>
      <div>
        {options.map(option => 
          <FabrxCheckbox
            className="me-3"
            ariaLabel={t(option.label)}
            name={t(option.label)}
            onChange={() => onChange(option.value)}
            checked={doseSelected && option.value == appointment.appointment_type}
            radio={true}
            label={t(option.label)}
          />
        )}
      </div>       
      {errors.dose && <div className="text-danger">{t(errors.dose)}</div>}
    </div>
  );
};


const OldChooseAppointmentType = ({ values, setFieldValue, errors, onClick }) => {
  const { t, i18n } = useTranslation();
  const { testGroup } = useContext(RegistrationContext);
  const [value, setValue] = useState(null);
  const { appointment } = values;
  const [previousCovidVaccination, setPreviousCovidVaccination] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [doseSelected, setDoseSelected] = useState(false);

  const options = doseOptions(testGroup.appointment_configurations);

  const onChange = (appointmentType) => {
    setValue(appointmentType)
    setFieldValue('appointment.appointment_type', appointmentType);
    setFieldValue('appointment.appointment_slot_id', null);
    setFieldValue('appointment_slot_group_id', null);
    setDoseSelected(true)
    onClick();
  };

  const onYesNoChange = (bool) => {
    setPreviousCovidVaccination(bool);
    setShowOptions(true);
  }

  return (
    <div>
      <h6>{t('registration.received_covid_vaccine_y_n')}</h6>
      <div className="mb-3">
        <FabrxCheckbox
          ariaLabel={t('yes')}
          name="previous_covid_vaccination"
          className="me-4"
          onChange={() => onYesNoChange(true) }
          radio={true}
          label={t('yes')}
          dataTestHook="previous_covid_vaccination_true"
        />
        <FabrxCheckbox
          ariaLabel={t('no')}
          name="previous_covid_vaccination"
          onChange={() => onYesNoChange(false)}
          radio={true}
          label={t('no')}
        />
      </div>
      { showOptions && <h6>{t('registration.select_vaccine_dose')}.</h6> }
        
      {errors.dose && <div className="text-danger">{t(errors.dose)}</div>}
    </div>
  );
};
export default ChooseAppointmentType;
