import React, { useEffect, useState } from 'react';
import DeleteSearchFilterModal from './DeleteSearchFiltersModal';
import SearchFilterBadge from './SearchFilterBadge';
import axios from 'axios';
import {
  Button,
  Modal
} from 'react-bootstrap'


const SearchFilters = ({
  test_group,
  source_url,
  filter_type,
}) => {

  const [searchFilters, setSearchFilters] = useState([]);
  const [showDeleteSearchFilterModal, setShowDeleteSearchFilterModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    getUsersSearchFilters();
  }, []);

  const getUsersSearchFilters = async () => {
    const response = await fetchSearchFilters();
    if (response.status === 200) {
      const searchFilters = response.data.search_filters;
      const urlParams = new URLSearchParams(window.location.search);
      const selectedSearchFilterId = parseInt(urlParams.get('search_filter_id'));
      const selectedSearchFilter = searchFilters.find(f => f.id === selectedSearchFilterId);
      setSearchFilters(searchFilters);
      if (!!selectedSearchFilter) setSelectedFilter(selectedSearchFilter);
    }
  }

  const fetchSearchFilters = async () => {
    return axios.get(`/test_groups/${test_group.id}/search_filters`, {
      params: {
        filter_type: filter_type,
      }
    });
  }

  return (
    <div className='my-2'>
      {searchFilters.map((searchFilter) => {
        return <SearchFilterBadge
          searchFilter={searchFilter}
          setShowDeleteSearchFilterModal={setShowDeleteSearchFilterModal}
          showDeleteSearchFilterModal={showDeleteSearchFilterModal}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          testGroup={test_group}
          source_url={source_url}
        />
      })}
      {searchFilters.length > 0 && (
        <Button
          variant="link"
          className="mx-3 btn-link-medium"
          onClick={() => window.location = `/test_groups/${test_group.slug}/${source_url}`}
        >
          Clear filters
        </Button>
      )}
      <Modal show={showDeleteSearchFilterModal} size='lg'>
        <DeleteSearchFilterModal
          selectedFilter={selectedFilter}
          testGroup={test_group}
          setShowDeleteSearchFilterModal={setShowDeleteSearchFilterModal}
          setSearchFilters={setSearchFilters}
          source_url={source_url}
        />
      </Modal>
    </div>
  )
}

export default SearchFilters;
