import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { buildLocalizedString } from '../common/locales/langs';
import { parseRange, formatRangeValue } from '../shared_labs/utils';

export class MultiplexResults {
  constructor(test, {renderers = {}}) {
    const { t, i18n } = useTranslation();
    this.t = t;
    this.test = test;
    this.service_specification = test.test_configuration.service_specification;
    this.results_schema = test.test_configuration.results_schema;
    this.subTests = this.results_schema.results;
    this.testResults = test.generic_test_results;
    this.renderers = renderers;
  }

  subTestFor(key) {
    return this.subTests.find((test) => test.type === key);
  }

  subTestResultLabel({ subTest, result }) {
    if (result.value_type == 'String') {
      return this.labelForStringValue(subTest, result.value);
    } else if (result.value_type == 'Numeric') {
      return this.labelForNumericValue(subTest, result);
    } else if (result.value_type == 'DateTime') {
      result.value_date_time;
    }
  }

  labelForStringValue(subTest, value) {
    if (subTest?.possible_values.length) {
      return subTest.possible_values.find(
        (possibleValue) => possibleValue.value === value,
      ).label;
    } else {
      return value;
    }
  }

  labelForNumericValue(subTest, result) {
    if (!result.value_numeric) return;

    const value = result.value_numeric;

    const rangeProps = this.rangeForValue(subTest, value.from);
    const rangeLabel =
      rangeProps?.type &&
      this.t(`result_page.result.${this.service_specification}.${rangeProps.type}`);
    const className = rangeProps?.type ? `text-${rangeProps.type}` : '';
    const cutOff = rangeProps?.cut_off;
    if (cutOff) {
      if (
        (rangeProps.from == 0 || rangeProps.from == Number.NEGATIVE_INFINITY) &&
        value < cutOff
      ) {
        value = `<${cutOff}`;
      } else if (rangeProps.to == Number.POSITIVE_INFINITY && value > cutOff) {
        value = `>${cutOff}`;
      }
    }
    return (
      <span className={className}>
        {formatRangeValue(value, rangeProps)}
        {subTest?.units || result.dynamic_result_units}
        {rangeLabel && `, ${rangeLabel}`}
      </span>
    );
  }

  rangeForValue(subTest, value) {
    if (!subTest?.ranges || !Object.keys(subTest?.ranges).length) return;

    const [rangeString, props] = Object.entries(subTest.ranges).find(
      ([rangeString, _props]) => {
        let range = parseRange(rangeString);
        if (range.from === range.to) {
          return range.from == +value;
        } else {
          return range.from <= +value && +value < range.to;
        }
      },
    );
    return { ...props, ...parseRange(rangeString) };
  }

  fixedResults() {
    const visibleTests = this.subTests.filter((st) => !st.hidden_in_results);
    if(this.testResults.length == 0 || visibleTests.length == 0) {
      return false
    }
    const rowProps = visibleTests.map(({ type: subTestKey }, index) => {
      const testResult = this.testResults.find(
        (result) => result.type == subTestKey,
      );
      const subTestResult = this.subTestResultValue(testResult);

      if (!subTestResult) return;

      const subTestResultKey = subTestResult
        ? `${this.service_specification}_${subTestKey}_${subTestResult}_result_text`
        : 'processing_result_text';

      const hasResultText = !!buildLocalizedString(
        this.test.test_configuration,
        subTestResultKey,
      );

      const resultLabel =
        buildLocalizedString(
          this.test.test_configuration,
          subTestResult
            ? `${this.service_specification}_${subTestKey}_${subTestResult}_result_string`
            : 'processing_result_string',
        ) ||
        this.subTestResultLabel({
          subTest: this.subTestFor(subTestKey),
          result: testResult,
        });

      const resultTextKey = this.test.result
        ? !!subTestResult
          ? `${this.service_specification}_${subTestKey}_${subTestResult}_result_text`
          : `${this.test.result}_result_text`
        : 'processing_result_text';

      return {
        index,
        testName: this.t(`result_page.result.${subTestKey}.result_label`),
        testConfiguration: this.test.test_configuration,
        resultLabel,
        resultTextKey,
        type: subTestKey,
      };
    });
    return this.renderers.ungrouped(rowProps);
  }

  dynamicResults() {
    const testsByRelatedGroupId = this.testResults.reduce((hash, result) => {
      hash[result.related_group_id] ||= [];
      hash[result.related_group_id].push(result);
      return hash;
    }, {});

    return Object.entries(testsByRelatedGroupId).map(
      ([groupId, results]) => {
        const rowProps = results.map((testResult, index) =>
          this.dynamicResultRowProps(testResult, index)
        );
        if (groupId) {
          const groupLabel = this.results_schema.related_group_mapping[groupId];
          return this.renderers.grouped({ groupId, groupLabel, results: rowProps });
        } else {
          return this.renderers.ungrouped(rowProps);
        }
      },
    );
  }

  dynamicResultRowProps(testResult, index) {
    const testName = testResult.dynamic_result_label || testResult.type;
    const resultLabel = this.subTestResultLabel({ result: testResult });

    return {
      testName: testName,
      testConfiguration: this.test.test_configuration,
      index,
      resultLabel,
      notes: testResult.notes,
      type: testResult.type,
    };
  }

  subTestResultValue(subTest) {
    return subTest?.value_numeric || subTest?.value_date_time || subTest?.value;
  }
}
