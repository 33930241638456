import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Webcam from './Webcam';
import PhotoBypassFields, { PhotoBypassButtons } from '../../shared_labs/components/PhotoBypassFields';

const UploadImage = ({ setImageData }) => {
  const [fileName, setFileName] = useState('');

  const updateTestImageData = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setImageData(reader.result);
  };

  return (
    <div className="my-3 d-flex flex-column align-items-center">
      <span>OR</span>
      <div className="rounded bg-light my-2">
        <label htmlFor="consentFile" className="btn btn-outline-primary m-0">
          Upload
        </label>
      </div>
      {fileName && (
        <span
          className="text-muted ms-2 w-100"
          style={{
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {fileName}
        </span>
      )}
      <input
        id="consentFile"
        className="d-none"
        type="file"
        accept="image/*;capture=camera"
        onChange={(e) => {
          setFileName(e.target.files[0].name);
          updateTestImageData(e.target.files[0]);
        }}
      />
    </div>
  );
};

const TestImageCapture = ({
  allowPictureUpload = false,
  edit,
  locked,
  setTestImageData,
  test,
  ...props
}) => {
  const testImageData = test.test_image_base64;
  const [capture, setCapture] = useState(false);
  const displayBypassPhotoFields = test?.test_configuration?.require_lab_photo && !!test?.administration;

  return (
    <React.Fragment>
      {displayBypassPhotoFields ? (
        <PhotoBypassFields
          locked={locked}
          test={test}
          {...props}
        />
      ) : (
        <>
          <Card
            className="overflow-hidden justify-content-center"
            style={{
              minHeight: 160,
              backgroundColor: !edit ? '#F5F5F5' : '#FFF',
              ...(capture || testImageData || test.test_image?.url
                ? { height: 'unset' }
                : { maxHeight: 200 }),
            }}
          >
            {capture ? (
              <Webcam
                setImageData={setTestImageData}
                setShow={setCapture}
                style={{ width: '100%', height: 'auto' }}
              />
            ) : testImageData || test.test_image?.url ? (
              <div>
                <img
                  src={testImageData || test.test_image.url}
                  className="shared-labs-webcam-image"
                  style={edit ? {} : { opacity: 0.5 }}
                />
                {edit && (
                  <div className="my-1 p-2 d-flex justify-content-around">
                    <Button
                      variant="outline-primary"
                      onClick={() => setCapture(true)}
                    >
                      Retake
                    </Button>
                  </div>
                )}
              </div>
            ) : edit ? (
              <React.Fragment>
                <div
                  className="d-flex flex-column align-items-center justify-content-center h-100 click-to-take-photo"
                  style={{ border: 'none', maxWidth: 'unset' }}
                  onClick={() => setCapture(true)}
                >
                  <FontAwesomeIcon icon={'camera'} style={{ fontSize: '3em' }} />
                  <span>Take a photo</span>
                </div>
              </React.Fragment>
            ) : (
              <div
                className="d-flex flex-column align-items-center justify-content-center h-100"
                style={{ color: '#C4C4C4' }}
              >
                <FontAwesomeIcon icon={'camera'} style={{ fontSize: '3em' }} />
                <span>No photo uploaded</span>
              </div>
            )}
          </Card>
          {!capture && edit && allowPictureUpload && (
            <UploadImage setImageData={setTestImageData} />
          )}
        </>
      )}
      <PhotoBypassButtons
        displayBypassPhotoFields={displayBypassPhotoFields}
        locked={locked}
        test={test}
        {...props}
      />
    </React.Fragment>
  );
};

export default TestImageCapture;
