import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #F7F9FC;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  position: relative;
  width: 100%;
`;

const SearchBoxWrapper = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
`; 


const SearchBox = ({items, onClickItem, ItemComponent }) => (
  <SearchBoxWrapper data-test-hook='autocomplete-search-box'>
    { items?.length > 0 &&
      <ItemWrapper>
        {items.map((item, index) => (
          <ItemComponent
            key={index}
            onClick={onClickItem}
            dataTestHook={`autocomplete-item-${index}`}
            {...item}
          />
        ))} 
      </ItemWrapper>
      }
  </SearchBoxWrapper>
)

export default SearchBox;
