import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import styled from 'styled-components';
import { VARIABLES } from './TextAreaWithVariables';

const PreviewContainer = styled.div`
  position: relative;
  background-color: white;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: transparent;
  word-break: break-word;

  &.active {
    box-sizing: border-box;
    border-color: var(--bs-primary);
  }
`;

const CharacterCount = ({
  count,
  limit = 160,
  whithinLimit = count < limit,
}) => (
  <span
    className={`position-absolute ps-2 ${
      whithinLimit ? 'text-muted' : 'text-danger'
    }`}
    style={{ bottom: 8, right: 8, fontSize: 13, cursor: 'default' }}
  >
    {count}/{limit}
  </span>
);

const CharacterLimitDescription = ({
  count,
  limit = 160,
  whithinLimit = count < limit,
}) => (
  <div className="mx-2">
    <span className="form-text">
      {!whithinLimit &&
        'Your message might be sent in multiple SMS messages and links might not work: '}
      A standard SMS message can contain a maximum of {limit} characters.{' '}
      {count} is an estimate based on the content of your message.
    </span>
  </div>
);

export const MessagePreview = ({
  organization_name = '',
  enable_markdown: enableMarkdown = false,
  target_field_id: targetFieldId,
  subject_field_id: subjectFieldId,
  additional_variables = [],
}) => {
  const [value, setValue] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [subject, setSubject] = useState('');
  const availableVariables = useRef(VARIABLES.concat(additional_variables));

  const updateValue = useCallback((event) => {
    let val = event.target.value;
    const keys = val.match(/(%{[a-zA-Z]+?.*?})/g);
    let characterCount = val.length;
    keys?.forEach((key) => {
      const subKeyMatch = /\(.*=(.*)\)/.exec(key);
      const subKey = subKeyMatch && subKeyMatch[1];
      const variable = subKey
        ? availableVariables.current.find((v) =>
            [
              key.replace(subKey, 'id'),
              key.replace(subKey, 'location'),
            ].includes(v.text),
          )
        : availableVariables.current.find((v) => v.text === key);
      if (variable) {
        val = val.replace(
          key,
          `<span class="text-primary pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="${
            variable.description
          }">${variable.previewText || variable.name}</span>`,
        );

        // Update estimated count
        if (['%{name}', '%{full_name}', '%{location_name}'].includes(key)) {
          characterCount += 12;
        } else {
          characterCount += 40;
        }
      }
    });
    setValue(val);
    setCharCount(characterCount);
  }, []);

  const updateSubject = useCallback((event) => {
    setSubject(event.target.value);
  }, []);

  const toggleActiveClass = useCallback((event) => {
    const previewId = `${event.target.id}_preview`;
    document.getElementById(previewId).classList.toggle('active');
  }, []);

  useEffect(() => {
    const elem = document.getElementById(targetFieldId);
    elem.addEventListener('content_change', updateValue);
    elem.addEventListener('focus', toggleActiveClass);
    elem.addEventListener('blur', toggleActiveClass);
    return () => {
      elem.removeEventListener('content_change', updateValue);
      elem.removeEventListener('focus', toggleActiveClass);
      elem.removeEventListener('blur', toggleActiveClass);
    };
  }, []);

  useEffect(() => {
    if (subjectFieldId) {
      const elem = document.getElementById(subjectFieldId);
      elem.addEventListener('change', updateSubject);
      elem.addEventListener('focus', toggleActiveClass);
      elem.addEventListener('blur', toggleActiveClass);
      return () => {
        elem.removeEventListener('change', updateSubject);
        elem.removeEventListener('focus', toggleActiveClass);
        elem.removeEventListener('blur', toggleActiveClass);
      };
    }
  }, []);

  return (
    <div
      className="my-2"
      style={{
        height: '100%',
      }}
    >
      <PreviewContainer
        className="ps-3 py-1 mb-2"
        style={{ cursor: 'default' }}
      >
        <span className="font-weight-bold">From:</span> {organization_name}
      </PreviewContainer>
      {subjectFieldId && (
        <label
          className="d-block"
          style={{ cursor: 'text' }}
          htmlFor={subjectFieldId}
        >
          <PreviewContainer
            id={`${subjectFieldId}_preview`}
            className="ps-3 py-1 my-2"
          >
            <span className="font-weight-bold">Subject:</span> {subject}
          </PreviewContainer>
        </label>
      )}
      <label
        className="d-block"
        style={{ cursor: 'text' }}
        htmlFor={targetFieldId}
      >
        <PreviewContainer
          id={`${targetFieldId}_preview`}
          className="ps-4 py-2 my-2"
          style={{
            minHeight: enableMarkdown ? 150 : 100,
            maxHeight: enableMarkdown ? '45vh' : '20vh',
            overflowY: 'auto',
          }}
        >
          {enableMarkdown ? (
            <ReactMarkdown source={value} escapeHtml={false} />
          ) : (
            <React.Fragment>
              <ReactMarkdown
                source={value}
                escapeHtml={false}
                allowedTypes={['text', 'paragraph', 'html']}
                unwrapDisallowed
              />
              <CharacterCount count={charCount} />
            </React.Fragment>
          )}
        </PreviewContainer>
      </label>
      {!enableMarkdown && <CharacterLimitDescription count={charCount} />}
    </div>
  );
};
