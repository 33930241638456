import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Select from '../../common/components/Select';

export const FilterBar = ({
  form_url,
  locations,
  selected_location_id,
  dates,
}) => {
  const [locationId, setLocationId] = useState(parseInt(selected_location_id));
  const [dayValue, setDayValue] = useState(dates ? dates.split(',') : '');
  const onDateChange = (arr) => {
    setDayValue(arr.map((dateObj) => dateObj.format('YYYY-MM-DD')));
  };

  return (
    <form
      noValidate="novalidate"
      className="simple_form search"
      action={form_url}
      acceptCharset="UTF-8"
      method="get"
    >
      <div className="row">
        <div className="col-12 col-md mb-3">
          <div className="select required search_appointment_slot_group_id">
            <Select
              name="appointment_slot_group_id"
              value={locationId}
              options={locations}
              valueKey="0"
              labelKey="1"
              includeBlank
              blankLabel="All locations"
              onChange={setLocationId}
            />
          </div>
        </div>
        <div className="col-12 col-md mb-3">
          <div className="string required search_dates">
            <input type="hidden" name="dates" id="dates" value={dayValue} />
            <DatePicker
              multiple
              format="YYYY-MM-DD"
              inputClass="form-control"
              placeholder="Start date"
              value={dayValue}
              onChange={onDateChange}
              fixMainPosition
              plugins={[<DatePanel />]}
              style={{ width: '350px' }}
            />
          </div>
        </div>
        <div className="col-auto mb-3 text-md-end">
          <input
            type="submit"
            name="commit"
            value="Search"
            className="btn btn-primary"
            data-disable-with="Search"
          />
        </div>
      </div>
    </form>
  );
};
