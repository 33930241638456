import React, { useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import styled from 'styled-components';

import { weekDays } from '../../common/components/MultiDatePickerField';
import { filterImmunizationRecords } from '../helpers/utils';

const CalendarInputContainer = styled.div`
  position: relative;
  max-width: 200px;
  margin-left: 18px;

  &:after {
    position: absolute;
    content: url('/images/icons/icon-calendar.svg');
    right: 10px;
    bottom: 12px;
  }
`;

const DoseDetails = ({
  immunizationKey,
  numDose,
  numDoses,
  disabled,
  doseDate,
  setDoseAdministrationDate,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="immunization__dose">
      <input
        id={`${immunizationKey}-dose-${numDose}`}
        name={`${immunizationKey}-dose-${numDose}`}
        type="checkbox"
        className="form-check-input flex-shrink-0"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={disabled}
      />
      <label
        className="immunization__name flex-shrink-0"
        htmlFor={`${immunizationKey}-dose-${numDose}`}
      >
        Dose {numDose} of {numDoses}
      </label>
      {checked && (
        <CalendarInputContainer>
          <DatePicker
            className="rmdp-primary"
            weekDays={weekDays}
            value={doseDate}
            onChange={setDoseAdministrationDate}
            maxDate={new DateObject()}
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            inputClass="form-control date-picker"
            containerStyle={{ flex: '1 0 3' }}
          />
        </CalendarInputContainer>
      )}
    </div>
  );
};

const ImmunizationDetails = ({
  user,
  immunization,
  present,
  toggleSelection,
  doses,
  setDoseAdministrationDate,
}) => {
  const missingDoses = immunization.num_doses - immunization.doses.length;

  return (
    <div className="immunization">
      <div
        className="immunization__header"
        onClick={() => toggleSelection(immunization.key)}
      >
        <input
          id={immunization.key}
          name={immunization.key}
          type="checkbox"
          className="form-check-input"
          checked={present}
          onChange={(e) => {}}
        />
        <span className="immunization__name">{immunization.name}</span>
      </div>
      {present && (
        <div
          className="immunization__details"
          onClick={(e) => e.stopPropagation()}
        >
          <span>
            Please select & enter the date received of the dose(s) has{' '}
            {user.name} completed.
          </span>
          <div>
            {[...Array(missingDoses)].map((_, idx) => (
              <DoseDetails
                key={idx}
                immunizationKey={immunization.key}
                numDose={immunization.doses.length + idx + 1}
                numDoses={immunization.num_doses}
                disabled={idx > 0 && !doses[idx - 1]}
                doseDate={doses[idx]?.dateObject}
                setDoseAdministrationDate={(date) =>
                  setDoseAdministrationDate(immunization.key, idx, date)
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const UploadType = ({
  user,
  files,
  dispatch,
  immunizationRecords,
  navigateTo,
}) => {
  const overdueImmunizations = filterImmunizationRecords(immunizationRecords, [
    'overdue',
  ]);

  const [immunizations, setImmunizations] = useState({});

  const toggleImmunization = (immunizationKey) => {
    const newImmunizations = { ...immunizations };
    if (!newImmunizations[immunizationKey]) {
      newImmunizations[immunizationKey] = { doses: [] };
    } else {
      delete newImmunizations[immunizationKey];
    }

    setImmunizations(newImmunizations);
  };

  const setDoseAdministrationDate = (immunizationKey, idx, dateObject) => {
    const newImmunizations = { ...immunizations };
    newImmunizations[immunizationKey].doses[idx] = {
      date: dateObject.format('YYYY-MM-DD'),
      dateObject,
    };

    setImmunizations(newImmunizations);
  };

  return (
    <div className="immunization-details container">
      <div className="mb-3">
        <a
          className="link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigateTo('back');
          }}
        >
          <i className="fa fa-chevron-left me-1"></i> Back
        </a>
      </div>
      <h1 className="title mb-1">Which immunizations did you upload today?</h1>
      <p className="description">
        Some immunizations may group different vaccinations together. If that is
        the case, please select all the immunization groups that apply.
      </p>
      <div className="immunization-details__list">
        {overdueImmunizations.map((immunization, idx) => (
          <ImmunizationDetails
            key={idx}
            user={user}
            immunization={immunization}
            toggleSelection={toggleImmunization}
            present={!!immunizations[immunization.key]}
            doses={immunizations[immunization.key]?.doses}
            setDoseAdministrationDate={setDoseAdministrationDate}
          />
        ))}
      </div>
      <div className="navigation-buttons-container">
        <button
          className="submit-button btn btn-primary"
          onClick={() => {
            dispatch({
              type: 'update',
              values: { immunization_records: immunizations },
            });
            navigateTo('next');
          }}
        >
          Add & continue
        </button>
        <button
          className="submit-button btn btn-outline-primary"
          onClick={() => navigateTo('next')}
        >
          Skip & continue
        </button>
      </div>
    </div>
  );
};

export default UploadType;
