import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import InsuranceContext from '../../../Insurance/contexts/InsuranceContext';
import InsuranceForm, { validateInsuranceForm } from './InsuranceForm';

const IAM_POLICYHOLDER = 'i_am';

const showInsuranceField = (testGroup, field) =>
  testGroup.insurance_fields_requested.includes(field) ||
  testGroup.insurance_fields_required.includes(field);
const requireInsuranceField = (testGroup, field) =>
  testGroup.insurance_fields_required.includes(field);

const Insurance = (props) => {
  const testGroup = props.test_group;

  const [formAction, setFormAction] = useState({
    requestInsurance: showInsuranceField(testGroup, 'insurance'),
    requireInsurance: requireInsuranceField(testGroup, 'insurance'),
    requestDriversLicense: showInsuranceField(testGroup, 'drivers_license'),
    requireDriversLicense: requireInsuranceField(testGroup, 'drivers_license'),
    requestSocialSecurityNumber: showInsuranceField(testGroup, 'ssn'),
    requireSocialSecurityNumber: requireInsuranceField(testGroup, 'ssn'),
    showInsuranceBack: false,
    showInsuranceInformation:
      requireInsuranceField(testGroup, 'insurance') ||
      props.user.insurance_policy_holder,
    showNonInsuranceInformation:
      !requireInsuranceField(testGroup, 'insurance') &&
      !props.user.insurance_policy_holder &&
      (props.user.insurance_status == 1 || props.user.insurance_status == 'do_not_have_health_insurance'),
    policyHolder: props.user.insurance_policy_holder,
    secondaryPolicyHolder: props.user.insurance_secondary_policy_holder,
    showSecondary: !!props.user.insurance_secondary_policy_holder,
    useSSN:
      !showInsuranceField(testGroup, 'drivers_license') &&
      showInsuranceField(testGroup, 'ssn'),
    participantHasID: true,
    memberBirthMonth: null,
    memberBirthYear: null,
    memberBirthDay: null,
    memberSecondaryBirthMonth: null,
    memberSecondaryBirthYear: null,
    memberSecondaryBirthDay: null,
    driverLicenseState: null,
  });

  useEffect(() => {
    [
      ['insurance_status', formAction.showInsuranceInformation ? 0 : 1],
      [
        'insurance_primary_member_date_of_birth(1i)',
        formAction.memberBirthYear,
      ],
      ['insurance_primary_member_date_of_birth(3i)', formAction.memberBirthDay],
      [
        'insurance_primary_member_date_of_birth(2i)',
        formAction.memberBirthMonth,
      ],
      [
        'insurance_secondary_member_date_of_birth(1i)',
        formAction.memberSecondaryBirthYear,
      ],
      [
        'insurance_secondary_member_date_of_birth(3i)',
        formAction.memberSecondaryBirthDay,
      ],
      [
        'insurance_secondary_member_date_of_birth(2i)',
        formAction.memberSecondaryBirthMonth,
      ]
    ].map((field) => {
      $(`input[name="user[${field[0]}]"][type=hidden]`).remove();
      $('<input />')
        .attr('type', 'hidden')
        .attr('name', `user[${field[0]}]`)
        .attr('value', field[1])
        .appendTo('#checkInModal, #checkInForm');
    });
  }, [formAction.memberBirthMonth,
      formAction.memberBirthYear,
      formAction.memberBirthDay,
      formAction.memberSecondaryBirthMonth,
      formAction.memberSecondaryBirthYear,
      formAction.memberSecondaryBirthDay,
      formAction.showInsuranceInformation,
    ]
  );

  const insuranceView = {
    view: (props) => <InsuranceForm {...props} />,
    validate: validateInsuranceForm,
  };

  return (
    <React.Fragment>
      <main>
        <div className="mt-4 bg-white">
          <InsuranceContext.Provider
            value={{
              testGroup,
              formAction,
              setFormAction,
              IAM_POLICYHOLDER,
            }}
          >
            <Formik
              initialValues={props}
            >
              {(formikProps) => (
                <div>
                  <div
                    className="form-inputs"
                    aria-describedby="insuranceStatusDesc"
                  >
                    {insuranceView.view(formikProps)}
                  </div>
                </div>
              )}
            </Formik>
          </InsuranceContext.Provider>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Insurance;
