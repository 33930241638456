import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const ToggleableChip = styled.button`
  min-width: 52px;
  font-size: 14px;
  font-weight: normal;
  border-width: 1px;
  background-color: transparent;

  ${({ active }) =>
    active &&
    css`
      background-color: #e9f1fb;
      color: #00496f;
      border-color: #2862fa;
    `}
`;

const YesNoCheckbox = ({
  name = undefined,
  yesValue = true,
  noValue = false,
  value: propValue = undefined,
  defaultValue = noValue,
  yesLabel = 'Yes',
  noLabel = 'No',
  onChange = undefined,
}) => {
  const [value, setValue] = useState(
    propValue !== undefined ? propValue : defaultValue,
  );
  const inputRef = useRef(null);

  const handleChange = (value) => {
    propValue === undefined && setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    propValue !== undefined && setValue(propValue);
  }, [propValue]);

  return (
    <div className="mt-2">
      {name && (
        <input name={name} type="hidden" value={value} ref={inputRef}></input>
      )}
      <ToggleableChip
        type="button"
        className="variable-chip btn btn-outline-tertiary btn-sm me-2"
        onClick={() => handleChange(yesValue)}
        active={value === yesValue}
      >
        {yesLabel}
      </ToggleableChip>
      <ToggleableChip
        type="button"
        className="variable-chip btn btn-outline-tertiary btn-sm"
        onClick={() => handleChange(noValue)}
        active={value === noValue}
      >
        {noLabel}
      </ToggleableChip>
    </div>
  );
};

export default YesNoCheckbox;
