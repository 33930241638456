import React from 'react';
import { Section, SectionItem } from '../../components/styled';
import { Col, Row, Card, Button } from 'react-bootstrap';
import ArrowUpRight from '../../../common/components/Icons/ArrowUpRight';

export default function ReviewInstructions({
  instructions,
  reviewInstruction,
  t,
}) {
  return (
    <Section style={{ marginBottom: '24px' }}>
      <SectionItem style={{ marginBottom: '48px' }}>
        <h3 className="poppins semibold ls-small">
          {t('test_strip.instructions.title')}
        </h3>
      </SectionItem>
      {instructions.map((test, index) => (
        <SectionItem
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px',
          }}
        >
          <Card
            className="p-4"
            style={{
              border: '1px solid #DEE2E6',
              borderRadius: '16px',
              boxShadow: 'none',
              height: '96px',
              width: '640px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Row>
              <Col>
                <h4 className="h4-20-regular mb-0">
                  {`${t('test_strip.instructions.subtitle')} ${
                    parseInt(index) + parseInt(1)
                  }: `}
                  <span
                    className="inter semibold"
                    style={{ fontWeight: '700' }}
                  >
                    {t(test.title)}
                  </span>
                </h4>
              </Col>
              <Col
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'right',
                }}
                xs="auto"
              >
                <Button
                  variant="link"
                  style={{ fontSize: '18px' }}
                  className="kit-flow header-btn text-start inter semibold"
                  onClick={() => reviewInstruction(index)}
                >
                  {t('test_strip.instructions.button')}
                  <ArrowUpRight className="ms-2" />
                </Button>
              </Col>
            </Row>
          </Card>
        </SectionItem>
      ))}
    </Section>
  );
}
