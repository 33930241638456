import React from 'react';

export function dotRead(obj, str) {
  str = str.split(".");
  for (var i = 0; i < str.length; i++)
      obj = obj[str[i]];
  return obj;
}

const SelectableRow = ({
  item,
  itemComponents = {},
  itemKeys,
  selectedItemIds,
  toggleSelection,
}) => {

  return (
    <tr key={item.id}>
      <td>
        <input
          type="checkbox"
          className="form-check-input"
          checked={selectedItemIds.find((id) => id == item.id) > -1}
          onChange={(e) => toggleSelection(item.id, e.target.checked)}
        />
      </td>
      {itemKeys.map((key) => {
        let itemData;
        if (key.includes('.')) {
          itemData = dotRead(item, key);
        } else {
          itemData = item[key];
        }
        const itemComponent = itemComponents[key];
        if (!!itemComponent) {
          return <td>{React.cloneElement(itemComponent, { props: item })}</td>
        } else {
          return <td>{itemData}</td>
        }
      })}
    </tr>
  );
};

export default SelectableRow;
