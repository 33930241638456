import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import Select, { IndicatorSeparator } from 'react-select';
import FabrxCheckbox from '../../../primary/FabrxCheckbox'
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import ErrorMessage from '../../../../common/components/ErrorMessage';

import RegistrationContext from '../../../contexts/RegistrationContext';

const PhoneInput = ({handleChange, setFieldValue, values, name, errors, required, disabled=false, withLandine=true}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState } = useContext(RegistrationContext);

  var countryCodeOptions = []
  for (const [key, value] of Object.entries(localFormState.countryCodes)) {
    countryCodeOptions.push({value: key, label:value})
  }
  return (
    <div>
      {
        countryCodeOptions.length > 0 ? (
          <div className="input-group flex-md-nowrap">
            <div className="select optional user_phone_number_country_code mb-3" style={{ width: "115px"}}>
              <Select
                name={name + "phone_number_country_code"}
                value={values.has_default_contact ? null : countryCodeOptions.filter(option => option.value === values.phone_number_country_code)}
                onChange={(s) => setFieldValue('phone_number_country_code', s.value)}
                options={countryCodeOptions}
                components={{ IndicatorSeparator: () => null }}
                placeholder="+1 US"
                disabled={disabled}
                styles={{ placeholder: (provided) => ({ ...provided, color: "#757575" }) }}
              />
            </div>
            <div className="tel optional user_phone_number" style={{ flexGrow: "2", width: "auto" }}>
              <FloatingLabelInput
                name={name + "phone_number"}
                className="tel optional"
                value={values.has_default_contact ? "" : values.phone_number || ""}
                onChange={handleChange}
                ariaRequired="true"
                ariaLabel="mobile phone number"
                ariaInvalid={!!errors.phone_number}
                id="phone_number"
                type="tel"
                label={t('registration.phone_number') + (required ? ' *' : '')}
                validation={errors.phone_number && 'is-invalid'}
                disabled={disabled}
              />
            </div>
          </div>
        ) : (
          <FloatingLabelInput
            name={name + "phone_number"}
            className="tel optional"
            value={values.has_default_contact ? "" : values.phone_number || ""}
            onChange={handleChange}
            ariaRequired="true"
            ariaLabel="mobile phone number"
            ariaInvalid={!!errors.phone_number}
            id="phone_number"
            type="tel"
            label={t('registration.phone_number') + (required ? ' *' : '')}
            validation={errors.phone_number && 'is-invalid'}
            disabled={disabled}
          />
        )
      }
      {errors.phone_number && <ErrorMessage message={t(errors.phone_number, { field: t('registration.phone_number') })} />}

      {
        withLandine && (
          <div className="mt-2">
            <FabrxCheckbox
              name="phone_number_unreachable"
              ariaLabel="landline phone number"
              onChange={(checked) => {
                setFieldValue('phone_number_unreachable', !values.phone_number_unreachable)
              }}
              checked={values.phone_number_unreachable || false}
              label={ t('registration.phone_number_unreachable_label') }
            />
          </div>
        )
      }
    </div>
  );
};

export default PhoneInput;
