import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Test = ({ test, baseTestGroupURL }) => {
  return (
    <Row className="my-4">
      <Col>
        <div>
          <b>
            <span
              className="me-2"
              style={{ color: test.test_configuration.color_code }}
            >
              <FontAwesomeIcon
                icon={['fa-regular', test.test_configuration.font_awesome_icon]}
              />
            </span>
            {test.test_configuration.checkout_name}
          </b>
        </div>
        <div>Barcode: {test.uid}</div>
        <div>
          Resulted: {test.results_at ? 'Test resulted' : 'Not resulted'}
        </div>
        <div>
          Administered:{' '}
          {new Date(test.localized_administered_at).toLocaleString()}
        </div>
      </Col>
      <Col className="text-end" sm="auto" xs={12}>
        <Button
          className="btn btn-outline-secondary my-3"
          onClick={() => {
            window.location.href = `${baseTestGroupURL}/case_reports/${test.id}/edit`;
          }}
        >
          View test
        </Button>
      </Col>
    </Row>
  );
};

export default Test;
