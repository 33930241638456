import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

import SurveyPreview from "../../../../common/components/SurveyPreviewV2";

const handleSaveSurvey = async (test, answers, submitTests, updateTest) => {
  if (!test.test_survey_save_allowed) return;
  updateTest({ ...test, test_survey_save_allowed: false });

  const response = await submitTests([{
    ...test,
    test_survey: {
      survey: answers,
      test_configuration_id: test.test_configuration_id,
    }
  }]);

  if (response.data.success) {
    console.log("Answers saved");
  } else {
    toatr.error("Something went wrong while saving the survey answers");
  }
}

const ServiceSurveySection = ({ submitTests, test, updateTest }) => {
  if (!Object.keys(test?.test_configuration?.survey || {}).length) return <span></span>;

  const surveyRef = useRef(null);
  const [answers, setAnswers] = useState(test.test_survey?.survey || {});

  useEffect(() => {
    const handleOutsideClickEvent = (event) => {
      if (surveyRef.current && !surveyRef.current.contains(event.target)) {
        handleSaveSurvey(test, answers, submitTests, updateTest);
      }
    };
    const handleSaveEvent = () => {
      handleSaveSurvey(test, answers, submitTests, updateTest);
    };

    // saves survey when clicking outside survey, pressing a button or scrolling
    document.addEventListener("mousedown", handleOutsideClickEvent);
    document.addEventListener("keydown", handleSaveEvent);
    window.addEventListener("scroll", handleSaveEvent);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickEvent);
      document.removeEventListener("keydown", handleSaveEvent);
      window.removeEventListener("scroll", handleSaveEvent);
    };
  }, [test, answers]);

  return (
    <div>
      <Form.Group ref={surveyRef}>
        <Form.Label>Survey</Form.Label>
        <SurveyPreview
          data={answers}
          json={test.test_configuration.survey}
          onValueChanged={(e) => {
            setAnswers(e.data);
            updateTest({ ...test, test_survey_save_allowed: true });
          }}
        />
      </Form.Group>
    </div>
  );
};

export default ServiceSurveySection;