import { DateObject } from 'react-multi-date-picker';

export const formatTime = (timeObject) =>
  `${timeObject.hour.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${timeObject.min.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;

export const HOURS = Array(24)
  .fill()
  .map((_, idx) => idx);

export const MINUTES = [
  [0, '00'],
  [15, '15'],
  [30, '30'],
  [45, '45'],
];

export const SELECT_TIME_OPTIONS = HOURS.map((hour) =>
  MINUTES.map((minuteOption) => ({
    value: { hour: hour, minute: minuteOption[0] },
    label: `${hour > 12 ? hour - 12 : hour}:${minuteOption[1]} ${
      hour < 12 ? 'AM' : 'PM'
    }`,
  })),
).flat();

export const isFuture = (time1, time2) =>
  time1?.hour === time2?.hour
    ? time1?.minute > time2?.minute
    : time1?.hour > time2?.hour;

export const createDateRanges = (dates) => {
  return dates.reduce((ranges, date) => {
    if (ranges.length === 0) {
      ranges.push([date]);
    } else {
      const lastRange = ranges[ranges.length - 1];
      const lastDate = lastRange[lastRange.length - 1];
      const lastDateObject = new DateObject().parse(lastDate);
      const dateObject = new DateObject().parse(date);

      if (lastDateObject.add(1, 'day').valueOf() === dateObject.valueOf()) {
        lastRange.push(date);
      } else {
        ranges.push([date]);
      }
    }
    return ranges;
  }, []);
};

export const formatDateRanges = (dateRanges) => {
  return dateRanges
    .map((dateRange) => {
      if (dateRange.length === 1)
        return new DateObject().parse(dateRange[0]).format('MM/DD');

      const firstDate = dateRange[0];
      const lastDate = dateRange[dateRange.length - 1];
      const firstDateObject = new DateObject().parse(firstDate);
      const lastDateObject = new DateObject().parse(lastDate);

      return firstDateObject.month.number === lastDateObject.month.number
        ? `${firstDateObject.format('MM')}/${firstDateObject.format(
            'DD',
          )} - ${lastDateObject.format('DD')}`
        : `${firstDateObject.format('MM/DD')} - ${lastDateObject.format(
            'MM/DD',
          )}`;
    })
    .join(', ');
};

const compareTimes = (timeObject1, timeObject2) => {
  const {
    starts_at: { hour: hour1, min: min1 },
  } = timeObject1;
  const {
    starts_at: { hour: hour2, min: min2 },
  } = timeObject2;

  if (hour1 === hour2) {
    return min1 - min2;
  } else {
    return hour1 - hour2;
  }
};

export const mergeAndSortSlots = (slotsGroup1, slotGroup2) => {
  if (!slotGroup2) return slotsGroup1;

  let mergedSlots = slotsGroup1.concat(slotGroup2);
  return mergedSlots.sort(compareTimes);
};
