import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import SelectableRow from './SelectableRow';

const SelectableTable = ({
  filteredPaginatedItems,
  itemKeys,
  nonSelectableItemIds = [],
  selectedItemIds,
  setSelectedItemIds,
  tableHeaders,
  RowComponent = SelectableRow,
  ...props
}) => {
  const [visibleSelected, setVisibleSelected] = useState(false);
  const everyItemNonSelectable = filteredPaginatedItems.every(i => nonSelectableItemIds.includes(i.id));

  useEffect(() => {
    setVisibleSelected(
      !!filteredPaginatedItems.length &&
      !everyItemNonSelectable &&
      filteredPaginatedItems
        .map((tg) => selectedItemIds.includes(tg.id) || nonSelectableItemIds.includes(tg.id))
        .reduce((a, b) => a && b, true),
    );
  }, [filteredPaginatedItems, selectedItemIds]);

  const toggleSelection = (id, checked) => {
    const newSelectedItemIds = checked
      ? [...selectedItemIds, id]
      : selectedItemIds.filter((itemId) => itemId !== id);
    setSelectedItemIds(newSelectedItemIds);
  };

  const toggleVisible = (checked) => {
    if (checked) {
      const additionalSelectionIds = filteredPaginatedItems
        .filter((item) => !selectedItemIds.includes(item.id) && !nonSelectableItemIds.includes(item.id))
        .map((item) => item.id);
      setSelectedItemIds([...selectedItemIds, ...additionalSelectionIds]);
    } else {
      const newSelectedIds = selectedItemIds.filter(
        (itemId) =>
          !filteredPaginatedItems.map((item) => item.id).includes(itemId),
      );
      setSelectedItemIds(newSelectedIds);
    }
    setVisibleSelected(checked);
  };

  return (
    <section className="fabrx-tables-light">
      <Table responsive className="table-lg">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="form-check-input"
                id="select-all-checkbox"
                checked={visibleSelected}
                disabled={everyItemNonSelectable}
                onChange={(e) => toggleVisible(e.target.checked)}
                data-selected-ids={selectedItemIds.join(',')}
              />
            </th>
            {tableHeaders.map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredPaginatedItems.map((item, idx) => (
            <RowComponent
              key={idx}
              item={item}
              selectedItemIds={selectedItemIds}
              itemKeys={itemKeys}
              toggleSelection={toggleSelection}
              {...props}
            />
          ))}
        </tbody>
      </Table>
    </section>
  );
};

export default SelectableTable;
