import React from 'react';

const Shield = ({ color = '#000000', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 30"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4682 0.418208C12.1663 0.305015 11.8337 0.305015 11.5318 0.418208L0.865169 4.41821C0.344764 4.61336 0 5.11085 0 5.66665V15C0 19.5952 3.04233 23.267 5.78866 25.6701C7.18984 26.8961 8.58472 27.8544 9.62667 28.5056C10.149 28.8321 10.5862 29.0836 10.8959 29.255C11.0508 29.3408 11.1741 29.4067 11.2604 29.452C11.3036 29.4747 11.3376 29.4922 11.3617 29.5045L11.3903 29.5191L11.399 29.5235L11.4018 29.5249C11.4023 29.5251 11.4037 29.5259 12 28.3333L11.4037 29.5259C11.7791 29.7136 12.2209 29.7136 12.5963 29.5259L12 28.3333C12.5963 29.5259 12.5959 29.5261 12.5963 29.5259L12.5982 29.5249L12.601 29.5235L12.6097 29.5191L12.6383 29.5045C12.6624 29.4922 12.6964 29.4747 12.7396 29.452C12.8259 29.4067 12.9492 29.3408 13.1041 29.255C13.4138 29.0836 13.851 28.8321 14.3733 28.5056C15.4153 27.8544 16.8102 26.8961 18.2113 25.6701C20.9577 23.267 24 19.5952 24 15V5.66665C24 5.11085 23.6552 4.61336 23.1348 4.41821L12.4682 0.418208ZM12.96 26.2443C12.5823 26.4804 12.2555 26.6722 12 26.817C11.7445 26.6722 11.4177 26.4804 11.04 26.2443C10.0819 25.6455 8.81016 24.7705 7.54467 23.6632C4.95767 21.3996 2.66667 18.4047 2.66667 15V6.59065L12 3.09065L21.3333 6.59065V15C21.3333 18.4047 19.0423 21.3996 16.4553 23.6632C15.1898 24.7705 13.9181 25.6455 12.96 26.2443Z"
      fill={color}
    />
  </svg>
);

export default Shield;
