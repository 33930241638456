import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Container,
  Modal,
} from 'react-bootstrap';
import ModalHeader from '../common/components/ModalHeader';
import Select from 'react-select';
import axios from 'axios';
import '../common/locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCheck, faXmark, faEnvelope, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { dataURItoBlob } from '../common/utils';
import Webcam from "../common/components/Webcam";
import { Camera, Digital, Manage, Upload } from '../common/components/Icons';
import { pushReminderToSign, additionalConsents } from '../Participants/components/participants/CheckInModal';

const guardianOptions = [
  { value: 'consented_by_parent_guardian', label: 'Parent / Guardian' },
  {
    value: 'consented_by_decision_maker',
    label: 'Authorized Medical Decision-maker',
  },
  {
    value: 'consented_by_guardian_verbal',
    label: 'Registrar with Guardian (verbal consent obtained)',
  },
  {
    value: 'consented_by_registrar',
    label: 'Registrar (verbal consent obtained)',
  },
  {
    value: 'consented_by_other',
    label: 'Other'
  }
];

export const buildGuardianConsent = (user, appointment, testGroup) => {
  const [guardianForm, setGuardianForm] = useState(appointment);
  const [guardianInfoEditDisabled, setGuardianInfoEditDisabled] = useState(true);
  const [savingGuardianInfo, setSavingGuardianInfo] = useState(false);
  const [showGuardianModal, setShowGuardianModal] = useState(false);
  const onHide = () => setShowGuardianModal(false);
  const updateGuardianInfo = () => {
    setSavingGuardianInfo(true);

    const user_consent_form_id = appointment.user_consent_form_id;
    const path = `/test_groups/${testGroup.id}/participants/${user.id}/consent_forms_users`;

    if (user_consent_form_id) {
      // .json to prevent redirection
      axios.put(`${path}/${user_consent_form_id}.json`, guardianForm, {}).then(() => {
        setSavingGuardianInfo(false);
      });
    } else {
      axios.post(path, guardianForm).then(() => {
        setSavingGuardianInfo(false);
      });
    }
  };

  return (user.errors.guardian_consented || appointment.guardian_name) ? (
      <div className="mt-3">
        <Button
          variant="primary"
          onClick={() => setShowGuardianModal(!showGuardianModal)}
          size="sm"
        >
          Guardian consent info
        </Button>
        <Modal
          show={showGuardianModal}
          onHide={onHide}
        >
          <ModalHeader closeButton onHide={onHide}>
            <Modal.Title as="h2">Group consent</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <div className="mb-3">
              <input
                className="form-control"
                type="text"
                placeholder="First name"
                name="consent_forms_user[guardian_first_name]"
                value={guardianForm.guardian_first_name || guardianForm.guardian_name}
                onChange={(e) => setGuardianForm(
                  {...guardianForm,
                    guardian_first_name: e.target.value,
                  })}
                disabled={guardianInfoEditDisabled}
              />
            </div>

            <div className="my-3">
              <input
                className="form-control"
                type="text"
                placeholder="Last name"
                name="consent_forms_user[guardian_last_name]"
                value={guardianForm.guardian_last_name}
                onChange={(e) => setGuardianForm(
                  {...guardianForm,
                    guardian_last_name: e.target.value,
                  })}
                disabled={guardianInfoEditDisabled}
              />
            </div>

            <div className="my-3">
              <input
                className="form-control"
                type="text"
                placeholder="Email"
                name="consent_forms_user[guardian_email]"
                value={guardianForm.guardian_email}
                onChange={(e) => setGuardianForm({...guardianForm, guardian_email: e.target.value})}
                disabled={guardianInfoEditDisabled}
              />
            </div>

            <div className="my-3">
              <input
                className="form-control"
                type="text"
                placeholder="Phone number"
                name="consent_forms_user[guardian_phone_number]"
                value={guardianForm.guardian_phone_number}
                onChange={(e) => setGuardianForm({...guardianForm, guardian_phone_number: e.target.value})}
                disabled={guardianInfoEditDisabled}
              />
            </div>

            <div className="mt-3">
              <Select
                aria-label="Select consented relationship"
                value={guardianOptions.find(x => x.value == guardianForm.guardian_relationship_type)}
                options={guardianOptions}
                name="consent_forms_user[guardian_relationship_type]"
                onChange={(e) => {
                  setGuardianForm({...guardianForm, guardian_relationship_type: e.value})
                }}
                isDisabled={guardianInfoEditDisabled}
                components={{ IndicatorSeparator: () => null }}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            {savingGuardianInfo ? (
              <span>Saving...</span>
            ) : (
              <span>
                <Button
                  onClick={() => setShowGuardianModal(false)}
                  variant="outline-primary"
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (!guardianInfoEditDisabled) updateGuardianInfo();
                    setGuardianInfoEditDisabled(!guardianInfoEditDisabled);
                  }}
                  className="mx-2"
                >
                {guardianInfoEditDisabled ? 'Edit' : 'Save'}
                </Button>
              </span>
            )}
          </Modal.Footer>
        </Modal>
      </div>
  ) : <div></div>
}

const AdditionalConsentState = ({consentFormUser}) => {
  const consent = consentFormUser || { consent: null, consented: false }
  const declinedConsent = consent.consent == "no";

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      { consent.consented
        ? "Consented"
        : declinedConsent
          ? "Declined consent"
          : "Consent for this service has not been provided by the participant"
      }
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {consent.consented ? (
        <span style={{color: "var(--bs-green)"}}>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      ) : (
        <span>
          <FontAwesomeIcon
            icon={declinedConsent ? faCircleXmark : faCircleExclamation}
            className="text-danger"
          />
        </span>
      )}
    </OverlayTrigger>
  )
}


export const ConsentContainer = ({
  consented,
  consentOption,
  showWebcam,
  webcamProps,
  fileText,
  fileProps,
  consentImage,
  badge,
  title="Group",
  isMobileView,
}) => {
  return (
    <Row className='my-3'>
      <Col>
        <div className={`p-4 border rounded ${!consented && "border-danger"}`}>
          <div className='d-flex'>
            <div>
              <b>{title} consent</b>
              {isMobileView && <span className="mx-1">{badge}</span>}
            </div>
            <div className='ms-auto my-auto'>
              {consentOption}
            </div>
          </div>
          <Row style={{alignItems: "center"}}>
            {showWebcam &&
              <Col xs={"auto"} className="ms-3 mb-2">
                <div className="position-relative">
                  <div
                    className="position-absolute popover fade shadow show bs-popover-end overflow-hidden"
                    style={{ top: -205, left: 174 }}
                  >
                    <Webcam
                      {...webcamProps}
                      style={{width: 350, height: 300, backgroundColor: "black"}}
                    />
                  </div>
                </div>
              </Col>
            }
            <Col xs={"auto"} className={fileText ? "mb-2" : ""}>
              {fileText && <span className="text-muted">{fileText}</span>}
              <input
                {...fileProps}
                className="d-none"
                type="file"
                accept="image/*;capture=camera"
              />
            </Col>
          </Row>
          {consentImage && <div><Image src={consentImage} className="my-3 rounded" height="150" /></div>}
        </div>
      </Col>
      {!isMobileView && (
        <Col xs="1" className="my-auto">
          {badge}
        </Col>
      )}
    </Row>
  )
}

const ConsentFormsUser = ({
  appointment: { consent_capture_method, user, ...appointment},
  test_group: testGroup,
  test_configurations: testConfigurations,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const consented = appointment['consents_signed?'];
  const [consentImage, setConsentImage] = useState({});
  const [fileText, setFileText] = useState({});
  const [showWebcam, setShowWebcam] = useState({testGroup: false});
  const fileRef = useRef({});

  const updateWebcamImage = (img, key) => {
    setConsentImage({...consentImage, [key]: img})
    let container = new DataTransfer();
    container.items.add(dataURItoBlob(img, true))
    document.getElementById("consentFile" + key).files = container.files;
    setShowWebcam(false);
  }

  const consentDropdown = (confirmationBypass, _setShowWebcam, fileRefKey) => (
    <Dropdown>
      <Dropdown.Toggle variant="link" className="chevron" id="dropdown-consent">
        Sign
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href={`${user.confirmation_link}?confirmation_bypass=${confirmationBypass}`} target="_blank">
          <Digital className='me-2' style={{ width: 14 }} /> Capture consent digitally
        </Dropdown.Item>
        <Dropdown.Item href="javascript:;" onClick={() => pushReminderToSign(appointment)}>
          <FontAwesomeIcon className='me-2' icon={faEnvelope}/> Send consent to participant
        </Dropdown.Item>
        <Dropdown.Item
          href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users`}
          target="_blank"
        >
          <Manage className='me-2' style={{ width: 14 }} /> Manage consents
        </Dropdown.Item>
        <Dropdown.Item onClick={() => _setShowWebcam(true)} >
          <Camera className='me-2' color='inherit' style={{ width: 14 }} />Take photo
        </Dropdown.Item>
        <Dropdown.Item onClick={() => fileRef.current[fileRefKey].click()}>
            <Upload className="me-1" color='inherit' style={{ width: 14 }} /> Upload waiver
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  return (
    <Container>
      <h3>Consents for {user.first_name} {user.last_name} ({user.date_of_birth})</h3>
      <form
        encType="multipart/form-data"
        acceptCharset="UTF-8"
        id="consentForm"
        method="post"
        action={`/test_groups/${testGroup.id}/participants/${user.id}/consent_forms_users/${appointment.id}/bulk_create`}
      >
        <input
          type="hidden"
          name="redirect_to"
          value={urlParams.get('redirect_to')}
        />
        <ConsentContainer
          consented={consented}
          consentOption={consented
            ? <a
                href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users?consent_type=test_group`}
                target="_blank"
              >
                View
              </a>
            : consentDropdown("show_consent", (e) => setShowWebcam({...showWebcam, testGroup: e}), "testGroup")
          }
          showWebcam={showWebcam.testGroup}
          webcamProps={{
            setImageData: (data) => updateWebcamImage(data, "testGroup"),
            setShow: (e) => setShowWebcam({...showWebcam, testGroup: e}),
          }}
          fileText={fileText.testGroup}
          fileProps={{
            ref: el => fileRef.current.testGroup = el,
            id: "consentFiletestGroup",
            name: "appointment[consent_waiver_image]",
            onChange: (e) => {
              setFileText({...fileText, testGroup: e.target.files[0].name});
              setConsentImage({...consentImage, testGroup: null});
            },
          }}
          consentImage={consentImage.testGroup}
          badge={consented
            ? <span style={{color: "var(--bs-green)"}}><FontAwesomeIcon icon={faCheck} /></span>
            : <span><FontAwesomeIcon icon={faCircleExclamation} className="text-danger" /></span>
          }
        />
        {buildGuardianConsent(user, appointment, testGroup)}
        {additionalConsents(testConfigurations).map(additionalConsent => {
          const consentFormUser = user.consent_forms_users.find(consentFormUser => consentFormUser.consent_form_id == additionalConsent.id);
          return (<>
            <ConsentContainer
              consented={consentFormUser?.consented}
              consentOption={consentFormUser
                ? <a
                    href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users?consent_type=${additionalConsent.testConfiguration.test_group_test_configuration.id}`}
                    target="_blank"
                  >
                    View
                  </a>
                : consentDropdown("show_test_configurations", (e) => setShowWebcam({...showWebcam, [additionalConsent.id]: e}), additionalConsent.id)
              }
              showWebcam={showWebcam[additionalConsent.id]}
              webcamProps={{
                setImageData: data => updateWebcamImage(data, additionalConsent.id),
                setShow: (e) => setShowWebcam({...showWebcam, [additionalConsent.id]: e}),
              }}
              fileText={fileText[additionalConsent.id]}
              fileProps={{
                ref: el => fileRef.current[additionalConsent.id] = el,
                id: "consentFile" + additionalConsent.id,
                name: `consent_forms[${additionalConsent.id}][consent_waiver_image]`,
                onChange: (e) => {
                  setFileText({...fileText, [additionalConsent.id]: e.target.files[0].name});
                  setConsentImage({...consentImage, [additionalConsent.id]: null });
                },
              }}
              consentImage={consentImage[additionalConsent.id]}
              badge={<AdditionalConsentState consentFormUser={consentFormUser} />}
              title={additionalConsent.testConfiguration.checkout_name || additionalConsent.testConfiguration.display_name}
            />
            <input
              className="d-none"
              name={`consent_forms[${additionalConsent.id}][test_group_test_configuration_id]`}
              value={additionalConsent.testConfiguration.test_group_test_configuration.id}
            />
          </>
        )})}
        <Button variant="link" disabled={!urlParams.get('redirect_to')} onClick={() => window.location.href = urlParams.get('redirect_to')}>Back</Button>
        <Button className='float-end' type="submit">Submit</Button>
      </form>
    </Container>
  );
};

export default ConsentFormsUser;
