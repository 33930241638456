import React from "react";

export const PencilIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 4.46395L11.5611 0L9.47962 2.0815L0 11.5361V16H4.46395L14.2696 6.21944L16 4.46395ZM14.5956 4.46395L13.5423 5.51724L10.5078 2.45768L11.5361 1.40439L14.5956 4.46395ZM1.00313 12.9655L3.08464 14.9969H1.00313V12.9655ZM4.26332 14.7712L1.17868 11.7618L9.78057 3.18495L12.8401 6.21944L4.26332 14.7712Z" fill="#2862FA"/>
  </svg>
)

export const HomeIcon = ({active}) =>  (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className={active ? "animate__animated animate__pulse" : ""}>
    <path d="M29.5 15.9419V15.942V27.5368C29.5 28.0535 29.2923 28.5515 28.9186 28.9206C28.5444 29.2902 28.0344 29.5 27.5 29.5H3.5C2.96561 29.5 2.45555 29.2902 2.0814 28.9206C1.70767 28.5515 1.5 28.0535 1.5 27.5368V15.942V15.9419C1.49993 15.2731 1.63807 14.6111 1.90633 13.9961C2.1746 13.3812 2.56754 12.8259 3.06175 12.3647L3.06187 12.3646L14.1214 2.04096L14.1215 2.04084C14.4921 1.69474 14.9852 1.5 15.5 1.5C16.0148 1.5 16.5079 1.69474 16.8785 2.04084L16.8786 2.04096L27.9381 12.3646L27.9382 12.3647C28.4325 12.8259 28.8254 13.3812 29.0937 13.9961C29.3619 14.6111 29.5001 15.2731 29.5 15.9419Z" stroke={active ? "#2862FA" : "black"} strokeWidth="2"/>
  </svg>
)

export const MedicalIcon = ({active}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className={active ? "animate__animated animate__pulse" : ""}>
    <g clipPath="url(#clip0_563_1317)">
      <path d="M20.7583 4.61182H24.3356C25.0946 4.61182 25.8226 4.91333 26.3593 5.45003C26.896 5.98673 27.1975 6.71465 27.1975 7.47366V26.0756C27.1975 26.8346 26.896 27.5626 26.3593 28.0993C25.8226 28.636 25.0946 28.9375 24.3356 28.9375H7.16458C6.40557 28.9375 5.67765 28.636 5.14095 28.0993C4.60425 27.5626 4.30273 26.8346 4.30273 26.0756V7.47366C4.30273 6.71465 4.60425 5.98673 5.14095 5.45003C5.67765 4.91333 6.40557 4.61182 7.16458 4.61182H10.7419" stroke={active ? "#2862FA" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.915 3.91785C11.0697 3.29872 11.427 2.74908 11.93 2.35629C12.4329 1.9635 13.0528 1.7501 13.691 1.75H17.8077C18.4459 1.7501 19.0657 1.9635 19.5687 2.35629C20.0717 2.74908 20.4289 3.29872 20.5837 3.91785L21.4737 7.47368H10.0264L10.915 3.91785Z" stroke={active ? "#2862FA" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.457 21.783H20.0426" stroke={active ? "#2862FA" : "black"} strokeWidth="2" strokeLinecap="round"/>
      <path d="M11.457 15.5H20.0426" stroke={active ? "#2862FA" : "black"} strokeWidth="2" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_563_1317">
        <rect width="30" height="30" fill="white" transform="translate(0.75 0.5)"/>
      </clipPath>
    </defs>
  </svg>
)

export const HelpIcon = ({active}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none"  className={active ? "animate__animated animate__pulse" : ""}>
  <g clipPath="url(#clip0_563_1529)">
    <circle cx="15.25" cy="15.5" r="14" stroke={active ? "#2862FA" : "black"} strokeWidth="2"/>
    <path d="M13.2965 18.3189C13.2965 17.8044 13.3585 17.3561 13.4826 16.9739C13.6221 16.577 13.8391 16.2022 14.1337 15.8494C14.4438 15.4966 14.8547 15.1365 15.3663 14.769C15.8934 14.3868 16.3043 14.0561 16.5988 13.7768C16.9089 13.4975 17.1337 13.2256 17.2733 12.961C17.4128 12.6817 17.4826 12.3657 17.4826 12.0129C17.4826 11.4543 17.2733 11.028 16.8547 10.7341C16.4516 10.4401 15.8702 10.2931 15.1105 10.2931C14.4283 10.2931 13.7771 10.3886 13.157 10.5797C12.5368 10.7561 11.9167 10.9986 11.2965 11.3073L10.25 9.23474C10.9632 8.88195 11.7384 8.58797 12.5756 8.35278C13.4128 8.11759 14.3353 8 15.343 8C16.8934 8 18.095 8.36013 18.9477 9.08039C19.8159 9.80066 20.25 10.7341 20.25 11.8806C20.25 12.5127 20.1415 13.0565 19.9244 13.5122C19.7229 13.9532 19.4205 14.3648 19.0174 14.7469C18.6143 15.1291 18.1182 15.5334 17.5291 15.9596C17.0484 16.3124 16.6764 16.6138 16.4128 16.8636C16.1647 17.1135 15.9942 17.3634 15.9012 17.6133C15.8236 17.8485 15.7849 18.1425 15.7849 18.4953V19.0465H13.2965V18.3189ZM12.8547 22.5522C12.8547 21.9202 13.0252 21.4792 13.3663 21.2293C13.7229 20.9794 14.1492 20.8545 14.6453 20.8545C15.126 20.8545 15.5368 20.9794 15.8779 21.2293C16.2345 21.4792 16.4128 21.9202 16.4128 22.5522C16.4128 23.1549 16.2345 23.5885 15.8779 23.8531C15.5368 24.1177 15.126 24.25 14.6453 24.25C14.1492 24.25 13.7229 24.1177 13.3663 23.8531C13.0252 23.5885 12.8547 23.1549 12.8547 22.5522Z" fill={active ? "#2862FA" : "black"} style={{fill: active ? "#2862FA" : "black"}}/>
  </g>
  <defs>
    <clipPath id="clip0_563_1529">
      <rect width="30" height="30" fill="white" transform="translate(0.25 0.5)"/>
    </clipPath>
  </defs>
  </svg>
)

export const TestIcon = ({size=62, active}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 62 61" fill="none" className={active ? "animate__animated animate__pulse" : ""}>
    <g clipPath="url(#clip0_550_1523)" style={{fill: "rgb(255 255 255)"}}>
      <circle cx="31" cy="30.5" r="30.5" fill="#2862FA" style={{fill: "#2862FA"}}/>
      <path style={{fill: "rgb(255 255 255)"}} d="M31 43.0581C30.0639 43.0581 29.305 42.2992 29.305 41.3631V19.6354C29.305 18.6993 30.0639 17.9404 31 17.9404C31.9361 17.9404 32.695 18.6993 32.695 19.6354V41.3631C32.695 42.2992 31.9361 43.0581 31 43.0581ZM20.1276 32.1857C19.1962 32.1857 18.4412 31.4306 18.4412 30.4993C18.4412 29.5679 19.1962 28.8128 20.1276 28.8128H41.8724C42.8038 28.8128 43.5588 29.5679 43.5588 30.4993C43.5588 31.4306 42.8038 32.1857 41.8724 32.1857H20.1276Z" fill="#F8F8FC"/>
    </g>
    <defs>
      <clipPath id="clip0_550_1523">
        <rect width="61" height="61" fill="white" transform="translate(0.5)" style={{fill: "rgb(255 255 255)"}}/>
      </clipPath>
    </defs>
  </svg>
)

export const MemberIcon = ({active}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className={active ? "animate__animated animate__pulse" : ""}>
    <g clipPath="url(#clip0_563_1536)">
      <circle cx="15.5" cy="15.5" r="14" stroke="#36363F" strokeWidth="2"/>
      <path d="M15.4616 12.1346C15.4616 14.5562 13.4986 16.5192 11.077 16.5192C8.65544 16.5192 6.69238 14.5562 6.69238 12.1346C6.69238 9.71306 8.65544 7.75 11.077 7.75C13.4986 7.75 15.4616 9.71306 15.4616 12.1346Z" stroke="#36363F" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4675 30.2058C18.9088 29.2078 19.1539 28.1037 19.1539 26.9424C19.1539 22.4816 15.5377 18.8655 11.077 18.8655C7.50869 18.8655 4.48085 21.1794 3.41211 24.3886C3.9027 25.0544 4.44701 25.6783 5.03864 26.2537C5.38064 23.2218 7.95364 20.8655 11.077 20.8655C14.4332 20.8655 17.1539 23.5862 17.1539 26.9424C17.1539 28.2671 16.73 29.4929 16.0105 30.4914C16.8487 30.4631 17.6696 30.3661 18.4675 30.2058Z" fill="#36363F"/>
      <circle cx="22.375" cy="13.625" r="3.375" stroke="#36363F" strokeWidth="2"/>
      <path d="M27.2157 24.8682C26.6582 22.7151 24.7023 21.125 22.375 21.125C20.676 21.125 19.1749 21.9724 18.2714 23.2676C17.9698 22.6784 17.598 22.1311 17.167 21.6368C18.4277 20.1752 20.2933 19.25 22.375 19.25C25.0441 19.25 27.358 20.7711 28.4971 22.9936C28.1168 23.6517 27.6881 24.2782 27.2157 24.8682Z" fill="#36363F"/>
    </g>
    <defs>
      <clipPath id="clip0_563_1536">
        <rect width="30" height="30" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
)
