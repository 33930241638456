import React from 'react';
import SuccessStep from './SuccessStep';

const data = {
  header: 'test_strip.shipping.header',
  image: '/images/sti-programs/shipping.svg',
  text: 'test_strip.shipping.text',
};

export default function ShippingStep({ t }) {
  return <SuccessStep data={data} t={t} />;
}
