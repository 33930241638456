import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modal } from "react-bootstrap";
import ModalHeader from "../common/components/ModalHeader";

import ButtonSelect from "../common/components/ButtonSelect";
import StatusBadge from "../common/components/StatusBadge";

const OverviewInfo = ({ labelClassName, label, value }) => {
  return (
    <div>
      <span className={`lead-20-medium inter bold caption ${labelClassName}`}>{label}: </span>
      <span>{value}</span>
    </div>
  );
};

const LocationsList = ({ locations }) => {
  const [showMore, setShowMore] = useState(false);

  let first_five = locations.slice(0, 5);
  let rest = locations.slice(5);
  return (
    <div>
      {first_five.map(l => {
        return (
          <OverviewInfo
            label={`• ${l["title"]}`}
            labelClassName="body-large-16-regular"
            value={l["value"]}
          />
        )
      })}
      <>
        <div id="show-more-locations" className="collapse" aria-labelledby="show-more-locations">
          {rest.map(l => {
            return (
              <OverviewInfo
                label={`• ${l["title"]}`}
                labelClassName="body-large-16-regular"
                value={l["value"]}
              />
            )
          })}
        </div>
        <a
          id="show-more-locations-button"
          className="button default ok mt-3 font-weight-bold"
          href="#show-more-locations"
          data-bs-toggle="collapse"
          aria-expanded="false"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show less -" : "Show more +"}
        </a>
      </>
    </div>
  )
};

const StatusChangeConfirmationModal = ({
  onSubmit,
  setStatusChangeConfirmationModalType,
  statusChangeConfirmationModalType,
}) => {
  if (!statusChangeConfirmationModalType) return <span></span>;
  return (
    <Modal
      show={!!statusChangeConfirmationModalType}
      onHide={() => setStatusChangeConfirmationModalType(null)}
      size="lg"
    >
      <ModalHeader>
        <Modal.Title as="h2">
          {statusChangeConfirmationModalType.change_to_confirmation_title}
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {statusChangeConfirmationModalType.change_to_confirmation_text.split('<br/>').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="mx-2"
          onClick={() => setStatusChangeConfirmationModalType(null)}
        >
          Cancel
        </Button>
        <Button
          className="mx-2"
          onClick={onSubmit}
        >
          {statusChangeConfirmationModalType.change_to_label}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TestGroupOverview = ({
  appointment_slot_groups,
  client_leads,
  show_status_dropdown,
  name,
  primary_leads,
  selected_status_label,
  status,
  test_group_banner,
  test_group_id,
  test_group_is_private,
  test_group_statuses,
}) => {

  const [statusChangeConfirmationModalType, setStatusChangeConfirmationModalType] = useState(null);

  const updateStatus = ({ value }) => {
    axios.put(`/test_groups/${test_group_id}.json`, {
      test_group: {
        name: name,
        status: value
      }
    }).then((response) => {
      if (response.data.success) {
        toastr.success("Test group status successfully updated");
        window.location.reload();
      }
    }).catch((error) => {
      console.log(error);
    })
  };

  return (
    <div>
      <StatusChangeConfirmationModal
        onSubmit={() => updateStatus({value: statusChangeConfirmationModalType.value})}
        setStatusChangeConfirmationModalType={setStatusChangeConfirmationModalType}
        statusChangeConfirmationModalType={statusChangeConfirmationModalType}
      />
      {!!test_group_banner?.text && (
        <div class="my-3 test_group_status_banner">
          <FontAwesomeIcon
            className="me-2"
            icon={faExclamationCircle}
            style={{ color: "var(--danger-red, #C71A00)"}}
          />
          <span dangerouslySetInnerHTML={{ __html: test_group_banner.text }}></span>
        </div>
      )}
      <div className="my-4 d-flex justify-content-between">
        <div className="d-flex">
          <span className="h3-24-semi-bold poppins me-2 my-auto">{name}</span>
          <StatusBadge status={status} />
          {test_group_is_private && (
            <StatusBadge
              backgroundColor="#FFEFE8"
              color="#FB4E03"
              text="Private"
              className="my-auto ms-2"
            />
          )}
        </div>
        {show_status_dropdown && (
          <ButtonSelect
            onChange={(option) => setStatusChangeConfirmationModalType(option)}
            options={test_group_statuses}
            selectedLabel={selected_status_label}
          />
        )}
      </div>
      <div>
        <div className="my-3">
          <h6 className="lead-20-medium inter bold caption">
            Locations & dates:
          </h6>
          {appointment_slot_groups.length > 5 ? (
            <LocationsList locations={appointment_slot_groups} />
          ) : (
            appointment_slot_groups.map(asg => {
              return (
                <OverviewInfo
                  label={`• ${asg["title"]}`}
                  labelClassName="body-large-16-regular"
                  value={asg["value"]}
                />
              );
            })
          )}
        </div>
        {primary_leads && (
          <OverviewInfo
            label={"Primary.Health leads"}
            value={primary_leads}
          />
        )}
        {client_leads && (
          <OverviewInfo
            label={"Client leads"}
            value={client_leads}
          />
        )}
      </div>
    </div>
  );
};

export default TestGroupOverview;
