import React, { useState } from 'react';
import axios from 'axios';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { validateEmail } from '../common/utils/index';
import ReactMarkdown from 'react-markdown';
import { PrimarySearchBar } from '../PrimaryDesignSystem/SearchBar';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import RegistrationDirectorySearch from '../Search/RegistrationDirectorySearch';

const isPhone = (str) => {
  return isMobilePhone(str);
}

const isEmail = (str) => {
  return validateEmail(str);
}

const EnterInformation = ({ onComplete, onError, token }) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState({});
  const [form, setForm] = useState({token: token});
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchOnLocationFinder = () => {
    setLoading(true)
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const url = "/find-your-location?" + new URLSearchParams(query).toString();
    window.location.href = url;
  }

  return (
    <div>
      {(currentStep == 0) ?
        <div>
          <div className="h3 mb-3" data-test-hook="finder">{t("enter_information.find_link")}</div>
          <div className="registration-directory-subtitle inter">{t("enter_information.subtitle")}</div>
          <div className="lead-20-medium mb-3">{t("enter_information.contact_info")}</div>
          <PrimarySearchBar
            value={input}
            dataTestInputHook="phone_or_email"
            dataTestButtonHook="continue"
            validation={error.phoneOrEmail ? "is-invalid" : ""}
            label={t("enter_information.label")}
            buttonText={t("enter_information.search")}
            error={t("enter_information.invalid")}
            isDisabled={disabled}
            onChange={(e) => {
              setInput(e.target.value?.trim())
            }}
            onClick={async (event) => {
              event.preventDefault();
              setDisabled(true)
              try {
                const appointmentRecovery = {};
                if (isEmail(input)) {
                  appointmentRecovery.email = input
                } else if (isPhone(input)) {
                  appointmentRecovery.phone_number = input
                } else {
                  setError({ phoneOrEmail: true });
                  return;
                }
                const response = await axios.post(`/finder/`, {
                  appointment_recovery: appointmentRecovery
                });
                onComplete(response.data.appointment_recovery);
              } catch (error) {
                onError();
              }
            }} />
          <div className="text-muted body-small-regular mt-3">
            <ReactMarkdown source={t("enter_information.legal")} />
          </div>
          <div className="well well-secondary d-flex align-items-center justify-content-between mt-40">
            <div className='h4 inter semibold m-0'>{t("enter_information.not_registered")}</div>
            <Button variant="secondary" size="md" onClick={() => { setCurrentStep(1) }}>
              {t("enter_information.register_here")}
            </Button>
          </div>
        </div>
        :
        <div>
          <h2>Search for your site</h2>
          <p className="mb-3">Search by keyword, city, zip, name, or organization code, or enter your address below to find the nearest sites to you.</p>
          <RegistrationDirectorySearch form={form} setForm={setForm} submit={searchOnLocationFinder} loading={loading} />
        </div>
      }

    </div>
  )
};

export default EnterInformation;
