import React, { useEffect, useReducer, useState } from "react";

import {
  getTranslationParams,
  languagesFromCustomMessageTemplateParams,
} from "./MessageTemplateForm";
import NewMessageAddRecipients from "./NewMessageAddRecipients";
import NewMessageContext from "../contexts/NewMessageContext";
import NewMessageCustomizeMessage from "./NewMessageCustomizeMessage";
import NewMessageHeader from "./NewMessageHeader";
import NewMessageScheduleDelivery from "./NewMessageScheduleDelivery";
import { PARAM_KEYS } from "./MessageTemplateForm";
import { TemplateFormHeader } from "./MessageTemplateForm";

const DYNAMICALLY_ADJUSTABLE_PARAMS = [
  {
    action: "setLocations",
    options_key: "location_filter_options",
    param_key: "locations",
  },
  {
    action: "setPopulations",
    options_key: "population_filter_options",
    param_key: "populations",
  },
  {
    
    action: "setTags",
    options_key: "tag_filter_options",
    param_key: "tags",
  },
  {
    action: "setTestGroups",
    options_key: "test_group_filter_options",
    param_key: "test_groups",
  },
];

export const languageKey = (type, language) => {
  return !!language ? `${type}_${language}` : type;
};

const NewMessage = ({
  current_user,
  custom_message_template,
  displayingMessageTemplateForm = false,
  filter_options,
  message_options,
}) => {

  const reducer = (state, action) => {
    switch (action.type) {
      case "setCustomTemplateValues":
        // setting new available languages
        const newLanguages = languagesFromCustomMessageTemplateParams(action.values);
        // removing current translation params and replacing with new translation params
        const keysToRemove = Object.keys(getTranslationParams(state.message_settings));
        keysToRemove.forEach(key => delete state["message_settings"][key])
        return {
          ...state,
          selected_language: null,
          message_settings: {
            ...state.message_settings,
            ...action.values,
            languages: newLanguages
          }
        };
      case "setDate":
        return { ...state, message_settings: { ...state.message_settings, date: action.values } };
      case "setEmailMessage":
        state["message_settings"][languageKey("email_message", state.selected_language)] = action.values;
        return { ...state };
      case "setEmailSubject":
        state["message_settings"][languageKey("email_subject", state.selected_language)] = action.values;
        return { ...state };
      case "setEndDate":
        return { ...state, message_settings: { ...state.message_settings, total_runs: null, end_date: action.values } };
      case "setLanguages":
        const newLanguage = action.values.find(l => !state.message_settings.languages.includes(l));
        const newLanguageParams = {};
        if (!!newLanguage) {
          PARAM_KEYS.forEach((paramKey) => {
            newLanguageParams[languageKey(paramKey, newLanguage)] = "";
          })
        }
        return { ...state, message_settings: { ...state.message_settings, languages: action.values, ...newLanguageParams } };
      case "setOrganizationId":
        const senderOrganizationName = filter_options.organization_filter_options
          .find(o => o.value == action.values)?.label;
        return { ...state, sender_organization_name: senderOrganizationName, message_settings: { ...state.message_settings, organization_id: action.values } };
      case "setRepeating":
        return { ...state, message_settings: { ...state.message_settings, repeating: action.values } };
      case "setSmsMessage":
        state["message_settings"][languageKey("sms_message", state.selected_language)] = action.values;
        return { ...state };
      case "setTime":
        return { ...state, message_settings: { ...state.message_settings, time: action.values } };
      case "setTimeZone":
        return { ...state, message_settings: { ...state.message_settings, time_zone: action.values } };
      case "setTotalRuns":
        return { ...state, message_settings: { ...state.message_settings, end_date: null, total_runs: action.values } };
      case "setAdministeredAtDate":
        return { ...state, filter_params: { ...state.filter_params, administered_at_date: action.values } };
      case "setAppointmentDate":
        return { ...state, filter_params: { ...state.filter_params, appointment_date: action.values } };
      case "setAppointmentStatus":
        return { ...state, filter_params: { ...state.filter_params, appointment_status: action.values } };
      case "setCaseReportStatus":
        return { ...state, filter_params: { ...state.filter_params, case_report_status: action.values } };
      case "setConsentedTo":
        return { ...state, filter_params: { ...state.filter_params, consented_to: action.values } };
      case "setLocations":
        return { ...state, filter_params: { ...state.filter_params, locations: action.values } };
      case "setOrganizations":
        const newOrganizationId = action.values.length == 1 ? action.values[0] : null; // when filtering only one org, we set it as the sender org
        return { ...state,
          filter_params: { ...state.filter_params, organizations: action.values },
          message_settings: { ...state.message_settings, organization_id: newOrganizationId }
        };
      case "setPopulations":
        return { ...state, filter_params: { ...state.filter_params, populations: action.values } };
      case "setTags":
        return { ...state, filter_params: { ...state.filter_params, tags: action.values } };
      case "setTestGroups":
        return { ...state, filter_params: { ...state.filter_params, test_groups: action.values } };
      case "setAllFilteredTestGroupUserIds":
        return { ...state, all_filtered_test_group_user_ids: action.values };
      case "setDeliverNow":
        return { ...state, deliver_now: action.values };
      case "setFilterOptions":
        return { ...state, filter_options: { ...action.values,
          organization_filter_options: state.filter_options.organization_filter_options } // organization options should not change
        };
      case "setRecipientCount":
        return { ...state, recipient_count: action.values };
      case "setPaginatedRecipients":
        return { ...state, paginated_recipients: action.values };
      case "setSelectedLanguage":
        return { ...state, selected_language: action.values };
      case "setTemplateName":
        return { ...state, template_name: action.values };
      case "setTemplateOrganization":
        return { ...state, template_organization_id: action.values };
      default:
        throw new Error("Unknown action.");
    }
  };

  const initialLanguages = !!custom_message_template
    ? languagesFromCustomMessageTemplateParams(custom_message_template?.params)
    : [];
  const initialOrganizationId = filter_options.organization_filter_options.length === 1
    ? filter_options.organization_filter_options[0]?.value
    : null;
  const initialSenderOrganizationName = initialOrganizationId
    ? filter_options.organization_filter_options.find(o => o.value == initialOrganizationId)?.label
    : null;
  const initialTestGroupId = filter_options.test_group_filter_options.length === 1
    ? filter_options.test_group_filter_options[0]?.value
    : null;

  const [state, dispatch] = useReducer(reducer, {
    filter_params: {
      appointment_date: null,
      appointment_status: null,
      case_report_status: null,
      consented_to: null,
      administered_at_date: null,
      locations: [],
      organizations: !!initialOrganizationId
        ? [initialOrganizationId]
        : [],
      populations: [],
      tags: [],
      test_groups: !!initialTestGroupId
        ? [initialTestGroupId]
        : [],
    },
    message_settings: {
      date: new Date(),
      email_message: "",
      email_subject: "",
      end_date: null,
      languages: initialLanguages,
      organization_id: initialOrganizationId,
      repeating: null,
      sms_message: "",
      time: null,
      time_zone: null,
      total_runs: null,
      ...custom_message_template?.params,
    },
    all_filtered_test_group_user_ids: [],
    deliver_now: null,
    filter_options: filter_options,
    paginated_recipients: [],
    recipient_count: 0,
    selected_language: null,
    sender_organization_name: initialSenderOrganizationName,
    template_id: custom_message_template?.id || null,
    template_name: custom_message_template?.name || "",
    template_organization_id: custom_message_template?.organization_id?.toString() || null,
  });

  const adjustFilterParams = () => {
    // when dynamically updating filter options we need to remove some values from filter params if they are not found in the new filter options
    // for example user filters based on a test_group then filters based on a random organization, we need to remove the selected test_group
    // from the params if test_group is not part of the the newly selected organization
    DYNAMICALLY_ADJUSTABLE_PARAMS.forEach((param) => {
      const acceptableValues = state.filter_options[param["options_key"]].map(o => o.value);
      const currentParams = state.filter_params[param["param_key"]];
      const newParams = currentParams.filter((k) => acceptableValues.includes(k));
      const paramsChanged = !_.isEqual(currentParams.sort(), newParams.sort());
      if (paramsChanged) {
        dispatch({ type: param["action"], values: newParams });
      }
    })
  };

  useEffect(() => {
    adjustFilterParams()
  }, [state.filter_options])

  const [currentStep, setCurrentStep] = useState(displayingMessageTemplateForm ? 1 : 0);

  const context = {
    currentStep,
    currentUser: current_user,
    dispatch,
    displayingMessageTemplateForm,
    messageOptions: message_options,
    setCurrentStep,
    state,
  };

  const sections = [
    <NewMessageAddRecipients />,
    <NewMessageCustomizeMessage />,
    <NewMessageScheduleDelivery />,
  ];

  return (
    <div>
      <NewMessageContext.Provider value={context}>
        {displayingMessageTemplateForm ? (
          <TemplateFormHeader state={state} />
        ) : (
          <NewMessageHeader
            currentStep={currentStep}
            dispatch={dispatch}
            setCurrentStep={setCurrentStep}
            state={state}
          />
        )}
        {sections[currentStep]}
      </NewMessageContext.Provider>
    </div>
  );
};

export default NewMessage;