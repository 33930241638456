import React from 'react';

const Upload = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 14 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: color,
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M0 15.4999C0 15.2347 0.105357 14.9803 0.292893 14.7928C0.48043 14.6053 0.734784 14.4999 1 14.4999H13C13.2652 14.4999 13.5196 14.6053 13.7071 14.7928C13.8946 14.9803 14 15.2347 14 15.4999C14 15.7651 13.8946 16.0195 13.7071 16.207C13.5196 16.3946 13.2652 16.4999 13 16.4999H1C0.734784 16.4999 0.48043 16.3946 0.292893 16.207C0.105357 16.0195 0 15.7651 0 15.4999ZM3.293 5.20691C3.10553 5.01938 3.00021 4.76507 3.00021 4.49991C3.00021 4.23474 3.10553 3.98044 3.293 3.79291L6.293 0.792908C6.48053 0.605437 6.73484 0.500122 7 0.500122C7.26516 0.500122 7.51947 0.605437 7.707 0.792908L10.707 3.79291C10.8892 3.98151 10.99 4.23411 10.9877 4.49631C10.9854 4.75851 10.8802 5.00932 10.6948 5.19473C10.5094 5.38014 10.2586 5.4853 9.9964 5.48758C9.7342 5.48986 9.4816 5.38907 9.293 5.20691L8 3.91391V11.4999C8 11.7651 7.89464 12.0195 7.70711 12.207C7.51957 12.3946 7.26522 12.4999 7 12.4999C6.73478 12.4999 6.48043 12.3946 6.29289 12.207C6.10536 12.0195 6 11.7651 6 11.4999V3.91391L4.707 5.20691C4.51947 5.39438 4.26516 5.49969 4 5.49969C3.73484 5.49969 3.48053 5.39438 3.293 5.20691Z"
    />
  </svg>
);

export default Upload;
