import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from '../../common/components/ModalHeader';
import { Row, Col } from 'react-bootstrap';

const logAttemptRequest = ({ id: test_id, test_group: { id } }) => {
  const notes = $(`#notes-${test_id}`).val();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      case_report_history: {
        test_id,
        case_report_status: 'attempted_contact',
        case_report_notes: notes !== '' ? notes : null,
      },
    }),
  };

  return fetch(`/test_groups/${id}/case_reports`, requestOptions);
};

const logAttempt = (test, attempted, setAttempted, setLogging, setOpen) => {
  setLogging(true);
  logAttemptRequest(test).then((response) => {
    if (response.status === 200) {
      setAttempted(attempted !== null ? attempted + 1 : 0);
      toastr.success('Attempt logged');

      setOpen(false);
    } else {
      toastr.error('Failed to log attempt');
    }
    setLogging(false);
  });
};

const LogAttemptModal = ({
  test,
  open = false,
  setOpen,
  attempted,
  setAttempted,
}) => {
  const [logging, setLogging] = useState(false);
  const onHide = () => setOpen(false);

  return (
    <Modal show={open} onHide={onHide} size="md">
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2">Log attempt</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <Row>
          <Col>
            <label className="form-label">Notes</label>
          </Col>
        </Row>
        <Row>
          <Col>
            <textarea
              rows="3"
              style={{ width: '100%' }}
              id={`notes-${test.id}`}
              placeholder="Add some optional notes here…"
            ></textarea>
          </Col>
        </Row>
        <hr />
        <div style={{ textAlign: 'right' }}>
          <Button
            disabled={logging}
            onClick={() =>
              logAttempt(test, attempted, setAttempted, setLogging, setOpen)
            }
          >
            Log
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogAttemptModal;
