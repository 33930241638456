import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Button, Card, Collapse } from 'react-bootstrap';
import Scanner from '../../../common/components/Scanner';
import { ChevronDropDown } from '../../../common/components/Chevron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Primary from '../../../../primary/index';
import { format, formatInTimeZone } from 'date-fns-tz';

import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import TestSurveyForm from '../forms/TestSurveyForm';
import VaccineResultField, {
  SelectFormGroup,
  VaccineResultLabelField,
} from './VaccineResultFields';
import { VaccinePdfLabelPrinter } from '../PdfLabelPrinter';

export const ScreeningStep = ({
  test,
  updateTest,
  showError=false,
  completeReasons=[],
  stepNumber,
}) => {
  const [showNotes, setShowNotes] = useState(false);
  const hasNotes = test.administered && test.notes;
  const [show, setShow] = useState(true);

  return (
    <div className="my-4">
      <div className="mb-3" onClick={()=> setShow(!show)}>
        <hr />
        <ChevronDropDown open={show} setOpen={setShow} />
        <h5 className="my-4">
          {stepNumber}. Participant screening responses {' '}
          { !test.test_survey?.admin_approved &&
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="text-danger"
            />
          }
        </h5>
      </div>
      <Collapse in={show}>
        <div>
          <TestSurveyForm
            test={test}
            updateTest={updateTest}
            disabled={test.administered}
            isInvalid={showError && completeReasons.includes("admin_approved")}
          />
          {!test.administered && (
            <Button variant="link" onClick={() => setShowNotes(!showNotes)}>
              {showNotes ? '-' : '+'} Notes
            </Button>
          )}
          {(showNotes || hasNotes) && (
            <VaccineResultLabelField
              label="Notes"
              formControl="textarea"
              value={test.notes}
              onChange={(e) => {
                updateTest({
                  ...test,
                  notes: e.target.value,
                });
              }}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export const VaccineProgramStep = ({
  fundingSourceOptions,
  options,
  requestEligibility,
  requestFundingSource,
  stepNumber,
  subTitle,
  test,
  title,
  updateTest,
  vaccineProgramField,
}) => {
  const [show, setShow] = useState(true);
  const extractKeys = (options) => {
    const keys = Object.keys(options);
    // make "unknown" the last option if present and does not have a code so "None of the above" makes sense
    if (keys.includes("unknown") && !options["unknown"]["code"]) keys.push(keys.splice(keys.indexOf("unknown"), 1)[0]);
    return keys;
  };
  const keys = extractKeys(options);
  return (
    <div className="my-4">
      <div className="mb-3" onClick={()=> setShow(!show)}>
        <hr />
        <ChevronDropDown open={show} setOpen={setShow} />
        <h5 className="my-4">
          {stepNumber}. {title}
        </h5>
      </div>
      <Collapse in={show}>
        <div className="mx-3">
          {requestEligibility && (
            <>
              <div className="mt-2 mb-3">
                {subTitle}
              </div>
              <div className="mx-2">
                {keys
                  .map((option) => {
                    const code = options[option]["code"] ? `(${options[option]["code"]})` : "";
                    const label = option === 'unknown' && !options[option]["code"]
                      ? 'None of the above'
                      : `${options[option]["label"]} ${code}`;
                    return (
                      <FabrxCheckbox
                        name={option}
                        checked={test.vaccine_result[vaccineProgramField] === option}
                        radio
                        onChange={() => {
                          const updatedTest = {...test};
                          updatedTest["vaccine_result"][vaccineProgramField] = option;
                          updateTest(updatedTest);
                        }}
                        label={label}
                      />
                    )
                  })
                }
              </div>
            </>
          )}
          {requestFundingSource && (
            <div className="my-3">
              <h6 className="my-2">Funding source</h6>
              <div className="mx-2">
                {Object.keys(fundingSourceOptions)
                  .map((option) => {
                    return (
                      <FabrxCheckbox
                        name={option}
                        checked={test.vaccine_result?.funding_source === option}
                        radio
                        onChange={() => {
                          updateTest({
                            ...test,
                            vaccine_result: {
                              ...test.vaccine_result,
                              funding_source: option,
                            },
                          });
                        }}
                        label={`${fundingSourceOptions[option]["label"]} (${fundingSourceOptions[option]["code"]})`}
                      />
                    )
                  })
                }
              </div>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export const VaccineLotStep = ({
  test,
  updateTest,
  vaccineRoute,
  setVaccineRoute,
  complete,
  stepNumber,
}) => {
  const [show, setShow] = useState(true);
  const [vaccineResult, testSurvey, testConfiguration] = [
    test.vaccine_result,
    test.test_survey,
    test.test_configuration,
  ];

  const vaccineLotOptions =
    testConfiguration.vaccine_lots &&
    testConfiguration.vaccine_lots.map((vaccineLot) => {
      return { label: vaccineLot.lot_number, value: vaccineLot.lot_number };
    });

  const [scannerOpen, setScannerOpen] = useState(false);

  return (
    <div className="mb-4">
      <div className="mb-3" onClick={()=> setShow(!show)}>
        <hr/>
        <ChevronDropDown open={show} setOpen={setShow} />
        <h5 className="my-4">
          {stepNumber}. Lot number
          <a
            target="_blank"
            href={`/test_groups/${test.test_group.id}/appointment_slot_groups/${test.appointment_slot_group.id}/vaccine_lots?active=true`}
            className="ms-3"
            variant="primary"
          >
            Manage lots
          </a>
        </h5>
      </div>
      <Collapse in={show}>
        <Row>
          <Col xs={scannerOpen ? 12 : "auto"} className="my-2">
            {test.administered !== true && (
              <Scanner
                onSubmit={(barcode, setError, close) => {
                  const parsed = Primary.parseVaccineBarcode(barcode);
                  if (parsed.error) {
                    setError(parsed.error);
                    return;
                  }
                  const lotNumber = parsed.lotNumber;
                  const vaccineLot = testConfiguration.vaccine_lots.find(
                    (vl) => vl.lot_number === lotNumber,
                  );
                  if (vaccineLot) {
                    setVaccineLot(vaccineLot);
                  } else {
                    setError(
                      `Lot number ${lotNumber} not found. Manage lots to add a new lot.`,
                    );
                  }
                  close();
                }}
                setScannerOpen={setScannerOpen}
              />
            )}
          </Col>
          <Col sm={6} xs={12} className="my-2">
            <FloatingLabelSelect
              value={vaccineLotOptions.filter(o => o.value === vaccineResult.lot_number)}
              onChange={(e) => {
                const vaccineLot = testConfiguration.vaccine_lots.find(
                  (vl) => vl.lot_number === e.value,
                );
                updateTest({
                  ...test,
                  vaccine_result: {
                    ...vaccineResult,
                    lot_number: vaccineLot.lot_number,
                    dose: vaccineLot.vaccine_route.default_dose.toString(),
                    expiration_date: vaccineLot.expiration_date,
                    vaccine_lot_id: vaccineLot.id,
                    administration_type:
                      vaccineLot.vaccine_route.default_administration_type,
                  },
                });
                setVaccineRoute(vaccineLot.vaccine_route);
              }}
              options={vaccineLotOptions}
              label="Lot number"
              filledValue={vaccineResult.lot_number}
            />
          </Col>
          {vaccineRoute && vaccineResult.lot_number && (
            <div className="mt-3">
              <Row>
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>Vaccine:</Col>
                <Col><b>{vaccineRoute.trade_name_with_manufacturer}</b></Col>
              </Row>
              <Row>
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>Expiration:</Col>
                <Col><b>{vaccineResult.expiration_date && format(new Date(vaccineResult.expiration_date), 'yyyy-MM-dd')}</b></Col>
              </Row>
            </div>
          )}
        </Row>
      </Collapse>
    </div>
  );
};

export const AdministrationStep = ({ test, updateTest, complete, showError=false, completeReasons=[], stepNumber }) => {
  const [show, setShow] = useState(true);
  const disabledVaccineDoseIds = test.test_configuration["disabled_vaccine_route_and_dose_ids"]["vaccine_dose_ids"].map(v => parseInt(v));
  const disabledVaccineRouteIds = test.test_configuration["disabled_vaccine_route_and_dose_ids"]["vaccine_route_ids"].map(v => parseInt(v));
  const [vaccineResult, vaccineRoute, testSurvey, testConfiguration, vaccineDoses, vaccineDoseId] = [
    test.vaccine_result,
    test.vaccine_result.vaccine_route,
    test.test_survey,
    test.test_configuration,
    test.vaccine_result.vaccine_route.vaccine_doses ? test.vaccine_result.vaccine_route.vaccine_doses.filter(vd => vd.active === true && !disabledVaccineDoseIds.includes(vd.id)) : [],
    test.vaccine_result.vaccine_dose_id
  ];
  const isDefaultDoseDisabled = disabledVaccineRouteIds.includes(vaccineRoute.id);
  const allVaccineDoses = vaccineDoses;
  if (!isDefaultDoseDisabled) allVaccineDoses.unshift({ id: null, dose_name: vaccineRoute.default_vaccine_dose_name, size: vaccineRoute.default_dose, dose_type: "default" });
  const onlyOneDoseAvailable = allVaccineDoses.length === 1;
  const defaultDose = allVaccineDoses.find(vd => vd.id == null);
  const initialDose = onlyOneDoseAvailable
        ? allVaccineDoses[0]
        : vaccineDoseId
          ? vaccineDoses.find(vd => vd.id === vaccineDoseId)
          : test.administered
            ? {dose_name: defaultDose?.dose_name, size: vaccineResult.dose}
            : defaultDose

  const [selectedDose, setSelectedDose] = useState(onlyOneDoseAvailable && {
    dose_name: initialDose?.dose_name,
    size: initialDose?.size,
  });

  if (initialDose && !vaccineResult.dose_name && onlyOneDoseAvailable) {
    updateTest({
      ...test,
      vaccine_result: {
        ...vaccineResult,
        dose_name: initialDose.dose_name || "N/A",
        vaccine_dose_id: initialDose.id,
        dose: initialDose.size || 0.0
      },
    });
  }

  const dosesInOrder = ["default", "dose_1", "dose_2", "dose_3", "dose_4", "dose_5", "booster_dose", "second_booster_dose", "additional_dose"];
  const administeredDoseOptions = allVaccineDoses.sort((a, b) => {
      return dosesInOrder.indexOf(a.dose_type) - dosesInOrder.indexOf(b.dose_type)
    }).map((vd, index) => {
      return { value: vd, label: vd.dose_name }
    });

  return (
    <div className="mb-4">
      <div className="mb-3" onClick={() => setShow(!show)}>
        <hr/>
        <ChevronDropDown open={show} setOpen={setShow} />
        <h5 className="my-4">{stepNumber}. Administration</h5>
      </div>
      <Collapse in={show}>
        <div>
          <Row>
            <Col sm={6} xs={12} className="my-2">
              {allVaccineDoses?.length > 0 && (
                <FloatingLabelSelect
                  name="administered_dose"
                  value={administeredDoseOptions.filter(o => o.label === selectedDose.dose_name)}
                  onChange={(vd) => {
                    setSelectedDose({dose_name: vd.value?.dose_name, size: vd.value?.size});
                    updateTest({
                      ...test,
                      vaccine_result: { ...vaccineResult, dose: vd.value?.size, vaccine_dose_id: vd.value?.id, dose_name: vd.value?.dose_name },
                    })
                  }}
                  options={administeredDoseOptions}
                  label="Administered dose"
                  filledValue={selectedDose.dose_name}
                />
              )}
            </Col>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelSelect
                name="administration_type"
                value={testConfiguration.administration_type_options.filter(o => o.value === vaccineResult.administration_type)}
                onChange={(at) => {
                  updateTest({
                    ...test,
                    vaccine_result: {
                      ...vaccineResult,
                      administration_type: at.value,
                    },
                  });
                }}
                options={testConfiguration.administration_type_options}
                label="Administered type"
                filledValue={vaccineResult.administration_type}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelInput
                name="dose"
                value={selectedDose.size}
                onChange={(e) => {
                  setSelectedDose({dose_name: selectedDose.dose_name, size: e.target.value})
                  updateTest({
                    ...test,
                    vaccine_result: { ...vaccineResult, dose: e.target.value },
                  });
                }}
                label="Dose (ml)"
              />
            </Col>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelSelect
                name="administration_site"
                value={testConfiguration.administration_site_options.filter(o => o.value === vaccineResult.administration_site)}
                onChange={(as) => {
                  updateTest({
                    ...test,
                    vaccine_result: {
                      ...vaccineResult,
                      administration_site: as.value,
                    },
                  });
                }}
                options={testConfiguration.administration_site_options}
                label="Site"
                filledValue={vaccineResult.administration_site}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export const ConfirmationStep = ({
  test,
  updateTest,
  complete,
  completeReasons,
  submitTests,
  onComplete,
  showError,
  setShowError,
  administrationLogged,
  vaccineInfoSheetProps,
  setAdministrationLogged,
  currentAdministratorName,
  setCurrentAdministratorName,
  currentAdministratorDegree,
  setCurrentAdministratorDegree,
  currentAdministratorInitials,
  setCurrentAdministratorInitials,
  stepNumber,
}) => {
  const [show, setShow] = useState(true);
  const [vaccineResult, testSurvey, testConfiguration] = [
    test.vaccine_result,
    test.test_survey,
    test.test_configuration,
  ];

  const [
    vaccineInfoSheetVersionApproval,
    vaccineInfoSheetVersion,
    vaccineInfoSheetVersionFileUrl,
    vaccineInfoSheetFlipper,
  ] = [
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version_approval,
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version,
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version_file_url,
    vaccineInfoSheetProps?.vaccine_info_sheet_flipper,
  ];

  const visVersionDateInUTC = vaccineInfoSheetVersion?.version_date
    && formatInTimeZone(new Date(vaccineInfoSheetVersion.version_date), 'UTC', 'MM/dd/yyyy');

  useEffect(() => {
    const testData = {
      ...test,
      vaccine_result: {
        ...vaccineResult,
      },
    }
    if (currentAdministratorName || !vaccineResult.administrator_name) {
      testData["vaccine_result"]["administrator_name"] = currentAdministratorName;
    }
    if (currentAdministratorDegree || !vaccineResult.administrator_degree) {
      testData["vaccine_result"]["administrator_degree"] = currentAdministratorDegree;
    }
    if (currentAdministratorInitials || !vaccineResult.administrator_initials) {
      testData["vaccine_result"]["administrator_initials"] = currentAdministratorInitials;
    }
    updateTest(testData)
  }, [currentAdministratorName, currentAdministratorDegree, currentAdministratorInitials])

  const redIconStyle = {
    // makes svg icon color red
    filter: "invert(75%) sepia(93%) saturate(3246%) hue-rotate(313deg) brightness(88%) contrast(123%)",
    width: "20px",
    margin: "0px 0px 3px 0px",
  }

  return (
    <div className="mb-4">
      <div className="mb-3" onClick={()=> setShow(!show)}>
        <hr/>
        <ChevronDropDown open={show} setOpen={setShow} />
        <h5 className="my-4">{stepNumber}. Confirm administration</h5>
      </div>
      <Collapse in={show}>
        <div>
          <Row>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelInput
                name="administrator_name"
                value={vaccineResult.administrator_name}
                onChange={(e) => {
                  updateTest({
                    ...test,
                    vaccine_result: {
                      ...vaccineResult,
                      administrator_name: e.target.value,
                    },
                  });
                  setCurrentAdministratorName(e.target.value);
                }}
                label="Administered by"
              />
            </Col>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelSelect
                name="administrator_degree"
                value={testConfiguration.administrator_degree_options.filter(o => [o.label, o.value].includes(vaccineResult.administrator_degree))}
                onChange={(e) => {
                  updateTest({
                    ...test,
                    vaccine_result: {
                      ...vaccineResult,
                      administrator_degree: e.value,
                    },
                  });
                  setCurrentAdministratorDegree(e.value);
                }}
                options={testConfiguration.administrator_degree_options}
                label="Administrator degree"
                filledValue={vaccineResult.administrator_degree}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12} className="my-2">
              <FloatingLabelInput
                name="administrator_initials"
                value={vaccineResult.administrator_initials}
                onChange={(e) => {
                  updateTest({
                    ...test,
                    vaccine_result: {
                      ...vaccineResult,
                      administrator_initials: e.target.value,
                    },
                  });
                  setCurrentAdministratorInitials(e.target.value);
                }}
                label="Signature (initials)"
              />
            </Col>
          </Row>
          <Card body className="mt-4">
            {vaccineInfoSheetFlipper && (
              vaccineInfoSheetVersionApproval ? (
                <div className="my-3">
                  <div className="my-2">Vaccine information provided on: {format(new Date(vaccineInfoSheetVersionApproval?.updated_at), 'MMM dd, yyyy HH:mm:ss zzz')}</div>
                  <div>
                    Edition date: <a href={vaccineInfoSheetVersionFileUrl} target="_blank">{visVersionDateInUTC}</a>
                  </div>
                </div>
              ) : (
                vaccineInfoSheetVersion ? (
                  <div className="text-danger my-3">
                    <img
                      src={`/images/icons/icon-alert-circle.svg`}
                      style={redIconStyle}
                      className="me-2"
                    />
                    Vaccine information statement (version:
                    <a href={vaccineInfoSheetVersionFileUrl} target="_blank"> {visVersionDateInUTC}</a>
                    ) attestation not obtained
                  </div>
                ) : (
                  <div className="text-danger my-3">
                    No relevant VIS available.
                  </div>
                )
              )
            )}
            <FabrxCheckbox
              name="statement_provided"
              onChange={(checked) => {
                updateTest({
                  ...test,
                  vaccine_result: {
                    ...vaccineResult,
                    statement_provided: !vaccineResult.statement_provided,
                    vaccine_info_sheet_version_approval_id: !vaccineResult.statement_provided ? vaccineInfoSheetVersionApproval?.id : null,
                    vaccine_info_sheet_version_id: !vaccineResult.statement_provided ? vaccineInfoSheetVersion?.id : null,
                  },
                });
              }}
              inputClassName={(showError && Object.keys(completeReasons).includes("statement_provided")) ? "is-invalid" : ""}
              checked={vaccineResult.statement_provided}
              label="Confirmed vaccine information statement or fact sheet provided"
            />
            <AdministeredSwitch
              test={test}
              updateTest={updateTest}
              complete={complete}
              submitTests={submitTests}
              setShowError={setShowError}
              administrationLogged={administrationLogged}
              setAdministrationLogged={setAdministrationLogged}
              disabled={showError && Object.values(completeReasons).length > 0}
            />
            {showError && Object.values(completeReasons).length > 0 && (
              <div className="text-danger my-3">
                Missing Information:{' '}
                {Object.values(completeReasons).map((reason) => {
                  return <li key={reason}>{reason}</li>;
                })}
              </div>
            )}
          </Card>
        </div>
      </Collapse>
    </div>
  );
};

export const VACCINE_LABELS = {
  label_A4_vaccine: "8.0x11.0 Vaccination Statement",
  label_2_0x1_25_vaccine: "2.0x1.25 Label",
  label_2_0x0_5_vaccine: "2.0x0.5 Label",
  label_2_0x1_0_vaccine: "2.0x1.0 Label",
  label_0_5x1_0_vaccine: "0.5x1.0 Label",
  label_1_25x2_0_vaccine: "1.25x2.0 Label",
}

export const PrintStep = ({ test, updateTest, submitTests, onComplete, stepNumber }) => {

  return (
    <div className="mb-4">
      <hr />
      <h5 className="my-4">{stepNumber}. Print vaccination cards/labels</h5>
      {!test.administered ? (
        <div className="text-muted">
          Available after administration has been logged.
        </div>
      ) : Object.keys(VACCINE_LABELS).map(key =>
        <VaccinePdfLabelPrinter
          test={test}
          onPrint={(print) => {
            updateTest({...test, administered: true});
            submitTests([test]).then((response) => {
              print();
              onComplete();
            });
          }}
          label={key}
          name={VACCINE_LABELS[key]}
        />
      )}
    </div>
  );
};

export const StepRefused = ({
  test,
  updateTest,
  complete,
  onComplete,
  submitTests,
  setShowError,
}) => {
  const [vaccineResult, testSurvey, testConfiguration] = [
    test.vaccine_result,
    test.test_survey,
    test.test_configuration,
  ];
  const [success, setSuccess] = useState('');

  return (
    <div className="mt-2">
      <SelectFormGroup
        label="Refusal Reason"
        options={testConfiguration.refusal_state_options}
        value={vaccineResult.refusal_state}
        handleChange={(e) => {
          updateTest({
            ...test,
            vaccine_result: { ...vaccineResult, refusal_state: e.value },
          });
        }}
        className="z5"
      />
      {vaccineResult.refusal_state == 'other_reason' && (
        <VaccineResultField
          label="Other"
          value={vaccineResult.refusal_note}
          onChange={(e) => {
            updateTest({
              ...test,
              vaccine_result: {
                ...vaccineResult,
                refusal_note: e.target.value,
              },
            });
          }}
        />
      )}
      <Button
        className="no-focus"
        variant={"primary"}
        onClick={() => {
          submitTests([test]).then((response) => {
            setSuccess('Successfully Logged');
            onComplete();
          });
        }}
      >
        Log Refusal
      </Button>
      {success && (
        <Alert className="mt-2" variant="success">
          {success}
        </Alert>
      )}
    </div>
  );
};

export const ActionButtons = ({
  test,
  updateTest,
  complete,
  submitTests,
  setShowError,
  disabled=false,
}) => {
  const [vaccineResult, testSurvey, testConfiguration] = [
    test.vaccine_result,
    test.test_survey,
    test.test_configuration,
  ];

  const logAdministration = () => {
    setShowError(!complete);
    if (complete) {
      updateTest({
        ...test,
        administered: test.administered === true ? null : true,
        vaccine_result: {
          ...vaccineResult,
          refusal_state: 'no_refusal',
          refusal_note: null,
        },
      });
      submitTests([
        {
          ...test,
          administered: test.administered === true ? null : true,
          vaccine_result: {
            ...vaccineResult,
            refusal_state: 'no_refusal',
            refusal_note: null,
          },
        },
      ]);
    }
  };

  return (
    <React.Fragment>
      <Button
        className="me-3 mt-3 no-focus"
        variant={
          test.administered ? 'success' : 'outline-secondary'
        }
        disabled={disabled}
        onClick={logAdministration}
      >
        {test.administered ? 'Logged administration' : 'Log administration'}
      </Button>
      <Button
        className="mt-3 no-focus"
        variant={test.administered === false ? 'danger' : 'outline-danger'}
        onClick={() =>
          updateTest({
            ...test,
            administered: test.administered === false ? null : false,
          })
        }
      >
        Refused vaccine
      </Button>
    </React.Fragment>
  );
};

export const AdministeredSwitch = ({
  test,
  updateTest,
  complete,
  submitTests,
  setShowError,
  administrationLogged,
  setAdministrationLogged,
}) => {
  const [vaccineResult] = [
    test.vaccine_result,
    test.test_survey,
    test.test_configuration,
  ];

  const logAdministration = () => {
    setShowError(!complete);
    if (complete) {
      updateTest({
        ...test,
        administered: test.administered === true ? null : true,
        vaccine_result: {
          ...vaccineResult,
          refusal_state: 'no_refusal',
          refusal_note: null,
        },
      });
      submitTests([
        {
          ...test,
          administered: test.administered === true ? null : true,
          vaccine_result: {
            ...vaccineResult,
            refusal_state: 'no_refusal',
            refusal_note: null,
          },
        },
      ]);
    } else {
      setAdministrationLogged(false)
    }
  };

  const AdministerOptionCard = ({ checked, dataTestHook, label, onClick }) => {
    const style = { borderRadius: "8px" };
    checked ? style["backgroundColor"] = "var(--secondary-100, #E7EDFD)" : style["border"] = "1px solid var(--secondary-100, #E7EDFD)";

    return (
      <div
        data-test-hook={dataTestHook}
        style={style}
        className="p-3 pointer my-2"
        onClick={onClick}
      >
        {checked
          ? <FontAwesomeIcon icon={'check-circle'} size="lg" color="var(--primary, #2862FA)" />
          : <FontAwesomeIcon icon={faCircle} size="lg" color="var(--secondary-100, #E7EDFD)" />
        }
        <span className="ms-2">{label}</span>
      </div>
    )
  }

  return (
    <Row className="mt-3">
      <h6>Log administration for {test.user.full_name} ({test.user.date_of_birth}):</h6>
      <Col lg={6} md={12}>
        <AdministerOptionCard
          dataTestHook="administration_logged"
          label={"Yes, administration logged"}
          checked={administrationLogged}
          onClick={() => {
            if (administrationLogged) return;
            setAdministrationLogged(true);
            logAdministration();
          }}
        />
      </Col>
      <Col lg={6} md={12}>
        <AdministerOptionCard
          label={"No, administration not logged / refused"}
          checked={administrationLogged === false}
          onClick={() => {
            if (administrationLogged === false) return;
            setAdministrationLogged(false);
            updateTest({
              ...test,
              administered: false,
            })
          }}
        />
      </Col>
    </Row>
  );
};
