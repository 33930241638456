import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import { PersonalFieldDropdown } from '../../SectionComponents';

const SexualOrientation = ({
  attributeString="",
  context = RegistrationContext,
  error,
  handleChange,
  setFieldValue,
  values,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState } = useContext(context);

  const sexualOrientationOptions = testGroup.sexual_orientations.map((sexualOrientationKey) => {
    return {
      value: sexualOrientationKey,
      label: t(`sexual_orientation.${sexualOrientationKey}`),
    }
  });
  const label = buildLocalizedString(testGroup, 'sexual_orientation_label');
  const helpText = buildLocalizedString(testGroup, 'sexual_orientation_help_text')
  const required = testGroup.required_attributes.sexual_orientation;

  return (
    <PersonalFieldDropdown>
      <div className="mb-2">
        <div className="text-muted">
          { buildLocalizedString(testGroup, 'sexual_orientation_subtitle') && (
            <div className="d-inline-block"><LocalizedMarkdown container={testGroup} stringKey='sexual_orientation_subtitle' /></div>
            )
          }
          {' '}
          { helpText && <HelpText text={ <LocalizedMarkdown container={testGroup} stringKey='sexual_orientation_help_text' /> } /> }
        </div>
        
        <FloatingLabelSelect
          ariaLabel="sexual_orientation select"
          value={sexualOrientationOptions.filter(option => (
            option.value === (localFormState.describeSexualOrientation ? "orientation_not_listed" : values.sexual_orientation)
          ))}
          onChange={(s) => {
            setLocalFormState({...localFormState, describeSexualOrientation: s.value === "orientation_not_listed"})
            setFieldValue(`${attributeString}sexual_orientation`, s.value);
          }} 
          name="sexual_orientation"
          options={sexualOrientationOptions}
          containerClassName="mt-3"
          filledValue={localFormState.describeSexualOrientation ? "orientation_not_listed" : values.sexual_orientation}
          label={
            label
              ? `${label} ${required ? '*' : '(Optional)'}`
              : `${t('sexual_orientation.title')} ${required ? '*' : '(Optional)'}`
          }
          className={error && 'is-invalid'}
        />
      </div>
      {localFormState.describeSexualOrientation &&
        <div className="mt-2 mb-2">
          <FloatingLabelInput
            value={values.sexual_orientation === "orientation_not_listed" ? "" : values.sexual_orientation}
            onChange={handleChange}
            className="mt-4"
            name={attributeString + "sexual_orientation"}
            label={ t("sexual_orientation.orientation_not_listed") }
          />
        </div>
      }
    </PersonalFieldDropdown> 
  );
};

export default SexualOrientation;
