import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalHeader from '../../common/components/ModalHeader';
import Select from '../../common/components/Select';
import LocationContext from '../context/LocationContext';
import { LocationContextProvider } from './LocationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'; 

const ModalTitle = ({ selectedLocation, previouslySelectedLocation }) => (
  <Modal.Title as="h2">
    {previouslySelectedLocation
      ? 'Confirm Your Location'
      : selectedLocation
      ? 'Edit Your Location'
      : 'Select Your Location'}
  </Modal.Title>
);

const NoteContent = ({ confirmation = false }) =>
  confirmation ? (
    <p className="mb-0">
      <span className="font-weight-bold">*Please note:</span> It has been 1 hour
      since you have selected your location. Please confirm you are still at
      this location to continue. Alternatively, please select another location.
    </p>
  ) : (
    <p className="mb-0">
      <span className="font-weight-bold">*Please note:</span> Your location will
      only be saved for 1 hour. Your location will not be saved after logging
      out. All services that you administer will be marked as administered at
      the location you have selected.
    </p>
  );

const LocationSelectModal = ({
  show,
  locations,
  selectedLocation: propSelectedLocation,
  previouslySelectedLocation: propPreviouslySelectedLocation,
  handleSave,
  onHide,
}) => {
  const [previouslySelectedLocation, _] = useState(
    propPreviouslySelectedLocation,
  );
  const [selectedLocation, setSelectedLocation] = useState(
    propSelectedLocation || previouslySelectedLocation,
  );

  const isEditingLocation = !!propSelectedLocation;
  // Modal dismissable if editing location
  const modalProps = isEditingLocation
    ? {
        onHide: onHide,
      }
    : { backdrop: 'static', keyboard: false };

  return (
    <Modal show={show} {...modalProps}>
      <ModalHeader
        closeButton={isEditingLocation}
        onHide={isEditingLocation && onHide}
      >
        <ModalTitle
          selectedLocation={propSelectedLocation}
          previouslySelectedLocation={previouslySelectedLocation}
        />
      </ModalHeader>
      <Modal.Body>
        {previouslySelectedLocation && <NoteContent confirmation />}
        <span>Available Locations:</span>
        <Select
          options={locations}
          valueKey="id"
          labelKey="title"
          value={selectedLocation}
          onChange={(location) => setSelectedLocation(location)}
          className="mt-2 mb-3"
        />
        {!previouslySelectedLocation && <NoteContent />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!selectedLocation}
          onClick={() => handleSave(selectedLocation)}
        >
          Save and Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const LocationToast = ({ location, onClick }) => (
  <div className="card card-toast">
    <div
      className="card-body"
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <FontAwesomeIcon icon={faLocationDot} className='text-primary' />
      <span
        className="font-weight-bold ms-1 me-2"
        style={{ fontSize: 14, lineHeight: 1 }}
      >
        {location ? `Location: ${location.title}` : 'No location selected'}
      </span>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={onClick}
        style={{ minHeight: 'unset', minWidth: 'unset', height: 24, width: 51 }}
      >
        Edit
      </Button>
    </div>
  </div>
);

const LocationSelector = ({ locations }) => {
  const { selectedLocation, previouslySelectedLocation, setSelectedLocation } =
    useContext(LocationContext);
  const [show, setShow] = useState(!selectedLocation);

  const handleSave = (location) => {
    setSelectedLocation(location);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <LocationToast
        location={locations.find((l) => l.id === parseInt(selectedLocation))}
        onClick={() => setShow(true)}
      />
      <LocationSelectModal
        show={show}
        locations={locations}
        selectedLocation={selectedLocation}
        previouslySelectedLocation={previouslySelectedLocation}
        handleSave={handleSave}
        onHide={() => setShow(false)}
      />
    </React.Fragment>
  );
};

const LocationSelectorWithContext = (props) => (
  <LocationContextProvider>
    <LocationSelector {...props} />
  </LocationContextProvider>
);

export default LocationSelectorWithContext;
