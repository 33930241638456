import React, { FC } from 'react';
import { Table as BsTable } from 'react-bootstrap';
import Header, { HeaderProps } from './Header';
import Cell from './Cell';

export interface TableProps<RecordType> {
  id?: string;
  headers: Array<HeaderProps<RecordType>>;
  data: Array<RecordType>;
  RowComponent?: FC<RecordType>;
}

const Table = <RecordType,>({
  id,
  headers,
  data,
  RowComponent,
}: TableProps<RecordType>) => (
  <BsTable id={id} responsive className="table-lg">
    <thead>
      <tr>
        {headers.map((header, idx) => (
          <Header key={idx} {...header} />
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((record, idx) =>
        RowComponent ? (
          <RowComponent key={idx} {...record} />
        ) : (
          <tr key={idx}>
            {headers.map((header, idx) => (
              <Cell key={idx} header={header} record={record} />
            ))}
          </tr>
        ),
      )}
    </tbody>
  </BsTable>
);

export default Table;
