import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker, { DateObject } from 'react-multi-date-picker';

const HighlightedDatePicker = ({ remoteUrl, value, multiple, ...props }) => {
  const [monthData, setMonthData] = useState({});

  const fetchMonthData = (month, year) => {
    axios.get(remoteUrl, { params: { month, year } }).then((response) => {
      const { data } = response;
      setMonthData({ ...monthData, ...data });
    });
  };

  useEffect(() => {
    const {
      month: { number: month },
      year,
    } = new DateObject(multiple ? value[value.length - 1] : value);

    fetchMonthData(month, year);
  }, [value]);

  return (
    <DatePicker
      {...props}
      multiple={multiple}
      value={value}
      mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
        let props = {};
        console.log('selectedDate', selectedDate);
        if (
          date.month.index === currentMonth.index &&
          !isSameDate(date, today) &&
          monthData[date.format('YYYY-MM-DD')] &&
          !(multiple
            ? selectedDate.find((d) => isSameDate(d, date))
            : isSameDate(date, selectedDate))
        ) {
          props.style = { backgroundColor: '#89c1f8' };
        }

        return props;
      }}
    />
  );
};

export default HighlightedDatePicker;
