import React, {useState} from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Exemption = ({
  allowNonMedicalExemptions,
  conditionOptions,
  dispatch,
  exemption
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const options = conditionOptions.map(option => ({value: option, label: t(`exemption.${option}`)}))

  return (
    <div className="my-3">
      <Button
        className="mb-3"
        size="sm"
        disabled={show}
        variant="outline-dark"
        onClick={() => {
          dispatch({
            type: "setExemption",
            value: {
              category: "medical",
              exemptionType: "permanent",
            },
          });
          setShow(true);
        }}
      >
        Log medical exemption
      </Button>
      {allowNonMedicalExemptions && (
        <Button
          className="mb-3 ms-2"
          size="sm"
          disabled={show}
          variant="outline-dark"
          onClick={() => {
            dispatch({
              type: "setExemption",
              value: {
                category: "non_medical",
                exemptionType: "permanent",
              },
            });
            setShow(true);
          }}
        >
          Log non-medical exemption
        </Button>
      )}
      {show && (
        <Card className="p-3 animate__animated animate__fadeIn">
          <Row>
            <Col>
              <h4>{exemption.category === "medical" ? "Medical" : "Non-medical"} exemption</h4>
            </Col>
            <Col xs="auto">
              <FontAwesomeIcon icon={faXmark} className="pointer" onClick={() => {
                setShow(false);
                dispatch({
                  type: "setExemption",
                  value: {},
                });
              }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FabrxCheckbox
                radio={true}
                name="exemption_type"
                checked={exemption.exemptionType === "temporary"}
                className="my-2"
                label="Temporary exemption"
                onChange={() => {
                  dispatch({
                    type: "setExemption",
                    value: { ...exemption, exemptionType: "temporary" },
                  });
                }}
              />
            </Col>
            <Col>
              <FabrxCheckbox
                radio={true}
                name="exemption_type"
                checked={exemption.exemptionType === "permanent"}
                className="my-2"
                label="Permanent exemption"
                onChange={() => {
                  dispatch({
                    type: "setExemption",
                    value: { ...exemption, exemptionType: "permanent" },
                  });
                }}
              />
            </Col>
          </Row>
          <div className="my-2">
            <h6>Immunizations under exemption</h6>
            <div className="text-muted mb-3">Please select all relevant immunizations that fall under the {exemption.category === "medical" && "medical"} exemption.</div>
            <FloatingLabelSelect
              label="Select all that apply *"
              value={options.filter(option => (exemption.conditions || []).indexOf(option.value) >= 0)}
              options={options}
              filledValue={exemption.conditions?.length > 0}
              isMulti={true}
              onChange={(s) => {
                dispatch({
                  type: "setExemption",
                  value: { ...exemption, conditions: (s || []).map((s) => s.value) },
                });
              }}
            />
          </div>
          {
            exemption.exemptionType === "temporary" &&
              <Row className="my-2">
                <Col>
                  <h5 className="my-4">Exemption expiration date</h5>
                </Col>
                <Col>
                  <FloatingLabelInput
                    name="expires_at"
                    label="Expiration date *"
                    type="date"
                    value={exemption.expiresAt}
                    className="mt-3"
                    onChange={(e) => {
                      dispatch({
                        type: "setExemption",
                        value: { ...exemption, expiresAt: e.target.value },
                      });
                    }}
                  />
                </Col>
              </Row>
          }
        </Card>
      )}
    </div>
  );
}

export default Exemption
