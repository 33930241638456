import React, { useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sumProperty } from '../../common/utils';
import { formatTime, mergeAndSortSlots } from '../../common/utils/slotting';
import HelpText from '../../common/components/HelpText';
import { faCheck, faPenToSquare, faUpRightFromSquare, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const Headers = ({
  hideDay = false,
  displayAdministered = false,
  displaySharedCapacities = false,
  displayNoShows = false,
}) => {
  let capacitiesPrefix = 'Group';

  return (
    <thead>
      <tr>
        {!hideDay && <th>Day</th>}
        <th style={{ minWidth: 140 }}>Hours</th>
        {displaySharedCapacities && (
          <th colSpan={2}>
            <div className="d-flex flex-column">
              <div className="w-100 d-flex flex-row align-items-center">
                <hr
                  className="flex-grow-1 mx-1 my-0"
                  style={{ borderWidth: 3 }}
                />
                <span>Shared</span>
                <hr
                  className="flex-grow-1 mx-1 my-0"
                  style={{ borderWidth: 3 }}
                />
              </div>
              <div
                className="d-flex flex-row align-items-end"
                style={{ gap: 24 }}
              >
                <span>Standard capacity</span>
                <span>Access key capacity</span>
              </div>
            </div>
          </th>
        )}
        <th colSpan={2}>
          <div className="d-flex flex-column">
            <div className="w-100 d-flex flex-row align-items-center">
              <hr
                className="flex-grow-1 mx-1 my-0"
                style={{ borderWidth: 3 }}
              />
              <span>{capacitiesPrefix}</span>
              <hr
                className="flex-grow-1 mx-1 my-0"
                style={{ borderWidth: 3 }}
              />
            </div>
            <div
              className="d-flex flex-row align-items-end"
              style={{ gap: 24 }}
            >
              <span>Standard capacity</span>
              <span>Access key capacity</span>
            </div>
          </div>
        </th>
        <th>
          Total appointments{' '}
          <HelpText text="Stats throughout this section are based on capacity planning and may result in different values if appointments are administered outside their original booking appointment date/time" />
        </th>
        {displayAdministered && (
          <th>
            <span
              style={{
                display: 'inline-flex',
                gap: '5px',
                alignItems: 'baseline',
              }}
            >
              Administered{' '}
              <HelpText text="Appointments administered based on appointment date/time" />
            </span>
          </th>
        )}
        {displayNoShows && <th>No shows</th>}
        <th></th>
      </tr>
    </thead>
  );
};

export const EditableCell = ({ editing, innerRef, value }) => (
  <td>
    {editing ? (
      <input
        ref={innerRef}
        style={{ width: 90 }}
        type="number"
        defaultValue={value}
      />
    ) : (
      value || '-'
    )}
  </td>
);

const SlotRow = ({
  id,
  starts_at,
  ends_at,
  capacity,
  access_key_capacity,
  booked,
  administered,
  shared_capacity,
  shared_access_key_capacity,
  group_capacity,
  group_access_key_capacity,
  no_shows,
  no_shows_path,
  participants_path,
  administered_path,
  hideDay = false,
  displayAdministered,
  displaySharedCapacities,
  displayNoShows,
  updateSlot,
  createSlot,
  ...slot
}) => {
  const isVirtualSlot = !id;
  const [editing, setEditing] = useState(false);
  const capacityFieldRef = useRef(null);
  const accessKeyCapacityFieldRef = useRef(null);

  const toggleEditing = (e) => {
    e.preventDefault();
    if (editing && updateSlot) {
      let params = {
        capacity: parseInt(capacityFieldRef.current.value) || 0,
        access_key_capacity:
          parseInt(accessKeyCapacityFieldRef.current.value) || 0,
      };

      // Virtual slot gets its attributes from the Appointment slots without a real Shared Capacity
      // We need additional params to create the Shared Capacity
      if (isVirtualSlot) {
        params = {
          shared_capacity: {
            staged: true,
            date: slot.date,
            start_time_hour: starts_at.hour,
            start_time_minute: starts_at.min,
            end_time_hour: ends_at.hour,
            end_time_minute: ends_at.min,
            time_zone: slot.time_zone,
            test_location_id: slot.test_location_id,
            ...params,
          },
        };

        createSlot(params);
      } else {
        updateSlot(params);
      }
    }
    setEditing(!editing);
  };

  return (
    <tr className="hoverable">
      {!hideDay && <td></td>}
      <td>{`${formatTime(starts_at)} - ${formatTime(ends_at)}`}</td>
      {displaySharedCapacities && (
        <React.Fragment>
          <EditableCell
            innerRef={capacityFieldRef}
            value={capacity}
            editing={editing}
          />
          <EditableCell
            innerRef={accessKeyCapacityFieldRef}
            value={access_key_capacity}
            editing={editing}
          />
        </React.Fragment>
      )}
      <td>{group_capacity}</td>
      <td>{group_access_key_capacity}</td>
      <td>
        {booked}{' '}
        {participants_path && (
          <a
            className="display-on-hover"
            href={participants_path}
            target="blank"
          >
            <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
          </a>
        )}
      </td>
      {displayAdministered && (
        <td>
          {administered}{' '}
          {administered_path && (
            <a
              className="display-on-hover"
              href={administered_path}
              target="blank"
            >
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          )}
        </td>
      )}
      {displayNoShows && (
        <td>
          {no_shows}{' '}
          {no_shows_path && (
            <a className="display-on-hover" target="blank" href={no_shows_path}>
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          )}
        </td>
      )}
      <td style={{ minWidth: 100 }}>
        {updateSlot &&
          (editing ? (
            <a onClick={toggleEditing} href="#">
              <FontAwesomeIcon icon={faCheck} /> Done
            </a>
          ) : (
            <a className="display-on-hover" onClick={toggleEditing} href="#">
              <FontAwesomeIcon icon={faPenToSquare} />{' '}
              {isVirtualSlot ? 'Create' : 'Edit'}
            </a>
          ))}
      </td>
    </tr>
  );
};

const DaySlotsGroupRow = ({
  booked,
  participants_path,
  administered,
  administered_path,
  no_shows,
  no_shows_path,
  daySlots,
  setDaySlots,
  for_test_location,
}) => {
  const [displayAllSlots, setDisplayAllSlots] = useState(false);

  const toggleDisplayAll = () => {
    setDisplayAllSlots(!displayAllSlots);
  };

  const updateSlotValues = (idx, values) => {
    const newDaySlots = [...daySlots];
    newDaySlots[idx] = { ...newDaySlots[idx], ...values };
    setDaySlots(newDaySlots);
  };

  const updateSlot = (idx) => (values) => {
    axios
      .patch(`${daySlots[idx].update_path}.json`, values)
      .then((response) => {
        const { data } = response;
        if (data.success) updateSlotValues(idx, values);
      });
  };

  const createSlot = (idx) => (values) => {
    axios.post(`${daySlots[idx].create_path}`, values).then((response) => {
      const { data } = response;
      if (data.success) {
        const {
          shared_capacity: { id, capacity, access_key_capacity, update_path },
        } = data;
        updateSlotValues(idx, {
          id,
          capacity,
          access_key_capacity,
          update_path,
        });
      }
    });
  };

  const details = useMemo(() => {
    const firstSlot = daySlots[0];
    const lastSlot = daySlots[daySlots.length - 1];

    return {
      hours: `${formatTime(firstSlot.starts_at)} - ${formatTime(
        lastSlot.ends_at,
      )}`,
      shared_capacity: sumProperty(daySlots, 'capacity'),
      shared_access_key_capacity: sumProperty(daySlots, 'access_key_capacity'),
      group_capacity: sumProperty(daySlots, 'group_capacity'),
      group_access_key_capacity: sumProperty(
        daySlots,
        'group_access_key_capacity',
      ),
    };
  }, [daySlots]);

  return (
    <React.Fragment>
      <tr className="hoverable">
        <td>
          <div>{details['hours']}</div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleDisplayAll();
            }}
          >
            {displayAllSlots ? 'Hide slots' : 'Show all slots'}
          </a>
        </td>
        <td>{details['shared_capacity']}</td>
        <td>{details['shared_access_key_capacity']}</td>
        <td>{details['group_capacity']}</td>
        <td>{details['group_access_key_capacity']}</td>
        <td>
          {booked}{' '}
          {participants_path && (
            <a
              className="display-on-hover"
              href={participants_path}
              target="blank"
            >
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          )}
        </td>
        <td>
          {administered}{' '}
          {administered_path && (
            <a
              className="display-on-hover"
              href={administered_path}
              target="blank"
            >
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          )}
        </td>
        <td>
          {no_shows}{' '}
          {no_shows_path && (
            <a className="display-on-hover" target="blank" href={no_shows_path}>
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          )}
        </td>
        <td></td>
      </tr>

      {displayAllSlots &&
        daySlots.map((slot, idx) => (
          <SlotRow
            for_test_location={for_test_location}
            hideDay
            displayAdministered
            displaySharedCapacities
            displayNoShows
            updateSlot={updateSlot(idx)}
            createSlot={createSlot(idx)}
            key={idx}
            {...slot}
          />
        ))}
    </React.Fragment>
  );
};

const EndOfDaySlotRow = ({
  createSlot,
  start_time_hour,
  start_time_minute,
  end_time_hour,
  end_time_minute,
  capacity,
  access_key_capacity,
  setDisplayNewSlot,
  ...slotAttrs
}) => {
  const [editing, setEditing] = useState(true);
  const [fields, setFields] = useState({ capacity, access_key_capacity });

  const toggleEditing =
    ({ create = false }) =>
    (e) => {
      e.preventDefault();
      if (create) {
        const slotParams = {
          start_time_hour,
          start_time_minute,
          end_time_hour,
          end_time_minute,
          capacity: parseInt(fields['capacity']),
          access_key_capacity: parseInt(fields['access_key_capacity']),
          ...slotAttrs,
        };
        createSlot(slotParams);
      }
      setEditing(!editing);
    };

  const updateField = (field) => (e) => {
    setFields({
      ...fields,
      [field]: e.target.value,
    });
  };

  return (
    <tr className="hoverable">
      <td></td>
      <td>{`${formatTime({
        hour: start_time_hour,
        min: start_time_minute,
      })} - ${formatTime({
        hour: end_time_hour,
        min: end_time_minute,
      })}`}</td>
      {editing ? (
        <React.Fragment>
          <td>
            <input
              type="number"
              style={{ width: 120 }}
              value={fields['capacity']}
              onChange={updateField('capacity')}
            />
          </td>
          <td>
            <input
              type="number"
              style={{ width: 120 }}
              value={fields['access_key_capacity']}
              onChange={updateField('access_key_capacity')}
            />
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>{capacity}</td>
          <td>{access_key_capacity}</td>
        </React.Fragment>
      )}
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>
        {editing ? (
          <div className="d-flex flex-column" style={{ minWidth: 90 }}>
            <a onClick={toggleEditing({ create: true })} href="#">
              <FontAwesomeIcon icon={faCheck}/> Create
            </a>
            <a
              className="text-danger"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDisplayNewSlot(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} /> Cancel
            </a>
          </div>
        ) : (
          <a
            className="display-on-hover"
            onClick={toggleEditing({ create: false })}
            href="#"
          >
            <FontAwesomeIcon icon={faPenToSquare}/> Edit
          </a>
        )}
      </td>
    </tr>
  );
};

const AddSlotRow = ({ endOfDaySlots = null, createSlot: propCreateSlot }) => {
  const [displayNewSlot, setDisplayNewSlot] = useState(false);

  const createSlot = (params) => {
    propCreateSlot(params);
    setDisplayNewSlot(false);
  };

  const endOfDaySlot = endOfDaySlots && endOfDaySlots[0];

  return (
    endOfDaySlot &&
    (displayNewSlot ? (
      <EndOfDaySlotRow
        {...endOfDaySlot}
        createSlot={createSlot}
        setDisplayNewSlot={setDisplayNewSlot}
      />
    ) : (
      <tr>
        <td colSpan={42}>
          <div className="w-100 d-flex">
            <div style={{ margin: 'auto' }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setDisplayNewSlot(true);
                }}
              >
                + New slot
              </a>
            </div>
          </div>
        </td>
      </tr>
    ))
  );
};

const SlotGroupRow = ({
  display_date: date,
  day_name,
  day_slots,
  group_only_slots,
  new_slots_path,
  doseManagementData: { additional_capacity, additional_access_key_capacity },
  setDoseManagementData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [slots, setSlots] = useState(
    mergeAndSortSlots(day_slots, group_only_slots),
  );
  const slotCount = slots.length;

  const details = useMemo(() => {
    const firstSlot = slots[0];
    const lastSlot = slots[slots.length - 1];

    return slotCount > 0
      ? {
          hours: `${formatTime(firstSlot.starts_at)} - ${formatTime(
            lastSlot.ends_at,
          )}`,
          shared_capacity: sumProperty(slots, 'capacity'),
          shared_access_key_capacity: sumProperty(slots, 'access_key_capacity'),
          group_capacity: sumProperty(slots, 'group_capacity'),
          group_access_key_capacity: sumProperty(
            slots,
            'group_access_key_capacity',
          ),
          booked: sumProperty(slots, 'booked'),
          administered: sumProperty(slots, 'administered'),
        }
      : {
          hours: 'No Slots',
          capacity: '-',
          access_key_capacity: '-',
          booked: '-',
          administered: '-',
          newSlotsPath: new_slots_path,
        };
  }, [slots]);

  const updateSlot = (idx) => (values) => {
    axios.patch(`${slots[idx].update_path}.json`, values).then((response) => {
      const { data } = response;
      if (data.success) {
        const {
          capacity: oldCapacity,
          access_key_capacity: oldAccessKeyCapacity,
        } = slots[idx];

        const {
          capacity: newCapacity,
          access_key_capacity: newAccessKeyCapacity,
        } = values;

        const newSlots = [...slots];
        newSlots[idx] = { ...newSlots[idx], ...values };

        setSlots(newSlots);
        setDoseManagementData({
          additional_capacity:
            additional_capacity - (newCapacity - oldCapacity),
          additional_access_key_capacity:
            additional_access_key_capacity -
            (newAccessKeyCapacity - oldAccessKeyCapacity),
        });
      }
    });
  };

  return (
    <React.Fragment>
      <tr className="hoverable">
        <td>
          <span style={{ display: 'inline-block', minWidth: 32 }}>
            {day_name}
          </span>{' '}
          <span>{date}</span>
        </td>
        <td className="column">
          <div>{details['hours']}</div>
          {slotCount > 0 && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setExpanded(!expanded);
              }}
            >
              {expanded ? 'Hide' : 'See/edit'} slots
            </a>
          )}
        </td>
        <td>{details['shared_capacity']}</td>
        <td>{details['shared_access_key_capacity']}</td>
        <td>{details['group_capacity']}</td>
        <td>{details['group_access_key_capacity']}</td>
        <td>{details['booked']}</td>
        <td>{details['administered']}</td>
        <td>
          {new_slots_path && (
            <div style={{ minWidth: 90 }}>
              <a
                className="display-on-hover"
                href={new_slots_path}
                target="blank"
              >
                <FontAwesomeIcon size="sm" icon={faPlus}/> Add slots
              </a>
            </div>
          )}
        </td>
      </tr>
      {expanded &&
        slots.map((slot, idx) => (
          <SlotRow
            {...slot}
            key={idx}
            displaySharedCapacities
            displayAdministered
            updateSlot={updateSlot(idx)}
          />
        ))}
    </React.Fragment>
  );
};

export const SlotGroupsTable = ({
  slotGroups,
  doseManagementData,
  setDoseManagementData,
}) => {
  return (
    <section className="fabrx-tables-light table-responsive">
      <table className="table table-lg">
        <Headers displaySharedCapacities displayAdministered />
        <tbody>
          {slotGroups.map((slotGroup, idx) => (
            <SlotGroupRow
              key={idx}
              {...slotGroup}
              doseManagementData={doseManagementData}
              setDoseManagementData={setDoseManagementData}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
};

const DaySlotsTable = ({
  day_summary,
  daySlots,
  endOfDaySlots,
  createSlot,
  setDaySlots,
}) => {
  const slotsCount = daySlots.length;

  return (
    <section className="fabrx-tables-light table-responsive">
      <table className="table table-lg">
        <Headers
          hideDay
          displayAdministered
          displayNoShows
          displaySharedCapacities
        />
        <tbody>
          {slotsCount > 0 ? (
            <React.Fragment>
              <DaySlotsGroupRow
                {...day_summary}
                daySlots={daySlots}
                setDaySlots={setDaySlots}
              />
              <AddSlotRow
                endOfDaySlots={endOfDaySlots}
                createSlot={createSlot}
              />
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan={42}>
                <div className="w-100 d-flex">
                  <div style={{ margin: 'auto' }}>No slots for this day</div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default DaySlotsTable;
