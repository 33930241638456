import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({
  show,
  onConfirm,
  onCancel,
  body,
  headerText = "Confirm",
  confirmButtonText = "Yes"
}) => {
  const [isLoading, setLoading] = useState(false);

  const handleClickConfirm = () => {
    setLoading(true)
    onConfirm()
  };

  return (
    <Modal show={show} onHide={onCancel} size="md" className="text-center">
      <Modal.Header>
        {headerText}
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          disabled={isLoading}
          onClick={handleClickConfirm}
        >{confirmButtonText}</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal;
