import React, { useContext } from 'react';
import PureButton from '../../../../common/components/PureButton';
import RegistrationContext from '../../../contexts/RegistrationContext';
import AvailabilityIndicator from './AvailabilityIndicator';

const AppointmentSlotGroupPill = ({
  appointmentSlotGroup,
  selected,
  onClick,
  autoFocus = false,
  disabled = false,
}) => {
  const { testGroup } = useContext(RegistrationContext);
  return (
    <PureButton
      key={appointmentSlotGroup.id + appointmentSlotGroup.title}
      onClick={onClick}
      block
      outline
      active={selected}
      disabled={disabled}
      autoFocus={autoFocus}
      className="my-2"
      aria-describedby="appointmentSlotErrorDesc"
      ariaLabel={ `${appointmentSlotGroup.title} ${selected ? ", checked" : ", unchecked"}` }
    >
      {appointmentSlotGroup.title}
      {disabled && (
        <div className="text-muted font-weight-light">
          Location not eligible for Dose Selection
        </div>
      )}
    </PureButton>
  );
};

export default AppointmentSlotGroupPill;
