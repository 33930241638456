import axios from 'axios';
import React, { useEffect, useCallback, useState } from 'react';
import '../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../common/components/LoadingOverlay';
import VerificationCodeInput from '../common/components/VerificationCodeInput';

const CodeVerify = ({ phoneNumber, emailAddress }) => {
  const { t, i18n } = useTranslation();
  const phone = phoneNumber;
  const email = emailAddress;
  const [otpCode, setOtpCode] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifyConfirmationCode = () =>
    axios.put('/sessions/validate_otp_code', {
      user: { phone_number: phone, email: email, code: otpCode },
    });

  const onVerify = () => {
    setIsInvalidCode(false);
    setIsVerified(true);
    setLoading(true);
    axios
      .post('/sessions', {
        user: { phone_number: phone, email: email, code: otpCode },
      })
      .then((response) => {
        window.location.href = response.data.redirect_url;
      });
  };

  const verify = useCallback(async () => {
    verifyConfirmationCode()
      .then(
        (response) =>
          response.data.verification_status === 'verified' && onVerify(),
      )
      .catch((_e) => {
        setIsInvalidCode(true);
      });
  });

  useEffect(() => {
    otpCode.length === 6 && verify(otpCode);
  }, [otpCode]);

  return (
    <div>
      <LoadingOverlay loading={loading} loadingText="Logging in...">
        <input id="user_code" name="user[code]" value={otpCode} type="hidden" />
      </LoadingOverlay>
      <div className="my-5">
        <VerificationCodeInput
          onChange={setOtpCode}
          verified={isVerified}
          invalid={isInvalidCode}
        />
      </div>
      {isInvalidCode && (
        <div className="text-danger">{t('enter_code.incorrect_code')}</div>
      )}
    </div>
  );
};

export default CodeVerify;
