import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import TablePagination from '../../common/components/TablePagination';

export const GroupStats = ({ group_stats_path }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [appointmentSlotGroups, setAppointmentSlotGroups] = useState([]);

  const fetchAppointmentSlotGroups = useCallback(
    (page) => {
      setLoading(true);
      axios.get(`${group_stats_path}&page=${page}`).then((response) => {
        const { data } = response;
        setAppointmentSlotGroups(data.appointment_slot_groups);
        setMeta(data.meta);
        setLoading(false);
      });
    },
    [group_stats_path],
  );

  useEffect(() => {
    fetchAppointmentSlotGroups(page);
  }, [page]);

  return (
    <React.Fragment>
      <section className="fabrx-tables-light table-responsive">
        <table className="mt-4 table table-lg">
          <thead>
            <tr>
              <th scope="col">Group</th>
              <th scope="col">Booked capacity</th>
              <th scope="col">Standard capacity</th>
              <th scope="col">Access key capacity</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4} className="center">
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>{' '}
                  Loading...
                </td>
              </tr>
            ) : (
              appointmentSlotGroups.map((appointmentSlotGroup, idx) => (
                <tr key={idx}>
                  <td>
                    <a
                      href={
                        appointmentSlotGroup.test_group_appointment_slots_path
                      }
                    >
                      {appointmentSlotGroup.test_group_name}
                    </a>
                  </td>
                  <td>{appointmentSlotGroup.test_group_appointments}</td>
                  <td>{appointmentSlotGroup.test_group_standard_capacity}</td>
                  <td>{appointmentSlotGroup.test_group_access_key_capacity}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>
      {meta && meta.total_pages > 1 && (
        <TablePagination
          currentPage={meta.current_page}
          nextPage={meta.next_page}
          prevPage={meta.prev_page}
          totalPages={meta.total_pages}
          setPage={setPage}
        />
      )}
    </React.Fragment>
  );
};
