import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import TemplateServicesModal from "./TemplateServicesModal";
import FloatingLabelInput from "../common/components/FloatingLabelInput";

const MAX_DISPLAYED_SERVICES = 3;

const RestrictionsSection = ({
  age_restriction,
  domain_restriction,
}) => {

  const restrictions = [
    age_restriction && <span key="age" style={{ fontSize: 14}}>Age: {age_restriction}</span>,
    domain_restriction && <span key="domain" style={{fontSize: 14}}>Domain: {domain_restriction}</span>,
  ].filter(r => !!r);

  return (
    <div>
      {restrictions.map((restriction, index) => (
        <React.Fragment key={index}>
          {restriction}
          {index < restrictions.length - 1 && ", "}
        </React.Fragment>
      ))}
    </div>
  );
};

const TemplateCard = ({ setSelectedTemplateId, template }) => {

  const displayedServices = template.test_group_test_configurations.slice(0, MAX_DISPLAYED_SERVICES);
  const hiddenServicesCount = template.test_group_test_configurations.length - MAX_DISPLAYED_SERVICES;

  return (
    <Card className="my-3">
      <Card.Body>
        <Row>
          <Col xs={9} md={10} lg={11}>
            <h5 className="mb-3 lead-20-medium inter bold">{template.name}</h5>
            <p className="my-3" dangerouslySetInnerHTML={{ __html: template.template_description }}></p>
            {!!displayedServices.length && (
              <div className="my-3 d-flex">
                <div style={{ width: "90px", marginRight: "8px", fontWeight: 700, fontSize: 14 }}>
                  Services
                </div>
                <div style={{ fontSize: 14 }}>
                  {displayedServices.map((tgtc) => tgtc.display_name).join(", ")}
                  {hiddenServicesCount > 0 && (
                    <span
                      className="pointer"
                      onClick={() => setSelectedTemplateId(template.id)}
                      style={{ color: 'var(--primary, #2862FA)', fontSize: 14 }}
                    >
                      &nbsp;<span className="font-weight-bold">+&nbsp;{hiddenServicesCount}&nbsp;more</span>
                    </span>
                  )}
                </div>
              </div>
            )}
            {template.restrictions.restricted && (
              <div className="my-3 d-flex">
                <div style={{ width: "90px", marginRight: "8px", fontWeight: 700, fontSize: 14 }}>
                  Restrictions
                </div>
                <RestrictionsSection {...template.restrictions} />
              </div>
            )}
          </Col>
          <Col xs={3} md={2} lg={1}>
            <div className="d-flex justify-content-end">
              <div
                className="d-flex justify-content-center align-items-center text-white my-auto"
                onClick={() => window.location.href = `/test_groups/new_from_template?template_test_group_id=${template.id}`}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  height: 50,
                  width: 50,
                  borderRadius: "50%",
                  marginRight: "0",
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const TestGroupTemplatesIndex = ({ template_test_groups }) => {

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [filteredTemplatesTestGroups, setFilteredTemplatesTestGroups] = useState(template_test_groups);
  const [search, setSearch] = useState("");
  const handleClose = () => setSelectedTemplateId(null);

  useEffect(() => {
    let newFilteredTemplatesTestGroups = template_test_groups;
    if (!!search) {
      newFilteredTemplatesTestGroups = newFilteredTemplatesTestGroups.filter((ttg) => {
        const nameIncludesSearchTerm = ttg.name.toLowerCase().includes(search.toLowerCase());
        const descriptionIncludesSearchTerm = ttg.template_description.toLowerCase().includes(search.toLowerCase());
        return nameIncludesSearchTerm || descriptionIncludesSearchTerm;
      })
    }
    setFilteredTemplatesTestGroups(newFilteredTemplatesTestGroups);
  }, [search]);

  return (
    <div>
      <FloatingLabelInput
        label="Search templates"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      {filteredTemplatesTestGroups.map((ttg) => {
        return (
          <TemplateCard
            setSelectedTemplateId={setSelectedTemplateId}
            template={ttg}
          />
        )
      })}
      <TemplateServicesModal
        handleClose={handleClose}
        selectedTemplate={template_test_groups.find((ttg) => ttg.id === selectedTemplateId)}
        show={!!selectedTemplateId}
      />
    </div>
  );
};

export default TestGroupTemplatesIndex;