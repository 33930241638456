import React, { useEffect, useContext, useState } from 'react';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import LocalizedMarkdown from './registration/fields/LocalizedMarkdown';
import SwitchUsers from '../../MembersPortal/components/partials/SwitchUsers';

import FormActions from './FormActions';
import RegistrationContext from '../contexts/RegistrationContext';
import IEModal from './IEModal';
import Header from './Header';
import { ProgressBar } from '../../common/components/Wizard';
import { UserCircle, Questionnaire } from '../../common/components/Icons/registration';
import NewSectionsSidebar from './NewSidebar';

const [PAST, CURRENT, FUTURE] = [0, 1, 2];
const LOGGED_IN_NOT_READY = 0;
const LOGGED_IN_READY = 1;
const LOGGED_IN_NO_HOUSEHOLD_MEMBERS = 2;

const detectIE = (window) =>
  window.navigator.userAgent.indexOf('MSIE ') !== -1 ||
  window.navigator.userAgent.indexOf('Trident/') !== -1;

const HeaderSection = ({
  testGroup,
  initialValues,
  localFormState,
  sections,
  currentRegistrationStep,
  isMobile,
}) => {
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(LOGGED_IN_NOT_READY);

  useEffect(() => {
    if (localFormState.loggedIn) {
      fetch(`/members/household_members.json`)
        .then((response) => response.json())
        .then((data) => {
          setHouseholdMembers(data.household_members);
          setLoadingMembers(
            data.household_members.length > 0
              ? LOGGED_IN_READY
              : LOGGED_IN_NO_HOUSEHOLD_MEMBERS,
          );
        })
        .catch((error) => setLoadingMembers(LOGGED_IN_NO_HOUSEHOLD_MEMBERS));
    }
  }, []);

  return (
    <React.Fragment>
      <LocalizedMarkdown
        container={testGroup}
        stringKey="registration_banner"
      />
      {detectIE(window) === true && <IEModal />}
      <Header
        testGroup={testGroup}
        initialValues={initialValues}
        justifyContent
        loggedInComponent={
          localFormState.loggedIn && loadingMembers !== LOGGED_IN_NO_HOUSEHOLD_MEMBERS && (
            <SwitchUsers
              currentUser={
                initialValues.id
                  ? householdMembers.find(
                      (hm) => hm.user.id === initialValues.id,
                    )
                  : {}
              }
              householdMembers={householdMembers}
              ready={loadingMembers}
              disableOtherMembers
            />
          )
        }
      />
      {isMobile && (
        <ProgressBar
          title="Registration"
          totalSteps={sections.length}
          completedSteps={currentRegistrationStep}
          capitalizeTitle
        />
      )}
    </React.Fragment>
  );
};

const RegistrationTab = styled.button`
  box-sizing: border-box;
  display: flex;
  height: 60px;
  width: 260px;
  padding: 0;
  border-width: 0;
  background-color: var(--bs-white);

  ${({ primaryColor }) =>
    primaryColor
      ? css`
          --primary-color-0: ${primaryColor};
          --primary-color-1: ${`${primaryColor}25`};
        `
      : css`
          --primary-color-0: #2862FA;
          --primary-color-1: #e5f3fa;
        `}

  --inactive-color: #3f3b3b;

  ${({ active }) =>
    active &&
    css`
      background-color: var(--primary-color-1);
    `}

  ${({ past, primaryColor }) =>
    past &&
    css`
      &:hover,
      &:focus {
        background-color: ${`${primaryColor}15`};
      }
    `}

  ${({ pointer, primaryColor }) =>
    pointer &&
    css`
      cursor: pointer;

      &:focus {
        box-shadow: inset 0px 0px 0px 3px ${primaryColor};
      }
    `}
`;

const RegistrationTabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  text-align: start;
  padding: 0px 25px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  color: var(--inactive-color);

  ${({ active }) =>
    active &&
    css`
      color: var(--primary-color-0);
      font-weight: 700;
    `}
  .icon {
    margin-right: 10px;
  }
`;

const SectionTab = ({
  header,
  Icon = Questionnaire,
  index,
  currentRegistrationStep,
  state = CURRENT,
  primaryColor,
  onClick,
}) => {
  const active = currentRegistrationStep === index && state === CURRENT;
  const past =
    state === PAST || (currentRegistrationStep > index && state === CURRENT);

  return (
    <RegistrationTab
      active={active}
      past={past}
      primaryColor={primaryColor}
      pointer={active || past}
      tabIndex={active || past ? 0 : -1}
      aria-label={`${header} page`}
      aria-selected={active}
      aria-current={active}
      role="tab"
      key={header}
      disabled={!past}
      onClick={onClick}
    >
      <RegistrationTabContent active={active}>
        {Icon && (
          <div className="icon">
            <Icon
              height={20}
              width={20}
              color={
                active ? 'var(--primary-color-0)' : 'var(--inactive-color)'
              }
            />
          </div>
        )}
        <span>{header}</span>
      </RegistrationTabContent>
    </RegistrationTab>
  );
};

const RegistrationUserNameBadge = styled.div`
  padding: 7px 0px;
  span {
    color: #586DA0;
    font-size: 18px;
    font-weight: 700;
  }
  svg {
    margin-bottom: 3px;
  }
  @media (max-width: 576px) {
    padding: 0px;
    margin-bottom: 20px;
  }
`;

const UserNameBadge = ({
  full_name,
}) => {
  return (
    <RegistrationUserNameBadge>
      <UserCircle
        height={24}
        width={24}
        color={'#586DA0'}
      />
      <span className="ms-2">
        {full_name}
      </span>
    </RegistrationUserNameBadge>
  );
};

const ViewContainer = styled.div`
  @media (max-width: 576px) {
    .main-container {
      min-height: unset;
    }

    .form-inputs {
      min-height: 75vh;
    }
  }
`;

const RegistrationCore = ({
  initialValues,
  onSubmit,
  sections,
  registrationProps,
  currentRegistrationStep,
  setCurrentRegistrationStep,
  onSubmitText,
  showFormActions = true,
  additionalSections,
  showSideBar = true,
  previousUrl,
}) => {
  // State initialization
  const { testGroup, setIsSubmitting, localFormState, setSubmitButtonDisabled } =
    useContext(RegistrationContext);
  const history = useHistory();
  // if this is set to true then the user should not be able to continue registration
  const [disallowContinue, doesNotQualifyForRegistration] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    if (urlParams.get('error')) {
      toastr.error(urlParams.get('error').split('+').join(' '), null, {
        timeOut: 1000000,
      });
    }
  }, []);

  useEffect(() => {
    setSubmitButtonDisabled(false);
    setIsSubmitting(false);
    window.scrollTo(0, 0);
  }, [currentRegistrationStep]);

  return (
    <div>
      <HeaderSection
        testGroup={testGroup}
        sections={sections}
        currentRegistrationStep={currentRegistrationStep}
        initialValues={initialValues}
        localFormState={localFormState}
        isMobile={isMobile}
      />
      <main>
        <Container style={{ maxWidth: 770 }}>
          <div>
            {showSideBar && (
              <NewSectionsSidebar
                sections={sections}
                additionalSections={additionalSections}
                currentRegistrationStep={currentRegistrationStep}
                setCurrentRegistrationStep={setCurrentRegistrationStep}
                previousUrl={previousUrl}
              />
            )}
            <ViewContainer className="w-100 registration__content">
              <div
                className={`main-container ${!isMobile && 'container'}`}
                id="main-content"
              >
                <Formik
                  initialValues={initialValues}
                  validate={sections[currentRegistrationStep].validate(
                    testGroup,
                  )}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    useEffect(() => {
                      if (Object.keys(formikProps.errors).length)
                        setIsSubmitting(false);
                      if (formikProps.errors.test_group_questionnaire_error)
                        doesNotQualifyForRegistration(true);
                    }, [formikProps]);

                    return (
                      <div className="web-view">
                        <form
                          onSubmit={formikProps.handleSubmit}
                          data-test-hook="registration_form"
                        >
                          {initialValues?.appointment?.appointment_slot_ids && ( // if family registration
                            <div className="text-end" style={ !isMobile ? { margin: "0px 0px -36px 0px"} : {} }>
                              <UserNameBadge
                                full_name={`${formikProps.values.first_name} ${formikProps.values.middle_name || ""} ${formikProps.values.last_name}`}
                              />
                            </div>
                          )}
                          <div className="form-inputs mb-5">
                            {sections[currentRegistrationStep].view({
                              ...registrationProps,
                              ...formikProps,
                            })}
                          </div>
                          {showFormActions && !disallowContinue && (
                            <FormActions onSubmitText={onSubmitText} />
                          )}
                        </form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </ViewContainer>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default RegistrationCore;
