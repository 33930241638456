import React, { useState } from "react";
import '../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import VerifyContactInformation from "./Verify";
import { Button } from "react-bootstrap";
import FloatingLabelInput from "../common/components/FloatingLabelInput";
import ErrorMessage from "../common/components/ErrorMessage";
import DateOfBirthInput from "../common/components/DateOfBirthInput";
import axios from "axios";

const Show = ({redirect, email, phone_number: phoneNumber }) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);

  return (
    <div className="mt-3">
      <VerifyContactInformation {...{
        email,
        phoneNumber,
        onVerify: () => window.location.href = urlParams.get("redirect")
      }} />
    </div>
  )
}

export default Show;
