import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorMessage = ({ message, className, id }) => {
  return (
    <div className={`d-flex font-size-12 text-danger ${className}`}>
      <img className="my-auto" alt="triangle-exclamation" src="/images/triangle-exclamation.svg" />
      <span className="ms-2" id={id} aria-live="polite">
        {message}
      </span>
    </div>
  );
};

export default ErrorMessage;
