import React, { useState } from 'react';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWalking,
  faHandHoldingMedical,
  faLaptopMedical,
} from '@fortawesome/pro-solid-svg-icons';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';

const ColSection = ({
  stepNumber,
  title,
  colSize = { xs: 12, md: 6 },
  children,
}) => {
  return (
    <Col xs={colSize.xs} md={colSize.md} className="mb-3">
      <div className="mb-3">
        {stepNumber}. {title}
      </div>
      {children}
    </Col>
  );
};

const NewAppointment = ({
  testGroupSlug,
  populations,
  accessKeys,
  testGroupUser,
  user,
  allowAccessCodeRegistration,
  allowMultipleRegistrations,
  ...props
}) => {
  const [population, setPopulation] = useState({});
  const [accessKey, setAccessKey] = useState({value: ''});
  const [notificationMessage, setNotificationMessage] = useState('');
  const [collectSignature, setCollectSignature] = useState(true);

  const forceAppointmentLink = `/test_groups/${testGroupSlug}/test_group_users/${testGroupUser.id}/new_appointment_on_behalf`;
  const appointmentLink = `/a/${testGroupSlug}/new?registration_type=${population.name}&key=${accessKey.value}&on_behalf_registration=true&access_code=${testGroupUser.accessCode}&last_name=${user.lastName}&date_of_birth=${user.dateOfBirth}${collectSignature ? '' : '&consent_bypass=horse-drawn-wagon'}`

  const sendSelfAppointmentRegistration = () => {
    let payload = {}
    fetch(`/test_groups/${testGroupUser.testGroupId}/test_group_users/${testGroupUser.id}/send_self_appointment`, {
      method: "POST",
      redirect: 'follow',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
    }).then(response => {
      setNotificationMessage(`Email sent to the participant. Redirecting to the participants page..`)
      setTimeout(() => {window.location.href = response.url}, 2000)
    });
  }

  return (
    <div>
      {notificationMessage && (
        <Row>
          <Alert dismissible key='notificationMessage' variant='success' onClose={() => setNotificationMessage('')}>
            {notificationMessage}
          </Alert>
        </Row>
      )}
      <Card body>
        <ColSection
          stepNumber={1}
          title="Which population does this participant belong to?"
        >
          <Card body>
            {populations.map((p) => {
              let active = false
              if (population.id) {
                active = population.id === p.id;
              } else {
                active = testGroupUser.populationId == p.id;
                setPopulation(p);
              }
              return (
                <Button
                  key={`populationSelection${p.id}`}
                  variant={active ? 'primary' : 'outline-primary'}
                  active={active}
                  block
                  onClick={() => {setPopulation(p)}}
                >
                  {p.friendlyName || p.name}
                </Button>
              );
            })}
          </Card>
        </ColSection>
        {accessKeys.length > 0 && (
          <ColSection
            stepNumber={2}
            title="Would you like to add an access key? (does not apply to Force add)"
          >
            <Card body>
              {accessKeys.map((key) => {
                let active = false
                if (accessKey) {
                  active = accessKey.value === key.value;
                }

                return (
                  <Button
                    key={`accessKeySelection${key.value}`}
                    variant={active ? 'primary' : 'outline-primary'}
                    active={active}
                    block
                    onClick={() => { setAccessKey(key); }}
                  >
                    {key.friendly_name || key.value}
                  </Button>
                );
              })}
            </Card>
          </ColSection>
        )}

        <ColSection
          stepNumber={2}
          title="How do you want to fix appointment for this participant?"
          colSize={{ xs: 12 }}
        >
          <Card body>
            <Row className="text-center">
              {allowAccessCodeRegistration && allowMultipleRegistrations && (
                <Col md={4} className="my-2">
                  <h1>
                    <FontAwesomeIcon
                      icon={faWalking}
                      style={{ color: 'var(--vivid-blue)' }}
                    />
                  </h1>
                  <p className="text-muted">
                    <b>Participant</b> selects appointment timeslot
                    independently.
                  </p>
                  <Button onClick={sendSelfAppointmentRegistration} variant="primary" block>
                    Send self-registration
                  </Button>
                </Col>
              )}
              <Col md={4} className="my-2">
                <h1>
                  <FontAwesomeIcon
                    icon={faHandHoldingMedical}
                    style={{ color: 'var(--vivid-blue)' }}
                  />
                </h1>
                <p className="text-muted">
                  I will select timeslot for the participant's appointment.
                </p>
                <Button variant="primary" block onClick={() => {window.open(appointmentLink, '_self');}} >
                  Register on behalf
                </Button>
                <div
                className={`text-start mt-2 ${!population.id && 'text-muted'}`}
              >
                <FabrxCheckbox
                  checked={collectSignature === true}
                  onChange={() => setCollectSignature(!collectSignature)}
                  label="Collect signature on this device (disable for consent bypass)"
                />
              </div>
              </Col>
              <Col md={4} className="my-2">
                <h1>
                  <FontAwesomeIcon
                    icon={faLaptopMedical}
                    style={{ color: 'var(--danger-red)' }}
                  />
                </h1>
                <p className="text-muted">
                  I will register the participant bypassing the schedule. (No
                  Dose Wasted End of Day)
                </p>
                <Button variant="danger" block onClick={() => {window.open(forceAppointmentLink, '_self');}} >
                  Force Register
                </Button>
              </Col>
            </Row>
          </Card>
        </ColSection>
      </Card>
    </div>
  );
};

export default NewAppointment;
