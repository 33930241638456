import React, { useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';

import ClinicsMap, {
  selectedPinIcon,
  selectedPartialPinIcon,
} from './ClinicsMap';
import EditClinicSearchParams from './EditClinicSearchParams';
import LocationCard from './LocationCard';
import { isMobile } from '../../ImmunizationStatus/components/UploadRecords';

const FindClinic = ({ state, dispatch, t }) => {
  const [mobileView, setMobileView] = useState('list'); // list or map
  const [selectedAppointmentSlotGroup, setSelectedAppointmentSlotGroup] = useState(null);
  const { appointmentSlotGroups } = state.formState;
  const exactMatchAppointmentSlotGroups = appointmentSlotGroups.filter(asg =>
    state.selectedServiceSpecifications.every(service =>
      !!asg.test_configurations.find(tc => tc.service_specification === service
        || tc.age_grouped_vaccine_service_specification === service)
    )
  );
  const partialMatchAppointmentSlotGroups = appointmentSlotGroups.filter(asg =>
    state.selectedServiceSpecifications.some(service =>
      !!asg.test_configurations.find(tc => tc.service_specification === service
        || tc.age_grouped_vaccine_service_specification === service)
    ) && !exactMatchAppointmentSlotGroups.includes(asg)
  );

  return (
    <div className={`${isMobile() ? '' : 'mx-5'}`}>
      <h2 className="my-4 poppins semibold">
        {t('family_registrations.find_clinic')}
      </h2>
      <EditClinicSearchParams dispatch={dispatch} state={state} t={t} />
      {isMobile() && (
        <div className="d-flex justify-content-between mb-2 flex-center-vertical">
          <div className="inter bold lead-20-medium">{t('otc.results')}</div>
          <img
            className="pointer my-auto"
            onClick={() =>
              setMobileView(mobileView === 'list' ? 'map' : 'list')
            }
            src={`/images/icons/icon-list-map-switch-${mobileView}.svg`}
          />
        </div>
      )}
      <Row style={isMobile() ? { width: '100%' } : {}}>
        <Col
          xl={5}
          lg={6}
          className={`${isMobile() && mobileView === 'map' && 'd-none'} ${
            state.loading && 'text-center'
          } clinics-list`}
        >
          {state.loading ? (
            <Spinner variant="gray-dark-700" animation="border" />
          ) : (
            <>
              {appointmentSlotGroups.length === 0 && (
                <div>
                  {!isMobile() && <h4 className="my-4">{t('otc.results')}</h4>}
                  <p>{t('family_registrations.no_matches')}</p>
                </div>
              )}
              {exactMatchAppointmentSlotGroups.length > 0 && (
                <div>
                  {exactMatchAppointmentSlotGroups.map((asg, idx) => {
                    return (
                      <LocationCard
                        active={selectedAppointmentSlotGroup?.id === asg.id}
                        appointmentSlotGroup={asg}
                        dispatch={dispatch}
                        key={idx}
                        matchType="exact"
                        showAvailableServices={
                          !state.selectedServiceSpecifications.length
                        }
                        setSelectedAppointmentSlotGroup={
                          setSelectedAppointmentSlotGroup
                        }
                        state={state}
                        t={t}
                      />
                    );
                  })}
                </div>
              )}
              {partialMatchAppointmentSlotGroups.length > 0 && (
                <div>
                  {exactMatchAppointmentSlotGroups.length > 0 && (
                    <hr className="my-5" />
                  )}
                  <div className="d-flex">
                    <img
                      src={selectedPartialPinIcon}
                      height={30}
                      className="me-2 my-auto"
                    />
                    <h4 className="my-4">
                      {t('family_registrations.partial_results')}
                    </h4>
                  </div>
                  <p className="mb-4">
                    {t('family_registrations.partial_matches')}
                  </p>
                  {partialMatchAppointmentSlotGroups.map((asg, idx) => {
                    return (
                      <LocationCard
                        active={selectedAppointmentSlotGroup?.id === asg.id}
                        appointmentSlotGroup={asg}
                        dispatch={dispatch}
                        key={idx}
                        matchType="partial"
                        setSelectedAppointmentSlotGroup={
                          setSelectedAppointmentSlotGroup
                        }
                        state={state}
                        t={t}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </Col>
        <Col
          xl={7}
          lg={6}
          className={`${isMobile() && mobileView === 'list' ? 'd-none' : ''}`}
        >
          {appointmentSlotGroups.length === 0 ? (
            <Card
              className="text-center"
              style={{ backgroundColor: '#F7F9FC' }}
            >
              <div className="my-auto">
                <img
                  src="/images/icons/icon-search-not-found.svg"
                  height={200}
                  className="mt-5"
                />
                <h2 className="mt-5" style={{ maxWidth: '300px', margin: '0 auto' }}>
                  {t('family_registrations.no_clinics_found')}
                </h2>
                <p>{t('family_registrations.broaden_filters')}</p>
              </div>
            </Card>
          ) : (
            <>
              {isMobile() && mobileView === 'map' && (
                <div>{t('family_registrations.select_pin')}</div>
              )}
              <ClinicsMap
                dispatch={dispatch}
                exactMatchAppointmentSlotGroups={
                  exactMatchAppointmentSlotGroups
                }
                markers={[
                  ...exactMatchAppointmentSlotGroups,
                  ...partialMatchAppointmentSlotGroups,
                ]}
                selectedMarker={selectedAppointmentSlotGroup}
                setSelectedMarker={setSelectedAppointmentSlotGroup}
                t={t}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FindClinic;
