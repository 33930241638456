import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MessageMetricsRow = ({
  failedCommunicationPresent,
  firstElement,
  lastElement,
  loading,
  metricsData,
  setShowDeliveryErrorModal,
}) => {
  return (
    <Card
      className="p-3"
      style={{
        borderRadius: firstElement
          ? "4px 4px 0px 0px"
          : lastElement
            ? "0px 0px 4px 4px"
            : "0px"
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div
            className="profile-circle me-2"
            style={{
              backgroundColor: metricsData.icon_color,
              height: "32px",
              width: "32px",
            }}
          >
            <div 
              className="text-nowrap"
              style={{
                fontSize: "8px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              <FontAwesomeIcon
                icon={metricsData.icon}
                style={{ height: "16px" }}
              />
            </div>
          </div>
          <div className="my-auto">{metricsData.label}</div>
        </div>
        <div className="d-flex my-auto">
          {loading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                style={{
                  color: metricsData.icon_color
                }}
              />
            </div>
          ) : (
            <>
              {metricsData.label == "Delivery success" && failedCommunicationPresent && (
                <FontAwesomeIcon
                  className="pointer my-auto me-2"
                  color="#CB2531"
                  icon={faCircleExclamation}
                  onClick={() => setShowDeliveryErrorModal(true)}
                  size="lg"
                />
              )}
              <div className="body-large-16-regular inter bold">{metricsData.value}</div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

const MessageMetrics = ({
  failedCommunicationPresent,
  selectedCommunicationId,
  setShowDeliveryErrorModal,
}) => {

  const [loading, setLoading] = useState(false);
  const [messageImpact, setMessageImpact] = useState(null); // TODO
  const [messageMetrics, setMessageMetrics] = useState([]);

  const fetchMessageMetricsAndImpact = async () => {
    setLoading(true);
    const response = await axios.get(`messages/${selectedCommunicationId}/message_metrics`);
    if (response.status === 200) {
      setMessageImpact(response.data.message_impact);
      setMessageMetrics(response.data.metrics);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchMessageMetricsAndImpact();
  }, [selectedCommunicationId])

  return (
    <>
      {messageImpact && ( 
        <div className="my-5">
          <h5 className="lead-20-medium inter semibold">Message impact</h5>
          <Card
            className="p-3"
            style={{ borderRadius: "4px" }}
          >
            <Row>
              {messageImpact.map(i => {
                return (
                  <Col>
                    <div className="h3-24-semi-bold inter regular">
                      {loading
                        ? <Spinner animation="border" size="sm" />
                        : i.value
                      }
                    </div>
                    <div className="body-medium-bold inter semibold">{i.label}</div>
                  </Col>
                )
              })}
            </Row>
          </Card>
        </div>
      )}

      <div className="my-5">
        <h5 className="lead-20-medium inter semibold">Message metrics</h5>
        <p className="body-medium-normal">
          Metrics are only collected for the first 7 days after message has been sent.
        </p>

        {messageMetrics.map((metricsData, idx) => {
          return (
            <MessageMetricsRow
              failedCommunicationPresent={failedCommunicationPresent}
              firstElement={idx === 0}
              lastElement={idx === messageMetrics.length - 1}
              loading={loading}
              metricsData={metricsData}
              setShowDeliveryErrorModal={setShowDeliveryErrorModal}
            />
          )
        })}
      </div>
    </>
  );
};

export default MessageMetrics;