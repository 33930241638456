import React from 'react';
import '../locales/i18n';
import { useTranslation } from 'react-i18next';
import { IndicatorSeparator } from 'react-select';
import { Row, Col } from "react-bootstrap";
import FloatingLabelSelect from './FloatingLabelSelect';
import FloatingLabelInput from './FloatingLabelInput';
import { isMobile } from '../utils/browser';

const DateOfBirthWithDropdown = ({currentDate, setCurrentDate, errors=false, isDisabled=false, customStyling=true}) => {
  const { t } = useTranslation();
  const { day, month, year } = currentDate;

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const monthOptions = months.map((month, index) => {
    return { value: (index + 1), label: t("date." + month) }
  });

  const limitInput = (e, limit) => {
    Number(e.target.value) && (e.target.value = e.target.value.slice(0,limit))
  }

  return (
    <Row className={customStyling ? "gx-0" : "gx-1"}>
      <Col xs="6" className={`my-2 ${customStyling && "dob-month"}`}>
        <FloatingLabelSelect
          containerClassName="z6 test-dob-month"
          value={monthOptions.filter(option => option.value === month)}
          onChange={(s) => {
            setCurrentDate({...currentDate, month: s.value });
          }}
          ariaLabel="month"
          options={monthOptions}
          components={{ IndicatorSeparator: () => null }}
          label={t("date.month")}
          menuPortalTarget={document.body} 
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isSearchable={!isMobile}
          ariaRequired="true"
          filledValue={month}
          id="month"
          className={errors && 'is-invalid'}
          isDisabled={isDisabled}
        />
      </Col>
      <Col xs="3" className={`my-2 ${customStyling && "dob-day"}`} >
        <FloatingLabelInput
          name="day"
          value={day || ""}
          onChange={(s) => {
            setCurrentDate({...currentDate, day: s.target.value });
          }}
          ariaLabel="day"
          ariaRequired="true"
          id="day"
          className={errors && 'is-invalid'}
          type="number"
          min="1"
          max="31"
          onInput={(e) => { limitInput(e, 2) }}
          autoComplete="bday-day"
          label={t('date.day')}
          validation={errors && 'is-invalid'}
          inputMode="numeric"
          pattern="[0-9]*"
          isDisabled={isDisabled}
        />
      </Col>
      <Col xs="3" className={`my-2 ${customStyling && "dob-year"}`} >
        <FloatingLabelInput
          name="year"
          value={year || ""}
          onChange={(s) => {
            setCurrentDate({...currentDate, year: s.target.value });
          }}
          ariaLabel="year"
          ariaRequired="true"
          id="year"
          className={errors && 'is-invalid'}
          type="number"
          onInput={(e) => { limitInput(e, 4) }}
          autoComplete="bday-year"
          label={t('date.year')}
          validation={errors && 'is-invalid'}
          inputMode="numeric"
          pattern="[0-9]*"
          isDisabled={isDisabled}
        />
      </Col>
    </Row>
  );
};

export default DateOfBirthWithDropdown;
