import React from 'react';

const UserCircle = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="10.9375" stroke={color} strokeWidth="2.125"/>
    <path
      d="M15.2443 8.92293C15.2443 10.7152 13.7914 12.1681 11.9991 12.1681C10.2068 12.1681 8.75391 10.7152 8.75391 8.92293C8.75391 7.13066 10.2068 5.67773 11.9991 5.67773C13.7914 5.67773 15.2443 7.13066 15.2443 8.92293Z"
      stroke={color} 
      strokeWidth="2.125"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70166 22.2158C5.59527 21.7507 5.53906 21.2664 5.53906 20.769C5.53906 17.2004 8.43199 14.3075 12.0006 14.3075C15.5692 14.3075 18.4621 17.2004 18.4621 20.769C18.4621 21.2664 18.406 21.7506 18.2996 22.2156C17.3892 22.7782 16.3979 23.2225 15.3473 23.527C15.9657 22.7775 16.3371 21.8166 16.3371 20.769C16.3371 18.374 14.3956 16.4325 12.0006 16.4325C9.6056 16.4325 7.66406 18.374 7.66406 20.769C7.66406 21.8167 8.03556 22.7775 8.654 23.5271C7.60335 23.2226 6.61209 22.7784 5.70166 22.2158Z"
      fill={color}
    />
  </svg>
);

export default UserCircle;
