export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Mes",
    "day": "Día",
    "year": "Año",
    "january": "Enero",
    "february": "Febrero",
    "march": "Marzo",
    "april": "Abril",
    "may": "Mayo",
    "june": "Junio",
    "july": "Julio",
    "august": "Agosto",
    "september": "Septiembre",
    "october": "Octubre",
    "november": "Noviembre",
    "december": "Diciembre"
  },
  "user": {
    "send_appointment_confirmation_message": "Su cita ha sido confirmada para {{name}}.",
    "landline_appointment_reminder_message": "Hola, {{full_name}}. Le recordamos que tiene una cita para {{name}}. Su cita es el {{date}}, {{time}} , en {{address}}.",
    "send_at_home_visit_confirmation_message": "Su cita ha sido confirmada el día {{date}} a las {{time}}.",
    "send_mail_order_confirmation_message": "Su orden de correo ha sido confirmada",
    "send_waitlist_message": "Se encuentra en lista de espera para {{name}}. No acuda hasta que confirmemos el horario de la cita.",
    "verify_contact": {
      "phone": "Pulse para confirmar su número telefónico: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Continúe el registro de su cita: {{url}}",
    "on_demand_confirmation_message": "Se ha registrado correctamente a {{name}}.",
    "appointment_reminder": "Antes de su cita a la(s) {{start_time}}, ingrese al enlace para completar cualquier acción pendiente y ver su confirmación. No acuda más de 15 minutos antes de su cita.",
    "appointment_reminder_base": "{{text}} PULSE AQUÍ: {{link}}",
    "test_result_notifier": {
      "text_message": "Se ha actualizado el portal del paciente de {{first_name_with_last_initial}}. Ingrese a {{url}} y use el código: {{access_code}}",
      "email_subject": "Nueva actividad en su portal de paciente",
      "email_intro": "Hola, {{first_name_with_last_initial}}:",
      "email_p1": "Tiene un nuevo mensaje o actualización de paciente.",
      "email_p2": "Haga clic en el botón a continuación o utilice el enlace para ver su portal de paciente e ingrese el código",
      "email_p3": "Ver el portal del paciente en:",
      "email_button": "Ver el portal"
    },
    "landline_appointment_reminder_message_without_time": "Hola, {{full_name}}. Le recordamos que tiene una cita para {{name}}. Su cita es el {{date}} en {{address}}.",
    "appointment_reminder_on_demand": "Antes de su cita el {{date}}, ingrese al enlace para completar cualquier acción pendiente y ver su confirmación.",
    "new_test_result_notifier": {
      "email_intro": "Se ha actualizado su portal de paciente.",
      "email_text_1": "Visite su portal de Historia Médica a continuación para ver los expedientes médicos o informes de laboratorio nuevos.  ",
      "email_button": "Historia médica",
      "email_text_2": "También puede copiar y pegar el siguiente URL en su navegador:"
    },
    "mailers": {
      "email_contact_us": "¿Tiene una pregunta? Contáctenos en"
    }
  },
  "errors": {
    "generic": {
      "message": "Algo salió mal, intente de nuevo"
    },
    "messages": {
      "blank": "no puede quedar vacío"
    },
    "incorrect_credentials": "Credenciales incorrectas, inténtalo de nuevo",
    "error_activating_test_kit": "Hubo un error al activar el kit de prueba"
  },
  "type": "Escriba",
  "payment": {
    "continue_label": "Realizar el pago",
    "policy_text": "Si cancela o no se presenta a la cita, se le devolverá el 80% del pago, 7 días después de la fecha de cita. Vea los [Terms of Service]({{link}})",
    "card_number": "Número de tarjeta",
    "expiry_date": "Fecha de vencimiento",
    "pay_button": "Pago",
    "no_credit_card": "Pagaré en el lugar",
    "cash_payment_helper": "Recuerde llevar **{{payment}}** a la consulta",
    "invoice": "Factura",
    "pay_by_card": "Pago con tarjeta",
    "cost": "Costo",
    "total": "Total",
    "pay_cash_at_appointment": "Pagaré con tarjeta durante la consulta en {{appointment}}",
    "view_invoice": "Ver/imprimir factura",
    "refund_policy": "Si cancelas tu cita, te reembolsaremos {{amount}} 7 días después de la fecha de tu cita. Puedes leer los términos de servicio completos {{link}}",
    "refund_window_passed": "Su cita no es elegible para un reembolso ya que ha pasado la fecha de la cita. Por favor contáctate directamente con los coordinadores del sitio para tener una resolución",
    "amount_paid": "Monto pagado",
    "balance": "Saldo"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Cargue el consentimiento de exención o firme en el campo de firma debajo",
    "stop": "Se requiere consentimiento para completar el registro",
    "parent_or_guardian_required_html": "Para finalizar su registro, su madre/padre, tutor/a, o representante autorizado debe dar su consentimiento en el formulario provisto debajo y firmar con su nombre. Usted <b>NO PUEDE</b> acordar a este servicio por iniciativa propia.",
    "send_parent_or_guardian": "Puede enviar a su madre/padre o tutor/a este enlace o pedir que firmen en la parte inferior de esta página.",
    "consent_guardian_name_signature": "Nombre",
    "consent_for_minor": "Consentimiento firmado en nombre del menor",
    "guardian_signature": "Firma de la madre/padre, tutor, o representante médico autorizado",
    "after_you_schedule": "Participantes menores de {{age}} años deben presentar consentimiento firmado de su madre/padre o tutor/a. Continúe hacia el final del registro para enviarlo a su tutor/a o firme por su dependiente.",
    "consent_guardian_last_name_signature": "Apellido",
    "consent_first_and_last_name": "Nombre y apellido",
    "phone_number": "Número de teléfono del tutor",
    "email": "Correo electrónico del tutor",
    "consent_registrar_with_guardian_name_signature": "Primer nombre del tutor(a)",
    "consent_registrar_with_guardian_last_name_signature": "Apellidos del tutor(a)",
    "consent_registrar_first_name_signature": "Primer nombre del registrador",
    "consent_registrar_last_name_signature": "Apellidos del registrador",
    "consent_on_behalf": "Estoy firmando en nombre de {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "He sido autorizado verbalmente por {{first_name}} {{last_name}} para firmar en su nombre.",
    "consent_on_behalf_registrar_with_guardian": "He sido autorizado verbalmente por el tutor(a) de {{first_name}} {{last_name}} para firmar en su nombre.",
    "registrar_phone": "Número telefónico de quien se registra ",
    "registrar_email": "Correo electrónico de quien se registra",
    "consented_by_parent_guardian": "Padre / Tutor",
    "consented_by_decision_maker": "Responsable médico autorizado",
    "consented_by_guardian_verbal": "Registrado con tutor (consentimiento verbal obtenido)",
    "consented_by_registrar": "Registrado (consentimiento verbal obtenido)",
    "full_name": "Nombre completo",
    "print": "Imprimir"
  },
  "registration": {
    "contact_information": "Nombre y dirección",
    "contact_information_additional": "Información de contacto de la madre/padre o tutor/a para comunicaciones sobre la cita y los resultados",
    "personal_information": "Información demográfica",
    "address_placeholder": "Ingrese su dirección de residencia",
    "first_name_label": "Nombre",
    "last_name_label": "Apellido",
    "errors": {
      "state": "Ingrese un código de Estado de 2 caracteres válido",
      "phone_number_invalid": "Número de teléfono inválido",
      "required": "Este campo es obligatorio",
      "email_domain": "La información ingresada es inválida, revisar. Registro limitado a participantes seleccionadas por la organización patrocinadora. Inténtelo con su email de trabajo o de la comunidad. Si cree que es elegible y se trata de un error, contáctese con el servicio de asistencia.",
      "age_requirement": "La fecha de nacimiento no cumple con los requisitos de edad",
      "signature_required": "La firma es obligatoria",
      "regex_invalid": "Esta información parece ser inválida",
      "date_invalid": "La fecha no existe",
      "invalid_entry": "No continúe con el registro. Siga las instrucciones en pantalla o comuníquese con su punto de contacto",
      "city": "La ciudad debe ser válida",
      "survey_unanswered": "Verifique que todas las preguntas obligatorias marcadas con * hayan sido respondidas.",
      "postal_code": "Debe ser un código postal válido de 5 dígitos",
      "field_required": "{{field}} debe tener un valor",
      "option_required": "Debe escoger una de las opciones",
      "year_length_invalid": "Introduzca la fecha de nacimiento con un año de cuatro dígitos (aaaa)",
      "invalid_day": "Día entre el 1 y el 31",
      "reached_max_chars": "Alcanzó el número de caracteres límite.",
      "chars_max": "Número máximo de caracteres",
      "minimum_length": "La entrada debe contener al menos {{length}} caracteres."
    },
    "employment_information": {
      "label": "Empleo",
      "address_1": "Dirección de la compañía",
      "address_2": "Ej.: Suite 200",
      "phone_number": "Número telefónico de la compañía",
      "company": "Nombre de la compañía",
      "postal_code": "Código postal del empleador",
      "employed": "Empleado",
      "sole_prorietor": "Empresario individual",
      "not_employed": "Desempleado",
      "reporting_supervisor": "Supervisor de informes",
      "reporting_department": "Departamento de informes",
      "supervisor_name": "Nombre del supervisor",
      "supervisor_email": "Email del supervisor"
    },
    "insurance_status": {
      "question": "¿Tiene seguro médico?",
      "have_health_insurance": "Sí, tengo seguro médico",
      "do_not_have_health_insurance": "No, no tengo seguro médico"
    },
    "insurance_policy_holder": {
      "question": "¿Quién es la persona titular del seguro?",
      "i_am": "Soy yo",
      "my_spouse": "Mi cónyuge o pareja",
      "my_parents": "Mis padres",
      "someone_else": "Otra persona",
      "policy_first_name": "Nombre del titular del seguro",
      "policy_last_name": "Apellido del titular del seguro",
      "policy_dob": "Fecha de nacimiento del titular del seguro",
      "name": "Nombre del titular del seguro"
    },
    "insurance_information": {
      "title": "Información del seguro",
      "company_name": "Nombre de la compañía de seguros",
      "id_number": "Número de afiliado",
      "group_number": "Número de plan grupal",
      "secondary_insurance_label": "Tengo seguro de salud secundario",
      "take_photo": "Por favor tome una foto de su tarjeta de seguro médico. Tomando la foto llenará partes del formulario.",
      "front_of_card": "El anverso de su tarjeta de seguro",
      "card_information": "Información de la tarjeta de seguro",
      "back_of_card": "Back of card"
    },
    "location_availability": "Actualmente, se prioriza el testeo de personas de la comunidad que viven en estas áreas",
    "custom_survey": "Cuestionario",
    "confirmation": "Revisar",
    "waitlisted": "En lista de espera",
    "schedule_your_appointment": "Programar cita",
    "information": "Información",
    "consent": "Consentimiento",
    "location": "Ubicación",
    "symptoms": "Síntomas",
    "address_required": "Ingrese su dirección",
    "consent_required": "Consienta para continuar",
    "required_field": "complete el campo requerido",
    "phone_number": "Número de teléfono móvil",
    "email": "Correo electrónico",
    "date_of_birth": "Fecha de nacimiento",
    "minimum_age": "La edad mínima para participar es {{year}} años.",
    "no_minimum_age": "No hay edad mínima para el testeo",
    "continue_button": "Continuar",
    "email_required": "Este campo debe contener un email válido.",
    "done": "Hecho",
    "signature": "Firma",
    "clear_button": "Borrar",
    "back_button": "Volver",
    "choose_location": "Seleccione una ubicación",
    "no_slots": "No hay más lugares disponibles en {{location}}",
    "choose_appointment": "Seleccione el horario de la cita en {{location}}",
    "appointment_required": "Seleccione el horario de la cita",
    "phone_number_required": "Número de teléfono inválido",
    "phone_number_label": "Ingrese un número telefónico que reciba mensajes de texto para recibir los resultados más rápido",
    "symptoms_experiencing": "Seleccione los síntomas que actualmente presenta. Si no presenta ningún síntoma, continúe.",
    "household": "Miembros de la familia",
    "household_p1": "Opcional, crear cita para miembros de la familia que necesitan ser testeados.",
    "add_dependent": "Añadir miembro de la familia",
    "remove_dependent": "Quitar dependiente",
    "dependent_consents": "Consiente",
    "submit": "Completo",
    "add_waitlist": "Añadir a lista de espera",
    "address": "Dirección de residencia",
    "address_1": "Dirección Línea 1",
    "address_2": "Dpt/piso #",
    "address_city": "Ciudad",
    "address_state": "Estado",
    "postal_code": "Código postal",
    "race_ethnicity": "Raza",
    "gender": "Identidad de género",
    "self_described_gender": "Género autopercibido",
    "interpreter": "¿Necesita un intérprete? Si lo necesita, ¿para qué idioma?",
    "consent_to_terms": "Acepto estos términos.",
    "reg_not_open": "El proceso de registro no está abierto.",
    "no_more_avail_spots": "El horario seleccionado no está disponible. Intente de nuevo.",
    "consent_helper": "Oprima y arrastre el cursor o utilice el dedo para firmar en el cuadro debajo",
    "phone_number_unreachable_label": "¿Teléfono de casa?",
    "select": "Seleccionar",
    "test_surveys": "Preguntas sobre la cita",
    "edit": "editar",
    "continue_to_registration": "Continúe con el registro",
    "accounts": {
      "already_have_an_account": "¿Ya tiene una cuenta?",
      "log_in": "Ingreso",
      "sign_up": "Registrarse",
      "sign_up_description": "Ingrese su información para crear una cuenta y ahorre tiempo en futuros registros",
      "log_in_description": "Escriba su email y contraseña o ingrese con Google u Outlook",
      "sign_in_with_label": "Ingrese con {{provider}}",
      "password_must_match": "Las contraseñas deben coincidir",
      "password_uppercase": "La contraseña debe tener al menos (%s) letra mayúscula.",
      "password_lowercase": "La contraseña debe tener al menos (%s) letra minúscula.",
      "password_number": "La contraseña debe tener al menos (%s) número.",
      "password_special": "La contraseña debe tener al menos (%s) caracteres especiales."
    },
    "password": "Contraseña",
    "password_confirmation": "Confirmación de contraseña",
    "consent_for": "Consentimiento para {{name}}",
    "book_one_time_appointment": "Reservar una consulta única",
    "duplicate_users": {
      "exists": "Usted ya está registrado",
      "overlapping_email_and_phone_p1": "Enviamos un mensaje a su número de teléfono y dirección de email.",
      "p2": "Usando este mensaje, podrá modificar su cita e ingresar los resultados del test.",
      "overlapping_phone_and_email_p3": "Revise su email y teléfono para más detalles.",
      "overlapping_email_p3": "Revise su email para más detalles.",
      "overlapping_phone_p3": "Revise su teléfono para más detalles.",
      "overlapping_phone_p1": "Enviamos un mensaje a su número de teléfono.",
      "overlapping_email_p1": "Enviamos un mensaje a su dirección de email.",
      "p4": "Si cree que se trata de un error, escríbanos a support@primary.health",
      "overlapping_email_and_phone_p3": "Revise su email y teléfono para más detalles."
    },
    "duplicate_waitlist": {
      "exists": "Ya se encuentra en lista de espera",
      "overlapping_email_and_phone_p1": "Otra confirmación ha sido enviada a su número de teléfono y dirección de email.",
      "p2": "Usando su confirmación, podrá añadir información adicional o quitarse de la lista de espera.",
      "overlapping_phone_and_email_p3": "Revise su email o teléfono para más detalles.",
      "overlapping_email_p3": "Revise su email para más detalles.",
      "overlapping_phone_p3": "Revise su teléfono para más detalles.",
      "overlapping_email_p1": "Enviamos otra confirmación a su email."
    },
    "insist_guardian_consent": "Consiento en nombre de",
    "confirmation_section": {
      "title": "Revise y confirme",
      "p1": "Revise su información antes de completar el registro.",
      "edit_information": "Vuelva y edite"
    },
    "iemodal": {
      "title": "Navegador no compatible",
      "body": "El registro puede realizarse en estos navegadores"
    },
    "show_other_locations": "Pulse para mostrar otras ubicaciones",
    "non_us_address": "Esta es una dirección internacional",
    "test_group_user_survey": "Selección de participantes",
    "self_consent": "Doy consentimiento por mi persona",
    "address_country": "País",
    "appointment": "Cita",
    "employee_id": "ID del empleado",
    "appointment_time": "Hora de la cita",
    "appointment_location": "Ubicación",
    "phone_or_email": "Número de teléfono o correo electrónico",
    "no_self_consent": "No doy mi consentimiento ",
    "no_insist_guardian_consent": "No doy el consentimiento en nombre de {{full_name}}",
    "additional_consents_helpertext": "Puede que se le pida su consentimiento luego ",
    "minimum_age_with_months": "La edad mínima para participar es {{year}} años y {{month}} meses.",
    "assistive_technology": "Tecnología asistiva ",
    "covid_vaccine_y_n": "¿Te gustaría tener una vacuna contra el COVID-19 con esta reserva?",
    "received_covid_vaccine_y_n": "¿Había sido vacunado contra el COVID-19 antes?",
    "covid_vaccinations": "Vacunas de la COVID-19",
    "select_vaccine_dose": "Por favor, selecciona la dosis de la vacuna que te gustaría recibir",
    "demographic_info": "Información demográfica",
    "additional_info": "Información adicional",
    "self_described_race": "Raza autodescrita",
    "verify_contact_information": "Verificación",
    "verify_contact": {
      "we_sent_you_a_code": "Por favor ingrese el código enviado a {{contact}}",
      "confirmation_code": "Código de confirmación",
      "code_resent": {
        "email": "Se ha enviado otro código a su correo electrónico ",
        "phone_number": "Se ha enviado otro código a su teléfono"
      },
      "did_not_receive_code": "¿No recibió el código de confirmación?",
      "verify_by": {
        "email": "Verificar a través de correo electrónico",
        "phone_number": "Verificar a través de teléfono"
      }
    },
    "preferred_method_of_communication": {
      "question": "Método de comunicación de preferencia",
      "helper_text": "Nos pondremos en contacto para informarle de las citas",
      "phone_number": "Teléfono (Pueden aplicarse cargos por mensajería y datos móviles)",
      "landline": "En este momento no podemos atender líneas telefónicas. Proporcione un correo electrónico para comunicaciones importantes",
      "verification_method": "¿Cómo quiere recibir su código de autenticación?",
      "verification_helper_text": "Se le enviará un código para verificar su identidad antes de iniciar sesión."
    },
    "skip_for_now": "Omitir por ahora",
    "default_information": {
      "title": "De acuerdo, usaremos esta dirección",
      "title_v2": "De acuerdo, usaremos esta información de contacto"
    },
    "middle_name_label": "Segundo nombre",
    "confirm_appointment": "Confirmar cita",
    "dob": "DOB",
    "different_appointment": "Cita diferente",
    "select_appointment_time": "Seleccionar hora",
    "decline_address": "Rechazo ingresar una dirección. Por favor, utilice la dirección del programa.",
    "patient_information": "Informacíon de la paciente",
    "scan_license": "Escanear licencia de conducir",
    "how_to_contact": "Cómo debemos contactarle?",
    "decline_email_or_phone": "Rechazo ingresar mi correo o número detelefono. Autorizo a los administradores del programa a recibir mis resultados.",
    "medical_screening": "Chequeo médico",
    "show_service_at_this_location": "Ver servicios para este sitio",
    "verbal_consent_provided": "De acuerdo con la Ley de Estadounidenses con Discapacidades, el participante o su tomador de decisiones legalmente autorizado proporcionó consentimiento verbal. Se obtendrá el consentimiento escrito del participante o de su tomador de decisiones legalmente autorizado en un plazo de 24 horas.",
    "unexpired_written_consent_present": "Este sitio tiene un consentimiento válido.",
    "select_to_proceed": "Por favor seleccione una opción para continuar.",
    "guardians_information": "Información del tutor",
    "registrars_information": "Información del registrador",
    "optional": "opcional",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Hombre",
    "female": "Mujer",
    "cisgender": "Cisgénero",
    "non_binary": "Cuirgénero o género no binario",
    "other": "Otra",
    "prefer_to_self_describe": "Identidad no listada",
    "prefer_not_to_disclose": "Prefiero no responder",
    "transgender_male": "Hombre transgénero/ hombre trans",
    "transgender_female": "Mujer transgénero/ mujer trans",
    "unknown": "Desconocido",
    "non_binary_only": "No binario",
    "intersex": "Intersexual",
    "transgender": "Transgénero",
    "gender_fluid": "Género fluido",
    "not_applicable": "No aplicable"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Indígena de las Américas o nativa de Alaska",
    "american_indian_central_america": "Indígena de las América (América del Sur y Central)",
    "asian": "Asiática",
    "black": "Negra o afroamericana",
    "latinx": "Latina, latinx o hispánica",
    "middle_eastern_north_african": "Oriente Medio o África del Norte",
    "native_hawaiian_pacific_islander": "Natural de Hawái u otra isla del Pacífico",
    "white_european": "Blanca o caucásica",
    "unknown": "Desconocida",
    "prefer_not_to_disclose": "Prefiero no responder",
    "asian_indian": "India asiática",
    "filipino": "Filipina",
    "japanese": "Japonesa",
    "korean": "Coreana",
    "vietnamese": "Vietnamita",
    "other_asian": "Otro origen asiático",
    "native_hawaiian": "Natural de Hawái",
    "guamanian_or_chamorro": "Guameña o chamorra",
    "samoan": "Samoana",
    "other_pacific_islander": "Natural de otras islas del pacífico",
    "chinese": "China",
    "help": "La palabra \"raza\" se refiere a un conjunto de características usadas para diferencias grupos de personas. La raza puede o no coincidir con la nacionalidad o el país del cual posee ciudadanía.",
    "subtitle": "La reglamentación requiere que recopilemos la siguiente información.",
    "laotian": "Laosiana",
    "cambodian": "Camboyana",
    "other": "Otra",
    "bangladeshi": "Bangladesí",
    "hmong": "Hmong",
    "indonesian": "Indonesio",
    "malaysian": "Malasio",
    "pakistani": "Paquistaní",
    "sri_lankan": "Ceilanés",
    "thai": "Tailandés",
    "taiwanese": "Taiwanes",
    "fijian": "Fiyiano",
    "guamanian": "Guameño",
    "tongan": "Tongano"
  },
  "languages": {
    "en": "Inglés",
    "fr": "Francés",
    "es": "Español",
    "so": "Somalí",
    "hmn": "Lengua hmong",
    "asl": "Lengua de signos americana (ASL)",
    "kar": "Lengua karénica",
    "am": "Amárico",
    "ru": "Ruso",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Fiebre o escalofríos",
    "cough": "Tos",
    "muscle_aches": "Dolor muscular",
    "severe_fatigue": "Fatiga severa (más de la usual)",
    "trouble_breathing": "Problemas para respirar",
    "diarrhea": "Diarrea",
    "loss_of_smell": "Pérdida del olfato",
    "loss_of_taste": "Pérdida del gusto",
    "shortness_of_breath": "Falta de aliento o dificultad para respirar",
    "headache": "Dolor de garganta",
    "sore_throat": "Dolor de cabeza",
    "congestion": "Congestión o secreción nasal",
    "nausea": "Nausea o vómito",
    "close_contact": "Contacto estrecho con una persona infectada*",
    "helper_text": {
      "close_contact": "*Ha tenido contacto estrecho (a 6 pies/1,8 metros de distancia por al menos 15 minutos) con alguien positivo para COVID-19?"
    },
    "suspected_exposure": "Se sospecha exposición",
    "none": "Sin síntomas"
  },
  "instructions": {
    "title": "Se encuentra registrado pero antes de irse...",
    "p1": "Esta página contiene información sobre su cita y el código de barras de su cita.",
    "appointment_scheduled": "Su cita se encuentra programada.",
    "verify_info": {
      "title": "Verifique su información",
      "p1": "Es importante validar su información de contacto para que pueda acceder rápidamente a su portal de pacientes.",
      "p2": "Una confirmación ha sido enviada a su e-mail. Pulse el enlace provisto.",
      "p3": "Si necesita cambiar su información de contacto, presione el botón de ayuda."
    },
    "verified": "Verificado",
    "not_verified": "No verificado",
    "resend_text": "¿No recibió el enlace?",
    "resend": "Reenviar",
    "skip": "Saltar validación y mostrar confirmación",
    "name": "Nombre",
    "appointment_details": "Cita",
    "date_and_time": "Fecha y hora",
    "add_to_calendar": "Añadir a calendario",
    "instructions": "Instrucciones generales",
    "successfully_flash": "¡Su cita ha sido creada!",
    "success_flash_email": "Email verificado con éxito",
    "success_flash_phone": "Número telefónico verificado con éxito",
    "mail_order": "Durante la semana recibirá por correo su kit para testeo.",
    "at_home": "Un prestador médico irá a su casa alrededor de esta fecha",
    "at_home_instructions": "Instrucciones a seguir en casa",
    "mail_order_instructions": "Instrucciones para envío por correo",
    "request_additional_appointment": "Reservar cita de seguimiento",
    "book_additional_appointment": "Reservar otra cita",
    "confirmation_code": "Código de confirmación",
    "completed": "Completado",
    "appointment_barcode": "Código de barras de la cita",
    "dependent_links": "Enlaces para cita de miembros de la familia",
    "on_demand_title": "Se encuentra registrado pero antes de irse...",
    "save_link": "Guarde este enlace",
    "verify_phone": "Verifique su número de teléfono",
    "verify_phone_description": "Es importante validar su información de contacto para que pueda acceder rápidamente a su historial. Un mensaje de texto de verificación ha sido enviado a su número.",
    "verify_email_description": "Es importante validar su información de contacto para que pueda acceder rápidamente a su historial. Un mensaje de verificación ha sido enviado a su dirección de email.",
    "information": "Información de registro",
    "follow_up_appointment": "Cita de seguimiento",
    "get_directions": "Obtener indicaciones",
    "cancel": "Cancelar cita",
    "reschedule_appointment": "Reprogramar cita",
    "reschedule_linked_appointments": "Reprogramar citas",
    "no_slots": "No quedan horarios disponibles",
    "check_results": "Compruebe el portal de pacientes",
    "follow_up_modal_header": "Programe su segunda dosis",
    "are_you_sure_you_want_to_cancel": "¿Confirma que quiere cancelar esta cita?",
    "please_choose_cancellation_reason": "Seleccione una razón de cancelación",
    "additional_details": "Agregue debajo cualquier información adicional",
    "errors": {
      "missing_cancellation_reason": "Seleccione una razón de cancelación"
    },
    "verify_email": "Verifique su email",
    "redcap_url": "Pulse para completar la encuesta",
    "verify_contact_information": "Verifique su información de contacto",
    "please_also": "Por favor, también",
    "new_title": "Confirmación para {{name}}",
    "contact_method": "Método de contacto",
    "next_steps": {
      "title": "Próximos pasos",
      "p1": "Tiene un kit de prueba para activar. Cuando esté listo para realizar la prueba, siga el enlace enviado a su correo electrónico para volver a esta página y activar su kit a continuación.",
      "administered": "Nos pondremos en contacto con usted cuando su kit llegue al laboratorio y sus resultados estén listos. Los resultados suelen tardar entre 7 y 14 días luego de llegar al laboratorio. Puede ver los resultados en su [Historial médico]({{link}}) cuando estén listos.",
      "resulted": "Los resultados están disponibles en su [Historial Médico]({{link}}).",
      "shipping_information": "Información de envío"
    },
    "save_this_page": {
      "title": "Guardar esta página",
      "p1": "Utilice esta página para reportar una autoexamen o ver los resultados a través del historial médico.",
      "p2": "Para acceder a esta página en el futuro, haga clic en el enlace de su correo electrónico o SMS de confirmación.",
      "p3": "Puede guardar esta página marcándola como favorita, añadiéndola a la pantalla de inicio o copiando el enlace en un lugar seguro."
    },
    "show_my_barcode": "Mostrar mi código de barras",
    "my_account": "Mi cuenta",
    "register_another": "Registrar otro participante",
    "update_vaccine": "Actualizar información sobre vacunas",
    "medical_history": "Historial médico",
    "upcoming_appointments": "Próximas citas",
    "reschedule": "Reagendar",
    "resend_confirmation": "Reenviar confirmacíon",
    "appointment_details_v2": "Detalles de la cita",
    "confirm_cancellation": "Confirmar la cancelación",
    "confirm_cancellation_question": "Está seguro que quiere cancelar la cita?",
    "select_new_appointment_time_below": "Seleccione una nueva cita. Si necesita modificar los servicios o el lugar de la cita, por favor cancele esta cita y programe una nueva.",
    "no_take_me_back": "No, regresar",
    "yes_cancel_appointment": "Si, cancela la cita",
    "update_appointment": "Actualizar cita",
    "select_new_appointment_time": "Por favor, seleccione una nueva cita.",
    "clinic": "Clínica",
    "services": "Servicios",
    "appointment_missed": "Cita perdida",
    "appointment_canceled": "Cita cancelada"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Código de acceso inválido"
    },
    "landing": {
      "p1": "Para ver sus resultados ingrese:",
      "access_code_label": "Código de acceso",
      "submit_button": "Enviar",
      "recent_results": "Resultados recientes"
    },
    "view_results": {
      "headline": "Resultados del test de {{name}}",
      "reregister": "Registrarse de nuevo",
      "status": {
        "likely_positive": "Resultados indeterminados",
        "test_not_performed": "Se requiere repetir test",
        "results_ready": "Resultados listos",
        "processing": "Procesando",
        "test_status": "Estado del test",
        "test_result": "Resultados del test",
        "administered": "Administrado",
        "results_pending": "Resultados pendientes",
        "test_results": "Resultados de pruebas"
      },
      "at": "En {{address}}"
    },
    "result_label": "Resultados",
    "result": {
      "flu_a": {
        "result_label": "Resultado para Gripe A"
      },
      "flu_b": {
        "result_label": "Resultado para Gripe B"
      },
      "covid19": {
        "result_label": "Resultado para COVID-19"
      },
      "covid": {
        "result_label": "Resultado para COVID-19"
      },
      "sars": {
        "result_label": "Resultado para SARS"
      },
      "control": {
        "result_label": "Control"
      },
      "rsv": {
        "result_label": "Resultado para RSV"
      },
      "result": {
        "result_label": "Resultado"
      },
      "hba1c": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "lead_venous": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "tc": {
        "result_label": "Resultado CT"
      },
      "hdl": {
        "result_label": "Resultado HDL"
      },
      "trg": {
        "result_label": "Resultado TRG"
      },
      "ldl": {
        "result_label": "Resultado LDL"
      },
      "non_hdl": {
        "result_label": "Resultado Colesterol no-HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "Proporción TC/HDL "
      },
      "glu": {
        "result_label": "Resultado Glucosa"
      },
      "alere_cholestech_ldx": {
        "danger": "En riesgo",
        "warning": "Anormal",
        "normal": "Normal"
      },
      "lead": {
        "result_label": "Plomo"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "creatinine": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "Normal",
        "warning": "Elevado",
        "danger": "Alto",
        "hypoglycemia": "Hypoglycemia",
        "prediabetes": "Prediabetes",
        "diabetes": "Diabetes"
      },
      "total_cholesterol_fasted": {
        "normal": "Normal",
        "elevated": "Elevado",
        "high": "Alto",
        "low": "Bajo",
        "abnormal": "Anormal"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normal",
        "elevated": "Elevado",
        "high": "Alto"
      },
      "a1c_now": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      },
      "blood_glucose": {
        "hypoglycemia": "Hypoglycemia",
        "medical_emergency": "Emergencia médica",
        "inconclusive_1": "Inconcluso 1",
        "inconclusive_2": "Inconcluso 2",
        "warning": "Bajo",
        "normal": "Normal",
        "prediabetes": "Prediabetes",
        "danger": "Diabetes",
        "possible_diabetes": "Posible diabetes"
      },
      "blood_glucose_ucsf": {
        "low": "Bajo",
        "normal": "Normal",
        "high": "Alto"
      }
    },
    "blood_glucose": {
      "warning": "Bajo",
      "normal": "Normal",
      "prediabetes": "Prediabetes",
      "danger": "Diabetes"
    },
    "documents": "Documentos",
    "self_administered": "Se retiró el autotest en {{location}}",
    "patient": "Paciente",
    "medical_history": "Historia Médica",
    "overview_title": "Seleccione una prueba o servicio para ver más detalles y cualquier acción adicional necesaria. Tenga en cuenta que algunos resultados pueden no estar disponibles aún o se habilitarán luego de hablar con un proveedor.",
    "insurance_information": "Complete la información del seguro",
    "contact_support": "Si requiere asistencia, o quiere cambiar su información de contacto, por favor comuníquese con soporte.",
    "show": "Mostrar",
    "hide": "Ocultar",
    "lab_report": "Informe de laboratorio",
    "contact_provider": {
      "header": "Resultados positivos",
      "description": "¿Deseas hablar con un proveedor para discutir tus resultados y tratamiento?",
      "yes_option_text": "Sí, quiero hablar con un proveedor.",
      "no_option_text": "No, he leído y entiendo mis resultados, y no quiero hablar con un proveedor.",
      "confirm_phone_number_header": "Confirma tu número de teléfono.",
      "confirm_phone_number_description": "Por favor confirma el mejor número de teléfono para tu consulta.",
      "confirm_button_text": "Confirma",
      "consultation_confirmed_header": "Cita confirmada",
      "consultation_confirmed_description": "Un proveedor te llamará al {{phone_number}} dentro de 2-3 días hábiles.",
      "acknowledgement_option_helper_text": "Algo que explique la importancia del tratamiento y permita al usuario saber cómo programar una cita si cambia de opinión.",
      "acknowledgement_confirmed_header": "Confirmación de reconocimiento",
      "acknowledgement_confirmed_description": "Algo sobre la importancia de recibir tratamiento con recursos enlazados. Recordatorio de que la consulta es totalmente gratuita y el proveedor puede recetar o hacer cualquier otra cosa que les ayude a resolver la infección.",
      "acknowledgement_confirmed_change_mind_text": "Si cambias de opinión, simplemente haz clic en “Me gustaría una consulta” a continuación.",
      "request_consultation_button_text": "Me gustaría una consulta"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Su dirección no cumple los requisitos de elegibilidad de ubicación de este programa."
    }
  },
  "member": {
    "medical_history": "Historial",
    "view_instructions": "Ver confirmación",
    "next_appointment": "Siguiente cita",
    "over_18": "Certifico que soy mayor de 18 años",
    "choose_test_configurations_title": "Seleccione el/los servicio(s) a recibir en su cita",
    "member_taken_error": "Usuario ya tiene una cuenta",
    "choose_test_configurations_subtitle": "El/los servicio(s) se aplicarán a todos los miembros de la familia",
    "service": "Servicio",
    "group_or_location": "Grupo/Ubicación",
    "details": "Detalles",
    "see_all": "Ver todo",
    "no_history": "El historial no está disponible en este momento.",
    "medical_history_title_with_name": "Historia de {{name}}",
    "no_dashboard": "No hay nada disponible en este momento",
    "product": "Producto",
    "price": "Precio",
    "quantity": "Cantidad",
    "total_services_selected": "Total de servicios seleccionados",
    "total_price": "Precio total"
  },
  "or": "o",
  "account": {
    "errors": {
      "must_be_13": "Debe ser mayor de 13 años para crear una cuenta"
    }
  },
  "self_administration": {
    "title": "Autoadministración",
    "self_administer_action": "Autoadministrador",
    "skip_to_link": "Avanzar hacia Autoadministración",
    "select_person": "Seleccione la persona a comprobar",
    "adult": "adulto",
    "child": "niño",
    "checked_out": "COMPROBADO",
    "go_back": "Regresar",
    "switch_to_scanner": "Cambiar a escáner",
    "enter_barcode": "Ingrese el código de barras",
    "scan_barcode": "Escanee el código de barras",
    "cancel": "Cancelar",
    "barcode_for": "Código de barras para",
    "enter_barcode_manually": "Ingresar código de barras manualmente",
    "instructions": "Escanee o ingrese el código de barras que se encuentra debajo del tubo de ensayo",
    "regex_prefix": "El código de barras debe tener",
    "all_completed": "Completado: todos los miembros del hogar deben haber completado este paso",
    "errors": {
      "no_appointment": "Hubo un problema al buscar su cita",
      "barcode_in_use": "Ya se ha utilizado este código de barras. Si usted no se aplicó el kit por favor hable con el personal que le dio el kit y solicite uno nuevo. De lo contrario, clíquee el botón de ayuda que se encuentra debajo",
      "test_error_general": "Hubo un problema al crear su testeo",
      "different_barcodes": "El código de barras ingresado no coincide"
    },
    "confirm_barcode_input": "Ingrese su código de barras otra vez para confirmar",
    "click_to_self_test": "Haga click aquí para autotestearse"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Lista de espera",
      "unavailable": "No hay citas disponibles"
    },
    "labels": {
      "address": "Dirección",
      "next_appointment": "Próxima cita disponible",
      "services": "Servicios disponibles"
    }
  },
  "waiting_room": {
    "header": "Se encuentra en sala de espera, espere a que le conectemos con el sistema de turnos.",
    "p1": "Por favor, sea paciente. Juntos le pondremos fin a esta pandemia.",
    "signature": "Atentamente",
    "p2": "Sabemos que la situación con la COVID-19 puede ser frustrante. Espere aquí mientras trabajamos lo más rápido posible para que ingrese y reserve su cita."
  },
  "users": {
    "send_appointment_confirmation_message": "Hola, {{full_name}}. Le recordamos que tiene una cita para {{name}}. Su cita es el {{date}}, {{time}}, en {{address}}.",
    "send_registration_link": "Hola, {{full_name}}. Regístrese para {{name}} mediante este enlace {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Primera dosis",
    "previous_appointment": "Cita previa",
    "booked_appointment": "Su cita",
    "second_dose": "Segunda dosis",
    "choose_second_location": "Elija la ubicación para la segunda cita"
  },
  "cancellation_types": {
    "cant_get_to_location": "Tuve un problema con el horario",
    "timing_conflict": "Me enfermé de COVID-19",
    "became_ill_with_covid19": "Recibí este servicio en otra parte",
    "received_service_elsewhere": "Otra",
    "other": "También puede escribir su nombre",
    "duplicate_appointment": "Cita duplicada"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Escriba su nombre ",
      "type_name": "Digitar firma"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Su código de acceso es",
      "please_confirm_your_email": "Para confirmar su email pulse el botón debajo",
      "hello": "Hola",
      "click_to_reschedule": "Pulse para ver instrucciones/reprogramar",
      "click_to_reschedule_on_demand": "Pulse para ver instrucciones"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Su cita a las {{time}} en {{location}} ha sido cancelada. Si esto es un error, por favor escriba un correo electrónico a support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Nombre del paciente",
    "date": "Fecha",
    "signature": "Firma del paciente",
    "and_or": "Y/O",
    "guardian_name": "Nombre de la madre/padre o tutor(a)",
    "guardian_signature": "Firma de la madre/padre o tutor(a)"
  },
  "employer_testing": {
    "hello_name": "Hola, {{name}}",
    "results": {
      "negative": "Negativo",
      "positive": "Positivo",
      "did_not_result": "Desconocido",
      "unknown": "Desconocido",
      "invalid": "Inválido",
      "pending": "Pendiente"
    },
    "code_reader": {
      "scan_your_code": "Escanee el código en la tarjeta del test",
      "find_in_top_right_hand_corner": "El código de escaneo de su test es único, se encuentra en la esquina superior derecha del test",
      "center_code": "Centre el código aquí.",
      "already_used_error": "El código de barras de este test ya ha sido usado. Contacte con el proveedor de su kit para obtener ayuda.",
      "click_to_scan": "Pulse para escanear el código QR",
      "scan_new_test_card": "Pulse aquí para escanear una nueva tarjeta de test.",
      "not_working": "¿No funciona?",
      "try_again": "Pulse aquí para intentar de nuevo."
    },
    "continue": "Continuar",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Confirme la fecha de nacimiento del participante para continuar",
      "contact_administrator": "Si la información mostrada es incorrecta, contacte con un administrador para recibir ayuda",
      "error": "La fecha de nacimiento ingresada es incorrecta. Inténtelo de nuevo o contacte con el administrador del sitio para que la actualice."
    },
    "result_entry": {
      "code_is_registered": "Su código está registrado.",
      "take_the_test": "Realice el test",
      "follow_the_instructions": "Siga las instrucciones provistas con el test y cronometre 15 minutos desde que colocó el hisopo nasal en el vial de la tarjeta.",
      "cant_find_instructions": "¿No encuentra las instrucciones?",
      "view_instructions": "Ver las instrucciones de Binax Now",
      "start_timer": "COMIENCE A CRONOMETRAR 15 MINUTOS (OPCIONAL)",
      "submit_within_15": "Consejo: envíe los resultados a los 15 minutos",
      "enter_test_results": "Ingrese el resultado del test",
      "choose_a_result": "Seleccione la opción que describa el resultado de su tarjeta de test",
      "submit": "Enviar",
      "must_log_result_and_photo": "Debe ingresar un resultado y una fotografía del test para continuar",
      "submit_within_15_v2": "Consejo: Envíe sus resultados cuando hayan transcurrido 15 minutos",
      "click_to_take_photo": "Pulse para fotografiar",
      "results_may_be_invalid": "Los resultados del test podrían ser inválidos"
    },
    "summary": {
      "title": "Resumen de resultados",
      "negative_subtitle": "Usted no tiene COVID-19",
      "negative_message": "Hemos notificado a su empleador que puede volver a trabajar en las instalaciones.",
      "positive_subtitle": "Lo sentimos, usted tiene COVID-19",
      "positive_message": "Hemos notificado a su empleador que usted tiene COVID-19.",
      "what_should_you_do": "Lo que debe hacer:",
      "employer_name_resources": "Nombre de recursos del empleador:",
      "follow_cdc_guidelines": "Siga las pautas recomendadas por el CDC"
    },
    "clarifying_results": {
      "title": "Esclarecer sus resultados",
      "which_did_your_result_most_look_like": "¿A cuál se parece más su resultado?",
      "no_lines": "No se ven líneas",
      "blue_control_line_only": "Solo línea azul de control",
      "pink_sample_line_only": "Solo línea rosa de la muestra",
      "blue_control_line_and_pink_sample_line": "Línea azul de control Y línea rosa/violeta de la muestra",
      "still_not_sure": "No está seguro",
      "submit_results": "Enviar resultados",
      "thank_you": "¡Gracias!",
      "clarification_received_message": "Su solicitud de esclarecimiento de resultado ha sido recibida",
      "only_pink_line": "Solo la linea rosa",
      "no_lines_v2": "Sin lineas"
    },
    "return_to_confirmation": "Vuelva a la página de confirmación"
  },
  "preferred_language": {
    "title": "Idioma de preferencia",
    "subtitle": "¿Qué idioma prefiere?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Serie de vacunación inicial",
    "one_dose": "Reserve una cita para una dosis específica. Esta es mi:",
    "title": "Selección de dosis",
    "first_dose": "Primera dosis solamente",
    "second_dose": "Segunda dosis solamente",
    "single_dose_title": "Dosis única",
    "additional": "Adicional",
    "booster": "Refuerzo",
    "supplemental": "Suplementario",
    "third_dose": "Solo la tercera dosis",
    "no_vaccine": "Ninguna de las anteriores"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "debe tener formato {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "debe tener formato {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "test no encontrado"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "usuario existente, no se permite actualizar"
            },
            "date_of_birth": {
              "invalid_date": "debe tener formato {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} no existe, seleccione entre las siguientes opciones válidas: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} no existen, seleccione entre las siguientes opciones válidas: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "género inexistente, seleccione entre las siguientes opciones válidas: {{genders}}"
            },
            "phone_number": {
              "invalid": "el número es inválido"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} no existe, seleccione entre las siguientes opciones válidas: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "No puede completar si no seleccionó \"prefiero autodescribirme\""
            },
            "sex": {
              "unknown_sex": "sexo inexistente, seleccione entre las siguientes opciones válidas: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "orientación sexual inexistente, seleccione entre las siguientes opciones válidas: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "estado inválido, seleccione entre las siguientes opciones válidas: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} no existe, seleccione entre las siguientes opciones válidas: {{races}}",
                "other": "{{unknown_races}} no existen, seleccione entre las siguientes opciones válidas: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "el estado de vacunación no es válido, elija uno de los siguientes valores aceptables: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "permisos insuficientes para asignar rol"
            },
            "user_id": {
              "unknown_user": "usuario no encontrado"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON inválido"
            },
            "user_id": {
              "unknown_user": "usuario no encontrado"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "lugar de la cita no encontrado"
            },
            "date_of_birth": {
              "invalid_date": "debe tener formato {{date_format}}"
            },
            "email": {
              "duplicated": "ya existe"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} no existe, seleccione entre las siguientes opciones válidas: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} no existen, seleccione entre las siguientes opciones válidas: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "género inexistente, seleccione entre las siguientes opciones válidas: {{genders}}"
            },
            "phone_number": {
              "invalid": "el número es inválido"
            },
            "population": {
              "unknown_population": "{{unknown_population}} no existe, seleccione entre las siguientes opciones válidas: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "completar si seleccionó \"prefiero autodescribirme\""
            },
            "sex": {
              "unknown_sex": "sexo inexistente, seleccione entre las siguientes opciones válidas: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "orientación sexual inexistente, seleccione entre las siguientes opciones válidas: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} no existe, seleccione entre las siguientes opciones válidas: {{races}}",
                "other": "{{unknown_races}} no existen, seleccione entre las siguientes opciones válidas: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "formulario de consentimiento no encontrado"
            },
            "access_code": {
              "unknown_user": "usuario no encontrado"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "JSON no válido"
            },
            "name": {
              "taken": "y la dirección están siendo usadas en otra ubicación"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "Usuario",
          "other": "Usuarios"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Ubicación",
          "other": "Ubicaciones"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Hispana o latina",
    "not_hispanic": "No hispana ni latina",
    "hispanic_expanded": {
      "other": "Otro origen hispano, latino o español",
      "mexican": "Mexicana, méxico-estadounidense, chicano/a",
      "puerto_rican": "Puertorriqueña",
      "cuban": "Cubana"
    },
    "subtitle": "La reglamentación requiere que recopilemos la siguiente información.",
    "help": "\"Etnicidad\" hace referencia a un conjunto de características compartidas como el idioma, costumbres culturales, religión, entre otras, utilizadas para diferenciar grupos de personas. Puede o no coincidir con su identidad racial",
    "unknown": "Desconocida",
    "title": "Etnicidad"
  },
  "sex_at_birth": {
    "question": "Sexo",
    "female": "Mujer",
    "male": "Hombre",
    "help": "\"Sexo\" hace referencia a la información en su acta de nacimiento.",
    "non_binary": "No binario",
    "subtitle": "La reglamentación requiere que recopilemos la siguiente información.",
    "decline": "Prefiero no responder",
    "unknown": "Desconocida"
  },
  "gender": {
    "help": "\"Identidad de género\" se refiere a cómo se identifica cada persona. Puede o no coincidir con el sexo que le fue asignado al nacer.",
    "subtitle": "Si lo desea, añada información sobre su identidad de género."
  },
  "sexual_orientation": {
    "title": "Orientación sexual",
    "subtitle": "Si lo desea, añada información sobre su orientación sexual.",
    "gay": "Gay, lesbiana u homosexual",
    "heterosexual": "Heterosexual",
    "bisexual": "Bisexual",
    "questioning": "Cuestionándose/no está seguro/no sabe",
    "prefer_not_to_disclose": "Prefiero no responder",
    "unknown": "Desconocida",
    "orientation_not_listed": "Orientación no listada",
    "not_applicable": "No aplicable",
    "pansexual": "Pansexual",
    "queer": "Queer"
  },
  "pronouns": {
    "title": "Pronombres que utiliza",
    "he": "Él",
    "she": "Ella",
    "they": "Elle",
    "choose_not_to_disclose": "Prefiero no responder",
    "prefer_to_self_describe": "Prefiero autodescribirme"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Licencia de conducir o número de identidad del Estado",
    "driver_license": "Licencia de conducir",
    "use_ssn_instead": "También puede usar el SSN",
    "social_security_number": "Número de seguro social",
    "state": "Estado",
    "upload_front_of_driver_license": "Subir el anverso de su licencia de conducir o número de identificación estatal",
    "choose_file": "Seleccionar archivo",
    "no_file_chosen": "No se ha seleccionado ningún archivo",
    "no_identification": "No tengo identificación",
    "insurance_card_has_a_back": "Mi tarjeta de seguro tiene un reverso",
    "upload_front_of_insurance_card": "Subir el anverso de su tarjeta de seguro.",
    "front_of_insurance_card_uploaded": "El anverso de su tarjeta de seguro se ha subido.",
    "insurance_card": "Tarjeta de seguro",
    "insurance_card_back": "Reverso de la tarjeta de seguro",
    "back_of_insurance_card_uploaded": "El reverso de su tarjeta de seguro se ha subido."
  },
  "quidel": {
    "certify_text": "Declaro que solo he enviado una vez los resultados de este test.",
    "entering_results": "Ingrese sus resultados",
    "review_instructions_1": "Controle las instrucciones con atención",
    "review_instructions_2": "Puede revisar las instrucciones provistas con el test, leer la guía paso a paso, o ver el video con las instrucciones:",
    "read_instructions": "Leer las instrucciones",
    "watch_video": "Ver el video",
    "timer": "Inicie el cronómetro luego de hacer girar el hisopo dentro del tubo. Detenga el cronómetro luego de 1 minuto y saque el hisopo del tubo. Atención: Debe reanudar el cronómetro y esperar 10 minutos para que el test funcione.",
    "start_timer": "Inicie el cronómetro (opcional)",
    "restart_timer": "Detener y reiniciar",
    "resume": "Reanudar",
    "one_minute_countdown": "¡Ha pasado 1 minuto! Saque el hisopo del tubo y reanude el cronómetro.",
    "take_photo": "Tome una fotografía de la tira de su test",
    "photo_warning": "Necesita enviar una fotografía para continuar.",
    "enter_results": "Ingrese los resultados del test",
    "choose_result": "Seleccione la opción que mejor refleje el resultado de la tira ¿Necesita ayuda? Pulse aquí para ver ejemplos de resultados.",
    "positive": "Positivo",
    "negative": "Negativo",
    "unknown": "Desconocido",
    "clarify": "Esclarecer resultados",
    "strip_question": "¿A cuál se parece más su tira?",
    "no_lines": "Sin líneas",
    "pink_line": "Solo una línea rosa",
    "not_sure": "No está seguro"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Foto de resultado",
    "retake_photo": "Volver a tomar una foto",
    "capture_photo": "Capturar foto",
    "confirm_information_correct": "Confirmo que la información anterior es correcta.",
    "submit": "Entregar",
    "title": "Ingrese resultados para {{name}}",
    "subtitle": "No es necesario descargar ni utilizar la aplicación iHealth COVID-19.",
    "instruction_title": "Ver instrucciones de iHealth",
    "instruction_guide": "Leer instrucciones",
    "video_guide": "Ver video",
    "add_photo": "Añadir foto",
    "confirm_result": "Confirmar resultado",
    "result_warning": "Seleccione el resultado para enviar",
    "confirm_warning": "Confirmar resultado para enviar",
    "administered_at_label": "¿Cuándo tomaste esta prueba?",
    "instruction_subtitle": "Vea las instrucciones que vinieron con su prueba o revise las instrucciones aquí."
  },
  "public_test_groups": {
    "title": "Kits de testeo de COVID-19",
    "appointment_recoveries_title": "¿Has vuelto al sitio web?",
    "appointment_recoveries_button_text": "Encuentra mi link",
    "search_test_group_title": "¿Primera vez?",
    "search_test_group_button_text": "Registrar ahora",
    "title_2": "Registro e informar de forma simple.",
    "step_one": "Registrar mi cuenta",
    "step_two": "Recoger la muestra de saliva ",
    "step_three": "Ver resultados en línea",
    "title_3": "Regístrate para activar tu kit",
    "population_title": "Registro",
    "population_button": "Activar",
    "faq_subtitle": "Conocer más",
    "faq_title": "Preguntas frecuentes",
    "faq_1": "¿Cómo devuelvo mi kit?",
    "faq_1_footer": "Ver instrucciones del test de SalivaDirect Funnel Collection",
    "faq_2": "Ya me registre, pero he olvidado mi cuenta",
    "faq_3": "¿Cómo activo mi kit?",
    "faq_2_subtitle": "Como eres un usuario recurrente, puedes confirmar tu informe usando el botón de '**Encuentra mi link**' más arriba.",
    "faq_3_subtitle": "Por favor, revisa el proceso de cuenta de Primary.Health utilizando las opciones a continuación",
    "footer_text_1": "Este producto no ha sido aprobado por la FDA, pero ha sido autorizado para ser utilizado en casos de emergencia por la FDA;",
    "footer_text_2": "Este producto ha sido autorizado solamente para recolectar y mantener muestra de saliva como ayuda en la detección del ácido núcleico proveniente del SARS-CoV-2, no debe ser utilizado para detectar otros virus o patógenos;",
    "footer_text_3": "El uso en caso de emergencias de este produco solamente se autoriza por el tiempo en que están dadas las circunstancias que justifican la autorización del uso por emergencia de dispositivos médicas, conforme el artículo 56 (b) (1) de la Ley  Federal de Alimentos, Medicamentos y Cosméticos, Compendio de Normas Federales de EE.UU. (U.S.C.) § 360bbb-3(b)(1), a menos que se extinga la declaración o se revoque antes la autorización.",
    "description": "Puede activar su kit de testeo usando los botones a continuación, si te resulta más conveniente.",
    "subtitle": "¿Primera vez en Primary?",
    "subtitle_1": "Acerca de sus kits de testeo de COVID-19",
    "faq_1_1": "Ponga el tubo de muestras cerrados en la bolsa para materiales biológicos",
    "faq_1_2": "Ubica la bolsa para materiales biológicos en la caja en la que te llegó con la etiqueta de SalivaDirect. Ubica la caja con la etiqueta de SalivaDirect en el FedEx UN 3373 Pak",
    "faq_1_3": "Remueve la cinta adhesiva del FedEx UN 3373 Pak y presiona firmemente para sellarlo.",
    "faq_1_4": "Deja tu muestra el mismo día que la recolectaste y dejala antes de la hora de la última recolecta. No dejes tu caja en el buzón en el fin de semana.",
    "faq_1_5": "Visita el sitio web de FedEx **[fedex.com/labreturns](https://www.fedex.com/labreturns)** para ver la ubicación de los buzones y los horarios de recogida.",
    "demo": {
      "title": "Instrucciones e información adicional sobre la prueba QuickVue At-Home OTC COVID-19",
      "online_instructions": "Instrucciones en línea",
      "download_instructions": "Descargar hoja de instrucciones",
      "begin": "Empezar",
      "instructions": {
        "welcome_title": "Bienvenido al sitio de pruebas de COVID-19 en casa.",
        "welcome_text_1": "Antes de comenzar la prueba, lávese las manos o use un desinfectante para manos.",
        "welcome_text_2": "Asegúrese de tener las manos secas antes de empezar.",
        "continue": "Continuar",
        "back": "Volver",
        "step_one_title": "Paso 1: Compruebe el kit de pruebas",
        "step_one_text_1": "Localice los componentes del kit:",
        "step_one_text_2": "Se recomienda también usar guantes (no suministrados) durante las pruebas.",
        "step_two_title": "Paso 2: Coloque el tubo en el portatubos",
        "step_two_text_1": "Retire el tapón de un **TUBO** y colóquelo en el **PORTATUBOS**.",
        "step_two_text_2": "**NOTA:** Se recomienda el uso de guantes.",
        "step_five_title": "Paso 5: Saque el hisopo del tubo",
        "step_five_text_1": "Espués de **UN (1) MINUTO**, Retire el hisopo del **TUBO** frotando la punta del hisopo contra la pared interior del tubo para exprimir todo el líquido posible",
        "step_five_text_2": "Tire el hisopo a la basura.",
        "step_six_title": "Paso 6: Abra la tira de prueba",
        "step_six_text_1": "Abra con cuidado la bolsa de la **TIRA DE PRUEBA** por la ranura y sostenga la **TIRA DE PRUEBA** como se indica.",
        "step_seven_title": "Paso 7: Coloque la tira de prueba en el tubo",
        "step_seven_text_1": "Coloque la **TIRA DE PRUEBA** dentro del **TUBO** con las flechas apuntando hacia abajo. Deje lab tira en el **TUBO** durante **DIEZ (10) MINUTOS COMPLETOS** – no la manipule ni la saque.",
        "step_eight_title": "Paso 8: Retire la tira de prueba del tubo",
        "step_eight_text_1": "A los **DIEZ (10) MINUTOS**, retire la **TIRA DE PRUEBA** del **TUBO** y colóquela sobre el perfil de la tira de prueba en los pasos 10-12. Asegúrese de que la **TIRA DE PRUEBA** esté en una superficie plana y con buena iluminación.",
        "step_eight_text_2": "**NOTA:** La prueba está pensada para ser leída a los diez (10) minutos. Si la prueba se lee antes de ese lapso o más de 5 minutos después del tiempo de lectura indicado, los resultados podrían ser inexactos (falso negativo, falso positivo o no válido) y se debe repetir la prueba.",
        "step_nine_title": "Paso 9: Compruebe sus resultados",
        "step_nine_text_1": "Hay tres tipos de resultados posibles.",
        "step_nine_text_2": "Comprobación de un resultado positivo",
        "step_nine_text_3": "Comprobación de un resultado negativo",
        "step_nine_text_4": "Comprobación de un resultado no válido",
        "step_ten_title": "Paso 10: Comprobación de un resultado positivo de la COVID-19",
        "step_ten_text_1": "Coloque la TIRA DE PRUEBA en el perfil de la tira de prueba que se muestra abajo y compárela con los ejemplos de resultados de la prueba que se muestran.",
        "step_three_title": "Paso 3: Obtenga la muestra de las fosas nasales",
        "step_three_text_1": "Saque el **HISOPO** de su envoltorio, teniendo cuidado de no tocar la punta del **HISOPO**.",
        "step_three_text_2": "Introduzca suavemente el **HISOPO** en la fosa nasal de 1,3 a 1,9 cm (0,5 a 0,75 pulgadas), dependiendo del tamaño de la nariz de la persona. Frote firmemente el **HISOPO** con un movimiento circular en la pared interior de **CADA FOSA NASAL al menos 4 veces**.",
        "step_three_text_3": "Asegúrese de frotar AMBAS fosas nasales con el mismo hisopo.",
        "step_three_text_5": "**NOTA:** Si no se obtiene correctamente la muestra, esto podría causar resultados negativos falsos.",
        "step_four_title": "Paso 4: Coloque el hisopo sentro del tubo",
        "step_four_text_1": "Coloque inmediatamente el **HISOPO** en el líquido dentro del **TUBO** y asegúrese de que esté tocando el fondo. Agítelo 3-4 veces.",
        "step_four_text_2": "Deje el hisopo en la solución durante **UN (1) MINUTO**.",
        "step_four_text_3": "**NOTA:** Si el hisopo está en la solución durante más de 10 minutos, no se debe usar",
        "step_three_text_4": "**NOTA:** Si está obteniendo la muestra de otras personas, use una mascarilla. En el caso de los niños, es posible que no sea necesario introducir el hisopo tan profundamente en la fosa nasal. En niños muy pequeños, podría ser necesario que otra persona sostenga la cabeza del niño mientras se obtiene la muestra."
      },
      "choose_language": "Elija su idioma"
    }
  },
  "self_resulting": {
    "report_result": "Informar un resultado",
    "report_result_subtitle": "Si su organización ofrece pruebas en casa y está listo para informar un resultado, haga clic en el botón a continuación.",
    "need_help": "¿Necesita ayuda?",
    "assistance": "Si necesita ayuda o modificar su información de contacto, por favor póngase en contacto con el centro de asistencia.",
    "contact_support": "Póngase en contacto con el centro de asistencia",
    "save_link": "Guarde este enlace para notificar los resultados posteriormente.",
    "send_via_email": "Enviar enlace por correo electrónico",
    "send_via_text": "Enviar enlace a través de texto SMS",
    "copy_link": "Copiar enlace",
    "resend_email": "Reenviar la verificación del correo electrónico",
    "phone_verified": "Teléfono verificado",
    "add_vaccination_status": "Añadir/actualizar su estado de vacunación COVID-19",
    "terms_of_service": "Condiciones del servicio",
    "verify_contact_information": "Verifique su información de contacto para que pueda recibir los resultados y las comunicaciones. Si necesita modificar su información de contacto, por favor póngase en contacto con el centro de asistencia.",
    "resend_text": "Reenviar la verificación de texto SMS",
    "loading": "Cargando",
    "add_to_account": "Añadir a la cuenta",
    "hi": "Hola",
    "email_verified": "Correo electrónico verificado",
    "go_to_my_account": "Ir a mi cuenta",
    "activate_kit": "Activar el kit de testeo",
    "report_custom_result": "Informar un resultado: {{test}}",
    "activate_custom_test": "Activar el test: {{test}}",
    "update_vaccination_status": "Actualize el estado de vacunación",
    "order_a_test_kit": "Ordenar un kit de prueba"
  },
  "enter_information": {
    "contact_info": "Ingrese su información de contacto",
    "label": "Teléfono o correo electrónico",
    "invalid": "Este no es un correo electrónico o número de teléfono válido.",
    "send": "Le enviaremos un código a su teléfono o correo electrónico.",
    "legal": "Al ingresar su número de teléfono o correo electrónico, usted acepta los **[Terminos de Servicios](https://primary.health/terms-of-service/)** y **[Politica de Privacidad](https://primary.health/privacy-policy/)** de Primary.Health.",
    "subtitle": "Le enviaremos un mensaje de texto o un correo electrónico para ayudarle a encontrar sus registros, o un enlace para notificar el resultado de una prueba.",
    "find_link": "¿Está registrado? Encuentre su enlace",
    "not_registered": "¿No está registrado?",
    "search_by_keyword": "Buscar por nombre, palabra clave, ciudad, código zip o de organización",
    "search": "Buscar",
    "register_here": "Regístrese aquí"
  },
  "contact_support": {
    "invalid": "Información inválida",
    "invalid_explanation": "Recientemente solicitó un enlace de inicio de sesión a Primary.Health. Sin embargo,",
    "never_registered": "¿Nunca se ha registrado? Comuníquese con su provedor de pruebas para obetner un enlace de registro, o encuentre su sitio a continuación.",
    "get_help": "Puede obtener ayuda para hacer coincidir su registro con su información de contacto con",
    "get_help_calling": "o llamando",
    "invalid_explanation_bold": "la información que proporcionaste no era válida.",
    "get_help_label": "Obtener ayuda ",
    "mobile": {
      "invalid_explanation": "No se encontraron usuarios que se puedan agregar a su cuenta",
      "get_help": "Si tiene preguntas, por favor comuníquese",
      "reason": "No todos los usuarios de Primary.Health cuentan con los requisitos para utilizar la nueva función de Cuentas. Cuando usted cumpla con los requisitos, recibirá una invitación de Primary.Health o del administrador de su grupo."
    },
    "zen_name": "Nombre de contacto",
    "ticket_type": "¿A qué se refiere?",
    "appointment_access_code": "Código de confirmación (si lo conoce)",
    "zen_desc": "Mensaje",
    "contact_us": "Contáctenos",
    "need_assistance": "Si requiere asistencia, o quiere cambiar su información de contacto, por favor comuníquese con soporte."
  },
  "show_appointments": {
    "welcome": "Encuentre su perfil",
    "welcome_subtitle_1": "Mostrando resultados para",
    "added_to_account": "Se agregó a su cuenta",
    "error": "Puede que este participante ya tenga una cuenta.",
    "welcome_subtitle_2": "¿No ve el nombre a continuación?",
    "welcome_not_logged_in": "Buscando enlace de sitio",
    "welcome_logged_in": "Seleccione su grupo",
    "welcome_subtitle_1_logged_in": "Para terminar de añadir un usuario, seleccione el usuario y el grupo. Los usuarios se asignan a una cuenta.",
    "welcome_subtitle_2_logged_in": "Si no ve a su usuario en la lista anterior, puede",
    "welcome_subtitle_1_not_logged_in": "Seleccione quién necesita localizar su sitio o añadir un nuevo usuario. ¿No encuentra el usuario?",
    "welcome_subtitle_3_logged_in": "pruebe con otro método de contacto",
    "welcome_subtitle_4_logged_in": "o contáctenos en",
    "welcome_subtitle_2_not_logged_in": "Actualizar búsqueda.",
    "welcome_subtitle_3": "Actualizar su búsqueda"
  },
  "enter_code": {
    "code": "¿Cuál es el código?",
    "enter_code": "Ingrese el código enviado a",
    "incorrect_code": "Ha introducido un código incorrecto.",
    "verify": "Verificar cuenta",
    "verified": "Verificado",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "¿Qué prueba realizó?",
    "photo_required": "Se requiere una foto del resultado",
    "find_your_test": "Encontar su prueba"
  },
  "login": {
    "create_account": "Crear una cuenta",
    "create_account_help": "Crear una cuenta no es lo mismo que registrarse para pruebas o vacunas. Una vez se registre",
    "create_account_help_schedule": "Programar citas",
    "create_account_help_record": "Registrar las pruebas caseras",
    "create_account_help_match": "Gestionar los registros de vacunación y de pruebas.",
    "continue": "Continuar como invitado",
    "already": "¿Ya tiene una cuenta? Haga clic en",
    "login": "para iniciar sesión.",
    "or_sign_in": "O ingresar con:",
    "no_account": "¿No tiene una cuenta? Haga clic en",
    "signup": "para registrarse",
    "here": "aquí",
    "email_address": "Dirección de correo electrónico:",
    "password": "Contraseña:",
    "complete_registration": "Completar la inscripción",
    "last_covid_test": "Último resultado de la prueba COVID-19",
    "no_record": "No hay registro",
    "viewing_information": "Visualización de la información de {{first_name}}",
    "download": "Descargar",
    "not_registered": "Parece que no está registrado en ningún grupo",
    "please_follow_link": "Por favor, siga el enlace de invitación de su correo electrónico para programar y acceder a su información con Primary.Health.",
    "log_results": "Registrar los resultados de mi prueba",
    "book_appointment": "Reservar cita",
    "no_new_appointments": "Actualmente, no se aceptan nuevas citas",
    "upload_vaccinations": "Subir vacunaciones",
    "vaccination": "vacunación",
    "new_appointment": "Nueva cita",
    "log_otc_results": "Registrar los resultados de una prueba de venta libre (OTC)",
    "no_household_members": "Todavía no tiene miembros de la familia.",
    "no_upcoming": "Ninguna cita próxima",
    "update_vaccination": "Actualizar vacunaciones",
    "upload_vaccination": "Subir vacunaciones",
    "title": "Bienvenido a Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "La creación de una cuenta le permite:",
    "p2": "Programar citas",
    "p3": "Registrar las pruebas caseras",
    "p4": "Gestionar los registros de vacunación y de pruebas de los familiares a cargo",
    "p5": "Proceso de inicio de sesión sencillo desde my.primary.health",
    "no_vaccination_record": "No se ha agregado una vacuna todavía",
    "vaccination_title_card": "Estado de la vacunación contra el Covid-19",
    "account_and_settings": "Cuenta y ajustes",
    "delete_account": "Eliminar cuenta",
    "language": "Idioma",
    "language_current": "Idioma actual: {{language}}",
    "log_out": "Cerrar sesión",
    "delete_account_title": "¿Está seguro/a que quiere eliminar su cuenta?",
    "new_encounter": "Nuevo encuentro de {{first_name}}",
    "new_encounter_subtitle": "¿Para qué grupo es este nuevo encuentro?",
    "no_programs": "Parece que no está inscrito en ningún programa",
    "edit_members": "Editar miembros",
    "edit_members_subtitle": "Elija el tablero que quiere ver o agregue un nuevo miembro a su cuenta",
    "add_members": "Agregar miembro",
    "delete_member": "¿Desea eliminar la cuenta de usuario de {{full_name}}?",
    "delete_member_subtitle": "Al quitar a este usuario, se eliminará toda la información asociada a su cuenta.",
    "select_member": "Selección de miembros",
    "edit_appointment": "Editar la cita",
    "route": {
      "me": "Tablero",
      "history": "Historial",
      "programs": "Agregar un test",
      "contact_us": "Ayuda",
      "choose_member": "Miembro",
      "settings": "Ajustes",
      "help": "Ayuda"
    },
    "user_dashboard": "Tablero de {{first_name}}",
    "view_full_results": "Vista en el portal de resultados",
    "required": "Requisito si usa la aplicación Primary",
    "no_email": "No tengo dirección de correo electrónico"
  },
  "vaccination_status": {
    "not_vaccinated": "No vacunado",
    "fully_vaccinated": "Completamente vacunado",
    "partially_vaccinated": "Parcialmente vacunado",
    "vaccination_records": "Registros de vacunación",
    "title": "¿Cuál es tu estado de vacunación contra el COVID-19?",
    "definition_title": "Definiciones de estado de vacunación",
    "definition_cdc": "Definición del estado de vacunación del CDC",
    "definition_osha": "Definición de estado de vacunación de la OSHA",
    "definition": {
      "fully_vaccinated": "2 semanas después de una dosis final en una serie de dos dosis de las vacunas Pfizer-BioNTech o Moderna, o una dosis de la vacuna Janssen de Johnson & Johnson.",
      "partially_vaccinated": "Recibió sólo una de dos dosis en una serie primaria, o han pasado menos de dos semanas después de recibir la dosis final en una serie de dos dosis o una dosis de la vacuna Janssen de Johnson & Johnson.",
      "not_vaccinated": "Ninguna vacuna contra el COVID-19 administrada.",
      "fully_vaccinated_with_one_booster": "Recibió una dosis de refuerzo de las vacunas Pfizer-BioNTech o Moderna después una serie completa o una dosis de la vacuna Janssen de Johnson & Johnson.",
      "fully_vaccinated_with_two_boosters": "Recibió una segunda dosis de refuerzo de las vacunas Pfizer-BioNTech o Moderna después de una serie completa o una dosis de la vacuna Janssen de Johnson & Johnson."
    },
    "label": {
      "fully_vaccinated": "Completamente vacunado",
      "partially_vaccinated": "Parcialmente vacunado",
      "not_vaccinated": "No vacunado",
      "fully_vaccinated_with_one_booster": "Completamente vacunado + refuerzo",
      "fully_vaccinated_with_two_boosters": "Completamente vacunado + 2 refuerzos",
      "prefer_not_to_answer": "Prefiero no contestar"
    },
    "progress_bar_title": "Historial de vacunación COVID-19",
    "upload_record_card": "Cargar su tarjeta de vacunación COVID-19",
    "show_example": "Mostrar ejemplo",
    "hide_example": "Ocultar ejemplo",
    "take_photo": "Clic para tomar una foto",
    "or": "O",
    "upload_image_or_pdf_instead": "Cargar una imagen o PDF",
    "select_doses_received": "Seleccionar todas las dosis de vacuna COVID-19 que ha recibido",
    "first_dose": "Primera dosis",
    "second_dose": "Segunda dosis",
    "first_booster_dose": "Primera dosis de refuerzo",
    "second_booster_dose": "Segunda dosis de refuerzo",
    "additional_dose": "Dosis adicional",
    "first_dose_manufacturer": "¿Cuál fue el fabricante de su primera dosis?",
    "first_dose_date": "¿Cuál fue la fecha de su primera dosis?",
    "second_dose_manufacturer": "¿Cuál fue el fabricante de su segunda dosis?",
    "second_dose_date": "¿Cuál fue la fecha de su segunda dosis?",
    "first_booster_dose_manufacturer": "¿Cuál fue el fabricante de su primera dosis de refuerzo?",
    "first_booster_dose_date": "¿Cuál fue la fecha de su primera dosis de refuerzo?",
    "second_booster_dose_manufacturer": "¿Cuál fue el fabricante de su segunda dosis de refuerzo?",
    "second_booster_dose_date": "¿Cuál fue la fecha de su segunda dosis de refuerzo?",
    "additional_dose_manufacturer": "¿Cuál fue el fabricante de su dosis adicional?",
    "additional_dose_date": "¿Cuál fue la fecha de su dosis adicional?",
    "completed_one": "Completo",
    "completed_two": "¡Gracias por actualizar su estado de vacunación!",
    "progress_bar_complete": "Completo",
    "upload_image": "Cargar imagen",
    "retake_photo": "Volver a tomar",
    "other": "Otro",
    "remove_first_dose": "Eliminar historial de primera dosis",
    "remove_second_dose": "Eliminar historial de segunda dosis",
    "remove_first_booster_dose": "Eliminar historial de primera dosis de refuerzo",
    "remove_second_booster_dose": "Eliminar historial de segunda dosis de refuerzo",
    "remove_additional_dose": "Eliminar historial de dosis adicional",
    "exemption": "¿Tengo una exención?",
    "exempt": "Tengo una exención",
    "not_exempt": "No tengo una exención",
    "enter_exemption": "Ingresar exención",
    "upload_exemption_documentation": "Cargar documentación de su exención",
    "remove_dose": "Eliminar dosis",
    "continue": "Continuar",
    "enter_credentials": "Ingrese sus credenciales",
    "incorrect_credentials": "Credenciales incorrectas, vuelva a intentarlo.",
    "add_photo": "Agregar foto"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Hola {{name}}. Notamos que no has finalizado de programar tu cita de {{group_name}} con {{org_name}}. Para no perder tu cita, tendrás que completar la etapa de pago. Haz click en este enlace para completar el proceso {{complete_url}}. Si quieres cancelar tu cita, haz click en este link {{cancel_url}}. Gracias"
    }
  },
  "otc": {
    "record_results_for_abbott": "Testear y registrar tus resultados del test BinaxNOW ",
    "record_results": "Testear y registrar tus resultados",
    "activate_a_kit": "Activar el kit",
    "activate_a_kit_for_test": "Activar un kit para un {{test}}",
    "todays_test_process": "Proceso de testeo de hoy",
    "date_of_birth_question": "¿Cuándo es la fecha de nacimiento de {{name}}?",
    "confirm_dob_to_continue": "Por favor, confirma la fecha de nacimiento del participante para continuar",
    "incorrect_dob": "Fecha de nacimiento incorrecta",
    "file_too_large": "Por favor asegúrate de que el tamaño del archivo cargado sea menor a {{size}}",
    "enter_valid_date_in_last_x_days": "Ingrese una fecha válida en los últimos {{n}} días ",
    "barcode_format_not_valid": "El formato del código de barras no es válido ",
    "complete_button": "Completa",
    "next_button": "Siguiente",
    "back_button": "Volver",
    "report_a_test": "Informar un test:",
    "x_%_completed": "{{n}}% completado",
    "answer_survey": "Responder las preguntas de la encuesta ",
    "steps": {
      "answer_survey": "Responder encuesta",
      "review_instructions": "Ver instrucciones ",
      "scan_code": "Escanear código ",
      "enter_time": "Ingresa la hora ",
      "enter_result": "Ingresa el resultado",
      "take_photo": "Tomar foto",
      "confirm_result": "Confirmar resultado",
      "selest_test_kit": "Elegir tipo de test ",
      "enter_date": "Ingresa  la fecha",
      "enter_date_and_time": "Ingresa la fecha y hora "
    },
    "review_instructions": "Ver las instrucciones del test ",
    "follow_instructions_or_below": "Seguí las instrucciones que vinieron con el test o lee las instrucciones a continuación ",
    "watch_video": "Ver video",
    "view_instructions": "Ver instrucciones ",
    "follow_instructions": "Seguí las instrucciones que vinieron con tu test. ",
    "scan_code": "Escanear el código QR del test",
    "qr_code_description": "El código QR es único y está en tu test",
    "show_example": "Mostrar ejemplo",
    "hide_example": "Ocultar ejemplo",
    "barcode_scanned": "Se ha escaneado el código de barras con éxito ",
    "enter_barcode": "Ingresa el código de barras",
    "scan_qr_code": "Escanear código QR",
    "which_test_did_you_take": "¿Qué test te hiciste?",
    "when_did_you_take_test": "¿Cuándo te hiciste este test?",
    "what_day_did_you_take_test": "¿Qué día te hiciste este test?",
    "what_time_did_you_take_test": "¿A qué hora te hiciste este test?",
    "time": {
      "today": "Hoy",
      "yesterday": "Ayer",
      "two_days_ago": "Hace 2 días"
    },
    "enter_current_time": "Ingresa la hora actual ",
    "enter_result": "Ingresa el resultado del test ",
    "choose_result_option": "Elija la opción que describe el resultado de su tarjeta de testeo",
    "clarify_results": "Clarifica tus resultados ",
    "take_result_photo": "Tomar foto de los resultados",
    "take_result_photo_description": "Toma una foto de la tarjeta de resultados para registrar sus resultados ",
    "my_result": "Mi resultado",
    "switch_camera": "Cambiar de camarat",
    "confirm_result": "Confirmar resultados",
    "name": "Nombre ",
    "date": "Fecha:",
    "result": "Resultado:",
    "results": "Resultados ",
    "test_submitted": "Gracias, {{first_name}}. Tu {{test}} ha sido cargado con éxito ",
    "dob": "Fecha de nacimiento:",
    "administered": "Administrado:",
    "or": "O",
    "upload": "Cargar",
    "change_uploaded_file": "Cambiar archivo cargado ",
    "take_photo": "Tomar foto",
    "confirm_information": "Confirmar información ",
    "barcode": "Código de barras:",
    "scan_test_barcode": "Escanear el código de barras del test",
    "barcode_description": "El código de barras es único y está en tu test",
    "enter_date_in_last_30_days": "Ingrese una fecha dentro de los últimos 30 días y la hora aproximada en la que se realizó el test",
    "add_image": "Añadir imagen",
    "change_image": "Cambiar imagen",
    "skip_barcode_reader": "¿No puedes escanear el código? Haz click en Siguiente para informar el resultado del test de todas formas",
    "enter_date_and_time": "Ingresa la fecha y hora del test",
    "enter_date": "Ingresa la fecha del test",
    "did_you_take_test_today": "¿Se hizo un test hoy?",
    "record_results_for_generic": "Pruebe y registre sus resultados para la prueba {{name}}.",
    "choose_result_option_custom": "Elige la opción que describe el resultado de tu {{test_name}}:"
  },
  "yes": "Sí",
  "no": "No",
  "event_token": {
    "title": "Por favor, ingresa el código a continuación en la máquina expendedora para recibir tu kit de test",
    "loading_text": "Cargando, por favor vuelve en un minuto"
  },
  "appointment_recovery": {
    "complete_title": "¡Ya está listo/a!",
    "subtitle_1": "Si está utilizando un iPhone, por favor presione **Listo** en la esquina superior a la izquierda de la pantalla para volver al tablero.",
    "subtitle_2": "Si está utilizando un Android, por favor presione **X** en la esquina superior a la izquierda de la pantalla.",
    "subtitle_0": "Por favor, salga del navegador para continuar."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Relación de consentimiento",
  "finish_registration": {
    "title": "Completa tu registro",
    "subtitle": "Por favor completa la siguiente información antes de tu cita:",
    "button": "Finalizar registro"
  },
  "arab_ethnicity": {
    "arab": "Árabe",
    "non_arab": "No árabe",
    "prefer_not_to_answer": "Prefiere no contestar",
    "question": "Etnia árabe",
    "subtitle": "Las normas exigen que recopilemos la siguiente información.",
    "help": "Etnia se refiere a un conjunto de rasgos en común como idioma, costumbres culturales, religión y otras características que se usan para diferenciar a grupos de personas. Puede que esté o no esté en línea también con la identidad racial."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Dependiendo de su dispositivo, haga clic en el siguiente enlace para descargar le Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Siguiente",
    "back": "Anterior",
    "complete": "Completo"
  },
  "registration_direcory": {
    "keyword_search_label": "Palabra clave, ciudad, código postal, nombre de la organización",
    "keyword_search_label_mobile": "Ciudad, código postal, nombre de la organización",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Abra la aplicación de cámara en tu dispositivo",
    "point_to_qr_code": "Apunte hacia el código QR y toque el enlace",
    "complete_registration": "Complete el registro",
    "need_help": "¿Necesita ayuda? Contáctenos en"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Certificación de información sobre vacunas",
    "select_checkbox": "Revise la siguiente información y marque la casilla correspondiente antes de continuar.",
    "opportunity_to_read": "Tengo o me han dado la oportunidad de leer, o me han leído, la(s) Declaración(es) de Información sobre Vacunas (VIS, por sus siglas en inglés) o la hoja informativa de Autorización de Uso de Emergencia (EUA, por sus siglas en inglés) proporcionadas para la(s) vacuna(s) que se me va(n) a administrar:",
    "vaccine_info_sheet": "hoja de información de vacunación",
    "vaccine_fact_sheet": "hoja de datos de vacunación",
    "vaccine_info_statement": "declaración de información sobre vacunas"
  },
  "exemption": {
    "dtap_tdap": "Tdap/Dtap (tétanos, difteria y tos ferina)",
    "mmr": "MMR (sarampión, paperas y rubeola)",
    "ipv_opv": "Polio",
    "hib": "HIB (haemophilus influenzae tipo b)",
    "hep_b": "HEPB (hepatitis B)",
    "var": "Varicela",
    "hep_a": "HepA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "este kit incluye pruebas para detectar la clamidia y gonorrea.",
    "B": "este kit incluye pruebas para detectar la clamidia y gonorrea.",
    "C": "este kit incluye pruebas para detectar la sífilis.",
    "D": "este kit incluye pruebas para VIH, sífilis, clamidia y gonorrea.",
    "E": "este kit incluye pruebas para detectar el VIH y la sífilis.",
    "box_type": "Caja {{type}}",
    "administered_text": "Nos pondremos en contacto con usted cuando su kit llegue al laboratorio y cuando sus resultados estén listos.",
    "activate_kit": "Activar mi kit de pruebas",
    "register_another_test": "Registrar otra prueba",
    "credentials": {
      "title": "Comencemos",
      "label": "Contacto",
      "p1": "Empezaremos con algunos datos sobre usted.",
      "location_step": {
        "p1": "¿Quién le ha proporcionado el kit de pruebas?",
        "load": "Más información"
      }
    },
    "checklist": {
      "not_urinated": "No ha orinado en la última hora.",
      "bathroom": "Tiene acceso a un cuarto de baño para la recolección de orina.",
      "anal_swab": "Tiene acceso a un lugar privado para realizar un frotis anal.",
      "blood_extraction": "Tiene acceso a un entorno limpio para la extracción de sangre.",
      "washed_hands": "Se ha lavado las manos con agua tibia y jabón durante 30 segundos.",
      "title": "¿Está listo para comenzar la recolección de muestras?",
      "no_alcohol": "No beba alcohol en día de la toma de muestras",
      "menstruation": "No tome la muestra vaginal durante el sangrado menstrual o durante las siguientes 24 horas de haber tenido relaciones."
    },
    "confirm": {
      "title": "Confirme sus datos",
      "p1": "¿Es correcta esa información?"
    },
    "display_name": {
      "A": "Clamidia & gonorrea (un sitio)",
      "B": "Clamidia & gonorrea (tres sitios)",
      "C": "Sífiliis",
      "shortened": {
        "A": "Clamidia & gonorrea",
        "B": "Clamidia & gonorrea",
        "C": "Sífiliis"
      },
      "CC": "Detección de Cáncer Colorrectal (FIT)",
      "CE": "Detección de Enfermedad Celíaca",
      "CR": "Prueba de Creatinina",
      "D": "Prueba de 4 Paneles - VIH, Sífilis, Clamidia y Gonorrea (Un Solo Sitio)",
      "DD": "Detección de Diabetes (HbA1c)",
      "E": "VIH y Sífilis",
      "H": "VPH (Virus del Papiloma Humano)",
      "P": "Hepatitis C",
      "HS": "Virus de Herpes Simple",
      "I": "VIH",
      "TT": "Nivel de Testosterona",
      "VD": "Vitamina D",
      "VS": "Vigilancia Viral",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Volver a escanear",
    "scanned": "Has escaneado la caja {{type}}",
    "not_yet": "Cargando",
    "success_registration": "¡Registro exitoso!",
    "no_box": "¿No tienes una caja?",
    "faq_link": "Visitar Preguntas frecuentes",
    "welcome": "¡Bienvenido!",
    "scan": {
      "title": "Está un paso más cerca de una vida más sana.",
      "p1": "Escanee o introduzca el **código de barras del kit** que aparece en el costado de su caja."
    },
    "appointments": {
      "title": "Seleccione el paciente y el proveedor",
      "p1": "Hemos encontrado más de un perfil asociado a su información de contacto. Seleccione el perfil correcto:",
      "new": "Nuevo paciente o proveedor"
    },
    "verify": {
      "title": "Confirme su identidad",
      "p1": "Hemos encontrado su historial."
    },
    "section_1": "Confirme el contenido del kit",
    "section_2": "Toma de muestras",
    "section_3": "Empaque",
    "section_4": "Envío",
    "box_a": "Caja A",
    "box_b": "Caja B",
    "box_c": "Caja C",
    "kit_flow": {
      "button_1": "Continuar con el empaquetado",
      "button_2": "Estoy listo.",
      "button_3": "Mi kit está completo y empacado",
      "button_4": "Terminé",
      "button_5": "Próxima prueba",
      "button_6": "Saltar instrucciones"
    },
    "contents": {
      "header": "¡Magnífico! Asegúrese de que su kit esté completo",
      "text_1": "¿Tu kit de prueba contiene lo siguiente?",
      "alchohol_pads": "2 almohadillas de alcohol",
      "up_to": "(Hasta {{num}} dependiendo del kit de prueba)",
      "bio_bags": "({{num}}) Bolsas de riesgo biológico",
      "blood_collection_card": "Tarjeta de extracción de sangre",
      "blood_collection_card_subtitle": "(1 o 2 dependiendo del kit de prueba)",
      "lancets": "Lancetas desechables",
      "up_to_other": "(Hasta 4 dependiendo del kit de la prueba)",
      "adhesive_bandages": "Banditas adhesivas",
      "pipette": "Pipeta",
      "urine_tube": "Tubo recolector de orina",
      "urine_cup": "Vaso recolector de orina",
      "oral_swab": "Hisopo bucal",
      "oral_tube": "Tubo recolector oral",
      "anal_swab": "Hisopo anal",
      "anal_tube": "Tubo recolector anal",
      "vaginal_tube": "1 tubo de toma de extracción vaginal",
      "vaginal_swab": "1 hisopo vaginal",
      "biodegradable_paper": "Papel de recolección biodegradable",
      "bottle_and_probe": "Botella y sonda de muestra",
      "prepaid_envelope": "Sobre de envío pre-pagado",
      "biohazard_subtitle": "(con la almohadilla absorbente)",
      "biohazard_bag": "1 bolsa para muestras",
      "biohazard_bag_plural": "({{count}}) bolsas de riesgo biológico",
      "alcohol_pads_plural": "{{count}} almohadillas de alcohol",
      "sterile_gauze_pad": "1 compresa de gasa estéril"
    },
    "packaging": {
      "title": "Lista de comprobación del paquete",
      "subtitle": "Antes de enviar su kit, asegúrese de que:"
    },
    "packaging_box_a": {
      "step_1": "Ha empacado todo el contenido del kit de pruebas",
      "step_2": "Ha utilizado todos los materiales de recolección contenidos en el kit para obtener las muestras requeridas",
      "step_3": "Su **fecha de nacimiento** está escrita en todos los tubos de recolección de muestras utilizando el formato MM/DD/AAAA",
      "step_4": "La **fecha de recolección** está escrita en todos los tubos de recolección de muestras utilizando el formato MM/DD/AAAA",
      "step_5": "El tubo recolector de muestras está cerrado herméticamente en su bolsa de riesgo biológico",
      "step_6": "Todos las muestras están dentro de la caja",
      "step_7": "La caja está dentro del sobre de envío prepagado",
      "step_8": "El sobre de devolución está completamente cerrado"
    },
    "packaging_box_b": {
      "step_1": "Ha empacado todo el contenido del kit, incluso si se saltó una o más pruebas",
      "step_2": "Ha utilizado todos los materiales de recolección contenidos en el kit para obtener las muestras requeridas",
      "step_3": "Su **fecha de nacimiento** está escrita en todos los tubos de recolección de muestras utilizando el formato MM/DD/AAAA",
      "step_4": "La **fecha de recolección** está escrita en todos los tubos de recolección de muestras utilizando el formato MM/DD/AAAA",
      "step_5": "Cada tubo recolector de muestras se encuentra sellado en su propia bolsa de riesgo biológico (sólo **una** muestra por bolsa)",
      "step_6": "Todos las muestras están dentro de la caja",
      "step_7": "La caja está dentro del sobre de envío prepagado",
      "step_8": "El sobre de devolución está completamente cerrado"
    },
    "packaging_box_c": {
      "step_1": "Ha empacado todo el contenido del kit de pruebas, incluidas las lancetas usadas.",
      "step_2": "Su **fecha de nacimiento** está escrita en la tarjeta de extracción de sangre utilizando el formato MM/DD/AAAA",
      "step_3": "La **fecha de toma de muestra** está escrita en la tarjeta de muestra de sangre en formato MM/DD/AAAA",
      "step_4": "La **fecha de extracción** está escrita en la tarjeta de extracción de sangre utilizando el formato MM/DD/AAAA",
      "step_5": "La bolsa de riesgo biológico está dentro de la caja",
      "step_6": "La caja está dentro del sobre de envío prepagado",
      "step_7": "El sobre de devolución está completamente cerrado"
    },
    "success": {
      "header_1": "¡Muy bien!",
      "text_1": "Ya está listo para recolectar su primera muestra.",
      "header_2": "¡Lo has conseguido!",
      "text_2": "**Próximo paso:** empacar su kit para enviarlo de vuelta.",
      "header_3": "¡Buen trabajo!",
      "text_3": "Estás un paso más cerca de una mejor salud.",
      "header_4": "¡Excelente trabajo!",
      "text_4": "Solo un paso más para finalizar",
      "text_5": "Está listo para tomar su primera muestra"
    },
    "instructions": {
      "title": "Seleccione una prueba para ver las instrucciones:",
      "subtitle": "Prueba",
      "button": "Instrucciones",
      "pdf": "Instrucciones en PDF",
      "test_num": "Prueba {{num}}:",
      "test": "Prueba:"
    },
    "instructions_box_a": {
      "title": "Recolección de orina",
      "subtitle": "Para esta prueba necesitarás los siguientes materiales:",
      "step_1": "No orine durante al menos 1 hora antes de la recolección. Lo mejor es utilizar la primera orina de la mañana.",
      "step_2": "Orine directamente en el PeeCanter o en el recipiente recolector, llenándolo entre 1/3 y 1/2.",
      "step_3": "Vierta la orina del PeeCanter o utilice la pipeta para transferir la orina del recipiente recolector a los tubos recolectores marcados con la etiqueta naranja  <span style=\"color: #FF5000\">**\"URINE\"**</span> .",
      "step_4": "Llene el tubo o tubos recolectores hasta que el líquido combinado se encuentre entre las líneas de llenado designadas.",
      "step_4_sublabel": "No llene el tubo más allá de la línea de llenado máximo o la muestra será rechazada.",
      "step_5": "Ponga la tapa del tubo de extracción y asegúrese de que está cerrada de manera uniforme y ajustada.",
      "step_6": "Escriba su fecha de nacimiento y la fecha de recolección de la muestra (fecha de hoy) en formato MM/DD/AAAA en el tubo, en el área designada.",
      "step_7": "Coloque la muestra en una bolsa de riesgo biológico vacía con la almohadilla absorbente y asegúrese de que el sello esté completamente cerrado."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Recolección de orina",
        "subtitle": "Para esta prueba necesitarás los siguientes materiales:",
        "step_1": "No orine durante al menos 1 hora antes de la recolección. Lo mejor es utilizar la primera orina de la mañana.",
        "step_2": "Orine directamente en el PeeCanter o en el recipiente recolector, llenándolo entre 1/3 y 1/2.",
        "step_3": "Vierta la orina del PeeCanter o utilice la pipeta para transferir la orina del recipiente recolector a los tubos recolectores marcados con la etiqueta naranja  <span style=\"color: #FF5000\">**\"URINE\"**</span> .",
        "step_4": "Llene el tubo o tubos recolectores hasta que el líquido combinado se encuentre entre las líneas de llenado designadas.",
        "step_4_subtitle": "No llene el tubo más allá de la línea de llenado máximo o la muestra será rechazada.",
        "step_5": "Ponga la tapa del tubo de extracción y asegúrese de que está cerrada de manera uniforme y ajustada.",
        "step_6": "Escriba su fecha de nacimiento y la fecha de recolección de la muestra (fecha de hoy) en formato MM/DD/AAAA en el tubo, en el área designada.",
        "step_7": "Coloque la muestra en una bolsa de riesgo biológico vacía con la almohadilla absorbente y asegúrese de que el sello esté completamente cerrado."
      },
      "test_2": {
        "title": "Hisopo bucal",
        "subtitle": "Para esta prueba necesitarás los siguientes materiales:",
        "step_1": "Sujete el bastoncillo entre la línea punteada (si existe) y la parte de algodón del bastoncillo.",
        "step_2": "Introduzca el hisopo en la boca y frote la punta contra la parte posterior de la garganta durante 10 segundos.",
        "step_3": "Retire con cuidado el hisopo y colóquelo en el tubo recolector marcado con la etiqueta azul <span style=\"color: #001F70\">**\"ORAL\"**</span>. Rompa el hisopo por la línea punteada doblándolo contra el tubo recolector.",
        "step_4": "Ponga la tapa del tubo de extracción y asegúrese de que está cerrada de manera uniforme y ajustada.",
        "step_5": "Escriba su fecha de nacimiento y la fecha de recolección de la muestra (fecha de hoy) en formato MM/DD/AAAA en el tubo, en el área designada.",
        "step_6": "Coloque la muestra en una bolsa de riesgo biológico vacía con la almohadilla absorbente y asegúrese de que el sello esté completamente cerrado."
      },
      "test_3": {
        "title": "Hisopo anal",
        "subtitle": "Para esta prueba necesitarás los siguientes materiales:",
        "step_1": "Sujete el bastoncillo entre la línea punteada (si existe) y la parte de algodón del bastoncillo.",
        "step_2": "Introduzca el hisopo 3 a 5 cm (1 a 2\") en el canal anal. Gire suavemente el hisopo durante 5 a 10 segundos para recoger cualquier posible organismo.",
        "step_3": "Retire con cuidado el hisopo y colóquelo en el tubo recolector marcado con la etiqueta verde <span style=\"color: #00C08C\">**“ANAL”**</span>. Rompa el hisopo por la línea punteada doblándolo contra el tubo recolector.",
        "step_4": "Ponga la tapa del tubo de extracción y asegúrese de que está cerrada de manera uniforme y ajustada.",
        "step_5": "Escriba su fecha de nacimiento y la fecha de recolección de la muestra (fecha de hoy) en formato MM/DD/AAAA en el tubo, en el área designada.",
        "step_6": "Coloque la muestra en una bolsa de riesgo biológico vacía con la almohadilla absorbente y asegúrese de que el sello esté completamente cerrado."
      }
    },
    "instructions_box_c": {
      "title": "Extracción de sangre",
      "subtitle": "Para esta prueba necesitarás los siguientes materiales:",
      "step_1": "Escriba su nombre, fecha de nacimiento y fecha de extracción en los campos designados. Utilice el formato MM/DD/AAAA.",
      "step_2": "Abra la solapa de la tarjeta de sangre para exponer los círculos del papel de recolección de sangre. No toque el papel de recolección de sangre.",
      "step_3": "Lávese las manos con agua tibia durante al menos 30 segundos y, a continuación, agítelas enérgicamente durante 15 segundos para estimular el flujo sanguíneo hacia los dedos.",
      "step_4": "Limpie la yema del dedo con alcohol. Lo mejor es utilizar el dedo corazón o anular de la mano no dominante.",
      "step_5": "Tome la lanceta y desenrosque el tapón. Presione firmemente la pequeña punta contra la yema del dedo, hasta que la aguja salga expulsada con un clic. Manteniendo la mano por debajo de la altura del corazón durante la extracción, masajee el dedo desde la base hasta la punta para estimular el flujo sanguíneo.",
      "step_5_subtitle": "Las lancetas son de un solo uso. Todas las lancetas deben devolverse con la muestra al laboratorio para su eliminación.",
      "step_6": "Empezando por el centro, aplique de 3 a 6 gotas para llenar el círculo e impregne el papel de recolección. No toque el papel con el dedo, ya que restringiría el flujo sanguíneo. Cuando el círculo esté lleno, pase al siguiente. Está bien que la sangre se extienda más allá de las líneas, pero no deje que las manchas de sangre se extiendan unas dentro de otras.",
      "step_6_subtitle": "No añada sangre adicional a un círculo una vez completo o seco. La «superposición» de sangre invalidará la muestra.",
      "step_7": "Sin cerrar la tarjeta de sangre, colóquela sobre una superficie plana y deje que el papel de recolección de sangre se seque al aire, a temperatura ambiente, durante al menos 30 minutos. No lo caliente, no lo seque con secador ni lo exponga a la luz solar directa. El calor dañaría la muestra.",
      "step_7_subtitle": "Compruebe el reverso del papel de recolección de sangre. La sangre debe saturarse por completo y llenar cada círculo del papel de recolección.",
      "step_8": "Cuando el papel de recolección de sangre esté seco, cierre la tarjeta de sangre plegando la solapa. Coloque la tarjeta de sangre y las lancetas en la bolsa de riesgo biológico con el disecante. Asegúrese de que la bolsa de riesgo biológico esté bien cerrada."
    },
    "test_tips": {
      "title": "Consejos para una correcta extracción de sangre",
      "subtitle": "Para obtener mejores resultados:",
      "step_1": "Asegúrese de no estar deshidratado mientras realiza la extracción. La hidratación favorece el flujo sanguíneo.",
      "step_2": "No realice la extracción inmediatamente después de fumar.",
      "step_3": "Lavarse y calentarse las manos con agua tibia ayudará a estimular el flujo sanguíneo en las manos.",
      "step_4": "Sacuda las manos enérgicamente hacia el suelo para estimular el flujo sanguíneo hacia los dedos.",
      "step_5": "Mantenga el dispositivo de recogida y las manos por debajo de la altura del corazón durante la recolección para un mejor flujo sanguíneo.",
      "step_6": "Es posible que necesite más de un pinchazo en el dedo. Repita estos consejos entre cada pinchazo."
    },
    "shipping": {
      "header": "Su kit está listo para ser enviado.",
      "text": "**¡Felicidades!** Finalizó su evaluación de salud sexual.",
      "success": "**Felicidades** Ha terminado su examen de salud sexual."
    },
    "pick_up": "Pase a buscar el kit de la prueba sugerida con el personal en el sitio y regístrese abajo.",
    "short_display_name": {
      "A": "Clamidia y Gonorrea",
      "B": "Clamidia y Gonorrea",
      "C": "Sífilis",
      "CC": "Cáncer Colorrectal",
      "CE": "Celíaca",
      "CR": "Creatinina",
      "D": "VIH, Sífilis, Clamidia y Gonorrea",
      "DD": "Diabetes",
      "E": "VIH y Sífilis",
      "H": "VPH (Virus del Papiloma Humano)",
      "P": "Hepatitis C",
      "HS": "Virus de Herpes Simple",
      "I": "VIH",
      "TT": "Testosterona",
      "VD": "Vitamina D",
      "VS": "Vigilancia Viral"
    },
    "test_kit": "Kit de prueba",
    "view_results": "Ver resultados",
    "recommended_kit_title": "Kits de pruebas recomendados",
    "instructions_box_h": {
      "title": "Toma de muestra vaginal",
      "subtitle": "Los tubos de muestras podrían contener conservantes líquidos. <span class=\"text-danger\">**No tire el conservante líquido de los tubos de muestras.**</span>",
      "step_1": "Sujete el hisopo entre la línea (si tiene una) y la punta con algodón en una mano y separe los labios (plieges de piel alrededor de la entrada vaginal).",
      "step_2": "Inserte el hisopo 5 cm (2in) en la entrada vaginal. Gentilmente gire el hisopo durante 30 segundos mientras lo frota con las paredes de la vagina.",
      "step_3": "Con cuidado, saque el hisopo y póngalo en el tubo de extracción marcado con una <span class=\"text-danger\">**ETIQUETA ROJA QUE DICE “VAGINAL” **</div>. Rompa el hisopo doblándolo contra el tubo de extracción.",
      "step_4": "Ponga la tapa del tubo de extracción y asegúrese de que está cerrada de manera uniforme y ajustada.",
      "step_5": "Escriba su fecha de nacimiento en formato MM/DD/AAAA en el tubo en el área designada (DOB).",
      "step_6": "Escriba la fecha de la toma de muestras (hoy) en formato MM/DD/AAAA en el tubo en el área designada (Coll. Date).",
      "step_7": "Ponga la muestra en una bolsa de riesgo biológico vacía con una almohadilla absorbente y verifique que el sellos esté completamente cerrado."
    },
    "packaging_box_h": {
      "step_3": "Su **fecha de nacimiento** se escribe en el tubo de recolección de muestras utilizando el formato MM/DD/AAAA.",
      "step_4": "La **fecha de recolección** se escribe en el tubo de recolección de muestras utilizando el formato MM/DD/AAAA.",
      "step_6": "La muestra está dentro de la caja."
    },
    "instructions_box_cc": {
      "title": "Toma de muestras fecales",
      "step_1": "Escriba su fecha de nacimiento y la fecha de recolección de la muestra (fecha de hoy) en formato MM/DD/AAAA en el tubo, en el área designada. Abra la tapa verde girando y levantando.",
      "step_2": "Coloque en el inodoro, encima del agua, el papel de recolección suministrado.",
      "step_3": "Deposite la muestra de heces encima del papel de recolección.",
      "step_4": "Recoja la muestra de heces antes de que el papel se hunda y la muestra de heces toque el agua.",
      "step_5": "Raspe la superficie de la muestra fecal con la sonda de muestra.",
      "step_6": "Cubra completamente la parte ranurada de la sonda de muestra con la muestra de heces.",
      "step_7": "Cierre la botella de muestreo insertando la sonda de muestra y ajuste firmemente la tapa verde. No la vuelva a abrir.",
      "step_8": "Descargue el tanque de agua. El papel de recolección es biodegradable y no dañará los sistemas sépticos.",
      "step_9": "Envuelva la botella de muestreo en una almohadilla absorbente e insértela en una bolsa de riesgo biológico."
    },
    "contact_support": {
      "title": "Vamos a arreglar esto",
      "text_1": "¡Lamentamos que algo haya salido mal!",
      "text_2": "Por favor, contáctenos para informarnos de lo que pasó y que podamos remplazar su kit."
    },
    "contact_support_success": {
      "title": "Recibimos su mensaje",
      "text_1": "Gracias por contarcarnos.",
      "text_2": "Un miembro del equipo de soporte se comunicará con usted pronto.",
      "button_text": "Regresar al sitio principal"
    },
    "kit_in_transit_to_patient": "¡Tu pedido está en camino! La mayoría de los pedidos\nse entregan dentro de 2-5 días hábiles. Una vez que recibas tu kit, te enviaremos\nmás información sobre cómo activarlo y enviarlo de vuelta.\n\nEnlace para rastrear: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "¡El laboratorio recibió tu kit! Recibirás una notificación para verificar tu portal de paciente cuando los resultados estén listos.",
    "registration_confirmation": "¡Tu registro ahora está completo! Visita tu página de confirmación de registro para activar tu kit de prueba:",
    "kit_ordered_online": "¡Hemos recibido tu pedido de kit y enviaremos una actualización una vez que se envíe!",
    "kit_ordered_online_email": {
      "header": "¡Recibimos tu pedido!",
      "p1": "¡Hemos recibido tu pedido! Una vez que tu pedido sea enviado, te enviaremos otra actualización.",
      "order_number": "# de Orden",
      "order_date": "Fecha de Orden"
    },
    "completed": "Completa",
    "I": "Su kit incluye pruebas para VIH",
    "CC": "Su kit incluye pruebas para cáncer Colorrectal",
    "CE": "Su kit incluye pruebas para enfermedad celíaca",
    "CR": "Su kit incluye pruebas para creatinina",
    "DD": "Su kit incluye pruebas para diabetes",
    "H": "Su kit incluye pruebas para VPH",
    "P": "Su kit incluye pruebas para hepatitis C",
    "HH": "Su kit incluye pruebas para hemoglobina(HbA1c)",
    "HS": "Su kit incluye pruebas para virus de Herpes simple (VHS-2)",
    "TT": "Su kit incluye pruebas para VIH",
    "VD": "Su kit incluye pruebas para VIH"
  },
  "copy_link": "Copiar enlace del sitio",
  "copied_link": "¡Se ha copiado el enlace!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Protección contra los virus comunes de la gripe.",
      "vaccine_covid": "Protección contra los virus COVID-19.",
      "tdap": "La vacuna Tdap puede prevenir el tétanos.",
      "polio": "Protección contra el virus de la polio. Requerido para los niños antes de comenzar la escuela.",
      "varicella": "Vacuna que protege contra la varicela.",
      "shingles": "Vacuna que protege contra el herpes zóster. La edad mínima es 18.",
      "human_papillomavirus": "Vacuna que protege contra el VPH. Recomendada para niños de 11 años.",
      "meningococcal": "La vacuna protege contra cuatro tipos de bacterias meningocócicas."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continua a ver mi resultado",
      "create_page_title": "Gracias!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Encuentra una clínica",
    "select_pin": "Selecciona un pin para ver detalles.",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Siguente clinica",
    "appointment_type": "Tipo de cita",
    "individual_appointment": "Cita individual",
    "family_appointment": "Cita familiar",
    "vaccines": "Vacunas",
    "what_kind_appointment": "¿Qué tipo de cita deseas reservar?",
    "select_family_or_individual": "Selecciona cita familiar si estás programando para varios miembros de la familia a la vez.",
    "vaccines_selected": "Vacunas seleccionadas",
    "where_care": "¿Dónde deseas recibir atención?",
    "select_vaccines_for_individual": "Escoger vacunas para la cita (opcional)",
    "select_vaccines_for_family": "Escoger vacunas para la cita familiar (opcional)",
    "schedule_services": "Agendar",
    "add_family": "Add family members and select services below.",
    "family_member": "Miembro de familia {{number}}",
    "morning": "Mañana",
    "afternoon": "Tarde",
    "slot_available": "{{number}} disponible",
    "within_x_miles": "Dentro de {{miles}} millas de",
    "any_distance": "A cualquier distancia de",
    "partial_results": "Resultados parciales",
    "partial_matches": "Las siguientes clínicas ofrecen algunas pero no todas las vacunas que está buscando",
    "no_matches": "No hay resultados para su búsqueda. Intenta cambiar tus filtros para obtener mejores resultados.",
    "no_clinics_found": "No hay clínicas que coincidan con tu búsqueda.",
    "broaden_filters": "Intenta cambiar tus filtros para obtener mejores resultados.",
    "unavailable_vaccines": "Vacunas no disponibles:",
    "available_vaccines": "Vacunas disponibles:",
    "select_date": "Selecciona una fecha",
    "available_appointments": "CITAS DISPONIBLES",
    "appointment_scheduling_info": "Podrá asignar citas específicas a miembros de la familia en el siguiente paso. Puede programar citas en diferentes días si lo prefiere.",
    "hold_selected_appointments": "Reservaremos sus citas seleccionadas por 15 minutos.",
    "appointments_selected": "CITAS SELECCIONADAS",
    "no_appointments_selected": "No se seleccionó ninguna cita",
    "vaccines_needed": "Vacunas seleccionadas",
    "select_x_appointments": "Selecciona {{number}} citas.",
    "more": "Más",
    "less": "Menos",
    "register_next_family_member": "Registrar la siguiente miembro de la familia",
    "successfully_registered_x_of_y_family_members": "Registración completo para {{x}} de {{y}} miembros de la familia",
    "next_family_member": "Siguiente miembro de la familia",
    "appointments_abbreviated": "citas",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Verificamos su correo electrónico",
    "hi_full_name": "Hola {{full_name}}",
    "verify_email_button": "Verificar su correo electrónico",
    "please_verify_email": "Por favor, utiliza el botón de abajo para verificar tu correo electrónico."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Hepatitis A",
    "hepatitis_b": "Hepatitis B",
    "hepatitis_a_b": "Hepatitis A & B",
    "hib": "Hib",
    "mmr": "Sarampión, paperas, rubéola (MMR)",
    "meningococcal": "Enfermedad meningocócica",
    "mpox": "Mpox",
    "pneumococcal": "Neumocócica",
    "polio": "Poliomielitis (IPV)",
    "rsv": "Infección por el virus respiratorio sincitial (VRS)",
    "rotovirus": "Rotavirus",
    "zoster": "Culebrilla",
    "tetanus_diptheria": "Tetanus & Diphtheria",
    "tdap": "Tdap",
    "typhoid": "Tifoidea",
    "varicella": "Varicela",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Adultos)",
    "covid-19_adult_description": "Personas 12 años o mas.",
    "covid-19_children": "COVID-19 (Niños)",
    "covid-19_children_description": "Niños de 4 a 11 años.",
    "covid-19_infants": "COVID-19 (Bebes)",
    "covid-19_infants_description": "Niños de 6 meses a 3 años.",
    "influenza": "Influenza",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}