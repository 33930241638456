import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

const ActionRow = ({ locales, testGroup, qrCodeUrl }) => {
  const [loading, setLoading] = useState(false);
  let baseUrl = qrCodeUrl.split('?')[0]
  let addlParams = qrCodeUrl.split('?')[1]
  let pdfUrl = `${baseUrl}.pdf?locales=${locales.toString()}${addlParams ? ("&" + addlParams) : "" }`
  let disabled = locales.length ? false : true;

  const printPdf = (url) => {
    setLoading(true);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
  
    iframe.style.display = 'none';
    iframe.onload = function() {
      setLoading(false);
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    iframe.src = url;
  }

  const openPreview = () => {
    window.open(pdfUrl, '_blank').focus();
  }

  return(
    <Row className='align-items-end mt-5 d-print-none'>
      <Col>
        <a href={`/test_groups/${testGroup.slug}`}>Back</a>
      </Col>
      <Col xs="auto">
        <Button variant='outline-secondary'
          className='me-3'
          disabled={disabled}
          onClick={() => { openPreview() }}
        >
          Print preview
        </Button>
        <Button
          variant='primary'
          disabled={disabled || loading}
          onClick={() => printPdf(pdfUrl)}
        >
          { loading && (
            <Spinner
              as="span"
              animation="border"
              variant="secondary"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          Print
        </Button>
      </Col>
    </Row>
  )
}

export default ActionRow;
