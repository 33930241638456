import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import VaccineGroup from './VaccineGroup';
import ImmunizationsFilter from './ImmunizationsFilter';
import { faPlus, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImmunizationsSummaryCard from './ImmunizationsSummaryCard';
import axios from 'axios';
import DownloadImmunizationRecordsModal from './widgets/DownloadImmunizationRecordsModal';
import EditVaccineHistoryModal from './widgets/EditVaccineHistoryModal';
import { format } from 'date-fns';
import { countExemptions, immunizationRecordCount } from '../utils';
import { pluralize } from '../../../bundles/TestGroups/components/Toolbar';


const ShowImmunizations = (initialProps) => {
  const [props, setProps] = useState(initialProps);
  const {
    test_group,
    test_group_user,
    immunization_records,
    pending_statuses,
    exemption_categories,
    exemption_types,
    exemption_options,
    reload_url,
    review_test_group_schools_student_upload_url
  } = props;

  const reload = async () => {
    const response = await axios.get(reload_url);
    setProps(response.data);
  }

  const immunizationRecords = immunization_records;
  const [filteredImmunizationRecords, setFilteredImmunizationRecords] = useState(immunizationRecords);
  const recordCount = filteredImmunizationRecords ? immunizationRecordCount(filteredImmunizationRecords) : 0;
  const exemptionCount = countExemptions(filteredImmunizationRecords);

  const [showDownloadImmunizationRecordsModal, setShowDownloadImmunizationRecordsModal] = useState(false);
  const [vaccineHistoryToEdit, setVaccineHistoryToEdit] = useState("");

  useEffect(() => {
    setFilteredImmunizationRecords(immunizationRecords);
  }, [immunizationRecords]);

  useEffect(() => {
    const toastrMessage = localStorage.getItem('immunizationSuccessMessage');
    if (!!toastrMessage) {
      toastr.success(toastrMessage);
      localStorage.removeItem('immunizationSuccessMessage');
    }
  }, [test_group_user.id]);

  const printImmunizationForm = async (staffInitials) => {
    window.open(`/print_labels/student_immunization_form?staff_initials=${staffInitials}&test_group_id=${test_group.slug}&primary_user_id=${test_group_user.user.id}`, '_blank')
  };

  return <div>
    <DownloadImmunizationRecordsModal
      showDownloadImmunizationRecordsModal={showDownloadImmunizationRecordsModal}
      setShowDownloadImmunizationRecordsModal={setShowDownloadImmunizationRecordsModal}
      onPrintClick={printImmunizationForm}/>
    <ImmunizationsSummaryCard
      immunizationRecords={immunizationRecords}
      testGroup={test_group}
      testGroupUser={test_group_user}
      recordCount={recordCount}
      currentImmunizationStatus={test_group_user.student_profile.immunization_status}
    />
    <h3 className="my-4">Immunization details</h3>
    <ImmunizationsFilter
      allowNonMedicalExemptions={test_group.allow_non_medical_exemptions}
      immunizationRecords={immunizationRecords}
      setFilteredImmunizationRecords={setFilteredImmunizationRecords}
    />
    <Row className="my-4">
      <Col>
        <h5>
          {pluralize(recordCount, "immunization record")}
          {exemptionCount > 0 && ` | ${pluralize(exemptionCount, "exemption")}`}
        </h5>
      </Col>
      <Col xs="auto">
      <Button
          size="sm"
          className="mr-2"
          variant="outline-secondary"
          onClick={() => setShowDownloadImmunizationRecordsModal(!showDownloadImmunizationRecordsModal)}
        >
          Download records
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            className="ms-1"
          />
        </Button>
      </Col>
      <Col xs="auto">
        <Button
          href={review_test_group_schools_student_upload_url}
          size="sm"
          className="mr-2"
          variant="outline-secondary"
        >
          New Immunization
          <FontAwesomeIcon
            icon={faPlus}
            className="ms-1"
          />
        </Button>
      </Col>
    </Row>
    {vaccineHistoryToEdit && (
      <EditVaccineHistoryModal
        reload={reload}
        vaccineHistoryToEdit={vaccineHistoryToEdit}
        setVaccineHistoryToEdit={setVaccineHistoryToEdit}
        test_group={test_group}
        test_group_user={test_group_user}
      />
    )}
    {
      filteredImmunizationRecords && Object.keys(filteredImmunizationRecords).map((key) => {
        const record = filteredImmunizationRecords[key];
        return (
          <VaccineGroup
            exemptionCategories={exemption_categories}
            exemptionOptions={exemption_options}
            exemptionTypes={exemption_types}
            immunizationRecordStatus={record.immunization_record_status}
            key={key}
            onEdit={async (dose) => {
              const vaccinatedAt = format(new Date(dose.date), 'yyyy-MM-dd');
              setVaccineHistoryToEdit({
                vaccinatedAt: vaccinatedAt,
                id: dose.id
              });
            }}
            pendingStatuses={pending_statuses}
            record={record}
            testGroup={test_group}
            testGroupUser={test_group_user}
          />
        );
      })
    }
    <div>
      <a href={`/test_groups/${test_group.slug}/schools/student_profiles`}>Back</a>
    </div>
  </div>
};

export default ShowImmunizations;
