import React from 'react';
import styled from 'styled-components';
import { formatBytes } from '../utils';

export const LoadingDecorator = () => (
  <div className="spinner-border text-primary" role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

export const SuccessDecorator = () => (
  <img src="/images/green-check.svg" alt="Delete icon" style={{ height: 16 }} />
);

export const RemoveButton = ({ onClick }) => (
  <img
    className="flex-shrink-0 pointer"
    src="/images/file-remove.svg"
    alt="Delete icon"
    style={{ width: 12 }}
    onClick={onClick}
  />
);

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .file-name,
  .file-size {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-name {
    max-width: 20ch;
  }

  .spinner-border {
    height: 18px;
    width: 18px;
    border-width: 3px;
  }
`;

const File = ({
  file,
  NameDecoratorComponent: NameDecorator = React.Fragment,
  actions = [],
}) => (
  <FileWrapper className="staged-file">
    <img src="/images/file-check.svg" alt="File icon" style={{ width: 18 }} />
    <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
      <div
        className="d-flex flex-row justify-content-between"
        style={{ gap: 12 }}
      >
        <div className="d-flex flex-row align-items-center" style={{ gap: 8 }}>
          <span className="file-name">{file.name}</span>
          <NameDecorator />
        </div>
        <span className="file-size text-right flex-shrink-0">
          {formatBytes(file.size)}
        </span>
      </div>
    </div>
    {actions.map((action, idx) => (
      <React.Fragment key={idx}>{action}</React.Fragment>
    ))}
  </FileWrapper>
);

export default File;
