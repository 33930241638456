import React from "react";

import FilterChip from "../../common/components/SearchFilters/FilterChip";
import Select, { CheckboxOption } from "../../common/components/Select";

const MessageFilter = ({
  dispatch,
  filterName,
  isDisabled,
  isMulti = false,
  options,
  placeHolder,
  selectedOptions,
  type,
  ...props
}) => {
  return (
    <div className="mt-3">
      <h6 className="body-medium-bold inter semibold">
        {filterName}
      </h6>
      <Select
        blurInputOnSelect={false}
        closeMenuOnSelect={!isMulti}
        controlShouldRenderValue={!isMulti}
        components={isMulti ? { Option: CheckboxOption } : {}}
        hideSelectedOptions={false}
        isClearable={isMulti}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={(values) => {
          dispatch({
            type: type,
            values: values,
          })
        }}
        options={options}
        placeholder={placeHolder}
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: "#A0ABC0",
          }),
        }}
        value={selectedOptions}
        verticallyCenterCheckBox={true}
        {...props}
      />
      {isMulti && !!selectedOptions?.length && (
        <div className="my-3 d-flex flex-row flex-wrap" style={{ gap: "8px 16px" }}>
          {selectedOptions.map((selectedOption) => {
            const label = options.find((availableOption) => availableOption.value === selectedOption)?.label;
            return (
              <FilterChip
                label={label}
                onRemove={isDisabled
                  ? null
                  : () => {
                    dispatch({
                      type: type,
                      values: selectedOptions.filter(o => o !== selectedOption),
                    })
                  }
                }
              />
            )
          })}
        </div>
      )}
    </div>
  );
};

export default MessageFilter;