import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../../../../vendor/assets/css/animate.css"
import { HelpIcon, HomeIcon, MedicalIcon, TestIcon } from '../common/Icons';
import MemberLink from '../common/MemberLink';
import { useContext } from 'react';
import MembersPortalContext from "../../context";
import { MemberIcon } from '../common/Icons';
import { profileColors } from '../utils';

const CONTACT_US = "contact_us";
const HISTORY_ROUTE = "history";
const MAIN_ROUTE = "me";
const CHOOSE_MEMBER = "choose_member";

const MOBILE_STYLE = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "white",
  height: 119,
  border: "1px solid #F5F5F5",
  boxShadow: "0 2px 4px rgb(96 97 112 / 20%)",
  zIndex: 99,
};

const buildLogTestButton = (currentUser, isMobile) => {
  const testIcon = <TestIcon size={isMobile ? 32 : 62}/>;
  const disabledLink = <div className='not-allowed-icon' data-test-hook="program_route">
                    {testIcon}
                  </div>
  if (!currentUser) return disabledLink;

  const selfCheckoutTestGroupUsers = currentUser.test_group_users.filter(
    testGroupUser => testGroupUser.self_checkout_appointment.found
  );

  return selfCheckoutTestGroupUsers.length === 0
    ? disabledLink
    : selfCheckoutTestGroupUsers.length === 1
      ? <a href={selfCheckoutTestGroupUsers[0].self_checkout_appointment.self_checkout_link} data-test-hook="program_route">
          {testIcon}
        </a>
      : <MemberLink to="/members/programs" data-test-hook="program_route">
          {testIcon}
        </MemberLink>
}

const Sidebar = ({onShowMembers}) => {
  const { currentUser, householdMembers, t } = useContext(MembersPortalContext);
  const location = useLocation();
  const routeActive = (route) => (
    location.pathname === '/members/' + route
  );
  const colorIndex = householdMembers.findIndex(x => x.id == currentUser?.id);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const buildMobileIconButton = (route, icon) => (
    <>
      <MemberLink to={`/members/${route}`} data-test-hook={`${route}_route`} style={{height: 35, textDecoration: "none"}}>
        {icon}
      </MemberLink>
      <div className={`text-${(routeActive(route)) ? "primary" : "dark"} small`}>{t('login.route.' + route)}</div>
    </>
  );

  const profile = (
    <div style={{height: 35}}>
      {currentUser?.id
        ? <div
            className='profile-circle'
            style={{
              backgroundColor: profileColors[colorIndex] ? profileColors[colorIndex] : "#2862FA",
            }}
          >
            <div>
              {currentUser.user.first_name[0].toUpperCase()}{currentUser.user.first_name[1]}
            </div>
          </div>
        : <MemberIcon/>
      }
    </div>
  );

  const isArchived = currentUser?.test_group_users && currentUser.test_group_users.filter(
    testGroupUser => testGroupUser.user_id == currentUser.id
  ).archived;

  return isMobile ? (
    <div style={MOBILE_STYLE}>
      <Row className="mt-3 justify-content-around" style={{alignItems: "center"}}>
        <Col className="sidebar-icon ms-2">
          {buildMobileIconButton(MAIN_ROUTE, <HomeIcon active={routeActive(MAIN_ROUTE)} />)}
        </Col>
        <Col className="sidebar-icon">
          {buildMobileIconButton(HISTORY_ROUTE, <MedicalIcon active={routeActive(HISTORY_ROUTE)} />)}
        </Col>
        <Col style={isArchived ? {color: "#717D96", pointerEvents: "none"} : null} className='sidebar-icon'>
          {buildLogTestButton(currentUser, true)}
          <div className="mt-1 small">{t('login.route.programs')}</div>
        </Col>
        <Col className="sidebar-icon">
          {buildMobileIconButton(CONTACT_US, <HelpIcon active={routeActive(CONTACT_US)} />)}
        </Col>
        <Col className='sidebar-icon me-2'>
          {buildMobileIconButton(CHOOSE_MEMBER, profile)}
        </Col>
      </Row>
    </div>
  ) : (
    <aside className="fabrx-aside fabrx-aside-five">
      <div className="fabrx-aside-header">
        <div style={{display: "grid", placeItems: "center"}}>
          <div onClick={onShowMembers} className="pointer" data-test-hook={`${CHOOSE_MEMBER}_route`}>
            {profile}
          </div>
          <div className={`text-${routeActive(CHOOSE_MEMBER) ? "primary" : "dark"} small pb-3`}>{t('login.route.' + CHOOSE_MEMBER)}</div>
        </div>
      </div>
      <div className="fabrx-aside-body fabrx-scroll accordion" data-simplebar>
        <div className="fabrx-menu-category">
          <ul className="list-unstyled menu-category pt-2">
            <li className="sidebar-icon">
              <MemberLink to="/members/me" data-test-hook="health_route" >
                <HomeIcon active={routeActive(MAIN_ROUTE)} />
              </MemberLink>
              <div className={`text-${routeActive(MAIN_ROUTE) ? "primary" : "dark"} pb-3 small`}>{t('login.route.' + MAIN_ROUTE)}</div>
            </li>
            <li className="sidebar-icon">
              <MemberLink to="/members/history" data-test-hook="history_route">
                <MedicalIcon active={routeActive(HISTORY_ROUTE)} />
              </MemberLink>
              <div className={`text-${routeActive(HISTORY_ROUTE) ? "primary" : "dark"} small pb-3`}>{t('login.route.' + HISTORY_ROUTE)}</div>
            </li>
            <li style={isArchived ? {color: "#717D96", pointerEvents: "none"} : null} className='sidebar-icon'>
              {buildLogTestButton(currentUser, false)}
              <div className={`small pb-3`}>{t('login.route.programs')}</div>
            </li>
            <li className="sidebar-icon" >
              <MemberLink to="/members/contact_us">
                <HelpIcon active={routeActive(CONTACT_US)} />
              </MemberLink>
              <div className={`text-${routeActive(CONTACT_US) ? "primary" : "dark"} small pb-3`}>{t('login.route.' + CONTACT_US)}</div>
            </li>
            <li className="sidebar-icon" >
              <MemberLink to="/members/settings">
                <img
                  src="/images/login/settings.svg"
                  height={31}
                  style={routeActive("settings")
                    ? {filter: "invert(29%) sepia(97%) saturate(1039%) hue-rotate(172deg) brightness(82%) contrast(101%)"}
                    : {}
                  } />
              </MemberLink>
              <div className={`text-${routeActive("settings") ? "primary" : "dark"} small pb-3`}>{t('login.route.' + "settings")}</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="fabrx-aside-footer" style={{paddingTop: 0}}>
        <a href="/members/sign_out" className="font-size-12">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <g data-name="Icon/Signout" transform="translate(0)" style={{fill: "black"}}>
              <path style={{fill: "black"}} data-name="Icon Color" d="M-22.5,24H-9.75a1.459,1.459,0,0,0,1.066-.434A1.456,1.456,0,0,0-8.25,22.5V15.727h-1.5v5.859a.884.884,0,0,1-.27.645.883.883,0,0,1-.644.269H-21.586a.844.844,0,0,1-.633-.269.906.906,0,0,1-.258-.645l.023-19.172a.9.9,0,0,1,.258-.644.844.844,0,0,1,.633-.269h10.9a.88.88,0,0,1,.644.269.883.883,0,0,1,.27.644V8.25h1.5V1.5A1.456,1.456,0,0,0-8.683.434,1.459,1.459,0,0,0-9.75,0H-22.5a1.468,1.468,0,0,0-1.055.434A1.436,1.436,0,0,0-24,1.5v21a1.436,1.436,0,0,0,.446,1.067A1.468,1.468,0,0,0-22.5,24Zm17.531-7.219a.743.743,0,0,0,.282-.059,1.069,1.069,0,0,0,.257-.153L0,12.023-4.43,7.476a.744.744,0,0,0-.539-.234.747.747,0,0,0-.539.234A.721.721,0,0,0-5.719,8a.719.719,0,0,0,.211.527l2.719,2.719H-15.727a.733.733,0,0,0-.539.211.736.736,0,0,0-.211.539.745.745,0,0,0,.211.527.712.712,0,0,0,.539.223H-2.742l-2.766,2.742a.783.783,0,0,0-.211.538.784.784,0,0,0,.211.539,1.058,1.058,0,0,0,.258.153A.734.734,0,0,0-4.969,16.781Z" transform="translate(24)" fill="black"/>
            </g>
          </svg>
        </a>
      </div>
    </aside>
  );
};

export default Sidebar;
