import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import DateRangeField from './DateRangeField';
import TablePagination from './TablePagination';
import { Button, Row, Col } from 'react-bootstrap';
import { humanize } from '../utils';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';

const fixISODateFormat = (date) => {
  if(date.length === 10) {
    return date.replace('-', '/');
  }
  return date;
};

const match = (longString, shortString) => {
  return longString.toLowerCase().includes(shortString.toLowerCase());
}

const SimpleSeach = ({fieldName, prompt, options, selected, onChange }) => {
  const [inputValue, setInputValue] = useState(selected);

  const props = {
    className: "basic-single",
    classNamePrefix: "select",
    placeholder: prompt,
    label: prompt,
    defaultValue: options.find((option) => option.value === parseInt(selected) || option.value === selected),
    onChange: (e) => {
      const value = e ? e.value : null;
      setInputValue(value == 0 ? true : value);
      onChange && onChange(value);
    },
    components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
    options: options,
    isSearchable: true,
    isClearable: true,
    name: fieldName,
    filledValue: inputValue,
    openMenuOnClick: false,
    defaultMenuIsOpen: false,
    closeMenuOnSelect: true,
    createOptionPosition: 'first'
  }

  return <CreatableSelect
    formatCreateLabel={(value) => value}
    {...props}
    />
}

const SearchableTable = ({
  title,
  urlPath,
  responseParam,
  columnDateProp,
  columnProps,
  columnHeaders,
  rows,
  lazyRows=function(){return null},
  clickableItem=null,
  clearClickableItem=function(){return null}
}) => {
  const pageLength = 10;
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [table, setTable] = useState([]);
  const [filteredTable, setFilteredTable] = useState([]);
  const [filterCleared, setFilterCleared] = useState(true);
  const [query, setQuery] = useState('');
  const [showQuery, setShowQuery] = useState(false);
  const [options, setOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDates, setShowDates] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', timeZoneName: 'short'}));

  const fetchTable = () => {
    if(reloading) {
      return;
    }
    setReloading(true);
    axios.get(urlPath)
      .then(response => {
        const newTable = response.data[responseParam]

        if(loading) {
          setFilteredTable(newTable)
          setTotalPages(Math.ceil(newTable.length/pageLength));
        }

        setTable(newTable);
        setLastUpdated(currentTime);
        setOptions(searchableOptions(newTable));
        setReloading(false);
        setLoading(false);
      })
      .catch(() => {
        setReloading(false);
        setLoading(false);
        toastr.error('An error occured when loading.');
      })
  };

  const currentTime = () => {
    return new Date().toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', timeZoneName: 'short'});
  };

  const searchableOptions = (newTable) => {
    const keys = filterColumnProps('optionable');
    return  [...new Set(keys.map(key => newTable.map(o => o[key])).flat())]
      .filter(item => item)
      .sort()
      .map(item => { return {label: humanize(item), value: item}; });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newStartDate = e.target.elements.start_date && e.target.elements.start_date.value;
    let newEndDate = e.target.elements.end_date && e.target.elements.end_date.value;
    fetchTable();
    clearClickableItem();

    if(newStartDate || newEndDate) {
      handleDateRange(newStartDate, newEndDate)
    } else {
      setStartDate(null);
      setEndDate(null);
      setShowDates(false);
      filterTable(query, null, null);
    }
  };

  const handleDateRange = (newStartDate, newEndDate) => {
    if(!newStartDate) {
      newStartDate = newEndDate;
    } else if(!newEndDate) {
      newEndDate = newStartDate;
    }

    if(newStartDate.toLowerCase() === 'today' || newEndDate.toLowerCase() === 'today') {
      newStartDate = new Date().toLocaleDateString('en-US');
      newEndDate = new Date().toLocaleDateString('en-US');
    } else {
      newStartDate = new Date(fixISODateFormat(newStartDate)).toLocaleDateString('en-US');
      newEndDate = new Date(fixISODateFormat(newEndDate)).toLocaleDateString('en-US');
    }

    filterTable(query, newStartDate, newEndDate);
  };

  const handleQueryChange = (id) => {
    setShowQuery(false);
    setQuery(id)
  };
  
  const filterTable = (newQuery, newStartDate, newEndDate, newClickableItem=null) => {
    let filtered = table;

    if(newClickableItem) {
      setFilterCleared(false);
      filtered = filtered.filter(row => filterColumnProps('clickable').filter(id => match(row[id], newClickableItem)).length > 0);
    }

    if(newQuery) {
      setQuery(newQuery);
      setShowQuery(true);
      setFilterCleared(false);
      filtered = filtered.filter(row => filterColumnProps('searchable').filter(id => match(row[id], newQuery)).length > 0);
    }

    if(newStartDate && newEndDate) {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setShowDates(true);
      setFilterCleared(false);
      filtered = filtered.filter(row => {
        const created_at = new Date(row[columnDateProp.id]).toLocaleDateString('en-US');
        return created_at >= newStartDate && created_at <= newEndDate;
      });
    }

    if(!newQuery && !newStartDate && !newEndDate && !newClickableItem) {
      setFilterCleared(true);
    }
    
    setPage(1);
    setTotalPages(Math.ceil(filtered.length/pageLength));
    setFilteredTable(filtered);
  };

  const filterColumnProps = (property) => {
    return columnProps.filter(column => column[property]).map(item => item.id);
  };

  const pagedTable = () => {
    const last = page * pageLength;
    const first = last - pageLength;
    return filteredTable.slice(first, last);
  };

  const clearFilter = () => {
    setFilterCleared(true);
    setShowQuery(false);
    setShowDates(false);
    clearClickableItem();
    setTotalPages(Math.ceil(table.length/pageLength));
    setFilteredTable(table);
  };

  const searchDescriptor = () => {
    if(loading) {
      return <div className='px-3' style={{filter: 'blur(8px)'}}>00 total results</div>
    } else {
      return(
        <div className='d-flex'>
          <strong className='px-3'>
            {filterCleared  ? `${table.length} total results` : `${filteredTable.length} results` }
          </strong>
          { !clickableItem && showQuery && query && ` for “${humanize(query)}”` }
          { !clickableItem && showDates && startDate && startDate == endDate ?
            ` Date: ${startDate}`
            :
            !clickableItem && showDates && startDate && endDate && ` Date: “${startDate}” - “${endDate}”`
          }
          { clickableItem && ` with “${clickableItem}”` }
          { filterCleared || <div className='text-primary px-2 pointer' onClick={() => {clearFilter()}}>Clear filters</div> }
        </div>
      )
    }
  };

  useEffect(() => {
    if(loading) {
      fetchTable();
    }
    if(clickableItem) {
      filterTable(null, null, null, clickableItem);
    }
    setOptions(searchableOptions(table));
  }, [clickableItem]);

  return(
    <React.Fragment>
      {title &&
        <Row className='pt-5 px-3'>
          <Col lg='7' className='mb-3'>
            <h2>{title}</h2>
          </Col>
          <div className='col-md text-muted text-end mt-2'>
            {reloading ?
              loading ?
                <div><FontAwesomeIcon icon={faRotate} className="fa-spin me-2 text-primary" /><small style={{filter: 'blur(8px)'}}>Last updated 00:00 PM PST</small></div>
                :
                <div><FontAwesomeIcon icon={faRotate} className="fa-spin me-2 text-primary" /><small>Last updated {lastUpdated}</small></div>
              :
              <small>Last updated {lastUpdated}</small>
            }
          </div>
        </Row>
      }
      <form onSubmit={handleSubmit}>
        <Row className='mb-3 pt-3 pb-3 mx-3 bg-light' style={{ borderRadius: '12px', alignItems: 'center' }}>
          <Col lg='7'>
            <SimpleSeach
              key={`search`}
              prompt='Search'
              fieldName='query'
              options={options}
              selected={query}
              onChange={id => {handleQueryChange(id);}}
            />
          </Col>
          <Col lg='2'>
              <DateRangeField
                start_day_field_name='start_date'
                end_day_field_name='end_date'
                placeholder='Date'
                date_format='MM/DD'
              />
          </Col>
          <Col className='text-end'>
            <Button className='btn btn-secondary mt-1' type='submit'>Search</Button>
          </Col>
        </Row>
      </form>
      { searchDescriptor() }
      { (!filteredTable || filteredTable.length === 0) && (!loading || !reloading) && <p>No records available</p> }
      <section className='fabrx-tables-light table-responsive px-1'>
        <table className='table table-lg'>
          <thead>
            {columnHeaders()}
          </thead>
          <tbody>
            { loading ? lazyRows() : rows(pagedTable()) }
          </tbody>
        </table>
      </section>
      {totalPages > 1 && (
        <TablePagination
          currentPage={page}
          nextPage={page < totalPages ? page + 1 : totalPages}
          prevPage={page > 1 ? page - 1 : 1}
          totalPages={totalPages}
          setPage={setPage}
        />
      )}
    </React.Fragment>
  );
};

export default SearchableTable;
