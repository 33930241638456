import React, { useState } from 'react';
import LocationContext from '../context/LocationContext';

const LOCATION_KEY = 'location_pref';

const setPreviouslySelectedLocation = (location) =>
  sessionStorage.setItem(LOCATION_KEY, location);

const getPreviouslySelectedLocation = () =>
  sessionStorage.getItem(LOCATION_KEY);

const getSelectedLocation = () => {
  var b = document.cookie.match(
    '(^|;)\\s*' + LOCATION_KEY + '\\s*=\\s*([^;]+)',
  );
  const location = b ? unescape(b.pop()) : null;
  if (location) setPreviouslySelectedLocation(location);

  return location;
};

const setLocationCookie = (location) => {
  const now = new Date();
  const expireTime = now.getTime() + 1000 * 3600;
  now.setTime(expireTime);
  const path = window.location.pathname
    .match(/\/test_groups\/[a-z0-9]+(?:[a-z0-9-_]+)*/)
    .pop();
  document.cookie = `${LOCATION_KEY}=${location};expires=${now.toUTCString()};path=${path};SameSite=Strict;`;
  setPreviouslySelectedLocation(location);
};

export const LocationContextProvider = ({ ...props }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    getSelectedLocation(),
  );

  const handleNewSelectedLocation = (location) => {
    setSelectedLocation(location);
    setLocationCookie(location);
  };

  // Only expose if selected location preference expired
  const previouslySelectedLocation =
    !selectedLocation && getPreviouslySelectedLocation();

  return (
    <LocationContext.Provider
      value={{
        selectedLocation,
        previouslySelectedLocation,
        setSelectedLocation: handleNewSelectedLocation,
      }}
      {...props}
    />
  );
};
