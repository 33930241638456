import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sum } from '../../common/utils';

import { formatAndLocalizeDateTime } from '../../common/locales/utils';
import MoreDatesModal from './MoreDatesModal';

const AppointmentDateCarousel = ({
  appointmentSlotsByDates,
  className,
  displayTotalNumberOfAppointments = false,
  onClick,
  selectedDay,
  t,
}) => {

  const appointmentSlotDates =  Object.keys(appointmentSlotsByDates).sort((a,b) => new Date(a) - new Date(b)),
    [dateIndex, setDateIndex] = useState(Math.max(appointmentSlotDates.indexOf(selectedDay) - 2, 0)),
    currentDates = appointmentSlotDates.slice(dateIndex, dateIndex + 5),
    [moreDatesModalOpen, setMoreDatesModalOpen] = useState(false);

    const monthLabel = formatAndLocalizeDateTime(
      new Date(appointmentSlotsByDates[currentDates[0]]?.appointment_slots[0]?.localized_starts_at_date),
      "MMMM"
    );

    const monthBadge = (
      monthLabel && (
        <div className="body-medium-bold inter bold">
          {monthLabel.toUpperCase()}
        </div>
      )
    );

    const moreDatesButton = (
      <Button
        className="text-decoration-underline"
        onClick={() => setMoreDatesModalOpen(true)}
        size="sm"
        variant="link"
      >
        More dates
      </Button>
    );

  return (
    <>
      <MoreDatesModal
        appointmentSlotDates={appointmentSlotDates}
        appointmentSlotsByDates={appointmentSlotsByDates}
        onHide={() => setMoreDatesModalOpen(false)}
        selectedDay={selectedDay}
        setDateIndex={setDateIndex}
        setSelectedDay={onClick}
        show={moreDatesModalOpen}
        t={t}
      />
      <div className="d-flex justify-content-between d-sm-none">
        {monthBadge}
        {moreDatesButton}
      </div>
      <Row className={`justify-content-center family-slots py-2 ${className}`}>
        <Col xs="auto" className='d-none d-sm-block align-self-center pointer'>
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={dateIndex === 0 ? { opacity: 0 } : undefined}
            onClick={() => setDateIndex(dateIndex - 5 || 0)}
          />
        </Col>
        {currentDates.map((day, idx) => {
          const appointmentSlotData = appointmentSlotsByDates[day];
          const totalNumberOfAppointments = sum(appointmentSlotData.appointment_slots.map(s => s.capacity));
          const localizedDay = formatAndLocalizeDateTime(new Date(appointmentSlotData.appointment_slots[0].localized_starts_at_date), 'eee, MM/dd');
          return (
            <Col xs={'auto'} key={idx} className="place-self-end"> 
              {idx == 0 && (
                <div className="d-none d-sm-block mb-2">
                  {monthBadge}
                </div>
              )}
              {idx === 4 && appointmentSlotDates.length > 5 && (
                <div className="text-end d-none d-sm-block my-1">
                  {moreDatesButton}
                </div>
              )}
              <Card
                style={{ maxWidth: 115 }}
                body
                data-test-hook={`day_${idx}`}
                className={
                  selectedDay == day
                    ? 'border border-primary text-primary pointer text-center gray-light-100'
                    : 'text-center pointer'
                }
                onClick={() => onClick(day)}
              >
                <div className="inter medium body-small-regular">{localizedDay || day}</div>
                <div
                  className={`body-small-regular ${
                    selectedDay == day
                      ? 'text-primary'
                      : 'text-muted'
                  }`}
                >
                  {displayTotalNumberOfAppointments
                    ? totalNumberOfAppointments
                    : appointmentSlotData.count
                  } {t("family_registrations.appointments_abbreviated")}
                </div>
              </Card>
            </Col>
          );
        })}
        <Col xs="auto" className='d-none d-sm-block align-self-center pointer'>
          <FontAwesomeIcon
            icon={faArrowRight}
            style={(dateIndex + 5) >= appointmentSlotDates.length ? {opacity: 0} : undefined}
            onClick={() => setDateIndex(dateIndex + 5)}
          />
        </Col>
      </Row>
    </>
  );
};

export default AppointmentDateCarousel;