import React, { useContext }  from 'react';
import { Link } from "react-router-dom";
import MembersPortalContext from '../../context';

const MemberLink = ({to, children, ...props}) => {
  const { currentUser } = useContext(MembersPortalContext);
  return (
    <Link to={currentUser?.id ? `${to}?current_user=${currentUser.id}` : to} {...props} >
      {children}
    </Link>
  );
};

export default MemberLink;
