import React, { useState } from "react";

import TestConfigurationActions from "./TestConfigurationActions";
import TestConfigurationsIndex from "./TestConfigurationsIndex";

const TestConfigurationsContainer = ({
  allowed_test_groups,
  meta,
  test_configurations,
}) => {

  const [selectedTestConfigurationIds, setSelectedTestConfigurationIds] = useState([]);

  return (
    <div>
      {!!selectedTestConfigurationIds.length && (
        <TestConfigurationActions
          allowedTestGroups={allowed_test_groups}
          selectedTestConfigurationIds={selectedTestConfigurationIds}
          testConfigurations={test_configurations}
          totalCount={meta.total_count}
        />
      )}
      <TestConfigurationsIndex
        meta={meta}
        selectedTestConfigurationIds={selectedTestConfigurationIds}
        setSelectedTestConfigurationIds={setSelectedTestConfigurationIds}
        testConfigurations={test_configurations}
      />
    </div>
  );
};

export default TestConfigurationsContainer;