import React, { useState } from 'react';
import Select from 'react-select';
import {
  Table,
  Checkbox,
  Dropdown,
  Alert,
  InputGroup,
  FormControl,
  Row,
  Col,
  Button,
  Form
} from 'react-bootstrap';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import axios from 'axios';
import MergeTagsModal from './MergeTagsModal';
import ConfirmModal from '../../common/components/ConfirmModal';
import AffectedUsers from './AffectedUsers';
import ActionCableSubscriber from '../../common/components/ActionCableSubscriber';

const TagsTable = ({ tags, test_group_id, can_delete }) => {
  const [tagMap, setTagMap] = useState(
    Object.fromEntries(tags.map((tag) =>
      [tag.id, false]
    ))
  );
  const [showMergeSelected, setShowMergeSelected] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search).get('tag_q')
  );

  const setAllTags = (value) => {
    setTagMap(
      Object.fromEntries(Object.keys(tagMap).map((id) =>
        [id, value]
      ))
    );
  }

  const onToggleSelectAll = (selected, totalCount) => {
    if ((selected > 0 && selected < totalCount) || selected === 0) {
      setAllTags(true)
    } else {
      setAllTags(false)
    }
  }

  const selectedCount = (tagMap) => {
    return Object.keys(Object.entries(tagMap).filter((tag) => tag[1])).length;
  }

  const selectedIds = () => {
    return Object.entries(tagMap).filter((t) => t[1]).map((t) => t[0]);
  }

  const onDeleteSelected = () => {
    const selected_ids = selectedIds();
    axios.post(`/test_groups/${test_group_id}/tags/bulk_destroy`, { selected_ids }).then((response) => {
      setShowConfirmDelete(false);
      showMessage('Tags deleted');
      setTimeout(function(){ window.location.reload(); }, 1000);
    })
    .catch(() => {
      setError("There was an issue deleting tags");
      setShowConfirmDelete(false);
    });
  }

  const onMergeSelected = () => {
    setShowMergeSelected(true);
  }

  const onSearch = (selected, e) => {
    if (e) { e.preventDefault(); }
    const path = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('tag_q');
    urlParams.delete('page');
    if (query) {
      urlParams.set('tag_q', query)
    }
    window.location.href = `${path}?${urlParams.toString()}`;
  };

  const renderTagSearch = () => {
    return (
      <Form onSubmit={(e) => onSearch(query, e)}>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by tag name"
            aria-label="Search by tag name"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <Button variant="outline-secondary" onClick={() => onSearch(query)}>Search</Button>
        </InputGroup>
      </Form>
    )
  }

  const renderActionsDropdown = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={selectedIds().length === 0}>
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => setShowConfirmDelete(true)}>
            Delete selected
          </Dropdown.Item>
          <Dropdown.Item
            disabled={selectedCount(tagMap) < 1}
            onClick={onMergeSelected}>
            Merge selected tags
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const showMessage = (message) => {
    setStatusMessage(message);
    setTimeout(() =>{
      setStatusMessage('');
    }, 10000);
  }

  const handleReceived = (data) => {
    showMessage(data.message);
  }

  return (
    <>
      <ActionCableSubscriber channel="BackgroundJobsChannel" onReceived={handleReceived} />
      {(statusMessage && statusMessage.length > 0) && <div className="active-cable-message">{statusMessage}</div>}
      {error && <Alert variant="danger">{error}</Alert>}
      <MergeTagsModal
        show={showMergeSelected}
        selectedTags={tags.filter((tag) => selectedIds().includes(`${tag.id}`))}
        onHideModal={() => setShowMergeSelected(false)}
        setError={setError}
        showMessage={showMessage}
        testGroupId={test_group_id}
      />
      <ConfirmModal
        show={showConfirmDelete}
        headerText="Delete tags"
        body={showConfirmDelete ? <AffectedUsers selected_ids={selectedIds()} /> : ""}
        confirmButtonText="Delete"
        onConfirm={onDeleteSelected}
        onCancel={() => setShowConfirmDelete(false)}
      />
      <Row>
        <Col>
          {renderTagSearch()}
        </Col>
        {can_delete && <Col>
          {renderActionsDropdown()}
        </Col>}
      </Row>
      <section className="fabrx-tables-light">
        <Table responsive className="table-lg">
          <thead>
            <tr>
              {can_delete && <th>
                <FabrxCheckbox
                  onChange={() => onToggleSelectAll(selectedCount(tagMap), tags.length)}
                  checked={selectedCount(tagMap) === tags.length}
                />
              </th>}
              <th>#</th>
              <th>Friendly name</th>
              <th>Description</th>
              <th>Show on registration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tags.map((tag) =>
              <tr key={tag.id}>
                {can_delete && <td>
                  <FabrxCheckbox
                    onChange={(selected) => {
                      setTagMap({
                        ...tagMap,
                        [tag.id]: !tagMap[tag.id]
                      })
                    }}
                    checked={tagMap[tag.id]}
                  />
                </td>}
                <td><b>{tag.id}</b></td>
                <td>{tag.friendly_name}</td>
                <td>{tag.description}</td>
                <td>{tag.show_on_registration ? 'Yes' : 'No'}</td>
                <td><a href={`/test_groups/${test_group_id}/tags/${tag.id}/edit`}>Edit</a></td>
              </tr>
            )}
          </tbody>
        </Table>
      </section>
    </>
  );
};

export default TagsTable;
