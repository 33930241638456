import React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap'


const DeleteSearchFilterModal = ({
  selectedFilter,
  testGroup,
  setShowDeleteSearchFilterModal,
  source_url,
}) => {

  const deleteSearchFilter = async (filterId) => {
    return axios.delete(`/test_groups/${testGroup.id}/search_filters/${filterId}`);
  }

  return (
    <div className='px-4 py-5'>
      <h3>Delete {selectedFilter.name} filter?</h3>
      <div className='d-flex justify-content-end my-3'>
        <Button
          variant='outline-primary'
          className='mx-2'
          onClick={() => setShowDeleteSearchFilterModal(false)}
        >
          No, go back
        </Button>
        <Button
          className='mx-2'
          onClick={ async () => {
            try {
              const response = await deleteSearchFilter(selectedFilter.id);
              if (response.status === 200) {
                toastr.success(response.data.message);
                window.location = `/test_groups/${testGroup.slug}/${source_url}`;
              } else {
                toastr.error('Something went wrong.');
              }
            } catch(e) {
              toastr.error('Something went wrong.');
            }
            setShowDeleteSearchFilterModal(false);
          }}
        >
          Yes, delete filter
        </Button>
      </div>
    </div>
  )
}

export default DeleteSearchFilterModal;
