import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Button, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';

const EnterCode = ({onComplete, appointmentRecovery, onError, onResend, loggedIn=false, titleKey="code"}) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendOk, setResendOk] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t, i18n } = useTranslation();

  const timerRenderer = ({ seconds, completed, api }) => {
    if (completed) {
      setResendOk(true);
      if (!api.isStopped()) api.stop();
      return <div></div>;
    } else {
      const secondsFormatted = ('0' + seconds).slice(-2);
      return <div className='text-muted'>Resend code in {secondsFormatted} seconds</div>;
    }
  };

  return (
    <div data-test-hook='enter_code'>
      <div className="h3 mb-4">{t(`enter_code.${titleKey}`)}</div>
      <div className="mb-4">{t("enter_code.enter_code")} <b>{appointmentRecovery.phone_number ? appointmentRecovery.phone_number : appointmentRecovery.email}</b></div>
      <FormControl
        size="lg"
        autoFocus
        value={code}
        onChange={(e) => {
          setCode(e.target.value)
          setHasError(false);
        }}
        isInvalid={hasError}
        maxLength={6}
        placeholder="000000"
        inputMode="numeric"
        className="px-3 w-100"
        required={true}
        style={{width: "110px"}}
        data-test-hook="code"
      />
      {hasError && <div className="text-danger">{t("enter_code.incorrect_code")}</div>}
      <div className="mb-3"></div>
      <Button disabled={loading} block data-test-hook="continue" onClick={async () => {
        try {
          setLoading(true);
          let body = loggedIn
            ? {code: code, filters: {
                test_groups_with_login_enabled: true,
                users_with_no_household: true,
              }}
            : {code: code};
          const response = await axios.put(`/finder/${appointmentRecovery.id}/validate.json`, body);
          onComplete(response.data);
        } catch(error) {
          setLoading(false)
          if (error?.response?.data?.error === 'incorrect_code') {
            setHasError(true);
          } else {
            onError()
          }
        }
      }}>
        {t("enter_code.verify")}
      </Button>
      <Button disabled={!resendOk} variant="outline-primary" block data-test-hook="resend" className='mt-3' onClick={async () => {
        try {
          const response = await axios.post(`/finder/`, {
            appointment_recovery: appointmentRecovery
          });
          onResend(response.data.appointment_recovery);
          setResendOk(false);
        } catch (error) {
          setResendOk(true);
        }
      }}>
        {t("instructions.resend")}
      </Button>
      {!resendOk && <Countdown
        autoStart={true}
        date={Date.now() + 30 * 1000}
        controlled={false}
        renderer={timerRenderer}
      />}
    </div>
  )
};

export default EnterCode;
