import React from 'react';

const FabrxCheckbox = ({
  id = `checkbox-${Date.now()}-${Math.floor(Math.random() * 100)}`,
  name,
  label,
  checked,
  onChange,
  radio,
  className,
  labelClassName = "",
  inputClassName = "",
  ariaLabel,
  value=false,
  defaultChecked=false,
  autoFocus = false,
  checkMarkStyle=true,
  dataTestHook,
  disabled=false,
}) => {
  const inputProps = { id, checked, defaultChecked, autoFocus, name, className: `form-check-input ${inputClassName}`, disabled };
  if (value) inputProps.value = value;

  return (
    <div className={`form-check ${radio && 'form-check-rounded'} ${checkMarkStyle && 'radio-has-check'} ${className}`}>
      <input
        type={radio ? 'radio' : 'checkbox'}
        data-test-hook={dataTestHook || name}
        aria-label={ariaLabel}
        onChange={(e) => {
          const checked = e.target.value === 'on';
          onChange(checked);
        }}
        {...inputProps}
      />
      <label className={`form-check-label ${labelClassName}`} htmlFor={id} data-test-hook={`${dataTestHook || name}_label`}>
        {label}
      </label>
    </div>
)};

export default FabrxCheckbox;
