import React, { useContext } from "react";
import OtcContext from '../contexts/OtcContext';
import FabrxCheckbox from '../../Registration/primary/FabrxCheckbox';
import { Card } from "react-bootstrap";
import { format } from 'date-fns';
import { MultiplexResults } from "./OtcSummary";

const OtcConfirmation = () => {

  const {
    t,
    result,
    user,
    certifyCheckboxChecked,
    setCertifyCheckboxChecked,
    setNextButtonDisabled,
    stepNumber,
    resultDate,
    resultTime,
    allowSelfResulting,
    barcode,
    testConfiguration,
    isTestMultiplex,
    multiplexSubTests,
    multiplexResult,
  } = useContext(OtcContext);

  const resultColor = {
    "positive": "red",
    "negative": "green",
    "did_not_result": "",
  }

  return (
    <div>
      <div>
        <h4>{stepNumber}. {allowSelfResulting ? t("otc.confirm_result") : t("otc.confirm_information")}</h4>
        <div className="m-2 my-4">
          <Card className="p-4">
            <div>
              <b>{t("otc.name")} </b>{user.full_name}
            </div>
            {allowSelfResulting ? (
              <>
                <div>
                  <b>{t("otc.date")} </b>{format(new Date(`${resultDate.replace(/-/g, "/")} ${resultTime}`), 'MMMM dd, yyyy')}
                  {resultTime && ` at ${format(new Date(`${resultDate.replace(/-/g, "/")} ${resultTime}`), 'h:mm aaa')}`}
                </div>
                <div>
                {isTestMultiplex ? (
                  <MultiplexResults
                    multiplexSubTests={multiplexSubTests}
                    multiplexResult={multiplexResult}
                    simplifiedSummary={true}
                    testConfiguration={testConfiguration}
                  />
                ) : (
                  <b>{t("otc.result")}<span style={{ color: resultColor[result] }}> {t(`employer_testing.results.${result}`)}</span></b>
                )}
                </div>
              </>
            ) : (
              <>
                <div>
                  <b>{t("otc.date")} </b>{format(new Date(), 'MMMM dd, yyyy')} at {format(new Date(), 'h:mm aaa')}
                </div>
                {barcode && (
                  <div>
                    <b>{t("otc.barcode")} </b><span>{barcode}</span>
                  </div>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
      <FabrxCheckbox
        checked={certifyCheckboxChecked}
        className="my-4"
        label={t("ihealth.confirm_information_correct")}
        name="confirminformation"
        onChange={() => {
          setCertifyCheckboxChecked(!certifyCheckboxChecked)
          setNextButtonDisabled(certifyCheckboxChecked)
        }}
      />
    </div>
  )
}

export default OtcConfirmation;