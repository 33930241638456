import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button  } from 'react-bootstrap';
import LocalizedMarkdown from './fields/LocalizedMarkdown';
import isMobilePhone from '../../shared/PhoneValidator'
import {
  ageInMonthsRemainder,
  ageInMonthsYear,
  calculateAgeInDays,
  calculateAgeInMonths,
  validateEmail,
} from '../../../common/utils';
import Primary from '../../../../primary/index';
import RegistrationContext from '../../contexts/RegistrationContext';
import {
  buildCustomizedLabel,
  buildLocalizedString,
} from '../../../common/locales/langs';
import AddressFields from './fields/AddressFields';
import OldDateOfBirthInput from './inputs/OldDateOfBirthInput';
import UserAlreadyExistsModal from './fields/UserAlreadyExistsModal';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { countryCodeObject } from '../../../common/utils';
import { showFieldOnRegistration } from './utils';
import PhoneAndEmailFields from './fields/PhoneAndEmailFields';
import { SectionTitle } from '../SectionComponents';
import IdScanner from '../../../common/components/IdScanner';
import Flipper from '../../../common/components/Flipper';

const MINIMUM_CHARS_IN_NAME = 2;

const formatDateNumber = (num) => (num.length === 1 ? '0' + num : num);

export const requiredByTestGroup = (testGroup, variable) =>
  testGroup.fields_required.includes(variable);

export const isAccessCodeRegistration = (location) => {
  let regex = /^\/a\/[a-zA-Z0-9_-]+\/new$/i;
  let instructionRegex = /^\/t\/[a-zA-Z _-]+\/u\/[a-zA-Z0-9]+$/i;
  if (regex.test(location)) {
    return true;
  }
  return instructionRegex.test(location);
}

export const onAfterStepValidateContactInformation = (values, context) => {
  if (context.localFormState.alreadyShownDuplicateUserModal || isAccessCodeRegistration(window.location.pathname)) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
  const date_of_birth = [
    values['date_of_birth_datetime(1i)'],
    formatDateNumber(values['date_of_birth_datetime(2i)']),
    formatDateNumber(values['date_of_birth_datetime(3i)']),
  ].join('-');
  const first_name = values.first_name;
  const last_name = values.last_name;
  const email = values.email;
  const phone_number = values.phone_number;
  const phone_number_country_code = values.phone_number_country_code;

  return new Promise((resolve, reject) => {
    axios
      .post(`/api/test_groups/${context.testGroup.id}/fuzzy_booking_search`, {
        user: {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: phone_number,
          date_of_birth: date_of_birth,
          phone_number_country_code: phone_number_country_code,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.resolved) {
          context.setLocalFormState({
            ...context.localFormState,
            showUserAlreadyExistsModal: true,
            alreadyShownDuplicateUserModal: true,
            overlappingContactInformation: data.overlapping_contact_information,
            duplicationType: data.duplication_type,
            currentUserPresent: data.current_user_present,
          });
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch((e) => {
        reject(Error(e));
      });
  });
};

const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day);
  return d.getFullYear() == year && d.getMonth() == month && d.getDate() == day;
};

const checkPassword = (population, password, errors) =>
  new Promise((resolve) => {
    axios
      .put(`/api/check_population_password/${population.id}`, {
        password,
      })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        errors.password = 'registration.errors.regex_invalid';
        resolve(true);
      });
  });

export const phoneOrEmailRequired = (testGroup) => {
  let noneRequired = !testGroup.fields_requested.includes('phone_number')
                      && !testGroup.fields_requested.includes('email')
                      && !requiredByTestGroup(testGroup, 'phone_number')
                      && !requiredByTestGroup(testGroup, 'email')
  return noneRequired || requiredByTestGroup(testGroup, 'phone_or_email')
}

export const validatePhoneAndEmailInformation = (testGroup) => (values) => {
 const errors = {};

  if (phoneOrEmailRequired(testGroup) && values.phone_or_email) {
    if (isMobilePhone(values.phone_or_email)) {
      values.phone_number = values.phone_or_email;
    } else if (validateEmail(values.phone_or_email)) {
      values.email = values.phone_or_email;
    } else {
      errors.phone_or_email = 'registration.errors.regex_invalid';
    }
  }

  if (requiredByTestGroup(testGroup, 'phone_number') && !values.phone_number) {
    errors.phone_number = 'registration.errors.field_required';
  } else if (!values.phone_number_country_code || values.phone_number_country_code === "US") {
    if (values.phone_number && !isMobilePhone(values.phone_number, ["en-US"])) {
      errors.phone_number = 'registration.errors.phone_number_invalid';
      if (phoneOrEmailRequired(testGroup)) errors.phone_or_email = 'registration.errors.phone_number_invalid'
    }
  } else if (values.phone_number_country_code && values.phone_number_country_code != "US") {
    let countryCode = values.phone_number_unreachable ? "any" : countryCodeObject[values.phone_number_country_code]
    if (values.phone_number  && !isMobilePhone(values.phone_number.replace(/[- )(]/g,''), countryCode)) {
      errors.phone_number = 'registration.errors.phone_number_invalid';
      if (phoneOrEmailRequired(testGroup)) errors.phone_or_email = 'registration.errors.phone_number_invalid'
    }
  }

  if (values.phone_number_unreachable && !values.email && showFieldOnRegistration(testGroup, "email")) errors.email = "registration.errors.field_required";
  if (requiredByTestGroup(testGroup, 'email') && !values.email) errors.email = 'registration.errors.field_required';
  if (requiredByTestGroup(testGroup, 'phone_number') && !values.phone_number) errors.phone_number = 'registration.errors.field_required';
  if (phoneOrEmailRequired(testGroup) && !values.phone_or_email) errors.phone_or_email = 'registration.errors.field_required';

  if (values.email) {
    if (!!testGroup.population.email_domain) {
      const emailDomains = testGroup.population.email_domain
        .split(',')
        .map((e) => e.trim());
      if (
        !emailDomains.some((domain) =>
          values.email.toUpperCase().includes(`@${domain.toUpperCase()}`),
        )
      ) {
        errors.email = 'registration.errors.email_domain';
        if (phoneOrEmailRequired(testGroup)) errors.phone_or_email = 'registration.errors.email_domain'
      }
    } else if (!!testGroup.email_domain) {
      const emailDomains = testGroup.email_domain
        .split(',')
        .map((e) => e.trim());
      if (
        !emailDomains.some((domain) =>
          values.email.toUpperCase().includes(`@${domain.toUpperCase()}`),
        )
      ) {
        errors.email = 'registration.errors.email_domain';
        if (phoneOrEmailRequired(testGroup)) errors.phone_or_email = 'registration.errors.email_domain'
      }
    }
    if (!validateEmail(values.email)) {
      errors.email = 'registration.errors.regex_invalid';
      if (phoneOrEmailRequired(testGroup)) errors.phone_or_email = 'registration.errors.email_domain'
    }
  }

  if (!values.phone_number && !values.email) {
    errors.email = 'registration.errors.field_required';
  }

  return errors;
}

export const validateContactInformation = (testGroup) => (values) => {
  const errors = testGroup.verify_contact_information
    ? {}
    : validatePhoneAndEmailInformation.bind(null, testGroup)()(values);
  if (!values.first_name)
    errors.first_name = 'registration.errors.field_required';
  if (values.first_name && values.first_name.length < MINIMUM_CHARS_IN_NAME)
    errors.first_name = 'registration.errors.minimum_length'; 
  if (
    !RegExp(/^([^0-9]*)$/).test(values.first_name) ||
    !RegExp(/^([^0-9]*)$/).test(values.last_name)
  )
    errors.first_name = 'No numbers in name allowed';
  if (!values.last_name)
    errors.last_name = 'registration.errors.field_required';
  if (values.last_name && values.last_name.length < MINIMUM_CHARS_IN_NAME)
    errors.last_name = 'registration.errors.minimum_length'; 

  if (testGroup.request_mailing_address) {
    if (!values.address_1)
      errors.address_1 = 'registration.errors.field_required';
    if (!values.city) errors.city = 'registration.errors.field_required';

    const verifyUSAddress =
      testGroup.address_type === 'us_address' ||
      (testGroup.address_type === 'global' && !values.non_us_address);
    if (verifyUSAddress) {
      if (!RegExp('^[A-Za-z]{2}$').test(values.state))
        errors.state = 'registration.errors.state';
      if (
        !RegExp(
          "^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$",
        ).test(values.city)
      )
        errors.city = 'registration.errors.city';
      if (!RegExp('^[0-9]{5}(-[0-9]{4})?$').test(values.postal_code))
        errors.postal_code = 'registration.errors.postal_code';

      if (!values.state) errors.state = 'registration.errors.field_required';
      if (!values.postal_code)
        errors.postal_code = 'registration.errors.field_required';
    } else {
      if (!values.country)
        errors.country = 'registration.errors.field_required';
    }
    // Validate the lat long is within the bounds
    const validated = Primary.evaluateLocationRules(
      {
        point: [values.lat, values.lng],
        address_1: values.address_1,
        county: values.county,
        postal_code: values.postal_code,
        state: values.state,
        city: values.city,
      },
      testGroup.access_rules.concat(testGroup.population.access_rules),
    );
    if (!validated) {
      errors.address_1 = 'required.errors.address_not_in_bounding_box';
      errors.addressDoesNotQualify = true;
    }
  }

  if (testGroup.population.employee_id_regex && !RegExp(testGroup.population.employee_id_regex).test(values.employee_id)) errors.employee_id = 'registration.errors.regex_invalid';

  if (testGroup.tags.length > 0 && values.tags.length === 0)
    errors.tag = 'registration.errors.field_required';

  const year = values['date_of_birth_datetime(1i)'];
  const month = values['date_of_birth_datetime(2i)'] - 1;
  const day = values['date_of_birth_datetime(3i)'];
  if (
    values['date_of_birth_datetime(2i)'] &&
    values['date_of_birth_datetime(3i)'] &&
    values['date_of_birth_datetime(1i)']
  ) {
    if (!isValidDate(year, month, day)) {
      errors.date_of_birth = 'registration.errors.date_invalid';
    }
    if(`${year}`.length !== 4) {
      errors.date_of_birth = "registration.errors.year_length_invalid"
    }
    if(day > 31) {
      errors.date_of_birth = "registration.errors.invalid_day"
    }
    const dateOfBirth = new Date(year, month, day);
    const ageInDays = calculateAgeInDays(dateOfBirth);
    const ageInMonths = calculateAgeInMonths(dateOfBirth);

    const hasAgeReq = (container, key) =>
      container[key] || container[key] === 0;

    if (testGroup.calculate_age_gating_in_days) {
      if ((hasAgeReq(testGroup, 'minimum_age_in_days') && ageInDays < testGroup.minimum_age_in_days) ||
          (hasAgeReq(testGroup, 'maximum_age_in_days') && ageInDays > testGroup.maximum_age_in_days)) {
        errors.date_of_birth = 'registration.errors.age_requirement';
      }
    } else {
      if ((hasAgeReq(testGroup, 'minimum_age_in_months') && ageInMonths < testGroup.minimum_age_in_months) ||
          (hasAgeReq(testGroup, 'maximum_age_in_months') && ageInMonths > testGroup.maximum_age_in_months)) {
        errors.date_of_birth = 'registration.errors.age_requirement';
      }
    }

    if (testGroup.population.calculate_age_gating_in_days) {
      if ((hasAgeReq(testGroup.population, 'minimum_age_in_days') && ageInDays < testGroup.population.minimum_age_in_days) ||
          (hasAgeReq(testGroup.population, 'maximum_age_in_days') && ageInDays > testGroup.population.maximum_age_in_days)) {
        errors.date_of_birth = 'registration.errors.age_requirement';
      }
    } else {
      if ((hasAgeReq(testGroup.population, 'minimum_age_in_months') && ageInMonths < testGroup.population.minimum_age_in_months) ||
          (hasAgeReq(testGroup.population, 'maximum_age_in_months') && ageInMonths > testGroup.population.maximum_age_in_months)) {
        errors.date_of_birth = 'registration.errors.age_requirement';
      }
    }
  } else {
    errors.date_of_birth = 'registration.errors.field_required';
  }

  if (!testGroup.population.has_password_regex) {
    return errors;
  } else {
    return checkPassword(testGroup.population, values.password, errors).then(
      () => {
        return errors;
      },
    );
  }
};

const MinimumAgeNotice = ({ testGroup }) => {
  const { t } = useTranslation();

  if (testGroup.calculate_age_gating_in_days && testGroup.population.calculate_age_gating_in_days) {
    return <span></span>
  } 

  const minimumAgeInMonths =
    testGroup.population.minimum_age_in_months ||
    testGroup.minimum_age_in_months;
  const monthRemainder = ageInMonthsRemainder(minimumAgeInMonths);
  const translationKey = !minimumAgeInMonths
    ? 'registration.no_minimum_age'
    : !monthRemainder
    ? 'registration.minimum_age'
    : 'registration.minimum_age_with_months';

  return (
    <Col xs={12} className="text-secondary mb-2">
      {t(translationKey, {
        year: ageInMonthsYear(minimumAgeInMonths),
        month: monthRemainder,
      })}
    </Col>
  );
};

const ContactInformation = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  setValues,
}) => {
  const { t, i18n } = useTranslation();
  const [showScan, setShowScan] = useState(false)
  const { testGroup, localFormState } = useContext(RegistrationContext);
  const urlParams = new URLSearchParams(window.location.search);
  const dateFieldRef = useRef(null)
  const stateFieldRef = useRef(null)

  useEffect(() => {
    const fieldId = [
      'first_name',
      'last_name',
      'date_of_birth',
      'phone_number',
      'address_1',
      'city',
      'state',
      'postal_code',
      'country',
      'email',
      'tag',
    ].find((id) => !!errors[id]);

    const elementIdMap = {
      date_of_birth: dateFieldRef.current,
      address_1: 'user-address-1',
      city: 'user-city',
      postal_code: 'user-postal-code',
      tag: 'tags',
      state: stateFieldRef.current
    };

    if (fieldId) {
      const elementId = elementIdMap[fieldId] ? elementIdMap[fieldId] : fieldId;
      const element = typeof elementId === "string" ? document.getElementById(elementId) : elementId
      element?.focus();
    }
  }, [errors]);

  const tagOptions = testGroup.tags.map(tag => ({ value: tag.id, label: tag.friendly_name }));
  const tagLabel = buildLocalizedString(testGroup, 'registration_tag_text');

  return (
    <div className="form-section">
      <SectionTitle>
        {t('registration.patient_information')}
      </SectionTitle>
      <Flipper record={testGroup} flag="scandit_id_scanner">
        <Flipper.Enabled>
          <div className="my-2">
            {showScan
              ? <IdScanner onScan={(v) => setValues({...values, ...v})} /> 
              : <Button onClick={() => setShowScan(true)}>{t('registration.scan_license')}</Button>
            }
          </div>
        </Flipper.Enabled>
      </Flipper>
      {localFormState.showUserAlreadyExistsModal && <UserAlreadyExistsModal />}
      <LocalizedMarkdown
        container={testGroup}
        stringKey="contact_information_helper_text"
      />
      {urlParams.get('access_code') && (
        <div>
          {t(buildLocalizedString(testGroup, 'access_code_label')) ||
            'Access Code'}
          : <span className="fw-bold">{urlParams.get('access_code')}</span>
        </div>
      )}
      <Row className="mb-3" as="fieldset">
        <legend className="lead-20-medium inter bold">{t('instructions.name')}</legend>
        <Col xs={12} md={5}>
          <FloatingLabelInput
            name="first_name"
            autoFocus={true}
            className="my-2"
            value={values.first_name || ''}
            onChange={handleChange}
            readOnly={!!values.disabled_fields.first_name}
            ariaRequired="true"
            ariaLabel="first name"
            minLength={2}
            ariaDescribedby={errors.first_name ? "firstNameErrorDesc" : undefined}
            ariaInvalid={!!errors.first_name}
            id="first_name_input"
            label={
              t(
                buildCustomizedLabel(
                  testGroup.population,
                  'registration.first_name_label',
                ),
              ) + ' *'
            }
            validation={errors.first_name && 'is-invalid'}
          />
          {errors.first_name && (
            <ErrorMessage
              id="firstNameErrorDesc"
              message={t(errors.first_name, {
                length: MINIMUM_CHARS_IN_NAME,
              })}
            />
          )}
        </Col>
        <Col xs={12} md={2}>
          <FloatingLabelInput
            name="middle_name"
            autoFocus={false}
            className="my-2"
            value={values.middle_name || ''}
            onChange={handleChange}
            readOnly={!!values.disabled_fields.first_name}
            ariaRequired="true"
            ariaLabel="MI (middle name)"
            id="middle_name_input"
            label="MI"
            validation={errors.middle_name && 'is-invalid'}
          />
        </Col>
        <Col xs={12} md={5}>
          <FloatingLabelInput
            name="last_name"
            autoFocus={false}
            className="my-2"
            value={values.last_name || ''}
            minLength={2}
            onChange={handleChange}
            readOnly={!!values.disabled_fields.last_name}
            ariaRequired="true"
            ariaLabel="last name"
            ariaDescribedby={errors.last_name ? "lastNameErrorDesc" : undefined}
            ariaInvalid={!!errors.last_name}
            id="last_name_input"
            label={
              t(
                buildCustomizedLabel(
                  testGroup.population,
                  'registration.last_name_label',
                ),
              ) + ' *'
            }
            validation={errors.last_name && 'is-invalid'}
          />
          {errors.last_name && (
            <ErrorMessage
              id="lastNameErrorDesc"
              message={t(errors.last_name, {
                length: MINIMUM_CHARS_IN_NAME,
              })}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12}>
          <label className="lead-20-medium inter bold">{ `${t('registration.date_of_birth')}` }</label>
        </Col>
        <MinimumAgeNotice testGroup={testGroup} />
        <Col xs={12} lg={8} md={8} className="p-0">
          <OldDateOfBirthInput name="" setFieldValue={setFieldValue} values={values} errors={errors} dateFieldRef={dateFieldRef} />
          {errors.date_of_birth && (
            <ErrorMessage
              id="dateOfBirthErrorDesc"
              className="px-3"
              message={t(errors.date_of_birth, { field: t('registration.date_of_birth') })}
            />
          )}
        </Col>
      </Row>
      { testGroup.request_personal_information &&
        <AddressFields errors={errors} values={values} setFieldValue={setFieldValue} stateFieldRef={stateFieldRef} />
      }
      <LocalizedMarkdown container={testGroup} stringKey="contact_information_details" />
      {
        testGroup.population.employee_id_regex && (
          <Row>
            <Col xs={12}>
              <LocalizedMarkdown container={testGroup.population} stringKey='employee_id_text' />
            </Col>
            <Col lg={6} sm={6} xl={6} md={6} className="mb-3">
              <FloatingLabelInput
                name="employee_id"
                className="string"
                value={values.employee_id || localFormState.employeeID}
                onChange={handleChange}
                ariaDescribedby="employeeIdErrorDesc"
                ariaInvalid={!!errors.employee_id}
                id="employee_id"
                label={t('registration.employee_id')}
                validation={errors.employee_id && 'is-invalid'}
            />
          </Col>
          <Col lg={6} sm={6} xl={6} md={6} className="mb-3">
            <FloatingLabelInput
              name="employee_id"
              className="string"
              value={values.employee_id || localFormState.employeeID}
              onChange={handleChange}
              ariaDescribedby="employeeIdErrorDesc"
              ariaInvalid={!!errors.employee_id}
              id="employee_id"
              label={t('registration.employee_id')}
              validation={errors.employee_id && 'is-invalid'}
            />
            {errors.employee_id && (
              <ErrorMessage
                id="employeeIdErrorDesc"
                message={t(errors.employee_id)}
              />
            )}
          </Col>
        </Row>
      )}
      {testGroup.population.has_password_regex && (
        <Row>
          <Col xs={12}>
            <LocalizedMarkdown
              container={testGroup.population}
              stringKey="password_input_description"
            />
          </Col>
          <Col xs={12} lg={6} sm={6} xl={6} md={6} className="mb-3">
            <FloatingLabelInput
              name="password"
              className="string"
              value={values.password || ''}
              onChange={handleChange}
              ariaDescribedby="passwordErrorDesc"
              ariaInvalid={!!errors.password}
              id="password"
              label={t('registration.password')}
              validation={errors.password && 'is-invalid'}
            />
            {errors.password && (
              <ErrorMessage
                id="passwordErrorDesc"
                message={t(errors.password)}
              />
            )}
          </Col>
        </Row>
      )}
      {testGroup.tags.length > 0 && (
        <Row>
          {tagLabel.length > 30 && (
            <Col xs={12} className="custom-tag-label">
              <LocalizedMarkdown
                container={testGroup}
                stringKey="registration_tag_text"
              />
            </Col>
          )}
          <Col xs={12} md={8} lg={8} xl={6} className="my-3">
            <FloatingLabelSelect
              value={tagOptions.filter((option) =>
                values.tags.includes(option.value),
              )}
              onChange={(s) =>
                setFieldValue(
                  'tags',
                  (s || []).map((s) => s.value),
                )
              }
              options={tagOptions}
              isMulti={true}
              ariaDescribedby="tagErrorDesc"
              ariaInvalid={!!errors.tag}
              id="tags"
              filledValue={values.tags.length}
              label={
                tagLabel
                  ? tagLabel.length <= 30
                    ? tagLabel
                    : 'Select'
                  : 'Choose all that describe you *'
              }
              className={errors.tag && 'is-invalid'}
            />
            {errors.tag && (
              <ErrorMessage
                id="tagErrorDesc"
                message={t(errors.tag, {
                  field: tagLabel || 'Choose all that describe you',
                })}
              />
            )}
          </Col>
        </Row>
      )}
      {!testGroup.verify_contact_information &&
        <PhoneAndEmailFields {...{ values, errors, setFieldValue }} />
      }
      <div className="text-muted">
        <LocalizedMarkdown
          container={testGroup}
          stringKey="registration_privacy_policy"
        />
      </div>
    </div>
  );
};

export default ContactInformation;
