import React, { useState, useEffect } from 'react';
import { Alert, Form, Col, Button } from 'react-bootstrap';

//This will probably get more complex
const Scanner = ({onSubmit, setScannerOpen}) => {
  const [scanned, setScanned] = useState('');
  const [error, setError] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  useEffect(() => setError(''), [showScanner]);
  return (
    <div>
      {
        !showScanner && <Button onClick={() => {
          setShowScanner(true);
          setScannerOpen(true);
        }} variant="outline-primary">Scan Barcode</Button>
      }
      {
        showScanner && <Form>
          <Form.Row className="justify-content-around">
            <Col lg={5} xs={12} className="my-2">
              <Form.Control
                autoFocus
                onChange={(e) => setScanned(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                value={scanned}
              />
              {error && <Alert className="mt-2" variant='danger'>{error}</Alert>}
            </Col>
            <Col lg={3} xs={5} className="my-2">
              <Button 
                onClick={() => {
                  onSubmit(scanned, setError, () => showScanner(false));
                  setScanned('');
                }} 
                variant="primary"
                block
              >
                Submit
              </Button>
            </Col>
            <Col lg={3} xs={5} className="my-2">
              <Button 
                onClick={() => {
                  setScanned('');
                  setShowScanner(false);
                  setScannerOpen(false);
                }} 
                variant="danger"
                block
              >
                Cancel
              </Button>
            </Col>
          </Form.Row>
        </Form>
      }
    </div>
  );
}

export default Scanner;