import React, { useContext } from 'react';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import HelpText from '../../../../common/components/HelpText';
import FloatingLabelSelect from '../../../../common/components/FloatingLabelSelect';
import FloatingLabelInput from '../../../../common/components/FloatingLabelInput';
import RegistrationContext from '../../../contexts/RegistrationContext';
import { buildLocalizedString } from '../../../../common/locales/langs';
import LocalizedMarkdown from './../fields/LocalizedMarkdown';
import { PersonalFieldDropdown } from '../../SectionComponents';

const GenderInput = ({
  attributeString="",
  context = RegistrationContext,
  error,
  handleChange,
  setFieldValue,
  values,
}) => {
  const { t, i18n } = useTranslation();
  const { testGroup, localFormState, setLocalFormState } = useContext(context);

  const genderOptions = testGroup.genders.map((genderKey) => {
    return {
      value: genderKey,
      label: t(`gender_key.${genderKey}`),
    }
  });
  const label = buildLocalizedString(testGroup, 'gender_label');
  const required = testGroup.required_attributes.gender_identity;

  return (
    <PersonalFieldDropdown
      helpText={
        buildLocalizedString(testGroup, 'gender_help_text') ? (
          <LocalizedMarkdown container={testGroup} stringKey='gender_help_text' />
          ) : <span>{ t('gender.help') }</span>
      }
    >
      <div className="mb-2">
        <div className="text-muted">
          { buildLocalizedString(testGroup, 'gender_subtitle') && (
            <div className="d-inline-block"><LocalizedMarkdown container={testGroup} stringKey='gender_subtitle' /></div>
            )
          }
        </div>

        <FloatingLabelSelect
          className={`test-user-gender ${error && 'is-invalid'}`}
          containerClassName="mt-3"
          name="gender"
          ariaLabel="gender select, required"
          value={genderOptions.filter(option => (
            option.value === (localFormState.describeGender ? "prefer_to_self_describe" : values.gender_string)
          ))}
          onChange={(s) => {
            setLocalFormState({...localFormState, describeGender: s.value === "prefer_to_self_describe"});
            setFieldValue(`${attributeString}gender_string`, s.value);
          }} 
          options={genderOptions}
          filledValue={localFormState.describeGender ? 'prefer_to_self_describe' : values.gender_string}
          label={
            label
              ? `${label} ${required ? '*' : '(Optional)'}`
              : `${t('registration.gender')} ${required ? '*' : '(Optional)'}`
          }
        />
      </div>
      {
        localFormState.describeGender ?
        <div className="my-2">
          <FloatingLabelInput
            value={values.self_described_gender || ""}
            onChange={handleChange}
            className="mt-4"
            name={attributeString + "self_described_gender"}
            label={`${t('registration.self_described_gender')} *`}
          />
        </div> : null
      }
    </PersonalFieldDropdown>
  );
};

export default GenderInput;
