import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faCirclePlus, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ServiceSpecificationBadge from './ServiceSpecificationBadge';
import ServiceSpecificationSelector from './ServiceSpecificationSelector';
import { useTranslation } from 'react-i18next';

const EditServiceSpecifications = ({
  dispatch,
  state,
}) => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const badges = state.filteredServiceSpecifications
    .map((serviceSpecification, idx) => (
      <Col xs="auto" key={idx} className="mb-4 pt-2">
        <ServiceSpecificationBadge
          serviceSpecification={serviceSpecification}
          t={t}
          onRemove={() => {
            const updatedServiceSpecifications = {};
            state.filteredServiceSpecifications.forEach((s) => updatedServiceSpecifications[s.key] = true);
            updatedServiceSpecifications[serviceSpecification.key] = false;
            dispatch({
              type: 'updateServiceSpecifications',
              values: updatedServiceSpecifications,
            })
          }}
        />
      </Col>
    ));

  return (
    <div className="border-bottom mt-2 mb-3">
      <div className="d-flex mb-2">
        <h6 className="lead-20-medium inter bold my-auto">{t('family_registrations.vaccines')}</h6>
        {!expanded && (
          <FontAwesomeIcon
            icon={expanded ? faCircleXmark : faCirclePlus}
            color={state.filteredServiceSpecifications?.length > 0 ? '#A0ABC0' : '#2862FA'}
            style={{ height: 20, alignSelf: 'center' }}
            className="pointer mx-2 my-3"
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </div>
      <Row className="mb-3">
        {expanded && (
          <div className="ms-2" style={{ width: 300 }}>
            <ServiceSpecificationSelector
              dispatch={dispatch}
              serviceSpecifications={state.serviceSpecifications}
              selectedServiceSpecifications={state.selectedServiceSpecifications}
            />
          </div>
        )}
        {badges}
      </Row>
    </div>
  );
};


export const EditTestConfigurations = ({
  onChange,
  testConfigurations,
  selectedTestConfigurations,
  dateOfBirth,
  index,
}) => {
  const { t, i18n } = useTranslation();
  const badges = selectedTestConfigurations
    .map((testConfigurationId, idx) => (
      <Col xs="auto" key={idx} className="mb-4 pt-2">
        <ServiceSpecificationBadge
          key={idx}
          t={t}
          serviceSpecification={
            testConfigurations.find(testConfiguration =>
              Number(testConfiguration.key) === Number(testConfigurationId)
            )
          }
          onRemove={() =>
            onChange(selectedTestConfigurations.filter(id => id !== testConfigurationId))
          }
        />
      </Col>
    ));

  return (
    <div className="py-4 my-2">
      <div className="d-flex mb-2">
        <h6 className="lead-20-medium inter bold my-auto">
          {t('family_registrations.vaccines_needed')} ({t('registration.optional')})
        </h6>
      </div>
      <div className="my-3" style={{ width: 450 }}>
        <ServiceSpecificationSelector
          dateOfBirth={dateOfBirth}
          dispatch={({values}) => onChange(Object.keys(values))}
          index={index}
          selectedServiceSpecifications={selectedTestConfigurations.map(x => Number(x))}
          serviceSpecifications={testConfigurations}
        />
      </div>
      <Row className="mb-3">
        {badges}
      </Row>
    </div>
  );
};

export default EditServiceSpecifications;
