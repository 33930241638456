import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { formatNumberWithCommas } from '../../../../common/utils';
import TooltipWrapper from '../../../../common/components/TooltipWrapper';
import { ThreeLineItem } from './Items';
import Wrapped from '../../../../common/components/Wrapped';

export const DetailCard = ({ count, title, tooltip: tooltipContent }) => (
  <Wrapped
    WrapperComponent={TooltipWrapper}
    wrapIf={!!tooltipContent}
    componentProps={{ tooltipContent }}
  >
    <Card className="text-center h-100">
      <Card.Body style={{ padding: '1rem' }}>
        <Card.Title style={{ fontWeight: 700 }}>
          {formatNumberWithCommas(count)}
        </Card.Title>
        <Card.Text>{title}</Card.Text>
      </Card.Body>
    </Card>
  </Wrapped>
);

export const CardWrapper = ({ children, className }) => {
  return (
    <Card className={className}>
      <Card.Body className="d-flex">{children}</Card.Body>
    </Card>
  );
};

export const ThreeLineInputCard = ({
  className,
  value: propValue,
  setValue: propSetValue,
  title,
  actionText,
  tooltip,
}) => {
  const [value, setValue] = useState(propValue);
  const [editing, setEditing] = useState(false);

  return (
    <CardWrapper className={className}>
      <ThreeLineItem
        title={title}
        count={
          editing ? (
            <input
              className="form-control m-auto w-100"
              type="number"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          ) : (
            formatNumberWithCommas(value)
          )
        }
        footer={
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (editing) propSetValue(value);
              setEditing(!editing);
            }}
          >
            {editing ? 'Done' : actionText}
          </a>
        }
        tooltip={!editing ? tooltip : null}
      />
    </CardWrapper>
  );
};
