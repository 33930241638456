import { isBefore, subHours } from 'date-fns';

const HOURS_BEFORE_STALE = 24;

class IISReferences {
  constructor(references) {
    this.references = references.sort((a, b) =>
      // Newest to oldest
      new Date(b.last_checked_at).getTime() - new Date(a.last_checked_at).getTime()
    );
  }

  mostRecent() {
    return this.references[0];
  }

  static isStale(reference) {
    const lastCheckedAt = new Date(reference.last_checked_at);
    const stalenessThreshold = subHours(Date.now(), HOURS_BEFORE_STALE);
    return isBefore(lastCheckedAt, stalenessThreshold);
  }

  static hasErrors(reference) {
    return reference.status !== 'ok';
  }
}

export default IISReferences;
