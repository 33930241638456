import React from 'react';

const SchoolImmunizations = ({
  startFileUpload = () => {},
  pendingImmunizationRecords = [],
}) => (
  <div className="school-immunizations">
    <span className="section-header d-none d-lg-block">
      School immunizations
    </span>

    <div className="status-card mt-2">
      <div className="d-flex flex-column" style={{ gap: 12 }}>
        <span className="card-period-header">
          2023-2024 Immunization Status
        </span>
        <span className="card-title">
          {
            pendingImmunizationRecords.filter(
              (r) => r.immunization_record_status === 'overdue',
            ).length
          }{' '}
          Overdue immunizations
        </span>
      </div>
      <div className="d-flex align-items-center">
        <button className="btn btn-outline-primary" onClick={startFileUpload}>
          Update immunizations
        </button>
      </div>
    </div>
  </div>
);

export default SchoolImmunizations;
