import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from 'date-fns';

import { getTranslationParams } from "./MessageTemplateForm";
import NewMessageConfirmationModal from "./NewMessageConfirmationModal";
import Select from '../../common/components/Select';

const STEPS = ["Add recipients", "Customize message", "Schedule"];

const getHour = (timeString) => {
  const hour = timeString.split(":")[0];
  return timeString.split(" ")[1] == "AM" ? hour : parseInt(hour) + 12;
}; 

const getMinutes = (timeString) => {
  return timeString.split(" ")[0].split(":")[1];
};

const NewMessageHeader = ({
  currentStep,
  dispatch,
  setCurrentStep,
  state,
}) => {

  const [customMessageTemplates, setCustomMessageTemplates] = useState(null);
  const [showMessageConfirmationModal, setShowMessageConfirmationModal] = useState(false);
  const deliverNow = state.deliver_now;
  const finalStep = currentStep >= STEPS.length - 1;

  const fetchCustomMessageTemplates = async () => {
    const response = await axios.get("/custom_message_templates");
    setCustomMessageTemplates(response.data);
  };

  useEffect(() => {
    if (!customMessageTemplates) fetchCustomMessageTemplates();
  }, []);

  const customMessageTemplateOptions = () => {
    if (!customMessageTemplates) return [];
    return customMessageTemplates.map((t) => {
      const label = (
        <div>
          <div className="body-small-regular inter semibold">{t.name}</div>
          <div className="body-small-regular truncate-after-two-lines">{t.params.email_subject}</div>
        </div>
      )
      return { value: t.id, label: label };
    });
  };

  const handleTemplateSelect = (selectedTemplateId) => {
    const template = customMessageTemplates.find(t => t.id == selectedTemplateId);
    dispatch({ type: "setCustomTemplateValues", values: template.params })
  };

  const sendMessage = async () => {
    const endDate = state.message_settings.end_date
      ? format(state.message_settings.end_date, 'yyyy-MM-dd')
      : null;
    const languages = state.message_settings.languages.includes("en")
      ? state.message_settings.languages
      : [...state.message_settings.languages, "en"]
    const translationParams = getTranslationParams(state.message_settings);
    const startDate = state.message_settings.date
      ? format(state.message_settings.date, 'yyyy-MM-dd')
      : null;

    const params = {
      communication: {
        end_date: deliverNow
          ? null
          : endDate,
        organization_id: state.message_settings.organization_id,
        params: {
          email_message: state.message_settings.email_message,
          email_subject: state.message_settings.email_subject,
          filter_params: state.filter_params,
          languages: languages,
          sms_message: state.message_settings.sms_message,
          selected_test_group_user_ids: state.all_filtered_test_group_user_ids,
          ...translationParams,
        },
        repeating: deliverNow
          ? null
          : state.message_settings.repeating,
        start_date: deliverNow
          ? null
          : startDate,
        start_hour: deliverNow
          ? null
          : getHour(state.message_settings.time),
        start_minute: deliverNow
          ? null
          : getMinutes(state.message_settings.time),
        time_zone: deliverNow
          ? null
          : state.message_settings.time_zone,
        total_runs: deliverNow
          ? null
          : state.message_settings.total_runs,
      }
    }
    try {
      await axios.post("/messages", params );
      toastr.success("Message successfully scheduled");
      window.location.href = `/messages?messages=${deliverNow ? "sent" : "scheduled"}`;
    } catch(e) {
      toastr.error("Something went wrong");
    }
  };

  const stepForward = () => {
    currentStep == 2
      ? sendMessage()
      : setCurrentStep(currentStep + 1)
  };

  const forwardButtonDisabled = () => {
    if (currentStep == 0) {
      if (!state.filter_params.organizations.length ||
          !state.all_filtered_test_group_user_ids.length
        ) return true;
    }
    if (currentStep == 1) {
      if (!state.message_settings.email_message ||
          !state.message_settings.email_subject ||
          !state.message_settings.organization_id
         ) {
        return true;
      }
    }
    if (currentStep == 2) {
      if ((!state.message_settings.date ||
          !state.message_settings.time ||
          !state.message_settings.time_zone
         ) && !state.deliver_now) {
        return true;
      }
      if (!!state.message_settings.repeating &&
          (!state.message_settings.end_date && !state.message_settings.total_runs)
         ) {
        return true;
      }
    }
    return false;
  };

  const stepper = (
    <div className="d-flex">
      {STEPS.map((stepName, idx) => {
        const stepInPast = idx < currentStep;
        const stepInFuture = idx > currentStep;
        const icon = stepInPast
          ? "a-sharp fa-solid fa-circle-check"
          : "fa-sharp fa-regular fa-circle-check";

        return (
          <div className={`mx-3 ${(stepInPast || stepInFuture) ? "text-muted" : "inter medium"}`}>
            <FontAwesomeIcon
              className="me-2"
              icon={icon}
              style={{ color: stepInFuture ? "#A0ABC0" : "" }}
            />
            {stepName}
          </div>
        )
      })}
    </div>
  );

  const stepperButtons = (
    <div className="d-flex">
      {currentStep > 0 && (
        <Button
          onClick={() => setCurrentStep(currentStep - 1)}
          variant="link"
        >
          Back
        </Button>
      )}
      {currentStep == 1 && (
        <div style={{ width: "200px" }}>
          <Select
            className="mx-2"
            onChange={handleTemplateSelect}
            options={customMessageTemplateOptions()}
            placeholder="Templates"
          />
        </div>
      )}
      <>
        <NewMessageConfirmationModal
          confirm={stepForward}
          deliverNow={deliverNow}
          onHide={() => setShowMessageConfirmationModal(false)}
          recipientCount={state.recipient_count}
          show={showMessageConfirmationModal}
        />
        <Button
          disabled={forwardButtonDisabled()}
          onClick={() => {
            finalStep
              ? setShowMessageConfirmationModal(true)
              : stepForward()
          }}
        >
          {finalStep
            ? `${deliverNow ? "Send message" : "Schedule message"}`
            : "Continue"
          }
        </Button>
      </>
    </div>
  );

  return (
    <div
      className="d-flex justify-content-between pt-3 pb-4"
      style={{ borderBottom: "1px solid #DEE2E6" }}
    >
      <Button
        href="/messages"
        variant="link"
      >
        Exit message builder
      </Button>
      <div className="my-auto">
        {stepper}
      </div>
      <div>
        {stepperButtons}
      </div>
    </div>
  );
};

export default NewMessageHeader;