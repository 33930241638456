import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BarcodeScanner from './BarcodeScanner';
import ModalHeader from './ModalHeader';
import FloatingLabelInput from './FloatingLabelInput';

const AccessCodeSearch = ({
  class_name = '',
  size = 'sm',
  show_as_dropdown_item: showAsDropdownItem = false,
}) => {
  const [show, setShow] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);

  const onSearch = (e) => {
    e.preventDefault();
    const path = window.location.pathname;
    const urlParams = new URLSearchParams();
    if (accessCode) {
      urlParams.set('access_code', accessCode);
    }
    window.location.href = `${path}?${urlParams.toString()}`;
  };

  const onHide = () => {
    setShowBarcodeScanner(false);
    setShow(false);
  };

  const inputRef = useRef();
  useEffect(() => {
    if (show) {
      inputRef.current && inputRef.current.focus();
    }
  });

  if (show) {
    return (
      <Modal show={open} onHide={onHide} size="lg" autoFocus={true}>
        <ModalHeader closeButton onHide={onHide}>
          <Modal.Title as="h2" id="accessCodeModalLabel">
            Search by Access Code
          </Modal.Title>
        </ModalHeader>
        <Form onSubmit={onSearch}>
          <Modal.Body>
            {showBarcodeScanner && (
              <BarcodeScanner
                onScan={(scanned) => {
                  setAccessCode(scanned);
                  setShowBarcodeScanner(false);
                }}
              />
            )}
            <FloatingLabelInput
              label="Search"
              id="search_access_code"
              onChange={(e) => setAccessCode(e.target.value)}
              value={accessCode}
              ref={inputRef}
              prependIcon="search"
            />
          </Modal.Body>
          <Modal.Footer>
          <Button
            onClick={() => {setShowBarcodeScanner(!showBarcodeScanner);}}
            variant="outline-primary"
            className="mx-2"
          >
            <FontAwesomeIcon icon={'camera'} className="me-2" />
            Scan
          </Button>
          <Button
            variant="primary"
            disabled={!accessCode}
            onClick={onSearch}
            className="mx-2"
          >
            Search
          </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  } else {
    return (
      <>
        {showAsDropdownItem ? (
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
            >
              Search by code
            </a>
          </li>
        ) : (
          <Button
            className={class_name}
            variant="outline-tertiary"
            size={size}
            onClick={() => setShow(true)}
          >
            Search by code
          </Button>
        )}
      </>
    );
  }
};

export default AccessCodeSearch;
