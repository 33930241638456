import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import OrganizationDropdown from './OrganizationDropdown';
import StatusDropdown from './StatusDropdown';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import TooltipWrapper from '../../common/components/TooltipWrapper';

const STATUSES = [
  { value: '', label: 'All Statuses' },
  { value: '0', label: 'Demo' },
  { value: '1', label: 'Draft' },
  { value: '2', label: 'Active' },
  { value: '3', label: 'Archived' },
  { value: '4', label: 'Read only' },
  { value: '6', label: 'Template' },
];

export const pluralize = (size, string) => {
  let value = `${size} ${string}`;
  return size > 1 ? `${value}s` : value;
};

const getStatus = (status) => {
  return STATUSES.filter(o => status.split(",").includes(o.value)).map(o => o.label).join(", ");
};

export const SearchBar = ({ inputValue, setInputValue, updateSearchFilter }) => {
  return (
    <form
      noValidate="novalidate"
      className="col pb-2 pb-lg-0 simple_form search"
    >
      <div className="d-flex align-items-center">
        <FloatingLabelInput
          className="col p-0"
          name="q"
          label="Search by name"
          type="text"
          id="search_q"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              updateSearchFilter(inputValue);
            }
          }}
        />
      </div>
    </form>
  );
};

const FilterBar = ({ inputValue, setInputValue, updateSearchFilter }) => {

  useEffect(() => {
    const handler = setTimeout(() => {
      updateSearchFilter(inputValue);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  return (
    <div className="col-md-6 col-lg mb-2 mb-lg-0">
      <FloatingLabelInput
        className="col p-0"
        name="q"
        label="Filter"
        type="text"
        id="filter_q"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
    </div>
  );
};

const BulkButtons = ({
  bulkEditEnabled,
  setBulkEditEnabled,
  selectedTestGroups,
}) => (
  <React.Fragment>
    {bulkEditEnabled && (
      <ButtonGroup className="col-auto ms-md-0 me-lg-3 ms-lg-2 mb-md-2 mb-lg-0 px-lg-0">
        <Button
          disabled={selectedTestGroups.length === 0}
          className="btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#bulk-edit-modal"
        >
          Edit {`(${selectedTestGroups.length})`}
        </Button>
      </ButtonGroup>
    )}
    {bulkEditEnabled ? (
      <ButtonGroup
        className="col-auto p-0 mx-3 ms-2 ms-md-0 mb-md-2 mb-lg-0"
        toggle
      >
        <Button
          onClick={() => setBulkEditEnabled(false)}
          variant="outline-tertiary"
        >
          Exit bulk edit
        </Button>
      </ButtonGroup>
    ) : (
      <ButtonGroup className="col-auto px-lg-0 pb-2 pb-lg-0 me-3" toggle>
        <TooltipWrapper
          placement="bottom"
          tooltipContent="Bulk edit mode overrides table pagination. Filter Groups by name."
          overlayProps={{ delay: { show: 150, hide: 400 } }}
        >
          <Button
            className="ms-2 ms-md-0"
            onClick={() => setBulkEditEnabled(true)}
            variant="outline-tertiary"
          >
            Bulk edit
          </Button>
        </TooltipWrapper>
      </ButtonGroup>
    )}
  </React.Fragment>
);

const FiltersDescription = ({ filters, organizations }) => {
  const selectedOrganization = organizations.find(
    (o) => o.id === parseInt(filters.organizationId),
  );

  const searchTermDescription = filters.q ? `"${filters.q}"` : '';
  const organizationDescription = selectedOrganization
    ? `organization "${selectedOrganization.name}"`
    : '';
  const statusDescription = filters.status
    ? `status "${getStatus(filters.status)}"`
    : '';

  let descriptions = [
    searchTermDescription,
    organizationDescription,
    statusDescription,
  ].filter((description) => !!description);
  descriptions = descriptions.map((description, idx) =>
    descriptions.length > 1 && idx === descriptions.length - 1
      ? ` and ${description}`
      : description,
  );

  return <span>{descriptions.join(', ')}</span>;
};

const Toolbar = ({
  filters,
  user,
  bulkEditEnabled,
  setBulkEditEnabled,
  organizations,
  testGroups,
  updateFilter,
  resetFilters,
  testGroupsCount,
  selectedTestGroups,
}) => {
  const { organizationId, q: searchTerm, status } = filters;
  const organizationOptions = useMemo(() => {
    const options = organizations.map((org) => ({
      value: org.id,
      label: org.name,
    }));
    options.unshift({ value: '', label: 'All Organizations' });

    return options;
  }, [organizations]);

  const [inputValue, setInputValue] = useState(searchTerm);

  const updateSearchFilter = useCallback(
    (searchTerm) => {
      updateFilter('q', searchTerm);
    },
    [updateFilter],
  );

  return (
    <React.Fragment>
      <div className="row align-items-center">
        {bulkEditEnabled ? (
          <FilterBar
            inputValue={inputValue}
            setInputValue={setInputValue}
            updateSearchFilter={updateSearchFilter}
          />
        ) : (
          <SearchBar
            inputValue={inputValue}
            setInputValue={setInputValue}
            updateSearchFilter={updateSearchFilter}
          />
        )}
        <div className="col-12 col-lg-3 col-md-6 pb-2 pb-lg-0">
          <OrganizationDropdown
            organizationId={organizationId}
            organizationOptions={organizationOptions}
            updateFilter={updateFilter}
          />
        </div>
        <div className="col-12 col-lg-2 col-md-6 pb-2 pb-lg-0">
          <StatusDropdown
            godUser={user.isGod}
            status={status}
            updateFilter={updateFilter}
          />
        </div>
        {!bulkEditEnabled && (
          <div className="col-auto btn-group ms-2 me-3 p-0">
            <Button
              variant="secondary"
              block
              onClick={() => updateSearchFilter(inputValue)}
            >
              Search
            </Button>
          </div>
        )}
        {user.canBulkEdit && (
          <BulkButtons
            bulkEditEnabled={bulkEditEnabled}
            setBulkEditEnabled={setBulkEditEnabled}
            selectedTestGroups={selectedTestGroups}
          />
        )}
        <div className="col-12">
          <p className="mt-3">
            <strong>{pluralize(testGroupsCount, 'result')}</strong>
            {Object.values(filters).filter((val) => !!val).length > 0 && (
              <React.Fragment>
                <span> for </span>
                <FiltersDescription
                  filters={filters}
                  organizations={organizations}
                />{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    resetFilters();
                  }}
                >
                  Clear filters
                </a>
              </React.Fragment>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Toolbar;
