import React from 'react';
import { Section, SectionItem } from '../../components/styled';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownCustomClass } from '../../components/styled';

export default function SuccessStep({ data, isMobile, t }) {
  const { header, image, text } = data;

  return (
    <Section>
      <SectionItem>
        <div
          className={`${isMobile ? 'text-center' : 'text-center mt-3 pt-3'}`}
        >
          <h3 className="mb-3 kit-success-text">{t(header)}</h3>
          <img className="mb-3" src={image} />
          <ReactMarkdown
            source={t(text)}
            renderers={{
              paragraph: ({ children }) => (
                <ReactMarkdownCustomClass customClass="kit-success-text">
                  {children}
                </ReactMarkdownCustomClass>
              ),
            }}
          />
        </div>
      </SectionItem>
    </Section>
  );
}
