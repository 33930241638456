import React from 'react';
import styled, { css } from 'styled-components';

import FabrxCheckbox from '../../../../Registration/primary/FabrxCheckbox';

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-inline: 1.25rem !important;
  padding-block: 0.75rem !important;
  border-bottom: 1px solid #e2e7f0;

  cursor: pointer;

  &:hover {
    background-color: #f7fafc;
  }

  ${({isDisabled}) => isDisabled && css`
    color: #A0ABC0;
    background-color: #F7F9FC;
  `}
  ${({isLastElement}) => isLastElement && css`
    border-bottom: 0px;
  `}
`;

const CheckboxOption = ({ innerProps, isDisabled, ...props }) => (
  <CustomOptionContainer
    {...innerProps}
    isDisabled={isDisabled}
    isLastElement={props.options[props.options.length - 1].key == props.data.key}
  >
    <FabrxCheckbox
      checked={props.isSelected}
      onChange={(value) => (value ? props.selectOption : props.clearValue)}
    />
    {props.children}
  </CustomOptionContainer>
);

export default CheckboxOption;
