import React, { useMemo, useState } from 'react';
import FloatingLabelSelect from './FloatingLabelSelect';

const makeOptions = (collection, valueKey, labelKey, options = {}) =>
  collection.map((opt) => {
    const value = opt[valueKey];
    let label = opt[labelKey];

    if (
      options['truncateLabelAt'] &&
      label.length > options['truncateLabelAt']
    ) {
      label = `${label.slice(0, options['truncateLabelAt'])}...`;
    }

    return { value, label };
  });

const MultiSelect = ({
  name,
  selected,
  opts,
  valueKey = 'id',
  label,
  labelKey = 'friendly_name',
  style = {},
  shouldAutoSubmit = false,
  truncateLabelAt = null,
}) => {
  const selectOptions = useMemo(() =>
    makeOptions(opts, valueKey, labelKey, { truncateLabelAt }),
  );

  const [currentlySelected, setCurrentlySelected] = useState(
    makeOptions(selected, valueKey, labelKey, { truncateLabelAt }),
  );

  const onChange = (selected) => {
    setCurrentlySelected(selected);
    if (shouldAutoSubmit) {
      const path = window.location.pathname;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete(name.toLowerCase());
      if (selected) {
        urlParams.set(
          name.toLowerCase(),
          selected.map((item) => item.value).join(','),
        );
      }
      window.location.href = `${path}?${urlParams.toString()}`;
    }
  };

  return (
    <div style={{ width: '100%', ...style }}>
      {!shouldAutoSubmit && (
        <input
          type="hidden"
          value={
            currentlySelected
              ? currentlySelected.map((item) => item.value).join(',')
              : ''
          }
          name={name.toLowerCase()}
        />
      )}
      <FloatingLabelSelect
        className="basic-multi"
        classNamePrefix="select"
        label={label || name}
        value={currentlySelected}
        options={selectOptions}
        isSearchable={true}
        components={{ IndicatorSeparator: () => null }}
        name="name"
        onChange={onChange}
        isMulti
        filledValue={Array.isArray(currentlySelected) && !!currentlySelected.length}
      />
    </div>
  );
};

export default MultiSelect;
