import React from 'react';
import PrimaryHeartOutline from '../../../common/components/Icons/PrimaryHeartOutline';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

export function ThankYou() {
  const { t } = useTranslation();
  return (
    <div className="text-center mb-3 pb-3">
      <PrimaryHeartOutline className="my-3 py-3" />
      <h4 className="my-2">{t('viral_surveillance.thank_you.title')}</h4>
      <p className="my-2">
        <ReactMarkdown
          source={t('viral_surveillance.thank_you.p_1', {
            link: 'https://covid19.ca.gov/variants/#in-california',
          })}
        />
      </p>
      <p className="my-2">
        <b>{t('viral_surveillance.thank_you.p_2')}</b>
      </p>
      <p className="my-2">{t('viral_surveillance.thank_you.p_3')}</p>
    </div>
  );
}
