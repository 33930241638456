import React from 'react';
import { HeaderProps } from './Header';

export interface CellProps<RecordType extends {}> {
  header: HeaderProps<RecordType>;
  record: RecordType;
}

const Cell = <RecordType,>({
  header: { CellComponent, ...header },
  record,
}: CellProps<RecordType>) => (
  <td
    className={header.cellClassName}
    colSpan={header.colSpan}
    style={header.cellStyle}
  >
    {CellComponent ? (
      <CellComponent {...record} />
    ) : header.field ? (
      record[header.field]
    ) : (
      record[header.name]
    )}
  </td>
);

export default Cell;
