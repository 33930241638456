import React, { useState, useEffect } from 'react';
import * as ScanditSDK from 'scandit-sdk';

const BarcodeScanner = ({
  onScan,
  containerClassName = 'col-sm-10 col-md-6',
}) => {
  useEffect(() => {
    ScanditSDK.configure(gon?.scandit_api_key, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build',
    }).then(() => {
      return ScanditSDK.BarcodePicker.create(
        document.getElementById('barcode-picker'),
        {
          playSoundOnScan: true,
          vibrateOnScan: true,
          videoFit: ScanditSDK.BarcodePicker.ObjectFit.COVER,
        },
      ).then((picker) => {
        window.barcodePicker = picker;
        picker.applyScanSettings(
          new ScanditSDK.ScanSettings({
            enabledSymbologies: [
              ScanditSDK.Barcode.Symbology.CODE128,
              ScanditSDK.Barcode.Symbology.DATA_MATRIX,
              ScanditSDK.Barcode.Symbology.QR,
              ScanditSDK.Barcode.Symbology.UPCA,
              ScanditSDK.Barcode.Symbology.UPCE,
              ScanditSDK.Barcode.Symbology.EAN8,
              ScanditSDK.Barcode.Symbology.EAN13,
              ScanditSDK.Barcode.Symbology.PDF417,
            ],
            codeDuplicateFilter: -1,
          }),
        );
        picker
          .on('scan', (scanResult) => {
            picker.pauseScanning();
            const barcode = scanResult.barcodes[0].data.replace('\u001D', ''); // Sanitize input
            onScan(barcode);
            picker.destroy();
          })
          .on('scanError', console.error);
      });
    });
    return () => {
      if (window.barcodePicker) {
        window.barcodePicker.destroy();
      }
    };
  }, []);
  return (
    <div>
      <div className="row">
        <div className={`center ${containerClassName}`}>
          <div
            id="scan-content"
            className="content"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              id="barcode-picker"
              className="barcode-picker"
              style={{
                zIndex: '10',
                width: '100%',
                height: '250px',
                boxSizing: 'border-box',
                border: '1px solid rgba(0, 0, 0, 0.26)',
                padding: '2px',
              }}
            ></div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeScanner;
