import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ReactMarkdown from 'react-markdown/with-html';

const IconWrapper = styled.div`
  height: 50px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconComponent = ({ Icon, iconStyles = {} }) => {
  return (
    <div>
      <IconWrapper>
        <Icon style={{ ...iconStyles }} color="#193154" />
      </IconWrapper>
    </div>
  );
};

export function Instructions({ data, t }) {
  const { title, steps } = data;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      <h4 className="my-2">{t(title)}</h4>
      <div className="my-2">
        {steps.map(({ label, substeps, icon }, index) => (
          <Row className="my-2 py-2" key={index}>
            <Col xs="auto" className="pe-0">
              <span className="fa-stack" style={{ verticalAlign: 'middle' }}>
                <i>
                  <FontAwesomeIcon
                    icon={'circle'}
                    style={{ color: '314677' }}
                    className="fa-stack-2x"
                  />
                </i>
                <span className="fa-layers-text" style={{ color: '#FFFFFF' }}>
                  {index + 1}
                </span>
              </span>
            </Col>
            <Col>
              <div className="my-1">
                <ReactMarkdown source={t(label)} escapeHtml={false} />
              </div>
              {substeps && (
                <div className="my-2 pt-2">
                  {substeps.map(({ label }, index) => (
                    <Row
                      className="my-2"
                      style={{ verticalAlign: 'middle' }}
                      key={index}
                    >
                      <Col xs="auto" className="pe-0">
                        <i>
                          <FontAwesomeIcon
                            icon={'circle'}
                            style={{ color: '314677' }}
                            className="fa-xs"
                          />
                        </i>
                      </Col>
                      <Col>{t(label)}</Col>
                    </Row>
                  ))}
                </div>
              )}
            </Col>
            {icon && (
              <Col
                xs="auto"
                className="d-flex align-items-center justify-content-center"
              >
                <IconComponent
                  Icon={icon}
                  iconStyles={{
                    minHeight: isMobile ? '40' : '32',
                    width: isMobile ? '45' : '36',
                  }}
                />
              </Col>
            )}
          </Row>
        ))}
      </div>
    </div>
  );
}
