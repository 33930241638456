import React, { FC, useState } from 'react';
import axios from 'axios';

import Select from '../../common/components/Select';
import ButtonControl from '../../common/components/Select/components/ButtonControl';
import { AssignableUser, Test } from './Index';

const assignTests = (test: Test, userId: number) =>
  axios.put(
    `/test_groups/${test.testGroup.slug}/case_reports/${test.id}.json`,
    { assigned_user_id: userId },
  );

interface AssigneeDropdownProps {
  selectedTests?: Test[];
  flush?: boolean;
  assignedUser?: {
    id: number;
    fullName: string;
  };
  assignableUsers: AssignableUser[];
  refreshOnAssign?: boolean;
}

const toastr = window['toastr'];

const AssigneeDropdown: FC<AssigneeDropdownProps> = ({
  selectedTests,
  flush = false,
  assignedUser,
  assignableUsers,
  refreshOnAssign = false,
}) => {
  const [value, setValue] = useState(assignedUser?.id);

  const onChange = (userId: number) => {
    selectedTests.forEach((test) =>
      refreshOnAssign
        ? assignTests(test, userId)
        : assignTests(test, userId)
            .then(() => {
              toastr?.success('Success');
              setValue(userId);
            })
            .catch((error: { message: any; response: any }) => {
              const errors = error.response?.data?.errors?.join('\n');
              toastr?.error(`${error.message}\n${errors}`);
            }),
    );

    if (refreshOnAssign) {
      window.location.reload();
    }
  };

  return (
    <Select
      value={value}
      options={assignableUsers}
      valueKey="id"
      labelKey="fullName"
      onChange={onChange}
      blankLabel="Unassigned"
      placeholder={flush ? 'Unassigned' : 'Assign to'}
      borderless={flush}
      components={{ Control: ButtonControl }}
    />
  );
};

export default AssigneeDropdown;
