import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import CloneSlotsModal from '../../common/components/CloneDayConfigurationModal';
import { sumProperty } from '../../common/utils';
import { formatTime } from '../../common/utils/slotting';
import { faCheck, faChartLine, faClone, faUpRightFromSquare, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

const ActionPanel = ({ bulkClone, freezeAppointmentSlots }) => (
  <div className="d-none my-2 dropdown" id="action-panel">
    <button
      className="btn btn-primary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Bulk actions
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#bulk-cancel-modal"
      >
        Cancel
      </a>
      <a
        className="dropdown-item"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          bulkClone();
        }}
      >
        Clone
      </a>
      <a
        className="dropdown-item"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (window.confirm("Freeze selected appointment slots?")) {
            freezeAppointmentSlots();
          }
        }}
      >
        Freeze
      </a>
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#bulk-reschedule-modal"
      >
        Reschedule
      </a>
      <a
        className="dropdown-item"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#update-modal"
      >
        Update
      </a>
    </div>
  </div>
);

const TableHeaders = () => (
  <thead>
    <tr>
      <th>
        <div className="form-check">
          <input
            type="checkbox"
            className="apply-all form-check-input"
            name="apply_all"
            id="apply-all"
            value="1"
            data-group="all"
          />
          <div className="state p-primary">
            <label></label>
          </div>
        </div>
      </th>
      <th>Location name</th>
      <th>Day</th>
      <th>Hours</th>
      <th colSpan={2}>
        <div className="d-flex flex-column">
          <div className="w-100 d-flex flex-row align-items-center">
            <hr className="flex-grow-1 mx-1 my-0" style={{ borderWidth: 3 }} />
            <span>Shared</span>
            <hr className="flex-grow-1 mx-1 my-0" style={{ borderWidth: 3 }} />
          </div>
          <div className="d-flex flex-row align-items-end" style={{ gap: 24 }}>
            <span style={{ flex: '1 0 0' }}>Standard capacity</span>
            <span style={{ flex: '1 0 0' }}>Access key capacity</span>
          </div>
        </div>
      </th>
      <th colSpan={2}>
        <div className="d-flex flex-column">
          <div className="w-100 d-flex flex-row align-items-center">
            <hr className="flex-grow-1 mx-1 my-0" style={{ borderWidth: 3 }} />
            <span>Group</span>
            <hr className="flex-grow-1 mx-1 my-0" style={{ borderWidth: 3 }} />
          </div>
          <div className="d-flex flex-row align-items-end" style={{ gap: 24 }}>
            <span style={{ flex: '1 0 0' }}>Standard capacity</span>
            <span style={{ flex: '1 0 0' }}>Access key capacity</span>
          </div>
        </div>
      </th>
      <th>Booked</th>
      <th>Administered</th>
      <th colSpan="1"></th>
    </tr>
  </thead>
);

const AppointmentSlotRow = ({
  appointmentSlot: {
    id,
    starts_at,
    ends_at,
    capacity,
    access_key_capacity,
    shared_capacity,
    shared_access_key_capacity,
    booked,
    administered,
    participants_path,
    administered_path,
  },
  group,
  expanded,
  updateSlot,
  countsLoading,
}) => {
  const [editing, setEditing] = useState(false);
  const [fields, setFields] = useState({ capacity, access_key_capacity });

  const toggleEditing = (e) => {
    e.preventDefault();
    if (editing) {
      const { capacity, access_key_capacity } = fields;
      updateSlot({
        capacity: parseInt(capacity) || 0,
        access_key_capacity: parseInt(access_key_capacity) || 0,
      });
    }
    setEditing(!editing);
  };

  const updateField = (field) => (e) => {
    setFields({
      ...fields,
      [field]: e.target.value,
    });
  };

  return (
    <tr className={expanded ? 'hoverable' : 'd-none'}>
      <td>
        <OverlayTrigger placement="top" overlay={<Tooltip>{id}</Tooltip>}>
          <div className="form-check">
            <input
              className="appointment-slot-checkbox form-check-input"
              type="checkbox"
              name="appointment_slots[][appointment_id]"
              id="appointment_slots__appointment_id"
              data-group={group}
              value={id}
            />
            <div className="state p-primary">
              <label></label>
            </div>
          </div>
        </OverlayTrigger>
      </td>
      <td></td>
      <td></td>
      <td>
        {formatTime(starts_at)} - {formatTime(ends_at)}
      </td>
      <td>{shared_capacity}</td>
      <td>{shared_access_key_capacity}</td>
      {editing ? (
        <React.Fragment>
          <td>
            <input
              type="number"
              value={fields['capacity']}
              onChange={updateField('capacity')}
            />
          </td>
          <td>
            <input
              type="number"
              value={fields['access_key_capacity']}
              onChange={updateField('access_key_capacity')}
            />
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>{capacity}</td>
          <td>{access_key_capacity}</td>
        </React.Fragment>
      )}
      {countsLoading ? (
        <React.Fragment>
          <td style={{ opacity: 0.5 }}>
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
          </td>
          <td style={{ opacity: 0.5 }}>
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <td>
            {booked}{' '}
            <a
              className="display-on-hover"
              href={participants_path}
              target="blank"
            >
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          </td>
          <td>
            {administered}{' '}
            <a
              className="display-on-hover"
              href={administered_path}
              target="blank"
            >
              <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
            </a>
          </td>
        </React.Fragment>
      )}
      <td>
        {editing ? (
          <a onClick={toggleEditing} href="#">
            <FontAwesomeIcon icon={faCheck} /> Done
          </a>
        ) : (
          <a className="display-on-hover" onClick={toggleEditing} href="#">
            <FontAwesomeIcon icon={faPenToSquare} /> Edit
          </a>
        )}
      </td>
    </tr>
  );
};

const AppointmentSlotsGroup = ({
  display_date: date,
  day_name,
  location: { name: location_name },
  shared_standard_capacity,
  shared_access_key_capacity,
  booked: booked_count,
  administered: administered_count,
  index: groupIndex,
  appointment_slots: propAppointmentSlots,
  groupCountsPath,
  participants_path,
  administered_path,
  dose_management_path,
  cloneDay,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [countsLoading, setCountsLoading] = useState(false);
  const [appointmentSlots, setAppointmentSlots] =
    useState(propAppointmentSlots);

  const details = useMemo(() => {
    const firstSlot = appointmentSlots[0];
    const lastSlot = appointmentSlots[appointmentSlots.length - 1];

    return {
      hours: `${formatTime(firstSlot.starts_at)} - ${formatTime(
        lastSlot.ends_at,
      )}`,
      capacity: sumProperty(appointmentSlots, 'capacity'),
      access_key_capacity: sumProperty(appointmentSlots, 'access_key_capacity'),
      booked: sumProperty(appointmentSlots, 'booked'),
      administered: sumProperty(appointmentSlots, 'administered'),
    };
  }, [appointmentSlots]);

  const updateSlot = (idx) => (values) => {
    axios
      .patch(`${appointmentSlots[idx].update_path}.json`, values)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          const newAppointmentSlots = [...appointmentSlots];
          newAppointmentSlots[idx] = { ...newAppointmentSlots[idx], ...values };
          setAppointmentSlots(newAppointmentSlots);
        }
      });
  };

  const fetchCounts = () => {
    setCountsLoading(true);

    axios
      .get(groupCountsPath, {
        params: { appointment_ids: appointmentSlots.map((s) => s.id) },
      })
      .then((response) => {
        const { appointment_slots: slotCounts } = response.data;
        const newAppointmentSlots = [...appointmentSlots];

        slotCounts.forEach(({ id, ...slot }) => {
          let idx = newAppointmentSlots.findIndex((s) => s.id === id);
          newAppointmentSlots[idx] = {
            ...newAppointmentSlots[idx],
            ...slot,
          };
        });

        setAppointmentSlots(newAppointmentSlots);
        setCountsLoading(false);
      })
      .catch(() => {
        setCountsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <tr className="hoverable">
        <td>
          <div className="form-check">
            <input
              type="checkbox"
              className="apply-to-group form-check-input"
              name="apply_to_group"
              value="1"
              data-group={groupIndex}
            />
            <div className="state p-primary">
              <label></label>
            </div>
          </div>
        </td>
        <td>{location_name}</td>
        <td>
          <span style={{ display: 'inline-block', minWidth: 32 }}>
            {day_name}
          </span>{' '}
          <span>{date}</span>
        </td>
        <td className="column">
          <div>{details['hours']}</div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              fetchCounts();
              setExpanded(!expanded);
            }}
          >
            {expanded ? 'Hide' : 'See/edit'} slots
          </a>
        </td>
        <td>{shared_standard_capacity}</td>
        <td>{shared_access_key_capacity}</td>
        <td>{details['capacity']}</td>
        <td>{details['access_key_capacity']}</td>
        <td>
          {details['booked'] || booked_count}{' '}
          <a
            className="display-on-hover"
            href={participants_path}
            target="blank"
          >
            <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
          </a>
        </td>
        <td>
          {details['administered'] || administered_count}{' '}
          <a
            className="display-on-hover"
            href={administered_path}
            target="blank"
          >
            <FontAwesomeIcon size="sm" icon={faUpRightFromSquare} />
          </a>
        </td>
        <td>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a
              className="display-on-hover"
              onClick={(e) => {
                e.preventDefault();
                cloneDay(groupIndex);
              }}
              href="#"
            >
              <FontAwesomeIcon icon={faClone} /> Clone day
            </a>
            {dose_management_path && (
              <a
                className="display-on-hover"
                href={dose_management_path}
                target="blank"
              >
                <FontAwesomeIcon icon={faChartLine}/> Dose management
              </a>
            )}
          </div>
        </td>
      </tr>
      {appointmentSlots.map((appointmentSlot, index) => (
        <AppointmentSlotRow
          key={index}
          group={groupIndex}
          appointmentSlot={appointmentSlot}
          expanded={expanded}
          updateSlot={updateSlot(index)}
          countsLoading={countsLoading}
        />
      ))}
    </React.Fragment>
  );
};

export const GroupedAppointmentSlots = ({
  appointment_slot_groups: appointmentSlotGroups,
  clone_day_path: cloneDayPath,
  clone_slots_path: cloneSlotsPath,
  freeze_appointment_slots_path: freezeAppointmentSlotsPath,
  group_counts_path: groupCountsPath,
}) => {
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [isBulkClone, setIsBulkClone] = useState(false);
  const [slotGroupsToClone, setSlotGroupsToClone] = useState([]);

  const startCloneDay = (groupIndex) => {
    setIsBulkClone(false);
    setCloneModalOpen(true);
    setSlotGroupsToClone([appointmentSlotGroups[groupIndex]]);
  };

  const startBulkClone = () => {
    setIsBulkClone(true);
    setCloneModalOpen(true);
    const selectedGroups = $('.apply-to-group:checked')
      .get()
      .map((e) => $(e).data('group'));

    const slotGroups = selectedGroups.map((groupIdx) => {
      const slotGroup = appointmentSlotGroups[groupIdx];
      const selectedSlotIds = $(
        `.appointment-slot-checkbox[data-group=${groupIdx}]:checked`,
      )
        .get()
        .map((e) => parseInt(e.value));

      return {
        ...slotGroup,
        appointment_slots: slotGroup.appointment_slots.filter((as) =>
          selectedSlotIds.includes(as.id),
        ),
      };
    });

    setSlotGroupsToClone(slotGroups);
  };

  const freezeAppointmentSlots = () => {
    const selectedAppointmentSlotIds = [];
    $('.appointment-slot-checkbox').map(function() {
      if (this.checked) selectedAppointmentSlotIds.push(this.value);
    });

    axios.post(freezeAppointmentSlotsPath, {
      appointment_slot_ids: selectedAppointmentSlotIds
    }).then(() => {
      toastr.success('Freezing selected appointment slots');
      window.location.reload();
    }).catch(() => {
      toastr.error('Something went wrong');
    });
  };

  return (
    <React.Fragment>
      <ActionPanel
        bulkClone={startBulkClone}
        freezeAppointmentSlots={freezeAppointmentSlots}
      />
      <section className="fabrx-tables-light table-responsive">
        <table className="table table-lg">
          <TableHeaders />
          <tbody>
            {appointmentSlotGroups.map((appointmentSlotGroup, index) => (
              <AppointmentSlotsGroup
                key={index}
                index={index}
                groupCountsPath={groupCountsPath}
                cloneDay={startCloneDay}
                {...appointmentSlotGroup}
              />
            ))}
          </tbody>
        </table>
      </section>
      <CloneSlotsModal
        show={cloneModalOpen}
        onHide={() => setCloneModalOpen(false)}
        cloneDayPath={cloneDayPath}
        cloneSlotsPath={cloneSlotsPath}
        groupsToClone={slotGroupsToClone}
        isBulkClone={isBulkClone}
      />
    </React.Fragment>
  );
};
