import { sum } from "../common/utils";

export const immunizationRecordCount = (immunizationRecords) =>
  sum(Object.values(immunizationRecords).map(immunizationRecord => immunizationRecord.doses.length))

export const countExemptions = (immunizationRecords) => {
  if (!immunizationRecords) return 0;
  return Object.values(immunizationRecords).filter((ir) => {
    return ["medically_exempt", "non_medically_exempt"].includes(ir["immunization_record_status"]);
  }).length;
};
