import React from 'react';
import { Button } from 'react-bootstrap';

const PureButton = ({
  color,
  className,
  outline,
  dataTest,
  ariaLabel,
  tabIndex,
  block,
  active,
  type,
  onClick,
  autoFocus = false,
  children,
  style,
  ...props
}) => {
  return (
    <Button
      variant={outline ? 'outline' : ''}
      data-test-hook={dataTest}
      block={block}
      type={type}
      autoFocus={autoFocus}
      className={`custom-focus pure-button ${active && 'active'} ${className}`}
      style={{ '--custom-color': color, ...style }}
      onClick={onClick}
      {...props}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    >
      {children}
    </Button>
  );
};

export default PureButton;
